<template>
  <div class="trade-history">
    <div class="tbl-history">
      <table class="table-size">
        <tbody>
          <tr
            v-for="(transaction, index) in getTransactions()"
            :key="index"
            @click="onRecentTradesClicked(transaction.price)"
          >
            <td
              class="trade-history-cl1"
              :class="transaction.transaction_type"
              :title="
                transaction.price
                  | formatCurrencyAmount(currency)
                  | paddingRight(coinSettingByPair.fractionPartPrice, '0')
              "
            >
              {{
                transaction.price
                  | formatCurrencyAmount(currency)
                  | paddingRight(coinSettingByPair.fractionPartPrice, "0")
              }}
            </td>
            <td
              class="trade-history-cl2"
              :title="
                transaction.quantity
                  | formatCurrencyAmount(coin)
                  | paddingRight(coinSettingByPair.fractionPartQuantity, '0')
              "
            >
              {{
                transaction.quantity
                  | formatCurrencyAmount(coin)
                  | paddingRight(coinSettingByPair.fractionPartQuantity, "0")
              }}
            </td>
            <td class="trade-history-cl3">
              {{ transaction.created_at | orderTime }}
            </td>
          </tr>
          <tr class="no-data" v-if="isEmptyData">
            <td>
              <span>
                <img
                  class="icon-search"
                  :src="
                    require(`@/assets/images/landing/icon-nodata${
                      theme === 'light-theme' ? '' : '-dark'
                    }.svg`)
                  "
                />
              </span>
              <p>{{ $t("common.datatable.no_data") }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import rf from "@/request/RequestFactory";
import Const from "@/common/Const";
import BigNumber from "bignumber.js";
import { mapState } from "vuex";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      marketTransactions: "",
      maxTransactionCount: 50,
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,

      isEmptyData: false,

      coinSettings: [],
      coinSettingByPair: {},
      disableByBetaTester: false,
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
    };
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
      pairCoins: (state) => state.pairCoins,
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  watch: {
    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;
      this.getCoinSettingsByPairCurrency();
      this.initData();
    },
    userThemeMode(theme) {
      this.theme = theme;
    },

    marketTransactions(newValue) {
      this.isEmptyData = window._.isEmpty(newValue);
    },
    $route(to, from) {
      this.initData();
    },
  },
  methods: {
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },

    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
      this.getRecentTransactions();
      this.getCoinSettingsByPairCurrency();
    },

    getTransactions() {
      return this.marketTransactions;
    },

    onRecentTradesClicked(price) {
      this.$broadcast("RecentTradeClicked", price);
    },

    getSocketEventHandlers() {
      return {
        OrderTransactionCreated: this.onOrderTransactionCreated,
      };
    },

    onOrderTransactionCreated(data) {
      let newItem = window._.cloneDeep(data.orderTransaction);
      if (this.currency != newItem.currency || this.coin != newItem.coin) {
        return;
      }
      let marketTransactions = window._.cloneDeep(this.marketTransactions);
      this.marketTransactions = this.pushNewTransaction(
        newItem,
        marketTransactions
      );
    },

    pushNewTransaction(transaction, transactions) {
      transactions?.splice(0, 0, transaction);
      if (transactions?.length > this.maxTransactionCount) {
        return transactions.slice(0, this.maxTransactionCount);
      }
      return transactions;
    },

    onReceiveTransactions(data) {
      if (!data || !data.length) return [];

      if (data.length <= this.maxTransactionCount) {
        return data;
      }

      return data.slice(0, this.maxTransactionCount);
    },
    getRecentTransactions() {
      let params = {
        currency: this.currency,
        coin: this.coin,
        count: 50,
      };
      if (this.disableByBetaTester) {
        this.marketTransactions = [];
        this.quantityTransactions = 0;
      } else {
        rf.getRequest("OrderRequest")
          .getRecentTransactions(params)
          .then((res) => {
            this.marketTransactions = this.onReceiveTransactions(res.data);
            this.quantityTransactions = this.marketTransactions.length;
          });
      }
    },

    getCoinSettings() {
      this.coinSettings = window._.map(
        this.masterdata.coin_settings,
        (item) => {
          item.fractionPartPrice = this.getDecimalPrecision(
            item.coin,
            item.precision
          );
          item.fractionPartQuantity = this.getDecimalPrecision(
            item.coin,
            item.quantity_precision
          );
          return item;
        }
      );
      this.getCoinSettingsByPairCurrency();
    },

    getCoinSettingsByPairCurrency() {
      if (window._.isEmpty(this.coinSettings)) {
        this.coinSettingByPair = {};
        return;
      }
      const result = window._.filter(this.coinSettings, (item) => {
        return item.currency === this.currency && item.coin === this.coin;
      });
      if (!window._.isEmpty(result)) {
        this.coinSettingByPair = result[0];
      }
    },

    getDecimalPrecision(currency, value) {
      if (currency === "usd") {
        // convert to million unit
        value = new BigNumber(value).div(1000000).toString();
      }
      return Math.round(Math.log(1 / value) / Math.log(10));
    },
    initData() {
      this.disableByBetaTester = false;
      let coin = this.$route.query.coin || Const.DEFAULT_COIN;
      let currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;

      const setting = this.pairCoins;
      if (!setting.is_enable && setting.is_show_beta_tester) {
        this.disableByBetaTester = true;
        if (window.isAuthenticated) {
          rf.getRequest("UserRequest")
            .getUserPairTradingSetting({ coin, currency })
            .then((res) => {
              if (
                res &&
                res.data &&
                res.data.enable_trading == Const.ENABLE_TRADING.ENABLE
              ) {
                this.disableByBetaTester = false;
              }
              this.getRecentTransactions();
            });
        } else {
          this.getRecentTransactions();
        }
      }
    },
  },
  mounted() {
    let params = {
      currency: this.currency,
      coin: this.coin,
      count: 50,
    };
    this.getCoinSettings();
    this.initData();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.dark-theme {
  .trade-history .tbl-history .table-size tr td {
    color: $color-white;
  }
}
.trade-history {
  width: 100%;
  display: inline-block;
  padding: 5px 0px;
  .tbl-history {
    width: 100%;
    height: 100%;
    float: left;
    .table-size {
      width: 100%;
      height: fit-content;
      float: left;
      tr {
        width: 100%;
        cursor: pointer;
        &:hover {
          td {
            //background-color: $color-ebony-clay;
          }
        }
        &.no-data {
          text-align: center;
          border-bottom-style: none;
          margin-top: 80px;
          display: flex;
          justify-content: center;
          td:first-child {
            padding-left: 0 !important;
          }

          p {
            color: $color-gray-dark;
            font-family: $font-inter-regular;
            font-size: $font-root;
            line-height: 16px;
            margin: 16px 0 0 0;
          }
          .icon-nodata {
            font-size: 60px;
            color: $color-grey-sliver;
          }
          &:hover {
            td {
              background: none !important;
            }
          }
        }
        .trade-history-cl1 {
          display: inline-block;
          float: left;
          width: 35%;
          text-align: left;
        }
        .trade-history-cl2 {
          display: inline-block;
          width: 40%;
          text-indent: 3%;
          text-align: center;
        }
        .trade-history-cl3 {
          display: inline-block;
          width: 25%;
          text-align: right;
          padding-right: 15px;
        }
        td {
          font-size: $font-root;
          line-height: 24px;
          text-overflow: ellipsis;
          overflow: hidden;
          color: $color-grey-dark;
          //&:last-child {
          //  padding-right: 10px;
          //}
          &:first-child {
            padding-left: 10px;
          }
          &.sell {
            color: $text-color-red;
          }
          &.buy {
            color: $text-color-jade;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .trade-history .tbl-history .table-size tr .trade-history-cl3 {
    padding-right: 0;
  }
}
</style>

<style lang="scss">
#home .page-container-right {
  #order_trade_form {
    overflow: hidden;
    height: 100%;
    .tab_content_ordertrade {
      overflow-y: auto;
      height: 100%;
      .item:hover {
        background-color: initial;
      }
    }

    .tbl-history {
      overflow-y: auto;
    }
  }
}
</style>
