<template>
  <div class="transitionSlide container-header-home-outside">
    <HomeSlide :isAuthenticated="isAuthenticated" />
    <markets-main id="markets_table_landing" visibleCoinName />
    <trust-section />
    <app-download />
    <crypto-stats :isAuthenticated="isAuthenticated" />
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import CookiesUtils from "@/common/CookiesUtils";
import Const from "@/common/Const";
import BigNumber from "bignumber.js";
import MarketsMain from "@/components/home_pages/MarketMain.vue";
import moment from "moment";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import TSlide from "@/components/Slide.vue";
import { mapState } from "vuex";
import HomeSlide from "@/components/home_pages/HomeSlide.vue";
import TrustSection from "@/components/home_pages/TrustSection.vue";
import AppDownload from "@/components/home_pages/AppDownload.vue";
import CryptoStats from "@/components/home_pages/CryptoStats.vue";

const CHART_COLORS = ["#e8d579", "#94bcea", "#f2bd8e", "#82e0c9", "#d9d9f1"];

export default {
  components: {
    TSlide,
    HomeSlide,
    TrustSection,
    AppDownload,
    CryptoStats,
    MarketsMain,
  },
  data() {
    return {
      currenSlide: 0,
      prices: {},
      top24hBtcCoins: [],
      pricesScope: {},
      idxColor: 0,
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,
      isAuthenticated: window.isAuthenticated,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 24,
        initialSlide: 0,
        direction: "horizontal",
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      banners: [],
      apiUrl: process.env.VUE_APP_STORAGE_URL,
      siteSetting: {},
      socicalNetworks: [],
      coinImages: [],

      insurance: {},
      sizeScreen: window.innerWidth,
    };
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
      theme: (state) => state.userThemeMode,
    }),
    visibleCoinChart() {
      if (
        window._.isEmpty(this.top24hBtcCoins) ||
        window._.isEmpty(this.pricesScope)
      ) {
        return false;
      }
      setTimeout(function () {
        $(".carousel").carousel({
          activate: function () {},
          timerAnimSlide: 400,
          itensDisplay: 4,
          spaceBetweenItens: 20,
          itensMove: 1,
          breakPoint: [
            { width: 800, itensDisplay: 3 },
            { width: 580, itensDisplay: 1 },
            { width: 700, itensDisplay: 2 },
          ],
          responsive: true,
        });
      }, 100);
      return true;
    },
    localeLang() {
      let lang = this.$store.state.localLang;
      if (typeof lang === "object") {
        lang = window.localStorage.locale;
      }
      if (lang === "ko") {
        lang = "en";
      }
      if (lang === "ko") {
        lang = "en";
      }
      // return lang;
      let obj = {
        lang: lang,
        en: "en",
      };
      return obj;
    },
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
    thisDark() {
      return this.theme === "light-theme" ? "dark" : "white";
    },
  },
  methods: {
    getImages() {
      return this.coinImages;
    },
    getPrice(pair) {
      return this.prices[pair] || { price: 0 };
    },
    checkShowByLocale() {
      return AuthenticationUtils.getLocale() !== "en";
    },
    getChartColor() {
      if (this.top24hBtcCoins && this.idxColor > this.top24hBtcCoins.length) {
        return;
      }
      const index = this.idxColor % 5;
      this.idxColor++;
      return CHART_COLORS[index];
    },

    getPriceScope(currency, coin) {
      return window._.filter(this.pricesScope, (priceScope) => {
        return priceScope.currency === currency && priceScope.coin === coin;
      })[0];
    },

    toUsdPrice(price, fromCurrency) {
      return new BigNumber(price)
        .mul(new BigNumber(this.getPrice("usd_" + fromCurrency).price))
        .toString();
    },

    pricesScopeUpdated(newPrices) {
      this.pricesScope = newPrices;

      this.calculateUsdPrice();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.calculateUsdPrice();
      this.top24hBtcCoins = this.getTop24hBtcCoins();
    },

    calculateUsdPrice() {
      window._.forEach(this.pricesScope, (item) => {
        item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
      });
    },

    getTop24hBtcCoins() {
      let dataReturn = window._.chain(this.prices)
        .filter((item) => item.currency === "btc")
        .orderBy(["volume"], ["desc"])
        .map((item) => item.coin)
        .take(4)
        .value();
      return dataReturn;
    },

    gotoUrl(url) {
      window.location.href = url;
    },

    gotoAPIDocs() {
      let url = process.env.VUE_APP_API_URL + "/api/documentation";
      window.open(url);
    },
    getInsuranceFund() {
      return rf
        .getRequest("InsuranceRequest")
        .getInsuranceFund()
        .then((res) => {
          this.insurance = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    initData() {
      rf.getRequest("PriceRequest")
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
        });

      rf.getRequest("PriceRequest")
        .getPrices24h()
        .then((res) => {
          this.pricesScopeUpdated(res.data);
        });

      rf.getRequest("NoticeRequest")
        .getBannerNotices()
        .then((res) => {
          this.banners = res.data;
        });

      _.forEach(this.masterdata?.coins, (item, key) => {
        this.coinImages[item.coin] = item.icon_image;
      });
      this.coinImages["usd"] = `@/assets/images/color_coins/usd.png`;
      this.getInsuranceFund();
    },

    setCookiesReferralCode() {
      const referralCode = this.$route.query[Const.REFERRAL_KEY] || null;
      if (referralCode) {
        CookiesUtils.saveItem(Const.REFERRAL_KEY, referralCode);
      }
    },

    doSlide() {
      if (this.currenSlide >= 3) {
        this.currenSlide = 0;
      } else {
        this.currenSlide = this.currenSlide + 1;
      }
    },

    goSlide(slide) {
      clearInterval(slideTimer);
      this.currenSlide = slide;
      slideTimer = setInterval(function () {
        window["landingCpn"].doSlide();
      }, 5000);
    },

    parseBannerDate(str) {
      if (str !== null && str.toString() !== "") {
        var date = new Date(str);
        return moment(String(date)).format("DD / MM / YYYY");
      } else {
        return str;
      }
    },
    convertSiteSetting(data) {
      window._.each(data, (item) => {
        this.siteSetting[item.key] = item.value;
      });
    },

    getMasterdata() {
      this.convertSiteSetting(this.masterdata?.settings);
      this.socicalNetworks =
        window._.filter(
          this.masterdata?.social_networks,
          (item) => item.is_active
        ) || {};
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  watch: {
    masterdata() {
      this.getMasterdata();
    },
  },
  mounted() {
    this.getMasterdata();
    if (
      this.$route.params.isChangedPassword === true ||
      this.$route.query.isChangedPassword
    ) {
      Message.success(
        window.i18n.t("change_password_form.successful_message"),
        {},
        { position: "bottom_left" }
      );
      AuthenticationUtils.removeAuthenticationData();
      this.isAuthenticated = false;
      window.app.$broadcast("CHANGED_PASSWORD");
    }
    this.setCookiesReferralCode();
    this.initData();

    // Handle 'window.GlobalSocket.notifyPricesUpdate'
    this.$on("PricesUpdated", (data) => {
      this.onPricesUpdated(data);
    });

    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "/tradingview/charting_library/static/js/external/carousel.js"
    );
    document.head.appendChild(recaptchaScript);
    window.addEventListener("resize", this.resizeHandler);
  },
  created() {
    window["landingCpn"] = this;
    this.getMasterdata();
  },
};

var slideTimer;
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/slide/slide";
@import "@/assets/sass/slide/TransitionSlide";

$bland_w_1200: 1200px;
$wain_btn_color: #c30b57;
$wain_bg: 1px solid rgba(255, 255, 255, 0.7);

.dark-theme {
  .container-header-home-outside {
    background-color: #151716;
  }
}

.container-header-home-outside {
  background-color: var(--background-color-primary);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 200px;
  #markets_table_landing {
    max-width: 1284px;
    width: 100%;
    @media screen and (max-width: 610px) {
      margin-top: unset;
    }
  }
  @media screen and (max-width: 610px) {
    gap: unset;
  }
}
</style>
