const mutations = {
  // updateUser: (state, payload) => {
  //   state.user = { ...payload }
  // },
  // updateInstrument: (state, payload) => {
  //   state.instrument = { ...payload }
  // },
  // updateSettlement: (state, payload) => {
  //   state.settlement = { ...payload }
  // },
  // updateFunding: (state, payload) => {
  //   state.funding = { ...payload }
  // },
  // updateInsurance: (state, payload) => {
  //   state.insurance = { ...payload }
  // },
  // updateCompositeIndex: (state, payload) => {
  //   state.compositeIndex = { ...payload }
  // },
  updateLocalLang: (state, payload) => {
    state.localLang = payload;
  }
}

export default {
  mutations
}
