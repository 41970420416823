<template>
  <div id="mobile_seriesApp">
    
    <contract-series-web/>

  </div>
</template>

<script>
  import ContractSeriesWeb from "./ContractSeries";

  export default {
    components: {
      ContractSeriesWeb,
    },
  }
</script>

<style lang="scss">
  @import "@/assets/sass/variables";
  #mobile_seriesApp {
    .content_page_right {
      .title_contracts {
        font-size: $font-big-20;
      }
    }
    .box_table_all_curious .table_all_curious td {
      width: auto !important;
      &:first-child {
        padding-right: 10px;
      }
    }
    .btb_view {
      width: 340px;
      max-width: 100%;
      min-width: auto;
    }
  }
  @media screen and (max-width: 1199px) {
    #content {
      &.page-container.isReferral {
        min-width: auto;
        max-width: 1199px;
        width: 100%;
      }
    }
  }
  .disable-app {
    display: none;
  }
  #footer,
  #header {
    &.disable-app {
      display: none;
    }
  }
</style>