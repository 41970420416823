<template>
  <div>
    <b-container fluid class="mobile-row">
      <b-row
        v-for="(group, index) in groupedHeaderTable"
        :key="index"
        class="mobile-cell-row"
      >
        <b-col
          cols="6"
          :class="{ cursor: group[0].id === 'triggerConditions' }"
        >
          <div>
            <label class="title" :title="$t(group[0].name)">{{
              $t(group[0].name)
            }}</label>
            <p v-if="group[0].id !== 'triggerConditions'">{{ "--" }}</p>
            <p v-else>{{ "--" }}</p>
          </div>
        </b-col>

        <b-col
          v-if="group[1]"
          cols="6"
          :class="{ cursor: group[1].id === 'triggerConditions' }"
        >
          <div class="col-right">
            <label class="title" :title="$t(group[1].name)">{{
              $t(group[1].name)
            }}</label>
            <p v-if="group[1].id !== 'triggerConditions'">{{ "--" }}</p>
            <p v-else>{{ "--" }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OPEN_ORDER_HEADER_CONST from "@/components/spotexchange_pages/order/DataTableInit";

export default {
  props: {},
  data() {
    return {
      headerTable: OPEN_ORDER_HEADER_CONST.ORDER_HISTORY_HEADER,
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    lightMode() {
      return this.theme === "light-theme";
    },
    groupedHeaderTable() {
      const groups = [];
      for (let i = 0; i < this.headerTable.length; i += 2) {
        groups.push(this.headerTable.slice(i, i + 2));
      }

      return groups;
    },
  },
  methods: {
    async fetch() {
      const res = await this.getData();
      this.rows = res?.data?.data;
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.mobile-row {
  padding: 20px;
  padding-top: 40px;
  border-bottom: 1px solid #ddd;
}
.mobile-cell-row {
  margin-bottom: 16px;
}

.cursor {
  cursor: pointer;
}

.text-center {
  text-align: center;
}
.col-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.btn {
  &-cancel {
    color: #03aac7;
  }
}

.title {
  color: #8e8e92;
  font-size: 12px;
}
</style>
