<template>
  <div class="link-authenticator">
   <div class="container-header">
     <div class="title_back">
       <img :src="require(`@/assets/images/drx/arrow-left-dark.png`)" alt="icon" class="icon_back" @click="clickBack" :style="{width: '24px', height: '24px'}">
     </div>
     <h2 class="title">Link Google Authenticator</h2>
   </div>

    <p class="description">
      Google Authenticator is a dynamic password tool that works like SMS dynamic verification. A dynamic verification code is generated every 30s after binding, and the verification code can be used for security verification of operations such as login, coin withdrawal, and modification of security settings.
    </p>

    <div class="step">
     <div class="container-info-step">
       <div class="container-step-number">
         <div class="step-number">1</div>
       </div>
       <div class="step-content">
         <h3 class="title-text">Download the Google Authenticator app</h3>
         <p class="des-text">
           iOS users log in to the App Store to search for "Authenticator". Android users log in to the app store or use the mobile browser to search for "Google Authenticator".
         </p>
       </div>
     </div>
      <div class="store-buttons">
       <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
         <img src="@/assets/images/drx/buttonDownloadAppstore.png" alt="Get it on Google Play" class="store-icon">
       </a>
        <a target="_blank" href="https://itunes.apple.com/us/app/google-authenticator/id388497605">
          <img src="@/assets/images/drx/buttonAppleStore.png" alt="Download on the App Store" class="store-icon">
        </a>
      </div>
    </div>

    <div class="step">
      <div class="container-info-step">
        <div class="container-step-number">
          <div class="step-number">2</div>
        </div>
        <div class="step-content">
          <h3 class="title-text">Add a key and back up in Google Authenticator</h3>
          <p class="des-text">
            Open Google Authenticator, scan the QR code below or manually enter the following key to add a verification token. The key is used to retrieve the Google Authenticator when the phone is replaced or lost. Be sure to save the following key backup before binding.
          </p>
        </div>
      </div>
      <div class="qr-code-container">
        <QR @keyUpdated="updateKey2fa"></QR>
      </div>
    </div>

    <div class="step">
      <div class="container-info-step">
        <div class="container-step-number">
          <div class="step-number">3</div>
        </div>
        <div class="step-content" :style="{maxWidth: '337px'}">
          <h3 class="title-text">Enter the 6-digit verification code generated by Google Authenticator</h3>
        </div>
      </div>
     <div class="container-input">
       <div class="verify-input">
         <div class="login">
           <div class="input-group">
             <div class="name_input">{{ $t('account.otp.txt_pass') }}</div>
             <input
                 v-model="password"
                 @keyup.enter="verifyCode"
                 @focus="resetError"
                 name="password"
                 type="password"
                 data-vv-validate-on="none"
                 v-validate="'required'"
                 class="input"
                 :class="{ error: errors.has('password') }"
                 :placeholder="$t('common.placeholders.login_password')"
             >
             <div class="invalid-feedback" v-if="errors.has('password')">
               {{ errors.first('password') }}
             </div>
           </div>
         </div>
         <div class="authencode">
           <div class="input-group">
             <div class="name_input">{{ $t('common.placeholders.otp_2') }}</div>
             <input-only-number
                 type="text"
                 autocomplete="off"
                 v-model="authenticationCode"
                 @keyup.enter="verifyCode"
                 @focus="resetError"
                 @keypress="isNumber"
                 maxlength="6"
                 name="code"
                 :data-vv-as="$t('validation.attributes.otp_recovery_code')"
                 data-vv-validate-on="none"
                 v-validate="'required|length:6'"
                 class="input"
                 :class="{ error: errors.has('code') }"
                 :placeholder="$t('common.placeholders.authen_code')"
             />
             <div class="invalid-feedback" v-if="errors.has('code')">
               {{ errors.first('code') }}
             </div>
           </div>
         </div>
       </div>
     </div>
    </div>
    <button @click="verifyCode" class="submit-button">Submit</button>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import QR from "@/pages/account/otp/QR.vue";
import rf from "@/request/RequestFactory";
import {mapState} from "vuex";
import SettingsSecurity from "@/pages/SettingsSecurity.vue";

export default {
  name: "LinkGoogleAuthenticator",
  components: {QR},
  data() {
    return {
      key2fa: '',
      password: '',
      authenticationCode: '',
      verificationCode: "",
      theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
    };
  },
  computed:{
    ...mapState({
      theme: state => state.userThemeMode,
    }),
  },
  methods: {
    handleSubmit() {
      // Xử lý mã xác thực
      if (this.verificationCode.length === 6) {
        alert("Code submitted: " + this.verificationCode);
      } else {
        alert("Please enter a valid 6-digit code.");
      }
    },
    clickBack() {
      this.$router.back();
    },
    updateKey2fa (key) {
      this.key2fa = key;
    },
    resetError () {
      this.errors.clear();
    },
    isNumber ($event) {
      this.resetError();
      const keyCode = ($event.which) ? $event.which : $event.keyCode;
      if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    async verifyCode () {
      if (this.isSubmitting) {
        return;
      }
      await this.$validator.validateAll();
      if (this.errors.any()) {
        return;
      }
      try {
        this.startSubmit();
        await rf.getRequest('UserRequest').addSecuritySettingOtp({ code: this.authenticationCode, password: this.password });
        this.endSubmit();
        Message.success(
            window.i18n.t('account.otp.success'),
            {},
            { position: 'bottom_left' }
        );
        this.$router.push({path: '/account/security'});
        this.closeModal();
      } catch(error) {
        this.endSubmit();
        this.errors.clear();
        // const errorCode = error.response.data.errors?.code;
        // const errorPassword = error.response.data.errors?.password;
        // if(errorCode) {
        //   this.errors.add({
        //     field: 'code',
        //     msg: this.$t('validation.otp_incorrect')
        //   });
        // }
        // if(errorPassword) {
        //   this.errors.add({
        //     field: 'password',
        //     msg: this.$t('validation.custom.password.correct_password')
        //   });
        // }
        const errorOtp = error.response.data.errors;
        window._.each(errorOtp, (value, key) => {
          this.errors.add(key, this.$t(`${value[0].formatUnicorn({values: ".jpg, .jpeg, .png"})}`));
        })
      }
    },
  },
};
</script>

<style scoped lang="scss">
.link-authenticator {
  color: #ffffff;
  border-radius: 15px;
  margin: auto;

  .container-header{
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
    @media screen and (max-width: 500px) {
      gap: 16px;
    }
    h2 {
      color: var(--text-primary-color);
      font-size: 30px;
      font-style: normal;
      line-height: 38px;
      letter-spacing: -0.39px;
      font-family: $font-notosans-bold;
      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }
  }
}

.qr-code-container{
  margin-top: 20px;
}
.description {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #8E8E92;
  margin-top: 16px;
  margin-bottom: 18px;
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  flex-direction: column;
  .container-info-step{
    align-items: center;
    display: flex;

    .title-text{
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      color: var(--text-primary-color);
    }
    .des-text{
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color: #8E8E92;
    }
  }
}

.container-step-number{
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 0px 16px 0px 0px;
  }
}

.step-number {
  background-color: var(--background-color-primary);
  border-radius: 1234px;
  border: 1px solid #CBD5E1;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #CBD5E1;
}

.step-content {
  flex: 1;
}

.store-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-left: 55px;
  @media screen and (max-width: 500px) {
    margin-left: 40px;
  }
}

.store-icon {
  height: 40px;
}

.submit-button {
  background-color: var(--text-primary-color);
  color: var(--background-color-primary);
  width: 510px;
  padding: 10px 16px;
  border-radius: 1234px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: unset;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.submit-button:hover {
  background-color: #e0e0e0;
}

.container-input{
  margin-left: 55px;
  width: 100%;
  max-width: 454px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  p{
    margin-bottom: 0;
  }
  .input-code {
    width: 100%;
    padding: 12px;
    color: #fff;
    font-size: 1rem;
    min-height: 48px;
    border-radius: 123px;
    border: 1px solid #27282B;
    background: #1F2023;
  }

  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
}

.verify-input {
  margin-top: 14px;
  .name_input{
    font-family: $font-inter-regular;
    color: var(--text-primary-color);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 8px;
  }
  .input {
    min-height: 48px;
    padding: 12px;
    width: 100%;
    border-radius: 123px;
    border: 1px solid var(--button-border-outline);
    background: var(--input-bg);
    max-width: 454px;
    font-size: 18px;
    line-height: 21px;
    font-family: $font-inter-regular;
    color: var(--text-primary-color);
    &::placeholder {
      color: var(--text-close);
      font-size: 14px;
    }
    &.error {
      border: 1px solid $text-color-red;
    }
  }
  .invalid-feedback {
    color: $text-color-red;
    font-family: $font-inter-regular;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-top: 8px;
  }
}
</style>
