import BigNumber from 'bignumber.js';
import CurrentInstrument from './CurrentInstrument.js'

export default {
  getContractValue: function(price, quantity) {
    if (!price || !quantity || price === '0' || quantity === '0') { return 0; }
    const multiplier = CurrentInstrument.getMultipler();
    return multiplier < 0
      ? new BigNumber(`${multiplier || 0}`).div(`${price || 0}`).mul(`${quantity || 0}`).toString()
      : new BigNumber(`${multiplier || 0}`).mul(`${price || 0}`).mul(`${quantity || 0}`).toString();
  },

  getContractPrice: function(value, quantity) {
    const multiplier = CurrentInstrument.getMultipler();
    // return multiplier < 0
    //   ? new BigNumber(`${multiplier || 0}`).mul(`${quantity || 0}`).div(`${value || 0}`).toString()
    //   : new BigNumber(`${value || 0}`).div(`${multiplier || 0}`).div(`${quantity || 0}`).toString();
    if (new BigNumber(`${multiplier || 0}`).comparedTo(0) < 0) {
      if (!value || new BigNumber(value).comparedTo(0) == 0) {
          return '100000000';
      }
      return new BigNumber(`${multiplier || 0}`).mul(`${quantity || 0}`).div(`${value || 0}`).toString();
    }
    if (!multiplier || new BigNumber(`${multiplier || 0}`).comparedTo(0) == 0 || !quantity || new BigNumber(`${quantity || 0}`).comparedTo(0) == 0) {
      return '0';
    }
    return new BigNumber(`${value || 0}`).div(`${multiplier || 0}`).div(`${quantity || 0}`).toString();
  },

  getInitMargin: function(value, withFee = true) {
    const initMarginRatio = CurrentInstrument.getInitMarginRatio();
    const fee = withFee ? this.getOrderFee(value) : 0;
    return new BigNumber(`${value || 0}`).mul(`${initMarginRatio || 0}`).absoluteValue().add(`${fee || 0}`).toString();
  },

  getMaintMargin: function(value, position) {
    const maintMarginRatio = CurrentInstrument.getMaintMarginRatio();
    const fee = this.getPositionFee(value, position);

    return new BigNumber(`${value || 0}`).mul(`${maintMarginRatio || 0}`).absoluteValue().add(`${fee || 0}`).toString();
  },

  getMargin: function(value, leverage) {
    if (!leverage) {
      return '0';
    }
    const baseMargin = new BigNumber(value).absoluteValue().toString();
    const margin = new BigNumber(`${baseMargin || 0}`).div(`${leverage || 0}`).toString();
    return new BigNumber(`${margin || 0}`).toString();
  },

  getRealisedPnl: function(entryValue, currentPrice, closeQuantity, currentQuantity)
  {
      const currentValue = this.getContractValue(currentPrice, currentQuantity);
      return new BigNumber(`${currentValue || 0}`).minus(`${entryValue || 0}`).mul(`${closeQuantity || 0}`).div(`${currentQuantity || 0}`).toString();
  },

  isMarginOpenable: function(price, quantity, position, availableAmount) {
    let realisedPnl = 0;
    const closeSize = this.getCloseSize(quantity, position.current_qty);
    const openSize = this.getOpenSize(quantity, position.current_qty);

    const positionQuantity = new BigNumber(`${position.current_qty || 0}`).add(`${quantity || 0}`).toString();

    if (new BigNumber(`${closeSize || 0}`).comparedTo(0) != 0) {
      let closeQuantity = new BigNumber(`${closeSize || 0}`).mul('-1').toString();
      realisedPnl = this.getRealisedPnl(position.entry_value, price, closeQuantity, position.current_qty, position.symbol);

      let closeValue = new BigNumber(`${position.entry_value || 0}`).mul(`${closeSize || 0}`).div(`${position.current_qty || 0}`).toString();
      position.entry_value = new BigNumber(`${position.entry_value || 0}`).add(`${closeValue || 0}`).toString();
    }
    if (new BigNumber(`${openSize || 0}`).comparedTo(0) != 0) {
        let enValue = this.getContractValue(price, openSize, position.symbol);
        position.entry_value = new BigNumber(`${position.entry_value || 0}`).add(`${enValue || 0}`).toString();
        // if (new BigNumber(`${enValue || 0}`).absoluteValue().add(`${position.risk_value || 0}`).comparedTo(`${position.risk_limit || 0}`) > 0) {
        //   return false
        // }
    }

    if (new BigNumber(`${openSize || 0}`).comparedTo(0) == 0) {
      return true;
    }

    if (new BigNumber(`${positionQuantity || 0}`).comparedTo(0) == 0) {
        return true;
    }

    const markPrice = CurrentInstrument.getMarkPrice();
    const exitValue = this.getContractValue(markPrice, positionQuantity);

    const instantPnl = new BigNumber(`${exitValue || 0}`).minus(`${position.entry_value || 0}`).toString();

    let margin = this.getMargin(position.entry_value, position.leverage);
    margin = new BigNumber(`${margin || 0}`).add(this.getTakerFee(position.entry_value)).toString();
    const maintMargin = this.getMaintMargin(position.entry_value, position);
    // if (new BigNumber(`${margin || 0}`).minus(`${maintMargin || 0}`).add(`${instantPnl || 0}`).add(`${availableAmount || 0}`).add(`${realisedPnl || 0}`).comparedTo(0) <= 0) {
    //   return false;
    // }
    return true;
  },

  getOpenOrderMargin: function(price, quantity, _position, account) {
    let entryValue = this.getContractValue(price, quantity);
    entryValue = new BigNumber(`${entryValue || 0}`).absoluteValue().toString();
    const newOrder = {
      quantity: new BigNumber(`${quantity || 0}`).absoluteValue().toString(),
      value: entryValue,
      side: new BigNumber(`${quantity || 0}`).comparedTo(0) > 0 ? 'buy' : 'sell',
    };
    let position = { ..._position };
    if (newOrder.side == 'buy') {
      position.open_order_buy_qty = new BigNumber(`${position.open_order_buy_qty || 0}`).add(`${newOrder.quantity || 0}`).toString();
      position.open_order_buy_value = new BigNumber(`${position.open_order_buy_value || 0}`).add(`${newOrder.value || 0}`).toString();
    } else {
      position.open_order_sell_qty = new BigNumber(`${position.open_order_sell_qty || 0}`).add(`${newOrder.quantity || 0}`).toString();
      position.open_order_sell_value = new BigNumber(`${position.open_order_sell_value || 0}`).add(`${newOrder.value || 0}`).toString();
    }

    let leverage = position.leverage || new BigNumber('1').div(CurrentInstrument.getInitMarginRatio()).toString();

    let nextValue = this.getOrderValue(position);
    let fee = this.getOrderFee(`${nextValue || 0}`, position);
    let nextMargin = this.getMargin(`${nextValue || 0}`, leverage);
    nextMargin = new BigNumber(`${nextMargin || 0}`).add(`${fee || 0}`).toString();

    let oldMargin = this.getMargin(`${position.open_order_value || 0}`, leverage);
    fee = this.getOrderFee(`${position.open_order_value || 0}`, position);
    oldMargin = new BigNumber(`${oldMargin || 0}`).add(`${fee || 0}`).toString();

    let margin = new BigNumber(`${nextMargin || 0}`).minus(`${oldMargin || 0}`).toString();
    position.open_order_margin = nextMargin;
    position.open_order_value = nextValue;

    let availableAmount = 0;
    if (position.is_cross == 1) {
        availableAmount = new BigNumber(`${account.availableBalance || 0}`).minus(`${margin || 0}`).toString();
    }
    if (!this.isMarginOpenable(price, quantity, position, availableAmount)) {
        return null;
    }

    return margin;
  },

  getOrderValue(position) {
    let buyQuantity = position.open_order_buy_qty;
    let sellQuantity = position.open_order_sell_qty;
    let buyValue = position.open_order_buy_value;
    let sellValue = position.open_order_sell_value;
    let baseBuyValue = 0;
    let baseSellValue = 0;
    let totalValue = 0;
    let diffValue = 0;
    let oldQuantity = 0;

    let currentQty = new BigNumber(`${position.current_qty || 0}`).absoluteValue().toString();
    if (position.current_qty > 0) {
      oldQuantity = sellQuantity;
      sellQuantity = new BigNumber(`${sellQuantity || 0}`).sub(`${currentQty || 0}`).toString();
      if (new BigNumber(`${sellQuantity || 0}`).comparedTo(0) <= 0) {
        sellQuantity = 0;
      } else {
        sellValue = new BigNumber(`${sellQuantity || 0}`).div(`${oldQuantity || 0}`).mul(sellValue).toString();
      }
    } else {
      oldQuantity = buyQuantity;
      buyQuantity = new BigNumber(`${buyQuantity || 0}`).sub(`${currentQty || 0}`).toString();
      if (new BigNumber(`${buyQuantity || 0}`).comparedTo(0) <= 0) {
        buyQuantity = 0;
      } else {
        buyValue = new BigNumber(`${buyQuantity || 0}`).div(`${oldQuantity || 0}`).mul(buyValue).toString();
      }
    }

    if (new BigNumber(`${buyQuantity || 0}`).comparedTo(0) != 0) {
      baseBuyValue = new BigNumber(`${buyValue || 0}`).div(`${buyQuantity || 0}`).toString();
    }
    if (new BigNumber(`${sellQuantity || 0}`).comparedTo(0) != 0) {
      baseSellValue = new BigNumber(`${sellValue || 0}`).div(`${sellQuantity || 0}`).toString();
    }

    const minQuantity = BigNumber.min(buyQuantity, sellQuantity).toString();
    const maxQuantity = BigNumber.max(buyQuantity, sellQuantity).toString();
    const diffQuantity = new BigNumber(`${maxQuantity || 0}`).minus(`${minQuantity || 0}`).toString();

    const maxBase = BigNumber.max(baseBuyValue, baseSellValue).toString();
    totalValue = new BigNumber(maxBase).mul(`${minQuantity || 0}`).toString();

    if (new BigNumber(`${buyQuantity || 0}`).comparedTo(`${sellQuantity || 0}`) > 0) {
      diffValue = new BigNumber(`${baseBuyValue || 0}`).mul(`${diffQuantity || 0}`).toString();
    } else {
      diffValue = new BigNumber(`${baseSellValue || 0}`).mul(`${diffQuantity || 0}`).toString();
    }
    totalValue = new BigNumber(`${totalValue || 0}`).add(`${diffValue || 0}`).toString();
    return new BigNumber(`${totalValue || 0}`).toString();
  },

  getLiquidationPrice: function(position, quantity, availableBalance) {
    if(!position) return
    let amount = 0;
    let margin = 0;
    if (position.is_cross == 1) {
      amount = availableBalance;
      let maxLeverage = new BigNumber(1).div(`${position.required_init_margin_percent || 0}`).toString();
      margin = this.getMargin(position.entry_value, maxLeverage);
    } else {
      margin = this.getMargin(position.entry_value, position.leverage);
    }
    margin = new BigNumber(`${margin || 0}`).add(this.getPositionFee(position.entry_value, position)).toString();
    const maintMargin = this.getMaintMargin(position.entry_value, position);
    const lossable = new BigNumber(`${margin || 0}`).minus(`${maintMargin || 0}`).mul('-1').minus(`${amount || 0}`).toString();
    let exitValue = new BigNumber(`${position.entry_value || 0}`).add(`${lossable || 0}`).toString();
    if (position.entry_value *  exitValue < 0) {
      exitValue = 0;
    }
    if (new BigNumber(`${position.entry_value || 0}`).comparedTo(0) == 0) {
      return 0;
    }
    return this.getContractPrice(exitValue, quantity);
  },

  getContractQuantityByMargin: function(price, position, account, side) {
    let signQty = side == 'buy' ? 1 : -1;
    let low = 0;
    let high = CurrentInstrument.getMaxQuanity();

    let currentQty = new BigNumber(`${position.current_qty || 0}`).add(`${position.open_order_buy_qty || 0}`).sub(`${position.open_order_sell_qty || 0}`).toString();

    if (side == 'buy' && new BigNumber(`${currentQty || 0}`).comparedTo(0) < 0) {
      low = new BigNumber(`${currentQty || 0}`).absoluteValue().toString();
      low = parseInt(low);
    } else if (side == 'sell' && new BigNumber(`${currentQty || 0}`).comparedTo(0) > 0) {
      low = new BigNumber(`${currentQty || 0}`).toString();
      low = parseInt(low);
    }

    if (this.isOpenOrderMargin(price, high, position, account, signQty)) {
      return high;
    }

    while (low <= high) {
      if (low >= high - 1) {
        if(this.isOpenOrderMargin(price, high, position, account, signQty)) {
          return high;
        } else {
          return low;
        }
      }
      let mid = parseInt((low + high) / 2);
      
      if(this.isOpenOrderMargin(price, mid, position, account, signQty)) {
        low = mid;
      } else {
        high = mid;
      }
    }
    return low;
  },

  isOpenOrderMargin: function(price, quantity, position, account, signQty) {
    let margin = this.getOpenOrderMargin(price, quantity * signQty, position, account)
    if (!margin || (new BigNumber(`${margin || 0}`).comparedTo(account.availableBalance) > 0)) return false;
    return true;
  },

  _roe2pnlPercent: function(roe, entryValue, margin) {
    return new BigNumber(`${roe || 0}`).div(`${entryValue || 0}`).mul(`${margin || 0}`).toString();
  },

  _roe2pnl: function(pnlPercent, entryValue) {
    const absEntryValue = new BigNumber(`${entryValue || 0}`).absoluteValue().toString();
    return new BigNumber(`${pnlPercent || 0}`).mul(`${absEntryValue || 0}`).div('100').toString();
  },

  getPnl: function(exitValue, entryValue) {
    return new BigNumber(`${exitValue || 0}`).minus(`${entryValue || 0}`).toString();
  },

  getPnlPercent: function(pnl, entryValue) {
    const absEntryValue = new BigNumber(`${entryValue || 0}`).absoluteValue().toString();
    return new BigNumber(`${pnl || 0}`).div(`${absEntryValue || 0}`).mul('100').toString();
  },

  getRoe: function(pnlPercent, leverage) {
    return new BigNumber(`${pnlPercent || 0}`).mul(`${leverage || 0}`).toString();
  },

  getOpenSize: function(quantity, currentSize)
  {
      return this.getRealQuantity(quantity, currentSize);
  },

  getCloseSize: function(quantity, currentSize)
  {
      const sign = parseFloat(quantity) > 0 ? 1 : -1;
      const closedSize = new BigNumber(`${currentSize || 0}`).mul(`${quantity || 0}`).toString() >= 0
        ? 0
        : BigNumber.min(new BigNumber(`${currentSize || 0}`).absoluteValue().toString(), new BigNumber(`${quantity || 0}`).absoluteValue().toString());
      return new BigNumber(`${closedSize || 0}`).mul(`${sign || 0}`).toString();
  },

  getRealQuantity: function(quantity, position) {
    const sign = parseFloat(quantity) > 0 ? 1 : -1;
    let closedPosition = parseFloat(new BigNumber(`${position || 0}`).mul(`${quantity || 0}`).toString()) >= 0
      ? 0
      : BigNumber.min(new BigNumber(`${position || 0}`).absoluteValue().toString(), new BigNumber(`${quantity || 0}`).absoluteValue().toString());
    closedPosition = new BigNumber(`${closedPosition || 0}`).mul(sign).toString();
    return new BigNumber(`${quantity || 0}`).minus(`${closedPosition || 0}`).toString();
  },

  getTakerFee: function(value) {
    return new BigNumber(`${value || 0}`).absoluteValue().mul(`${CurrentInstrument.getTakerFee() || 0}`).toString();
  },

  getFundingRate: function(value) {
    return new BigNumber(`${value || 0}`).absoluteValue().mul(`${CurrentInstrument.getFundingRate() || 0}`).toString();
  },

  getOrderFee: function(value, position) {
    let leverage = position.leverage || new BigNumber('1').div(CurrentInstrument.getInitMarginRatio()).toString();
    return new BigNumber(1).div(`${leverage || 0}`).add(2).mul(`${this.getTakerFee(value) || 0}`).toString();
  },

  getPositionFee: function(value, position) {
    let leverage = position.leverage || new BigNumber('1').div(CurrentInstrument.getInitMarginRatio()).toString();
    return new BigNumber(1).div(`${leverage || 0}`).add(1).mul(`${this.getTakerFee(value) || 0}`).toString();
  },

  reupdatePosition: function(_position, balance) {
    let position = { ..._position };
    let leverage = position.leverage || new BigNumber('1').div(CurrentInstrument.getInitMarginRatio()).toString();
    if (new BigNumber(`${position.current_qty || 0}`).comparedTo(0) == 0) {
      position.entry_value = 0;
      position.init_margin = 0;
      position.maint_margin = 0;
      return position;
    }
    let newInitMargin = this.getMargin(`${position.entry_value || 0}`, leverage);
    let positionFee = this.getPositionFee(`${position.entry_value || 0}`, position);
    newInitMargin = new BigNumber(`${newInitMargin || 0}`).add(`${positionFee || 0}`).toString();

    let oldInitMargin = new BigNumber(`${position.init_margin || 0}`).minus(`${position.extra_margin || 0}`).toString();
    let reduceRatio = new BigNumber(oldInitMargin).comparedTo(0) == 0 ? 1 : new BigNumber(newInitMargin).div(oldInitMargin).toString();
    reduceRatio = BigNumber.min(reduceRatio, 1).toString()
    position.extra_margin = new BigNumber(`${position.extra_margin || 0}`).mul(reduceRatio).toString();
  
    position.init_margin = new BigNumber(newInitMargin).add(position.extra_margin).toString();
    position.maint_margin = this.getMaintMargin(`${position.entry_value || 0}`, position);
    return position;
  },

  tryToMatchPosition: function(price, quantity, _position, balance) {
    let position = { ..._position };
    let leverage = position.leverage || new BigNumber('1').div(CurrentInstrument.getInitMarginRatio()).toString();

    let realisedPnl = 0;
    let orderMargin = 0;
    let matchingFee = 0;
    let beforeMargin = position.init_margin;
    let originalOrderMargin = this.getMargin(`${position.open_order_value || 0}`, leverage);
    let originalOrderFee = this.getOrderFee(`${position.open_order_value || 0}`, position);
    originalOrderMargin = new BigNumber(`${originalOrderMargin || 0}`).add(`${originalOrderFee || 0}`).toString();

    const closeSize = this.getCloseSize(quantity, position.current_qty);
    const openSize = this.getOpenSize(quantity, position.current_qty);

    if (new BigNumber(`${closeSize || 0}`).comparedTo(0) != 0) {

      let closeQuantity = new BigNumber(`${closeSize || 0}`).mul('-1').toString();
      realisedPnl = this.getRealisedPnl(position.entry_value, price, closeQuantity, position.current_qty, position.symbol);

      let closeValue = new BigNumber(`${position.entry_value || 0}`).mul(`${closeSize || 0}`).div(`${position.current_qty || 0}`).toString();
      position.entry_value = new BigNumber(`${position.entry_value || 0}`).add(`${closeValue || 0}`).toString();
      position.current_qty = new BigNumber(`${position.current_qty || 0}`).add(`${closeSize || 0}`).toString();

      let closeTradeValue = this.getContractValue(price, closeSize);
      let closeTradeFee = this.getTakerFee(closeTradeValue);
      matchingFee = new BigNumber(matchingFee).add(closeTradeFee).toString();
    }

    position = this.reupdatePosition(position, balance);

    if (new BigNumber(`${openSize || 0}`).comparedTo(0) != 0) {
      let openValue = this.getContractValue(price, openSize);
      position.entry_value = new BigNumber(`${position.entry_value || 0}`).add(`${openValue || 0}`).toString();
      position.current_qty = new BigNumber(`${position.current_qty || 0}`).add(`${openSize || 0}`).toString();

      let openTradeValue = this.getContractValue(price, openSize);
      let openTradeFee = this.getTakerFee(openTradeValue);
      matchingFee = new BigNumber(matchingFee).add(openTradeFee).toString();
    }

    position = this.reupdatePosition(position, balance);
    let refundPositionMargin = new BigNumber(beforeMargin).minus(position.init_margin).toString();

    let openOrderValue = this.getOrderValue(position);
    let openOrderFee = this.getOrderFee(openOrderValue, position);
    orderMargin = this.getMargin(openOrderValue, leverage);
    orderMargin = new BigNumber(orderMargin).add(openOrderFee).toString();

    position.open_order_value = openOrderValue;
    position.open_order_margin = orderMargin;

    let recentBalance = new BigNumber(`${balance || 0}`).minus(`${matchingFee || 0}`).add(`${originalOrderMargin || 0}`).minus(`${orderMargin || 0}`).add(`${realisedPnl || 0}`).add(`${refundPositionMargin || 0}`).toString();
    if (new BigNumber(`${position.current_qty || 0}`).comparedTo(0) != 0) {
      position.liquidation_price = this.getLiquidationPrice(position, position.current_qty, recentBalance);
    } else {
      position.liquidation_price = 0;
    }

    // console.log({position, recentBalance, balance, matchingFee, originalOrderMargin, orderMargin, realisedPnl, refundPositionMargin})

    return position;
  },
}