<!-- WalletCard.vue -->
<template>
  <div class="wallet-card">
    <div class="wallet-header">
      <span :style="{ fontSize: '20px', fontWeight: '700' }">Wallet</span>
      <router-link to="/funds/balances-wallet" class="link">
        <span class="text_overview">{{ $t("funds.header") }}</span>
        <i class="icon-arrow3 custom"></i>
      </router-link>
    </div>
    <div class="wallet-content">
      <div class="blance_left">
        <div class="wrap_estmate">
          <span class="text_estimate">{{
            $t("account.estimated_balance")
          }}</span>
          <img
            :src="`/images/${typeIcon}.svg`"
            alt="icon"
            @click="$emit('onToggleShowEstimate')"
          />
        </div>
        <div v-if="isShowEstimate">
          <div class="number_balance">
            <span class="value"
              >{{ totalBtcBalance | formatNumberAndDecimal }}
              <span class="sub_value_normal">BTC</span></span
            >
            <span class="sub_value"
              >≈ ${{ totalUsdBalance | formatNumberAndDecimal }}</span
            >
          </div>
        </div>
        <div v-else>
          <div class="number_balance">
            <span class="value">******</span>
          </div>
        </div>
      </div>
      <div class="wallet-actions">
        <router-link to="/funds/deposits-wallet">
          <button class="btn btn-deposit">
            {{ $t("funds.balances.deposit") }}
          </button>
        </router-link>
        <router-link to="/funds/withdraw-wallet">
          <button class="btn btn-withdraw">
            {{ $t("funds.balances.withdrawal") }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletCard",
  props: {
    amount: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    conversion: {
      type: String,
      required: true,
    },
    typeIcon: {
      type: String,
      required: true,
    },
    isShowEstimate: {
      type: Boolean,
      required: true,
    },
    totalBtcBalance: {
      type: Number,
      required: true,
    },
    totalUsdBalance: {
      type: Number,
      required: true,
    },
  },
  emits: ["onToggleShowEstimate"],
};
</script>

<style scoped lang="scss">
.wallet-card {
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.wallet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary-color);
}

.wallet-header span {
  font-weight: bold;
  font-size: 18px;
}

.wallet-overview {
  color: #b0b0b0;
  font-size: 14px;
  text-decoration: none;
}

.wallet-overview:hover {
  text-decoration: underline;
}

.wallet-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  @media screen and (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.wallet-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.estimated-value {
  display: flex;
  align-items: center;
  color: #b0b0b0;
  font-size: 14px;
}

.estimated-value i {
  margin-left: 5px;
}

.wallet-amount {
  display: flex;
  flex-direction: column;
}

.amount {
  font-size: 36px;
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.amount .currency {
  font-size: 18px;
  color: #b0b0b0;
}

.conversion {
  color: #b0b0b0;
  font-size: 14px;
}

.wallet-actions {
  display: flex;
  gap: 10px;
  @media screen and (max-width: 770px) {
    width: 100%;
    justify-content: center;
  }
}

.btn {
  display: flex;
  width: 136px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border: none;
  border-radius: 1234px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.144px;
  @media screen and (max-width: 770px) {
    padding: 10px 16px;
    font-size: 14px;
  }
}

.btn-deposit {
  background-color: #03AAC7;
  color: white;
}

.btn-withdraw {
  background-color: var(--text-primary-color);
  color: var(--background-color-primary);
}
.blance_left {
  .wrap_estmate {
    display: flex;
    align-self: center;
    gap: 14px;
    .text_estimate {
      font-family: $font-notosans-regular;
      color: var(--text-desc);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .number_balance {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;
    .value {
      font-family: $font-notosans-bold;
      color: var(--text-primary-color);
      font-size: 36px;
      line-height: 40px;
      @media screen and (max-width: 770px) {
        font-size: 30px;
      }
    }
    .sub_value {
      font-family: $font-notosans-regular;
      color: var(--text-desc);
      font-size: 16px;
      line-height: 40px;
    }
    .sub_value_normal {
      font-family: $font-notosans-regular;
      color: var(--text-primary-color);
      font-size: 16px;
      line-height: 40px;
    }
  }
  .estimate_note_wrap {
    border-top: 1px dashed var(--text-search);
    max-width: 350px;
    .estimate_note {
      margin-top: 8px;
      background: var(--accent-color);
      border-radius: 8px;
      padding: 10px;
      color: $color-white;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.text_overview {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  color: #8e8e92 !important;
}
.custom {
  color: #8e8e92 !important;
}
</style>
