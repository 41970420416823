<template>
  <div id="left-menu">
    <div
      class="wrapper"
      v-for="(item, index) in listTab"
      :key="index"
      @click="select(item)"
    >
      <router-link :to="`${item.url}`">
        <div
          :class="['tab', tabIdActive == item.id && 'selected']"
          @click="select(item.id)"
        >
          <img :src="lightMode ? item.icon : item.iconDark" />
          <span>{{ $t(item.nameTab) }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { left_menu } from "@/pages/wallet/constants/mockData";
import { mapState } from "vuex";
export default {
  data() {
    return {
      listTab: left_menu,
    };
  },
  props: {
    tabIdActive: { type: Number, default: 1 },
    sizeScreen: { type: Number, default: 0 },
  },
  computed: {
    ...mapState({
      lightMode: (state) => state.userThemeMode === "light-theme",
    }),
  },
  methods: {
    select(item) {
      this.$emit("selected", item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  #left-menu {
    .selected {
      background-color: #2d2e2f !important;
    }

    .wrapper {
      &:hover {
        background-color: #2d2e2f !important;
      }
    }
  }
}
#left-menu {
  height: 100%;
  min-height: 700px;
  padding: 24px 16px 0px 24px;

  .wrapper {
    a {
      text-decoration: unset;
    }

    .selected {
      background-color: #f2f4f7;
    }

    &:hover {
      background-color: #f2f4f7;
    }

    img {
      width: 20px;
    }
    .tab {
      padding: 16px;
      display: flex;
      align-items: center;
      border-radius: 16px;

      cursor: pointer;
      gap: 8px;
      span {
        font-weight: 500;
        font-size: 14px;
        font-family: $font-notosans-medium;
        color: var(--text-primary-color);
      }
    }
    border-radius: 16px;
    margin: 4px 0px;
  }
}
.hideLeftMenu {
  display: none;
}

.dark-theme {
  #left-menu {
    background: #17181a;
  }
}
</style>
