import { render, staticRenderFns } from "./ContentWrap.vue?vue&type=template&id=23ac4dc3&scoped=true&"
import script from "./ContentWrap.vue?vue&type=script&lang=js&"
export * from "./ContentWrap.vue?vue&type=script&lang=js&"
import style0 from "./ContentWrap.vue?vue&type=style&index=0&id=23ac4dc3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ac4dc3",
  null
  
)

export default component.exports