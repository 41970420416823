<template>
  <div id="history" class="page_container">
    <Breadcrumb :items="breadcrumbItems" />

    <Header />
    <div class="history_container">
      <!-- <div class="title-medium-small">{{$t('funds.history.transaction_history')}}</div> -->

      <div class="toolbar">
        <div class="tab-container">
          <div
            class="tab-element"
            @click="changeTab('deposit')"
            v-bind:class="{ active: selectedTab === 'deposit' }"
          >
            {{ $t("funds.history.deposit") }}
          </div>
          <div
            class="tab-element"
            @click="changeTab('withdraw')"
            v-bind:class="{ active: selectedTab === 'withdraw' }"
          >
            {{ $t("funds.history.withdrawal") }}
          </div>
          <div
            class="tab-element"
            @click="changeTab('transfer')"
            v-bind:class="{ active: selectedTab === 'transfer' }"
          >
            {{ $t("funds.balances.transfer") }}
          </div>
        </div>
        <span class="export-file" v-if="selectedTab !== 'transfer'">
          <div @click="downloadReport()" class="excel_export">
            <!-- <img src="@/assets/images/wallet/exportIcon.svg" /> -->
            <span v-if="selectedTab === 'deposit'">{{
              $t("funds.history.export_complete_deposit_history")
            }}</span>
            <span v-else>{{
              $t("funds.history.export_complete_withdraw_history")
            }}</span>
          </div>
        </span>
      </div>
      <div v-if="selectedTab === 'transfer'">
        <TransferHistory />
      </div>
      <div
        class="history_table"
        v-if="selectedTab === 'deposit' || selectedTab === 'withdraw'"
        :class="{ isPagination: lastPage > 1 }"
      >
        <template v-if="sizeScreen > 768">
          <data-table3
            :getData="getData"
            :msg-empty-data="$t('funds.transaction_history.no_deposit_data')"
            :limit="10"
            :widthTable="'100%'"
            :key="key"
            ref="datatable"
            @isPagination="isPagination"
            @DataTable:finish="onDatatableFinish"
          >
            <template slot-scope="props">
              <th class="cl1" data-sort-field="status" @click="props.echoclick">
                {{ $t("funds.history.status") }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th
                class="cl2"
                data-sort-field="currency"
                @click="props.echoclick"
              >
                {{ $t("funds.history.coin") }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>

              <th class="cl3" data-sort-field="amount" @click="props.echoclick">
                {{ $t("funds.history.amount") }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th
                class="cl4"
                data-sort-field="created_at"
                @click="props.echoclick"
              >
                {{ $t("funds.history.date") }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>

              <th
                class="cl4"
                data-sort-field="currency"
                @click="props.echoclick"
              >
                <!-- {{ $t("funds.history.network") }}
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span> -->

                Action
              </th>

              <!-- <th class="cl5 t-right after_none"></th> -->
            </template>
            <template slot="body" slot-scope="props">
              <template>
                <tr
                  :class="{
                    active: props.index === isActiveRow,
                    white: (props.index + 1) % 2 !== 0 || props.index === 0,
                    white: (props.index + 1) % 2 === 0 && props.index !== 0,
                  }"
                >
                  <td class="cl1 t-left fs18 cl-transaction-status">
                    <div :class="`status-${props.item.status}`">
                      {{ props.item.status | transactionStatus }}
                    </div>
                  </td>
                  <td class="cl2 t-left fs18">
                    <img
                      class="icon-coin"
                      :src="`${coinImages[props.item.currency]}`"
                      alt=""
                      width="28"
                      height="28"
                    />
                    {{ props.item.currency | uppercase }}
                  </td>
                  <!-- <td class="cl3 t-center fs18">
                {{ props.item.network_name }}
              </td> -->
                  <td class="cl3 t-left fs18">
                    {{ props.item.amount | abs | formatNumberAndDecimal }}
                  </td>
                  <td class="cl4 t-left fs18">
                    {{ props.item.created_at | timestampToDate }}
                    <template v-if="sizeScreen < 768">
                      <span
                        @click="activeRow(props.index)"
                        class="glyphicon glyphicon-menu-down"
                        :class="
                          isActiveRow === props.index ? 'active-icon' : ''
                        "
                        aria-hidden="true"
                      ></span>
                    </template>
                  </td>
                  <td class="cl4" v-if="sizeScreen > 768">
                    <div class="fl-center">
                      <span
                        @click="activeRow(props.index)"
                        class="glyphicon glyphicon-menu-down"
                        :class="
                          isActiveRow === props.index ? 'active-icon' : ''
                        "
                        aria-hidden="true"
                      ></span>

                      <div
                        v-if="isActiveRow === props.index"
                        :class="{ even: props.index % 2 === 1 }"
                      >
                        <div class="info">
                          <template v-if="props.item.internal_transaction_id">
                            <span
                              v-if="configs.type === 'deposit'"
                              class="label-text row-transaction-info"
                              >{{ $t("funds.history.from") }}</span
                            >
                            <span
                              v-if="configs.type !== 'deposit'"
                              class="label-text row-transaction-info"
                              >{{ $t("funds.history.to") }}</span
                            >: {{ props.item.internal_transaction_id }}
                          </template>
                          <template v-if="!props.item.internal_transaction_id">
                            <p>
                              <span class="label-text row-transaction-info"
                                >{{ $t("funds.history.address") }}:
                              </span>
                              <span>{{ props.item.to_address }}</span>
                            </p>
                            <p v-if="props.item.currency === 'xrp'">
                              <span class="label-text row-transaction-info"
                                >{{ $t("funds.history.tag") }}:
                              </span>
                              <span>{{
                                props.item.blockchain_sub_address
                              }}</span>
                            </p>
                            <p
                              class="txid-link"
                              v-if="
                                props.item.status !== 'canceled' &&
                                props.item.status !== 'pending'
                              "
                            >
                              <span class="label-text row-transaction-info"
                                >{{ $t("funds.history.txid") }}:
                              </span>
                            </p>
                            <p
                              class="txid-link"
                              v-if="
                                props.item.status !== 'canceled' &&
                                props.item.status !== 'pending'
                              "
                            >
                              <span>{{ props.item.transaction_id }}</span>
                            </p>
                          </template>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </data-table3>
        </template>

        <template v-else>
          <ListHistoryMobile
            :currentTab="selectedTab"
            :getData="getData"
            :coinImages="coinImages"
            :isActiveRow="isActiveRow"
            :activeRow="activeRow"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionHistory from "@/components/shared_components/funds/TransactionHistory";
import Utils from "@/common/Utils";
import rf from "@/request/RequestFactory";
import Header from "@/pages/wallet/component/Header.vue";
import { mapState } from "vuex";
import TransferHistory from "@/components/spotexchange_pages/wallet/TransferHistory.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ListTransactionHistory from "@/components/spotexchange_pages/wallet/ListTransactionHistory.vue";
import ListHistoryMobile from "@/components/spotexchange_pages/wallet/ListHistoryMobile.vue";

export default {
  components: {
    TransactionHistory,
    TransferHistory,
    Header,
    Breadcrumb,
    ListTransactionHistory,
    ListHistoryMobile,
  },
  data() {
    let selectedTab = this.$route.query.type
      ? this.$route.query.type
      : "deposit";
    return {
      selectedTab: selectedTab,
      configs: {
        type: this.$route.query.type ? this.$route.query.type : "deposit",
      },
      isActiveRow: -1,
      noFunds: this.getNoFunsText(selectedTab),
      coinNames: {},
      coinImages: {},
      key: 1,
      sizeScreen: window.innerWidth,
      lastPage: 0,
      breadcrumbItems: [
        {
          label: "Wallet",
          link: "/funds/balances-wallet",
        },
        {
          label: this.$t("funds.history.transaction_history"),
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
    }),
  },
  watch: {
    "$route.query.type": {
      handler(value) {
        this.key += this.key;
        this.selectedTab = !value ? "deposit" : value;
      },
    },
  },
  methods: {
    isPagination(value) {
      this.lastPage = value;
    },
    onDatatableFinish() {
      // Only sort 'amount' field a again. Because amount field can be negative (withdraw) or positive (deposit).
      const datatable = this.$refs.datatable;
      if (datatable.params.sort !== "amount") {
        return;
      }
      const result = window._.chain(datatable.rows)
        .map((item) => {
          item.amount = Math.abs(parseFloat(item.amount));
          return item;
        })
        .orderBy([datatable.params.sort], [datatable.params.sort_type])
        .value();
      // console.log(result, datatable.params.sort_type);
      this.$refs.datatable.rows = result;
    },
    activeRow(rowIndex) {
      this.isActiveRow = this.isActiveRow === rowIndex ? -1 : rowIndex;
    },
    getNoFunsText(selectedTab) {
      return selectedTab == "deposit"
        ? window.i18n.t("funds.transaction_history.no_deposit_data")
        : window.i18n.t("funds.transaction_history.no_deposit_data");
    },
    changeTab(tab) {
      if (this.selectedTab === tab) return;
      if (tab !== "deposit") {
        this.$router.push({ query: { type: tab } });
      } else {
        this.$router.push({ query: {} });
      }
      this.selectedTab = tab;
      this.configs = {
        type: this.selectedTab,
      };
      this.noFunds = this.getNoFunsText(this.selectedTab);
      this.refresh();
    },
    refresh() {
      this.$refs?.datatable?.refresh();
    },
    getData(params) {
      this.isActiveRow = -1;
      let meta = {
        type: this.configs.type,
      };
      return rf
        .getRequest("TransactionRequest")
        .getHistory(Object.assign({}, params, meta));
    },
    getTransactionUrl(currency, transactionId) {
      return Utils.getTransactionUrl(currency, transactionId);
    },
    downloadReport() {
      const fileName =
        this.selectedTab === "deposit"
          ? "deposit_history.csv"
          : "withdrawal_history.csv";
      rf.getRequest("TransactionRequest").downloadTransactions(
        { timezone_offset: Utils.getTimzoneOffset(), type: this.selectedTab },
        fileName
      );
    },
    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(
        this.masterData.coins_confirmation,
        (item) => item.coin
      );
      this.coinsMasterData = window._.keyBy(
        this.masterData.coins,
        (item) => item.coin
      );
      this.coinsMasterData.usd = {
        name: window.i18n.t(`currency.usd.fullname`),
      };

      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
      });

      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    // document.title = this.$t('menu.history_title') + ` – ${this.tabTitle}`;
    document.title = this.$t("menu.history_title");
    this.handleMasterData();
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.icon-coin {
  object-fit: contain;
  border-radius: 28px;
}
.cl4 {
  width: 166px;
}
.fl-center {
  display: flex;
  align-items: center;
}
#history {
  padding: unset;
  .history_table {
    .tableContainer {
      table {
        border-top: 0px;
        thead {
          height: auto;
          tr {
            th {
              font-size: $font-root;
              font-weight: 500;
              line-height: 20px;
              padding: 15px 0px;
              text-align: left;
              color: $text-placeholder;

              &:nth-child(1) {
                padding-left: 8px;
                border-radius: 8px 0px 0px 8px;
                width: 15%;
                min-width: 150px;
              }
              &:nth-child(2) {
                width: 14%;
                min-width: 132px;
              }
              &:nth-child(3) {
                width: 15%;
                min-width: 150px;
              }
              &:nth-child(4) {
                width: 22%;
                min-width: 190px;
              }
              &:nth-child(5) {
                padding-right: 8px;
                width: 35%;
              }
              /* &:nth-child(6) {
                padding-right: 8px;
                width: 8%;
              } */
              &:last-child {
                border-radius: 0px 8px 8px 0px;
                @media screen and (max-width: 768px) {
                  min-width: 0px;
                }
              }
              @media screen and (max-width: 992px) {
                font-size: $font-root;
              }
            }
          }
        }
        tbody {
          tr {
            vertical-align: top;
            height: 40px;
            overflow-y: hidden;
            transition-property: height;
            transition-duration: 0.3s, 0.3s;
            transition-timing-function: ease, ease-in;

            &:nth-child(1) {
              width: 42.5%;
            }
            &:nth-child(2) {
              width: 16%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 21%;
            }

            &.even {
              background: transparent;
            }
            &:hover {
              background: transparent;
            }
            &.active {
              max-height: 300px;
              transition-property: height;
              transition-duration: 0.3s, 0.3s;
              background: transparent;
              transition-timing-function: ease, ease-in;
              .glyphicon-menu-down {
                transition-duration: 0.5s;
                transform: rotate(180deg);
              }
            }

            &.empty-data {
              background: transparent;
            }

            td {
              font-size: $font-root;
              padding: 20px 0px;
              text-align: left;
              color: var(--color-text);
              vertical-align: middle;
              border-bottom: 1px solid var(--color-border-line);
              &:nth-child(1) {
                padding-left: 8px;
              }
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                text-align: left;
              }
              &:nth-child(6) {
                text-align: left;
                position: absolute;
                z-index: 9999;
              }
              &.info {
                background: transparent;
                border-bottom: solid 1px var(--bgr-btn);
                padding-top: 0;
                color: $color-grey-dusty;
                font-size: 14px;
                .label-text {
                  color: var(--text-primary-color);
                }
                .to {
                  color: $color-grey;
                }
                @media only screen and (max-width: 768px) {
                  font-size: $font-root;
                }
              }
              .status-success {
                color: $text-color-jade;
              }
              .status-pending {
                color: $text-color-yellow;
              }
              .status-submitted {
                color: $color-dark-green;
              }
              .status-error,
              .status-cancel,
              .status-rejected {
                color: $text-color-red;
              }
            }
            .glyphicon-menu-down {
              color: $text-placeholder;
              font-size: 12px;
            }
            .glyphicon-menu-down.active-icon {
              color: $color-caribbean-green !important;
            }
            &.active {
              .glyphicon-menu-down {
                color: $color-grey-dusty;
              }
            }
            .cl-transaction-status {
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              letter-spacing: normal;
              color: $color-blue-custom !important;
              div {
                width: 69px;
              }
            }
            .row-transaction-info {
              min-width: 90px;
              display: inline-block;
              color: var(--color-text);
            }
          }
        }
      }
    }
  }
}
#history {
  .isPagination {
    margin-bottom: 60px;
  }
  .history_table {
    .VuePagination {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 35px;
      position: absolute;
      padding-top: 0;
      @media screen and (max-width: 992px) {
        bottom: 60px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

#history ::v-deep .history_table .VuePagination {
  position: unset;
}
::v-deep {
  .wrap-table {
    position: relative;
    min-height: 494px;
  }
  table tbody tr.empty-data .fixed-empty {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
}

.history_container {
  width: 100%;
  position: relative;
  margin-top: 40px;
}

.title-medium-small {
  margin-bottom: 20px;
}

.color_white {
  color: $color_white;
}

.fs13 {
  font-size: 13px;
}

.fs18 {
  font-size: 18px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.gray_color {
  color: $color-grey;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;

    .tab-container,
    .export-file {
      width: 100%;
    }
  }

  .tab-element {
    color: $color-gray-dark;
    font-size: $font-root;
    font-weight: 400;
    line-height: 20px;

    height: 30px;
    padding: unset !important;
    border: unset !important;
    background: unset !important;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin-right: 16px;
    &.active {
      color: $color-caribbean-green !important;
      background-color: unset !important;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 1px solid $color-caribbean-green;
        top: 40px;
        width: -webkit-fill-available;
        border-radius: 8px;
      }
    }
  }
}

.icon-export {
  color: $color_yellow;
}

.history_table {
  margin-top: 24px;
  width: 100%;
}

.active_sort {
  color: $color_yellow;
}

.t-left {
  text-align: left;
}
.t-center {
  text-align: center;
}

/* .white {
  background-color: $color_white;
  } */

.gray {
  background-color: $color-grey-white !important;
}

.info {
  text-align: left;
  overflow: initial;
  padding-left: 25px;
  font-size: $font-root;
  span {
    color: var(--text-primary-color);
    .label-text {
      color: $color-grey;
    }
    a {
      color: $color-grey-dark;
    }
  }
}
.txid-link {
  float: left;
  &:last-child {
    width: 300px;
    word-break: break-all;
  }
}

tr {
  th {
    text-align: center;
  }
}

.sort,
.glyphicon {
  cursor: pointer;
}

#history {
  .history_container {
    .export-file {
      position: relative;
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      border: 1px solid var(--color-text);
      background: var(--color-text);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;

      .excel_export {
        color: var(--color-export);
        font-size: $font-medium-bigger;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  #history .history_table .tableContainer table {
    thead {
      th {
        height: 52px;
      }
    }
    tbody tr {
      td:nth-child(4) {
        span {
          margin-left: 10px;
        }
      }
      td {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .toolbar .tab-element {
    font-size: 16px;
    line-height: 21px;
  }
}
</style>
