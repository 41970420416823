<template>
  <div class="container-header-home-slide-inside">
    <div class="container_slide_home">
      <div :style="{ display: 'flex', flexDirection: 'column' }">
        <div class="container-right">
          <div :style="{maxWidth: '500px'}">
            <p class="title">
              Smart choice on your
              <span
                class="title hight-light"
                v-if="sizeScreen < 600"
                :style="{ marginLeft: '1px' }"
                >future</span
              >
              <span class="title hight-light" v-if="sizeScreen > 600">future</span>
            </p>
          </div>
          <p class="description" v-if="sizeScreen > 600">
            Buy, sell and market digital assets at exchange Dikex
          </p>
        </div>

          <button class="button"  v-if="sizeScreen > 600 && !isAuthenticated">
            <router-link
                :to="{ name: 'Register', query: { destination: $route.name } }"
            >
            {{ $t("landing.signup") }}
            </router-link>
          </button>
      </div>
      <div class="container-left">
        <img class="cup" src="@/assets/images/drx/cup.png" alt="" />
        <img class="pos pos1" src="@/assets/images/drx/grey.png" alt="" />
        <img class="pos pos2" src="@/assets/images/drx/yellow.png" alt="" />
        <img class="pos pos3" src="@/assets/images/drx/purple.png" alt="" />
        <img class="pos pos4" src="@/assets/images/drx/green.png" alt="" />
        <div class="pos blur" />
      </div>
    </div>
    <div
      v-if="sizeScreen < 600 && !isAuthenticated"
      :style="{
        display: 'flex',
        flexDirection: 'column',
        padding: '0 15px',
      }"
    >
      <p class="description">
        Buy, sell and market digital assets at exchange Dikex
      </p>

        <button class="button">
          <router-link
            :to="{ name: 'Register', query: { destination: $route.name } }"
        >{{ $t("landing.signup") }}
        </router-link>
        </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSlide",
  props: ["isAuthenticated"],
  data() {
    return {
      title: "Welcome to My Slide",
      sizeScreen: window.innerWidth,
    };
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    console.log(this.isAuthenticated);
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/slide/slide";
@import "@/assets/sass/slide/TransitionSlide";

$bland_w_1200: 1200px;
$wain_btn_color: #c30b57;
$wain_bg: 1px solid rgba(255, 255, 255, 0.7);

.container-header-home-slide-inside {
  width: 100%;
  height: 100%;
  .container_slide_home {
    max-width: 1284px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @media screen and (max-width: 610px) {
      padding: 0 15px;
      justify-content: space-between;
    }

    .container-right {
      display: flex;
      flex-direction: column;
      color: var(--text-primary-color);
      .title {
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -1.2px;
        font-family: $font-notosans-bold;
        @media screen and (max-width: 610px) {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
        }
      }
      .hight-light {
        color: #03aac7;
      }
    }

    .container-left {
      position: relative;
      background-image: url("@/assets/images/drx/bg.png");
      @media screen and (max-width: 610px) {
        max-width: 200px;
      }
      @media screen and (max-width: 420px) {
        max-width: 162px;
      }
      .cup {
        width: 100%;
        height: 100%;
        max-width: 667px;
        max-height: 374px;
      }
      .pos {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .pos1 {
        top: 80px;
        left: 19px;
        max-width: 140px;
        max-height: 122px;
        @media screen and (max-width: 1000px) {
          top: 40px;
          left: 19px;
          max-width: 120px;
          max-height: 102px;
        }
        @media screen and (max-width: 610px) {
          max-width: 100px;
          max-height: 80px;
        }
        @media screen and (max-width: 600px) {
          max-width: 60px;
          max-height: 40px;
          top: 0px;
          left: 0px;
        }
      }
      .pos2 {
        top: 118px;
        right: 24px;
        max-width: 99px;
        max-height: 92px;
        @media screen and (max-width: 1000px) {
          top: 80px;
          right: 24px;
          max-width: 69px;
          max-height: 62px;
        }
        @media screen and (max-width: 610px) {
          max-width: 49px;
          max-height: 42px;
        }
        @media screen and (max-width: 600px) {
          max-width: 29px;
          max-height: 22px;
          top: 0px;
          right: 14px;
        }
      }
      .pos3 {
        bottom: 15px;
        left: 54px;
        max-width: 172px;
        max-height: 135px;
        @media screen and (max-width: 1000px) {
          bottom: 0px;
          left: 54px;
          max-width: 152px;
          max-height: 100px;
        }
        @media screen and (max-width: 610px) {
          left: 30px;
          max-width: 132px;
          max-height: 80px;
        }
        @media screen and (max-width: 600px) {
          max-width: 60px;
          max-height: 40px;
          bottom: 0px;
          left: 0px;
        }
      }
      .pos4 {
        bottom: 34px;
        right: 98px;
        max-width: 147px;
        max-height: 87px;
        @media screen and (max-width: 1000px) {
          bottom: 0px;
          right: 50px;
          max-width: 127px;
          max-height: 67px;
        }
        @media screen and (max-width: 610px) {
          right: 30px;
          max-width: 107px;
          max-height: 47px;
        }
        @media screen and (max-width: 600px) {
          max-width: 80px;
          max-height: 27px;
          bottom: 0px;
          right: 0px;
        }
      }
      .blur {
        border-radius: 537px;
        bottom: -50px;
        right: 0;
        left: 0;
        width: 100%;
        max-width: 537px;
        height: 136px;
        background-image: linear-gradient(98deg, #5a717c 6.1%, #05b4cc 103.66%);
        z-index: 10;
        filter: blur(100px);
        @media screen and (max-width: 600px) {
          max-width: 150px;
          bottom: 0px;
        }
      }
    }
  }
  .description {
    color: var(--text-primary-color);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 8px;
    @media screen and (max-width: 610px) {
      font-size: 12px;
      margin-top: 8px;
    }
  }
  .button {
    border-radius: 40px;
    border: 1px solid #03aac7;
    background: #03aac7;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 12px 20px;
    color: white;
    margin-top: 38px;
    display: flex;
    width: fit-content;
    justify-content: center;
    a{
      color: white;
    }
    @media screen and (max-width: 610px) {
      margin-top: unset;
      width: 100%;
    }
  }
}
</style>
