<template>
  <div id="open-order-screen" class="page_container container_history">
    <!-- <span class="export-file">
      <div class="excel_export">
        <span class="icon-export"/>&nbsp;
        <span>Export Complete Open Orders</span> 
      </div>
    </span> -->

    <div class="open-order-content clearfix">
      <sidebar class="sidebar" />
      <div class="main-open-order">
        <header-title
          :title="`${$t('menu.open_orders')}`"
          :label="`${$t('menu.wallet.spot')}`"
        />
        <!-- <div class="box-title position-relative justify-content-end">
          <div class="position-absolute title-open-order">
            <p class="title_spot">{{ $t("title.order") }}</p>
            <p>{{ $t("menu.open_orders") }}</p>
          </div>
          <img
            class="title-img"
            :src="
              require(`@/assets/images/bgr-order${
                sizeScreen < 992 ? '-mobile' : ''
              }.svg`)
            "
            alt=""
          />
        </div> -->
       
          <open-order
            class="order-group-screen"
            :options="{ isNoPadding: true }"
            :ref="commonConst.OPEN_ORDER"
            :tableOpenOrder="true"
          />
      </div>
    </div>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";
import OpenOrder from "./OpenOrder.vue";
import Sidebar from "../common/Sidebar.vue";
import HeaderTitle from "../common/HeaderTitle.vue";
import DataCard from "./DataCardOpenOrder.vue";
export default {
  components: {
    OpenOrder,
    Sidebar,
    HeaderTitle,
    DataCard,
  },
  data() {
    return {
      dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB,
      commonConst: COMMON_CONST,
      fetchingOpenOrder: false,
      sizeScreen: window.innerWidth,
    };
  },
  methods: {
    getSocketEventHandlers() {
      return {
        OrderBookUpdated: this.onReceiveOrderBook,
      };
    },
    onReceiveOrderBook(res) {
      if (this.fetchingOpenOrder) return false;

      let openOrderComponent = this.$refs[this.commonConst.OPEN_ORDER];
      let params = {
        limit: openOrderComponent.$refs.datatable.limit,
        page: openOrderComponent.$refs.datatable.page,
      };

      this.fetchingOpenOrder = true;
      openOrderComponent.$refs.datatable.getData(params).then((res) => {
        this.fetchingOpenOrder = false;
        return res;
      });
    },
    selectDate(item) {},
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.dark-theme {
  .container_history {
    background: $color-wood-smoke;

    .main-open-order {
      background-color: $color-grey-black !important;
      .box-title {
        background: $color-black-shark !important;
      }
    }
  }
}

.container_history {
  width: 100%;
  margin: 0px auto;
  &after {
    display: block;
    clear: both;
    content: "";
  }
}
@media screen and (max-width: 1300px) {
  .sidebar {
    display: none;
  }
}
#open-order-screen {
  padding: 4px 0 0;

  .box-title {
    .title-open-order {
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
      .title_spot {
        font-size: $font-big-24;
      }
      p {
        margin: 0;
      }
    }
    background: $pure_color_black;
    width: 100%;
    font-size: 34px;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-open-order {
      left: 40px;
      top: 53%;
      transform: translateY(-50%);

      .title_spot {
        font-size: $font-big-24;
        line-height: 29px;
        font-family: $font-inter-medium;
        font-weight: 500;
      }
      p {
        margin: 0;
        font-family: $font-inter-bold;
        font-weight: 600;
      }
    }
  }
  table {
    table {
      .tableContainer3 {
        thead {
          tr {
            th:first-child {
              text-align: left !important;
            }
          }
        }
      }
    }
  }

  .title-img {
    padding-right: 100px;
  }
  .export-file {
    display: inline-block;
    float: right;
    line-height: 30px;
    font-size: $font-small;
    font-weight: 500;
    position: relative;
    .excel_export {
      color: $color-blue-custom;
      font-size: $font-small;
      cursor: pointer;
      margin-top: 20px;
      .icon-export {
        float: right;
        line-height: 30px;
        margin-left: 6px;
      }
    }
  }
  .open-order-content {
    display: flex;
    min-height: calc(100vh - 65px - 252px);
  }
  .main-open-order {
    width: 100%;
    padding-top: 24px;
    background-color: var(--color-sidebar);
    @media screen and (max-width: 1550px) {
      max-width: calc(100vw - 300px);
    }
    @media screen and (max-width: 1300px) {
      max-width: 100%;
    }
  }

  .order-group-screen {
    padding: 40px 0 50px 40px;
    max-width: 93%;
    @media screen and (max-width: 1665px) {
      padding-right: 20px;
    }
  }
}
@media screen and (max-width: 1600px) {
  #open-order-screen .order-group-screen {
    padding: 40px 17px 80px 17px;
    position: relative;
    max-width: calc(100vw - 350px);
  }
}

@media screen and (max-width: 1300px) {
  #open-order-screen .order-group-screen {
    max-width: 1318px;
    padding: 40px 15px 0px 15px;
  }
}

@media only screen and (max-width: 992px) {
  #open-order-screen {
    .box-title {
      max-height: 80px;
      .title-open-order {
        left: 16px;
        font-size: 24px;
        line-height: 29px;
        .title_spot {
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }

  #open-order-screen .title-img {
    padding-right: 0;
  }

  #open-order-screen .box-title .title-open-order {
    left: 16px;
  }
  #open-order-screen .order-group-screen {
    padding: 40px 15px 0px 15px;
    position: relative;

    &::v-deep {
      .no-data {
        margin-bottom: 0px;
        min-height: 300px !important;
        overflow: auto !important;
      }
    }
  }
}
</style>
