var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-container",on:{"click":_vm.handleSliderClick}},[_c('div',{staticClass:"tooltip-container",style:(_vm.tooltipStyle)},[_vm._v(" "+_vm._s(_vm.currentValue)+"% ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],staticClass:"slider",style:({
      background: _vm.lightMode ?`linear-gradient(
        to right,
        #17181A 0%, 
        #17181A ${((_vm.currentValue - _vm.min) / (_vm.max - _vm.min)) * 100}%, 
        #dedede ${((_vm.currentValue - _vm.min) / (_vm.max - _vm.min)) * 100}%,
        #dedede 100%)` :`linear-gradient(
        to right,
        #dedede 0%, 
        #dedede ${((_vm.currentValue - _vm.min) / (_vm.max - _vm.min)) * 100}%, 
        #232323 ${((_vm.currentValue - _vm.min) / (_vm.max - _vm.min)) * 100}%,
        #232323 100%)`  ,
    }),attrs:{"type":"range","min":_vm.min,"max":_vm.max,"step":_vm.step},domProps:{"value":(_vm.currentValue)},on:{"input":_vm.updateValue,"mousemove":_vm.updateTooltipPosition,"mouseleave":_vm.hideTooltip,"mouseover":_vm.showTooltip,"__r":function($event){_vm.currentValue=$event.target.value}}}),_c('div',{staticClass:"slider-ticks"},_vm._l((_vm.intervals),function(interval){return _c('span',{key:interval,class:['tick', { 'tick-hidden': _vm.currentValue === interval }]})}),0),_c('div',{staticClass:"slider-labels"},_vm._l((_vm.intervals),function(interval){return _c('span',{key:interval,staticClass:"label"},[_vm._v(" "+_vm._s(interval)+"% ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }