<template>
  <div v-if="items.length > 0" class="infinite-scroll" @scroll="handleScroll">
    <TransferItem
        v-for="(item, index) in items"
        :key="index"
        :date="item.created_at | formatMomentDate "
        :coin-img="coinImages[item.coin]"
        :coin-name="item.coin | uppercase"
        :amount=" item.amount | formatNumberAndDecimal "
        :from="item.source | filterWallet(item.coin)"
        :to="item.destination | filterWallet(item.coin)"
    />
    <div v-if="loading" class="loading">Loading...</div>
  </div>
  <div v-else class="list-no-data">{{ $t('common.datatable.no_have_data') }}</div>
</template>

<script>
import rf from '@/request/RequestFactory';
import TransferItem from "@/components/history_wallet/TransferItem.vue";
import ActivityItem from "@/components/setting_pages/ActivityItem.vue";

export default {
  components: {
    ActivityItem,
    TransferItem
  },
  data() {
    return {
      items: [],
      loading: false,
      currentPage: 1,
      nextPageUrl: null,
    };
  },
  props:{
    coinImages: {
      type: []
    },
    currentTime: {
      type: {}
    },
    currentPair: {
      type: {}
    },
  },
  mounted() {
    this.loadMore();
  },
  watch: {
    currentTime() {
      this.resetAndReload();
    },
    currentPair() {
      this.resetAndReload();
    }
  },
  methods: {
    async loadMore() {
      if (this.loading) return;

      this.loading = true;
      try {
        const params = {
          page: this.currentPage,
        };
        let paramsFilter = {};
        console.log(this.currentPair, this.currentTime)
        if (!!this.currentTime) {
          paramsFilter.dateStart = this.currentTime;
        }
        if (!!this.currentPair) {
          paramsFilter.coin = this.currentPair
        }
        const response = await rf.getRequest('TransactionRequest').getTransferHistory(Object.assign({}, paramsFilter, params));
        if (response && response.data.data) {
          this.items = [...this.items, ...response.data.data];
          this.currentPage = response.data.current_page + 1;
          this.nextPageUrl = response.data.next_page_url;
        }

        if (!this.nextPageUrl) {
          this.noMoreData = true;
        }
      } catch (error) {
        console.error('Lỗi khi tải dữ liệu thiết bị người dùng:', error);
      } finally {
        this.loading = false;
      }
    },
    resetAndReload() {
      this.currentPage = 1;
      this.items = [];
      this.noMoreData = false;
      this.loadMore();
    },
    handleScroll(event) {
      const container = event.target;

      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
        if (!this.loading && !this.noMoreData) {
          this.loadMore();
        }
      }
    },
  },
};
</script>

<style scoped>
.infinite-scroll {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100vh;
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.infinite-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari và Edge */
}

.loading {
  padding: 10px;
  text-align: center;
}
</style>
