<template>
  <div class="transactions base_deposit_withdraw" id="id_base_deposit_wd">
    <div>
      <slot name="breadcumb" />
    </div>
    <div class="transactions-content container">
      <div class="title">
        <slot name="title" />
      </div>
      <div class="transactions-content main-content">
        <div class="left-content">
          <div class="name-select">{{ $t("address.select_coin") }}</div>
          <div class="group-switch">
            <coin-select-box
              v-model="selectedCoin"
              :placeholder="
                $t('m_funds.withdrawals.select_coin', {
                  action: $t(`funds.action.${configs.type}`),
                })
              "
              :options="coins"
            ></coin-select-box>
          </div>
          <div v-if="isActiveData">
            <div class="chain-name-select">
              {{ $t("fund.action.chain_name") }}
            </div>
            <div class="group-switch">
              <network-select-box
                v-model="selectedNetwork"
                :options="networks"
              ></network-select-box>
            </div>
          </div>

          <!-- <div v-show="chainName">
          <div class="chain-name-select" >{{$t('fund.action.chain_name')}}</div>
          <div class="chain-name-box d-flex align-items-center" >
              {{chainName}}
          </div>
          </div> -->
          <template v-if="isActive">
            <!-- <div class="chain-name">
                <p class="title-chainName"> {{ $t("funds.withdrawals.title_chain_name") }}</p>
                <input readonly value="Erc20"/>
            </div> -->
            <div class="list-balance">
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.withdrawals.total_balance")
                }}</span>
                <span class="number_coin_dps">
                  {{
                    balance.balance
                      | toNumber
                      | formatCurrencyAmount(selectedCoin.coin, "0")
                  }}
                  {{ selectedCoin.shortNameCurrency }}
                </span>
              </div>
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.balances.in_use")
                }}</span>
                <span class="number_coin_dps">
                  {{
                    balance.in_order
                      | toNumber
                      | formatCurrencyAmount(selectedCoin.coin, "0")
                  }}
                  {{ selectedCoin.shortNameCurrency }}
                </span>
              </div>
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.withdrawals.available_balance")
                }}</span>
                <span class="number_coin_dps">
                  {{
                    balance.available_balance
                      | toNumber
                      | formatCurrencyAmount(selectedCoin.coin, "0")
                  }}
                  {{ selectedCoin.shortNameCurrency }}
                </span>
              </div>
            </div>
          </template>
          <template v-if="isActive && isDepositOrWithdraw && !isDisableDeposit">
            <slot name="actions" />
          </template>
          <div
            class="alert alert-danger text-center"
            role="alert"
            v-show="isActive && (!isDepositOrWithdraw || isDisableDeposit)"
          >
            {{ $t("funds.disable_coin_msg") }}
          </div>
        </div>
        <div class="right-content">
          <transaction-history :configs="configs" ref="transaction_history" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import TransactionHistory from "@/components/shared_components/funds/TransactionHistory";
import CoinSelectBox from "@/components/shared_components/common/CoinSelectBox";
import NetworkSelectBox from "@/components/shared_components/common/NetworkSelectBox";
import { mapState } from "vuex";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";

export default {
  inject: ["$validator"],
  components: {
    TransactionHistory,
    CoinSelectBox,
    NetworkSelectBox,
  },
  data() {
    return {
      coins: [],
      networks: [],
      balance: {},
      selectedCoin: {},
      selectedNetwork: {},
      withdrawTransactions: [],
      minHeight: 430,

      isDepositOrWithdraw: false,
      isDisableDeposit: false, // Disable by User Withdrawal Setting
      userWidthdrawSettings: [],
      chainName: "",
      ethicon: ETHIcon,
    };
  },
  props: ["configs"],
  watch: {
    $route(to, from) {
      this.findSelectedCoin();
    },

    selectedCoin: function (newValue) {
      this.$emit("CHANGE_SELECTED_COIN", this.selectedCoin);
      this.$broadcast(
        "selectedCoinChange",
        newValue && newValue.shortNameCurrency
      );
      if (window._.isEmpty(newValue)) {
        return;
      }
      this.validateDepositWithdraw();
      this.getCurrencyBalance(newValue.coinName);
      this.selectedNetwork = {};
      this.getNetworkList();
      this.getUserWithdrawalSetting().then((res) => {
        // Check Enable by User Withdrawal Setting
        if (this.configs.type !== "withdraw") {
          return true;
        }
        let coinName = newValue.coinName;
        let record = window._.find(this.userWidthdrawSettings, (item) => {
          return item.coin == coinName;
        });
        if (!record || record.enable_withdrawal == "enable") {
          this.isDisableDeposit = false;
        } else {
          this.isDisableDeposit = true;
        }
      });

      this.getChainName();
    },

    selectedNetwork: function (newValue) {
      this.$emit("CHANGE_SELECTED_NETWORK", newValue);
    },
    isDepositOrWithdraw(newState) {
      if (!newState) {
        return;
      }
      this.$emit("CHANGE_SELECTED_COIN", this.selectedCoin);
    },
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    isActive() {
      // return !window._.isEmpty(this.selectedCoin);
      return (
        !window._.isEmpty(this.selectedCoin) &&
        !window._.isEmpty(this.selectedNetwork)
      );
    },
    isActiveData() {
      return !window._.isEmpty(this.selectedCoin);
    },
  },
  methods: {
    getChainName() {
      if (this.selectedCoin.coinName) {
        this.chainName = this.masterdata.coins.find(
          (item) => item.coin === this.selectedCoin.coinName
        )?.network;
      }
    },
    updateTransactionSetting(data) {
      const result = data.find(
        (word) => word.coin === this.selectedCoin.coinName
      );
      if (result) {
        if (result[this.getField()]) {
          this.isDepositOrWithdraw = true;
        } else {
          this.isDepositOrWithdraw = false;
        }
      }
    },
    onUserWithdrawalSettingEvent(data) {
      if (this.getField() == "is_deposit") {
        return true;
      }

      let res = JSON.parse(data);
      if (this.selectedCoin.coinName != res.coin) {
        return true;
      }
      if (res.isDelete) {
        this.isDisableDeposit = false;
      } else {
        if (res.enable_withdrawal == "disable") {
          this.isDisableDeposit = true;
        }
      }
    },

    getField() {
      if (this.configs.type === "withdraw") {
        return "is_withdraw";
      }

      return "is_deposit";
    },

    findSelectedCoin() {
      const coinName = this.$route.query.coin || undefined;
      if (coinName) {
        this.selectedCoin = _.find(this.coins, (coin) => {
          return coin.coinName === coinName;
        });
      }
    },

    getCurrencyBalance(currency) {
      this.balance = {};
      rf.getRequest("BalanceRequest")
        .getBalanceTransactionMain(currency)
        .then((res) => {
          this.balance = res.data;
          this.$emit("BALANCE_UPDATED", this.balance);
        });
    },

    getCoinList() {
      let coinCodes = window._.flatMap(
        this.masterdata.coins_confirmation,
        function (value) {
          return [value.coin];
        }
      );

      coinCodes = window._.uniq(coinCodes);

      let countCoins = coinCodes.length;

      const coinNames = {};
      const coinImages = {};

      _.forEach(this.masterdata.coins, (item, key) => {
        coinNames[item.coin] = item.name;
        coinImages[item.coin] = item.icon_image;
        coinImages["eth"] = this.ethicon;
      });
      for (let i = 0; i < countCoins; i++) {
        let coinCode = coinCodes[i];
        let coinInfo = window._.findLast(
          this.masterdata.coins,
          (item) => item.coin == coinCode
        );
        if (!coinInfo || coinCode == "usd") {
          let coin = window._.findLast(
            this.masterdata.coin_settings,
            (item) => {
              return item.coin == coinCode;
            }
          );
          if (!coin) {
            coin = {
              coin: coinCode,
              name: window.i18n.t(`currency.${coinCode}.fullname`),
            };
          }
          if (!coin.name) {
            coin.name = window.i18n.t(`currency.${coinCode}.fullname`);
          }
          this.coins.push({
            coinName: coin.coin,
            shortNameCurrency: coin?.currency?.toUpperCase(),
            fullNameCurrency: coin.name,
            iconCurrency: coinImages[coin.coin],
          });

          coinInfo = coin;
        } else {
          this.coins.push({
            coinName: coinCode,
            shortNameCurrency: coinCode?.toUpperCase(),
            fullNameCurrency: coinInfo.name,
            iconCurrency: coinImages[coinCode],
          });
        }
      }
      this.findSelectedCoin();
    },
    getWithdrawalsNetwork() {
      let params = {
        currency: this.selectedCoin.coinName,
      };
      rf.getRequest("UserRequest")
        .getWithdrawalsNetworks(params)
        .then((res) => {
          const dataConvert = res?.data.map((item) => {
            return {
              ...item,
              networkName: item.network_code,
              shortNameCurrency: item?.currency?.toUpperCase(),
              fullNameCurrency: item.name,
            };
          });
          this.networks = dataConvert;
        });
    },
    getDepositNetwork() {
      let params = {
        currency: this.selectedCoin.coinName,
      };
      rf.getRequest("UserRequest")
        .getDepositNetworks(params)
        .then((res) => {
          const dataConvert = res?.data.map((item) => {
            return {
              ...item,
              networkName: item.network_code,
              shortNameCurrency: item?.currency?.toUpperCase(),
              fullNameCurrency: item.name,
            };
          });
          this.networks = dataConvert;
        });
    },
    getNetworkList() {
      if (this.getField() == "is_withdraw") {
        this.getWithdrawalsNetwork();
      } else {
        this.getDepositNetwork();
      }
    },
    // onBalanceUpdated(newBalance) {
    //   if (!this.selectedCoin || !newBalance || this.selectedCoin.coinName !== newBalance.currency) {
    //     return;
    //   }

    //   this.balance = {...newBalance}
    //   this.$emit("BALANCE_UPDATED", this.balance);
    // },
    onTransactionCreated() {
      this.getCurrencyBalance(this.selectedCoin.coinName);
    },
    validateDepositWithdraw() {
      this.isDepositOrWithdraw = false;
      const setting = window._.filter(
        this.masterdata.coins_confirmation,
        (item) => item.coin === this.selectedCoin.coinName
      );
      if (!window._.isEmpty(setting)) {
        this.isDepositOrWithdraw =
          this.configs.type === "withdraw"
            ? setting[0].is_withdraw
            : setting[0].is_deposit;
      }
    },

    getSocketEventHandlers() {
      return {
        // WithdrawDepositBalanceEvent: this.onBalanceUpdated,
        TransactionSettingEvent: this.updateTransactionSetting,
        UserWithdrawalSettingEvent: this.onUserWithdrawalSettingEvent,
        TransactionCreated: this.onTransactionCreated,
      };
    },

    getUserWithdrawalSetting() {
      return rf
        .getRequest("UserRequest")
        .getUserWithdrawalSetting()
        .then((res) => {
          this.userWidthdrawSettings = res.data;
          return res;
        });
    },
  },

  mounted() {
    this.getCoinList();
    const height = document.body.clientHeight - 373;
    this.minHeight = height > 430 ? height : 430;

    if (this.getField() == "is_withdraw") {
      this.getUserWithdrawalSetting();
    }
    this.getChainName();
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#id_base_deposit_wd {
  .main-content {
    display: flex;
    column-gap: 40px;
    row-gap: 40px;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
    }

    .left-content {
      width: 27%;
      min-width: 350px;

      @media screen and (max-width: 1280px) {
        width: 100%;
      }
    }

    .right-content {
      flex: 1;
    }
  }
}

.link-green {
  color: $color-caribbean-green;

  &:hover {
    color: $color-caribbean-green;
  }
}

.dark-theme {
  #id_base_deposit_wd {
    background-color: #17181a !important;
  }
}
#id_base_deposit_wd {
  //max-width: 1340px;
  padding: 24px 40px;
  .multiselect__tags .multiselect__input {
    border: none !important;
  }
  .multiselect__input {
    height: 35px;
  }
  .multiselect__content-wrapper {
    overflow-x: hidden;
  }
  .button_drop_search {
    background: var(--color-bg-select);
    font-size: $font-big;
    font-family: $font-notosans-bold;
  }
  @media screen and (max-width: 992px) {
    //background: unset;
    padding: 16px 20px;
    .button_drop_search {
      background: var(--color-bg-select);
      font-size: $font-root;
      font-family: $font-notosans-regular;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.is-error {
  color: $color-green;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.txt-blue {
  color: $color-blue-cerulean-light;
}

.txt-bold {
  font-weight: bold;
}

.form-input {
  background: $background-catskill-white;
  border: 1px solid $color-alto;
}

.form-control {
  border-radius: 0;
}

.btn-submit {
  background: $color-blue-cerulean-light;
  color: $color-white;
  margin-top: 5px;
  margin-bottom: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  &:hover,
  &:active,
  &:focus {
    background: $color-corn;
    border-color: $color-corn;
    color: $color-white;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.transactions {
  min-height: 100%;
  height: auto;
  background: transparent;
  display: flow-root;
  .container {
    float: left;
  }
  .transactions-content {
    max-width: 100% !important;
    .title {
      color: var(--text-primary-color);
      font-size: 30px;
      font-family: $font-notosans-bold;
      line-height: 41px;
      margin-bottom: 40px;
      margin-top: 24px;
      padding: 0;
      font-weight: 600;
    }
    padding: 0;
    .transactions-content {
      justify-content: space-between;
      display: flex;

      .left-content {
        padding: 0;
        .name-select {
          color: var(--text-primary-color);
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 24px;
          font-family: $font-notosans-bold;
          font-weight: 700;
        }

        .chain-name-select {
          color: var(--text-primary-color);
          font-size: 18px;
          margin-top: 30px;
          margin-bottom: 8px;
          font-family: $font-notosans-regular;
        }
        .chain-name-box {
          background: var(--color-input);
          border-radius: 8px;
          height: 49px;
          padding: 14px 15px;
          color: var(--text-primary-color);
          font-size: 18px;
        }

        .chain-name {
          margin-top: 30px;
          .title-chainName {
            font-weight: 400;
            font-size: $font-big;
            color: var(--text-primary-color);
            margin-bottom: 8px;
          }
          input {
            height: 49px;
            width: 100%;
            padding-left: 15px;
            font-weight: 400;
            font-size: $font-big;
            border-radius: 8px;
            background: var(--color-bg-select);
            border: unset;
            color: var(--text-primary-color);
          }
        }
        .list-balance {
          margin-top: 26px;
          margin-bottom: 40px;
          .item-balance {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            .name-balance {
              color: var(--text-close);
              font-size: 16px;
              line-height: 22px;
            }
            .number_coin_dps {
              color: var(--dark-1);
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
        @media screen and (max-width: 768px) {
          max-width: unset;
        }
      }
      .right-content {
        padding: 0px;

        .name-history {
          color: var(--dark-1);
          font-size: 20px;
          line-height: 24px;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          margin-top: 30px;
          padding: 0 0 70px 0;
        }
      }
    }
    .row::before {
      content: unset;
    }
  }
}

@media screen and (max-width: 992px) {
  .transactions {
    .transactions-content {
      max-width: 100%;
      flex-direction: column;
      .title {
        font-size: 30px;
        line-height: 38px;
      }

      .transactions-content {
        .left-content {
          max-width: 100%;
          .name-select {
            font-size: 18px;
            line-height: 21px;
          }
          .list-balance {
            .item-balance {
              .name-balance {
                font-size: 14px;
                line-height: 16px;
              }
              .number_coin_dps {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
        .right-content {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
