<template>
  <div >
    <div class="container-header">
      <div class="title_back">
        <img :src="require(`@/assets/images/drx/arrow-left-dark.png`)" alt="icon" class="icon_back" @click="clickBack" :style="{width: '24px', height: '24px'}">
      </div>
      <h2>{{ $t('account.modal.cancel_2fa.title') }}</h2>
    </div>
    <div class="verify-input">
      <div class="row_form">
        <div class="input-group">
          <div class="name_input">{{ $t('account.otp.txt_pass') }}</div>
          <div class="input_password">
            <input
                :type="isShowPassWord ? 'text' : 'password'"
                @focus="resetErrors"
                data-vv-validate-on="none"
                v-validate="'required'"
                name="password"
                :placeholder="$t('common.placeholders.login_password')"
                class="input"
                v-model="cancelGoogleAuthForm.password"
                :class="{ error: errors.has('password') }"
                id="authentication-code"
            >
            <img class="icon_password" :src="require(`@/assets/images/drx/${typeIcon}`)" alt="icon" @click="toggleShowPassword" />
          </div>
          <div class="invalid-feedback" v-if="errors.has('password')">
            {{ errors.first('password') }}
          </div>
        </div>
      </div>
      <div class="row_form">
        <div class="input-group" >
          <div class="name_input">{{ $t('common.placeholders.otp_2') }}</div>
          <div @click="resetErrors">
            <input-only-number
                autocomplete="off"
                @focus="resetErrors"
                data-vv-validate-on="none"
                v-validate="'required|length:6'"
                :data-vv-as="$t('validation.attributes.otp_recovery_code')"
                :placeholder="$t('common.placeholders.authen_code')"
                v-model="cancelGoogleAuthForm.code"
                class="input"
                :class="{ error: errors.has('code') }"
                name="code"
                maxlength="6">
            </input-only-number>
          </div>
          <div class="invalid-feedback" v-if="errors.has('code')">
            {{ errors.first('code') }}
          </div>
        </div>

      </div>
      <div >
        <button class="btn" :disabled="isSubmitting" @click="delOtpRecoveryCode()">{{ getSubmitName($t('common.action.confirm')) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import rf from "@/request/RequestFactory";

export default {
  name: "DisableGoogleAuth",
  data() {
    return {
      cancelGoogleAuthForm: {
        code: '',
        password: '',
      },
      isShowPassWord: false,
      modalName:'CancelGoogleAuthModal'
    }
  },
  computed: {
    ...mapState({
      theme: state => state.userThemeMode,
    }),
    typeIcon() {
      if(this.theme === 'light-theme') {
        return this.isShowPassWord ? 'open-eyes-light.png' : 'close-eyes-light.png';
      } else {
        return this.isShowPassWord ? 'open-eyes-dark.png' : 'close-eyes-dark.png';
      }
    },
  },
  props: {
    status: {
      type: String,
      default: "disabled"
    }
  },
  methods: {
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    async delOtpRecoveryCode() {
      if (this.isSubmitting) {
        return;
      }
      await this.$validator.validateAll();
      if (this.errors.any()) {
        return;
      }
      try {
        this.startSubmit();
        const res = await rf.getRequest('UserRequest').delGoogleAuth(this.cancelGoogleAuthForm);
        this.endSubmit();
        this.$emit('CancelGoogleAuthModal:CLOSE');
        this.showSuccess(this.$t('account.modal.cancel_2fa.success'));
      } catch (error) {
        this.endSubmit();
        this.resetErrors();
        const errorOtp = error.response.data.errors;
        window._.each(errorOtp, (value, key) => {
          this.errors.add(key, this.$t(`${value[0].formatUnicorn({values: ".jpg, .jpeg, .png"})}`));
        })
        // const errorCode = error.response.data.errors?.code;
        // const errorPassword = error.response.data.errors?.password;
        // if(errorCode) {
        //   this.errors.add({
        //     field: 'code',
        //     msg: this.$t('validation.otp_incorrect')
        //   });
        // }
        // if(errorPassword) {
        //   this.errors.add({
        //     field: 'password',
        //     msg: this.$t('validation.custom.password.correct_password')
        //   });
        // }
      }

    },
    showSuccess(message) {
      Message.success(
          message,
          {},
          { position: 'bottom_left' }
      );
    },
    clearInput(){
      this.cancelGoogleAuthForm = Object.assign({
        code: '',
        password: '',
      });
    },
    toggleShowPassword() {
      this.isShowPassWord = !this.isShowPassWord
    },
    clickBack() {
      this.$router.back();
    },
  },
  created(){
    this.$on('hideCommonModal', (modalName) => {
      if (modalName !== this.modalName){
        return;
      }
      this.clearInput();
    });
  }
};
</script>

<style scoped lang="scss">
.container-header{
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  @media screen and (max-width: 500px) {
    gap: 16px;
  }
  h2 {
    color: var(--text-primary-color);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.39px;
    @media screen and (max-width: 500px) {
      font-size: 20px;
    }
  }
}
.verify-input {
  max-width: 454px;
  .row_form{
    margin-bottom: 24px;
  }
  .name_input{
    font-family: $font-inter-regular;
    color: var(--text-primary-color);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 8px;
  }
  .input_password {
    position: relative;
    .icon_password {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }
  .input {
    background: #1F2023;
    border-radius: 123px;
    min-height: 48px;
    padding: 12px;
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    font-family: $font-inter-regular;
    color: var(--text-primary-color);
    border: 1px solid var(--button-border-outline);
    background: var(--input-bg);
    &::placeholder {
      color: var(--text-close);
      font-size: 12px;
    }
    &.error {
      border: 1px solid $text-color-red;
    }
  }
  .invalid-feedback {
    color: $text-color-red;
    font-family: $font-inter-regular;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-top: 8px;
  }
}
.btn {
  background-color: var(--text-primary-color);
  color: var(--background-color-primary);
  padding: 10px 16px;
  border-radius: 1234px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 40px;
  margin-top: 48px;
  margin-bottom: 20px;
}
</style>
