import Numeral from '../lib/numeral';
import Vue from 'vue';
import Utils from './Utils';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import Const from '@/common/Const';

Vue.filter('formatUsdAmount', function (value, zeroValue) {
  if (window._.isNil(zeroValue)) {
    zeroValue = '';
  }
  return value ? Numeral(value).format("0,0") : zeroValue;
});

Vue.filter('formatPrice', function (value, zeroValue) {
  if (window._.isNil(zeroValue)) {
    zeroValue = '';
  }

  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : zeroValue;
});

Vue.filter('formatNaturalPart', function (value) {
  return Utils.formatCurrencyAmount(Math.floor(value), 'usd', value >= 0 ? '0' : '');
});

Vue.filter('formatDecimalForAdmin', function (value, afterDecimal = 2, maxLength = 10) {
  return Utils.cutDecimalForAdmin(value, parseFloat(afterDecimal), maxLength);
});

Vue.filter('toFixed', function (x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += (new Array(e + 1)).join('0');
    }
  }
  return x;
});
Vue.filter('formatNumberCutDecimal', function (value, maxLength = 10, afterDecimal = 2,) {
  return Utils.numberFormatCutDecimal(value, maxLength, afterDecimal);
});

Vue.filter('cutDecimals', function (value, afterDecimal = 2, maxLength = 10) {
  return Utils.cutDecimals(value, afterDecimal, maxLength);
});

Vue.filter('phoneNumber', function (value) {
  if (value) {
    return value.replace(/(\d{3})(\d{4})(\d{4})/, '$1.$2.$3');
  }
});

Vue.filter('uppercase', function (value) {
  if (!!value) return ('' + value).toUpperCase();
  return '';
});

Vue.filter('upperFirst', function (value) {
  return window._.upperFirst(value);
});


Vue.filter('titleCase', function (value) {
  return window._.startCase(window._.toLower(value));
});

Vue.filter('uppercaseFirst', function (value) {
  console.log("value",value)
  return window._.startCase(value);
});

Vue.filter('getPrecision', function (value) {
  return Numeral(value).format(".[00000000]");
});

Vue.filter('getPrecisionNoDot', function (value) {
  return Numeral(value).format(".[00000000]").replace('.', '');
});

Vue.filter('formatFloatNumber', function (numb) {
  // return Utils.formatDigitsNumber(numb, Utils.getPrecisionNumber(numb))
  const a = Utils.getPrecisionNumber(numb)
  return typeof numb == 'string' ? parseFloat(numb).toPrecision(numb.length - 1) : 0.00000001
});

Vue.filter('naturalPartOfNumber', function (number) {
  return Math.floor(number);
});

Vue.filter('naturalPartOfNumberWithDot', function (number) {
  let value = Math.floor(number);
  return Numeral(value).format("0,0") + '.';
});

Vue.filter('to2Precision', function (value) {
  return Numeral(value).format("0.00");
});
Vue.filter('changedPercent', function (value) {
  let pattern = '0.00';
  if (value > 0) {
    pattern = `+${pattern}`;
  }
  return Numeral(value).format(pattern) + "%";
});
Vue.filter('changedPercent2', function (value) {
  let pattern = '0.00';
  if (value > 0) {
    pattern = `+${pattern}`;
  }
  return value.toString().includes('.') ?
    Numeral(value).format(pattern) + "%"
    : value > 0 ? Numeral(value).format(`+0`) + "%" : Numeral(value).format('0') + "%";
});
Vue.filter('toPercent', function (value) {
  return value * 100 + "%";
});
Vue.filter('floatToPercent', function (value) {
  return Numeral(value * 100).format("0.00") + "%";
});
Vue.filter('changedPercentFee', function (value) {
  return Numeral(value * 100).format("0.[0000]") + ' %';
});
Vue.filter('percentWithOneDecimal', function (value) {
  return Numeral(value).format("0.0") + "%";
});
Vue.filter('percentWithMultiDecimal', function (value) {
  return Numeral(value).format("0.000000") + "%";
});
Vue.filter('currencyName', function (value) {
  return Utils.getCurrencyName(value);
});
Vue.filter('fullName', function (value) {
  return window.i18n.t('currency.' + value + '.fullname');
});

Vue.filter('upperCaseFirstLetter', function (value) {
  switch (value.toLowerCase()) {
    case 'usd':
    case 'ripple':
      return value.toUpperCase();
    default:
      return value.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  }
});

Vue.filter('formatCurrencyAmount', function (amount, currency, zeroValue) {
  return Utils.formatCurrencyAmount(amount, currency, zeroValue);
});
Vue.filter('formatCurrencyAmount2', function (amount, currency, zeroValue) {
  return Utils.formatCurrencyAmount2(amount, currency, zeroValue);
});

Vue.filter('formatFeeAmount', function (amount, currency, zeroValue) {
  if (typeof amount == 'string') {
    amount = Number.parseFloat(amount);
  }
  return Utils.formatCurrencyAmount8(amount, currency, zeroValue);
});

Vue.filter('formatTransactionFee', function (amount, currency, zeroValue) {
  return Utils.formatFee(amount, currency, zeroValue);
});

Vue.filter('formatCurrencyAmountAndShortText', function (amount, currency, zeroValue, maxlength) {
  const value = Utils.formatCurrencyAmount(amount, currency, zeroValue).toString();
  return value.length <= maxlength ? value : value.slice(0, maxlength).concat('...');
});

Vue.filter('shortTextNotRounding', function (amount, currency, zeroValue, maxlength) {
  const value = amount.toString();
  return value.length <= maxlength ? value : value.slice(0, maxlength).concat('...');
});

Vue.filter('convertToLocalTime', function (value) {
  return Utils.convertToLocalTime(value);
});

Vue.filter('formatMarginNumber', function (amount, numberOfDecimalDigits, zeroValue = null, isSetPositive = false) {
  return Utils.formatMarginNumber(amount, numberOfDecimalDigits, zeroValue, isSetPositive);
});

Vue.filter('formatMarginValue', function (amount) {
  return Utils.formatMarginValue(amount);
});

Vue.filter('formatDigitsNumber', function (amount, numberOfDecimalDigits, showZeroAtTheEnd) {
  return Utils.formatDigitsNumber(amount, numberOfDecimalDigits, showZeroAtTheEnd);
});

Vue.filter('formatMarginCost', function (amount, zeroValue) {
  return Utils.formatMarginCost(amount, zeroValue);
});

Vue.filter('formatMarginCapital', function (amount, zeroValue) {
  return Utils.formatMarginCapital(amount, zeroValue);
});

Vue.filter('formatMarginPrice', function (amount, tickSize, customDefault = '--') {
  if (tickSize) {
    let roundedValue = new BigNumber(tickSize).round(0, BigNumber.ROUND_HALF_DOWN);
    if (roundedValue.lt(tickSize)) {
      tickSize = new BigNumber(tickSize).minus(roundedValue).toString();
    }
  } else {
    tickSize = Const.MARGIN_DEFAULT_TICK_SIZE;
  }

  let value = '1';
  let precision = 0;
  while (new BigNumber(value).comparedTo(tickSize || 0) > 0) {
    value = new BigNumber(value).div(10).toString();
    precision++;
  }
  return Utils.formatMarginPrice(amount, precision, customDefault);
});

Vue.filter('formatOrderPrice', function (amount, currency, zeroValue) {
  if (amount) {
    return Utils.formatCurrencyAmount(amount, currency, zeroValue);
  } else {
    return window.i18n.t('order_list.market_price');
  }
});

Vue.filter('create_order_label', function (orderType, tradeType) {
  if (orderType && tradeType) {
    return window.i18n.t('create_order.button_texts.' + tradeType + '.' + orderType);
  } else {
    return '';
  }
});

Vue.filter('dateFormat', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY.MM.DD');
});

Vue.filter('timeFormat', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
});

Vue.filter('dateFormatSupport', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
})

Vue.filter('date', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM.DD');
});

Vue.filter('time', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
});

Vue.filter('orderTime', function (timestamp) {
  return moment(timestamp, 'x').format('HH:mm:ss');
});

Vue.filter('orderDateTime', function (timestamp) {
  return moment(timestamp, 'x').format('MM.DD HH:mm:ss');
});

Vue.filter('openOrderDateTime', function (timestamp) {
  return moment(timestamp, 'x').format('MM-DD HH:mm:ss');
});

Vue.filter('orderDate', function (timestamp) {
  return moment(timestamp, 'x').format('YYYY-MM-DD');
});

Vue.filter('timestampToDate', function (timestamp) {
  return moment(timestamp, 'x').format('YYYY/MM/DD HH:mm:ss');
});

Vue.filter('timestampToDayAndMonth', function (timestamp) {
  return moment(timestamp, 'x').format('MM-DD HH:mm:ss');
});

Vue.filter('localTime', function (timestamp) {
  var dateFormat = 'YYYY-MM-DD HH:mm:ss';
  var testDateUtc = moment.utc(timestamp);
  var localDate = testDateUtc.local();
  return localDate.format(dateFormat);
});

Vue.filter('YearMonthDate', function (timestamp) {
  return moment(timestamp, 'x').format('YYYY-MM-DD');
});

Vue.filter('localString', function (value) {
  return parseFloat(value).toLocaleString()
});

Vue.filter('fourNumberId', function (id) {
  if (id != null) {
    let tmpId = "00000000" + id;
    return tmpId.substring(tmpId.length, tmpId.length - 4)
  }
});

Vue.filter('user_type', function (type) {
  switch (type) {
    case 'normal':
      return '일반';
    case 'company':
      return '회사';
    case 'referrer':
      return '추천인';
  }
  return type;
});

Vue.filter('order_status', function (order) {
  if (order.custom_status == 'canceled') {
    return window.i18n.t('common.order_status.canceled');
  }
  else if (order.custom_status == 'filled') {
    return window.i18n.t('common.order_status.filled');
  }
  else return window.i18n.t('common.order_status.partial_filled');
});

Vue.filter('securityLevel', function (level) {
  switch (level) {
    case 1:
      return window.i18n.t('security_level.email');
    case 2:
      return window.i18n.t('security_level.sms');
    case 3:
      return window.i18n.t('security_level.id');
    case 4:
      return window.i18n.t('security_level.bank');
    case 5:
      return window.i18n.t('security_level.otp');
  }
});

Vue.filter('tradeType', function (trade_type) {
  switch (trade_type) {
    case 'buy':
      return window.i18n.t('common.trade_type.buy');
    case 'sell':
      return window.i18n.t('common.trade_type.sell');
  }
});

Vue.filter('number', function (number, round = 2) {
  if (!number || !isFinite(number)) {
    return 0;
  }
  return Number(parseFloat(number).toFixed(round));
});

Vue.filter('formatTimeStamp', function (timestamp, format) {
  return moment(timestamp, 'x').format(format);
});

Vue.filter('abs', function (number) {
  return Math.abs(number);
});

Vue.filter('transactionStatus', function (status) {
  if (status == 'cancel') return window.i18n.t('common.order_status.canceled')
  else
    return window.i18n.t('common.transaction_status.' + status);
});

// Vue.filter('transactionStatusCancelled', function (status) {
//   return window.i18n.t('.' + status);
// });
Vue.filter('convertToBigNumber', function (number) {
  if (!number) return "0";
  return (new BigNumber(number)).toString();
});

Vue.filter('mulBigNumber', function (number1, number2) {
  if (!number1 || !number2) return "0";
  return (new BigNumber(number1)).mul(number2).toString();
});

Vue.filter('divBigNumber', function (number1, number2) {
  if (!number1) return "0";
  if (!number2) return "1";
  return (new BigNumber(number1)).div(number2).toString();
});

Vue.filter('convertConditionStatus', function (condition) {
  if (condition == 'le') return '<=';
  if (condition == 'ge') return '>=';
  return '';
});

Vue.filter('paddingRight', function (value, length, character) {
  const number = parseFloat(value);
  if (isNaN(number) || length <= 0) {
    return value;
  }
  const strValue = `${value}`;
  const [strNaturalPart, strDecimalPart] = strValue.split('.');
  return `${strNaturalPart}.${window._.padEnd(strDecimalPart, length, character)}`;
});

Vue.filter('toNumber', function (value) {
  const number = parseFloat(value);
  if (isNaN(number)) {
    return value;
  }
  // is e number (Ex: 1e-7)
  if (number.toString().includes('e')) {
    return Utils.trimEndZero(new BigNumber(`${value || 0}`).toFixed(20));
  }
  return number;
});


Vue.filter('trimEndZero', function (value) {
  return Utils.trimEndZero(new BigNumber(`${value || 0}`).toFixed(20));
})

Vue.filter('shortText', function (value, maxlength) {
  if (typeof (value) !== 'string') return value;
  return value.length <= maxlength ? value : value.slice(0, maxlength - 3).concat('...');
});

Vue.filter('amountToPositiveNumber', function (value) {
  const number = parseFloat(value);
  return number >= 0 ? value : (new BigNumber(number)).mul(-1).toString();
});
Vue.filter('filterWallet', function (value, asset) {
  if (value === "main")
    return window.i18n.t('funds.balances.main_account');
  else if (value === "spot") return window.i18n.t('funds.balances.spot_balance')
  else {
    if (asset !== "usd" && asset !== "usdt")
      return window.i18n.t('funds.balances.future.coin_M')
    else return window.i18n.t('funds.balances.margin_balance')
  }
});
Vue.filter('formatMomentDate', function (value) {
  return moment(value).format('YYYY-MM-DD HH:mm:ss');
});

Vue.filter('isTooltip', function (value, maxLength) {
  if (value.toString().length > maxLength) return true;
  return false;
})
Vue.filter('formatCoinName', function (value) {
  return value.replace(/ Token| MANA/g, '');
})
Vue.filter('formatShortPrice', function (value, maxLength) {
  if (value == null || Number(value) == 0 || value == "Infinity") return '--';
  const formattedValue = value.replaceAll(',', '');
  const split = formattedValue.split('.');
  const beforeDecimal = split[0];
  const shorttedNumber = beforeDecimal.length > maxLength ? formattedValue.slice(0, maxLength - 1) : formattedValue.slice(0, maxLength);
  return formattedValue.length > maxLength ? shorttedNumber.replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...') : value.replace(/\d(?=(\d{3})+\.)/g, "$&,");
})

Vue.filter('formatCurrencyNoRound', function (value) {
  return value.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,")
})

Vue.filter('formatChangedPercent', function (value, maxLength) {
  if (value == null || Number(value) == 0 || value == "Infinity") return '0.00%';
  const numFormat = parseInt(value).toString();
  return numFormat.length > maxLength ? Number(numFormat.slice(0, maxLength)).toLocaleString().concat('...').concat('%') : Number(numFormat).toLocaleString().concat('%');
})

Vue.filter('formatNumberAfterDecimal', function (value, afterDecimal) {
  return Utils.formatNumberAfterDecimal(value, afterDecimal)
})

Vue.filter('formatNumberAndDecimal', function (value, afterDecimal) {
  return Utils.formatNumberAndDecimal(value, afterDecimal)
})

Vue.filter('timeAgo', function (inputTime) {
  const now = new Date();
  const targetTime = new Date(inputTime);
  const timeDifference = now - targetTime;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return seconds <= 1 ? "just now" : `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
  } else if (hours < 24) {
    return hours === 1 ? "an hour ago" : `${hours} hours ago`;
  } else {
    return days === 1 ? "a day ago" : `${days} days ago`;
  }
});