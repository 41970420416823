<template>

  <div id="top_mid_table" class="top_mid_table">
    <div class="content_orderbook">

      <div class="head_orderbook">
        <div class="group_sort_orderbook">
          <ul class="list_sort_od">
            <li>
              <div v-on:click="tab = tabConfig.sell" class="icon_sort_od" v-bind:class="{'active' : tab === tabConfig.sell}" >
                <i class="icon-sort3 icon_up_sort"></i>
              </div>
            </li>
            <li>
              <div v-on:click="tab = tabConfig.bothSellBuy" class="icon_sort_od" v-bind:class="{'active' : tab === tabConfig.bothSellBuy}" >
                <span class="icon-sort2 icon_up_sort">
                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                </span>
              </div>
            </li>
            <li>
              <div v-on:click="tab = tabConfig.buy" class="icon_sort_od" v-bind:class="{'active' : tab === tabConfig.buy}" >
                <i class="icon-sort1 icon_down_sort"></i>
              </div>
            </li>
          </ul>
          <div class="list_decimals_od">
            <select-box
              v-model="tickSize"
              :options="tickSizes"
              :isReturnObject="true"
              :hasCustomLabel="true"
            >
              <template slot="label_selected" slot-scope="{ selected }">
                <span>{{ $t('margin.grouping', {value: formatGrouping(tickSize)}) }}</span>
              </template>
              <template slot="options" slot-scope="{ option }">
                <span class="full_name_coin_select">{{ $t('margin.grouping', {value: formatGrouping(option)}) }}</span>
              </template>
            </select-box>
          </div>
        </div>
      </div>

      <div class="body_orderbook">
        <div class="top-title">
          <ul class="title-group">
            <li class="od-price">{{ $t('margin.price') }}</li>
            <li class="od-size">{{ $t('margin.size') }}</li>
            <li class="od-total">{{ $t('margin.total') }}</li>
          </ul>
        </div>
        <!-- Begin SellOrderbook -->
        <div class="order-red-container" v-bind:class="{'active_red_max' : tab === tabConfig.sell,' active_red_min' : tab === tabConfig.buy}">
          <table id="orderbook_red">
            <tbody class="orderbook_red">
              <tr :key="index" v-for="(item, index) in orderbookDisplay.sell"
                @click.stop="onSellOrderbookClicked(item, index)"
                :class="{'row-clicked': item.isClickedRow}">
                <div class="fix_sell_od" :style="{width: item.percent + '%'}"></div>
                <td class="sell_color" :title="item.price | orderbookPrice(pricePrecision)">
                  {{ item.price | orderbookPrice(pricePrecision) }}
                </td>
                <td v-bind:class="[{'padr10' : true} , {'isASC' : item && item.changeQty && item.changeQty > 0 && isAnimation}, {'isDESC' : item && item.changeQty && item.changeQty < 0 && isAnimation}]" :title="item.quantity | formatMarginNumber(0)">
                  {{ item.quantity | formatMarginNumber(0) }}
                </td>
                <td :title="item.totalQuantity | formatMarginNumber(0)">
                  {{ item.totalQuantity | formatMarginNumber(0) }}
                </td>
              </tr>
            </tbody>
          </table><!-- End SellOrderbook -->
        </div>

        <!-- Begin Current price -->
        <div class="block_center_od">
          <div class="border_block_center_od">
            <span class="od_number_fix" :title="$t('margin.last_price')" :class="{'grown-price': lastPriceChange > 0, 'down-price': lastPriceChange < 0}">
              <span>
                {{ lastPrice | formatMarginPrice(instrument.tick_size) }}
              </span>
              <i class="icon_zhang" size="12" :class="{'icon-arrow5': lastPriceChange > 0, 'icon-arrow6': lastPriceChange < 0}"></i>
            </span>
            <div class="od_number_balance">
              <span class="tooltip1" :title="$t('margin.index_price')">{{ indexPrice | formatMarginPrice(instrument.tick_size) }}
                <span class="tooltipText1 tooltip-index-price">{{ $t('margin.index_price_tooltip') }}</span>
              </span> /
              <span class="color_cccccc tooltip1" :title="$t('margin.mark_price')">{{ markPrice | formatMarginPrice(instrument.tick_size) }}
                <span class="tooltipText1 tooltip-mark-price">{{ $t('margin.mark_price_tooltip') }}</span>
              </span>
            </div>
          </div>
        </div><!-- End Current price -->

        <!-- Begin BuyOrderbook -->
        <table id="orderbook_blue" v-bind:class="{' active_blue_max' : tab === tabConfig.buy,' active_blue_min' : tab === tabConfig.sell}">
          <tbody v-bind:class="{' orderbook_blue_min' : tab === 1,' orderbook_blue_max' : tab === 3}" class="orderbook_blue">
            <tr :key="index" v-for="(item, index) in orderbookDisplay.buy"
              @click.stop="onBuyOrderbookClicked(item, index)"
              :class="{'row-clicked': item.isClickedRow}">
              <div class="fix_back_od" :style="{width: item.percent + '%'}"></div>
              <td class="buy_color" :title="item.price | orderbookPrice(pricePrecision)">
                {{ item.price | orderbookPrice(pricePrecision, item.totalQuantity > 0 ? '0' : '--') }}
              </td>
              <!-- <td class="padr10" :title="item.quantity | formatMarginNumber(2)"> -->
                <td v-bind:class="[{'padr10' : true} , {'isASC' : item && item.changeQty && item.changeQty > 0 && isAnimation}, {'isDESC' : item && item.changeQty && item.changeQty < 0 && isAnimation}]" :title="item.quantity | formatMarginNumber(0)">
                {{ item.quantity | formatMarginNumber(2) }}
              </td>
              <td :title="item.totalQuantity | formatMarginNumber(2)">
                {{ item.totalQuantity | formatMarginNumber(2) }}
              </td>
            </tr>
        </tbody>
      </table><!-- End BuyOrderbook -->
    </div>
  </div>
</div>

</template>
<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import Const from '@/common/Const';
  import BigNumber from 'bignumber.js';
  import SelectBox from '@/components/shared_components/common/SelectBox';
  import Utils from '@/common/Utils'
  import Numeral from '@/lib/numeral';

  const SELL = 'sell';
  const BUY = 'buy';
  const BLANK = '--';
  const TAB_SELL = 1;
  const BOTH_SELL_BUY = 2;
  const TAB_BUY = 3;

  export default {
    components: {
      SelectBox,
    },
    data() {
      return {
        tab: BOTH_SELL_BUY,
        tabConfig: {sell: TAB_SELL, bothSellBuy: BOTH_SELL_BUY, buy: TAB_BUY},

        buyRowCount: 14,
        sellRowCount: 7,
        oldChange : {},
        isChange: false,
        orderbooksDisplay : {},

        tickSize: undefined,

        // Beta Tester
        hideDataOrderBook: true,  // Default auto hide data
      }
    },
    watch: {
      symbol(newValue) {
        this.onSymbolChanged();
      },

      tab(newValue) {
        this.$nextTick(() => {
          this.reCalculateMinMaxRowDisp();
          this.scrollToEnd();
        });
      },

      tickSizes(newValue) {
        if (!this.tickSize && newValue.length > 0) {
          this.tickSize = newValue[0];
        }
      },
      deep: true,
    },
    computed: {
      ...mapGetters({
        symbol: 'currentSymbol',
        orderbook: 'orderbook',
        instrument: 'currentInstrument',
        indexBySymbol: 'indexBySymbol',
        animation : 'animation',
      }),
      ...mapState({
        masterdata: state => state.masterdata,
      }),

      orderbookDisplay() {
        return this.getOrderbookDisplay();
      },
      isAnimation() {
        return this.isChange && this.animation;
      },

      tickSizes() {
        if (this.instrument && this.instrument.symbol) {
          return [1, 2, 5, 10, 20, 50, 100, 200].map(i => {
            return new BigNumber(i).times(this.instrument.tick_size).toString();
          });
        } else {
          return [];
        }
      },

      lastPrice() {
        if (this.instrument) {
          return this.instrument.last_price;
        }
      },

      lastPriceChange() {
        if (this.instrument) {
          return this.instrument.last_change;
        }
        return 0;
      },

      indexPrice() {
        if (this.instrument) {
          const symbol = this.instrument.reference_index;
          const index = this.indexBySymbol(symbol);
          if (index) {
            return index.value;
          }
        }
      },

      markPrice() {
        if (this.instrument) {
          return this.instrument.mark_price;
        }
      },

      pricePrecision() {
        if (!this.tickSize) {
          return 10;
        }

        let tickSize = `${this.tickSize}`;
        let index = tickSize.indexOf('.');
        if (index < 0) {
            return 0;
        }
        let i = index + 1;
        for (let i = tickSize.length - 1; i > index; i--) {
          if (tickSize.charAt(i) !== '0') {
            return i - index;
          }
        }
        return 1;
      }
    },
    filters: {
      orderbookPrice(amount, precision) {
        let format = precision == 0 ?
          '0,0' :
          '0,0.' + Array(precision + 1).join('0');

        return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : '--';
      }
    },
    methods: {
      ...mapActions([
        'getOrderbook',
        'clearOrderbook',
      ]),
      getEventHandlers() {
        return {
          BetaTesterHideData: this.onBetaTesterHideData,
          BetaTesterShowData: this.onBetaTesterShowData,
        }
      },

      onBetaTesterHideData(data) {
        this.hideDataOrderBook = true;
      },

      onBetaTesterShowData(data) {
        this.hideDataOrderBook = false;
      },

      onSellOrderbookClicked(item, index) {
        if (!item || !item.price) {
          return;
        }
        this.clickedRow(item);

        this.$broadcast('MarginOrderbookClicked', {
          ...item,
          'side': 'sell',
        });
      },

      onBuyOrderbookClicked(item, index) {
        if (!item || !item.price) {
          return;
        }
        this.clickedRow(item);

        this.$broadcast('MarginOrderbookClicked', {
          ...item,
          'side': 'buy',
        });
      },

      getOrderbookDisplay() {
        let orderbook = this.orderbook;
        // console.log(this.orderbook.ch)
        this.isChange = ! (_.isEqual(this.orderbook.meta, this.oldChange));
        if (this.isChange) {
          this.oldChange = this.orderbook.meta
        }
        let buyOrderbook = orderbook.buy;
        let sellOrderbook = orderbook.sell;
        let changeBuy =  (orderbook && orderbook.change && orderbook.change.buy) ? orderbook.change.buy : [];
        let changeSell =  (orderbook && orderbook.change && orderbook.change.sell) ? orderbook.change.sell : [];
        
        [buyOrderbook, sellOrderbook] = this.groupOrderbook(buyOrderbook, sellOrderbook, this.tickSize, changeBuy, changeSell);
        if (this.hideDataOrderBook) {
          buyOrderbook = [];
          sellOrderbook = [];
        } else if (this.tab === BOTH_SELL_BUY) {
          buyOrderbook = buyOrderbook.slice(0, this.buyRowCount);
          sellOrderbook = sellOrderbook.slice(0, this.sellRowCount);
        }

        buyOrderbook = this.calculateTotalQuantity(buyOrderbook);
        sellOrderbook = this.calculateTotalQuantity(sellOrderbook);

        [buyOrderbook, sellOrderbook] = this.calculatePercent(buyOrderbook, sellOrderbook);

        this.fillRowBlankIfNeed(buyOrderbook, this.buyRowCount);
        this.fillRowBlankIfNeed(sellOrderbook, this.sellRowCount);

        const data = {
          buy: buyOrderbook,
          sell: sellOrderbook.reverse(),
        }

        // setTimeout(()=> {
        //   data.buy.forEach(element => {
        //       element.changeQty = 0;
        //   });
        //   data.sell.forEach(element => {
        //       element.changeQty = 0;
        //   });
        // }, 0)
        this.orderbooksDisplay = data;
        return data
      },

      groupOrderbook(buyOrderbook, sellOrderbook, tickSize, changeBuy, changeSell) {
        if (!tickSize) {
          return [[], []];
        }

        let groupedBuyOrderbook = this.groupSubOrderbook(buyOrderbook, tickSize, false, changeBuy);
        let groupedSellOrderbook = this.groupSubOrderbook(sellOrderbook, tickSize, true, changeSell);

        return [groupedBuyOrderbook, groupedSellOrderbook];
      },

      groupSubOrderbook(subOrderbook, grouping, isSellOrderbook, change) {
        let groupedOrderbook = [];
        for (let item of subOrderbook) {
          let price = new BigNumber(item.price).dividedToIntegerBy(grouping).times(grouping).toString();
          if (isSellOrderbook && !(new BigNumber(price).eq(item.price))) {
            price = new BigNumber(price).plus(grouping).toString();
          }

          let row = {
            quantity: item.quantity,
            price: price,
            changeQty: item.changeQty || 0,
            time : item.time || 0,
          };
          if (groupedOrderbook.length === 0) {
            groupedOrderbook.push(row);
          } else {
            let lastRow = groupedOrderbook[groupedOrderbook.length - 1];
            if (lastRow.price === row.price) {
              lastRow.quantity = (new BigNumber(lastRow.quantity)).plus(item.quantity).toString();
              lastRow.changeQty = new BigNumber(lastRow.changeQty).plus(row.changeQty).toString();
              lastRow.time = new Date().getTime();
            } else {
              groupedOrderbook.push(row);
            }
          }
        }

        if (change.length > 0) {
          for (let itemChange of change) {
            let priceChange = new BigNumber(itemChange.price).dividedToIntegerBy(grouping).times(grouping).toString();
            if (isSellOrderbook && !(new BigNumber(priceChange).eq(itemChange.price))) {
              priceChange = new BigNumber(priceChange).plus(grouping).toString();
            }
            groupedOrderbook.map(el => {
              if (el.price == priceChange) {
                el.changeQty = (new BigNumber(el.changeQty)).sub(itemChange.quantity).toString();
                el.time = new Date().getTime();
              }
              return el;
            })

          }

        }


        for (let item of groupedOrderbook) {
          if (new BigNumber(item.quantity).eq(item.changeQty)) {
            item.changeQty = 0;
          }
        }
        

        return groupedOrderbook;
      },

      calculateTotalQuantity(subOrderbook) {
        let totalQuantity = 0;
        return subOrderbook.map(item => {
          totalQuantity = new BigNumber(totalQuantity).plus(item.quantity).toString();
          item.totalQuantity = totalQuantity;
          return item;
        });
      },

      calculatePercent(buyOrderbook, sellOrderbook) {
        let maxTotalQuantity = this.getMaxTotalQuantity(buyOrderbook, sellOrderbook, this.tab);
        buyOrderbook = buyOrderbook.map(item => ({
          ...item,
          percent: item.totalQuantity * 100 / maxTotalQuantity,
        }))
        sellOrderbook = sellOrderbook.map(item => ({
          ...item,
          percent: item.totalQuantity * 100 / maxTotalQuantity,
        }))
        return [buyOrderbook, sellOrderbook];
      },

      clickedRow(row) {
        row.isClickedRow = true;
        setTimeout(() => {
          row.isClickedRow = false;
        }, 100);
      },

      onSymbolChanged() {
        this.tickSize = undefined;
        this.clearOrderbook();
        this.getOrderbook(this.symbol);
        window.GlobalSocket.listenForMarginOrderbook(this.symbol);
      },

      getMaxTotalQuantity(buyOrderbook, sellOrderbook, type) {
        let totalBuyQuantity = 0;
        let totalSellQuantity = 0;

        if (buyOrderbook.length > 0) {
          totalBuyQuantity = buyOrderbook[buyOrderbook.length - 1].totalQuantity;
        }
        if (sellOrderbook.length > 0) {
          totalSellQuantity = sellOrderbook[sellOrderbook.length - 1].totalQuantity;
        }
        if (type === TAB_BUY) {
          return totalBuyQuantity;
        } else if (type === TAB_SELL) {
          return totalSellQuantity;
        } else {
          return Math.max(totalBuyQuantity, totalSellQuantity);
        }
      },

      convertOrderbookDataType(orderbook) {
        return {
            buy: this.convertDataType(orderbook.buy),
            sell: this.convertDataType(orderbook.sell),
        }
      },

      convertDataType(data) {
        return data.map(item => ({
          price: parseFloat(item.price),
          quantity: parseFloat(item.quantity),
          count: parseFloat(item.count),
        }));
      },
      getPriceGroups() {
        this.priceGroups = window._.map(this.masterdata.price_groups, item => {
          item.precision = this.getDecimalPrecision(item.currency, item.value);
          return item;
        });
        this.getPriceGroupsByPairCurrency();

        this.coinSettings = window._.map(this.masterdata.coin_settings, item => {
          item.fractionPartQuantity = this.getDecimalPrecision(item.coin, item.quantity_precision);
          item.fractionPartAmount = 8;
          return item;
        });
        this.getCoinSettingsByPairCurrency();
      },

      fillRowBlankIfNeed(subOrderbook, size) {
        while(subOrderbook.length < size) {
          subOrderbook.push(this.createRowSubOrderbook());
        }
      },

      createRowSubOrderbook() {
        return {
          price: undefined,
          quantity: 0,
          percent: 0
        };
      },

      formatGrouping(value) {
        if (value) {
          return new BigNumber(value).toString();
        } else {
          return '';
        }
      },

      handleWindowResize() {
        this.reCalculateMinMaxRowDisp();
      },

      // changeColorQty() {
      //   showColor
      // },

      reCalculateMinMaxRowDisp() {
        setTimeout(() => {
          let heightRow = 26;
          const $elSell = this.$el.querySelector("#orderbook_red");
          const $elBuy = this.$el.querySelector("#orderbook_blue");

          switch(this.tab) {
            case TAB_SELL:
              this.buyRowCount = Math.floor($elSell.offsetHeight / heightRow);
              this.sellRowCount = Math.floor($elSell.offsetHeight / heightRow);
              break;
            case BOTH_SELL_BUY:
                this.sellRowCount = Math.floor($elBuy.offsetHeight / heightRow);
                this.buyRowCount = Math.floor($elBuy.offsetHeight / heightRow);
              break;
            case TAB_BUY:
              this.buyRowCount = Math.floor($elBuy.offsetHeight / heightRow);
              this.sellRowCount = Math.floor($elBuy.offsetHeight / heightRow);
              break;
            default:
              console.error('Tab selection incorrect');
              break;
          }
        }, 201); // with trasition: 0.2s
      },

      scrollToEnd() {
        let container = this.$el.querySelector(".orderbook_red");
        container.scrollTop = container.scrollHeight;
      },
      autoRemoveFlicker() {
        setInterval(() => {
          // console.log("aaaaaaaaaaaaa");
          let now = new Date().getTime();
          this.orderbooksDisplay.buy.forEach(el => {
            if (el.time && el.time +100 < now) {
              el.time = 0;
              el.changeQty = 0;
            }
          })
          this.orderbooksDisplay.sell.forEach(el => {
            if (el.time && el.time + 100 < now) {
              el.time = 0;
              el.changeQty = 0;
            }
          })

        }, 100);
      }
    },

    mounted() {
      window.addEventListener('resize', this.handleWindowResize)
      this.reCalculateMinMaxRowDisp();
      this.onSymbolChanged();
      this.autoRemoveFlicker();
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/variables";

  #top_mid_table {
    .list_decimals_od {
      .button_drop_search {
        min-width: 100px;
        max-width: 150px;
        width: auto;
        background: transparent;
        padding: 3px 3px 3px 8px !important;
        i.icon-arrow1::before{
          color: var(--text-close) !important;
        }
        &.active {
        //  i.icon-arrow1::before{
        //  color: $color-caribbean-green !important;
        //}
        }
        span{
          font-size: 14px;
          color: $color-gray-dark;
        }
      }
      .box_list_search_select {
        .list_search_select {
          background: var(--bgr-pagination);
          li {
            padding: 4.5px 14px;
            span {
              &:hover {
                color: $color-caribbean-green;
              }
            }
           
          }
        }
      }
      .full_name_coin_select{
        font-size: 14px;
        color: var(--text-primary-color);
      }
    }
  }
</style>

<style lang="scss" scoped>

@import "@/assets/sass/variables";

  $heightOptionsHeader           : 31px;
  $heightHeaderTitle             : 27px;
  $heightBlockCenter             : 70px;
  $transition                    : all 0.2s;
  @-webkit-keyframes highlightPseudo {
    // 0% {
    //     opacity: 0
    // }

    // 10% {
    //     opacity: 0.7
    // }

    // 100% {
    //     opacity: 0
    // }
}
#top_mid_table{
  display: block;
  width: 100%;
  ul{
    list-style-type: none;
    margin: 0px;
  }
  .content_orderbook{

    .head_orderbook{
      border-bottom: 1px solid;
      border-color: $color-bright-gray-dark;
      width: 100%;
      height: 40px;
      padding: 5px 10px 3px 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      .group_sort_orderbook{
        float: right;
        position: relative;
        display: inline-block;
        padding: 0px;
        width: 100%;
        .list_sort_od{
          display: flex;
          flex-wrap: wrap;
          float: right;
          list-style-type: none;
          li{
            float: left;
            .icon_sort_od{
              display: inline-block;
              float: left;
              width: 20px;
              height: 20px;
              margin-left: 7px;
              margin-top: 1px;
              cursor: pointer;
              text-align: center;
              line-height: 23px;
              border: 1px solid;
              border-color: transparent;
              .icon_up_sort {
                color: $color-red;
              }
            }
            .icon_sort_od.active {
              border-color: $color-eden-dark;
            }
          }
        }
        .select_decimals_od {
          background: #efefef;
          font-family: Arial;
          font-size: $font-small;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-grey-dusty;
          height: 22px;
          padding: 0px 5px;
          border-color: $color-grey-border;

        }
        .list_decimals_od {
          float: left;
          position: relative;
        }
      }
      .border-first-line {
        padding: 0;
      }
    }

    .body_orderbook {
      $heightFullOrderbook: 384px;
      width: 100%;
      background: transparent;
      .orderbook_red{
        visibility: visible;
        height: 210px;
        transition: 0.1s;
        overflow: hidden;
        tr{
          td{
            &:nth-child(2){
              color: $color-denim;
              
            }
            &:nth-child(3){
              padding-left: 2px;
              padding-right: 2px;
              &.isASC {
                background-color: #52b370;
                opacity: 0.6;
                // animation: ease-out highlightPseudo;
                // transition: 0.5s highlightPseudo;
                color: white;
                
              }
              // &.hightlight{
              //   opacity: 0.6;
              //   animation: ease-out highlightPseudo;
              //   // transition: 0.5s highlightPseudo;
              // }
              &.isDESC {
                background-color: rgba(232,112,79,0.8);
                opacity: 0.6;
                // animation: ease-out highlightPseudo;
                // transition: 0.5s highlightPseudo;
                color: white;
              }
            }
          }
        }
      }
      .orderbook_red.orderbook_red_min {
        visibility: hidden;
        transition: 0.1s;
        height: 0px;
        padding-top: 0px;
        margin-top: -1px;
      }
      .orderbook_red.orderbook_red_max {
        visibility: visible;
        transition: 0.1s;
        height: $heightFullOrderbook;
      }
      .orderbook_blue {
        visibility: visible;
        transition: 0.1s;
        height: 210px;
        overflow: hidden;
        tr{
          position: relative;
          td{
            position: relative;
            z-index: 10;
            &:nth-child(2){
              color: $color-jungle-green;
            }
            &.isASC {
                background-color: #52b370;
                opacity: 0.6;
                animation: 0.5s ease-out highlightPseudo;
                color: white;
              }
              &.isDESC {
                background-color: rgba(232,112,79,0.8);
                opacity: 0.6;
                animation: 0.5s ease-out highlightPseudo;
                color: white;
              }
          }
        }
      }
      .orderbook_blue.orderbook_blue_min {
        visibility: hidden;
        transition: 0.1s;
        height: 0px;
      }
      .orderbook_blue.orderbook_blue_max {
       visibility: visible;
       transition: 0.1s;
       height: $heightFullOrderbook;
       overflow-y: auto !important;
     }
     table {
      width: 100%;
      display: block;
      line-height: 20px;
      transition: all 0.3s;
      tbody {
        display: block;
        width: 100%;
        padding-top: 6px;
        tr {
          position: relative;
          padding: 0px 10px;
          overflow: hidden;
          display: block;
          width: 100%;
          cursor: pointer;
          td {
            position: relative;
            z-index: 1;
            width: 33.33%;
            height: 24px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            float: left;
            text-align: center;
            font-size: $font-smaller;
            color: $color-gray-dark;
            font-weight: 400;
            padding: 3px 0px;
            font-family: $font-inter-regular;
            letter-spacing: 0.2px;
          }
          td:nth-child(2) {
            text-align: left;
          }
          td:nth-child(4) {
            // width: 38% !important;
            text-align: right;
          }
          .fix_back_od {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            background-color: rgba($color-green, 0.2);
            transition: all 0.3s ease-in-out;
          }
          .fix_sell_od {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            background-color: rgba($color-denim, 0.2);
            transition: all 0.3s ease-in-out;
          }
        }
        .row-clicked {
          border: 1px solid #459cd4;
        }
      }
    }
    .block_center_od {
      background: $color-charade;
      display: block;
      overflow: hidden;
      position: relative;
      z-index: 3;
      .border_block_center_od {
        padding: 13px 0px 7px 0px;
        height: 70px;
        position: relative;
        display: block;
        overflow: hidden;
        .od_number_fix {
          color: $color-white;
          line-height: 24px;
          display: block;
          width: 100%;
          font-size: $font-medium;
          font-family: $font-family-roboto-medium;
          letter-spacing: 0.6px;
          float: left;
          text-align: center;
          &.grown-price {
            color: $color-jungle-green;
          }
          &.down-price {
            color: $color-denim;
          }
          .icon_zhang {
            height: 19px;
            line-height: 19px;
            margin-right: 2px;
            display: inline-block;
            width: 15px;
            font-size: $font-small;
            position: relative;
    		    margin-top: 0px;
    		    top: 0px;
            display: none;
          }
          .icon-arrow5 {
            color: $color-jungle-green;
            display: inline-block;
          }
          .icon-arrow6 {
            color: $color-denim;
            display: inline-block;
          }
          .difference {
            letter-spacing: 0.2px;
            color: $color-grey;
            font-size: $font-smaller;
            display: inline-block;
            margin-left: 3px;
          }
        }
            .od_number_balance {
              display: block;
              width: 100%;
              text-align: center;
              float: left;
              font-size: $font-root;
              line-height: 25px;
              color: $color-grey-dusty;
              font-family: $font-family-roboto-medium;
              .color_cccccc {
                color: $color-grey-sliver;
              }
            }
            .icon_analytics {
              display: inline-block;
              width: 20px;
              height: 19px;
              position: absolute;
              top: 50%;
              right: 5px;
              margin-top: -8px;
              color: $color-green;
              font-size: $font-medium;
              display: inline-block;
            }
          }
        }
        .border-first-line {
          div {
            padding: 0 !important;
            border: none !important;
            border-top: 1px solid $color-grey-border !important;
          }
        }
      }
    }
  }

  #top_mid_table{
    height: 100%;
    .content_orderbook{
      height: 100%;
      .body_orderbook{
        height: calc(100% - #{$heightOptionsHeader} - 10px);
        .top-title{
          height: #{$heightHeaderTitle};
          background: transparent;
          position: relative;
          z-index: 10;
          .title-group{
            padding: 0 10px;
            display: inline-block;
            width: 100%;
            // border-bottom: 1px solid $color-alto;
            li{
              width: 33.33%;
              display: block;
              float: left;
              text-align: center;
              font-size: $font-mini;
              color: $color-grey-dusty;
              font-weight: 500;
              padding: 4px 0px 3px 0px;
              line-height: 20px;
              height: 27px;
              &:nth-child(1) {
                text-align: left;
              }
              &:nth-child(3) {
                text-align: right;
              }
            }
          }
        }
        .order-red-container{
          transition: $transition;
          height: calc( 50% - 35px - 13.5px);
          position: relative;
          overflow: hidden;
          &.active_red_min{
            height: 0px;
          }
          &.active_red_max{
            transition: $transition;
            height: calc(100% - #{$heightHeaderTitle} - #{$heightBlockCenter});
            overflow: auto;
          }
        }
        #orderbook_red{
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
          width: 100%;
          min-height: 100%;
          transition: $transition;
          tbody{
            tr{
              border-top: 1px solid transparent;
              &:last-child{
              border-bottom: 1px solid transparent;
              }
            }
          }
          .orderbook_red{
            transition: $transition;
            height: 100%;
            overflow-y: auto;
            padding-top: 0px;
          }
        }
        #orderbook_blue{
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
          height: calc( 50% - 35px - 13.5px);
          transition: $transition;
          overflow: hidden;
          tbody{
            tr{
              border-top: 1px solid transparent;
              &:last-child{
              border-bottom: 1px solid transparent;
              }
            }
          }
          .orderbook_blue{
            transition: $transition;
            height: 100%;
            padding-top: 0;
          }
          &.active_blue_max{
            height: calc(100% - #{$heightHeaderTitle} - #{$heightBlockCenter});
            transition: $transition;
            overflow: auto;
          }
          &.active_blue_min{
            height: 0px;
            transition: $transition;
          }
        }
      }
    }
  }
  .tooltip1 {
    position: relative;
    display: inline-block;
  }
  /* Tooltip text */
  .tooltip1 .tooltipText1 {
    visibility: hidden;
    background-color: #222832;
    color: #cccccc;
    text-align: center;
    padding: 5px 5px;
    border-radius: 4px;
    font-size: 13px;
    font-family: $font-family-roboto;
    line-height: 17px;
  
    /* Position the tooltip text - see examples below! */
    box-shadow: 0px 0px 3px #cccccc;
    position: absolute;
    z-index: 3;
  }

  .tooltip-index-price {
    width: 250px;
    top: -35px;
    right: -130px;
  }

  .tooltip-mark-price {
    width: 250px;
    top: -30px;
    right: -70px;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip1:hover .tooltipText1 {
    visibility: visible;
  }
</style>
