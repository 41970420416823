<template>
  <masked-input
    type="text"
    :mask="createNumberMask"
    :guide="false"
    v-model="internalValue"
    v-on:input="updateValue"
    @focus="$emit('focus')"
    @blur="onBlur"
    :placeholder="placeholder"
    ref="input"
  >
  </masked-input>
</template>
<script>
import MaskedInput from "vue-text-mask";
import BigNumber from "bignumber.js";
import rf from "@/request/RequestFactory";
import Numeral from "@/lib/numeral";
import { mapGetters } from "vuex";

export default {
  components: {
    MaskedInput,
  },
  props: {
    value: "",
    focusHandler: {
      default: function () {},
    },
    placeholder: {
      type: String,
      default: "",
    },
    precision: {},
    maxLengthCus: {
      type: Number,
      default: 16,
    },
    changePrecision: {
      default: function () {
        return false;
      },
    },
    coin: {
      default: function () {
        return "";
      },
    },
    isMargin: { type: Boolean, default: false },
    allowNegative: { type: Boolean, default: false },
  },
  watch: {
    value: function (val) {
      this.updateValue(val);
    },
  },
  computed: {
    ...mapGetters(["contract"]),
  },
  data() {
    return {
      internalValue: "",
      internalPrecision: 0,
    };
  },
  methods: {
    createNumberMask: function (value) {
      var standardizedValue = this.standardize(value);
      var formatedValue = this.formatNumber(standardizedValue);
      var result = [];
      for (var i = 0; i < formatedValue.length; i++) {
        var char = formatedValue.charAt(i);
        if (char >= "0" && char <= "9") {
          result.push(/\d/);
        } else if (char == ".") {
          result.push(/\./);
        } else {
          result.push(char);
        }
      }
      return result;
    },

    updateValue: function (value) {
      if (value == undefined) {
        this.internalValue = undefined;
        return;
      }
      var stringValue = value ? this.removeExponent(value.toString()) : value;
      var standardizedValue = this.standardize(stringValue);
      var formattedValue = this.formatNumber(standardizedValue);
      this.internalValue = formattedValue;

      if (this.isMargin) {
        if (
          typeof formattedValue !== "undefined" &&
          formattedValue !== null &&
          typeof this.contract.tick_size !== "undefined" &&
          this.contract.tick_size !== null
        ) {
          let arrValue = formattedValue.toString().split(".");
          if (arrValue.length > 1) {
            let valueComp = Numeral("0." + arrValue[1]);
            let tickSize = Numeral(this.contract.tick_size);
            let arrInter = this.internalValue.toString().split(".");
            let arrTick = tickSize.value().toString().split(".");

            let valueOut;
            if (valueComp.value() >= tickSize.value()) {
              valueOut =
                Math.abs(Numeral(arrValue[0]).value()) + tickSize.value();
            } else {
              valueOut = Numeral(arrValue[0]).value();
              if (
                arrInter.length > 1 &&
                arrTick.length > 1 &&
                arrInter[1].length === arrTick[1].length
              ) {
                valueOut += ".";
                for (var i = 0; i < arrTick[1].length; i++) {
                  valueOut += "0";
                }
              }
            }

            if (typeof valueOut !== "undefined" && valueOut !== null) {
              if (
                this.allowNegative &&
                arrValue[0].indexOf("-") === 0 &&
                Numeral(valueOut).value() !== 0
              ) {
                valueOut = "-" + valueOut;
              }
              let standardizedValue = this.standardize(valueOut.toString());
              this.internalValue = this.formatNumber(standardizedValue);
              if (
                arrInter.length > 1 &&
                arrTick.length > 1 &&
                arrInter[1].length === arrTick[1].length
              ) {
                $(this.$el).val(this.internalValue);
              }
            }
          }
        }
      }

      var newValue = standardizedValue
        ? new BigNumber(standardizedValue)
        : undefined;
      if (newValue === undefined && newValue !== value) {
        this.$emit("input", newValue);
      } else if (
        (value && !value.isBigNumber) ||
        (newValue && !newValue.eq(value))
      ) {
        this.$emit("input", newValue.toString());
      }
    },

    removeExponent: function (data) {
      var data = String(data).split(/[eE]/);
      if (data.length == 1) return data[0];

      var z = "",
        sign = this < 0 ? "-" : "",
        str = data[0].replace(".", ""),
        mag = Number(data[1]) + 1;

      if (mag < 0) {
        z = sign + "0.";
        while (mag++) z += "0";
        return z + str.replace(/^\-/, "");
      }
      mag -= str.length;
      while (mag--) z += "0";

      return str + z;
    },

    onFocus: function () {
      this.$emit("focus");
    },

    onBlur: function () {
      this.$emit("blur");
    },

    standardize: function (value = "") {
      // if(!value) console.error('Value has no data'); //#preventSwallowEx
      var precision = this.internalPrecision;
      if (this.isMargin) {
        if (typeof this.precision !== "undefined") {
          precision = this.precision;
        }
      }
      if (typeof value !== "string") {
        value = value.toString();
      }
      var result = value.trim().replace(/[^0-9\.]/g, "");
      if (this.allowNegative && value.indexOf("-") === 0) {
        result = "-" + result;
      }
      var dotIndex = result.indexOf(".");
      if (dotIndex == 0) {
        result = "0" + result;
      } else if (dotIndex > 0) {
        result =
          result.substring(0, dotIndex + 1) +
          result.substring(dotIndex + 1).replace(/[\.]/g, "");
        if (precision > 0) {
          result = result.slice(0, dotIndex + precision + 1);
        } else {
          result = result.slice(0, dotIndex);
        }
      }
      result = this.removeLeadingZeros(result);
      return this.trimNumber(result);
    },

    trimNumber(number) {
      const dotIndex = number.indexOf(".");
      let maxLength = this.maxLengthCus;
      if (dotIndex > 0 && dotIndex < this.maxLengthCus) {
        maxLength += 1;
      }
      if (dotIndex == this.maxLengthCus) {
        maxLength -= 1;
      }
      if (number.length > maxLength) {
        number = number.substring(0, maxLength);
      }
      return number;
    },

    removeLeadingZeros: function (value) {
      var result = value;
      while (true) {
        if (result.length < 2) break;
        if (result.charAt(0) == "0" && result.charAt(1) != ".") {
          result = result.slice(1);
        } else {
          break;
        }
      }
      return result;
    },

    formatNumber: function (value) {
      var result = value + "";
      var x = result.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      result = x1 + x2;
      return result;
    },

    setPrecision(precision) {
      this.internalPrecision = precision;
    },

    focus() {
      this.$refs.input.$refs.input.focus();
    },
    setPrecisionCurrency(val) {
      this.internalPrecision = val > 8 ? 8 : val;
    },
  },
  mounted() {
    this.$refs.input.$refs.input.autocomplete = "new-password";
    if (this.changePrecision) {
      rf.getRequest("CurrencyCoinRequest")
        .getDecimalCoin(this.coin)
        .then((res) => {
          this.setPrecisionCurrency(res.data);
        });
    }
  },
  created() {
    this.internalPrecision = this.precision;
    if (this.value) {
      this.updateValue(this.value);
    }
  },
};
</script>
