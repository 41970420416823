<template>
  <!-- <div id="about" class="clearfix"> -->
  <div class="layout">
    <section class="about_top">
      <div class="left">
        <div class="title">About Dikex</div>
        <p class="description">The beginning of the finance, with Dikex</p>
      </div>
      <div class="right">
        <img
          src="@/assets/images/about/about-img.png"
          alt="about-dikex"
          class="about-img"
        />
      </div>
    </section>

    <section class="our_story">
      <div class="our_story_title">Our story</div>
      <div class="about_infor">
        {{ $t("about.information") }}
      </div>
      <div class="our_box">
        <div class="box_item">
          <p class="box_item_title">{{ $t("about.title_vision") }}</p>
          <p class="box_item_description">
            {{ $t("about.sub_title_vision") }}
          </p>
          <p class="box_item_description">
            {{ $t("about.information_vision") }}
          </p>
          <div class="box_item_end">
            <img
              :src="require(`@/assets/images/about/about-vision${dark}.png`)"
              class="item_img"
            />
          </div>
        </div>
        <div class="box_item">
          <p class="box_item_title">{{ $t("about.title_mission") }}</p>
          <p class="box_item_description">
            {{ $t("about.information_mission") }}
          </p>
          <div class="box_item_end">
            <img
              :src="require(`@/assets/images/about/about-mission${dark}.png`)"
              class="item_img img_bottom"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="about_reasons">
      <div class="about_reasons_title">{{ $t("about.title_reasons") }}</div>
      <div class="reasons_box">
        <div class="reason">
          <div class="reason_top">
            <div class="reason_img">
              <img
                :src="
                  require(`@/assets/images/about/about-security${dark}.png`)
                "
              />
            </div>
          </div>
          <div class="reason_bottom">
            <p class="reason_title">{{ $t("about.title_security") }}</p>
            <div class="reason_desc">{{ $t("about.des_security") }}</div>
          </div>
        </div>
        <div class="reason">
          <div class="reason_top">
            <div class="reason_img">
              <img
                :src="require(`@/assets/images/about/about-reserve${dark}.png`)"
              />
            </div>
          </div>
          <div class="reason_bottom">
            <p class="reason_title">{{ $t("about.title_ratio") }}</p>
            <div class="reason_desc">{{ $t("about.des_ratio") }}</div>
          </div>
        </div>
        <div class="reason">
          <div class="reason_top">
            <div class="reason_img">
              <img
                :src="require(`@/assets/images/about/about-smart${dark}.png`)"
              />
            </div>
          </div>
          <div class="reason_bottom">
            <p class="reason_title">{{ $t("about.title_smart") }}</p>
            <div class="reason_desc">{{ $t("about.des_smart") }}</div>
          </div>
        </div>
        <div class="reason">
          <div class="reason_top">
            <div class="reason_img">
              <img
                :src="
                  require(`@/assets/images/about/about-customer${dark}.png`)
                "
              />
            </div>
          </div>
          <div class="reason_bottom">
            <p class="reason_title">{{ $t("about.title_customer") }}</p>
            <div class="reason_desc">{{ $t("about.des_customer") }}</div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- <section class="top-about">
      <div class="content-des-about">
        <div class="title-top-about">
          <h3 class="title-about">{{ $t("about.title") }}</h3>
          <p class="sub-title">{{ $t("about.sub_title") }}</p>

          <div class="about-infor">
            {{ $t("about.information") }}
          </div>
        </div>

        <div v-if="sizeScreen < 992" class="bgr-mobile">
          <img
            width="375px"
            height="300px"
            src="@/assets/images/about/bgr-about-mobile2.png"
          />
        </div>
      </div>
    </section> -->
  <!-- <about-vision></about-vision> -->

  <!-- <section class="about-value">
      <div class="content-value">
        <h3 class="title-value">{{ $t("about.title_value") }}</h3>
        <div class="group-item">
          <div class="item-value">
            <img src="@/assets/images/about/value-trans.png" />
            <p>{{ $t("about.title_transparency") }}</p>
          </div>
          <div class="item-value">
            <img src="@/assets/images/about/value-stable.png" />
            <p>{{ $t("about.title_stable") }}</p>
          </div>
          <div class="item-value">
            <img src="@/assets/images/about/value-con.png" />
            <p>{{ $t("about.title_convenient") }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="about-reason">
      <div class="content-value">
        <h3 class="title-value">{{ $t("about.title_reasons") }}</h3>
        <div class="group-reason row">
          <div class="item-reason col-6 col-xs-6 col-md-6 col-xl-3 col-sm-6">
            <img
              :src="require(`@/assets/images/about/img-security${dark}.png`)"
            />
            <p>{{ $t("about.title_security") }}</p>
            <div class="des-reason">{{ $t("about.des_security") }}</div>
          </div>
          <div class="item-reason col-6 col-xs-3 col-md-6 col-xl-3 col-sm-6">
            <img :src="require(`@/assets/images/about/img-ratio${dark}.png`)" />
            <p>{{ $t("about.title_ratio") }}</p>
            <div class="des-reason">{{ $t("about.des_ratio") }}</div>
          </div>
          <div class="item-reason col-6 col-xs-6 col-md-6 col-xl-3 col-sm-6">
            <img :src="require(`@/assets/images/about/img-smart${dark}.png`)" />
            <p>{{ $t("about.title_smart") }}</p>
            <div class="des-reason">{{ $t("about.des_smart") }}</div>
          </div>
          <div class="item-reason col-6 col-xs-6 col-md-6 col-xl-3 col-sm-6">
            <img
              :src="require(`@/assets/images/about/img-customer${dark}.png`)"
            />
            <p>{{ $t("about.title_customer") }}</p>
            <div class="des-reason">{{ $t("about.des_customer") }}</div>
          </div>
        </div>
      </div>
    </section> -->
  <!-- </div> -->
</template>

<script>
import AboutVision from "@/components/about_pages/AboutVision.vue";
import { mapState } from "vuex";

export default {
  components: {
    AboutVision,
  },
  data() {
    return {
      sizeScreen: window.innerWidth,
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.scrollToTop);
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);

    window.addEventListener("beforeunload", this.scrollToTop);
  },
  created() {
    document.title = this.$t("menu.about") + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.layout {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);

  .about_top {
    display: flex;
    align-items: center;
    .left {
      color: var(--text-about-title);
      .title {
        font-family: $font-notosans-bold;
        font-size: $font-title-size-big-small-2;
        font-style: normal;
        font-weight: 700;
        line-height: 68px;
        letter-spacing: -1.08px;
      }
      .description {
        font-family: $font-notosans-medium;
        font-size: $font-big-20;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 140% */
        letter-spacing: -0.2px;
      }
    }

    .right {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .about-img {
        width: 466.825px;
        height: 455.057px;
        flex-shrink: 0;
      }
      @media screen and (max-width: 768px) {
        .about-img {
          width: 256.643px;
          height: 250.173px;
          flex-shrink: 0;
        }
      }
    }
  }
  .our_story {
    display: flex;
    flex-direction: column;
    gap: 56px;
    padding: 64px 0;
    font-family: $font-notosans-medium;

    .our_story_title {
      color: var(--text-about-title);
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.768px;
    }
    .about_infor {
      white-space: pre-line;
      color: var(--text-our-story);
      font-size: $font-medium-bigger;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.112px;
    }
    .our_box {
      display: flex;
      align-items: center;
      gap: 40px;
      .box_item {
        display: flex;
        padding: 32px;
        flex-direction: column;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 24px;
        background: var(--bg-our-item);
        position: relative;

        color: var(--text-our-story);

        .box_item_title {
          font-size: $font-big-24;
          font-style: normal;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
          letter-spacing: -0.288px;
        }
        .box_item_description {
          font-size: $font-medium-bigger;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 137.5% */
          letter-spacing: -0.112px;
        }
        .box_item_end {
          display: flex;
          justify-content: flex-end;
          margin-top: auto;
          position: relative;
          height: 170px;
          .item_img {
            width: 120px;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
  }
  .about_reasons {
    display: flex;
    flex-direction: column;
    gap: 56px;
    padding: 40px 0;
    .about_reasons_title {
      color: var(--text-about-title);
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.768px;
    }

    .reasons_box {
      display: flex;
      align-items: center;
      gap: 24px;
      align-self: stretch;
    }
    .reason {
      display: flex;
      max-width: 282px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
      .reason_top {
        display: flex;
        justify-content: center;
        .reason_img {
          display: flex;
          width: 200px;
          height: 200px;
          padding: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .reason_bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        color: var(--text-reason-title);
        text-align: center;
        .reason_title {
          font-size: $font-big-24;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: -0.288px;
          color: var(--text-reason-title);
          max-width: 229px;
          min-height: 58px;
        }
        .reason_desc {
          font-size: $font-medium-bigger;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.112px;
          color: var(--text-reason-desc);
        }
      }
    }
  }
  .our_story_title,
  .about_reasons_title {
    font-family: $font-notosans-bold;
  }

  @media screen and (max-width: 992px) {
    .about_top {
      flex-direction: column-reverse;
      align-items: flex-start;
      .left .title {
        font-size: 36px;
      }
      .left .description {
        font-size: $font-medium-bigger;
      }
    }
    .our_story {
      gap: 16px;
      .our_box {
        flex-direction: column;
        gap: 18px;
      }
    }
    .about_reasons {
      .reasons_box {
        flex-direction: column;
      }
    }
    .our_story_title,
    .about_reasons_title {
      font-family: $font-notosans-bold;
      font-size: $font-title-size-medium-small;
      text-align: center;
    }
  }
}
</style>
