<template>
  <div id="wallet-future">
    <div class="content-right-wrapper">
      <div class="estimate-balance">
        <div class="estimate">
          <div class="estimate-btc">
            <p class="title-balance">
              {{ $t("title.total_margin_balance") }}
              <span class="unHideCoin firstCoin">{{
                $t("balances.future.total_balance")
              }}</span>
            </p>
            <span>
              <img
                v-if="userThemeMode == 'light-theme'"
                :src="`/images/eye-${!tongglePrice ? 'open' : 'close'}.svg`"
                alt="eye"
                width="22"
                height="12"
                @click="clickHide"
                style="margin-left: 14px"
              />
              <img
                v-else
                :src="`/images/eyes-${
                  !tongglePrice ? 'open-dark' : 'close-dark'
                }.svg`"
                alt="eye"
                width="22"
                height="12"
                @click="clickHide"
                style="margin-left: 14px"
              />
            </span>
          </div>
          <div v-if="!tongglePrice" class="price-coin">
            <p>{{ priceCoin | formatNumberAndDecimal }} BTC</p>
            <span
              >≈
              <fiat-currency
                :value="valued | formatNumberAndDecimal"
              ></fiat-currency
            ></span>
          </div>
          <div v-else class="price-coin-hide">***************</div>
        </div>
        <div class="recent-box total-balance">
          <p class="recent-title title-balance">
            {{ $t("title.total_wallet_balance") }}
            <span class="unHideCoin">{{
              $t("balances.future.wallet_balance")
            }}</span>
          </p>
          <div v-if="!tongglePrice">
            <p class="price-coin">
              {{ totalSpotBtcBalance | formatNumberAndDecimal }} BTC
            </p>
            <p class="recent-rounding">
              ≈
              <fiat-currency
                :value="totalUsdSpotBalance | formatNumberAndDecimal"
              ></fiat-currency>
            </p>
          </div>
          <div v-else>
            <p class="recent-coin">***********</p>
          </div>
        </div>
        <div class="recent-box">
          <p class="recent-title title-balance">
            {{ $t("title.total_PNL") }}
            <span class="unHideCoin">{{ $t("balances.future.PNL") }}</span>
          </p>
          <div v-if="!tongglePrice">
            <p
              :class="clazzUnrealized(totalFuturesBtcBalance, !tongglePrice)"
              class="price-coin"
              style="color: #01bc8d"
            >
              {{ totalFuturesBtcBalance | formatNumberAndDecimal }} BTC
            </p>
            <p
              class="recent-rounding"
              :class="clazzUnrealized(totalFuturesBtcBalance, !tongglePrice)"
            >
              ≈
              <fiat-currency
                :value="totalUsdFutureBalance | formatNumberAndDecimal"
              ></fiat-currency>
            </p>
          </div>
          <div v-else>
            <p class="recent-coin">***********</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import Cookies from "js-cookie";

export default {
  methods: {
    clazzUnrealized(value, toggle) {
      if (value == 0 || !toggle) {
        return "";
      }
      return value > 0 ? "unrealizedPNL" : "unrealizedROE";
    },
    clickHide() {
      this.$emit("clickHide");
    },
    convertCoin(coin) {
      return coin.toString().length > 10
        ? coin.toString().slice(0, 11) + "..."
        : coin;
    },
    checkDisplayFullCoin(coin) {
      if (coin.toString().length > 10) return true;
      return false;
    },
  },
  props: {
    priceCoin: {
      type: String,
      default: "",
    },
    valued: {
      type: String,
      default: "",
    },
    tongglePrice: {
      type: Boolean,
      default: false,
    },
    totalSpotBtcBalance: {
      type: String,
      default: "",
    },
    totalUsdSpotBalance: {
      type: String,
      default: "",
    },
    totalFuturesBtcBalance: {
      default: 0,
    },
    totalUsdFutureBalance: {
      default: 0,
    },
  },
  data() {
    return {
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
    };
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";

img {
  cursor: pointer;
}

#wallet-future {
  .title-balance {
    display: inline-block;
    font-family: 400;
    font-size: $font-medium-bigger !important;
    color: var(--text-close);
    line-height: 24px;
    position: relative;
    opacity: 1 !important;
    .unHideCoin {
      display: none !important;
    }
    p:hover {
      .unHideCoin {
        z-index: 1000;
        white-space: break-spaces;
        display: block !important;
        position: absolute;
        padding: 10px;
        min-width: 340px !important;
        top: -80px;
        border-radius: 8px;
        background: var(--color-tooltip);
        color: $color-white !important;
        font-size: 16px;
        width: fit-content !important;
        text-align: start;
      }
      .firstCoin {
        //top: 0px;
      }
    }
  }

  /* background: var(--background-color-primary); */
  .content-right-wrapper {
    padding-right: 260px;
    @media screen and (max-width: 1919px) {
      padding-right: 3%;
    }

    .price-coin {
      color: var(--text-primary-color);
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 8px;
    }

    .unrealizedPNL {
      color: $text-color-jade;
    }

    .unrealizedROE {
      color: $text-color-red !important;
    }

    .estimate-balance {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;

      .estimate {
        min-width: 258px;

        .estimate-btc {
          margin-bottom: 2px;

          p {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: var(--color-percent);
            opacity: 0.8;
            display: inline-block;
          }

          span {
            /* margin-left: 14px; */
            align-items: center;
            display: inline-flex;
          }
        }

        .price-coin {
          //position: relative;

          p {
            color: var(--text-primary-color);
            font-weight: 700;
            font-size: 36px;
            margin-bottom: 8px;
          }

          span {
            color: var(--text-primary-color);
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
          }

          .unHideCoin {
            display: none;
          }

          p:hover {
            .unHideCoin {
              display: block;
              position: absolute;
              padding: 10px;
              border-radius: 8px;
              background: var(--color-tooltip);
              color: $color-white !important;
              //top: -40px;
              font-size: 16px;
            }
          }
        }

        .price-coin-hide {
          font-weight: 600;
          font-size: 30px;
          color: var(--text-primary-color);
          @media screen and (max-width: 992px) {
            font-size: 16px;
          }
        }
      }

      .recent-box {
        min-width: 258px;
        color: var(--text-primary-color);
        //position: relative;

        .recent-title {
          font-size: 20px;
          line-height: 24px;
          color: var(--color-percent);
          opacity: 0.8;
        }

        .recent-coin {
          font-weight: 600;
          font-size: 30px;
          margin-bottom: 12px;

          span {
            color: $dark-2;
          }
        }

        .recent-rounding {
          color: var(--text-primary-color);
          font-weight: 600;
          font-size: 28px;
          line-height: 34px;
        }

        .unHideCoin {
          display: none;
        }

        p:hover {
          .unHideCoin {
            display: block;
            position: absolute;
            padding: 10px;
            border-radius: 8px;
            background: var(--color-tooltip);
            color: $color-white !important;
            //top: -8px;
            font-size: 16px;
          }
        }
      }

      @media only screen and (max-width: 1530px) {
        //width: 520px;
      }
    }

    @media only screen and (max-width: 1200px) {
    }
    @media only screen and (max-width: 1024px) {
      .estimate-balance {
        .recent-transactions {
          max-width: 400px;
          width: 100%;
        }

        .recent-transactions {
          max-width: unset;
          display: block;
        }
      }
    }
    @media only screen and (max-width: 992px) {
      padding: 0;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  #wallet-future {
    .content-right-wrapper {
      .estimate-balance {
        padding: 0px;
        flex-direction: column;
        .estimate {
          float: left;
          width: unset;

          .estimate-btc {
            span {
              margin-left: 8px;
              display: inline;
            }

            .title-balance {
              font-size: 16px;
              line-height: 19px;
            }
          }

          img {
            width: 14px;
            height: 8px;
          }

          .price-coin {
            p {
              margin: 0;
              font-size: 36px;
              font-weight: 700;
            }

            span {
              font-size: 16px;
              line-height: 21px;
            }
          }
        }

        .total-balance {
          float: right;
          min-width: 27.5%;
          width: unset;
          margin-right: 12px;

          @media screen and (max-width: 992px) {
            margin-top: 40px;
            margin-bottom: 40px;
          }
        }

        .recent-box {
          .recent-title {
            font-size: 14px;
            line-height: 19px;
            width: 100%;
            margin-bottom: 10px;
          }

          .recent-coin {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 6px;
          }

          .recent-rounding {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
