<template>
  <div>
    <ul class="list_tab_coin" v-if="(instruments && instruments.length)">
      <li v-for="instrument in instruments" :key='instrument.symbol' class="item" @click="changeTab(instrument)" v-bind:class="{'active' : instrument.rootSymbol === showTabHeader}">
        <div class="name_coin">{{$t(`${Array.join(['margin.symbol', instrument.rootSymbol.toLowerCase(), 'coinname'], ".")}`) }}</div>
        <div class="clearfix"></div>
        <div class="sub_coin">
          <span class="coin">{{$t(`${Array.join(['margin.symbol', instrument.rootSymbol.toLowerCase()], ".")}`) }}</span>
        </div>
      </li>
    </ul>
    <div class="clearfix"></div>
    <div class="content_tab_coin">
      <div class="content_info_coin">
        <li v-if="childInstrument" :key='contract.symbol' v-for="contract in childInstrument.child" @click="updateContract(contract)" v-bind:class="{'item': true, 'active': stateContract[contract.root_symbol] === contract.symbol}">
          {{contract.symbol | uppercase}}:
          {{showContractLabel(contract)}}
          <span class="percent">({{ getLeverage(contract) | formatMarginNumber(2)}}x)</span>
          <span class="price" :class="{'up': contract.last_change > 0, 'down': contract.last_change < 0}">
            {{ contract.last_price | formatMarginPrice(contract.tick_size)}}
            <i class="icon" :class="{'icon-arrow5': contract.last_change > 0, 'icon-arrow6': contract.last_change < 0}"></i>
          </span>
          <div v-if="contract.type == 2" class="tooltip1">
            <div class="icon-help">
              <span class="tooltipText1 tooltip-contract">{{ $t('margin.perpetual_contract_tooltip') }}</span>
            </div>
          </div>
        </li>
        <li class="item">
        <router-link v-if="currentInstrument.root_symbol" tabindex="3" class="tooltip1" :to="getRedirectURI(currentInstrument.root_symbol)">{{ $t('margin.instrument.guide', {
            symbol: $t(`${Array.join(['margin.symbol', currentInstrument.root_symbol.toLowerCase()], ".")}`),
            coinname: $t(`${Array.join(['margin.symbol', currentInstrument.root_symbol.toLowerCase(), 'coinname'], ".")}`)
          }) }}
            <div class="icon-help"></div>
            <span class="tooltipText1 tooltip-guide" v-html="$t('margin.contract_guide_tooltip', { rootSymbol: currentInstrument.root_symbol, symbol: currentInstrument.symbol })"></span>
          </router-link>
        </li>
        <div class="fl-right animation-status">
          <span class="color-span"> {{$t('margin.animation')}} </span> 
          <label class="marr10 switch">
                  <input v-model="copyAnimation" type="checkbox" @click="updateAnimationStatus()">
                  <span>
                    <i></i>
                  </span>
                </label>
                <!-- <input type="checkbox" v-model="enableAnimation"/> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import Const from "@/common/Const";
  import {mapActions, mapGetters} from 'vuex';
  import Path, {getLayoutPath} from "@/routes";
  import moment from 'moment';
  import BigNumber from 'bignumber.js';

  export default {
    components: {

    },
    computed: {
      ...mapGetters(['currentInstrument', 'instruments', 'childInstrumentByRootSymbol', 'instrumentBySymbol', 'stateContract','animation']),
    },
    props:{
      currentSymbol: {}
    },
    data() {
      return {
        showTabHeader: '',
        showContracts: {},
        childInstrument: [],
        copyAnimation: null,
      }
    },
    watch: {
      currentInstrument(value) {
        if(this.currentInstrument) {
          this.multiplier = this.currentInstrument.multiplier;
          this.showTabHeader = this.currentInstrument.root_symbol;
          this.childInstrument = this.childInstrumentByRootSymbol(this.currentInstrument.root_symbol);
          this.updateContract({symbol: this.currentInstrument.symbol, root_symbol: this.currentInstrument.root_symbol});
        }
      },
      //watching currentSymbol is getted in uri params
      currentSymbol(value){
        if(this.currentSymbol) {
          const instrument = this.instrumentBySymbol(this.currentSymbol)
          if(instrument) {
            // console.log('watch currentSymbol, instrument, childInstrument', this.currentSymbol, instrument, this.childInstrument)
            this.showTabHeader = instrument.root_symbol
            this.childInstrument = this.childInstrumentByRootSymbol(instrument.root_symbol);
            this.updateContract({symbol: instrument.symbol, root_symbol: instrument.root_symbol});
            this.setCurrentSymbol(this.currentSymbol);
          }
        }
      },
      animation (value) {
        this.copyAnimation = value;
      }
    },
    methods: {
      ...mapActions(['setCurrentSymbol', 'updateStateContract', 'getStatusAnimtion', 'updateAnimation']),
      changeTab(instrument){
        const {child, rootSymbol} = instrument;
        const symbol = this.stateContract && this.stateContract[rootSymbol] ? this.stateContract[rootSymbol] : child[0].symbol;
        // console.log('changeTab -----------', instrument, symbol, rootSymbol, this.currentInstrument)
        this.showTabHeader = rootSymbol;
        this.showContracts = this.childInstrumentByRootSymbol(rootSymbol);
        this.childInstrument = instrument;
        this.updateContract({symbol, root_symbol: rootSymbol});
      },
      updateAnimationStatus() {
        // this.animation = !this.animation;
        let params = {
          'status' : !this.animation        
        }
        this.updateAnimation(params);
      },
      showContractLabel(contract){
        const {type, expiry} = contract;
        if(typeof type == undefined) {
          console.error('The contract is missing type', contract); //#stopSwallowEx
          return 'Unknown Type'
        }
        const typedata = {
          0: this.$t('margin.contract.call_option'),
          1: moment(expiry, 'YYYY-MM-DD HH:mm:ss').format("MMM DD"),
          2: this.$t('margin.contract.perpetual'),
          3: this.$t('margin.contract.put_option')
        }
        return typedata[type]
      },
      updateContract({symbol, root_symbol}){
        const marginRoute = getLayoutPath({name: 'mam exchange'})
        this.updateStateContract({symbol, root_symbol});
        this.$router.push({path: marginRoute.path, query: {symbol: symbol}})
      },
      getRedirectURI(symbol = ""){
        let url = getLayoutPath({name: 'Contract Series Guide Index'}, true) + symbol.toLowerCase();
        return url;
      },
      getLeverage(instrument) {
        return new BigNumber(1).div(instrument.init_margin).toString();
      },
    },
    mounted() {
      this.getStatusAnimtion();
      this.copyAnimation = this.animation;
    }
   }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .color-span {
    color: #999999;
  }
  .list_tab_coin {
    float: left;
    margin: 0px;
    margin-top: 5px;
    .item {
      margin-right: 1px;
      display: inline-block;
      float: left;
      background-color: transparent;
      border-top: 3px solid transparent;
      padding: 4px 20px 4px 20px;
      height: 45px;
      text-align: center;
      cursor: pointer;
      .name_coin {
        font-size: $font-small;
        display: block;
        width: 100%;
        margin-bottom: 4px;
        line-height: 15px;
        color: $color-grey;
        font-family: $font-family-roboto-bold;
      }
      .sub_coin {
        display: block;
        width: 100%;
        overflow: hidden;
        line-height: 11px;
        font-size: $font-mini-mini;
        color: $color-grey;
        font-family: $font-family-roboto-medium;
        .coin {
          display: inline-block;
          line-height: 13px;
          margin-left: 5px;
          font-size: $font-smaller;
        }
        .percent {
          display: inline-block;
          line-height: 11px;
          &.up {
            color: $color-jungle-green;
          }
          &.down {
            color: $color-denim;
          }
        }
      }
      &:hover {
        background-color: $color-charade; 
      }
      &.active {
        border-color: $color-orange;
        background-color: $color-charade;
        .name_coin {
          color: $color-white;
        }
        .sub_coin {
          color: $color-white;
          .percent {
            &.up {
              color: $color-jungle-green;
            }
            &.down {
              color: $color-denim;
            }
          }
        }
      }
    }
  }
  .content_tab_coin {
    height: 35px;
    background-color: $color-charade;
    display: block;
    overflow: hidden;
    width: 100%;
    .content_info_coin {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 7px 2px 7px 10px;
      line-height: 14px;
      .animation-status {
        margin-top:4px;
        font-size: 12px;
        .switch {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        margin-top: 1px;
        cursor: pointer;
        input {
          display:none;
          &:checked +span {
            background: $color-orange;
            text-align: left;
            color: #999999;
            &:before {
              content: "";
              color: $color_white;
              font-size: 12px;
            }
            i {
              left: 8px;
            }
            &:after {
              content: " ";
              color: $color_white;
            }
          }

        }
        span {
          display: inline-block;
          padding: 0px 6px 0 9px;
          width: 20px;
          height: 12px;
          line-height: 14px;
          border-radius: 11px;
          background: $color_grey_icon_light;
          position: relative;
          color: #999999;
          font-size: 14px;
          text-align: right;
          &:after {
            content: "";
            color: $color_white;
            line-height: 20px;
            font-size: 12px;
          }
          i {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background: $color_white;
            position: absolute;
            left: 0;
            top: 0px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      }
      .item {
        cursor: pointer;
        font-size: $font-smaller;
        display: inline-block;
        float: left;
        color: $color-grey-sliver;
        margin-right: 20px;
        font-family: $font-family-roboto-medium;
        border: 1px solid transparent;
        border-radius: 10px;
        outline: none;
        position: relative;
        padding-left: 15px;
        height: 21px;
        line-height: 21px;
        &.active{
          padding-left: 24px;
          padding-right: 8px;
          border: 1px solid #6f6f6f;
          background: #333333;
          color: $color-blue-custom;
          &:before {
            background-color: $color-blue-custom;
            left: 10px;
          }
        }
        .percent {
          font-family: $font-family-roboto;
        }
        .price {
          color: $color-grey-sliver;
          .icon {
            line-height: 15px;
            margin-left: 5px;
            font-size: $font-mini;
          }
          &.up {
            color: $color-jungle-green;
            .icon {
              color: $color-jungle-green;
            }
          }
          &.down {
            color: $color-denim;
            .icon {
              color: $color-denim;
            }
          }
        }
        .icon-help {
          line-height: 14px;
          margin-left: 5px;
          font-size: $font-smaller;
          float: right;
          position: relative;
          top: 2px;
        }
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          background-color: $color-grey-sliver;
          border-radius: 50%;
          display: block;
          float: left;
          top: 6px;
          left: 4px;
          position: absolute;
        }
      }
    }
  }
  .tooltip1 {
    position: relative;
    display: inline-block;
  }
  /* Tooltip text */
  .tooltip1 .tooltipText1 {
    visibility: hidden;
    background-color: #222832;
    color: #cccccc;
    text-align: center;
    padding: 5px 5px;
    border-radius: 4px;
    font-size: 13px;
    font-family: $font-family-roboto;
    line-height: 17px;
  
    /* Position the tooltip text - see examples below! */
    box-shadow: 0px 0px 3px #cccccc;
    position: absolute;
    z-index: 3;
  }

  .tooltip-contract {
    width: 300px;
    top: -6px;
  }

  .tooltip-guide {
    width: 420px;
    top: -6px;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip1:hover .tooltipText1 {
    visibility: visible;
  }
</style>