<template>
  <div class="contain">
    <main id="content">
      <div class="header-content">
        <div class="guest-logo">
          <router-link to="/">
            <img
              class="logo-monas"
              :src="
                require(`@/assets/images/logo-${
                  userTheme === 'light-theme' ? 'dark' : 'white'
                }.svg`)
              "
            />
          </router-link>
        </div>
        <div class="left-side">
          <div
            class="bar f-fr lang-bar dropdown-block btn-responsive"
            @focus="lang_bar = true"
            @blur="lang_bar = false"
            @mouseover="lang_bar = true"
            @click="lang_bar = !lang_bar"
            @mouseleave="lang_bar = false"
          >
            <button
              :class="[isAuthenticated ? 'lang' : '', 'btn', 'btn-lang-head']"
            >
              <img
                class="dropdown-active"
                v-if="userTheme === 'light-theme'"
                :src="require(`@/assets/images/icon/globe-black.svg`)"
              />
              <img
                class="dropdown-active"
                v-else
                :src="require(`@/assets/images/icon/globe-white.svg`)"
              />
            </button>
            <div class="dropdown-head">
              <ul class="subLang" v-show="lang_bar">
                <li>
                  <a href="#" @click="updateUserLocale('en')">
                    <!-- <img src="@/assets/images/icon/flag_en.svg" /> -->
                    <span>{{ $t("menu.lang.acr.en_2") }}</span>
                  </a>
                </li>
                <!-- <li>
                  <a href="#" @click="updateUserLocale('ko')">
                    <img src="@/assets/images/icon/flag_ko.svg" />
                    <span>{{ $t('menu.lang.acr.ko_2') }}</span>
                  </a>
                </li> -->
                <li>
                  <a href="#" @click="updateUserLocale('vi')">
                    <!-- <img src="@/assets/images/icon/flag_vi.svg" /> -->
                    <span>{{ $t("menu.lang.acr.vi_2") }}</span>
                  </a>
                </li>
                <li>
                  <a href="#" @click="updateUserLocale('zh')">
                    <!-- <img src="@/assets/images/icon/flag_vi.svg" /> -->
                    <span>{{ $t("menu.lang.zh") }}</span>
                    <div class="img-link" />
                  </a>
                </li>
                <li>
                  <a href="#" @click="updateUserLocale('ja')">
                    <!-- <img src="@/assets/images/icon/flag_vi.svg" /> -->
                    <span>{{ $t("menu.lang.ja") }}</span>
                    <div class="img-link" />
                  </a>
                </li>
                <li>
                  <a href="#" @click="updateUserLocale('de')">
                    <!-- <img src="@/assets/images/icon/flag_vi.svg" /> -->
                    <span>{{ $t("menu.lang.acr.de_2") }}</span>
                    <div class="img-link" />
                  </a>
                </li>

                <li>
                  <a href="#" @click="updateUserLocale('es')">
                    <!-- <img src="@/assets/images/icon/flag_vi.svg" /> -->
                    <span>{{ $t("menu.lang.acr.es_2") }}</span>
                    <div class="img-link" />
                  </a>
                </li>

                <li>
                  <a href="#" @click="updateUserLocale('fr')">
                    <!-- <img src="@/assets/images/icon/flag_vi.svg" /> -->
                    <span>{{ $t("menu.lang.acr.fr_2") }}</span>
                    <div class="img-link" />
                  </a>
                </li>

                <li>
                  <a href="#" @click="updateUserLocale('id')">
                    <!-- <img src="@/assets/images/icon/flag_vi.svg" /> -->
                    <span>{{ $t("menu.lang.acr.id_2") }}</span>
                    <div class="img-link" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div tabindex="8" class="bar f-fr mode-theme">
            <input
              @change="toggleTheme"
              id="checkbox"
              type="checkbox"
              class="switch-checkbox"
            />
            <label for="checkbox" class="switch-label">
              <img
                :src="
                  require(`@/assets/images/icon/${
                    userTheme === 'light-theme' ? 'moon-light' : 'sun-light'
                  }.svg`)
                "
              />
            </label>
          </div>

          <!-- <div
            class=" responsiveTabRight "
            :class=" menuActive? 'active' : ''"
          >
            <button @click="menuActive = !menuActive"  class='menu-button'>
              <img v-if="menuActive" :src="require(`@/assets/images/icon/close.svg`)" class='menu-button' >
              <img v-else :src="require(`@/assets/images/icon/menu${userTheme === 'light-theme' ?  '-black' : '-white'}.svg`)" class='menu-button' >
            </button>
          </div> -->
        </div>
      </div>
      <div class="body"><slot></slot></div>
    </main>

    <!-- <footer id="footer">
      <page-footer :miniFooter="true">
      </page-footer>
    </footer> -->
  </div>
</template>

<script>
import PageFooter from "../common/PageFooter.vue";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import rf from "@/request/RequestFactory";
import Cookies from "js-cookie";

export default {
  components: {
    PageFooter,
  },
  data() {
    return {
      userTheme: "light-theme",
      lang_bar: false,
      menuActive: false,
    };
  },
  methods: {
    toggleTheme() {
      const activeTheme =
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme";
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
        this.$store.dispatch("setUserThemeMode", "dark-theme");
      } else {
        this.setTheme("light-theme");
        this.$store.dispatch("setUserThemeMode", "light-theme");
      }
    },

    getTheme() {
      return (
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme"
      );
    },

    setTheme(theme) {
      Cookies.set("user-theme", theme, {
        domain: process.env.VUE_APP_PUBLIC_URL,
      });
      this.userTheme = theme;
      this.$store.dispatch("setUserThemeMode", theme);
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
    updateUserLocale(locale) {
      AuthenticationUtils.setLocale(locale);
      this.news = [];
      // this.getNewsData();
      // this.getUserNewsInfo();
      let params = {
        lang: locale,
      };
      if (this.isAuthenticated) {
        rf.getRequest("LocaleRequest").setUserLocale(params);
      }
      this.$i18n.locale = locale;
      // localStorage.setItem('locale', locale)
      Cookies.set("locale", locale, { domain: process.env.VUE_APP_PUBLIC_URL });
      window.i18n.locale = locale;
      this.$store.dispatch("setLang", locale);
      this.$store.commit("updateLocalLang", locale);
    },
    resizeHandler() {
      this.menuActive = false;
    },
  },
  mounted() {
    // theme
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/common.scss";
#content {
  //min-height: calc(100vh - 40px);
  min-height: 100vh;
  background: var(--background-color-primary);
}
.header-content {
  //width: 100%;
  height: 64px;
  background: var(--background-color-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  //margin-left: 40px;
  //margin-right: 40px;
  //width: 1490px;
  @media screen and (max-width: 1490px) {
    width: auto;
    padding: 0 20px;
  }

  .left-side {
    display: flex;
    gap: 10px;
    align-items: center;
    //width: 200px;
    max-width: 100%;
    .dropdown-block {
      position: relative;
      display: inline-block;
      //margin: 15px 0px 12px 0px;
      .btn-lang-head {
        border-radius: 3px;
        border: 0;
        line-height: 19px;
        float: left;
        margin: 0;
        font-family: $font-inter-regular;
        color: var(--text-primary-color);
        letter-spacing: 0.9px;
        padding: 8px;
        font-size: $font-medium-bigger;

        &.left_bar {
          color: var(--text-primary-color);
          float: right;
          right: 0;
        }
        background-color: transparent;
        .icon-arrow-up {
          display: inline-flex;
          font-size: $font-medium-bigger;
          transform: scaleX(1.7);
          transform-origin: 0 0;
          position: absolute;
          margin-left: 5px;
          top: 10px;
        }
        &.lang {
          color: var(--text-primary-color);
        }
        &.supportTab {
          padding: 7px 0px;
        }
        .active {
          color: $color-caribbean-green;
        }
      }
      .dropdown-head {
        position: absolute;
        top: 100%;
        margin: 0px;
        right: 0px;
        z-index: 10;
        min-width: 250px;
        width: 100%;
        border-radius: 8px;
        display: flex;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        &.right-side {
          left: 0;
          > .tradeTab {
            display: flex;
            align-items: center;
            flex-direction: column;
            min-width: 200px;
            > .arrow-right {
              float: right;
            }
          }
        }
        &.dropdown-large {
          left: 0;
          min-width: 400px;
        }
        > ul {
          padding: 15px;
          margin-top: 3px;
          background-color: var(--background-color-primary);
          border-radius: 8px;
          overflow: hidden;
          li {
            display: block;
            width: 100%;
            color: var(--text-primary-color);
            a {
              display: flex !important;
              align-items: center;
              span {
                font-family: $font-inter-regular;
                color: var(--text-primary-color);
                font-size: $font-medium-bigger;
                width: 100%;
                display: flex;
                margin-left: 12px;
                &.icon-arrow {
                  width: fit-content;
                  > img {
                    float: right;
                  }
                }
              }
            }
            &:hover {
              background-color: var(--hover-item-nav);
              border-radius: 8px;
              a {
                span {
                  color: var(--text-primary-color);
                }
              }
            }
            &:last-child {
              border-bottom: 0;
            }
            a {
              display: block;
              width: 100%;
              font-size: $font-small;
              color: $color-white;
              line-height: 19px;
              padding: 10px 13px;
            }
          }
        }
        .subLang {
          width: 100%;
          //font-family: $font-family-roboto-medium;
          font-family: $font-inter-regular;
          li {
            a {
              font-size: $font-inter-regular;
            }
          }
        }
      }
    }
  }
}
.body {
  //border-top: var(--border-header) 2px solid;
}
//.guest-logo {
// transform: translateY(30%);
//}
.guest-logo img {
  min-height: 20px;
  height: 20px;
  //margin: 0 auto;
  display: block;
}

.auth-container {
  max-width: 454px;
  margin: 0 auto;
  padding: 0 12px;
}

.responsiveTabRight {
  display: none;
}

@media screen and (max-width: 1300px) {
  .responsiveTabRight {
    display: block;
    float: right;
    margin-left: 8px;
    .menu-button {
      background: transparent;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/common.scss";
.auth-container {
  padding: 50px 10px 100px 10px;
  max-width: 454px;
  margin: 0 auto;
  padding: 0 12px;

  .btn-primary {
    width: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &:hover,
    &:active,
    &:focus {
      background: $color-corn;
      border-color: $color-corn;
      color: $color-white;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }
  .guest-page-form {
    position: relative;
    .guest-page-form-header {
      margin: 18px 0px 0px;
      .title {
        color: $color-corn-pale;
        margin: 0 0 20px;
      }
      .sub-title {
        color: $color-grey-dusty;
        margin-bottom: 10px;
      }
    }
    .guest-page-form-body {
      width: 100%;
      .text-yellow {
        color: $text-yellow;
        font-weight: normal;
      }

      .login_box {
        label {
          font-weight: normal;
          color: $color-grey-dusty;
          margin-bottom: 0;
          a {
            margin-left: 5px;
            font-weight: bold;
          }
        }
      }
      .label {
        color: var(--text-primary-color) !important;
        margin-bottom: 5px;
        font-size: $font-big;

        display: block;
        text-align: start;
        font-family: $font-inter-regular;
      }
    }
  }
  .guest-page-form-lang {
    width: 400px;
    margin: 0 auto;
    max-width: 100%;
    .guest-page-form-header {
      display: flex;
      text-align: center;
      margin: 18px 0px 0px;
      .title {
        color: $color-corn-pale;
        margin: 0 0 20px;
      }
      .sub-title {
        color: $color-grey-dusty;
        margin-bottom: 10px;
      }
    }
    .guest-page-form-body {
      font-size: 12px;
      .text-yellow {
        color: $text-yellow;
        font-weight: normal;
      }
      .login_box {
        label {
          font-weight: normal;
          color: $color-grey-dusty;
          margin-bottom: 0;
          a {
            margin-left: 5px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .t-uppercase {
    text-transform: uppercase;
  }
}

.switch-checkbox {
  display: none;
}

.mode-theme {
  outline: none;
}

.switch-label {
  align-items: center;
  border-radius: 8rem;
  cursor: pointer;
  display: flex;
  z-index: 1;
  margin: 0;
  outline: none;
  padding: 8px;
  img {
    width: 24px;
    height: 24px;
  }
}
.img_theme_mode {
  height: 21px;
}

.switch-toggle-checked {
  transform: translateX(calc(8rem * 0.6)) !important;
}

@media screen and (max-width: 992px) {
  .left-side {
    width: auto !important;
    gap: 0 !important;
  }
}

@media screen and (max-width: 576px) {
  .guest-page-form-body {
    /* width: 315px !important; */
  }
  .header-content {
    padding: 15px 16px;
    height: 60px !important;
    justify-content: space-between !important;
    gap: 0% !important;
  }
}
</style>
