import Vue from 'vue';
import rf from '@/request/RequestFactory';

export default class {

constructor() {
    //public channels
    this.listenForPrices();
    this.listenForOrderTransaction();
    this.listenForOrderBook();
    this.listenForSetting();
    
    this.listenForPriceChanges();
    this.listenForCoinMarketCap();
    this.listenForCoinCheck();
    this.listenForNews();
    this.listenUpdateNews();
    this.listenUpdateCircuitBreaker();
    this.listenForTransactionSetting();
    this.listenForInstrument();
    this.listenForIndex();
    this.listenForMarginEntryUpdate();
    this.listenForEntryLeaderBoardUpdated();
    this.listenForMarginSetting();
    this.listen24hPrice()

    if (window.isAuthenticated) {
      //user private channels
      rf.getRequest('UserRequest').getCurrentUser()
        .then(res => {
          var userId = res.data.id;
          this.listenForBalance(userId);
          this.listenForTransaction(userId);
          this.listenForOrderList(userId);
          this.listenForOrderEvent(userId);
          this.listenForUserSessionRegistered(userId);
          this.listenForOtpUpdated(userId);
          this.listenForMarginOrder(userId);
          this.listenForMarginPosition(userId);
          this.listenForUserMarginTrade(userId);
          this.listenForUserWithdrawalSetting(userId);
          this.listenForEntryNumberOfRequest(userId);
          this.listenForMarginEntryRequestUpdated(userId);
          this.listenForMarginContestStatus();
        });
    }

    Vue.mixin({
      mounted: function () {
        if (this.getSocketEventHandlers) {
          window._.forIn(this.getSocketEventHandlers(), (handler, eventName) => {
            this.$on(eventName, handler);
          });
        }
      },
      beforeDestroy() {
        if (this.getSocketEventHandlers) {
          window._.forIn(this.getSocketEventHandlers(), (handler, eventName) => {
            this.$off(eventName, handler);
          });
        }
      }
    });
  }

  notifyPricesUpdate(newPrices) {
    this.prices = window._.merge(this.prices || {}, newPrices);
    window.app.$broadcast('PricesUpdated', newPrices.data);
  }

  listenForPrices() {
    window.Echo.channel('App.Prices')
      .listen('PricesUpdated', (newPrices) => {
        this.notifyPricesUpdate(newPrices);
    });
  }

  listenForMarginOrderbook(symbol) {
    if (this.lastOderbookSymbol) {
      window.Echo.channel('App.MarginOrderbook.' + this.lastOderbookSymbol).unbind();
      window.Echo.leave('App.MarginOrderbook.' + this.lastOderbookSymbol);
    }
    if (symbol) {
      this.lastOderbookSymbol = symbol;
      window.Echo.channel('App.MarginOrderbook.' + symbol).listen('MarginOrderbookUpdated', (data) => {
        window.app.$store.dispatch('updateOrderbook', data.data);
      });
    }
  }

  listenForMarginTrade(symbol) {
    if (this.lastTradeSymbol) {
      window.Echo.channel('App.MarginTrade.' + this.lastTradeSymbol).unbind();
      window.Echo.leave('App.MarginTrade.' + this.lastTradeSymbol);
    }
    if (symbol) {
      this.lastTradeSymbol = symbol;
      window.Echo.channel('App.MarginTrade.' + symbol)
        .listen('MarginTradesCreated', (trade) => {
          window.app.$broadcast('MarginTradesCreated', [].concat(trade.data));
          window.app.$store.commit('updateInstrumentLastPrice', [].concat(trade.data));
        });
    }
  }

  listenForUserMarginTrade(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('MarginTradeCreated', (data) => {
        window.app.$broadcast('MarginTradeCreated', data.data);
      });
  }

  listenForMarginEntryUpdate() {
    window.Echo.channel('Margin.Entry.Updated')
      .listen('MarginEntryUpdated', (data) => {
        window.app.$broadcast('MarginEntryUpdated', data);
      });
  }
  listenForMarginContestStatus() {
    window.Echo.channel('Margin.Contest.Status')
    .listen('MarginContestStatusUpdated', (data) => {
      window.app.$broadcast('MarginContestStatusUpdated', data);
    })
  }

  listenForMarginEntryRequestUpdated(userId) {
    window.Echo.private('App.User.' + userId)
    .listen('MarginEntryMyRequestUpdated', (data) => {
      window.app.$broadcast('MarginEntryMyRequestUpdated', data);
    })
  }

  listenForMarginOrder(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('MarginOrderUpdated', (data) => {
        window.app.$broadcast('MarginOrderUpdated', data.data);
      });
  }

  listenForEntryLeaderBoardUpdated(){
    window.Echo.channel('Entry.LeaderBoard.Update')
      .listen('EntryLeaderBoardUpdated', (data) => {
        window.app.$broadcast('EntryLeaderBoardUpdated', data.data);
      });
  }

  listenForMarginPosition(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('MarginPositionUpdated', (data) => {
        window.app.$broadcast('MarginPositionUpdated', data.data);
        if (data.data.manager_email == null) {
          window.app.$store.commit('updatePosition', data.data);
        } else {
          window.app.$store.commit('updateMamPosition', data.data);
        }
      });
  }

  listenForBalance(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('BalanceUpdated', (balance) => {
        window.app.$broadcast('BalanceUpdated', balance.data);
        if (this.balance) {
          this.balance.data = window._.merge(this.balance.data || {}, balance.data);
        }
    });
    window.Echo.private('App.User.' + userId)
      .listen('AmlBalanceUpdated', (balance) => {
        window.app.$broadcast('AmlBalanceUpdated', balance.data);
    });
    window.Echo.private('App.User.' + userId)
      .listen('MainBalanceUpdated', (balance) => {
        window.app.$broadcast('MainBalanceUpdated', balance.data);
    });
    window.Echo.private('App.User.' + userId)
      .listen('MamBalanceUpdated', (balance) => {
        window.app.$broadcast('MamBalanceUpdated', balance.data);
    });
    window.Echo.private('App.User.' + userId)
      .listen('MarginBalanceUpdated', (balance) => {
        window.app.$broadcast('MarginBalanceUpdated', balance.data);
    });
    window.Echo.private('App.User.' + userId)
      .listen('SpotBalanceUpdated', (balance) => {
        window.app.$broadcast('SpotBalanceUpdated', balance.data);
    });
    window.Echo.private('App.User.' + userId)
      .listen('WithdrawDepositBalanceEvent', (balance) => {
        window.app.$broadcast('WithdrawDepositBalanceEvent', balance.data);
    });
    window.Echo.private('App.User.' + userId)
      .listen('BetaTesterStatusChanged', (data) => {
        window.app.$broadcast('BetaTesterStatusChanged', data.data);
    });

  }

  listenForTransaction(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('TransactionCreated', (transaction) => {
        window.app.$broadcast('TransactionCreated', transaction.data);
    });
  }

  listenForOrderTransaction(userId) {
    window.Echo.channel('App.Orders')
      .listen('OrderTransactionCreated', (transaction) => {
        window.app.$broadcast('OrderTransactionCreated', transaction.data);
    });
  }

  listenForOrderBook() {
    window.Echo.channel('App.OrderBook')
      .listen('OrderBookUpdated', (orderBook) => {
        window.app.$broadcast('OrderBookUpdated', orderBook.data);
    });
  }

  listenForMarginSetting() {
    window.Echo.channel('App.MarginSetting')
      .listen('MarginIndicatorUpdated', (marginIndicator) => {
        window.app.$broadcast('MarginIndicatorUpdated', marginIndicator.data);
    });
  }

  listenForSetting() {
    window.Echo.channel('App.Setting')
      .listen('SettingUpdated', (Setting) => {
        window.app.$broadcast('SettingUpdated', Setting.data);
    });
  }

  listenForTransactionSetting() {
    window.Echo.channel('App.Models.Admin')
      .listen('TransactionSettingEvent', (Setting) => {
          window.app.$broadcast('TransactionSettingEvent', Setting.data);
      });
  }

  listenForInstrument() {
    window.Echo.channel('App.Instrument')
      .listen('InstrumentExtraInformationsUpdated', (data) => {
        window.app.$store.commit('updateInstrument', data.data);
    });
  }

  listenForIndex() {
    window.Echo.channel('App.Index')
      .listen('MarginIndexUpdated', (data) => {
        window.app.$broadcast('MarginIndexUpdated', data.data);
        window.app.$store.commit('updateIndex', data.data);
    });
  }

  listenForUserWithdrawalSetting(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('UserWithdrawalSettingEvent', (Setting) => {
        window.app.$broadcast('UserWithdrawalSettingEvent', Setting.data);
    });
  }

  listenForEntryNumberOfRequest (userId) {
    window.Echo.private('App.User.' + userId)
      .listen('MarginNumberOfRequestUpdated', (data) => {
        window.app.$broadcast('EntryNumberOfRequest', data.data);
    });
  }

  listenForEntryTeamUpdated (team_id) {
    window.Echo.channel(`Entry.Team.${team_id}.Updated`)
        .listen('MarginEntryTeamUpdated', (data) => {
          window.app.$broadcast('EntryTeamUpdated', data.data);
        });
  }

  listenForEntryUpdated(user_id){
    window.Echo.channel(`Entry.${user_id}.Updated`)
        .listen('MarginEntryIndividualUpdated', (data) => {
          window.app.$broadcast('EntryTeamUpdated', data.data);
        });
  }

  listenForUserOrderBook(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('UserOrderBookUpdated', (orderBook) => {
        window.app.$broadcast('UserOrderBookUpdated', orderBook.data);
    });
  }

  listenForOrderList(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('OrderListUpdated', (data) => {
        window.app.$broadcast('OrderListUpdated', data.data);
    });
  }

  listenForOrderEvent(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('OrderChanged', (data) => {
        window.app.$broadcast('OrderChanged', data.data);
    });
  }

  listenForUserSessionRegistered(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('UserSessionRegistered', (data) => {
        window.app.$broadcast('UserSessionRegistered', data.data);
    });
  }

  listenForPriceChanges() {
    window.Echo.channel('App.MarketPriceChanges')
      .listen('MarketPriceChangesUpdated', (res) => {
        window.app.$broadcast('MarketPriceChangesUpdated', res.data);
      });
  }

  listenForOtpUpdated(userId) {
    window.Echo.private('App.User.' + userId)
      .listen('OtpUpdated', (res) => {
        window.app.$broadcast('OtpUpdated', res.data);
      });
  }

  listenForCoinMarketCap() {
    window.Echo.channel('App.CoinMarketCapTicker')
    .listen('.update', (res) => {
      window.app.$broadcast('CoinMarketCapTickerUpdated', res.data);
    });
  }

  listenForCoinCheck() {
    window.Echo.channel('App.CoinCheckBtcUsdExchanges')
    .listen('CoinCheckBtcUsdExchangesUpdated', (res) => {
      window.app.$broadcast('CoinCheckBtcUsdExchangesUpdated', res.data);
    });
  }

  listenForNews() {
    window.Echo.channel('App.News')
    .listen('NewsStateUpdated', (res) => {
      window.app.$broadcast('NewsStateUpdated', res);
    });
  }

  listenUpdateNews() {
    window.Echo.channel('App.UpdateNews')
    .listen('ZendeskNewsUpdated', (res) => {
      window.app.$broadcast('ZendeskNewsUpdated', res);
    });
  }

  listen24hPrice() {
    window.Echo.channel('tickers')
      .listen('tickers', (data) => {
          console.log(data)
          window.app.$broadcast('tickers', data);
      });

      // this.socket.on("*",function(event,data) {
      //     console.log(event);
      //     console.log(data);
      // });

  }

  listenUpdateCircuitBreaker() {
    window.Echo.channel('App.CircuitBreaker')
    .listen('CircuitBreakerSettingUpdated', (res) => {
      window.app.$broadcast('CircuitBreakerSettingUpdated', res);
    });

    window.Echo.channel('App.CircuitBreaker')
    .listen('CircuitBreakerCoinPairSettingUpdated', (res) => {
      window.app.$broadcast('CircuitBreakerCoinPairSettingUpdated', res);
    });

  }

}
