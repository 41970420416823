<template>
  <modal :name="name" width="830" id="agreement-otp-modal">
    <div slot="body">
      <div class="modal-form">
        <div class="box-title">{{$t('account.modal.agreement_otp.title')}}</div>
        <div class="note-header-top">
          <div v-html="`${$t('account.modal.agreement_otp.policy_content', {contact_email : contact_email})}`" class="sub_title"></div>
        </div>
        <div class="note-header-content">
          <div class="note-header" >
            <div class="block">
              <div class="text_head">
                {{$t('account.modal.agreement_otp.policy_content_text2')}}
              </div>
            </div>
            <div class="block">
              <div class="text_head">{{$t('account.modal.agreement_otp.policy_content_text3')}}</div>
              <p class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text4')}}</p>
              <p class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text5')}}</p>
              <p class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text6', {APP_NAME: APP_NAME})}}</p>
            </div>
            <div class="block">
              <div class="text_head">{{$t('account.modal.agreement_otp.policy_content_text7')}}</div>
              <p class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text8')}}</p>
              <p class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text9')}}</p>
              <p class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text10')}}</p>
            </div>
          </div>
          <div class="note-header">
              <div class="note-header-right-content">
                <img src="/images/icon/icon-verified.svg" alt="icon" class="icon">
                <span class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text11')}}</span>
              </div>
              <div class="note-header-right-content">
                <img src="/images/icon/icon-verified.svg" alt="icon" class="icon">
                <span class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text12')}}</span>
              </div>
              <div class="note-header-right-content">
                <img src="/images/icon/icon-verified.svg" alt="icon" class="icon">
                <span class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text13', {APP_NAME: APP_NAME})}}</span>
              </div>
              <div class="note-header-right-content">
                <img src="/images/icon/icon-verified.svg" alt="icon" class="icon">
                <span class="text_sub">{{$t('account.modal.agreement_otp.policy_content_text14')}}</span>
            </div>
          </div>
        </div>
        <div class="click-lost-OTP">
          <button class="btn-click" @click="hideModal">
            {{ $t('common.action.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>

import Cookies from 'js-cookie'
import Modal from '../common/Modal';
import {mapState} from "vuex";

export default {
    components: {
      Modal,
    },

    props: {
      name: {type: String, default: 'lostOtpPolicyModal'},
    },
    data() {
      return {
        isJa : Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) == "ja",
        contact_email: '',
        APP_NAME: process.env.VUE_APP_NAME,
        siteSetting: {},

      }
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata
      })
    },

    methods: {
      hideModal() {
        CommonModal.hide(this.name)
      },
      convertSiteSetting(data) {
        window._.each(data, item => {
          this.siteSetting[item.key] = item.value;
          this.contact_email = this.siteSetting.contact_email
          this.$forceUpdate()
        });
      },
    },
    watch: {
      masterdata() {
        this.$nextTick(() => {
          this.convertSiteSetting(this.masterdata?.settings)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .modal-dialog{
    width: 830px ! important;
  }
  #agreement-otp-modal {
    .modal-form{
      margin: 0 auto;
      padding: 0;
      .icon-save:before {
        content: "\E922";
        color: $text-curious-blue;
        height: 15px;
        width: 20.2px;
      }
      .box-title {
        margin-bottom: 8px;
        font-family: $font-inter-bold;
        color: var(--text-primary-color);
        font-size: 34px;
        line-height: 41px;
        text-transform: capitalize;
      }

      .note-header-top {
        .sub_title {
          font-family: $font-inter-regular;
          font-size: 16px;
          line-height: 27px;
          color: var(--text-desc);
          ::v-deep  {
            .primary-link {
              color: $color-aqua-green;
            }
          }
        }
      }
      .note-header-content{
        margin-top: 30px;
        display: flex;
        gap: 5px;
        .text_sub{
          font-family: $font-inter-regular;
          font-size: 16px;
          line-height: 21px;
          color: var(--text-desc);
          margin-bottom: 5px;
        }

        .note-header {
          padding: 20px 17px;
          background-color: var(--color-dark-5);
          flex: 1;
          .block + .block {
            margin-top: 16px;
          }
          .text_head{
            margin-bottom: 5px;
            font-family: $font-inter-bold;
            font-size: 18px;
            line-height: 21px;
            color: var(--text-primary-color);
          }
        }
        .note-header-right-content {
          display: flex;
          gap: 10px;
          & + .note-header-right-content {
            margin-top: 15px;
          }
          .icon {
            height: 100%;
          }
        }
      }
    }
    .click-lost-OTP{
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn-click {
        @include btn-common(yes);
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 992px) {
    #agreement-otp-modal {
      .modal-body {
        padding: 16px;
      }
      .modal-form {
        max-height: 500px;
        overflow-y: auto;
        padding-right: 9px;
        margin-right: 7px;
        .box-title {
          font-size: 20px;
          line-height: 24px;
        }
        .note-header-top {
          .sub_title {
            font-size: 14px;
            line-height: 19px;
          }
        }
        .note-header-content {
          flex-direction: column;
          .note-header {
            .text_head {
              font-size: 16px;
              line-height: 21px;
            }
          }
          .text_sub {
            font-size: 14px;
            line-height: 21px;  
          }
          .note-header-right-content {
            .icon {
              width: 16px;
              margin-top: 3px;
            }
          }
        }
      }
     
    }
  }
</style>

<style lang="scss">
  @import "@/assets/sass/variables";
  #agreement-otp-modal {
    .modal-header {
      height: 30px;
    }
    .modal-body {
      padding: 30px;
    }

    .modal-footer {
      display: none;
    }
    @media screen and (max-width: 992px) {
      .modal-body {
        padding: 16px 0 16px 16px;
      }
    }
  }

</style>