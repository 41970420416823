var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"flex-sb"},[_c('div',{staticClass:"img-box"},[_c('div',[(_vm.coinImages[_vm.coin?.coin])?_c('img',{staticClass:"icon-coin",attrs:{"src":_vm.coinImages[_vm.coin?.coin],"alt":""}}):_c('img',{staticClass:"icon-coin",attrs:{"src":_vm.getDefaultIcon(_vm.coin?.coin),"alt":""}})]),_c('div',{staticClass:"coin-name"},[_vm._v(_vm._s(_vm.coin.name))])]),(
          _vm.coin.isDeposit ||
          _vm.coin.isWithdraw ||
          _vm.transferType('COIN_M', _vm.coin.coin) ||
          (_vm.transferType('FUTURE', _vm.coin.coin) && _vm.coin.coin !== 'usd')
        )?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"btn-dropdown"},[_c('div',{staticClass:"btn-icon",on:{"click":_vm.toggleMenu}},[_c('img',{attrs:{"src":require(`@/assets/images/wallet/more-horizontal${_vm.dark}.png`)}}),(_vm.activeMenu)?_c('div',{staticClass:"menu-dropdown"},[_c('span',{staticClass:"w-full text-left p-2 menu-item"},[(_vm.coin.isDeposit)?_c('router-link',{attrs:{"to":_vm.coin.coin == 'usd'
                    ? { name: 'Deposit USD Wallet' }
                    : {
                        name: 'Deposits Wallet',
                        query: { coin: _vm.coin.coin },
                      }}},[_vm._v(" "+_vm._s(_vm.$t("funds.balances.deposit"))+" ")]):_vm._e()],1),_c('span',{staticClass:"w-full text-left p-2 menu-item"},[(_vm.coin.isWithdraw)?_c('router-link',{attrs:{"to":_vm.coin.coin == 'usd'
                    ? { name: 'Withdraw USD Wallet' }
                    : {
                        name: 'Withdrawals Wallet',
                        query: { coin: _vm.coin.coin },
                      }}},[_vm._v(" "+_vm._s(_vm.$t("funds.balances.withdrawal"))+" ")]):_vm._e()],1),(
                (_vm.transferType('COIN_M', _vm.coin.coin) ||
                  _vm.transferType('FUTURE', _vm.coin.coin)) &&
                _vm.coin.coin !== 'usd'
              )?_c('span',{staticClass:"w-full text-left p-2 menu-item",class:{ acvite_transfer: _vm.coin.isActiveRow },on:{"click":function($event){return _vm.handleActiveRow(_vm.coin)}}},[_c('a',[_vm._v(" "+_vm._s(_vm.$t("funds.balances.tranfer")))])]):_vm._e()]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"flex-sb pl-36"},[_c('div',{staticClass:"text-start"},[_c('div',{staticClass:"text-title"},[_vm._v(_vm._s(_vm.$t("funds.balances.name")))]),_c('div',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.coin.name))])]),_c('div',{staticClass:"text-end"},[_c('div',{staticClass:"text-title"},[_vm._v(_vm._s(_vm.$t("funds.balances.total_balance")))]),_c('div',{staticClass:"text-name"},[(!_vm.tongglePrice)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumberAndDecimal")(_vm.coin.totalBalance))+" ")]):_c('span',[_vm._v("******")])])])]),_c('div',{staticClass:"flex-sb pl-36"},[_c('div',{staticClass:"text-start"},[_c('div',{staticClass:"text-title"},[_vm._v(" "+_vm._s(_vm.$t("funds.balances.avaliable_balance"))+" ")]),_c('div',{staticClass:"text-name"},[(!_vm.tongglePrice)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumberAndDecimal")(_vm.coin.availableBalance))+" ")]):_c('span',[_vm._v("******")])])]),_c('div',{staticClass:"text-end"},[_c('div',{staticClass:"text-title"},[_vm._v(" "+_vm._s(_vm.$t("funds.balances.available"))+" /"),_c('br'),_vm._v(" "+_vm._s(_vm.$t("funds.balances.total_futures_balance"))+" ")]),_c('div',{staticClass:"text-name"},[(!_vm.tongglePrice)?_c('div',[(_vm.coin.marginAvailabelBalance > 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumberAndDecimal")(_vm.displayPositive(_vm.coin.marginAvailabelBalance)))+" ")]):_c('span',[_vm._v("0")]),_vm._v(" / "),(_vm.coin.marginBalance > 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumberAndDecimal")(_vm.displayPositive(_vm.coin.marginBalance)))+" ")]):_c('span',[_vm._v("0")])]):_c('div',[_vm._v("******")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }