var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container"},[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.columns),function(col){return _c('th',{key:col.field,on:{"click":function($event){return _vm.sortTable(col.field)}}},[_vm._v(" "+_vm._s(col.label)+" "),(_vm.currentSort === col.field)?_c('span',[_vm._v(_vm._s(_vm.currentSortDir === "asc" ? "↑" : "↓"))]):_vm._e()])}),0)]),_c('tbody',_vm._l((_vm.paginatedData),function(row,index){return _c('tr',{key:index},[_vm._l((_vm.columns),function(col){return (col.field === 'id')?_c('td',{key:col.field,class:index === 0 || index === 1 || index === 2
              ? 'text-top'
              : 'text-normal'},[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e()}),_vm._l((_vm.columns),function(col){return (col.field === 'name')?_c('td',{key:col.field},[_c('div',{style:({
              display: 'flex',
              alignItems: 'center',
              maxWidth: '90px',
            })},[_c('img',{style:({ width: '28px', height: '28px', objectFit: 'contain' }),attrs:{"src":_vm.logCoin(row['coin'])}}),_c('p',{staticClass:"text-coin"},[_vm._v(_vm._s(row["name"]))]),_c('p',{staticClass:"text-sign"},[_vm._v("/"+_vm._s(row["sign"]))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(row['type']),expression:"row['type']"}],staticClass:"container-type"},[_vm._v(" "+_vm._s(row["type"])+" ")])])]):_vm._e()}),_vm._l((_vm.columns),function(col){return (
            col.field !== 'id' &&
            col.field !== 'action' &&
            col.field !== 'name'
          )?_c('td',{key:col.field},[_vm._v(" "+_vm._s(row[col.field])+" ")]):_vm._e()}),_vm._l((_vm.columns),function(col){return (col.field === 'action')?_c('td',{key:col.field},[_c('span',{staticClass:"action-trade"},[_vm._v("Trade")])]):_vm._e()})],2)}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"pagination"},[_c('button',{attrs:{"disabled":_vm.currentPage === 1},on:{"click":function($event){return _vm.changePage(_vm.currentPage - 1)}}},[_vm._v(" Previous ")]),_c('span',[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.totalPages))]),_c('button',{attrs:{"disabled":_vm.currentPage === _vm.totalPages},on:{"click":function($event){return _vm.changePage(_vm.currentPage + 1)}}},[_vm._v(" Next ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }