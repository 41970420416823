<template>
  <div
    id="open-history"
    class="page_history custtable"
    :class="{ no_padding: options && options.isNoPadding }"
  >
    <modal-sub />
    <div class="group-control" v-if="!options">
      <date-tab @change-select-date="changeDate" ref="selectDateTab" />
      <div class="group-date">
        <span class="date-title">{{ $t("funds.history.from") }}</span>
        <datepicker
          :value="dateFromDefault"
          class="date-from"
          @selected="selectDateFrom"
          :format="customFormatter"
          input-class="date-picker-input"
        ></datepicker>
        <datepicker
          :value="dateToDefault"
          class="date-to"
          @selected="selectDateTo"
          :format="customFormatter"
          input-class="date-picker-input"
        ></datepicker>
      </div>
      <button class="button-search" @click="actionSearch">
        {{ $t("common.search") }}
      </button>
    </div>

    <div
      class="group-control"
      v-if="options"
      :class="{ is_single_page: options && options.isNoPadding }"
    >
      <div class="group-date-picker">
        <div class="label-text">{{ $t("order.order_history.date") }} :</div>
        <div class="group-date">
          <datepicker
            :value="dateFromDefault"
            class="date-from"
            @selected="selectDateFrom"
            :format="customFormatter"
            input-class="date-picker-input"
          ></datepicker>
          <datepicker
            :value="dateToDefault"
            class="date-to"
            @selected="selectDateTo"
            :format="customFormatter"
            input-class="date-picker-input"
          ></datepicker>
        </div>
      </div>

      <div class="group-pair hidden">
        <div class="label-text">{{ $t("order.order_history.pair") }} :</div>
        <div class="content-search">
          <div class="toolbar-element">
            <div class="coin-input" v-click-outside="clickOut">
              <input
                type="text"
                :placeholder="$t('order.order_history.coin')"
                v-model="filters.coin"
                @click="showCoinOption()"
                @keyup="onSearchCoin()"
                @keyup.enter="actionSearch"
              />
              <div class="box-coin-option" v-if="isShowCoinOption">
                <div class="coin-option">
                  <span
                    class="option"
                    v-for="coin in coins"
                    @click="onSelectCoin(coin)"
                    >{{ coin | uppercase }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <span class="bw-element">/</span>
          <div class="toolbar-element2">
            <select-box
              v-model="filters.currency"
              :placeholder="$t('order.order_history.all')"
              :options="getMarkets()"
            />
          </div>
        </div>
      </div>

      <div class="group-type">
        <div class="label-text">{{ $t("order.order_history.side") }} :</div>
        <div class="content-search">
          <select-box
            v-model="filters.tradeType"
            :placeholder="$t('order.order_history.all')"
            :options="sideList"
          />
          <button class="button-search" @click="actionSearch">
            {{ $t("orders.trade_history.search") }}
          </button>
          <button class="button-reset" @click="actionReset">
            {{ $t("orders.trade_history.reset") }}
          </button>
        </div>
      </div>
      <div class="export-file">
        <a
          class="excel_export f-fr mart3"
          @click="downloadReport()"
          @mouseover="mouseOver"
          @mouseout="mouseOver"
        >
          <span class="icon-export" />&nbsp;
          {{ $t("order.order_history.export_complete_order_history") }}
        </a>
        <div v-show="isShowToolTip" class="export-file-tooltip">
          {{ $t("order.order_history.export_complete_order_history_tooltip") }}
        </div>
      </div>
    </div>
    <data-table3
      ref="datatable"
      :getData="getData"
      :msg-empty-data="$t('order.order_history.no_data')"
      class="datatable"
      id="order-table"
      :limit="10"
    >
      <template slot-scope="props">
        <th
          class="w_165"
          :title="$t('order.open_order.date')"
          data-sort-field="created_at"
          @click="props.echoclick"
        >
          {{ $t("order.open_order.date") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('table.symbol')"
          data-sort-field="instrument_symbol"
          @click="props.echoclick"
        >
          {{ $t("table.symbol") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('margin.quantity')"
          data-sort-field="quantity"
          @click="props.echoclick"
        >
          {{ $t("margin.quantity") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('margin.order_price')"
          data-sort-field="price"
          @click="props.echoclick"
        >
          {{ $t("margin.order_price") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('order.open_order.filled')"
          data-sort-field="filled"
          @click="props.echoclick"
        >
          {{ $t("order.open_order.filled") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('order.open_order.side')"
          data-sort-field="side"
          @click="props.echoclick"
        >
          {{ $t("order.open_order.side") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th :title="$t('margin.stop_price')" @click="props.echoclick">
          {{ $t("margin.stop_price") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('margin.fill_price')"
          data-sort-field="executed_price"
          @click="props.echoclick"
        >
          {{ $t("margin.fill_price") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('order.open_order.type')"
          class="width10"
          data-sort-field="type"
          @click="props.echoclick"
        >
          {{ $t("order.open_order.type") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('order.order_history.status')"
          class="width5"
          data-sort-field="status"
          @click="props.echoclick"
        >
          {{ $t("order.order_history.status") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('table.text')"
          class="text-left"
          @click="props.echoclick"
        >
          {{ $t("table.text") }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :title="$t('table.note')"
          class="text-left"
          @click="props.echoclick"
        >
          <span class="fl-right">{{ $t("table.note") }}</span>
          <i class="group-sort-tabl3 fl-right" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td>{{ props.item.created_at | timestampToDate }}</td>
          <td>{{ props.item.instrument_symbol | uppercase }}</td>
          <td>
            {{ props.item.quantity | formatMarginNumber(props.item.quantity) }}
          </td>
          <td>
            <span
              v-if="
                (!props.item.stop_type ||
                  [
                    'stop_market',
                    'trailing_stop',
                    'take_profit_market',
                  ].indexOf(props.item.stop_type) !== -1) &&
                props.item.type !== 'limit' &&
                ['ifd', 'oco'].indexOf(props.item.pair_type) === -1
              "
            >
              {{ $t("margin.market") }}
            </span>
            <span v-else>
              {{
                props.item.price
                  | formatMarginPrice(OrderUtils.getTickSize(props.item), 0)
              }}
            </span>
          </td>
          <td>
            {{
              (_.get(props.item, "quantity", "0") -
                _.get(props.item, "remaining", "0"))
                | formatMarginNumber(5)
            }}
          </td>
          <td
            :class="{
              blue: props.item.side == 'buy',
              red: props.item.side == 'sell',
            }"
          >
            {{ props.item.side | uppercaseFirst }}
          </td>
          <td>
            {{ getStopPrice(props.item) }}
          </td>
          <td>
            {{
              props.item.executed_price
                | formatMarginPrice(OrderUtils.getTickSize(props.item))
            }}
          </td>
          <td>
            {{ $t(OrderUtils.getOrderType(props.item)) | uppercaseFirst }}
          </td>
          <td>
            {{ $t(OrderUtils.getOrderStatus(props.item)) | uppercaseFirst }}
          </td>
          <td
            class="text-left"
            v-if="
              props.item.note == null ||
              OrderUtils.getOrderStatus(props.item).toUpperCase() == 'Expired'
            "
          >
            {{ "--" }}
          </td>
          <td class="text-left" v-else>
            {{ props.item.note | uppercaseFirst }}
          </td>
          <td class="text-right">{{ OrderUtils.getOrderNote(props.item) }}</td>
        </tr>
      </template>
    </data-table3>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateTab from "../common/DateTab.vue";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import moment from "moment";
import MasterdataUtils from "@/common/MasterdataUtils";
import Utils from "@/common/Utils";
import SelectBox from "@/components/shared_components/common/SelectBoxTabFocus";
import ModalSub from "@/components/shared_components/common/ModalSub";
import OrderUtils from "../exchange/basic/positions/OrderUtils.js";
import { mapActions } from "vuex";

export default {
  components: {
    DateTab,
    Datepicker,
    SelectBox,
    ModalSub,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    coin: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currency: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
  },
  data() {
    return {
      OrderUtils,
      commonConst: COMMON_CONST,
      dateFromDefault: moment().subtract(6, "days").toDate(),
      dateToDefault: moment().toDate(),
      dateFrom: moment().subtract(6, "days").startOf("day").format("x"),
      dateTo: moment().endOf("day").format("x"),
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      filters: {
        coin: "",
        currency: "",
        tradeType: "",
      },
      durationDateFrom: null,
      isHide: false,
      isShowToolTip: false,
      sideList: [
        {
          id: "all",
          name: window.i18n.t("order.order_form.all"),
        },
        {
          id: "buy",
          name: window.i18n.t("order.order_form.buy"),
        },
        {
          id: "sell",
          name: window.i18n.t("order.order_form.sell"),
        },
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions(["getInstrument"]),
    getSocketEventHandlers() {
      return {
        MarginOrderUpdated: this.onMarginOrderUpdated,
      };
    },
    onMarginOrderUpdated() {
      this.$refs.datatable.refresh();
    },
    getStopPrice(item) {
      return item.pair_type == "ifd"
        ? "--"
        : this.$options.filters.convertConditionStatus(item.stop_condition) +
            " " +
            this.$options.filters.formatMarginPrice(
              item.stop_price,
              OrderUtils.getTickSize(item)
            );
    },
    changeDate(currentDateTab) {
      switch (currentDateTab.id) {
        case this.commonConst.ONE_DAY:
          this.durationDateFrom = moment().startOf("day").format("x");
          break;
        case this.commonConst.ONE_WEEK:
          this.durationDateFrom = moment()
            .subtract(6, "days")
            .startOf("day")
            .format("x");
          break;
        case this.commonConst.ONE_MONTH:
          this.durationDateFrom = moment()
            .subtract(1, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
          break;
        default:
          this.durationDateFrom = moment()
            .subtract(3, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
      }
      const params = {
        start_at: this.durationDateFrom,
        end_at: moment().endOf("day").format("x"),
      };
      this.$refs.datatable.filter(params);
    },
    getData(params) {
      params = {
        ...params,
        need_paginate: 1,
        start_at: this.dateFrom,
        end_at: this.dateTo,
      };

      return new Promise((resolve, reject) => {
        return rf
          .getRequest("MarginRequest")
          .getAllOrders(params)
          .then((res) => resolve({ data: res.data }))
          .catch((err) => reject(err));
      });
    },
    actionSearch() {
      if (this.dateFrom > this.dateTo) {
        this.showError(window.i18n.t("common.date.warning"));
        return false;
      }
      this.$refs.datatable.filter(this.filterParams());
      if (!this.options) {
        this.$refs.selectDateTab.resetSelectDate();
      }
    },
    filterParams() {
      const filterParams = {
        start_at: this.dateFrom,
        end_at: this.dateTo,
      };
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin;
      }
      if (this.filters.currency) {
        if (this.filters.currency == "all") {
          filterParams.currency = "";
        } else {
          filterParams.currency = this.filters.currency;
        }
      }
      if (this.filters.tradeType) {
        // filterParams.side = this.filters.tradeType;
        if (this.filters.tradeType == "all") {
          filterParams.side = "";
        } else {
          filterParams.side = this.filters.tradeType;
        }
      }
      return filterParams;
    },
    actionReset() {
      this.filters.coin = "";
      this.filters.currency = "";
      this.filters.tradeType = "";
      this.dateFromDefault = moment().subtract(6, "days").toDate();
      this.dateToDefault = moment().toDate();
      this.dateFrom = moment().subtract(6, "days").startOf("day").format("x");
      this.dateTo = moment().endOf("day").format("x");
      this.$refs.datatable.refresh();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    selectDateFrom(dateFrom) {
      this.dateFrom = moment(dateFrom).startOf("day").format("x");
    },
    selectDateTo(dateTo) {
      this.dateTo = moment(dateTo).endOf("day").format("x");
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    requestCoinList() {
      MasterdataUtils.getCoins((coins) => {
        this.coinMasters = coins;
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin.toUpperCase();
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    downloadReport() {
      let endDate = moment().format("x");
      let startDate = moment().subtract("6", "months").format("x");

      const params = {
        ...this.filterParams(),
        start_at: startDate,
        end_at: endDate,
        timezone_offset: Utils.getTimzoneOffset(),
      };
      rf.getRequest("MarginRequest").exportOrderCSV(
        params,
        "Margin-OrderHistory.csv"
      );
    },
    mouseOver() {
      this.isShowToolTip = !this.isShowToolTip;
    },

    getMarkets() {
      return window._.map(COMMON_CONST.MARKETS, (item) => {
        return { id: item, name: item.toUpperCase() };
      });
    },
    showError(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },
    showSuccess(message) {
      Message.success(message, {}, { position: "bottom_left" });
    },
  },
  created() {
    this.getInstrument();
  },
  mounted() {
    this.requestCoinList();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.fl-right {
  padding-left: 4px;
  padding-right: 15px;
}
.btn-subs {
  width: 55px;
  height: 25px;
  border-radius: 3px;
  border: solid 1px $color-jungle-green;
  color: $color-jungle-green;
  letter-spacing: 0.9px;
  font-size: $font-smaller;
  line-height: 20px;
  padding: 2px;
  overflow: hidden;
  text-transform: uppercase;
  text-align: center;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom;
    border-color: $color-blue-custom;
    color: $color-white;
  }
}
.w_130 {
  width: 130px;
}
.w_165 {
  width: 165px;
}
.content_text_table {
  max-width: 111px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.last-column {
  text-align: right !important;
}
.width5 {
  width: 5%;
}
.width10 {
  width: 10%;
}
#open-history {
  .group-control {
    padding: 9px 20px 9px 20px;
    font-size: $font-small;
    &:after {
      display: block;
      clear: both;
      content: "";
    }
    #date-tab {
      float: left;
    }
    .group-date {
      display: inline-block;
      margin-left: 36px;
      position: relative;
      float: left;
      .date-title {
        margin-right: 5px;
      }
      .vdp-datepicker {
        display: inline-block;
      }
      .date-to {
        margin-left: -5px;
      }
    }
    &.is_single_page {
      display: block;
      width: 100%;
      padding: 0 0 20px 0;
      position: relative;
      background: $color-white-smoke;
      &:after {
        display: block;
        clear: both;
        content: "";
      }
      .label-text {
        color: $color-grey-dusty;
        font-size: $font-small;
        line-height: 25px;
        margin-bottom: 3px;
      }
      .button-search {
        margin-left: 25px;
        margin-right: 10px;
        background-color: $color-jungle-green;
        color: $color-white;
        box-shadow: none;
        font-family: $font-family-roboto-bold;
        border: 1px solid $color-jungle-green;
        border-radius: 3px;
        width: 85px;
        height: 32px;
        font-size: $font-smaller;
        float: left;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        &:hover {
          background: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
      }
      .group-date-picker {
        display: inline-block;
        float: left;
        margin-right: 20px;
        .group-date {
          display: inline-block;
          margin-left: 0;
          position: relative;
          .vdp-datepicker {
            display: inline-block;
            float: left;
          }
          .date-to {
            margin-left: 0;
          }
        }
      }
      .group-pair {
        display: inline-block;
        margin-right: 20px;
        float: left;

        input {
          background-color: transparent;
          height: 49px;
          width: 95px !important;
          float: left;
          border: 0px;
          padding: 0px 5px 0px 14px;
          margin-right: 1px;
          font-size: $font-root;
          color: $color-grey-dusty;
        }
        .sc_search_select {
          background-color: $color-catskill-white;
          height: 30px;
          width: 80px;
        }
        .bw-element {
          float: left;
          font-size: 15px;
          margin-left: 5px;
          height: 30px;
          line-height: 30px;
          margin-right: 5px;
          color: $color-grey-dusty;
        }
      }
      .group-type {
        display: inline-block;
        margin-right: 20px;
        float: left;
        .sc_search_select {
          height: 30px;
          width: 80px;
          display: inline-block;
          float: left;
        }

        button {
          line-height: 20px;
          height: 32px;
          padding: 6px 16px;
          width: auto;
          min-width: 85px;
        }
      }
      .export-file {
        display: inline-block;
        float: right;
        margin-top: 40px;
        line-height: 20px;
        font-size: $font-small;
        font-weight: 500;
        position: relative;
        font-family: $font-family-roboto;
        a {
          color: $color-blue-custom;
          font-size: $font-small;
          .icon-export {
            line-height: 20px;
            font-size: $font-root;
            float: right;
            margin-left: 5px;
          }
        }
        .export-file-tooltip {
          display: none;
          position: absolute;
          bottom: 100%;
          margin-bottom: 5px;
          right: 0;
          width: 220px;
          line-height: 20px;
          background-color: $color-white;
          padding: 5px 10px;
          font-size: $font-smaller;
          font-weight: normal;
        }
        &:hover {
          .export-file-tooltip {
            display: block;
          }
        }
      }
      .button-reset {
        margin-left: 0px;
        margin-right: 0px;
        background-color: $color-jungle-green;
        color: $color-white;
        box-shadow: none;
        font-family: $font-family-roboto-bold;
        border: 1px solid $color-jungle-green;
        border-radius: 3px;
        width: 85px;
        height: 32px;
        font-size: $font-smaller;
        float: left;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        &:hover {
          background: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
      }
      .toolbar-element {
        background-color: $color-alabaster;
        margin-right: 0px;
        display: inline-block;
        float: left;
        input {
          border: 1px solid $color-alto;
          border-radius: 3px;
          line-height: 20px;
          padding-top: 5px;
          padding-bottom: 5px;
          height: 32px;
          &:focus {
            border: 1px solid $color-jungle-green;
          }
        }
      }
      .toolbar-element2 {
        background-color: $color-catskill-white;
        margin-right: 0px;
        display: inline-block;
        float: left;
      }
      .coin-input {
        position: relative;
        float: left;

        .box-coin-option {
          left: 0;
          position: absolute;
          top: 100%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-negative: 1;
          flex-shrink: 1;
          margin-top: 6px;
          z-index: 999;
          background-color: $color-white;
          -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
          box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
          &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: $color-white;
            border-width: 5px;
            margin-left: -5px;
          }
          .coin-option {
            position: relative;
            padding: 5px 0px;
            max-height: 135px;
            overflow-y: auto;
            .option {
              display: block;
              position: relative;
              display: inline-block;
              white-space: nowrap;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              width: 100%;
              padding: 0 20px;
              margin: 0;
              line-height: 25px;
              height: 25px;
              font-size: $font-root;
              color: $color-grey-dark;
              font-weight: normal;
              min-width: 100%;
              cursor: pointer;
              &:hover {
                background-color: $color-jungle-green;
                color: $color-white;
              }
            }
          }
        }
      }
      .excel_export {
        cursor: pointer;
        font-weight: 500;
      }
    }
  }
  #order-table {
    padding-bottom: 0;
    table {
      border: 0px;
      thead {
        background-color: $color-white;
        border: 0px;
        tr {
          th:nth-child(3),
          th:nth-child(4) {
            min-width: 65px;
          }
          th:nth-child(5) {
            min-width: 85px;
          }
          th:nth-child(6) {
            min-width: 70px;
          }
          th:nth-child(7) {
            min-width: 70px;
          }
          th:nth-child(8),
          th:nth-child(13) {
            min-width: 85px;
          }
          th:nth-child(10) {
            min-width: 145px;
          }
          th {
            font-family: $font-family-roboto-medium;
            font-size: $font-small;
            padding: 8px 0px 8px 20px;
            &:last-child {
              padding: 8px 4px 8px 0px;
            }
          }
        }
      }
      tbody {
        text-align: left;
        tr {
          &.canceled {
            opacity: 0.3;
          }
          height: auto;
          font-size: $font-small;
          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            //padding: 7px 0px 6px 20px;
            overflow: initial;
            vertical-align: middle;
            &.blue {
              color: $color-jungle-green;
            }
            &.red {
              color: $color-denim;
            }
            &:last-child {
              text-align: right;
              padding: 7px 20px 6px 0px;
            }
          }
        }
      }
    }
  }
}
.VuePagination {
  background-color: $color-mirage;
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

.dark-theme {
  .vdp-datepicker {
    input {
      background-color: $color-gray-charade !important;
      color: $color-storm-grey !important;
    }
  }
}
#open-history {
  .is_single_page {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          background: var(--color-tab-data-table) !important;
          padding: 7px 10px 5px 10px;
          width: 100%;
          height: 32px;
          border: 1px solid $color-alto;
          background-color: $color-white-smoke;
          border-radius: 3px;
          &:focus,
          &:hover,
          &.active {
            border: 1px solid $color-jungle-green;
          }
        }
        .box_list_search_select {
          ul {
            width: 80px;
            li {
              &:hover {
                background-color: $color-jungle-green;
                span,
                a {
                  color: $color-white;
                }
              }
            }
          }
        }
      }
    }

    .vdp-datepicker {
      input {
        padding: 5px 3px 5px 47px !important;
        background-position: top 11px left 14px !important;
        background-color: var(--color-tab-data-table) !important;
        margin-right: 0px !important;
        &:focus {
          border: 1px solid $color-jungle-green;
        }
      }
      @media screen and (max-width: 992px) {
        input {
          height: 36px !important;
          background-position: top 5px left 12px !important;
        }
      }
      &.date-from {
        input {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
      &.date-to {
        input {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  #open-history {
    &.custtable {
      #order-table {
        table {
          thead {
            tr {
              th {
                padding: 8px 0px 8px 8px;
                &:last-child {
                  padding: 8px 8px 8px 0px;
                  .fl-right {
                    padding-left: 4px;
                    padding-right: 0px;
                  }
                }
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 7px 0px 6px 8px;
                &:last-child {
                  padding: 7px 8px 6px 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
