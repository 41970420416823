<template>
  <div>
    <div class="balance-container">
      <div class="table-container table-history">
        <data-table4 ref="datatable" :getData="getData" :msgEmptyData="$t('common.datatable.data_empty.request_history')" id="order-table" :limit="10">
          <template slot-scope="props">
            <th data-sort-field="user_id" class="cl1">
              {{$t('mam.follower_id')}}
            </th>
            <th data-sort-field="type" class="cl2">
              {{$t('mam.type')}}
            </th>
            <th data-sort-field="created_at" class="cl3">
              {{$t('mam.submited_at')}}
            </th>
            <th data-sort-field="amount_abs" class="cl4">
              {{$t('mam.amount')}}
            </th>
            <th data-sort-field="status" class="cl5">
              {{$t('mam.status')}}
            </th>
            <th data-sort-field="note"  class="cl6">
              {{$t('mam.note')}}
            </th>
            <th class="cl7"></th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="cl1">{{ props.item.user_id }}</td>
              <td class="cl2">{{ getRequestType(props.item) }}</td>
              <td class="cl3">{{ props.item.created_at | convertToLocalTime }}</td>
              <td class="cl4">{{ props.item.amount | formatMarginNumber(8, '0', true) }} {{ $t('margin.symbol.btc') }}</td>
              <td class="cl5">{{ getRequestStatus(props.item) }}</td>
              <td  class="cl6">
                <!-- <span>{{ props.item.note | shortText(70)}}</span>
                <span class="full_value_right" v-if="props.item.note">{{ props.item.note }}</span> -->
                 <div class="item_name_setting font14">
                  <span class="txt_email_setting font14">{{ props.item.note }}</span>
                  <div class="tooltip_name_setting font14">{{ props.item.note }}</div>
                </div>
              </td>
              <td v-if="props.item.status === 'submitted'" class="cl7">
                <button @click="confirmRejectRequest(props.item.id)" class="btn btn-reject">{{ $t('common.action.reject') }}</button>
                <button @click="confirmApproveRequest(props.item.id)" class="btn btn-approve">{{ $t('common.action.approve') }}</button>
              </td>
              <td v-else-if="props.item.status === 'approved' && props.item.type === 'revoke'" class="cl7">
                <button @click="confirmExecuteRequest(props.item.id)" class="btn btn-execute">{{ $t('common.action.execute_im') }}</button>
              </td>
              <td v-else></td>
            </tr>
          </template>
        </data-table4>
        <!-- end pagination-container -->
      </div>
      <!-- end table container -->
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Mam from '../common/mam.js'

  export default {
    data() {
      return {
        isShowPendingOnly: false,
      }
    },
    methods: {
      getEventHandlers() {
        return {
          showPendingMaster: this.onShowPendingMaster,
        };
      },
      onShowPendingMaster(value) {
        this.isShowPendingOnly = value;
        this.$refs.datatable.refresh();
      },
      getRequestType (item) {
        return Mam.getRequestType(item);
      },
      getRequestStatus (item) {
        return Mam.getRequestStatus(item);
      },
      confirmExecuteRequest (id) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          content: this.$t('mam.confirm.execute_im'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.executeRequest(id);
          },
          onCancel: () => {}
        });
      },
      confirmApproveRequest (id) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          content: this.$t('mam.confirm.approve'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.approveRequest(id);
          },
          onCancel: () => {}
        });
      },
      confirmRejectRequest (id) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          content: this.$t('mam.confirm.reject'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.rejectRequest(id);
          },
          onCancel: () => {}
        });
      },
      getData (params) {
        params.is_pending = this.isShowPendingOnly ? 1 : 0;
        return rf.getRequest('MamRequest').getMasterRequest(params);
      },
      executeRequest (id) {
        const params = {
          id,
          is_master: 1,
          action: Mam.action.execute_im
        };
        return rf.getRequest('MamRequest').updateRequest(params).then(() => {
          this.$refs.datatable.refresh();
          Message.success(window.i18n.t('mam.execute_im.success'), {}, { position: 'bottom_left' });
        }).catch((error)=> {
          if (error && error.response && error.response.data && error.response.data.message) {
            Message.error(error.response.data.message, {}, { position: 'bottom_left' });
          }
        });
      },
      approveRequest (id) {
        const params = {
          id,
          is_master: 1,
          action: Mam.action.approve
        };
        return rf.getRequest('MamRequest').updateRequest(params).then(() => {
          this.$refs.datatable.refresh();
          Message.success(window.i18n.t('mam.approve.success'), {}, { position: 'bottom_left' });
        }).catch(()=> {
          Message.error(window.i18n.t('mam.approve.fail'), {}, { position: 'bottom_left' });
        });
      },
      rejectRequest (id) {
        const params = {
          id,
          is_master: 1,
          action: Mam.action.reject
        };
        return rf.getRequest('MamRequest').updateRequest(params).then(() => {
          this.$refs.datatable.refresh();
          Message.success(window.i18n.t('mam.reject.success'), {}, { position: 'bottom_left' });
        }).catch(()=> {
          Message.error(window.i18n.t('mam.reject.fail'), {}, { position: 'bottom_left' });
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss"; 
  .cl1 {
    width: 8%;
  }
  .cl2 {
    width: 8%;
  }
  .cl3{
    width: 15%;
  }
  .cl4 {
      width: 15%;
  }
  .cl5 {
      width: 10%;
  }
  .cl6{
      width: 24%;

  }
  .cl7{
      width: 20%;

  }
  .tableContainer{
    overflow: inherit !important;
  }

  .item_name_setting {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_setting {
      display: block;
      max-width: 100%;
      max-width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover {
      .tooltip_name_setting {
        transition: 0.5s;
        display: inline-block;
      }
    }
    .tooltip_name_setting{
      position: absolute;
      top: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      // width: 300px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: #333333;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      width: 400px;
      white-space: pre-wrap;
      word-break: break-all;
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
  }
  .item_name_setting {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_setting {
      display: block;
      max-width: 100%;
      max-width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover {
      .tooltip_name_setting {
        transition: 0.5s;
        display: inline-block;
      }
    }
    .tooltip_name_setting{
      position: absolute;
      top: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      // width: 300px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: #333333;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
  }
  
  .btn-reject {
    width: 70px;
    height: 25px;
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
    color: $color-jungle-green;
    letter-spacing: 0.9px;
    font-size: $font-smaller;
    line-height: 20px;
    padding: 2px;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    background-color: transparent;
    float: right;
    margin-left: 10px; 
    &:hover {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
    }
  }
  .btn-approve {
    width: 70px;
    height: 25px;
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
    color: $color-white;
    letter-spacing: 0.9px;
    font-size: $font-smaller;
    line-height: 20px;
    padding: 2px;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    background-color: $color-jungle-green;
    float: right;
    margin-left: 10px; 
    &:hover {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
    }
  }
  .btn-execute {
    width: 180px;
    height: 25px;
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
    color: $color-white;
    letter-spacing: 0.9px;
    font-size: $font-smaller;
    line-height: 20px;
    padding: 2px;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    background-color: $color-jungle-green;
    float: right;
    margin-left: 10px; 
    &:hover {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
    }
  }
  .clearfix-20 {
    display: block;
    clear: both;
    height: 20px;
  }

  #order-table {
    background-color: transparent;
    table {
      border: 0px;
      thead {
        background-color: $color-white;
        border: 0px;
        tr {
          height: 48px;
          th {
            font-family: $font-family-roboto-medium;
            font-size: $font-small;
            padding: 8px 0px 8px 20px;
            &:last-child {
              padding: 8px 20px 8px 0px;
            }
            &[data-sort-order='asc'] {
              color: $color-jungle-green;
            }
            &[data-sort-order='desc'] {
              color: $color-jungle-green;
            }
          }
        }
      }
      tbody {
        text-align: left;
        tr {
          &.canceled {
            opacity: 0.3
          }
          height: auto;
          font-size: $font-small;
          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            padding: 7px 0px 6px 20px;
            overflow: initial;
            vertical-align: middle;
            &.blue {
              color: $color-jungle-green;
            }
            &.red {
              color: $color-denim;
            }
            &:last-child {
              text-align: left;
              padding: 7px 20px 6px 0px;
            }
            .full_value_right {
              white-space: normal !important;
              position: absolute;
              top: 100%;
              left: -35px;
              color: black;
              background-color: $color-white;
              max-width: 100%;
              min-height: 30px;
              word-break: break-all;
              line-height: 20px;
              padding: 1px 5px;
              z-index: 5;
              border-radius: 3px;
              box-shadow: 0px 0px 5px rgba(88, 88, 88, 0.5);
              display: none;
            }
            &:hover {
              .full_value_right {
                display: block;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
    @import "@/assets/sass/variables";
    @import "@/assets/sass/common.scss";

    #order-table {
        background-color: transparent;
        table {
            margin-bottom: 22px;
        }
    }
</style>