<template>
  <div>
    <div class="address-box">
      <template v-if="!isAddNewAddress">
        <!-- <template v-if="enableWhiteListAndEmptyWhitlist">
          <span class="empty_whitelist"
            >{{ $t("funds.withdrawals.empty_whitelist.text") }}
            <router-link :to="{ name: 'Address Manager' }" target="_blank">
              {{
                $t("funds.withdrawals.empty_whitelist.address_management")
              }}</router-link
            >
          </span>
        </template> -->
        <template>
          <div class="inputAdr">
            <input
              type="text"
              class="form-control form-input"
              :maxlength="maxlength"
              v-model="address.blockchain_address"
              @focus="$emit('focus')"
              ref="input"
              :disabled="enableWhiteList"
              :class="{ error: errors.has('blockchain_address') }"
              :placeholder="
                enableWhiteListAndEmptyWhitlist && $t('withdrawal.place-holder')
              "
            />
            <p v-if="address.blockchain_address" class="fullAdr">
              {{ address.blockchain_address }}
            </p>
            <!-- <span
              class="btn-drop"
              @click="toggleSelectWithdrawAddresses()"
              :class="{ active_address: isTurnOnSelectWithdrawAddress }"
            >
              <i
                class="glyphicon icon-arrow1"
                :class="{ show_address: isTurnOnSelectWithdrawAddress }"
              ></i>
            </span> -->
          </div>
        </template>
        <p v-show="errors.has('blockchain_address')" class="invalid-feedback">
          {{ errors.first("blockchain_address") }}
        </p>

        <div class="option plusAddress-whitelist" v-if="enableWhiteList">
          <router-link :to="{ name: 'Address Manager' }" target="_blank">
            {{ $t("funds.withdrawals.empty_whitelist.address_management") }}
          </router-link>
        </div>
      </template>

      <template v-else>
        <input
          type="text"
          class="form-control form-input"
          disabled="disabled"
          :placeholder="$t('funds.withdrawals.add_new_address')"
          @focus="$emit('focus')"
        />
        <!-- <span
          class="btn-drop"
          @click="toggleSelectWithdrawAddresses()"
          v-click-outside="turnOffSelectWithdrawAddress"
          :class="{ active_address: isTurnOnSelectWithdrawAddress }"
        >
          <i
            class="glyphicon icon-arrow1"
            :class="{ show_address: isTurnOnSelectWithdrawAddress }"
          ></i>
        </span> -->
      </template>

      <div
        class="select"
        :class="{ active_address: isTurnOnSelectWithdrawAddress }"
      >
        <span class="option plusAddress" @click="addNewAddress()">
          <img src="@/assets/images/icon/icon-plus.svg" />
          {{ $t("funds.withdrawals.use_a_new_address") }}
        </span>

        <div
          class="option"
          v-for="withdrawAddress in withdrawAddresses"
          v-bind:key="withdrawAddress.id"
          @click="
            selectWithdrawAddress(
              withdrawAddress.wallet_address,
              withdrawAddress.wallet_sub_address
            )
          "
        >
          {{ withdrawAddress.wallet_name }}
          <span>
            {{ withdrawAddress.wallet_address.slice(0, 5) }}...{{
              withdrawAddress.wallet_address.slice(-4)
            }}</span
          >
          <span v-if="withdrawAddress.wallet_sub_address">{{
            withdrawAddress.wallet_sub_address
          }}</span>
          <div class="addHideWrapper">
            <span class="adressHide">{{ withdrawAddress.wallet_address }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="new-address" v-if="isAddNewAddress">
      <div class="wallet-name input-group">
        <input
          type="text"
          class="form-input"
          :maxlength="maxLength20"
          @focus="$emit('focus')"
          :class="{ error: isAddNewAddress && errors.has('wallet_name') }"
          :placeholder="$t('funds.withdrawals.withdrawal_placeholder_label')"
          v-model="address.wallet_name"
        />
        <p v-show="errors.has('wallet_name')" class="invalid-feedback">
          {{ errors.first("wallet_name") }}
        </p>
      </div>
      <div class="wallet-address">
        <input
          type="text"
          class="form-input"
          :maxlength="maxlength"
          @focus="$emit('focus')"
          :class="{
            error: isAddNewAddress && errors.has('blockchain_address'),
          }"
          :placeholder="$t('funds.withdrawals.withdrawal_placeholder_address')"
          v-model="address.blockchain_address"
        />
        <p v-show="errors.has('blockchain_address')" class="invalid-feedback">
          {{ errors.first("blockchain_address") }}
        </p>
      </div>
    </div>
    <!-- <div
      class="form-group xrp-tag"
      v-if="coin === 'xrp' || coin === 'eos' || coin === 'trx'"
      style="margin-top: 20px"
    >
      <label
        >{{ coin | uppercase }} {{ $t("funds.withdrawals.xrp_tag") }}</label
      >
      <label class="checkbox_nomemo"><input type="checkbox" @click="checkNoMemo($event.target.checked)"> {{ $t('funds.withdrawals.no_memo') }}</label>
      <input-destination-tag
        ref="blockchain_sub_address"
        class="xrp-input-withdrawTag"
        :disabled="enableWhiteList"
        v-model="address.blockchain_sub_address"
        :coin="coin"
        @focus="$emit('focus')"
      />
      <span v-show="errors.has('blockchain_sub_address')" class="f-left invalid-feedback">{{ errors.first('blockchain_sub_address') }}</span>
    </div> -->
  </div>
</template>

<script>
import Utils from "@/common/Utils";
import InputDestinationTag from "./InputDestinationTag";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";

export default {
  inject: ["$validator"],
  components: {
    InputDestinationTag,
  },
  data() {
    return {
      address: {},
      isAddNewAddress: false,
      isTurnOnSelectWithdrawAddress: false,
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      maxLength20: COMMON_CONST.MAX_LENGTH_20,
      isNoMemo: false,
    };
  },

  computed: {
    enableWhiteListAndEmptyWhitlist() {
      return this.enableWhiteList && this.withdrawAddresses.length === 0;
    },
  },

  props: {
    value: { type: Object, required: true },
    withdrawAddresses: { type: Array, required: true },
    coin: { type: String, required: true },
    enableWhiteList: { type: Boolean, default: false },
    networkId: { type: Number, required: false },
  },

  watch: {
    value: function (newVal) {
      if (_.isEmpty(newVal)) {
        return (this.address = {});
      }
      this.address = Object.assign({}, this.address, newVal);
    },
    address: {
      handler: function (newVal, oldVal) {
        const internalVal = JSON.stringify(newVal);
        const externalVal = JSON.stringify(this.value);
        if (internalVal === externalVal) {
          return;
        }
        this.$emit("input", newVal);
      },
      deep: true,
    },
    coin: function () {
      this.address = {};
      this.clearInternalErrors();
      this.turnOffSelectWithdrawAddress();
    },
    withdrawAddresses: function () {
      this.autoFillWithdrawAddress();
    },
  },

  methods: {
    checkNoMemo(e) {
      this.$emit("onIsNoMemo", e);
    },
    toggleSelectWithdrawAddresses() {
      // if (this.enableWhiteListAndEmptyWhitlist) {
      //   return;
      // }
      this.isTurnOnSelectWithdrawAddress = !this.isTurnOnSelectWithdrawAddress;
    },

    turnOffSelectWithdrawAddress() {
      this.isTurnOnSelectWithdrawAddress = false;
    },

    turnOnAddNewAddress() {
      this.isAddNewAddress = true;
      this.$emit("onAddNewAddress", this.isAddNewAddress);
    },

    turnOffAddNewAddress() {
      this.isAddNewAddress = false;
      this.$emit("onAddNewAddress", this.isAddNewAddress);
    },

    addNewAddress() {
      this.address = Object.assign({}, this.address, {
        blockchain_address: "",
        blockchain_sub_address: "",
      });
      this.clearInternalErrors();
      this.turnOnAddNewAddress();
      this.turnOffSelectWithdrawAddress();
    },

    selectWithdrawAddress(address, subAddress) {
      this.address = Object.assign({}, this.address, {
        blockchain_address: address || "",
        blockchain_sub_address: subAddress || "",
      });
      this.clearInternalErrors();
      this.turnOffAddNewAddress();
      this.turnOffSelectWithdrawAddress();
    },

    clearInternalErrors() {
      this.errors.remove("blockchain_address");
      this.errors.remove("wallet_name");
    },

    autoFillWithdrawAddress() {
      const address = _.head(this.withdrawAddresses) || {};
      this.address = Object.assign({}, this.address, {
        blockchain_address: address.wallet_address || "",
        blockchain_sub_address: address.wallet_sub_address || "",
      });
    },

    async validate() {
      if (this.enableWhiteListAndEmptyWhitlist) {
        return;
      }
      if (this.isAddNewAddress && _.isEmpty(this.address.wallet_name)) {
        this.errors.add(
          "wallet_name",
          this.$t("account.identity.errors.required")
        );
      }
      if (_.isEmpty(this.address.blockchain_address)) {
        this.errors.add(
          "blockchain_address",
          this.$t("account.identity.errors.required")
        );
      }
      //   if (this.coin === "xrp" || this.coin === "eos" || this.coin === "trx"
      // ) {
      //     this.$refs.blockchain_sub_address.validate();
      //   }
      if (this.coin === "xrp" || this.coin === "eos" || this.coin === "trx") {
        if (
          this.address.blockchain_address &&
          this.address.blockchain_sub_address &&
          !Utils.isWalletAddress(
            this.coin,
            this.address.blockchain_address,
            this.address.blockchain_sub_address
          )
        ) {
          this.errors.add(
            "blockchain_address",
            this.$t("funds.withdrawals.errors.invalid")
          );
        }
        if (this.isAddNewAddress) {
          this.withdrawAddresses.forEach((e) => {
            if (
              e &&
              e.wallet_address == this.address.blockchain_address &&
              e.wallet_sub_address == this.address.blockchain_sub_address
            ) {
              this.errors.add(
                "blockchain_address",
                window.i18n.t(
                  "funds.withdrawals.errors.blockchain_address_sub_address_exists"
                )
              );
              // this.errors.add('blockchain_sub_address', window.i18n.t('funds.withdrawals.errors.blockchain_address_sub_address_exists'));
            }
          });
        }
      } else {
        if (
          !Utils.isWalletAddress(this.coin, this.address.blockchain_address)
        ) {
          this.errors.add(
            "blockchain_address",
            this.$t("funds.withdrawals.errors.invalid")
          );
        }
        if (this.isAddNewAddress) {
          this.withdrawAddresses.forEach((e) => {
            if (e && e.wallet_address == this.address.blockchain_address) {
              this.errors.add(
                "blockchain_address",
                window.i18n.t(
                  "funds.withdrawals.errors.blockchain_address_exists"
                )
              );
            }
          });
        }
      }

      const isExistWithdraw = _.find(
        this.withdrawAddresses,
        (withdrawAddress) => {
          return (
            withdrawAddress.wallet_address ===
              this.address.blockchain_address &&
            withdrawAddress.wallet_sub_address ===
              this.address.blockchain_sub_address
          );
        }
      );
      if (this.isAddNewAddress && isExistWithdraw) {
        this.errors.add(
          "blockchain_address",
          window.i18n.t("funds.withdrawals.errors.exist_withdraw_address")
        );
      }

      return rf
        .getRequest("UserRequest")
        .validateBlockchainAddress(
          this.coin,
          this.address.blockchain_address.trim(),
          this.networkId
        )
        .then((res) => {
          if (!res.data) {
            this.errors.add(
              "blockchain_address",
              this.$t("funds.withdrawals.errors.invalid")
            );
            return false;
          }
          return true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
a {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: $color-caribbean-green !important;
  &:hover {
    text-decoration: underline;
  }
}
.form-input {
  box-shadow: none;
  // border: unset !important;
  border: 1px solid #efefef !important;
  // border: solid 1px $border-alto;
  padding-left: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  border-radius: 123px;

  // background: var(--bg-input);
  &:focus {
    border-color: $color-jungle-green;
  }
}

.active_address {
  background-color: var(--background-color-modal) !important;
  border: none !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: $color-bright-gray;
  }
}
.icon-arrow1 {
  &:before {
    color: $color-gray-dark;
  }
}

.invalid-feedback {
  color: $text-color-red;
  margin-top: 8px;
}

.dark-theme {
  input {
    border-color: #2d2e2f !important;
  }
}

.address-box {
  position: relative;
  width: 100%;
  input {
    width: 100%;
    background-color: transparent;
  }
  .inputAdr {
    border-radius: 123px;
    position: relative;

    .fullAdr {
      display: none;
    }
    &:hover {
      .fullAdr {
        display: block;
        position: absolute;
        background-color: $dark-1;
        padding: 8px;
        border-radius: 8px;
        color: $color-white;
        top: -40px;
      }
    }
  }
  .plusAddress-whitelist {
    margin-top: 12px;
    display: inline-block;
  }
  .select {
    z-index: 10;
    background: transparent;
    position: relative;
    display: block;
    width: 100%;
    margin-top: 8px;
    font-size: 14px;
    border-radius: 8px;
    .option {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-top: none;
      cursor: pointer;
      color: var(--text-primary-color);
      //font-size: $font-medium-bigger;
      font-size: $font-big;
      font-weight: 400;
      position: relative;
      display: flex;
      align-content: center;
      @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
      }
      .addHideWrapper {
        width: fit-content;
        position: absolute;
        top: calc(-100% - 12px);
        right: 0;
        border-radius: 8px;
        padding: 16px 8px;
        background-color: $dark-1;
        display: none;
        .adressHide {
          display: block;
          color: $color-white;
          text-align: center;
          font-size: 16px;
        }
      }
      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
      &:hover {
        .addHideWrapper {
          display: block;
        }
      }
    }
    .plusAddress {
      justify-content: flex-start;
      gap: 12px;
      color: $color-caribbean-green;
      font-size: 14px;
    }
  }
  .form-control {
    display: inline-block;
    height: 48px;
    line-height: normal;
    border: unset;
    border-radius: 0;
    outline: none;
    border-radius: 123px;
    line-height: 20px;
    color: var(--text-primary-color);
    box-shadow: unset;
  }
  .btn-drop {
    display: inline-block;
    float: right;

    border: unset;
    width: 45px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    background-color: var(--bg-input) !important;
    border-radius: 8px;
    cursor: pointer;
  }
  .show_address {
    transform: rotate(180deg);
  }
}

.new-address {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  width: 100%;

  input {
    background-color: transparent;
  }
  .form-control {
    display: inline-block;
    border: 1px solid $color-grey-border;
    border-radius: 0;
    height: 42px;
    outline: none;
    background: $color-white;
  }
  .wallet-name {
    width: 124px;
  }
  .wallet-address {
    width: 100%;
    // width: calc(100% - 15px - 162px);
  }
  .form-group {
    // input {
    //   background-color: var(--bg-input);
    //   border-radius: 8px;
    //   color: var(--text-primary-color);
    //   border: unset !important;
    // }
  }
  .sub-char {
    display: inline-block;
    width: 15px;
    height: 42px;
    line-height: 42px;
    text-align: center;
  }
  .error-box {
    width: 100%;
    margin: 5px 0;
    .is-error {
      display: inline-block;
    }
  }
}
.error-box {
  font-size: $font-smaller;
}
.empty_whitelist {
  padding: 10px 12px;
  font-size: 14px;
  color: #555555;
  width: calc(100% - 56px);
  display: inline-block;
  background: var(--bg-input);
  border: solid 1px var(--color-border-empty-input);
}

input {
  // &:focus,
  // &:active {
  //   border: 1px solid $color-green !important;
  // }
  font-size: 14px !important;
  &.error {
    border: 1px solid $text-warning !important;
  }
  &::placeholder {
    font-size: 14px !important;
  }
}
.checkbox_nomemo {
  float: right;
}
.xrp-tag {
  font-size: $font-small;
  label {
    font-weight: 400;
    font-size: 18px;
    color: var(--text-primary-color);
    margin-bottom: 8px;
  }
  .input-group {
    .form-input {
      // border: solid 1px var(--bg-input) !important;
    }
  }
  .xrp-input-withdrawTag {
    ::v-deep {
      .form-input {
        font-size: 18px;
        font-family: $font-inter-regular;
        padding: 0 15px;
        height: 50px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .new-address {
    height: auto;
    margin-bottom: 20px;

    .input-group {
      margin: 0;
    }
    .wallet-name {
      width: 40%;
    }
    .wallet-address {
      .form-input {
        &::placeholder {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  .form-group {
    padding: 10px;
  }
  .address-box {
    .form-control {
      &::placeholder {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .select {
      .option {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .input-group {
    .form-input {
      &::placeholder {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .form-group {
    padding: unset;
  }
}
@media screen and (max-width: 375px) {
  .address-box .inputAdr:hover .fullAdr {
    word-break: break-all;
    top: -60px;
  }
}
</style>
