<template>
    <div class="roe-detail">
      <div class="roe-con">
        <div class="roe-header" :class="'roe_header_'+$t('i18n.lang')">
          <div class="tit" v-if="roe_name ==='personal'"  v-html="$t('roe_team.header_txt.personal.tit')"></div>
          <div class="tit" v-if="roe_name ==='team'" v-html="$t('roe_team.header_txt.team.tit')"></div>
          <div class="btn-group">
            <!-- <div class="btn_team" @click="roeDetail('team')" :class="roe_name === 'team' ? 'active' :'' ">
              {{$t('roe_personal_team_slide.team_btn')}}</div> -->
            <div class="btn_team" @click="roeDetail('personal')" :class="roe_name === 'personal' ? 'active' :'' ">{{$t('roe_personal_team_slide.personal_btn')}}</div>
          </div>
          <!-- <div class=""></div> -->
          <div class="subtit" :class="'subtit_'+$t('i18n.lang')">
            <div class="subtit_con" v-if="roe_name ==='personal'" v-html="$t('roe_team.header_txt.personal.subtit')"></div>
            <div class="subtit_con" v-if="roe_name ==='team'" v-html="$t('roe_team.header_txt.team.subtit')"></div>
          </div>
        </div>
        <div class="team-con" v-show="roe_name === 'team'">
          <!-- 大纲 -->
          <div class="outline bg_black">
            <div class="outline-item" v-for="(item, index) in $t('roe_team.outline_list')" :key="index">
              <div class="maintit">{{item.tit}}：</div>
              <div class="subtit" v-html="item.subtit"></div>
            </div>
            <div class="outline-item">
              <div class="maintit">{{$t('roe_team.team_bonus.tit')}}：</div>
              <div class="subtit" v-html="$t('roe_team.team_bonus.money',{btc: '1,000'})"></div>
            </div>
          </div>
          <!-- 奖金 -->
          <div class="bonus bg_black">
            <div class="maintit">{{$t('roe_team.team_txt.teamAndAllMoney')}}</div>
            <div class="bonus_table">
              <div class="tab">
                <div class="tabtit">{{$t('roe_team.team_txt.roe_txt')}}</div>
                <div class="table_item">
                  <table border="1">
                    <tr>
                      <td>{{$t('roe_team.team_txt.team')}}</td>
                      <td>{{$t('roe_team.team_txt.all_money')}}</td>
                    </tr>
                    <tr v-for="(item, index) in roe_team_price" :key="index">
                      <td>{{item.person_num}} {{$t('roe_team.team_txt.teamtxt')}}</td>
                      <td>{{item.price_num / 2}} BTC</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="tab">
                <div class="tabtit">{{$t('roe_team.team_txt.trading_txt')}}</div>
                <div class="table_item">
                  <table border="1">
                    <tr>
                      <td>{{$t('roe_team.team_txt.team')}}</td>
                      <td>{{$t('roe_team.team_txt.all_money')}}</td>
                    </tr>
                    <tr v-for="(item, index) in roe_team_price" :key="index">
                      <td>{{item.person_num}} {{$t('roe_team.team_txt.teamtxt')}}</td>
                      <td>{{item.price_num / 2}} BTC</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="tab">
                <div class="tabtit">{{$t('roe_team.bonus_rules')}}</div>
                <div class="table_item">
                  <table border="1">
                    <tr>
                      <td>{{ $t('roe_acti_rule_ranking') }}</td>
                      <td>{{ $t('roe_team.bonus_rate') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $tc('roe_num_ranking_one',3) }}</td>
                      <td>70%</td>
                    </tr>
                    <tr>
                      <td>{{ $tc('roe_num_ranking_two',3) }}</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>{{ $tc('roe_num_ranking_three',3) }}</td>
                      <td>5%</td>
                    </tr>
                    <tr>
                      <td>{{ $tc('roe_num_ranking_four',3) }} </td>
                      <td>{{$t('roe_num_ranking_rate',{btc:'2.1%'})}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="tab tab_txt">
                <div class="maintit">{{$t('roe_team.bonus_dis_demo_time.tit')}}</div>
                <div class="subtit" v-html="$t('roe_team.bonus_dis_demo_time.subtit')"></div>
              </div>
              <div class="tab tab_txt mt30" v-for="(item, index) in $t('roe_team.bonus_dis_demo')" :key="index">
                <div class="maintit">{{item.tit}}</div>
                <div class="subtit" v-html="item.subtit"></div>
              </div>
              
             
            </div>
          </div>
          <!-- 竞赛补充事项 -->
          <div class="supplement bg_black">
            <div class="maintit">{{$t('roe_team.complement.tit')}}：</div>
            <ul>
              <li v-for="(item, index) in $t('roe_team.complement.list')" :key="index">{{item}}</li>
            </ul>
            <div class="maintit">{{$t('roe_team.cancel.tit')}}：</div>
            <ul>
              <li v-for="(item, index) in $t('roe_team.cancel.list')" :key="index">{{item}}</li>
            </ul>
          </div>
        </div>
        <div class="personal-con" v-show="roe_name === 'personal'">
          <!-- 大纲 -->
          <div class="outline bg_black">
            <div class="outline-item" v-for="(item, index) in $t('roe_competition_conten')" :key="index">
              <div class="maintit">{{item.tit}}：</div>
              <div class="subtit" v-html="item.desc"></div>
            </div>
            <!-- <div class="outline-item">
                    <div class="maintit">{{$t('roe_competition_conten.tit')}}</div>
                    <div class="subtit" v-for="(item, index) in $t('roe_competition_conten.con')" :key="index">
                      <div class="tit" v-if="item.tit">{{item.tit}}：</div>
                      <div class="sub" v-html="item.desc"></div>
                    </div>
                  </div> -->
          </div>
          <!-- 参赛队伍和总奖金 -->
          <div class="bonus bg_black">
            <div class="maintit">{{ $t('roe_acti_table') }}</div>
            <div class="bonus_table">
              <div class="tab">
                <div class="tabtit">{{ $t('roe_acti_table') }}</div>
                <div class="table_item">
                  <table border="1">
                    <tr>
                      <td>{{ $t('roe_acti_table_person') }}</td>
                      <td>{{ $t('roe_acti_table_money') }}</td>
                      <td>{{ $t('roe_acti_table_person') }}</td>
                      <td>{{ $t('roe_acti_table_money') }}</td>
                    </tr>
                    <tr v-for="(item, index) in roe_num_price" :key="index">
                      <td>{{ $tc('roe_person_info', item.num_type_1,{num:item.num_1}) }}</td>
                      <td>{{ $tc('roe_person_info', 3,{num:item.price_num_1}) }}</td>
                      <td>{{ $tc('roe_person_info', item.num_type_2,{num:item.num_2}) }}</td>
                      <td>{{ $tc('roe_person_info', 3,{num:item.price_num_2}) }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!-- 赏金分配规则 -->
            <div class="rules">
              <p class="maintit">{{ $t('roe_acti_rule') }}</p>
              <div class="roe_ranking_list">
                <div class="roe_ranking_l">
                  <table>
                    <tr>
                      <td>{{ $t('roe_acti_rule_ranking') }}</td>
                      <td>{{ $t('roe_acti_rule_rate') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $tc('roe_num_ranking_one',0) }}</td>
                      <td>70%</td>
                    </tr>
                    <tr>
                      <td>{{ $tc('roe_num_ranking_two',0) }}</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>{{ $tc('roe_num_ranking_three',0) }}</td>
                      <td>5%</td>
                    </tr>
                    <tr>
                      <td>{{ $tc('roe_num_ranking_four',0) }} </td>
                      <td>{{$t('roe_num_ranking_rate',{btc:'2.1%'})}}</td>
                    </tr>
                  </table>
                </div>
                <div class="roe_ranking_r">
                  <!-- <span class="one">NO.1
                          <br />70%</span>
                        <span class="two">NO.2
                          <br />10%</span>
                        <span class="three">NO.3
                          <br />5%</span>
                        <span class="four">NO.4~10
                          <br /> 2.1%</span> -->
                </div>
              </div>
            </div>
  
            <div class="roe_ranking_table">
              <table>
                <tr class="roe_ranking_table_tit">
                  <td colspan="5">
                    <div class="con">{{ $t('roe_act_money_time_table',{person:500,btcNum:1}) }}</div>
                  </td>
                  <td colspan="5">
                    <div class="con">{{ $t('roe_act_money_time_table',{person:'10,000',btcNum:10}) }}</div>
                  </td>
                </tr>
                <tr>
                  <slot>
                    <td>{{ $t('roe_acti_rule_ranking') }}</td>
                    <td>{{ $tc('roe_num_ranking_one',1) }}</td>
                    <td>{{ $tc('roe_num_ranking_two',1) }}</td>
                    <td>{{ $tc('roe_num_ranking_three',1) }}</td>
                    <td>{{ $tc('roe_num_ranking_four',1) }}</td>
                  </slot>
                  <slot>
                    <td>{{ $t('roe_acti_rule_ranking') }}</td>
                    <td>{{ $tc('roe_num_ranking_one',1) }}</td>
                    <td>{{ $tc('roe_num_ranking_two',1) }}</td>
                    <td>{{ $tc('roe_num_ranking_three',1) }}</td>
                    <td>{{ $tc('roe_num_ranking_four',1) }}</td>
                  </slot>
                </tr>
                <tr>
                  <td>{{ $t('roe_acti_rule_reward') }}</td>
                  <td>0.7 BTC</td>
                  <td>0.1 BTC</td>
                  <td>0.05 BTC</td>
                  <td>{{$t('roe_num_ranking_rate',{btc:'0.021 BTC'})}}</td>
                  <td>{{ $t('roe_acti_rule_reward') }}</td>
                  <td>7 BTC</td>
                  <td>1 BTC</td>
                  <td>0.5 BTC</td>
                  <td>{{$t('roe_num_ranking_rate',{btc:'0.21 BTC'})}}</td>
                </tr>
              </table>
            </div>
            <p class="common_txt" v-html="$t('roe_act_money_time',{day:20,day_m:10})"></p>
          </div>
          <!-- 竞赛补充事项 -->
          <div class="supplement bg_black">
            <div class="maintit">{{ $t('roe_act_desc_tit') }}</div>
            <ul>
              <li v-for="(item, index) in $t('roe_act_desc_list')" :key="index">{{item}}</li>
            </ul>
            <!-- 取消参赛资格 -->
            <p class="maintit">{{ $t('roe_act_desc_cancel') }}</p>
            <ol class="roe_list num_list">
              <li> {{ $t('roe_act_desc_cancel_list1',{btc:0.02}) }}</li>
              <!-- <li>②{{ $t('roe_act_desc_cancel_list3') }}</li> -->
              <li> {{ $t('roe_act_desc_cancel_list4') }}</li>
            </ol>
          </div>
          <!-- 风险警告 -->
          <div class="warn">{{ $t('roe_act_warn') }}</div>
        </div>
  
      </div>
      <div class="roe-footer"></div>
    </div>
  
  </template>
  <script>
    import moment from 'moment';
    export default {
      data() {
        return {
          roe_name: 'personal',
          roe_num_price: [{
            num_type_1: 0,
            num_1: '20~1,999',
            price_num_1: 1,
            num_type_2: 0,
            num_2: '6,000~6,999',
            price_num_2: 6
          }, {
            num_type_1: 0,
            num_1: '2,000~2,999',
            price_num_1: 2,
            num_type_2: 0,
            num_2: '7,000~7,999',
            price_num_2: 7
          }, {
            num_type_1: 0,
            num_1: '3,000~3,999',
            price_num_1: 3,
            num_type_2: 0,
            num_2: '8,000~8,999',
            price_num_2: 8
          }, {
            num_type_1: 0,
            num_1: '4,000~4,999',
            price_num_1: 4,
            num_type_2: 0,
            num_2: '9,000~9,999',
            price_num_2: 9
          }, {
            num_type_1: 0,
            num_1: '5,000~5,999',
            price_num_1: 5,
            num_type_2: 1,
            num_2: '10,000',
            price_num_2: 10
          }],
          roe_team_price: [{
            person_num: '10〜99',
            price_num: 2
          }, {
            person_num: '100〜499',
            price_num: 20
          }, {
            person_num: '500〜999',
            price_num: 100
          }, {
            person_num: '1,000',
            price_num: 200
          }],
          roe_act_time_range_en: {
            fromTime: '2020/3/23',
            toTime: '2020/4/6',
            allDay: 2
          },
          roe_act_time_range_ja: {
            fromTime: '2020年3月23日',
            toTime: '2020年4月6日',
            allDay: '2'
          }
        }
      },
      mounted() {
        let name = this.$route.query.name;
        if (!!name) {
          this.roe_name = name;
        } else {
          this.$router.push({ query: { name: this.roe_name } });
        }
        console.log('monuted', !!name)
      },
      watch: {
        $route() {
          this.roe_name = this.$route.query.name;
        },
      },
      methods: {
        roeDetail(name) {
          this.$router.push({ query: { name: name } })
        }
      },
    }
  </script>
  <style lang="scss" scoped>
    @import "@/assets/sass/variables";
    $min-w1026: 1026px;
    $min-w920: 1026px;
    $max-w: 80%;
    $bc: #ccc;
    $pl_16: 16px;
    $pl_12: 12px;
    $bg: #2DAC91;
    $linecolor-light:#2DAC91;
    $linecolor-dark:#669C35;
    $bg: #120426;
    $f24: 24px;
    $f32: 30px;
    $f20: 20px;
    $f18: 18px;  
    $f16: 16px;    
    $f14: 14px;  
    $f12: 12px;
    $f10: 10px;
    $br: 10px;
    .roe-detail {
      width: 100%;
      min-width: $page-width;
      background-color: $bg;
      height: auto;
      position: relative;
      overflow: hidden;
      clear: both;
      color: $pure_color_white;
      z-index: 0;
      &::after {
        width: 100%;
        height: calc(100% - 436px);
        position: absolute;
        left: 0px;
        top: 436px;
        content: '';
        background: url('@/assets/images/roe/bot-bg.png') repeat;
        background-size: auto auto;
      }
      &::before {
        width: 100%;
        height: 436px;
        position: absolute;
        left: 0px;
        top: 0px;
        content: '';
        background: url('@/assets/images/roe/top-bg.png') repeat;
        background-size: auto 100%;
      }
      .roe-con {
        margin: 0px auto;
        width: $min-w920;
        height: 100%;
        position: relative;
        z-index: 1;
        &::after {
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0px;
          top: 436px;
          content: '';
          background: url('@/assets/images/roe/con-bg.png') no-repeat;
          background-size: 100% auto;
        }
        &::before {
          width: 250px;
          height: 310px;
          position: absolute;
          right: 0px;
          top: 774px;
          content: '';
          background: url('@/assets/images/roe/rocket.png') no-repeat;
          background-size: 100% 100%;
          z-index: 4;
        }
        .roe-header {
          padding-top: 70px;
          height: 425px;
          position: relative;
          &::after {
            width: 300px;
            height: 260px;
            position: absolute;
            content: '';
            top: -96px;
            right: -96px;
            background: url('@/assets/images/roe/header_bg_1.png') no-repeat;
            background-size: 100% 100%;
          }
          &::before {
            width: 340px;
            height: 310px;
            position: absolute;
            content: '';
            top: -64px;
            right: 116px;
            background: url('@/assets/images/roe/header_bg_2.png') no-repeat;
            background-size: 100% 100%;
          }
          .tit {
            /* margin-top: px; */
            width: 580px;
            font-size: $f32;
            font-weight: 600;
            color: $color-orange-red;
            text-shadow: $orange-text-shadow;
            position: relative;
            text-transform: uppercase;
            font-family: $font-family-adobe;
          }
          .btn-group {
            display: flex;
            flex-direction: row;
            margin: 48px 0px 100px 0px;
            div {
              font-size: 10px;
              width: 124px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              border-radius: 30px;
              margin-right: 15px;
              cursor: pointer;
              background-color: $color-green-light;
              font-family: $font-family-adobe;
            }
            .active {
              background-color: $color-orange-red;
              /* line-height: 33px; */
            }
            div:hover {
              background-color: $color-orange-red;
              transition: background-color .5s;
            }
          }
          .subtit {
            width: 860px;
            height: 66px;
            float: right;
            font-weight: 500;
            background: url('@/assets/images/roe/header-subtit.png') no-repeat;
            background-size: 100% 100%;
            position: relative;
            padding-left: 84px;
            font-size: $f12;
            .subtit_con {
              width: 64%;
              height: 100%;
              align-items:center; 
              display: -webkit-flex;
              /* justify-content:center;  */
              font-family: $font-family-adobe;
              display: -webkit-flex;
            }
          }
          .subtit_zh {
            width: 870px;
            height: 70px;
            font-size: 13px;
          }
        }
        .maintit {
          color: $color-orange-red;
          font-size: $f18;
          padding-left: $pl_12;
          position: relative;
          font-family: $font-family-adobe;
          &::after {
            width: 5px;
            height: 24px;
            background-color: $color-orange;
            position: absolute;
            left: 0px;
            top: 50%;
            content: '';
            transform: translateY(-50%);
          }
        }
        .team-con {
          z-index: 2;
          margin-top: 40px;
          position: relative;
        }
        .bg_black {
          height: auto;
          border-radius: 10px;
          background-color: rgba(0, 0, 0, .5);
          margin-top: 20px;
          padding: 20px 40px 20px 28px;
          z-index: 3;
        }
        .outline .outline-item {
          margin-bottom: 20px;
          .subtit {
            padding-left: $pl_12;
            font-size: $f14;
            line-height: 28px;
            font-family: $font-family-adobe;
            .tit {
              color: $color-orange-red;
              font-weight: 600;
              margin-top: 10px;
            }
          }
        }
        .bonus_table {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 80px;
          .tab {
            width: 45%;
            margin: 20px 0px;
            .tabtit {
              color: $color-orange;
              font-size: $f18;
              text-align: center;
              margin-bottom: 20px;
              font-family: $font-family-adobe;
            }
            .table_item {
              background-color: $orange-red-opacity;
              border-radius: 10px;
              overflow: hidden;
              border: 1px solid $color-orange-red;
              box-shadow: $border-orange-shadow-weigh;
              table {
                width: 101%;
                height: 101%;
                margin: 0px auto;
                border-width:0px;
                tr td,
                th {
                  text-align: center;
                  padding: 10px 0px;
                  width: 50%;
                }
                tr {
                  border-bottom-color: $color-orange-red;
                  box-shadow: $border-orange-shadow;
                  &:last-child td,
                  &:last-child {
                    border-bottom-color: transparent;
                    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
                  }
                  &:first-child {
                    border-top-color: transparent;
                  }
                }
               
                td:nth-child(2n-1) {
                  border-right-color: $color-orange-red;
                  border-left-color: transparent;
                  box-shadow: $border-orange-shadow;
                }
              }
            }
  
          }
          .tab_txt {
            background-color: $orange-red-opacity;
            border-radius: $br;
            padding: 20px;
            position: relative;
            margin-top: 64px;
            .maintit {
              color: $pure_color_white;
              font-size: $f16;
              font-family: $font-family-adobe;
            }
            .subtit {
              padding-left: $pl_12;
              font-size: $f12;
              line-height: 28px;
              margin-top: 4px;
              font-family: $font-family-roboto;
            }
          }
          .mt30 {
            margin-top: 40px;
          }
        }
        .supplement {
          ul {
            list-style-type: disc;
            margin: 5px 0px 20px 26px;
            li {
              font-size: $f14;
              line-height: 26px;
              font-family: $font-family-roboto;
            }
          }
        }
  
        .personal-con {
          position: relative;
          z-index: 3;
          .bonus_table .tab {
            width: 80%;
            margin: 0px auto;
            table {
              tr:last-child td{
                border-bottom-color: transparent;
              }
              td:nth-child(2n) {
                border-right-color: $color-orange-red;
                /* box-shadow: $border-orange-shadow; */
              }
              td:nth-child(2n-1),
              td:last-child {
                border-right-color: transparent !important;
                box-shadow: 0px 0px 0px rgba(0,0,0,0);
              }
            }
            .table_item {
              td {
                width: 25%;
              }
            }
          }
          .rules {
            margin: 80px 0px;
            .roe_ranking_list {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              width: 97%;
              margin: 0px auto;
              .roe_ranking_l {
                td {
                  padding: 10px 48px;
                  font-size: $f12;
                  white-space: nowrap;
                  text-align: center;
                  font-family: $font-family-adobe;
  
                }
                td:nth-child(2n) {
                  /* text-align: right; */
                }
              }
              .roe_ranking_r {
                width: 350px;
                height: 190px;
                background-image: url('@/assets/images/rebate/ranking.png');
                background-size: 100% 100%;
                margin-left: 8%;
                position: relative;
                cursor: default;
                span {
                  position: absolute;
                  bottom: 7%;
                  text-align: center;
                  font-family: $font-family-adobe;
                  color: rgba(252, 252, 252, 1);
                }
                .one {
                  left: 31.8%;
                  bottom: 12%;
                  font-size: 24px;
                }
                .two {
                  font-size: $f14;
                  left: 10%;
                }
                .three {
                  left: 58%;
                  font-size: $f12;
                }
                .four {
                  font-size: 9px;
                  right: 10%;
                }
              }
  
            }
          }
          .roe_ranking_table {
            /* margin: 34px auto 20px; */
            table {
              background: $orange-red-opacity;
              margin-bottom: 20px;
              border-radius: 10px;
              tr {
                border-top-width: 0px;
                border-bottom: 1px solid $color-orange-red;
              }
              tr:last-child {
                border-bottom-width: 0px;
              }
              td {
                /* border: 1px solid $color-orange-red; */
                width: 10%;
                box-sizing: border-box;
                overflow: hidden;
                padding: 10px 5px;
                font-family: $font-family-adobe;
                text-align: center;
                border-right: none;
                color: #fff;
                font-size: $f14;
                border-right: 1px solid $color-orange-red;
              }
              td:last-child {
                border-right-width: 0px;
              }
              .roe_ranking_table_tit td .con {
                display: inline-block;
                margin: 0px auto;
                max-width: 380px;
                font-size: $f12;
                font-family: $font-family-adobe;
              }
            }
  
          }
          .supplement ol {
            margin: 5px 0px 20px 26px;
            li {
              font-size: $f14;
              line-height: 26px;
              font-family: $font-family-adobe;
            }
          }
          .warn {
            font-size: $f12;
            color: $color-orange-red;
            background-color: $orange-red-opacity;
            padding: 20px 30px;
            border-radius: $br;
            font-family: $font-family-adobe;
          }
        }
      }
      .roe-footer {
        width: $min-w1026;
        height: 908px;
        margin: 300px auto 0px;
        background: url('@/assets/images/roe/roe-footer.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        &::before {
          width: 365px;
          height: 495px;
          position: absolute;
          right: 0px;
          bottom: 140%;
          z-index: 4;
          content: '';
          /* background: url('@/assets/images/roe/alien.png') no-repeat; */
          background-size: 100% 100%;
        }
      }
    }
  </style>