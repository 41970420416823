<template>
  <div id="tab_profit_loss">

    <div class="header_tab_content clearfix">
      <div class="fl-left txt-Side">{{ $t('margin.side') }}:</div>
      <label class="switch box_switch" v-bind:class="{active: isShort}" @click="onClick">
        <div class="txt-long">{{ $t('margin.long') }}</div>
        <span>
          <i></i>
        </span>
        <div class="txt-short">{{ $t('margin.short') }}</div>
      </label>
    </div>

    <div class="body_tab_content clearfix">
      <div class="col-6">
        <div class="form-group form-inline">
          <label for="entryPrice">{{ $t('margin.leverage') }}</label>
          <!-- <input type="number" class="form-control valid" step=""> -->
          <currency-input :maxLengthCus='4' :key='index' :ref="refCurrencyInput" :precision="2" class="form-control valid" v-model="leverage" @input="handleChange"/>
        </div>
        <div class="clearfix"></div>
        <div class="form-group form-inline">
          <label for="entryPrice">{{ $t('margin.entry_price') }}</label>
          <!-- <input type="number" class="form-control valid" step=""> -->
          <currency-input :maxLengthCus='16' :key='index' :ref="refCurrencyInput" restrictStepPrice="true" :precision="decimal" class="form-control valid" v-model="entryPrice" @input="handleChange"/>
        </div>
        <div class="clearfix"></div>
        <div class="form-group form-inline">
          <label for="entryPrice">{{ $t('margin.roe_percent') }}</label>
          <!-- <input type="number" class="form-control valid" step=""> -->
          <currency-input :maxLengthCus='16' :key='index' :ref="refCurrencyInput" :precision="2" class="form-control valid" v-model="roe" @input="handleChange"/>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="col-6">
        <div class="table-container table-calculatorOutput">
          <data-table ref="datatable" :getData="getData">
            <template slot="body" slot-scope="props">
              <tr>
                <td class="text-left">{{ props.item.name }}</td>
                <td v-if="!props.item.name.includes('%')" class="text-right font-bold">
                  <div class="box_value_right clearfix">
                    <div class="value_right">{{ props.item.value | formatMarginNumber }}</div>
                    <div class="full_value_right">{{ props.item.value | formatMarginNumber }}</div>
                  </div>
                </td>
                <td v-else class="text-right font-bold">
                  <div class="box_value_right clearfix">
                    <div class="value_right">{{ props.item.value | formatMarginNumber(2) }}%</div>
                    <div class="full_value_right">{{ props.item.value | formatMarginNumber(2) }}%</div>
                  </div>
                </td>
              </tr>
            </template>
          </data-table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Utils from '@/common/Utils'
  import BigNumber from 'bignumber.js';
  import MarginCalculator from './MarginCalculator.js'
  import CurrencyInput from "./CurrencyInput.vue";
  import { mapGetters } from 'vuex';

  export default {
    components: {
      CurrencyInput
    },
    data() {
      return {
        isShort: false,

        leverage: 0,
        entryPrice: 0,
        roe: 0,

        targetPrice: 0,
        pnlPercent: 0,
        index: 0,
        refCurrencyInput: this.getRefUniqueId(),
      }
    },
    watch: {
      isShort() {
        this.handleChange();
      },
      currentSymbol() {
        this.$refs[this.refCurrencyInput].setPrecision(this.precisionPrice());
        this.index++;
      }
    },
    computed: {
      ...mapGetters(['currentInstrument', 'currentSymbol']),
      decimal() {
        if (this.currentInstrument && this.currentInstrument.tick_size) {
          return Utils.getPrecisionNumber(this.currentInstrument.tick_size);
        }
        return 1;
      },
    },
    methods: {
      getRefUniqueId() {
        return window._.uniqueId('input-coin-number-');
      },
      handleChange() {
        let quantity = 1;
        if (this.isShort) {
          quantity = -1;
        }
        const entryValue = MarginCalculator.getContractValue(this.entryPrice, quantity);
        const margin =  new BigNumber(`${entryValue || 0}`).dividedBy(`${this.leverage || 0}`).toString();
        this.pnlPercent = MarginCalculator._roe2pnlPercent(this.roe, entryValue, margin);
        const pnl = MarginCalculator._roe2pnl(this.pnlPercent, entryValue);
        const targetValue = new BigNumber(`${entryValue || 0}`).add(`${pnl || 0}`).toString();
        this.targetPrice = MarginCalculator.getContractPrice(targetValue, quantity);
        this.$refs.datatable.refresh();
      },
      getData (params) {
        return new Promise(resolve => {
          return resolve(
            [ 
              {name: window.i18n.t('margin.entry_price'), value: this.entryPrice,},
              {name: window.i18n.t('margin.target_price'), value: this.targetPrice,},
              {name: window.i18n.t('margin.profit_loss_percent'), value: this.pnlPercent,},
              {name: window.i18n.t('margin.roe_percent'), value: this.roe,},
            ]
          );
        });
      },
      onClick() {
        this.isShort = !this.isShort;
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .text-left{
    color: $color-grey-dark;
  }
  .box_value_right {
    .value_right {
      display: block;
      width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      text-align: right;
    }
    .full_value_right {
      position: absolute;
      right: 30px;
      min-width: 100px;
      background-color: $color-white;
      line-height: 20px;
      padding: 1px 5px;
      z-index: 5;
      border-radius: 3px;
      box-shadow: 0px 0px 5px rgba(88, 88, 88, 0.5);
      display: none;
    }
    &:hover {
      .full_value_right {
        display: block;
      }
    }
  }
  #tab_profit_loss {
    .header_tab_content {
      background-color: $color-bright-gray;
      padding: 6px 30px 2px 30px;
      line-height: 20px;
      .txt-Side {
        font-size: $font-small;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
      }
      .box_switch {
        display: inline-block;
        float: right;
        margin: 0px;
        cursor: pointer;
        .txt-long {
          text-align: center;
          display: inline-block;
          float: left;
          margin-right: 25px;
          min-width: 65px;
          letter-spacing: 0.9px;
          font-size: $font-smaller;
          font-weight: 500;
          font-family: $font-family-roboto-bold;
          text-transform: uppercase;
          color: $color-jungle-green;
        }
        .txt-short {
          text-align: center;
          display: inline-block;
          float: right;
          margin-left: 25px;
          min-width: 45px;
          letter-spacing: 0.9px;
          font-size: $font-smaller;
          font-weight: 500;
          font-family: $font-family-roboto-bold;
          text-transform: uppercase;
          color: $color-grey-dusty;
        }
        span {
          float: left;
          width: 23px;
          height: 14px;
          background-color: $color-jungle-green;
          border-radius: 7.5px;
          top: 3px;
          display: inline-block;
          padding: 0px 6px 0 9px;
          position: relative;
          color: $color-white;
          line-height: 24px;
          text-align: right;
          i {
            left: 2px;
            top: 2px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: $color-white;
            position: absolute;    
            display: inline-block;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          }
          &:before,
          &:after {
            opacity: 0;
            font-size: 0px;
          }
        }
        &.active span i {
          left: 10px;
        }
        &.active span {
          background-color: $color-denim;
        }
        &.active .txt-short {
          color: $color-denim !important;
        }
        &.active {
          .txt-long {
            color: $color-grey-dusty;
          }
          .txt-short {
            color: $color-denim;
          }
        }
      }
    }
  }
  .body_tab_content {
    padding: 18px 0px;
    .col-6 {
      width: calc(50% - 10px);
      float: left;
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
        margin-left: 10px;
      }
    }

    .form-group {
      margin: 0px;
      margin-bottom: 5px;
      display: block;
      clear: both;
      float: left;
      width: 100%;
      >label {
        width: 75px;
        font-size: $font-small;
        color: $color-grey-dark;
        font-weight: 500;
        line-height: 25px;
        padding: 5px 0px;
        margin: 0px;
        margin-right: 0px;
        float: left;
      }
      >input {
        height: 35px;
        border-radius: 3px;
        border: solid 1px $color-alto;
        width: calc(100% - 75px);
        float: right;
        font-size: $font-small;
        font-family: $font-family-roboto-bold;
        line-height: 25px;
        padding: 4px 5px 4px 12px;
        box-shadow: none;
        &:focus {
          border: 1px solid $color-jungle-green;
        }
      }
    }

    .table-calculatorOutput {
      border: solid 1px $color-alto;
      background-color: $color-alabaster;
      border-radius: 3px;
      tr {
        height: 28px;
        border-bottom: solid 1px $color-alto;
        &:last-child {
        border-bottom: none;
        }
        td {
          overflow: initial;
          font-size: $font-small;
          padding: 5px 3px 3px 15px;
          &:last-child {
            padding: 5px 10px 3px 3px;
          }
        }
      }
      .font-bold {
        font-family: $font-family-roboto-bold;
      }
    }
  }
</style>