 import rf from '@/request/RequestFactory';

const state = {
  masterAccount: undefined
}

const actions = {
  getMasterAccount({ commit, dispatch, state }, params) {
    if (state.masterAccount) {
      return new Promise(resolve => resolve(state.masterAccount));
    }

    return new Promise((resolve, reject) => {
      rf.getRequest("MamRequest").getMasterAccount()
        .then(res => {
          commit('updateMamMasterAccount', res.data);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        })
    });
  },

}

const getters = {
  masterAccount: state => {
    return state.masterAccount
  }
}

const mutations = {
  updateMamMasterAccount: (state, payload) => {
    state.masterAccount = payload
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
