 import rf from '@/request/RequestFactory';
import BigNumber from 'bignumber.js';
import Utils from '@/common/Utils';
import Const from '@/common/Const';

const actions = {
  getInstrument({ commit, state }, params) {
    rf.getRequest('MarginRequest').getInstrument()
      .then(res => {
        const response = res.data && Array.isArray(res.data) ? res.data : []
        response.map(el=>el.contract_value = Utils.getUnit('contract_value', el));
        const filtedResponse = _.filter(response, (item) => item.state && item.state.toLowerCase() == 'open');
        const sorted = window._.chain(filtedResponse).orderBy(['id'], ['asc']).value();
        const symbol = sorted && sorted[0] && sorted[0].symbol ? sorted[0].symbol : Const.MARGIN_DEFAULT_SYMBOL;
        commit('setInstruments', sorted);
        commit('setAllInstrument', response);
        commit('saveAllContractName', sorted);
        if(!state.currentSymbol && (params && !params.currentSymbol)) commit('setCurrentSymbol', symbol)
      });
  },
  setCurrentSymbol({ commit }, symbol) {
    commit('setCurrentSymbol', symbol);
  },
  getListRiskLimit({ commit, state }, params) {
    rf.getRequest('MarginRequest').getListRiskLimit(params)
      .then(res => {
        const response = res.data && Array.isArray(res.data) ? res.data : [];
        commit('setRiskLimits', response);
      });
  },
};

const getters = {
  /* get the instrument without sort*/
  instrument: state => {
    return state.instrument;
  },
  /* get the all the instruments without sort*/
  allInstrument: state => {
    return state.allInstrument;
  },
  /* find a contract in all instrument list by symbol */
  allInstrumentBySymbol: (state) => (symbol=null) => {
    if(!symbol) symbol = state.currentSymbol
    const current = state.allInstrument.find(el=>el.symbol == symbol)
    return current
  },
  /* get the instrument list after ordered by root symbol and id */
  instruments: state => {
    try{
        if(state.instrument && state.instrument.length > 0){
          return window._.chain(state.instrument).orderBy(['id'], ['asc']).groupBy("root_symbol").map((key, value)=>({rootSymbol: value, child: key})).value();
        }
        return {}
    }catch(err){
      console.error(err);
      return {}
    }
  },
  /* get instrument list by root symbol */
  childInstrumentByRootSymbol: (state, getters, rootState, rootGetters) => (rootSymbol=null) => {
    try{
      
        if(state.instrument && state.instrument.length > 0){
          const instrumentTree = window._.chain(state.instrument).orderBy(['id'], ['asc']).groupBy("root_symbol").map((key, value)=>({rootSymbol: value, child: key})).value();
          if(!rootSymbol) rootSymbol = instrumentTree[0].rootSymbol
          // console.log('getters childInstrumentByRootSymbol', instrumentTree);
          return instrumentTree.find(el=>el.rootSymbol == rootSymbol)
        }

        return {}
    }catch(err){
      console.error(err);
      return {}
    }
  },
  currentSymbol: state => {
    return state.currentSymbol;
  },
  /* get current instrument by current symbol */
  currentInstrument: state => {
    const currentInstrument = state.instrument && state.instrument.find(item => item.symbol === state.currentSymbol);
    return currentInstrument ? currentInstrument : {}
  },
  /* get current instrument by current symbol */
  /* another name */
  contract: (state, getters) => {
    return getters.currentInstrument;
  },
  /* find instrument in instrument list by symbol */
  instrumentBySymbol: (state) => (symbol=null) => {
    if(!symbol) symbol = state.currentSymbol
    const current = state.instrument.find(el=>el.symbol == symbol)
    return current
  },
  instrumentUsingThisIndex: (state, getters) => ({symbol}) => {
    const i = state.instrument.filter(el=>el.reference_index == symbol && el.symbol != symbol)
    return (i && i.length > 0) ? i : []
  },
  riskLimits: state => {
    return state.riskLimits;
  }
}

const mutations = {
  setRiskLimits: (state, payload) => {
    state.riskLimits = payload;
  },
  setInstruments: (state, payload) => {
    if(!Array.isArray(payload)) payload = []
    state.instrument = payload.map(item => {
        item.last_change = 0;
        if (item.extra) {
            for (let field in item.extra) {
                item[field] = item.extra[field];
            }
            delete item.extra;
        }
        return item;
    });
  },
  setAllInstrument: (state, payload) => {
    if(!Array.isArray(payload)) payload = []
    state.allInstrument = payload.map(item => {
        item.last_change = 0;
        if (item.extra) {
            for (let field in item.extra) {
                item[field] = item.extra[field];
            }
            delete item.extra;
        }
        return item;
    });
  },
  setCurrentSymbol: (state, payload) => {
    state.currentSymbol = payload;
  },
  updateCurrentInstrument: (state, payload) => {
    if(!payload.symbol) payload.symbol = state.currentSymbol
    const currentInstrument = state.instrument.find(el=>el.symbol == payload.symbol)
    if(state.instrument && Array.isArray(state.instrument))
      state.instrument = window._.unionBy(state.instrument, [currentInstrument], "symbol");
  },
  updateInstrument: (state, payload) => {
    try{

      const instrument = state.instrument.find(el => el.symbol === payload.symbol);
      if (instrument) {
        for (let field in payload.data) {
          if (field === 'last_price' && instrument.last_price) {
            const last_change = new BigNumber(payload.data.last_price).minus(instrument.last_price).toString();
            if(last_change != 0) instrument.last_change = last_change;
          }
          instrument[field] = payload.data[field];
        }
      }

    }catch(err){
      console.error(err)
    }
  },
  updateInstrumentLastPrice: (state, payload) => {
    const trade = payload[payload.length - 1];
    const instrument = state.instrument.find(el => el.symbol === trade.instrument_symbol);
    if (instrument) {
      if (instrument.last_price) {
        instrument.last_change = new BigNumber(trade.price).minus(instrument.last_price).toString();
      }
      instrument.last_price = trade.price;
    }
  }
}

export default {
  actions,
  getters,
  mutations,
  state: {
    instrument: [],
    allInstrument: [],
    currentSymbol: '',
    riskLimits: [],
  },
};