<template>
  <div id="create-api">
    <Breadcrumb :items="breadcrumbItems" class="container-breadcrumb" />
    <ModalAPIKey :type="dataOtp.action" ref="APIKey" :newApiNameProps="newApiName" :scopeSetting="scopeSetting" @onClose="handleHideModal" @onSubmit="onSubmitCreateAPI"/>
    <otp-modal ref="OtpModal" :is-disable-button="isDisableButton" :params="dataOtp" :submit="verifyOtp"/>
    <div class="header-api-page">
      <span class="title">Api Management</span>
      <button class="submit-button" @click="showModalAPIKey({action: 'create'})">{{
          $t('account.api.create_btn')
        }}
      </button>
    </div>
    <div class="create-api-content">
      <div class="list-detail-api" :style="{display: 'flex', flexDirection: 'column', gap: '16px'}">
        <div v-for="api in apiList" :key="api.id" class="list_box_api clearfix">
          <APIManagementItem
              :id="api.id"
              :name="api.name"
              :qr_code="api.qr_code"
              :scopeSetting="getScopeItem(api)"
              :secret="api.secret"
              @delete="confirmDeleteApi(api)"
              @edit="showModalAPIKey({action: 'editAPI', api})"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import rf from '@/request/RequestFactory'
import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal';
import Utils from '@/common/Utils';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import COMMON_CONST from '@/common/Const';
import AccountSiteBar from '@/components/shared_components/common/AccountSiteBar.vue';
import AccountTopHeader from "@/components/shared_components/common/AccountTopHeader";
import ModalAPIKey from "@/components/setting_pages/ModalAPIKey.vue";
import {mapState} from "vuex";
import APIManagementItem from "@/components/setting_pages/APIManagementItem.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

const TOKEN_API_TYPE = 'api_token';
const ruleList = [{
  key: 'read',
  scope: 4,
  enable: true
}, {
  key: 'withdrawal',
  scope: 1,
  enable: false
}, {
  key: 'trading',
  scope: 2,
  enable: false
}]

export default {
  components: {
    Breadcrumb,
    APIManagementItem,
    ConfirmationModal,
    OtpModal,
    AccountSiteBar,
    AccountTopHeader,
    ModalAPIKey
  },

  data() {
    return {
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      maxlengthApi: 20,
      newApiName: '',
      otpCode: null,
      urlQR: {},
      // urlQRLock : '@/assets/images/qr_lock.png',
      key2fa: '',
      enableEditing: {},
      scopeEditing: {},
      scopeCreating: [],
      apiList: [],
      scopeSetting: [],
      dataOtp: {},
      user: null,
      isDisableButton: false,
      ipRestrictionSettings: {},
      // ip_restricted: {},
      ipInput: {},
      is_restrict: {},
      isLoading: true,
      api: {},
      isEditing: false,
      editingValue: undefined,
      breadcrumbItems: [
        { label: "Profile", link: "/account/dashboard" },
        { label: "Api Management", link: "dashboard" },
      ],
    };
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  watch: {
    apiList(value) {
      this.apiList.map(el => {
        const data = this.generateScope(el);
        this.generateIPlist(el);
        this.is_restrict = {
          ...this.is_restrict,
          [el.id]: el.is_restrict
        }

        this.scopeEditing = {
          ...this.scopeEditing,
          [el.id]: data
        }
      });
    },
    ipRestrictionSettings(value) {
    },
    ipInput(value) {

    }

  },

  mounted() {
    this.getUserInformation();
    this.getApiSettings();
    this.getScopeSetting();
  },

  methods: {
    handleHideModal() {
      // this.cleanUp();
    },
    getScopeItem(api) {
      const copyData = [...this.scopeSetting]
      return copyData.map(item => {
        return {...item, isChecked: api.scopes[0].includes(item.value)};
      })
    },
    onUpdateScopesAPI(otp) {
      const params = {
        scopes: this.scopeSetting.filter(item => item.isChecked).map(item => item.value).toString().replaceAll(',', ''),
        ip_restricted: this.editingValue.ip_restricted,
        name: this.editingValue.name,
        is_restrict: this.editingValue.is_restrict,
        otp: otp.otp
      }

      rf.getRequest('UserRequest').updateScopesAPI(params, this.editingValue.id)
          .then(res => {
            this.getApiSettings();
            this.onCancelEditing();
          })
    },

    onCancelEditing() {
      this.isEditing = false;
      this.editingValue = undefined;
    },
    callbackOtp(data) {
      this.otpCode = data.otp
      $('.loading-effect').hide();
      this.isDisableButton = false;
      window.CommonModal.hide('OtpModal');
      if (this.dataOtp.action == 'create')
        this.createNewApi(data)
      if (this.dataOtp.action == 'save')
        this.saveApi(this.dataOtp.api, this.dataOtp.scope)
      if (this.dataOtp.action == 'delete')
        this.deleteApi(this.dataOtp.api)
      if (this.dataOtp.action == 'deleteAll')
        this.deleteApi()
      if (this.dataOtp.action == 'editAPI')
        this.onUpdateScopesAPI(data)
    },

    ipRestricted(api, condition) {
      // this.ipRestrictionSettings[api] = condition;
      this.is_restrict[api] = condition
    },

    confirmIps(api) {
      const ip = this.ipInput[api];

      const validateIp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(ip)

      if (validateIp) {
        if (!this.ipRestrictionSettings[api]) this.ipRestrictionSettings[api] = []
        this.ipRestrictionSettings = {
          ...this.ipRestrictionSettings,
          [api]: window._.union(this.ipRestrictionSettings[api], [ip])
        }
      }

    },

    removeIps(api, item) {
      this.ipRestrictionSettings = {
        ...this.ipRestrictionSettings,
        [api]: window._.filter(this.ipRestrictionSettings[api], (el) => el !== item)
      }
    },
    showModalAPIKey(data) {
      if (this.user.security_setting.otp_verified === 0) {
        Message.error(window.i18n.t('account.otp.should_enable_first'));
        return
      }
      this.dataOtp = {
        ...data,
      }
      if (data.action === 'create') {
        this.newApiName = ''
        this.scopeSetting.map(item => {
          item.isChecked = false;
          return item;
        })
      }
      if (data.action === 'editAPI') {
        this.isEditing = true;
        this.editingValue = data.api;
        this.scopeSetting.map(item => {
          item.isChecked = data.api.scopes[0].includes(item.value)
          return item;
        })
        this.newApiName = data.api.name
      }
      window.CommonModal.show('APIKey');
    },
    showOTPModal(data, bypass = false) {
      if (this.user.security_setting.otp_verified === 0) {
        Message.error(window.i18n.t('account.otp.should_enable_first'));
        return
      }
      this.dataOtp = {
        ...data,
      }
      if (bypass) {
        this.callbackOtp(this.dataOtp)
        return
      }
      window.CommonModal.show('OtpModal');
      // this.verifyOtp(this.dataOtp);
    },
    onSubmitCreateAPI({apiName, permissions}) {
      console.log("dataOtp",this.dataOtp)
      window.CommonModal.hide('APIKey');
      this.newApiName = apiName;
      this.scopeCreating = permissions.filter(item => item.isChecked).map(item => item.value).toString().replaceAll(',', '');
      window.CommonModal.show('OtpModal');
    },
    verifyOtp(params) {
      $('.loading-effect').css('display', 'flex');
      this.isDisableButton = true;
      rf.getRequest('UserRequest').otpVerify({otp: params.otp}).then(res => {
        this.callbackOtp(params);
      }).catch(error => {
        $('.loading-effect').hide();
        this.isDisableButton = false;
        if (!error.response) {
          this.showError(window.i18n.t('common.message.network_error'));
          return;
        }

        const errors = error.response.data.errors
        if (params.otp?.length !== 6) {
          this.showGoogleAuthenticationModal();
        }
        window._.each(errors, (value, key) => {
          this.errors.add(key, this.$t(`${value[0].formatUnicorn({values: ".jpg, .jpeg, .png"})}`));
        });
      });
    },

    showGoogleAuthenticationModal() {
      window.CommonModal.show('OtpModal');
    },

    getUserInformation(useCache = true) {
      rf.getRequest('UserRequest').getCurrentUser(useCache).then(res => {
        this.user = Object.assign({}, this.user, res.data);
      });
    },

    checkRestrictPage() {
      if (!this.user) {
        this.getUserInformation();
        return
      }
      // if(this.user.security_level < 3) {
      //   const path = '/page-not-found'; //TODO: update getting the layoutpath after merged margin branch
      //   this.$router.push({ path });
      // }

    },

    getScopeSetting() {
      rf.getRequest('UserRequest').getScopesAPISetting()
          .then(res => {
            const outputArray = Object.entries(res.data).map(([key, value]) => {
              return {value: parseInt(key), key: value, name: this.mapNameScopes(value), isChecked: value === 'READ'};
            });
            this.scopeSetting = outputArray;
          })
    },

    mapNameScopes(name) {
      let nameScope = 'Enable Reading';
      switch (name) {
        case 'READ':
          nameScope = 'Enable Reading';
          break;
        case 'TRADE':
          nameScope = 'Enable Spot Trading';
          break;
        case 'FUTURE_TRADING':
          nameScope = 'Enable future trading';
          break;
        case 'WITHDRAW':
          nameScope = 'Enable Withdrawals';
          break;
      }

      return nameScope
    },

    getApiSettings() {
      this.checkRestrictPage();
      this.isLoading = true
      rf.getRequest('UserRequest').getUserApiSettings()
          .then(res => {
            this.apiList = res.data;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    generateScope({id, scopes}) {
      const clone = window._.cloneDeep(ruleList);

      if (!scopes || typeof scopes !== 'object' || typeof scopes === 'object' && scopes.length <= 0) {
        return clone
      }

      if (JSON.stringify(scopes) == JSON.stringify(["*"])) {
        clone.map(el => {
          el.enable = true
        })
        return clone

      } else {

        scopes.map(scope => {

          let def = {
            7: [4, 2, 1],
            6: [4, 2],
            5: [4, 1],
            4: [4],
            3: [2, 1],
            2: [2],
            1: [1],
          }

          clone.map(el => {
            const enable = def[scope] && def[scope].includes(el.scope)
            el.enable = el.enable ? el.enable : enable
          })

        })
        return clone
      }
    },

    generateIPlist(data) {
      const {ip_restricted} = data;
      if (ip_restricted) {
        const text = ip_restricted.split(",");
        data.ip_restricted = text;
        this.ipRestrictionSettings[data.id] = text;
        return data
      }
    },

    showModal(config) {
      const classC = config.class;
      const type = config.type;
      const title = config.title;
      const content = config.content;
      const customContent = config.customContent;
      let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
      let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
      if (config.noAction) {
        btnCancelLabel = null;
        btnConfirmLabel = null;
      }
      const onConfirm = config.onConfirm;
      const onCancel = config.onCancel;
      window.ConfirmationModal.show({
        class: classC,
        type: type,
        title: title,
        content: content,
        customContent: customContent,
        btnCancelLabel: btnCancelLabel,
        btnConfirmLabel: btnConfirmLabel,
        onConfirm: onConfirm,
        onCancel: onCancel
      });
    },

    confirmDeleteApi(api) {
      if (this.user.security_setting.otp_verified == 0) {
        Message.error(window.i18n.t('account.otp.should_enable_first'));
        return
      }
      const title = window.i18n.t('account.api.delete_api');
      const message = api && api.name ? window.i18n.t('account.api.delete_api_message_withname', {name: api.name}) : window.i18n.t('account.api.delete_api_message_noname');
      this.showModal({
        type: 'delete_api',
        title: title,
        class: 'custom_modal_del_api',
        content: message,
        btnCancelLabel: window.i18n.t('common.action.no'),
        btnConfirmLabel: window.i18n.t('common.action.yes'),
        widthModal: 560,
        onConfirm: () => {
          this.showOTPModal({action: 'delete', api})
        },
        onCancel: () => {

        }
      });
    },

    confirmDeleteAllApi() {
      const title = window.i18n.t('Delete All');
      const message = window.i18n.t('Are you sure want to delete all API Key?');
      this.showModal({
        type: 'delete_all_api',
        title: title,
        class: 'custom_modal_del_api',
        content: message,
        btnCancelLabel: window.i18n.t('common.action.no'),
        btnConfirmLabel: window.i18n.t('common.action.yes'),
        onConfirm: () => {
          this.showOTPModal({action: 'deleteAll'})
        },
        onCancel: () => {

        }
      });
    },

    updateScopeItem(api, scopes) {
      const item = this.scopeEditing[api.id].find(el => el.key == scopes.key)
      if (item)
        this.scopeEditing[api.id].find(el => el.key == scopes.key).enable = !scopes.enable
    },

    createNewApi(data) {
      const name = this.newApiName ? this.newApiName.trim() : '';
      if (!name) return
      rf.getRequest('UserRequest').createUserApiSettings({name, scope: this.scopeCreating, ...data}).then(res => {

        if (res.success) {

          this.urlQR = {
            [res.data.token.id]: res.data.qrCodeUrl
          }
          this.apiList.map(el => {
            el.secret = '***********************'
          })
          this.apiList.unshift(res.data.token);

          this.newApiName = '';

          Message.success(window.i18n.t('account.api.create_success'));

          this.getApiSettings()
        }

      }).catch(err => {
        Message.error(window.i18n.t('account.api.create_fail'));
      });
    },

    editApi(api, previousRules) {
      const id = api.id
      this.scopeEditing = {
        ...this.scopeEditing,
        [id]: previousRules
      }

      this.enableEditing = {
        ...this.enableEditing,
        [id]: this.enableEditing[id] !== undefined ? !this.enableEditing[id] : true
      }
    },

    closeApi(api) {
      this.enableEditing = {
        ...this.enableEditing,
        [api.id]: false
      }
    },

    saveApi(api, previousRules) {
      const getScopeForCurrentRules = (inputScopeArray) => {
        if (inputScopeArray.every(el => el.enable)) return 7
        return inputScopeArray.filter(el => el.enable).map(el => el.scope).reduce((a, b) => a + b, 0)
      }

      const params = {
        ...api,
        otp: this.otpCode,
        scopes: getScopeForCurrentRules(previousRules),
        ip_restricted: this.ipRestrictionSettings[api.id] ? this.ipRestrictionSettings[api.id].join(",") : "",
        is_restrict: this.is_restrict[api.id]
      }

      rf.getRequest('UserRequest').updateUserApiSettings(params).then(res => {
        if (res.data) {
          this.closeApi(api);
          Message.success(window.i18n.t('account.api.save_success'), {}, {position: 'bottom_right'});
        }
      }).catch(err => {
        Message.error(window.i18n.t('account.api.save_fail'), {}, {position: 'bottom_right'});
      })
    },

    deleteApi(api) {
      if (api) {
        const id = api.id;
        rf.getRequest('UserRequest').deleteOneApiSettings({id, otp: this.otpCode}).then(res => {
          if (api && res.success) {
            Message.success(window.i18n.t('account.api.delete_success'));
            this.getApiSettings();
          }
        }).catch(err => {
          Message.error(window.i18n.t('account.api.delete_fail'));
        });
      } else {
        rf.getRequest('UserRequest').deleteAllApiSettings({otp: this.otpCode}).then(res => {
          if (res.success) {
            this.getApiSettings();
            Message.success(window.i18n.t('account.api.delete_success'));
          }
        }).catch(err => {
          Message.error(window.i18n.t('account.api.delete_fail'));
        });
      }
    },
    copyApiKey(text) {
      if (!navigator.clipboard) {
        Utils.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text);
      Message.success(window.i18n.t('funds.deposit.copy_success'));
    },
    checkAvailable(api) {
      return api && api.id ? this.enableEditing[api.id] : false
    }
  },

};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.submit-button {
  background-color: var(--text-primary-color);
  color: var(--background-color-primary);
  width: 100%;
  padding: 10px 16px;
  border-radius: 1234px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 159px;
  margin-left: auto;
  @media screen and (max-width: 500px) {
    margin-left: unset;
    max-width: unset;
  }
}

.grid-checkbox {
  grid-template-columns: 50% 50%;
}

.checkbox-function {
  right: 35px;
  font-size: 16px;
  color: $color-grey;
  cursor: pointer;

  label {
    cursor: pointer;
    color: var(--dark-1);
    font-family: $font-inter-regular;

    .button-check {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
      background: #DEDEDE;
      border-radius: 2px;
      height: 20px;
      width: 20px;
      display: block;
      float: left;
      position: relative;
      margin-right: 5px;
      @media screen and (max-width: 500px) {
        height: 16px;
        width: 16px;
      }
      &:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 1px;
        width: 6px;
        height: 13px;
        border: solid $color-grey-dark;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        display: none;
      }
    }

    .checkbox_input {
      display: none;

      &[type="checkbox"]:checked + .button-check {
        background-color: $color-caribbean-green;
      }

      &[type="checkbox"]:checked + .button-check:after {
        display: block;
      }
    }

  }

}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.clearfix-17 {
  clear: both;
  display: block;
  height: 17px;
  width: 100%;
}

.clearfix-35 {
  clear: both;
  display: block;
  height: 35px;
  width: 100%;
}



#create-api {
  background-color: var(--background-color-primary-2);
  max-height: 100%;
  height: 100%;
  padding: 24px 40px;
  .header-api-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      color: var(--text-primary-color);
    }
    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .account_navbar {
    margin-top: 4px;
  }

  .create-api-content {
    margin-top: 48px;
    .title_page {
      font-size: $font-title-size-medium-small;
      font-family: $font-inter-bold;
      line-height: 36px;
      color: var(--text-primary-color);
      margin-bottom: 28px;
    }

    #box_enter_api_key {
      padding: 36px 43px;
      width: 1150px;
      max-width: 100%;

      &.box_api {
        width: 100%;
      }

      .content {
        height: 100%;
        width: 100%;
        display: flex;
        //justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .group_enter_key {
        display: flex;
        column-gap: 30px;
        //justify-content: center;
        .input_enter_key {
          width: 360px;
          height: 49px;
          background-color: var(--background-color-second);
          border-radius: 8px;
          padding: 14px 15px;
          border: 1px solid var(--background-color-second);
          //outline: none;
          color: var(--color-text-content);
          font-size: $font-big;
          line-height: 21px;

          &:hover, & :active, &:focus {
            border: 1px solid $color-caribbean-green !important;

          }

          &::placeholder {
            color: var(--text-close);
          }
        }

        .btn_creat_new_key {
          padding: 14.5px 20px;
          width: 180px;
          max-width: 100%;
          background-color: $color-caribbean-green;
          border-radius: 8px;
          font-size: 18px;
          font-family: $font-inter-regular;
          line-height: 21px;
          color: $color-grey-dark;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: $color-aquamarine;
          }
        }

        button[disabled] {
          background-color: $color-aero-blue;
          color: $color-gray-dark;
          opacity: 1;
        }
      }

      .title_left {
        font-family: $font-inter-bold;
        font-size: $font-title-size-medium-2;
        line-height: 36px;
        color: var(--text-primary-color)
      }

      .box_api_detail {
        padding-right: 20px;
      }

      .header_detail_api {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 13px;

        .name {
          white-space: nowrap;
          font-family: $font-inter-regular;
          color: var(--text-dropdown);
          font-size: $font-big;
          line-height: 21px;
          margin: 0 !important;
        }

        .list_button {
          width: 100%;
        }

        .btn_setting {
          padding: 14px 20px;
          //width: 140px;
          max-width: 100%;
          border-radius: 8px;
          background-color: $text-color-red;
          color: $pure_color_white;
          font-family: $font-inter-regular;
          font-size: $font-big;
          line-height: 21px;
          width: 140px;
          height: 49px;
          float: right;
        }

      }

      .header-api {
        content: '';
        width: 100%;
        border: 1px solid var(--background-color-second);
        margin-bottom: 30px;
        margin-top: 10px;
      }

      .body_detail_api {
        display: flex;
        gap: 60px;

        .right_sub {
          display: flex;
          gap: 60px;

          & div {
            width: 300px;
            max-width: 100%;

            .flex-container {
              display: flex;

            }

            .flex-code {
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: $font-inter-regular;
              font-size: $font-big;
              line-height: 21px;
              color: var(--text-dropdown);
            }
          }

          //width: 400px;
        }

        .label_apiKey {
          margin-bottom: 5px;
          font-family: $font-inter-regular;
          font-size: $font-medium-bigger;
          line-height: 20px;
          color: var(--text-desc);
        }
      }

      .list-detail-api {
        height: 500px;
        max-height: 100%;
        overflow-x: auto;
      }
    }
  }

  .modal-content {
    width: 560px;
  }
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
}

#message {
  :deep(#message_content) {
    align-items: center !important;
  }
}

</style>
