<template>
  <div class="slider-container" @click="handleSliderClick">
    <div class="tooltip-container" :style="tooltipStyle">
      {{ currentValue }}%
    </div>

    <input
      type="range"
      :min="min"
      :max="max"
      :step="step"
      v-model="currentValue"
      @input="updateValue"
      @mousemove="updateTooltipPosition"
      @mouseleave="hideTooltip"
      @mouseover="showTooltip"
      class="slider"
      :style="{
        background: lightMode ?`linear-gradient(
          to right,
          #17181A 0%, 
          #17181A ${((currentValue - min) / (max - min)) * 100}%, 
          #dedede ${((currentValue - min) / (max - min)) * 100}%,
          #dedede 100%)` :`linear-gradient(
          to right,
          #dedede 0%, 
          #dedede ${((currentValue - min) / (max - min)) * 100}%, 
          #232323 ${((currentValue - min) / (max - min)) * 100}%,
          #232323 100%)`  ,
      }"
    />
    <div class="slider-ticks">
      <span v-for="interval in intervals" :key="interval"  :class="['tick', { 'tick-hidden': currentValue === interval }]"></span>
    </div>
    <div class="slider-labels">
      <span v-for="interval in intervals" :key="interval" class="label">
        {{ interval }}%
      </span>
    </div>
    <!-- <div class="slider-labels">
      <span v-for="interval in intervals" :key="interval" class="label">
        {{ interval }}
      </span>
    </div> -->
    <!-- <div class="current-value">Current Value: {{ currentValue }}</div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    intervals: {
      type: Array,
      default: () => [0, 25, 50, 75, 100],
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentValue: this.value,
      tooltipVisible: false,
      tooltipPosition: { left: 0 },
    };
  },
  watch: {
    value(val){
      if(val===0 ||val===25 ||val===50 ||val===75 ||val===100 ){
        this.currentValue= val
      }
    }
  },
  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    lightMode() {
      return this.theme === "light-theme";
    },
    tooltipStyle() {
      return {
        display: this.tooltipVisible ? 'block' : 'none',
        left: `${this.tooltipPosition.left}px`,
        // top: `${this.tooltipPosition.top}px`,
      };
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.currentValue);
    },
    showTooltip() {
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    updateTooltipPosition(event) {
      const rect = event.target.getBoundingClientRect();
      const offset = (this.currentValue - this.min) / (this.max - this.min) * rect.width;
      this.tooltipPosition.left = offset;
      // this.tooltipPosition.top = rect.top - 30; // Position above the slider thumb
    },

    handleSliderClick(event) {
    const rect = event.currentTarget.getBoundingClientRect();
    const clickPosition = event.clientX - rect.left;
    const sliderWidth = rect.width;
    const newValue = this.min + ((clickPosition / sliderWidth) * (this.max - this.min));
    this.currentValue = Math.round(newValue / this.step) * this.step; // làm tròn theo step
    this.updateValue(); // Gửi sự kiện lên component cha nếu cần
  },
  },
};
</script>

<style lang="scss" scoped>

.dark-theme {

  .tick {
  background-color: #17181A; 
  border: 2px solid white;
}


.slider::-webkit-slider-thumb {
 
  background-color: #17181A; 

  border: 2px solid white;

}

}
.slider-container {
  position: relative;
  width: 100%;
  text-align: center;
  float: left;
  padding: 15px 0;
}

.slider {
  width: 100%;
  margin: 0 auto;
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background: #dedede;
  border-radius: 5px;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #fff; /* Orange thumb */
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #17181A; /* White border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease; /* Smooth transformation */
}

/* .slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
} */

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: #fff; /* Orange thumb */
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #00DDB3; /* White border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease; /* Smooth transformation */
}

.tooltip-container {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 100;
  top: -30px;
}

.slider-ticks {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -13px; 
  margin-top: 8px;
}

.tick {
  -webkit-appearance: none;
  appearance: none;
  width: 8px;
  height: 8px;
  background-color: #fff; 
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #E4E7EC;

}
.tick-hidden {
  opacity: 0; 
}
.slider-labels {
  display: flex;
  justify-content: space-between;

  margin-top: 10px;
}

.label {
   /* width: 20%; */
  text-align: center; 
  color: #8E8E92;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
}

.current-value {
  margin-top: 20px;
  font-weight: bold;
}
</style>
