<template>
  <div class="datatable" id="table_wd_deposit">
    <div class="title-table" v-show="configs.is_show_title">
      <div class="left">
        <span>{{ $t("funds.withdrawals.history") }}</span>
      </div>
      <div class="right">
        <div class="group-tooltip">
          <!-- <span class="icon-help"></span> -->
          <img src="/images/icon/icon-question.svg" alt="icon" />
          <span class="name-tooltip">{{
            $t("funds.withdrawals.did_not_receive_the_mail")
          }}</span>
          <div class="box-tooltip">
            <div
              class="bubble top"
              id="still-not-receive"
              v-html="
                $t('funds.withdrawals.tooltip', {
                  url: $siteSupportArticle(
                    $i18n.locale,
                    'cannot-receive-email'
                  ),
                })
              "
            ></div>
          </div>
        </div>
        <div class="view_all_link">
          <router-link
            :to="{ name: 'Transaction History', query: { type: configs.type } }"
            class="view_all"
          >
            {{ $t("funds.withdrawals.view_all") }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="content-table">
      <div class="transaction-table">
        <data-table3
          :getData="getData"
          :limit="10"
          :widthTable="'100%'"
          ref="datatable"
          :msgEmptyData="configs.msgEmptyData"
          @DataTable:finish="onDatatableFinish"
          :hasLayoutMobile="true"
        >
          <template slot-scope="props">
            <th
              class="cl1 t-left sort"
              data-sort-field="status"
              @click="props.echoclick"
            >
              <span class="th-strong">{{ $t("funds.history.status") }}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th
              class="cl2 t-left sort"
              data-sort-field="currency"
              @click="props.echoclick"
            >
              <span class="th-strong">{{ $t("funds.history.coin") }}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <!-- <th
              class="cl3 t-left sort"
              data-sort-field="network_id"
              @click="props.echoclick"
            >
              <span class="th-strong">{{ $t("funds.history.network") }}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th> -->
            <th
              class="cl4 t-left sort"
              data-sort-field="amount"
              @click="props.echoclick"
            >
              <span class="th-strong">{{ $t("funds.history.amount") }}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th
              class="cl4 t-right sort"
              data-sort-field="created_at"
              @click="props.echoclick"
            >
              <span class="th-strong">{{ $t("funds.history.date") }}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th></th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td
                class="cl1 t-left"
                :class="{
                  active: props.index === isActiveRow,
                  even: props.index % 2 === 1,
                }"
              >
                <strong
                  v-if="props.item.status == 'success'"
                  class="td-strong success"
                  >{{ props.item.status | transactionStatus }}</strong
                >
                <strong
                  v-else-if="props.item.status == 'pending'"
                  class="td-strong pending"
                  >{{ props.item.status | transactionStatus }}</strong
                >
                <strong
                  v-else-if="props.item.status == 'rejected'"
                  class="td-strong rejected"
                  >{{ props.item.status | transactionStatus }}</strong
                >
                <strong
                  v-else-if="props.item.status == 'cancel'"
                  class="td-strong cancel"
                  >{{ props.item.status | transactionStatus }}</strong
                >
                <strong v-else class="td-strong cancel">{{
                  props.item.status | transactionStatus
                }}</strong>
              </td>
              <td
                class="cl2 t-left"
                :class="{
                  active: props.index === isActiveRow,
                  even: props.index % 2 === 1,
                }"
              >
                <span>
                  <strong class="td-strong">{{
                    props.item.currency | uppercase
                  }}</strong>
                </span>
              </td>
              <!-- <td
                class="cl3 t-left network"
                :class="{
                  active: props.index === isActiveRow,
                  even: props.index % 2 === 1,
                }"
              >
                <span>
                  <strong class="td-strong">{{
                    props.item?.network_name
                  }}</strong>
                </span>
              </td> -->
              <td
                class="cl4 t-left"
                :class="{
                  active: props.index === isActiveRow,
                  even: props.index % 2 === 1,
                }"
                :title="Number(props.item.amount)"
              >
                <strong class="td-strong">{{
                  props.item.amount | trimEndZero | formatNumberAndDecimal
                }}</strong>
              </td>
              <td
                class="cl4 t-right"
                :class="{
                  active: props.index === isActiveRow,
                  even: props.index % 2 === 1,
                }"
              >
                <span>{{ props.item.created_at | orderDate }}</span>
                <span class="text-sub">{{
                  props.item.created_at | orderTime
                }}</span>
              </td>
              <td
                class="cl5 t-right"
                @click="activeRow(props.index)"
                :class="{
                  active: props.index === isActiveRow,
                  even: props.index % 2 === 1,
                }"
              >
                <span
                  v-if="isActiveRow === props.index"
                  class="icon-arrow2 icon"
                ></span>
                <span v-else class="icon-arrow1 icon"></span>
              </td>
            </tr>
            <tr
              v-if="isActiveRow === props.index"
              :class="{ even: props.index % 2 === 1 }"
            >
              <td class="info" colspan="5">
                <template v-if="props.item.is_external == 0">
                  <div class="wrapper">
                    <div class="address_txid">
                      <span class="address_txid1"
                        >{{ $t("funds.history.address") }}:</span
                      >
                      <div class="item_email_user">
                        <span class="txt_email_user">{{
                          props.item.to_address
                        }}</span>
                        <span
                          v-if="props.item.currency === 'ada'"
                          class="tooltip_email_user"
                          >{{ props.item.to_address }}</span
                        >
                      </div>
                      <!-- <span>{{ props.item.to_address }}</span> -->
                    </div>
                    <div
                      class="address_txid"
                      v-if="props.item.currency === 'xrp'"
                    >
                      <span class="address_txid1"
                        >{{ $t("funds.history.tag") }}:</span
                      >
                      <span>{{ props.item.blockchain_sub_address }}</span>
                    </div>
                    <div
                      class="address_txid"
                      v-if="
                        props.item.status !== 'canceled' &&
                        props.item.status !== 'pending'
                      "
                    >
                      <span class="address_txid1"
                        >{{ $t("funds.history.txid") }}:</span
                      >
                      <span class="text_txid">{{
                        props.item.transaction_id
                      }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="address_txid">
                    <p class="address_txid1">
                      {{ $t("funds.history.address") }}:
                    </p>
                    <span class="text_txid_link">{{
                      props.item.to_address
                    }}</span>
                  </div>

                  <div
                    v-if="props.item.currency === 'xrp'"
                    class="address_txid"
                  >
                    <p class="address_txid1">{{ $t("funds.history.tag") }}:</p>
                    <span class="text_txid_link">{{
                      props.item.blockchain_sub_address
                    }}</span>
                  </div>

                  <div
                    class="address_txid"
                    v-if="
                      props.item.status !== 'canceled' &&
                      props.item.status !== 'pending'
                    "
                  >
                    <span class="address_txid1"
                      >{{ $t("funds.history.txid") }}:</span
                    >
                    <span class="text_txid_link">
                      {{ props.item.tx_hash }}</span
                    >

                    <!-- <p
                      class="txid-link"
                      v-if="
                        props.item.status !== 'canceled' &&
                        props.item.status !== 'pending'
                      "
                    > -->
                    <!-- <span>
                        &nbsp;<a
                          :href="getTransactionUrl(props.item.currency, props.item.tx_hash)"
                          target="_blank"
                        > &nbsp&nbsp{{ props.item.tx_hash }}</a>
                      </span> -->
                    <!-- <span>
                      
                      </span>
                    </p> -->
                  </div>
                </template>
              </td>
            </tr>
          </template>
          <template slot="body-mobile" slot-scope="props">
            <div class="item-row">
              <div class="item-row-flex">
            <div class="item-left">
              <span class="text-below"> {{ $t("funds.history.status") }}  </span>
              <span class="text-above">
                <strong
                  v-if="props.item.status == 'success'"
                  class="td-strong success"
                  >{{ props.item.status | transactionStatus }}</strong
                >
                <strong
                  v-else-if="props.item.status == 'pending'"
                  class="td-strong pending"
                  >{{ props.item.status | transactionStatus }}</strong
                >
                <strong
                  v-else-if="props.item.status == 'rejected'"
                  class="td-strong rejected"
                  >{{ props.item.status | transactionStatus }}</strong
                >
                <strong
                  v-else-if="props.item.status == 'cancel'"
                  class="td-strong cancel"
                  >{{ props.item.status | transactionStatus }}</strong
                >
                <strong v-else class="td-strong cancel">{{
                  props.item.status | transactionStatus
                }}</strong></span
              >
             
            </div>
            <div class="item-right">
              <span class="text-below"> {{ $t("funds.history.coin") }} </span>
              <span class="text-above">
                <strong class="td-strong">{{
                    props.item.currency | uppercase
                  }}</strong>
              </span>
              
            </div>
          </div>
          <div class="item-row-flex">
            <!-- <div class="item-left">
              <span class="text-below"> {{ $t("funds.history.network") }} </span>
              <span class="text-above">
                <strong class="td-strong">{{
                    props.item?.network_name
                  }}</strong></span
              >
             
            </div> -->
            <div class="item-left">
              <span class="text-below"> {{ $t("funds.history.amount") }} </span>
              <span class="text-above">
                <strong class="td-strong">{{
                  props.item.amount | trimEndZero | formatNumberAndDecimal
                }}</strong>
              </span>
             
            </div>
            <div class="item-right">
              <span class="text-below"> {{ $t("funds.history.date") }} </span>
              <span class="text-above">
                <span>{{ props.item.created_at | orderDate }}</span>
                <span class="text-sub">{{
                  props.item.created_at | orderTime
                }}</span></span
              >
              
            </div>
          </div>
          <!-- <div class="item-row-flex">
            <div class="item-left">
              <span class="text-below"> {{ $t("funds.history.date") }} </span>
              <span class="text-above">
                <span>{{ props.item.created_at | orderDate }}</span>
                <span class="text-sub">{{
                  props.item.created_at | orderTime
                }}</span></span
              >
             
            </div>
       
          </div> -->
          <div class="line" />
            </div>
          </template>
        </data-table3>
      </div>
    </div>
  </div>
</template>
<script>
import rf from "@/request/RequestFactory";
import Utils from "@/common/Utils";

export default {
  props: {
    configs: {
      type: Object,
      default: function () {
        return {
          type: "",
          is_show_title: true,
          msgEmptyData: "",
          linkContactUs: "",
        };
      },
    },
  },
  data() {
    return {
      FixSortIE: null,
      isActiveRow: -1,
      coin: this.$route.query.coin,
    };
  },

  watch: {
    configs: function () {
      this.refresh();
    },
  },

  methods: {
    convertAmount(amount) {
      let amountAbs = this.$options.filters.abs(amount);
      return amountAbs.toLocaleString();
    },
    activeRow(rowIndex) {
      this.isActiveRow = this.isActiveRow === rowIndex ? -1 : rowIndex;
    },

    onDatatableFinish() {
      const datatable = this.$refs.datatable;
      // Only sort 'amount' field a again. Because amount field can be negative (withdraw) or positive (deposit).
      if (datatable.params.sort !== "amount") {
        return;
      }
      const result = window._.chain(datatable.rows)
        .map((item) => {
          item.amount = Math.abs(parseFloat(item.amount));
          return item;
        })
        .orderBy([datatable.params.sort], [datatable.params.sort_type])
        .value();
      this.$refs.datatable.rows = result;
    },

    getData(params) {
      this.isActiveRow = -1;
      let meta = {
        type: this.configs.type,
      };
      return rf
        .getRequest("TransactionRequest")
        .getCoinHistory(
          Object.assign({}, params, meta),
          this.coin ? this.coin : null
        );
    
    },
    onSelectedCoinChange(coin) {
      if (this.coin.toUpperCase() !== coin) {
        this.coin = coin;
        this.refresh();
      }
    },

    getTransactionUrl(currency, transactionId) {
      return Utils.getTransactionUrl(currency, transactionId);
    },

    onTransactionCreated() {
      this.refresh();
    },

    getSocketEventHandlers() {
      return {
        TransactionCreated: this.onTransactionCreated,
        selectedCoinChange: this.onSelectedCoinChange,
      };
    },

    refresh() {
      this.$refs.datatable.refresh();
    },
    notRoundNumber(num) {
      num = num
        ? Number(num).toString()
        : num.replace(
            /^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/,
            "$1$2$3"
          );
      if (num.length > 11) {
        return `${num.slice(0, 8)}...`;
      }
      return num;
    },
  },
  created() {
    let rootPath = this.$router.options.routes.find(
      (el) => el.name == "Layout"
    );
    let path =
      rootPath &&
      rootPath.children &&
      rootPath.children.find((el) => el.name == "Contact US");
    this.linkContactUs = path && path.path;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.item-row {
  /* width: 100vw !important; */
  /* padding: 16px 16px; */
  width: 100%;
  .line {
    background-color: #8e8e92;
    height: 1px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .item-row-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .item-left {
      display: flex;
      flex-direction: column;
    }
    .item-right {
      display: flex;
      flex-direction: column;
      text-align: right;
    }
    .text-above {
      font-size: 12px;
      line-height: 18px;
      color: var(--text-check-label);
      font-weight: 400;
    }
    .text-below {
      font-size: 12px;
      line-height: 18px;
      color: #8e8e92;
      font-weight: 400;
    }
    .checkbox-function {
      color: var(--text-check-label);
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
    .btn-cancel {
      color: #03aac7;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}
.cl3 {
  min-width: 130px;
}
.cl4 {
  min-width: 130px;
}
.cl5 {
  padding-left: 0px;
  width: 130px;
}
.date {
  white-space: nowrap;
}
#table_wd_deposit {
  table {
    thead {
      th {
        font-size: 14px;
        line-height: 17px;
        color: var(--text-close);
        background: transparent;
        padding: 15px;
      }
      th:first-child {
        border-radius: 8px 0 0 8px;
        width: 24.84%;
        @media screen and (max-width: 992px) {
          width: 20.5%;
        }
      }
      th:nth-child(2) {
        width: 18.28%;
      }
      th:nth-child(3) {
        width: 18.28%;
      }
      th:nth-child(4) {
        width: 17.13%;
      }
      th:nth-child(5) {
        width: 16.61%;
      }
      th:last-child {
        border-radius: 0px 8px 8px 0;
      }
    }
    tbody {
      td {
        padding: 23px 8px;
        color: var(--text-primary-color);
        background: var(--bg-history-table);
        &.network {
          white-space: unset;
        }
        &.info {
          text-align: left;
          overflow: initial;
          font-size: 18px;
          color: var(--text-primary-color);
          white-space: normal;
          padding: 20px 15px !important;
          span {
            .label-text {
              border-bottom: $color-denim;

              width: 20%;
            }
          }
        }

        &:last-child {
          vertical-align: middle;
          padding-left: 0;
        }
      }

      .cl4 {
        span {
          display: block;

          &:first-child {
            font-size: 18px;
            color: var(--text-primary-color);
          }
        }
        .text-sub {
          color: var(--text-close);
          font-size: 16px;
        }
      }
    }
  }
}
.icon-arrow1 {
  color: var(--text-close);
  &.icon {
    cursor: pointer;
  }
}
.icon-arrow2 {
  color: var(--text-close);
  &.icon {
    cursor: pointer;
  }
}

.label-text {
  width: 90px;
  margin: 0;
  color: var(--text-primary-color);
}
.sort,
.glyphicon {
  cursor: pointer;
}

.th-strong {
  font-size: 14px;
  line-height: 17px;
}
.td-strong {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;

  &.success {
    color: $text-color-jade;
  }
  &.pending {
    color: $text-color-yellow;
  }
  &.rejected {
    color: $text-color-red;
  }
  &.cancel {
    color: $text-color-red;
  }
}
.title-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 5;
  margin-bottom: 30px;
  .left {
    color: var(--text-primary-color);
    font-size: 20px;
    line-height: 24px;
    font-family: $font-inter-bold;
  }
  .right {
    display: flex;
    gap: 20px;
    font-size: 18px;
    line-height: 22px;
    position: relative;
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;

    .letf {
      font-size: 18px;
      line-height: 21px;
    }
    .right {
      width: 100%;
      margin-top: 16px;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      display: block;
      .group-tooltip {
        .name-tooltip {
          font-weight: 400;
          
          font-size: $font-root;
        }
      }
    }
  }
}

.group-tooltip {
  position: relative;
  display: flex;
  cursor: pointer;
  .name-tooltip {
    color: $text-color-red;
    margin-left: 8px;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 142.857% */
  }
  .box-tooltip {
    position: absolute;
    display: none;
    bottom: -80px;
    left: 0;
    z-index: 999999;
  }
  &:hover .box-tooltip {
    display: block;
  }
}
.bubble {
  background: rgba(25, 25, 25, 0.8);
  border-radius: 8px;
  width: 227px;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  color: $color-white;
  text-align: initial;
  a {
    color: red !important;
    &:hover {
      text-decoration: none;
      color: $color-blue-custom !important;
    }
  }
  /*triangle side*/

  /*top*/
  &.top:after {
    border-color: $color-white transparent;
    border-width: 0 13px 12px 13px;
    top: -12px;
    left: 45%;
  }
  &.top:before {
    border-color: #d0cfd2 transparent;
    border-width: 0 13px 11px 13px;
    top: -13px;
    left: 45%;
  }
}
.view_all {
  color: $color-caribbean-green;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
tr {
  th {
    white-space: nowrap;
  }
  vertical-align: top;
  height: 40px;
  overflow-y: hidden;
  transition-property: height;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease, ease-in;
  background: transparent;

  &:hover {
    // background: $color-bright-gray;
  }
  &.active {
    max-height: 300px;
    transition-property: height;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease, ease-in;
    .icon-arrow_2_1 {
      transition-duration: 0.5s;
      color: $color-yellow-pale;
      transform: rotate(180deg);
    }
  }
  td {
    &.active {
      background: var(--bg-input);
    }
  }
}
.datatable {
  th {
    background-color: $color-white;
  }
}
.wrapper {
  padding: 0px 25px 15px 0;

  border-bottom: solid 1px var(--bgr-btn);
}
.address_txid {
  display: flex;
  padding-bottom: 15px;
  .address_txid1 {
    width: 80px;
    margin-right: 18px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    word-break: break-word;
  }
  .text_txid {
    font-size: 18px;
    font-weight: 400;
    width: 484px;
    word-break: break-word;
  }
  .text_txid_link {
    max-width: 480px;
    white-space: normal;
    word-break: break-all;
  }
}

.item_email_user {
  display: block;
  position: relative;
  .txt_email_user {
    display: block;
    max-width: 485px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    word-break: break-word;
  }
  .tooltip_email_user {
    position: absolute;
    width: 500px;
    top: -17px;
    left: 20px;
    line-height: 20px;
    padding: 5px 20px;
    background-color: $color_white;
    white-space: normal;
    word-wrap: break-word;
    z-index: 10;
    font-size: $font_root;
    font-weight: 500;
    color: $color-grey-dark;
    transition: 0.5s;
    display: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }
  &:hover {
    .tooltip_email_user {
      display: block;
      transition: 0.5s;
      max-width: 330px;
    }
  }
}

@media screen and (max-width: 992px) {
  .group-tooltip {
    .box-tooltip {
      top: 27px;
      min-width: 100%;
    }
  }
  .bubble {
    position: absolute;
    min-width: 100%;
    top: 20%;
  }
  .view_all_link {
    position: absolute;
    top: 3px;
    right: 0px;
  }
  .view_all {
    font-size: 14px;
    line-height: 16px;
  }

  .td-strong {
    font-size: 14px;
    line-height: 16px;
  }
  #table_wd_deposit {
    table {
      thead {
        th {
          &:last-child {
            width: 10px;
          }
        }
      }
      tbody {
        td {
          padding: 10px 15px !important;
        }
        .cl4 {
          span:first-child {
            font-size: 14px;
          }
          .text-sub {
            font-size: 14px;
          }
        }
      }
    }
  }
  .txid-link {
    width: 90px;
  }

  .address_txid {
    &:last-child {
      .address_txid1 {
        width: 70px;
      }
    }
    .address_txid1 {
      width: 70px;
    }
    .address_txid1,
    .txt_email_user,
    .text_txid {
      font-size: 14px;
      line-height: 16px;
    }
    .text_txid_link {
      font-size: 14px;
      line-height: 16px;
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .transaction-table {
    /* min-width: $min-width-table; */
  }
  .content-table {
    z-index: 5;
    border: 0px;
    border-top: none;
    overflow-y: auto;
    overflow-x: auto;
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";
#still-not-receive {
  a {
    color: $color-blue-custom !important;
  }
}

#table_wd_deposit {
  table {
    overflow-y: initial;
  }
  .tableContainer {
    width: 100%;
    min-height: 300px;
    margin-bottom: 76px;
    padding-bottom: 17px;
    thead {
      background: transparent;
    }
    @media screen and (max-width: 375px) {
      overflow: auto;
      white-space: nowrap;
    }
  }
  .VuePagination {
    position: absolute;
    width: 100%;
    bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  #table_wd_deposit {
    .tableContainer {
      margin-bottom: 0;
    }
    .VuePagination {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: -21px;
    }
  }
}
</style>
