<template>
  <div class="container-anti-phising" >
    <Breadcrumb :items="breadcrumbItems" class="container-breadcrumb" />
    <create-anti-phishing-modal :mode="typeModal" ref="modalAntiPhishing"></create-anti-phishing-modal>
    <div class="body-anti-phising w-100">
      <div class="d-flex position-relative top-body w-100">
        <img :src="require(`@/assets/images/drx/arrow-left-dark.png`)" alt="icon" class="icon_back" @click="clickBack" :style="{width: '24px', height: '24px'}">
        <div class="title-phishing">{{ $t("anti_title") }}</div>
      </div>
      <div class="anti-phishing-container">
        <div class="d-flex infor-phishing">
          <img :src="require(`@/assets/images/drx/${infoMode}`)" />
          <span>
          {{ $t('anti_note', { APP_SHORT_NAME }) }}
          </span>
        </div>
        <div v-if="step === 'change'" class="w-100">
          <div class="input-form-phising">
            <label>{{ $t('anti.old.code') }}</label>
            <input type="text" class="form-control" placeholder="12***"
                   v-model="params.oldCode"
                   name="oldCode"
                   disabled
            />
          </div>
          <div class="input-form-phising position-relative">
            <label>{{ $t('anti.new.code') }}</label>
            <input type="text" class="form-control"
                   v-validate="{
                    required: true,
                    min: 4,
                    max: 20,
                    regex: /^[a-zA-Z0-9]*$/,
                  }"
                   maxlength="20"
                   v-model="params.newCode"
                   @focus="resetErrors"
                   name="newCode"
                   :class="{ 'error': errors.has('newCode') }"
            />
            <span class="recommend" :class="{ 'error': errors.has('newCode') }">
              {{ $t('anti.text_anti_note') }}
            </span>
          </div>
          <div class="input-form-phising">
            <label>{{ $t('anti.confirm.code') }}</label>
            <input type="text" class="form-control"
                   v-validate="'required'"
                   maxlength="20"
                   v-model="params.confirmNewCode"
                   @focus="resetErrors"
                   name="confirmNewCode"
                   :class="{ 'error': errors.has('confirmNewCode') }"
            />
            <span class="recommend error" v-show="errors.has('confirmNewCode')">
              {{ errors.first('confirmNewCode') }}
            </span>
          </div>
        </div>


        <div v-if="step == 'create'" class="input-form-phising">
          <div class="how-it-work">{{ $t('anti.title_work')}}</div>
          <span class="recommend">
            {{ $t('anti.content_work', { APP_SHORT_NAME }) }}
          </span>
        </div>
        <div class="input-form-phising">
          <div class="how-it-work">{{ $t('anti.title_how_work') }}</div>
          <span class="recommend">
            {{ $t('anti.content_how_work', { APP_SHORT_NAME }) }}
          </span>
        </div>
        <button v-if="step === 'create'" class="btn btn-success" @click="showAntiPhishingModal">{{ $t('anti.btn-create') }}</button>
        <button v-if="step === 'change'" class="btn btn-success" @click="changeAntiPhishingModal" type="submit">{{ $t('anti.btn-change') }}</button>

      </div>

    </div>
  </div>

</template>

<script>
import AccountTopHeader from '@/components/shared_components/common/AccountTopHeader.vue';
import AccountSiteBar from "@/components/shared_components/common/AccountSiteBar";
import CreateAntiPhishingModal from "./modal/CreateAntiPhishingModal";
import rf from "@/request/RequestFactory";
import {mapState} from "vuex";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    Breadcrumb,
    AccountTopHeader,
    AccountSiteBar,
    CreateAntiPhishingModal
  },
  data() {
    return {
      params : {
        oldCode: '',
        newCode: '',
        confirmNewCode: ''
      },
      step: this.mode,
      typeModal: 'create',
      sizeScreen: window.innerWidth,
      APP_SHORT_NAME: process.env.VUE_APP_SHORT_NAME,
      breadcrumbItems: [
        { label: "Profile", link: "/account/dashboard" },
        { label: "Security", link: "/account/security" },
        { label: this.mode === 'create' ? "Create Anti-Phishing Code" : "Change Anti-Phishing Code", link: "/account/security" },
      ],
    }
  },
  props: {
    mode: {
      type: 'create' | 'change',
      default: 'create'
    },
  },
  computed: {
    ...mapState({
      user: state => state.user,
      theme: state => state.userThemeMode,
    }),
    confirmedCode() {
      return this.params.confirmNewCode === this.params.newCode;
    },
    infoMode() {
      return this.theme === 'light-theme' ? 'info-light.png' : 'info-dark.png';
    },
  },
  methods: {
    clickBack() {
      this.$router.push({name: 'SettingsSecurity'});
    },
    showAntiPhishingModal() {
      this.typeModal = 'create';
      window.CommonModal.show('CreateAntiPhishingModal');
      this.$refs.modalAntiPhishing.$props.mode = this.mode;
    },

    async changeAntiPhishingModal() {
      const params = {
        is_anti_phishing: !!this.params.confirmNewCode,
        anti_phishing_code: this.params.confirmNewCode
      }
      this.resetErrors();
      await this.$validator.validate('confirmNewCode');

      if (this.params.newCode !== this.params.confirmNewCode) {
        this.errors.add('confirmNewCode', this.$t('anti.validate.code_not_match'));
      }
      await this.$validator.validate('newCode');
      if (this.errors.any()) {
        return;
      }
      rf.getRequest('UserRequest').createAntiPhishing(params).then(() => {
        Message.success('Successfully');
        this.$refs.modalAntiPhishing.$props.mode = 'resend';
        this.$router.push({name: 'EmailVerification', query: { code: this.params.confirmNewCode, type: 'update' }});
      }).catch(err => {
        Message.error('');
      })
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    getUser(useCache = true) {
      rf.getRequest('UserRequest').getCurrentUser(useCache).then();
    },
    resizeHandler() {
          this.sizeScreen = window.innerWidth;
        },

  },
  mounted() {
    if (this.user?.user_anti_phishing_active_latest.length !== 0){
      const oldCode = this.user?.user_anti_phishing_active_latest[0]?.anti_phishing_code.slice(0, 2) + window._.repeat('*', this.user?.user_anti_phishing_active_latest[0]?.anti_phishing_code.length -2);
      this.params.oldCode = oldCode;
    }
    const code = this.$route.params.code;
    if(code ) {
      rf.getRequest('UserRequest').verifyAntiPhishing(code).then(() => {
        this.typeModal = this.$router.history.current.path.includes('create') ? 'success' : 'change';
        window.CommonModal.show('CreateAntiPhishingModal');
        this.$refs.modalAntiPhishing.$props.mode = this.$router.history.current.path.includes('create') ? 'success' : 'change';
        this.getUser(false);
        
      }).catch(err => {
        this.typeModal = 'invalid';
        window.CommonModal.show('CreateAntiPhishingModal');
        this.$refs.modalAntiPhishing.$props.mode = 'invalid';
      })
    }
    this.step = this.$router.history.current.path.includes('create') ? 'create': 'change'
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

}

</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.container-anti-phising{
  padding: 24px 40px;
  @media screen and (max-width: 992px) {
    padding: 24px 20px;
  }
}
.dark-theme {
  .body-anti-phising {
    max-width: 454px;
    width: 100%;
    .top-body {
      align-items: center;
      .icon-arrow4 {
        &::before {
          color: $color-auro-metal;
        }
      }
      .title-phishing {
        color: $color-white;
      }
    }
    .anti-phishing-container {
      .infor-phishing {
        background: #1F2023;;
        span {
          color: white;
        }
      }
      .input-form-phising {
        .how-it-work {
          color: $color-white;
        }

        .recommend {
          color: $color-storm-grey;
          &.error {
            color: $text-color-red;
          }
        }
        label {
          color: $color-white;
        }
        input {
          background: $color-black-shark;
          color: $color-white;
          &.error {
            border: 1.5px solid $text-color-red;
          }
        }
      }
    }

  }
}


.body-anti-phising {
  .form-control {
    box-shadow: inset 0 0 0 rgb(0 0 0 / 8%);
  }

  .top-body {
    margin-bottom: 24px;
    margin-top: 24px;
    .icon-arrow4 {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      &::before {
          color: $color-grey-dark;
        }
    }
    .title-phishing {
      font-weight: 600;
      font-family: $font-inter-bold;
      font-size: 30px;
      color: $color-grey-dark;
      text-align: left;
      margin-left: 20px;
    }
  }
  .anti-phishing-container {
    max-width: 530px;
    font-family: $font-inter-regular;


    .btn-success {
      background-color: var(--text-primary-color);
      color: var(--background-color-primary);
      border-radius: 1234px;
      font-size: 16px;
      width: 100%;
      border: none;
      height: 50px;
      font-weight: 700;
      text-transform: none;
    }

    .infor-phishing {
      width: 100%;
      border-radius: 8px;
      background: $color-aqua-day;
      padding: 10px 20px;
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 992px) {
        padding: 12px;
      }
      span {
        color: $color-caribbean-green;
        font-size: 14px;
        margin-left: 8px;
      }
    }
    .input-form-phising {
      margin-bottom: 24px;

      .how-it-work {
        font-size: 16px;
        color: $dark-1;
        font-family: $font-inter-bold;
      }

      .recommend {
        font-size: 12px;
        color: $color-gray-dark;
        margin-top: 8px;
        &.error {
          color: $text-color-red;
        }
      }
      label {
        font-size: 18px;
        color: $dark-1;
        font-weight: 400;

      }
      input {
        background: $color-alabaster;
        height: 48px;
        font-size: 18px;
        border-radius: 123px;
        border: 1px solid var(--button-border-outline);
        background: var(--input-bg);

        &.error {
          border: 1.5px solid $text-color-red;
        }
      }
    }
  }

}

@media screen and (max-width: 992px) {
  .body-anti-phising {
    .anti-phishing-container {
      max-width: 340px;

      .input-form-phising {
        margin-bottom: 30px;
      }

      .infor-phishing {
        margin-bottom: 30px;
      }
    }

    .top-body {
      margin-bottom: 30px;

      .title-phishing {
        font-size: 20px;
      }
    }
  }
}
</style>