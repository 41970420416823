<template>
  <div>
    <modal :name="modalName" width="500">
      <template slot="body">
        <h3 class="box-title-modalsub"><span>{{ $t('common.manage_allocation') }}</span></h3>
        <div class="group_find_join_modal">

        </div>
        <div class="modalsub_container clearfix">
          <div id="table_manageModal" class="clearfix">
            <data-table ref="datatable" :getData="getData" id="order-table" :limit="10">
              <th data-sort-field="id">ID</th>
              <th class="txt-center th_active">Active</th>
              <th data-sort-field="balance">{{ $t('funds.balances.balance') }}</th>
              <th data-sort-field="lot">%Lot</th>
              <template slot="body" slot-scope="props">
                <tr>
                  <td :title="props.item.id">{{ props.item.id }}</td>
                  <td class="txt-center">
                    <label id="checked_manageModal">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                    </label>
                  </td>
                  <td :title="props.item.balance">{{ props.item.balance }}</td>
                  <td :title="props.item.lot">
                    <input type="text" class="input_lot">
                  </td>
                </tr>
              </template>
            </data-table>
          </div>
        </div>
        <button class="btn btn_send">{{ $t('common.apply') }}</button>
      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from './Modal';
  import rf from '@/request/RequestFactory'

  export default {
    components: {
      Modal,
    },
    data() {
      return {
        modalName:'ManageModal',
      }
    },
    computed: {
       
    },
    methods: {
       getData (params) {
        return new Promise(resolve => {
          return resolve(
            [ 
              {id: '1', balance: '100.000',},
              {id: '1', balance: '100.000',},
              {id: '1', balance: '100.000',},
            ]
          );
        });
      },
      closeModal () {
        this.$emit(`${this.modalName}:CLOSE`);
      },      
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .box-title-modalsub {
    margin: 5px 0px 0px 0px;
    background-color: $color-white;
    text-align: center;
    overflow: hidden;
    padding-bottom: 20px;
    span {
      display: inline-block;
      padding: 5px 10px;
      position: relative;
      font-size: $font-big-20;
      font-family: $font-family-roboto-medium;
      color: $color-eden;
      line-height: 30px;
      &:before {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: -60px;
      }
      &:after {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: -60px;
      }
    }
  }
  .modalsub_container {
    padding-bottom: 10px;
    background-color: $color-alabaster;
    #table_manageModal {
      #order-table {
        padding-bottom: 0px;
        th {
          color: $color-grey !important;
          &.txt-center {
            text-align: center;
          }
          &.th_active {
            &:after {
              display: none;
            }
          }
          &:last-child {
            text-align: left !important;
          }
        }
        tr {
          td {
            color: $color-grey-dark !important;
            padding: 5px 0px 4px 20px !important;
            &.txt-center {
              text-align: center;
            }
            &:last-child {
              padding-right: 20px !important;
              text-align: left !important;
            }
          }
          &:hover {
            background-color: $color-bright-gray !important;
          }
        }
      }
    }
    .input_lot {
      width: 100%;
      height: 31px;
      border-radius: 3px;
      border: solid 1px $color-alto;
      line-height: 20px;
      padding: 5px 15px;
      font-size: $font-root;
      color: $color-grey-dark;
    }
  }
  .btn_send {
    display: block;
    margin: 30px auto 40px auto;
    width: 360px;
    height: 50px;
    border-radius: 3px;
    background-color: $color-jungle-green;
    line-height: 20px;
    padding: 15px;
    letter-spacing: 1.1px;
    color: $color-white;
    font-size: $font-root;
    text-transform: uppercase;
    font-family: $font-family-roboto-bold;
    &:hover, &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
    }
  }
  .col-6 {
    width: 50%;
    float: left;
  }
  #checked_manageModal {
    display: block;
    position: relative;
    width: 18px;
    height: 18px;
    margin: auto;
    cursor: pointer;
    font-size: $font-title-size-small;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: solid 1px $color-grey
    }
    input:checked ~ .checkmark {
      background-color: $color-jungle-green;
      border-color: $color-jungle-green;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark:after {
      left: 5px;
      top: 1px;
      width: 7px;
      height: 11px;
      border: solid $color-white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

</style>

<style lang="scss">
  @import "@/assets/sass/variables";
  #ManageModal {
    .modal-footer {
      display: none;
    }
    .modal-body {
      padding: 0px;
    }
    table {
      tbody {
        tr.empty-data:hover {
          background-color: transparent !important;
        }
      }
    }
  }
  #SpotExchange_page #order-group-block #order-group #sub-accounts #ManageModal #order-table th:last-child {
    text-align: left !important;
    width: 180px;
    &:before {
      display: none;
    }
  }
</style>