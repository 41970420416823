<template>
  <div id="contact_details" class="clearfix">
     <div class="clearfix"></div>
    
      <div id="header_contract_details" @click="showContractDetails()">
        <span class="txt">{{ $t('margin.contract_details') }}</span><i class="icon icon-arrow2"></i>
      </div>
      <div class="clearfix"></div>
      <div id="content_contract_details">
        <div class="box_contract_details clearfix">
            
          <div class="lastPriceWidget">
            <div :class="{'priceWidget': true, 'up': lastPriceChange > 0, 'down': lastPriceChange < 0}"><span :title="$t('margin.last_price')">{{contract.last_price | formatMarginPrice(contract.tick_size)}} <i :class="{'icon': true, 'icon-arrow5': lastPriceChange > 0, 'icon-arrow6': lastPriceChange < 0}"></i></span></div>
            <!-- <div :class="{'priceWidget': true, 'up': lastPriceChange > 0, 'down': lastPriceChange < 0">{{contract.last_price | formatMarginPrice(contract.tick_size)}} <i class="icon icon-arrow5 icon-arrow6"></i> <span class="amplitude">$0.06</span></div> -->
            <!-- <div class="priceWidget"><span :title="$t('margin.last_price')">{{contract.last_price | formatMarginPrice(contract.tick_size)}}</span></div> -->
            <div class="clearfix"></div>
            <div class="limits nowrap">
              <a class="indicativeSettlePrice">
                <span :title="$t('margin.index_price')">{{indexPrice | formatMarginPrice(contract.tick_size)}}</span></a>
                <span class="fairPrice">/<a class="tooltipWrapper"><span :title="$t('margin.mark_price')">{{contract.mark_price | formatMarginPrice(contract.tick_size)}}</span></a></span>
              <div class="positionDeleverageIndicatorWrapper box_value_right">
                <div class="tooltipWrapper">
                  <div class="leverageLight statusGreen" :class="{'inactive': !isActiveIndicator(1)}"></div>
                  <div class="leverageLight statusGreen" :class="{'inactive': !isActiveIndicator(2)}"></div>
                  <div class="leverageLight statusGreen" :class="{'inactive': !isActiveIndicator(3)}"></div>
                  <div class="leverageLight statusYellow" :class="{'inactive': !isActiveIndicator(4)}"></div>
                  <div class="leverageLight statusRed" :class="{'inactive': !isActiveIndicator(5)}"></div>
                </div>
                <span class="full_value_right cost_sell">{{ $t('margin.indicator') }}</span>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>

          <div class="contractStats" @mouseover="isMouseOverContract = true" @mouseleave="isMouseOverContract = false">
            <div class="tooltip1">
              <div class="lineItem">
                <span class="key">{{ $t('margin.pricing_source') }}</span>
                <span class="value">{{ $t('margin.amanpuri_index') }}</span>
              </div>
              <span class="tooltipText1"><span class="underline">{{ $t('margin.pricing_source') }}</span> {{ $t('margin.pricing_source_details') }}</span>
            </div>
            <div class="lineItem">
              <span class="key">{{ $t('margin.amanpuri_index_price') }}</span>
              <span class="value">{{indexPrice | formatMarginPrice(contract.tick_size)}}</span>
            </div>
            <div class="tooltip1">
              <div class="lineItem">
                <span class="key">{{ isMouseOverContract ? $t('margin.24hturnover') : $t('margin.24h_volume') }}</span>
                <span v-if="isMouseOverContract" class="value">{{ contract.total_turnover_24h | formatMarginPrice(contract.tick_size) }} {{ contract.settle_currency }}</span>
                <span v-else class="value">{{ contract.total_volume_24h | formatMarginPrice(contract.tick_size) }} {{ contract.quote_currency }}</span>
              </div>
              <span class="tooltipText1"><span class="underline">{{ $t('margin.24hturnover') }}</span> {{ $t('margin.24h_turnover_tooltip') }}</span>
              <!-- <span class="tooltipText1"><span class="underline">{{ $t('24H Turnover') }}</span> {{ $t('margin.24h_turnover_tooltip') }}</span> -->
            </div>
            <!-- <div class="tooltip1">
              <div class="lineItem">
                <span class="key">{{ $t('margin.24hturnover') }}</span>
                <span class="value">{{ contract.total_turnover_24h | formatMarginPrice(contract.tick_size) }} {{ contract.settle_currency }}</span>
              </div>
              <span class="tooltipText1"><span class="underline">{{ $t('margin.24hturnover') }}</span> {{ $t('margin.24h_turnover_tooltip') }}</span>
            </div> -->
            <div class="tooltip1">
              <div class="lineItem">
                <span class="key">{{ isMouseOverContract ? $t('margin.open_value') : $t('margin.open_interest') }}</span>
                <span v-if="isMouseOverContract" class="value">{{ contract.open_value | formatMarginPrice(contract.tick_size) }} {{ contract.settle_currency | uppercase }}</span>
                <span v-else class="value">{{ contract.open_interest | formatMarginPrice(contract.tick_size) }} {{ getContractValue('quantity', contract) | uppercase }}</span>
              </div>
              <span class="tooltipText1"><span class="underline">{{ $t('Open Value') }}</span> {{ $t('margin.open_value_tooltip') }}</span>
            </div>
            <!-- <div class="tooltip1">
              <div class="lineItem">
                <span class="key">{{ $t('margin.open_value') }}</span>
                <span class="value">{{ contract.open_value | formatMarginPrice(contract.tick_size) }} {{ contract.settle_currency | uppercase }}</span>
              </div>
                <span class="tooltipText1"><span class="underline">{{ $t('Open Value') }}</span> {{ $t('margin.open_value_tooltip') }}</span>
            </div> -->
            <div v-if="contract.type === 2" class="tooltip1">
              <div class="lineItem">
              <span class="key">{{ $t('margin.funding_rate') }}</span>
              <span class="value">
                <span class="pricePos up">{{ caculatorPecent(contract.funding_rate) | formatDigitsNumber(3) }}% </span>
                <span class="currentTime">{{ $t('margin.in_hour',{0 : formatDurationHour(), 1 : formatDurationMinute(), 2 : formatDurationSecond()})}} </span>
              </span>
            </div>
            <span class="tooltipText1"><span class="underline">{{ $t('Funding Rate') }}</span> {{ $t('margin.funding_rate_tooltip') }}</span>
            </div>
            <div class="tooltip1">
              <div class="lineItem">
                <span class="key">{{ $t('margin.contract_value') }}</span>
                <span class="value">{{ getContractValue('contract_value', contract) }}</span>
              </div>
              <span class="tooltipText1"><span class="underline">{{ $t('Contract Value') }}</span> {{ $t('margin.contract_value_tooltip') }}</span>
            </div>
            <div class="lineItem">
              <span class="key">{{ $t('margin.expiry_date') }}</span>
              <span v-if='contract.type === 1' class="value">{{  contract.expiry | convertToLocalTime }}</span>
              <span v-else class="value">{{ $t('common.perpetual') }}</span>
            </div>
            <div class="lineItem">
              <span class="key"><router-link :to="getLayout()" class="">{{ $t('margin.more_details') }}</router-link>
              </span>
            </div>
          </div>

        </div>
      </div>
  </div>
</template>

<script type="text/javascript">
  import rf from '@/request/RequestFactory'
  import { mapGetters, mapMutations } from 'vuex';
  import { getLayoutPath } from '@/routes';
  import moment from 'moment';
  import Numeral from '@/lib/numeral';
  import Utils from '@/common/Utils'

  export default {
    components: {
    },
    computed: {
      ...mapGetters(['contract', 'indexPrice', 'currentSymbol', 'position', 'indicator']),
      lastPriceChange() {
        if (this.contract) {
          return this.contract.last_change;
        }
        return 0;
      },
    },
    data() {
      return {
        moment: moment,
        isMouseOverContract : false,
        countdownFundingTime:moment.duration(8,'hours'),
        value: 0,
      }
    },
    watch: {
      currentSymbol (value) {
        this.value = this.getIndicatorLevel(this.indicator);
      }
    },
    methods: {
      ...mapMutations(['updateIndicator']),
      getSocketEventHandlers() {
        return {
          MarginIndicatorUpdated: this.onMarginIndicatorUpdated,
          MarginPositionUpdated: this.onPositionUpdated,
        };
      },
      onMarginIndicatorUpdated(data) {
        this.updateIndicator(data);
      },
      onPositionUpdated() {
        this.value = this.getIndicatorLevel(this.indicator);
      },
      getIndicatorLevel (data) {
        if (window.isAuthenticated) {
          let arr = _.filter(data, (item) => { return item.key.includes(this.currentSymbol); });
          if (!this.position || this.position.current_qty == 0 || this.position.pnl_ranking <= 0) {
            return 0;
          }
          if (this.position.pnl_ranking <= arr[0].value) {
            return 1;
          } else if (this.position.pnl_ranking <= arr[1].value) {
            return 2;
          } else if (this.position.pnl_ranking <= arr[2].value) {
            return 3;
          } else if (this.position.pnl_ranking <= arr[3].value) {
            return 4;
          } else {
            return 5;
          }
        }
        return 0;
      },
      isActiveIndicator (index) {
        return this.value >= index;
      },
      getContractValue(referrence, contract){
        return Utils.getUnit(referrence, contract)
      },
      getLayout(){
        return getLayoutPath({name: 'Contract Specification Index'}, true) + _.get(this.contract, 'symbol', 'btc')
      },
      showContractDetails() {
        var element = document.getElementById("content_contract_details");
        element.classList.toggle("isHidden");
        var element = document.getElementById("header_contract_details");
        element.classList.toggle("isRemove");
      },
      caculatorPecent(value) {
        return Numeral(value).value() * 100;
      },

      updateCountdownFundingTime() {
        const fundingTime = moment.utc(this.contract.funding_timestamp);
        const now = moment.utc();
        const duration = moment.duration(fundingTime.diff(now));
        this.countdownFundingTime = duration;
      },

      formatDuration(){
        const hour = this.countdownFundingTime.hours();
        const minute = this.countdownFundingTime.minutes();
        const sec = this.countdownFundingTime.seconds();
        return `${hour}h ${minute}m ${sec}s`;
      },

      formatDurationHour () {
        return this.countdownFundingTime.hours();
      },

      formatDurationMinute () {
        return this.countdownFundingTime.minutes();
      },

      formatDurationSecond () {
        return this.countdownFundingTime.seconds();
      }
    },
    created(){
      setInterval(()=>{
        this.updateCountdownFundingTime();
        this.value = this.getIndicatorLevel(this.indicator);
      },1000)
    }
   }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';

  .box_value_right {
    position: relative;
    .full_value_right {
      position: absolute;
      top: 100%;
      background-color: #222832;
      color: #cccccc;
      min-width: 250px;
      line-height: 20px;
      padding: 1px 5px;
      z-index: 5;
      border-radius: 3px;
      box-shadow: 0px 0px 5px rgba(88, 88, 88, 0.5);
      display: none;
      font-family: $font-family-roboto;
    }
    .cost_sell {
      text-align: left;
      left: -200px;
    }
    &:hover {
      cursor: help;
      .full_value_right {
        display: block;
      }
    }
  }
  #your_position {
    height: 100%;
  }
  .up {
    color: $color-orange;
  }
  .down {
    color: $color-denim;
  }
  #contact_details {
    margin-top: -1px;
  }
  #header_contract_details {
    height: 41px;
    background-color: $color-ebony-clay;
    padding: 10px 15px;
    line-height: 20px;
    font-size: $font-root;
    color: $color-orange;
    font-family: $font-family-roboto-bold;
    cursor: pointer;
    border-top: solid 1px $color-bright-gray-dark;
    border-bottom: solid 1px $color-bright-gray-dark;
    .icon {
      float: right;
      font-size: $font-mini-mini;
      display: inline-block;
      height: 20px;
      line-height: 20px;
    }
    .icon-arrow2 {
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    &.isRemove {
      .icon-arrow2 {
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Safari */
        transform: rotate(180deg);
      }
    }
  }
  #content_contract_details {
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    /*height: 250px;*/
    opacity: 1;
    background-color: #111722;
    &.isHidden {
      height: 0px;
      opacity: 0.5;
      overflow: hidden;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .box_contract_details {
      padding: 11px 15px;
      .lastPriceWidget {
        line-height: 25px;
        margin-bottom: 10px;
        text-align: center;
        .priceWidget {
          color: $color-grey;
          letter-spacing: 0.6px;
          font-size: $font-big-20;
          font-family: $font-family-roboto-bold;
          vertical-align: middle;
          .icon {
            font-size: $font-big;
          }
          &.up {
            color: $color-green;
          }
          &.down {
            color: $color-denim;
          }
          .amplitude {
            font-size: $font-smaller;
            color: $color-grey;
            letter-spacing: 0.2px;
          }
        }
        .limits {
          text-align: center;
          color: $color-grey-sliver;
          font-size: $font-root;
          letter-spacing: 0.2px;
          font-family: $font-family-roboto-medium;
          .indicativeSettlePrice {
            padding: 0px 3px;
            color: $color-grey-dusty;
            &:hover {
              color: $color-white;
              background-color: rgba(45, 172, 145, 0.5);
            }
          }
          .tooltipWrapper {
            padding: 0px 3px;
            color: $color-grey-sliver;
            &:hover {
              color: $color-white;
              background-color: rgba(45, 172, 145, 0.5);
            }
          }
        }
        .positionDeleverageIndicatorWrapper {
          display: inline-block;
          position: relative;
          padding-left: 5px;
          &:before {
            content: "";
            height: 16px;
            margin: auto 0;
            width: 1px;
            background-color: $color-grey;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
          }
          .leverageLight {
            display: inline-block;
            height: 12px;
            width: 6px;
            border: 1px solid $color-grey-dusty;
            margin-right: -2.5px;
            border-radius: 1px;
            position: relative;
            top: 2px;
          }
          .tooltipWrapper {
            padding-right: 5px;
            padding-bottom: 1px;
            &:hover {
              color: #ffffff;
              background-color: rgba(45, 172, 145, 0.5);
            }
          }
          .statusGreen {
            background-image: initial;
            background-color: #52b370;
          }
          .statusYellow {
            background-image: initial;
            background-color: #f9b008;
          }
          .statusRed {
            background-image: initial;
            background-color: #f90000;
          }
          .inactive {
            background-image: initial;
            background-color: rgba(70, 177, 103, 0.1);
            border-top-color: rgb(144, 140, 131);
            border-right-color: rgb(144, 140, 131);
            border-bottom-color: rgb(144, 140, 131);
            border-left-color: rgb(144, 140, 131);
          }
        }
      }
      .contractStats {
        .lineItem {
          padding: 0 6px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          word-wrap: normal;
          font-size: $font-smaller;
          line-height: 22px;
          color: $color-grey;
          span {
            display: inline-block;
            &.key {
              float: left;
              a {
                color: $color-blue-custom;
                &:hover {
                  text-decoration: underline;
                  color: $color-blue-custom;
                }
              }
            }
            &.value {
              font-family: $font-family-roboto-bold;
              float: right;
              text-align: right;
              &.up {
                color: $color-orange;
              }
              &.down {
                color: $color-denim;
              }
            }
          }
        }
      }
    }
  }

  .tooltip1 {
    position: relative;
    .key,
    .value {

      &:hover {
        cursor: help;
      }
    }
  }

  /* Tooltip text */
  .tooltip1 .tooltipText1 {
    visibility: hidden;
    width: 200px;
    background-color: #222832;
    color: #cccccc;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 13px;
  
    /* Position the tooltip text - see examples below! */
    box-shadow: 0px 0px 3px #cccccc;
    position: absolute;
    z-index: 50;
    right: 10px;
    bottom: 20px;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip1:hover .tooltipText1 {
    visibility: visible;
  }

  .underline {
    text-decoration: underline;
    display: block;
  }
</style>