<template>
  <div class="spot-overview">
    <div class="right-content-wrapper">
      <div class="info-coin">
        <div class="info-coin-left">
          <div class="info-coin-left-header">
            <p class="est-value">
              {{ $t(estimatedTotal) }}
            </p>
            <div class="icon-wrapper" v-if="!tongglePrice">
              <img
                :src="require(`../assets/icon/eyes.svg`)"
                @click="clickHide()"
              />
            </div>
            <div class="icon-wrapper" v-else>
              <img
                :src="
                  require(`../assets/icon/hidePriceIcon${
                    theme === 'light-theme' ? '' : '-dark'
                  }.svg`)
                "
                @click="clickHide()"
              />
            </div>
          </div>

          <div v-if="!tongglePrice">
            <p class="price-coin">
              {{ totalSpotBtcBalance | formatNumberAndDecimal }} BTC
            </p>
            <p class="rounding">
              ≈ ${{ totalSpotUsdBalance | formatNumberAndDecimal }}
            </p>
          </div>
          <div v-else class="hidePrice">***********</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../component/Header.vue";
import { mapState } from "vuex";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
    };
  },
  methods: {
    clickHide() {
      this.$emit("clickHide");
    },
  },
  components: {
    Header,
  },
  props: {
    totalSpotBtcBalance: {
      type: String,
      default: "",
    },
    totalSpotUsdBalance: {
      type: String,
      default: "",
    },
    tongglePrice: {
      type: Boolean,
      default: false,
    },
    estimatedTotal: {
      type: String,
      default: "funds.spot_esstimate",
    },
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
img {
  cursor: pointer;
}

.hide-icon {
  width: 18px;
}
.spot-overview {
  .right-content-wrapper {
    h1 {
      margin-bottom: 26px;
      font-weight: 600;
      font-size: 34px;
      color: $color-grey-dark;
    }
    .info-coin {
      display: flex;
      justify-content: space-between;

      .info-coin-left {
        .info-coin-left-header {
          margin-bottom: 8px;
          display: flex;
          align-items: center;
        }

        p {
          margin-bottom: 0px;
        }
        .est-value {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: inline-block;
          color: var(--text-close);
        }
        .icon-wrapper {
          margin-left: 8px;
          img {
            width: 19px;
            height: 14px;
          }
        }
        .price-coin,
        .hidePrice {
          font-weight: 700;
          font-size: 36px;
          margin-bottom: 12px;
          color: var(--text-primary-color);
          @media screen and (max-width: 992px) {
            font-size: 20px;
          }
        }
        .rounding {
          font-size: 16px;
          font-weight: 400;
          color: var(--text-close);
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .right-content-wrapper {
      .info-coin {
        .info-coin-left {
          .est-value {
            font-size: 16px;
            line-height: 19px;

            img {
              width: 19px;
              margin-left: 14px;
            }
          }
          .price-coin {
            font-size: 20px;
            line-height: 24px;
          }
          .rounding {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
}
</style>
