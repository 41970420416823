<template>
  <div class="infinite-scroll" @scroll="handleScroll">
    <DeviceItem
        v-for="(item, index) in items"
        :key="item.id"
        :deviceName="`${item.kind}, ${item.operating_system}, ${item.platform}`"
        :loginTime="convertDate(item.created_at)"
        :ip="item.latest_ip_address"
        :kind="item.kind"
        @delete="confirmRemoveDevice(item.id, item.kind, item.operating_system, item.platform )"
    />
    <div v-if="loading" class="loading">Loading...</div>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import DeviceItem from "@/components/setting_pages/DeviceItem.vue";
import moment from "moment/moment";

export default {
  components: {
    DeviceItem
  },
  data() {
    return {
      items: [],
      loading: false,
      currentPage: 1,
      nextPageUrl: null,
    };
  },
  mounted() {
    this.loadMore();
  },
  methods: {
    async loadMore() {
      if (this.loading) return;

      this.loading = true;
      try {
        const params = {
          page: this.currentPage,
        };

        const response = await rf.getRequest('UserRequest').getUserDevice(params);
        if (response && response.data.data) {
          this.items = [...this.items, ...response.data.data];
          this.currentPage = response.data.current_page + 1;
          this.nextPageUrl = response.data.next_page_url;
        }

        if (!this.nextPageUrl) {
          this.noMoreData = true;
        }
      } catch (error) {
        console.error('Lỗi khi tải dữ liệu thiết bị người dùng:', error);
      } finally {
        this.loading = false;
      }
    },
    handleScroll(event) {
      const container = event.target;

      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
        if (!this.loading && !this.noMoreData) {
          this.loadMore();
        }
      }
    },
    convertDate(date) {
      var dateFormat = 'YYYY-MM-DD HH:mm:ss';
      var testDateUtc = moment.utc(date);
      var localDate = testDateUtc.local();
      return localDate.format(dateFormat);
    },
    async refreshDevice() {
      this.items = [];
      this.currentPage = 1;
      this.nextPageUrl = null;
      this.noMoreData = false;
      await this.loadMore();
    },
    removeDevice (deviceId, deviceSystem, devicePlatform,deviceKind) {
      rf.getRequest('UserRequest').deleteDevice(deviceId).then((res) => {
        this.refreshDevice();

        const divice = window._.capitalize(deviceSystem) + ", " + devicePlatform + ", " + deviceKind ;

        Message.deleteDevice(
            window.i18n.t('address.deleted_device'),
            window.i18n.t('address.deleted_success_message', { name: divice }));
      });
    },
    confirmRemoveDevice (deviceId, deviceKind, deviceSystem, devicePlatform) {
      window.ConfirmationModal.show({
        type: 'primary',
        title: '',
        icon: '/images/icon/icon-devices.svg',
        content: this.$t('account.modal.confirm_trusted_device'),
        btnCancelLabel: window.i18n.t('common.action.no'),
        btnConfirmLabel: window.i18n.t('common.action.yes'),
        onConfirm: () => {
          this.removeDevice(deviceId,deviceKind, deviceSystem, devicePlatform);
        },
        onCancel: () => {

        }
      });
    },
  },
};
</script>

<style scoped>
.infinite-scroll {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100vh;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.infinite-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari và Edge */
}


.loading {
  padding: 10px;
  text-align: center;
}
</style>
