<template>
  <div id="OrderConfirm">
    <modal :name="modalName" :modalHeaderCustom="'order-confirm-header'" :modalBodyCustom="'order-confirm'" :hasModalFooter="true" :modalFooterCustom="'order-confirm-footer'" width="560">
      <template slot="body">
        <div slot="body" class="order-confirm-modal">
          <div class="icon">
            <div class="modal-title">
              <span class="title-l2"></span>
              <h4 class="modal-headerTitle" :class="{'header-color-sell': orderInfo.side === 'sell'}">
                <template v-if="orderInfo.type === 'limit' && orderInfo.stopType === null">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy_limit') }}</template>
                  <template v-else>{{ $t('margin.sell_limit') }}</template>
                </template>
                <template v-if="orderInfo.type === 'market' && orderInfo.stopType === null">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy_market') }}</template>
                  <template v-else>{{ $t('margin.sell_market') }}</template>
                </template>
                <template v-if="orderInfo.stopType === 'stop_limit'">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy') }} {{ $t('margin.stop_limit') }}</template>
                  <template v-else>{{ $t('margin.sell') }} {{ $t('margin.stop_limit') }}</template>
                </template>
                <template v-if="orderInfo.stopType === 'stop_market'">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy') }} {{ $t('margin.stop_market') }}</template>
                  <template v-else>{{ $t('margin.sell') }} {{ $t('margin.stop_market') }}</template>
                </template>
                <template v-if="orderInfo.stopType === 'trailing_stop'">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy') }} {{ $t('margin.trailing_stop') }}</template>
                  <template v-else>{{ $t('margin.sell') }} {{ $t('margin.trailing_stop') }}</template>
                </template>
                <template v-if="orderInfo.stopType === 'take_profit_limit'">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy_take_profit_limit') }}</template>
                  <template v-else>{{ $t('margin.sell_take_profit_limit') }}</template>
                </template>
                <template v-if="orderInfo.stopType === 'take_profit_market'">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy_take_profit_market') }}</template>
                  <template v-else>{{ $t('margin.sell_take_profit_market') }}</template>
                </template>
                <template v-if="orderInfo.stopType === 'oco'">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy') }} {{ $t('margin.oco') }}</template>
                  <template v-else>{{ $t('margin.sell') }} {{ $t('margin.oco') }}</template>
                </template>
                <template v-if="orderInfo.stopType === 'ifd'">
                  <template v-if="orderInfo.side === 'buy'">{{ $t('margin.buy') }} {{ $t('margin.ifd') }}</template>
                  <template v-else>{{ $t('margin.sell') }} {{ $t('margin.ifd') }}</template>
                </template>
              </h4>
              <span class="title-l2"></span>
            </div>
          </div>
          <div class="lead">
            <div>
              <span class="label-buy" v-if="orderInfo.side === 'buy'">{{ $t('margin.buy') }}</span> 
              <span class="label-sell" v-else>{{ $t('margin.sell') }}</span>
              {{getHeaderLabel(orderInfo)}}
            </div>
            <div v-if="orderInfo.stopType === 'oco'">
              <span>{{ $t('title.or') | uppercaseFirst }}</span> 
              <span class="label-buy" v-if="orderInfo.side === 'buy'">{{ $t('margin.buy') }}</span> 
              <span class="label-sell" v-else>{{ $t('margin.sell') }}</span>
              {{getHeaderLabel({...orderInfo, limitPrice: orderInfo.stopPrice})}}
            </div>
            <div v-if="orderInfo.stopType === 'stop_limit' || orderInfo.stopType === 'stop_market' || orderInfo.stopType === 'take_profit_limit' || orderInfo.stopType === 'take_profit_market' || orderInfo.stopType === 'oco'">
              {{ $t('margin.trigger') }}: 
              <template v-if="orderInfo.trigger === 'last'">{{ $t('margin.last_price') }}</template>
              <template v-else-if="orderInfo.trigger === 'mark'">{{ $t('margin.mark_price') }}</template>
              <template v-else>{{ $t('margin.index_price') }}</template>
              @ <template v-if="orderInfo.stopType === 'stop_limit' || orderInfo.stopType === 'stop_market'">{{orderInfo.stopPrice | formatMarginPrice(contract.tick_size)}}</template>
              <template v-else-if="orderInfo.stopType === 'take_profit_market' || orderInfo.stopType === 'take_profit_limit' || orderInfo.stopType === 'oco'">{{orderInfo.triggerPriceInput | formatMarginPrice(contract.tick_size)}}</template>
              <template v-else>{{orderInfo.triggerPrice | formatMarginPrice(contract.tick_size)}}</template>

              <template v-if="['stop_limit', 'stop_market', 'oco'].includes(orderInfo.stopType)">
                <template v-if="orderInfo.side == 'buy'">{{ $t('margin.and_above') }}.</template>
                <template v-else>{{ $t('margin.and_below') }}.</template>
              </template>
              <template v-else>
                <template v-if="orderInfo.side == 'buy'">{{ $t('margin.and_below') }}.</template>
                <template v-else>{{ $t('margin.and_above') }}.</template>
              </template>
            </div>
            <div v-if="orderInfo.stopType === 'trailing_stop'">
              {{ $t('margin.trailing_stop_offset') }}: {{orderInfo.trailValue | formatMarginPrice(contract.tick_size)}}
              <template v-if="!!hasData('trailing_stop', contract)">
                ({{ $t('margin.currently') }} <template v-if="orderInfo.side === 'buy'">{{ $t('margin.ge_equal') }}</template><template v-else>{{ $t('margin.le_equal') }}</template> 
                <template v-if="orderInfo.trigger === 'last'">{{ hasData('trailing_stop', contract, 'last') | formatMarginPrice(contract.tick_size)}})</template>
                <template v-else-if="orderInfo.trigger === 'mark'">{{ hasData('trailing_stop', contract, 'mark') | formatMarginNumber(2)}})</template>
                <template v-else>{{hasData('trailing_stop', contract, 'index') | formatMarginPrice(contract.tick_size)}})</template>
              </template>
            </div>
            <div v-if="orderInfo.stopType === 'ifd'">
              <span>{{ $t('title.then') | uppercaseFirst}}</span> 
              <span class="label-sell" v-if="orderInfo.side === 'buy'">{{ $t('margin.sell') }}</span> 
              <span class="label-buy" v-else>{{ $t('margin.buy') }}</span>
              {{ getHeaderLabel({...orderInfo, symbol: contract.symbol, limitPrice: orderInfo.stopPrice}) }}
            </div>

          </div>
          <div class="content_info">
            <div class="position">
              {{ $t('margin.your_position') }}: {{contract.symbol | uppercase(contract.symbol)}}
              <div class="chart-position">
                <chart-position />
              </div>
            </div>
            <div class="grid">
              <table>
                <tr>
                    <td>{{ $t('margin.order_value') }}</td>
                    <td>{{ orderInfo.orderValue | formatMarginCost }} {{ $t('margin.symbol.btc') }}</td>
                </tr>
                <tr>
                    <td>{{ $t('margin.cost') }} @ <template v-if="position.leverage === 0">{{ $t('margin.cross') }}</template><template v-else>{{ position.leverage | formatMarginNumber(2) }}x</template></td>
                    <td>{{ getCostValue(orderInfo.side) | formatMarginCost(getCostValue(orderInfo.side) == 0 ? '0' : '--') }} {{ $t('margin.symbol.btc') }}</td>
                </tr>
                <tr>
                    <td>{{ $t('margin.available_balance') }}</td>
                    <td>{{ this.balance.availableBalance | formatMarginCapital }} {{ $t('margin.symbol.btc') }}</td>
                </tr>
                <tr>
                    <td>{{ $t('margin.position_after_ex') }}</td>
                    <td>{{ orderInfo.positionSize | formatMarginNumber }}</td>
                </tr>
                <tr>
                    <td>{{ $t('margin.mark_price') }}</td>
                    <td>{{ contract.mark_price | formatMarginNumber(2) }}</td>
                </tr>
                <tr>
                    <td>{{ $t('margin.est_liqui_price') }}</td>
                    <td>{{ estLiquidationPrice | formatMarginNumber(2) }}</td>
                </tr>
                <!-- <tr>
                    <td>{{ $t('margin.est_mark_price') }}</td>
                    <td>{{ estMarkPrice }}</td>
                </tr> -->
              </table>
              <div v-if="orderInfo.stopType === 'stop_limit' || orderInfo.stopType === 'stop_market' || orderInfo.stopType === 'trailing_stop' || orderInfo.stopType === 'take_profit_limit' || orderInfo.stopType === 'take_profit_market' || orderInfo.stopType === 'oco'" class="alert-info">
                <b class="font-b">{{ $t('margin.note') }}:</b> 
                <template v-if="orderInfo.closeOnTrigger">{{ $t('margin.alert_close_trigger_checked') }}</template>
                <template v-else>{{ $t('margin.alert_close_trigger_uncheck') }}</template>
              </div>
              <!--
              <label class="label_check">
                  <input type="checkbox" name="dontShowAgain" value="">{{ $t('margin.do_not_show_again') }}
              </label>
              -->
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
    import rf from '@/request/RequestFactory'
    import Modal from "@/components/shared_components/common/Modal";
    import ChartPosition from './ChartPosition.vue';
    import MarginCalculator from '@/components/marginexchange_pages/common/MarginCalculator.js';
    import { mapGetters } from 'vuex';
    import BigNumber from "bignumber.js";

    export default {
        components: {
            Modal,
            ChartPosition
        },
        data() {
            return {
                modalName:'OrderConfirm',
                orderInfo: {
                    
                }
            };
        },
        watch: {
            indexPrice(value){}
        },
        computed: {
            ...mapGetters({ contract: 'contract', balance: 'mamBalance', position: 'mamPosition', indexPrice: 'indexPrice' }),
            estLiquidationPrice(){
                let price = '';
                if (this.orderInfo.type === 'limit') {
                    price = this.orderInfo.limitPrice;
                } else {
                    price = this.contract.last_price;
                }
 
                let availableBalance = this.balance.maxAvailableBalance;
                let quantity = this.orderInfo.side == 'buy' ? parseInt(this.orderInfo.quantity) : parseInt(this.orderInfo.quantity) * -1;
                const position = MarginCalculator.tryToMatchPosition(price, quantity, this.position, availableBalance);
                let estLiquidationPrice = position.liquidation_price;

                if (estLiquidationPrice == 'NaN') {
                    estLiquidationPrice = 0;
                }
                return Number(estLiquidationPrice).toFixed(2);
            },
            // estMarkPrice(){
            //     //deprecated this method
            //     let leverage = this.position.leverage;
            //     if (leverage === 0) {
            //         leverage = 1 / this.position.required_init_margin_percent;
            //     }

            //     let margin = MarginCalculator.getOpenOrderMargin(this.orderInfo.limitPrice, this.orderInfo.quantity, this.position, this.balance);
            //     let resultingQty = new BigNumber(`${this.position.current_qty || 0}`).add(`${this.orderInfo.quantity || 0}`).toString();
            //     let estLiquidationPrice = MarginCalculator.getLiquidationPrice(this.position, resultingQty, margin);
            //     let numerator = Math.abs(Math.abs(estLiquidationPrice) - this.orderInfo.limitPrice).toFixed(2);
            //     let estMarkPrice = numerator / this.orderInfo.limitPrice;
            //     if(!this.orderInfo.limitPrice) return '--'
            //     return (estMarkPrice * 100).toFixed(2) + "%" + " (" + numerator + ")";
            // }
        },
        methods: {
            getHeaderLabel(order){
                const quantity = order && order.quantity ? this.$options.filters.formatPrice(order.quantity, '0') : this.$options.filters.formatPrice(this.orderInfo.quantity, '0')
                const symbol = order && order.symbol ? this.$options.filters.uppercase(order.symbol) : this.$options.filters.uppercase(this.contract.symbol)
                const limitPrice = order && order.limitPrice ? this.$options.filters.formatMarginPrice(order.limitPrice, this.contract.tick_size) : this.$options.filters.formatMarginPrice(this.orderInfo.limitPrice, this.contract.tick_size)
                const stopPrice = order && order.stopPrice ? this.$options.filters.formatMarginPrice(order.stopPrice, this.contract.tick_size) : this.$options.filters.formatMarginPrice(this.orderInfo.stopPrice, this.contract.tick_size)
                const contract = this.$tc('margin.contract.pluralization', parseInt(quantity), {n: parseInt(quantity)})
                if(this.orderInfo.type === 'limit' && !this.orderInfo.stopType || this.orderInfo.stopType === 'stop_limit' || this.orderInfo.stopType === 'take_profit_limit')
                    return this.$t('margin.contract_of_symbol_at_limit_price', {symbol, contract, quantity, limitPrice})

                if(this.orderInfo.stopType == 'oco')
                    return this.$t('margin.contract_of_symbol_at_limit_price', {symbol, contract, quantity, limitPrice})

                if(this.orderInfo.stopType == 'ifd')
                    return this.$t('margin.contract_of_symbol_at_limit_price', {symbol, contract, quantity, limitPrice})
                else
                    return this.$t('margin.contract_of_symbol_at_market', {symbol, contract, quantity})
            },
            getCostValue(side){
                return side == 'buy' ? this.orderInfo.costValue : this.orderInfo.costValueSell
            },
            hasData(stopType, contract, text){
                if(contract && this.orderInfo.stopType == stopType){
                    const data = {
                        'last': contract.last_price && new BigNumber(contract.last_price).add(this.orderInfo.trailValue),
                        'mark': contract.mark_price && new BigNumber(contract.mark_price).add(this.orderInfo.trailValue),
                        'index': this.indexPrice && new BigNumber(this.indexPrice).add(this.orderInfo.trailValue)
                    }
                    if(text) return data[text]
                    return data[this.orderInfo.trigger]
                }
                return 0
            },
            initData(orderInfo) {
                // console.log('initData', orderInfo);
                this.orderInfo = orderInfo;
            }
        },
        mounted() {

        }
    }
</script>

<style lang="scss">
    @import '@/assets/sass/variables';

    .order-confirm {
        padding: 0 !important;
    }

    .order-confirm-header {
        padding-bottom: 0px !important;
    }

    .order-confirm-footer {
        padding-top: 20px !important;
        padding-bottom: 35px !important;

        td {
            text-align: left;
            width: 50%;

            button {
                font-family: $font-family-roboto-bold;
                font-weight: 500;
                text-transform: uppercase;
                font-size: $font-root !important;
                max-width: 130px !important;
                height: 40px !important;
                border-radius: 3px;

                &:hover {
                    background-color: $color-blue-custom !important;
                    color: $color-white !important;
                    border-color: $color-blue-custom !important;
                }
            }

            .btn-cancel {
                color: $color-jungle-green !important;
                border: 1px solid $color-jungle-green !important;
                background-color: transparent !important;
            }

            .btn-buy {
                background-color: $color-jungle-green !important;
                color: $color-white !important;
            }

            .btn-disable {
                pointer-events: none;
                opacity: 0.5;
            }

            &:first-child {
                text-align: right;
            }
        }
    }
</style>

<style lang="scss" scoped>
    @import '@/assets/sass/variables';
    .order-confirm {
        padding: 0;
        .order-confirm-modal {
            .icon {
                text-align: center;
                padding: 0px 60px;
                .modal-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .title-l2{
                        width: 25%;
                        margin: 10px 0px 10px 0px;
                        height: 1px;
                        border-radius: 5px;
                        background-color: $color-light-grey;
                    }

                    .modal-headerTitle {
                        font-size: $font-big-20;
                        color: $color-dark-green;
                        margin: 0;
                        width: 50%;
                        font-family: $font-family-roboto-medium;
                    }
                    .header-color-sell {
                        color: $color-thunderbird;
                    }
                }
            }
            .lead {
                margin: 15px 0px 15px 0px;
                font-size: $font-big-20;
                text-align: center;
                color: $color-black;

                span.label-buy {
                    color: $color-jungle-green;
                }

                span.label-sell {
                    color: #f74a41;
                }
            }
            .content_info {
                background-color: $color-white-smoke;
                padding: 10px 25px;
                overflow-y: scroll;
                max-height: 50vh;

                .position {
                    text-align: center;
                    font-size: $font-medium-bigger;
                    font-family: $font-family-roboto-medium;
                    color: $color-black;

                    .chart-position {
                        margin-top: 10px;
                    }
                }

                .grid {
                    width: 100%;
                    overflow: auto;
                    margin-top: 19px;

                    table {
                        width: 100%;
                        border: 1px solid $color-alto;

                        tr {
                            td {
                                text-align: center;
                                border-left: 1px solid $color-alto;
                                padding: 5px;

                                &:first-child {
                                    border-left: 0px;
                                }
                                &:last-child {
                                    font-family: $font-family-roboto-bold;
                                }
                            }

                            &:first-child {
                                td {
                                    padding-top: 10px;
                                }
                            }

                            &:last-child {
                                td {
                                    padding-bottom: 10px;
                                }
                            }
                        }
                    }

                    .alert-info {
                        margin-top: 13px;
                        background-color: rgba(45, 172, 145, 0.15);
                        padding: 16px;
                        text-align: center;
                        color: $color-grey-dark;
                        font-size: $font-small;
                        .font-b {
                          font-family: $font-family-roboto-bold;
                          font-weight: 500;
                        }
                    }

                    .label_check {
                        line-height: 22px;
                        font-weight: normal;
                        cursor: pointer;
                        margin: 0px;
                        float: right;
                        color: $color-grey-dusty;
                        margin-top: 5px;

                        input {
                            float: left;
                            margin-right: 5px;
                            margin-left: 0px;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }
    
</style>