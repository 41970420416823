var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_mid_table",attrs:{"id":"top_mid_table"}},[_c('div',{staticClass:"content_orderbook"},[_c('div',{staticClass:"head_orderbook"},[_c('div',{staticClass:"group_sort_orderbook"},[_c('ul',{staticClass:"list_sort_od"},[_c('li',{class:{ selected: _vm.tab === _vm.tabConfig.bothSellBuy }},[_c('div',{staticClass:"icon_sort_od",on:{"click":function($event){_vm.tab = _vm.tabConfig.bothSellBuy}}},[_c('span',{attrs:{"id":"tooltip-target-1"}},[_c('img',{attrs:{"src":require(`@/assets/images/icons/bothSellBuy${
                      _vm.theme === 'light-theme' ? '' : '-dark'
                    }.svg`)}}),_c('div',{staticClass:"tool-tip-custom"},[_vm._v(" "+_vm._s(_vm.$t("tooltip_order-book"))+" ")])])])]),_c('li',{class:{ selected: _vm.tab === _vm.tabConfig.buy }},[_c('div',{staticClass:"icon_sort_od",on:{"click":function($event){_vm.tab = _vm.tabConfig.buy}}},[_c('span',{attrs:{"id":"tooltip-target-1"}},[_c('img',{attrs:{"src":require(`@/assets/images/icons/buy${
                      _vm.theme === 'light-theme' ? '' : '-dark'
                    }.svg`)}}),_c('div',{staticClass:"tool-tip-custom",class:{ 'tooltip-buy': _vm.tab === _vm.tabConfig.buy }},[_vm._v(" "+_vm._s(_vm.$t("tooltip_buy-book"))+" ")])])])]),_c('li',{class:{ selected: _vm.tab === _vm.tabConfig.sell }},[_c('div',{staticClass:"icon_sort_od",on:{"click":function($event){_vm.tab = _vm.tabConfig.sell}}},[_c('span',{attrs:{"id":"tooltip-target-1"}},[_c('img',{attrs:{"src":require(`@/assets/images/icons/sell${
                      _vm.theme === 'light-theme' ? '' : '-dark'
                    }.svg`)}}),_c('div',{staticClass:"tool-tip-custom"},[_vm._v(" "+_vm._s(_vm.$t("tooltip_sell-book"))+" ")])])])])]),_c('div',{staticClass:"list_decimals_od"},[_c('select-box',{attrs:{"options":_vm.priceGroupsByPair,"isReturnObject":true,"hasCustomLabel":true},scopedSlots:_vm._u([{key:"label_selected",fn:function({ selected }){return [_c('span',[_vm._v(_vm._s(`${selected.precision} `)+_vm._s(_vm.$t("order.order_book.decimals")))])]}},{key:"options",fn:function({ option }){return [_c('span',{staticClass:"full_name_coin_select"},[_vm._v(_vm._s(`${option.precision}`)+" "+_vm._s(_vm.$t("order.order_book.decimals")))])]}}]),model:{value:(_vm.selectedPriceGroup),callback:function ($$v) {_vm.selectedPriceGroup=$$v},expression:"selectedPriceGroup"}})],1)])]),_c('div',{staticClass:"d-lg-none d-flex block_center_od"},[_c('div',{staticClass:"border_block_center_od"},[_c('div',{staticClass:"current-price"},[_c('span',{staticClass:"od_number_fix",class:{
              'grown-price': _vm.changedPrice > 0,
              'down-price': _vm.changedPrice < 0,
            }},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyAmount")(_vm.latestPrice,_vm.currency, "0"))+" ")]),(_vm.currency !== 'usd')?_c('fiat-currency',{attrs:{"classes":"od_number_balance","sub-classes":"text-small","value":_vm.fiatCurrency}}):_vm._e()],1),_c('i',{staticClass:"icon_zhang",class:{
            'icon-arrow5': _vm.changedPrice > 0,
            'icon-arrow6': _vm.changedPrice < 0,
          },attrs:{"size":"12"}})])]),_c('div',{staticClass:"body_orderbook",class:{ body_both: _vm.tab === _vm.tabConfig.bothSellBuy }},[_c('div',{staticClass:"order-red-container",class:{
          active_red_max: _vm.tab === _vm.tabConfig.sell,
          ' active_red_min': _vm.tab === _vm.tabConfig.buy,
        },attrs:{"id":"orderbook_red_max"}},[_c('div',{staticClass:"top-title"},[_c('ul',{staticClass:"title-group"},[_c('li',{staticClass:"od-price"},[_vm._v(" "+_vm._s(_vm.$t("order.order_book.price"))+"("+_vm._s(_vm.currency.toUpperCase())+") ")]),_c('li',{staticClass:"od-amount"},[_vm._v(" "+_vm._s(_vm.$t("order.order_book.amount"))+"("+_vm._s(_vm.coin.toUpperCase())+") ")]),_c('li',{staticClass:"od-total"},[_vm._v(" "+_vm._s(_vm.$t("order.order_book.total"))+"  ")])])]),_c('table',{attrs:{"id":"orderbook_red"}},[_c('tbody',{staticClass:"orderbook_red"},_vm._l((_vm.sellOrderBookDisplay),function(item,index){return (index < _vm.calculateSizeSellOrder(_vm.tab))?_c('tr',{key:index,class:{ 'row-clicked': item.isClickedRow },on:{"click":function($event){$event.stopPropagation();return _vm.onSellOrderBookClicked(item, index)}}},[_c('div',{staticClass:"fix_sell_od",style:({ width: item.percent + '%' })}),_c('td',{staticClass:"sell_color",attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.price),_vm.coin, '--'),_vm.selectedPriceGroup.precision, '0')}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.price),_vm.coin, "--"),_vm.selectedPriceGroup.precision, "0"))+" ")]),_c('td',{staticClass:"padr10 p-right",attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.quantity),_vm.coin, '--'),
                      _vm.coinSettingByPair.fractionPartQuantity,
                      '0'
                    )}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(item.quantity,_vm.coin, "--"), _vm.coinSettingByPair.fractionPartQuantity, "0" ))+" ")]),_c('td',{staticClass:"total-color",attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(_vm.calculateTotalQuantityCoinForDisplay(
                    _vm.sellOrderBookDisplay,
                    item,
                    index,
                    'sell'
                  )),_vm.coin, '--'),
                      _vm.coinSettingByPair.fractionPartQuantity,
                      '0'
                    )}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(_vm.calculateTotalQuantityCoinForDisplay( _vm.sellOrderBookDisplay, item, index, "sell" )),_vm.coin, "--"), _vm.coinSettingByPair.fractionPartQuantity, "0" ))+" ")])]):_vm._e()}),0)])]),_c('div',{staticClass:"d-lg-flex d-none block_center_od w-100"},[_c('div',{staticClass:"border_block_center_od w-100"},[_c('div',{staticClass:"current-price"},[_c('span',{staticClass:"od_number_fix",class:{
                'grown-price': _vm.changedPrice > 0,
                'down-price': _vm.changedPrice < 0,
              }},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyAmount")(_vm.latestPrice,_vm.currency, "0"))+" ")]),(_vm.currency !== 'usd')?_c('fiat-currency',{attrs:{"classes":"od_number_balance","sub-classes":"text-small","value":_vm.fiatCurrency}}):_vm._e()],1),_c('i',{staticClass:"icon_zhang",class:{
              'icon-arrow5': _vm.changedPrice > 0,
              'icon-arrow6': _vm.changedPrice < 0,
            },attrs:{"size":"12"}})])]),_c('table',{class:{
          ' active_blue_min': _vm.tab === _vm.tabConfig.sell,
          ' active_blue_max': _vm.tab === _vm.tabConfig.buy,
          active_both: _vm.tab === _vm.tabConfig.bothSellBuy,
        },attrs:{"id":"orderbook_blue"}},[_c('div',{staticClass:"top-title"},[_c('ul',{staticClass:"title-group"},[_c('li',{staticClass:"od-price"},[_vm._v(" "+_vm._s(_vm.$t("order.order_book.price"))+"("+_vm._s(_vm.currency.toUpperCase())+") ")]),_c('li',{staticClass:"od-amount"},[_vm._v(" "+_vm._s(_vm.$t("order.order_book.amount"))+"("+_vm._s(_vm.coin.toUpperCase())+") ")]),_c('li',{staticClass:"od-total"},[_vm._v(" "+_vm._s(_vm.$t("order.order_book.total"))+"  ")])])]),_c('tbody',{staticClass:"orderbook_blue",class:{
            ' orderbook_blue_min': _vm.tab === 1,
            ' orderbook_blue_max': _vm.tab === 3,
          }},_vm._l((_vm.buyOrderBookDisplay),function(item,index){return (index < _vm.calculateSizeSellOrder(_vm.tab))?_c('tr',{key:index,staticClass:"orderbook__row",class:{ 'row-clicked': item.isClickedRow },on:{"click":function($event){$event.stopPropagation();return _vm.onBuyOrderBookClicked(item, index)}}},[_c('div',{staticClass:"fix_back_od",style:({ width: item.percent + '%' })}),_c('td',{staticClass:"sell_color",attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.price),_vm.coin, '--'),_vm.selectedPriceGroup.precision, '0')}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.price),_vm.coin, "--"),_vm.selectedPriceGroup.precision, "0"))+" ")]),_c('td',{staticClass:"padr10 p-right",attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.quantity),_vm.coin, '--'),_vm.coinSettingByPair.fractionPartQuantity, '0')}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.quantity),_vm.coin, "--"),_vm.coinSettingByPair.fractionPartQuantity, "0"))+" ")]),_c('td',{staticClass:"total-color",attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(_vm.calculateTotalQuantityCoinForDisplay(
                  _vm.buyOrderBookDisplay,
                  item,
                  index,
                  'buy'
                )),_vm.coin, '--'),_vm.coinSettingByPair.fractionPartQuantity, '0')}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(_vm.calculateTotalQuantityCoinForDisplay( _vm.buyOrderBookDisplay, item, index, "buy" )),_vm.coin, "--"),_vm.coinSettingByPair.fractionPartQuantity, "0"))+" ")])]):_vm._e()}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }