<template>
  <div>
    <modal :name="modalName" width="450">
      <template slot="body">
        <h3 class="box-title-modalsub">
            <span>Alert</span>
        </h3>
        <div class="modalsub_container clearfix">
          <div class="des_content_join">
            <span class="txt_des_ctj">{{message}}</span>
          </div>
          <div class="clearfix"></div>
          <button class="btn btn_send" @click="closeModal()">{{ $t('common.action.ok') }}</button>
        </div>

      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from './Modal';
  export default {
    props: {
      message: { type: String, default: '' },
    },
    components: {
      Modal,
      configs: {
        name: '',
      },
    },
    data() {
      return {
        modalName: 'ShowErrorAssignRevokeModal',
      }
    },
    computed: {
    },
    watch: {
    },
    methods: {
      closeModal() {
        this.$emit(`${this.modalName}:CLOSE`);
        window.CommonModal.hide(this.modalName);
      },
    },
    mounted() {
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables.scss";

  .box-title-modalsub {
    margin: -10px 0px 0px 0px;
    background-color: $color-white;
    text-align: center;
    overflow: hidden;
    padding-bottom: 20px;

    span {
      display: inline-block;
      padding: 5px 10px;
      position: relative;
      font-size: $font-big-20;
      font-family: $font-family-roboto-medium;
      color: $color-eden;
      line-height: 30px;

      &:before {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: -60px;
      }

      &:after {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: -60px;
      }
    }
  }

  .group_find_join_modal {
    margin: 0px auto 30px auto;
    width: 320px;
    max-width: 94%;
    overflow: hidden;

    .txt_find {
      display: inline-block;
      float: left;
      line-height: 40px;
      width: 24px;
      font-size: $font-root;
      color: $color-grey-dark;
    }

    .btn-find {
      display: block;
      float: right;
      width: 80px;
      height: 40px;
      color: $color-white;
      margin-left: 10px;
      font-size: 12px;
      letter-spacing: 0.9px;
      border-radius: 3px;
      font-family: $font-family-roboto-bold;
      text-transform: uppercase;
      background-color: $color-jungle-green;
      border: 1px solid $color-jungle-green;

      &:hover, &:focus {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
      }
    }

    .input_find {
      display: inline-block;
      float: left;
      width: calc(100% - 24px - 90px);
      height: 40px;
      border-radius: 3px;
      border: solid 1px $color-alto;
      padding: 10px;
      line-height: 20px;
      font-size: $font-root;
      color: $color-grey-dark;

      &:focus {
        border-color: $color-jungle-green;
      }
    }
  }

  .modalsub_container {
    background-color: $color-alabaster;
    text-align: center;
    padding: 45px 20px 25px 20px;
    min-height: 125px;

    .txt_des_ctj {
      font-family: $font-family-roboto;
    }
    .left_des_ctj {
      padding-left: 60px;
      padding-right: 5px;

      .group_des_ctj {
        font-size: $font-root;
        color: $color-grey-dark;
        line-height: 20px;
        margin-bottom: 8px;
        font-family: $font-family-roboto-bold;
      }
    }
  }

  .btn_send {
    display: block;
    margin: 30px auto 5px auto;
    width: 120px;
    height: 50px;
    border-radius: 3px;
    background-color: $color-jungle-green;
    line-height: 20px;
    padding: 15px;
    letter-spacing: 1.1px;
    color: $color-white;
    font-size: $font-root;
    text-transform: uppercase;
    font-family: $font-family-roboto-bold;

    &:hover, &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
    }
  }

  .col-6 {
    width: 50%;
    float: left;
  }

</style>

<style lang="scss">
  @import "@/assets/sass/variables.scss";

  #ShowErrorAssignRevokeModal {
    .modal-footer {
      display: none;
    }

    .modal-body {
      padding: 0px;
    }

    table {
      tbody {
        tr.empty-data:hover {
            background-color: transparent !important;
        }
      }
    }
  }
</style>
