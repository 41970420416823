import { render, staticRenderFns } from "./BalancesFutures.vue?vue&type=template&id=265dad08&scoped=true&"
import script from "./BalancesFutures.vue?vue&type=script&lang=js&"
export * from "./BalancesFutures.vue?vue&type=script&lang=js&"
import style0 from "./BalancesFutures.vue?vue&type=style&index=0&id=265dad08&prod&lang=scss&scoped=true&"
import style1 from "./BalancesFutures.vue?vue&type=style&index=1&id=265dad08&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265dad08",
  null
  
)

export default component.exports