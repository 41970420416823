<template>
  <div id="master_id_detail" class="master_id_detail clearfix">
    <create-master-account ref='CreateMasterAccount'/>
    <div class="left_content clearfix">
      <button v-if="masterAccount.status == 'closed' || masterAccount.status == undefined" :disabled="isLoading" class="btn btn-creat-account" @click="showCreateMasterAccount()"> <i class="icon icon-plus"></i> {{ $t('mam.create_account') }}</button>
      <button v-else-if="masterAccount.status == 'closing'" class="btn btn-creat-account" disabled>{{ $t('mam.closing_account') }}</button>
      <button v-else class="btn btn-creat-account" :disabled="isLoading" @click="confirmCloseMasterAccount()">{{ $t('mam.close_account') }}</button>
      <div class="clearfix"></div>
      <ul class="list_total clearfix">
        <li class="item">
          <span class="name">{{ $t('mam.current_performance_fee') }}</span>
          <span class="value">{{ masterAccount.performance_rate | formatMarginNumber(2) }} %</span>
        </li>
        <li class="item">
          <span class="name line-height-32">{{ $t('mam.next_performance_fee') }}</span>
          <div class="value1">
            <div v-if='isEditingNextPerFee' class="group_input_value">
              <currency-input :precision="2" 
                                :maxLengthCus='16'
                                class="input_value"
                                :class="{'error': errors.has('performance_fee')}"
                                v-model="nextPerFee" />
              <span class="unit"> %</span>
            </div>
            <div v-else class="group_input_value1">
              <div class="input_value1">{{ masterAccount.next_performance_rate | formatMarginNumber(2) }} %</div>
            </div>
            <div class="text-inline" v-if="masterAccount.status == 'opened'">
              <span v-if='!isEditingNextPerFee' class="btn btn_edit_network" @click="isEditingNextPerFee = !isEditingNextPerFee"><i class="icon-edit"></i></span>
              <div v-else>
                <span class="btn btn_close_network" @click="isEditingNextPerFee = !isEditingNextPerFee"><i class="icon-close2"></i></span>
                <span class="btn btn_save_network" @click="changeNextPerformanceFee()"><i class="icon-save"></i></span>
              </div>
              
            </div>
          </div>
          <div class="clearfix"></div>
            <div class="clearfix" v-if="errors.has('performance_fee')"
                  :class="{ 'msg-error' : errors.first('performance_fee') }">{{ errors.first('performance_fee') }}
            </div>
        </li>
        <li class="item">
          <span class="name">{{ $t('mam.max_drawdown') }}</span>
          <span class="value">{{ (masterAccount.max_drawdown * 100) | formatMarginNumber(2, (masterAccount.status == 'closed' || masterAccount.status == undefined) ? null : '0', true) }} %</span>
        </li>
        <li class="item">
          <span class="name">{{ $t('Total Gain') }}</span>
          <span class="value">{{ (masterAccount.fund_gain * 100) | formatMarginNumber(2, (masterAccount.status == 'closed' || masterAccount.status == undefined) ? null : '0') }} %</span>
        </li>
      </ul>
      <div class="clearfix clearfix-10"></div>
      <ul class="list_total clearfix">
        <li class="item">
          <span class="name">{{ $t('Total Capital') }}</span>
          <span class="value">{{ masterAccount.fund_capital | formatMarginNumber }} {{ $t('margin.symbol.btc') }}</span>
        </li>
        <li class="item">
          <span class="name">{{ $t('Total Balance') }}</span>
          <span class="value">{{ masterAccount.fund_balance | formatMarginNumber }} {{ $t('margin.symbol.btc') }}</span>
        </li>
        <li class="item">
          <span class="name">{{ $t('Unrealised Commission') }}</span>
          <span class="value">{{ masterAccount.unrealised_commission | formatMarginNumber(8, (masterAccount.status == 'closed' || masterAccount.status == undefined) ? null : '0') }} {{ $t('margin.symbol.btc') }}</span>
        </li>
        <li class="item">
          <span class="name">{{ $t('mam.created_at') }}</span>
          <span class="value">{{ masterAccount.status == 'closed' ? null : masterAccount.created_at | convertToLocalTime }}</span>
        </li>
      </ul>
      <div class="forced-close-condition">{{ $t('mam.forced_close_condition') }}</div>
      <div class="clearfix"></div>
      <div class="group_note clearfix">
        <div class="title_note">{{ $t('Note') }}</div>
        <div class="clearfix"></div>
        <div class="content_note clearfix">
          <div class="note_line">{{ $t('mam.guide_1') }}</div>
          <div class="note_line">{{ $t('mam.guide_2') }}</div>
          <div class="note_line">
            {{ $t('mam.guide_3') }}<br>
            {{ $t('mam.guide_4') }}<br>
            {{ $t('mam.guide_5') }}<br>
            {{ $t('mam.guide_6') }}
          </div>
          <div class="note_line">{{ $t('mam.guide_7') }}</div>
          <div class="note_line">{{ $t('mam.guide_8') }}</div>
          <div class="note_line">{{ $t('mam.guide_9') }}</div>
          <div class="note_line">{{ $t('mam.guide_10') }}</div>
        </div>
      </div>
    </div>
    <div class="right_content clearfix">
      <data-table3 class="table_salve_id" :getData="getData" :limit="10" :widthTable="'100%'" ref="datatable" msgEmptyData="msgEmptyData">
        <template slot-scope="props">
          <th class="text-left w_40" @click="props.echoclick">
            {{$t('Type')}}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="text-left w_40" @click="props.echoclick">
            {{$t('Count')}}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="text-left w_20" @click="props.echoclick">
            {{$t('Amount')}}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left txt-medium">{{ props.item.type }}</td>
            <td class="text-left">{{ props.item.count | formatMarginNumber(0, '0') }}</td>
            <td class="text-left">{{ props.item.amount | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
          </tr>
        </template>
      </data-table3>
    </div>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
  import Const from '@/common/Const';
  import CreateMasterAccount from '../common/CreateMasterAccount.vue';
  import Mam from '../common/mam.js'

  export default {
    components: {
      CreateMasterAccount,
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        overviewData: {},
        masterAccount: {},
        isLoading: true,
        isEditingNextPerFee: false,
        nextPerFee: 0,
      }
    },
    props: {

    },
    watch: {
     isEditingNextPerFee(){
       if(!this.isEditingNextPerFee){
         this.errors.clear();
       }
     }
    },
    computed: {

    },
    methods: {
      getEventHandlers() {
        return {
          hideCommonModal: this.onHideCommonModal,
        };
      },
      onHideCommonModal(modalName) {
        if(modalName == 'CreateMasterAccount') {
          this.initData();
        }
      },
      showCreateMasterAccount () {
        window.CommonModal.show('CreateMasterAccount');
      },
      confirmCloseMasterAccount() {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          content: window.i18n.t('mam.confirm.close_account'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.closeMasterAccount()
          },
          onCancel: () => {}
        });
      },
      closeMasterAccount () {
        rf.getRequest("MamRequest").closeMasterAccount({ id: this.masterAccount.id })
          .then(res => {
            if (res && res.success) {
              this.masterAccount = res.data;
              Message.success(window.i18n.t('mam.msg.close_account.success'), {}, {position: 'bottom_left'});
            }
          })
          .catch(error => {
            Message.error(window.i18n.t('mam.msg.close_account.fail'), {}, {position: 'bottom_left'});
            this.convertRemoteErrors(error);
          })
      },
      changeNextPerformanceFee () {
        if (!this.validate()) return;
        rf.getRequest("MamRequest").updateNextPerformanceFee({ next_performance_rate: this.nextPerFee})
        .then(res => {
          if (res && res.data) {
            this.masterAccount.next_performance_rate = res.data.next_performance_rate;
          }
          this.isEditingNextPerFee = !this.isEditingNextPerFee;
          Message.success(window.i18n.t('mam.update.success'), {}, { position: 'bottom_left' });
        })
        .catch(error => {
          if (error && error.response && error.response.data) {
            // Message.error(error.response.data.message, {}, { position: 'bottom_left' });
            this.errors.add('performance_fee', error.response.data.message);
            this.convertRemoteErrors(error);
          }
        })
      },
      validate() {
        this.errors.clear();
        if(!this.nextPerFee){
          this.errors.add('performance_fee', window.i18n.t('mam.change.performance_fee_error_required'));
        }
        if (this.nextPerFee == 0) {
          this.errors.add('performance_fee', window.i18n.t('mam.change.performance_fee_error_min_value'));
        }
        if (this.nextPerFee >= 100) {
          this.errors.add('performance_fee', window.i18n.t('mam.change.next_performance_fee_error_max_value', { MAX_VALUE: '100' }));
        }

        if (this.errors.count() > 0) {
          return false;
        }

        return true;
      },
      getData (params) {
        const { assignCount, assignAmount, revokeCount, revokeAmount, totalCount, totalAmount } = this.overviewData;
        return new Promise(resolve => {
          return resolve(
            [ 
              {type: 'Assign', count: assignCount, amount: assignAmount},
              {type: 'Revoke', count: revokeCount, amount: revokeAmount},
              {type: 'Total', count: totalCount, amount: totalAmount},
            ]
          );
        });
      },
      initData () {
         rf.getRequest("MamRequest").getMasterAccount()
          .then(res => {
            if (res && res.data) {
              let data = res.data;
              this.masterAccount = res.data;
            }
            this.isLoading = false;
          })
          .catch(error => {
            this.convertRemoteErrors(error);
            this.isLoading = false;
          })

        rf.getRequest("MamRequest").getMasterOverview()
          .then(res => {
            if (res && res.data) {
              this.overviewData = res.data;
              this.$refs.datatable.refresh();
            }
          })
          .catch(error => {
          })
      }
    },
    created() {
      this.initData();
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  @import "@/assets/sass/common.scss";
  .note_line {
    padding: 5px 0px;
  }
  .forced-close-condition {
    color: $color-red-warning;
    font-size: $font-small;
    line-height: 20px;
    padding-bottom: 20px;
  }
  .text-inline {
    padding-left: 10px;
  }
  
  .btn_edit_network, .btn_save_network, .btn_close_network {
    color: $color-jungle-green;
    height: 27px;
    padding: 5px 6px;
    line-height: 23px;
    &:active {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    }
  }
  .btn_edit_network {
    .icon-edit:before {
      color: $color-jungle-green;
    }
    &:hover {
      .icon-edit:before {
        color: $color-blue-custom;
      }
    }
  }
  .btn_save_network {
    .icon-save:before {
      color: $color-jungle-green;
    }
    &:hover {
      .icon-save:before {
        color: $color-blue-custom;
      }
    }
  }
  .btn_close_network {
    .icon-close2:before {
      color: $color-jungle-green;
    }
    &:hover {
      .icon-close2:before {
        color: $color-blue-custom;
      }
    }
  }

  .w_40 {
    width: 40%;
  }

  .w_20 {
    width: 20%;
  }

  .clearfix-10 {
    display: block;
    width: 100%;
    height: 10px;
  }

  .line-height-32 {
    line-height: 32px;
  }

  .btn-creat-account {
    display: inline-block;
    float: right;
    min-width: 170px;
    text-align: center;
    line-height: 20px;
    padding: 5px 10px;
    height: 30px;
    border-radius: 3px;
    letter-spacing: 0.92px;
    text-transform: uppercase;
    border: 0px;
    background-color: $color-jungle-green;
    font-family: $font-family-roboto-bold;
    font-size: $font-smaller;
    color: $color-white;

 
    &:hover {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
      outline: none;
    }
  }

  .master_id_detail {
    padding: 20px 0px;

    .left_content {
      padding: 0px 25px;
      margin-right: -1px;
      display: block;
      width: 50%;
      float: left;
      min-height: 240px;
      border-right: 1px solid $color-gallery;

      .list_total {
        margin-bottom: 15px;

        .item {
          display: block;
          width: 100%;
          margin-top: 5px;
          margin-bottom: 5px;
          line-height: 20px;
          float: left;

          .name {
            display: block;
            width: 190px;
            float: left;
            margin-right: 5px;
            font-size: $font-root;
            color: $color-grey-dusty;
          }

          .value {
            display: block;
            overflow: hidden;
            font-weight: 500;
            font-family: $font-family-roboto-medium;
            font-size: $font-root;
            
            .group_2 {
              width: 100px;
              display: block;
              position: relative;

              .input_value {
                width: 100%;
                height: 32px;
                line-height: 20px;
                padding: 5px 10px;
                font-weight: 500;
                padding-right: 25px;
                font-family: $font-family-roboto-medium;
                font-size: $font-root;
              }
              .unit {
                display: inline-block;
                position: absolute;
                top: 0px;
                right: 0px;
                line-height: 20px;
                padding: 5px 6px;
              }
            }
          }
          .value1 {
            display: flex;
            overflow: hidden;
            font-weight: 500;
            font-family: $font-family-roboto-medium;
            font-size: $font-root;
            
            .group_input_value {
              width: 100px;
              display: block;
              position: relative;

              .input_value {
                width: 100%;
                height: 32px;
                line-height: 20px;
                padding: 5px 10px;
                font-weight: 500;
                padding-right: 25px;
                font-family: $font-family-roboto-medium;
                font-size: $font-root;
              }
              .unit {
                display: inline-block;
                position: absolute;
                top: 0px;
                right: 0px;
                line-height: 20px;
                padding: 5px 6px;
              }
            }
            .group_input_value1 {
              width: 50px;
              display: block;
              position: relative;

              .input_value {
                width: 100%;
                height: 32px;
                line-height: 20px;
                padding: 5px 10px;
                font-weight: 500;
                padding-right: 25px;
                font-family: $font-family-roboto-medium;
                font-size: $font-root;
              }
              .input_value1 {
                width: 100%;
                height: 32px;
                line-height: 20px;
                padding: 5px 0px;
                font-weight: 500;
                font-family: $font-family-roboto-medium;
                font-size: $font-root;
              }
              .unit {
                display: inline-block;
                position: absolute;
                top: 0px;
                right: 0px;
                line-height: 20px;
                padding: 5px 6px;
              }
            }

          }
        }
      }
      .group_note {
        border-radius: 1px;
        padding: 15px 20px;
        font-size: 13px;
        line-height: 20px;
        background-color: $color-gallery;
        color: $color-grey-dusty;

        .title_note {
          margin-bottom: 7px;
          line-height: 16px;
          font-weight: 500;
          font-family: $font-family-roboto-medium;
          font-size: $font-root;
          color: $color-grey-dark;
        }
      }
    }

    .right_content {
      padding: 0px 25px;
      display: block;
      width: 50%;
      float: left;
      min-height: 240px;
      border-left: 1px solid $color-gallery;

      .table_salve_id {

        th {
          padding-left: 10px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          background-color: $background-default;
          font-family: $font-family-roboto-medium;
          font-size: $font-small;
        }
    
        tr {

          &:hover {
            background-color: transparent;
          }

          td {
            padding-left: 10px;
            color: $color-grey-dark;

            &.txt-medium {
              font-family: $font-family-roboto-medium;
            }
          
            &:last-child {
              text-align: left;
            }
          }
        }
      }
    }
  }
     
</style>
<style lang="scss">
  @import "@/assets/sass/variables";
  @import "@/assets/sass/common.scss";
  .msg-error {
    color: $color-red-warning;
    font-size: $font-small;
    line-height: 20px;
  }
</style>