<template>
  <div class="status-badge" :class="statusClass">
    <span :class="`status-dot ${statusText === 'Enable' ? 'enabled' : 'disabled'}`"></span>
    <span>{{ statusText }}</span>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  props: {
    status: {
      type: String,
      default: "disabled"
    }
  },
  computed: {
    statusClass() {
      return this.status.toLowerCase();
    },
    statusText() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    }
  }
};
</script>

<style scoped>
.status-badge {
  display: inline-flex;
  align-items: center;
  background-color: var(--badge-status);
  color: var(--text-primary-color);
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  max-width: 63px;
  max-height: 24px;
  gap: 4px;
}

.disabled.status-dot {
  width: 6px;
  height: 6px;
  background-color: #C8CDCD;
  border-radius: 50%;
}

.enabled {
  background-color: #4caf50;
}

.enabled.status-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #4caf50;
}

.disabled {
  background-color: #2e2e2e;
}

.status-badge.disabled .status-dot {
  background-color: #555;
}
</style>
