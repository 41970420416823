<template>
  <div>
    <transition name="modal" v-if="show">
      <div>
      <div class="modal-mask" v-if="configs.mask == true">
      </div>
        <div class="modal show" tabindex="-1" role="dialog" :id="name">
          <div class="modal-dialog" v-bind:class="configs.position" role="document" v-bind:style="{ width: width + 'px'}">
            <div class="modal-content" v-bind:class="modalContentCustom">
              <div class="modal-header" :class="modalHeaderCustom" v-if="enableClose || title">
                <button type="button" id="btn_close_modal" class="close" @click="hideModal()" v-if="enableClose"><span aria-hidden="true" class="icon-close"></span></button>
                <h4 class="modal-title" v-if="title" v-bind:style="cssTitle" v-html="title"></h4>
              </div>
              <div class="modal-body" :class="modalBodyCustom">
                <slot name="body" :configs="configs"></slot>
              </div>
              <div class="modal-footer" v-if="hasModalFooter" v-bind:class="modalFooterCustom">
                <table>
                  <tbody>
                    <tr>
                      <td v-for="button in configs.buttons">
                        <button type="button" class="btn-cancel btn btn-default" v-bind:style="button.style" v-bind:class="button.class" @click="button.callback">{{ button.label }}</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
        </div>
    </transition>
  </div>
</template>

<script>
  window.CommonModal = {
    show: function(modalName, configs){
      window.app.$broadcast('showCommonModal', modalName, configs);
    },
    hide: function(modalName){
      window.app.$broadcast('hideCommonModal', modalName);
    }
  }
  export default {
    props: {
      name: {
        default : 'defaultModal',
        type    : String
      },
      title: {
        default : '',
        type    : String
      },
      cssTitle: {
        default : () => {},
        type    : Object
      },
      enableClose: {
        default : true,
        type    : Boolean
      },
      width: {
        default : '',
        type    : String
      },
      hasModalFooter: {
        default : true,
        type    : Boolean
      },
      modalContentCustom: {
        default : '',
        type    : String
      },
      modalHeaderCustom: {
        default : '',
        type    : String
      },
      modalFooterCustom: {
        default : '',
        type    : String
      },
      modalBodyCustom: {
        default : '',
        type    : String
      }
    },
    data() {
      return {
        show: false,
        configs: {
          mask: true,
          buttons: [],
          close: null,
        }
      }
    },
    methods: {
      hideModal() {
        window.app.$broadcast('hideCommonModal', this.name);
      },
    },
    created () {
      let self = this;
      this.$on('showCommonModal', (modalName, userConfigs) => {
        if (modalName == self.name){
          self.show = true;
          self.configs = Object.assign(self.configs, userConfigs);
          if (self.configs.onShown) {
            window.setTimeout(function() {
              self.configs.onShown();
            }, 0);
          }
        }
      });
      this.$on('hideCommonModal', (modalName) => {
        if (modalName == self.name){
          self.show = false;

          if (self.configs.close) {
            self.configs.close();
          }
        }
      });
    }
  }
</script>

<style lang="scss"  scoped>
  @import "@/assets/sass/variables.scss";
  #btn_close_modal {
    &:hover {
      .icon-close {
        background-color: $color-light-grey;
        color: $color-grey;
      }
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    display: block;
    transition: opacity .3s ease;
  }

  .modal {
    text-align     : center;
    padding        : 0!important;
    z-index        : 10000;
    pointer-events : none;

    &:before {
      content        : '';
      display        : inline-block;
      height         : 100%;
      vertical-align : middle;
      margin-right   : -4px;
    }

    .modal-dialog {
      vertical-align : middle;
      display        : inline-block;
      text-align     : left;

      &.bottom-left {
        position : absolute;
        left     : 15px;
        bottom   : 15px;
        margin   : 0;
      }

      &.bottom-right {
        position : absolute;
        right    : 15px;
        bottom   : 15px;
        margin   : 0;
      }

      .modal-content {
        border-radius: 0;
        pointer-events: auto;

        .modal-header {
          padding: 15px;
          position: relative;
          border: 0;

          .close {
            opacity: 0.9;
            z-index: 100;
            position: relative;
            outline: none;
            background: transparent !important;
            &:hover {
              opacity: 1;
            }
            .icon-close {
              border-radius: 50%;
              color: $color-grey;
              line-height: 32px;
              overflow: hidden;
              text-align: center;
              display: inline-block;
              font-size: $font-smaller;
              float: right;
              width: 30px;
              height: 30px;
              margin: 0px 0px 0 0;
              cursor: pointer;
              padding-left: 2px;
              &:hover {
                background-color: $color-light-grey;
                color: $color-grey;
              }
            }
          }
        }
        .modal-title {
          color: #404040;
          line-height: 3;
          font-size: 14px;
        }

        .modal-body {
          padding: 15px 15px 11px 15px;
        }

        .modal-footer {
          .btn {
            border-radius : 4px;
            padding       : 9px 30px;
            color         : white;
            border        : none;
            font-size     : 13px;
          }
          border-top: 0px;
          padding-top: 8px;
          padding-bottom: 40px;
          padding-left: 20px;
          padding-right: 20px;

          table {
            width: 100%;
          }

          td {
            padding-left: 10px;
            padding-right: 10px;
          }

          button {
            width: 100%;
          }
        }
        .btn-cancel {
          background: #808080;
          &:hover {
            background: #8d8d8d;
          }
        }
        .btn-confirm {
          background: #0070C0;
          transition: all ease-out 0.3s;
          &:hover {
            background: #005EA4;
          }
        }
      }
    }
  }
  .modalContentCustom{
    min-height: 400px !important;
    .modal-header{
      padding: 13.5px !important;
    }
    .modal-body{
      padding-top: 0px !important;
    }
    .modalFooterCustom{
      padding-bottom: 22px !important;
    }
  }
</style>
