<template>
    <!-- MAM多账户管理系统 -->
    <div class="slideDetail mamBox">
        <div class="what_is_mam">
            <p class="mam_tit">WHAT IS MAM?</p>
            <div class="mam_logo">
                <p v-html="$t('mam_detail')"></p>
            </div>
        </div>
        <div class="mam_txt_desc">
            <div class="mam_txt_desc_con">
                <div class="mam_desc">
                    <p class="mam_tit">{{ $t('mam_detail_whatis') }}</p>
                    <p class="mam_txt f_tip">{{ $t('mam_detail_whatis_tip') }}</p>
                    <p class="mam_txt" v-html="$t('mam_detail_whatis_desc')"></p>
                </div>
                <div class="mam_desc">
                    <p class="mam_tit">{{$t('mam_detail_methods')}}</p>
                    <p class="mam_txt f_tip" v-html="$t('mam_detail_methods_tip')"></p>
                    <p class="mam_txt" v-html="$t('mam_detail_methods_desc')"></p>
                </div>
            </div>
        </div>
        <div class="mamAnLogo">
            <div class="mamAnLogo_i">
                <img class="" src="@/assets/images/rebate/mamAnLogo_1.png" />
            </div>
            <div class="mamAnLogo_i">
                <img class="" src="@/assets/images/rebate/mamAnLogo_2.png" />
            </div>
            <div class="mamAnLogo_i">
                <img class="" src="@/assets/images/rebate/mamAnLogo_3.png" />
            </div>
            <div class="mamAnLogo_i">
                <img class="" src="@/assets/images/rebate/mamAnLogo_4.png" />
            </div>
        </div>
        <div class="mam_revoke">
            <div class="mam_revoke_con">
                <div class="revoke_box">
                    <p class="revoke_tit">{{$t('mam_revoke_tit')}}</p>
                    <p class="revoke_desc" v-for="(item, index) in $t('mam_revoke_list')" :key="index">- &nbsp;{{ item }}</p>
                    <div class="mt_30">
                        <p class="revoke_desc" v-for="(item, index) in $t('mam_revoke_numlist')" :key="index" v-html="item"></p>
                    </div>
                </div>
                <div class="revoke_box">
                    <p class="revoke_tit">{{$t('mam_computed_tit')}}</p>
                    <p class="revoke_desc" v-for="(item, index) in $t('mam_computed_profitloss_list')" :key="index">- &nbsp;{{ item }}</p>
                    <div class="mt_30">
                        <p class="revoke_desc" v-for="(item, index) in $t('mam_computed_profitloss_demolist')" :key="index" v-html="item"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mam_contractUs">
            <div class="mam_con">
                <div class="becomeMam mam_contractUs_i">
                    <img src="@/assets/images/rebate/mam_become.png" class="iconImg" />
                    <p class="mam_contractUs_i_tit" v-html="$t('mam_becomeMam')"></p>
                    <p class="mam_contractUs_i_subtit">{{ $t('mam_becomeMam_desc') }}</p>
                </div>
                <div class="becomeMam mam_contractUs_i">
                    <img src="@/assets/images/rebate/mam_reward.png" class="iconImg" />
                    <p class="mam_contractUs_i_tit">{{ $t('mam_reward_tit') }}</p>
                    <p class="mam_contractUs_i_subtit" v-html="$t('mam_reward_desc')"></p>
                </div>
                <div class="becomeMam mam_contractUs_i">
                    <img src="@/assets/images/rebate/mam_require.png" class="iconImg" />
                    <p class="mam_contractUs_i_tit">{{ $t('mam_require_tit') }}</p>
                    <p class="mam_contractUs_i_subtit" v-html="$t('mam_require_desc')"></p>
                </div>              
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    export default {
        data() {
            return {

            }
        },
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/sass/variables";
    @import "@/assets/sass/slide/slidedetail";
    $bg_EC7232: #EC7232;
    $c_fff: #fff;
    $bg_F5F4F0: #F5F4F0;
    $bg_5BB2AD: #5BB2AD;
    $bg_46918B: #46918B;
    $w_80: 80%;
    $w_41: 41%;
    
    .mamBox {
        width: 100%;
        .mt_30 {
            margin-top: 30px;
        }
        .what_is_mam {
            padding: 50px 0px 50px;
            height: 395px;
            overflow: hidden;
            box-sizing: border-box;
            text-align: center;
            background-color: $bg_EC7232;
            .mam_tit {
                font-size: 30px;
                color: $c_fff;
            }
            .mam_logo {
                width: 220px;
                height: 272px;
                margin: 0px auto 0px;
                background: url('@/assets/images/rebate/mam-tit.png') no-repeat;
                background-size: 100% 100%;
                position: relative;
                p {
                    color: #fff;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 18px;
                }
            }
        }
        .mam_txt_desc {
            background-color: $bg_F5F4F0;
            .mam_txt_desc_con {
                width:$w_80;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                margin: 0px auto;
                padding: 44px 0px;
                .mam_desc {
                    width: $w_41;
                    .mam_tit {
                        font-size: 17px;
                        color: #231815;
                        font-weight: 500;
                    }
                    .mam_txt {
                        font-size: 13px;
                        color: rgba(141, 141, 141, 1);
                        line-height: 22px;
                    }
                    .f_tip {
                        color: #76C3BF;
                    }
                }
            }
        }
        .mam_revoke {
            background-color: $bg_5BB2AD;
            padding: 70px 0px;
            .mam_revoke_con {
                width: $w_80;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                margin: 0px auto;
                .revoke_box {
                    width: $w_41;
                    background-color: $bg_46918B;
                    border-radius: 10px;
                    padding: 32px 24px;
                    color: #fff;
                }
            }
        }
        .mam_con {
            width:$w_80;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 0px auto;   
        }
        .mam_contractUs {
            background-color: #131B27;
            padding: 64px 0px;
            .mam_contractUs_i {
                padding: 60px 35px;
                background-color: #080A0F;
                border-radius: 10px;
                color: #fff;
                width: 26%;
                box-sizing: border-box;
                .iconImg {
                    width: 76px;
                    height: 76px;
                    margin: 0px auto 22px;
                    border-radius: 50%;
                    background-color: #fff;
                    font-size: 24px;
                    display: block;
                }
                .mam_contractUs_i_tit {
                    text-align: center;
                    font-size: 14px;
                    line-height: 24px;
                    span {
                        color: #ed7232;
                    }
                }
                .mam_contractUs_i_subtit {
                    font-size: 12px;
                    line-height: 30px;
                    margin-top: 20px;
                    span {
                        color: #ed7232;
                    }
                }
            }
        }
        .mamAnLogo {
            width: 55%;
            height: 137px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            margin: 0px auto;
            position: relative;
            .mamAnLogo_i {
                margin-top: 24px;
                width: 154px;
                height: 154px;
                box-shadow: 4px 4px 4px rgba(0,0,0,.2);
                border-radius: 10px;
                transition: transform .5s ease-in-out;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }
            .mamAnLogo_i:hover {
                transform: translateY(-50px);
                
            }
            
        }
    }
</style>