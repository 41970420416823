<template> 
  <div id="confirmationModalSecond" class="modal fade" :class="configs.class" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" :style="{height: configs.heightModal, with: configs.withModal}">
    <div class="modal-mask"></div>      
    <div class= "modal-dialog" :class="{'dialog22' :configs.type === 'resetMail'}" role="document">
      <div class="modal-content" :class="{'borderTop' :configs.type === 'convertSmallBalance'}" >
        <div class="modal-header">
          <span class=" symbol message-icon"></span>
          <button type="button" class="close" data-dismiss="modal" @click="redirectLanding" v-if="configs.type === 'confirm-succeed-modal'"><span aria-hidden="true" class="icon-close"></span></button>
          <button type="button" class="close" data-dismiss="modal" @click="redirectLogin" v-else-if="configs.type === 'confirm-fail-modal'"><span aria-hidden="true" class="icon-close"></span></button>
          <button type="button" class="close" data-dismiss="modal" @click="redirectLogin" v-else-if="configs.type === 'resetPW'"><span aria-hidden="true" class="icon-close"></span></button>
          <button type="button" class="close" data-dismiss="modal" @click="onCancel" v-else-if="configs.type === 'success'"><span aria-hidden="true" class="icon-close"></span></button>
          <div v-else-if="configs.type==='convertSmallBalanceSuccess'"></div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-else><span aria-hidden="true" class="icon-close"></span></button>
        </div>
        <div class="modal-body">
          <div class="t-center">
              <div class="">
                <!-- -----------------------Modal confirm convert small balance success ---------------------- -->
                
                <div v-if="configs.type === 'convertSmallBalanceSuccess'" class="fo_light_blue">
                  <!-- <span class="icon-email2 icon_modal_confir login-complete-icon"></span> -->
                  <img class="img_coin_chart" :src="configs.img" width="100px"/>
                  <div class="wrap-notification2">
                    <div class="tit_modal_confirm" v-html="configs.title"></div>
                    <div class="total-coin">+ {{configs.total}} 4IRE</div>
                  </div>
                  <div class="mart50 padding-14">
                    <button type="button " data-dismiss="modal" v-on:click="redirectSendConfirmEmail" class="btn btn-confirm btn-space btn-primary btn-forgotToLogin">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
              </div>
        </div>
        </div>
      </div>
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>

<script>
  window.confirmationModalSecond = {
    show: function(configs){
      window.app.$broadcast('showModalSecond', configs);
    }
  }
  export default {
    data(){
      return{
        configs: {
          type            : '',
          title           : '',
          content         : '',
          btnCancelLabel  : this.$t('common_action.cancel'),
          btnConfirmLabel : this.$t('common_action.confirm'),
          onConfirm       : () => {},
          onCancel        : () => {},
          size            : 'small',
          class           : '',
          heightModal     : '',
          withModal       : '',
          displayCloseButton : false,
        },
      }
    },
    props: {
      showRePass: { type: Boolean, default: false },
      linkOnCancel: { type: String},
    },
    methods: {
      onCancel() {
        if (typeof this.linkOnCancel !== 'undefined') {
          let path = this.$router.options.routes.find(el => el.name == this.linkOnCancel);
          if (!path) {
            console.error('Cannot find path by name!', this.linkOnCancel);
          }
          this.$router.push(path ? path : '/');
        }
      },
      show () {
        const modal = $('#confirmationModalSecond');
        modal.find('.close').unbind('click').click(this.configs.onCancel);
        modal.find('.btn-cancel').unbind('click').click(this.configs.onCancel);
        modal.find('.btn-confirm').unbind('click').click(this.configs.onConfirm);
        modal.modal('show');
      },
      hide () {
        $('#confirmationModalSecond').modal('hide');
      },
      redirectLogin(){
        this.$router.push({ name: 'Login' });
      },
      redirectLanding(){
        this.$router.push({ name: 'Landing Page' });
      },
      redirectForgotPassword(){
        this.$router.push({ name: 'Landing Page' });
      },
      redirectResetPassword(){
        this.showRePass = true;
        this.$emit('showRePass', this.showRePass);
      },
      redirectSendConfirmEmail(){
        this.$emit('exit',true);
      }
    },
    created () {
      let self = this;
      var compatibleBrowser = typeof Object['__defineSetter__'] === 'function';
      this.$on('showModalSecond', (userConfigs) => {
        self.configs = Object.assign(self.configs, userConfigs);
        this.show();
      });
    }
  };
</script>
<style lang="scss">
    @import "@/assets/sass/variables";
  .dialog22{
    margin: 9% auto 0px auto !important;
  }
  #confirmationModalSecond {
    .close {
      &:hover {
        .icon-close {
          background-color: $color-light-grey;
          color: $color-grey;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  #confirmationModalSecond {
    @import "@/assets/sass/variables";
    position   : fixed;
    z-index: 9998;
    text-align : center;
    padding    : 0!important;
    transition-duration: 0.3s;
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: block;
      transition: opacity .3s ease;
      overflow: hidden;
    }
    .modal{
      text-align: center;
      padding: 0!important;
      overflow: hidden;
    }
    .modal:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
    .icon-help2, .icon-email3, .icon-money {
      font-size: 70px;
      color: $color-jungle-green;
      margin-top: 0px;
    }
    .icon-congratulations{
      font-size: 110px;
      color: #2DAC91;
      margin-top: 0px;
    }
    .tit_modal_confir{
      color: $color-eden;
      font-size: $font-big-20;
      font-family: $font-family-roboto-medium;
      line-height: 29px;
      margin-bottom: 15px;
      margin-top: 25px;
    }
    .h2-title {
      font-family: Roboto-Regular;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #999999;
    }
    .tit_modal_confir_convert_balance {
      color: $color-eden;
      font-size: 30px;
      font-family: $font-family-roboto-medium;
      line-height: 29px;
      margin-bottom: -55px;
      margin-top: -5px;
      max-width: 350px;
      margin-left: 90px;
    }
    .tit_modal_confirm{
      font-family: $font-family-roboto-medium;
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #12575f;
    }
    .total-coin {
      font-family: Roboto-Regular;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #333333;
    }
    .modal-dialog {
      z-index: 10000;
      width: 550px;
      max-width: 80%;
      max-height: 80%;
      vertical-align : middle;
      display        : inline-block;
      text-align     : left;
      margin: 6% auto 0px auto;

      .modal-content {
        &.borderTop{
          border-top: 10px solid #2dac91;
        }
        border-radius: 5px;
        position:unset;
        border: 0;
          .modal-title {
            color: $color_grey_text;
            display: inline-block;
            font-size: $font-big-20;
          }
          .close {
            height: 30px;
            width: 30px;
            opacity: 0.9;
            z-index: 100;
            position: relative;
            outline: none;
            background: transparent !important;
            &:hover {
              opacity: 1;
              .icon-close {
                background-color: $color-light-grey;
                color: $color-grey;
              }
            }
            
            .icon-close {
              border-radius: 50%;
              // background-color: $color-grey-white;
              color: $color-grey;
              line-height: 32px;
              overflow: hidden;
              text-align: center;
              display: inline-block;
              font-size: $font-smaller;
              float: right;
              width: 30px;
              height: 30px;
              margin: 0px 0px 0 0;
              cursor: pointer;
              &:hover{
                background-color: $color-light-grey;
                color: $color-grey;
              }
            }
          }
        .modal-header{
          position: relative;
          padding: 15px;
          border-bottom: none;
        }
        .modal-body{
          border: 0px;
          color: rgb(51,51,51);
          position: unset;
          padding: 15px 25px 60px !important;
          font-size: $font-small;
          .content_text{
            margin: 4px auto 13px auto;
            display: block;
            max-width: 350px;
            min-height: 60px;
            font-family: $font-family-roboto;
            font-size: $font-medium-bigger;
            font-weight: 500;
            line-height: 1.56;
            text-align: center;
            color: $color-grey-dark;
            strong{
              font-size: $font-medium-bigger;
              color: $color-grey;
              font-weight: 400;
              line-height: 24px;
            }
          }
          .primary{
            margin-top: 30px;
            margin-bottom: 20px;
            .btn-primary{
              background-color: $color-yellow;
              border-color: $color-yellow;
              color: $color_white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
              &:hover, &:active, &:focus{
                background: $color-corn;
                border-color: $color-corn;
                color: $color-white;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
          }
          .success{
            .btn-primary{
              background-color: $color-green;
              border-color: $color-green;
              color: $color_white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
              &:hover, &:active, &:focus{
                background: $color-corn;
                border-color: $color-corn;
                color: $color-white;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
          }
          .warning{
            .btn-primary{
              background-color: $color-yellow-pale;
              border-color: $color-yellow-pale;
              color: $color_white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
              &:hover, &:active, &:focus{
                background: $color-corn;
                border-color: $color-corn;
                color: $color-white;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
          }
          .danger{
            .btn-primary{
              background-color: $color-red;
              border-color: $color-red;
              color: $color_white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
              &:hover, &:active, &:focus{
                background: $color-corn;
                border-color: $color-corn;
                color: $color-white;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
          }
          button{
            box-shadow: 0 1px 0 rgba(0,0,0,.05);
            border: 1px solid $color-jungle-green;
            padding: 10px 10px;
            font-size: $font-root;
            line-height: 20px;
            height: 40px;
            border-radius: 3px;
            font-weight: 500;
            min-width: 130px;
            margin: 5px;
            font-weight: 700;
            text-transform: uppercase;
            background-color: $color-jungle-green;
            color: $color_white;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            &:hover, &:active, &:focus{
              background-color: $color-blue-custom;
              border-color: $color-blue-custom;
              -webkit-transition: 0.5s;
              transition: 0.5s;
            }
          }
        }
      }
      
      .modal-footer {
        padding: 15px;
        background-color: $color-white;
        border-top: none;
        .btn {
          border-radius : 0px;
          padding       : 7px 30px;
          color         : $color-white;
          border        : none;
          font-size     : 13px;
        }
        .btn-cancel {
          background: $color_black_logo;
          &:hover {
            opacity: 0.2;
          }
        }
      }
      .complete-icon::before, .login-complete-icon::before{
        color:$color-green;
      }
      .complete-icon{
        font-size: 70px;
      }
      .login-complete-icon{
        font-size:80px;
      }
      // new css
      .fo_green2{
        display: flex;
        width: 70%;
        margin:0 auto;
      } 
      .title-l{
          margin: 13px 0px 15px 0px;
          height: 1px;
          width: 10%;
          border-radius: 5px;
          background-color: $color-light-grey;
      }
      .title-header-modal{
        width: 80%;
        font-family: $font-family-roboto-medium;
        color: $color-eden !important;
        margin-top: 0 !important
      }
      .title-header-content{
        font-family: $font-family-roboto-medium;
        color: $color-grey-dark;
        font-weight: bold;
      }
      .modal-content-text{
        font-family: $font-family-roboto;
        max-width: 57%;
        margin: 0 auto;
        color: $color-grey-dusty;
        // font-weight: bold;
      }
      .btn-forgotToLogin{
          margin-top:20px !important;
          width: 190px;
          height: 50px !important;
          border-radius: 3px !important;
          border : 1px solid $color-jungle-green !important;
          background-color: $color-jungle-green !important;
          &:hover{
            background-color: $color-curious-blue !important;
            border : 1px solid $color-curious-blue !important;
          }
      }
      .fo_green_4{
        // width : 345px;
        margin: 0 auto;
      }
      .fo_green_4 .wrap-notification h4{
        color : $color-grey-dark;
        font-size: 16px;
        line-height: 25px;
        font-family: $font-family-roboto-medium;
      }
      .fo_green_4 p{
        // color : $color-dark-green !important;
        color :red;
        font-size :14px;
        font-family: $font-family-roboto-medium;
      }
      .fo_green_4 .wrap-notification h4 p strong{
        font-family: $font-family-roboto-bold;
      }
    }
    &.modal-success-email .modal-dialog {
      height: 250px;
    }
    .wrap-notification2{
      text-overflow: ellipsis;
      overflow: hidden;
      padding-top: 22px;
      padding-bottom:2px;
      white-space: nowrap;
    }

    &.modal-success-password .modal-dialog {
      height: 330px ! important;
      width: 480px ! important;
    }

    .icon-save{
      font-size: $font-title-size-big-small-3;
    }
    .icon-save:before {
      content: "\E922";
      color: $text-green;
    }
  }
  .login_confirm_modal {
    .modal-body {
      padding-bottom: 0px !important;
    }
  }
  .padd-bottom-20{
    height: 80px !important;
  }
  .padd-bottom-10{
    height: 9px !important;
  }
  .padd-bottom-15{
    height: 18px !important;
  }
</style>
