<template>
  <div id="faq-page">
    <div id="left-sitebar">
      <TabFaq @tabType="tabType" :dataTab="dataCategories" />
    </div>
    <div id="right_content">
      <div class="faq-header">
        <div>
          FAQ
        </div>
      </div>
      <div class="faq-right_content" v-if="dataSubCategories?.length>0">

        <FAQItem :dataList="dataSubCategories" />
        <!-- <div class="list-title">
          <div v-for="(item, index) in dataTitle" :key="index" class="item-title"
            :class="idActive === item?.id ? 'active' : ''" @click="setActiveTitle(item?.id)">
            {{ $t(item?.title) }}
          </div>
        </div> -->

        <!-- <div class="list-content">
          <div v-for="(item, index) in dataSubCategories" :key="index" class="item-content" @click="idActiveSub = item?.id">
            <FAQItem :id="item.id" :title="item?.title_en" />
          </div>
        </div> -->

        <!-- <div class="list-title">
          <div v-for="(item, index) in dataTitle" :key="index" class="item-title" :class="titleActive === item?.title ? 'active': ''" @click="setTitle(item?.title)">
                {{ $t(item?.title) }}
          </div>
        </div> -->

        <!-- <div class="list-content">
          <div v-for="(item, index) in dataItem" :key="index" class="item-content" @click="valueActive=item?.label">
            <div class="item-content_label" :class="valueActive === item?.label ? 'active': ''">
              {{ $t(item?.label) }}
            </div>

            <div class="item-content_text" :class="valueActive === item?.label ? 'active': ''">
              <div v-html="$t(item?.content)"></div>
            </div>
          </div>
        </div> -->
        <!-- {{ dataType }} {{ titleActive }} -->

      </div>
    </div>
  </div>
</template>
<script>
import TabFaq from "@/components/shared_components/common/TabFaq.vue";
import Const from '@/common/Const';
import rf from '@/request/RequestFactory'
import FAQItem from './FAQItem.vue'

export default {
  components: {
    TabFaq,
    FAQItem,
  },
  data() {
    return {
      idCategoriesActive: null,
      dataCategories: [],
      dataSubCategories: [],
      dataList: Const.FAQ_DATA,
      dataTitle: [],
      idActive: null,
      idActiveSub: null,
      // titleActive: '2FA',
      dataItem: [],
      // valueActive: ''
    }
  },
  created() {
    // this.listTitle();
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    tabType(value) {
      console.log('value', value)
      this.idCategoriesActive = value?.id
      this.getSubCategories(value.sub_categories)
    },
    getListFaqCategories() {
      rf.getRequest('UserRequest').getListCategories().then(res => {
        const data = res?.data?.reverse()
        this.dataCategories = data
        if(data.length >0){
          this.getSubCategories(data[0].sub_categories)
        }
        // this.getListTitle(res?.data)
      })
    },

    getSubCategories(dataSub) {
      this.dataSubCategories = dataSub
    },

    getListTitle(dataList) {
      const data = dataList?.map((i) => {
        return {
          id: i?.id,
          title: i?.title_en
        }
      })
      this.dataTitle = data
      this.idActive = data[0]?.id
      this.setActiveTitle(data[0]?.id)
    },
    setActiveTitle(value) {
      this.idActive = value
      const dataFind = this.dataList.find(item => item.id === value)
      this.dataItem = dataFind?.sub_categories
    },

    // listTitle() {
    //   const data = this.dataList.filter(item => item.type === this.dataType)
    //   const uniqueTitles = [];
    //   const filteredData = data.filter(item => {
    //     if (!uniqueTitles.includes(item.title)) {
    //       uniqueTitles.push(item.title);
    //       return true;
    //     }
    //     return false;
    //   });
    //   // this.titleActive = filteredData?.[0]?.title
    //   this.dataTitle = filteredData
    //   this.setTitle(filteredData?.[0]?.title);
    // },
    // setTitle(value) {
    //   this.titleActive = value
    //   const dataTypes = this.dataList.filter(item => item.type === this.dataType)

    //   const data = dataTypes.filter(item => item?.title === value)
    //   this.dataItem = data?.[0].data
    // },
  },
  mounted() {
    document.title = this.$t("faq") + ` – ${this.tabTitle}`;
    window.addEventListener('beforeunload', this.scrollToTop);
    this.getListFaqCategories()
  },
  watch: {
    dataType() {
      // this.listTitle();
    },
    // dataTitle(newData) {
    //   this.setTitle()
    // },
    // titleActive(newData) {
    //   console.log('newData :>> ', newData);
    // },

  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";

.m-bottom {
  margin-bottom: 8px;
}

.dark-theme {
  #faq-page {
    background-color: $color-night;

    .title-container {
      background: $color-black-shark;
      border-top: 4px solid $color-black;
    }
  }
}

#faq-page {
  display: flex;
  height: 100%;
  border-top: solid 4px var(--color-black);
  color: var(--dark-1);

  #left-sitebar {
    // max-width: 340px;
    background-color: var(--background-color-primary);
    border-right: solid 4px var(--color-black);
  }

  #right_content {
    flex: 1%;
    background-color: var(--background-home-page);
    width: 75%;
    padding: 10px;

    .faq-header {
      margin-bottom: 30px;
      font-size: 30px;
      font-weight: bold
    }

    .faq-right_content {
      padding: 24px 0;
      border: 1px solid #eaecef;
      border-radius: 16px;
    }

    .list-title {
      display: flex;
      gap: 20px;
      font-size: 20px;
      border-bottom: 1px solid #eaecef;
      padding: 0 24px;
    }

    .item-title {
      cursor: pointer;
      color: var(--dark-1);
      border-bottom: 2px solid;
      border-color: transparent;

      &.active {
        border-color: #03aac7;
        font-weight: bold;
      }
    }

    .list-content {
      padding: 0 24px;
      margin-top: 20px;
    }

    .item-content {
      border-bottom: 1px solid #eaecef;
      padding: 10px;

      &:last-child {
        border-bottom: 0;
      }
    }

    .item-content_label {
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;

      &:hover,
      &.active {
        color: #03aac7;
      }
    }

    .item-content_text {
      display: none;

      &.active {
        display: block;
      }
    }

  }

  .overScreen {
    width: 100%;
  }

  .hideLeftMenu {
    display: none;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 32px;
    padding-top: 16px;

    #right_content {
      width: 100%;
      margin-top: 16px;

      .list-title {
        font-size: 18px;
        line-height: 22px;
      }

      .item-content_label {
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
          color: #03aac7;
        }
      }
    }
  }
}
</style>
