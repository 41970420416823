<template>
  <div>
    <modal :name="modalName" width="660">
      <template slot="body">
        <h3 class="box-title-modalsub"><span>{{ $t('mam.join_fund') }}</span></h3>
        <div class="search-master">{{ $t('mam.search_master') }}</div>
        <div class="group_find_join_modal">
          <span class="txt_find">{{ $t('mam.id') }}</span>
            <currency-input :precision="0" 
                                  :maxLengthCus='16'
                                  v-model="searchKey"
                                  @focus="resetError"
                                  class="input_find"/>
          <button class="btn btn-find" @click="searchMasterAccount()">{{ $t('mam.find') }}</button>
          <div class="clearfix"></div>
          <div class="clearfix"></div>
          <span class="txt_find_hide">{{ $t('mam.id') }}</span>
          <div class="clearfix" :class="{ 'msg-error' : errors.first('master_id') }" v-if="errors.first('master_id')">{{ errors.first('master_id') }}</div>
          <div class="clearfix" :class="{ 'msg-error' : errors.first('master_id_require') }" v-if="errors.first('master_id_require')">{{ errors.first('master_id_require') }}</div>
        </div>
        <div class="modalsub_container clearfix" :class="{ background_white : !showMasterAccount}">
          <div class="des_content_join clearfix" v-if="showMasterAccount">
            <div class="col-6">
              <div class="left_des_ctj">
                <div class="group_des_ctj">
                  <span class="txt_des_ctj">{{ $t('mam.performance_fee') }}: </span>{{ masterAccountData.performance_rate | formatMarginNumber(2) }} %
                </div>
                <div class="clearfix"></div>
                <div class="group_des_ctj">
                  <span class="txt_des_ctj">{{ $t('mam.next_performance_fee') }}: </span> {{ masterAccountData.next_performance_rate | formatMarginNumber(2) }} %
                </div>
                <div class="clearfix"></div>
                <div class="group_des_ctj">
                  <span class="txt_des_ctj">{{ $t('mam.max_drawdown') }}: </span>{{ (masterAccountData.max_drawdown * 100) | formatMarginNumber(2, '0', true) }} %
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="col-6">
              <div class="left_des_ctj">
                <div class="group_des_ctj">
                  <span class="txt_des_ctj">{{ $t('mam.total_capital') }}: </span>{{ masterAccountData.fund_capital | formatMarginNumber }}  {{ $t('margin.symbol.btc') }}
                </div>
                <div class="clearfix"></div>
                <div class="group_des_ctj">
                  <span class="txt_des_ctj">{{ $t('mam.total_balance') }}: </span>{{ masterAccountData.fund_balance | formatMarginNumber }}  {{ $t('margin.symbol.btc') }}
                </div>
                <div class="clearfix"></div>
                <div class="group_des_ctj">
                  <span class="txt_des_ctj">{{ $t('mam.total_gain') }}: </span>{{ (masterAccountData.fund_gain * 100) | formatMarginNumber(2, '0') }} %
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="left_des_ctj">
              <div class="group_des_ctj">
                <span class="txt_des_ctj">{{ $t('mam.next_period') }}: </span>{{ getNextPeriod() }}
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <form class="form_join_modal" v-on:submit.prevent v-if="showMasterAccount">
            <div class="group_input_join">
              <label>{{ $t('mam.amount') }}</label>
              <div class="clearfix"></div>
              <div class="gr_input_jm">
                <currency-input :precision="8" 
                                :maxLengthCus='16'
                                :disabled="dataError"
                                v-model="inputAmount"
                                @focus="resetError"/>

                <span class="rihg_input_as">{{ $t('margin.symbol.btc') }}</span>
              </div>
              <div class="clearfix"></div>
              <div class="clearfix" v-if="errors.first('amount')" :class="{ 'msg-error' : errors.first('amount') }">{{ errors.first('amount') }}</div>
            </div>
            <div class="clearfix"></div>
            <div class="group_input_join">
              <label>{{ $t('mam.note') }}</label>
              <div class="clearfix"></div>
              <div class="textarea-contatiner">
                <textarea rows="2" v-model="inputNote" maxlength="190"
                                 @focus="resetError"
                                 :disabled="dataError"
                                 ></textarea>

              </div>
              <div class="clearfix"></div>
              <div class="clearfix" v-if="errors.has($t('note'))" :class="{ 'msg-error' : errors.first('note') }">{{ errors.first('note') }}</div>
            </div>
            <div v-if="isFreezeTime()">
              <div>{{ $t('mam.freeze_time') }}</div>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
            <button class="btn btn_send" @click="onRequestJoinFund()" :disabled="dataError">{{ $t('mam.request_to_join') }}</button>
          </form>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from './Modal';
  import rf from '@/request/RequestFactory'
  import moment from 'moment';
  import BigNumber from "bignumber.js";
  import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';

  export default {
    components: {
      Modal,
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        modalName: 'JoinMamModal',
        masterAccountData: {},
        searchKey: undefined,
        showMasterAccount: false,
        inputAmount: '',
        inputNote: '',
        dataError: false,
        availableBtc: 0,
      }
    },
    methods: {
      getNextPeriod () {
        let nextPeriod = moment.unix(moment().add(1, 'months') / 1000).format("YYYY-MM-");
        nextPeriod += "01 00:00:00";
        return nextPeriod;
      },
      isFreezeTime () {
        if (moment.unix(moment.now() / 1000).format("DD") > '25') {
          return true;
        }
        return false;
      },
      closeModal() {
        this.resetData();
        this.$emit(`${this.modalName}:CLOSE`);
        window.CommonModal.hide('JoinMamModal');
      },
      resetError () {
        this.errors.clear();
      },
      searchMasterAccount() {
        this.resetError();
        if(!this.searchKey) return this.errors.add('master_id_require', window.i18n.t('mam.require_id'));
        rf.getRequest("MamRequest").getMasterAccount({ id: this.searchKey })
            .then(res => {
              if (res && res.data) {
                this.masterAccountData = res.data;
                this.dataError = false;
                this.showMasterAccount = true;
              } else {
                this.showMasterAccount = false;
                this.masterAccountData = {};
                this.dataError = true;
                this.errors.add('master_id', window.i18n.t('mam.fund_not_exist'));
              }
            })
            .catch(error => {
              this.dataError = true;
              this.errors.add('master_id', window.i18n.t('mam.fund_not_exist'));
            })
      },
      resetData() {
        this.showMasterAccount = false;
        this.searchKey = '';
        this.inputAmount = '';
        this.inputNote = '';
      },
      validate() {
        this.errors.clear();

        if (!this.inputAmount) {
          this.errors.add('amount', window.i18n.t('common.error.required', { field: 'amount' }));
        }
        if (new BigNumber(`${this.inputAmount || 0}`).comparedTo(0) == 0) {
          this.errors.add('amount', window.i18n.t('funds.deposit_usd.errors.min_amount', {min_amount: 0}));
        }
        if (new BigNumber(`${this.inputAmount || 0}`).comparedTo(`${this.availableBtc || 0}`) > 0) {
          this.errors.add('amount', window.i18n.t('mam.balance.not_enough'));
        }

        if (this.errors.count() > 0) {
          return false;
        }

        return true;
      },
      async onRequestJoinFund() {
        await this.getAvailableBtc();
        if (!this.validate()) return;
        const params = {
          amount: this.inputAmount,
          note: this.inputNote,
          master_id: this.searchKey,
        };
        rf.getRequest("MamRequest").createJoiningRequest(params)
          .then(res => {
            if (res && res.data) {
              this.$broadcast('RefreshFollowerOverview');
              Message.success(window.i18n.t('mam.join.success'), {}, { position: 'bottom_left' });
              this.closeModal();
            } else {
              Message.error(window.i18n.t('mam.fund_not_exist_2'), {}, { position: 'bottom_left' });
            }
          })
          .catch(error => {
            this.convertRemoteErrors(error);
            if (error && error.response && error.response.data) {
              Message.error(error.response.data.message, {}, { position: 'bottom_left' });
            }
          })
      },
      getAvailableBtc () {
        return rf.getRequest("UserRequest").getBalance()
          .then(res => {
            if (res && res.data && res.data.main && res.data.main.btc) {
              this.availableBtc = res.data.main.btc.available_balance
            }
          })
      }
    },
    watch: {
    },
    created () {
      this.getAvailableBtc();
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables.scss";
  .msg-error {
    color: $color-red-warning;
    font-size: $font-small;
    line-height: 20px;
  }
  .search-master {
    padding-right: 20px;
    color: #666666;
    text-align: center;
    margin-bottom: 15px;
    padding-left: 20px;
  }
  .box-title-modalsub {
    margin: 5px 0px 0px 0px;
    background-color: $color-white;
    text-align: center;
    overflow: hidden;
    padding-bottom: 10px;
    span {
      display: inline-block;
      padding: 5px 10px;
      position: relative;
      font-size: $font-big-20;
      font-family: $font-family-roboto-medium;
      color: $color-eden;
      line-height: 30px;
      &:before {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: -60px;
      }
      &:after {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: -60px;
      }
    }
  }
  .group_find_join_modal {
    margin:0px auto 30px auto;
    width: 320px;
    max-width: 94%;
    overflow: hidden;
    .txt_find {
      display: inline-block;
      float: left;
      line-height: 40px;
      width: 24px;
      font-size: $font-root;
      color: $color-grey-dark;
    }
    .txt_find_hide {
      display: inline-block;
      float: left;
      width: 24px;
      font-size: $font-root;
      color: #ffffff;
    }
    .btn-find {
      display: block;
      float: right;
      width: 80px;
      height: 40px;
      color: $color-white;
      margin-left: 10px;
      font-size: 12px;
      letter-spacing: 0.9px;
      border-radius: 3px;
      font-family: $font-family-roboto-bold;
      text-transform: uppercase;
      background-color: $color-jungle-green;
      border: 1px solid $color-jungle-green;
      &:hover {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
      }
    }
    .input_find {
      display: inline-block;
      float: left;
      width: calc(100% - 24px - 90px);
      height: 40px;
      border-radius: 3px;
      border: solid 1px $color-alto;
      padding: 10px;
      line-height: 20px;
      font-size: $font-root;
      color: $color-grey-dark;
      &:focus {
        border-color: $color-jungle-green;
      }
    }
  }
  .modalsub_container {
    padding: 25px 0px;
    background-color: $color-alabaster;
    &.background_white {
      background-color: $color-white;
    }
    .left_des_ctj {
      padding-left: 60px;
      padding-right: 5px;
      .group_des_ctj {
        font-size: $font-root;
        color: $color-grey-dark;
        line-height: 20px;
        margin-bottom: 8px;
        font-family: $font-family-roboto-bold;
        .txt_des_ctj {
          font-family: $font-family-roboto;
        }
      }
    }
  }
  .form_join_modal {
    padding: 5px 60px;
    margin-bottom: 45px;
    .group_input_join {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      label {
        color: $color-grey-dark;
        font-size: $font-root;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      input {
        width: 100%;  border-radius: 3px;
        border: solid 1px $color-alto;
        height: 40px;
        line-height: 20px;
        padding: 5px 50px 5px 20px;
        font-size: $font-root;
        color: $color-grey-dark;
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      .textarea-contatiner {
        position: relative;
        width: 100%;
        border: solid 1px $color-alto;
        background-color: white;
        padding-top: 1px;
      }
      textarea {
        width: 100%;
        border-radius: 3px;
        min-height: 80px;
        line-height: 20px;
        padding: 5px 20px;
        font-size: $font-root;
        color: $color-grey-dark;
        max-width: 100%;
        resize: none;
        overflow-y: scroll;
        border: none;
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      .gr_input_jm {
        position: relative;
        display: block;
        width: 100%;
        .rihg_input_as {
          position: absolute;
          right: 15px;
          line-height: 40px;
          bottom: 0px;
          display: inline-block;
          font-size: $font-root;
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
        }
      }
    }
  }
  .btn_send {
    display: block;
    margin: 20px auto 0px auto;
    width: 270px;
    height: 50px;
    border-radius: 3px;
    background-color: $color-jungle-green;
    line-height: 20px;
    padding: 15px;
    letter-spacing: 1.1px;
    color: $color-white;
    font-size: $font-root;
    text-transform: uppercase;
    font-family: $font-family-roboto-bold;
    &:hover {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
    }
  }
  .col-6 {
    width: 50%;
    float: left;
  }

</style>

<style lang="scss">
  @import "@/assets/sass/variables.scss";
  #JoinMamModal {
    max-height: 130vh;
    .modal-footer {
      display: none;
    }
    .modal-body {
      padding: 0px;
    }
    table {
      tbody {
        tr.empty-data:hover {
          background-color: transparent !important;
        }
      }
    }
  }
</style>