<template>
  <div id="convert-sm-balance" class="page_container">
    <div class="convertBalance-container">
        <div class="box-title title-medium-small">{{ $t('funds.balances.convert_sm_balance') }}</div>
        <div class="table-container table-convert-balance">
            <!-- <alert-with-button-modal/>
            <message-with-icon/> -->
            <data-table :getData="loadData" ref="datatable" :msgEmptyData="$t('funds.balances.no_data')" :limit="10" @DataTable:finish="onDatatableFinished">
                <th class="text-center">
                    <label class="lbCheckbox-container">
                        <input type="checkbox" hidden/>
                        <span class="checkmark" :class="this.isCheckAll ? 'row-selected checkall-selected' : 'checkall-unselected'" @click="checkAll" ></span>
                    </label>
                </th>
                <th data-sort-field="coin" @click="FixSortIE = !FixSortIE">
                  {{$t('funds.balances.coin')}}
                  <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                  <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
                </th>
                <th data-sort-field="name" @click="FixSortIE = !FixSortIE">
                  {{$t('funds.balances.name')}}
                  <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                  <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
                </th>
                <th data-sort-field="availableBalance" @click="FixSortIE = !FixSortIE">
                  {{$t('funds.deposit.available_balance')}}
                  <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                  <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
                </th>
                <th data-sort-field="btcValue" @click="FixSortIE = !FixSortIE">
                  {{$t('funds.balances.btc_value')}}
                  <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                  <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
                </th>
                <th data-sort-field="amlValue" @click="FixSortIE = !FixSortIE">
                  {{$t('funds.balances.approx')}}
                  <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                  <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
                </th>
                <template slot="body" slot-scope="props">
                    <tr>
                      <td class="text-center">
                        <label class="lbCheckbox-container">
                          <input type="checkbox" hidden/>
                          <span class="checkmark" :class="props.item.isSelected ? 'row-selected' : 'row-unselected'" @click="checkRow($event, props.item)"></span>
                        </label>
                      </td>
                      <td class="text-left">{{ props.item.coin | uppercase }}</td>
                      <td class="text-left">{{ props.item.name }}</td>
                      <td class="text-left">{{ props.item.availableBalance | toNumber  | formatCurrencyAmount(props.item.coin, '0') }}</td>
                      <td class="text-left">{{ props.item.btcValue | toNumber  | formatCurrencyAmount(props.item.coin, '0') }}</td>
                      <td class="text-left">{{ props.item.amlValue | toNumber  | formatCurrencyAmount(props.item.coin, '0') }}</td>
                    </tr>
                </template>

            </data-table>
          <!-- end table container -->
        </div>
      <!-- end-balance-container -->
      <div class="form-group">
        <div class="flex_button_box">
          <button type="submit" class="form-control btn btn-submit btn-success btn-convert-balance " :class="{'disabled_item': (!Object.keys(this.balances).length || this.selectedCoinCount == 0)}" @click="showModal">{{$t('funds.convert_to_amal')}}</button>
          <div class="coin-selected-text">
            <span v-html="$t('funds.balances.you_have_selected_coin', {coin: this.selectedCoinCount})"></span>
          </div>
        </div>
      </div>
    </div>
    <message-with-icon/>
    <!-- END NEW BALANCE LAYOUT -->
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import Const from '@/common/Const';
  import AlertWithButtonModal from '@/components/shared_components/common/AlertWithButtonModal';
  import MessageWithIcon from '@/components/shared_components/common/MessageWithIcon';
  import { mapState } from 'vuex';

  const MIN_BTC = 0.001;
  const MIN_AMOUNT_CRYPTO = Math.pow(10, -8); // 10^-8

  // Prevent convert 0.00000001 to 1e-8
  BigNumber.config({ EXPONENTIAL_AT: 15 });

export default {
    data() {
      return {
        hideBalances: false,
        isShowNotification: false,
        search: '',
        limit: undefined,

        accountBalances: undefined,
        prices: undefined,
        balances: [],
        totalPendingWithdraws: [],

        tradeCoin: [],
        displayPairCoin: false,
        tradeUsd: [],

        isAuthenticated: window.isAuthenticated,
        coinSetting: {},
        coinsMasterData: {},
        noFunds: window.i18n.t('funds.balances.no_data'),
        isCheckAll: false,
        selectedCoinCount: 0,
        FixSortIE: null,
        amalCoin: null,
        totalAmalGet: 0,
      }
    },
    components: {
      AlertWithButtonModal,
      MessageWithIcon,

    },
    props: {
      isSinglePage: {
        type: Boolean,
        default: true
      },
    },
    watch: {
      accountBalances(newAccBalances) {
        const currencies = Object.keys(newAccBalances);
      },
      prices(newPrices) {
        this.refresh();
      },
      totalPendingWithdraws(newVal) {
        this.refresh();
      },
      search(newValue) {
        this.$refs.datatable.refresh();
      },
      hideBalances() {
        this.$refs.datatable.refresh();
      },
      isTransferFromMain() {
        this.$refs.datatable.refresh();
      }
    },
    computed: {
      ...mapState({
        masterData: state => state.masterdata,
      }),
      totalBtcBalance() {
        return this.sumBy('btcValue');
      },
      totalUsdBalance() {
        return this.sumBy('usdValue');
      },
      totalOrder() {
        return this.sumBy('orderBtcValue');
      },
    },
    methods: {
      onDatatableFinished() {
        if (!this.$refs.datatable || !this.$refs.datatable.rows) {
          return;
        }
        window._.each(this.$refs.datatable.rows, item => {
          item.isActiveRow = false;
        });
      }, 
      
      showNotification() {
        this.isShowNotification = !this.isShowNotification;
      },
      hideNotification() {
        this.isShowNotification = false;
      },
      loadData (params) {
        return new Promise((resolve) => {
          let data = this.balances;
          data = _.filter(data, (item) => `${item.coin}${item.name}`.toLowerCase().includes(this.search.toLowerCase()));
          // if (this.hideBalances) {
          //   data = _.filter(data, (item) => {
          //     let btcValue = this.getBalance(item.coin, Const.BTC);
          //     return (new BigNumber(btcValue)).lte(MIN_BTC);
          //   });
          // }
          if (params.sort && params.sort_type) {
            data = _.orderBy(data, (item) => {
              if (isNaN(item[params.sort])) {
                return item[params.sort];
              }
              return new BigNumber(`${item[params.sort]}`).toNumber();
            }, [params.sort_type]);
          }
          return resolve({ data });
        });
      },
      refresh() {
        const isNoData = window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices);
        if (isNoData) {
          return;
        }
        this.transferData();
        this.$refs.datatable.refresh();
      },
      transferData() {
        let data = [];
        let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE];
        let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE];
        let marginBalanceData = this.accountBalances[Const.TYPE_MARGIN_BALANCE];
        let exchangeBalanceData = this.accountBalances[Const.TYPE_EXCHANGE_BALANCE];
        let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE];

        for (let coin in coinList) {
          if ((coin + '').toLowerCase() == Const.AML) {
            continue;
          }

          const item = {};
          item.coin = coin;
          item.name = this.getCoinName(coin);

          item.balance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].balance) || 0;
          item.availableBalance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].available_balance) || 0;
          item.exchangeBalance = (exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin].balance) || 0;
          item.marginBalance = (marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin].balance) || 0;
          item.mamBalance = (mamBalanceData && mamBalanceData[coin] && mamBalanceData[coin].balance) || 0;

          item.totalBalance = new BigNumber(item.balance).add(item.exchangeBalance);
          if (coinType == 'btc') {
            item.totalBalance = new BigNumber(item.totalBalance).add(item.marginBalance).add(item.mamBalance);
          }
          let totalBalance = item.totalBalance;
          item.totalBalance = item.totalBalance.toString();

          item.btcValue = this.getBtcAvailableBalance(coin, Const.BTC);
          let btcValue = new BigNumber(item.btcValue);
          let availableBalance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].available_balance) || 0;
          availableBalance = new BigNumber(availableBalance);
          if (availableBalance.isZero() || btcValue.gte(MIN_BTC) ) {
            continue;
          }

          let coinType = item.coin || '';
          item.disableMarginBalance = (coinType != Const.BTC);
          item.enableMamBalance = (coinType == Const.BTC);
          item.isSelected = false;

          item.amount = 0;

          item.order = this.calculateInOrder(item);
          item.orderBtcValue = this.getOrderBtcBalance(item);
          item.amlValue = this.getAmalAvailableBalance(coin, Const.AML);

          let usdValue = this.getBalance(coin, Const.USD);
          // if do not have price of usd_$coin pair, transform to btc_$coin pair then transform to usd_btc pair.
          if (!usdValue) {
            const pair = 'usd_btc';
            const price = this.prices[pair] ? this.prices[pair].price : '0';
            usdValue = new BigNumber(item.btcValue).mul(price).toString();
          }
          item.usdValue = usdValue;

          item.isDeposit = this.coinSetting[item.coin] && this.coinSetting[item.coin].is_deposit;
          item.isWithdraw = this.coinSetting[item.coin] && this.coinSetting[item.coin].is_withdraw;

          data.push(item);
        }

        // sort by coin name asc
        this.balances = window._.sortBy(data, 'name');
      },
      getCoinName(coin) {
        let coinName = '';
        if (this.coinsMasterData && this.coinsMasterData[coin]) {
          coinName = this.coinsMasterData[coin].name;
        }
        return coinName;
      },
      getBalance(coin, currency) {
        if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

        if (coin === currency) {
          return `${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`;
        }
        const pair = `${currency}_${coin}`;
        if (!this.prices[pair]) {
          return 0;
        }
        return new BigNumber(`${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`).mul(this.prices[pair].price).toString();
      },
      getAmalAvailableBalance(coin, currency) {
        if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

        let balance = this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].available_balance;
        if (coin === currency) {
          return `${balance}`;
        }

        return this.convertCoins(coin, Const.AML, balance);
      },
      getBtcAvailableBalance(coin, currency) {
        if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

        let balance = this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].available_balance;
        if (coin === currency) {
          return `${balance}`;
        }

        return this.convertToBtcValue(coin, balance);
      },
      convertToBtcValue(coin, value) {
        let balance = value;
        let pair = `btc_${coin}`;
        if (!this.prices[pair]) {
          pair = `${coin}_btc`;
          if (!this.prices[pair]) {
            return 0;
          }
          return new BigNumber(`${balance}`).div(this.prices[pair].price).toString();
        }
        return new BigNumber(`${balance}`).mul(this.prices[pair].price).toString();
      },
      convertBtcToCoinValue(coin, value) {
        let balance = value;
        let pair = `btc_${coin}`;
        if (!this.prices[pair]) {
          pair = `${coin}_btc`;
          if (!this.prices[pair]) {
            return 0;
          }
          return new BigNumber(`${balance}`).mul(this.prices[pair].price).toString();
        }
        return new BigNumber(`${balance}`).div(this.prices[pair].price).toString();
      },
      convertCoins(fromCoin, toCoin, value) {
        // Example:
        // If do not have price of usd_$coin pair, transform to btc_$coin pair then transform to usd_btc pair.
        let btcValue = this.convertToBtcValue(fromCoin, value);
        let newValue = this.convertBtcToCoinValue(toCoin, btcValue);

        return newValue;
      },
      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
        // this.addPricesInBtc();
      },
      getOrderBtcBalance(item) {
        if (!this.prices[`btc_${item.coin}`]) {
          return 0;
        }
        return new BigNumber(item.order).mul(this.prices[`btc_${item.coin}`].price).toString();
      },
      calculateInOrder(item) {
        // const result =  window._.find(this.totalPendingWithdraws, (row) => { return row && row.currency === item.coin});
        // const totalPendingWithdraw = result ? result.total : '0';
        // return new BigNumber(item.totalBalance).sub(item.availableBalance).sub(totalPendingWithdraw).toString();
        return new BigNumber(`${item.totalBalance}`).sub(item.availableBalance).toString();
      },
      sumBy(attr) {
        let total = new BigNumber(0);
        for (let item of this.balances) {
          total = total.add(new BigNumber(item[attr]));
        }
        return total.toString();
      },
      onBalanceUpdated(newAccountBalances) {
        this.accountBalances = window._.merge({}, this.accountBalances, newAccountBalances);
      },
      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          BalanceUpdated: this.onBalanceUpdated
        }
      },
      checkAll() {
        this.isCheckAll = !this.isCheckAll;

        let isCheckAll = this.isCheckAll;
        this.balances = this.balances.map(function(item, index) {
          item.isSelected = isCheckAll;
          return item;
        });
        this.$refs.datatable.refresh();

        // Count selected Coins
        let selectedCoins = this.balances.filter(item => item.isSelected);
        this.selectedCoinCount = selectedCoins.length;

      },
      checkRow(e, item) {
        item.isSelected = !item.isSelected;

        let isCheckAll = this.isCheckAll;
        let selectedItems = this.balances.filter(item => item.isSelected);

        if (selectedItems.length == this.balances.length) {
          this.isCheckAll = true;
        } else if (selectedItems.length > 0 && selectedItems.length < this.balances.length) {
          if (this.isCheckAll) {
            this.isCheckAll = false;
          }
        } else {
          this.isCheckAll = false;
        }

        // Count selected Coins
        let selectedCoins = this.balances.filter(item => item.isSelected);
        this.selectedCoinCount = selectedCoins.length;
      },
      showModalSuccess(){
        window.confirmationModalSecond.show({
          type: "convertSmallBalanceSuccess",
          title: this.$t('balance.convert.success'),
          content: "",
          btnCancelLabel: null,
          btnConfirmLabel: null,
          onCancel: () => {
          },
          onConfirm: () => {
          },
          img: this.amalCoin.icon_image,
          total: this.totalAmalGet,
        });
      },
      showModal(){
        window.ConfirmationModal.show({
          type: "convertSmallBalance",
          title: this.$t('balance.convert.ques'),
          content: "",
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.convertSmallBalance();
          },
          onCancel: () => {
          }
        });
      },
      convertSmallBalance() {
        let coinSelected = this.balances.filter(item => item.isSelected).map(item => item.coin);
        if (coinSelected.length == 0) {
          this.showErrorMessage(window.i18n.t('funds.balances.please_select_coins'));
          return false;
        }
        let params = {
          coins: coinSelected.join(',')
        };

        let self = this;
        rf.getRequest('UserRequest').convertSmallBalance(params).then(response => {
          if (response.success) {
            this.totalAmalGet = response.data.total;
            
            this.showModalSuccess();

            // Reset checkboxes
            this.isCheckAll = false;
            this.selectedCoinCount = 0;
            this.balances = this.balances.map(item => item.isSelected = false);
          }

          // Get data balance to update datatable
          rf.getRequest('UserRequest').getBalance(false).then(res => {
            self.onBalanceUpdated(res.data);
            self.refresh();
          });

        });

      },
      onDatatableFinished() {
        if (!this.$refs.datatable || !this.$refs.datatable.rows) {
          return;
        }
        window._.each(this.$refs.datatable.rows, item => {
          item.isActiveRow = false;
        });
      },
      showErrorMessage(message, callBack = () => {}) {
        window.MessageWithIcon.error(message);
      },
      showInfoMessage(message, callBack = () => {}) {
        window.MessageWithIcon.success(message);
      },

    },
    created() {
      if (!window.isAuthenticated) {
        return;
      }

      rf.getRequest('UserRequest').getCurrentUser().then(res => {
        let securityLevel = res.data.security_level;

        rf.getRequest('MasterdataRequest').getAll().then(res => {
          this.limit = window._.find(res.withdrawal_limits, (wl) => {
            return wl.security_level === securityLevel && wl.currency === 'btc';
          });
        });
      });

      rf.getRequest('UserRequest').getBalance(false).then(res => {
        this.onBalanceUpdated(res.data);
        this.refresh();
      });
      rf.getRequest('PriceRequest').getPrices().then(res => {
        this.onPricesUpdated(res.data);
      });

      document.title = this.$t('menu.balances') + ` – ${this.tabTitle}`;
    },
    mounted() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(this.masterData.coins_confirmation, item => item.coin);
      this.coinsMasterData = window._.keyBy(this.masterData.coins, item => item.coin);
      this.coinsMasterData.usd = { name: window.i18n.t(`currency.usd.fullname`) }
      this.amalCoin = this.masterData.coins.find(el => {
        if (el.coin == Const.AML) return el.icon_image;
      }) 
    },
}

</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  @import "@/assets/sass/common.scss";
  .btn-success{
    width: 165px;  
    height: 32px;
    border-radius: 3px;
    float: right;
    transition: 0.5s;
    &:hover,&:active{
      background: $color-blue-custom;
      border-color: $color-blue-custom !important;
    }
  }
  .lbCheckbox-container{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .lbCheckbox-container:hover input ~ .checkmark {
    background-color: $color-white;
    border-color: $color-jungle-green;
  }
  .lbCheckbox-container input:checked ~ .checkmark {
    background-color: $color-jungle-green;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .checkmark.checkall-selected:after {
    content: "";
    position: absolute;
    display: none;
  }
  .checkmark.checkall-unselected:after {
    content: none;
  }
  .lbCheckbox-container input:checked ~ .checkmark.checkall-unselected {
    background-color: $color-white !important;
  }
  .checkmark.row-selected:after  {
    content: "";
    position: absolute;
    display: none;
    background-color: transparent;
  }
  .lbCheckbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  .lbCheckbox-container .checkmark:after {
    left: 9px;
    top: 4px;
    width: 8px;
    height: 13px;
    border: solid $color-white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .row-selected:after {
    content: "";
    position: absolute;
    display: block !important;
  }
  .lbCheckbox-container .row-selected:after {
    left: 5px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .lbCheckbox-container input:checked ~ .row-selected:after {
      display: block !important;
  }
  .lbCheckbox-container input ~ .row-selected {
      background-color: $color-jungle-green !important;
      border: 1px solid $color-jungle-green;
  }
  .checkmark.row-unselected:after  {
    content: none;
  }
  .row-unselected:after {
    content: none;
  }
  .lbCheckbox-container .row-unselected:after {
    left: 9px;
    top: 4px;
    width: 8px;
    height: 13px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .lbCheckbox-container input:checked ~ .row-unselected:after {
      display: none;
  }
  .lbCheckbox-container input ~ .row-unselected {
      background-color: $color-white !important;
      border: 1px solid $color-alto;
  }
  lbCheckbox-container:hover {
    color: $color-white;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    margin: 5px 20px;
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $color-white;
    border: 1px solid $color-alto;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: $color-grey-sliver;
  }
  thead{
    background-color: $color-white !important;
  }
  #convert-sm-balance{
    .balance-container {
      margin: 0;
      .table-container thead th{
          height: 22px;
          border-bottom: 1px solid $color-gray;
          font-size: $font-mini;
      }
    }
  }
  table{
    thead{
      background: transparent !important
    }
  }
  #convert-sm-balance {
    &.page_container {
    .flex_button_box {
      padding-top: 22px;
      .coin-selected-text {
        float: right;
        display: inline-block;
        margin-right: 10px;
        margin-top: 8px;
        .coin-count {
          font-weight: bold;
        }
      }
    }
    .convertBalance-container {
      .table-container {
        th {
          line-height: 20px;
          padding: 20px 0px 10px 0px;
          font-size: $font-small;
          font-weight: 500;
          color: $color-grey;
          background-color: $color-white;
          font-family: $font-family-roboto-medium;
          &.after_none:after {
            display: none;
          }
          &.coin_balances {
            padding-left: 53px;
          }
          &[data-sort-order="asc"], &[data-sort-order="desc"] {
            color: $color-jungle-green;
          }
        }
        tr {
          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 28px;
            padding: 8px 0px 6px 0px;
            overflow: initial;
            &.coin_balances {
              padding-left: 20px;
            }
            &:last-child {
              text-align: left;
            }
            .icon_balances {
              font-size: 20px;
              color: $color-jungle-green;
              cursor: pointer;
            }
            .icon_balances.active-icon {
              color: $color-eden;
            }
            .btn-lose-color {
              .icon_balances {
                color: $color-grey;
                cursor: not-allowed;
              }
            }
            .btn-transfer {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 38px;
              position: relative;
              margin-top: -12px;
              top: 6px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              background-color: transparent;
            }
            &.acvite_transfer {
              .btn-transfer {
                background-color: $color-bright-gray;
              }
            }
            .btn-mam {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 42px;
              position: relative;
              margin-top: -18px;
              font-size: 11px;
              top: 12px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              overflow: hidden;
              background-color: transparent;
              margin-left: 3px;
            }
            &.acvite_mam {
              .btn-mam {
                background-color: $color-bright-gray;
              }
            }
          }
          &:hover {
            background-color: $color-bright-gray;
            td {
              .icon_balances {
                color: $color-eden;
              }
            }
          }
        }
        .table_drop_balances {
          position: relative;
          padding: 22px 37px 23px 37px;
          background-color: $color-bright-gray;
          .close_tbale {
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 20px;
            height: 20px;
            font-size: $font-smaller;
            line-height: 20px;
            text-align: center;
            color: $color-grey-dark;
            cursor: pointer;
          }
          &:hover {
            background-color: $color-bright-gray;
          }
        }
      }
      .btn-convert-balance {
        font-size: 12px;
        text-transform: uppercase;
      }
      .disabled_item {
        background-color: $color-bright-gray;
        border: none;
        cursor: not-allowed;
        pointer-events: none;
        color: $color_black;
      }
    }
  }
  .group_transfer_form {
    font-family: $font-family-roboto;
    display: block;
    float: left;
    margin-right: 15px;
    .btn-transfer-form {
      border-radius: 3px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      width: 40px;
      font-size: 20px;
      color: $color-eden;
      padding: 8px;
      overflow: hidden;
      text-align: center;
      &:hover {
      color: $color-bright-gray;
      background-color: $color-eden;
      }
    }
    .btn-send-transfer-form {
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
      background-color: $color-jungle-green;
      height: 40px;
      width: 180px;
      font-size: $font-smaller;
      color: $color-white;
      padding: 8px;
      overflow: hidden;
      text-align: center;
      letter-spacing: 0.9px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      &:focus,
      &:hover {
        background-color: $color-blue-custom;
        border: solid 1px $color-blue-custom;
        color: $color-white;
      }
    }
    label.txt {
      display: block;
      width: 100%;
      line-height: 20px;
      color: $color-grey-dusty;
      font-size: $font-small;
      font-weight: 500;
      height: 20px;
    }
    input.input-form {
      border-radius: 3px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      font-size: $font-root;
      color: $color-grey-dark;
      font-family: $font-family-roboto-medium;
      padding: 10px 13px;
      line-height: 20px;
    }
    select.input-form {
      border-radius: 3px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      font-size: $font-root;  
      color: $color-grey-dark;
      font-family: $font-family-roboto-medium;
      padding: 10px 10px;
      line-height: 20px;
    }
    .w_155 {
      width: 155px;
    }
    .w_220 {
      width: 220px;
    }
    .input-amount {
      position: relative;
      .name_amount {
        position: absolute;
        top: 10px;
        right: 10px;  
        font-size: $font-root;  
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        text-transform: uppercase;
      }
      input.input-form {
        padding-right: 40px;
      }
      & > input:focus {
        border: 1px solid #2dac91;
      }
    }
  }
  .select-control {
    border-radius: 3px;
    border: solid 1px $color-alto;
    background-color: $color-bright-gray;
    height: 40px;
    font-size: $font-root;
    color: $color-grey-dark;
    font-family: $font-family-roboto-medium;
    padding: 10px 10px;
    line-height: 20px;
    text-align: left;
    .select-icon:before {
      color: $color-grey-dark;
      float: right;
      font-size: 11px;
      line-height: 22px;
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 9px;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: $color-white;
    padding: 10px 0px;
    .tab-element {
      cursor: pointer;
      display: block;
      width: 100%;
      line-height: 20px;
      padding: 5px 20px;
      font-size: $font-root;
      color: $color-grey-dark;
      &:hover {
        color: $color-jungle-green-light;
      }
    }
    .disabled-item {
      background-color: #bbb;
      cursor: not-allowed;
      color: white;
      &:hover {
        color: white;
      }
    }
  }

}
 .convertBalance-container {
  display: inline-block;
  width: 100%;

  .box-title {
    margin-bottom: $title-bottom-margin;
  }
}
.table-history {
  padding-bottom: 15px;
}
.table-container {
  background: $color-white;
}
</style>
