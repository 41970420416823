const openOrderHeader = [
  {
    id: "created_at",
    name: "order.open_order.date",
  },
  {
    id: "coin",
    name: "order.open_order.pair",
  },
  {
    id: "type",
    name: "order.open_order.type",
  },
  {
    id: "trade_type",
    name: "order.open_order.side",
  },
  {
    id: "price",
    name: "order.open_order.price",
  },
  {
    id: "quantity",
    name: "order.open_order.amount",
  },
  {
    id: "executed_quantity",
    name: "order.open_order.filled",
  },
  {
    id: "total",
    name: "order.open_order.total",
  },
  {
    name: "order.open_order.trigger_conditions",
    id: "triggerConditions",
    textRight: true,
  },
];

const orderHistoryHeader = [
  {
    id: "updated_at",
    name: "order.open_order.date",
  },
  {
    id: "coin",
    name: "order.open_order.pair",
  },
  {
    id: "type",
    name: "order.open_order.type",
  },
  {
    id: "trade_type",
    name: "order.open_order.side",
  },
  {
    id: "executed_price",
    name: "order.order_history.average",
  },
  {
    id: "price",
    name: "order.order_history.price",
  },
  {
    id: "executed_quantity",
    name: "order.open_order.filled",
  },
  {
    id: "quantity",
    name: "order.open_order.amount",
  },
  {
    id: "total",
    name: "order.open_order.total",
  },
  {
    id: "triggerConditions",
    name: "order.open_order.trigger_conditions",
  },
  // {
  //   id: "status",
  //   name: "order.order_history.status",
  // },
];

const tradeHistoryHeader = [
  {
    id: "created_at",
    name: "order.open_order.date",
  },
  {
    id: "coin",
    name: "order.order_history.pair",
  },
  {
    id: "trade_type",
    name: "order.order_history.type",
  },
  {
    id: "price",
    name: "order.order_history.price",
  },
  {
    id: "role",
    name: "Role",
  },
  {
    id: "quantity",
    name: "order.order_history.filled",
  },
  {
    id: "fee",
    name: "order.order_history.fee",
  },
  {
    id: "amount",
    name: "order.order_history.total",
  },
];

export default {
  OPEN_ORDER_HEADER: openOrderHeader,
  ORDER_HISTORY_HEADER: orderHistoryHeader,
  TRADE_HISTORY_HEADER: tradeHistoryHeader,
};
