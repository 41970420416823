<template>
  <router-link :to="{name: 'Spot Exchange', query: {coin : priceScope.coin, currency : priceScope.currency}}">
  <div class="coin-chart" >
    <div class="infor">
      <img class="img_coin_chart" :src="imageCoins[priceScope.coin]" width="20px"/>
      <div class="coin-detail">
        <div class="coin-name clearfix">
          <span class="color-gray name_coin_top">{{ priceScope.coin | uppercase }}/{{ priceScope.currency | uppercase }}</span>
          <span class="right_coin_name" :class="percentClass(priceScope.changed_percent)" v-tooltip="{ content: $t('landing.chart.change') }">
            {{ priceScope.changed_percent | changedPercent }} <span :class="{'icon-arrow5': priceScope.changed_percent > 0, 'icon-arrow6': priceScope.changed_percent < 0}"></span>
          </span>
        </div>
        <div class="clearfix coin_rate" :class="calculateLastPriceStatus(priceScope.current_price)">
        <!-- <div class="clearfix coin_rate" :class="{'span-grown-up': isChangePrice && priceScope.changed_percent > 0, 'span-grown-down': isChangePrice && priceScope.changed_percent < 0}"> -->
          <!-- <span class="arrow" :class="{'arrow-up': priceScope.changed_percent > 0, 'arrow-down': priceScope.changed_percent < 0}" v-show="isChangePrice"></span> -->
          <span class="currency" v-tooltip="{ content: $t('landing.chart.last_price') }">
            {{ priceScope.current_price | formatCurrencyAmount(priceScope.current_price, '0') }}
          </span>
          <span class="currency_usd">${{ priceScope.usdPrice | formatCurrencyAmount('usd', '0') }}</span>
        </div>
        <div class="if_coin_c">
          <span class="name_vl_coin">{{$t('exchange.basic.trading_page.volume')}}: </span>
          <span class="usd-currency f-fr">{{ priceScope.volume | formatCurrencyAmount(priceScope.currency, '0') }}<span class="usd-currency ml-2"></span></span>
          <span class="code_coin_c marr5">{{ priceScope.coin | uppercase }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="chart">
      <vue-highcharts :options="options" :ref="chartRef"></vue-highcharts>
    </div> -->
  </div>
  </router-link>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import TimezoneUtils from '@/common/TimezoneUtils';
  import VueHighcharts from 'vue2-highcharts';
  import moment from 'moment';

  export default {
    components: {
      VueHighcharts
    },
    props: {
      priceScope               : {type: Object},
      color                    : {type: String, default: '#b9d3f1'},
      imageCoins               : {type: Array},
    },
    computed: {
      chartRef() {
        return `${this.priceScope.coin}Charts`;
      }
    },
    data() {
      return {
        isChangePrice : false,
        data          : [],
        options       : this.getChartOptions(),
      }
    },
    methods: {
      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated
        }
      },

      getEventHandlers() {
        return {
          selectedPairMarket: this.onSelectedPairMarket
        };
      },

      onPricesUpdated(newPrice) {
        const key = `${this.priceScope.currency}_${this.priceScope.coin}`;
        if (!newPrice[key]) {
          return;
        }
        this.onChartUpdated(newPrice);

        this.showChangePrice();
      },

      onChartUpdated(item) {
        if (item) {
          this.data.push([parseFloat(item.created_at), parseFloat(item.price)]);
        }
        // this.$refs[this.chartRef].chart.series[0].setData(this.data);
      },

      getChartOptions() {
        return {
          chart: {
            backgroundColor: null,
            borderWidth: 0,
            type: 'area',
            margin: [0, -4, 0, -4],
            height: 70,
            style: {
             overflow: 'visible'
            },
          },
          time: {
            // timezone: TimezoneUtils.getTimezone()
            timezoneOffset: TimezoneUtils.getTimezoneOffset()
          },
          title: {
            text: null
          },
          subtitle: {
            text: null
          },
          credits: {
           enabled: !1
          },
          tooltip: {
          valueDecimals: 10
          },
          xAxis: {
            labels: {
              enabled: !1
            },
            title: {
              text: null
            },
            startOnTick: !1,
            endOnTick: !1,
            tickPositions: [],
            lineWidth: 0
          },
          yAxis: {
            endOnTick: !1,
            startOnTick: !1,
            type: 'datetime',
            labels: {
              enabled: !1,
            },
            title: {
              text: null
            },
            tickPositions: [],
            min: 0
          },
          legend: {
            enabled: !1
          },
          tooltip: {
            headerFormat: `{point.x: %Y-%m-%d %H:%M:%S}<br>`,
          },
          plotOptions: {
            area: {
              marker: {
                enabled: !1,
                symbol: "circle",
                radius: 2,
                states: {
                  hover: {
                  enabled: !0
                  }
                }
              }
            }
         },
         series:
         [
           {
            name: `${window.i18n.t('landing.price')} ${this.priceScope.currency.toUpperCase()}`,
            data: this.data,
            lineWidth: .8,
            fillOpacity: .08,
            color: this.color
           }
         ]
        }
      },

      percentClass(percent) {

        return percent > 0 ? 'grown-up' : (percent < 0 ? 'grown-down' : '');
        
      },

      calculateLastPriceStatus(price) {

        return price > 0 ? 'span-grown-up' : (price < 0 ? 'span-grown-down' : '');

      },

      showChangePrice() {
        this.isChangePrice = true;

        setInterval(() => {
          this.isChangePrice = false;
        }, 3000);
      },

      onSelectedPairMarket(pair) {
        // TODO: Refresh chart

      },

    },

    mounted() {
      const params = {
        coin       : this.priceScope.coin,
        currency   : this.priceScope.currency,
        resolution : 3600000, // a hour
        from       : moment().add(-3, 'days').format('X'), // 3 days ago.
        to         : moment().format('X')
      };

      rf.getRequest('PriceRequest').getChartBars(params).then(res => {
        this.data = window._.map(res, item => {
          const created_at = parseInt(item.time);
          const price = parseFloat(item.close);
          return [created_at, price];
        });
        this.onChartUpdated();
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  
  .coin-chart {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: solid 1px #e5e5e5;
    background-color: $color-alabaster;
    .infor {
      padding: 26px 20px 26px 20px;
      .img_coin_chart{
        width: 50px;
        height: 50px;
        float: left;
        margin-right: 15px;
      }
      .coin-detail{
        display: block;
        overflow: hidden;
        .coin-name{
          line-height: 17px;
          margin-bottom: 12px;
          display: block;
          width: 100%;
          .color-gray{
            color: $color-grey-dark;
            font-size: $font-root;
            margin-right: 5px;
            font-weight: 500;
            font-family: $font-family-roboto-bold;
          }
          .name_coin_top {
            max-width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            float: left;
          }
          .right_coin_name{
            line-height: 17px;
            float: right;
            font-size: $font-root;
            color: $color-grey-dark;
            max-width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            float: right;
            &.grown-up {
              color: $color-jungle-green;
            }
            &.grown-down {
              color: $color-red-main;
            }
            .percent-arrow:after{
              content: "\e906";
              font-family: 'icomoon' !important;
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-size: $font-medium;
              float: right;
              margin-left: 3px;
              &.grown-down{
                color: $color-alizarin-crimson;
              }
            }
          }
        }
        .coin_rate{
          display: block;
          line-height: 20px;
          margin-bottom: 8px;
          .currency {
            float: left;
            display: inline-block;
            max-width: 50%;
            overflow: hidden;
            white-space: nowrap;
            // color: $color-jungle-green;
            color: $color-grey-dark;
            font-size: $font-medium-bigger;
            font-weight: 500;
            font-family: $font-family-roboto-bold;
            text-overflow: ellipsis;
            padding-right: 9px;
          }
          &.span-grown-up {
            .currency {
              color: $color-jungle-green;
            }
          }
          &.span-grown-down {
            .currency {
              color: $color-denim;
            }
          }
          .currency_usd{
            float: right;
            display: inline-block;
            color: $color-grey-dark;
            font-size: $font-root;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 50%;
          }
        }
        .if_coin_c{
          font-size: $font-small;
          color: $color-grey-dusty;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .chart {
      width: 100%;
      height: calc(100% - 80px);
      .vue-highcharts {
        box-shadow: none;
        border: none;
        border-radius: 0px;
        padding: 0;
        margin: -2px 0px;
      }
    }
    .ml-2 {
      margin-left: 2px;
    }
    .ml-8 {
      margin-left: 8px;
    }
    &:hover {
      border: solid 1px $color-jungle-green;
    }
  }
</style>
