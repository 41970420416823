<template >
  <div id="account_device" >
    <Breadcrumb :items="breadcrumbItems" class="container-breadcrumb" />
    <change-password ref='changePassComponent'/>
    <otp-modal name="otp_modal" :submit="updateModeWhitelist" :params="getParamsUpdateModeWhitelist()" :is-disable-button="isDisableButton" :contact_email="siteSetting.contact_email"/>
    <cancel-google-auth-modal @CancelGoogleAuthModal:CLOSE="hideCancelGoogleAuthModal"></cancel-google-auth-modal>
    <enable-google-authentication @EnableGoogleAuthentication:CLOSE="hideEnableGoogleAuthenticaion"></enable-google-authentication>
    <lost-otp-modal :contact_email="siteSetting.contact_email" name="lostOtpForm" @DISABLE_OTP_SUCCESS="disableOtpSuccess"/>
    <confirmation-modal>
      <template slot="content">
        <div class="checkbox-bank">
          <input type="checkbox" id="c-bank" v-model="isCreateNewBankAccount"/>
          <label for="c-bank">{{ $t('refferal.confirm_bank_account_content') }}</label>
        </div>
      </template>
    </confirmation-modal>
      <div  v-if="sizeScreen > 500" class="account_right">
        <div class="account_right_content">
          <div class="title_back">
            <img :src="require(`@/assets/images/drx/arrow-left-dark.png`)" alt="icon" class="icon_back" @click="clickBack" :style="{width: '24px', height: '24px'}">
            <span class="title_account">{{ $t('account.device_management')}}</span>
          </div>
          <div class="account_right_main">
            <div class="path_last_login">
              <div class="datatable">
                <data-table :getData="getUserDevice" :widthTable="'auto'" :classHeader="'header-css'" @DataTable:finish="onDatatableFinished" ref="datatableDevice" class="table_min">
                    <th class="thead-custom">{{$t('account.device')}}</th>
                    <th class="thead-custom">{{$t('account.date')}}</th>
                    <th class="thead-custom">{{$t('account.ip_address')}}</th>
                    <th class="thead-custom">{{$t("account.ip_action")}}</th>
                  <template slot="body" slot-scope="props">
                    <tr class="device-manager">
                      <td :style="{display: 'flex', gap: '8px'}">
                        <img class="ico" :src="getImageUrl(props.item.kind)"/>
                        <span class="text-styling">{{ props.item.kind | uppercaseFirst }}, {{props.item.operating_system }}, {{props.item.platform}}</span></td>
                      <td class="thead-description">{{ convertDate(props.item.created_at) }}</td>
                      <td class="thead-description">{{ props.item.latest_ip_address }}</td>
                      <td class="actions" :style="{fontSize: '14px', fontWeight: '700'}" @click.prevent="confirmRemoveDevice(props.item.id, props.item.kind, props.item.operating_system, props.item.platform )">
                        {{ $t('action.delete') }}
                      </td>
                    </tr>
                    <template v-if="props.item.isActiveRow">
                      <tr
                        class="device-manager"
                        :class="{ odd: ((props.index + 1) % 2 === 0) }"
                        v-for="(connection, index) in props.item.user_connection_histories" :key="index">
                        <td></td>
                        <td>{{ convertDate(connection.created_at) }}</td>
                        <td>{{ connection.ip_address }}</td>
                        <td></td>
                        <!--<td></td>-->
                      </tr>
                    </template>
                  </template>
                </data-table>
              </div>
              <!--<device-manager/>-->
            </div>
          </div>
        </div>
      </div>
    <InfiniteScrollDevice v-if="sizeScreen < 500" />
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
  import CancelGoogleAuthModal from '@/components/shared_components/otp/CancelGoogleAuthModal';
  import OtpModal from '@/components/shared_components/funds/common/OtpModal';
  import EnableGoogleAuthentication from './account/EnableGoogleAuthentication';
  import ChangePassword from './account/ChangePassword.vue';
  import DeviceManager from './account/DeviceManager.vue';
  import LostOtpModal from '@/components/shared_components/otp/LostOtpModal';
  import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal';
  import SelectBox from '@/components/shared_components/common/SelectBox';
  import moment from 'moment';
  import { getLayoutPath } from '@/routes';
  import ChartContracts from "./ChartIndex.vue";
  import { mapState } from 'vuex';
  import Cookies from 'js-cookie'
  import AccountSiteBar from '@/components/shared_components/common/AccountSiteBar.vue';
  import AccountTopHeader from '@/components/shared_components/common/AccountTopHeader.vue';
  import InfiniteScrollDevice from "@/components/setting_pages/InfiniteScrollDevice.vue";
  import 'url-search-params-polyfill';
  import Breadcrumb from "@/components/Breadcrumb.vue";
  const KYC_UNVERIFED = 'unverified';
  const KYC_PENDING = 'pending';
  const KYC_VERIFIED = 'verified';
  const KYC_REJECTED = 'rejected';
  const BANK_UNVERIFED = 'unverified';
  const BANK_CREATING = 'creating';
  const BANK_VERIFING = 'verifing';
  const BANK_VERIFIED = 'verified';
  const BANK_REJECTED = 'rejected';
  var sha1 = require('sha1');
  export default {
    components: {
      Breadcrumb,
      ChartContracts,
      CancelGoogleAuthModal,
      EnableGoogleAuthentication,
      OtpModal,
      ChangePassword,
      LostOtpModal,
      DeviceManager,
      ConfirmationModal,
      SelectBox,
      AccountSiteBar,
      AccountTopHeader,
      InfiniteScrollDevice
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        linkKycAccount: getLayoutPath({name: 'IdentityPersonal'}, true),
        linkAPIAccount: getLayoutPath({name: 'ApiCreated'}, true),
        linkAPIPage: process.env.VUE_APP_API_URL + '/api/documentation',
        isShowTooltip: false,
        tab : 'main',
        enableWhiteList: undefined,
        enableLeaderboard: undefined,
        localShowFakeName: undefined,
        lastLogin: {},
        withdrawalLimitBTC: [],
        check: 0,
        settings: {
          email: true,
          phone: false,
          bank: false,
          otp: false,
          password: false,
        },
        kyc: {
          status: '',
          bank_status: '',
        },
        user: {},
        infomation: {},
        otp: undefined,
        userSettingWhitelist: undefined,
        enableOtp: true,
        emailNotification: true,
        telegramNotification: true,
        lineNotification:false,
        isCreateNewBankAccount: false,
        enableAmalFromAdmin: false,
        enableAmlPay: false,
        app_name : process.env.VUE_APP_NAME,
        moment: moment,
        isDisableButton: false,
        siteSetting: {},
        idEncrypt:'',
        perpetual_dividend: false,
        isEditFakeName: false,
        fakeNameTemp:'',
        theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
        breadcrumbItems: [
          { label: "Profile", link: "/account/dashboard" },
          { label: "Security", link: "/account/security" },
          { label: "Device management", link: "dashboard" },
        ],
        sizeScreen: window.innerWidth,
      }
    },
    watch: {
      enableAmalFromAdmin(newValue) {
        if (!newValue) {
          this.enableAmlPay = false;
        }
      },
      userThemeMode(theme) {
        this.theme = theme;
      },
    },
    mounted(){
      window.addEventListener("resize", this.resizeHandler);
      this.getMasterData();
      let url=window.location.href;
      if(url.includes("need-close")) {
          window.close();
      }
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
        userThemeMode: (state) => state.userThemeMode,
      }),
      isVerifiableBank() {
        return this.kyc.bank_status === BANK_CREATING
                || this.kyc.bank_status === BANK_VERIFING || this.kyc.bank_status === BANK_VERIFIED;
      },
      appUrl() {
        return window.location.origin;
      },
      submittableKyc() {
        return window._.get(this, "user.security_setting.otp_verified", 0);
      },
      userSecurityLevel() {
        if (this.user.security_level > this.user.max_security_level) {
          return this.user.security_level;
        }
        return this.user.max_security_level;
      },
      desktopMode() {
        return this.theme !== "light-theme" ? "desktop-light.svg" : "desktop-dark.svg";
      },
      mobileMode() {
        return this.theme !== "light-theme" ? "smartphone-light.svg" : "smartphone-dark.svg";
      },
      tabletMode() {
        return this.theme !== "light-theme" ? "tablet-light.svg" : "tablet-dark.svg";
      },
      otherDeviceMode(){
        return this.theme === "light-theme" ? "other-device-light.svg" : "other-device-dark.svg";
      }
    },
    methods: {
      resizeHandler() {
        this.sizeScreen = window.innerWidth;
        this.isOpen = false
      },
      getImageUrl(kind) {
        let ico = '';
        switch (kind) {
          case 'desktop':
            ico = this.desktopMode
                break;
          case 'smartphone':
            ico = this.mobileMode
            break;
          case 'tablet':
            ico = this.tabletMode
            break;
          default:
            ico = this.otherDeviceMode
        }
        return require(`@/assets/images/drx/${ico}`);
      },
      onSaveFakeName(){
          rf.getRequest('UserRequest').onUpdateFakeName({fake_name: this.fakeNameTemp}).then(res =>{
            if(res && res.data){
             this.user.fake_name = this.fakeNameTemp;
             this.isEditFakeName = false;
             this.getUser(false);
             this.showSuccess(this.$t('mam.update.success'));
            }
          })
        },
      activeLeaderboard() {
      },
      getLinkPnlChart() {
          rf.getRequest('UserRequest').getLinkPnlChart({name: 'Pnl-Chart'}).then(res => {
              const path = APP_URL + '/pnl-chart?token='+res.data.id
              // if (!navigator.clipboard) {
              //   Utils.fallbackCopyTextToClipboard(path);
              //   return;
              // }
              // navigator.clipboard.writeText(path);
              // this.$router.push({ name: 'PNLChart', query: {token: res.data.id} });
              window.open(path,"_blank");
          });
        },
      convertDate(date) {
          var dateFormat = 'YYYY-MM-DD HH:mm:ss';
          var testDateUtc = moment.utc(date);
          var localDate = testDateUtc.local();
          return localDate.format(dateFormat);
        },
        addChannelTelegram: function (userID) {
          document.getElementById("cancel_focus_telegram").blur();
          this.listenForTelegramLine(userID);
          let apiTelegram = process.env.VUE_APP_MIX_API_TELEGRAM + userID;
          window.open(apiTelegram, "_blank");
        },

        getParamsUpdateModeWhitelist() {
          return {
            active: !this.userSettingWhitelist
          }
        },
        listenForTelegramLine(userId) {
            window.Echo.private('App.User.' + userId)
              .listen('UserNotificationUpdated', (res) => {
                    let channel = res.data.channel;
                    let is_enable= res.data.is_enable;
                    if(channel==="line"){
                      this.lineNotification = is_enable;
                    }
                    if(channel==="mail"){
                      this.emailNotification = is_enable;
                    }
                    if(channel==="telegram"){
                      this.telegramNotification = is_enable;
                    }
              });
        },
        async redirectLine(userID){
            await this.encryptId();
            document.getElementById("cancel_focus_line").blur();
            if(userID){
              let callback_uri=this.$router.resolve({name: 'Account'}).href+"?need-close";
              let state = userID + "&" + this.idEncrypt + "$" + callback_uri;
              let client_id=process.env.VUE_APP_MIX_LINE_CLIENT_ID;
              let redirect_uri=process.env.VUE_APP_API_URL+'/api/v1/get-auth-code';
              let response_type = "code";
              let url="https://notify-bot.line.me/oauth/authorize?";
              let scope="notify";
              var searchParams = new URLSearchParams("");
              searchParams.append("client_id",client_id);
              searchParams.append("redirect_uri",redirect_uri);
              searchParams.append("response_type",response_type);
              searchParams.append("scope",scope);
              searchParams.append("state",state);
              url=url+searchParams;
              window.open(url,"_blank");
            }
        },
        changeWalletFee(type) {
          if (this.tab != type && this.enableAmlPay) {
            this.tab = type;
            let param = {
              wallet_name : type,
            }
            rf.getRequest('UserRequest').changeTypeWalletAmalPayFee(param).then(res => {});
          }

        },

        showChannelModal(type, channel) {
            if (type === 'disable') {
                window.ConfirmationModal.show({
                    type: channel === 'email' ? 'disableEmail' : channel === 'telegram' ? 'disableTelegram' : channel==='line' ? 'disableLine':'',
                    title: '',
                    content: channel === 'email' ?
                        this.$t('account.email_disable_confirm') :
                        channel === 'telegram' ? this.$t('account.email_disable_confirm') :
                        channel === 'line' ? this.$t('account.email_disable_confirm') : '',
                    btnCancelLabel: window.i18n.t('common.action.no'),
                    btnConfirmLabel: window.i18n.t('common.action.yes'),
                    onConfirm: () => {
                        this.actionNotification(type, channel);
                    }
                });
            } else {
                document.getElementById("cancel_focus_mail").blur();
                this.actionNotification(type, channel);
            }
        },
      actionNotification(type, channel) {
            let param = {
                active: 1
            };
            if (type === 'disable') {
                param.active = 0;
            } else {
                param.active = 1;
            }
            if (channel === 'email') {
                rf.getRequest('UserRequest').changeEmailNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
            if (channel === 'telegram' && param.active == 0) {
                rf.getRequest('UserRequest').changeTelegramNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
            if (channel === 'line' ) {
                rf.getRequest('UserRequest').changeLineNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
        },
      showTooltip() {
        this.isShowTooltip = !this.isShowTooltip;
      },
      hideTooltip() {
        this.isShowTooltip = false;
      },
      showCancelGoogleAuthModal() {
        window.CommonModal.show('CancelGoogleAuthModal');
      },
      showChangePasswordModal() {
        window.CommonModal.show('ChangePassword');
        this.$refs.changePassComponent.resetData();
      },
      hideCancelGoogleAuthModal() {
        window.CommonModal.hide('CancelGoogleAuthModal');
        this.getSecuritySettings();
        this.getUser(false);
      },
      hideEnableGoogleAuthenticaion() {
        window.CommonModal.hide('EnableGoogleAuthentication');
        this.getSecuritySettings();
        this.getUser(false);
      },
      showEnableOtpModal() {
        window.CommonModal.show('EnableGoogleAuthentication');
      },
      showModalLostOtp () {
        window.CommonModal.show('lostOtpForm');
      },
      disableOtpSuccess () {
        this.getSecuritySettings();
        this.getUser(false);
      },
      loadDataImmediately() {
        this.getUserSettings();
        this.getUserNotification();
        this.getSecuritySettings();
        this.getUser();
        this.getWithdrawalLimitBTC();
        this.getUserKyc();
        this.getDividendSettings();
      },
      getSecuritySettings() {
        rf.getRequest('UserRequest').getSecuritySettings().then(res => {
          this.settings = Object.assign({}, this.settings, res.data);
          this.enableOtp = window._.get(this, "settings.otp_verified", 0);
        });
      },
      getUserKyc() {
        rf.getRequest('UserRequest').getUserKyc().then(res => {
          this.kyc = res.data || {};
          this.isCreateNewBankAccount = this.kyc.bank_status === BANK_CREATING;
        });
      },
      async encryptId() {
        await rf.getRequest('UserRequest').encryptId().then(res => {
          this.idEncrypt = sha1(res.toString()) || {};
        });
      },
      getDividendSettings() {
        rf.getRequest('UserRequest').getDividendSetting().then(res => {
          this.enableAmalFromAdmin = res.data?.enable_fee_amal || false;
          this.perpetual_dividend = res.data?.enable_wallet_pay_fee || false;
        })

      },
      getHistoryConnection(params) {
        const promise = rf.getRequest('UserRequest').getHistoryConnection(params);
        this.getLatest(promise);
        return promise;
      },
      getLatest(promise) {
        promise.then(res => {
          this.lastLogin = res.data.data[0];
        });
      },

      getUserNotification(){
        rf.getRequest('UserRequest').getUserNotificationSettings().then(res => {
            const emailNotification =  _.find(res.data, (item) => {
              return item.channel === 'mail'
            }) || {};

            this.emailNotification = emailNotification.is_enable === 1;

            const telegramNotification = _.find(res.data, (item) => {
              return item.channel === 'telegram'
            }) || {};

            this.telegramNotification = telegramNotification.is_enable === 1;

            const lineNotification = _.find(res.data, (item) => {
              return item.channel === 'line'
            }) || {};
            this.lineNotification = lineNotification.is_enable === 1;
        });
      },

      getUserSettings() {
        rf.getRequest('UserRequest').getUserSettings().then(res => {
          const setting = _.find(res.data, (item) => {
            return item.key === 'whitelist'
          }) || {};
          this.userSettingWhitelist = this.enableWhiteList = !!parseInt(setting.value);

          const amlPay =  _.find(res.data, (item) => {
            return item.key === 'amal_pay'
          }) || {};
          const amlWalletPay =  _.find(res.data, (item) => {
            return item.key === 'amal_pay_wallet'
          }) || {};
          this.enableAmlPay = amlPay.value === "1";
          if (amlWalletPay && amlWalletPay.value) {
            this.tab = amlWalletPay.value;
          }

        });
      },
      getUser(useCache = true) {
        rf.getRequest('UserRequest').getCurrentUser(useCache).then(res => {
          this.user = Object.assign({}, this.user, res.data);
          this.listenForTelegramLine(res.data.id);
          this.localShowFakeName = this.user.security_setting.use_fake_name;
          return this.enableLeaderboard = this.user.security_setting.use_fake_name
        });
      },
      getWithdrawalLimitBTC() {
        rf.getRequest('UserRequest').getWithdrawalLimitBTC().then(res => {
            this.withdrawalLimitBTC = res.data;
        });
      },
      activeWhitelist() {
        const message = this.userSettingWhitelist ? window.i18n.t('account.turn_on_mode_whitelist') : window.i18n.t('account.turn_off_mode_whitelist');
        this.showModal({
          type: 'primary',
          title: '',
          content: message,
          onConfirm: () => {
            if (this.enableOtp) {
              window.CommonModal.show('otp_modal');
              this.enableWhiteList = this.userSettingWhitelist;
            } else {
              const params = this.getParamsUpdateModeWhitelist();
              this.updateModeWhitelist(params);
            }
          },
          onCancel: () => {
            this.enableWhiteList = this.userSettingWhitelist;
          }
        });
      },
      updateModeWhitelist(params) {
        this.isDisableButton = true;
        rf.getRequest('UserRequest').activeWhitelist(params).then( response => {
          this.isDisableButton = false;
          window.CommonModal.hide('otp_modal');
          this.userSettingWhitelist = this.enableWhiteList = response.data.whitelist;
        }).catch(error => {
          this.isDisableButton = false;
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'));
          } else {
            this.convertRemoteErrors(error);
          }
        })
      },
      activeLeaderboard() {
        const message = this.enableLeaderboard ? window.i18n.t('leaderboard.show_fake_username') : window.i18n.t('leaderboard.show_real_username');
        this.showModal({
          title: '',
          content: message,
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.updateLeaderboard()
          },
          onCancel: () => {
            this.enableLeaderboard = !this.enableLeaderboard ? 1 : 0
          }
        });
      },
      updateLeaderboard() {
        let params = {
          use_fake_name: !this.enableLeaderboard ? 0 : 1
        }
        rf.getRequest('UserRequest').activeLeaderBoard(params).then(res=>{
          this.localShowFakeName = res.data;
        }).catch(err=>{
          this.localShowFakeName = 0;
        });
      },
      showSuccess(message) {
        Message.success(
          message,
          {},
          { position: 'bottom_left' }
        );
      },
      getSocketEventHandlers() {
        return {
          OtpUpdated: this.getSecuritySettings
        }
      },
      verifyBankAccount (status) {
        rf.getRequest('UserRequest').updateBankAccountStatus(status).then(res => {
          this.getUserKyc();
        });
      },
      verifyBankModal () {
        if (this.kyc.status !== KYC_VERIFIED) {
          this.showModal({
            type: 'warning',
            title: 'Error',
            content: this.$t('refferal.alert_bank_account_content'),
            noAction: true,
          });
          return;
        }
        this.showModal({
          type: 'confirm',
          title: this.$t('refferal.confirm_bank_account_title'),
          customContent: true,
          onConfirm: () => {
            this.verifyBankAccount({status: this.isCreateNewBankAccount ? BANK_CREATING : BANK_VERIFING})
          }
        });
      },
      showModal (config) {
        const type = config.type;
        const title = config.title;
        const content = config.content;
        const customContent = config.customContent;
        let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
        let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
        if (config.noAction) {
          btnCancelLabel = null;
          btnConfirmLabel = null;
        }
        const onConfirm = config.onConfirm;
        const onCancel = config.onCancel;
        window.ConfirmationModal.show({
          type: type,
          title: title,
          content: content,
          customContent: customContent,
          btnCancelLabel: btnCancelLabel,
          btnConfirmLabel: btnConfirmLabel,
          onConfirm: onConfirm,
          onCancel: onCancel
        });
      },
      onClickedVerifyGoogleAuthen(pathRoute) {
        if (!this.submittableKyc) {
          Message.error(this.$t('account.otp.should_enable_first'), {}, { position: 'bottom_left' });
          return;
        }
        this.$router.push({ path: pathRoute });
      },
      activeAmlPay() {
        if (this.enableAmalFromAdmin) {
          let param = {
            active: !this.enableAmlPay ? 1 : 0
          }
          rf.getRequest('UserRequest').changeAmlPay(param).then(res => { });
        }
      },
      getUserDevice(params) {
        const promise = rf.getRequest('UserRequest').getUserDevice(params);
        return promise;
      },
      onDatatableFinished() {
        if (!this.$refs.datatableDevice || !this.$refs.datatableDevice.rows) {
          return;
        }
        window._.each(this.$refs.datatableDevice.rows, item => {
          item.isActiveRow = false;
        });
      },
      activeRow(item) {
        item.isActiveRow = !item.isActiveRow;
        this.$forceUpdate();
      },
      refreshDevice() {
        this.$refs.datatableDevice.refresh();
      },
      confirmRemoveDevice (deviceId, deviceKind, deviceSystem, devicePlatform) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          icon: '/images/icon/icon-devices.svg',
          content: this.$t('account.modal.confirm_trusted_device'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.removeDevice(deviceId,deviceKind, deviceSystem, devicePlatform);
          },
          onCancel: () => {

          }
        });
      },
      removeDevice (deviceId, deviceSystem, devicePlatform,deviceKind) {
        rf.getRequest('UserRequest').deleteDevice(deviceId).then((res) => {
          this.refreshDevice();

          const divice = window._.capitalize(deviceSystem) + ", " + devicePlatform + ", " + deviceKind ;

          Message.deleteDevice(
            window.i18n.t('address.deleted_device'),
            window.i18n.t('address.deleted_success_message', { name: divice }));
        });
      },

      getMasterData() {
        window._.each(this.masterdata.settings, item => {
          this.siteSetting[item.key] = item.value;
        });
      },
      clickBack() {
        this.$router.back();
      }
    },
    created () {
      this.loadDataImmediately();
        document.title = this.$t('menu.account') + ` – ${this.tabTitle}`;
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  #account_device {
    padding: 24px 40px;
    @media screen and (max-width: 500px) {
      padding: 24px 20px;
    }
    .account_right {
      flex: 1;
      .thead-custom{
        background: transparent;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #8E8E92;
        padding: 4px 0px 4px 12px;
        border-bottom: 1px solid var(--line-color-table-home);
        border-top: 1px solid var(--line-color-table-home);
      }
      .text-styling{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #8E8E92;
      }
      .thead-description{
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: var(--text-primary-color);
      }
      .device-manager{
        border-bottom: 1px solid var(--line-color-table-home);
        border-top: 1px solid var(--line-color-table-home);
      }
      .ico{
        width: 20px;
        height: 20px;
      }
      .account_right_content {
        min-height: 650px;
        .title_back {
          display: flex;
          gap: 18px;
          align-items: center;
          margin-top: 24px;
          margin-bottom: 30px;
          .icon_back {
            cursor: pointer;
            height: 16px;
          }
        }
        .title_account {
          font-family: $font-inter-bold;
          color: var(--text-dropdown);
          font-size: 30px;
          font-weight: 700;
          line-height: 38px;
        }
        .sub_title_account {
          font-family: $font-inter-regular;
          color: var(--dark-2);
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 70px;
        }
      }
    }
    .tableContainer{
      table{
        th{
          font-size: 18px;
          line-height: 22px;
          color: var(--text-close);
          font-weight: 400;
          background: var(--color-tab-data-table);

          &:hover{
            color: var(--text-close) !important;
          }
        }
        th:first-child{
            width: 40.2%;
        }
        th:nth-child(2) {
            width: 22.8%;
        }
        th:nth-child(3) {
            width: 20%;
        }
        th:nth-child(4) {
            width: 9%;
            text-align: center;
        }

        td{
          color: var(--text-primary-color);
        }
      }
    }


  }
  @media screen and (max-width: 992px) {
    #account_device {
      .account_right {
        .account_right_content {
          padding: 30px 15px 120px;
          position: relative;
          .title_back {
            .icon_back {
              height: 13px;
            }
          }
          .title_account {
            font-size: 20px;
            line-height: 24px;
          }
          .sub_title_account {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 30px;
          }
          .account_right_main {
            padding-bottom: 25px;
            width: 100%;
            z-index: 5;
            border: 0px;
            border-top: none;
            overflow-y: auto;
            overflow-x: auto;
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            &::-webkit-scrollbar-track {
              background: $color-alabaster;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 8px;
              background: $color-gray-alto;
            }
            .datatable {
              width: 100%;
              min-width: 992px;
            }
            }
        }
      }
      .tableContainer {
        table {
          th {
            font-size: 14px;
            line-height: 16px;
          }
          tbody {
            td {
              font-size: 14px;
              line-height: 16px;
              padding: 17px 15px;
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
#account_device {
  .account_right {
    table {
      thead {
        th {
          background-color: var(--color-dark-5);
          color: var(--text-desc);
        }
      }
      tbody {
        tr {
          td {
            padding: 16px 0px 16px 12px;
            text-align: left;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            color: #F4F5F7;

            &:last-child {
              padding: 16px 0px 16px 12px;
              color: $color-aqua-green;
              cursor: pointer;
              text-align: center;
            }
          }
        }
      }
    }
  }
.modal-body{
  .content_text{
    color: var(--text-primary-color) !important;
    font-weight: 600;
  }
}
@media screen and (max-width: 992px) {
  .account_right {
    table {
      tbody {
        tr {
          td {
            font-size: 14px;
            line-height: 16px;
              &:last-child {
                padding: 17px 15px;
            }
          }

        }
      }
    }
  }
  .VuePagination {
          position:absolute;
          left:0;
          right:0;
          margin-left:auto;
          margin-right:auto;
          bottom: 20px;
        }

  .modal-body {
    padding: 0 15px 10px !important;

    .icon {
      width: 80px;
      height: 80px;
    }
    .content_text{
      font-size: 16px !important;
      line-height: 19px !important;
    }
    .mart50{
      gap: 15px !important;
    }
    button {
      min-width: unset !important;
      padding: 14.5px 10px !important;
      font-size: 16px !important;
      line-height: 19px !important;
    }
  }
}

}

</style>
