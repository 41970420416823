<template>
  <div class="overview">
    <div class="header">
      <div class="header-name">{{ $t("today.PNL") }}</div>
      <div class="position-relative information" id="infor">
        <!-- <img
          :src="
            require(`@/assets/images/icon/infor${
              theme === 'light-theme' ? '-light' : '-dark'
            }.svg`)
          "
          alt=""
        /> -->
        <div class="position-absolute content" id="content-infor">
          <span v-html="$t('common.spot.todayPNL')"></span>
        </div>
      </div>
    </div>
    <div class="hoverPNL">
      <div v-if="!tongglePrice" :class="clazzPNL(pnl)">
        {{ pnl | formatNumberAndDecimal }}
      </div>
      <div v-else class="hidePrice">***********</div>
    </div>
    <div class="hoverPNL">
      <div v-show="checkTooltipPNLPercent(pnlChange, 7)" class="unHideCoin">
        {{ pnlChange }}%
      </div>

      <!-- <div v-if="!tongglePrice" :class="clazzPNL(pnlChange)">
        {{ formatPNLChange(pnlChange?.toString(), 2, 8) }}
      </div> -->
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import { mapState } from "vuex";
import rf from "@/request/RequestFactory";

export default {
  data() {
    return {
      pnl: "0",
      pnlChange: "0",
    };
  },
  props: {
    tongglePrice: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
  },
  methods: {
    checkDisplayFullCoin(coin, maxDecimal, maxLength) {
      const numFormat = new BigNumber(coin).toFixed(
        maxDecimal || 2,
        BigNumber.ROUND_DOWN
      );
      return numFormat.replace(".", "").length > maxLength;
    },
    checkTooltipPNLPercent(num, maxLength) {
      return num.toString().length > maxLength;
    },
    clazzPNL(value) {
      if (value === 0) {
        return "pnl";
      }
      return value > 0 ? "pnl pnl-green" : value < 0 ? "pnl pnl-red" : "pnl";
    },
    cutDecimals(value, maxDecimal, maxLength) {
      const numFormat = new BigNumber(Math.abs(value).toString()).toFixed(
        maxDecimal || 2,
        BigNumber.ROUND_DOWN
      );
      const split = value.split(".");
      const beforeDecimal = split[0];
      const shorttedNumber =
        beforeDecimal.length > maxLength
          ? numFormat
              .slice(0, maxLength - 1)
              .concat("...")
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : numFormat
              .slice(0, maxLength)
              .concat("...")
              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      const formattedNumber =
        numFormat.length > maxLength
          ? shorttedNumber
          : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return formattedNumber;
    },
    formatPNL(value, maxDecimal, maxLength) {
      if (value === "0") {
        return 0;
      }
      const isPositive = Number(value) > 0;
      const isZero = Number(value) === 0;
      const formattedNumber = this.cutDecimals(value, maxDecimal, maxLength);
      return isPositive || isZero
        ? `$${formattedNumber}`
        : `- $${formattedNumber}`;
    },
    formatPNLHover(value, decimal) {
      const numFormat = new BigNumber(value).toFixed(
        decimal || 2,
        BigNumber.ROUND_DOWN
      );

      return numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    formatPNLChange(value, decimal, maxlength) {
      if (value === "0") {
        return "--";
      }
      const isPositive = Number(value) > 0;
      const isZero = Number(value) === 0;
      const formattedNumber = this.cutDecimals(value, decimal, maxlength);
      return isPositive || isZero
        ? `${formattedNumber}%`
        : `- ${formattedNumber}%`;
    },
    calculatePNL() {
      rf.getRequest("UserRequest")
        .calculatePNL()
        .then((res) => {
          this.pnl = res.data.pnl;
          this.pnlChange = res.data.pnl_change;
        });
    },
  },
  created() {},
  mounted() {
    this.calculatePNL();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.overview {
  margin-top: 16px;
  display: flex;
  align-items: center;

  .header {
    display: flex;
    gap: 8px;
    justify-items: center;
    .header-name {
      font-family: inherit;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-close);
    }
  }

  .pnl {
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--text-primary-color);
  }

  .pnl-red {
    color: $text-color-red;
  }
  .pnl-green {
    color: $text-color-jade;
  }
  .hoverPNL {
    position: relative;
    .unHideCoin {
      display: none;
    }
    &:hover {
      .unHideCoin {
        z-index: 10;
        display: block;
        position: absolute;
        padding: 10px;
        border-radius: 8px;
        background: var(--color-tooltip);
        color: $color-white !important;
        top: 40px;
        font-size: 16px;
      }
    }
    .hidePrice {
      font-weight: 600;
      font-size: 30px;
      color: var(--text-primary-color);
      padding-top: 5px;
      @media screen and (max-width: 992px) {
        font-size: 20px;
      }
    }
  }
}
.information {
  .content {
    width: 227px;
    height: auto;
    z-index: 1;
    right: -110px;
    top: 24px;
    border-radius: 8px;
    padding: 10px;
    color: white;
    display: none;
  }

  .infor-light {
    display: block;
  }
  .infor-dark {
    display: none;
  }
}

div#infor:hover div#content-infor {
  display: block;
}
</style>
