<template>
  <div class="item-tab">
    <data-table3
      ref="datatable"
      :msgEmptyData="$t('common.datatable.no_have_data')"
      :getData="getData"
      class="table-landing"
      id="data-table-landing"
      :defaultSort="false"
      :limit="6"
    >
      <template slot-scope="props">
        <th
          class="w_20"
          :data-sort-field="marketSelected === 'COIN_M' || marketSelected === 'USD_M' ? 'coin' : 'pair'"
          v-if="isVisibleColumn('pair')"
          @click="props.echoclick"
        >
          <span v-if="!futuresTable">{{ $t("landing.pair") }}</span>
          <span v-else>{{ $t("funds.balances.name") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <!--        <th-->
        <!--            data-sort-field="full_name"-->
        <!--            class="col-coin w_13"-->
        <!--            v-if="isVisibleColumn('coin')"-->
        <!--            @click="props.echoclick"-->
        <!--        >-->
        <!--          <span>{{ $t("landing.coin") }}</span>-->
        <!--          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>-->
        <!--          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>-->
        <!--        </th>-->
        <th
          data-sort-field="current_price"
          class="col-last-price w_20"
          v-if="isVisibleColumn('current_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.last_price") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="changed_percent"
          class="col-24h-changed w_14"
          v-if="isVisibleColumn('changed_percent')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.change") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="max_price"
          class="col-max-price w_17"
          v-if="isVisibleColumn('max_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.high") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="min_price"
          class="col-min-price w_18"
          v-if="isVisibleColumn('min_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.low") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :data-sort-field="
            marketSelected === 'COIN_M' || marketSelected === 'USD_M'
              ? 'quote_volume'
              : 'quote_volume'
          "
          class="col-volume w_10"
          v-if="
            isVisibleColumn(
              marketSelected === 'COIN_M' || marketSelected === 'USD_M'
                ? 'quote_volume'
                : 'quote_volume'
            )
          "
          @click="props.echoclick"
        >
          <span>{{ $t("landing.volume") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <!-- <tr> -->
          <td
            class="text-left col-pair"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('pair')"
          >
            <span
              class="icon-star2"
              :class="
                isFavorite(props.item.coin, props.item.currency)
                  ? 'icon-star2 icon-star-selected'
                  : 'star_gray'
              "
              @click.stop="
                isFavorite(props.item.coin, props.item.currency)
                  ? removeFavorite(props.item.coin, props.item.currency)
                  : insertFavorite(props.item.coin, props.item.currency)
              "
            ></span>
            <span class="coin">{{ props.item.coin | currencyName }}</span
            ><span class="currency" v-if="props.item.currency"
              >/{{ props.item.currency | currencyName }}</span
            >
            <span class="currency" v-else> {{$t("common.perpetual")}}</span>
          </td>
          <!--          <td-->
          <!--              class="col-coin"-->
          <!--              @click="selectedCurrenyPair(props.item.currency, props.item.coin)"-->
          <!--              v-if="isVisibleColumn('coin')"-->
          <!--          >-->
          <!--            {{ props.item.coin | fullName }}-->
          <!--          </td>-->
          <td
            class="col-last-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('current_price')"
          >
            <span
              class="change-node"
              :class="calculateLastPriceStatus(props.item)"
            >
              <span v-if="marketSelected === 'COIN_M' || marketSelected === 'USD_M'">
                {{
                  props.item.current_price | formatNumberAndDecimal
                }}
              </span>
              <span v-else>
                {{
                  props.item.current_price
                      | formatNumberAndDecimal
                }}
              </span>
            </span>
            <span
              class="is-muted"
              v-if="props.item.currency !== 'usd' && props.item.usdPrice > 0"
            >
              / ${{ props.item.usdPrice | formatNumberAndDecimal }}
            </span>
          </td>
          <td
            class="change-node col-24h-changed"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :class="{
              grown: props.item.changed_percent > 0,
              down: props.item.changed_percent < 0,
              black: props.item.changed_percent == 0,
            }"
            v-if="isVisibleColumn('changed_percent')"
          >
            {{ formatShortText((props.item.changed_percent), 2, 8) | changedPercent }}
          </td>
          <td
            class="col-max-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('max_price')"
          >
          <span v-if="marketSelected === 'COIN_M' || marketSelected === 'USD_M'">
            {{
              props.item.max_price | formatNumberAndDecimal
            }}
            </span>
            <span v-else>
              {{
                props.item.max_price
                    | formatNumberAndDecimal
              }}
            </span>
          </td>
          <td
            class="col-min-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('min_price')"
          >
          <span v-if="marketSelected === 'COIN_M' || marketSelected === 'USD_M'">
            {{
              props.item.min_price | formatNumberAndDecimal
            }}
            </span>
            <span v-else>
              {{ 
                props.item.min_price | formatNumberAndDecimal
              }}
            </span>
          </td>
          <td
            class="col-volume"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="
              isVisibleColumn(
                marketSelected === 'COIN_M' || marketSelected === 'USD_M'
                  ? 'quote_volume'
                  : 'volume'
              )
            "
          >
            <span
              v-if="marketSelected === 'COIN_M' || marketSelected === 'USD_M'"
            >
              {{
                props.item.quote_volume | formatNumberAndDecimal
              }}
            </span>
            <span v-else>{{props.item.quote_volume | formatNumberAndDecimal }}</span>
          </td>
        </tr>
      </template>
    </data-table3>
  </div>
</template>

<script>
import FavoritesManager from "@/common/FavoritesManager";
import COMMON_CONST from "@/common/Const";
import BigNumber from "bignumber.js";
import rf from '@/request/RequestFactory'

const ALL_COLUMNS = [
  "pair",
  "coin",
  "current_price",
  "changed_percent",
  "max_price",
  "min_price",
  "volume",
  "quote_volume"
];

export default {
  name: "marketPagination",
  components: {},
  props: {
    favorites: { type: [Array] },
    marketSelected: { type: String },
    coinNames: { type: String },
    priceScope: { type: [Object, Array] },
    columns: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    allPriceCoinsData: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    futuresTable: {type: Boolean , default: false}
  },
  data() {
    return {
      price24hData: [],
      allDataFromFuture: [], // this is data from current page of future tab
      // use count for first init component
      count: 0,
      saveDataFutureAPI: [], // difference with allDataFromFuture, this is data on first call API(all page)
      fetchingData: false,
      currentPage: 1,
      future24Data: [],
      listInstruments: [],
      lastPage: 0
    };
  },
  watch: {
    marketSelected () {
      this.resetSortOrder();
      this.$refs.datatable.getDataSuccess = false;
      this.$refs.datatable.params.sort = '';
      this.$refs.datatable.params.sort_type = '';
      this.$refs.datatable.lastPage = 0;
      this.$refs.datatable.rows = [];
      this.currentPage = 1;
      this.allDataFromFuture = []
      if (this.$attrs.tabMain !== COMMON_CONST.FUTURE_TAB) {
        this.$refs.datatable.params.sort = "quote_volume";
        this.$refs.datatable.params.sort_type = "desc";
        setTimeout(() => {
          const thElement = document.querySelector('th[data-sort-field="quote_volume"]');
          if (thElement) {
            thElement.setAttribute('data-sort-order', 'desc');
          }
        }, 100);

      }
      this.checkFetchedData();
    },
    coinNames: _.debounce(function (e) {
      this.$refs.datatable.getDataSuccess = false;
      this.$refs.datatable.params.sort = "";
      this.$refs.datatable.params.sort_type = "";
      this.$refs.datatable.lastPage = 0;
      this.$refs.datatable.rows = [];
      this.currentPage = 1;
      this.resetSortOrder();
      this.checkFetchedData();
    }, 350)
  },
  computed: {
    visibleColumns() {
      if (window._.isEmpty(this.columns)) {
        return ALL_COLUMNS;
      }
      return this.columns;
    },
  },
  methods: {
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
      }
    },
    onPricesUpdated(newValue) {
      let coinKey = Object.keys(newValue)[0];

      if(this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
        this.allPriceCoinsData[coinKey].current_price = newValue[coinKey].price;
        this.allPriceCoinsData[coinKey].quote_volume = newValue[coinKey].volume;
        this.allPriceCoinsData[coinKey].changed_percent = newValue[coinKey].change;
        this.$refs && this.$refs.datatable && this.$refs.datatable.hotFetch();
        return;
      }

      if (this.price24hData.hasOwnProperty(coinKey)) {
        this.price24hData[coinKey].current_price = newValue[coinKey].price;
        this.price24hData[coinKey].quote_volume = newValue[coinKey].volume;
        this.price24hData[coinKey].changed_percent = newValue[coinKey].change;
      }

    },

    isPagination(value) {
      this.$emit('isPagination', value)
    },
    resetSortOrder() {
      let thElements = document.querySelectorAll('th[data-sort-field]');
      thElements.forEach(element => {
        element.setAttribute('data-sort-order', '');
      });
    },

    calculateLastPriceStatus(item) {
      let compareResult;
      if (item.previous_price)  {
        compareResult = new BigNumber(item.current_price || 0).comparedTo(
            item.previous_price || 0
        );
      } else {
        compareResult = new BigNumber(item.lastPriceChange || 0);
      }
      return {
        grown: compareResult > 0,
        down: compareResult < 0,
        black: compareResult == 0,
      };
    },

    isVisibleColumn(column) {
      return window._.indexOf(this.visibleColumns, column) > -1;
    },

    isFavorite(coin, currency) {
      const conditionFuture = this.futuresTable || this.tabMain === COMMON_CONST.FAVORITES_TAB
      const coinPair =  conditionFuture?  coin : this.getCoinPair(coin, currency)
      if(conditionFuture) {
        let result = window._.filter(this.favorites, function (value) {
          return value.symbol == coinPair
        });
        return result.length > 0;
      }else {
        let result = window._.filter(this.favorites, function (value) {
          return value.coin_pair == coinPair
        });
        return result.length > 0;
      }
    },

    insertFavorite(coin, currency) {
      if(!this.futuresTable) {
        const coinPair = this.getCoinPair(coin, currency);
        FavoritesManager.addFavorite({ coin_pair: coinPair }).then((data) => {
          this.favorites.push(data);
        });
      }
      else {
        const data = {
          isFavorite: true ,
          symbol: coin
        }
        FavoritesManager.addFavoriteFuture(data).then((data) => {
          this.favorites.push(data);
        });
      }
    },

    removeFavorite(coin, currency) {
      if(!this.futuresTable) {
        const coinPair = this.getCoinPair(coin, currency);
        let favoriteObject = window._.find(this.favorites, [
          "coin_pair",
          coinPair,
        ]);

        if (window.isAuthenticated) {
          let favoriteAuthenticated = JSON.parse(window.localStorage.getItem('favorite_pairs_authenticated'));
          if(favoriteAuthenticated) {
            favoriteAuthenticated = favoriteAuthenticated.filter(item => item.id !== favoriteObject.id);
            window.localStorage.setItem('favorite_pairs_authenticated', JSON.stringify(favoriteAuthenticated || []));
          }
        }

        FavoritesManager.removeFavorite(favoriteObject).then((res) => {
          this.favorites.splice(this.favorites.indexOf(favoriteObject), 1);
          // In the favorites tab, table remove pair which isn't favorited.
          if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
            // this.price24hData = window._.filter(this.price24hData, (item) => {
            //   const pair = this.getCoinPair(item.coin, item.currency);
            //   return pair !== coinPair;
            // });
            this.$refs.datatable.getDataSuccess = false;
            this.$refs.datatable.lastPage = 0;
            this.$refs.datatable.rows = [];
            this.checkFetchedData();
          }
        });
      }
      else {
        const data = {
          isFavorite: false,
          symbol: coin
        }
        FavoritesManager.removeFavoriteFuture(data).then((data) => {
          let favoriteObject = window._.find(this.favorites, items => {
            return items.symbol == coin
          });
          this.favorites.splice(this.favorites.indexOf(favoriteObject), 1)
          // this.favorites.push(data);
        });
      }

    },

    getCoinPair(coin, currency) {
      return `${coin}/${currency}`;
    },

    selectedCurrenyPair(currency, coin) {
      let newurl = ''
      const pair = { currency: currency, coin: coin };
      if(!this.futuresTable) {
        newurl = `/spot-exchange/basic?currency=${pair.currency}&coin=${pair.coin}`;
      } else {
        this.$router.push({name: 'Margin Future',  params: { coin: `futures/${this.marketSelected}/${pair.coin}` }});
        return
      }

      this.$broadcast("selectedPairMarket", pair);

      window.history.pushState({ path: newurl }, "", newurl);

      this.$router.go(newurl);
    },

    checkFetchedData() {
      if (!this.fetchingData) {
        this.fetchingData = true;
        this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
      }
    },

    getData(params) {
      const coin = {limit: 5, page: this.currentPage || 1, coin: this.marketSelected || 'btc', keyword: this.coinNames.toLowerCase()};
      // add desc sort by volume for spot tab on first init component
      if (this.count === 0) {
        params.sort = "quote_volume"
        params.sort_type = "desc"
        setTimeout(() => {
          this.$refs.datatable.params.sort = "quote_volume";
          this.$refs.datatable.params.sort_type = "desc";
        }, 100);


        this.count++;
      }
      params = Object.assign({}, coin, params);
      this.currentPage = params.page;

      // favourite tab
      if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
        delete params.coin;

        // socket
        if (params.socket) {
          return FavoritesManager.getFavoritesPagination(params, this.allPriceCoinsData).then((res) => {
            let responseData = res;
            this.fetchingData = false;
            if(responseData.data.data) {
              responseData.data.data.map(f =>
                window._.assign(f,
                  this.allPriceCoinsData[`${f.coin_pair.split('/')[1]}_${f.coin_pair.split('/')[0]}`],
                  this.allPriceCoinsData[`${f.coin_pair.split('_')[1]}_${f.coin_pair.split('_')[0]}`])
              )
            }
            this.price24hData = responseData.data.data || [];
            return responseData;
          });
        }

        // api or localstorage
        return FavoritesManager.getFavoritesPagination(params, this.allPriceCoinsData).then((res) => {
          let responseData = res;
          if (window.isAuthenticated) {
            window.localStorage.setItem('favorite_pairs_authenticated', JSON.stringify(res.data || []));
            const data = FavoritesManager.reformatDataFavorites(responseData.data, params, this.allPriceCoinsData);
            responseData = { data };
          }
          this.fetchingData = false;
          if (responseData.data.data) {
            responseData.data.data.map(f =>
              window._.assign(f,
                this.allPriceCoinsData[`${f.coin_pair.split('/')[1]}_${f.coin_pair.split('/')[0]}`],
                this.allPriceCoinsData[`${f.coin_pair.split('_')[1]}_${f.coin_pair.split('_')[0]}`])
            )
          }
          this.price24hData = responseData.data.data || [];
          return responseData;
        });
      }

      // future tab
      if (this.$attrs.tabMain === COMMON_CONST.FUTURE_TAB) {
        // socket from future
        if (params.socket) {
          return this.futureData(this.allDataFromFuture, params);
        }

        if (params.sort && params.sort_type && this.allDataFromFuture.length > 0) {
          return this.futureData(this.allDataFromFuture, params);
        }
        if (params.sort && params.sort_type && this.price24hData.length > 0) {
          return new Promise((resolve) => {
            this.fetchingData = false;
            return resolve(this.futureData(this.price24hData, params));
          })
        }

        // when the user is unsorted and has called the API to get data from the future
        if (this.allDataFromFuture.length > 0) {
          return new Promise((resolve) => {
            this.fetchingData = false;
            return resolve(this.futureData(this.saveDataFutureAPI, params));
          })
        }

        // first go to future tab and get data from API
        return rf.getRequest("PriceRequest")
          .get24hFuture({...params, contractType: params.coin})
          .then((res) => {
            this.fetchingData = false;
            this.price24hData = res.data;
            this.saveDataFutureAPI = res.data;
            const thElement = document.querySelector('th[data-sort-field="quote_volume"]');
            thElement.setAttribute('data-sort-order', 'desc');
            this.$refs.datatable.params.sort = "quote_volume";
            this.$refs.datatable.params.sort_type = "desc";

            return this.futureData(res.data, params)
          })
      }

      // spot tab and first call API
      return rf.getRequest('PriceRequest').getPrices24h(params)
        .then(res => {
          this.fetchingData = false;
          this.price24hData = res.data.data;
          return res;
        });
    },


    futureData(res, params) {
      const dataFuture =[]
      let data = []
      delete params.coin;
      let rawData = res.map(item => {
        return {...item, quote_volume: item.quote_volume || item.quoteVolume}
      });

      if(params.sort) {
        rawData = _.orderBy(
          rawData,
            (item) => {
              if (isNaN(item[params.sort])) {
                if (params.sort !== "full_name") {
                  return item[params.sort];
                } else {
                  return window.i18n.t("currency." + item.coin + ".fullname");
                }
              }
              return new BigNumber(`${item[params.sort]}`).toNumber();
            },
            [params.sort_type]
        );
      }
      rawData.forEach(items => {
        // const typeMarket = this.marketSelected === 'USDT CONTRACTS'?
        //   items.symbol.includes('USDT')  && !items.symbol.includes('USDTUSD')
        //   : items.symbol.includes('USDTUSD') ||  !items.symbol.includes('USDT')
        if (items.symbol.includes(this.coinNames.toLocaleUpperCase())) {
          dataFuture.push({
              coin: items.symbol || items.coin,
              current_price: items.lastPrice || items.current_price,
              min_price: items.lowPrice || items.min_price,
              max_price: items.highPrice || items.max_price,
              changed_percent: items.priceChangePercent || items.changed_percent,
              lastPriceChange: items.lastPriceChange || 0,
              volume: items.volume || items.volume,
              quote_volume: items.quoteVolume || items.quote_volume,
              symbol: items.symbol
          });
        }
        const total = dataFuture.length;
        const last_page = Math.ceil(total/params.limit);
        let from = params.page;
        if(params.page) {
          from = (params.page - 1) * params.limit + 1;
        }
        let to = params.page;
        if(params.page) {
          to = (params.page - 1) * params.limit + params.limit;
        }
        if (to > total) {
          to = total;
        }
        let data_pairs = dataFuture;
        if (params.page) {
          const start = (params.page - 1) * params.limit;
          const end = start + params.limit;
          data_pairs = dataFuture.slice(start, end);
        }
        data = {
          current_page: params.page ? params.page : 1,
          data: data_pairs,
          first_page_url: "/?page=1",
          from: from,
          last_page: last_page,
          last_page_url: `/?page=${last_page}`,
          path: "/",
          per_page: params.limit,
          to: to,
          total: total
        };
      })
      this.allDataFromFuture = dataFuture;
      this.price24hData = data.data;
      return Promise.resolve({data});
    },

    onUpdateFutureData(data){
          if(this.futuresTable && this.allDataFromFuture.length > 0){
              this.allDataFromFuture = this.allDataFromFuture.map((item) => {

                  const newDataFromSocket = data.find(d => d.symbol === item.coin);
                  if(newDataFromSocket) {
                      item = {
                          coin: newDataFromSocket.symbol,
                          current_price: newDataFromSocket.lastPrice,
                          min_price: newDataFromSocket.lowPrice,
                          max_price: newDataFromSocket.highPrice,
                          changed_percent: newDataFromSocket.priceChangePercent,
                          lastPriceChange: newDataFromSocket.lastPriceChange,
                          volume: newDataFromSocket.volume,
                          quote_volume: newDataFromSocket.quoteVolume,
                          symbol: newDataFromSocket.symbol
                      }
                  }
                  return item;
              })
              this.$refs && this.$refs.datatable && this.$refs.datatable.hotFetch();
          }
    },
    
    getInstruments() {
      rf.getRequest("UserRequest")
          .getInstruments()
          .then((res) => {
            const assetsKeyObject = {};
            res.data.forEach((asset) => {
              return (assetsKeyObject[asset.symbol] = {
                ...asset,
              });
            });
            this.listInstruments = assetsKeyObject;
          });
    },
    numFormat (value, maxDecimal) {
      const numFormat = new BigNumber(value).toFixed(maxDecimal, BigNumber.ROUND_DOWN);
      return numFormat;
    },
    formatShortTextAdmin(value, coin, maxLength) {
      if(value == null || value == "Infinity") return '0.0';
      const split = parseFloat(value).toString().split('.');
      const beforeDecimal = split[0];
      const maxDecimal = -Math.ceil(Math.log10(Number(this.listInstruments[coin]?.minPriceMovement ?? '0.01')));
      const numFormat = this.numFormat(value, maxDecimal)
      const shorttedNumber = beforeDecimal.length > maxLength ? numFormat.slice(0, maxLength-1).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...') :
          numFormat.slice(0, maxLength).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...')
      return numFormat.length > maxLength ? shorttedNumber : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    formatShortText(value, maxDecimal, maxLength) {
      if(value == null || value == "Infinity") return '0.0';
      const split = parseFloat(value).toString().split('.');
      const beforeDecimal = split[0];
      const numFormat = this.numFormat(value, maxDecimal)
      const shorttedNumber = beforeDecimal.length > maxLength ? numFormat.slice(0, maxLength-1).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...') :
          numFormat.slice(0, maxLength).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...')
      const returnValue = numFormat.length > maxLength ? shorttedNumber : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return returnValue.toString();
    },
  },
  sockets: {
    connect: function () {
    },
  },
  mounted() {
    this.sockets.subscribe('tickers', (data) => {
      this.onUpdateFutureData(data);
    });
    if (this.$attrs.tabMain !== COMMON_CONST.FUTURE_TAB) {
      const thElement = document.querySelector('th[data-sort-field="quote_volume"]');
      thElement.setAttribute('data-sort-order', 'desc');
    }
  },
  created() {
    this.checkFetchedData();
    this.getInstruments();
  },
  destroyed() {
    window.localStorage.removeItem('favorite_pairs_authenticated');
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  .item-tab .table-landing table .icon-star2 {
    color: $color-auro-metal;
  }
}
.w_8 {
  width: 10%;
}
.w_10 {
  width: 10%;
}
.w_13 {
  width: 13%;
}
.w_14 {
  width: 14%;
}
.w_15 {
  width: 15%;
}
.w_17 {
  width: 15%;
}
.w_18 {
  width: 18%;
}
.w_20 {
  width: 22%;
}
.w_16 {
  width: 16%;
}
.w_24 {
  width: 24%;
}
.w_14 {
  width: 14%;
}
.item-tab {
  display: block;
  width: 100%;
  min-width: $min-width-table;
  .grown {
    color: $color-jungle-green !important;
  }
  .down {
    color: $color-denim !important;
  }
  .table-landing {
    ::v-deep {
      table {
        table-layout: fixed;
      }
    }
    table {
      .icon-star2 {
        display: block;
        float: left;
        width: 15px;
        font-size: $font-medium;
        margin-right: 20px;
        margin-top: 5px;
        height: 20px;
        float: left;
        color: $color-grey;
        cursor: pointer;
      }
      .icon-star2 {
        color: $color-gray-alto;
        cursor: pointer;
      }

      .icon-star-selected {
        color: $color-yellow!important;
      }

      th {
        padding: 10px 10px 10px 0;
        font-family: $font-inter-regular;
        font-size: $font-root;
        background: var(--color-tab-data-table);
        height: 63px;
        color: var(--color-percent);
        text-align: left;

        &:first-child {
          border-radius: 8px 0 0 8px;
          padding-left: 24px;
        }

        &:last-child {
          border-radius: 0px 8px 8px 0;
        }
      }
      tr {
        &:hover {
          background-color: var(--bgr-hover-row);
          cursor: pointer;
        }
      }

      td {
        text-align: left;
        font-family: $font-inter-regular;
        padding: 15px 0px;
        color: var(--text-primary-color);
        font-size: 14px;

        &:first-child {
          padding-left: 24px;
        }

        .icon-no-data {
          padding-top: 50px;
        }
      }
    }
  }
  .col-pair {
    font-family: $font-inter-regular;
    font-size: $font-big;
    .coin {
      color: var(--text-primary-color);
    }
    .currency {
      color: var(--text-primary-color);
    }
  }

  .is-muted {
    color: var(--text-primary-color);
  }
}
.table-landing > table tr {
  padding: 0 25px;
  cursor: pointer;
}

.table-landing > table td {
  padding: 10px 10px 10px 0;
  color: $color-grey-dark;
}

.table-landing > table td:last-child {
  padding-right: 25px;
}

.table-landing > table tr td:first-child {
  padding-left: 20px;
}

.table-landing > table th:first-child {
  padding-left: 60px;
}

@media screen and (max-width: 992px) {
  .item-tab {
    .table-landing {
      table {
        td {
          font-size: 14px;
        }
        .icon-star2 {
          margin-top: 2px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 576px) {
}
</style>
<style lang="scss">
@media screen and (max-width: 992px) {
  .item-tab {

    table {
      overflow-y: initial !important;
    }
    .wrapper-pagination{
      margin-bottom: 108px;
    }
    .VuePagination{
      position:absolute;
          left:0;
          right:0;
          margin-left:auto;
          margin-right:auto;
          margin-top: 38px;
    }
  }
}

</style>