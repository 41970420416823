import BaseRequest from './base/BaseRequest';

export default class LeaderboardRequest extends BaseRequest {

    exportSpotTradingVolumeRanking(params) {
        let url = '/trading-volume-ranking';
        return this.get(url, params);
    }

    exportMarginTradingVolumeRanking(params) {
        let url = '/margin-top-leader-boards-by-vol';
        return this.get(url, params);
    }

    getLeaderboardSetting(params){
        let url = '/get-leaderboard-setting';
        return this.get(url, params);
    }

}