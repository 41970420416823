<template>
  <deposit-page ref="deposit_page" :configs="configs">
    <template slot="title">{{ $t("funds.deposit.title_page") }}</template>
    <template slot="actions">
      <div class="deposit-content">
        <div class="address-box form-important">
          <!-- <label class="icon-star2" v-if="coinName === 'usdt'"></label> -->
          <!-- <template v-if="coinName === 'xrp' || coinName === 'eos'">
            <div class="form-group address-box">
              <div for="blockchain_sub_address">
                {{
                  $t("funds.deposit.deposit_tag", {
                    coin: coinName.toUpperCase(),
                  })
                }}
              </div>
              <input
                id="blockchain_sub_address"
                class="address"
                :value="address.blockchain_sub_address"
                readonly
              />
            </div>
            <div class="btn-box">
              <button
                tabindex="0"
                :class="{ chrome_only: isChrome }"
                type="button"
                class="btn box_left"
                :disabled="!address.blockchain_sub_address"
                v-clipboard:copy="address.blockchain_sub_address"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
              >
                {{ $t("funds.deposit.copy_tag") }}
              </button>
              <button
                tabindex="0"
                :class="{ chrome_only: isChrome }"
                type="button"
                class="btn box_right"
                :disabled="!address.blockchain_sub_address"
                @click="showQRCode('blockchain_sub_address')"
              >
                {{ $t("funds.deposit.show_qr") }}
              </button>
            </div>
          </template> -->
          <!-- <div
            v-if="coinName === 'usdt'"
            class="form-group text-important"
            v-html="
              $t('funds.deposit.important.text1', {
                name: coinName.toUpperCase(),
              })
            "
          ></div> -->
          <!-- <label class="icon-star2"></label> -->
          <!-- <div class="note">
              <img  v-if="coinName === 'xrp' || coinName === 'eos' || coinName === 'trx'" src="/images/icon/icon-note.svg"/>
              <div
                v-if="coinName"
                class="form-group text-important"
                v-html="
                  $t('funds.deposit.important.text2', {
                    name: coinName.toUpperCase(),
                  })
                "
              ></div>
          </div> -->
          <p class="message_noitice">Send only {{ coinName.toUpperCase() }} to this deposit address. Sending any other currency to this address may result in the loss of your deposit.</p>
          <div v-if="coinName" for="address" class="form-address">
            {{
              $t("funds.deposit.deposit_address", {
                coin: coinName && coinName.toUpperCase(),
              })
            }}
          </div>
          <div class="tool-tip">
              <p v-show="bigger" class="fullAdress">{{address.blockchain_address}}</p>
              <input
                  id="address"
                  ref="address"
                  class="address customDepositAddress"
                  :value="address.blockchain_address"
                  readonly
                />
                
          </div>
          <div class="btn-box">
            <button
              tabindex="0"
              :class="{ chrome_only: isChrome }"
              type="button"
              class="btn box_left"
              :disabled="!address.blockchain_address"
              v-clipboard:copy="address.blockchain_address"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="onCopyError"
            >
              <!-- <img src="/images/icon/icon-copy.svg" alt="icon" class="icon" /> -->
              {{ $t("funds.deposit.coppy_address") }}
            </button>
            <button
              tabindex="0"
              :class="{ chrome_only: isChrome }"
              type="button"
              class="btn box_right"
              :disabled="!address.blockchain_address"
              @click="showQRCode('blockchain_address')"
            >
              <!-- <img
                src="/images/icon/icon-qr-code.svg"
                alt="icon"
                class="icon"
              /> -->
              {{ $t("funds.deposit.show_qr") }}
            </button>
          </div>

          <div class="form-notice">
        <p class="text-notice">{{ $t("funds.deposit.tips.text1") }}</p>
        <ul>
          <li
           v-if="(coinName === 'xrp' || coinName === 'eos') && getConfirmation(coinName) === 1" v-html="`${$t('funds.deposit.tips.text2', { name: coinName.toUpperCase(), count: getConfirmation(coinName) })}` "
          ></li> 
          <li v-html="$t('funds.deposit.tips.text3')" class></li>
        </ul>
      </div>
        </div>
      </div>
   
      <modal v-if="coinName" name="blockchain_address" width="560">
        <div slot="body" class="qrcode-modal">
          <div v-if="coinName" class="text-head">
            {{
              $t("funds.deposit.deposit_address", {
                coin: coinName.toUpperCase(),
              })
            }}
          </div>
          <div class="text-center qr-code">
            <div class="qr-img">
              <img :src="address.qrcode" />
            </div>
          </div>
          <div>
            <div class="text-center model-address">
              {{ address.blockchain_address }}
            </div>
          </div>
          <div>
            <div class="text-center">
              <button
                type="button"
                class="btn-model"
                v-clipboard:copy="address.blockchain_address"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
              >
                <!-- <img src="/images/icon/icon-copy.svg" alt="icon" class="icon" /> -->
                {{ $t("funds.deposit.coppy_address") }}
              </button>
            </div>
          </div>
        </div>
      </modal>
      <modal v-if="coinName" name="blockchain_sub_address" width="560">
        <div slot="body" class="qrcode-modal">
          <div v-if="coinName" class="text-head text-center">
            {{
              $t("funds.deposit.deposit_tag", { coin: coinName.toUpperCase() })
            }}
          </div>
          <div class="text-center qr-code">
            <div class="qr-img">
              <img :src="address.qr_tag" />
            </div>
          </div>
          <div>
            <div class="text-center">{{ address.blockchain_sub_address }}</div>
          </div>
          <div>
            <div class="text-center">
              <button
                type="button"
                class="btn-model"
                v-clipboard:copy="address.blockchain_sub_address"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
              >
                <!-- <img src="/images/icon/icon-copy.svg" alt="icon" class="icon" /> -->
                {{ $t("funds.deposit.copy_tag") }}
              </button>
            </div>
          </div>
        </div>
      </modal>
    </template>
  </deposit-page>
</template>

<script>
import rf from "@/request/RequestFactory";
import Modal from "@/components/shared_components/common/Modal";
import BaseDepositWithdraw from "./BaseDepositWithdraw";
import {mapState} from "vuex";

export default {
  components: {
    Modal,
    DepositPage: BaseDepositWithdraw,
  },



  data() {
    return {
      configs: {
        type: "deposit",
        is_show_title: true,
        msgEmptyData: window.i18n.t("funds.deposit.no_data"),
      },
      selectedCoin: {},
      selectedNetwork: {},
      address: {},
      currency: {},
      confimations: {},
      isChrome: window.navigator.userAgent.toLowerCase().indexOf("chrome") > 0,
      bigger : false,
    };
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    coinName() {
      // if (_.isEmpty(this.selectedCoin.coinName)) {
      //   return "";
      // }
      return this.selectedCoin?.coinName || "";
    },
    networkId() {
      return this.selectedNetwork?.id;
    },
  },
  watch: {
    "address": {
      handler() {
       if(this.address.blockchain_address && this.address.blockchain_address.toString().length > 35 ) {
          this.bigger = true;
       }
      },
    },
  },
  methods: {
    getQRCodeGoogleUrl() {
      return rf
        .getRequest("UserRequest")
        .getQRCodeGoogleUrl()
        .then((res) => {
          this.qrCodeUrl = res.data.url;
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    showQRCode(name) {
      CommonModal.show(name, {
        position: "center",
        mask: true,
      });
    },

    onCopySuccess() {
      Message.success(
        window.i18n.t("funds.deposit.copy_success"),
        {},
        { position: "bottom_right" }
      );
    },

    onCopyError() {
      Message.error(
        window.i18n.t("funds.deposit.copy_error"),
        {},
        { position: "bottom_right" }
      );
    },

    getDepositAddress(network_id) {
      let params = {
        currency: this.coinName,
        network_id : network_id
      };

      rf.getRequest("UserRequest")
        .getDepositAddress(params)
        .then((res) => {
          this.address = res.data;
        });
    },

    getConfirmation(coin) {
      const confimation = this.confimations[coin]
        ? this.confimations[coin].confirmation
        : 1;
      return parseInt(confimation, 10) || 1;
    },

    getConfirmations() {
      this.confimations = window._.keyBy(
        this.masterdata.coins_confirmation,
        "coin"
      );
    },
  },
  mounted() {
    this.scrollToTop();
    this.$refs.deposit_page.$on("CHANGE_SELECTED_COIN", (selectedCoin) => {
      this.selectedCoin = selectedCoin;
      this.address = {};
      this.$router.replace({ query: { coin: this.selectedCoin.coinName} })
      // this.getDepositAddress();
      this.getConfirmations();
    });

    this.$refs.deposit_page.$on("CHANGE_SELECTED_NETWORK", (selectedNetwork) => {
      if(selectedNetwork.id){
        this.selectedNetwork = selectedNetwork;
        this.getDepositAddress(selectedNetwork.id);
      }
    });
    document.title = this.$t("menu.deposits") + ` – ${this.tabTitle}`;

    this.$on("UPDATED_LOCALE", () => {
      this.configs.msgEmptyData = window.i18n.t("funds.deposit.no_data");
    });
  },
};
</script>
<style lang="scss">
#id_base_deposit_wd {
  .modal-content {
    .modal-body {
      // padding-left: 0;
      // padding-right: 0;
    }
    .modal-footer {
      display: none;
    }
  }
}
.dark-theme{

  #id_base_deposit_wd {
  .modal-content {
    background-color: #17181A;

   
  }
}

}

</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme{

  .deposit-content {
    background: linear-gradient(97.91deg, #5A717C 6.1%, #05B4CC 103.66%);
    .address-box {
    &.form-important {
      background-color: #17181A !important;
     
    }   
    .customDepositAddress {
      border: 1px solid #2D2E2F;
      background: #17181A;
    }
  }

  .btn-box {

   .box_left{
       border-color: #2D2E2F;
      background: #17181A;
      color: white;
     }
     .box_right{
      color: #17181A;
      background: white;
     }
 }

 
  }

  .btn-model {
    background-color: white;
    color: #17181A;

}
}

.qrcode-modal {
  padding-left: 16px;
  padding-right: 16px;
}

.btn-model {
  @include btn-common(yes);
  padding: 14.5px 20px;
  min-width: 200px;
  width: 100%;
  max-width: 100%;
  background-color: #17181A;
  color: white;
  border-radius: 63px;
}
.message_noitice{
  color: #F1493F;
  font-size: 14px;
  margin-bottom: 16px;
}

.text-head {
  font-family: 600;
  color: var(--color-black_2);
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 16px;
}
.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  .qr-img {
    /* background: var(--bgr-qr-code); */
    width: 232px;
    /* padding: 26px; */
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}
.model-address {
  margin-top: 20px;
  margin-bottom: 40px;
  font-family : 500;
  color: var(--text-primary-color);
  font-size: 18px;
  line-height: 22px;
  overflow-wrap: break-word;
}
.form-notice {
  margin-top: 15px;
  .text-notice {
    color: var(--text-close);
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px;
    font-weight: 400;
  }
  li {
    color: var(--dark-1);
    font-size: 16px;
    line-height: 21px;
  }
}
.note {
  display: flex;
  align-items: flex-start;
  img {
    margin-right: 11px;
  }
}
.deposit-content {
  border-radius: 16px; 
  padding: 1px; 
  background: linear-gradient(97.91deg, #7BD3FF 6.1%, #05B4CC 103.66%);
  .address-box {
    &.form-important {
      background-color: white !important;
      border-radius: 16px;
      padding: 20px;
      @media screen and (max-width: 375px) {
        padding: 15px;
      }
    }
    .text-important {
      color: $text-color-red;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    label {
    }
    .icon-star2 {
      color: $color-denim;
    }
    .address {
      padding: 13px 15px;
      color: var(--text-primary-color);
      font-size: 18px;
      line-height: 22px;
      border-radius: 8px;
      background: var(--color-input-deposit);
      border: none;
      width: 100%;
      resize: none;
      height: 49px;
    }
    .customDepositAddress {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      pointer-events: none;
      border: 1px solid #EFEFEF;
      border-radius: 63px;
    }
    .tool-tip {
      position: relative;
      .fullAdress {
        display: none;
      }
      &:hover{
      .fullAdress {
        display: block;
       position: absolute;
        top: -36px;
        left: 12px;
        padding: 10px;
        background: $bgr-tooltip;
        border-radius: 8px;
        color: $color-white;
      }
      }
    }
    .form-address {
      color: var(--dark-1);
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
      font-weight: 400;
    }
  }
  .form-group {
    div {
      color: var(--dark-1);
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 8px;
      font-weight: 400;
    }
  }
  .btn-box {
   

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 30px;
    .btn {
      @include btn-common(yes);
      border-radius: 63px;
      font-size: 16px;
      font-weight: 600;
      flex: 1;
    }
    .box_left{
        background: white;
        border: 1px solid #EFEFEF;
       
      }
      .box_right{
        background: #17181A;
       color: white;
      }
  }
}
.go-to-trade {
  width: 135px;
  height: 19px;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #12575f;
}
.change {
  padding-top: 30px;
  display: flex;
}
//.chrome_only[tabindex]:focus {
  //background-color: $color-blue-custom;
  //border-color: $color-blue-custom;
//}
@media screen and (max-width: 992px) {
  .deposit-content {
    .address-box {
      .text-important {
        font-size: 14px;
        line-height: 16px;
      }

      .form-address {
        font-size: 14px;
        line-height: 16px;
      }

      .address {
        font-size: 14px;
        line-height: 16px;
        resize: none;
        height: 49px;
        padding: 8px 15px;
      }
    }
    .btn-box {
      button {
        font-weight: 400;
        font-size: 14px !important;
      }

    
    }
  
  }
  .text-head {
    font-size: 20px;
    line-height: 24px;
  }
  .form-notice {
    li {
      font-size: 14px;
      line-height: 16px;
    }
    .text-notice {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .model-address {
    font-size: 14px;
    line-height: 16px;
  }
  .btn-model {
    
  }
}
@media screen and (max-width: 576px) {
  .deposit-content {
    .btn-box {
      gap: 10px;

      button {
        max-width: 100%;
        font-weight: 400;
        font-size: 13px !important;
      }
      .btn {
        img {
          width: 15px;
        }
      }
    }
  }
}
</style>

