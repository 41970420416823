<template>
  <div id="your_position">
    <risk-limit-modal/>
    <div id="header_your_position" @click="showYourPosition()">
      <span class="txt">
        {{ $t('margin.your_position') }}: {{ currentSymbol }} 
        <i class="icon-help">
          <div class="tooltip_custom_c">{{ $t('margin.your_position_tooltip') }}</div>
        </i>
      </span>
      <i class="icon icon-arrow2"></i>
    </div>
    <div id="content_your_position">
      <div class="box_your_position_1 clearfix">

        <div class="positionStatusIndicator clearfix">
          <div class="col-6">
            <div class="posValue" :class="[{'roe-up' : Number(position.current_qty) > 0}, {'roe-down' : Number(position.current_qty) < 0}]">{{position.current_qty | formatMarginNumber(0, '0')}}</div>
            <div class="posLabel">{{ $t('margin.contracts') }}</div>
          </div>
          <div class="col-6">
            <div class="posValue " :class="[{'roe-up' : Number(roe) > 0}, {'roe-down' : Number(roe) < 0}]">{{ roe | formatMarginNumber(2) }}%</div>
            <div class="posLabel roe">
              {{ $t('margin.roe') }}

              <div class="tooltip_custom_c">
                {{ $t('margin.roe_tooltip') }}
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <chart-your-position/>
        <div class="clearfix clearfix-10"></div>
        <div class="content_bottom_positions clearfix">
          <div class="col-4">
            {{ $t('margin.risk_limit') }}
          </div>
          <div class="col-8 text-right">
            <b>{{ position.risk_value | formatMarginNumber(4, '0') }} / {{ position.risk_limit | formatMarginNumber(0) }} {{ $t('margin.symbol.btc') }}</b>
            <button class="btn btn-edit" @click="showRiskLimitModal(position.risk_limit)"><i class="icon icon-edit"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import MarginCalculator from '@/components/marginexchange_pages/common/MarginCalculator.js'
  import RiskLimitModal from '../../common/RiskLimitModal.vue'
  import ChartYourPosition from './ChartYourPosition.vue';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      ChartYourPosition,
      RiskLimitModal,
    },
    computed: {
      ...mapGetters({ position: 'mamPosition', currentSymbol: 'currentSymbol' }),
      roe: function () {
        const pnlPercent = MarginCalculator.getPnlPercent(this.position.unrealised_pnl, this.position.entry_value);
        return MarginCalculator.getRoe(pnlPercent, this.position.leverage);
      },
    },
    data() {
      return {
       // isActive: false;
      }
    },
    methods: {
      ...mapActions(['getMamPositions']),
      // getSocketEventHandlers() {
      //   return {
      //     MarginOrderUpdated: this.onMarginOrderUpdated,
      //     MarginPositionUpdated: this.MarginPositionUpdated,
      //   };
      // },
      // async onMarginOrderUpdated(data) {
      //   if(data && window.isAuthenticated){
      //     await this.getMamPositions();
      //   }
      // },
      // async MarginPositionUpdated(data) {
      //   if(data && window.isAuthenticated){
      //     await this.getMamPositions();
      //   }
      // },

      // getSocketEventHandlers() {
      //   return {
      //     MarginOrderUpdated: this.onMarginOrderUpdated,
      //     MarginPositionUpdated: this.MarginPositionUpdated,
      //   };
      // },
      // async onMarginOrderUpdated(data) {
      //   if(data && window.isAuthenticated){
      //     console.log('getPositions onMarginOrderUpdated 2', data);
      //     await this.getPositions();
      //   }
      // },
      // async MarginPositionUpdated(data) {
      //   if(data && window.isAuthenticated){
      //     console.log('getPositions onMarginOrderUpdated MarginPositionUpdated 2', data);
      //     await this.getPositions();
      //   }
      // },
      showYourPosition() {
        var element = document.getElementById("content_your_position");
        element.classList.toggle("isHidden");
        var element = document.getElementById("header_your_position");
        element.classList.toggle("isRemove");
      },
      showRiskLimitModal(riskLimit) {
        window.CommonModal.show('RiskLimitModal');
      }
    },
    mounted() {
    }
   }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .clearfix-10 {
    clear: both;
    height: 10px;
    display: block;
  }
  .col-6 {
    width: 50%;
    float: left;
    display: block;
  }
  .col-4 {
    width: 33.33%;
    float: left;
    display: block;
  }
  .col-8 {
    width: 66.67%;
    float: left;
    display: block;
  }
  .text-right {
    text-align: right;
  }
  #your_position {
    height: 100%;
  }
  .btn-edit {
    float: right;
    width: 20px;
    height: 20px;
    padding: 0px;
    text-align: center;
    line-height: 20px;
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    margin-left: 5px;
    font-size: 15px;
    color: $color-orange;
  }
  #header_your_position {
    height: 41px;
    background-color: #1d2c38;
    padding: 10px 15px;
    line-height: 20px;
    font-size: $font-root;
    color: $color-orange;
    font-family: $font-family-roboto-bold;
    cursor: pointer;
    border-top: solid 1px $color-bright-gray-dark;
    border-bottom: solid 1px $color-bright-gray-dark;
    .txt {
      display: inline-block;
      float: left;
      .icon-help {
        display: inline-block;
        float: right;
        margin-left: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        position: relative;
        .tooltip_custom_c {
          margin-right: -50px; 
          display: none;
          width: 310px;
          background-color: #222832;
          color: #cccccc;
          padding: 5px 5px;
          border-radius: 4px;
          font-size: 13px;
          -webkit-box-shadow: 0px 0px 3px #cccccc;
          box-shadow: 0px 0px 3px #cccccc;
          position: absolute;
          z-index: 50;
          right: 10px;
          bottom: calc(100% + 5px);
          text-align: left;
          font-family: $font-family-roboto;
        }
        &:hover {
          .tooltip_custom_c {
            display: block;
          }
        }
      }
    }
    .icon {
      float: right;
      font-size: $font-mini-mini;
      display: inline-block;
      height: 20px;
      line-height: 20px;
    }
    .icon-arrow2 {
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    &.isRemove {
      .icon-arrow2 {
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Safari */
        transform: rotate(180deg);
      }
    }
  }

  #content_your_position {
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: calc(100% - 41px);
    opacity: 1;
    background-color: #11212e;
    &.isHidden {
      height: 0px;
      opacity: 0.5;
      overflow: hidden;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .box_your_position_1 {
      padding: 13px 10px;
      padding-bottom: 31px;
    }
    .positionStatusIndicator {
      text-align: center;
      display: block;
      line-height: 20px;
      margin-bottom: 10px;
      .col-6 {
        width: 50%;
        float: left;
        display: block;
      }
      .posValue {
        font-size: 18px;
        color: $color-white;
        font-family: $font-family-roboto-bold;
      }
      .roe-up {
        color: $color-jungle-green;
      }
      .roe-down {
        color: $color-denim;
      }
      .posLabel {
        color: $color-grey-sliver;
        font-size: $font-small;
        position: relative;

        .tooltip_custom_c {
          margin-right: -20px; 
          display: none;
          width: 220px;
          background-color: #222832;
          color: #cccccc;
          padding: 5px 5px;
          border-radius: 4px;
          font-size: 13px;
          -webkit-box-shadow: 0px 0px 3px #cccccc;
          box-shadow: 0px 0px 3px #cccccc;
          position: absolute;
          z-index: 50;
          right: 10px;
          bottom: calc(100% + 5px);
          text-align: left;
          font-family: $font-family-roboto;
        }
        &:hover {
          .tooltip_custom_c {
            display: block;
          }
        }
        &.roe {
          cursor: help;
        }
      }
    }
    .content_bottom_positions {
      margin-top: 8px;
      line-height: 20px;
      font-size: $font-smaller;
      color: $color-grey-sliver;
      b {
        font-family: $font-family-roboto-bold;
        font-weight: 500;
      }

    }
  }
</style>