<template>
  <div id="referral">
    <div class="referral_wrapper">
      <Breadcrumb :items="breadcrumbItems" class="container-breadcrumb" />
      <div class="content_referral">
        <div class="introduce">
          <div class="container-custom">
            <div class="introduce-wrapper">
              <h2>{{ $t("account.referral_des") }}</h2>
              <p class="sub-introduece">
                {{
                  $t("account.referral_content", {
                    number:
                      Math.trunc(settingReferral.refund_percent_at_level_1) ||
                      0,
                  })
                }}
              </p>
            </div>
            <div class="introduce-img3d">
              <img src="../assets/images/img3DRef.png" alt="" />
            </div>
          </div>
        </div>
        <div class="referral-program">
          <div class="title">{{ $t("refferal.title") }}</div>
          <div class="container-custom">
            <div class="box-content-wrapper">
              <div class="column-left">
                <div
                  class="box"
                  v-for="(item, index) in boxContent"
                  :key="index"
                >
                  <img
                    :src="theme === 'light-theme' ? item.img : item.imgDark"
                  />
                  <div class="content">
                    <p class="title-detail">
                      {{ item.title }} {{ $t(item.muti) }}
                    </p>
                    <p class="content-detail">
                      {{
                        item.id === 3
                          ? $t(item.content, {
                              value: Math.trunc(
                                settingReferral.refund_percent_at_level_1
                              ),
                            })
                          : $t(item.content)
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="column-right">
                <div class="qr-character">
                  <div class="my-referral-id">
                    <div class="title-id">
                      <span class="tit_id_referral">{{
                        $t("refferal.text6")
                      }}</span>
                    </div>
                    <div class="code-id">
                      <span
                        class="code_id_referral"
                        id="idCopy"
                        v-if="getUserReferral"
                      >
                        &nbsp;{{ getUserReferral }}
                      </span>
                      <img
                        :src="require(`../assets/images/iconCopy${dark}.svg`)"
                        @click="showSuccess($t('refferal.text25'), 'id')"
                      />
                    </div>
                  </div>

                  <div class="my-referral-id">
                    <div class="title-id">
                      <span class="tit_id_referral">{{
                        $t("refferal.text8")
                      }}</span>
                    </div>
                    <div class="code-id">
                      <span class="code_id_referral"
                        >&nbsp;{{
                          replaceMiddleString(this.appUrl, getUserReferral)
                        }}</span
                      >
                      <span class="d-none" id="linkCopy">
                        {{ urlReferral }}</span
                      >
                      <img
                        :src="require(`../assets/images/iconCopy${dark}.svg`)"
                        @click="showSuccess($t('refferal.text25'))"
                      />
                    </div>
                  </div>
                  <div class="action-qr">
                    <div class="btn-share-qr">
                      <div class="list_society_link">
                        <a
                          @click="fbShareAction(user)"
                          href="javascript:void(0)"
                        >
                          <img
                            :src="require(`@/assets/images/icon-facebook.svg`)"
                          />
                        </a>
                        <a
                          @click="twShareAction(user)"
                          href="javascript:void(0)"
                        >
                          <img
                            :src="require(`@/assets/images/icon-twitter.svg`)"
                          />
                        </a>
                        <a
                          @click="linShareAction(user)"
                          href="javascript:void(0)"
                        >
                          <img
                            :src="require(`@/assets/images/icon-linkedin.svg`)"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="export_refarral" id="export-referral-desktop">
        <div class="table_left_refarral">
          <div class="datatable">
            <p class="table_title">{{ $t("refferal.text10") }}</p>
            <buttonReferral
              Program
              class="excel_export fl-right"
              @click="downloadReportFriend()"
              :disabled="disableUserFriend"
            >
              {{ $t("refferal.text26") }}
              <!-- <img src="../pages/wallet/assets/icon/exportreferral.svg" /> -->
            </buttonReferral>
            <div class="clearfix"></div>
            <div
              class="clearfix box_responsive_table"
              id="table_referral_friends"
            >
              <data-table2
                :getData="getAllReferrer"
                :msgEmptyData="$t('account.no_referral_history')"
                @DataTable:finish="onLoadHight"
                :isTableUserFriend="true"
                @isHasDataUserFriend="checkDataUserFriend"
              >
                <template>
                  <th>
                    <span class="title">
                      {{ $t("common.placeholders.email") }}
                    </span>
                  </th>
                  <th>
                    <span class="title">
                      {{ $t("refferal.text11") }}
                    </span>
                  </th>
                </template>
                <template slot="body" slot-scope="props">
                  <tr>
                    <td class="text-table">
                      {{ props.item.email }}
                    </td>
                    <td class="text-table">
                      {{ convertTime(props.item.created_at) }}
                    </td>
                  </tr>
                </template>
              </data-table2>
            </div>
          </div>
        </div>
        <div class="table_right_refarral">
          <div class="datatable">
            <p class="table_title">{{ $t("refferal.text24") }}</p>
            <button
              class="excel_export fl-right"
              @click="downloadReportCommission()"
              :disabled="disableUserCommissions"
            >
              {{ $t("refferal.text26") }}
              <!-- <img src="../pages/wallet/assets/icon/exportreferral.svg" /> -->
            </button>
            <div class="clearfix"></div>
            <div
              class="clearfix box_responsive_table"
              id="table_latest_commission"
            >
              <ul class="head_group_positions">
                <li
                  class="item"
                  @click="showContentTypes = 'spot'"
                  v-bind:class="{ active: showContentTypes === 'spot' }"
                >
                  {{ $t("common.spot_exchange") }}
                </li>
                <li
                  class="item futureItem"
                  @click="showContentTypes = 'usd_m'"
                  v-bind:class="{ active: showContentTypes === 'usd_m' }"
                >
                  USDⓈ-M {{ $t("common.margin_exchange") }}
                </li>
                <li
                  class="item futureItem"
                  @click="showContentTypes = 'coin_m'"
                  v-bind:class="{ active: showContentTypes === 'coin_m' }"
                >
                  COIN-M {{ $t("common.margin_exchange") }}
                </li>
              </ul>
              <div class="content-table-right">
                <data-table2
                  :getData="getUserReferralCommission"
                  :msgEmptyData="$t('account.no_commission_history')"
                  ref="datatable_commission"
                  @DataTable:finish="onLoadHight"
                  :isTableUserCommission="true"
                  @isHasDataUserCommission="checkDataUserCommission"
                >
                  <template>
                    <th>
                      <span class="title">
                        {{ $t("refferal.text22") }}
                      </span>
                    </th>
                    <th>
                      <span class="title">
                        {{ $t("refferal.text27") }}
                      </span>
                    </th>
                    <th>
                      <span class="title">
                        {{ $t("common.placeholders.email") }}
                      </span>
                    </th>
                    <th>
                      <span class="title">
                        {{ $t("refferal.text11") }}
                      </span>
                    </th>
                  </template>
                  <template slot="body" slot-scope="props">
                    <tr>
                      <td class="text-table w_158">
                        <div class="comission-wrapper">
                          <div class="d-none">
                            {{ convertData(props) }}
                          </div>
                          <p>
                            {{
                              props.item.amount.toString().trim()
                                | formatNumberAndDecimal
                            }}
                          </p>
                          <span
                            v-if="countNumber(props) == false"
                            class="space"
                          >
                            &nbsp;{{ props.item.coin.toUpperCase() }}</span
                          >
                          <span v-else
                            >&nbsp;{{ props.item.coin.toUpperCase() }}</span
                          >
                        </div>
                      </td>
                      <td class="text-table">
                        {{ props.item.commission_rate }} %
                      </td>
                      <td class="text-table">
                        {{ props.item.email }}
                      </td>
                      <td class="text-table">
                        {{ convertTime(props.item.created_at) }}
                      </td>
                    </tr>
                  </template>
                </data-table2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="export_refarral" id="export-referral-mobile">
        <div class="table_left_refarral">
          <div class="datatable">
            <p class="table_title">{{ $t("refferal.text10") }}</p>
            <buttonReferral
              Program
              class="excel_export fl-right"
              @click="downloadReportFriend()"
              :disabled="disableUserFriend"
            >
              {{ $t("refferal.text26") }}
            </buttonReferral>
            <div class="clearfix"></div>
            <div
              class="clearfix box_responsive_table"
              id="table_referral_friends"
            >
              <data-table2
                :getData="getAllReferrer"
                :msgEmptyData="$t('account.no_referral_history')"
                @DataTable:finish="onLoadHight"
                :isTableUserFriend="true"
                @isHasDataUserFriend="checkDataUserFriend"
              >
                <template slot="body" slot-scope="props">
                  <div class="box-referral">
                    <div class="row-1">
                      <span class="text-left">{{
                        $t("common.placeholders.email")
                      }}</span>
                      <span class="text-right">{{ props.item.email }}</span>
                    </div>
                    <div class="row-2">
                      <span class="text-left">{{ $t("refferal.text11") }}</span>
                      <span class="text-right">{{
                        convertTime(props.item.created_at)
                      }}</span>
                    </div>
                  </div>
                  <!-- <th>
                    <span class="title">
                      {{ $t("common.placeholders.email") }}
                    </span>
                  </th>
                  <th>
                    <span class="title">
                      {{ $t("refferal.text11") }}
                    </span>
                  </th>
                </template>
                <template slot="body" slot-scope="props">
                  <tr>
                    <td class="text-table">
                      {{ props.item.email }}
                    </td>
                    <td class="text-table">
                      {{ convertTime(props.item.created_at) }}
                    </td>
                  </tr> -->
                </template>
              </data-table2>
            </div>
          </div>
        </div>
        <div class="table_right_refarral">
          <div class="datatable">
            <p class="table_title">{{ $t("refferal.text24") }}</p>
            <button
              class="excel_export fl-right"
              @click="downloadReportCommission()"
              :disabled="disableUserCommissions"
            >
              {{ $t("refferal.text26") }}
            </button>
            <div class="clearfix"></div>
            <div
              class="clearfix box_responsive_table"
              id="table_latest_commission"
            >
              <ul class="head_group_positions">
                <li
                  class="item"
                  @click="showContentTypes = 'spot'"
                  v-bind:class="{ active: showContentTypes === 'spot' }"
                >
                  {{ $t("common.spot_exchange") }}
                </li>
                <li
                  class="item futureItem"
                  @click="showContentTypes = 'usd_m'"
                  v-bind:class="{ active: showContentTypes === 'usd_m' }"
                >
                  USDⓈ-M {{ $t("common.margin_exchange") }}
                </li>
                <li
                  class="item futureItem"
                  @click="showContentTypes = 'coin_m'"
                  v-bind:class="{ active: showContentTypes === 'coin_m' }"
                >
                  COIN-M {{ $t("common.margin_exchange") }}
                </li>
              </ul>
              <div class="content-table-right">
                <data-table2
                  :getData="getUserReferralCommission"
                  :msgEmptyData="$t('account.no_commission_history')"
                  ref="datatable_commission"
                  @DataTable:finish="onLoadHight"
                  :isTableUserCommission="true"
                  @isHasDataUserCommission="checkDataUserCommission"
                >
                  <template slot="body" slot-scope="props">
                    <div class="box-referral">
                      <div class="row-1">
                        <span class="text-left">
                          {{ $t("refferal.text22") }}
                        </span>
                        <span class="text-right">
                          <div class="d-none">
                            {{ convertData(props) }}
                          </div>
                          <p>
                            {{
                              props.item.amount.toString().trim()
                                | formatNumberAndDecimal
                            }}
                          </p>
                          <span
                            v-if="countNumber(props) == false"
                            class="space"
                          >
                            &nbsp;{{ props.item.coin.toUpperCase() }}</span
                          >
                          <span v-else
                            >&nbsp;{{ props.item.coin.toUpperCase() }}</span
                          >
                        </span>
                      </div>
                      <div class="row-1">
                        <span class="text-left">
                          {{ $t("refferal.text27") }}
                        </span>
                        <span class="text-right">
                          {{ props.item.commission_rate }} %
                        </span>
                      </div>
                      <div class="row-1">
                        <span class="text-left">
                          {{ $t("common.placeholders.email") }}
                        </span>
                        <span class="text-right">
                          {{ props.item.email }}
                        </span>
                      </div>
                      <div class="row-1">
                        <span class="text-left">
                          {{ $t("refferal.text11") }}
                        </span>
                        <span class="text-right">
                          {{ convertTime(props.item.created_at) }}
                        </span>
                      </div>
                    </div>
                    <!-- <th>
                      <span class="title">
                        {{ $t("refferal.text22") }}
                      </span>
                    </th>
                    <th>
                      <span class="title">
                        {{ $t("refferal.text27") }}
                      </span>
                    </th>
                    <th>
                      <span class="title">
                        {{ $t("common.placeholders.email") }}
                      </span>
                    </th>
                    <th>
                      <span class="title">
                        {{ $t("refferal.text11") }}
                      </span>
                    </th>
                  </template>
                  <template slot="body" slot-scope="props">
                    <tr>
                      <td class="text-table w_158">
                        <div class="comission-wrapper">
                          <div class="d-none">
                            {{ convertData(props) }}
                          </div>
                          <p>
                            {{
                              props.item.amount.toString().trim()
                                | formatNumberAndDecimal
                            }}
                          </p>
                          <span
                            v-if="countNumber(props) == false"
                            class="space"
                          >
                            &nbsp;{{ props.item.coin.toUpperCase() }}</span
                          >
                          <span v-else
                            >&nbsp;{{ props.item.coin.toUpperCase() }}</span
                          >
                        </div>
                      </td>
                      <td class="text-table">
                        {{ props.item.commission_rate }} %
                      </td>
                      <td class="text-table">
                        {{ props.item.email }}
                      </td>
                      <td class="text-table">
                        {{ convertTime(props.item.created_at) }}
                      </td>
                    </tr> -->
                  </template>
                </data-table2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="program-detail-wrapper">
        <div class="container-custom">
          <div class="title_absolute">{{ $t("refferal.text12") }}</div>
          <div class="detail_referral">
            <div class="block_tric_referral clearfix">
              <ul>
                <li
                  class="li1"
                  v-html="
                    $t('refferal.text14', {
                      rate: trimNumber(this.settingReferral.refund_percent),
                      rate2: trimNumber(
                        this.settingReferral.refund_percent_next_program
                      ),
                      people:
                        this.settingReferral.number_people_in_next_program,
                    })
                  "
                ></li>
                <div class="level">
                  <ul>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 1"
                    >
                      {{ $t("referral.level1") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral.refund_percent_at_level_1
                          )
                        }}%</span
                      >
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 2"
                    >
                      {{ $t("referral.level2") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral.refund_percent_at_level_2
                          )
                        }}%</span
                      >
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 3"
                    >
                      {{ $t("referral.level3") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral.refund_percent_at_level_3
                          )
                        }}%</span
                      >
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 4"
                    >
                      {{ $t("referral.level4") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral.refund_percent_at_level_4
                          )
                        }}%</span
                      >
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 5"
                    >
                      {{ $t("referral.level5") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral.refund_percent_at_level_5
                          )
                        }}%</span
                      >
                    </li>
                    <li class="line spaceLevel">
                      *
                      {{
                        $t("referral.next_program", {
                          condition:
                            this.settingReferral.number_people_in_next_program,
                          rate: this.settingReferral
                            .refund_percent_next_program,
                        })
                      }}
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 1"
                    >
                      {{ $t("referral.level1") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral
                              .refund_percent_in_next_program_lv_1
                          )
                        }}%</span
                      >
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 2"
                    >
                      {{ $t("referral.level2") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral
                              .refund_percent_in_next_program_lv_2
                          )
                        }}%</span
                      >
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 3"
                    >
                      {{ $t("referral.level3") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral
                              .refund_percent_in_next_program_lv_3
                          )
                        }}%</span
                      >
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 4"
                    >
                      {{ $t("referral.level4") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral
                              .refund_percent_in_next_program_lv_4
                          )
                        }}%</span
                      >
                    </li>
                    <li
                      class="line"
                      v-if="this.settingReferral.number_of_levels >= 5"
                    >
                      {{ $t("referral.level5") }}:
                      <span
                        >{{ $t("referral.rate") }}
                        {{
                          trimNumber(
                            this.settingReferral
                              .refund_percent_in_next_program_lv_5
                          )
                        }}%</span
                      >
                    </li>
                  </ul>
                </div>
                <div class="limit">
                  <ul>
                    <li>
                      <span
                        v-html="$t('refferal.text16', { APP_SHORT_NAME })"
                      ></span>
                    </li>
                    <li>
                      <span v-html="$t('refferal.text18')"></span>
                    </li>
                    <li>
                      <span
                        v-html="$t('refferal.text28', { APP_SHORT_NAME })"
                      ></span>
                    </li>
                  </ul>
                </div>
                <div class="notice">
                  <li class="notice_title">
                    *
                    {{ $t("refferal.text-notice-import") }}
                  </li>
                  <li>
                    {{ $t("refferal.text-notice-content", { APP_SHORT_NAME }) }}
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import Utils from "@/common/Utils";
import Modal from "@/components/shared_components/common/Modal.vue";
import DateTimeSelect from "@/components/shared_components/common/DateTimeSelect";
import COMMON_CONST from "@/common/Const";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { referral_program_box } from "@/constants/defaute_value";
import AccountSiteBar from "@/components/shared_components/common/AccountSiteBar.vue";
import AccountTopHeader from "@/components/shared_components/common/AccountTopHeader.vue";
import QrcodeVue from "qrcode.vue";
import * as Crypto from "crypto-js";
import Cookies from "js-cookie";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    Breadcrumb,
    DateTimeSelect,
    Modal,
    AccountSiteBar,
    AccountTopHeader,
    QrcodeVue,
  },
  data() {
    return {
      userReferralCommissions: undefined,
      userReferralCommissionsForMargin: undefined,
      prices: undefined,
      sort: {},
      numberFriend: 0,
      refferalCode: {},
      titles: "",
      isLogin: window.isAuthenticated,
      topUserRefCommissions: [],
      settingReferral: {},
      heightTable: "",
      APP_SHORT_NAME: process.env.VUE_APP_SHORT_NAME,
      showContentTypes: "spot",
      disableUserCommissions: true,
      disableUserFriend: true,
      boxContent: referral_program_box,
      bigger: false,
      urlReferral: undefined,
      biggerCommissionsForMargin: false,
      isWebview: false,
      getUserReferral: "",
      breadcrumbItems: [
        { label: "Profile", link: "/account/dashboard" },
        { label: "Referral", link: "/account/referral" },
      ],
    };
  },

  methods: {
    replaceMiddleString(url, code) {
      return url.slice(0, 2) + "..." + code;
    },
    countNumber(value) {
      const trimNumber = this.trimNumber(value.item.amount);
      // const countNumber = this.$options.filters.formatCurrencyAmount(trimNumber);
      const countNumber = trimNumber;
      if (countNumber.toString().length > 7) return true;
      return false;
    },
    convertData(value) {
      const trimNumber = this.trimNumber(value.item.amount);
      // if (countNumber.toString().length > 7) {
      //   const cutNumber = countNumber.toString().slice(0, 7) + "...";
      //   return cutNumber;
      // }
      return this.$options.filters.formatNumberAndDecimal(trimNumber);
    },
    getHeightTable() {
      var heightTableFriends = document.getElementById(
        "table_referral_friends"
      )?.clientHeight;
      var heightTableLatest = document.getElementById(
        "table_latest_commission"
      )?.clientHeight;

      if (
        heightTableFriends &&
        heightTableLatest &&
        heightTableFriends > heightTableLatest
      ) {
        this.heightTable = heightTableFriends;
      } else {
        this.heightTable = heightTableLatest;
      }
      return;
    },
    onLoadHight() {
      this.$nextTick(() => {
        this.getHeightTable();
      });
    },
    convertTime(time) {
      let stillUtc = moment.utc(time).toDate();
      let local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
      return local;
    },
    trimNumber(number) {
      if (!number) return number;
      return new BigNumber(number);
    },
    getReferralSetting() {
      rf.getRequest("UserRequest")
        .getReferralSetting({ is_webview: this.isWebview })
        .then((res) => {
          this.settingReferral = res.data;
          this.settingReferral.refund_percent =
            Number(this.settingReferral.refund_percent_at_level_1) +
            Number(this.settingReferral.refund_percent_at_level_2) +
            Number(this.settingReferral.refund_percent_at_level_3) +
            Number(this.settingReferral.refund_percent_at_level_4) +
            Number(this.settingReferral.refund_percent_at_level_5);
          this.settingReferral.refund_percent_next_program =
            Number(this.settingReferral.refund_percent_in_next_program_lv_1) +
            Number(this.settingReferral.refund_percent_in_next_program_lv_2) +
            Number(this.settingReferral.refund_percent_in_next_program_lv_3) +
            Number(this.settingReferral.refund_percent_in_next_program_lv_4) +
            Number(this.settingReferral.refund_percent_in_next_program_lv_5);
        });
    },
    fbShareAction(user) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${
          this.appUrl
        }/register?ref=${
          user?.referrer_code || this.$route.query.referralCode
        }`,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );

      //window.open("https://www.facebook.com/sharer/sharer.php?u=" + escape(this.refferalCode.url) + "&t=" + document.title, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      return false;
    },
    twShareAction(user) {
      const url =
        this.appUrl + "/register?ref=" + user?.referrer_code ||
        this.$route.query.referralCode;
      window.open(
        `https://twitter.com/intent/tweet?original_referer=${url}/register?ref=${
          user.referrer_code || this.$route.query.referralCode
        }&ref_src=&text=&tw_p=tweetbutton&url=${url}`,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );
      //window.open("https://twitter.com/intent/tweet?original_referer=" + this.refferalCode.url + "&ref_src=&text=&tw_p=tweetbutton&url=" + this.refferalCode.url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      return false;
    },
    linShareAction(user) {
      const url =
        this.appUrl + "/register?ref=" + user?.referrer_code ||
        this.$route.query.referralCode;
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          url
        )}&title=&summary=&source=`,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );
      //window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + this.refferalCode.url + "&title=" + "&summary=" + "&source=", '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      return false;
    },

    showSuccess(message, type) {
      let copyText;
      var textArea = document.createElement("textarea");
      if (type == "id") {
        copyText = document.getElementById("idCopy");
        textArea.value = copyText.textContent.trim();
      } else {
        copyText = document.getElementById("linkCopy");
        textArea.value = copyText.textContent.trim();
      }
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999); // For mobile devices
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
      Message.success(message, {}, { position: "bottom_left" });
    },
    showDateTimeSelectModal() {
      window.CommonModal.show("DateTimeSelect");
    },
    downloadReportFriend() {
      rf.getRequest("UserRequest").downloadReportFriend(
        {
          timezone_offset: Utils.getTimzoneOffset(),
          is_webview: this.isWebview,
        },
        "ReferralFriends.csv"
      );
    },
    downloadReportCommission() {
      rf.getRequest("UserRequest").downloadReportCommission(
        {
          timezone_offset: Utils.getTimzoneOffset(),
          type: this.showContentTypes || "spot",
          is_webview: this.isWebview,
        },
        "CommissionHistory.csv"
      );
    },

    getUserReferralCommission(params = null) {
      if (!this.isLogin) {
        return;
      }
      let default_object = {
        type: this.showContentTypes || "spot",
      };
      params.limit = 3;
      params.is_webview = this.isWebview;
      const response = rf
        .getRequest("UserRequest")
        .getUserReferralCommission(Object.assign(default_object, params));
      return response;
    },

    getAllUserReferralCommission() {
      if (!this.isLogin) {
        return;
      }
      //  get referrer_histories with type=spot to calculate total commission for SpotEx
      const response = rf
        .getRequest("UserRequest")
        .getUserReferralCommission({ is_webview: this.isWebview });
      this.getuserReferralCommissionBalances(response);
      //  get referrer_histories with type=margin to calculate total commission for MarginEx
      let params = {
        type: "future",
        is_webview: this.isWebview,
      };
      const responseForMargin = rf
        .getRequest("UserRequest")
        .getUserReferralCommission(params);
      this.getuserReferralCommissionBalancesForMargin(responseForMargin);
    },
    getUserReferralFriends() {
      if (!this.isLogin) {
        return;
      }
      const promise = rf
        .getRequest("UserRequest")
        .getUserReferralFriends({ is_webview: this.isWebview });
      return promise;
    },
    getAllReferrer(params = null) {
      if (!this.isLogin) {
        return;
      }
      params.is_webview = this.isWebview;
      params.limit = 4;
      const promise = rf.getRequest("UserRequest").getAllReferrer(params);
      return promise;
    },
    countFriend() {
      rf.getRequest("UserRequest")
        .getTotalReferrer({ is_webview: this.isWebview })
        .then((res) => {
          this.numberFriend = res;
        });
    },
    getuserReferralCommissionBalances(response) {
      response.then((res) => {
        this.userReferralCommissions = res.data;
      });
    },
    getuserReferralCommissionBalancesForMargin(response) {
      response.then((res) => {
        this.userReferralCommissionsForMargin = res.data;
      });
    },
    getTopUserReferralCommission() {
      rf.getRequest("UserRequest")
        .getTopUserReferralCommission({ is_webview: this.isWebview })
        .then((res) => {
          this.topUserRefCommissions = res.data;
        });
    },
    createUserQrcode() {
      let params = {
        url: `${window.location.origin}/register?${COMMON_CONST.REFERRAL_KEY}=`,
        is_webview: this.isWebview,
      };
      rf.getRequest("UserRequest")
        .createUserQrcode(params)
        .then((res) => {
          this.refferalCode = Object.assign({}, this.refferalCode, res.data);
        });
    },
    getPrices() {
      rf.getRequest("PriceRequest")
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
        });
    },
    loadDataImmediately() {
      this.getReferralSetting();
      this.createUserQrcode();
      this.getUserReferralFriends();
      this.countFriend();
      //this.getTopUserReferralCommission();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
    },
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
      };
    },
    refresh() {
      this.sumBTC();
      this.sumBTCForMargin();
      const isNoData =
        window._.isEmpty(this.accountBalances) ||
        window._.isEmpty(this.prices) ||
        window._.isEmpty(this.coins);
      if (isNoData) {
        return;
      }
    },
    sumBTC() {
      let sum = new BigNumber(0);
      let self = this;
      _.map(this.userReferralCommissions, function (value, key) {
        sum = sum.add(
          new BigNumber(
            self.getBalance(value.amount, value.coin.toLowerCase(), "btc")
          )
        );
      });
      return sum;
    },
    sumBTCForMargin() {
      let sum = new BigNumber(0);
      let self = this;
      _.map(this.userReferralCommissionsForMargin, function (value, key) {
        sum = sum.add(
          new BigNumber(
            self.getBalance(value.amount, value.coin.toLowerCase(), "btc")
          )
        );
      });
      return sum;
    },
    getBalance(balance, coin, currency) {
      if (!balance) {
        return 0;
      }

      if (!this.prices) {
        return 0;
      }

      if (coin === currency) {
        return balance;
      }

      const pair_btc =
        coin == "usd" ? `${coin}_${currency}` : `${currency}_${coin}`;
      const pair_eth = `eth_${coin}`;
      const pair_usdt = `usdt_${coin}`;
      const pair_usd = `usd_${coin}`;
      if (!this.prices[pair_btc]) {
        if (pair_btc == "btc_usdt")
          return new BigNumber(balance)
            .div(this.prices["usdt_btc"].price)
            .toString();
        if (this.prices[pair_eth]) {
          return new BigNumber(balance)
            .mul(this.prices[pair_eth].price)
            .mul(this.prices["btc_eth"].price)
            .toString();
        }
        if (this.prices[pair_usdt]) {
          return new BigNumber(balance)
            .mul(this.prices[pair_usdt].price)
            .div(this.prices["usdt_btc"].price)
            .toString();
        }
        if (this.prices[pair_usd]) {
          return new BigNumber(balance)
            .mul(this.prices[pair_usd].price)
            .div(this.prices["usd_btc"].price)
            .toString();
        }
        return 0;
      }
      return coin == "usd"
        ? new BigNumber(balance).div(this.prices[pair_btc].price).toString()
        : new BigNumber(balance).mul(this.prices[pair_btc].price).toString();
    },
    getImageMedal(index) {
      switch (index) {
        case 0:
          return "icon-no1";
        case 1:
          return "icon-no2";
        case 2:
          return "icon-no3";
      }
    },
    checkDataUserCommission(isHasData) {
      this.disableUserCommissions = !isHasData;
    },
    checkDataUserFriend(isHasData) {
      this.disableUserFriend = !isHasData;
    },
    detectWebviewPlatform() {
      const navigator = window.navigator;
      const userAgent = navigator.userAgent;
      const normalizedUserAgent = userAgent.toLowerCase();
      const standalone = navigator.standalone;
      const isIos =
        /ip(ad|hone|od)/.test(normalizedUserAgent) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
      const isAndroid = /android/.test(normalizedUserAgent);
      const isSafari = /safari/.test(normalizedUserAgent);
      const isWebview =
        (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
        (isIos && !standalone && !isSafari);
      return isWebview;
    },
  },
  mounted() {
    // `${this.appUrl}/register?ref=${user.referrer_code}`"
    this.urlReferral = `${this.appUrl}/register?ref=${
      this?.getUser ? this.getUser : this.$route.query.referralCode
    }`;
    document.title = this.$t("refferal.title") + ` – ${this.tabTitle}`;

    if (this.isLogin) {
      this.loadDataImmediately();
      this.getAllUserReferralCommission();
    }
  },
  // beforeUpdate() {
  //    this.getUserReferral = this.$store.state?.user?.referrer_code
  // },
  watch: {
    prices(newPrices) {
      this.refresh();
    },
    showContentTypes(newValue) {
      this.$refs.datatable_commission.refresh();
    },
  },
  computed: {
    ...mapGetters(["user"]),
    balanceCommissionsCustom() {
      if (this.userReferralCommissions) {
        const temp = this.sumBTC();
        const convertTemp = this.$options.filters.formatCurrencyAmount(temp);
        if (convertTemp.length > 5) {
          this.bigger = true;
          const cutNumber = convertTemp.toString().slice(0, 5) + "...";
          return cutNumber;
        }
        return convertTemp;
      }
    },
    ...mapState({
      theme: (state) => state.userThemeMode,
      userData: (state) => state.user,
    }),
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
    balanceCommissions() {
      if (this.userReferralCommissions) {
        const temp = this.sumBTC();
        const convertTemp = this.$options.filters.formatCurrencyAmount(temp);
        if (convertTemp.toString().length > 5) this.bigger = true;
        return this.sumBTC();
      }
    },
    balanceCommissionsForMargin() {
      if (this.userReferralCommissionsForMargin) {
        if (this.sumBTCForMargin().toString().length > 5)
          this.biggerCommissionsForMargin = true;
        return this.sumBTCForMargin();
      }
    },
    appUrl() {
      return window.location.origin;
    },
    getUser() {
      return this.userData?.referrer_code;
    },
  },
  beforeCreate() {
    const route = this.$route.query;
    const token = route.token?.trim().replaceAll(" ", "+");
    const theme = route.theme?.trim();
    const locale = route.locale?.trim();
    if (locale) {
      this.$i18n.locale = locale;
      Cookies.set("locale", locale, { domain: process.env.VUE_APP_PUBLIC_URL });
      window.i18n.locale = locale;
      this.$store.dispatch("setLang", locale);
    }
    if (route) {
      const secretKey = process.env.VUE_APP_SECRET_KEY;
      const themeMode = theme === "dark" ? "dark-theme" : "light-theme";
      const value = Crypto.AES.decrypt(token, secretKey).toString(
        Crypto.enc.Utf8
      );
      Cookies.set("access_token", value, {
        domain: process.env.VUE_APP_PUBLIC_URL,
      });

      Cookies.set("user-theme", themeMode, {
        domain: process.env.VUE_APP_PUBLIC_URL,
      });
      this.$store.commit("setUserThemeMode", themeMode);
      AuthenticationUtils.dataLoaded = false;
      const isAuthentication = AuthenticationUtils.isAuthenticated();
      window.axios.defaults.headers.common["Authorization"] =
        "Bearer " + AuthenticationUtils.getAccessToken();
      window.isAuthenticated = isAuthentication;
      this.$store.commit(
        "setAuthentication",
        AuthenticationUtils.getAccessToken()
      );
    }
  },
  created() {
    // this.isLogin = true;
    this.isWebview = this.detectWebviewPlatform();
    rf.getRequest("UserRequest")
      .getCurrentUser(false, { is_webview: this.isWebview })
      .then((res) => {
        this.getUserReferral = window._.get(res, "data.referrer_code", "");
        // this.updateUser(res.data);
      });
    this.getPrices();
  },
};
</script>

<style lang="scss" scoped>
#export-referral-desktop {
  display: block !important;
}
#export-referral-mobile {
  display: none !important;
}
/* Breadcrumb styling */
.breadcrumb .breadcrumb-item a {
  color: #57585c;
  font-weight: 700;
}

.breadcrumb .breadcrumb-item.active {
  color: #17181a;
  font-weight: 700;
}

/* Dark theme for breadcrumb */
.dark-theme .breadcrumb .breadcrumb-item a {
  color: #dddddd;
  font-weight: 700;
}

.dark-theme .breadcrumb .breadcrumb-item.active {
  color: #ffffff;
  font-weight: 700;
}
tr {
  position: relative;
}
.t-left {
  text-align: left !important;
  padding-left: 29px;
}
.referel_block {
  display: flex;
  gap: 20px;
  height: 100%;
}
.box_responsive_table {
  background-color: transparent;
  border-radius: 8px;
  .item {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .title {
    font-size: 12px;
    font-weight: 400;
    color: #8e8e92;
  }
  .text-table {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  th {
    border-top: 1px solid var(--border-referral);
    border-bottom: 1px solid var(--border-referral);
  }
  tr {
    border-top: 1px solid var(--border-referral);
    border-bottom: 1px solid var(--border-referral);
  }
}
.w_158 {
  max-width: 163px;
  padding-left: 7px !important;
}
#table_latest_commission {
  .text-table {
    font-size: $font-root;
  }
}
.text-table {
  font-size: $font-big;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: var(--text-close);
  padding: 14px 0px 8px 20px;
  .comission-wrapper {
    display: flex;
    justify-content: center;
    max-width: 160px;
    .hide-character {
      img {
        margin-left: 7px;
        margin-top: -4px;
      }
      .hide-commision {
        display: none;
      }
      &:hover {
        .hide-commision {
          display: block;
          position: absolute;
          color: #fff;
          background: #333333;
          padding: 10px;
          border-radius: 8px;
          top: -40px;
          left: 5px;
        }
      }
    }

    p {
      margin-bottom: 0px;
      white-space: nowrap;
    }
    .space {
      //margin-left: 4px;
    }
  }
  .VuePagination {
    color: red;
  }
}
.head_group_positions {
  overflow: hidden;
  background-color: transparent;
  position: relative;
  height: 58px;
  margin-bottom: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .item {
    display: inline-block;
    float: left;
    text-align: center;
    line-height: 16px;
    color: var(--text-close);
    padding: 12px 16px;
    margin-right: 1px;
    background-color: transparent;
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    margin-top: 8px;
    &.active {
      color: var(--text-check-label);
      border-bottom: 2px solid $color-aqua-green;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-weight: 600;
      font-size: 18px;
    }
    &:hover {
    }
    .sub {
      font-family: $font-family-roboto;
    }
    .icon {
      font-family: $font-family-roboto;
    }
  }
}
.referal_element {
  .hideCoin {
    display: none;
    font-size: 34px;
    font-weight: 600;
    color: var(--text-primary-color);
  }
  &:hover {
    .hideCoin {
      display: block;
      position: absolute;
      color: #fff;
      background: #333333;
      padding: 10px;
      border-radius: 8px;
      top: -55px;
      left: -20px;
      font-size: 26px;
    }
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
:deep(#acount_content) {
  width: 77%;
}
#referral {
  .referral_wrapper {
    background: var(--background-home-page);
    width: 100%;
    padding: 20px 40px;
    .content_referral {
      padding: 0px 0px 50px 0px;
      background: var(--background-home-page);
      .introduce {
        background-image: linear-gradient(
          90deg,
          rgba(129, 254, 233, 1) 0%,
          rgba(246, 110, 255, 1) 100%
        );
        background-repeat: no-repeat;
        height: auto;
        width: 100%;
        padding: 40px 60px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        color: $color-white;
        margin-top: 24px;
        margin-bottom: 40px;
        position: relative;
        .sideBarMobile {
          position: absolute;
          top: 16px;
          right: 16px;
          width: 30px;
        }
        .container-custom {
          position: relative;
          display: flex;
          height: fit-content;
          justify-content: space-between;
          width: 100%;
          margin-right: auto;
          margin-left: auto;
          //padding-right: 260px;
          padding-left: 40px;
          @media only screen and (max-width: 1440px) {
            padding-right: 40px;
            padding-left: 40px;
          }
          .introduce-wrapper {
            max-width: 690px;
            h2 {
              font-weight: 700;
              font-size: 30px;
              line-height: 38px;
              color: #151717;
              margin-bottom: 10px;
              font-family: $font-notosans-bold;
            }
            .sub-introduece {
              font-weight: 500;
              font-size: 16px;
              color: #151717;
            }
          }
          .introduce-img3d {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            bottom: -40px;
            right: 0px;
            height: auto;
            img {
              width: 40%;
            }
          }
        }
      }
      .referral-program {
        .title {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          color: var(--color-text-content);
        }
        .container-custom {
          padding-top: 16px;
          width: 100%;
          margin-right: auto;
          margin-left: auto;
          @media screen and (max-width: 1550px) {
            padding-right: 40px;
          }
          .title {
            font-size: 36px;
            margin-bottom: 50px;
            color: var(--text-primary-color);
            line-height: 44px;
          }
          .box-content-wrapper {
            display: flex;
            // align-items: center;
            // justify-content: center;
            .column-left {
              display: flex;
              flex-direction: column;
              max-width: 400px;
              margin-right: 150px;
              .box {
                display: flex;
                margin-bottom: 16px;
                max-width: 600px;
                img {
                  margin-right: 10px;
                  width: 40px;
                  height: 40px;
                }
                .content {
                  .title-detail {
                    font-family: $font-inter-medium;
                    font-weight: 700;
                    font-size: 16px;
                    color: var(--color-text-content);
                    line-height: 22px;
                  }
                  .content-detail {
                    font-weight: 300;
                    font-size: 14px;
                    color: #8e8e92;
                    line-height: 30px;
                    margin-bottom: 0px;
                    opacity: 0.8;
                  }
                }
              }
            }
            .column-right {
              background-color: var(--background-ref-card);
              padding: 32px 24px;
              border-radius: 16px;
              overflow: hidden;
              color: $color-grey-dusty;
              font-size: $font-root;
              line-height: 20px;
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              width: 600px;
              .qr-character {
                width: 100%;
                .my-referral-id {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 11px 15px;
                  background-color: var(--background-ref-item);
                  margin-bottom: 10px;
                  border-radius: 8px;
                  width: auto;
                  .title-id {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #8e8e92;
                  }
                  .code-id {
                    display: flex;
                    align-items: center;
                    .code_id_referral {
                      font-weight: 400;
                      font-size: 14px;
                      color: var(--text-primary-color);
                      margin-right: 12px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 100%;
                      text-align: end;
                      line-height: 20px;
                    }
                    img {
                      cursor: pointer;
                    }
                  }
                }
                .action-qr {
                  display: flex;
                  justify-content: flex-end;
                  .list_society_link {
                    display: flex;
                    gap: 10px;
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .referral-detail {
      // background: var(--bg-referral);
      .conainer_referral {
        padding-right: 260px;
        max-width: 100%;
        padding-left: 40px;
        padding-top: 70px;
        @media screen and (max-width: 1755px) {
          width: 95%;
          padding-right: 0px;
          padding-left: 40px;
        }
        @media screen and (max-width: 992px) {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    .program-detail-wrapper {
      margin-bottom: 200px;
      .container-custom {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        .title_absolute {
          margin-bottom: 16px;
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          color: var(--text-primary-color);
        }
      }
    }

    .tit_page_referral {
      margin-bottom: 20px;
      color: $color-eden;
      font-weight: 500;
    }
    .step_account {
      margin: 20px 0px 40px 0px;
      .block_circle {
        width: calc(100% / 3);
        display: block;
        float: left;
        padding-right: 40px;
        &:last-child {
          padding-right: 0px;
          .circle {
            &:after {
              display: none;
            }
          }
          .block_content {
            &::after {
              content: none;
            }
          }
        }
        &:first-child {
          .circle {
            &:before {
              display: none;
            }
          }
        }
        .block_content {
          float: left;
          width: 100%;
          border-radius: 3px;
          background-color: $color-bright-gray;
          min-height: 250px;
          padding: 40px 0px 40px 60px;
          position: relative;
          &::after {
            z-index: 1;
            position: absolute;
            top: 32%;
            left: 100%;
            content: "";
            width: 0;
            height: 0;
            border-left: solid 40px $color-bright-gray;
            border-right: solid 40px transparent;
            border-bottom: solid 40px transparent;
            border-top: solid 40px transparent;
          }
        }
        .circle {
          margin-bottom: 18px;
          position: relative;
          i {
            width: 60px;
            height: 60px;
            font-size: 60px;
            text-align: center;
            line-height: 60px;
            border-radius: 50%;
            position: relative;
            z-index: 2;
            background-color: $color-bright-gray;
            color: $color-eden;
          }
        }
        .title_step {
          margin-bottom: 10px;
          font-family: $font-family-roboto;
          font-size: $font-medium-bigger;
          font-weight: 500;
          line-height: normal;
          color: $color-black;
        }
        .content_step {
          width: 262px;
          max-width: 96%;
          font-size: $font-small;
          line-height: 1.54;
          color: $color-grey-dusty;
        }
      }
    }
    .commission {
      margin-right: -30px;
      overflow: hidden;
      .block_commission {
        display: block;
        width: calc(100% / 3 - 4px);
        margin-right: 4px;
        float: left;
        background-color: $color-eden;
        padding: 6px;
        border-radius: 3px;
        overflow: hidden;
        &:nth-child(2) {
          margin-left: 4px;
        }
        &:nth-child(3) {
          margin-left: 4px;
          margin-right: 0px;
        }

        .img_commission {
          width: 64px;
          height: 64px;
          float: left;
          line-height: 35px;
          padding: 15px 1px 15px 0px;
          text-align: center;
          border-radius: 50%;
          display: block;
          color: $color-white;
          overflow: hidden;
          background-color: transparent;
          margin-right: 25px;
          i {
            font-size: 35px;
          }
        }
        .sub_commission {
          width: calc(100% - 64px - 25px);
          float: left;
          padding: 9px 0px;
          font-size: $font-root;
          color: $color-white;
          line-height: 23px;
          .left_sub_commission {
            display: inline-block;
            float: left;
          }
          .right_sub_commission {
            display: inline-block;
            float: right;
            min-width: 40%;
          }
          .emailcommission {
            font-weight: 600;
          }
          .btc_commission {
            font-weight: 600;
          }
        }
      }
    }
    .box_qr_referral {
      display: flex;
      overflow: hidden;
      margin: 30px 0px 90px 0px;
      .box_qr_referral_left {
        max-width: 560px;
        max-height: 298px;
        margin-right: 17px;
        padding: 30px;
        background: var(--background-home-page);
        border-radius: 8px;
        .crop_qr_referral {
          float: left;
          width: 160px;
          height: 160px;
          margin-right: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .qr_content_referral {
          overflow: hidden;
          display: block;
          color: $color-grey-dusty;
          font-size: $font-root;
          line-height: 20px;
          .qr-left {
            margin-bottom: 20px;
            display: flex;
            .qr-image {
              margin-right: 30px;
              img {
                background-color: $pure_color_white;
                border-radius: 8px;
              }
            }
          }
          .action-qr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: var(--background-color-second);
            border-radius: 8px;
            padding: 15px;
            img {
              cursor: default;
            }
            .btn-share-qr {
              display: flex;
              align-items: center;
              span {
                margin-right: 20px;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: var(--text-primary-color);
              }
              .list_society_link {
                a {
                  color: var(--text-primary-color);
                  font-size: 16px;
                  &:not(:last-child) {
                    margin-right: 23px;
                  }
                }
                img {
                  cursor: pointer;
                }
              }
            }
          }
          .rate_nb_referral {
            color: $color-grey-dark;
            font-weight: 700;
          }
          .code_id_referral {
            color: $color-grey-dark;
            font-weight: 700;
          }

          .tit_input_link_referran {
            margin-bottom: 8px;
          }
          .rata_referral {
            margin-bottom: 8px;
          }
          .society_link {
            margin-top: 29px;
            .tit_society_link {
              display: inline-block;
              float: left;
              margin-right: 8px;
            }
            .list_society_link {
              display: block;
              overflow: hidden;
              a {
                display: inline-block;
                float: left;
                width: 20px;
                height: 20px;
                font-size: $font-medium-bigger;
                color: $color-grey;
                line-height: 20px;
                text-align: center;
                margin: 0px 2px;
                &:hover {
                  color: $color-green;
                }
              }
            }
          }
          .input_link_referran {
            width: 100%;
            .group_input_link_referran {
              width: 100%;
              input {
                width: 100%;
                height: 42px;
                font-size: $font-root;
                line-height: 20px;
                padding: 15px;
                color: $color-grey-dark;
                margin-bottom: 12px;
                margin-top: 5px;
                border-radius: 3px;
                background-color: $color-bright-gray;
                font-weight: 600;
              }
              button {
                width: 118px;
                height: 35px;
                padding: 8px 10px 7px 10px;
                text-transform: uppercase;
                border-radius: 3px;
                background: transparent;
                font-family: $font-family-roboto-bold;
                font-size: $font-smaller;
                font-weight: bold;
                letter-spacing: 0.9px;
                color: $color-green;
                &:hover,
                &:active,
                &:focus {
                  background-color: $color-blue-custom;
                  border-color: $color-blue-custom;
                  color: $color-white;
                }
              }
            }
          }
        }
      }
      .box_qr_referral_right {
        max-width: 703px;
        max-height: 298px;
        background: var(--background-home-page);
        border-radius: 8px;
        padding: 30px 25px 25px 25px;
        flex: 1;
        overflow-x: auto;
        .referal_element {
          display: block;
          width: 33%;
          margin-top: 56px;
          text-align: center;
          position: relative;
          .content {
            font-size: 34px;
            font-weight: 600;
            color: var(--text-primary-color);
            @media screen and (max-width: 1500px) {
              font-size: 24px;
            }
            .wrapper_coin {
              display: flex;
              justify-content: center;
              .coin_number {
                font-size: 34px;
                font-weight: 600;
                text-transform: uppercase;
                color: var(--text-primary-color);
                @media screen and (max-width: 1919px) {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 70px;
                }
                @media screen and (max-width: 1500px) {
                  font-size: 24px;
                }
                @media screen and (max-width: 768px) {
                  width: unset;
                }
              }
            }
          }
          .coinBtc {
            .hideCoin {
              display: none;
            }
            &:hover {
              .hideCoin {
                display: block;
                position: absolute;
                color: #fff;
                background: #333333;
                padding: 10px 5px;
                border-radius: 8px;
                top: -55px;
                left: -20px;
                font-size: 26px;
                width: 240px;
              }
            }
          }
          .tit {
            color: var(--text-desc);
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
          }
        }
      }
    }

    .export_refarral {
      //overflow: hidden;
      margin-bottom: 33px;
      display: flex;
      flex-direction: column;
      .table_left_refarral {
        width: 600px;
        display: block;
        margin-right: 15px;
        .tableContainer {
          background: var(--background-home-page);
          min-height: 360px;
          padding: 16px 0;
          border-radius: 8px;
          table {
            thead {
              th {
                &:first-child {
                  width: 60%;
                  max-width: 176px;
                  min-width: 140px;
                  text-align: left;
                  padding-left: 10px;
                }
                &:nth-child(2) {
                  width: 40%;
                  max-width: 164px;
                  min-width: 130px;
                  text-align: left;
                }
              }
            }
            tbody {
              td {
                &:first-child {
                  padding-left: 10px;
                  text-align: left;
                }
                &:nth-child(2) {
                  text-align: left;
                }
              }
            }
          }
        }
      }
      .table_right_refarral {
        width: 100%;
        display: block;
        margin-right: 15px;
        .tableContainer {
          background: var(--background-home-page);
          min-height: 360px;
          padding: 16px 0;
          border-radius: 8px;
          table {
            thead {
              th {
                &:first-child {
                  width: 20%;
                  max-width: 176px;
                  min-width: 140px;
                  text-align: left;
                  padding-left: 10px;
                }
                &:nth-child(2) {
                  width: 30%;
                  max-width: 164px;
                  min-width: 130px;
                  text-align: center;
                }
                &:nth-child(3) {
                  width: 30%;
                  max-width: 164px;
                  min-width: 130px;
                  text-align: center;
                }
                &:nth-child(4) {
                  width: 20%;
                  max-width: 164px;
                  min-width: 130px;
                  text-align: right;
                }
              }
            }
            tbody {
              td {
                &:first-child {
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
      .datatable {
        .table_title {
          float: left;
          font-size: 20px;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 0px;
          color: var(--text-primary-color);
        }
        .excel_export {
          cursor: pointer;
          display: inline-block;
          font-size: 14px;
          font-weight: 700;
          padding-top: 6px;
          color: $color-caribbean-green;
          background: none;
          img {
            margin-left: 12px;
          }
        }
        table {
          thead {
            th {
              color: var(--text-primary-color);
              text-align: left;
              font-size: 20px;
              line-height: 28px;

              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
    .detail_referral {
      max-width: 100%;

      .block_tric_referral {
        ul {
          list-style-type: disc !important;
          font-size: 14px;
          color: #8e8e92;
          line-height: 20px;
          padding-left: 0px;
          li {
            color: #8e8e92;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            margin-left: 32px;
            .line {
              width: 100%;
              clear: both;
              color: var(--text-desc);
              margin-bottom: 16px;
              p {
                font-weight: 400;
                font-size: 18px;
                color: var(--text-desc);
                line-height: 30px;
              }
              span {
                margin-left: 5px;
                margin-bottom: 15px;
                color: var(--text-desc);
              }
            }
            line::marker {
              color: var(--text-desc);
            }

            &:before {
              display: inline-block;
              float: left;
              content: "\E907";
              font-family: "icomoon" !important;
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: $color-green;
              -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
              line-height: 20px;
              margin-right: 10px;
              width: 15px;
              font-size: $font-medium;
              position: absolute;
              top: 0px;
              left: 0px;
            }
          }
          .li1 {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-left: 32px;
            color: #8e8e92;
          }
          .level {
            font-weight: 400;
            font-size: 24px;
            color: var(--text-desc);
            margin-bottom: 16px;
            ul {
              color: var(--text-desc);
              .spaceLevel {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #8e8e92;
                /* padding-left: 16px; */
              }
            }
          }
          .limit {
            ul {
              li {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #8e8e92;
                span {
                  color: #8e8e92;
                }
              }
            }
          }
          .notice {
            margin-top: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--text-desc);
            .notice_title {
              margin-bottom: 6px;
            }
            p {
              margin-bottom: 0px;
              line-height: 29px;
            }
          }
        }
      }
      .block_important {
        margin-top: 10px;
        background-color: $color-bright-gray;
        padding: 25px 30px;
        font-size: $font-root;
        color: $color-grey-dusty;
        line-height: 20px;
        .fo_blue {
          color: $color-grey-dark;
          font-weight: 600;
          margin-bottom: 7px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  #referral {
    .action-qr {
      justify-content: center !important;
      img {
        height: auto;
      }
      .btn-share-qr {
        span {
          font-size: 14px;
          margin-right: 10px;
        }
        .list_society_link {
          a {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
    }
    .referral_wrapper {
      padding: 20px 24px;
      .content_referral {
        padding-bottom: 30px;
        .introduce {
          padding: 32px 16px;
          background-image: linear-gradient(
            90deg,
            rgba(129, 254, 233, 1) 0%,
            rgba(246, 110, 255, 1) 100%
          );
          overflow: hidden;

          .container-custom {
            position: relative;
            justify-content: space-around;
            text-align: center;
            padding: 0 18px;
            .introduce-wrapper {
              max-width: 215px;
              h2 {
                line-height: 24px;
                font-size: 18px;
                font-weight: 700;
                color: #151717;
              }
              .sub-introduece {
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: #151717;
              }
            }
            .introduce-img3d {
              position: absolute;
              bottom: -32px;
              right: -69px;
              img {
                opacity: 0.4 !important;
                width: 60%;
              }
            }
          }
        }

        .referral-program {
          .container-custom {
            width: auto;
            padding: 0;
            .title {
              font-size: 20px;
              margin-bottom: 24px;
            }
            .box-content-wrapper {
              display: flex;
              flex-direction: column;
              .column-left {
                margin: 0;
                margin-top: 20px;
                margin-bottom: 20px;
                .box {
                  width: 100%;
                  img {
                    width: 40px;
                    height: 40px;
                    margin-right: 6px;
                  }
                  .content {
                    .title-detail {
                      font-size: 16px;
                      line-height: 22px;
                    }
                    .content-detail {
                      font-size: 14px;
                      line-height: 20px;
                    }
                  }
                }
              }
              .column-right {
                width: 100%;
              }
            }
          }
        }
      }
      .referral-detail {
        .conainer_referral {
          padding: 20px 15px 0;
        }
      }
      .box_qr_referral {
        flex-direction: column;
        margin: 0;
        .box_qr_referral_left {
          margin-bottom: 30px;
          max-height: 100%;
          max-width: 100%;
          margin-right: 0;
          .qr_content_referral {
            .qr-left {
              flex-direction: column;
              margin-bottom: 0px;

              .qr-image {
                margin: 0 0 20px 0;
                text-align: center;
              }
              .qr-character {
                .my-referral-id {
                  width: 100%;
                  min-width: 100%;
                  .title-id {
                    font-size: 14px;
                  }
                  .code-id {
                    .code_id_referral {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        .box_qr_referral_right {
          max-height: 100%;
          max-width: 100%;
          padding: 20px;
          .referal_element {
            width: 100%;
            margin: 0;

            .content {
              font-size: 24px;
              margin-bottom: 15px;
              .wrapper_coin {
                .coin_number {
                  font-size: 24px;
                }
              }
            }

            .tit {
              font-size: 14px;
            }
          }
        }
      }
      .referel_block {
        flex-direction: column;
      }

      .export_refarral {
        margin-top: 30px;
        margin-bottom: 0px;
        flex-direction: column;
        .table_left_refarral {
          width: 100%;
          max-width: 100%;
        }
        .datatable {
          .excel_export {
            font-size: 14px;
            padding: 0;

            img {
              margin-left: 6px;
              width: 13.5px;
              height: 15px;
            }
          }
          .table_title {
            font-size: 16px;
            line-height: 19px;
          }
          .tableContainer {
            padding: 24px 0;
            //min-height: 250px;
            table {
              th {
                font-size: 14px;
                line-height: 16px;
              }

              td {
                font-size: 14px;
              }
            }
          }
        }

        .table_right_refarral {
          margin: 30px 0;
          width: 100% !important;
          max-width: 100%;

          .content-table-right {
            position: relative;
            width: 100%;
            border-top: none;

            .tableContainer3 {
              .box_table2 {
                table {
                  thead {
                    th {
                      &:first-child {
                        width: 25%;
                        max-width: 176px;
                        min-width: 140px;
                      }
                      &:nth-child(2) {
                        width: 23%;
                        max-width: 164px;
                        min-width: 130px;
                      }
                      &:nth-child(3) {
                        width: 23%;
                        max-width: 164px;
                        min-width: 130px;
                      }
                      &:nth-child(4) {
                        width: 29%;
                        max-width: 206px;
                        min-width: 165px;
                      }
                    }
                  }
                }
              }
            }

            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            &::-webkit-scrollbar-track {
              background: $color-alabaster;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 8px;
              background: $color-gray-alto;
            }
            .tableContainer::v-deep {
              //min-width: 570px;
              .box_table2 {
                padding-bottom: 12px;
              }
            }
          }
        }
      }
      .head_group_positions {
        height: 52px;
        display: block;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
          display: none;
        }
        .item {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          float: none;
        }
      }
      .program-detail-wrapper {
        margin-bottom: 60px;
        .container-custom {
        }
      }
      .title_absolute {
        font-size: 20px;
        line-height: 24px;
      }
      .detail_referral {
        .block_tric_referral {
          ul li {
            font-size: 14px;
            line-height: 18px;
            margin-left: 23px;
          }
          ul {
            .li1 {
              font-size: 14px;
              line-height: 18px;
              margin-left: 23px;
            }
            .level {
              ul {
                .spaceLevel {
                  font-size: 14px;
                  line-height: 18px;
                  padding-left: 0;
                }
              }
            }
            .limit {
              ul {
                li {
                  font-size: 14px;
                  line-height: 18px;
                  span {
                  }
                }
              }
            }
            .notice {
              font-size: 14px;
              line-height: 18px;
              padding-left: 0;
              .notice_title {
                margin-left: 23px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #export-referral-desktop {
    display: none !important;
  }
  #export-referral-mobile {
    display: block !important;
    .box-referral {
      border: 1px solid #eaeaed;
      padding: 16px;
      border-radius: 16px;
      .row-1,
      .row-2 {
        display: flex;
        justify-content: space-between;
        .text-left {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8e8e92;
        }
        .text-right {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: var(--text-transfer);
        }
      }
    }
    .head_group_positions {
      .item {
        text-align: center;
        line-height: 16px;
        color: var(--text-transfer);
        padding: 14px 16px;
        background-color: transparent;
        cursor: pointer;
        font-weight: 700;
        font-size: 14px;
        margin-top: 16px;
        &.active {
          color: var(--text-check-label);
          border-bottom: 2px solid #03aac7;
        }
        &:hover {
          color: var(--text-check-label);
        }
        .sub {
          font-family: $font-family-roboto;
        }
        .icon {
          font-family: $font-family-roboto;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  #referral {
    .referral_wrapper {
      .content_referral {
        .introduce {
          padding: 32px 16px;
          background-image: linear-gradient(
            90deg,
            rgba(129, 254, 233, 1) 0%,
            rgba(246, 110, 255, 1) 100%
          );
        }
      }
    }
  }
  #referral .referral_wrapper .export_refarral .datatable .table_title {
    font-size: 12px;
  }
  #referral .referral_wrapper .export_refarral .datatable .excel_export {
    font-size: 12px;
  }
}
</style>
<style lang="scss">
#referral
  .referral_wrapper
  .export_refarral
  .table_right_refarral
  .tableContainer {
  .box_table2 {
    table {
      th {
        &:first-child {
          width: 24.24%;
        }
        &:nth-child(2) {
          width: 30.64%;
        }
        &:nth-child(3) {
          width: 28.26%;
        }
        &:nth-child(4) {
          width: 15.8%;
          padding-left: 10px;
        }
      }
    }
    tbody {
      td {
        &:nth-child(3) {
          padding-right: 10px;
        }
        &:last-child {
          padding-left: 10px;
        }
      }
      tr {
        &.empty-data {
          td {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
}

#referral
  .referral_wrapper
  .export_refarral
  .table_left_refarral
  .tableContainer {
  .box_table2 {
    tbody {
      tr {
        &.empty-data {
          p:first-child {
            padding-top: 64px;
            @media screen and (max-width: 320px) {
              padding-top: 0;
            }
          }
          td {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
}
</style>
