<template>
    <div id="form-input-order-id">
           <!--left-->
      <div class="form-input-order">
<!-- 
        <div class="head_form_order cleafix" >
          <ul>
            <li  @click="showFormOrder = CONSTS.TYPE_BUY" class="tab-buy" v-bind:class="{'active': showFormOrder === CONSTS.TYPE_BUY}">
               {{ $t('order.order_form.buy') }} {{coin | currencyName}} 
            </li>
            <li  @click="showFormOrder = CONSTS.TYPE_SELL" class="tab-sell" v-bind:class="{'active': showFormOrder === CONSTS.TYPE_SELL}">
               {{ $t('order.order_form.sell') }} {{coin | currencyName}} 
            </li>
          </ul>
        </div> -->

        <div class="order-type justify-content-between" :class="{'invisible': disabledTab}">
          <div class="d-flex">

           <div v-on:click="() => this.$emit('tabChange', 'limit')" class="tab-element" v-bind:class="{'active': tab === 'limit'}">
              <span class="text-size">{{ $t('order.order_form.limit') }}</span>
            </div>
            <div v-on:click="() => this.$emit('tabChange', 'market')" class="tab-element" v-bind:class="{'active': tab === 'market'}">
              <span class="text-size">{{ $t('orders.open_order.market') }}</span>
            </div>
            <div v-on:click="() => this.$emit('tabChange', 'stop_limit')" class="tab-element" v-bind:class="{'active': tab === 'stop_limit'}">
              <span class="text-size">{{ $t('order.order_form.stop_limit') }}</span>
              <!-- <span class="question-sign" @click="showTooltipLimit()" v-click-outside="hideTooltipLimit"></span> -->
              <div v-if="isShowTooltipLimit" class="tooltip-order">
                <p class="text-tooltip">{{ $t('order.order_form.stop_limit_tooltip') }}</p>
                <a :href="$siteSupportArticle($i18n.locale, 'how-to-use-stop-limit-function')" target="_blank"> {{ $t('order.order_form.view_more') }}</a>
              </div>
            </div>
            <div v-on:click="() => this.$emit('tabChange', 'stop_market')" class="tab-element" v-bind:class="{'active': tab === 'stop_market'}">
              <span class="text-size">{{ $t('order.order_form.stop_market') }}</span>
              <!-- <span class="question-sign" @click="showTooltipMarket()" v-click-outside="hideTooltipMarket"></span> -->
              <div v-if="isShowTooltipMarket" class="tooltip-order">
                <p class="text-tooltip"> {{ $t('order.order_form.stop_market_tooltip') }}</p>
                <a :href="$siteSupportArticle($i18n.locale,'how-to-use-stop-limit-function')" target="_blank">{{ $t('order.order_form.view_more') }}</a>
              </div>
            </div>

          </div>

          <div class="position-relative information" id="infor">
              <img class="w-100 infor-dark" src="@/assets/images/icon/infor-dark.svg" alt=""/>
              <img class="w-100 infor-light" src="@/assets/images/icon/infor-light.svg" alt=""/>
              <div class="position-absolute content" id="content-infor">
                <span v-if="tab === 'limit'" v-html="$t('order.order_form.limit_tooltip')"></span>
                <span v-if="tab === 'market'" v-html="$t('order.order_form.market_tooltip')"></span>
                <span v-if="tab === 'stop_limit'" v-html="$t('order.order_form.stop_limit_tooltip')"></span>
                <span v-if="tab === 'stop_market'" v-html="$t('order.order_form.stop_market_tooltip')"></span>
              </div>
            </div>


        </div>

        <div class="content_form_order">

          <div class="content_form_buy" v-if="showFormOrder === CONSTS.TYPE_BUY">
            <div class="right_from_mn cleafix available-coin">
<!--             <i class="icon_money icon-wallet "></i>-->
              <div class="title">{{ $t('order.order_form.available') }}:</div>
              <span class="align_vertical value-coin">
                {{ currencyBalance | formatCurrencyAmount(currency, '0') }} {{toUpperCase(currency)}}
              </span>
            </div>
            <div class="group_input_form cleafix">
              <!-- <span class="txt_left_group" v-show="tab === 'limit'||tab === 'market'">
                {{ $t('order.order_form.price') }}
              </span> -->
              <label class="input_right_group" v-show="tab === 'limit' || tab === 'market'" @click="removeSelected('buy', tab)">
                <marked-input v-show="tab === 'limit'" v-model="limitBuyOrder.price" :unit="currency" :placeholder="$t('order.order_form.price')" :precision="pricePrecision"  :visible-button="false"/>
                <marked-input v-show="tab === 'market'" :disabled="true" :value="$t('trading_information.market_price')" :placeholder="$t('order.order_form.price')"  :input-text="true" :component="OrderForm" :visible-button="false" />
              </label>
            </div>

            <div class="group_input_form cleafix">
              <!-- span class="txt_left_group"  v-show="tab === 'stop_limit'||tab === 'stop_market'">
                {{ $t('order.order_form.stop') }}
              </span> -->
              <label @click="removeSelected('buy', tab)" class="input_right_group" v-show="tab === 'stop_limit' || tab === 'stop_market'">
                <marked-input v-show="tab === 'stop_limit'" v-model="stopLimitBuyOrder.stop" :unit="currency" :placeholder="$t('order.order_form.stop')" :precision="pricePrecision"  :visible-button="false"/>
                <marked-input v-show="tab === 'stop_market'" v-model="stopMarketBuyOrder.stop" :unit="currency" :placeholder="$t('order.order_form.stop')" :precision="pricePrecision"  :visible-button="false"/>
              </label>
            </div>

            <div class="group_input_form cleafix">
              <!--  <span class="txt_left_group"  v-show="tab === 'stop_limit'||tab === 'stop_market'">
                {{ $t('order.order_form.limited_price') }}
              </span> -->
              <label @click="removeSelected('buy', tab)" class="input_right_group" v-show="tab === 'stop_limit' || tab === 'stop_market'">
                <marked-input v-show="tab === 'stop_limit'" v-model="stopLimitBuyOrder.price" :unit="currency" :placeholder="$t('order.order_form.limited_price')" :precision="pricePrecision"  :visible-button="false"/>
                <marked-input :disabled="true" v-show="tab === 'stop_market'" :value="$t('trading_information.market_price')" :placeholder="$t('order.order_form.limited_price')" :input-text="true" :component="OrderForm" :visible-button="false"/>
              </label>
            </div>

            <div class="group_input_form cleafix">
              <!--  <span class="txt_left_group">
                {{ $t('order.order_form.amount') }}
              </span> -->
              <label @click="removeSelected('buy', tab)" class="input_right_group">
                <marked-input v-show="tab === 'limit'" v-model="limitBuyOrder.amount" :unit="coin" :placeholder="$t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/>
                <!-- <marked-input v-show="tab === 'market'" v-model="marketBuyOrder.amount" :unit="coin" :placeholder="$t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/> -->
                <marked-input v-show="tab === 'market'" :name="'market-buy'" v-model="marketBuyOrder.amount" @select-value='handleDataUpdate' :select-token="selectPair" :placeholder="currency===marketBuyOrder.coin ? $t('order.order_form.total') : $t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/>
                <marked-input v-show="tab === 'stop_limit'" v-model="stopLimitBuyOrder.amount" :unit="coin" :placeholder="$t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/>
                <marked-input v-show="tab === 'stop_market'" v-model="stopMarketBuyOrder.amount" :unit="coin" :placeholder="$t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/>
              </label>
            </div>

            <div class="group_input_form cleafix">
              <span class="txt_left_group mgb-none"></span>
              <label class="input_right_group percent-spot">
                <!-- <button class="per_btn" v-bind:class="{'activeBtnBuy' : percentBuy === 25}" @click="onBuyPercentClicked(25, tab)">25%</button> -->
                <span class="check_percent" :class="{ selected :selectedPercent[tab]['p_25']['buy']}" @click="onBuyPercentClicked(25, tab)">25%</span>
                <span class="check_percent" :class="{ selected :selectedPercent[tab]['p_50']['buy']}" @click="onBuyPercentClicked(50, tab)">50%</span>
                <span class="check_percent" :class="{ selected :selectedPercent[tab]['p_75']['buy']}" @click="onBuyPercentClicked(75, tab)">75%</span>
                <span class="check_percent" :class="{ selected :selectedPercent[tab]['p_100']['buy']}" @click="onBuyPercentClicked(100, tab)">100%</span>
              </label>
            </div>

            <slider @input="onSlideChange($event,tab)" v-model="sliderValue"/>

            <div class="group_input_form cleafix">
              <!-- <span class="txt_left_group" v-show="tab === 'limit'||tab === 'stop_limit'">
                {{ $t('order.order_form.total') }}
              </span> -->
              <label @click="removeSelected('buy', tab)" class="input_right_group" v-show="tab === 'limit' || tab === 'stop_limit'">
                <marked-input :unit="currency" v-show="tab === 'limit'" v-model="limitBuyOrder.total"  :placeholder="$t('order.order_form.total')" :precision="pricePrecision" :classes="classTotal" :visible-button="false"/>
                <marked-input :unit="currency" v-show="tab === 'stop_limit'" v-model="stopLimitBuyOrder.total"  :placeholder="$t('order.order_form.total')" :precision="pricePrecision" :classes="classTotal" :visible-button="false"/>
              </label>
            </div>

            <div class="group_input_form cleafix" v-if="Islogin">
              <span class="txt_left_group"></span>
              <div class="input_right_group no-select">
                <button class="btn-success-market" @click="actionBuyOrderClicked()" :disabled="disableBuySell || waitCountDownTimeoutRemain || blockTrading || isSubmitting || disableByBetaTester">
                  {{ $t('order.order_form.buy') }}  {{coin | currencyName}}
                </button>
                <!-- <button class="btn-success" @click="actionBuyOrderClicked()" :disabled="disableBuySell || waitCountDownTimeoutRemain || blockTrading || isSubmitting">{{ $t('order.order_form.buy') }}</button> -->
              </div>
            </div>

            <div class="group_input_form cleafix mgt15" v-if="!Islogin" >
              <span class="txt_left_group"></span>
              <label class="input_right_group">
                <div class="textAuthen">
                    <router-link class="but_get_log" :to="{name: 'Login'}">{{ $t('login.title-3')}}</router-link>
                    <span> {{ $t('order.order_form.or')}} </span>
                    <router-link class="but_get_log" :to="{name: 'Register'}" >{{ $t('order.order_sign_up')}}</router-link>
                </div>
              </label>
            </div>
          </div>

          <div class="content_form_sell" v-if="showFormOrder === CONSTS.TYPE_SELL">
             <div class="right_from_mn cleafix available-coin">
<!--                <i class="icon_money icon-wallet "></i>-->
               <div class="title">{{ $t('order.order_form.available') }}:</div>
               <span class="align_vertical value-coin">
                  {{ coinBalance | formatCurrencyAmount(coin, '0') }} {{ toUpperCase(coin) }}
                </span>
             </div>
             <div class="group_input_form cleafix">
              <!-- <span class="txt_left_group" v-show="tab === 'limit'||tab === 'market'">
                {{ $t('order.order_form.price') }}
              </span> -->
               <label @click="removeSelected('sell', tab)" class="input_right_group" v-show="tab === 'limit' || tab === 'market'">
                 <marked-input v-show="tab === 'limit'" v-model="limitSellOrder.price" :unit="currency" :placeholder="$t('order.order_form.price')" :precision="pricePrecision"  :visible-button="false"/>
                 <marked-input :disabled="true" v-show="tab === 'market'" :value="$t('trading_information.market_price')" :placeholder="$t('order.order_form.price')" :input-text="true" :component="OrderForm" :visible-button="false"/>
               </label>
            </div>

            <div class="group_input_form cleafix">

              <label @click="removeSelected('sell', tab)" class="input_right_group" v-show="tab === 'stop_limit' || tab === 'stop_market'">
                <marked-input v-show="tab === 'stop_limit'" v-model="stopLimitSellOrder.stop" :unit="currency" :precision="pricePrecision" :placeholder="$t('order.order_form.stop')"  :visible-button="false"/>
                <marked-input v-show="tab === 'stop_market'" v-model="stopMarketSellOrder.stop" :unit="currency" :precision="pricePrecision" :placeholder="$t('order.order_form.stop')"  :visible-button="false"/>
              </label>
            </div>

            <div class="group_input_form cleafix">
              <!-- <span class="txt_left_group" v-show="tab === 'stop_limit'||tab === 'stop_market'">
                {{ $t('order.order_form.limited_price') }}
              </span> -->
              <label @click="removeSelected('sell', tab)" class="input_right_group" v-show="tab === 'stop_limit' || tab === 'stop_market'">
                <marked-input v-show="tab === 'stop_limit'" v-model="stopLimitSellOrder.price" :unit="currency" :placeholder="$t('order.order_form.limited_price')" :precision="pricePrecision"  :visible-button="false"/>
                <marked-input :disabled="true" v-show="tab === 'stop_market'" :value="$t('trading_information.market_price')" :placeholder="$t('order.order_form.limited_price')" :input-text="true" :component="OrderForm" :visible-button="false"/>
              </label>
            </div>

            <div class="group_input_form cleafix">
             <!--  <span class="txt_left_group">
                {{ $t('order.order_form.amount') }}
              </span> -->
              <label class="input_right_group" @click="removeSelected('sell', tab)">
                <marked-input v-show="tab === 'limit'" v-model="limitSellOrder.amount" :unit="coin" :placeholder="$t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/>
                <!-- <marked-input v-show="tab === 'market'" v-model="marketSellOrder.amount" :unit="coin" :placeholder="$t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/> -->
                <marked-input v-show="tab === 'market'" :name="'market-sell'" v-model="marketSellOrder.amount"  @select-value='handleUpdateMarketSell' :select-token="selectPair" :placeholder="currency===marketSellOrder.coin ? $t('order.order_form.total') : $t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/>
                <marked-input v-show="tab === 'stop_limit'" v-model="stopLimitSellOrder.amount" :unit="coin" :placeholder="$t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/>
                <marked-input v-show="tab === 'stop_market'" v-model="stopMarketSellOrder.amount" :unit="coin" :placeholder="$t('order.order_form.amount')" :precision="amountPrecision" :classes="classAmount" :visible-button="false"/>
              </label>
            </div>

            <div class="group_input_form cleafix">
              <span class="txt_left_group mgb-none"></span>
              <label class="input_right_group percent-spot">
                <!-- <button class="per_btn" v-bind:class="{'activeBtnBuy' : percentBuy === 25}" @click="onSellPercentClicked(25, tab)">25%</button> -->
                <span class="check_percent" :class="{ selected :selectedPercent[tab]['p_25']['sell']}"  @click="onSellPercentClicked(25, tab)">25%</span>
                <span class="check_percent" :class="{ selected :selectedPercent[tab]['p_50']['sell']}" @click="onSellPercentClicked(50, tab)">50%</span>
                <span class="check_percent" :class="{ selected :selectedPercent[tab]['p_75']['sell']}" @click="onSellPercentClicked(75, tab)">75%</span>
                <span class="check_percent" :class="{ selected :selectedPercent[tab]['p_100']['sell']}" @click="onSellPercentClicked(100, tab)">100%</span>
              </label>
            </div>

            <slider @input="onSlideChangeSell($event,tab)" v-model="sliderValue"/>

            <div class="group_input_form cleafix">
              <!-- <span class="txt_left_group" v-show="tab === 'limit'||tab === 'stop_limit'">{{ $t('order.order_form.total') }}</span> -->
              <label @click="removeSelected('sell', tab)" class="input_right_group" v-show="tab === 'limit' || tab === 'stop_limit'">
                <marked-input :unit="currency" class="adasdad" v-show="tab === 'limit'" v-model="limitSellOrder.total" :placeholder="$t('order.order_form.total')" :precision="pricePrecision" :classes="classTotal" :visible-button="false"/>
                <marked-input :unit="currency" class="asdasdasd" v-show="tab === 'stop_limit'" v-model="stopLimitSellOrder.total" :placeholder="$t('order.order_form.total')" :precision="pricePrecision" :classes="classTotal" :visible-button="false"/>
              </label>
            </div>

            <div class="group_input_form cleafix" v-if="Islogin" >
              <span class="txt_left_group"></span>
              <div class="input_right_group no-select">
                <button class="btn-danger-market" @click="actionSellOrderClicked()" :disabled="disableBuySell || waitCountDownTimeoutRemain || blockTrading || isSubmitting || disableByBetaTester">
                  {{ $t('order.order_form.sell') }}   {{coin | currencyName}}
                </button>
                <!-- <button class="btn-danger" @click="actionSellOrderClicked()" :disabled="disableBuySell || waitCountDownTimeoutRemain || blockTrading || isSubmitting ">{{ $t('order.order_form.sell') }}</button> -->
              </div>
            </div>

            <div class="group_input_form cleafix mgt15" v-if="!Islogin" >
              <span class="txt_left_group"></span>
              <label class="input_right_group">
                <div class="textAuthen">
                    <a class="but_get_log" href="/login">{{ $t('login.title-3')}}</a>
                    <span> {{ $t('order.order_form.or')}} </span>
                    <a class="but_get_log" href="/register" >{{ $t('order.order_sign_up')}}</a>
                </div>
              </label>
            </div>
          </div>

          <div class="group_input_form cleafix trading-block-status" v-if="waitCountDownTimeoutRemain || blockTrading" >
            <span class="txt_left_group"></span>
            <label class="input_right_group">
              <span v-if="(displayRemainSecondBlockTrading || 0) > 0" v-html="$t('circuit_breaker.remain_second_text', { 'minutes': convertSecondToMinute(displayRemainSecondBlockTrading) })"></span>
            </label>
          </div>

          <div class="group_input_form cleafix trading-block-status" v-if="disableByBetaTester" >
            <span class="txt_left_group"></span>
            <label class="input_right_group">
              <span v-html="$t('beta_tester.disable_by_beta_tester')"></span>
            </label>
          </div>

        </div>
      </div>

    </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import moment from 'moment';
import Utils from '@/common/Utils'
import Const from '@/common/Const';
import OrderUtils from '@/common/OrderUtils';
import BigNumber from "bignumber.js";
import MarkedInput from "@/components/spotexchange_pages/common/MarkedInput";
import { mapState } from 'vuex';
import COMMON_CONST from "@/common/Const";
import Slider from "@/components/spotexchange_pages/common/Slider.vue";

const ENABLE_TRADING = Const.CIRCUIT_BREAKER.ENABLE_TRADING;
const DISABLE_TRADING = Const.CIRCUIT_BREAKER.DISABLE_TRADING;
const ALLOW_TRADING = false;
const BLOCK_TRADING = true;
const TYPE_SELL = 0;
const TYPE_BUY = 1;

export default {
  components: {
    MarkedInput,
    Slider,
  },

  props: {
    tab: {
      type: String,
      default: 'limit'
    },
    disabledTab: {
      type: Boolean,
      default: false
    },
    showFormOrder: {
      type: Number,
      default: 1
    }

  },

  data() {
    return {
      isShowTooltipMarket: false,
      isShowTooltipLimit: false,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,
      selectPair: [this.$route.query.coin || Const.DEFAULT_COIN,this.$route.query.currency || Const.DEFAULT_CURRENCY],
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      Islogin: window.isAuthenticated,
      currentPrice: undefined,
      prices: {},
      balances: {},
      percentSell: 0,
      percentBuy: 0,
      isActiveBuyPercent: false,
      coinSetting: undefined,
      clickRow: false,

      currencyBalance: undefined,
      coinBalance: undefined,
      limitBuyOrder: {
        price: undefined,
        amount: undefined,
        total: undefined,
        percent: 0
      },
      limitSellOrder: {
        price: undefined,
        amount: undefined,
        total: undefined,
        percent: 0
      },
      marketBuyOrder: {
        amount: undefined,
        percent: 0,
        price: undefined,
        coin: this.$route.query.coin || Const.DEFAULT_COIN
      },
      marketSellOrder: {
        amount: undefined,
        percent: 0,
        price: undefined,
        coin: this.$route.query.coin || Const.DEFAULT_COIN
      },
      stopLimitBuyOrder: {
        stop: undefined,
        price: undefined,
        amount: undefined,
        total: undefined,
        percent: 0
      },
      stopLimitSellOrder: {
        stop: undefined,
        price: undefined,
        amount: undefined,
        total: undefined,
        percent: 0
      },
      stopMarketBuyOrder: {
        stop: undefined,
        amount: undefined,
        percent: 0
      },
      stopMarketSellOrder: {
        stop: undefined,
        amount: undefined,
        percent: 0
      },

      selectedPercent: {
        'limit': {
          'p_25': {
            'buy': false,
            'sell': false,
          },
          'p_50': {
            'buy': false,
            'sell': false,
          },
          'p_75': {
            'buy': false,
            'sell': false,
          },
          'p_100': {
            'buy': false,
            'sell': false,
          },
        },
        'market': {
          'p_25': {
            'buy': false,
            'sell': false,
          },
          'p_50': {
            'buy': false,
            'sell': false,
          },
          'p_75': {
            'buy': false,
            'sell': false,
          },
          'p_100': {
            'buy': false,
            'sell': false,
          },
        },
        'stop_limit': {
          'p_25': {
            'buy': false,
            'sell': false,
          },
          'p_50': {
            'buy': false,
            'sell': false,
          },
          'p_75': {
            'buy': false,
            'sell': false,
          },
          'p_100': {
            'buy': false,
            'sell': false,
          },
        },
        'stop_market': {
          'p_25': {
            'buy': false,
            'sell': false,
          },
          'p_50': {
            'buy': false,
            'sell': false,
          },
          'p_75': {
            'buy': false,
            'sell': false,
          },
          'p_100': {
            'buy': false,
            'sell': false,
          },
        },

      },
      classAmount: "input-amount",
      classTotal: "input-total",
      OrderForm: "order-form",
      classInput: "border-bottom",
      isSubmitting: false,
      disableBuySell: false, //   disable for go live production
      blockTrading: false,  // disable trading for circuit breaker
      disableByBetaTester: false,
      remainSecondBlockTrading: 0,
      displayRemainSecondBlockTrading: 0,
      countTimeoutRemain: null,
      waitCountDownTimeoutRemain: false,
      sellOrderBookDisplay: [],
      CONSTS: {
        ...Const,
        TYPE_SELL: TYPE_SELL,
        TYPE_BUY: TYPE_BUY,
      },
      sliderValue: 0,
    };
  },

  watch: {
    recallBalances() {
      this.getBlances(false);
      this.$store.dispatch('setRecallBalances', false);
    },
    remainSecondBlockTrading(remainSeconds) {
      this.displayRemainSecondBlockTrading = remainSeconds;
      if (this.countTimeoutRemain) {
        clearInterval(this.countTimeoutRemain);
      }

      if (this.blockTrading) {
        this.countTimeoutRemain = setInterval(() => {
          this.displayRemainSecondBlockTrading--;
          if (this.displayRemainSecondBlockTrading <= 0) {
            this.displayRemainSecondBlockTrading = 0;
            clearInterval(this.countTimeoutRemain);
            // this.showSuccess(window.i18n.t("circuit_breaker.unblocking"));
            this.showUnblocking();
          }
        }, 1000);
      }

    },
    tab() {
      if(this.tab === 'limit') {
        this.percentBuy = this.limitBuyOrder.percent;
        this.percentSell = this.limitSellOrder.percent;
      } else if(this.tab === 'market') {
        this.percentBuy = this.marketBuyOrder.percent;
        this.percentSell = this.marketSellOrder.percent;
      } else if(this.tab === 'stop_limit') {
        this.percentBuy = this.stopLimitBuyOrder.percent;
        this.percentSell = this.stopLimitSellOrder.percent;
      } else {
        this.percentBuy = this.stopMarketSellOrder.percent;
        this.percentSell = this.stopMarketSellOrder.percent;
      }
    },

    //=================== limit Buy Order ===================
    "limitBuyOrder.price"(newValue, oldValue) {
      if (window._.isEmpty(newValue)) {
        return;
      }
      this.onChangePriceInput(newValue, this.limitBuyOrder);
    },
    "limitBuyOrder.amount"(newValue, oldValue) {
      this.onChangeAmountInput(newValue, oldValue, this.limitBuyOrder);
    },
    "limitBuyOrder.total"(newValue, oldValue) {
      this.onChangeTotalInput(newValue, oldValue, this.limitBuyOrder);
    },

    //=================== limit Sell Order ===================
    "limitSellOrder.price"(newValue, oldValue) {
      this.onChangePriceInput(newValue, this.limitSellOrder);
    },
    "limitSellOrder.amount"(newValue, oldValue) {
      this.onChangeAmountInput(newValue, oldValue, this.limitSellOrder);
    },
    "limitSellOrder.total"(newValue, oldValue) {
      this.onChangeTotalInput(newValue, oldValue, this.limitSellOrder);
    },

    //=================== Stop limit Buy Order ===================
    "stopLimitBuyOrder.price"(newValue, oldValue) {
      this.onChangePriceInput(newValue, this.stopLimitBuyOrder);
    },
    "stopLimitBuyOrder.amount"(newValue, oldValue) {
      this.onChangeAmountInput(newValue, oldValue, this.stopLimitBuyOrder);
    },
    "stopLimitBuyOrder.total"(newValue, oldValue) {
      this.onChangeTotalInput(newValue, oldValue, this.stopLimitBuyOrder);
    },

    //=================== Stop limit Sell Order ===================
    "stopLimitSellOrder.price"(newValue, oldValue) {
      this.onChangePriceInput(newValue, this.stopLimitSellOrder);
    },
    "stopLimitSellOrder.amount"(newValue, oldValue) {
      this.onChangeAmountInput(newValue, oldValue, this.stopLimitSellOrder);
    },
    "stopLimitSellOrder.total"(newValue, oldValue) {
      this.onChangeTotalInput(newValue, oldValue, this.stopLimitSellOrder);
    },

    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;
      this.selectPair = [this.$route.query.coin || Const.DEFAULT_COIN,this.$route.query.currency || Const.DEFAULT_CURRENCY]
      // this.marketBuyOrder.coin = this.$route.query.coin || Const.DEFAULT_COIN;
      // this.marketSellOrder.coin = this.$route.query.coin || Const.DEFAULT_COIN;

      this.onCurrencyPairChanged();
    }
  },

  computed: {
    ...mapState({
      masterData: state => state.masterdata,
      recallBalances: state => state.recallBalances,
    }),
    pricePrecision() {
      return this.coinSetting ? parseFloat(this.coinSetting.price_precision) : 0.0001;
    },
    amountPrecision() {
      return this.coinSetting
        ? parseFloat(this.coinSetting.quantity_precision)
        : 0.0001;
    },
    minimumTotalOrder() {
      return this.coinSetting
        ? parseFloat(this.coinSetting.minimum_amount)
        : 0.0001;
    },
  },

  methods: {
    onSlideChange(e,type) {
      const percent = Number(e)
      if (!this.Islogin) {
        return;
      }
      if(percent===25 ||percent===50 ||percent===75 ||percent===100 ){
        this.changeSelected(percent,'buy', type);
      } else {
        this.removeSelected('buy', type);
      }
      this.percentBuy = (percent);
      let price = undefined;
      switch (type) {
        case "limit":
          price = this.limitBuyOrder.price;
          this.limitBuyOrder.percent = percent;
          break;
        case "market":
          price = this.calculateBuyMarketPrice(percent, type);
          this.marketBuyOrder.percent = percent;
          break;
        case "stop_market":
          price = this.currentPrice;
          this.stopMarketBuyOrder.percent = percent;
          break;
        case "stop_limit":
          price = this.stopLimitBuyOrder.price;
          this.stopLimitBuyOrder.percent = percent;
          break;
      }
      let amount = undefined;
      if (price) {
        const balance = new BigNumber(`${this.currencyBalance || 0}`);
        amount = balance
          .div(new BigNumber(`${price}`))
          .mul(percent)
          .div(100);
        amount = new BigNumber(
          amount.div(this.amountPrecision)
        ).mul(this.amountPrecision);
      }
      switch (type) {
        case "limit":
          this.limitBuyOrder.amount = amount;
          break;
        case "market":
          this.marketBuyOrder.amount = amount;
          break;
        case "stop_limit":
          this.stopLimitBuyOrder.amount = amount;
          break;
        case "stop_market":
          this.stopMarketBuyOrder.amount = amount;
          break;
      }
    },
    onSlideChangeSell(e,type) {
      const percent = Number(e)
      if (!this.Islogin) {
        return;
      }
      if(percent===25 ||percent===50 ||percent===75 ||percent===100 ){
        this.changeSelected(percent,'sell', type);
      } else {
        this.removeSelected('sell', type);
      }
      this.percentSell = percent;
      const balance = new BigNumber(`${this.coinBalance || 0}`);
      let amount = balance.mul(percent).div(100);
      amount = new BigNumber(amount.div(this.amountPrecision)).mul(
        this.amountPrecision
      );

      switch (type) {
        case "limit":
          this.limitSellOrder.amount = amount;
          this.limitSellOrder.percent = percent;
          break;
        case "market":
          this.marketSellOrder.amount = amount;
          this.marketSellOrder.percent = percent;
          break;
        case "stop_limit":
          this.stopLimitSellOrder.amount = amount;
          this.stopLimitSellOrder.percent = percent;
          break;
        case "stop_market":
          this.stopMarketSellOrder.amount = amount;
          this.stopMarketSellOrder.percent = percent;
          break;
      }
    },
    isGreaterThanZero (value) {
      if (new BigNumber(`${value || 0}`).comparedTo(0) > 0) {
        return true;
      }
      return false;
    },
    getEventHandlers() {
      return {
        RecentTradeClicked: this.onRecentTradesClick,
        SellOrderBookClicked: this.onSellOrderBookClicked,
        BuyOrderBookClicked: this.onBuyOrderBookClicked,
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        FocusOrderForm: this.onFocusOrderForm,
        IgnoreBetaTesterStatusUpdate: this.onIgnoreBetaTesterStatusUpdate,
        SpotSellOrderBookDisplay: this.onSpotSellOrderBookDisplay,
      };
    },
    handleDataUpdate(data) {
      this.marketBuyOrder.coin = data
    },
    handleUpdateMarketSell(data) {
      this.marketSellOrder.coin = data
    },
    onIgnoreBetaTesterStatusUpdate(ignoreStatus) {
      if (ignoreStatus == this.CONSTS.BETA_TESTER.ACTIVE) {
        this.disableByBetaTester = false;
      } else {
        this.disableByBetaTester = true;
      }
    },
    onSpotSellOrderBookDisplay(data) {
      this.sellOrderBookDisplay = window._.cloneDeep(data);;
    },
    onFocusOrderForm(type, price, maxAmount) {
      if (type == 'sell') {
        // this.showFormOrder = 1;
        this.onSellOrderBookClicked(price, maxAmount);
      } else {
        // this.showFormOrder = 0;
        this.onBuyOrderBookClicked(price, maxAmount);
      }
    },

    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
      this.blockTrading = this.getBlockTradingStatus(this.currency, this.coin);
      this.onCurrencyPairChanged();
    },

    onRecentTradesClick(price) {
      this.updateLimitBuyOrder(price);
      this.updateStopLimitBuyOrder(price);
      this.updateStopMarketBuyOrder(price);

      this.updateLimitSellOrder(price);
      this.updateStopLimitSellOrder(price);
      this.updateStopMarketSellOrder(price);
    },

    onSellOrderBookClicked(price, maxAmount) {
      this.clickRow = true;
      this.clearLimitSellOrderForm();
      this.clearStopLimitSellOrderForm();
      this.clearPercentOrderTab();

      const amount = this.calculateAmountSellOrderBookClicked(
          price,
          maxAmount,
          this.currencyBalance
      );
      if (this.tab === 'limit') {
        this.updateLimitSellOrder(price);
        this.updateLimitBuyOrder(price, amount);
      } else if (this.tab === 'stop_limit') {
        this.updateStopLimitSellOrder(price);
        this.updateStopLimitBuyOrder(price, amount);
      } else if (this.tab === 'stop_market') {
        this.updateStopMarketBuyOrder(price, amount);
        this.updateStopMarketSellOrder(price, amount);
      }
    },

    onBuyOrderBookClicked(price, maxAmount) {
      this.clickRow = true;
      this.clearLimitBuyOrderForm();
      this.clearStopLimitBuyOrderForm();
      this.clearPercentOrderTab();

      const amount = this.calculateAmountBuyOrderBookClicked(
        price,
        maxAmount,
        this.coinBalance
      );
      if (this.tab === 'limit') {
        this.updateLimitSellOrder(price, amount);
        this.updateLimitBuyOrder(price);
      } else if (this.tab === 'stop_limit') {
        this.updateStopLimitSellOrder(price, amount);
        this.updateStopLimitBuyOrder(price);
      } else if (this.tab === 'stop_market') {
        this.updateStopMarketBuyOrder(price, amount);
        this.updateStopMarketSellOrder(price, amount);
      }
    },

    clearPercentOrderTab() {
      this.selectedPercent = {
        'limit': {
          'p_25': {
            'buy': false,
            'sell': false,
          },
          'p_50': {
            'buy': false,
            'sell': false,
          },
          'p_75': {
            'buy': false,
            'sell': false,
          },
          'p_100': {
            'buy': false,
            'sell': false,
          },
        },
        'market': {
          'p_25': {
            'buy': false,
            'sell': false,
          },
          'p_50': {
            'buy': false,
            'sell': false,
          },
          'p_75': {
            'buy': false,
            'sell': false,
          },
          'p_100': {
            'buy': false,
            'sell': false,
          },
        },
        'stop_limit': {
          'p_25': {
            'buy': false,
            'sell': false,
          },
          'p_50': {
            'buy': false,
            'sell': false,
          },
          'p_75': {
            'buy': false,
            'sell': false,
          },
          'p_100': {
            'buy': false,
            'sell': false,
          },
        },
        'stop_market': {
          'p_25': {
            'buy': false,
            'sell': false,
          },
          'p_50': {
            'buy': false,
            'sell': false,
          },
          'p_75': {
            'buy': false,
            'sell': false,
          },
          'p_100': {
            'buy': false,
            'sell': false,
          },
        },

      }
    },

    calculateAmountSellOrderBookClicked(price, maxAmount, rawBalance) {
      if (!this.Islogin) {
        return maxAmount;
      }
      if (isNaN(rawBalance)) {
        return 0;
      }
      const balance = new BigNumber(`${rawBalance}`);

      const totalCurrency = new BigNumber(`${price}`).mul(`${maxAmount}`);

      let amount = 0;
      if (totalCurrency.gt(balance)) {
        // const exponent = `${Math.pow(10, this.amountPrecision)}`;
        amount = balance.div(`${price}`);
        amount = new BigNumber(
          Math.floor(amount.div(`${this.amountPrecision}`))
        )
          .mul(`${this.amountPrecision}`)
          .toString();
      } else {
        amount = `${maxAmount}`;
      }
      return amount;
    },

    calculateAmountBuyOrderBookClicked(price, maxAmount, rawBalance) {
      if (!this.Islogin) {
        return maxAmount;
      }
      if (isNaN(rawBalance)) {
        return 0;
      }
      const amount = new BigNumber(`${maxAmount}`);
      if (amount.lte(new BigNumber(`${rawBalance}`))) {
        return amount.toString();
      }
      return rawBalance;
    },

    updateLimitBuyOrder(price, amount) {
      this.limitBuyOrder.price = price;
      this.limitBuyOrder.amount = amount || this.limitBuyOrder.amount;
    },

    updateLimitSellOrder(price, amount) {
      this.limitSellOrder.price = price;
      this.limitSellOrder.amount = amount || this.limitSellOrder.amount;
    },

    updateStopLimitBuyOrder(price, amount) {
      this.stopLimitBuyOrder.price = price;
      // this.stopLimitBuyOrder.stop = price;
      this.stopLimitBuyOrder.amount = amount || this.stopLimitBuyOrder.amount;
    },

    updateStopLimitSellOrder(price, amount) {
      this.stopLimitSellOrder.price = price;
      // this.stopLimitSellOrder.stop = price;
      this.stopLimitSellOrder.amount = amount || this.stopLimitSellOrder.amount;
    },

    updateStopMarketBuyOrder(price, amount) {
      // this.stopMarketBuyOrder.stop = price;
      // this.stopMarketBuyOrder.amount = amount || this.stopMarketBuyOrder.amount;
    },

    updateStopMarketSellOrder(price, amount) {
      // this.stopMarketSellOrder.stop = price;
      // this.stopMarketSellOrder.amount =
      //   amount || this.stopMarketSellOrder.amount;
    },

    getSocketEventHandlers() {
      return {
        BalanceUpdated: this.onBalanceUpdated,
        PricesUpdated: this.onPricesUpdated,
        CircuitBreakerSettingUpdated: this.onCircuitBreakerSettingUpdated,
        CircuitBreakerCoinPairSettingUpdated: this.onCircuitBreakerCoinPairSettingUpdated,
        SpotBalanceUpdated: this.onBalanceUpdated
      };
    },
    onCircuitBreakerSettingUpdated(data) {
      if (!data || !data.data) {
        return false;
      }
      this.circuitBreakerSetting = data.data;
      let oldStatusTrading = this.blockTrading;
      this.blockTrading = this.getBlockTradingStatus(this.currency, this.coin);
      if (this.blockTrading != oldStatusTrading) {
        if (this.blockTrading) {
          // this.showError(window.i18n.t("circuit_breaker.blocking"));
          // return false;
          this.showBlocking(false);
        }
        if (!this.displayRemainSecondBlockTrading) {
          // this.showSuccess(window.i18n.t("circuit_breaker.unblocking"));
          this.showUnblocking();
        }
      }
    },
    onCircuitBreakerCoinPairSettingUpdated(data) {
      if (!data || !data.data) {
        return false;
      }
      // Sample data:
      // block_time: "9.00000"
      // circuit_breaker_percent: "8.0000000000"
      // coin: "eth"
      // created_at: "2019-08-22 04:25:08"
      // currency: "btc"
      // id: 1
      // range_listen_time: "7.00000"
      // status: "disable"
      // updated_at: "2019-08-23 04:28:11"
      let eventData = data.data;
      // Merge Block Status
      this.mergerBlockStatus(eventData);
      if (this.currency != eventData.currency || this.coin != eventData.coin) {
        return false;
      }
      this.updateRemainSecondBlockTrading(eventData);

      let newBlockTradingStatus = !!eventData.block_trading;
      if (this.blockTrading == newBlockTradingStatus) {
        return true;
      }

      if (eventData.status == 'disable') {
        this.blockTrading = false;
        // this.showSuccess(window.i18n.t("circuit_breaker.unblocking"));
        // return false;
        this.showUnblocking(false);
      }

      this.blockTrading = newBlockTradingStatus;
      // console.log('this.blockTrading', this.blockTrading);
      if (this.blockTrading) {
        // this.showError(window.i18n.t("circuit_breaker.blocking"));
        // return true;
        this.showBlocking(true);
      }
      if (!this.displayRemainSecondBlockTrading) {
        // this.showSuccess(window.i18n.t("circuit_breaker.unblocking"));
        this.showUnblocking();
      }
    },
    showBlocking: _.debounce(function (retVal = null) {
        this.showError(window.i18n.t("circuit_breaker.blocking"));
        if (retVal != null) {
          return retVal;
        }
      }, 500),
    showUnblocking: _.debounce(function (retVal = null) {
        this.showSuccess(window.i18n.t("circuit_breaker.unblocking"));
        if (retVal != null) {
          return retVal;
        }
      }, 500),
    mergerBlockStatus(eventData) {
      this.coinPairSetting = this.coinPairSetting.map(item => {
        if ((item.coin == eventData.coin) && (item.currency == eventData.currency)) {
          return eventData;
        }
        return item;
      });
    },
    getBlockTradingStatus(currency, coin) {
      if (!this.circuitBreakerSetting) {
        return ALLOW_TRADING;
      }
      // If circuit breaker setting is DISABLE monitor trading
      // no need block trading
      if (this.circuitBreakerSetting.status == 'disable') {
        return ALLOW_TRADING;
      }
      let res = this.coinPairSetting.find(item => {
        return (item.coin == this.coin) && (item.currency == this.currency);
      });
      if (!res) return ALLOW_TRADING;
      if (res.status == "disable") return ALLOW_TRADING;
      if (res.block_trading == 0) return ALLOW_TRADING;  // enable trading

      this.updateRemainSecondBlockTrading(res);

      return BLOCK_TRADING;
    },
    convertSecondToMinute(secondsInput) {
      let seconds = secondsInput || 0;
      let minutes = parseInt(seconds / 60) || 0;
      let secondsInMinute = parseInt(seconds % 60) || 0;

      return minutes + ':' + (secondsInMinute < 10 ? ('0' + secondsInMinute) : secondsInMinute);
    },
    updateRemainSecondBlockTrading(coinPairSetting) {
      let now = moment();
      let nowAt = moment().format('x');
      let timezoneOffset = (new Date(Math.floor(nowAt / 1000))).getTimezoneOffset(); // Example: Asia/Bangkok: -420

      if (!coinPairSetting.unlocked_at) {
        let _this = this;
        let remainDiffSeconds = this.displayRemainSecondBlockTrading;
        if (remainDiffSeconds) {
          this.waitCountDownTimeoutRemain = true;
          setTimeout(() => {
            _this.remainSecondBlockTrading = 0;
            _this.displayRemainSecondBlockTrading = 0;
            _this.waitCountDownTimeoutRemain = false;
          }, remainDiffSeconds * 1000);
        }
        return true;
      }

      let unlockedAtUTC = coinPairSetting.unlocked_at;
      let unlockedAtWithTimezone = unlockedAtUTC;
      let remain = unlockedAtWithTimezone - nowAt;
      remain = Math.floor(remain / 1000);
      this.remainSecondBlockTrading = remain;
    },
    onBalanceUpdated(newBalances) {
      if (newBalances && newBalances[Const.TYPE_EXCHANGE_BALANCE]) {
        newBalances = newBalances[Const.TYPE_EXCHANGE_BALANCE];
      }

      this.balances = Object.assign({}, this.balances, newBalances);
      if (window._.isEmpty(this.balances)) {
        return;
      }
      this.calculateCoinBalance();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      if (window._.isEmpty(this.prices)) {
        return;
      }
      this.calculateLimitOrder();
    },
    calculateCoinBalance() {
      if (this.balances[this.currency]) {
        this.currencyBalance = this.formatCurrencyRoundDown(this.balances[this.currency].available_balance, this.currency, '0');
      }
      if (this.balances[this.coin]) {
        this.coinBalance = this.formatCurrencyRoundDown(this.balances[this.coin].available_balance, this.coin, '0');
      }
    },
    calculateLimitOrder(isChangedPair) {
      var priceObject = this.prices[this.currency + "_" + this.coin] || {};
      this.currentPrice = priceObject.price;
      if (priceObject.price) {
        const price = new BigNumber(`${priceObject.price}`);
        if (!this.limitBuyOrder.price || isChangedPair) {
          this.limitBuyOrder.price = price;
        }
        if (!this.limitSellOrder.price || isChangedPair) {
          this.limitSellOrder.price = price;
        }
        if (!this.marketBuyOrder.price || isChangedPair) {
          this.marketBuyOrder.price = price;
        }
        if (!this.marketSellOrder.price || isChangedPair) {
          this.marketSellOrder.price = price;
        }
      }
    },
    calculateOrderTotal(data) {
      // input amount focus
      const classes = document.activeElement.getAttribute("class");
      if (classes && classes.includes(this.classTotal)) {
        return;
      }
      if (data.price && data.amount) {
        const price = new BigNumber(`${data.price}`);
        const amount = new BigNumber(`${data.amount}`);
        data.total = price.mul(amount).toString();
      }
    },
    calculateOrderAmount(data) {
      // input amount focus
      const classes = document.activeElement.getAttribute("class");
      if (classes && classes.includes(this.classAmount)) {
        return;
      }
      if (data.price && data.total) {
        const price = this.newBigNumber(data.price);
        if (price.eq(0)) {
          // division by zero
          return;
        }
        data.amount = this.newBigNumber(data.total).div(price);
      }
      if (!data.total && !this.clickRow) {
        data.amount = '';
      }
    },
    emptyTotalOrder(order) {
      order.total = null;
    },
    calculateBuyMarketPrice(percent, type) {
      if (this.sellOrderBookDisplay.length > 1) {
        let foundRecord = this.sellOrderBookDisplay[this.sellOrderBookDisplay.length - 1];
        if (foundRecord && foundRecord.price) {
          return foundRecord.price;
        }
      }

      return this.currentPrice;
    },
    onBuyPercentClicked(percent, type) {
      if (!this.Islogin) {
        return;
      }
      this.changeSelected(percent,'buy', type);
      this.percentBuy = percent;
      let price = undefined;
      switch (type) {
        case "limit":
          price = this.limitBuyOrder.price;
          this.limitBuyOrder.percent = percent;
          break;
        case "market":
          price = this.calculateBuyMarketPrice(percent, type);
          this.marketBuyOrder.percent = percent;
          break;
        case "stop_market":
          price = this.currentPrice;
          this.stopMarketBuyOrder.percent = percent;
          break;
        case "stop_limit":
          price = this.stopLimitBuyOrder.price;
          this.stopLimitBuyOrder.percent = percent;
          break;
      }
      let amount = undefined;
      if (price) {
        const balance = new BigNumber(`${this.currencyBalance || 0}`);
        amount = balance
          .div(new BigNumber(`${price}`))
          .mul(percent)
          .div(100);
        amount = new BigNumber(
          amount.div(this.amountPrecision)
        ).mul(this.amountPrecision);
      }
      switch (type) {
        case "limit":
          this.limitBuyOrder.amount = amount;
          break;
        case "market":
          this.marketBuyOrder.amount = amount;
          break;
        case "stop_limit":
          this.stopLimitBuyOrder.amount = amount;
          break;
        case "stop_market":
          this.stopMarketBuyOrder.amount = amount;
          break;
      }
    },
    onSellPercentClicked(percent, type) {
      if (!this.Islogin) {
        return;
      }
      this.changeSelected(percent,'sell', type);
      this.percentSell = percent;
      const balance = new BigNumber(`${this.coinBalance || 0}`);
      let amount = balance.mul(percent).div(100);
      amount = new BigNumber(amount.div(this.amountPrecision)).mul(
        this.amountPrecision
      );
      switch (type) {
        case "limit":
          this.limitSellOrder.amount = amount;
          this.limitSellOrder.percent = percent;
          break;
        case "market":
          this.marketSellOrder.amount = amount;
          this.marketSellOrder.percent = percent;
          break;
        case "stop_limit":
          this.stopLimitSellOrder.amount = amount;
          this.stopLimitSellOrder.percent = percent;
          break;
        case "stop_market":
          this.stopMarketSellOrder.amount = amount;
          this.stopMarketSellOrder.percent = percent;
          break;
      }
    },
    changeSelected(percent, type, tab) {
      this.removeSelected(type, tab);
      this.selectedPercent[tab]['p_'+percent][type] = true;
      this.sliderValue = percent
    },
    removeSelected(type, tab) {
      this.selectedPercent[tab]['p_25'][type] = false;
      this.selectedPercent[tab]['p_50'][type] = false;
      this.selectedPercent[tab]['p_75'][type] = false;
      this.selectedPercent[tab]['p_100'][type] = false;
    },

    createOrder(data) {
      data.currency = this.currency;
      data.coin = this.coin;
      let balance = this.currencyBalance;
      if (data.trade_type === 'sell') {
        balance = this.coinBalance;
      }
      data.balance = balance;

      try {
        OrderUtils.validateOrderInput(data, this.coinSetting);
      } catch (error) {
        if(error.disableSubMessage) {
          this.showInputError(error.message);
        } else {
          this.showInputErrorAndSubMessage(error.message);
        }

        this.isSubmitting = false;
        return;
      }

      delete data.priceMarket
      this.sendOrderRequest(data);
    },

    onChangePriceInput(newValue, order) {
      if (window._.isEmpty(newValue)) {
        this.emptyTotalOrder(order);
        return;
      }
      this.calculateOrderTotal(order);
    },

    onChangeAmountInput(newValue, oldValue, order) {
      if (window._.isEmpty(newValue)) {
        this.emptyTotalOrder(order);
        return;
      }
      const newAmount = this.toValue(newValue, this.amountPrecision);
      const oldAmount = this.toValue(oldValue, this.amountPrecision);
      if (newAmount === oldAmount) {
        return;
      }
      this.calculateOrderTotal(order);
    },

    onChangeTotalInput(newValue, oldValue, order) {
      const newTotal = this.toValue(newValue, this.pricePrecision);
      const oldTotal = this.toValue(oldValue, this.pricePrecision);
      if (newTotal === oldTotal) {
        return;
      }
      this.calculateOrderAmount(order);
      this.clickRow = false;
    },

    newBigNumber(value) {
      if (value instanceof BigNumber) {
        return value;
      }
      return new BigNumber(`${value || 0}`);
    },

    toValue(value, precision) {
      const decimalPart = this.calculateDecimalPart(precision);

      let result = "";
      if (value instanceof BigNumber) {
        result = value.toString();
      } else {
        result = `${value || ""}`;
      }

      const dotIndex = result.indexOf(".");
      if (dotIndex === -1) {
        return result;
      }
      if (decimalPart > 0) {
        return result.slice(0, dotIndex + decimalPart + 1);
      }
      return result.slice(0, dotIndex);
    },

    /*
        Get number decimal part.
        Use Math.log(x) which is equivalent to ln(x) in mathematics
        Ex: precision = 0.0016
        '1/precision' = 625 = 5 x 5 x 5 x 5
          => Math.log(625)/Math.log(5) = 'stop_market'.
      */
    calculateDecimalPart(precision) {
      return Math.round(Math.log(1 / precision) / Math.log(10));
    },

    actionBuyOrderClicked() {
      this.isSubmitting = true;
      if (this.tab === "limit") {
        this.onCreateLimitBuyOrderClicked();
      } else if (this.tab === "market") {
        this.onCreateMarketBuyOrderClicked();
      } else if (this.tab === "stop_limit") {
        this.onCreateStopLimitBuyOrderClicked();
      } else if (this.tab === "stop_market") {
        this.onCreateStopMarketBuyOrderClicked();
      }
    },

    actionSellOrderClicked() {
      this.isSubmitting = true;
      if (this.tab === "limit") {
        this.onCreateLimitSellOrderClicked();
      } else if (this.tab === "market") {
        this.onCreateMarketSellOrderClicked();
      } else if (this.tab === "stop_limit") {
        this.onCreateStopLimitSellOrderClicked();
      } else if (this.tab === "stop_market") {
        this.onCreateStopMarketSellOrderClicked();
      }
    },

    onCreateLimitBuyOrderClicked() {
      let data = {
        trade_type: "buy",
        type: "limit",
        quantity: this.limitBuyOrder.amount,
        price: this.limitBuyOrder.price,
        total: this.limitBuyOrder.total,
      };
      this.createOrder(data);
    },

    onCreateLimitSellOrderClicked() {
      let data = {
        trade_type: "sell",
        type: "limit",
        quantity: this.limitSellOrder.amount,
        price: this.limitSellOrder.price,
        total: this.limitSellOrder.total,
      };
      this.createOrder(data);
    },

    onCreateMarketBuyOrderClicked() {
      const data = {
        trade_type: "buy",
        type: "market",
        quantity: this.marketBuyOrder.amount,
      };

      if(this.marketBuyOrder?.coin ===  this.currency) {
        const quantityCoin= (this.marketBuyOrder?.amount / this.marketBuyOrder?.price || 0).toFixed(8)
        data.quantity = quantityCoin.toString()
        data.priceMarket = this.marketBuyOrder.price
      }
      // console.log('data', data)
      // console.log('this.marketBuyOrder', this.marketBuyOrder)
      this.createOrder(data);
    },

    onCreateMarketSellOrderClicked() {
      let data = {
        trade_type: "sell",
        type: "market",
        quantity: this.marketSellOrder.amount
      };
      if(this.marketSellOrder?.coin  ===  this.currency) {
        const quantityBTC = (this.marketSellOrder?.amount / this.marketSellOrder?.price || 0).toFixed(8)
        data.quantity = quantityBTC.toString()
        data.priceMarket = this.marketSellOrder.price
      }
      this.createOrder(data);
    },

    onCreateStopLimitBuyOrderClicked() {
      const stopCondition = this.getStopCondition(this.stopLimitBuyOrder.stop);
      let data = {
        trade_type: "buy",
        type: "stop_limit",
        quantity: this.stopLimitBuyOrder.amount,
        price: this.stopLimitBuyOrder.price,
        base_price: this.stopLimitBuyOrder.stop,
        stop_condition: stopCondition,
        total: this.stopLimitBuyOrder.total,
      };
      this.createOrder(data);
    },

    onCreateStopLimitSellOrderClicked() {
      const stopCondition = this.getStopCondition(this.stopLimitSellOrder.stop);
      let data = {
        trade_type: "sell",
        type: "stop_limit",
        quantity: this.stopLimitSellOrder.amount,
        price: this.stopLimitSellOrder.price,
        base_price: this.stopLimitSellOrder.stop,
        stop_condition: stopCondition,
        total: this.stopLimitSellOrder.total,
      };
      this.createOrder(data);
    },

    onCreateStopMarketBuyOrderClicked() {
      const stopCondition = this.getStopCondition(this.stopMarketBuyOrder.stop);
      let data = {
        trade_type: "buy",
        type: "stop_market",
        quantity: this.stopMarketBuyOrder.amount,
        base_price: this.stopMarketBuyOrder.stop,
        stop_condition: stopCondition
      };
      this.createOrder(data);
    },

    onCreateStopMarketSellOrderClicked() {
      const stopCondition = this.getStopCondition(
        this.stopMarketSellOrder.stop
      );
      let data = {
        trade_type: "sell",
        type: "stop_market",
        quantity: this.stopMarketSellOrder.amount,
        base_price: this.stopMarketSellOrder.stop,
        stop_condition: stopCondition
      };
      this.createOrder(data);
    },

    onCurrencyPairChanged() {
      this.calculateLimitOrder(true);
      this.calculateCoinBalance();

      this.getCoinSetting();
    },

    getStopCondition(basePrice) {
      if (basePrice) {
        basePrice = new BigNumber(`${basePrice}`);
        if (basePrice.gte(new BigNumber(`${this.currentPrice}`))) {
          return "ge";
        } else {
          return "le";
        }
      }
    },

    sendOrderRequest(data) {
      rf.getRequest("OrderRequest")
        .createANewOne(data)
        .then(res => {
          if (res.success) {
            const tradeType =
              res.data.trade_type === "sell"
                ? window.i18n.t("order.order_form.sell")
                : window.i18n.t("order.order_form.buy");
            this.showSuccess(
              window.i18n.t("order.order_form.success", {
                trade_type: tradeType.toLowerCase()
              })
            );
          }
          this.getBlances(false);
          this.cleartOrderForm(data);
          this.isSubmitting = false;
        })
        .catch(error => {
          if (!error.response) {
            this.showError(window.i18n.t("common.message.network_error"));
          } else {
            this.showInputError(error.response.data.message);
          }
          this.isSubmitting = false;
        });
    },

    cleartOrderForm(data) {
      if (data.type == "limit" && data.trade_type == "buy") {
        this.clearLimitBuyOrderForm();
      } else if (data.type == "limit" && data.trade_type == "sell") {
        this.clearLimitSellOrderForm();
      } else if (data.type == "market" && data.trade_type == "buy") {
        this.clearMarketBuyOrderForm();
      } else if (data.type == "market" && data.trade_type == "sell") {
        this.clearMarketSellOrderForm();
      } else if (data.type == "stop_limit" && data.trade_type == "buy") {
        this.clearStopLimitBuyOrderForm();
      } else if (data.type == "stop_limit" && data.trade_type == "sell") {
        this.clearStopLimitSellOrderForm();
      } else if (data.type == "stop_market" && data.trade_type == "buy") {
        this.clearStopMarketBuyOrderForm();
      } else if (data.type == "stop_market" && data.trade_type == "sell") {
        this.clearStopMarketSellOrderForm();
      }
    },

    clearLimitBuyOrderForm() {
      this.limitBuyOrder.amount = undefined;
      this.limitBuyOrder.total = undefined;
    },

    clearLimitSellOrderForm() {
      this.limitSellOrder.amount = undefined;
      this.limitSellOrder.total = undefined;
    },

    clearMarketBuyOrderForm() {
      this.marketBuyOrder.amount = undefined;
    },

    clearMarketSellOrderForm() {
      this.marketSellOrder.amount = undefined;
    },

    clearStopLimitBuyOrderForm() {
      this.stopLimitBuyOrder.amount = undefined;
      this.stopLimitBuyOrder.total = undefined;
    },

    clearStopLimitSellOrderForm() {
      this.stopLimitSellOrder.amount = undefined;
      this.stopLimitSellOrder.total = undefined;
    },

    clearStopMarketBuyOrderForm() {
      this.stopMarketBuyOrder.amount = undefined;
    },

    clearStopMarketSellOrderForm() {
      this.stopMarketSellOrder.amount = undefined;
    },

    showError(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },

    showInputError(message) {
      Message.errorInputSpot(message, {}, { position: "bottom_left" });
    },

    showInputErrorAndSubMessage(message) {
      Message.errorInputSpot(message, window.i18n.t("tooltip.check-again"), { position: "bottom_left" });
    },


    showSuccess(message) {
      Message.success(message, {}, { position: "bottom_left" });
    },

    toUpperCase(value) {
      return Utils.getCurrencyName(value);
    },

    formatCurrencyRoundDown(amount, currency, value) {
      if (amount == undefined) {
        return 0;
      }

      let numberOfDecimalDigits = currency && currency.toLowerCase() === 'usd' ? Const.NUMBER_OF_DECIMAL_DIGITS_USD : Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;

      let newValue = new BigNumber(amount);

      return newValue.round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR).toString();
    },

    handleMasterData() {
      if (this.masterData.circuit_breaker_settings && this.masterData.circuit_breaker_settings.length > 0) {
        this.circuitBreakerSetting = this.masterData.circuit_breaker_settings[0];
      }
      this.coinPairSetting = this.masterData.circuit_breaker_coin_pair_settings;
      this.blockTrading = this.getBlockTradingStatus(this.currency, this.coin);
    },

    getBlances(useCache = true) {
      if (window.isAuthenticated) {
        rf.getRequest("UserRequest")
            .getBalance(useCache, Const.TYPE_EXCHANGE_BALANCE)
            .then(res => {
              this.onBalanceUpdated(res.data);
            });
      }
    },

    async initData() {
      this.getCoinSetting();
      this.handleMasterData();

     this.getBlances();
      rf.getRequest("PriceRequest")
        .getPrices()
        .then(res => {
          this.onPricesUpdated(res.data);
        });
    },

    getCoinSetting() {
      if (!this.masterData) {
        return ;
      }
      const setting = window._.find(this.masterData.coin_settings, setting => {
        return setting.currency == this.currency && setting.coin == this.coin;
      });
      this.coinSetting = setting;
    }
  },
  mounted() {
    this.coin = this.$route.query.coin || Const.DEFAULT_COIN;
    this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
    this.initData();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .dark-theme {
    .head_form_order {
      ul {
        li {
          &.tab-buy {
            border-radius: 8px;
            color: $color-storm-grey;
            background: $color-gunmetal;

            &.active{
              background-color: $text-color-jade;
              color: $color-white;
            }
          }
          &.tab-sell {
            border-radius: 8px;
            background: $color-gunmetal;
            color: $color-storm-grey;
            &.active{
              background-color: $text-color-red;
              color: $color-white;
            }
          }
        }
      }
    }
    .order-type {
      color: $color-gun-powder;
      border-bottom: 1px solid #2D2E2F;

      .active {
        color : white;
      }

    }

    .content_form_order {
      .right_from_mn {
        .title {
          color: $color-storm-grey!important;
        }
        .value-coin {
          color: white!important;
        }
      }

      .percent-spot {
        .check_percent {
          /* background: $color-gunmetal; */
          border: 1px solid #414244;
          color: $color-storm-grey;
          &.selected {
            /* background: $color-aqua-green;
            border-color: $color-aqua-green; */
            color: white;
            font-weight: 600;
          }
          &:hover {
            /* background: $color-aqua-green;
            border-color: $color-aqua-green; */
            color: white;

            font-weight: 600;
          }
        }
      }
    }

    .input_right_group {
      hr {
        background: $color-gray-charade;
      }
    }

    .information {
      .infor-light {
        display: none!important;
      }
      .infor-dark {
        display: block!important;
      }
    }

    .textAuthen {
      background: $color-caribbean-green;
      color: $color-grey-dark;
      a {
        color: $color-grey-dark;
      }
    }
    .order-type .information .content {
      background: $bgr-tooltip-type;
    }
  }
  .icon-wallet {
    padding-left: 2px;
  }
  .head_form_order {
    display: none;
    overflow: hidden;
    margin-bottom: 24px;
    ul {
      li {
        display: block;
        width: 50%;
        float: left;
        background-color: $color-alabaster;
        color: $color-gray-dark;
        font-size: $font-root;
        min-height: 40px;
        text-align: center;
        padding: 10px;
        cursor: pointer;
        overflow: hidden;
        font-family: $font-inter-regular;
        &.tab-buy {
          border-radius: 8px;
          font-size: 18px;
          text-transform: uppercase;

          &.active{
            font-family: $font-inter-bold;
            font-weight: 800;
            background-color: $text-color-jade;
            color: $color-white;
          }
        }
        &.tab-sell {
          border-radius: 8px;
          font-size: 18px;
          text-transform: uppercase;

          &.active{
            font-family: $font-inter-bold;
            font-weight: 800;
            background-color: $text-color-red;
            color: $color-white;
          }
        }
      }
    }
  }

  .order-type {
    display:flex;
    gap: 1rem;
    color: $color-gray-alto;
    margin-bottom: 12px;
    border-bottom: 1px solid #E4E7EC;
    padding-bottom: 12px;

    .tab-element {
      font-size: $font-root;
      line-height: 19px;
      font-family: $font-inter-regular;
    }


    div {
      cursor: pointer;

      /* &:first-child {
        gap: 12px;
      } */
      gap: 19px;
      /* width: 100%; */
    }

    .information {
      .content {
        width: 227px;
        height: auto;
        z-index: 1;
        right: 0;
        top: 24px;
        background: rgba(25, 25, 25, 0.8);
        border-radius: 8px;
        padding: 10px;
        color: white;
        display: none;
      }

      .infor-light {
        display: block;
      }
      .infor-dark {
        display: none;
      }

    }

    div#infor:hover div#content-infor {
      display: block;
    }

    .active {
      color: $color-grey-black;
    }
  }

  .right_from_mn {
    display: block;
    overflow: hidden;
    float: none;
    width: 100%;
    color: $color-grey;
    font-size: $font-root;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .percent-spot {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  .input_right_group {
    margin-bottom: 12px;
    width: 100%;

    hr {
      margin-top: 3px;
      margin-bottom: 15px;
      background: $color-alabaster;
      height: 4px;
      border: none;
    }

    &.no-select {
      user-select: none;
    }

    .check_percent {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      margin-right: 0;
      width: 25%;
      border: 1px solid #DFDFDF;
      background-color: transparent;
      text-align: center;
      color: $color-gray-dark;
      font-size: $font-root;
      font-family: $font-inter-regular;

      cursor: pointer;
      border-radius: 50px;
      min-height: 32px;
      &.selected {
         background-color: #01bc8d;

        /* border-color: $color-caribbean-green; */
        color: $color-white;
        font-weight: 600;
      }
      &:hover {
        /* background: $color-caribbean-green;
        border-color: $color-caribbean-green; */
        opacity: .8;
        color: $color-grey-dark;
        font-weight: 600;
      }
    }
    .btn-danger-market {
      min-height: 50px;
      width: 100%;
      border-radius: 50px;
      background-color: $text-color-red;
      text-align: center;
      line-height: 20px;
      padding: 10px;
      font-size: 18px;
      font-family: $font-inter-regular;
      font-weight: 600;
      letter-spacing: 1.1px;
      color: $color-white;
      margin-top: 12px;
      &:hover {
        opacity: 0.8;
      }
    }
    .btn-success-market {
      min-height: 50px;
      width: 100%;
      border-radius: 50px;
      background-color: $text-color-jade;
      text-align: center;
      line-height: 20px;
      padding: 10px;
      font-size: 18px;
      font-family: $font-inter-regular;
      font-weight: 600;
      letter-spacing: 1.1px;
      color: $color-white;
      margin-top: 12px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .group_input_form {
    display: block;
    width: 100%;
    float: left;
  }
  .textAuthen {
    height: 50px;
    background: $color-aqua-green;
    display: flex;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $dark-1;
    line-height: 20px;
    margin-top: 15px;
    font-family: $font-inter-regular;

    span {
        margin-right: 5px;
        margin-left: 5px;
        font-weight: 300;
        font-family: $font-inter-regular;
    }

    a {
      //text-decoration: underline;
      color: #333333;
      font-family: $font-inter-bold;
      text-underline-offset: 4px;
    }
  }

@media screen and (max-width: 992px) {
  .head_form_order ul li.tab-buy {
    font-size: $font-medium-bigger;
  }
  .head_form_order ul li.tab-sell {
    font-size: $font-medium-bigger;
  }
  .order-type .tab-element {
    font-size: $font-root;
  }
  .textAuthen {
    font-size: $font-medium-bigger;
  }
}
</style>

<style lang="scss">
  @import "@/assets/sass/variables";
  #form-input-order-id {
    .coin_input {
      input {
        padding-right: 58px;
        // cursor: not-allowed;
      }
    }
    .trading-block-status {
      color: $color-denim;
    }

    .available-coin {
      display: flex;
      font-size: $font-root;
      line-height: 17px;
      gap: 10px;
      /* justify-content: space-between; */
      align-items: center;
      font-family: $font-inter-regular;
      .title {
        color: $color-gray-dark;
      }

      .value-coin {
        color: $color-grey-dark;
      }
    }
  }
  @media screen and (max-width: 1250px) {
    .head_form_order {
      display: block!important;
    }
  }
</style>