<template>
  <div class="item-tab">
    <data-table3
      ref="datatable"
      :get-data="getData"
      :table-no-data="true"
      id="data-table-landing"
      class="table-landing"
      :msgEmptyData="$t('common.datatable.no_have_data')"
    >
      <template slot="body" slot-scope="props">
        <tr>
          <td
            class="text-left col-pair"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('pair')"
          >
            <span
              v-if="!isSportExchange"
              class="icon-star1"
              :class="
                isFavorite(props.item.coin, props.item.currency)
                  ? 'icon-star2'
                  : 'star_gray'
              "
              @click.stop="
                isFavorite(props.item.coin, props.item.currency)
                  ? removeFavorite(props.item.coin, props.item.currency)
                  : insertFavorite(props.item.coin, props.item.currency)
              "
            ></span>
            <span
              v-else
              class="icon-star2"
              :style="{
                color: isFavorite(props.item.coin, props.item.currency)
                  ? '#FFBF0F'
                  : userThemeMode === 'light-theme'
                  ? '#DEDEDE'
                  : '#6D7387',
              }"
              :class="
                isFavorite(props.item.coin, props.item.currency)
                  ? 'icon-star3'
                  : 'star_gray'
              "
              @click.stop="
                isFavorite(props.item.coin, props.item.currency)
                  ? removeFavorite(props.item.coin, props.item.currency)
                  : insertFavorite(props.item.coin, props.item.currency)
              "
            ></span>
            <span class="number-top">{{ props?.index +1 }}</span>
            <span class="coin"> {{ props.item.coin | currencyName }}</span
            ><span class="currency"
              >/{{ props.item.currency | currencyName }}</span
            >
          </td>
          <td
            class="col-coin"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('coin')"
          >
            {{ props.item.coin | fullName }}
          </td>
          <td
            class="col-last-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.current_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('current_price')"
          >
            <span
              class="change-node"
              :class="calculateLastPriceStatus(props.item)"
            >
              {{
                props.item.current_price
                  | formatCurrencyAmount(props.item.currency, "0")
              }}
            </span>
            <span
              class="is-muted"
              v-if="props.item.currency !== 'usd' && props.item.usdPrice > 0"
            >
              <span v-if="!isSportExchange"
                >/ ${{
                  props.item.usdPrice | formatCurrencyAmount("usd", "0")
                }}</span
              >
            </span>
          </td>
          <td
            class="change-node col-24h-changed"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :class="{
              grown: props.item.changed_percent > 0,
              down: props.item.changed_percent < 0,
              black: props.item.changed_percent == 0,
            }"
            :title="props.item.changed_percent | changedPercent"
            v-if="isVisibleColumn('changed_percent')"
          >
            {{ props.item.changed_percent | changedPercent }}
          </td>
          <td
            class="col-max-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.max_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('max_price')"
          >
            {{
              props.item.max_price
                | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
          <td
            class="col-min-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.min_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('min_price')"
          >
            {{
              props.item.min_price
                | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
          <td
            class="col-volume"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.quote_volume
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('quote_volume')"
          >
            {{ props.item.quote_volume | formatNumberAndDecimal }}
          </td>
        </tr>
      </template>
    </data-table3>
  </div>
</template>

<script>
import FavoritesManager from "@/common/FavoritesManager";
import COMMON_CONST from "@/common/Const";
import BigNumber from "bignumber.js";
import { mapState } from "vuex";
import rf from "@/request/RequestFactory";

const ALL_COLUMNS = [
  "pair",
  "coin",
  "current_price",
  "changed_percent",
  "max_price",
  "min_price",
  "quote_volume",
];

export default {
  components: {},
  props: {
    priceScope: { type: [Object, Array] },
    columns: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    favorites: { type: [Array] },
    marketSelected: { type: String },
    zone: {},
    isSportExchange: { type: Boolean, default: false },
    isSwitchPercentAndVolumn: { type: Boolean, default: false },
    triggerSortAfterUpdateDataOfSocket: {},
  },
  data() {
    return {
      pricesSorted: {},
      FixSortIE: null,
      isSwitch: false,
      marketTop: [],
    };
  },
  created() {
    this.getMarketHot();
  },
  watch: {
    priceScope: {
      handler() {
        this.pricesSorted = this.priceScope;
      },
      immediate: true,
    },
    pricesSorted() {
      if (this.$refs.datatable) {
        this.$refs.datatable.refresh();
      }
    },
    marketTop() {
      if (this.$refs.datatable) {
        this.$refs.datatable.refresh();
      }
    },
    triggerSortAfterUpdateDataOfSocket() {
      if (this.$refs.datatable) {
        this.$refs.datatable.refresh();
      }
    },
  },
  computed: {
    visibleColumns() {
      if (window._.isEmpty(this.columns)) {
        return ALL_COLUMNS;
      }
      return this.columns;
    },
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },

  methods: {
    getMarketHot() {
      rf.getRequest("MarketHot")
        .getMarketTop()
        .then((res) => {
          if (res?.success) this.marketTop = res?.data;
        });
    },

    calculateLastPriceStatus(item) {
      if (!item.current_price || !item.previous_price) {
        return {
          black: true,
        };
      }

      let compareResult = new BigNumber(item.current_price).comparedTo(
        item.previous_price
      );
      return {
        grown: compareResult > 0,
        down: compareResult < 0,
        black: compareResult == 0,
      };
    },

    isVisibleColumn(column) {
      return window._.indexOf(this.visibleColumns, column) > -1;
    },
    isFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      let result = window._.filter(this.favorites, function (value) {
        return value.coin_pair == coinPair;
      });
      return result.length > 0;
    },

    insertFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      FavoritesManager.addFavorite({ coin_pair: coinPair }).then((data) => {
        this.favorites.push(data);
      });
    },

    removeFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      let favoriteObject = window._.find(this.favorites, [
        "coin_pair",
        coinPair,
      ]);

      FavoritesManager.removeFavorite(favoriteObject).then((res) => {
        this.favorites.splice(this.favorites.indexOf(favoriteObject), 1);
        // In the favorites tab, table remove pair which isn't favorited.
        if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
          this.pricesSorted = window._.filter(this.pricesSorted, (item) => {
            const pair = this.getCoinPair(item.coin, item.currency);
            return pair !== coinPair;
          });
        }
      });
    },

    getCoinPair(coin, currency) {
      return `${coin}/${currency}`;
    },

    selectedCurrenyPair(currency, coin) {
      if (!currency) {
        currency = "eth";
      }
      if (!coin) {
        coin = "btc";
      }

      const pair = { currency: currency, coin: coin };
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?currency=${pair.currency}&coin=${pair.coin}`;
      // const newurl = `/spot-exchange/basic?currency=${pair.currency}&coin=${pair.coin}`;

      this.$broadcast("SpotSelectedPairMarket", { ...pair, zone: this.zone });
      // window.history.pushState({ path: newurl }, '', newurl);

      this.$router.replace(
        window.location.pathname +
          `?currency=${pair.currency}&coin=${pair.coin}`
      );
    },

    getData(params) {
      const data = new Promise((resolve) => {
        let pricesSorted = this.pricesSorted;
        const filterKeys = this.marketTop;
        const isObject = _.isObject(pricesSorted) && !_.isArray(pricesSorted);

        let filteredData = []
        if (isObject) {
          // filteredData =  _.pick(pricesSorted, filterKeys);
          filteredData =  _.chain(pricesSorted)
          .pickBy((value, key) => filterKeys.includes(key))
          .values()
          .value();

        } else {
          filteredData =  _.filter(pricesSorted, (item) => {
            const combinedKey = item.currency + "_" + item.coin;
            return filterKeys.includes(combinedKey);
          });
        
        }
        return resolve(filteredData);
      });
      return data
    },
   
  },
  mounted() {
    if (this.isSwitchPercentAndVolumn) {
      const thElement = document.querySelector(
        'th[data-sort-field="quote_volume"]'
      );
      if (thElement) {
        thElement.setAttribute("data-sort-order", "");
        thElement.click();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.icon-arrow-left-right {
  width: 18px;
  height: 18px;
  background-color: var(--text-close);
  -webkit-mask-image: url("@/assets/images/icons/ArrowsLeftRight.svg");
  mask-image: url("@/assets/images/icons/ArrowsLeftRight.svg");
  -webkit-mask-repeat: no-repeat;
}
.number-top{
  margin-left: 5px;
  width: 16px;
  display: inline-block;
}
.item-tab {
  display: block;
  width: 100%;
  .grown {
    color: $text-color-jade !important;
  }
  .down {
    color: $text-color-red !important;
  }
  .table-landing {
    table {
      .icon-star1 {
        display: block;
        float: left;
        width: 15px;
        font-size: $font-medium;
        margin-right: 20px;
        height: 20px;
        float: left;
        color: $color-grey;
        cursor: pointer;
      }
      .icon-star2 {
        color: $color-yellow;
        cursor: pointer;
      }
      .icon-star3 {
        color: $color-yellow;
        cursor: pointer;
      }

      th {
        padding: 25px 10px 10px 0;
      }

      td {
        text-align: left;

        &:last-child {
          text-align: left;
        }
      }
    }
    table tbody tr {
      height: 25px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .col-pair {
    font-family: $font-inter-regular;
    .coin {
      color: var(--text-primary-color);
    }
    .currency {
      color: var(--text-close);
    }
  }

  .is-muted {
    color: $color-grey;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.table-landing > table tr {
  padding: 0 25px;
  cursor: pointer;

  &:first-child {
    margin-top: 54px;
  }
}

.table-landing > table td {
  padding: 10px 10px 10px 0;
  color: $color-grey-dark;
}

.table-landing > table td:last-child {
  padding-right: 25px;
}

.table-landing > table tr td:first-child {
  padding-left: 20px;
}

.table-landing > table th:first-child {
  padding-left: 60px;
}
</style>
