<template>
  <div>

    <div class="head_group_positions">
      <li class="item" @click="showContentPositons = 0" v-bind:class="{'active': showContentPositons === 0}">
        {{ $t('margin.instruments') }} <span class="sub"></span>
      </li>
      <li class="item" @click="showContentPositons = 1" v-bind:class="{'active': showContentPositons === 1}">
        {{ $t('margin.indices') }} <span class="sub"></span>
      </li>
    </div>

    <div class="clearfix"></div>
    
    <div class="content_group_positions">
      <div class="" v-if="showContentPositons === 0">        
        <instruments></instruments>
      </div>
      <div class="" v-if="showContentPositons === 1">
        <indices></indices>
      </div>
    </div>

  </div>
</template>

<script>
  import Instruments from '@/components/marginexchange_pages/exchange/basic/instrumentsAndindices/Instruments.vue';
  import Indices from '@/components/marginexchange_pages/exchange/basic/instrumentsAndindices/Indices.vue';

  export default {
    components: {
      Instruments,
      Indices,
    },
    data() {
      return {
        showContentPositons: 0,
      }
    },
 }
</script>

<style lang="scss" scoped>  
  @import '@/assets/sass/variables';
  .head_group_positions {
    overflow: hidden;
    padding: 5px 15px 0px 15px;
    background-color: #1d2c38;
    margin-bottom: -1px;
    .item {
      display: inline-block;
      float: left;
      height: 35px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      text-align: center;
      line-height: 16px;
      color: $color-grey-sliver;
      font-size: $font-root;
      font-family: $font-family-roboto-medium;
      padding: 11px 16px 5px 16px;
      margin-right: 1px;
      position: relative;
      background-color: transparent;
      cursor: pointer;
      &.active {
        background-color: $color-mirage;
        color: $color-orange;
      }
      &:hover {
        background-color: $color-mirage;
      }
      .sub {
        font-family: $font-family-roboto;
      }
      .icon {
        font-family: $font-family-roboto;
      }
    }
  }
  .content_group_positions {
    border-top: solid 1px $color-bright-gray-dark;
    overflow-y: hidden;
    min-height: 220px;
    >div {
      width: calc(100% - 4px);
      margin: auto;
    } 
  }
</style>