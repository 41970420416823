<template>
  <div id="contact" class="clearfix">
    <div class="tit_page">{{$t('contact.contact_us')}}</div>
    <!-- <div class="page1200"> -->
    <div class="left-content">
      <div class="question">
        <div class="text-q1">{{$t('contact.got_question')}}</div>
        <div class="text-q2">{{$t('contact.please_check')}}</div>
      </div>
      <div class="btn_submit">
        <!-- <button @click="goSiteSupportPage">{{$t('contact.view_suport')}}</button> -->
        <button>{{btn_text}}</button>
      </div>
      <div class="text">
        {{$t('contact.please')}}
        <a
          href="#"
        >{{$t('contact.submit_request')}}</a>
      </div>
    </div>
    <div class="right-content">
      <p class="join_text">{{$t('contact.non_account_related')}}</p>
      <div class="footer" style="vertical-align:bottom">
        <!-- <i class="image icon-facebook"></i>
        <i class="image icon-twitter"></i>
        <i class="image icon-linkedin"></i>-->
        <!-- <i class="image icon-info"></i> -->
        <a
          :key="socicalNetwork.link"
          v-for="socicalNetwork in socicalNetworks"
          :href="socicalNetwork.link"
          :title="socicalNetwork.name"
          target="_blank"
          class="image"
        >
          <i :class="socicalNetwork.icon_class"></i>
        </a>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import {mapState} from 'vuex'

export default {
  data() {
    return {
      btn_text: this.$i18n.t("contact.view_suport").toUpperCase(),
      siteSetting: {},
      socicalNetworks: {}
    };
  },
  computed: {
    ...mapState({
      masterdata: state => state.masterdata
    })
  },
  methods: {
    convertSiteSetting(data) {
      window._.each(data, item => {
        this.siteSetting[item.key] = item.value;
      });
    },
    handleMasterData() {
      this.convertSiteSetting(this.masterdata.settings);
      this.socicalNetworks =
        window._.filter(
          this.masterdata.social_networks,
          item => item.is_active
        ) || {};
    }
  },
  mounted() {
    document.title = this.$t("menu.contact") + ` – ${this.tabTitle}`;
    this.handleMasterData();
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
#contact {
  background-color: $color-alabaster;
  width: 100%;
  padding-bottom: 100px;

  .tit_page {
    font-family: $font-family-roboto-medium;
    font-size: $font-title-size-medium-small;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-bottom: 25px;
    color: $color-eden-light;
  }

  .left-content {
    display: inline-block;
    width: 35.9%;
    float: left;
    padding-right: 0px;
    .question {
      width: 100%;
      color: $color-grey-dusty;
      .text-q1 {
        font-family: $font-family-roboto-medium;
        font-size: $font-medium-bigger;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-black;
        margin-bottom: 15px;
      }
      .text-q2 {
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.77;
        letter-spacing: normal;
        color: $color-grey-dusty;
      }
    }
    .btn_submit {
      margin-top: 24px;
      text-align: center;
      button {
        width: 100%;
        height: 40px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.42;
        letter-spacing: 0.9px;
        text-align: center;
        padding: 0 100px;
        background-color: $color-jungle-green;
        color: $color-white;
        &:hover {
          background-color: $color-blue-custom;
        }
      }
    }
  }
  .right-content {
    display: inline-block;
    width: 51.3%;
    height: 180px;
    border-radius: 3px;
    float: right;
    background-color: $color-bright-gray;
    .join_text {
      height: 46px;
      width: 352px;
      font-family: $font-family-roboto;
      font-size: $font-root;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: center;
      color: $color-grey-dark;
      margin-top: 28px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .text {
    font-family: $font-family-roboto;
    font-size: $font-small;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.92;
    letter-spacing: normal;
    margin-top: 15px;
    text-align: center;
    color: $color-grey-dusty;
    a {
      font-family: Roboto;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.92;
      letter-spacing: normal;
      margin-left: 0;
      color: $color-blue-custom;
    }
    &.join_text {
      padding-top: 40px;
    }
  }

  .footer {
    padding-top: 10px;
    padding-bottom: 20px;
    color: $color-grey-dusty;
    text-align: center;

    .image {
      width: 12%;
      display: inline-block;
      font-size: $font-title-size-medium;
      color: $color-grey;
      img {
        height: 30px;
      }
      &:hover {
        color: $color-jungle-green;
      }
    }
  }
}
</style>