import BaseModelRequest from "./base/BaseModelRequest";

export default class UserRequest extends BaseModelRequest {
  getModelName() {
    return "users";
  }

  preVerifyCaptcha() {
    return this.get("/captcha/pre-verify");
  }

  login(
    email,
    password,
    otp = "",
    geetestData = {},
    is_ggcaptcha,
    redirectUrl
  ) {
    let params = {
      grant_type: "password",
      client_id: process.env.VUE_APP_MIX_CLIENT_ID,
      client_secret: process.env.VUE_APP_MIX_CLIENT_SECRET,
      username: email,
      password: password,
      scope: "*",
      otp: otp,
      geetestData: geetestData,
      is_ggcaptcha: is_ggcaptcha,
      redirectUrl: redirectUrl,
    };
    return this.post("/oauth/token", params);
  }

  register(
    { email, password, passwordConfirmation, referralCode = "" },
    geetestData = {},
    is_ggcaptcha
  ) {
    let params = {
      email: email,
      password: password,
      password_confirmation: passwordConfirmation,
      agree_term: 1,
      referrer_code: referralCode,
      geetestData: geetestData,
      is_ggcaptcha: is_ggcaptcha,
    };
    return this.post("/users", params);
  }

  confirm(code) {
    return this.post("/confirm-email", { code });
  }

  confirmResetPassword(token) {
    return this.post("/confirm-token", { token });
  }
  resendConfirm({ email }) {
    let params = {
      email: email,
    };
    return this.post("/reconfirm-email", { params });
  }

  resetPassword(email) {
    return this.post("/reset-password", { email });
  }

  executeResetPassword({ email, password, passwordConfirmation, token }) {
    let params = {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    };
    return this.post("/execute-reset-password", params);
  }

  getCurrentUser(useCache = true, params) {
    if (this.user && useCache) {
      return new Promise((resolve, reject) => {
        resolve(this.user);
      });
    }

    return new Promise((resolve, reject) => {
      let url = "/user";
      var self = this;
      this.get(url, params)
        .then(function (user) {
          self.user = user;
          resolve(user);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  getBalance(useCache = true, store) {
    if (useCache && window.GlobalSocket) {
      if (window.GlobalSocket.balance) {
        return new Promise((resolve, reject) => {
          const balance = store
            ? window.GlobalSocket.balance.data[store]
            : window.GlobalSocket.balance.data;
          resolve({ success: true, data: balance });
        });
      }
    }

    return new Promise((resolve, reject) => {
      let url = "/balances";
      var self = this;
      this.get(url)
        .then(function (res) {
          window.GlobalSocket.balance = res;
          const balance = store
            ? window.GlobalSocket.balance.data[store]
            : window.GlobalSocket.balance.data;
          resolve({ success: true, data: balance });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  getMainBalance() {
    let url = "/balances";
    return this.get(url);
  }

  getFuturesBalances(params) {
    let url = "/balance";
    return this.getFutures(url, params);
  }

  convertSmallBalance(params) {
    return new Promise((resolve, reject) => {
      let url = "/convert-small-balance";
      var self = this;
      this.post(url, params)
        .then(function (res) {
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  transferBalance(params) {
    return new Promise((resolve, reject) => {
      let url = "/transfer-balance";
      var self = this;
      return this.post(url, params)
        .then(function (res) {
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  getTotalSupply() {
    let url = "/total-supply";
    return this.get(url);
  }

  getReferralSetting(params) {
    let url = "/setting-referral";
    return this.get(url, params);
  }

  getInstruments() {
    let url = "/instruments";
    return this.getFutures(url);
  }

  getLinkPnlChart(params) {
    let url = "/create-pnl-token";
    return this.post(url, params);
  }

  getDetailsBalance(currency, store) {
    let url = "/balance/" + currency;
    return this.get(url, { store });
  }

  getDetailsUsdBalance(currency, store) {
    let url = "/balance-usd/" + currency;
    return this.get(url, { store });
  }

  getSecuritySettings(params) {
    let url = "/security-settings";
    return this.get(url, params);
  }

  createUserQrcode(params) {
    let url = "/create-user-qrcode";
    return this.post(url, params);
  }

  registerBetaTester(params) {
    let url = "/register-beta-tester";
    return this.post(url, params);
  }

  getUserNotificationSettings(params) {
    let url = "/user-notification-settings";
    return this.get(url, params);
  }

  getUserSettings(params) {
    let url = "/user-settings";
    return this.get(url, params);
  }

  getUserKyc(params) {
    let url = "/user-kyc";
    return this.get(url, params);
  }

  updateUserKyc(params) {
    let url = "/user-kyc";
    return this.post(url, params);
  }

  getTopUserReferralCommission() {
    let url = "/top-user-referral-commission";
    return this.get(url);
  }

  getUserReferralCommission(params) {
    let url = "/user-referral-commission";
    return this.get(url, params);
  }
  activeWhitelist(params) {
    let url = "/user-whitelist";
    return this.put(url, params);
  }

  activeLeaderBoard(params) {
    let url = "/user/use-fake-name";
    return this.put(url, params);
  }

  getOrderBookSettings(params) {
    let url = "/order-book-settings";
    return this.get(url, params);
  }

  getUserReferralFriends(params) {
    let url = "/get-user-referral-friends";
    return this.get(url, params);
  }

  getAllReferrer(params) {
    let url = "/get-all-referrer";
    return this.get(url, params);
  }

  getTotalReferrer(params) {
    let url = "/get-total";
    return this.get(url, params);
  }

  updateOrderBookSettings(params) {
    let url = "/order-book-settings";
    return this.put(url, params);
  }

  getDeviceRegister() {
    let url = "/devices";
    return this.get(url);
  }

  getUserDevice(params) {
    let url = "/user-devices";
    return this.get(url, params);
  }

  updateRestrictMode(params) {
    let url = "/restrict-mode";
    return this.put(url, params);
  }

  deleteDevice(id) {
    let url = "/device/" + id;
    return this.del(url);
  }

  grantAccessDevice(id) {
    let url = "/device/" + id;
    return this.post(url);
  }

  getHistoryConnection(params) {
    let url = "/connections";
    return this.get(url, params);
  }

  getQRCodeGoogleUrl() {
    let url = "/key-google-authen";
    return this.get(url);
  }

  addSecuritySettingOtp(authentication_code) {
    let url = "/add-security-setting-otp";
    return this.put(url, authentication_code);
  }

  verify(params) {
    let url = "/verify-google-authenticator";
    return this.get(url, params);
  }

  getWithdrawDaily(currency) {
    let url = "/transactions/withdraw-daily";
    return this.get(url, { currency: currency });
  }

  getUsdWithdrawDaily(currency) {
    let url = "/transactions/withdraw-daily-usd";
    return this.get(url);
  }

  getWithdrawalAddresses(params) {
    let url = "/withdrawal-address";
    return this.get(url, params);
  }

  getWithdrawalsAddresses(params) {
    let url = "/withdrawals-address";
    return this.get(url, params);
  }

  updateOrCreateWithdrawalAddress(params) {
    let url = "/withdrawal-address";
    return this.post(url, params);
  }
  getWithdrawalsNetworks(params) {
    let url = "/withdrawal-networks";
    return this.get(url, params);
  }
  getDepositNetworks(params) {
    let url = "/deposit-networks";
    return this.get(url, params);
  }

  generateDepositAddress(params) {
    let url = "/deposit-address";
    return this.put(url, params);
  }

  getDepositAddress(params) {
    let url = "/deposit-address";
    return this.get(url, params);
  }

  changePassword(params) {
    let url = "/change-password";
    return this.put(url, params);
  }

  delGoogleAuth(params) {
    let url = "/google-auth";
    return this.del(url, params);
  }

  delRecoveryCodeWithAuth(params) {
    let url = "/del-recovery-code-with-auth";
    return this.del(url, params);
  }

  setUserLocale(params) {
    let url = "/locale";
    return this.put(url, params);
  }

  getInfoToAuthenticate() {
    let url = "/phone_verification_data";
    return this.get(url);
  }

  verifyBankAccount(params) {
    let url = "/bank-account";
    return this.put(url, params);
  }

  sendSmsOtp() {
    let url = "/send-sms-otp";
    return this.post(url);
  }

  disableOtpAuthentication(params) {
    let url = "/disable-otp-authentication";
    return this.del(url, params);
  }

  getWithdrawalLimitBTC(params) {
    let url = "/withdrawal-limit";
    return this.get(url, params);
  }

  otpVerify(params) {
    let url = "/otp-verify";
    return this.post(url, params);
  }

  createIdentity(params) {
    let url = "/create-identity";
    return this.post(url, params);
  }

  updateBankAccountStatus(params) {
    let url = "/update-status-user-kyc";
    return this.post(url, params);
  }

  downloadReportFriend(params, fileName) {
    const url = "/referral/friends/export";
    this.download(url, params, fileName);
  }

  downloadReportCommission(params, fileName) {
    const url = "/referral/commission/export";
    this.download(url, params, fileName);
  }

  changeTelegramNotification(params) {
    let url = "/change-telegram-notification";
    return this.post(url, params);
  }

  changeEmailNotification(params) {
    let url = "/change-email-notification";
    return this.post(url, params);
  }

  changeLineNotification(params) {
    let url = "/change-line-notification";
    return this.post(url, params);
  }

  encryptId(params) {
    let url = "/encrypt-id";
    return this.post(url, params);
  }

  sendNotify(message) {
    return this.post("/send-notification", { message });
  }
  changeAmlPay(params) {
    let url = "/change-aml-pay";
    return this.post(url, params);
  }

  getSalesPointBalance(useCache = true) {
    if (useCache && window.GlobalSocket) {
      if (window.GlobalSocket.balance) {
        return new Promise((resolve, reject) => {
          resolve(window.GlobalSocket.balance);
        });
      }
    }

    return new Promise((resolve, reject) => {
      let url = "/balances";
      var self = this;
      this.get(url)
        .then(function (res) {
          window.GlobalSocket.balance = res;
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  getUserWithdrawalSetting(params) {
    let url = "/user-withdrawal-setting";
    return this.get(url, params);
  }

  getUserApiSettings(params) {
    let url = "/hmac-tokens";
    return this.get(url, params);
  }

  getScopesAPISetting() {
    let url = "/list-scopes";
    return this.get(url);
  }

  updateScopesAPI(params, apiKey) {
    let url = `/hmac-tokens/${apiKey}`;
    return this.put(url, params);
  }

  createUserApiSettings(params) {
    let url = "/hmac-tokens/create";
    return this.get(url, params);
  }

  updateUserApiSettings(params) {
    const { id } = params;
    let url = "/hmac-tokens/" + id;
    return this.put(url, params);
  }

  deleteOneApiSettings(params) {
    const { id, otp } = params;
    let url = "/hmac-tokens/" + id + "?otp=" + otp;
    return this.del(url, params);
  }

  deleteAllApiSettings(params) {
    const { otp } = params;
    let url = "/hmac-tokens/all?otp=" + otp;
    return this.del(url, params);
  }

  getUserPairTradingSetting(params) {
    let url = "/user-pair-trading-setting";
    return this.get(url, params);
  }
  changeTypeWalletAmalPayFee(params) {
    let url = "/change-wallet-amal-fee";
    return this.get(url, params);
  }
  getDividendSetting() {
    let url = "/get-dividend-settings";
    return this.get(url);
  }
  changeAnimationStatus(params) {
    let url = "/margin/animation";
    return this.post(url, params);
  }
  getAnimationStatus() {
    let url = "/margin/animation";
    return this.get(url);
  }

  onUpdateFakeName(data) {
    let url = "/user-update-fake-name";
    return this.put(url, data);
  }

  validateBlockchainAddress(currency, blockchain_address, network_id) {
    const url = `/validate-blockchain-address?currency=${currency}&blockchain_address=${blockchain_address}&network_id=${network_id}`;
    return this.get(url);
  }
  getListVoucher(
    type = "",
    status = "",
    expires_date = "",
    limit = 8,
    page = ""
  ) {
    const url = `/vouchers/get-list-voucher?type=${type}&status=${status}&expires_date=${expires_date}&limit=${limit}&page=${page}`;
    return this.get(url);
  }
  claimVoucher(params) {
    const url = "/vouchers/claim";
    return this.post(url, params);
  }

  claimVoucherFuture(params) {
    const url = "/vouchers/claim/future";
    return this.post(url, params);
  }

  createAntiPhishing(params) {
    const url = "/user/anti-phishing";
    return this.post(url, params);
  }

  verifyAntiPhishing(code) {
    const url = `/verify-anti-phishing/${code}`;
    return this.post(url);
  }

  reconfirmEmailAntiPhishing(params) {
    const url = `/reconfirm-email-anti-phishing`;
    return this.post(url, params);
  }

  accessToken(data) {
    const url = `/access-token`;
    return this.postFutures(url, data);
  }

  walletFutureAsset() {
    const url = `/balance/assets`;
    return this.getFutures(url);
  }

  withdrawFutureBalance(data) {
    const url = `/account/withdraw`;
    return this.postFutures(url, data);
  }

  transferFutureBalance(data) {
    const url = `/transfer`;
    return this.post(url, data);
  }

  getOpenOrderMargin(data) {
    const url = "/order/open";
    return this.postFutures(url, data);
  }

  getQRGenerate(params) {
    const url = "/qr-code/generate";
    return this.get(url, params);
  }

  getCheckQR(data) {
    const url = "/qr-code/check";
    return this.post(url, data);
  }
  calculatePNL() {
    const url = "/pnl";
    return this.get(url);
  }

  
  getListInquiries(params) {
    let url = '/inquiries';
    return this.get(url, params);
  }
  getListInquiriesType() {
    let url = '/inquiry/type';
    return this.get(url);
  }

  createInquirity(data) {
    const url = '/inquiries'
    return this.post(url, data)
  }
  detailInquirity(id) {
    const url = `/inquiry/${id}`
    return this.get(url)
  }

  getListCategories() {
    let url = '/faq/categories';
    return this.get(url);
  }
  getDetailFaq(params) {
    let url = '/faq/faqs';
    return this.get(url,params);
  }
  
  getKrwSetting() {
    let url = '/krw/setting';
    return this.get(url);
  }

  getKrwBankAccounts() {
    let url = '/krw/bank-accounts';
    return this.get(url);
  }
  getKrwBankName() {
    let url = '/krw/bank-names';
    return this.get(url);
  }
  depositKrw(params) {
    let url = '/krw/deposit';
    return this.post(url, params);
  }
  withdrawKrw(params) {
    let url = '/krw/withdraw';
    return this.post(url, params);
  }

  getTransactionsKRWHistory(params, type) {
    let url = '/krw/transactions';
    if (type) {
      url += '/' + type;
    }
    return this.get(url, params);
  }

}
