import BaseRequest from './base/BaseRequest';

export default class MarginRequest extends BaseRequest {

  getIndicator (params) {
    let url = '/margin/indicator';
    return this.get(url, params);
  }

  updateToCross (params) {
    let url = '/margin/update-to-cross';
    return this.post(url, params);
  }

  // Begin Beta Tester
  getContractSetting (params) {
    let url = '/margin/contract-setting';
    return this.get(url, params);
  }

  getTradingSetting (params) {
    let url = '/margin/trading-setting';
    return this.get(url, params);
  }

  registerBetaTester (params) {
    let url = '/margin/register-beta-tester';
    return this.post(url, params);
  }
  // End Beta Tester

  getFunding(params) {
    let url = '/margin/funding';
    return this.get(url, params);
  }
  exportFundingCsv(params, fileName = 'Funding.csv') {
    let url = '/margin/funding-export';
    return this.download(url, params, fileName);
  }
  getInsurance(params) {
    let url = '/margin/insurance';
    return this.get(url, params);
  }
  exportInsuranceCsv(params, filename = 'Insurance-History.csv') {
    let url = '/margin/insurance-export';
    return this.download(url, params, filename);
  }

  getSettlement(params) {
    let url = '/margin/settlement';
    return this.get(url, params);
  }

  exportSettlementCsv(params, fileName = 'Settlement-History.csv') {
    let url = '/margin/settlement-export';
    return this.download(url, params, fileName);
  }

  getInstrument(params) {
    let url = '/margin/instrument/all';
    return this.get(url, params);
  }
  
  getInstrumentSymbol(params, symbol) {
    let url = `/margin/instrument/${symbol}`;
    return this.get(url, params);
  }
  
  getActiveInstrument(params) {
    let url = '/margin/instrument/active';
    return this.get(url, params);
  }

  getCompositeIndex(params) {
    let url = '/margin/instrument/composite-index';
    return this.get(url, params);
  }

  getIndiceBySymbol(params, symbol) {
    let url = '/margin/instrument/indices/' + symbol;
    return this.get(url, params);
  }

  getActiveIndices(params) {
    let url = '/margin/instrument/indices-active';
    return this.get(url, params);
  }

  getIndexRequest(params) {
    let url = '/margin/instrument/index';
    return this.get(url, params);
  }

  getIndexCompositeRequest(params, symbol) {
    let url = '/margin/instrument/composite_indices/'+symbol;
    return this.get(url, params);
  }

  getListRiskLimit(params) {
    let url = '/margin/instrument/risk-limit-list';
    return this.get(url, params);
  }

  getPositions(params) {
    let url = '/margin/positions';
    return this.get(url, params);
  }

  updateLeverage(params) {
    let url = '/margin/update-leverage';
    return this.post(url, params);
  }

  updateMargin (params) {
    let url = '/margin/update-margin';
    return this.post(url, params);
  }

  updateRiskLimit (params) {
    let url = '/margin/update-risk-limit';
    return this.post(url, params);
  }

  getChartBars(params) {
    let url = '/margin/chart/bars';
    return this.get(url, params);
  }

  getPnLChartBars(params) {
    let url = '/margin/pnl-chart/bars';
    return this.get(url, params);
  }

  getIndiceChartBars(params) {
    let url = '/margin/indice-chart/bars';
    return this.get(url, params);
  }

  getBalance(params) {
    let url = '/margin/balance';
    return this.get(url, params);
  }


  /* Margin Order Book */

  getOrderbook(params) {
    let url = '/margin/orderbook';
    return this.get(url, params);
  }

  /* Margin Order */

  createOrder(params) {
    let url = '/margin/order/create';
    return this.post(url, params);
  }

  getActiveOrder(params) {
    let url = '/margin/order/get-active';
    return this.get(url, params);
  }

  getStopOrders(params) {
    let url = '/margin/order/stops';
    return this.get(url, params);
  }

  getFilledOrders(params) {
    let url = '/margin/order/fills';
    return this.get(url, params);
  }

  getAllOrders(params) {
    let url = '/margin/order';
    return this.get(url, params);
  }

  cancelActiveOrder(params) {
    let url = '/margin/order/cancel-active-order';
    return this.post(url, params);
  }
  
  exportOrderCSV(params, fileName) {
    let url = '/margin/order/export';
    this.download(url, params, fileName);
  }

  /* Margin Trade */

  getRecentTrades(params) {
    let url = '/margin/trade/recent';
    return this.get(url, params);
  }

  getTradeList(params) {
    let url = '/margin/trade';
    return this.get(url, params);
  }

  createMasterOrder(params) {
    let url = '/mam/master-orders';
    return this.post(url, params);
  }

  createSubOrder(params) {
    let url = '/mam/master-orders/create-sub-order';
    return this.post(url, params);
  }
  
  exportTradeCSV(params, fileName) {
    let url = '/margin/trade/export';
    this.download(url, params, fileName);
  }
  exportTopLeader(params) {
    let url = '/margin/leaderboard';
    return this.get(url, params);
  }

  getTopLeaderEntry(params){
    let url = '/margin/top-entry-leaderboard';
    return this.get(url, params);
  }

  getPosition(params) {
    let url = '/positions';
    return this.getFutures(url, params)
  }

}
