<template>
  <div id="account_container">
    <!-- <div id="account-sitebar" :class="{'hideLeftMenu': sizeScreen < 1300}">
      <AccountSiteBar :is-open="isOpen" />
    </div> -->
    <div v-if="sizeScreen < 1300" :class="isOpen ? 'activeMenu' : ''"></div>

    <div
      id="account-sitebar"
      v-click-outside="clickOutside"
      :class="{
        tab_menu: sizeScreen < 1300,
        hideLeftMenu: !isOpen && sizeScreen < 1300,
      }"
    >
      <AccountSiteBar :is-open="isOpen" />
    </div>
    <div id="acount_content" :class="{ overScreen: sizeScreen < 1300 }">
      <div class="wrapper-tab-menu" :class="{ hide: sizeScreen >= 1300 }">
        <div class="btn-tab-menu" @click="isOpen = !isOpen">
          <img width="16px" src="@/assets/images/tab_menu.png" />
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>
<script>
import AccountSiteBar from "@/components/shared_components/common/AccountSiteBar.vue";
export default {
  data() {
    return {
      sizeScreen: window.innerWidth,
      isOpen: false,
    };
  },
  components: {
    AccountSiteBar,
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
      this.isOpen = false;
    },
    clickOutside(event) {
      if (event.target.className !== "btn-tab-menu") {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
#account_container {
  display: flex;
  height: 100%;
  #account-sitebar {
    // max-width: 340px;
    background-color: var(--background-color-primary);
    border-right: solid 1px var(--bg-line);
  }
  #acount_content {
    flex: 1%;
    background-color: var(--background-home-page);
    width: 75%;
  }
  .overScreen {
    width: 100%;
  }
  .hideLeftMenu {
    display: none;
  }
}
</style>
