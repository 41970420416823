<template>
  <div id="mam-funds" class="page-container">
    <funds-spot-ex :isSinglePage="false" ref="balancesComponent" />
  </div>
</template>

<script>
import FundsSpotEx from "@/pages/funds/FundsSpotEx.vue";

export default {
  components: {
    FundsSpotEx,
  },
  methods: {
    hideSmallPair(hideBalances) {
      this.$refs.balancesComponent.hideBalances = hideBalances;
      this.$refs.balancesComponent.refresh();
    },
  },
};
</script>
