<template>
    <div :id="containerId" class="trading-view-chart"></div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import TimezoneUtils from '@/common/TimezoneUtils';
  import LocalStorageUtils from '@/common/LocalStorageUtils';
  import Const from '@/common/Const';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
    },
    props: {
      multipleChart   : { type: Boolean, default: false },
      symbol          : { type: String },
      resolution      : { default: '15', type: String },
      minTick         : { default: 'default', type: String },
    },
    data() {
      var dataFeed           = new Datafeeds.UDFCompatibleDatafeed(process.env.VUE_APP_API_URL + "/api/v1/margin/chart");
      dataFeed.getBars       = this.getBars.bind(this);
      dataFeed.subscribeBars = this.subscribeBars.bind(this);
      return {
        tradingViewChart   : null,
        isChartReady: false,
        realtimeDatafeed   : dataFeed,
        lastBar            : {},
        onRealtimeCallback : {},
        indexStudyId: '',
        disableByBetaTester: false,
      }
    },
    computed: {
      ...mapGetters({ instrument: 'currentInstrument' }),
      containerId() {
        return 'trading-view-chart';
      }
    },
    methods: {
      subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        this.onRealtimeCallback[symbolInfo.name] = onRealtimeCallback;
      },
      fillDataBars(bars, disableByBetaTester) {
        bars = window._.forEach(bars, function(bar) {
          bar.time = parseInt(bar.time);
          bar.open   = disableByBetaTester == true ? 0 : parseFloat(bar.open);
          bar.close  = disableByBetaTester == true ? 0 : parseFloat(bar.close);
          bar.high   = disableByBetaTester == true ? 0 : parseFloat(bar.high);
          bar.low    = disableByBetaTester == true ? 0 : parseFloat(bar.low);
          bar.volume = disableByBetaTester == true ? 0 : parseFloat(bar.volume);
        });
        return bars;
      },
      getBars (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        // console.log('test tradingview chart', symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest)
        this.$emit('update:resolution', this.resolution);
        let symbol      = symbolInfo.ticker;
        let params = {
          symbol     : symbol,
          resolution : this.resolutionToMilliseconds(resolution),
          from       : from,
          to         : to
        };
        let self = this;
        rf.getRequest('MarginRequest').getChartBars(params).then(bars => {
          let deniedUser = this.disableByBetaTester;
          bars = this.fillDataBars(bars, deniedUser);
          // bars = window._.forEach(bars, function(bar) {
          //   bar.time = parseInt(bar.time);
          //   bar.open   = parseFloat(bar.open);
          //   bar.close  = parseFloat(bar.close);
          //   bar.high   = 77;//parseFloat(bar.high);
          //   bar.low    = 3;//parseFloat(bar.low);
          //   bar.volume = 1;//parseFloat(bar.volume);
          // });
          if (bars.length) {
            let currentTime = Math.floor(Date.now() / resolution) * resolution;
            let lastBar = bars[bars.length - 1];
            if (lastBar.time > currentTime) {
                bars.pop();
            }
          }
          if (bars.length && !self.lastBar[symbol]) {
            self.lastBar[symbol] = bars[bars.length - 1];
          }
          onHistoryCallback(bars, {noData: (bars.length == 0)});
          this.createEmptyBarIfNeeded(symbol);
        });
      },
      getEventHandlers() {
        return {
          BetaTesterHideData: this.onBetaTesterHideData,
          BetaTesterShowData: this.onBetaTesterShowData,
        }
      },

      onBetaTesterHideData(data) {
        // Only when the chart is showing,
        // then set hide and re-build chart
        if (!this.disableByBetaTester) {
          this.disableByBetaTester = true;
          setTimeout(() => {
            this.createChart();
          }, 1000);
        }
      },

      onBetaTesterShowData(data) {
        // Only when the chart is hidden,
        // then set show and re-build chart
        if (this.disableByBetaTester) {
          this.disableByBetaTester = false;
          setTimeout(() => {
            this.createChart();
          }, 1000);
        }
      },

      getSocketEventHandlers() {
        return {
          MarginTradesCreated: this.onMarginTradesCreated,
          MarginIndexUpdated: this.onMarginIndexUpdated,
        };
      },
      onMarginTradesCreated(data) {
        if (data.length === 0 || this.symbol !== data[0].instrument_symbol) {
          return;
        }
        for (let trade of data) {
            this.onMarginTradeCreated(trade.instrument_symbol, trade.price, trade.quantity, trade.created_at);
        }
      },
      onMarginIndexUpdated(data) {
        if (data.symbol === this.instrument.reference_index) {
          this.onMarginTradeCreated(data.symbol, data.value, 0, Date.now());
        }
      },
      onMarginTradeCreated(symbol, price, quantity, createdAt) {
        price           = parseFloat(price);
        let volume      = parseFloat(quantity);
        let resolution  = this.resolutionToMilliseconds(this.resolution);
        let time        = Math.floor(createdAt/resolution) * resolution;
        let existingBar = this.lastBar[symbol];
        if (this.lastBar[symbol]) {
          if (time == this.lastBar[symbol].time) {
            existingBar.volume += volume;
            existingBar.high   = Math.max(price, existingBar.high);
            existingBar.low    = Math.min(price, existingBar.low);
            existingBar.close  = price;
          } else {
            existingBar = {
              "time"   : time,
              "high"   : Math.max(price, existingBar.close),
              "low"    : Math.min(price, existingBar.close),
              "open"   : existingBar.close,
              "close"  : price,
              "volume" : volume,
            };
          }
        } else {
          // existingBar = {
          //   "time"   : time,
          //   "high"   : price,
          //   "low"    : price,
          //   "open"   : price,
          //   "close"  : price,
          //   "volume" : volume,
          // };
          return;
        }
        this.lastBar[symbol] = existingBar;
        if (this.onRealtimeCallback[symbol]) {
          this.onRealtimeCallback[symbol](existingBar);
        }
      },
      createEmptyBarIfNeeded(symbol) {
        let createdAt   = Date.now();
        let resolution  = this.resolutionToMilliseconds(this.resolution);
        let time        = Math.floor(createdAt / resolution) * resolution;
        if (symbol === this.instrument.reference_index) {
          time = Math.ceil(createdAt / resolution) * resolution;
        }

        if (this.lastBar[symbol] && time > this.lastBar[symbol].time) {
          let price = this.lastBar[symbol].close;
          this.lastBar[symbol] = {
            "time"   : time,
            "high"   : price,
            "low"    : price,
            "open"   : price,
            "close"  : price,
            "volume" : 1,
          };
          if (this.onRealtimeCallback[symbol]) {
            this.onRealtimeCallback[symbol](this.lastBar[symbol]);
          }
        }
      },
      resolutionToMilliseconds(resolution) {
        var minutes = parseInt(resolution);
        // CHARTING_RESOLUTION_MULTIPLICITY : {'D': 1440, 'W': 10080, 'M': 43200},
        if (isNaN(minutes)) {
          minutes = Const.CHARTING_RESOLUTION_MULTIPLICITY[resolution];
        }
        return minutes * 60 * 1000;
      },
      createChart: function() {
        this.isChartReady = false;
        let disabled_features = [
            "use_localstorage_for_settings",
            // "left_toolbar", 
            "context_menus",
            "display_market_status",
            "timeframes_toolbar",
            "header_compare",
            "header_undo_redo",
            "header_saveload",
            "header_screenshot",
            "header_interval_dialog_button",
            "volume_force_overlay",
            "control_bar",
            "border_around_the_chart",
            "header_widget",
            "header_symbol_search",
            "chart_property_page_scales"
          ];
        this.tradingViewChart = new TradingView.widget({
          symbol                     : this.symbol,// window._.get(this, "symbol", 'BTCUSD'),
          interval                   : this.resolution,
          locale                     : window.i18n.locale,
          timezone                   : TimezoneUtils.getTimezone(),
          container_id               : this.containerId,
          datafeed                   : this.realtimeDatafeed,
          library_path               : window.location.protocol + '//' + window.location.host + "/tradingview/charting_library/",
          autosize                   : true,
          withdateranges             : false,
          client_id                  : 'amanpuri',
          user_id                    : 'public_user_id',
          save_image                 : false,
          drawings_access            : { type: 'black', tools: [ { name: "Regression Trend" } ] },
          disabled_features          : disabled_features,
          indicators_file_name       : '/js/indexes.js',
          custom_css_url             : 'tradingview.css',
          enabled_features: [
            "dont_show_boolean_study_arguments",
            "hide_last_na_study_output",
          ],
          overrides: {
            "paneProperties.topMargin"                             : 15,
            "scalesProperties.showStudyLastValue"                  : true,
            "paneProperties.background"                            : "#191F2B",
            "mainSeriesProperties.candleStyle.upColor"             : "#299b82",
            "mainSeriesProperties.candleStyle.downColor"           : "#f74940",
            "mainSeriesProperties.candleStyle.drawWick"            : true,
            "mainSeriesProperties.candleStyle.drawBorder"          : true,
            "mainSeriesProperties.candleStyle.borderColor"         : "#299b82",
            "mainSeriesProperties.candleStyle.borderUpColor"       : "#299b82",
            "mainSeriesProperties.candleStyle.borderDownColor"     : "#f74940",
            "mainSeriesProperties.candleStyle.wickUpColor"         : '#299b82',
            "mainSeriesProperties.candleStyle.wickDownColor"       : '#f74940',
            "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
            "mainSeriesProperties.minTick"                         : this.minTick,
            "paneProperties.vertGridProperties.color"              : "#2B3038",
            "paneProperties.horzGridProperties.color"              : "#2B3038",
            "scalesProperties.lineColor"                           : "#3C4049"
          },
          studies_overrides: {
            "volume.volume.color.0": "rgba(247, 73, 64, 0.19)",
            "volume.volume.color.1": "rgba(41, 155, 130, 0.2)",
            "volume.volume.transparency": 15,
            "volume.volume ma.color": "#f74940",
            "volume.volume ma.transparency": 0,
            "volume.volume ma.linewidth": 1,
            "volume.volume ma.plottype": 'line',
            "volume.show ma": true,
          }
        });
        var self = this;
        this.tradingViewChart.onChartReady(function() {
          self.isChartReady = true;
          if (!self.reloadTrandingChart()) {
            self.tradingViewChart.chart().createStudy('Moving Average', false, false, [20], null, {'Plot.linewidth': 1.3, 'Plot.color': '#0090eb'});
            self.tradingViewChart.chart().createStudy('Moving Average', false, false, [40], null, {'Plot.linewidth': 1.3, 'Plot.color': '#2dac91'});
            self.changeChartStudy();
          }
          self.watchMinTick(self.minTick);
          self.$watch('minTick', self.watchMinTick);
        });
        
      },
      watchMinTick(newMinTick) {
        const overrides = Object.assign({}, this.tradingViewChart.options.overrides);
        overrides['mainSeriesProperties.minTick'] = newMinTick;
        this.tradingViewChart.applyOverrides(overrides);
      },
      showIndicatorDialog() {
        if (!window._.isEmpty(this.tradingViewChart)) {
          this.tradingViewChart.chart().executeActionById('insertIndicator');
        }
      },
      showChartPropertiesDialog() {
        if (!window._.isEmpty(this.tradingViewChart)) {
          this.tradingViewChart.chart().executeActionById('chartProperties');
        }
      },
      
      getResolution(desc) {
        const a = this.tradingViewChart.chart().resolution();
        // console.log(desc, a)
      },

      saveTradingChartConfiguration() {
        this.tradingViewChart.save((res) => {
          LocalStorageUtils.saveItem('margin_trading_chart', JSON.stringify(res));
        });
      },

      reloadTrandingChart() {
        try{
          const jsonObject = LocalStorageUtils.getItem('margin_trading_chart');
          if (jsonObject) {
            this.tradingViewChart.load(JSON.parse(jsonObject));
            return true;
          }
          return false;
        }catch(err){
          return false;
        }
      },

      setBarStyle(type) {
        this.tradingViewChart.chart().setChartType(type);
      },

      changeChartStudy() {
        if (this.indexStudyId) {
          this.tradingViewChart.chart().removeEntity(this.indexStudyId);
        }
        this.tradingViewChart.chart().createStudy(this.instrument.reference_index, false, true, [], (entityId) => {
          this.indexStudyId = entityId;
        });
      },
    },
    watch: {
      symbol: function(newSymbol) {
        this.lastBar  = {};
        if (this.isChartReady) {
          this.tradingViewChart.setSymbol(newSymbol, this.resolution, function(){});
          this.changeChartStudy();
        }
      },
      isChartReady: function(val) {
        if(this.isChartReady){
          this.tradingViewChart.setSymbol(this.symbol, this.resolution, function(){});
        }
      },
      resolution: function(newResolution) {
        if (this.isChartReady && this.symbol) {
          this.tradingViewChart.setSymbol(this.symbol, newResolution, function(){});
        }
      }
    },
    mounted() {
      // Create chart for the first time
      var that = this;
      TradingView.onready(function()
      {
        that.createChart();
      });

      window.setInterval(() => {
        this.createEmptyBarIfNeeded(this.symbol);
        this.createEmptyBarIfNeeded(this.instrument.reference_index);
      }, 15000);

      // Create chart when route change, re-render
      if (typeof this.tradingViewChart != 'undefined'){
        this.createChart();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .trading-view-chart {
    height: 100%;
  }
</style>