<template>
  <!-- 合作伙伴 -->
  <div class="lading-page">
    <div class="coopeartion-banner">
      <div class="keynote">
        <div class="con">
          <h3>{{ $t('coopeartion_d.banner.main') }}</h3>
          <p class="desc">{{ $t('coopeartion_d.banner.main_step') }}</p>
        </div>
      </div>
      <div class="coopeartion-banner-bg">
        <div class="co-become-tit">{{ $t('coopeartion_d.banner.tit') }}</div>
        <div class="co-become-subtit">
          {{ $t('coopeartion_d.banner.subtit') }}
        </div>
        <div class="co-become-btn">
          <a href="#" target="_blank" class="item single">
            {{ $t('coopeartion_d.banner.login') }}
            <!-- <div>{{ $t('coopeartion_d.banner.login') }}</div> -->
            <!-- <span>{{ $t('coopeartion_d.banner.manage_login') }}</span> -->
          </a>
          <a href="#" target="_blank" class="item active">
            <div>{{ $t('coopeartion_d.banner.partner') }}</div>
            <span>{{ $t('coopeartion_d.banner.manage_partner') }}</span>
          </a>
          <!-- <div class="item active">
              <div>{{ $t('coopeartion_d.banner.partner') }}</div>
              <span>{{ $t('coopeartion_d.banner.manage_partner') }}</span>
            </div> -->
        </div>
        <!-- <div class="co-become-desc">{{ $t('coopeartion_d.banner.detail') }}</div> -->

        <div class="plan" v-html="$t('coopeartion_d.banner.plan')"></div>
      </div>
    </div>
    <!-- 步骤 -->
    <div class="cooperation-step">
      <div class="step-con">
        <div class="invite_step">
          <div
            class="step_item"
            v-for="(item, index) in $t('coopeartion_d.step')"
            :key="index"
          >
            <div class="step_logo">
              <img :src="item.step_img" />
            </div>
            <div class="step_tit">{{ item.step_num }}</div>
            <div class="step_desc" v-html="item.step_desc"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴报酬 -->
    <div class="coopeartion-reward">
      <div class="reward-tit">
        <img src="@/assets/images/rebate/high_d_compute_3.png" />{{
          $t('coopeartion_d.reward.tit')
        }}
      </div>
      <div class="invite_step">
        <div
          class="step_item"
          v-for="(item, index) in $t('coopeartion_d.reward.step')"
          :key="index"
        >
          <div class="step_tit">0{{ index + 1 }}</div>
          <div class="step_desc" v-html="item"></div>
        </div>
      </div>
      <a
        href="https://amanpuri.io/referral"
        target="_blank"
        class="partner-btn"
      >
        <div>{{ $t('coopeartion_d.banner.partner') }}</div>
        <span>{{ $t('coopeartion_d.banner.manage_partner') }}</span>
      </a>
    </div>
    <!-- 合作伙伴功能 -->
    <div class="cooperation-partner">
      <div class="reward-tit">
        <img src="@/assets/images/rebate/high_d_compute_2.png" />{{
          $t('coopeartion_d.func.tit')
        }}
      </div>
      <div class="invite_step">
        <div
          class="step_item"
          v-for="(item, index) in $t('coopeartion_d.func.step')"
          :key="index"
        >
          <div class="step_logo">
            <img :src="'@/assets/images/rebate/func-' + index + '.png'" />
          </div>
          <div class="step_tit">{{ item.step_num }}</div>
          <div class="step_desc" v-html="item.step_desc"></div>
        </div>
      </div>
    </div>
    <!-- 寻找合作伙伴 -->
    <div class="cooperation-seek">
      <div class="reward-tit">
        <img src="@/assets/images/rebate/search.png" />{{
          $t('coopeartion_d.partner.tit')
        }}
      </div>
      <h2>{{ $t('coopeartion_d.partner.maintit') }}</h2>
      <div class="seek-list">
        <div
          class="seek-item"
          v-for="(item, index) in $t('coopeartion_d.partner.step')"
          :key="index"
        >
          <img :src="'@/assets/images/rebate/seek-' + index + '.png'" />
          <p>{{ item }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {},
  computed: {},
}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/variables';
$bc_040421: #040421;
$bc_0E2945: #0e2945;
$c_rgba: rgba(255, 255, 255, 0.3);
$f_22: 22px;
$f_14: 14px;
$f_16: 16px;
$f_8: 8px;
$m_w_940: 940px;
div,
p {
  font-family: 'Roboto-Regular', sans-serif;
}

.lading-page {
  min-width: 1200px;
  max-width: 100%;
  background-color: $color-white;
  .coopeartion-banner {
    width: 100%;
    height: 580px;
    background-color: $bc_040421;
    position: relative;
    .coopeartion-banner-bg {
      min-width: $m_w_940;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      background: url('@/assets/images/rebate/coopeartion__db.png')
        no-repeat;
      background-size: 100% 92%;
      color: #fff;
      text-align: center;
      .co-become-tit {
        font-size: $f_22;
        font-weight: 600;
        margin: 70px auto 8px;
      }
      .co-become-subtit {
        font-size: $f_14;
      }
      .co-become-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 45px auto 14px;
        .item {
          padding: 4px 0px;
          margin: 0px 10px;
          min-width: 210px;
          max-width: 270px;
          border-radius: 10px;
          min-height: 44px;
          background-color: $c_rgba;
          cursor: pointer;
          display: inline-block;
          color: #fff;
          div {
            font-size: 15px;
            font-weight: 600;
            line-height: 10px;
            margin-top: 6px;
          }
          span {
            font-size: $f_8;
          }
        }
        .single {
          font-size: 15px;
          font-weight: 700;
          line-height: 36px;
          background-color: #6f55a3;
        }
        .active {
          background-color: #f9ae06;
        }
      }
      .co-become-desc {
        font-size: $f_8;
        color: $c_rgba;
      }
      .plan {
        width: 90%;
        padding: 15px 42px;
        border-radius: 10px;
        background-color: $c_rgba;
        font-size: 14px;
        text-align: left;
        line-height: 22px;
        margin: 143px auto 0px;
      }
    }
    .keynote {
      position: absolute;
      top: 280px;
      left: 0px;
      width: 100%;
      height: 68px;
      background-color: $c_rgba;
      z-index: 2;
      .con {
        width: 846px;
        margin: 0px auto;
        h3 {
          font-size: 21px;
          color: #fff;
          margin: 15px 0px 5px;
        }
        p {
          font-size: $f_8;
          color: $c_rgba;
        }
      }
    }
  }
  .cooperation-step {
    background-color: $bc_0E2945;
    position: relative;
    height: 278px;
    .step-con {
      width: $m_w_940;
      position: absolute;
      top: -42px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3px;
      .step_item {
        background-color: #2286e1;
        padding: 10px 25px 30px;
        box-sizing: border-box;
        border-radius: 10px;
        .step_tit {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .coopeartion-reward {
    position: relative;
    width: $m_w_940;
    margin: 0px auto;
    padding: 158px 0px 70px;
    display: flex;
    flex-direction: column;
    .invite_step {
      color: #424242;
      .step_item {
        position: relative;
        border: 1px solid #424242;
        border-radius: 10px;
        padding: 24px;
        position: relative;
        .step_desc {
          font-size: 11px;
          line-height: 22px;
          font-family: Roboto-Bold, sans-serif;
          position: relative;
        }
        .step_tit::after {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 1px;
          content: '';
          background-color: #000;
        }
        .step_tit {
          position: absolute;
          top: -120px;
          font-size: 50px;
          text-align: center;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .partner-btn {
      display: inline-block;
      padding: 4px 60px;
      margin: 58px auto 0px;
      border-radius: 10px;
      background-color: #f9ae06;
      cursor: pointer;
      text-align: center;
      color: #fff;
      div {
        font-size: $f_16;
        font-weight: 600;
        line-height: 10px;
        margin-top: 6px;
      }
      span {
        font-size: $f_8;
      }
    }
  }

  .cooperation-partner {
    width: 100%;
    height: auto;
    padding: 70px 0px;
    background-color: #0e2945;
    position: relative;
    .invite_step {
      width: $m_w_940;
      margin: 0px auto;
    }
    .reward-tit {
      background-color: #fff;
      color: #0e2945;
    }
  }

  .cooperation-seek {
    position: relative;
    padding: 52px 0px 72px;
    h2 {
      font-size: 16px;
      font-weight: 600;
      color: #0e2945;
      text-align: center;
      margin-bottom: 42px;
    }
    .seek-list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .seek-item {
        margin: 0px 22px;
        img {
          width: 64px;
          height: 64px;
          border-radius: 5px;
        }
        p {
          font-size: 10px;
          font-weight: 600;
          text-align: center;
          margin-top: 12px;
        }
      }
    }
  }

  .invite_step {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: default;
    .step_item {
      flex: 1;
      padding: 0px 10px;
      box-sizing: border-box;
      margin: 0px 24px;
    }
    .step_logo {
      width: 50px;
      height: 50px;
      margin: 0px auto;
    }
    font-family: 'Roboto-Regular', sans-serif;
    color: #fff;
    text-align: center;
    .step_tit {
      font-size: 14px;
      margin: 22px auto 10px;
    }
    .step_desc {
      font-size: 12px;
      /* width: 165px; */
      line-height: 20px;
      margin: 0px auto;
    }
  }
  .reward-tit {
    background-color: #0e2945;
    border-radius: 10px;
    padding: 15px 35px;
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    img {
      width: 30px;
      display: inline-block;
      margin-right: 6px;
      /* height: 25px; */
    }
    color: #fff;
    font-size: 15px;
    font-weight: 600;
  }
}
</style>
