 import rf from '@/request/RequestFactory';

const actions = {
  getOrders({ commit }) {
    if (!window.isAuthenticated) return
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getActiveOrder().
        then(res => {
          if (res.data) {
            commit('updateOrders', res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
};

const getters = {
  activeOrderCount: state => {
    return state.order.activeCount;
  },
  stopOrderCount: state => {
    return state.order.stopCount;
  },
  orders: state => {
    return state.orders;
  },
};

const mutations = {
  setActiveOrderCount: (state, payload) => {
    state.order.activeCount = payload;
  },
  setStopOrderCount: (state, payload) => {
    state.order.stopCount = payload;
  },
  updateOrders: (state, payload) => {
    state.orders = [...payload];
  },
};

export default {
  actions,
  getters,
  mutations,
  state: {
    order: {
      activeCount: 0,
      stopCount: 0,
    },
    orders: {}
  },
};