import BaseRequest from './base/BaseRequest';

export default class SettingRequest extends BaseRequest {

    getShowRemainAML() {
      const url = '/get-show-remain-aml';
      return this.get(url);
    }

    getPairCoinSettings(params) {
      const url = '/pair-coin-settings';
      return this.get(url, params);
    }
  }
  