import BigNumber from 'bignumber.js';
import MarginCalculator from '../../../common/MarginCalculator.js';

export default {
  caculatorOrderValue(data) {
    const contVal = MarginCalculator.getContractValue(data.price, data.quantity);
    return new BigNumber(`${contVal || 0}`).absoluteValue().toString();
  },

  getTickSize(order) {
    let instrument = window.app.$store.getters.allInstrumentBySymbol(order.instrument_symbol) || {};
    return instrument.tick_size || '0.1';
  },

  getTickSizeByPosition(position) {
    let instrument = window.app.$store.getters.allInstrumentBySymbol(position.symbol) || {};
    return instrument.tick_size || '0.1';
  },

  getTriggerPrice(order) {
    try{

      let instrument = window.app.$store.getters.instrumentBySymbol(order.instrument_symbol) || {};
      if (order.trigger === 'last') {
        return instrument.last_price || 0;
      } else if (order.trigger === 'mark') {
        return instrument.mark_price || 0;
      } else if (order.trigger === 'index') {
        let index = window.app.$store.getters.indexBySymbol(instrument.reference_index);
        return index.value || 0;
      }
      
      return instrument.last_price

    }catch(err){
      return 0
    }
  },

  getTriggerDistance(order) {
    return order.stop_price && new BigNumber(this.getTriggerPrice(order) || 0).minus(order.stop_price).toString();
  },

  getOrderStatus(order) {
    if(!order) return ''
    switch (order.status) {
      case 'canceled':
        return order.note == 'expired' ? 'common.status.expired' : 'common.status.cancel';
      case 'pending':
        return order.pair_type == 'ifd' ? 'common.status.new' : (order.remaining && new BigNumber(order.remaining).comparedTo(order.quantity) === 0
          ? 'common.status.new' : 'margin.partially_filled');
      case 'executed':
        return 'margin.filled';
      case 'stopping':
        return 'common.status.untriggered';
      case 'executing':
        return 'common.order_status.partial_filled';
      case 'new':
        return order.pair_type == 'ifd' ? 'common.status.pending' : 'common.status.new';
      case '':
        return '';
    }

  },

  getOrderNote (order) {
    if(!order) return '';
    if (order.pair_type == 'ifd' || order.pair_type == 'oco') {
      if (order.reference_id) {
        return window.i18n.t(order.stop_type ? 'margin.stop_limit_id' : 'margin.limit_id', { id: order.reference_id });
      }
      return window.i18n.t(order.stop_type ? 'margin.stop_limit_id' : 'margin.limit_id', { id: order.id });
    }
    return '--';
  },

  getOrderType(order) {
    if (order.pair_type == 'ifd') {
      return 'margin.ifd';
    } else if (order.pair_type == 'oco') {
      return 'margin.oco';
    }
    if (!order.stop_type) {
      if (order.type == 'limit') {
        return 'margin.limit';
      }
      return 'margin.market';
    }
    switch (order.stop_type) {
      case 'stop_limit':
        return 'margin.stop_limit';
      case 'stop_market':
        return 'margin.stop_market';
      case 'trailing_stop':
        return 'margin.trailing_stop';
      case 'take_profit_market':
        return 'margin.take_profit_market';
      case 'take_profit_limit':
        return 'margin.take_profit_limit';
      case 'oco':
        return 'margin.oco';
      case 'ifd':
        return 'margin.ifd';
      default:
        return '';
    }
  }
}