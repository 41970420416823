import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import COMMON_CONST from "@/common/Const";
import OPEN_ORDER_HEADER_CONST from "@/components/spotexchange_pages/order/DataTableInit";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";
import { mapState } from "vuex";

const ORDER_TYPE_LIMIT = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_LIMIT;
const ORDER_TYPE_MARKET = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_MARKET;
const ORDER_TYPE_STOP_LIMIT = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_STOP_LIMIT;
const ORDER_TYPE_STOP_MARKET =
  COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_STOP_MARKET;
const ORDER_TYPE_ALL = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_ALL;

export default {
  data() {
    return {
      headerTable: OPEN_ORDER_HEADER_CONST.OPEN_ORDER_HEADER,
      commonConst: COMMON_CONST,
      isHide: false,
      deleteType: COMMON_CONST.DEFAULT,
      coin: this.coinCode,
      currency: this.currencyCode,
      fetchingOpenOrder: false,
      coinsMasterData: {},
      coinNames: {},
      coinImages: {},
      currencyCoinNames: {},
      currencyCoinsImages: {},
      isHasHover: window.matchMedia("(min-width: 1150px)").matches,
      ethicon: ETHIcon,
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
    }),
  },
  methods: {
    handleWindowResize() {
      this.isHasHover = window.matchMedia("(min-width: 1150px)").matches;
    },
    localizeType(type) {
      if (type == "limit") return this.$t("order.order_form.limit");
      if (type == "market") return this.$t("orders.open_order.market");
      if (type == "stop_market")
        return this.$t("order.order_form.stop_market_table");
      if (type == "stop_limit")
        return this.$t("order.order_form.stop_limit_table");
    },
    mulBigNumber(number1, number2) {
      if (!number1 || !number2) return "0";
      return new BigNumber(number1)
        .multipliedBy(number2)
        .toFixed(15)
        .toString();
    },
    isIE() {
      let ua = navigator.userAgent;
      var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
      if (is_ie && $("table").length > 0) {
        $("#open-order table").addClass("fixieCancelbox-full");
      }
    },
    getData(params) {
      if (!window.isAuthenticated) {
        return new Promise((resolve) => resolve({}));
      }
      return rf
        .getRequest("OrderRequest")
        .getOrdersPending(params)
        .then((res) => {
          this.fetchingOpenOrder = false;
          this.$emit("dataFromChild", res.data.total);
          return res;
        });
    },
    showErrorToastMessageWithType(type) {
      let message = "";
      let subMessage = "123";
      switch (type) {
        case ORDER_TYPE_LIMIT:
          message = window.i18n.t("order.open_order.no_data.limit");
          subMessage = window.i18n.t(
            "order.open_order.no_data.limit_sub_message"
          );
          break;
        case ORDER_TYPE_MARKET:
          message = window.i18n.t("order.open_order.no_data.market");
          subMessage = window.i18n.t(
            "order.open_order.no_data.market_sub_message"
          );
          break;
        case ORDER_TYPE_STOP_LIMIT:
          message = window.i18n.t("order.open_order.no_data.stop_limit");
          subMessage = window.i18n.t(
            "order.open_order.no_data.stop_limit_sub_message"
          );
          break;
        case ORDER_TYPE_STOP_MARKET:
          message = window.i18n.t("order.open_order.no_data.stop_market");
          subMessage = window.i18n.t(
            "order.open_order.no_data.stop_market_sub_message"
          );
          break;
        case ORDER_TYPE_ALL:
        default:
          message = window.i18n.t("order.open_order.no_data.all");
          subMessage = window.i18n.t(
            "order.open_order.no_data.all_sub_message"
          );
          break;
      }
      this.showOrderWarningMessage(message, subMessage);
    },
    cancelOrder(type, id = "") {
      let message = window.i18n.t("order.open_order.cancel_order_success");
      const request =
        type === "one"
          ? rf.getRequest("OrderRequest").cancel(id)
          : type === "all"
          ? rf.getRequest("OrderRequest").cancelAll()
          : rf.getRequest("OrderRequest").cancelByType({ type });

      request
        .then((res) => {
          this.showSuccessMessage(message);
          this.$store.dispatch("setRecallBalances", true);
        })
        .catch((error) => {
          if (!error.response) {
            this.showErrorMessage(
              window.i18n.t("common.message.network_error")
            );
          } else {
            this.showErrorToastMessageWithType(type);
          }
        });
    },
    showSuccessMessage(message) {
      this.$refs.datatable.refresh();
      Message.success(message, {}, { position: "bottom_left" });
    },
    showErrorMessage(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },
    showOrderWarningMessage(message, subMessage) {
      Message.orderWarning(
        message,
        subMessage,
        {},
        { position: "bottom_left" }
      );
    },
    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(
        this.masterData.coins_confirmation,
        (item) => item.coin
      );
      this.coinsMasterData = window._.keyBy(
        this.masterData.coins,
        (item) => item.coin
      );
      this.coinsMasterData.usd = {
        name: window.i18n.t(`currency.usd.fullname`),
      };

      _.forEach(this.masterData.coins, (item) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
        this.currencyCoinNames[item.coin] = item.name;
        this.currencyCoinsImages[item.coin] = item.icon_image;
      });

      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon;
      this.currencyCoinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.currencyCoinsImages["usd"] = `/images/color_coins/usd.png`;
      this.currencyCoinsImages["eth"] = this.ethicon;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
    this.isIE();
    this.handleMasterData();
  },
};
