<template>
  <div
    id="trade-history-convert"
    :class="{ no_padding: options && options.isNoPadding }"
    class="trade_layout_convert_history"
  >
    <div
      id="is_single_page_trade "
      class="group-control is_single_page"
      v-if="true"
      :class="{ is_single_page: options && options.isNoPadding }"
    >
      <div class="group-date-picker from-area">
        <div class="label-text">{{ $t("order.order_history.date_from") }}</div>
        <div class="group-date from-date">
          <datepicker
            :value="dateFromDefault"
            class="date"
            @selected="selectDateFrom"
            :format="customFormatter"
            input-class="date-picker-input"
            :language="lang"
          ></datepicker>
        </div>
      </div>
      <div class="group-date-picker to-area">
        <div class="label-text">{{ $t("order.order_history.date_to") }}</div>
        <div class="group-date to-date">
          <datepicker
            :value="dateToDefault"
            class="date"
            @selected="selectDateTo"
            :format="customFormatter"
            input-class="date-picker-input"
            :language="lang"
          ></datepicker>
        </div>
      </div>
      <div class="group-pair pair-area">
        <div class="content-search">
          <div
              class="drop-down-pair position-relative d-flex justify-content-between align-items-center"
              v-click-outside="clickOutSidePair"
          >
            <div class="w-100 current-pair"  @click="openDropPair = !openDropPair">
              <span v-if="currentPair.name">{{ currentPair.name }}</span>
              <span v-else class="placeholder-input">{{ $t("convert.coin-pair") }}</span>
            </div>
            <i
              class="icon"
              @click="openDropPair = !openDropPair"
              :class="{
                'icon-arrow2': openDropPair,
                'icon-arrow1': !openDropPair,
              }"
            ></i>
            <div class="position-absolute drop-list-pair" v-show="openDropPair">
              <div class="position-relative text-search-pair">
                <input
                  v-model="pairName"
                  class="w-100 h-100"
                  type="text"
                  @input="searchPair"
                  @focus="focusInput = true"
                />
                <img
                    class="close-value position-absolute"
                    width="19"
                    height="19"
                    :src="
                    require(`@/assets/images/landing/icon-search${themeDark}.svg`)
                  "
                />
              </div>
              <div class="list-pair-drop">
                <template v-if="pairShow?.length > 0">
                  <div
                      class="list-pair"
                      v-for="pair in pairShow"
                      :key="pair.value"
                      @click="selectPair(pair)"
                  >
                    <span>{{ pair.name }}</span>
                  </div>
                </template>
                <div v-else class="list-no-data">{{ $t("convert.noresult") }}.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="group-type button-area">
        <div class="content-search search-type side-layout-order">
          <div class="button-side-layout-order">
            <button class="button-search" @click="actionSearch">
              {{ $t("common.search") }}
            </button>
            <button class="button-reset" @click="actionReset">
              {{ $t("button.reset") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <data-table3
      ref="datatable"
      :getData="getData"
      :msg-empty-data="$t('order.convert_history.no_data')"
      class="datatable history-table"
      id="order-table-history"
      :limit="6"
    >
      <template slot-scope="props">
        <th
          v-for="item in headerTable"
          :key="item.id"
          :data-sort-field="item && item.isSort ? item.id : null"
          @click="props.echoclick"
          :class="[$t(item.name), { cursor: !item.isSort }]"
        >
          <span>{{ $t(item.name) }}</span>
          <span class="unHideCoin" v-if="item.hoverTitle">{{$t(item.hoverTitle)}}</span>
          <i
            v-if="item.isSort"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td
            class="cl_pair"
            :title="`${props.item.coin} / ${props.item.currency}` | uppercase"
          >
            <img
              v-if="openOrderLayout !== 'trade'"
              :src="`${coinImages[props?.item?.coin]}`"
              alt=""
              width="30"
              height="30"
              class="coin-img"
            />
            <img
              v-if="openOrderLayout !== 'trade'"
              :src="`${currencyCoinsImages[props?.item?.currency]}`"
              alt=""
              width="30"
              height="30"
              class="currency-img"
            />
            {{ props?.item?.coin | uppercase }}/{{
              props?.item?.currency | uppercase
            }}
          </td>
          <td v-if="props.item.type == 'limit'">
            {{ $t('order.order_form.limit') }}
          </td>
          <td v-if="props.item.type == 'market'">
            {{ $t('order.trade_history.market') }}
          </td>
          <template v-if="props.item.trade_type === 'sell'">
            <td>
             <span class="hoverPrice">
<!--                <div-->
<!--                  v-show="isShowTooltip(props.item.quantity, 10, 5)"-->
<!--                  class="unHideCoin"-->
<!--                >-->
<!--                  {{ formatRoundDown(props.item.quantity, 5) }}-->
<!--                </div>-->
                {{
                 props.item.quantity | formatNumberAndDecimal
                }}
                {{ props?.item?.coin | uppercase }}
              </span>
            </td>
            <td>
              <template>
                <span class="hoverPrice" v-if="props.item.type == 'market'">
<!--                  <div-->
<!--                    v-show="isShowTooltip((props.item.quantity * props.item.price).toString(), 10, 5)"-->
<!--                    class="unHideCoin"-->
<!--                  >-->
<!--                    {{ formatRoundDown((props.item.quantity * props.item.price).toString(), 5) }}-->
<!--                  </div>-->
                  <!-- {{
                    props.item.quantity * props.item.price | formatNumberAndDecimal
                }} -->
                {{
                  props.item.price
                    ? props.item.quantity * props.item.price
                    : (props.item.quantity * props.item.executed_price)
                      | formatNumberAndDecimal
                }}
                
                {{ props?.item?.currency | uppercase }}
                </span>
                <span class="hoverPrice" v-else>
<!--                  <div-->
<!--                    v-show="isShowTooltip((props.item.quantity * props.item.price).toString(), 10, 5)"-->
<!--                    class="unHideCoin"-->
<!--                  >-->
<!--                    {{ formatRoundDown((props.item.quantity * props.item.price).toString(), 5) }}-->
<!--                  </div>-->
                  {{
                    props.item.quantity * props.item.price | formatNumberAndDecimal
                }}
                {{ props?.item?.currency | uppercase }}
                </span>
              </template>
            </td>
          </template>
          <template v-else>
           <td>
              <!-- {{
               props.item.quantity * props.item.price| formatNumberAndDecimal
              }} -->
              {{
                props.item.price
                  ? props.item.quantity * props.item.price
                  : (props.item.quantity * props.item.executed_price)
                    | formatNumberAndDecimal
              }}
              {{ props?.item?.currency | uppercase }}
            </td>
            <td>
             <template>
                <span class="hoverPrice" v-if="props.item.type == 'market'">
<!--                  <div-->
<!--                    v-show="isShowTooltip((props.item.quantity).toString(), 10, 5)"-->
<!--                    class="unHideCoin"-->
<!--                  >-->
<!--                    {{ formatRoundDown((props.item.quantity).toString(), 5) }}-->
<!--                  </div>-->
                  {{
                    props.item.quantity | formatNumberAndDecimal}}
                  {{ props?.item?.coin | uppercase }}
                </span>
                <span class="hoverPrice" v-else>
<!--                  <div-->
<!--                    v-show="isShowTooltip((props.item.quantity).toString(), 10, 5)"-->
<!--                    class="unHideCoin"-->
<!--                  >-->
<!--                    {{ formatRoundDown((props.item.quantity).toString(), 5) }}-->
<!--                  </div>-->
                  {{
                    props.item.quantity | formatNumberAndDecimal
                }}
                  {{ props?.item?.coin | uppercase }}
                </span>
              </template>
            </td>
          </template>

          <td width="190px" class="t-left">
            <span class="hoverPrice">
<!--                <div-->
<!--                  v-show="isShowTooltip(props.item.executed_quantity, 10, 5)"-->
<!--                  class="unHideCoin"-->
<!--                >-->
<!--                  {{ formatRoundDown(props.item.executed_quantity, 5) }}-->
<!--                </div>-->
                {{
                props.item.executed_quantity | formatNumberAndDecimal
                }}
              </span>
             {{props.item.coin | uppercase}}
          </td>
          <td v-if="props.item.status == 'canceled'">
            <div>
              1 {{ props.item.coin | uppercase }} = -- {{ props.item.currency | uppercase }}
            </div>
            <div>
              1 {{ props.item.currency | uppercase }} = -- {{ props.item.coin | uppercase }}
            </div>
          </td>
          <td v-else>
            <div >
              1 {{ props.item.coin | uppercase }} =
              <span class="hoverPrice">
<!--                <div-->
<!--                  v-show="isShowTooltip(props.item.executed_price, 8, 8)"-->
<!--                  class="unHideCoin"-->
<!--                >-->
<!--                  {{ formatRoundDown(props.item.executed_price, 8) }}-->
<!--                </div>-->
                {{
                  props.item.executed_price |  formatNumberAndDecimal
                }}
              </span>
              <!-- {{
                props.item.executed_price
                  | toNumber
                  | formatCurrencyAmount(props.item.currency, "0")
              }} -->
              {{ props.item.currency | uppercase }}
            </div>
            <div>
              1 {{ props.item.currency | uppercase }} =
              <span class="hoverPrice">
<!--                <div-->
<!--                  v-show="-->
<!--                    isShowTooltip(-->
<!--                      (1 / props.item.executed_price).toString(),-->
<!--                      8,-->
<!--                      8-->
<!--                    )-->
<!--                  "-->
<!--                  class="unHideCoin"-->
<!--                >-->
<!--                  {{-->
<!--                    formatRoundDown(-->
<!--                      (1 / props.item.executed_price).toString(),-->
<!--                      8-->
<!--                    )-->
<!--                  }}-->
<!--                </div>-->
                {{
                  1 / props.item.executed_price | formatNumberAndDecimal
                }}
              </span>
              <!-- {{
                (1 / props.item.executed_price)
                  | toNumber
                  | formatCurrencyAmount(props.item.coin, "0")
              }} -->
              {{ props.item.coin | uppercase }}
            </div>
          </td>
          <td width="190px" class="t-left">
            {{ props.item.updated_at | timestampToDate }}
          </td>
          <td
            width="190px"
            class="t-left"
            v-if="props.item.trade_type !== 'buy' && props.item.fee_amal"
          >
            {{ props.item.status }}
          </td>
          {{
            props.item.activeRow
          }}
          <td
            :class="props.item.status"
            :title="props.item.amount | formatCurrencyAmount(props.item.coin)"
            @click="toggleRow(props.index)"
          >
            <div class="d-flex align-items-center justify-content-between">
              <span v-if="props.item.status === 'executing'"
                >{{ $t("convert.partially-filled") }}</span
              >
              <span v-else-if="props.item.status === 'executed'">{{ $t("order.open_order.filled") }}</span>
              <span v-else-if="props.item.status === 'canceled'">{{ $t("common.order_status.canceled") }}</span>
              <span v-else> {{ props.item.status | upperFirst }}</span>

              <i
                :class="{
                  'icon-arrow2': opened.includes(props.index),
                  'icon-arrow1': !opened.includes(props.index),
                }"
                width="5"
              />
            </div>
          </td>
          <!-- <td class="" width="50px"></td> -->
        </tr>
        <template v-if="opened.includes(props.index)">
          <tr class="table_dropdown">
            <td colspan="11" class="dropdown-content">
              <div class="item-content">
                <div>
                  <p>{{ $t("order.history.convert_time") }}</p>
                  <p>{{ props.item.updated_at | timestampToDate }}</p>
                </div>
                <div>
                  <p>{{ $t("order.history.input_price") }}</p>
                  <p>
                    1 {{ props.item.coin | uppercase }} =
                    {{ (props.item.type == 'market' ? '--' : props.item.price) | formatNumberAndDecimal}} {{ props.item.currency | uppercase }}
                  </p>
                </div>
                <div v-if="props.item.trade_type === 'sell'">
                    <p>{{ $t('order.history.received') }}</p>
                    <!-- <p> {{ props.item.executed_quantity * props.item.executed_price - props.item.fee}} -->
                    <p> {{(props.item.executed_quantity * props.item.executed_price - props.item.fee) | formatNumberAndDecimal}}
                      {{ props.item.currency | uppercase }}
                    </p>
                </div>
                <div v-else>
                  <p>{{ $t('order.history.spent') }}</p>
                  <p>{{ (props.item.executed_quantity * props.item.executed_price) | formatNumberAndDecimal}}
                    {{ props.item.currency | uppercase }}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </data-table3>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateTab from "../common/DateTab.vue";
import OPEN_ORDER_HEADER_CONST from "./DataTableInit.js";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import moment from "moment";
import MasterdataUtils from "@/common/MasterdataUtils";
import Utils from "@/common/Utils";
import SelectBox from "@/components/shared_components/common/SelectBox";
import {mapState} from "vuex";
import Cookies from "js-cookie";
import BigNumber from "bignumber.js";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"

export default {
  components: {
    DateTab,
    Datepicker,
    SelectBox,
    DateRangePicker,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    coinCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currencyCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },

    tableHistory: {
      type: Boolean,
      default: false,
    },
    openOrderLayout: {
      type: "trade" | "order",
      default: "order",
    },
  },
  data() {
    return {
      opens: "right",
      dateRange: {
        startDate: moment().subtract(6, "days").toDate(),
        endDate: moment().toDate(),
      },
      single_range_picker: false,
      show_ranges: false,
      singleDatePicker: false,
      timePicker: false,
      timePicker24Hour: false,
      showDropdowns: false,
      autoApply: true,
      showWeekNumbers: false,
      linkedCalendars: false,
      alwaysShowCalendars: true,
      appendToBody: false,
      closeOnEsc: false,
      headerTable: OPEN_ORDER_HEADER_CONST.CONVERT_HISTORY_HEADER,
      sideOptions: [],
      commonConst: COMMON_CONST,
      dateFromDefault: moment().subtract(6, "days").toDate(),
      dateToDefault: moment().toDate(),
      dateFrom: moment().subtract(6, "days").startOf("day").format("x"),
      dateTo: moment().endOf("day").format("x"),
      disableFromDate: {
        from: new Date(),
      },
      disableToDate: {
        from: new Date(),
      },
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      filters: {
        currency: "",
        tradeType: "",
      },
      durationDateFrom: null,
      isHide: false,
      isShowToolTip: false,
      coin: this.coinCode,
      currency: this.currencyCode,
      fetchingTradeHistory: false,
      markets: [],
      coinAMAL: COMMON_CONST.AML,
      coinNames: {},
      coinImages: {},
      currencyCoinNames: {},
      currencyCoinsImages: {},
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
      openDropPair: false,
      focusInput: "",
      pairName: "",
      currentPair: {},
      pairShow: [],
      inforDrop: false,
      opened: [],
      ethicon: ETHIcon,
      lang: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) || 'en',
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
      userThemeMode: (state) => state.userThemeMode,
      user: (state) => state.user,
    }),
    themeDark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
  methods: {
    toggleRow(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        SpotOrderListUpdated: this.onOrderListUpdated,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
    },
    onOrderListUpdated(data, isHideOtherPair) {
      if (!this.fetchingTradeHistory) {
        this.fetchingTradeHistory = true;
        if (isHideOtherPair) {
          this.$refs.datatable.filter({
            coin: this.coin,
            currency: this.currency,
          });
        } else {
          this.$refs.datatable.refresh();
        }
      }
    },
    changeDate(currentDateTab) {
      switch (currentDateTab.id) {
        case this.commonConst.ONE_DAY:
          this.durationDateFrom = moment().startOf("day").format("x");
          break;
        case this.commonConst.ONE_WEEK:
          this.durationDateFrom = moment()
            .subtract(6, "days")
            .startOf("day")
            .format("x");
          break;
        case this.commonConst.ONE_MONTH:
          this.durationDateFrom = moment()
            .subtract(1, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
          break;
        default:
          this.durationDateFrom = moment()
            .subtract(3, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
      }
      const params = {
        start_date: this.durationDateFrom,
        end_date: moment().endOf("day").format("x"),
      };
      this.$refs.datatable.filter(params);
    },
    getData(params) {
      if (!isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      // const el = document.getElementById('is_single_page_trade');
      // if (el) {
      //   el.scrollIntoView({behavior: "smooth"});
      // }
      const dateRange = this.getDefaultDateRange();
      params = Object.assign({}, dateRange, params);
      params.market_type = 1;
      return rf
        .getRequest("OrderRequest")
        .getOrderHistory(params)
        .then((res) => {
          this.fetchingTradeHistory = false;
          return res;
        });
    },

    getDefaultDateRange() {
      return {
        start_date: this.dateFrom,
        end_date: moment().endOf("day").format("x"),
      };
    },
    showError(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },
    actionSearch() {
      if (this.dateFrom > this.dateTo) {
        this.showError(this.$t("common.date.warning"));
        return;
      }
      this.$refs.datatable.filter(this.filterParams());
      if (!this.options) {
        // this.$refs.selectDateTab.resetSelectDate();
      }
    },
    filterParams() {
      const filterParams = {
        start_date: this.dateFrom,
        end_date: this.dateTo,
      };
      if (this.currentPair.name) {
        let [coin, currency] = this.currentPair.name.toLowerCase().split("/");
        filterParams.coin = coin;
        filterParams.currency = currency;
      }
      if (this.filters.tradeType && this.filters.tradeType !== "all") {
        filterParams.trade_type = this.filters.tradeType;
      }
      return filterParams;
    },
    actionReset() {
      this.filters.coin = "";
      this.filters.currency = "";
      this.filters.tradeType = "";
      this.currentPair.name = "";
      this.dateFromDefault = moment().subtract(6, "days").toDate();
      (this.dateRange = {
        startDate: moment().subtract(6, "days").toDate(),
        endDate: moment().toDate(),
      }),
        (this.dateToDefault = moment().toDate());
      this.dateFrom = moment().subtract(6, "days").startOf("day").format("x");
      this.dateTo = moment().endOf("day").format("x");
      // this.$refs.datatable.refresh();
      $(".datatable").find("th").attr("data-sort-order", "");
      this.$refs.datatable.orderBy = null;
      this.$refs.datatable.sortedBy = null;
      this.actionSearch();
    },
    async selectDateFrom(dateFrom) {
      this.dateFrom = moment(dateFrom).valueOf();
      this.dateFrom = moment(dateFrom).startOf("day").format("x");
    },
    async selectDateTo(dateTo) {
      this.dateTo = moment(dateTo).endOf("day").format("x");
      if (dateTo) {
        this.disableToDate = {
          from: new Date(dateTo),
        };
      }
    },
    async selectDateRange(date) {
      this.dateFrom = moment(date.startDate).valueOf();
      this.dateFrom = moment(date.startDate).startOf("day").format("x");
      this.dateTo = moment(date.endDate).endOf("day").format("x");
      if (date) {
        this.disableToDate = {
          from: new Date(date.endDate),
        };
      }
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    requestCoinList() {
      MasterdataUtils.getCoins((coins) => {
        this.coinMasters = coins;
        this.coinMasters.unshift(this.$t("order.order_form.all"));
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin.toUpperCase();
      if (coin === "All") {
        this.filters.coin = coin;
      }
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    downloadReport() {
      const params = {
        start_date: this.dateFrom,
        end_date: this.dateTo,
        timezone_offset: Utils.getTimzoneOffset(),
      };
      rf.getRequest("OrderRequest").downloadTradeHistory(
        params,
        "TradeHistory.csv"
      );
    },
    mouseOver() {
      this.isShowToolTip = !this.isShowToolTip;
    },

    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, (item) => {
        return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    getMarkets() {
      return window._.map(COMMON_CONST.MARKETS_2, (item) => {
        return {
          id: item.name,
          name: this.$t(item.name),
          icon: item.icon,
        };
      });
    },
    initSideOptions() {
      this.sideOptions = this.commonConst.SELECT_SIDE.map((option) => {
        return {
          id: option.id,
          name: this.$t(option.name),
        };
      });
    },

    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(
        this.masterData.coins_confirmation,
        (item) => item.coin
      );
      this.coinsMasterData = window._.keyBy(
        this.masterData.coins,
        (item) => item.coin
      );
      this.coinsMasterData.usd = {
        name: window.i18n.t(`currency.usd.fullname`),
      };

      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
        this.currencyCoinNames[item.coin] = item.name;
        this.currencyCoinsImages[item.coin] = item.icon_image;
      });

      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon
      this.currencyCoinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.currencyCoinsImages["usd"] = `/images/color_coins/usd.png`;
    },
    clickOutSidePair() {
      this.openDropPair = false;
      this.pairName = "";
      this.focusInput = false;
      this.pairShow = this.pairs;
    },
    searchPair() {
      this.pairShow = this.pairs.filter((item) =>
        item.name.toLowerCase().trim().includes(this.pairName.toLowerCase())
      );
    },
    handleDelete() {
      this.pairName = "";
      this.searchPair();
    },
    selectPair(pair) {
      this.currentPair = {
        name: pair.name,
        from: pair.name.split("/")[0],
        to: pair.name.split("/")[1],
        coin: pair.name.split("/")[0],
        currency: pair.name.split("/")[1],
      };
      this.openDropPair = false;
      this.pairName = '';
      this.pairShow = this.pairs;
      this.focusInput = false;
    },
    numFormat (value, maxDecimal) {
      const split = parseFloat(value).toString().split('.');
      const afterDecimal = split[1];
      const numFormat = afterDecimal && afterDecimal.length > maxDecimal ? new BigNumber(value).toFixed(
        maxDecimal || 2,
        BigNumber.ROUND_DOWN
      ) : parseFloat(value).toFixed(1);
      return numFormat;
    },
    formatRoundDown(value, maxDecimal) {
      if(value == null || Number(value) == 0 || value == "Infinity") return '--';
      const numFormat = this.numFormat(value, maxDecimal)
      return numFormat.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    formatShortText(value, maxDecimal, maxLength) {
      if(value == null || Number(value) == 0 || value == "Infinity") return '--';
      const split = parseFloat(value).toString().split('.');
      const beforeDecimal = split[0];
      const numFormat = this.numFormat(value, maxDecimal)
      const shorttedNumber = beforeDecimal.length > maxLength ? numFormat.slice(0, maxLength-1).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...') : 
      numFormat.slice(0, maxLength).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...')
      return numFormat.length > maxLength ? shorttedNumber : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    isShowTooltip(value, maxLength, maxDecimal) {
      if(value == null || Number(value) == 0 || value == "Infinity") return false;
      const numFormat = this.numFormat(value, maxDecimal)
      return numFormat.replace(".", "").length > maxLength;
    },
  },
  mounted() {
    this.requestCoinList();
    this.getMarkets();
    this.initSideOptions();
    this.$on("UPDATED_LOCALE", () => {
      this.initSideOptions();
    });
    this.handleMasterData();
  },
  created() {
    rf.getRequest("PriceRequest")
      .getPrices24h()
      .then((res) => {
        this.pairs = Object.keys(res.data)
          .map((key) => {
            const coin = key.split("_")[1].toUpperCase();
            const currency = key.split("_")[0].toUpperCase();
            return { name: `${coin}/${currency}`, value: key };
          })
          .sort((a, b) => {
            const coinA = a.name;
            const coinB = b.name;
            return coinA.localeCompare(coinB);
          });
        this.pairShow = this.pairs;
      });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  /* #trade-history-convert .group-control.is_single_page .group-pair .drop-down-pair .drop-list-pair{
    background: $bg-gray-shark;
  } */
  .vdp-datepicker__calendar {
    border: 1px solid $color-gray-charade !important;
    background: $color-gray-charade;
  }
  .icon-arrow1:before {
    font-size: 13px;
    color: var(--monas-choose-pair-convert) !important;
  }
  .icon-arrow2:before {
    font-size: 13px;
    color: var(--monas-choose-pair-convert) !important;
  }
  #trade-history-convert {
    background: $color-night;

    :deep(.empty-data) {
      .icon-no-data-1 {
        margin-top: 80px !important;
      }
    }
    .group-control {
     
      .vue-daterange-picker {
        ::v-deep {
          .reportrange-text {
            background: $color-gunmetal;

            .range-from,
            .range-to {
              color: $color-storm-grey;
            }
            span {
              color: $color-gray-mako;
            }
          }
        }
      }
      .time-picker {
        color: $color-auro-metal;
      }
    }
    .tableContainer3 {
      min-height: 500px;
   
      table {

        margin-bottom: 0 !important;
        thead {
          background: var(--color-tranfer);

          th {
            color: $color-storm-grey;
          }
        }

        tbody {
          tr {
            td {
              color: white;
            }
            .red {
              color: $color-denim !important;
            }
            .blue {
              color: $text-color-jade !important;
            }
          }
        }
      }
    }
  }
}
.trade_layout_convert_history #order-table-history ::v-deep {
  .history-table {
    .VuePagination {
      position: unset;
      margin: 20px 0;
    }
  }
  .wrap-table{
    overflow: auto;
    min-height: 500px;
  }
}
.hoverPrice {
  position: relative;
  .unHideCoin {
    display: none;
  }
  &:hover {
    .unHideCoin {
      display: block;
      position: absolute;
      padding: 6px;
      border-radius: 8px;
      background: var(--color-tooltip);
      color: $color-white !important;
      top: 20px;
      left: 0;
      font-size: 16px;
      z-index: 10;
    }
  }
}
.trade_layout_convert_history {
  table {
    background: transparent !important;

    thead {
      background: var(--background-color-second);

      tr {
        font-family: $font-inter-regular;
        font-size: $font-root;
        position: relative;

        th {
          font-weight: 400;
          font-family: $font-inter-regular;
          font-size: 14px;
          height: 65px;
          background-color: var(--color-header-table);
          color: var(--color-percent);
          .unHideCoin {
            display: none;
          }
          &:hover {
            .unHideCoin {
              z-index: 100;
              white-space: break-spaces;
              display: block;
              position: absolute;
              padding: 10px;
              border-radius: 8px;
              background: var(--color-tooltip);
              color: $color-white !important;
              font-size: 16px;
              width: 227px !important;
              top: 220px;
              text-align: start;
              @media screen and (max-width: 1550px) {
                top: 220px;
               }
              @media screen and (max-width: 992px) {
                font-size: $font-root;
                top: 320px;
               }
            }
          }
          &:nth-child(2){
              width: 7.85%;
              min-width: 80px;

          }
            &:nth-child(3){
              width: 7.85%;
              min-width: 145px;
            }
            &:nth-child(4){
              width: 11.66%;
              min-width: 145px;
            }
            &:nth-child(5){
              width: 10.16%;
              min-width: 116px;
            }
            &:nth-child(6){
              width: 14.42%;
              min-width: 215px;
            }
            &:nth-child(7){
              width: 16.71%;
              min-width: 240px;
            }
            &:nth-child(8){
              width: 13.86%;
              min-width: 140px; 
              text-align: left;
              padding-left: 0;
            }
            &:nth-child(9){
              width: 10.67%;
              min-width: 110px; 
            }
        }
        th:first-child {
          padding-left: 24px;
          text-align: start;
          border-radius: 8px 0px 0px 8px !important;
          width: 18.64% ;
          min-width: 150px;
        }
        th:last-child {
          border-radius: 0px 8px 8px 0px !important;
          padding-right: 34px;
          padding-left: 22px;
        }
      }
    }
    tbody {
      tr {
        td{
          white-space: normal;
          padding: 20px 0;
          p{
            margin: 0 !important;
          }
          &:last-child {
             padding-right: 24px;
             padding-left: 22px;
            .icon-arrow1, .icon-arrow2 {
              font-size: 12px;
              color: var(--text-close) !important;
              cursor: pointer;
            }
            padding-right: 24px;
          }
        }
      }
    }
  }
  //min-width: 1201px;
  table {
    background: transparent;
    thead {
      tr {
        th:first-child {
          text-align: left !important;
        }
        &.Executed {
          min-width: 120px;
        }
       
      }
    }
    tbody {
      tr {
        td {
          font-size: 14px;
          line-height: 19px;  
          padding: 13px 0;
          &:first-child {
            text-align: left !important;
            padding-left: 24px !important;
          }
          td:last-child {
            padding-right: 40px !important;
            text-align: left !important;
          }
          &.executing {
            color: $text-color-yellow !important;
            white-space: break-spaces;
            text-align: left!important;
          }
          &.canceled {
            color: $text-color-red !important;
          }
          &.executed {
            color: $text-color-jade !important;
          }
          overflow: visible !important;
        }
      }
      .table_dropdown {
        background: var(--color-tranfer);
        div.item-content {
          display: flex;
          flex-direction: column;
          padding: 4px 0;
          p {
            display: inline-block;
            font-size: $font-root;
            line-height: 21px;
            &:first-child {
              width: 190px;
              margin-bottom: 7px !important;
            }
          }
        }
      }
    }
  }
}

#trade-history-convert {
  .tableContainer3 {
    table {
      width: 100%;
      overflow-y: initial;

      thead {
        background: var(--color-tranfer);

        tr {
          background: var(--color-tranfer);
          th:first-child {
            text-align: left !important;
          }
        }
      }

      tbody {
        tr {
          td {
            font-family: $font-inter-regular !important;
            color: $color-grey-dark;
            //font-size: $font-medium-bigger!important;
            text-align: center;

            &:first-child {
              text-align: left !important;
              padding-left: 24px;
            }
            .coin-img, .currency-img {
              border-radius: 100%;
            }
            .currency-img{
                  margin-left: -8px;
            }
          }
        }
      }
      background: $color-gray-alabaster;
    }
  }

  table {
    margin-left: 0 !important;
  }

  .group-control {
    max-width: 1318px;
    font-size: $font-small;

    .time-picker {
      font-size: $font-medium-bigger;
      color: $color-gray-dark;
      margin-left: 30px;
      margin-right: 8px;
    }
    &:after {
      display: block;
      clear: both;
      content: "";
    }
    #date-tab {
      float: left;
    }
    .group-date {
      display: inline-block;
      margin-left: 36px;
      position: relative;
      float: left;

      .to-title {
        left: 46%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $color-grey-dark;
      }
      .date-title {
        margin-right: 5px;
      }
      .vdp-datepicker {
        display: inline-block;
      }
      .date-to {
        margin-left: -5px;
      }
    }
    .vue-daterange-picker {
      ::v-deep {
        .reportrange-text {
          background: $color-alabaster;
          border-radius: 8px;
          border: none;
          box-shadow: none;
          display: flex;
          align-items: center;
          font-family: $font-inter-regular;
          font-size: $font-root;

          .range-from {
            color: $color-gray-dark;
            margin-right: 4px;
            width: 95px;
          }
          .range-to {
            color: $color-gray-dark;
            margin-left: 4px;
            width: 95px;
          }

          .icon-calendar {
            margin-left: 8px;
          }

          span {
            color: $color-grey-dark;
          }
        }

        .daterangepicker {
          td.active {
            background-color: $color-aqua-green;
            border-color: transparent;
            color: white;
            border-radius: 50%;
          }
          td.off {
            background-color: #fff !important;
            border-color: transparent !important;
            color: #999 !important;
          }
        }
      }
    }
    .button-reset {
      margin-left: 0px;
      margin-right: 0px;
      background-color: var(--text-search);
      color: var(--color-neutral-dark);
      box-shadow: none;
      font-family: $font-inter-regular;
      border: 1px solid var(--text-search);
      border-radius: 3px;
      width: 100px !important;
      height: 49px;
      font-size: $font-smaller;
      float: left;
      /* -webkit-transition: 0.5s; */
      /* transition: 0.5s; */
      letter-spacing: 0.9px;
      border-radius: 8px !important;
      font-size: 18px !important;
      &:hover {
        background: $color-gray-dark;
        border-color: $color-gray-dark;
        color: $color-white;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }
    .button-search {
      margin-left: 0px;
      margin-right: 12px;
      background-color: $color-caribbean-green;
      color: $color-grey-dark;
      box-shadow: none;
      font-size: 18px;
      border: 1px solid $color-caribbean-green;
      border-radius: 8px !important;
      width: 100px !important;
      height: 49px;
      float: left;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      letter-spacing: 0.9px;

      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color-grey-dark;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
      @media screen and (max-width: 992px) {
        width: 87px;
        font-size: 14px;
        line-height: 19px;
        height: 36px;
        margin-left: 0px;
      }
    }

    &.is_single_page {
      display: grid;
      grid: 'from to pair btn';
      grid-column-gap: 20px;
      width: fit-content;
      padding: 0px 0 30px 0px;
      position: relative;
      align-items: flex-end;

      .from-area {
        grid-area: from;
        min-width: 160px;
      }
      .to-area {
        grid-area: to;
        min-width: 160px;
      }

      .pair-area {
        grid-area: pair;
        min-width: 150px;
      }
      .button-area {
        grid-area: btn;
        min-width: 192px;
      }

      &:after {
        display: block;
        clear: both;
        content: "";
      }
      .label-text {
        color: var(--text-primary-color);
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      .button-search {
        margin-left: 0px;
        margin-right: 12px;
        background-color: $color-caribbean-green;
        color: $color-grey-dark;
        box-shadow: none;
        font-size: 18px;
        border: 1px solid $color-caribbean-green;
        border-radius: 8px !important;
        width: 100px !important;
        height: 49px;
        float: left;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        letter-spacing: 0.9px;

        &:hover {
          background: $color-aquamarine;
          border-color: $color-aquamarine;
          color: $color-grey-dark;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
      }
      .group-date-picker {
        display: inline-block;
        float: left;
        //margin-right: 20px;
        .group-date {
          display: inline-block;
          margin-left: 0;
          position: relative;
          .vdp-datepicker {
            display: inline-block;
            float: left;
          }
          .date-to {
            margin-left: 0;
          }
        }
      }
      .content-search {
        position: relative;
      }
      .group-pair {
        display: inline-block;
        //margin-right: 20px;
        float: left;
        input {
          background-color: var(--color-tab-data-table);
          height: 30px;
          float: left;
          border: 0px;
          padding: 0px 5px 0px 14px;
          margin-right: 1px;
          font-size: 18px;
          color: var(--text-primary-color);
        }
        .sc_search_select {
          /* background-color: $color-catskill-white; */
          height: 30px;
          width: 95px;
        }
        .bw-element {
          float: left;
          font-size: 15px;
          margin-left: 5px;
          height: 30px;
          line-height: 30px;
          margin-right: 5px;
          margin-top: 10px;
          color: var(--text-close);
        }
        .drop-down-pair {
          width: 150px;
          height: 49px;
          padding: 14px;
          padding: 12.5px 15px;
          background-color: var(--color-tab-data-table);
          border-radius: 8px;
          cursor: pointer;
           @media screen and (max-width: 992px) {
              height: 44px !important;
            }
          .current-pair {
            font-size: 18px;
            color: var(--monas-choose-pair-convert);
            display: flex;
            align-items: center;
            .placeholder-input {
              position: absolute;
            }
            @media screen and (max-width: 992px) {
              font-size: 14px;
            }
          }
          
          .icon-arrow1 {
            color: var(--monas-choose-pair-convert);
          }
          .drop-list-pair {
            width: 200px;
            background: var(--background-color-primary);
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            top: 55px;
            left: 0;
            padding: 20px 0px 20px 20px;
            z-index: 15;

            .list-pair {
              margin-bottom: 16px;
              font-size: 18px;
              color: var(--color-dropdown);

              &:last-child {
                margin-bottom: 0;
              }
              &:hover{
                color: $color-caribbean-green;
              }
            }
            .list-pair-drop {
              max-height: 230px;
              overflow-y: auto;
            }
            .list-no-data {
              text-align: center;
              padding: 24px 0;
              color: var(--text-close);
            }

            .text-search-pair {
              width: 160px;
              height: 39px;
              margin-bottom: 16px;

              input {
                background: var(--background-color-second);
                border-radius: 8px;
                border: none;
                padding: 0 16px;
              }

              .icon-search {
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
              }
              .close-value {
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
              }
            }
          }
        }
        .group-type {
          display: inline-block;
          margin-right: 20px;
          float: left;
          .sc_search_select {
            height: 30px;
            width: 95px;
            display: inline-block;
            float: left;
          }

          button {
            line-height: 20px;
            height: 49px;
            padding: 6px 16px;
            width: auto;
            min-width: 85px;
          }
        }
      }
      .group-type {
        display: inline-block;
        margin-right: 20px;
        float: left;
        .sc_search_select {
          height: 30px;
          width: 95px;
          display: inline-block;
          float: left;
        }

        button {
          line-height: 20px;
          height: 49px;
          padding: 6px 16px;
          width: auto;
          min-width: 85px;
        }
      }
      .button-reset {
        margin-left: 0px;
        margin-right: 0px;
        background-color: var(--text-search);
        color: var(--color-neutral-dark);
        box-shadow: none;
        font-family: $font-inter-regular;
        border: 1px solid var(--text-search);
        border-radius: 3px;
        width: 100px !important;
        height: 49px;
        font-size: $font-smaller;
        float: left;
        /* -webkit-transition: 0.5s; */
        /* transition: 0.5s; */
        letter-spacing: 0.9px;
        border-radius: 8px !important;
        font-size: 18px !important;
        &:hover {
          background: $color-gray-dark;
          border-color: $color-gray-dark;
          color: $color-white;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
        @media screen and (max-width: 992px) {
            width: 80px !important;
            font-size: 14px;
            line-height: 19px;
            // height: 39px !important;
          }
      }
      .toolbar-element {
        background-color: var(--color-tab-data-table);
        margin-right: 0px;
        display: inline-block;
        float: left;
        border-radius: 8px;
        width: 95px;
        font-family: $font-inter-regular;
        input {
          /* border: 1px solid $color-alto; */
          border-radius: 8px;
          line-height: 20px;
          padding-top: 5px;
          padding-bottom: 5px;
          height: 49px;
          width: 100%;
          &:focus {
            border: 1px solid $color-jungle-green;
          }

          &::placeholder {
            color: var(--text-search);
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
          }
        }
      }
      .toolbar-element2 {
        background-color: var(--color-tab-data-table);
        margin-right: 0px;
        display: inline-block;
        float: left;
        border-radius: 8px;
        width: 95px;
      }
      .coin-input {
        position: relative;
        float: left;
        .box-coin-option {
          left: 0;
          position: absolute;
          top: 100%;
          max-width: 116px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-negative: 1;
          flex-shrink: 1;
          margin-top: 6px;
          z-index: 999;
          background: var(--bgr-dropdown);
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
          border-radius: 8px;
          .coin-option {
            position: relative;
            padding: 15px 0px;
            overflow-y: auto;
            max-height: 260px;
            .option {
              display: block;
              position: relative;
              display: inline-block;
              white-space: nowrap;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              width: 100%;
              padding: 6px 20px;
              margin: 0;
              line-height: 25px;
              min-height: 25px;
              font-size: $font-root;
              color: var(--color-dropdown);
              font-weight: normal;
              min-width: 100%;
              cursor: pointer;
              text-transform: uppercase;
              &:hover {
                background-color: $color-jungle-green;
                color: $color-white;
              }
              &:first-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                text-transform: capitalize;
                color: var(--text-dropdown) !important;
              }
            }
          }
        }
      }
      .excel_export {
        cursor: pointer;
        font-weight: 500;
      }
    }
  }
}

#date-tab {
  float: left;
}

@media screen and (max-width: 992px) {
  .trade_layout_convert_history #order-table-history.history-table {
    overflow: hidden;
    padding-bottom: 60px;
    ::v-deep {
      .wrap-table {
        overflow: auto;
        min-height: 500px;
      }
    }
  }

  #trade-history-convert .group-control.is_single_page .group-type button {
    font-size: 14px;
    line-height: 19px;
    height: 44px;
  }
  .trade_layout_convert_history #order-table-history.history-table{
    padding-top: 10px;
  }
  #trade-history-convert .group-control.is_single_page .group-pair .drop-down-pair .drop-list-pair .text-search-pair{
    width: 118px;
  }
  #trade-history-convert .group-control.is_single_page .group-pair .drop-down-pair{
    width: 100%;
    height: 39px;
    padding: 10px 15px;
    .icon-arrow1 {
      font-size: 12px;
    }
    .current-pair {
      .placeholder-input {
        font-size: 14px;
        line-height: 19px;
      }
    }
    .drop-list-pair {
      left: 0;
      width: 150px;
      @media screen and (max-width: 992px) {
        top: 40px;
      }
      .list-pair {
        font-size: 14px;
        line-height: 19px;
        .text-search-pair{
          width: 118px;
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
  #trade-history-convert .group-control.is_single_page .group-pair input{
    font-size: 14px;
  }

  .trade_layout_convert_history table tbody {
    tr td{
      font-size: 14px;
      line-height: 19px;

      &.cl_pair {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .table_dropdown {
      div.item-content p{
        font-size: 14px;
        line-height: 16px;
        &:first-child {
          width: 150px;
        }
      }
    }
  }

  .side-layout-order {
    .sc_search_select {
      width: 100% !important;
      height: auto !important;
    }
    .button-side-layout-order {
      width: 100%;
      margin-top: 24px;
      .button-search {
        margin-left: 0 !important;
        margin-right: 8px !important;
        font-size: 14px !important;
        padding: 9px 20px !important;
      }
      .button-reset {
        font-size: 14px !important;
        padding: 9px 20px !important;
      }
    }
  }
  #trade-history-convert
    .group-control.is_single_page
    .group-pair
    .sc_search_select {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  #trade-history-convert .group-control.is_single_page {
    grid: 'from to' 'pair btn';
  }
  #order-table-history.history-table {
    ::v-deep {
      .VuePagination .pagination {
        margin-top: 32px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  #trade-history-convert .group-control.is_single_page {
    grid: 'from to' 'pair pair' 'btn btn';
    grid-row-gap: 16px;
  }
  .side-layout-order .button-side-layout-order {
    margin-top: 1px;
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

.dark-theme {
  #trade-history-convert .is_single_page .vdp-datepicker input {
    background-image: url("@/assets/images/icon/date-time-home-dark.svg") !important;
    background-repeat: no-repeat;
    background-size: 28px 24px;
  }
}
#trade-history-convert {
  #order-table-history.table-history {
    table {
      tbody {
        td:not(:last-child) {
          padding: 25px 24px 25px 24px;
        }
        .cl_pair {
          text-align: left !important;
          position: relative;
          padding-left: 0 !important;

          .currency-img {
            margin-left: -8px;
          }
        }
      }
    }
  }
  .vdp-datepicker {
    input {
      font-family: $font-inter-regular;
      font-size: $font-medium-bigger;
      background: $color-alabaster;
      height: 35px;
      cursor: pointer;
      width: 180px;
      background-image: url("@/assets/images/icon/date-time-order.svg") !important;
      background-repeat: no-repeat;
      background-size: 28px 24px;
    }
  }

  .date-to {
    input {
      height: 35px;
      width: 146px !important;
      border: none !important;
      border-radius: 0px 8px 8px 0px !important;
      background-position: top 9px right 16px !important;
    }
  }
  .date-from {
    input {
      background-image: none !important;
      padding: 0 0 0 16px !important;
      border: none !important;
      border-radius: 8px 0px 0px 8px !important;
      width: 116px !important;
    }
  }

  #order-table-history {
    table {
      background: transparent;
    }
  }
  .is_single_page {
    .search-type {
      .sc_search_select {
        .group_search_select {
          .box_list_search_select {
            left: 0 !important;
            ul {
              width: 95px !important;

              li {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          background-color: var(--color-tab-data-table) !important;
          padding: 14px 10px !important;
          height: 49px !important;
          border-radius: 8px !important;
          border: none !important;
          &.active {
            border: 1px solid $color-jungle-green;
          }
        }
        .list_search_select {
          background: var(--bgr-dropdown);
        }

        .box_list_search_select {
          left: -21px !important;
          font-family: $font-inter-regular;

          ul {
            width: 116px !important;
            padding: 15px 0;
            li {
              text-transform: uppercase;
              padding: 6px 20px;
              span {
                color: var(--color-dropdown);

                img {
                  margin-right: 5px;
                }
              }
              &:hover {
                background-color: $color-jungle-green;
                span,
                a {
                  color: $color-white;
                }
              }

              &:first-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                text-transform: capitalize !important;

                span {
                  color: var(--text-dropdown) !important;
                }
              }
            }
          }
        }
      }
    }
    .from-date {
      .vdp-datepicker__calendar {
        right: auto;
        left: 0px;
      }
    }
    .vdp-datepicker__calendar {
      width: 310px;
      padding: 20px 30px;
      border-radius: 8px;
      box-shadow: 0px 4px 10px $color-shadow;
      border: 1px solid $color-white;
      header {
        span.up {
          //width: 55.428571%;
        }
      }
      .cell {
        padding: 0;
        color: var(--text-primary-color);
        font-size: 16px;
        height: auto;
        line-height: 36px;
        @media screen and (max-width: 992px){
          font-size: $font-root;
          line-height: 38px;
        }
        &.day-header{
          color: var(--text-close);
          font-size: 16px;
          line-height: 19px;
          @media screen and (max-width: 992px){
            font-size: $font-root;
          }
        }
      }
      header {
        span {
          color: var(--text-primary-color);
          font-size: 16px;
          @media screen and (max-width: 992px) {
            font-size: $font-root;
          }
        }
      }
    }

    .to-date {
      .vdp-datepicker__calendar {
        right: 0;
        left: auto;
      }
    }

    .vdp-datepicker {
      input {
        padding: 5px 3px 5px 47px !important;
        background-position: top 11px left 14px !important;
        height: 49px !important;
        background-color: var(--color-tab-data-table) !important;
        margin-right: 0px !important;
        border-radius: 8px !important;
        color: var(--text-close) !important;
        font-size: 18px !important;
        border: none !important;
        &:focus {
          border: 1px solid $color-jungle-green;
        }
      }

      @media screen and (max-width: 576px) {
        input {
          height: 36px !important;
          background-position: top 5px left 12px !important;
        }
      }
    }
  }
  .side-layout-order {
    display: flex;
    flex-wrap: wrap;
  }
  .VuePagination {
    padding-top: 0px;
  }
}
@media screen and (max-width: 1550px) {
  #trade-history-screen .order-group-screen {
    // padding: 40px 17px 170px 17px;
    position: relative;
  }
  #order-table-history.history-table {
    .VuePagination {
      position: unset;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto;
      bottom: 60px;
    }
  }
  .trade_layout_convert_history {
    #order-table-history.history-table {
      padding-bottom: 0px;
    }
    
  }
}

@media screen and (max-width: 992px) {
  #trade-history-convert .group-control.is_single_page .group-date-picker .group-date {
    height: 44px!important;
    .vdp-datepicker {
      height: 44px!important;
      input {
        height: 44px!important;
        background-position: top 8px left 10px !important;
      }
    }
  }
  #trade-history-convert .group-control.is_single_page {
    .vdp-datepicker input {
      width: 100%;
    }
  }

  #trade-history-convert .is_single_page .vdp-datepicker input {
    font-size: 14px !important;
  }
  #trade-history-convert
    .group-control.is_single_page
    .group-date-picker
    .group-date {
    width: 100%;
  }
  #trade-history-convert
    .group-control.is_single_page
    .group-date-picker
    .group-date
    .vdp-datepicker {
    width: 100%;
  }
  #trade-history-convert
    .is_single_page
    .sc_search_select
    .group_search_select
    .button_drop_search {
    height: 36px !important;
    padding: 9px 10px !important;
  }
  #trade-history-convert .group-control.is_single_page .toolbar-element input {
    height: 36px !important;
    font-size: 14px !important;

    &::placeholder {
      font-size: 14px !important;
      line-height: 16px;
    }
  }
  #trade-history-convert .group-control.is_single_page {
    .group-pair,
    .group-type {
      width: 100%;
      margin-top: 8px;
      margin-right: 0 !important;
    }
  }

  .label-text {
    font-size: $font-root !important;
  }

  .content-search {
    display: flex;
    justify-content: space-between;
    .toolbar-element {
      width: 48% !important;
      .coin-input {
        input {
          width: 100%;
        }
      }
    }
    .toolbar-element2 {
      width: 48% !important;
    }
  }
  #trade-history-convert {
    .group-control {
      &.is_single_page {
        .coin-input {
          width: 100%;
          .box-coin-option {
            max-width: 100% !important;
          }
        }
        .group-type {
          .button-search {
            &:hover {
              background-color: $color-caribbean-green;
              border-color: $color-caribbean-green;
              color: $color-grey-dark;
              -webkit-transition: 0.5s;
              transition: 0.5s;
            }
          }
          .button-reset {
            font-size: 14px !important;
          }
        }
      }
    }
    .is_single_page {
      .sc_search_select {
        .group_search_select {
          .box_list_search_select {
            left: 0px !important;
            width: 100%;
            ul {
              width: 100% !important;
              left: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>

