<template>
<div class="market-footer" id="id-market-footer">
    <span class="margin_auto">
        <span class="date_market_footer"><digital-clock/></span>
        <span class="txt_volume_24">{{ $t('exchange.basic.trading_page.volume_24h') }} </span>
        <template v-for="(currency, index) in markets">
          <div class="group_mk_ft">
            <!-- <span class="margin_ri/ght text-grey" v-if="index !== 0">/</span> -->
            <span class="nb_mk_ft"> {{ volumes[currency] | formatCurrencyAmount(currency, '0') }}</span>
            <span class="text-grey-dusty margin_right">{{ currency | uppercase }}</span>
          </div>
        </template>

    </span>
</div>
</template>

<script>
  import _ from 'lodash';
  import BigNumber from 'bignumber.js';
  import DigitalClock from '@/components/salespoint_pages/exchange/basic/DigitalClock';
  import rf from '@/request/RequestFactory'
  import COMMON_CONST from '@/common/Const';

  export default {
    components: {
      DigitalClock
    },
    data() {
      return {
        markets : COMMON_CONST.MARKETS,
        volumes: [],
        prices: {},
      }
    },
    methods: {
      onPricesUpdated(data) {
        this.prices = Object.assign({}, this.prices, data);
        const volumes = {};

        _.forEach(this.prices, (coin) => {
          if (this.markets.includes(coin.currency)) {
            const total = volumes[coin.currency] || 0;
            volumes[coin.currency] = new BigNumber(`${total}`).add(coin.volume).toString();
          }
        });

        this.volumes = volumes;
      },
      initData() {
        rf.getRequest('PriceRequest').getPrices24h().then(res => {
          this.onPricesUpdated(res.data);
        });
      },
    },
    mounted() {
      this.initData();
      this.$on('PricesUpdated', data => {
        this.onPricesUpdated(data);
      });
    },
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
  #id-market-footer{
    .date_market_footer {
      #clock-timer {
      color: $color-grey-dusty;
      }
    }
  }
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .market-footer {
      width: 100%;
      color: $color-grey-dusty;
      font-size: $font-small;
      background-color: $color-athens-gray; 
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
  }
  .group_mk_ft {
    display: inline-block;
    float: none;
    margin: 10px 15px;
  }
  .margin_auto {
    width: 100%;
    text-align: center;
    padding: 9px 0px 8px 0px;
  }
  .group_mk_ft {
    color: $color-grey-dusty;
    font-size: $font-small;
    line-height: 22px;
    text-transform: uppercase;
    .nb_mk_ft {
      font-size: $font-medium-bigger;
      color: $color-grey-dark;
      margin-right: 2px;
    }
  }
  .date_market_footer {
    display: inline-block;
    color: $color-grey-dusty;
    text-align: center;
    font-size: $font-medium-bigger;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    margin-right: 10px;
  }
  .text-grey-dusty {
    color: $color-grey-dusty;
  }
  .txt_volume_24{   
    margin: 10px 15px; 
    display: inline-block;
    color: $color-grey-dusty;
    text-align: center;
    font-size: $font-medium-bigger;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }
</style>