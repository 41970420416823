<template>
  <div>
    <modal :name="modalName" width="500">
      <template slot="body">
        <h3 class="box-title-modalsub"><span>{{ $t('mam.manage_sub_orders') }}</span></h3> 
        <div class="modalsub_container">
          <data-table ref="datatable" :get-data="getData" :limit="10">
            <th>{{ $t('orders.open_order.amount') }}</th>
            <th>{{ $t('exchange.basic.trading_page.volume') }}</th>
            <th class="th_sub_add"></th>
            <template slot="body" slot-scope="props">
              <tr>
                <td :title="props.item.Amount">{{ props.item.Amount }}</td>
                <td :title="props.item.Volume">{{ props.item.Volume }}</td>
                <td class="th_sub_add">
                  <button class="btn btn-cancel">{{ $t('notice.cancel') }}</button>
                </td>
              </tr>
            </template>
          </data-table>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from './Modal';
  import rf from '@/request/RequestFactory'

  export default {
    components: {
      Modal,
    },
    data() {
      return {
        modalName:'ModalSub',
      }
    },
    computed: {
       
    },
    methods: {
      getData(params) {
        return new Promise(resolve => {
          return resolve(
            [ 
              {Amount: '1000000123', Volume: '100.000'},
              {Amount: '1000000123', Volume: '100.000'},
              {Amount: '1000000123', Volume: '100.000'},
              {Amount: '1000000123', Volume: '100.000'},
              {Amount: '1000000123', Volume: '100.000'},
              {Amount: '1000000123', Volume: '100.000'},
            ]
          );
        });
      },
      closeModal () {
        this.$emit(`${this.modalName}:CLOSE`);
      },      
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .box-title-modalsub {
    margin: 5px 0px 0px 0px;
    background-color: $color-white;
    text-align: center;
    overflow: hidden;
    padding-bottom: 20px;
    span {
      display: inline-block;
      padding: 5px 10px;
      position: relative;
      font-size: $font-big-20;
      font-family: $font-family-roboto-medium;
      color: $color-eden;
      line-height: 30px;
      &:before {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: -60px;
      }
      &:after {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: -60px;
      }
    }
  }
  .modalsub_container {
    padding-bottom: 15px;
    background-color: $color-alabaster;
    th {
      font-size: $font-small;
      color: $color-grey;
      font-family: $font-family-roboto-medium;
      line-height: 20px;
      padding: 15px 5px 15px 20px;
      text-align: left;
    }
    tr {
      td {
        font-size: $font-root;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        line-height: 25px;
        padding: 8px 5px 7px 20px;
        text-align: left;
      }
    }
    .btn-cancel {
      height: 25px;
      float: right;
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
      color: $color-jungle-green;
      width: 70px;
      text-align: center;
      overflow: hidden;
      padding: 0px;
      line-height: 20px;
      letter-spacing: 0.9px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      background-color: $color-alabaster;
      font-size: $font-smaller;
      padding-top: 1px;
      &:hover {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
        color: $color-white;
      }
    }
  }

</style>

<style lang="scss">
  @import "@/assets/sass/variables";
  #ModalSub {
    .modal-footer {
      display: none;
    }
    .modal-body {
      padding: 0px;
    }
    table {
      tbody {
        tr.empty-data:hover {
          background-color: transparent !important;
        }
      }
    }
  }
</style>