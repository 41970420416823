<template>
  <!-- <div class="max-w-md mx-auto"> -->
  <!-- Coin List -->
  <!-- <div v-for="coin in coins" :key="coin.coin" class="list"> -->
  <!-- Coin Container -->
  <div class="list">
    <div class="item">
      <div class="flex-sb">
        <div class="img-box">
          <div>
            <img
              v-if="coinImages[coin?.coin]"
              class="icon-coin"
              :src="coinImages[coin?.coin]"
              alt=""
            />
            <img
              v-else
              class="icon-coin"
              :src="getDefaultIcon(coin?.coin)"
              alt=""
            />
          </div>
          <div class="coin-name">{{ coin.name }}</div>
        </div>
        <!-- Dropdown Trigger -->
        <div
          v-if="
            coin.isDeposit ||
            coin.isWithdraw ||
            transferType('COIN_M', coin.coin) ||
            (transferType('FUTURE', coin.coin) && coin.coin !== 'usd')
          "
          class="btn-dropdown"
          v-click-outside="closeDropdown"
        >
          <div @click="toggleMenu" class="btn-icon">
            <img
              :src="
                require(`@/assets/images/wallet/more-horizontal${dark}.png`)
              "
            />
            <!-- Dropdown Menu -->
            <div v-if="activeMenu" class="menu-dropdown">
              <span class="w-full text-left p-2 menu-item">
                <router-link
                  v-if="coin.isDeposit"
                  :to="
                    coin.coin == 'usd'
                      ? { name: 'Deposit USD Wallet' }
                      : {
                          name: 'Deposits Wallet',
                          query: { coin: coin.coin },
                        }
                  "
                >
                  {{ $t("funds.balances.deposit") }}
                </router-link>
              </span>
              <span class="w-full text-left p-2 menu-item">
                <router-link
                  v-if="coin.isWithdraw"
                  :to="
                    coin.coin == 'usd'
                      ? { name: 'Withdraw USD Wallet' }
                      : {
                          name: 'Withdrawals Wallet',
                          query: { coin: coin.coin },
                        }
                  "
                >
                  {{ $t("funds.balances.withdrawal") }}
                </router-link>
              </span>
              <span
                class="w-full text-left p-2 menu-item"
                @click="handleActiveRow(coin)"
                v-bind:class="{ acvite_transfer: coin.isActiveRow }"
                v-if="
                  (transferType('COIN_M', coin.coin) ||
                    transferType('FUTURE', coin.coin)) &&
                  coin.coin !== 'usd'
                "
                ><a> {{ $t("funds.balances.tranfer") }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-sb pl-36">
        <div class="text-start">
          <div class="text-title">{{ $t("funds.balances.name") }}</div>
          <div class="text-name">{{ coin.name }}</div>
        </div>

        <div class="text-end">
          <div class="text-title">{{ $t("funds.balances.total_balance") }}</div>
          <div class="text-name">
            <span v-if="!tongglePrice">
              {{ coin.totalBalance | formatNumberAndDecimal }}
            </span>
            <span v-else>******</span>
          </div>
        </div>
      </div>

      <div class="flex-sb pl-36">
        <div class="text-start">
          <div class="text-title">
            {{ $t("funds.balances.avaliable_balance") }}
          </div>
          <div class="text-name">
            <span v-if="!tongglePrice">
              {{ coin.availableBalance | formatNumberAndDecimal }}
            </span>
            <span v-else>******</span>
          </div>
        </div>

        <div class="text-end">
          <div class="text-title">
            {{ $t("funds.balances.available") }} /<br />
            {{ $t("funds.balances.total_futures_balance") }}
          </div>
          <div class="text-name">
            <div v-if="!tongglePrice">
              <span v-if="coin.marginAvailabelBalance > 0">
                {{
                  displayPositive(coin.marginAvailabelBalance)
                    | formatNumberAndDecimal
                }}
              </span>
              <span v-else>0</span>
              /
              <span v-if="coin.marginBalance > 0">
                {{
                  displayPositive(coin.marginBalance) | formatNumberAndDecimal
                }}
              </span>
              <span v-else>0</span>
            </div>
            <div v-else>******</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import BTCIcon from "@/assets/images/icon/coin/color/btc.png";
import XRPIcon from "@/assets/images/icon/coin/color/xrp.png";
import EOSIcon from "@/assets/images/icon/coin/color/eos.png";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";
import { mapState } from "vuex";

export default {
  props: {
    coin: {
      type: Object,
      // required: true,
    },
    coinImages: {
      type: Object,
      default: () => ({}),
    },
    transferType: {
      type: Function,
    },
    tongglePrice: {
      type: Boolean,
    },
    displayPositive: {
      type: Function,
    },
    onActiveRow: {
      type: Function,
    },
  },
  data() {
    return {
      activeMenu: false,
      menuOptions: ["Deposit", "Withdrawal", "Transfer"],
      isLoaded: false,
      coins: [{ coin: "bnb", totalBalance: 12, futuresBalance: 123 }],
      btcicon: BTCIcon,
      xrpicon: XRPIcon,
      eosicon: EOSIcon,
      ethicon: ETHIcon,
    };
  },

  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  methods: {
    toggleMenu() {
      if (!this.activeMenu) {
        this.activeMenu = true;
      }
    },
    closeDropdown() {
      this.activeMenu = false;
    },
    handleOptionClick(option) {
      this.closeDropdown();
    },

    getDefaultIcon(coin) {
      return coin === "btc"
        ? this.btcicon
        : coin === "xrp"
        ? this.xrpicon
        : coin === "eos"
        ? this.eosicon
        : null;
    },
    handleActiveRow(coin) {
      this.onActiveRow(coin);
    },
  },
};
</script>

<style scoped lang="scss">
.pl-36 {
  padding-left: 36px;
}
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    position: relative;
    .img-box {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--color-text);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      img.icon-coin {
        width: 28px;
        height: 28px;
        border-radius: 28px;
      }
    }

    .btn-dropdown {
      position: relative;
      .btn-icon {
        width: 24px;
        height: 24px;
      }
      .menu-dropdown {
        position: absolute;
        right: 0;
        background: var(--White, #fff);
        border-radius: 16px;

        /* Shadow/sm */
        box-shadow: 0px 1px 3px 0px rgba(23, 23, 23, 0.1),
          0px 1px 2px 0px rgba(23, 23, 23, 0.06);

        .menu-item {
          cursor: pointer;
          display: block;
          padding: 12px;
          color: var(--color-tex);
          font-size: $font-smaller;
          font-weight: 500;
          line-height: 18px;
          border-radius: 16px;
          a {
            color: var(--color-tex);
          }
        }
      }
    }

    .text-title {
      color: $color-gray-dark;
      font-size: $font-smaller;
      font-weight: 500;
      line-height: 18px; /* 150% */
    }
    .text-name {
      color: var(--color-text);
      font-size: $font-root;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
</style>
