<template>
  <div
    id="open-order"
    class="open_order_s"
    :class="{
      no_padding: options && options.isNoPadding,
      trade_layout: openOrderLayout === 'trade',
      order_layout: openOrderLayout !== 'trade',
    }"
  >
    <modal-sub />
    <data-table3
      ref="datatable"
      :get-data="getData"
      :typeEmpty="openOrderLayout !== 'trade' && !typeEmpty"
      :tableOpenOrder="tableOpenOrder"
      :msg-empty-data="$t('order.open_order.no_data')"
      id="order-table"
      :limit="6"
      :hasLayoutMobile="true"
    >
      <template slot-scope="props">
        <th
          v-for="(item, index) in headerTable"
          :key="index"
          :title="$t(item && item.name)"
          :data-sort-field="
            item && item.id !== 'triggerConditions' ? item.id : null
          "
          @click="props.echoclick"
          :class="{ cursor: item.id === 'triggerConditions' }"
        >
          <span>{{ $t(item.name) }}</span>
          <i
            v-if="item.id !== 'triggerConditions'"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th class="cancel-box text-right">
          <cancel-box @action-cancel-box="actionCancelOpenOrder" />
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td class="cl_date" :title="props.item.created_at | timestampToDate">
            {{ props.item.created_at | timestampToDate }}
          </td>
          <td
            class="cl_coin"
            :title="`${props.item.coin} / ${props.item.currency}` | uppercase"
          >
            <img
              :src="`${coinImages[props?.item?.coin]}`"
              alt=""
              width="30"
              height="30"
              class="coin-img"
            />
            <img
              :src="`${currencyCoinsImages[props?.item?.currency]}`"
              alt=""
              width="30"
              height="30"
              class="currency-img"
            />
            {{ props.item.coin | uppercase }}/{{
              props.item.currency | uppercase
            }}
          </td>
          <td :title="props.item.type | uppercaseFirst">
            {{ localizeType(props.item.type) | uppercaseFirst }}
          </td>
          <td
            :class="{
              blue: props.item.trade_type === 'buy',
              red: props.item.trade_type === 'sell',
            }"
            :title="props.item.trade_type | tradeType"
          >
            {{ props.item.trade_type | tradeType }}
          </td>
          <td class="cl_price">
            <span
              v-if="
                props.item.type == 'market' || props.item.type == 'stop_market'
              "
              :title="$t('order.open_order.market')"
            >
              {{ $t("order.open_order.market") }}</span
            >
            <span
              v-else
              :title="
                props.item.price
                  | formatCurrencyAmount(props.item.currency, '0')
              "
            >
              <template v-if="isHasHover">{{
                props.item.price | formatNumberAndDecimal
              }}</template>
              <template v-else>{{
                props.item.price | formatNumberAndDecimal
              }}</template>
            </span>
          </td>
          <td
            class="cl_amount"
            :title="props.item.quantity | formatNumberAndDecimal"
          >
            <template v-if="isHasHover">{{
              props.item.quantity | formatNumberAndDecimal
            }}</template>
            <template v-else>{{
              props.item.quantity | formatNumberAndDecimal
            }}</template>
          </td>
          <td
            :title="
              props.item.executed_quantity
                | toNumber
                | divBigNumber(props.item.quantity)
                | floatToPercent
            "
          >
            {{
              props.item.executed_quantity
                | toNumber
                | divBigNumber(props.item.quantity)
                | floatToPercent
                | formatNumberAndDecimal
            }}
          </td>
          <td
            class="cl_total"
            :title="
              mulBigNumber(props.item.quantity, props.item.price)
                | formatNumberAndDecimal
            "
          >
            <template v-if="isHasHover">{{
              mulBigNumber(props.item.quantity, props.item.price)
                | formatNumberAndDecimal
            }}</template>
            <template v-else>{{
              mulBigNumber(props.item.quantity, props.item.price)
                | formatNumberAndDecimal
            }}</template>
          </td>
          <td class="cl_trigger">
            {{ props.item.stop_condition | convertConditionStatus }}
            {{ props.item.base_price | formatNumberAndDecimal }}
          </td>
          <td class="t-right cursor-pointer">
            <span
              class="cursor-pointer"
              @click="actionCancelOpenOrder('one', props.item.id)"
              >{{ $t("common.action.cancel") }}</span
            >
          </td>
        </tr>
      </template>
      <template slot="header-action-mobile">
      <div class="item-row">
        <div class="item-row-flex">
            <div class="checkbox-function">
              <label
                for="check_open_order_id"
                :class="{ check: isHideOtherPair === true }"
              >
                <input
                  type="checkbox"
                  name="checkbox"
                  v-model="isHideOtherPair"
                  value="check"
                  id="check_open_order_id"
                  @change="hideOtherPair()"
                />
                <span class="button-check"></span>
                {{
                  isCurrentTab === commonConst.FUNDS
                    ? $t("order.open_order.hide_small_assets")
                    : $t("order.open_order.hide_other_pairs")
                }}
              </label>
            </div>
            <div class="btn-cancel">
              <cancel-box @action-cancel-box="actionCancelOpenOrder" />
            </div>
          </div></div>
      </template>
      <template slot="body-mobile" slot-scope="props">
        <div class="item-row">
        
          <!-- <div class="item-row-flex">
            <date-tab
              class="date-tab"
              @change-select-date="changeDate"
              ref="selectDateTab"
            />
            <img :src="require('/src/assets/images/iconFilter.svg')" alt="" />
          </div> -->
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above">
                {{ props.item.created_at | timestampToDate }}</span
              >
              <span class="text-below"> {{$t("order.open_order.date")}} </span>
            </div>
            <div class="item-right">
              <span class="text-above">
                {{ `${props.item.coin} / ${props.item.currency}` | uppercase }}
              </span>
              <span class="text-below"> {{$t("order.open_order.pair")}} </span>
            </div>
          </div>
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above">
                {{ localizeType(props.item.type) | uppercaseFirst }}
              </span>
              <span class="text-below"> {{ $t("order.open_order.type") }} </span>
            </div>
            <div class="item-right">
              <span
                class="text-above"
                :class="{
                  blue: props.item.trade_type === 'buy',
                  red: props.item.trade_type === 'sell',
                }"
                >{{ props.item.trade_type | tradeType }}</span
              >
              <span class="text-below"> {{$t("order.open_order.side")}} </span>
            </div>
          </div>
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above">
                <span
                  v-if="
                    props.item.type == 'market' ||
                    props.item.type == 'stop_market'
                  "
                  :title="$t('order.open_order.market')"
                >
                  {{ $t("order.open_order.market") }}</span
                >
                <span
                  v-else
                  :title="
                    props.item.price
                      | formatCurrencyAmount(props.item.currency, '0')
                  "
                >
                  <template v-if="isHasHover">{{
                    props.item.price | formatNumberAndDecimal
                  }}</template>
                  <template v-else>{{
                    props.item.price | formatNumberAndDecimal
                  }}</template>
                </span></span
              >
              <span class="text-below"> {{$t("order.open_order.price")}} </span>
            </div>
            <div class="item-right">
              <span class="text-above">
                {{ props.item.quantity | formatNumberAndDecimal }}</span
              >
              <span class="text-below"> {{$t("order.open_order.amount")}} </span>
            </div>
          </div>
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above">
                {{
                  props.item.executed_quantity
                    | toNumber
                    | divBigNumber(props.item.quantity)
                    | floatToPercent
                    | formatNumberAndDecimal
                }}
              </span>
              <span class="text-below"> {{$t("order.open_order.filled")}} </span>
            </div>
            <div class="item-right">
              <span class="text-above">
                {{
                  mulBigNumber(props.item.quantity, props.item.price)
                    | formatNumberAndDecimal
                }}
              </span>
              <span class="text-below"> {{$t("order.open_order.total")}} </span>
            </div>
          </div>
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above">
                {{ props.item.stop_condition | convertConditionStatus }}
                {{ props.item.base_price | formatNumberAndDecimal }}
              </span>
              <span class="text-below"> {{ $t("order.open_order.trigger_conditions") }} </span>
            </div>
            <div class="item-right"></div>
          </div>
          <div class="line" />
        </div>
      </template>
    </data-table3>
  </div>
</template>

<script>
import OPEN_ORDER_HEADER_CONST from "./DataTableInit.js";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import DateTab from "../common/DateTab.vue";
import CancelBox from "@/components/shared_components/common/CancelBox";
import ModalSub from "@/components/shared_components/common/ModalSub";
import BigNumber from "bignumber.js";
import { mapState } from "vuex";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";

const ORDER_TYPE_LIMIT = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_LIMIT;
const ORDER_TYPE_MARKET = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_MARKET;
const ORDER_TYPE_STOP_LIMIT = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_STOP_LIMIT;
const ORDER_TYPE_STOP_MARKET =
  COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_STOP_MARKET;
const ORDER_TYPE_ALL = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_ALL;

export default {
  components: {
    DateTab,
    CancelBox,
    ModalSub,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    coinCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currencyCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
    tableOpenOrder: {
      type: Boolean,
      default: false,
    },
    openOrderLayout: {
      type: "trade" | "order",
      default: "order",
    },
    typeEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTable: OPEN_ORDER_HEADER_CONST.OPEN_ORDER_HEADER,
      commonConst: COMMON_CONST,
      isHide: false,
      deleteType: COMMON_CONST.DEFAULT,
      coin: this.coinCode,
      currency: this.currencyCode,
      fetchingOpenOrder: false,
      coinsMasterData: {},
      coinNames: {},
      coinImages: {},
      currencyCoinNames: {},
      currencyCoinsImages: {},
      isHasHover: window.matchMedia("(min-width: 1150px)").matches,
      ethicon: ETHIcon,
      isHideOtherPair: false
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
    }),
    openOrders() {
      return [
        {
          id: 1,
          created_at: new Date().toISOString(),
          coin: "BTC",
          currency: "USD",
          type: "limit",
          trade_type: "buy",
          price: "20000",
          quantity: "0.5",
          executed_quantity: "0.2",
          stop_condition: ">=",
          base_price: "19000",
        },
        {
          id: 2,
          created_at: new Date().toISOString(),
          coin: "ETH",
          currency: "USD",
          type: "market",
          trade_type: "sell",
          price: "1500",
          quantity: "1.2",
          executed_quantity: "1.0",
          stop_condition: "<=",
          base_price: "1400",
        },
      ];
    },
  },
  methods: {
    hideOtherPair() {
      this.$emit("action-navi-tab", {
        action: this.commonConst.ACTION_HIDE_OTHER_PAIR,
        isCheck: this.isHideOtherPair,
      });
    },
    handleWindowResize() {
      this.isHasHover = window.matchMedia("(min-width: 1150px)").matches;
    },
    localizeType(type) {
      if (type == "limit") return this.$t("order.order_form.limit");
      if (type == "market") return this.$t("orders.open_order.market");
      if (type == "stop_market")
        return this.$t("order.order_form.stop_market_table");
      if (type == "stop_limit")
        return this.$t("order.order_form.stop_limit_table");
    },
    mulBigNumber(number1, number2) {
      if (!number1 || !number2) return "0";
      return new BigNumber(number1).mul(number2).toFixed(15).toString();
    },
    isIE() {
      let ua = navigator.userAgent;
      var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
      if (is_ie && $("table").length > 0) {
        $("#open-order table").addClass("fixieCancelbox-full");
      }
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        SpotOrderListUpdated: this.onOrderListUpdated,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
    },
    onOrderListUpdated(data, isHideOtherPair) {
      if (!this.fetchingOpenOrder) {
        this.fetchingOpenOrder = true;
        if (isHideOtherPair) {
          this.$refs.datatable.filter({
            coin: this.coin,
            currency: this.currency,
          });
        } else {
          this.refreshTable();
        }
      }
    },
    refreshTable() {
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    getData(params) {
      if (!window.isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      if (this.openOrderLayout === "order") {
        // const el = document.getElementById('open-order');
        // if (el) {
        //   el.scrollIntoView({behavior: "smooth"});
        // }
      }

      return rf
        .getRequest("OrderRequest")
        .getOrdersPending(params)
        .then((res) => {
          this.fetchingOpenOrder = false;
          this.$emit("dataFromChild", res.data.total);
          return res;
        });
    },
    showModalSub() {
      window.CommonModal.show("ModalSub");
    },
    actionCancelOpenOrder(deleteType = null, id = null) {
      const type = deleteType ? deleteType : this.deleteType;
      if (this.$refs.datatable.rows.length === 0) {
        this.showErrorToastMessageWithType(type);
        return;
      }

      if (
        (!deleteType && this.deleteType === this.commonConst.DEFAULT) ||
        this.$refs.datatable.rows.length === 0
      ) {
        return;
      }

      this.confirmCancelOrder(deleteType, id);
    },

    showErrorToastMessageWithType(type) {
      let message = "";
      let subMessage = "123";
      switch (type) {
        case ORDER_TYPE_LIMIT:
          message = window.i18n.t("order.open_order.no_data.limit");
          subMessage = window.i18n.t(
            "order.open_order.no_data.limit_sub_message"
          );
          break;
        case ORDER_TYPE_MARKET:
          message = window.i18n.t("order.open_order.no_data.market");
          subMessage = window.i18n.t(
            "order.open_order.no_data.market_sub_message"
          );
          break;
        case ORDER_TYPE_STOP_LIMIT:
          message = window.i18n.t("order.open_order.no_data.stop_limit");
          subMessage = window.i18n.t(
            "order.open_order.no_data.stop_limit_sub_message"
          );
          break;
        case ORDER_TYPE_STOP_MARKET:
          message = window.i18n.t("order.open_order.no_data.stop_market");
          subMessage = window.i18n.t(
            "order.open_order.no_data.stop_market_sub_message"
          );
          break;
        case ORDER_TYPE_ALL:
        default:
          message = window.i18n.t("order.open_order.no_data.all");
          subMessage = window.i18n.t(
            "order.open_order.no_data.all_sub_message"
          );
          break;
      }
      this.showOrderWarningMessage(message, subMessage);
    },

    confirmCancelOrder(type, id = "") {
      let confirmationMessage = "";
      let titleModal = "";
      switch (type) {
        case "one":
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_one_message"
          );
          titleModal = window.i18n.t("order.open_order.cancel_one_title");
          break;
        case ORDER_TYPE_ALL: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_all_message"
          );
          titleModal = window.i18n.t("order.open_order.cancel_all_order");
          break;
        }
        case ORDER_TYPE_LIMIT: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_limit_price_message"
          );
          titleModal = window.i18n.t(
            "order.open_order.cancel_limit_order_title"
          );
          break;
        }
        case ORDER_TYPE_MARKET: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_market_orders_message"
          );
          titleModal = window.i18n.t(
            "order.open_order.cancel_market_orders_title"
          );
          break;
        }
        case ORDER_TYPE_STOP_LIMIT: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_stop_limit_price_message"
          );
          titleModal = window.i18n.t(
            "order.open_order.cancel_stop_limit_price_title"
          );
          break;
        }
        case ORDER_TYPE_STOP_MARKET: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_stop_market_orders_message"
          );
          titleModal = window.i18n.t(
            "order.open_order.cancel_stop_market_orders_title"
          );
          break;
        }
        default:
          return;
      }
      ConfirmationModal.show({
        type: "primary",
        title: titleModal,
        content: confirmationMessage,
        btnCancelLabel: window.i18n.t("common.action.close"),
        btnConfirmLabel: window.i18n.t("common.action.confirm"),
        onConfirm: () => {
          this.cancelOrder(type, id);
        },
        onCancel: function () {},
        icon: "",
        textAlign: "left",
        class: "open-order-modal",
      });
    },
    cancelOrder(type, id = "") {
      this.isShowConfirmDialog = false;
      let message = window.i18n.t("order.open_order.cancel_order_success");
      switch (type) {
        case "one":
          message = window.i18n.t("order.open_order.cancel_order_success");
          this.requestHandler(
            rf.getRequest("OrderRequest").cancel(id),
            message,
            type
          );
          break;

        case "all":
          this.requestHandler(
            rf.getRequest("OrderRequest").cancelAll(),
            message,
            type
          );
          break;
        default:
          this.requestHandler(
            rf.getRequest("OrderRequest").cancelByType({ type: type }),
            message,
            type
          );
          break;
      }
    },
    requestHandler(promise, message, type) {
      promise
        .then((res) => {
          this.showSuccessMessage(message);
          this.$store.dispatch("setRecallBalances", true);
        })
        .catch((error) => {
          if (!error.response) {
            this.showErrorMessage(
              window.i18n.t("common.message.network_error")
            );
          } else {
            // this.showErrorMessage(error.response.data.message);
            this.showErrorToastMessageWithType(type);
          }
        });
    },
    showSuccessMessage(message) {
      this.$refs.datatable.refresh();
      Message.success(message, {}, { position: "bottom_left" });
    },
    showErrorMessage(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },
    showOrderWarningMessage(message, subMessage) {
      Message.orderWarning(
        message,
        subMessage,
        {},
        { position: "bottom_left" }
      );
    },
    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(
        this.masterData.coins_confirmation,
        (item) => item.coin
      );
      this.coinsMasterData = window._.keyBy(
        this.masterData.coins,
        (item) => item.coin
      );
      this.coinsMasterData.usd = {
        name: window.i18n.t(`currency.usd.fullname`),
      };

      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
        this.currencyCoinNames[item.coin] = item.name;
        this.currencyCoinsImages[item.coin] = item.icon_image;
      });

      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon;
      this.currencyCoinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.currencyCoinsImages["usd"] = `/images/color_coins/usd.png`;
      this.currencyCoinsImages["eth"] = this.ethicon;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
    this.isIE();
    this.handleMasterData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.item-row {
  width: 100vw !important;
  padding: 16px 16px;
  .line {
    background-color: #8e8e92;
    height: 1px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .item-row-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .item-left {
      display: flex;
      flex-direction: column;
    }
    .item-right {
      display: flex;
      flex-direction: column;
      text-align: right;
    }
    .text-above {
      font-size: 12px;
      line-height: 18px;
      color: var(--text-check-label);
      font-weight: 400;

      &.blue{
        color:#01BC8D
      }

      &.red{
color:#d92d20;
      }
    }
    .text-below {
      font-size: 12px;
      line-height: 18px;
      color: #8e8e92;
      font-weight: 400;
    }
    .checkbox-function {
      color: var(--text-check-label);
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
    .btn-cancel {
      color: #03aac7;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}

.trade_layout {
  #order-table table tbody tr td:last-child {
    text-align: center;
  }
}
#order-table ::v-deep table {
  margin-bottom: 20px;
  thead tr th:last-child {
    padding-right: 40px;
  }
}

::v-deep {
  #cancel-box .dropdown button {
    text-align: right;

    &:after {
      display: none;
    }
  }
}
.order_layout {
  ::v-deep {
    .VuePagination {
      padding-top: 0;
    }
  }
  #order-table table tbody tr td {
    font-size: 14px;
    color: var(--text-transfer);
  }
}
</style>
<style lang="scss">
@import "@/assets/sass/variables";

.w-15 {
  width: 15%;
}
.dark-theme {
  #open-order {
    .VuePagination {
      background: $color-cinder !important;
      ul {
        margin: 0 !important;
        padding: 20px 0 !important;
      }
    }
    .tableContainer3 {
      //min-height: 500px;

      table {
        background: transparent;
        margin-bottom: 0;
        thead {
          tr {
            th {
              color: $color-storm-grey !important;
            }
          }
        }

        tbody {
          tr {
            td {
              color: white !important;
            }
            .red {
              color: $text-color-red !important;
            }
            .blue {
              color: $text-color-jade !important;
            }
          }
          //.empty-data {
          //  background: transparent;
          //}
          .empty-data {
            background: transparent;
            //background: $color-cinder;
          }
        }
      }
    }
  }
}

.trade_layout {
  .tableContainer3 {
    margin-top: 30px;
    overflow-x: auto;
    min-width: 1201px;
    //min-height: 500px;
    @media only screen and (max-width: 992px) {
      min-width: 100%;
    }

    table {
      thead {
        tr {
          height: auto !important;
          th {
            text-align: center;
            font-weight: 400;
          }
          th:first-child {
            text-align: left;
            width: 13%;
            padding-left: 40px;
            min-width: 200px;
          }

          th:nth-child(2) {
            width: 8%;
            min-width: 90px;
          }

          th:nth-child(3) {
            width: 7.5%;
            min-width: 80px;
          }
          th:nth-child(4) {
            width: 8.4%;
            min-width: 90px;
          }
          th:nth-child(5) {
            width: 9.2%;
            min-width: 100px;
          }
          th:nth-child(6) {
            width: 9.2%;
            min-width: 100px;
          }
          th:nth-child(7) {
            width: 7.5%;
            min-width: 80px;
          }
          th:nth-child(8) {
            width: 9.3%;
            min-width: 100px;
          }
          th:nth-child(9) {
            width: 14%;
            min-width: 150px;
          }
          th:last-child {
            width: 12%;
            min-width: 115px;
            .btn-cancel {
              padding-left: 0 !important;
            }
          }

          .cancel-box {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
          .btn-cancel {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
        }
      }

      tbody {
        tr {
          .coin-img,
          .currency-img {
            display: none;
          }
          td {
            border-top: 16px solid transparent;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            &:first-child {
              text-align: left !important;
              padding-left: 40px;
            }
          }

          .t-right {
            .image {
              img {
                display: none;
              }
            }
          }
          .cursor-pointer {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    @media screen and (min-width: 1700px) {
      width: 97%;
    }
  }
}

#order-table.open_order_s {
  overflow: initial;

  .red {
    color: $text-color-red !important;
  }
  .blue {
    color: $text-color-jade !important;
  }
  .btn-subs {
    width: 55px;
    height: 25px;
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
    color: $color-jungle-green;
    letter-spacing: 0.9px;
    font-size: $font-smaller;
    line-height: 20px;
    padding: 2px;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    float: right;
    &:hover,
    &:active,
    &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
    }
  }
  .btn-close-order {
    display: inline-block;
    float: right;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    font-size: $font-small;
    color: $color-jungle-green;
    cursor: pointer;
    &:hover {
      color: $color-dark-green;
    }
  }
  th.cancel-box {
    padding-right: 0px;
    #cancel-box {
      .dropdown {
        .dropdown-menu {
          display: block;
          border: none !important;
          font-size: 18px !important;
          li {
            a {
              font-size: 18px !important;
            }
          }
        }
      }
    }
  }

  .cancel-box {
    .dropdown-menu {
      border: none !important;
    }
  }

  th {
    font-family: $font-inter-regular;
    font-size: $font-medium-bigger;
  }

  tr {
    font-family: $font-inter-regular;
  }
  .VuePagination {
    // background-color: transparent;
  }
}
.order-group-screen {
  ::-webkit-scrollbar-thumb {
    background: var(--bgr-scroll);
  }
  ::-webkit-scrollbar-track {
    background: var(--bgr-scroll-track) !important;
  }
  .history-table {
    padding: 0px 0 150px 40px;
    max-width: 93%;
    @media screen and (max-width: 1550px) {
      max-width: 100%;
    }
  }

  .tableContainer3 {
    min-height: 500px !important;
    background: none !important;
    table {
      background: none !important;
    }
    th {
      color: $color-gray-dark;
      font-family: $font-inter-regular !important;
      font-size: 14px !important;
      /* background: var(--background-color-second); */
      height: 63px;
      &:first-child {
        border-radius: 8px 0 0 8px;
        padding-left: 24px;
        text-align: left;
      }

      &[data-sort-field="created_at"] {
        //width: 18.9%;
        min-width: 200px;
      }
      &[data-sort-field="coin"] {
        //width: 13%;
        text-align: left;
        min-width: 160px;
      }
      &[data-sort-field="type"] {
        //width: 7.1%;
        min-width: 90px;
      }
      &[data-sort-field="trade_type"] {
        min-width: 70px;
      }
      &[data-sort-field="executed_price"] {
        min-width: 70px;
      }
      &[data-sort-field="price"] {
        //width: 8.4%;
        text-align: center;
        min-width: 100px;
      }
      &[data-sort-field="quantity"] {
        //width: 7.5%;
        text-align: center;
        min-width: 100px;
      }
      &[data-sort-field="executed_quantity"] {
        //width: 7.1%;
        text-align: center;
        min-width: 100px;
      }
      &[data-sort-field="total"] {
        //width: 7.1%;
        text-align: center;
        min-width: 100px;
      }
      &:nth-child(9) {
        min-width: 90px;
      }
      &.trigger {
        //width: 11%;
        text-align: center;
        min-width: 135px;
        cursor: default;
      }
      &:last-child {
        //width: 11%;
        min-width: 130px;
        border-radius: 0px 8px 8px 0px;
      }
      @media screen and (max-width: 992px) {
        font-size: $font-root !important;
      }
    }

    tbody {
      td {
        padding: 20px 0;
        &:first-child {
          padding-left: 24px;
        }
      }
    }
    .cl_date {
      text-align: left !important;
      color: var(--color-text-content);
    }
    .cl_coin {
      //font-size: 18px !important;
      text-align: left !important;
      color: var(--color-text-content);
      img {
        object-fit: contain;
        border-radius: 50%;
      }
    }
    .cl_price,
    .cl_total,
    .t-right,
    .cl_trigger,
    .cl_amount {
      text-align: center !important;
      color: var(--color-text-content);
      .cancel {
        display: none;
      }
    }
    .cursor-pointer {
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
    .cl_coin {
      position: relative;

      .currency-img {
        margin-left: -8px;
      }
    }

    .empty-data {
      text-align: center !important;

      td {
        text-align: center !important;
        padding-top: 80px !important;
      }
    }
  }
}
.order-group-screen .table-open-order {
  table {
    tbody {
      td {
        //font-size: 18px !important;
        line-height: 21px;
      }
    }
  }
}

#order-table {
  //min-height: 300px;
  &.no-data {
    overflow: auto !important;
  }
  .cursor {
    cursor: default;
  }
  tbody {
    tr {
      td {
        font-family: $font-inter-regular !important;
        //font-size: 16px !important;
      }
    }
  }
  .VuePagination {
    background: none;
  }
  .pagination {
    justify-content: center;
  }
  table {
    width: 100%;
    overflow-y: initial;
    margin-bottom: 22px;
    font-family: $font-inter-regular;

    tbody {
      tr {
        td {
          font-family: $font-inter-regular !important;
          color: $color-grey-dark;
          //font-size: $font-medium-bigger!important;
          text-align: center;
        }
      }
    }
    background: $color-gray-alabaster;
    .empty-data {
      background: transparent;
    }
  }
  .icon-sort-table {
    font-size: 17px !important;
  }
}
#confirmationModal {
  &.open-order-modal {
    .tit_modal_confir {
      font-size: 34px;
      line-height: 46px;
    }
    .modal-dialog {
      width: 590px;
      @media only screen and (max-width: 414px) {
        width: 343px;
      }
      @media only screen and (max-width: 675px) {
        width: 500px;
      }
      width: 590px;
      .modal-content {
        .modal-body {
          .content_text {
            font-size: 18px;
            line-height: 24px;
            @media only screen and (max-width: 675px) {
              font-size: 16px;
              line-height: 19px;
            }
          }
          button {
            &.btn-cancel {
              color: var(--color_dark_1);
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    &.order_open_modal {
      .tit_modal_confir {
        font-size: 20px;
        line-height: 27px;
      }
      button {
        &.btn-cancel,
        &.btn-confirm {
          line-height: 21px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .order-group-screen .tableContainer3 {
    th:nth-child(9) {
      min-width: 140px;
    }
    th:last-child {
      min-width: 137px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .order-group-screen .history-table {
    padding: 0;
  }
  .order-group-screen .table-open-order {
    margin-bottom: 122px;
    .VuePagination {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: -62px;
      padding: 0;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .trade_layout .tableContainer3 {
    table thead tr th {
      &:nth-child(5) {
        min-width: 130px;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .trade_layout .tableContainer3 table tbody tr td:first-child {
    padding-left: 20px;
  }
  #order-table {
    table {
      margin-bottom: 15px !important;
    }
  }
  .order-group-screen .table-open-order {
    margin-bottom: 122px;
    .VuePagination {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: -62px;
    }
  }
  .order-group-screen .table-open-order {
    margin-bottom: 153px;
    .VuePagination {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: -113px;
    }
  }
  .order-group-screen .history-table {
    padding: 0;
    max-width: 100%;
  }
  .order-group-screen {
    .tableContainer3 {
      min-height: auto;
    }
    .no_data {
      .tableContainer3 {
        min-height: auto !important;
      }
    }
  }
}
</style>
