<template>
  <div>
    <div class="amount-head">
      <label
        >{{
          $t("funds.withdrawals.amount", {
            coin_name: coin && coin.toUpperCase(),
          })
        }}
      </label>
      <span class="max-amount">
        {{ $t("funds.withdrawals.withdrawal_placeholder_available") }}:
        <span class="color-grey-dark">
          {{ calcAmountAvailable() | formatCurrencyAmount(coin, "0") }}
          {{ coin | uppercase }}
        </span>
      </span>
    </div>
    <div class="amount-box input-group">
      <span class="amount-input">
        <currency-input
          v-model="amount"
          :class="{ error: errors.has('amount') }"
          :changePrecision="true"
          :coin="this.coin"
          class="form-input txt-right"
          id="amount"
          :placeholder="$t('funds.history.amount')"
          @focus="$emit('focus')"
        />
        <span class="max-amount">
          <span class="set-max" @click="setMaxAmount()">{{
            $t("common.max_only")
          }}</span>
        </span>
      </span>
    </div>

    <p class="invalid-feedback" v-if="errors.has('amount')">
      {{ errors.first("amount") }}
    </p>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
export default {
  inject: ["$validator"],
  props: {
    value: { required: true },
    coin: { type: String, required: true },
    network: { type: String, required: false },
    withdrawLimit: { type: Object, required: true },
    balance: { type: Object, required: true },
    withdrawDaily: { type: String, default: "0" },
  },
  data() {
    return {
      amount: "",
      decimalCoin: null,
    };
  },

  computed: {
    maxWithdrawAvailable: function () {
      //[Withdrawal Limit 1 time]
      const limit = `${this.withdrawLimit.limit || 0}`;
      const fee = `${this.withdrawLimit.fee || 0}`;
      const dailyLimit = `${this.withdrawLimit.daily_limit || 0}`;

      //[Withdrawal used]
      const withdrawDaily = `${this.withdrawDaily || 0}`;
      const availableBalance = this.balance.available_balance || 0;

      //[24h Withdrawal Limit remaining]
      const withdrawDailyLimit = BigNumber.max(
        new BigNumber(dailyLimit).minus(withdrawDaily),
        "0"
      );

      let withdrawDailyAvailable = new BigNumber("0");

      const compare = new BigNumber(availableBalance).comparedTo(
        BigNumber.min(withdrawDailyLimit, limit).add(fee)
      );

      if (compare === -1) {
        withdrawDailyAvailable = new BigNumber(availableBalance);
      } else {
        withdrawDailyAvailable = BigNumber.min(withdrawDailyLimit, limit).add(
          fee
        );
      }

      return BigNumber.max(withdrawDailyAvailable, "0").toString();
    },

    externalAmount() {
      return new BigNumber(`${this.amount || 0}`)
        .sub(`${this.withdrawLimit.fee || "0"}`)
        .toString();
    },
  },

  watch: {
    value(val) {
      const externalAmount = new BigNumber(`${this.externalAmount || 0}`);
      if (externalAmount.comparedTo(`${val || 0}`) === 0) {
        return;
      }
      this.amount = val;
    },

    amount: function (newVal) {
      if (window._.isEmpty(newVal)) {
        return this.emitInput("");
      }
      const newAmount = new BigNumber(`${newVal || 0}`);
      if (newAmount.comparedTo("0") < 1) {
        return this.emitInput("0");
      }
      this.emitInput(this.externalAmount);
    },
    coin: function () {
      this.amount = "";
    },
    network: function () {
      this.amount = "";
    },
  },

  methods: {
    emitInput(newVal) {
      if (newVal !== this.value) {
        this.$emit("input", newVal);
      }
    },

    calcAmountAvailable() {
      let amount = new BigNumber(`${this.maxWithdrawAvailable || 0}`);

      if (amount.comparedTo(this.withdrawLimit.fee || 0) === 0) {
        return "0";
      }

      return amount.toString();
    },

    validate() {
      if (window._.isEmpty(this.amount)) {
        this.errors.add(
          "amount",
          window.i18n.t("bank_setting.error.lose_amount")
        );
      }
      const amount = `${this.amount || 0}`;
      // const minAmount = `${this.withdrawLimit.minium_withdrawal || 0}`;
      const minAmount = new BigNumber(
        `${this.withdrawLimit.minium_withdrawal || 0}`
      ).plus(`${this.withdrawLimit.fee || 0}`);
      if (new BigNumber(amount).comparedTo(minAmount) < 0) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.min_amount", {
            min_amount: minAmount,
          })
        );
      }
      if (
        new BigNumber(amount).comparedTo(
          `${this.balance.available_balance || 0}`
        ) > 0
      ) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.max_balance")
        );
      }
      if (
        new BigNumber(amount).comparedTo(`${this.calcAmountAvailable() || 0}`) >
        0
      ) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.max_once_amount")
        );
      }
    },

    setMaxAmount() {
      this.amount = this.calcAmountAvailable();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.f-left {
  float: left;
}

.amount-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-input {
  background: $color-bright-gray;
  &:focus {
    border: 1px solid $color-jungle-green !important;
  }
}

.dark-theme {
  .amount-input {
    border-color: #2d2e2f !important;
  }
}
.amount-box {
  width: 100%;
  margin-bottom: 0px !important;
  margin-top: 8px;
  .amount-input {
    width: 100%;
    position: relative;
    float: left;
    height: 100%;
    font-size: $font-root;
    border: 1px solid #efefef;
    background-color: transparent !important;
    border-radius: 123px;
    input {
      height: 100%;
      padding-right: 60px;
      height: 45px;
      border-radius: 123px;
      background: transparent;
      padding-left: 12px;
      font-family: $font-inter-regular;
      font-weight: 400;
      font-size: 14px;
      color: var(--text-primary-color);
      border: unset;
    }
    .error {
      border: 1px solid $color-alizarin-crimson;
    }
    .max-amount {
      position: absolute;
      right: 10px;
      z-index: 9;
      top: 50%;
      transform: translateY(-50%);
      color: var(--text-close);
      cursor: pointer;
      font-size: $font-root;
      line-height: 17px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  .set-max {
    float: right;
    width: 46px;
    text-align: center;
    color: $color-aqua-green;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
  }
  .set-max-576 {
    display: none;
  }
  .color-grey-dark {
    color: $color-grey-dark;
  }

  span.color-grey-dark {
    font-size: $font-root;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 8px;
    color: var(--text-primary-color);
  }
}
.max-amount-576 {
  display: none;
}
.invalid-feedback {
  color: $text-color-red;
  margin: 8px 0 0 0;
}

@media screen and (max-width: 576px) {
  .max-amount-576 {
    display: block;
    .color-grey-dark {
      color: var(--dark-1);
      line-height: 21px;
      margin-bottom: 5px;
      font-weight: 400;
      font-family: $font-inter-bold;
    }
  }
  .amount-box {
    .amount-input {
      .error {
        border: 1px solid $color-alizarin-crimson;
      }
      .max-amount {
        /* display: none; */
      }
    }

    .set-max-576 {
      display: block;
      position: absolute;
      right: 0;
      top: 30%;
      float: right;
      width: 46px;
      text-align: center;
      color: $color-aqua-green;
      cursor: pointer;
      text-transform: uppercase;
      border-left: 1px solid $color-gray-alto;
      font-size: 14px;
    }
  }

  .amount-box {
    .amount-input {
      .max-amount {
        /* display: none; */
      }
    }
    .set-max {
      /* display: none; */
    }
  }
}
</style>
