<template>
  <div id="overview-detail">
    <div class="content-right-wrapper">
      <div class="estimate">
        <div class="estimate-btc">
          <p>{{ $t("title_balance") }}</p>
          <span v-if="!tongglePrice">
            <img
              :src="
                require(`../assets/icon/eyes${
                  theme === 'light-theme' ? '' : '-dark'
                }.svg`)
              "
              @click="clickHide()"
              width="24"
              height="12"
            />
          </span>
          <span v-else
            ><img
              :src="
                require(`../assets/icon/hidePriceIcon${
                  theme === 'light-theme' ? '' : '-dark'
                }.svg`)
              "
              @click="clickHide()"
              width="24"
              height="12"
            />
          </span>
        </div>
        <div v-if="!tongglePrice" class="price-coin">
          <p>{{ priceCoin | formatNumberAndDecimal }} BTC</p>
          <span>≈ <fiat-currency :value="valued"> </fiat-currency></span>
        </div>
        <div class="price-coin-hide" v-else>***************</div>
      </div>

      <div class="recent-transactions">
        <div class="transaction-item">
          <div class="transaction-img">
            <img
              :src="
                require(`@/assets/images/wallet/spot${
                  theme === 'light-theme' ? '' : '-dark'
                }.png`)
              "
              alt="spot-img"
            />
          </div>
          <div class="recent-box">
            <p class="recent-title">{{ $t("menu.wallet.spot") }}</p>
            <div v-if="!tongglePrice">
              <p class="recent-coin">
                {{ totalSpotBtcBalance | formatNumberAndDecimal }} BTC
              </p>
              <p class="recent-rounding">
                ≈ <fiat-currency :value="totalUsdSpotBalance"></fiat-currency>
              </p>
            </div>
            <div v-else>
              <p class="recent-coin">***********</p>
            </div>
          </div>
        </div>

        <div class="transaction-item">
          <div class="transaction-img">
            <img
              :src="
                require(`@/assets/images/wallet/future${
                  theme === 'light-theme' ? '' : '-dark'
                }.png`)
              "
              alt="future-img"
            />
          </div>
          <div class="recent-box">
            <p class="recent-title">{{ $t("menu.wallet.futures") }}</p>
            <div v-if="!tongglePrice">
              <p class="recent-coin">
                {{ totalFuturesBtcBalance | formatNumberAndDecimal }} BTC
              </p>
              <p class="recent-rounding">
                ≈ <fiat-currency :value="totalUsdFutureBalance"></fiat-currency>
              </p>
            </div>
            <div v-else>
              <p class="recent-coin">***********</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import Cookies from "js-cookie";

export default {
  methods: {
    clickHide() {
      this.$emit("clickHide");
    },
    convertCoin(coin) {
      if (coin.toString().length > 10) {
        return coin.toString().slice(0, 11) + "... ";
      }
      return coin;
    },
    checkDisplayFullCoin(coin) {
      if (coin.toString().length > 10) return true;
      return false;
    },
  },
  props: {
    priceCoin: {
      type: String,
      default: "",
    },
    valued: {
      type: String,
      default: "",
    },
    tongglePrice: {
      type: Boolean,
      default: false,
    },
    totalSpotBtcBalance: {
      type: String,
      default: "",
    },
    totalUsdSpotBalance: {
      type: String,
      default: "",
    },
    totalFuturesBtcBalance: {
      type: String,
      default: "",
    },
    totalUsdFutureBalance: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
    };
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
      showBalances: (state) => state.isShowBalances,
    }),
    showBalances() {
      return (this.tongglePrice = this.showBalances);
    },
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
img {
  cursor: pointer;
}
#overview-detail {
  .content-right-wrapper {
    margin: 40px 0;
    .estimate {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 40px;
      .estimate-btc {
        p {
          color: $text-placeholder;
          font-size: $font-medium-bigger;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.112px;
          display: inline-block;
        }
        span {
          margin-left: $font-root;
          align-items: center;
          display: inline-flex;
        }
      }

      .price-coin {
        position: relative;
        p {
          color: var(--color-text);
          font-size: 36px;
          font-weight: 700;
          line-height: 44px;
          letter-spacing: -0.504px;
          margin-bottom: 8px;
        }
        span {
          color: $text-placeholder;
          font-size: $font-medium-bigger;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.112px;
        }
        .unHideCoin {
          display: none;
        }
        p:hover {
          .unHideCoin {
            display: block;
            position: absolute;
            padding: 10px;
            border-radius: 8px;
            background: var(--color-tooltip);
            color: $color-white !important;
            top: -40px;
            font-size: 16px;
          }
        }
      }
      .price-coin-hide {
        font-weight: 600;
        font-size: 34px;
        color: var(--text-primary-color);
        @media screen and (max-width: 992px) {
          font-size: 20px;
        }
      }
    }

    .recent-transactions {
      display: flex;
      align-items: center;
      gap: 16px;

      .transaction-item {
        display: flex;
        width: 500px;
        max-width: 500px;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        align-self: stretch;
        border-radius: 16px;
        border: 1px solid var(--border-color-transaction);

        .transaction-img {
          width: 40px;
          height: 40px;
        }

        .recent-box {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;
          .recent-title {
            color: var(--color-text);
            font-size: $font-medium-bigger;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.112px;
          }
          .recent-coin {
            color: var(--color-text);
            font-size: $font-big-20;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.2px;
            span {
              color: $dark-2;
            }
          }
          .recent-rounding {
            color: $text-placeholder;
            font-size: $font-root;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.084px;
          }
          .unHideCoin {
            display: none;
          }
          p:hover {
            .unHideCoin {
              display: block;
              position: absolute;
              padding: 10px;
              border-radius: 8px;
              background: var(--color-tooltip);
              color: $color-white !important;
              top: -8px;
              font-size: 16px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 992px) {
      .recent-transactions {
        width: 100%;
        .transaction-item {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    @media screen and (max-width: 500px) {
      .recent-transactions {
        width: 100%;
        flex-direction: column;
        .transaction-item {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
