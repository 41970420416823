<template>
  <vue-highcharts :options="options" ref="lineCharts" id="high-chart" v-if="visible"></vue-highcharts>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import VueHighcharts from 'vue2-highcharts';
  import BigNumber from 'bignumber.js';
  import {mapState} from 'vuex';
  import Cookies from 'js-cookie'

  export default {
    components: {
      VueHighcharts
    },
    props: {
      coin        : { type: String},
      currency    : { type: String},
    },
    data() {
      return {
        visible: true,

        internalCoin: undefined,
        internalCurrency: undefined,

        orderBook: [],
        buyOrdersBook: [],
        sellOrdersBook: [],
        tickerSize: undefined,
        options: this.getChartOptions(),
        disableByBetaTester: false,
        theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
      };
    },
    watch: {
      userThemeMode(theme) {
        this.theme = theme;
        this.updateChartOptions();
      },
      '$route' (to, from) {
        this.initData();
      }
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
        pairCoins: state => state.pairCoins,
        userThemeMode: state => state.userThemeMode
      })
    },
    methods: {
      getSocketEventHandlers() {
        return {
          OrderBookUpdated: this.onReceiveOrderBook,
        }
      },
      onReceiveOrderBook(data) {
        if (this.internalCurrency != data.currency || this.internalCoin != data.coin || this.tickerSize != data.tickerSize) {
          return;
        }

        if (data.isFullOrderBook) {
          this.orderBook = data.orderBook;
        } else {
            this.mergeSubOrderBook(this.orderBook.buy, data.orderBook.buy);
            this.mergeSubOrderBook(this.orderBook.sell, data.orderBook.sell);
        }
        // build chart
        this.buildChart();
      },
      mergeSubOrderBook(subOrderBook, newSubOrderBook) {
        const newRows = [];

        window._.each(newSubOrderBook, item => {
          const rowExisted = this.findRowByPrice(subOrderBook, item.price);

          if (rowExisted) {
            rowExisted.quantity = new BigNumber(`${rowExisted.quantity || 0}`).add(`${item.quantity || 0}`).toString();
          } else {
            newRows.push(item);
          }
        });
        // concat with newRows
        subOrderBook = window._.concat(subOrderBook, newRows);
      },
      findRowByPrice(subOrderBook, price) {
        return window._.find(subOrderBook, item => {
          return new BigNumber(`${item.price || 0}`).eq(`${price || 0}`);
        });
      },
      getDefaultParams() {
        return {
          currency: this.internalCurrency,
          coin: this.internalCoin,
          tickerSize: this.tickerSize
        };
      },
      getTickerSize() {
        let priceGroups = window._.filter(this.masterdata.price_groups, (item) => {
          return item.currency === this.internalCurrency && item.coin === this.internalCoin;
        });
        this.tickerSize = window._.minBy(priceGroups, 'value').value;
        this.getOrderBook();
      },
      getOrderBook() {
        rf.getRequest('OrderRequest').getOrderBook(this.getDefaultParams()).then(res => {
          this.orderBook = res.data;
          this.buildChart();
        });
      },
      reload(coin, currency) {
        this.internalCoin = coin;
        this.internalCurrency = currency;
        this.getTickerSize();
      },
      redraw() {
        this.visible = !this.visible;
        this.$nextTick(() => {
          // Re-render start
          this.visible = !this.visible;
          this.$nextTick(() => {
            // Re-render end
            this.buildChart();
          });
        });
      },
      buildChart() {
        const orderBook = window._.cloneDeep(this.orderBook);
        if (this.disableByBetaTester) {
          orderBook.buy = [];
          orderBook.sell = [];
        }

        orderBook.buy = this.transformOrderBook(orderBook.buy);
        orderBook.sell = this.transformOrderBook(orderBook.sell);

        this.sortOrderBook(orderBook);
        
        this.buyOrdersBook = orderBook.buy;
        this.sellOrdersBook = orderBook.sell;

        this.updateChartOptions();
      },
      transformOrderBook(rawData) {
        let data = window._.map(rawData, item => {
          const price = parseFloat(item.price);
          const quantity = parseFloat(item.quantity);
          return [price, quantity];
        });
        return this.normalizeSeries(data);
      },
      updateChartOptions() {
        this.$refs.lineCharts.chart.update(this.getChartOptions());
        this.$refs.lineCharts.chart.zoomOut();
      },
      normalizeSeries(data) {
        _.eachRight(data, (item, index) => {
          let vol = 0;
          for (let i = index; i >= 0; i--) {
            vol += data[i][1];
          }
          item[1] = vol;
        });
        return data;
      },
      sortOrderBook(orderBook) {
        orderBook.buy = window._.orderBy(orderBook.buy, (row) => parseFloat(row.price), ['desc']);
        orderBook.sell = window._.orderBy(orderBook.sell, (row) => parseFloat(row.price), ['asc']);
      },
      getChartOptions() {
        return {
          chart: {
            type: 'area',
            zoomType: 'x',
            backgroundColor: this.theme === 'light-theme' ? "#FBFBFB" : "#151717",
          },
          title: {
            text: null
          },
          xAxis: {
            allowDecimals: true,
            gridLineWidth: 1,
            gridLineColor: this.theme === 'light-theme' ? '#D9DCE1' : '#2B3038',
            lineColor: 'rgba(255,255,255,.2)',
            tickColor: 'rgba(255,255,255,.2)',
            labels: {
              formatter: function () {
                return `${this.value}`;
              }
            }
          },
          yAxis: {
            gridLineColor: this.theme === 'light-theme' ? '#D9DCE1' : '#2B3038',
            lineColor: 'rgba(255,255,255,.2)',
            tickColor: 'rgba(255,255,255,.2)',
            title: {
              text: null,
            },
            labels: {
              align: 'left',
              x: 0,
            },
            showFirstLabel: false,
          },
          tooltip: {
            pointFormat: this.pointFormat(),
            headerFormat: '',
            backgroundColor: '#fff',
            borderColor: 'rgba(255,255,255,.08)',
            style: {
              color: '#999'
            }
          },
          plotOptions: {
            area: {
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 2,
                states: {
                  hover: {
                    enabled: true,
                  },
                },
              },
            },
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: window.i18n.t('exchange.basic.chart.buy_order'),
              color: '#00a854',
              data: this.buyOrdersBook,
              lineWidth: 2,
              fillOpacity: 0.1,
              showInLegend: false
            },
            {
              name: window.i18n.t('exchange.basic.chart.sell_order'),
              color: '#f04134',
              data: this.sellOrdersBook,
              lineWidth: 2,
              fillOpacity: 0.1,
              showInLegend: false
            }
          ]
        };
      },
      pointFormat(currency) {
        return `${window.i18n.t('exchange.basic.chart.price')}: {point.x} <b>${this.internalCurrency}</b><br/>{series.name}: {point.y}`;
      },
      initData() {
        this.disableByBetaTester = false;
        let coin = this.$route.query.coin || Const.DEFAULT_COIN;
        let currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;

        const setting = this.pairCoins;
        if (!setting.is_enable && setting.is_show_beta_tester) {
          this.disableByBetaTester = true;

          if (window.isAuthenticated) {
            rf.getRequest("UserRequest")
              .getUserPairTradingSetting({ coin, currency })
              .then(res => {
                if (res && res.data && res.data.enable_trading == Const.ENABLE_TRADING.ENABLE) {
                  this.disableByBetaTester = false;
                }
                this.buildChart();
              });
          } else {
            this.buildChart();
          }
        } else {
          this.buildChart();
        }
      },
    },
    mounted() {
      this.internalCoin = this.coin || Const.DEFAULT_COIN;
      this.internalCurrency = this.currency || Const.DEFAULT_CURRENCY;
      this.initData();
      this.getTickerSize();
    }
  }
</script>
<style lang="scss">
  #high-chart {
    padding: 0;
    margin: 0 auto;
    background-color: #181f2b;
    height: 100%;
    width: 100%;
    .highcharts-container {
      height: 100% !important;
      .highcharts-root {
        height: 100% !important;
      }
    }
  }
</style>
