<template>
  <div class="item-tab">
    <data-table3
      ref="datatable"
      :msgEmptyData="$t('common.datatable.no_have_data')"
      :get-data="getData"
      class="table-landing"
      id="data-table-landing"
      :defaultSort="false"
      :limit="6"
    >
      <template slot-scope="props">
        <th
          class="w_16 col-pair-thead"
          :data-sort-field="isHomePage ? '' : 'coin'"
          v-if="isVisibleColumn('pair')"
          @click="props.echoclick"
        >
          <span v-if="!futuresTable">{{ $t("landing.pair") }}</span>
          <span v-else>{{ $t("funds.balances.name") }}</span>
          <div
            v-if="!isHomePage"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <!-- <th
          data-sort-field="full_name"
          class="col-coin w_13"
          v-if="isVisibleColumn('coin')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.coin") }}</span>
          <div v-if="!isHomePage"  class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th> -->
        <th
          :data-sort-field="isHomePage ? '' : 'current_price'"
          class="col-last-price col-last-price-thead"
          v-if="isVisibleColumn('current_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.last_price") }}</span>
          <div
            v-if="!isHomePage"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :data-sort-field="isHomePage ? '' : 'changed_percent'"
          class="col-24h-changed col-24h-changed-thead w_16"
          v-if="isVisibleColumn('changed_percent')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.change") }}</span>
          <div
            v-if="!isHomePage"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          :data-sort-field="isHomePage ? '' : 'max_price'"
          class="col-max-price w_17"
          v-if="isVisibleColumn('max_price')"
          v-show="sizeScreen > 600"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.high") }}</span>
          <div
            v-if="!isHomePage"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          v-show="sizeScreen > 600"
          :data-sort-field="isHomePage ? '' : 'min_price'"
          class="col-min-price w_18"
          v-if="isVisibleColumn('min_price')"
          :disabled="isHomePage"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.low") }}</span>
          <div
            v-if="!isHomePage"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          v-show="sizeScreen > 600"
          :data-sort-field="isHomePage ? '' : 'quoteVolume'"
          class="col-volume w_11"
          v-if="isVisibleColumn('quoteVolume')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.volume") }}</span>
          <div
            v-if="!isHomePage"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
            v-show="sizeScreen > 600"
            class="col-volume w_11"
            v-if="isVisibleColumn('quoteVolume')"
            @click="props.echoclick"
        >
          <span>Action</span>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <!-- <tr> -->
          <td
            class="text-left col-pair text-padding"
            @click="sizeScreen < 600 && selectedCurrenyPair(props.item.currency, props.item.coin, props.item)"
            v-if="isVisibleColumn('pair')"
            :style="{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }"
          >
            <span
              class="icon-star1"
              v-if="!isHomePage"
              :class="
                isFavorite(props.item.coin, props.item.currency)
                  ? 'icon-star2'
                  : 'star_gray'
              "
              @click.stop="
                isFavorite(props.item.coin, props.item.currency)
                  ? removeFavorite(props.item.coin, props.item.currency)
                  : insertFavorite(props.item.coin, props.item.currency)
              "
            ></span>
            <img
              v-show="!!props.item.img"
              class="icon-coin"
              :src="props.item.img"
              alt=""
              width="30"
              height="30"
            />
            <div>
              <span class="coin">{{ props.item.coin | currencyName }}</span>
              <span class="currency" v-if="props.item.currency"
                >/{{ props.item.currency | currencyName }}</span
              >
              <span class="currency" v-else> perpetual</span>
            </div>
          </td>
          <!-- <td
            class="col-coin"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('coin')"
          >
            {{ props.item.coin | fullName }}
          </td> -->
          <td
            class="col-last-price text-padding col-last-price-tbody"
            @click="sizeScreen < 600 && selectedCurrenyPair(props.item.currency, props.item.coin, props.item)"
            v-if="isVisibleColumn('current_price')"
          >
            <span
              class="change-node"
              :class="calculateLastPriceStatus(props.item)"
            >
              {{ props.item.current_price | formatNumberAndDecimal }}
            </span>
            <span
              class="is-muted"
              v-if="props.item.currency !== 'usd' && props.item.usdPrice > 0"
            >
              / ${{ props.item.usdPrice | formatNumberAndDecimal }}
            </span>
          </td>
          <td
            class="change-node col-24h-changed text-padding col-24h-changed-tbody"
            @click="sizeScreen < 600 && selectedCurrenyPair(props.item.currency, props.item.coin, props.item)"
            :class="{
              grown: props.item.changed_percent > 0,
              down: props.item.changed_percent < 0,
              black: props.item.changed_percent == 0,
            }"
            v-if="isVisibleColumn('changed_percent')"
          >
            <span v-if="sizeScreen > 600">{{
              formatShortText(props.item.changed_percent, 2, 8) | changedPercent
            }}</span>
            <div
              class="container-mobile"
              :class="{
                grown: props.item.changed_percent > 0,
                down: props.item.changed_percent < 0,
                black: props.item.changed_percent == 0,
              }"
              v-if="sizeScreen < 600"
            >
              {{
                formatShortText(props.item.changed_percent, 2, 8)
                  | changedPercent
              }}
            </div>
          </td>
          <td
            v-show="sizeScreen > 600"
            class="col-max-price text-padding"
            @click="sizeScreen < 600 && selectedCurrenyPair(props.item.currency, props.item.coin, props.item)"
            v-if="isVisibleColumn('max_price')"
          >
            {{ props.item.max_price | formatNumberAndDecimal }}
          </td>
          <td
            v-show="sizeScreen > 600"
            class="col-min-price text-padding"
            @click="sizeScreen < 600 && selectedCurrenyPair(props.item.currency, props.item.coin, props.item)"
            v-if="isVisibleColumn('min_price')"
          >
            {{ props.item.min_price | formatNumberAndDecimal }}
          </td>
          <td
            v-show="sizeScreen > 600"
            class="col-volume text-padding"
            @click="sizeScreen < 600 && selectedCurrenyPair(props.item.currency, props.item.coin, props.item)"
            v-if="isVisibleColumn('quoteVolume')"
          >
            {{ props.item.quoteVolume | formatNumberAndDecimal }}
          </td>
          <td v-show="sizeScreen > 600"
              class="col-volume"
              @click="selectedCurrenyPair(props.item.currency, props.item.coin, props.item)">
            <span class="text-trading">Trade</span>
          </td>
        </tr>
      </template>
    </data-table3>
  </div>
</template>

<script>
import FavoritesManager from "@/common/FavoritesManager";
import COMMON_CONST from "@/common/Const";
import Const from "@/common/Const";
import BigNumber from "bignumber.js";
import rf from "@/request/RequestFactory";
import { mapState } from "vuex";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";

const ALL_COLUMNS = [
  "pair",
  "coin",
  "current_price",
  "changed_percent",
  "max_price",
  "min_price",
  "quoteVolume",
];

export default {
  components: {},
  props: {
    priceScope: { type: [Object, Array] },
    columns: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    favorites: { type: [Array] },
    marketSelected: { type: String },
    coinNames: { type: String },
    isHomePage: { type: Boolean, default: false },
    futuresTable: { type: Boolean, default: false },
    tabMain: { type: String },
    subTabMain: { type: String },
    futurePrice: {},
  },
  data() {
    return {
      sizeScreen: window.innerWidth,
      ethicon: ETHIcon,
      pricesSorted: {},
      fetchingData: false,
      listInstruments: [],
      allDataFromFuture: [],
      favoriteFutureMarket: [], // data favourite tab future (market)
      // noData: window.i18n.t('common.datatable.no_data'),
    };
  },
  watch: {
    priceScope: {
      handler() {
        this.pricesSorted = this.priceScope;
        if (this.$refs.datatable) {
          this.$refs.datatable.refresh();
          this.checkFetchedData();
        }
      },
      immediate: true,
      masterData() {
        this.$nextTick(() => {
          this.marketData(this.dataTopMarket);
        });
      },
    },

    // subTabMain() {
    //     // this.pricesSorted = this.priceScope;
    //     if (this.$refs.datatable) {
    //       // this.$refs.datatable.refresh();
    //       this.checkFetchedData()
    //   }
    // },
    // pricesSorted() {
    //   if (this.$refs.datatable) {
    //     this.$refs.datatable.refresh();
    //     this.checkFetchedData()
    //   }
    // },
    coinNames() {
      this.$refs.datatable.getDataSuccess = false;
      this.$refs.datatable.params.sort = "";
      this.$refs.datatable.params.sort_type = "";
      this.$refs.datatable.lastPage = 0;
      this.$refs.datatable.rows = [];
      this.currentPage = 1;
      this.checkFetchedData();
    },
  },
  computed: {
    visibleColumns() {
      if (window._.isEmpty(this.columns)) {
        return ALL_COLUMNS;
      }
      return this.columns;
    },
    ...mapState({
      masterData: (state) => state.masterdata,
    }),
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
    calculateLastPriceStatus(item) {
      let compareResult;
      if (item.previous_price) {
        compareResult = new BigNumber(item.current_price || 0).comparedTo(
          item.previous_price || 0
        );
      } else {
        compareResult = new BigNumber(item.lastPriceChange || 0);
      }

      return {
        grown: compareResult > 0,
        down: compareResult < 0,
        black: compareResult == 0,
      };
    },

    checkFetchedData() {
      if (!this.fetchingData) {
        this.fetchingData = true;
        this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
      }
    },

    isVisibleColumn(column) {
      return window._.indexOf(this.visibleColumns, column) > -1;
    },

    isFavorite(coin, currency) {
      const conditionFuture =
        this.futuresTable || this.tabMain === COMMON_CONST.FAVORITES_TAB;
      const coinPair = conditionFuture
        ? coin
        : this.getCoinPair(coin, currency);
      if (conditionFuture) {
        let result = window._.filter(this.favorites, function (value) {
          return value.symbol == coinPair;
        });
        return result.length > 0;
      } else {
        let result = window._.filter(this.favorites, function (value) {
          return value.coin_pair == coinPair;
        });
        return result.length > 0;
      }
    },

    insertFavorite(coin, currency) {
      if (!this.futuresTable) {
        const coinPair = this.getCoinPair(coin, currency);
        FavoritesManager.addFavorite({ coin_pair: coinPair }).then((data) => {
          this.favorites.push(data);
        });
      } else {
        const data = {
          isFavorite: true,
          symbol: coin,
        };
        FavoritesManager.addFavoriteFuture(data).then((data) => {
          this.favorites.push(data);
        });
      }
    },

    getSocketEventHandlers() {
      return {
        OrderTransactionCreated: this.onOrderTransactionCreated,
      };
    },

    // homepage future
    onUpdateFutureData(data) {
      if (this.futuresTable && this.allDataFromFuture.length > 0) {
        this.allDataFromFuture = this.allDataFromFuture.map((item) => {
          const newDataFromSocket = data.find((d) => d.symbol === item.coin);
          if (newDataFromSocket) {
            item = {
              coin: newDataFromSocket.symbol,
              lastPrice: newDataFromSocket.lastPrice,
              lowPrice: newDataFromSocket.lowPrice,
              highPrice: newDataFromSocket.highPrice,
              min_price: newDataFromSocket.lowPrice,
              max_price: newDataFromSocket.highPrice,
              priceChangePercent: newDataFromSocket.priceChangePercent,
              lastPriceChange: newDataFromSocket.lastPriceChange,
              volume: newDataFromSocket.volume,
              quoteVolume: newDataFromSocket.quoteVolume,
              symbol: newDataFromSocket.symbol,
            };
          }
          return item;
        });
        this.$refs && this.$refs.datatable && this.$refs.datatable.hotFetch();
      }
    },

    // update data socket of tab favorite future( market)
    onUpdateDataFavoritesFutureFromSocket(data) {
      if (this.futuresTable && this.priceScope.length > 0) {
        this.favoriteFutureMarket = this.priceScope.map((item) => {
          const newDataFromSocket = data.find((d) => d.symbol === item.coin);
          if (newDataFromSocket) {
            item = {
              coin: newDataFromSocket.symbol,
              lastPrice: newDataFromSocket.lastPrice,
              lowPrice: newDataFromSocket.lowPrice,
              highPrice: newDataFromSocket.highPrice,
              min_price: newDataFromSocket.lowPrice,
              max_price: newDataFromSocket.highPrice,
              priceChangePercent: newDataFromSocket.priceChangePercent,
              lastPriceChange: newDataFromSocket.lastPriceChange,
              volume: newDataFromSocket.volume,
              quoteVolume: newDataFromSocket.quoteVolume,
              symbol: newDataFromSocket.symbol,
            };
          }
          return item;
        });
        this.$refs && this.$refs.datatable && this.$refs.datatable.hotFetch();
      }
    },

    removeFavorite(coin, currency) {
      if (!this.futuresTable && this.marketSelected !== "Future") {
        const coinPair = this.getCoinPair(coin, currency);
        let favoriteObject = window._.find(this.favorites, [
          "coin_pair",
          coinPair,
        ]);

        FavoritesManager.removeFavorite(favoriteObject).then((res) => {
          this.favorites.splice(this.favorites.indexOf(favoriteObject), 1);
          // In the favorites tab, table remove pair which isn't favorited.
          if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
            // this.price24hData = window._.filter(this.price24hData, (item) => {
            //   const pair = this.getCoinPair(item.coin, item.currency);
            //   return pair !== coinPair;
            // });
            this.$refs.datatable.getDataSuccess = false;
            this.$refs.datatable.lastPage = 0;
            this.$refs.datatable.rows = [];
            this.checkFetchedData();
          }
        });
      } else {
        const data = {
          isFavorite: false,
          symbol: coin,
        };
        FavoritesManager.removeFavoriteFuture(data).then((data) => {
          let favoriteObject = window._.find(this.favorites, (items) => {
            return items.symbol == coin;
          });
          this.favorites.splice(this.favorites.indexOf(favoriteObject), 1);
          // this.favorites.push(data);
          this.fetchingData = false;
          this.checkFetchedData();
        });
      }
    },

    getCoinPair(coin, currency) {
      return `${coin}/${currency}`;
    },

    selectedCurrenyPair(currency, coin, item) {
      let newurl;
      const pair = { currency: currency, coin: coin };
      if (this.tabMain === "Favorites" && this.marketSelected === "Future") {
        this.$router.push({
          name: "Margin Future",
          params: { coin: `futures/${item.contractType}/${pair.coin}` },
        });
        return;
      }
      if (!this.futuresTable) {
        newurl = `/spot-exchange/basic?currency=${pair.currency}&coin=${pair.coin}`;
      } else {
        this.$router.push({
          name: "Margin Future",
          params: { coin: `futures/${this.subTabMain}/${pair.coin}` },
        });
        return;
      }
      this.$broadcast("selectedPairMarket", pair);

      window.history.pushState({ path: newurl }, "", newurl);

      this.$router.go(newurl);
    },

    getData(params) {
      let pricesSorted = this.priceScope;
      this.currentPage = params.page;
      const coin = {
        limit: 6,
        page: this.currentPage,
        keyword: this.coinNames,
      };
      let nextPage = coin.page - params.page;
      params = Object.assign({}, coin, params);
      if (nextPage != 0) {
        params.sort = "";
        params.sort_type = "";
        // this.resetSortOrder();
      }

      // Tab future (homepage future)
      if (this.futuresTable && this.subTabMain !== "btc") {
        if (this.isHomePage) {
          this.fetchingData = false;
          let listPairShow =
            this.subTabMain !== "COIN_M"
              ? Const.LIST_PAIR_HOME_USDM
              : Const.LIST_PAIR_HOME_COINM;
          if (params.socket) {
            return new Promise((resolve) => {
              return resolve({
                data: {
                  data: this.futureDataFormat(
                    this.allDataFromFuture,
                    listPairShow
                  ),
                },
              });
            });
          } else {
            return new Promise((resolve) => {
              return rf
                .getRequest("PriceRequest")
                .get24hFuture({ contractType: this.subTabMain })
                .then((res) => {
                  return resolve(this.futureDataFormat(res.data, listPairShow));
                });
            });
          }
        }
      }

      // homepage spot
      if (this.isHomePage && this.tabMain === "Spot") {
        const combineData = Object.assign({}, pricesSorted);
        if (Object.keys(combineData).length !== 0) {
          return new Promise((resolve) => {
            return resolve(
              _.chain(combineData)
                .filter((item) => {
                  const coinPair = `${item.coin}/${item.currency}`;
                  const listPairShow = [
                    "btc/usdt",
                    "eth/usdt",
                    "bnb/usdt",
                    "sol/usdt",
                    "usdt/usd",
                  ];
                  return listPairShow.includes(coinPair);
                })
                .map((coin) => {
                  if (coin.coin === "btc") {
                    coin.position = 0;
                  } else if (coin.coin === "eth") {
                    coin.position = 1;
                  } else if (coin.coin === "bnb") {
                    coin.position = 2;
                  } else if (coin.coin === "sol") {
                    coin.position = 3;
                  } else {
                    coin.position = 4;
                  }
                  return coin;
                })
                .map((c) => {
                  const coin = this.masterData?.coins.find(
                    (m) => m.coin == c.coin
                  );
                  const img =
                    coin?.coin === "eth" ? this?.ethicon : coin?.icon_image;
                  // c.volume = c.quoteVolume || c.quote_volume || 0;
                  c.quoteVolume = c.quoteVolume || c.quote_volume || 0;
                  return { ...c, img };
                })
                .sortBy("position")
                .value()
            );
          });
        }
      }
      // other case
      return new Promise((resolve) => {
        if (params.sort && params.sort_type) {
          if (params.socket) {
            pricesSorted.map((item) => {
              const newItemSocket = this.favoriteFutureMarket.find(
                (socketItem) => socketItem.coin === item.coin
              );
              if (newItemSocket) {
                item.current_price = newItemSocket.lastPrice;
                item.lowPrice = newItemSocket.lowPrice;
                item.max_price = newItemSocket.max_price;
                item.min_price = newItemSocket.min_price;
                item.changed_percent = newItemSocket.lastPriceChange;
                item.lastPriceChange = newItemSocket.lastPrice;
                item.volume = newItemSocket.volume;
                item.quoteVolume = newItemSocket.quoteVolume;
                item.symbol = newItemSocket.symbol;
              }
              return item;
            });
          }
          pricesSorted = _.orderBy(
            pricesSorted,
            (item) => {
              if (isNaN(item[params.sort])) {
                if (params.sort !== "full_name") {
                  return item[params.sort];
                } else {
                  return window.i18n.t("currency." + item.coin + ".fullname");
                }
              }
              return new BigNumber(`${item[params.sort]}`).toNumber();
            },
            [params.sort_type]
          );
        }

        // tab favourite future market
        if (this.tabMain === COMMON_CONST.FAVORITES_TAB) {
          this.fetchingData = false;
          return resolve(
            this.futureData(
              pricesSorted.filter((item) => this.isFavorite(item.coin)),
              params
            )
          );
        }

        //
        if (this.marketSelected === COMMON_CONST.TYPE_FUTURE) {
          return _.chain(pricesSorted)
            .filter((item) => this.isFavorite(item.coin, item.currency))
            .value();
        }
        return _.chain(pricesSorted)
          .filter((item) => item.currency === this.marketSelected)
          .value();
      });
    },
    futureData(res, params) {
      const dataFuture = [];
      let data = [];
      res.forEach((items) => {
        if (items.coin.includes(this.coinNames.toLocaleUpperCase())) {
          dataFuture.push({
            coin: items.coin,
            changed_percent: items.changed_percent || items.priceChangePercent,
            current_price: items.current_price || items.lastPrice,
            lowPrice: items.lowPrice,
            highPrice: items.highPrice,
            min_price: items.lowPrice,
            max_price: items.max_price,
            lastPriceChange: items.lastPriceChange || 0,
            lastPrice: items.lastPrice || 0,
            volume: items.volume,
            quoteVolume: items.quoteVolume,
            contractType: items.contractType,
          });
        }
      });
      const total = dataFuture.length;
      const last_page = Math.ceil(total / params.limit);
      let from = params.page;
      if (params.page) {
        from = (params.page - 1) * params.limit + 1;
      }
      let to = params.page;
      if (params.page) {
        to = (params.page - 1) * params.limit + params.limit;
      }
      if (to > total) {
        to = total;
      }
      let data_pairs = dataFuture;

      if (params.page) {
        const start = (params.page - 1) * params.limit;
        const end = start + params.limit;
        data_pairs = dataFuture.slice(start, end);
      }
      data = {
        current_page: params.page ? params.page : 1,
        data: data_pairs,
        first_page_url: "/?page=1",
        from: from,
        last_page: last_page,
        last_page_url: `/?page=${last_page}`,
        path: "/",
        per_page: params.limit,
        to: to,
        total: total,
      };
      this.totalRecord = dataFuture.length;
      return { data: data };
    },
    futureDataFormat(res, listPairShow) {
      this.fetchingData = false;
      const data = [];
      res.forEach((items) => {
        const coin = this.masterData?.coins.find((m) => {
          return items.coin?.toLowerCase().includes(m.coin);
        });
        const img = coin?.coin === "eth" ? this?.ethicon : coin?.icon_image;
        data.push({
          coin: items.symbol,
          current_price: items.lastPrice,
          min_price: items.lowPrice,
          max_price: items.highPrice,
          changed_percent: items.priceChangePercent,
          lastPriceChange: items.lastPriceChange || 0,
          volume: items.volume,
          quoteVolume: items.quoteVolume,
          contractType: items?.contractType,
          img,
        });
      });
      let filteredData = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (listPairShow.includes(item.coin)) {
          item.position = listPairShow.indexOf(item.coin);
          filteredData.push(item);
        }
      }
      this.allDataFromFuture = filteredData.sort(
        (a, b) => Number(a.position) - Number(b.position)
      );
      return this.allDataFromFuture;
    },
    getInstruments() {
      rf.getRequest("UserRequest")
        .getInstruments()
        .then((res) => {
          const assetsKeyObject = {};
          res.data.forEach((asset) => {
            return (assetsKeyObject[asset.symbol] = {
              ...asset,
            });
          });
          this.listInstruments = assetsKeyObject;
        });
    },
    numFormat(value, maxDecimal) {
      const split = value.toString().split(".");
      const afterDecimal = split[1];
      const numFormat =
        afterDecimal && afterDecimal.length > maxDecimal
          ? new BigNumber(value).toFixed(maxDecimal || 2, BigNumber.ROUND_DOWN)
          : value;
      return numFormat;
    },
    formatShortTextAdmin(value, currency, coin, maxLength) {
      if (value == null || Number(value) == 0 || value == "Infinity")
        return "--";
      const split = parseFloat(value).toString().split(".");
      const beforeDecimal = split[0];
      const maxDecimal = -Math.ceil(
        Math.log10(
          Number(
            this.listInstruments[coin.concat(currency).toLocaleUpperCase()]
              ?.minPriceMovement ?? "0.01"
          )
        )
      );
      const numFormat = this.numFormat(value, maxDecimal);
      const shorttedNumber =
        beforeDecimal.length > maxLength
          ? numFormat
              .slice(0, maxLength - 1)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .concat("...")
          : numFormat
              .slice(0, maxLength)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .concat("...");
      return numFormat.length > maxLength
        ? shorttedNumber
        : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    formatShortText(value, maxDecimal, maxLength) {
      if (value == null || Number(value) == 0 || value == "Infinity")
        return "--";
      const split = parseFloat(value).toString().split(".");
      const beforeDecimal = split[0];
      const numFormat = this.numFormat(value, maxDecimal);
      const shorttedNumber =
        beforeDecimal.length > maxLength
          ? numFormat
              .slice(0, maxLength - 1)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .concat("...")
          : numFormat
              .slice(0, maxLength)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .concat("...");
      return numFormat.length > maxLength
        ? shorttedNumber
        : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    this.sockets.subscribe("tickers", (data) => {
      if (this.subTabMain !== "btc") {
        if (this.isHomePage) {
          this.onUpdateFutureData(data);
        } else {
          this.onUpdateDataFavoritesFutureFromSocket(data);
        }
      }
    });
    if (this.isHomePage) {
      const thElements = document.querySelectorAll("th");
      thElements.forEach((th) => th.classList.add("heading"));
    } else {
      const thElement = document.querySelector(
        'th[data-sort-field="quoteVolume"]'
      );
      thElement.setAttribute("data-sort-order", "");
      thElement.click();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.w_8 {
  width: 8.5%;
}
.w_10 {
  width: 10%;
}
.w_13 {
  width: 13%;
}
.w_11 {
  width: 11.5%;
}
.w_15 {
  width: 15%;
}
.w_17 {
  width: 17.4%;
}
.w_18 {
  width: 18%;
}
.w_20 {
  width: 22%;
}
.w_16 {
  width: 16.7%;
}
.w_24 {
  width: 24.7%;
}
.w_14 {
  width: 14.5%;
}
.text-trading{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #03AAC7;
}
.item-tab {
  display: block;
  width: 100%;
  min-width: $min-width-table;
  .grown {
    color: $color-jungle-green !important;
  }
  .down {
    color: $color-denim !important;
  }
  .table-landing {
    table {
      border-collapse: collapse;
      .icon-star1 {
        display: block;
        float: left;
        width: 15px;
        font-size: $font-medium;
        margin-right: 20px;
        margin-top: 5px;
        height: 20px;
        float: left;
        color: $color-grey;
        cursor: pointer;
      }
      .icon-star2 {
        color: $color-yellow;
        cursor: pointer;
      }
      .col-pair-thead {
        @media screen and (max-width: 610px) {
          width: 40%;
        }
      }
      .col-last-price-thead {
        @media screen and (max-width: 610px) {
          text-align: right;
          width: 30%;
        }
      }
      .col-24h-changed-thead {
        @media screen and (max-width: 610px) {
          width: 30%;
          text-align: right;
        }
      }
      .col-last-price-tbody {
        @media screen and (max-width: 610px) {
          text-align: right;
        }
      }
      .col-24h-changed-tbody {
        @media screen and (max-width: 610px) {
          text-align: right;
          .container-mobile {
            padding: 6px 12px;
            width: 72px;
            border-radius: 8px;
          }
          .grown {
            background-color: $color-jungle-green !important;
            color: white !important;
          }
          .down {
            background-color: $color-denim !important;
            color: white !important;
          }
        }
      }
      th {
        padding: 10px 10px 10px 0;
        font-family: $font-inter-regular;
        font-size: $font-root;
        height: 63px;
        color: var(--color-percent);
        text-align: left;
      }
      .heading {
        cursor: unset !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #8e8e92;
        @media screen and (max-width: 500px) {
          font-size: 12px;
          font-weight: 400;
        }
      }
      tr {
        border-bottom: 1px solid var(--line-color-table-home);
        @media screen and (max-width: 500px) {
          border-bottom: 1px solid transparent;
        }
        &:hover {
          cursor: pointer;
        }
      }

      td {
        text-align: left;
        font-family: $font-inter-regular;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: var(--text-primary-color);

        &:last-child {
          text-align: left;
        }

        .icon-no-data {
          padding-top: 50px;
        }
        &.col-last-price {
          padding-right: 10px;
        }
      }

      .text-padding {
        padding: 20px 8px 20px 0px;
      }
    }
  }

  .col-pair {
    font-family: $font-inter-regular;
    font-size: $font-big;
    .coin {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--text-primary-color);
    }
    .currency {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #8e8e92;
    }
    @media screen and (max-width: 610px) {
      padding-left: unset !important;
    }
  }

  .is-muted {
    color: var(--text-primary-color);
  }
}
.table-landing > table tr {
  padding: 0 25px;
  cursor: pointer;
}

.table-landing > table td {
  padding: 10px 10px 10px 0;
  color: $color-grey-dark;
}

@media screen and (max-width: 992px) {
  .item-tab {
    min-width: 1380px;
    .table-landing {
      table {
        td {
          font-size: 14px;
        }
        .icon-star1 {
          margin-top: 2px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .item-tab {
    min-width: unset;
  }
}
@media screen and (max-width: 576px) {
}
</style>
