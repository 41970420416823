<template>
  <div>
    <modal :name="name" width="615" modalContentCustom="modalContentCustom" modalFooterCustom="modalFooterCustom">
      <template slot="body" slot-scope="props">
        <div id="lost_otp_form">
          <img src="/images/icon/icon-logo-google.svg" alt="" class="logo">
          <div class="box-title">{{$t('account.modal.lost_otp.title')}}</div>
          <p class="note_header" v-html="$t('account.modal.lost_otp.guide.common')"></p> 
          <div>
            <input
             @focus="resetErrors"
              v-model="otp_recovery_code"
              :placeholder="$t('account.modal.lost_otp.otp_restore')"
              type="text"
              maxlength="16"
              :class="{ error: errors.has('otp_recovery_code') }"
              class="input"
              :data-vv-as="$t('validation.attributes.recovery_code')"
              name="otp_recovery_code"
              data-vv-validate-on="recovery_code"
              v-validate="'required'">
            <div class="invalid-feedback" v-if="errors.has('otp_recovery_code')" >
                {{ errors.first('otp_recovery_code') }}
            </div>
          </div> 
          <div class="link-primary-OTP">
            <div class="link" @click="showLostOtpPolicyModal()">{{  $t('account.modal.lost_otp.otp_policy') }}</div>
          </div>
          <button class="btn-submit" @click="delOtpRecoveryCode(props.configs)">
            {{ $t('account.modal.lost_otp.stop_use_otp') }}
          </button>
        </div>
      </template>
    </modal>
    <lost-otp-policy :name="`${this.name}_lostOtpPolicyModal`" :contact_email="contact_email"/>
  </div>
</template>
<script>
  import Modal from '../common/Modal';
  import rf from '@/request/RequestFactory'
  import COMMON_CONST from '@/common/Const';
  import LostOtpPolicy from '../otp/LostOtpPolicy';

  export default {
    components: {
      Modal,
      maxlength : COMMON_CONST.MAX_LENGTH_INPUT,
      LostOtpPolicy
    },

    data() {
      return {
        otp_recovery_code: ''
      }
    },

    props: {
      name: {type: String, default: 'lostOtpForm'},
      contact_email: {type: String, default: ''},
    },

    methods: {
      showLostOtpPolicyModal() {
        this.errors.clear();
        CommonModal.hide(this.name);
        CommonModal.show(`${this.name}_lostOtpPolicyModal`);
      },

      resetErrors() {
        if (this.errors.any()) {
          this.errors.clear();
        }
      },

      async delOtpRecoveryCode(configs) {

        this.errors.clear();

        await this.$validator.validate('otp_recovery_code');

        if (this.errors.any()) {
          $(".btn-success").blur();
          return;
        }

        let params = {
          'otp_recovery_code': this.otp_recovery_code
        };

        if (window.isAuthenticated) {
          this.handleDelOtpRecoveryCode(rf.getRequest('UserRequest').delRecoveryCodeWithAuth(params));
        } else {
          const data = configs.data || {};
          params = Object.assign(params, data);
          this.handleDelOtpRecoveryCode(rf.getRequest('UserRequest').disableOtpAuthentication(params));
        }
      },

      handleDelOtpRecoveryCode(promise) {
        promise.then(res => {
          this.onDelRecoveryCode(res.message)
          this.$emit('DISABLE_OTP_SUCCESS');
        }).catch((error) => {
          this.onErrors(error.response.data.errors || []);
          this.convertRemoteErrors(error);
        });
      },

      onErrors(errors) {
        // this.errors.clear();
        // _.forEach(errors, (messages, field) => {
        //   _.forEach(messages, (message) => {
        //     this.errors.add(field, message);
        //   });
        // });
        this.errors.add({
          field: 'otp_recovery_code',
          msg: this.$t('validation.otp_incorrect')
        });
      },

      onDelRecoveryCode(message) {
        this.errors.clear();
        CommonModal.hide(this.name);
        Message.success(message, {}, { position: 'bottom_left' });
      }
    },
    mounted() {
      window.addEventListener('keyup', (event) => {
        this.errors.clear();
      }),
      this.$on('hideCommonModal', (name) => {
        if (name === this.name) {
          this.otp_recovery_code = '';
          this.errors.clear();
        }
      })
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  #lost_otp_form {
    .logo {
      display: block;
      margin: 0 auto 25px;
    }
    .box-title {
      margin-bottom: 8px;
      font-family: $font-inter-bold;
      color: var(--dark-1);
      font-size: 34px;
      line-height: 40px;
      text-align: center;
    }
    .note_header {
      margin-bottom: 40px;
      font-family: $font-inter-regular;
      color: $dark-2;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
    .input {
      border-radius: 8px;
      padding: 14px 15px;
      border: none;
      width: 100%;
      
      font-size: 18px;
      line-height: 21px;
      font-family: $font-inter-regular;
      color: var(--text-primary-color);
      background: var(--background-color-second);
      &.error {
        border: 1px solid $text-color-red;
      }
    }
    .invalid-feedback {
      color: $text-color-red;
      font-family: $font-inter-regular;
      font-size: 14px;
      line-height: 17px;
      text-align: left;
      margin-top: 8px;
    }
    .btn-submit {
      @include btn-common(yes);
      width: 100%;
      margin-top: 30px;
    }

    .link-primary-OTP {
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
      .link {
        display: inline-block;
        font-family: $font-inter-regular;
        font-size: 16px;
        line-height: 19px;
        color: $color-aqua-green;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .form-input{
    &:focus, &:active{
        border-bottom: 1px solid $border-jungle-green !important;
      }
      &.error{
        border-bottom: 1px solid $border-alizarin-crimson !important;
      }
  }
</style>