<template>
  <div id="current_rate" class="clearfix" v-if="countryCurrency && isHasCurrentRates">
    <div class="group_current_rate clearfix">
      <div class="group_icon_current_rate">
        <i class="icon-chart"></i>
        <span class="txt_current_rate" v-html="$t('landing.current_rate')"></span>
      </div>
      <div class="list_current_rate">
        <ul>
          <li v-for="coin in coins" :key="coin" v-if="currentRates[coin] && currentRates[coin].price_currency">
            <span class="name_coin">1{{ coin | uppercase }}</span>
            <span class="comparison_sign">=</span>
            <span class="number_rate">{{ currentRates[coin].price_currency | formatCurrencyAmount(coin) }}</span>
            <span class="currency">{{ countryCurrency | uppercase }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import MasterdataUtils from '@/common/MasterdataUtils';

  const CURRENCY_COUNTRY = 'currency_country';

  export default {
    components: {
      MasterdataUtils
    },
    data() {
      return {
        currentRates: {},
        coins: [],
        countryCurrency: null,
      };
    },
    computed: {
      isHasCurrentRates() {
        return !window._.isEmpty(this.currentRates);
      }
    },
    methods: {
      getSocketEventHandlers() {
        return {
          CoinMarketCapTickerUpdated: this.onCoinMarketCapTicker
        }
      },

      getCurrentRates() {
        rf.getRequest('CoinMarketCap').getCurrentRates().then(res => {
          this.onCoinMarketCapTicker(res.data);
        });
      },

      onCoinMarketCapTicker(data) {
        this.currentRates = window._.chain(data)
          .map(item => {
            item.coin = item.symbol.toLowerCase();
            return item;
          })
          .keyBy('coin')
          .value();
      },

      getMasterdata() {
        rf.getRequest('MasterdataRequest').getAll().then(res => {
          this.coins = window._.map(res.coins_confirmation, item => item.coin);
          const result = window._.filter(res.settings, item => item.key === CURRENCY_COUNTRY);
          if (result.length) {
            this.countryCurrency = result[0].value;
          }
        });
        MasterdataUtils.getCoins((coins) => {
          this.coins = coins;
        })
        console.log(1)
      }
    },
    created() {
      this.getMasterdata();
      this.getCurrentRates();
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
#current_rate {
  .group_current_rate {
    display: block;
    width: 900px;
    margin: auto;
    padding: 30px 0px 20px 0px;
    .group_icon_current_rate {
      width: 225px;
      float: left;
      display: block;
      .icon-chart {
        font-size: $font-title-size-big;
        color: $color-blue-cerulean-light;
        float: left;
        margin-right: 15px;
      }
      .txt_current_rate {
        display: block;
        overflow: hidden;  
        color: $color-blue-cerulean-light;  
        font-size: $font-big;
        font-weight: 600;
        line-height: 22px;
      }
    }
    .list_current_rate {
      width: calc(100% - 225px);
      display: block;
      float: left;
      ul {
        font-size: $font-small;
        color: $color-grey-dusty;
        line-height: 20px;
        list-style-type: none;
        li {
          float: left;
          width: calc(100% / 3);
          margin-bottom: 15px;
          font-weight: 500;
          span {
            display: inline-block;
            float: left;
            &.comparison_sign{
              margin: 0px 20px;
            }
            &.number_rate {
              color: $color-blue-cerulean-light;
              font-weight: 600;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &.currency {
              margin-left: 3px; 
            }
          }
        }
      }
    }
  }  
}

</style>
