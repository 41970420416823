export default {
  MASTERDATA: {
    KEY: "masterdata",
    VERSION_KEY: "masterdata_version",
    EVENT_CHANGED: "MasterdataChanged",
  },
  KEYS_GLOBAL: {
    APP_NAME: "DIKEX",
    APP_SHORT_NAME: "DIKEX",
    COIN_HOLDING: "AMAL",
  },
  MIXPANEL_TOKEN: "45865afc8f2a3bd0bd9ccb3c346e7d32",
  SPOT_EXCHANGE: {
    ORDER_TYPE_ALL: "all",
    ORDER_TYPE_LIMIT: "limit",
    ORDER_TYPE_MARKET: "market",
    ORDER_TYPE_STOP_LIMIT: "stop_limit",
    ORDER_TYPE_STOP_MARKET: "stop_market",
  },
  OPEN_ORDER: "open_order",
  ORDER_HISTORY: "order_history",
  TRADE_HISTORY: "trade_history",
  SUB_ACCOUNTS: "sub_accounts",
  FUNDS: "funds",
  ONE_DAY: "one_day",
  ONE_WEEK: "one_week",
  ONE_MONTH: "one_month",
  THREE_MONTHS: "three_months",
  PER_PAGE: 10,
  DESC: "desc",
  ASC: "asc",
  USD: "usd",
  USDT: "usdt",
  BTC: "btc",
  AML: "amal",
  ETH: "eth",
  DEFAULT: "default",
  CURRENT_SUBJECT_SEARCH_DATE_TAB: "CURRENT_SUBJECT_SEARCH_DATE_TAB",
  CURRENT_SUBJECT_SEARCH_DATE_PICKER: "CURRENT_SUBJECT_SEARCH_DATE_PICKER",
  ACTION_SORT: "action_sort",
  ACTION_CANCEL: "action_cancel",
  ACTION_CHANGE_ORDER_TAB: "action_change_order_tab",
  ACTION_HIDE_OTHER_PAIR: "action_hide_other_pair",
  OPEN_ORDER_NAVI: [
    {
      id: "open_order",
      name: "menu.open_orders",
      isActive: false,
    },
    {
      id: "order_history",
      name: "menu.order_history",
      isActive: false,
    },
    {
      id: "trade_history",
      name: "menu.trade_history",
      isActive: false,
    },
    {
      id: "funds",
      name: "menu.funds",
      isActive: false,
    },
    // {
    //   id: 'sub_accounts',
    //   name: 'menu.sub_accounts',
    //   isActive: false,
    // }
  ],
  OPEN_ORDER_DATE_TAB: [
    {
      id: "one_day",
      name: "menu.one_day",
      isActive: false,
    },
    {
      id: "one_week",
      name: "menu.one_week",
      isActive: false,
    },
    {
      id: "one_month",
      name: "menu.one_month",
      isActive: false,
    },
    {
      id: "three_month",
      name: "menu.three_months",
      isActive: false,
    },
  ],
  MARKETS: ["all", "btc", "eth", "usd", "usdt"],
  MARKETS_2: [
    {
      name: "order.order_form.all",
    },
    {
      name: "BTC",
      icon: "/images/icon/coin/icon-btc.svg",
    },
    {
      name: "ETH",
      icon: "/images/icon/coin/icon-eth.png",
    },
    {
      name: "USD",
      icon: "/images/color_coins/usd.png",
    },
    {
      name: "USDT",
      icon: "/images/icon/coin/icon-usdt.png",
    },
  ],
  DEFAULT_MARKET: "btc",
  DEFAULT_COIN: "btc",
  DEFAULT_CURRENCY: "usdt",
  DEFAULT_MARGIN_SYMBOL: "BTCUSD",
  FUNDING: "funding",
  FAVORITES_TAB: "Favorites",
  DEFAULT_TAB: "Spot",
  FUTURE_TAB: "Futures",
  SELECT_SIDE: [
    {
      id: "all",
      name: "order.order_form.all",
    },
    {
      id: "buy",
      name: "order.order_form.buy",
    },
    {
      id: "sell",
      name: "order.order_form.sell",
    },
  ],
  TOTAL_AMAL: 126000000,
  TRADING_VIEW_CHART: {
    OBJECT: {
      SALES_POINT: "trandingview.object.salespoint",
      SPOT_EXCHANGE: "trandingview.object.spotexchange",
      MARGIN_EXCHANGE: "trandingview.object.marginexchange",
      PROFIT: "trandingview.object.profit",
    },
    BAR_STYLE: {
      STYLE_BARS: 0,
      STYLE_CANDLES: 1,
      STYLE_LINE: 2,
      STYLE_AREA: 3,
      STYLE_HEIKEN_ASHI: 8,
      STYLE_HOLLOW_CANDLES: 9,
    },
  },
  MINUTES: ["1", "3", "5", "15", "30"],
  HOURS: ["60", "120", "240", "360", "720"],
  CHARTING_RESOLUTION_MULTIPLICITY: { D: 1440, W: 10080, M: 43200 },
  REFERRAL_KEY: "ref",
  LANGUAGES: ["en", "ja", "vis", "il", "tl"],
  FIAT_LIKE_LIST: ["usd", "usdt"],
  NUMBER_OF_DECIMAL_DIGITS: 8,
  NUMBER_OF_DECIMAL_DIGITS_USD: 2,
  // TYPE_MAIN_BALANCE: 'main',
  TYPE_MAIN_BALANCE: "spot",
  TYPE_EXCHANGE_BALANCE: "spot",
  TYPE_MARGIN_BALANCE: "margin",
  TYPE_MAM_BALANCE: "mam",
  PERPETUAL_DIVIDEND_BALANCE: "perpetual_dividend_balance",
  DIVIDEND_BALANCE: "dividend_balance",
  TYPE_AIRDROP_BALANCE: "airdrop",
  CIRCUIT_BREAKER: {
    ENABLE_TRADING: "enable",
    DISABLE_TRADING: "disable",
  },
  ENABLE_TRADING: {
    ENABLE: "enable",
    DISABLE: "disable",
    WAITING: "waiting",
    IGNORE: "ignore",
  },
  BETA_TESTER: {
    ACTIVE: "active",
    WAITING: "waiting",
    INACTIVE: "inactive",
  },
  TIME_IN_FORCE: [
    {
      id: "gtc",
      name: "GoodTillCancel",
      isActive: true,
    },
    {
      id: "ioc",
      name: "ImmediateOrCancel",
      isActive: false,
    },
    {
      id: "fok",
      name: "FillOrKill",
      isActive: false,
    },
  ],
  MAX_LENGTH_INPUT: 190,
  MAX_LENGTH_20: 20,
  COMMISSION_PERCENT: "20%",
  // MENU_CONTRACTS: [
  //   {
  //     type: 'tree',
  //     icon: '',
  //     name: 'Historical Data',
  //     isHasPermission: false,
  //     items: [
  //       {
  //         type: 'item',
  //         icon: '',
  //         name: 'Funding History',
  //         router: {
  //           name: '/margin-exchange/contracts/funding-history'
  //         }
  //       },
  //       {
  //         type: 'item',
  //         icon: '',
  //         name: 'Insurance Fund',
  //         router: {
  //           name: '/margin-exchange/contracts/insurance-fund'
  //         }
  //       },
  //       {
  //         type: 'item',
  //         icon: '',
  //         name: 'Settlement History',
  //         router: {
  //           name: '/margin-exchange/contracts/settlement-history'
  //         }
  //       },
  //       {
  //         type: 'item',
  //         icon: '',
  //         name: 'Leaderboard',
  //         router: {
  //           name: '/margin-exchange/contracts/leaderboard'
  //         }
  //       },
  //     ]
  //   },
  //   {
  //     type: 'tree',
  //     icon: '',
  //     name: 'Contract Guides',
  //     isHasPermission: false,
  //     items: [
  //       {
  //         type: 'item',
  //         icon: '',
  //         name: 'Futures Guide',
  //         router: {
  //           name: '/margin-exchange/contracts/futures-guide'
  //         }
  //       },
  //       {
  //         type: 'item',
  //         icon: '',
  //         name: 'Perpetual Contracts Guide',
  //         router: {
  //           name: '/margin-exchange/contracts/perpetual-contracts-guide'
  //         }
  //       },
  //       // {
  //       //   type: 'item',
  //       //   icon: '',
  //       //   name: 'Upside Profit Contracts Guide',
  //       //   router: {
  //       //     name: 'z3'
  //       //   }
  //       // },
  //       // {
  //       //   type: 'item',
  //       //   icon: '',
  //       //   name: 'Downside Profit Contracts Guide',
  //       //   router: {
  //       //     name: 'z4'
  //       //   }
  //       // },
  //     ]
  //   },
  //   {
  //     type: 'tree',
  //     icon: '',
  //     name: 'Contracts',
  //     isHasPermission: false,
  //     items: []
  //   },
  //   {
  //     type: 'tree',
  //     icon: '',
  //     name: 'Indices',
  //     isHasPermission: false,
  //     items: []
  //   },
  // ],
  MARGIN_CONTRACTS: {
    BTC: "btc",
    BTC7D: "btc7d",
    XBJ: "xbj",
    ADA: "ada",
    BCH: "bch",
    ETH: "eth",
    TRX: "trx",
  },
  MARGIN_ORDER_TAB_LIST: [
    "limit",
    "market",
    "stop_limit",
    "stop_market",
    "trailing_stop",
    "take_profit_market",
    "take_profit_limit",
  ],
  MARGIN_ORDER_TAB_EXCLUDE: {
    //[contract_symbol]: [...MARGIN_ORDER_TAB_LIST]
  },
  MARGIN_INDICE_TYPE: {
    PREMIUM: "premium",
    FREQUENCY: "frequency",
    AMI: "ami",
    CONSTANCE: "constance",
  },
  MARGIN_DEFAULT_SYMBOL: "BTCUSD",
  MARGIN_DEFAULT_TICK_SIZE: 0.5,
  MAM_ALLOCATION_LIST: ["lot", "percent"],
  LOGIN_ACCEPT_AGREEMENT: "ACCEPT_AGREEMENT",
  MARKET_TAB_MAIN: ["Favorites", "Spot", "Futures"],
  MARKET_TAB_HOME: ["Spot", "Futures"],
  SETTINGS_ACCOUNT_PATH: [
    {
      path: "/account/dashboard",
      name: "account.dashboard",
      icon: "/images/icon/icon-dashboard.svg",
      iconDark: "/images/icon/icon-dashboard-dark.svg",
      // iconActive: '/images/icon/icon-dashboard-active.svg',
    },
    {
      path: "/account/security",
      name: "account.security",
      icon: "/images/icon/icon-security.svg",
      iconDark: "/images/icon/icon-security-dark.svg",
      // iconActive: '/images/icon/icon-security-active.svg',
    },
    {
      path: "/account/referral",
      name: "account.referral",
      icon: "/images/icon/icon-referral.svg",
      iconDark: "/images/icon/icon-referral-dark.svg",
      // iconActive: '/images/icon/icon-referral-active.svg',
    },
    {
      path: "/account/reward-center",
      name: "account.reward.center",
      icon: "/images/icon/icon-reward.svg",
      iconDark: "/images/icon/icon-reward-dark.svg",
      // iconActive: '/images/icon/icon-reward-active.svg',
    },
    {
      path: "/account/api-key",
      name: "account.api.management",
      icon: "/images/icon/icon-api-manage.svg",
      iconDark: "/images/icon/icon-api-manage-dark.svg",
      // iconActive: '/images/icon/icon-api-manage-active.svg',
    },
  ],

  OPEN_ORDER_SIDEBAR: [
    {
      id: "open_order",
      name: "menu.open_orders",
      isActive: true,
    },
    {
      id: "order_history",
      name: "menu.order_history",
      isActive: false,
    },
    {
      id: "trade_history",
      name: "menu.trade_history",
      isActive: false,
    },
  ],

  // AAVE: Spot balance
  // BNB: Spot balance, COIN-M Futures
  // BTC: Spot balance, COIN-M Futures
  // LINK: Spot balance
  // ATOM: Spot balance, COIN-M Futures
  // MANA: Spot balance
  // DOGE: Spot balance
  // ETH: Spot balance, COIN-M Futures
  // FIL: Spot balance
  // LTC:  COIN-M Futures
  // MATIC: Spot balance, COIN-M Futures
  // DOT: Spot balance
  // SOL: Spot balance, COIN-M Futures
  // USDT: Spot balance, USDs-M Futures
  // TRX: Spot balance
  // UNI: Spot balance, COIN-M Futures
  // USD: Spot balance, USDs-M Futures

  COIN_TRANSFER_TYPE: {
    SPOT: [
      "AAVE",
      "BNB",
      "BTC",
      "LINK",
      "ATOM",
      "MANA",
      "DOGE",
      "ETH",
      "FIL",
      "MATIC",
      "DOT",
      "SOL",
      "USDT",
      "TRX",
      "UNI",
      "USD",
    ],
    COIN_M: ["BNB", "BTC", "ATOM", "ETH", "MATIC", "SOL", "UNI", "LTC", "XRP"],
    FUTURE: ["USD", "USDT"],
  },

  LIST_PAIR_HOME_USDM: ["BTCUSDT", "ETHUSDT", "BNBUSDT", "XRPUSDT", "LTCUSDT"],

  LIST_PAIR_HOME_COINM: [
    "BTCUSDM",
    "ETHUSDM",
    "BNBUSDM",
    "SOLUSDM",
    "MATICUSDM",
  ],
};
