<template>
  <base-layout>
    <div class="auth-container">
      <div class="input-group">
        <!--            <div class=" verify-box">-->
        <!--            <a class="link-primary text-homepage-login" target="_blank" href="/">-->
        <!--                <span class="icon-network"></span>-->
        <!--                <span class="link-text-login ">{{ host }}</span>-->
        <!--            </a>-->
        <!--            </div>-->
      </div>
      <!-- <div class="guest-logo">
                <router-link to="/" ><img src="@/assets/images/logo/logo-demo.png"></router-link>
            </div> -->
      <BlackPopup @blackPopClick="blackPopClick" v-if="showAcceptPop">
        <div class="pop_con law_tip">
          <div class="poptit">Confirm／確認</div>
          <div class="poptip">
            アマンプリは各国の法律に準じた営業をしています。
            一部の国の法律では利用を制限されることがあります。
            自国の法律をよく理解した上で利用を行って下さい。
            またアマンプリでは既にデポジットされている資金について口座凍結、出金禁止などの対応は一切おこなっておりません。
          </div>
          <div class="accept">
            <input
              type="checkbox"
              name="accept"
              value="true"
              @click="acceptClick"
              checked="checked"
            />
            理解しました。
          </div>
          <button
            :class="{
              chrome_only: isChrome && !errors.any(),
              disabledBtn: isSubmitting,
            }"
            tabindex="0"
            :disabled="!isAccept"
            type="button"
            class="btn-primary btn-primary-loading btn-primary"
            @click="acceptSubmit"
          >
            <img
              src="@/assets/images/loading.png"
              class="loading-img"
            />Confirm／確認
          </button>
        </div>
      </BlackPopup>
      <!-- end guest-logo -->
      <div
        :class="{ 'guest-page-form': locale, 'guest-page-form-lang': !locale }"
      >
        <!-- Begin OTP form -->
        <div class="form-container" id="otp-form" v-show="shouldShowOtpForm">
          <img
            src="/images/icon/icon-logo-google.svg"
            alt="google-authentication_modal"
            width="136px"
            height="106"
            class="icon-google"
          />
          <div class="guest-page-form-header-opt text-center">
            <h2 class="title-account">{{ $t("login.title_otp") }}</h2>
          </div>
          <div class="guest-page-form-body">
            <div class="input-group mb-0">
              <!--              <div class="input-group-prepend"><span class="icon-chrome2"></span></div>-->
              <currency-input-otp
                v-if="shouldShowOtpForm"
                type="text"
                maxlength="6"
                id="otp-field"
                class="form-input"
                :class="{ error: errors.has('otp') }"
                v-model="otp"
                name="otp"
                :placeholder="$t('common.placeholders.otp')"
                @focus="resetError"
              />
            </div>
            <div class="invalid-feedback" v-if="failCapcha">
              {{ this.$t("common.geetest.expired") }}
            </div>
            <div class="message-validate-login-google-otp">
              <div
                class="invalid-feedback invalid-feedback-otp"
                v-if="errors.has('otp')"
              >
                {{ errors.first("otp") }}
              </div>
            </div>
            <div class="login_box-otp">
              <label
                class="form-check-label text-normal"
                @click="showModalLostOtp"
              >
                <a class="link-primary f-13" href="#">{{
                  $t("shared_components.google_authentication_modal.lost_code")
                }}</a>
              </label>
            </div>
            <div class="form_actions">
              <button
                :class="{ chrome_only: isChrome }"
                tabindex="0"
                :disabled="isSubmitting"
                type="button"
                class="btn-primary"
                @click="submit"
              >
                {{ getSubmitName($t("account.identity.submit")) }}
              </button>
            </div>
          </div>
        </div>
        <!-- End OTP form -->
        <!-- Begin login form -->
        <div class="form-container" v-show="!shouldShowOtpForm">
          <div class="guest-page-form-header text-center">
            <h3 class="title t-uppercase-comment title-account">
              {{ $t("login.title-2") }}
            </h3>
          </div>
          <div
            class="guest-page-form-body"
            v-if="Object.keys(activeCode).length == 0"
          >
            <div class="input-group">
              <label class="label-input mb-8">{{ $t("login.email2") }}</label>
              <input
                v-model.trim="email"
                @focus="resetError"
                :placeholder="$t('common.placeholders.email2')"
                :class="{ error: errors.has('email') }"
                :name="'email'"
                :maxlength="maxlength"
                data-vv-validate-on="none"
                v-validate="'required|email'"
                type="text"
                class="form-input"
              />
              <div class="invalid-feedback" v-if="errors.has('email')">
                {{ errors.first("email") }}
              </div>
            </div>

            <div class="input-group">
              <!--              <div class="input-group-prepend"><span class="icon-key2"></span></div>-->
              <label class="label-input mb-8">{{ $t("login.password") }}</label>
              <div class="input-group">
                <div class="pos-relative">
                  <input
                    v-model="password"
                    @focus="resetError"
                    :placeholder="$t('common.placeholders.password2')"
                    :class="{ error: errors.has('password') }"
                    name="password"
                    :maxlength="maxlength"
                    data-vv-validate-on="none"
                    v-validate="'required'"
                    :type="isEyeOpen ? 'text' : 'password'"
                    class="form-input"
                  />
                  <div @click="isEyeOpen = !isEyeOpen" class="icon-last">
                    <img
                      v-if="userThemeMode == 'light-theme'"
                      :src="`/images/eye-${isEyeOpen ? 'open' : 'close'}.svg`"
                      alt="eye"
                    />
                    <img
                      v-else
                      :src="`/images/eyes-${
                        isEyeOpen ? 'open-dark' : 'close-dark'
                      }.svg`"
                      alt="eye"
                    />
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.has('password')">
                  {{ errors.first("password") }}
                </div>
                <div class="invalid-feedback" v-if="errors.has('login_error')">
                  {{ errors.first("login_error") }}
                </div>
              </div>
            </div>
            <!-- use geetest -->
            <!--                <geetest-com ref="getGeetestObj" @geetest_updated="onGeetestUpdated"></geetest-com>-->
            <!--                <div class="invalid-feedback invalid-feedback_1" v-if="errors.has('invalid_captcha')">-->
            <!--                    {{ errors.first('invalid_captcha') }}-->
            <!--                  <VueRecaptcha id="login-captcha" ref="recaptcha" :sitekey="GOOGLE_CAPTCHA_KEY"-->
            <!--                                @verify="onVerifyCaptcha" @expired="onExpiredCaptcha"-->
            <!--                               />-->
            <!--                  <div class="invalid-feedback_1" v-if="failCapcha === true">-->
            <!--                    {{ $t('common.geetest.fail') }}-->
            <!--                </div>-->
            <!-- <div class="input-group">
                    <div>
                    <div id="embed-captcha"></div>
                    <p id="wait" class="show">{{ $t('common.geetest.loading') }}<p>
                    <p id="notice" class="hide">{{ $t('common.geetest.fail') }}</p>
                    </div>
                    <div class="invalid-feedback" v-if="geetestFail">
                    {{ $t('common.geetest.fail') }}
                    </div>
                </div> -->
            <!-- end geetest -->
            <div class="form_actions">
              <button
                :class="{
                  chrome_only: isChrome && !errors.any(),
                  disabledBtn: isSubmitting,
                }"
                tabindex="0"
                :disabled="isSubmitting"
                type="button"
                class="btn-primary btn-login btn-primary-loading"
                @click="submit"
              >
                <img src="@/assets/images/loading.png" class="loading-img" />{{
                  getSubmitName($t("login.title"))
                }}
              </button>

              <router-link
                class="link-primary forgot_password"
                to="/reset-password"
                >{{ $t("login.forgot") }}</router-link
              >
            </div>

            <div class="box_line">
              <span class="line" />
              <span>Or</span>
              <span class="line" />
            </div>

            <div class="login_box">
              <router-link class="link-primary" to="/register">{{
                $t("menu.register_3")
              }}</router-link>
            </div>
          </div>
          <!-- New Device Login -->
          <div v-else class="fo_green_4 guest-page-form-body">
            <img
              src="@/assets/images/icon/trace2.svg"
              width="100px"
              height="80px"
            />
            <!--                  <span class="icon-responsive login-complete-icon"></span>-->
            <div class="wrap-notification">
              <h3
                class="tit_modal_confir password-change"
                v-html="$t('login.active_device_success', activeCode)"
              ></h3>
            </div>
            <div class="mart50">
              <button
                type="button "
                data-dismiss="modal"
                v-on:click="redirectLogin"
                class="btn-primary btn-ok"
              >
                {{ $t("common.action.ok") }}
              </button>
            </div>
          </div>
          <!--
              <div class="code-qr-app">
                <div :class="{box_expired : statusQRLogin == 1 }" v-if="statusQRLogin == 1 ">
                  <img src="@/assets/images/icon/successCheck.svg" width="80px" height="80px"/>
                  <p class="text-confirm">{{ $t('login.qr-confirm') }}</p>
                </div>
                <div :class="{box_expired: statusQRLogin == 3}" v-else-if="statusQRLogin == 3">
                  <p class="text-confirm">{{ $t('login.qr-expried') }}</p>
                  <button type="button" class="btn-fresh" @click="handelRefreshQR">{{ $t('login.qr-btn-refresh') }}</button>
                </div>
                <div :class="{box_expired: statusQRLogin == 0}" v-else-if="statusQRLogin == 0">
                  <p class="text-confirm">{{ $t('login.qr-cancelled') }}</p>
                  <button type="button" class="btn-fresh" @click="handelRefreshQR">{{ $t('login.qr-btn-refresh') }}</button>
                </div>
                <div :class="{box_expired: statusQRLogin == 2}" v-else-if="statusQRLogin == 2 ">
                  <img src="@/assets/images/icon/successCheck.svg" width="80px" height="80px"/>
                  <p class="text-confirm">{{ $t('login.qr-login-in') }}</p>
                </div>
                <div class="box-qr-code" :class="{'time-expired': statusQRLogin !== -1}">
                  <qrcode :value="dataQR" render-as="canvas" level="L"/>
                </div>
               
                <p class="text-qr"> {{ $t('login.qr-text') }}</p>
                <p class="note-qr" v-html="$t('login.qr-note')"></p>
              </div> -->
        </div>
        <!-- End login form -->
      </div>
      <lost-otp-modal
        name="login_lostOtpForm"
        @DISABLE_OTP_SUCCESS="disableOtpSuccess"
        :contact_email="siteSetting.contact_email"
      />
      <!--            <succeed-modal name="login-succeed-modal" :message="modalMessage" />-->
      <ConfirmationModal
        name="login_confirm_modal"
        class="login_confirm_modal"
      />
      <message></message>
    </div>
  </base-layout>
</template>

<script>
import rf from "@/request/RequestFactory";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import LostOtpModal from "@/components/shared_components/otp/LostOtpModal";
import Message from "@/components/shared_components/common/Message";
import BaseLayout from "./BaseLayout.vue";
import SucceedModal from "./SucceedModal.vue";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal";
import COMMON_CONST from "@/common/Const";
import CurrencyInputOtp from "@/components/shared_components/common/CurrencyInputOtp.vue";
import GeetestCom from "@/components/Geetest.vue";
import BlackPopup from "@/components/Popup.vue";
import CookiesUtils from "@/common/CookiesUtils";
import { VueRecaptcha } from "vue-recaptcha";
import { mapState } from "vuex";
import Cookies from "js-cookie";

const OTP_ERROR = "invalid_otp";

export default {
  data() {
    return {
      email: "",
      password: "",
      otp: "",
      modalMessage: "",
      shouldShowOtpForm: false,
      isConfirming: false,
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      host: window.location.origin,
      siteSetting: {},
      isChrome: window.navigator.userAgent.toLowerCase().indexOf("chrome") > 0,
      otpRequest: "",
      isOnline: true,
      redirectLink: null,
      locale: true,
      geetestData: null,
      showAcceptPop: false,
      isAccept: true,
      targetUrl: "",
      GOOGLE_CAPTCHA_KEY: process.env.VUE_APP_GOOGLE_CAPTCHA_KEY,
      is_ggcaptcha: 0,
      failCapcha: null,
      isEyeOpen: false,
      activeCode: {},
      dataQRRandom: "",
      dataQR: "",
      statusQRLogin: -1,
    };
  },
  components: {
    BaseLayout,
    LostOtpModal,
    SucceedModal,
    Message,
    ConfirmationModal,
    CurrencyInputOtp,
    GeetestCom,
    BlackPopup,
    VueRecaptcha,
  },
  props: {
    confirmationCode: { type: String, default: "" },
    deviceCode: { type: String, default: "" },
    customRedirect: { type: String, default: null },
  },
  watch: {
    otp: function (newVal) {
      if (window._.size(newVal) === 6) {
        this.submit();
      }
    },
    $route: function (to, from) {
      console.log(from);
    },
  },

  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
      userThemeMode: (state) => state.userThemeMode,
    }),
    isSubmitable() {
      return (
        !this.isSubmitting &&
        !this.isConfirming &&
        (!this.shouldShowOtpForm || this.otp.length >= 6)
      );
    },
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      await this.$recaptcha("login").then((token) => {
        this.geetestData = token;
        this.is_ggcaptcha = 1;
      });
    },
    onVerifyCaptcha(response) {
      this.failCapcha = false;
      this.geetestData = response;
      this.is_ggcaptcha = 1;
    },
    onExpiredCaptcha() {
      this.failCapcha = true;
      this.geetestData = null;
      this.is_ggcaptcha = 0;
    },
    resetError() {
      this.errors.clear();
    },
    openGeetestLink() {
      const url = "/authorization";
      window.open(url, "_blank");
      return;
    },
    confirm() {
      this.isConfirming = true;
      if (this.isSubmitting) {
        return;
      }
      this.startSubmit();
      rf.getRequest("UserRequest")
        .confirm(this.confirmationCode)
        .then((response) => {
          this.isConfirming = false;
          this.endSubmit();
          this.showConfirmedModal(true);
        })
        .catch((error) => {
          this.isConfirming = false;
          this.endSubmit();
          if (this.isOnline) {
            this.showConfirmedModal(false);
          } else {
            $("#confirmationModal").modal("hide");
            this.showModalIsOffline();
          }
        });
    },
    activeDevice() {
      if (this.isSubmitting) {
        return;
      }
      this.startSubmit();
      rf.getRequest("UserRequest")
        .grantAccessDevice(this.deviceCode)
        .then((response) => {
          // console.log('responsive' + JSON.stringify(response.data.result));
          this.endSubmit();
          // this.showActiveDeviceModal(response.data.result || {});
          this.activeCode = response.data.result || {};
          // this.de
        })
        .catch((error) => {
          this.endSubmit();
          if (this.isOnline) {
            this.showActiveDeviceModal(false);
          } else {
            $("#confirmationModal").modal("hide");
            this.showModalIsOffline();
          }
        });
    },
    async submit() {
      this.resetError();
      this.is_ggcaptcha = 0;
      if (!this.shouldShowOtpForm) {
        await this.$validator.validate("email");
        await this.$validator.validate("password");
        // if (!this.$refs.getGeetestObj.validateForm()) {
        //   return;
        // }
      } else {
        if (this.otp.length < 6 && this.otp.length > 0) {
          this.errors.add(
            "otp",
            this.$t("funds.withdrawals.errors.otp_length_6_digits")
          );
        }

        if (this.otp.length === 0) {
          this.errors.add(
            "otp",
            this.$t("funds.withdrawals.errors.otp_required")
          );
        }
      }
      await this.recaptcha();

      if (!this.geetestData) {
        // this.failCapcha = true;
        return;
      }

      if (this.errors.any()) {
        return;
      }
      if (!this.isSubmitable) {
        return;
      }
      this.submitReq();
      // let lang = window.localStorage.locale;
      // let  acceptAgreement = !!CookiesUtils.getItem(COMMON_CONST.LOGIN_ACCEPT_AGREEMENT);
      // if(lang === 'ja' && !acceptAgreement) {
      //     this.showAcceptPop = true;
      // }else {
      //     if (!this.isSubmitable) {
      //         return;
      //     }
      //     this.submitReq();
      // }
    },
    acceptSubmit() {
      this.showAcceptPop = false;
      window.location.href = this.targetUrl;
      CookiesUtils.saveItem(COMMON_CONST.LOGIN_ACCEPT_AGREEMENT, true);
    },
    blackPopClick() {
      this.acceptSubmit();
    },
    submitReq() {
      let acceptAgreement = !!CookiesUtils.getItem(
        COMMON_CONST.LOGIN_ACCEPT_AGREEMENT
      );

      this.resetError();
      this.startSubmit();
      this.otpRequest = this.otp;

      // const redirectRouteName = this.$route.query.return_to;
      const redirectRouteName = this.redirectLink;
      rf.getRequest("UserRequest")
        .login(
          this.email,
          this.password,
          this.otpRequest,
          this.geetestData,
          this.is_ggcaptcha,
          redirectRouteName
        )
        .then((response) => {
          this.endSubmit();
          // checkTerminal bug
          localStorage.setItem("checkTerminal", JSON.stringify(response));
          let locale = response.locale;
          response.locale =
            Cookies.get("locale", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
            locale;
          console.log("response", response);
          AuthenticationUtils.saveAuthenticationData(response);
          const defaultRouteName = "Landing Exchange";
          const destinationRouteName = this.$route.query.destination;
          let urlSearch = new URLSearchParams(window.location.search);
          console.log("urlSearch", window.location.search);
          const returnTo = urlSearch.get("return_to");
          const redirectRouteName =
            response.redirectUrl || destinationRouteName || "Landing Exchange";
          console.log("returnTo", returnTo);
          console.log("redirectRouteName", redirectRouteName);
          let targetUrl = "";
          if (returnTo == "zendesk") {
            targetUrl = redirectRouteName;
          } else {
            let abc = this.$router.resolve({ name: redirectRouteName });
            console.log("abc", abc);
            // targetUrl = this.$router.resolve({ name: redirectRouteName }).href;
            targetUrl = this.$router.resolve({ name: redirectRouteName }).href;
            // targetUrl = redirectRouteName;
          }
          if (this.customRedirect) {
            targetUrl = this.$router.resolve({
              name: this.customRedirect,
            }).href;
          }
          if (targetUrl == "/") {
            // Default redirect to Sale Point
            targetUrl = this.$router.resolve({ name: defaultRouteName }).href;
          }
          this.targetUrl = targetUrl;
          console.log("targetUrl", this.targetUrl);
          window.localStorage.removeItem("Trade_popup");
          if (window.localStorage.locale === "ja" && !acceptAgreement) {
            this.showAcceptPop = true;
          } else {
            window.location.href = targetUrl;
          }
        })
        .catch((error) => {
          if (this.isOnline) {
            this.endSubmit();
            // this.$refs.getGeetestObj.resetForm();
            this.showAcceptPop = false;
            const isOtpError = error.response.data.error === OTP_ERROR;
            this.errors.add(
              isOtpError ? "otp" : "login_error",
              this.$t(`${error.response.data.message}`)
            );

            if (this.shouldShowOtpForm || !isOtpError) {
              // this.errors.add(isOtpError ? 'otp' : 'login_error', this.$t(`${error.response.data.message}`));
              // this.errors.items[0].msg = this.$t(`${error.response.data.message}`)
              // console.log(error.response.data.error, error.response.data.message)
              // this.errors.add({field : error.response.data.error, msg : this.$t(`${error.response.data.message}`)})

              if (this.errors.items[0]) {
                this.errors.items[0].msg = this.$t(
                  `${error.response.data.message}`
                );
                // this.errors.items[0].msg = this.$t('auth.failed_login');
              }
              // if (error.response.data.error == "geetest_failed") {
              //   this.errors.add('invalid_captcha', 'Captcha is invalid.');
              // } else {
              //   this.errors.add(isOtpError ? 'otp' : 'login_error', this.$t(`${error.response.data.message}`));
              //   if (error.response.data.error === "invalid_credentials") {
              //     if (this.errors.items[0]) {
              //         this.errors.items[0].msg = this.$t('auth.failed_login');
              //     }
              //   }
              //   else if (error.response.data.error === "invalid_device") {
              //     if (this.errors.items[0]) {
              //         this.errors.items[0].msg = this.$t('account.invalid_device');
              //     }
              //   }
              //   else if (error.response.data.error === "invalid_otp") {
              //     if (this.errors.items[0]) {
              //         this.errors.items[0].msg = this.$t('validation.otp_incorrect');
              //     }
              //   }
              // }
            }
            this.shouldShowOtpForm = isOtpError;
          } else {
            $("#confirmationModal").modal("hide");
            this.showModalIsOffline();
          }
        });
    },
    acceptClick(e) {
      this.isAccept = e.target.checked;
    },
    onGeetestUpdated(data) {
      this.geetestData = data;
    },
    showModalLostOtp() {
      const data = {
        email: this.email,
        password: this.password,
      };
      window.CommonModal.show("login_lostOtpForm", { data: data });
    },
    showConfirmedModal(isSucceed) {
      this.modalMessage = isSucceed
        ? this.$t("login.confirm_success")
        : this.$t("login.confirm_failed");
      // window.CommonModal.show('login-succeed-modal');
      isSucceed
        ? this.showModal("confirm-succeed-modal2", this.modalMessage)
        : this.showModal("confirm-fail-modal", this.modalMessage);
    },
    showActiveDeviceModal(result) {
      this.modalMessage = result
        ? this.$t("login.active_device_success", result)
        : this.$t("login.active_device_failed");
      window.CommonModal.show("login-succeed-modal");
      result
        ? this.showModal("login-succeed-modal", this.modalMessage)
        : this.showModal("confirm-fail-modal-device", this.modalMessage);
    },
    disableOtpSuccess(message) {
      this.shouldShowOtpForm = false;
      this.otp = "";
    },
    showModal(type, message) {
      window.ConfirmationModal.show({
        type: type,
        title: message,
        content: "",
        btnCancelLabel: null,
        btnConfirmLabel: null,
        widthModal: 560,
        onConfirm: () => {},
        onCancel: () => {},
      });
    },
    showModalIsOffline() {
      $("#confirmationModal").modal("hide");
      let type = "connection-internet-fail",
        message = this.$t("client_connection.fail");
      this.showModal(type, message);
    },
    initData() {
      window._.each(this.masterdata.settings, (item) => {
        this.siteSetting[item.key] = item.value;
      });
      this.generateRandomFormat();
    },
    handleInternetConnection(status) {
      this.isOnline = status;
      if (!status) {
        this.showModalIsOffline();
      }
    },
    redirectLogin() {
      this.activeCode = {};
      this.$router.push({ name: "Login" });
    },
    toggleTheme() {
      const activeTheme =
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme";
      if (this.$route.query.theme === "dark" || activeTheme === "dark-theme") {
        this.setTheme("dark-theme");
        this.$store.dispatch("setUserThemeMode", "dark-theme");
      } else {
        this.setTheme("light-theme");
        this.$store.dispatch("setUserThemeMode", "light-theme");
      }
    },
    setTheme(theme) {
      Cookies.set("user-theme", theme, {
        domain: process.env.VUE_APP_PUBLIC_URL,
      });
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    generateRandomFormat() {
      const getRandomDigits = () =>
        Math.floor(Math.random() * 1000)
          .toString()
          .padStart(3, "0");
      this.dataQRRandom = `${getRandomDigits()}-${getRandomDigits()}-${getRandomDigits()}`;
      this.getQRCode(this.dataQRRandom);
    },
    getQRCode(value) {
      const params = {
        random: value,
      };
      rf.getRequest("UserRequest")
        .getQRGenerate(params)
        .then((res) => {
          let data = {
            ip_address: res.data.ip,
            platform: res.data.platform,
            location: res.data.location?.countryName,
            qrcode: res.data.qrcode,
            random: res.data.random,
          };
          this.dataQR = JSON.stringify(data);
        });
    },
    getQRCodeCheck() {
      if (this.statusQRLogin !== 1 && this.statusQRLogin !== -1) return;
      let dataQR = JSON.parse(this.dataQR);
      const data = {
        random: dataQR.random,
        qrcode: dataQR.qrcode,
      };
      rf.getRequest("UserRequest")
        .getCheckQR(data)
        .then((res) => {
          // scanning | 0: cancel, 1: scanning, 2: confirm , 3: isExpired
          if (res.data === false) {
            this.statusQRLogin = 3;
          } else if (res.data.status == 1) {
            this.statusQRLogin = 1;
          } else if (res.data.status == 0) {
            this.statusQRLogin = 0;
          } else if (res.data.access_token) {
            this.statusQRLogin = 2;
            AuthenticationUtils.saveAuthenticationData(res.data);
            const defaultRouteName = "Landing Exchange";
            const destinationRouteName = this.$route.query.destination;
            let urlSearch = new URLSearchParams(window.location.search);
            const returnTo = urlSearch.get("return_to");
            const redirectRouteName =
              res.data.access_token.redirectUrl ||
              destinationRouteName ||
              "Landing Exchange";
            let targetUrl = "";
            if (returnTo == "zendesk") {
              targetUrl = redirectRouteName;
            } else {
              targetUrl = this.$router.resolve({
                name: redirectRouteName,
              }).href;
            }
            if (this.customRedirect) {
              targetUrl = this.$router.resolve({
                name: this.customRedirect,
              }).href;
            }
            if (targetUrl == "/") {
              // Default redirect to Sale Point
              targetUrl = this.$router.resolve({ name: defaultRouteName }).href;
            }
            this.targetUrl = targetUrl;
            window.location.href = targetUrl;
          }
        });
    },
    handelRefreshQR() {
      this.statusQRLogin = -1;
      this.generateRandomFormat();
    },
    // checkAvaliableQRCode() {
    //   setInterval(this.getQRCodeCheck, 3000);
    // }
  },

  mounted() {
    let lang = Cookies.get("locale", {
      domain: process.env.VUE_APP_PUBLIC_URL,
    });
    if (lang == "ja" || lang == "ko") this.locale = false;
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 13 && this.$route.name == "Login") {
        this.submit();
      }
    });
    if (this.confirmationCode) {
      return this.confirm();
    }
    if (this.deviceCode) {
      return this.activeDevice();
    }
    window.addEventListener("online", (event) => {
      this.handleInternetConnection(true);
    });
    window.addEventListener("offline", (event) => {
      this.handleInternetConnection(false);
    });
    // this.checkAvaliableQRCode()
  },
  created() {
    let urlSearch = new URLSearchParams(window.location.search);
    let redirectRouteName = urlSearch.get("return_to");
    console.log("redirectRouteName", redirectRouteName);
    if (redirectRouteName) {
      this.redirectLink = redirectRouteName;
      this.$router.replace({ name: "LoginZendesk" });
    }
    this.toggleTheme();
    this.initData();
  },
};
</script>
<style lang="scss">
.primary-app {
  color: $text-color-jade !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";
@import "@/assets/sass/reset.scss";
.invalid-feedback {
  padding-top: 5px;
  font-size: $font-root;
  font-family: $font-inter-regular;
}

.invalid-feedback_1 {
  margin-top: -15px;
  margin-bottom: 15px;
  font-size: $font-root;
  font-family: $font-inter-regular;
  color: $text-color-red;
}

input,
.form-input {
  display: block;
  //height: 49px;
  /* width: 400px !important; */
  max-width: 100%;
  padding: 12px;
  background: var(--background-color-second);
  border-radius: 123px;
  border-color: var(--color-border);
  color: var(--text-primary-color) !important;
  font-size: $font-root;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;

  &:focus,
  &:active {
    border: 1px solid $color-aquamarine !important;
  }
  &.error {
    border: 1px solid $text-color-red !important;
  }
  &::placeholder {
    color: var(--text-placeholder);
  }
}

input::placeholder {
  color: var(--text-placeholder);
}

.input-group-prepend {
  span {
    line-height: 22px;
  }
}

.verify-box {
  border-radius: 3px;
  height: 45px;
  text-align: center;
  border: $border-white;
  padding-left: 0 !important;
  background-color: $color-aqua-day;
  line-height: 30px;
  .icon-home2 {
    padding-right: 10px;
  }
  a {
    position: relative;
    top: 5%;
    transform: perspective(1px) translateY(-50%);
  }
}

.text-homepage-login {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 20px;
  .link-text-login {
    font-size: $font-medium;
    line-height: 40px;
    color: $pure_color_white;
    font-weight: normal;
    align-items: center;
    justify-content: center;
  }
  .icon-network {
    color: $pure_color_white;
    font-size: $font-big-24;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}

.btn-ok {
  text-transform: none;
}
.btn-primary {
  height: 50px;
  /* width: 400px; */
  max-width: 100%;
  border-radius: 1234px;
  background-color: $color-caribbean-green;
  line-height: 2.07;
  letter-spacing: 1.1px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.084px;

  &:disabled {
    background-color: $color-aquamarine !important;
    border-color: $border-blue-custom !important;
    color: $color-grey-dark !important;
  }
  &:hover:enabled,
  &:focus {
    background-color: $color-aquamarine !important;
    border-color: $border-blue-custom !important;
    color: $color-grey-dark !important;
  }
}
.btn-login {
  text-transform: none;
}

.login_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  margin-top: 32px;
}

.title-l {
  margin: 13px 0px 15px 0px;
  height: 1px;
  width: 55%;
  border-radius: 5px;
  background-color: $color-light-grey;
}

.forgot_password {
  display: block;
  text-align: end;
  margin-top: 12px;
}

.f-13 {
  font-size: $font-small;
  font-weight: normal !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.title-account {
  margin: auto !important;
  margin-bottom: 40px !important;
  font-size: $font-title-size-medium-2;
  font-style: normal;
  line-height: 41px;
  color: var(--text-primary-color) !important;
  font-family: $font-inter-bold !important;
}

.form-container {
  max-width: 100%;
}

.img-network-login {
  height: 25px;
  margin: 0px 15px;
}

.link-primary {
  text-decoration: none;
  color: $color-caribbean-green !important;
  font-size: 16px;
  line-height: 21px;
  &:hover {
    color: $color-caribbean-green !important;
  }
}

.text-center-opt span {
  width: 71px;
}

//LOGIN GOOGLE AUTHENTICATION CODE
.mb-0 {
  margin-bottom: 0px;
}

.guest-page-form-header-opt {
  height: 40px;
  display: flex;
  margin-top: 0;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;

  .title-otp {
    margin: 0;
    padding-bottom: 0px;
    font-family: $font-family-roboto-medium;
    font-size: $font-big-20;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: $text-eden;
  }

  .hr-title {
    width: 71px;
    margin: 0;
    border: 0;
    height: 1px;
    background-color: $background-light-grey;
  }
}

.login_box-otp {
  height: 20px;
  margin-top: 8px;
  //margin-bottom: 30px;
  font-family: $font-inter-regular;
  font-size: $font-medium-bigger;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  width: 100%;
  label {
    text-align: right;
  }
  a {
    color: $text-curious-blue;
    text-decoration: none;
  }
}

.hr-input {
  height: 4px;
  margin: 0;
  border: 0;
  border-bottom: 1px solid green;
}

.message-validate-login-google-otp {
  display: flex;
  span {
    display: flex;
    align-items: center;
    font-family: $font-family-roboto;
    font-size: $font-small;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.23;
    letter-spacing: normal;
    text-align: right;
    color: $text-warning;
  }
}

:deep(#login-captcha) {
  max-width: 90%;
  margin-top: 24px;
  margin-bottom: 40px;
  transform: scale(1);
  -webkit-transform: scale(1.33, 1.2);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.form-check-label {
  margin-bottom: 0px;
} //LOGIN GOOGLE AUTHENTICATION CODE

#otp-form {
  width: 510px;
  margin: 0 auto;
  .guest-page-form-body {
    margin: auto;
    width: 100%;
    .form-input {
      width: 100% !important;
    }
    .form_actions {
      width: 100% !important;
      .btn-primary {
        width: 100%;
      }
    }
  }
}

.guest-page-form-body {
  /* width: 400px !important; */
  max-width: 100%;
  .form_actions {
    margin-top: 30px;
  }
}

.icon-google {
  width: 100%;
  margin: 20px auto;
}

.input-group {
  margin-bottom: 24px;
  .icon-last {
    img {
      position: absolute;
      top: 40%;
      right: 15px;
      float: left;
      cursor: pointer;
    }
  }
}
input {
  &:focus,
  &:active {
    border: 1px solid $color-aquamarine !important;
  }
  &.error {
    border: 1px solid $text-color-red !important;
  }
}
label {
  font-weight: 400 !important;
  font-size: $font-smaller;
  line-height: 16px;
  color: var(--text-label) !important;
  letter-spacing: -0.06px;
}
.fo_green_4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .tit_modal_confir {
    font-size: $font-medium-bigger;
    font-family: $font-inter-regular;
    text-align: center;
    color: var(--text-tertiary-color);
  }
  :deep(.label-device) {
    font-size: $font-medium-bigger !important;
    font-family: $font-inter-regular;
    text-align: center;
    color: $color-caribbean-green;
  }
}
.code-qr-app {
  position: absolute;
  top: 23%;
  left: 65%;
  background: $color-white-smoke;
  width: 248px;
  max-height: 309px;
  text-align: center;
  padding: 40px 0;
  border-radius: 8px;

  .text-qr {
    color: $color-grey-dark;
    margin: 26px 0 16px;
    font-family: $font-inter-medium;
    font-size: 18px;
    line-height: 21px;
  }
  .note-qr {
    color: $color-gray-dark;
    font-size: 14px;
    line-height: 18px;
  }
  .box_confirm,
  .box_expired {
    position: absolute;
    text-align: center;
    width: 248px;
    padding: 17px 0;
    opacity: 1;
    z-index: 100;
  }
  .text-confirm {
    font-size: 16px;
    line-height: 19px;
    color: $color-grey-dark;
    margin-top: 19px;
    margin-bottom: 16px;
  }

  .btn-fresh {
    background: $color-gray-alto;
    width: 114px;
    height: 50px;
    opacity: 1;
    font-size: 18px;
    line-height: 21px;
    border-radius: 8px;
    color: $color-grey-dark;
  }
  .opacity {
    opacity: 0.05;
  }
  .box-qr-code {
    div {
      :deep(canvas) {
        width: 140px !important;
        height: 140px !important;
      }
    }
  }
  .time-expired {
    div {
      :deep(canvas) {
        opacity: 0.05;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .left-side {
    width: 100% !important;
  }
}
@media screen and (max-width: 576px) {
  .title-account {
    margin-bottom: 25px !important;
    font-size: $font-big-24;
    line-height: 29px;
  }

  .guest-page-form-body {
    /* width: 315px !important; */

    .input-group {
      margin-bottom: 16px;
      width: 100%;
      input {
        font-size: $font-root !important;
      }
    }

    input {
      &:focus,
      &:active {
        border: 1px solid $color-aquamarine !important;
      }
      &.error {
        border: 1px solid $text-color-red !important;
      }
    }

    #login-captcha {
      transform: scale(1);
      -webkit-transform: scale(1.05, 1);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
    .btn-primary {
      width: 100%;
    }
    .form_actions {
      margin-bottom: 30px;
    }
    :deep(.password-change) {
      font-size: $font-root;
      .mt {
        padding: 12px 0px;
      }
      .label-device {
        font-size: $font-root !important;
      }
    }
    .mart50 {
      width: 100%;
    }
  }

  #otp-form {
    margin: auto;

    :deep(.icon-google) {
      height: 80px;
    }

    .guest-page-form-header-opt {
      margin-bottom: 20px;
    }

    .guest-page-form-body {
      .form-input {
        input {
          font-size: $font-root;
        }
      }
    }
  }

  #confirmationModal {
    :deep(.modal-dialog) {
      width: 343px !important;
      .modal-header {
        padding-top: 10px !important;
      }
      .modal-content {
        width: 343px !important;
        height: 281px;
        .modal-body {
          .fo_green_4 {
            img {
              width: 74px;
              height: 74px !important;
            }
            .title-header-modal {
              margin-top: 23px !important;
              font-size: $font-big-24;
              margin-bottom: 5px;
            }
            .tit_modal_confirm {
              font-size: $font-medium-bigger;
            }
            .modal-content-text {
              font-size: $font-medium-bigger;
              margin: 0 auto 10px;
            }
            .mb-20 {
              margin-bottom: 0px !important;
            }
            .mt-40 {
              margin-top: 20px !important;
            }
            .btn-resetMail {
              width: 160px !important;
              margin-top: 10px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  #login-captcha::v-deep {
    max-width: 100%;
    transform: unset !important;
    div {
      max-width: 100%;
      iframe {
        width: 100%;
      }
    }
  }
}

.box_line {
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--text-or);
  font-size: $font-root;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;

  .line {
    flex: 1;
    height: 1px;
    background-color: var(--bg-line);
  }
}
.mb-8 {
  margin-bottom: 8px;
}
</style>
