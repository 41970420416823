import FuturesIcon from "../assets/icon/Futures_icon.svg";
import IconOverviewDark from "../assets/icon/icon-overview-dark.svg";
import IconSpotDark from "../assets/icon/icon-spot-dark.svg";
import IconTransaction from "../assets/icon/icon_transaction.svg";
import OverviewIcon from "../assets/icon/OverviewIcon.svg";
import SpotIcon from "../assets/icon/Spot_icon.svg";
import OverViewIconDark from "../assets/icon/Overview_icon_dark.svg";

import TransactionHistoryIcon from "../assets/icon/TransactionHistory_icon.svg";

export const left_menu = [
  {
    id: 1,
    nameTab: "wallet.navi_overview",
    icon: "/images/icon-overview.svg",
    iconDark: "/images/icon-overview-dark.svg",
    iconActive: OverviewIcon,
    iconDark: OverViewIconDark,
    url: "/funds/balances-wallet",
  },
  {
    id: 2,
    nameTab: "wallet.navi_spot",
    icon: SpotIcon,
    iconDark: IconSpotDark,
    iconActive: "/images/icon-spot-active.svg",
    url: "/funds/spot-wallet",
  },
  {
    id: 3,
    nameTab: "wallet.navi_futures",
    icon: FuturesIcon,
    iconDark: "/images/icon/icon-futures.svg",
    iconActive: "/images/icon-futures-active.svg",
    url: "/funds/futures-wallet",
  },
  {
    id: 4,
    nameTab: "wallet.navi_transaction",
    icon: TransactionHistoryIcon,
    iconDark: IconTransaction,
    iconActive: "/images/icon-transaction-active.svg",
    url: "/funds/history-wallet",
  },
];
