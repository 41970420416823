<template>
  <div class="sc_search_select" v-click-outside="turnOffSelect">
    <div class="group_search_select clearfix">
      <div
        class="button_drop_search"
        :class="[{ active: isShow }, classFocused]"
        @click="toggleSelect()"
      >
        <slot
          name="label_selected"
          v-if="hasCustomLabel"
          :selected="value"
        ></slot>
        <span v-else v-html="label" />
        <i class="icon-arrow1"></i>
      </div>
      <div class="box_list_search_select active" v-show="isShow">
        <ul class="list_search_select">
          <li v-for="option in avaiableOptions" @click="select(option)">
            <slot v-if="hasCustomLabel" name="options" :option="option"></slot>
            <span v-else class="full_name_coin_select" v-html="option.name" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    hasCustomLabel: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isReturnObject: {
      type: Boolean,
      default: false,
    },
    value: { default: "" },
    classFocused: { type: [String, Object], default: "" },
  },
  data() {
    return {
      avaiableOptions: this.options,
      isShow: false,
    };
  },
  computed: {
    label() {
      const selected = this.options.find((item) => {
        if (window._.isObject(this.value)) {
          return item === this.value;
        }
        return item.id === this.value;
      });
      if (selected) {
        return selected.name;
      }
      return this.placeholder;
    },
  },
  watch: {
    options() {
      this.avaiableOptions = this.options;
    },
  },
  methods: {
    toggleSelect() {
      this.isShow = !this.isShow;
    },
    turnOffSelect() {
      this.isShow = false;
    },
    select(option) {
      if (this.isReturnObject) {
        this.$emit("input", option);
      } else {
        this.$emit("input", option.id);
      }
      this.turnOffSelect();
    },
    includes(str, query) {
      /* istanbul ignore else */
      if (str === undefined) str = "undefined";
      if (str === null) str = "null";
      if (str === false) str = "false";
      const text = str.toString().toLowerCase();
      const value = query.trim().toString().toLowerCase();
      return text.includes(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.sc_search_select {
  .tit_search_select {
    font-size: $font-title-size-big;
    color: $color-white;
    margin-bottom: 20px;
    line-height: 44px;
  }
  .group_search_select {
    position: relative;
    .button_drop_search {
      display: inline-block;
      float: left;
      margin-top: 0px;
      margin-right: 3px;
      border-radius: 3px;
      background-color: transparent;
      height: 25px;
      color: $color-grey;
      font-size: $font-root;
      border: 1px solid transparent;
      padding: 3px 11px;
      line-height: 20px;
      cursor: pointer;
      span {
        display: inline-block;
        float: left;
        color: $color-grey-dusty;
        font-size: $font-root;
        line-height: 18px;
      }
      i.icon-arrow1 {
        display: inline-block;
        float: right;
        color: $color-grey-dusty;
        line-height: 20px;
        font-size: 9px;
        margin-left: 5px;
      }
      &:hover {
        background-color: $color-charade;
        span {
          color: $color-jungle-green;
        }
        i.icon-arrow1 {
          color: $color-jungle-green;
        }
      }
    }
    .button_drop_search.active {
      background-color: $color-charade;
      span {
        color: $color-jungle-green;
      }
      i.icon-arrow1 {
        color: $color-jungle-green;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        top: -2px;
        position: relative;
      }
    }
    .box_list_search_select {
      padding-top: 5px;
      position: absolute;
      display: none;
      width: auto;
      top: 100%;
      left: 0px;
      z-index: 111;
      float: left;
      visibility: hidden;
      opacity: 0;
      ul {
        margin: 0;
      }
    }
    .box_list_search_select.active {
      display: block;
      visibility: visible;
      opacity: 1;
    }
    .box_input_s {
      display: block;
      width: 100%;
      padding: 0px 30px;
      .input_search_select {
        width: 100%;
        border: 1px solid $color-grey-sliver;
        color: $color-grey-dark;
        border-radius: 25px;
        height: 40px;
        outline: -webkit-focus-ring-color auto 0px;
        outline-offset: 0px;
        font-size: $font-small;
        padding: 0px 30px;
      }
    }
    .list_search_select {
      background: $background-default;
      box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
      list-style-type: none;
      overflow-y: auto;
      position: relative;
      padding: 5px 0px;
      &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $color-white;
        border-width: 5px;
        margin-left: -5px;
      }
      li {
        display: block;
        width: 100%;
        line-height: 20px;
        cursor: pointer;
        padding: 3px 14px;
        font-size: $font-root;
        overflow: hidden;
        &:hover {
          background-color: transparent;
          span {
            color: $color-jungle-green;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/sass/variables";
.bold {
  font-weight: 700 !important;
}
.icon_coin_slect {
  display: inline-block;
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 22px;
  img {
    width: 100%;
    height: 100%;
  }
}
.code_coin_slect {
  display: inline-block;
  float: left;
  line-height: 20px;
  font-size: $font-root;
  font-weight: 700;
  color: $color-grey-dark;
  margin-right: 8px;
}
.full_name_coin_select {
  display: block;
  white-space: nowrap;
  float: left;
  line-height: 20px;
  font-size: $font-root;
  color: $color-grey-dark;
}
</style>
