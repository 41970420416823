<template>
  <modal :name="modalName" :enableClose="false" width="750" :hasModalFooter="false">
    <template slot="body">
      <div id="account-authen">
        <div class="wrap-icon-close" @click="closeModal">
          <i class="icon-close"></i>
        </div>

        <div class="box-title title-medium-small">
          <h3>{{ $t("qna.inquiry") }}</h3>
        </div>

        <!-- End title -->
        <div class="authen-container">

          <!-- Begin StepContent -->
          <div class="step-content">
            <!-- end-step-0 -->
            <div>
              <!-- <p class="sub-title-step">{{ $t("account.otp.scan_qrcode") }}</p> -->
              <div class="content-box fourth">
                <div class="verify-input">
                  <div class="login">

                    <div class="input-group">
                      <div class="name_input">
                        {{ $t("qna.inquiry_type") }}
                      </div>
                      <div class="input">
                        <select-box :disabled="!!idDefault" v-model="selectedType" :options="dataType"
                          class="w-full"></select-box>
                      </div>

                    </div>
                    <div class="input-group">
                      <div class="name_input">
                        {{ $t("qna.inquiry_title") }}
                      </div>
                      <input v-model="title" :disabled="!!idDefault" @keyup.enter="verify" @focus="resetError"
                        name="title" type="text" data-vv-validate-on="none" v-validate="'required'" class="input"
                        :class="{ error: errors.has('title') }" :placeholder="$t('common.placeholders.title')" />
                      <div class="invalid-feedback" v-if="errors.has('title')">
                        {{ errors.first("title") }}
                      </div>
                    </div>
                  </div>
                  <div class="authencode">
                    <div class="input-group">
                      <div class="name_input">
                        {{ $t("qna.inquiry_content") }}
                      </div>
                      <textarea type="text" autocomplete="off" v-model="content" @keyup.enter="verify" rows="4"
                        :disabled="!!idDefault" @focus="resetError" name="content" :data-vv-as="$t('content')"
                        data-vv-validate-on="none" v-validate="'required'" class="input textarea"
                        :class="{ error: errors.has('content') }" :placeholder="$t('common.placeholders.content')" />
                      <div class="invalid-feedback" v-if="errors.has('content')">
                        {{ errors.first("content") }}
                      </div>
                    </div>
                  </div>

                  <div class="authencode" v-if="!!idDefault">
                    <div class="input-group">
                      <div class="name_input">
                        {{ $t("qna.inquiry_answer") }}
                      </div>
                      <div class="input input-answer" v-html="answer">

                      </div>
                      <!-- <textarea type="text" autocomplete="off" v-model="answer"
                      :disabled="!!idDefault"
                        @focus="resetError" name="answer" :data-vv-as="$t('answer')" data-vv-validate-on="none"
                        v-validate="'required'" class="input" :class="{ error: errors.has('answer') }"
                         />
                      <div class="invalid-feedback" v-if="errors.has('answer')">
                        {{ errors.first("answer") }}
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="step-button">

              <button id="btnNext" v-if="!idDefault" :disabled="isSubmitting" class="btn-next" @click="createQna">
                {{ $t("m_common.action.confirm") }}
              </button>

            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/shared_components/common/Modal";
import rf from "@/request/RequestFactory";
import SelectBox from '@/components/shared_components/common/SelectBox';


export default {
  data() {
    return {
      isShowing: true,
      step: {
        current: 0,
        max: 3,
      },
      isVerifyOTP: false,
      isVerifyStep1: false,
      title: "",
      content: "",
      answer: "",
      modalName: "EnableCreateQna",
      workflowRunUrl: "",
      bank_status: "init",
      selectedType: null,
      dataType: [],

    };
  },
  components: {
    Modal,
    SelectBox
  },
  props: {
    workflowRunUrlDefault: { type: String, default: null }, // Code Peter update 31/07/2024
    // workflowRunUrlDefault: { type: String, default: "" }, // Code cũ của Life
    firstName: { type: String, default: "" },
    lastName: { type: String, default: "" },
    bankStatus: { type: String, default: "" },
    status: { type: String, default: "" },
    idDefault: {
      type: String | Number,
    },
  },
  watch: {
    selectedType: function (newVal) {

    },
    idDefault: function (newVal) {
      if (newVal) {
        this.getDataInquiry(newVal)
      } else {
        this.title = ''
        this.content = ''
        this.selectedType = null

      }

    },
  },
  methods: {
    resetError() {
      this.errors.clear();
    },

    closeModal() {
      this.isShowing = false;
      this.$emit(`${this.modalName}:CLOSE`);
    },
    async getListType() {
      try {
        const response = await rf.getRequest("UserRequest").getListInquiriesType();

        if (response?.success) {
          this.dataType = response?.data
        }
      } catch (error) {
        this.endSubmit();
      }
    },

    async getDataInquiry(id) {
      console.log('id :>> ', id);
      try {
        const response = await rf.getRequest("UserRequest").detailInquirity(id);
        if (response?.success) {
          this.selectedType = response?.data?.inquiry_type?.id
          this.title = response?.data?.title
          this.content = response?.data?.question
          this.answer = response?.data?.answer

        }
      } catch (error) {
        this.endSubmit();
      }
    },

    async createQna() {
      if (this.isSubmitting) {
        return;
      }
      try {
        this.startSubmit();
        const data = {
          "type_id": this.selectedType,
          "title": this.title,
          "question": this.content
        }
        const response = await rf.getRequest("UserRequest").createInquirity(data);
        if (response?.success) {
          window.CommonModal.hide('EnableCreateQna');
          this.$emit(`${this.modalName}:SUBMIT`);
          this.selectedType = null
          this.title = ''
          this.content = ''
        }
        this.endSubmit();
        Message.success(
          window.i18n.t('qna.create.success'),
          {},
          { position: 'bottom_left' }
        );
      } catch (error) {
        this.endSubmit();
        this.errors.clear();
      }
    },

    async verify() {
      if (this.isSubmitting) {
        return;
      }
      await this.$validator.validateAll();
      if (this.errors.any()) {
        return;
      }
    },

    getEventHandlers() {
      return {
        showCommonModal: this.onModalShowing,
      };
    },
    onModalShowing(modalName) {
      if (modalName == "EnableIdentityVerified") {
        this.isShowing = true;
      }
    },
  },
  created() {
    this.getListType()
    this.$on("showCommonModal", (modalName, userConfigs) => {
      if (modalName === this.modalName) {
        this.step.current = 0;
        this.first_name = "";
        this.last_name = "";
        this.isVerifyStep1 = false;
      }
      if (this.firstName && this.status) {
        this.first_name = this.firstName;
        // this.isVerifyStep1 = true;
      }
      if (this.lastName && this.status) {
        this.last_name = this.lastName;
        // this.isVerifyStep1 = true;
      }
      if (this.workflowRunUrlDefault && this.status !== "rejected") {
        this.step.current = 1;
        this.workflowRunUrl = this.workflowRunUrlDefault;
      }
      if (
        this.bankStatus !== "init" &&
        this.workflowRunUrlDefault === "" &&
        this.status !== "rejected"
      ) {
        this.step.current = 2;
        this.isVerifyStep1 = true;
      }
    });
  },
  mounted() {
    console.log('this.idDefault :>> ', this.idDefault);
    if (this.idDefault) {
      this.getDataInquiry(this.idDefault)
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/common.scss";

.group_search_select {
  display: flex;

  .button_drop_search {
    flex: 1;
    display: flex !important;
    justify-content: space-between;
  }
}

// This line was added by Mr. Peter on August 18th 2024
#EnableIdentityVerified {
  &.modal {
    .modal-dialog {
      height: 80%;
      overflow: auto;

      .modal-content {
        .modal-footer {
          display: none;
        }
      }
    }
  }
}

#EnableGoogleAuthentication {
  //pointer-events: auto;
  overflow: auto;
}

#account-authen {
  width: 100%;

  .wrap-icon-close {
    display: flex;
    justify-content: flex-end;
  }

  .icon-close {
    border-radius: 50%;
    color: var(--color-icon-close);
    position: relative;
    left: 15px;
    top: 30px;
    cursor: pointer;
  }

  .text-grey {
    color: $color-grey-dusty;
    font-size: $font-small;
  }

  .text-gg {
    color: var(--text-primary-color) !important;
    font-weight: 700;
  }

  .text-bold {
    font-family: $font-family-roboto-medium;
  }

  .box-title {
    margin-bottom: 60px;

    h3 {
      font-family: $font-inter-bold;
      color: var(--text-dropdown);
      font-size: 34px;
      line-height: 40px;
      text-align: center;
    }
  }

  .authen-container {
    width: 100%;
    margin: 0 auto;
    background: var(--bgr-dropdown);

    .steps {
      .list_authen {
        display: flex;

        .step {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 12px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 18px;
            width: 50%;
            border-top: 2px dashed var(--bgr-btn);
            transform: translateY(-50%) translateX(-100%);
          }

          &:first-child::before {
            display: none;
          }

          .step_number {
            width: 36px;
            border-radius: 40px;
            color: var(--color-step);
            font-family: $font-inter-regular;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            border: 2px solid var(--color-step);
            display: inline-block;
            padding: 5px 0;
          }

          .step_number_des {
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: var(--color-step);
          }

          &.active {
            .step_number {
              background: var(--text-dropdown);
              border: 2px solid transparent;
              color: var(--bgr-pagination);
            }

            .step_number_des {
              color: var(--text-primary-color);
            }

            &::before {
              border-top: 2px dashed $color-aqua-green;
            }
          }
        }
      }
    }

    .step-content {
      padding-top: 60px;
      margin: auto;

      .sub-title-step {
        font-family: $font-inter-bold;
        color: var(--text-dropdown);
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 30px;
      }

      .kyc-content {
        height: 500px;
        overflow: auto;
        scrollbar-width: none;

        iframe {
          width: 100%;
          height: 700px;
          border: none;
        }
      }

      .content-box {
        width: 100%;
        text-align: center;
        max-width: 600px;
        margin: auto;

        .title-step {
          font-size: $font-medium-bigger;
          font-family: $font-family-roboto-medium;
          margin-bottom: 40px;
          color: $color-black;
        }

        .applink {
          display: flex;
          gap: 30px;
          justify-content: center;
          margin-top: 40px;
        }
      }

      .qr-code {
        margin: 40px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        .img_qr {
          width: 130px;
          height: 130px;
          padding: 10px;
          background: #ffffff;
          box-shadow: 0px 3px 8px rgba(51, 51, 51, 0.102);
          border-radius: 8px;
        }
      }

      .show-key {
        font-size: 22px;
        line-height: 27px;
        font-family: $font-inter-bold;
        color: $dark-1;
      }

      .backup-key {
        font-family: $font-inter-regular;
        color: var(--text-close);
        font-size: 18px;
        line-height: 20px;
      }

      .verify-input {
        .name_input {
          font-family: $font-inter-regular;
          color: var(--text-primary-color);
          font-size: 18px;
          line-height: 22px;
          text-align: left;
          margin-bottom: 8px;
        }

        .input {
          background: var(--bgr-hover-row);
          border-radius: 8px;
          padding: 14px 15px;
          border: none;
          width: 100%;

          font-size: 18px;
          line-height: 21px;
          font-family: $font-inter-regular;
          color: var(--text-primary-color);

          &.input-answer {
            text-align: left;
            max-height: 200px;
            overflow: auto;
          }

          &::placeholder {
            color: var(--text-close);
          }

          &.error {
            border: 1px solid $text-color-red;
          }
        }

        .invalid-feedback {
          color: $text-color-red;
          font-family: $font-inter-regular;
          font-size: 14px;
          line-height: 17px;
          text-align: left;
          margin-top: 8px;
        }
      }
    }

    .step-button {
      margin: 60px auto 0;
      display: flex;
      justify-content: center;
      gap: 40px;
      max-width: 600px;

      button {
        flex: 1;
        min-width: 240px;
        max-width: 440px;

        &.btn-next {
          @include btn-common(yes);
        }

        &.btn-prev {
          @include btn-common(no);
        }

        &:disabled {
          opacity: 0.7;
        }
      }
    }
  }
}

#EnableGoogleAuthentication {
  &.modal .modal-dialog .modal-content {
    .modal-body {
      padding: 30px 50px 50px;
    }

    .modal-footer {
      display: none;
    }
  }
}

.text-note {
  font-family: $font-inter-regular;
  color: var(--text-primary-color);
  font-size: 18px;
  line-height: 22px;
  margin-top: 30px;
  text-align: left;

  p {
    margin: 0;
  }
}

@media screen and (max-width: 1100px) {

  #EnableGoogleAuthentication,
  #EnableIdentityVerified {
    .modal-dialog {
      width: 620px !important;
    }

    &.modal .modal-dialog .modal-content {
      .modal-body {
        padding: 30px 16px;
      }

      #account-authen {
        .wrap-icon-close {
          padding-right: 20px;
        }

        .title-medium-small {
          margin-bottom: 30px;

          h3 {
            font-size: 20px;
            line-height: 24px;
          }
        }

        .authen-container {
          .steps {
            .list_authen {
              .step {
                &::before {}

                .step_number {}

                .step_number_des {
                  &.dp-none {
                    display: none;
                  }
                }

                &.active {
                  .step_number {
                    width: 32px;
                    font-size: 16px;
                    line-height: 19px;
                  }

                  .step_number_des {}

                  &::before {}
                }
              }
            }

            .dp-show {
              margin: 20px 0;
              display: block;

              .step_des {
                font-family: $font-inter-regular;
                font-size: 16px;
                line-height: 19px;
                color: var(--text-primary-color);
                text-align: center;
              }
            }
          }

          .step-content {
            padding: 0;

            .sub-title-step {
              font-size: 18px;
              line-height: 21px;
              margin-bottom: 25px;
            }

            .content-box {
              .title-step {}

              .applink {
                margin-top: 0;
                gap: 15px;
                flex-direction: column;
              }
            }

            .qr-code {
              margin-top: 25px;

              img {
                width: 100px;
                height: 100px;
              }
            }

            .show-key {
              font-size: 16px;
              line-height: 19px;
            }

            .backup-key {
              font-size: 14px;
              line-height: 16px;
            }

            .verify-input {
              .name_input {
                font-size: 14px;
                line-height: 17px;
              }

              .input {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }

          .step-button {
            margin-top: 30px;
            gap: 15px;

            button {
              min-width: unset;
            }
          }
        }
      }
    }
  }
}
</style>
