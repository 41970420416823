import BaseRequest from './base/BaseRequest';
import Utils from '@/common/Utils';


export default class PriceRequest extends BaseRequest {

  getPrices() {
    if(window.GlobalSocket) {
      if (window.GlobalSocket.prices) {
        return new Promise((resolve, reject) => {
          resolve(window.GlobalSocket.prices);
        });
      }
    }

    return this.getPricesNow();
  }

  getPricesNow() {
    let url = '/prices';
    return this.get(url).then(res => {
      window.GlobalSocket.notifyPricesUpdate(res);
      return res
    });
    // if (!this.gettingPrices) {
    //   this.gettingPrices = true;
    //   let url = '/prices';
    //   this.get(url).then(res => {
    //     window.GlobalSocket.notifyPricesUpdate(res);
    //   });
    // }

    // return new Promise((resolve, reject) => {
    //   resolve({ data: {} });
    // });
  }

  getPricesPagination(params) {
    let url = '/prices/pagination';
    return this.get(url, params).then(res => {
      window.GlobalSocket.notifyPricesUpdate(res);
      return res
    });
  }

  getChartBars(params) {
    let url = '/chart/bars';
    return this.get(url, params);
  }

  getPriceScope(currency) {
    let url = '/price-scope';
    return this.get(url, currency);
  }

  getPrices24h(params) {
    let url = '/prices/24h';
    return this.get(url, params);
  }

  getMarketInfo(params) {
    let url = '/market-info';
    return this.get(url, params);
  }

  getAmlSettings(params) {
    let url = '/aml-settings';
    return this.get(url, params);
  }

  getBonus(params) {
    let url = '/get-my-bonus';
    return this.get(url, params);
  }

  getMarketPrices() {
    const url = '/coin-for-sale-point';
    return this.get(url);
  }

  get24hFuture(param) {
    const url = '/ticker/24h'
    return this.getFutures(url, param);
  }

}
