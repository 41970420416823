<template>
  <div
    class="list-transaction"
    :class="{
      active: index === isActiveRow,
      white: (index + 1) % 2 !== 0 || index === 0,
      white: (index + 1) % 2 === 0 && index !== 0,
    }"
  >
    <div class="flex-sb">
      <div class="text-start">
        <div class="text-label">{{ $t("funds.history.status") }}</div>
        <div :class="`status-${item.status}`">
          {{ item.status | transactionStatus }}
        </div>
      </div>

      <div class="text-end">
        <div class="text-label">{{ $t("funds.history.coin") }}</div>
        <div class="img-box">
          <img
            class="icon-coin"
            :src="`${coinImages[item.currency]}`"
            alt=""
            width="28"
            height="28"
          />
          <span class=""> {{ item.currency | uppercase }}</span>
        </div>
      </div>
    </div>

    <div class="flex-sb">
      <div class="text-start">
        <div class="text-label">{{ $t("funds.history.amount") }}</div>
        <div class="text-title">
          {{ item.amount | abs | formatNumberAndDecimal }}
        </div>
      </div>

      <div class="text-end">
        <div class="text-label">{{ $t("funds.history.date") }}</div>
        <div class="text-title">
          {{ item.created_at | timestampToDate }}
        </div>
      </div>
    </div>

    <div class="flex-sb">
      <div class="text-title">Action</div>
      <div class="text-end">
        <span
          @click="activeRow(index)"
          class="glyphicon glyphicon-menu-down"
          :class="isActiveRow === index ? 'active-icon' : ''"
          aria-hidden="true"
        />
      </div>
    </div>

    <div v-if="isActiveRow === index" :class="{ even: index % 2 === 1 }">
      <div class="info">
        <template v-if="item.internal_transaction_id">
          <span
            v-if="configs.type === 'deposit'"
            class="label-text row-transaction-info"
            >{{ $t("funds.history.from") }}</span
          >
          <span
            v-if="configs.type !== 'deposit'"
            class="label-text row-transaction-info"
            >{{ $t("funds.history.to") }}</span
          >: {{ item.internal_transaction_id }}
        </template>
        <template v-if="!item.internal_transaction_id">
          <p>
            <span class="label-text row-transaction-info"
              >{{ $t("funds.history.address") }}:
            </span>
            <span>{{ item.to_address }}</span>
          </p>
          <p v-if="item.currency === 'xrp'">
            <span class="label-text row-transaction-info"
              >{{ $t("funds.history.tag") }}:
            </span>
            <span>{{ item.blockchain_sub_address }}</span>
          </p>
          <p
            class="txid-link"
            v-if="item.status !== 'canceled' && item.status !== 'pending'"
          >
            <span class="label-text row-transaction-info"
              >{{ $t("funds.history.txid") }}:
            </span>
          </p>
          <p
            class="txid-link"
            v-if="item.status !== 'canceled' && item.status !== 'pending'"
          >
            <span>{{ item.transaction_id }}</span>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ListTransactionHistory",
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    coinImages: {
      type: Object,
      default: () => ({}),
    },
    isActiveRow: {
      type: Number,
    },
    activeRow: {
      type: Function,
    },
  },
  data() {
    return {
      activeMenu: false,
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  methods: {
    closeDropdown() {
      this.activeMenu = false;
    },
    handleOptionClick(option) {
      this.closeDropdown();
    },
  },
};
</script>

<style scoped lang="scss">
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.status-success {
  color: $text-color-jade;
}
.status-pending {
  color: $text-color-yellow;
}
.status-submitted {
  color: $color-dark-green;
}
.status-error,
.status-cancel,
.status-rejected {
  color: $text-color-red;
}

.list-transaction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-input-border);
  .img-box {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-text);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    img.icon-coin {
      width: 28px;
      height: 28px;
      border-radius: 28px;
    }
  }

  .text-title {
    color: var(--color-text);
    font-size: $font-root;
    font-weight: 500;
    line-height: 20px;
  }
  .text-label {
    color: $text-placeholder;
    font-size: $font-smaller;
    font-weight: 500;
    line-height: 18px;
  }
  .info {
    text-align: left;
    overflow: initial;
    font-size: $font-smaller;
    color: var(--color-text);
    span {
      display: block;
    }
  }

  &.active {
    max-height: 300px;
    transition-property: height;
    transition-duration: 0.3s, 0.3s;
    background: transparent;
    transition-timing-function: ease, ease-in;
    .glyphicon-menu-down {
      transition-duration: 0.5s;
      transform: rotate(180deg);
    }
  }
  .glyphicon-menu-down {
    color: $text-placeholder;
    font-size: 12px;
  }
  .glyphicon-menu-down.active-icon {
    color: $color-caribbean-green !important;
  }
  &.active {
    .glyphicon-menu-down {
      color: $color-grey-dusty;
    }
  }
}
</style>
