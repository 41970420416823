<template>
  <div class="infinite-scroll" @scroll="handleScroll">
    <ActivityItem
        v-for="(item, index) in items"
        :key="item.id"
        :ip="item.ip_address"
        :loginTime="item.created_at | timestampToDate"
    />
    <div class="loading">Loading...</div>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import ActivityItem from "@/components/setting_pages/ActivityItem.vue";

export default {
  components: {
    ActivityItem
  },
  data() {
    return {
      items: [],
      loading: false,
      currentPage: 1,
      nextPageUrl: null,
    };
  },
  mounted() {
    this.loadMore();
  },
  methods: {
    async loadMore() {
      if (this.loading) return;

      this.loading = true;
      try {
        const params = {
          page: this.currentPage,
        };

        const response = await rf.getRequest('UserRequest').getHistoryConnection(params);
        console.log("response",response)
        if (response && response.data.data) {
          this.items = [...this.items, ...response.data.data];
          this.currentPage = response.data.current_page + 1;
          this.nextPageUrl = response.data.next_page_url;
        }

        if (!this.nextPageUrl) {
          this.noMoreData = true;
        }
      } catch (error) {
        console.error('Lỗi khi tải dữ liệu thiết bị người dùng:', error);
      } finally {
        this.loading = false;
      }
    },
    handleScroll(event) {
      const container = event.target;

      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
        if (!this.loading && !this.noMoreData) {
          this.loadMore();
        }
      }
    },
  },
};
</script>

<style scoped>
.infinite-scroll {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100vh;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.infinite-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari và Edge */
}

.loading {
  padding: 10px;
  text-align: center;
}
</style>
