import { render, staticRenderFns } from "./CoinSelectBox.vue?vue&type=template&id=4dcb2407&scoped=true&"
import script from "./CoinSelectBox.vue?vue&type=script&lang=js&"
export * from "./CoinSelectBox.vue?vue&type=script&lang=js&"
import style0 from "./CoinSelectBox.vue?vue&type=style&index=0&id=4dcb2407&prod&lang=scss&scoped=true&"
import style1 from "./CoinSelectBox.vue?vue&type=style&index=1&id=4dcb2407&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dcb2407",
  null
  
)

export default component.exports