<template>
  <div class="btn-group" v-if="isLoaded && isEntryTime">
    <button :class="{'bgc-entry': !isJoinedEntry, 'bgc-in-entry': isJoinedEntry}" @click="TeamEntry"
            class="btn btn-entry" v-if="isTeamBattle">
      <span v-if="!isJoinedEntry">{{ $t('margin.entry') }}</span>
      <span v-else>{{ $t('margin.entry') }}
        <span class="box_custom_tooltip" v-if="isLeader">
          <span :class="{active: numberOfRequest > 0}">{{ " [" + numberOfRequest + "]" }}</span>
          <div class="tooltip_custom_c">
            {{$t('entry.number_of_request')}}
          </div>
        </span>
      </span>
    </button>
    <button :class="{'bgc-entry': !isJoinedEntry, 'bgc-in-entry': isJoinedEntry}" @click='entry'
            class="btn btn-entry" v-else-if="!isTeamBattle && !isJoinedEntry">{{ $t('margin.entry') }}
    </button>

    <button :class="{'bgc-in-entry': isJoinedEntry}" @click="entry" class="btn btn-entry"
            v-else-if="!isTeamBattle && isJoinedEntry">{{$t('margin.entry') }}
    </button>
    <register-entry v-on:entry-success="isJoinedEntry = true" :isJoinedEntry="isJoinedEntry"></register-entry>


    <div id="TeamEntryPopup">
      <modal name="TeamEntry" width="1000">
        <template slot="body">
        <div class="contest-information">
            <div class="row">
              <div class="col-xs-6">
                <div class="name">{{$t('margin.entry.contest_name')}}:</div>
                <div class="value"> {{contest.name}}</div>
              </div>
              <div class="col-xs-6">
                <div class="name">{{ $t('margin.minimum_amount') }}:</div>
                <div class="value">{{ contest.minimum_margin | formatCurrencyAmount(null, '0') }} BTC</div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6">
                <div class="name">{{$t('margin.entry.entry_time')}}:</div>
                <div class="value">{{contest.start_entry_time | convertToLocalTime}} - {{contest.end_entry_time | convertToLocalTime}}
                </div>
              </div>
              <div class="col-xs-6">
                <div class="name">{{ $t('margin.minimum_volume') }}:</div>
                <div class="value">{{ contest.minimum_volume | formatCurrencyAmount(null, '0') }} BTC</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6">
                <div class="name">{{$t('margin.entry.contest_time')}}:</div>
                <div class="value">{{contest.start_contest_time | convertToLocalTime}} - {{contest.end_contest_time | convertToLocalTime}}
                </div>
              </div>
              <div class="col-xs-6">
                <div class="name">{{ $t('margin.minimum_member') }}:</div>
                <div class="value">{{ contest.minimum_member }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6" v-if="teamId">
                <div class="name">{{$t('margin.entry.team_id')}}:</div>
                <span class="value">{{teamId}}</span>
              </div>
              <div class="col-xs-6">
                <div class="name">{{ $t('margin.entry.maximum_member') }}:</div>
                <div class="value">{{ contest.maximum_member }}</div>
              </div>
            </div>

            <div class="row" v-if="teamName">
              <div class="col-xs-6">
                <div class="name">{{$t('margin.entry.team_name')}}:</div>
                <div class="value">{{teamName}}</div>
              </div>
            </div>

          </div>
          <ul class="table_entry_modal clearfix">
            <li :class="{active: onTabEntry === 'my-team'}" @click="onTabEntry='my-team'" class="item"
                v-if="isJoinedEntry">{{$t('margin.entry.my_team_tab_title')}}
            </li>
            <li :class="{active: onTabEntry === 'list'}" @click="onTabEntry = 'list'" class="item"
                v-if="!isJoinedEntry">{{ $t('margin.entry.team_entry_tab_title') }}
            </li>
            <li :class="{active: onTabEntry === 'team-request'}" @click="onTabEntry = 'team-request'" class="item"
                v-if="isLeader">
              {{ $t('margin.entry.team_request_tab_title') }}
              <span class="box_custom_tooltip" v-if="isLeader">
                <span :class="{active: numberOfRequest > 0}">{{ " [" + numberOfRequest + "]" }}</span>
                <div class="tooltip_custom_c">
                  {{$t('entry.number_of_request')}}
                </div>
              </span>
            </li>
            <li :class="{active: onTabEntry === 'my-request'}" @click="onTabEntry = 'my-request'" class="item"
                v-if="!isJoinedEntry">{{ $t('margin.entry.my_request_tab_title') }}
            </li>
          </ul>

          <my-team-entry-overview :isLeader="isLeader" :onTabEntry="onTabEntry"/>

          <team-entry-list :canEntry="!isJoinedEntry" :contest="contest" :onTabEntry="onTabEntry"
                           v-on:reload-entry="checkShowEntryOnUI"/>

          <my-request-entry-list :onTabEntry="onTabEntry"/>

          <team-request-entry-list :onTabEntry="onTabEntry"/>

        </template>
      </modal>
    </div>
  </div>
  <contest :contest="contest" :isLeader="isLeader" class="btn-group fl-left"
           v-else-if="isLoaded && isJoinedEntry && isContestTime"/>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import RegisterEntry from '@/components/marginexchange_pages/contracts/historical_data/RegisterEntry';
  import Mam from '@/pages/mam/common/mam.js';
  import TeamEntryList from "./Tab/TeamEntryList";
  import TeamRequestEntryList from "./Tab/TeamRequestEntryList";
  import MyRequestEntryList from "./Tab/MyRequestEntryList";
  import Modal from '@/components/shared_components/common/Modal.vue';
  import MyTeamEntryOverview from "./Tab/MyTeamEntryOverview";
  import Contest from './Contest';


  export default {
    components: {
      MyTeamEntryOverview,
      TeamEntryList,
      RegisterEntry,
      Modal,
      MyRequestEntryList,
      TeamRequestEntryList,
      Contest,
    },
    data() {
      return {
        Mam,
        contest: {},
        isEntryTime: false,
        isTeamBattle: false,
        isLeader: false,
        canEntry: false,
        isJoinedEntry: false,
        teamId: false,
        teamName: false,

        onTabEntry: 'list',

        isContestTime: false,
        isShowPendingOnly: false,
        numberOfRequest: 0,
        isLoaded: false,
      }
    },
    methods: {
      getOpeningContestOrLatestContest() {
        this.isLoaded = false;
        rf.getRequest("EntryRequest").getOpeningContestOrLatestContest()
            .then(res => {
              if (res && res.data) {
                this.contest = res.data;
                this.isTeamBattle = res.data.is_team_battle;
                this.isLoaded = true;
              }
            })
      },
      getEventHandlers() {
        return {
          // reloadLeaderboard: this.onReloadLeaderboard,
          EntryNumberOfRequest: this.onEntryNumberOfRequest,
          MarginEntryMyRequestUpdated : this.onMarginEntryMyRequestUpdated,
          MarginEntryUpdated: this.onMarginEntryUpdate,
          MarginContestStatusUpdated: this.onMarginContestStatus,
        };
      },
      onEntryNumberOfRequest(data) {
        this.numberOfRequest = data;
      },
      // onReloadLeaderboard() {
      //   this.getOpeningContestOrLatestContest();
      //   this.checkShowEntryOnUI();
      // },
      onMarginEntryMyRequestUpdated(data) {
        if ((!this.isLeader) && data && data.data && data.data.status == "approve") {
          this.onTabEntry = 'my-team';
          this.isJoinedEntry = true;
        }
      },
      onMarginEntryUpdate(data) {
        if (data.isOutTeam) {
          // if (data.isLeaderOutTeam) 
          // let rs = await this.checkShowEntryOnUI();
          this.checkShowEntryOnUI().then(() => {
            if (!this.isJoinedEntry) {
              window.CommonModal.hide('TeamEntry');
              this.onTabEntry = 'list';
            }
          })
          // this.$nextTick(() =>{
          // })
        }
      },
      onMarginContestStatus(data) {
      if (data && data.step && data.step > 0) {
        if (data.step == 3) {
            this.isJoinedEntry = false;
            this.isEntryTime = false;
            this.isContestTime = false;
        } else {
          if(data.step == 1){
            this.getOpeningContestOrLatestContest();
            this.checkShowEntryOnUI().then(() => {
              if (!this.isJoinedEntry) {
                this.onTabEntry = 'list';
              }
            })
          }else{
            this.checkShowEntryOnUI()
          }
        }
      }
    },
      async checkShowEntryOnUI() {
        return Promise.resolve(rf.getRequest("EntryRequest").showEntryOnUI()
            .then(res => {
              if (res && res.data) {
                  this.isEntryTime = res.data.is_entry_time;
                  this.isContestTime = res.data.is_contest_time;
                  this.isLeader = res.data.is_leader;
                  this.isJoinedEntry = res.data.is_joined_entry;
                  this.teamId = res.data.team_id;
                  this.teamName = res.data.team_name;
                  this.numberOfRequest = res.data.number_of_request;
                  if (res.data.is_joined_entry) {
                    this.onTabEntry = 'my-team';
                    return res;
                  }
              }
            }))
      },
      entry() {
        window.CommonModal.show("RegisterEntry");
      },
      TeamEntry() {
        window.CommonModal.show("TeamEntry");
      },
      showSuccess(message) {
        Message.success(message, {}, {position: 'bottom_left'});
      },
      showError(message) {
        Message.error(message, {}, {position: 'bottom_left'});
      },
    },
    created() {
      if (window.isAuthenticated) {
        this.checkShowEntryOnUI();
        this.getOpeningContestOrLatestContest();
      }

    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  .contest-information {
    background-color: white;
    margin-bottom: 20px;

    .row {
      padding: 5px 10px;

      .name {
        display: inline-block;
        vertical-align: top;
        text-transform: capitalize;
      }

      .value {
        display: inline-block;
        font-weight: bold;
        word-break: break-word;
        padding-left: 15px;
      }
    }
  }

  .minimum-margin-amount {
    float: right;
    margin-left: 10px;
    margin-top: -5px;
    text-transform: capitalize;
  }

  .bgc-entry {
    background-color: $color-red;
  }

  .bgc-in-entry {
    background-color: #299b82;
  }

  .bgc-contest {
    background-color: $color-jungle-green !important;
  }

  .btn-entry {
    display: block;
    float: right;
    min-width: 90px;
    height: 32px;
    margin-left: 10px !important;
    border-radius: 3px !important;
    letter-spacing: 0.9px;
    color: $color-white;
    text-transform: uppercase;
    font-family: $font-family-roboto-bold;
    font-size: $font-smaller;

    &:hover, &:active {
      background-color: $color-blue-custom;
      color: $color-white;
    }
  }

  .box_custom_tooltip {
    position: relative;

    .tooltip_custom_c {
      visibility: hidden;
      display: none;
      width: 350px;
      background-color: #222832;
      color: #cccccc;
      text-align: center;
      padding: 5px 0;
      border-radius: 4px;
      font-size: 13px;
      font-family: $font-family-roboto;
      -webkit-box-shadow: 0px 0px 3px #cccccc;
      box-shadow: 0px 0px 3px #cccccc;
      position: absolute;
      z-index: 50;
      right: 50%;
      top: 130%;
      line-height: 18px;
      white-space: initial;
      text-transform: none;

      &.left {
        left: auto;
        right: 100%;
      }

      .underline {
        text-decoration: underline;
        display: block;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }

    .active {
      color: $color-denim;
    }

    &:hover {
      cursor: help;

      .tooltip_custom_c {
        display: block;
        visibility: visible;
        color: #cccccc;
      }
    }
  }
</style>
<style lang="scss">
  @import "@/assets/sass/variables";

  #TeamEntryPopup {
    .modal-content {
      .disabled-button {
        cursor: not-allowed !important;
        opacity: 0.65;
      }

      background-color: $color-alabaster !important;

      .tab_entry {
        padding-top: 15px;
        background-color: #ffffff;

        .group_search_save {
          padding: 10px;
        }
      }

      .table_entry_modal {
        display: block;
        width: 100%;
        margin: 0 0 0 0;

        .item {
          display: inline-block;
          float: left;
          line-height: 20px;
          padding: 9px 15px 8px 15px;
          font-size: 16px;
          text-transform: capitalize;
          font-family: $font-family-roboto-medium;
          color: $color-grey-dark;
          cursor: pointer;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;

          &.active {
            color: $color-jungle-green;
            background-color: $color-white;
          }
        }
      }

      .title_entry_team_popup {
        font-size: $font-title-size-small-25;
        font-family: $font-family-roboto-medium;
        display: block;
        color: $color-jungle-green;
        margin: 0px;
        margin-bottom: 15px;
        line-height: 29px;
      }

      .save_contracts {
        display: inline-block;
        float: right;
        line-height: 20px;
        padding: 6px 0px;
        color: $color-blue-custom;
        font-size: $font-small;
        cursor: pointer;

        .icon {
          display: inline-block;
          float: right;
          line-height: 20px;
          margin-left: 6px;
        }
      }

      .des_title_contracts {
        font-size: $font-root;
        color: $color-grey-dusty;
        margin-bottom: 12px;
        line-height: 20px;
      }

      .form_search_contracts {
        display: block;
        width: 100%;
        float: left;

        .input-search-contracts {
          display: block;
          float: left;
          width: calc(50% - 190px);
          border-radius: 3px;
          border: solid 1px $color-alto;
          height: 32px;
          line-height: 20px;
          padding: 6px 15px;
          font-size: $font-small;
          color: $color-grey-dark;

          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-grey-sliver;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $color-grey-sliver;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $color-grey-sliver;
          }
        }

        .btn-search-contracts {
          display: block;
          float: left;
          width: 85px;
          height: 32px;
          margin-left: 10px;
          border-radius: 3px;
          background-color: $color-jungle-green;
          letter-spacing: 0.9px;
          color: $color-white;
          text-transform: uppercase;
          font-family: $font-family-roboto-bold;
          font-size: $font-smaller;

          &:hover, &:active {
            background-color: $color-blue-custom;
            color: $color-white;
          }
        }

        .btn-create-team {
          display: block;
          float: right;
          height: 32px;
          min-width: 170px;
          margin-left: 10px;
          border-radius: 3px;
          background-color: $color-jungle-green;
          letter-spacing: 0.9px;
          color: $color-white;
          text-transform: uppercase;
          font-family: $font-family-roboto-bold;
          font-size: $font-smaller;

          &:hover, &:active {
            background-color: $color-blue-custom;
            color: $color-white;
          }
        }

        .btn-clear-search-contracts {
          display: block;
          float: left;
          width: 85px;
          height: 32px;
          margin-left: 10px;
          border-radius: 3px;
          // background-color: $color-jungle-green-light;
          background-color: $color-jungle-green;
          letter-spacing: 0.9px;
          color: $color-white;
          text-transform: uppercase;
          font-family: $font-family-roboto-bold;
          font-size: $font-smaller;

          &:hover, &:active {
            // background-color: $color-dark-green;
            background-color: $color-blue-custom;
            color: $color-white;
          }
        }
      }

      .btn-icon-login {
        color: $color-jungle-green;
        font-size: 20px;
        cursor: pointer;
      }

      .btn-detail {
        min-width: 70px;
        height: 25px;
        line-height: 20px;
        padding: 2px 5px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        letter-spacing: 0.92px;
        text-align: center;
        font-family: $font-family-roboto-bold;
        font-size: $font-smaller;
        color: $color-jungle-green;
        text-transform: uppercase;
        background-color: transparent;
        float: right;
        margin-left: 12px;

        &:hover {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
        }
      }

      .btn-approve {
        min-width: 70px;
        height: 25px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        color: $color-white;
        letter-spacing: 0.9px;
        font-size: $font-smaller;
        line-height: 20px;
        padding: 2px;
        overflow: hidden;
        text-transform: uppercase;
        text-align: center;
        background-color: $color-jungle-green;
        float: right;
        margin-left: 10px;

        &:hover {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
        }
      }

      .btn-reject {
        min-width: 70px;
        height: 25px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        color: $color-jungle-green;
        letter-spacing: 0.9px;
        font-size: $font-smaller;
        line-height: 20px;
        padding: 2px;
        overflow: hidden;
        text-transform: uppercase;
        text-align: center;
        background-color: transparent;
        float: right;
        margin-left: 10px;

        &:hover {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
        }
      }

      table {
        background-color: #ffffff;

        th {
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 700;
          background-color: #ffffff;
        }

        td {
          font-weight: bold;
          text-align: left;
        }
      }

      .box_custom_tooltip {
        position: relative;

        .tooltip_custom_c {
          visibility: hidden;
          display: none;
          width: 350px;
          background-color: #222832;
          color: #cccccc;
          text-align: center;
          padding: 5px 0;
          border-radius: 4px;
          font-size: 13px;
          font-family: $font-family-roboto;
          -webkit-box-shadow: 0px 0px 3px #cccccc;
          box-shadow: 0px 0px 3px #cccccc;
          position: absolute;
          z-index: 50;
          right: 50%;
          top: 130%;
          line-height: 18px;
          white-space: initial;
          text-transform: none;

          &.left {
            left: auto;
            right: 100%;
          }

          .underline {
            text-decoration: underline;
            display: block;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }

        .active {
          color: $color-denim;
        }

        &:hover {
          cursor: help;

          .tooltip_custom_c {
            display: block;
            visibility: visible;
            color: #cccccc;
          }
        }
      }
    }
  }

</style>

