<template>
  <div id="side-bar">
    <ul>
      <li>
        <router-link
          to="/spot-exchange/orders/open"
          class="link-sidebar"
          :class="isOpenOrder ? 'active' : ''"
        >
          <img
            :src="
              require(`@/assets/images/spot_exchange/${
                lightMode ? 'spot-icon' : 'spot-icon-dark-theme'
              }.svg`)
            "
          />
          {{ $t("menu.wallet.spot") }}</router-link
        >
      </li>
      <li>
        <a
          href="https://dev-future.dikex.com/orders"
          class="link-sidebar"
        >
          <img
            :src="
              require(`@/assets/images/spot_exchange/${
                lightMode ? 'futures-icon' : 'futures-icon-dark-theme'
              }.svg`)
            "
          />
          <!-- {{ $t("sidebar.order_history") }} -->
          {{ $t("menu.wallet.futures") }}
      </a>
      </li>
      <!-- <li>
        <router-link
          to="/convert"
          class="link-sidebar"
          :class="isTradeHistory ? 'active' : ''"
        >
          <img
            :src="
              require(`@/assets/images/spot_exchange/${
                lightMode ? 'convert-icon' : 'convert-icon-dark-theme'
              }.svg`)
            "
          /> -->
          <!-- {{ $t("sidebar.trade_history") }} -->
          <!-- {{ $t("menu.convert") }}
        </router-link> -->
      <!-- </li> -->
    </ul>
  </div>
</template>
<script>
import COMMON_CONST from "@/common/Const";
import { mapState } from "vuex";

export default {
  data() {
    return {
      naviList: COMMON_CONST.OPEN_ORDER_SIDEBAR,
      commonConst: COMMON_CONST,
      visible: true,
      visibleFuture: false,
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    lightMode() {
      return this.theme === "light-theme";
    },
    isOpenOrder() {
      return this.$route.path.includes("/spot-exchange/orders");
    },
    isOpenHistory() {
      return this.$route.path.includes("/spot-exchange/orders/history");
    },
    isTradeHistory() {
      return this.$route.path.includes("/spot-exchange/orders/trade-history");
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-theme {
  #side-bar {
    background: $color-grey-black;
    /* border-right: 1px solid var(  --bg-line); */
    ul {
      li {
        &:hover {
          background: #2d2e2f;
        }
      }
      .active {
        background: #2d2e2f;
        color: $color-white !important;
      }
      .link-sidebar {
        color: white;
      }
    }
  }
}

#side-bar {
  width: 258px;
  min-width: 258px;
  font-family: $font-inter-regular;
  background-color: $color_white;
  min-height: calc(100% - 65px - 252px);
  padding: 24px 16px 24px 24px;
  border-right: 1px solid var(--bg-line);

  ul li {
    cursor: pointer;
    margin: 4px 0px;

    &:hover {
      background: #f2f4f7;
      border-radius: 16px;
    }
  }

  .link-sidebar {
    line-height: 24px;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    font-family: $font-notosans-medium;
    color: var(--text-primary-color);

    img {
      width: 20px;
      height: 20px;
    }
  }

  .active {
    /* border-right: 6px solid $color-caribbean-green; */
    background: #f2f4f7;
    border-radius: 16px;
  }
  .btn-default-order {
    background: none;
    font-size: 20px;
    line-height: 24px;
    padding: 18px 21px 18px 50px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    p {
      margin: 0;
      display: flex;
      align-items: center;
    }
  }
  .dropdown-active {
    margin-right: 18px;
  }
  .icon-arrow1 {
    font-size: 12px;
  }
  .icon-arrow2 {
    font-size: 12px;
  }
  .icon-button {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    min-width: unset;

    .link-sidebar {
      font-size: 12px;
      padding: 12px;
    }
  }
}
</style>
