<template>
  <withdraw-page ref="withdraw_page" :configs="configs">
    <template slot="breadcumb"> <Breadcrumb :items="items" /></template>
    <template slot="title">
      {{ $t("funds.withdrawals.title_page") }}
    </template>
    <template slot="actions">
      <BorderGradient>
        <div
          class="withdraw-content"
          style="display: flex; flex-direction: column"
        >
          <div class="form-notice-danger">
            <ul class="first-ul">
              <li>
                {{ $t("funds.withdrawals.important.text2") }}:
                {{ minimumWithdrawFee | formatCurrencyAmount(coinName, "0") }}
                {{ coinName | uppercase }}
              </li>
              <li>{{ $t("funds.withdrawals.important.text3") }}</li>
            </ul>
          </div>
          <div class="form-group form-address-box">
            <label>
              {{
                $t("funds.withdrawals.withdrawal_address", {
                  coin: coinName && coinName.toUpperCase(),
                })
              }}</label
            >
            <input-block-chain-address
              ref="blockchain_address"
              v-model="withdrawAddress"
              :coin="coinName"
              :networkId="networkId"
              :withdrawAddresses="withdrawAddresses"
              :enableWhiteList="enableWhiteList"
              @onIsNoMemo="onIsNoMemo"
              @onAddNewAddress="onAddNewAddress"
              @focus="resetErrors"
            />
          </div>
          <div class="clearfix"></div>

          <div class="form-group amount">
            <input-amount
              ref="amount"
              v-model="amount"
              :coin="coinName"
              :network="networkName"
              :withdrawLimit="withdrawLimit"
              :balance="balance"
              :withdrawDaily="withdrawDaily"
              @focus="resetErrors"
            />
          </div>
          <div>
            <div class="withdrawal_limit_24">
              <span>{{ $t("funds.withdrawals.withdrawal_limit_24") }}</span>
              <span class="txt-bold">
                {{ " " }}
                <template v-if="withdrawDaily.toString().includes('-')">{{
                  getNumber(withdrawDaily)
                }}</template>
                <template v-else>{{
                  withdrawDaily | formatCurrencyAmount(coinName, "0.000")
                }}</template>
                /{{
                  withdrawLimit.daily_limit
                    | formatCurrencyAmount(coinName, "0.000")
                }}
                {{ coinName | uppercase }}
              </span>
            </div>
          </div>
          <p class="form-group quantity">
            <span class="transaction_fee">
              {{ $t("funds.withdrawals.transaction_fee") }}:
              <span class="txt-bold">{{
                withdrawLimit.fee | formatCurrencyAmount(coinName, "0.000")
              }}</span>
            </span>
            <span class="you_will_get">
              {{ $t("funds.withdrawals.you_will_get") }}:
              <span class="txt-bold">
                {{
                  Number(amount) <= 0 ? "0.00" : Number(amount).toLocaleString()
                }}</span
              >
            </span>
          </p>
          <div class="form-group wrapperBtn">
            <template v-if="isSubmitting">
              <!-- <div class="container-btn"> -->
              <button type="submit" class="form-control btn btn-submit">
                {{ $t("funds.withdrawals.loading") }}
              </button>
              <!-- </div> -->
            </template>
            <template v-else>
              <!-- <div class="container-btn"> -->
              <button
                type="submit"
                class="form-control btn btn-submit"
                @click="withdraw()"
              >
                {{ $t("funds.withdrawals.submit") }}
              </button>
              <!-- </div> -->
            </template>
          </div>

          <div class="form-notice note">
            <p class="txt-medium">{{ $t("funds.withdrawals.tips.text1") }}</p>
            <ul class="second-ul">
              <li v-html="$t('funds.withdrawals.tips.text2')"></li>
              <li v-html="$t('funds.withdrawals.tips.text3')"></li>
            </ul>
          </div></div
      ></BorderGradient>

      <otp-modal
        class="otp-form"
        ref="otp_modal"
        :submit="createTransaction"
        :params="getParams()"
        :is-disable-button="isDisableButton"
        :contact_email="siteSetting.contact_email"
      />
    </template>
  </withdraw-page>
</template>

<script>
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import BaseDepositWithdraw from "./BaseDepositWithdraw";
import InputAmount from "@/components/shared_components/funds/common/InputAmount";
import InputBlockChainAddress from "@/components/shared_components/funds/withdraw/InputBlockChainAddress";
import OtpModal from "@/components/shared_components/funds/common/OtpModal";
import { mapState } from "vuex";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BorderGradient from "@/components/BorderGradient.vue";

export default {
  components: {
    OtpModal,
    InputAmount,
    InputBlockChainAddress,
    WithdrawPage: BaseDepositWithdraw,
    Breadcrumb,
    BorderGradient,
  },
  data() {
    return {
      configs: {
        type: "withdraw",
        is_show_title: true,
        msgEmptyData: window.i18n.t("funds.withdrawals.no_data"),
      },
      amount: "",
      balance: {},
      selectedCoin: {},
      selectedNetwork: {},
      withdrawLimit: {},
      withdrawLimitNetwork: {},
      withdrawDaily: "0",
      withdrawAddress: {},
      securitySetting: {},
      withdrawAddresses: [],
      isSubmitting: false,
      enableWhiteList: false,
      isDisableButton: false,
      siteSetting: {},
      isNoMemo: false,
      isAddNewAddress: false,
      minimumWithdrawFee: 0,
      items: [
        {
          label: "Wallet",
          link: "/funds/balances-wallet",
        },
        {
          label: "Overview",
          link: "/funds/balances-wallet",
        },
        {
          label: "Withdrawal Crypto",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    coinName() {
      if (_.isEmpty(this.selectedCoin.coinName)) {
        return "";
      }
      return this.selectedCoin.coinName;
    },
    networkName() {
      if (_.isEmpty(this.selectedNetwork.name)) {
        return "";
      }
      return this.selectedNetwork.name;
    },
    networkId() {
      return this.selectedNetwork?.id;
    },
  },
  methods: {
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onIsNoMemo(isNoMemo) {
      this.isNoMemo = isNoMemo;
    },
    onAddNewAddress(isAddNewAddress) {
      this.isAddNewAddress = isAddNewAddress;
    },
    getSecuritySettings() {
      rf.getRequest("UserRequest")
        .getSecuritySettings()
        .then((res) => {
          this.securitySetting = res.data;
        });
    },

    getSocketEventHandlers() {
      return {
        OtpUpdated: this.onOtpUpdated,
      };
    },

    async validate() {
      this.errors.clear();
      if (
        new BigNumber(`${this.withdrawLimit.daily_limit || 0}`).comparedTo(
          0
        ) === 0
      ) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.not_allowed_withdraw")
        );
      }
      this.$refs.amount.validate();
      const res = await this.$refs.blockchain_address.validate();
      if (!res) return false;

      if (this.errors.count() > 0) {
        return false;
      }
      return true;
    },

    async withdraw() {
      await this.getSettingWithdrawLimit(false);
      if (!(await this.validate())) {
        return false;
      }
      if (!this.securitySetting.otp_verified) {
        return Message.error(this.$t("funds.withdrawals.errors.kyc"));
      }
      CommonModal.show("OtpModal");
    },

    onError(errors) {
      this.errors.clear();
      _.forEach(errors, (messages, field) => {
        _.forEach(messages, (message) => {
          // this.errors.add(field, message))
          this.errors.add(field, this.$t(`${message}`));
        });
      });
    },

    getParams() {
      let params = {
        currency: this.coinName,
        network_id: this.networkId,
        amount: `-${this.amount || "0"}`,
        blockchain_address: this.withdrawAddress.blockchain_address,
        is_no_memo: this.isNoMemo,
        is_new_address: this.isAddNewAddress,
        fee: this.withdrawLimit.fee || "0",
        daily_limit: this.withdrawLimit?.daily_limit || "0",
        minium_withdrawal: this.withdrawLimit?.minium_withdrawal || "0",
        limit: this.withdrawLimit?.limit || "0",
        // ...this.withdrawLimitNetwork
      };
      if (!_.isEmpty(this.withdrawAddress.wallet_name)) {
        params.wallet_name = this.withdrawAddress.wallet_name;
      }
      if (
        this.coinName === "xrp" ||
        this.coinName === "eos" ||
        this.coinName === "trx"
      ) {
        params.blockchain_sub_address =
          this.withdrawAddress.blockchain_sub_address;
      }
      return params;
    },

    createTransaction(params) {
      this.isDisableButton = true;
      this.isSubmitting = true;
      rf.getRequest("TransactionRequest")
        .withdraw(params)
        .then((res) => {
          this.onCreateTransaction(res.data);
          this.$broadcast("TransactionCreated");
          this.isDisableButton = false;
        })
        .catch((error) => {
          this.isDisableButton = false;
          this.isSubmitting = false;

          if (
            error.response.data.message.includes(
              "Please enable Google Authentication"
            )
          ) {
            return Message.error(
              window.i18n.t("funds.withdrawals.errors.google")
            );
          }

          if (error.response.status === 422) {
            this.onError(error.response.data.errors || []);
            if (
              Object.keys(error.response.data.errors).length != 1 ||
              !error.response.data.errors.otp
            ) {
              CommonModal.hide("OtpModal");
            }
          } else {
            Message.error(
              window.i18n.t(
                `funds.withdrawals.error_message.${error.response.data.message}`
              )
            );
          }
        });
    },

    onCreateTransaction(data) {
      this.isSubmitting = false;
      this.withdrawAddress = {};
      this.amount = "";
      this.getWithdrawalAddresses();
      this.getWithdrawDaily(this.coinName);
      CommonModal.hide("OtpModal");
      Message.success(window.i18n.t("funds.withdrawals.success_message"));
    },

    getWithdrawalAddresses(network_id) {
      let params = {
        currency: this.coinName,
        network_id: network_id,
      };
      rf.getRequest("UserRequest")
        .getWithdrawalAddresses(params)
        .then((res) => {
          this.withdrawAddresses = res.data;
        });
    },

    getWithdrawDaily(coin) {
      rf.getRequest("UserRequest")
        .getWithdrawDaily(coin)
        .then((res) => {
          this.withdrawDaily = `${res.data || 0}`;
        });
    },

    init() {
      this.errors.clear();
      // this.getWithdrawalAddresses();
      this.getSecuritySettings();
      this.getSettingWithdrawLimit();
      this.getSettingEnableWhiteList();
      this.handleMasterData();
    },

    getSettingEnableWhiteList() {
      rf.getRequest("UserRequest")
        .getUserSettings()
        .then((res) => {
          const row =
            _.find(res.data, (row) => {
              return row.key === "whitelist";
            }) || {};
          this.enableWhiteList = !!parseInt(row.value);
        });
    },

    getSettingWithdrawLimit(useCache = true) {
      rf.getRequest("UserRequest")
        .getCurrentUser(useCache)
        .then((res) => {
          const securityLevel = res.data.security_level;
          let withdrawLimit = {};

          withdrawLimit =
            window._.find(this.masterdata.withdrawal_limits, (wl) => {
              return (
                wl.security_level === securityLevel &&
                wl.currency === this.coinName
              );
            }) || {};
          this.withdrawLimit = withdrawLimit;
          // this.minimumWithdrawFee = new BigNumber(
          //   `${this.withdrawLimit.minium_withdrawal || 0}`
          // ).plus(`${this.withdrawLimit.fee || 0}`);
        });
    },

    onOtpUpdated() {
      this.getSecuritySettings();
      this.getSettingWithdrawLimit(false);
    },

    handleMasterData() {
      window._.each(this.masterdata.settings, (item) => {
        this.siteSetting[item.key] = item.value;
      });
    },
    getNumber(number) {
      const num = number.toString().split("e-")[1];
      return num ? Number(number).toFixed(num) : number;
    },
  },

  mounted() {
    this.scrollToTop();
    this.$refs.withdraw_page.$on("CHANGE_SELECTED_COIN", (selectedCoin) => {
      this.selectedCoin = selectedCoin;
      this.$router.replace({ query: { coin: this.selectedCoin.coinName } });
      this.init();
    });
    this.$refs.withdraw_page.$on(
      "CHANGE_SELECTED_NETWORK",
      (selectedNetwork) => {
        this.selectedNetwork = selectedNetwork;

        // const withdrawLimitNew = {
        //   fee : selectedNetwork.fee,
        //   minium_withdrawal : selectedNetwork.minium_withdrawal,
        // }
        this.minimumWithdrawFee = selectedNetwork.minium_withdrawal;
        this.withdrawLimit.fee = selectedNetwork.fee;
        this.withdrawLimit.minium_withdrawal =
          selectedNetwork.minium_withdrawal;
        // this.withdrawLimitNetwork = withdrawLimitNew
        if (selectedNetwork.id) this.getWithdrawalAddresses(selectedNetwork.id);
      }
    );
    this.$refs.withdraw_page.$on("BALANCE_UPDATED", (balance) => {
      this.balance = balance;
      this.getWithdrawDaily(this.coinName);
    });
    document.title = this.$t("menu.withdrawals") + ` – ${this.tabTitle}`;

    this.$on("UPDATED_LOCALE", () => {
      this.configs.msgEmptyData = window.i18n.t("funds.withdrawals.no_data");
    });
  },
};
</script>
<style lang="scss">
.otp-form {
  .modal-footer {
    padding: 0px !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.form-address-box {
  margin-bottom: 0 !important;
  margin-top: 16px;
  label {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px !important;
    color: $color-grey-dark;
    font-family: $font-notosans-medium;
  }
}

.amount {
  margin-top: 16px !important;
  label {
    font-size: 14px !important;
    font-family: $font-notosans-medium;
  }
}

.f-left {
  float: left;
  color: var(--color-text-title);
  font-family: $font-inter-regular;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 6px;
  margin-right: 4px;
  .txt-bold {
    font-weight: 500;
    color: var(--text-primary-color);
    font-size: 14px;
  }
}

.form-group.quantity {
  display: flex;
  justify-content: space-between;
  .transaction_fee,
  .you_will_get {
    color: var(--color-text-title);
    font-size: 14px;
    line-height: 17px;
  }
}

.withdrawal_limit_24 {
  text-align: right;
  gap: 10px;
  color: var(--color-text-title);
  font-family: $font-inter-regular;
  font-size: 12px;
  line-height: 17px;
  margin-top: 6px;
  .txt-bold {
    font-family: $font-inter-regular;
    color: var(--text-primary-color);
    font-size: 12px;
    margin: 0;
  }
}

.withdraw-content {
  padding: 24px;

  .form-important {
    height: 80px;
    width: 100%;
    color: $color-red;
    padding: 15px 12px;
    margin-bottom: 12px;
    ul {
      padding-left: 25px;
      list-style: none;
      li {
        font-size: $font-smaller;
      }
    }
  }
  .form-group {
    margin-bottom: 0px;
    label {
      font-weight: 400;
      font-size: 18px;
      color: var(--text-primary-color);
      margin-bottom: 8px;
    }
  }
}

.txt-bold {
  color: var(--dark-1);
  font-family: $font-inter-bold;
  line-height: 21px;
  margin-bottom: 5px;
  font-weight: 400;
}

.dark-theme {
  .form-group {
    .form-control {
      &.btn-submit {
        background-color: white !important;
        color: #17181a !important;
      }
    }
  }
}
.form-group {
  margin-top: 16px;
  .form-control {
    width: 255px;
    background: $color-jungle-green;
    font-size: $font-big;
    color: $color-white;
    font-weight: 500;
    height: 40px;
    border-radius: 3px;
    margin-bottom: 30px;
    outline: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &:hover,
    &:active,
    &:focus {
      background: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
    &.btn-submit {
      width: 100%;
      color: #fff;
      border: none;
      height: 44px;
      border-radius: 63px;
      margin-top: 16px;
      background: #17181a;

      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 4px;
  }
}

.form-notice {
  padding-top: 20px;
  height: auto;
  .txt-medium {
    color: var(--color-text-title);
    font-size: 14px;
    line-height: 17px;
  }
  .first-ul {
    list-style: disc;
    color: $color-alizarin-crimson;
    li {
      font-size: $font-smaller;
    }
  }
  .second-ul {
    li {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: var(--text-primary-color);
      margin: 0;
      margin-bottom: 15px;
      &.txt-bold {
        margin-bottom: 15px;
      }
      // &::before {
      //   content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      //   font-weight: bold; /* If you want it to be bold */
      //   display: inline-block; /* Needed to add space between the bullet and the text */
      //   width: 1em; /* Also needed for space (tweak if needed) */
      //   margin-left: -1em; /* Also needed for space (tweak if needed) */
      // }
    }
  }
}
.form-notice-danger {
  height: auto;
  .first-ul {
    list-style: disc;
    color: $text-color-red;
    padding: 0 0 0 20px;
    li {
      font-size: 14px;

      &:nth-child(2) {
        margin-top: 16px;
      }
    }
  }
  .second-ul {
    li {
      font-size: $font-smaller !important;
      line-height: 1.67;
      color: $color-grey-dark;
      margin: 0;
      list-style: none;
      &.txt-bold {
        margin-bottom: 15px;
      }
      &::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .withdrawal_limit_24 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }

  .you_will_get {
    display: block;
  }

  .form-group.quantity {
    flex-direction: row;
    margin-top: 16px;
  }
  .form-notice-danger {
    padding: 20px 16px 16px 10px;

    .first-ul {
      li {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .withdraw-content {
    .form-group {
      label {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
@media screen and (max-width: 870px) {
  .withdrawal_limit_24 {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }
  .withdraw-content {
    .amount {
    }
    .quantity {
      padding-top: 0px;
      margin-top: -4px;
    }
    .wrapperBtn {
      padding-top: 0px;
      button {
        margin-top: 0px;
        margin-bottom: 4px;
      }
    }
  }
  .form-group.quantity {
    margin-top: 3px;
  }
}
</style>
