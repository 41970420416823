<template>
  <div id="trades_table">
     
    <div class="table-container table-trades">
      <table ref="datatable">
        <tbody>
          <tr v-for="trade in trades">
            <td class=""><span class="price_table" :class="{'up': trade.trade_type == 'buy', 'down': trade.trade_type == 'sell'}">{{ trade.price | formatMarginNumber(10) }}</span></td>
            <td class="text-center">{{ trade.quantity | formatMarginNumber(0) }}</td>
            <td class="text-right">{{ trade.created_at | orderTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  export default {
    components: {

    },
    data() {
      return {
        trades: [],
      }
    },
    props: {

    },
    watch: {
      symbol(newValue) {
        this.onSymbolChanged();
      },
    },
    computed: {
      ...mapGetters({
        symbol: 'currentSymbol',
      }),
    },
    methods: {
      getRecentTrades () {
        let params = {
          symbol: this.symbol,
        };
        rf.getRequest('MarginRequest').getRecentTrades(params).then(data => {
          this.trades = data.data;
        });
      },
      onSymbolChanged() {
        this.trades = [];
        this.getRecentTrades();
        window.GlobalSocket.listenForMarginTrade(this.symbol);
      },
      getSocketEventHandlers() {
        return {
          MarginTradesCreated: this.onMarginTradesCreated,
        };
      },
      onMarginTradesCreated(data) {
        if (data.length === 0 || this.symbol !== data[0].instrument_symbol) {
          return;
        }
        this.trades = data.reverse().concat(this.trades).slice(0, 50);
      },
    },
    mounted() {
      this.onSymbolChanged();
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .fl-right {
    float: right;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  #trades_table {
    .table-trades {
      tr {
        vertical-align: middle;
        height: 25px;
        text-align: left;
        td {
          line-height: 20px;
          padding: 2px 2px 2px 12px;
          font-size: 12px;
          font-family: $font-family-roboto-medium;
          width: 33.33%;
          &:nth-child(1) {
            width: 35%;
          }
          &:nth-child(2) {
            padding: 2px 2px 2px 2px;
            width: 35%;
          }
          &:nth-child(3) {
            width: 30%;
          }
          span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.price_table {
              max-width: 130px;
            }
          }
          &:last-child {
            padding: 2px 12px 2px 2px;
          }
          .up {
            color: $color-jungle-green;
          }
          .down {
            color: $color-denim;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  @import "@/assets/sass/variables";
  #trades_table {
    .table-trades {
      table {
        width: 100%;
        color: $color-gray-dark;
      }
      tr {
        &.empty-data {
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
</style>