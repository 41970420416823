<template>
  <div class="login-info">
    <div class="info-item">
      <span class="label">Login time</span>
      <span class="value">{{loginTime}}</span>
    </div>
    <div class="info-item">
      <span class="label">IP</span>
      <span class="value">{{ip}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginInfo",
  props:{
    ip: {
      type: String,
      required: true,
    },
    loginTime: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.login-info {
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: Arial, sans-serif;

  .info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: #8E8E92;
    }

    .value {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color: var(--text-primary-color);
    }
  }
}
</style>
