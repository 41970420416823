<template>
  <div class="wrapper">
    <!--Menu Items -->
    <div class="menu_button" @click="menuActive = !menuActive">
      <img
        :src="
          require(`@/assets/images/spot_exchange/Menu_list_icon${
            lightMode ? '_light' : '_dark'
          }.png`)
        "
        width="24px"
        height="24px"
      />
    </div>

    <!-- Breadcrumb -->
    <b-breadcrumb :items="items"></b-breadcrumb>

    <!-- Tabs -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link
          to="/spot-exchange/orders/open"
          class="nav-link"
          :class="{ active: isActive('/spot-exchange/orders/open') }"
        >
          {{ $t("menu.open_orders") }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/spot-exchange/orders/history"
          class="nav-link"
          :class="{ active: isActive('/spot-exchange/orders/history') }"
        >
          {{ $t("menu.order_history") }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/spot-exchange/orders/trade-history"
          class="nav-link"
          :class="{ active: isActive('/spot-exchange/orders/trade-history') }"
        >
          {{ $t("menu.trade_history") }}
        </router-link>
      </li>
    </ul>
    <div class="title-wrapper">
      <h1 :class="['title', lightMode ? 'title-light' : 'title-dark']">
        {{ title }}
      </h1>
      <p
        :class="[
          'title-label',
          lightMode ? 'title-label-light' : 'title-label-dark',
        ]"
      >
        {{ label }}
      </p>
    </div>
    <!-- Router View to display the selected tab content -->
    <router-view />

    <div v-show="menuActive" class="menu">
      <div class="menu_overlay" @click="menuActive = !menuActive"></div>
      <div class="menu_item">
        <sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "./Sidebar.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  components: {
    Sidebar,
  },
  data() {
    return {
      menuActive: false,
      items: [
        {
          text: "Order",
          href: "#",
        },
        {
          text: "Spot",
          active: true,
        },
      ],
    };
  },
  methods: {
    isActive(path) {
      return this.$route.path === path;
    },
  },

  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    lightMode() {
      return this.theme === "light-theme";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding-left: 37px;

  @media screen and (max-width: 1024px) {
    padding-left: 20px;
  }
}

.title {
  > h1 {
    font-size: 30px;

  }
  &-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &-label {
    border-radius: 20px;
    color: #6c6c6e;
    padding: 6px 12px;
    &-light {
      color: #6c6c6e;
      border: 1px solid #e6e6e6;
    }
    &-dark {
      color: #8e8e92;
      border: 1px solid #2d2e2f;
    }
  }

  &-light {
    color: #17181a;
    font-family: $font-notosans-bold  !important;

  }
  &-dark {
    color: #ffffff;
    font-family: $font-notosans-bold  !important;

  }
}

/* Breadcrumb styling */
.breadcrumb .breadcrumb-item a {
  color: #57585c;
  font-weight: 700;
}

.breadcrumb .breadcrumb-item.active {
  color: #17181a;
  font-weight: 700;
}

/* Dark theme for breadcrumb */
.dark-theme .breadcrumb .breadcrumb-item a {
  color: #dddddd;
  font-weight: 700;
}

.dark-theme .breadcrumb .breadcrumb-item.active {
  color: #ffffff;
  font-weight: 700;
}

/* Styling for  tab */

.nav-tabs {
  margin-top: 24px;
}

.nav-tabs .nav-link.active {
  color: #03aac7 !important;
  /* border: none; */
  border-bottom: 2px solid #03aac7 !important;
}

.nav-tabs .nav-link {
  color: #8e8e92 !important;
}

.nav-tabs {
  > li:first-of-type > .nav-link {
    padding-left: 0;
  }

  border-bottom: 1px solid #e6e6e6;
}

.dark-theme .nav-tabs {
  border-bottom: 1px solid #2d2e2f;
}

/* Menu button */

.menu_button {
  display: none;

  @media screen and (max-width: 1023px) {
    display: block;
    margin-bottom: 16px;
  }
}

.menu {
  &_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00000033;
    z-index: 10;
  }

  &_item {
    width: 70%;
    position: fixed;
    background-color: white;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.dark-theme {
  .menu_item {
    background-color: $color-grey-black;
  }
}
</style>
