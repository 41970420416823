<template>
  <div id="content-wrap" class="content-wrapper">
    <section class="content clearfix">
      <transition name="page" mode="out-in">
        <router-view/>
      </transition>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
     
    };
  },

  methods: {

  }
};
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  #content-wrap {
    padding: 45px 40px 80px 40px;
  }
</style>