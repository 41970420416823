<template>
  <div class="chart-container" id="exchange-chart">
    <fullscreen @change="fullscreenChange" ref="fullscreen">
      <div class="chart-option position-relative">
        <div class="time-option position-relative" v-show="chartActive == 1">
          <div style="margin-right: 12px;">Time</div>
          <template v-if="multipleChart">
            <div class="select-box-mul-chart">
              <select-box class="time"
                v-model="selectedPair"
                :placeholder="$t('common.no_select')"
                :options="pairs" isReturnObject/>
            </div>

            <div class="select-box-mul-chart">
              <select-box class="time" id="search_select_dt"
                v-model="selectedTime"
                :placeholder="$t('exchange.basic.label_minute')"
                :options="times()"/>
            </div>
          </template>
          <template v-else>
            <!-- <div>
              <select-box class="time"
                :class="selectedMinute !==null ? 'active' : ''"
                v-model="selectedMinute"
                :placeholder="$t('exchange.basic.label_minute')"
                :options="minutes()"
                :classFocused="{'active-time': (timeActive === 1)}"
                :is-icon-drop-down2="true"
              />
            </div>
            <div>
              <select-box class="time"
                v-model="selectedHour"
                :placeholder="$t('exchange.basic.label_hour')"
                :options="hours()"
                :classFocused="{'active-time': (timeActive === 2)}"
                :is-icon-drop-down2="true"
              />
            </div> -->
            <div class="d-xxl-none d-flex">
              <select-box class="time"
                v-model="selectedTime"
                :options="optionTime()"
                :classFocused="'active-time'"
                :is-icon-drop-down2="true"
              />
            </div> 

            <div class="d-none d-xxl-flex">
              <div class="time div-time time-filter" :class="{'active-time':(timeActive == 1)}" @click="onChangeResolution('1m')">1m</div>
              <div class="time div-time time-filter" :class="{'active-time':(timeActive == 2)}" @click="onChangeResolution('5m')">5m</div>
              <div class="time div-time time-filter" :class="{'active-time':(timeActive == 3)}" @click="onChangeResolution('15m')">15m</div>
              <div class="time div-time time-filter" :class="{'active-time':(timeActive == 4)}" @click="onChangeResolution('1h')">1H</div>
              <div class="time div-time time-filter" :class="{'active-time':(timeActive == 5)}" @click="onChangeResolution('4h')">4H</div>
              <div class="time div-time time-filter" :class="{'active-time':(timeActive == 6)}" @click="onChangeResolution('D')">1D</div>
            </div>
           
            <!-- <div class="time div-time time-filter" :class="{'active-time':(timeActive == 4)}" @click="onChangeResolution('W')">1W</div> -->
            <!-- API load data for chart does't support -->
            <!-- <div class="time div-time time-filter" :class="{'active-time':(timeActive == 5)}" @click="onChangeResolution('M')">1M</div> -->
          </template>

     

          <div class="d-none d-xxl-flex">
            <div class="line"/>
          </div>
          <div class="d-xxl-flex mt-0 setting-minimize">
            <div class="time div-time button-icon" v-on:click.stop="showIndicatorDialog()">
              <!-- <span class="icon-chart1"></span> -->
              <img 
                :src="require(`@/assets/images/icon/icon-fx.svg`)"
              />
            </div>
            <div class="time div-time button-icon" v-on:click.stop="showChartPropertiesDialog()">
              <!-- <span class="icon-setting"></span> -->
              <img 
                :src="require(`@/assets/images/icon/icon-setting.svg`)"
              />
            </div>
            <div class="time div-time button-icon group_favered" :class="{ 'active':(showFavoredList == true)}" @click="onshowFavoredList()">
              <div class="btn btn-favored">

              <span class="icon" :class="{
                'icon-heikin-ashi2'   :activeFavoredItem === 2,
                'icon-bar'            :activeFavoredItem === 1,
                'icon-heikin-ashi3'   :activeFavoredItem === 3,
                'icon-heikin-ashi'    :activeFavoredItem === 4,
                'icon-line2'          :activeFavoredItem === 5,
                'icon-line3'          :activeFavoredItem === 6,
              }"></span>
                <i class="icon-arrow1 icon_drop"></i>
              </div>
              <div class="box_favored_list clearfix" v-show="showFavoredList == true">
                <ul class="favored_lit">
                  <li class="item" @click="activeFavoredItem = 1, showSetBarStyle(0)">
                    <i class="icon icon-bar"></i>
                    <span class="name">{{ $t('barstyle_chart.bars') }}</span>
                  </li>
                  <li class="item" @click="activeFavoredItem = 2, showSetBarStyle(1)">
                    <i class="icon icon-heikin-ashi2"></i>
                    <span class="name">{{ $t('barstyle_chart.candles') }}</span>
                  </li>
                  <li class="item" @click="activeFavoredItem = 3, showSetBarStyle(9)">
                    <i class="icon icon-heikin-ashi3"></i>
                    <span class="name">{{ $t('barstyle_chart.hollow_candles') }}</span>
                  </li>
                  <li class="item" @click="activeFavoredItem = 4, showSetBarStyle(8)">
                    <i class="icon icon-heikin-ashi"></i>
                    <span class="name">{{ $t('barstyle_chart.heikin_ashi') }}</span>
                  </li>
                  <li class="item" @click="activeFavoredItem = 5, showSetBarStyle(2)">
                    <i class="icon icon-line2"></i>
                    <span class="name">{{ $t('barstyle_chart.line') }}</span>
                  </li>
                  <li class="item" @click="activeFavoredItem = 6, showSetBarStyle(3)">
                    <i class="icon icon-line3"></i>
                    <span class="name">{{ $t('barstyle_chart.area') }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="time div-time button-icon" @click.stop="onSaveTrandingChartConfiguration()" id="btn-upload-chart">
              <span class="icon-upload"></span>
              <span class="tooltip-upload">{{ $t('barstyle_chart.save_chart') }}</span>
            </div> -->
            <!-- <div class="time div-time button-icon" @click.stop="onReloadTrandingChart()" id="btn-download-chart">
              <span class="icon-download"></span>
              <span class="tooltip-download">{{ $t('barstyle_chart.load_chart') }}</span>
            </div> -->
          </div>
          <div v-click-outside="clickOutMenuTradingView" class="tooltip-dot d-block d-xxl-none">
            <div @click="isShowToolChart =!isShowToolChart" class="position-relative tooltip-dot d-flex d-xxl-none">
              <img 
                :src="require(`@/assets/images/icon/chevron-up-down.svg`)"
              />
            </div>
            <div class="d-flex d-xxl-none mt-0 position-absolute minimize-tooltip" v-if="isShowToolChart" >
              <!-- <div class="time div-time button-icon" v-on:click.stop="showIndicatorDialog()">
                <img 
                  :src="require(`@/assets/images/icon/icon-fx.svg`)"
                />
              </div> -->
              <!-- <div class="time div-time button-icon" v-on:click.stop="showChartPropertiesDialog()">
                <img 
                  :src="require(`@/assets/images/icon/icon-setting.svg`)"
                />
              </div> -->
              <!-- <div class="time div-time button-icon group_favered" :class="{ 'active':(showFavoredList == true)}" @click="onshowFavoredList()">
                <div class="btn btn-favored">

              <span class="icon" :class="{
                'icon-heikin-ashi2'   :activeFavoredItem === 2,
                'icon-bar'            :activeFavoredItem === 1,
                'icon-heikin-ashi3'   :activeFavoredItem === 3,
                'icon-heikin-ashi'    :activeFavoredItem === 4,
                'icon-line2'          :activeFavoredItem === 5,
                'icon-line3'          :activeFavoredItem === 6,
              }"></span>
                  <i class="icon-arrow1 icon_drop"></i>
                </div>
                <div class="box_favored_list clearfix" v-show="showFavoredList == true">
                  <ul class="favored_lit">
                    <li class="item" @click="activeFavoredItem = 1, showSetBarStyle(0)">
                      <i class="icon icon-bar"></i>
                      <span class="name">{{ $t('barstyle_chart.bars') }}</span>
                    </li>
                    <li class="item" @click="activeFavoredItem = 2, showSetBarStyle(1)">
                      <i class="icon icon-heikin-ashi2"></i>
                      <span class="name">{{ $t('barstyle_chart.candles') }}</span>
                    </li>
                    <li class="item" @click="activeFavoredItem = 3, showSetBarStyle(9)">
                      <i class="icon icon-heikin-ashi3"></i>
                      <span class="name">{{ $t('barstyle_chart.hollow_candles') }}</span>
                    </li>
                    <li class="item" @click="activeFavoredItem = 4, showSetBarStyle(8)">
                      <i class="icon icon-heikin-ashi"></i>
                      <span class="name">{{ $t('barstyle_chart.heikin_ashi') }}</span>
                    </li>
                    <li class="item" @click="activeFavoredItem = 5, showSetBarStyle(2)">
                      <i class="icon icon-line2"></i>
                      <span class="name">{{ $t('barstyle_chart.line') }}</span>
                    </li>
                    <li class="item" @click="activeFavoredItem = 6, showSetBarStyle(3)">
                      <i class="icon icon-line3"></i>
                      <span class="name">{{ $t('barstyle_chart.area') }}</span>
                    </li>
                  </ul>
                </div>
              </div> -->
           
              <!-- <div class="time div-time button-icon" @click.stop="onSaveTrandingChartConfiguration()" id="btn-upload-chart">
                <span class="icon-upload"></span>
                <span class="tooltip-upload">{{ $t('barstyle_chart.save_chart') }}</span>
              </div> -->
              <!-- <div class="time div-time button-icon" @click.stop="onReloadTrandingChart()" id="btn-download-chart">
                <span class="icon-download"></span>
                <span class="tooltip-download">{{ $t('barstyle_chart.load_chart') }}</span>
              </div> -->
              <div class="chart-type d-flex d-xxl-none">
                <span class="icon-close1" @click.stop="onCloseChart()" v-if="multipleChart && !fullscreen"/>
                <div class="chart-tab">
                  <!-- <a class="tab-element" href="/mul-charts" target="_blank" v-if="!multipleChart">{{ $t('exchange.basic.chart.btn_add_chart') }}</a> -->
                  <div class="tab-element" :class="{'active-tab':(chartActive == 1)}" @click="chartActive = 1">{{ $t('exchange.basic.chart.candle') }}</div>
                  <div class="tab-element" :class="{'active-tab':(chartActive == 2)}" @click="chartActive = 2">{{ $t('exchange.basic.chart.depth') }}</div>
                </div>
              </div>
              <div class="time div-time button-icon">
                <span class="icon-screen" @click="toggleFullScreen" :class="{'icon-expad': !fullscreen, 'icon-exit-full-screen': fullscreen}"></span>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'d-none d-xxl-flex':chartActive == 1, 'chart-type-position': chartActive == 2}" class="chart-type position-absolute">
          <span class="icon-close1" @click.stop="onCloseChart()" v-if="multipleChart && !fullscreen"/>
           <div class="chart-tab h-100 d-fex align-items-center">
            <!-- <a class="tab-element" href="/mul-charts" target="_blank" v-if="!multipleChart">{{ $t('exchange.basic.chart.btn_add_chart') }}</a> -->
            <div class="d-fex align-items-center chart-tab_bar">
              <div class="tab-element" :class="{'active-tab':(chartActive == 1)}" @click="chartActive = 1">{{ $t('exchange.basic.chart.candle') }}</div>
              <div class="tab-element" :class="{'active-tab':(chartActive == 2)}" @click="chartActive = 2">{{ $t('exchange.basic.chart.depth') }}</div>
            </div>
            
            <div @click="toggleFullScreen" class="icon-screen p-0" :class="{'icon-expand': !fullscreen, 'icon-exit-expand': fullscreen}"></div>
          </div>

        </div>
      </div>
      <div class="chart-content" :class="{'chart-full-screen': fullscreen}" >
          <trading-view-chart
            :symbol="symbol"
            :resolution.sync="resolution"
            :min-tick = "minTick" multipleChart
            ref="tradingView" v-show="chartActive === 1">
          </trading-view-chart>
          <high-charts :coin="coin" :currency="currency" v-show="chartActive === 2" ref="depthChart"></high-charts>
      </div>
    </fullscreen>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import TradingViewChart from './TradingViewChart';
  import HighCharts from './HighCharts';
  import LocalStorageUtils from '@/common/LocalStorageUtils';
  import SelectBox from '@/components/shared_components/common/SelectBox';
  import Const from '@/common/Const';
  import {mapState} from 'vuex';

  const M1 = '1m';
  const M5 = '5m';
  const M15 = '15m';
  const H1 = '1h';
  const H4 = '4h';
  const DAY = 'D';
  const WEEK = 'W';
  const MONTH = 'M';

  export default {
    components: {
      TradingViewChart,
      HighCharts,
      SelectBox
    },
    props: {
      keyChart        : { type: Number, default: 1 },
      multipleChart   : { type: Boolean, default: false },
      changeTabFromHome : { type: String, default: 'market' },
    },
    data() {
      return {
        currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
        coin: this.$route.query.coin || Const.DEFAULT_COIN,

        resolution: '1',
        minTick: 'default',

        selectedMinute: null,
        selectedHour: '60',
        isSelectMinute: true,
        chartActive: 1,
        timeActive: 4,
        fullscreen: false,

        // It's used for multiple charts.
        pairs: [],
        selectedPair: null,
        selectedTime: '60',

        /*button show Favored List*/
        showFavoredList: false,
        activeFavoredItem: 2,
        isShowToolChart: false,
      };
    },
    computed: {
      symbol() {
        return this.toUpperCase(this.coin) + '/' + this.toUpperCase(this.currency);
      },
      ...mapState({
        masterData: state => state.masterdata,
      }),
    },
    watch: {
      resolution() {
        this.saveDataLocalStorage();
      },
      changeTabFromHome (newValue){
        this.isShowToolChart = false;
        return newValue === this.changeTabFromHome;
      },

      selectedMinute(newMinute) {
        if (!newMinute || this.multipleChart) {
          return;
        }
        this.timeActive = 1;
        this.resolution = newMinute;
        this.selectedHour = null;
      },

      selectedHour(newHour) {
        if (!newHour || this.multipleChart) {
          return;
        }
        this.timeActive = 2;
        this.resolution = newHour;
        this.selectedMinute = null;
      },

      selectedTime(newTime) {
        console.log('newTime', newTime)
        // if (!newTime || !this.multipleChart) {
        //   return;
        // }
        if (!newTime) {
          return;
        }
        console.log('newTime', newTime)
        this.resolution = newTime;
        
      },

      '$route' (to, from) {
        this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
        this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

        this.onChangedPairCurrency();
      },

      selectedPair(newValue) {
        if (!this.multipleChart) {
          return;
        }
        this.coin         = newValue.coin;
        this.currency     = newValue.currency;
        this.updateMinTick();
        this.onChangedPairInDepthChart();
      },
    },
    methods: {
      clickOutMenuTradingView(){
        this.isShowToolChart = false;
      },
      getEventHandlers() {
        return {
          SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        }
      },

      onSpotSelectedPairMarket(pair) {
        this.currency = pair.currency;
        this.coin = pair.coin;

        let foundPair = window._.find(this.pairs, item => {
          return (item.coin == this.coin) && (item.currency == this.currency);
        });

        if(foundPair) {
          this.selectedPair = foundPair;
          this.updateMinTick();
          this.$refs.depthChart.reload(this.coin, this.currency);
        }
      },

      minutes() {
        return window._.map(Const.MINUTES, item => {
          const name = this.formatTime(item);
          return {id: `${item}`, name: name};
        });
      },

      hours() {
        return window._.map(Const.HOURS, item => {
          const name = this.formatTime(item);
          return {id: `${item}`, name: name};
        });
      },
      optionTime() {
        const data = [
          {
            id: '1',
            name: M1
          },
          {
            id: '5',
            name: M5
          },
          {
            id: '15',
            name: M15
          },
          {
            id: '60',
            name: H1
          },
          {
            id: '240',
            name: H4
          },
          {
            id: 'D',
            name: DAY
          },
        ]
        return data
      },
      times() {
        const arr = [
          { id: 'D', name: window.i18n.t('exchange.basic.time.one_day') },
          { id: 'W', name: window.i18n.t('exchange.basic.time.one_week') },
          { id: 'M', name: window.i18n.t('exchange.basic.time.one_month') }
        ];
        return window._.concat(this.minutes(), this.hours(), arr);
      },

      formatTime(time) {
        time = parseInt(time);
        if (time >= 60) {
          let hours = time / 60;
          return window.i18n.t(hours > 1 ? 'exchange.basic.time.hours' : 'exchange.basic.time.hour', { number: hours });
        }
        return window.i18n.t(time > 1 ? 'exchange.basic.time.minutes' : 'exchange.basic.time.minute', { number: time });
      },

      onChangedPairCurrency() {
        this.updateMinTick();
        this.getDataLocalStorage();

        // update depth chart
        this.onChangedPairInDepthChart();
      },

      onChangeResolution(time) {
        this.timeActive = this.getTimeActive(time);
        const convertTime = this.getTimeMinus(time)
        this.resolution = convertTime;
        this.selectedMinute = null;
        this.selectedHour = null;
      },

      onCloseChart() {
        ConfirmationModal.show({
          type: 'warning',
          title: '',
          content: window.i18n.t('exchange.basic.chart.msg_close_chart'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.$emit('closeChart', this.keyChart);
            this.fullscreen = false;
          },
          onCancel: () => {
          }
        });
      },

      onChangedPairInDepthChart() {
        if (this.chartActive === 2) {
          this.$refs.depthChart.reload(this.coin, this.currency);
        }
      },

      redrawChart() {
        this.$nextTick(() => {
          // Re-render depth chart
          this.$refs.depthChart.redraw();
        });
      },

      getTimeActive(time) {
        switch(time) {
          case M1:
          return 1;
          case M5:
          return 2;
          case M15:
          return 3;
          case H1:
          return 4;
          case H4:
          return 5;
          case DAY:
          return 6;
          // case DAY:
          //   return 3;
          // case WEEK:
          //   return 4;
          // case MONTH:
          //   return 5;
        }
        throw "Selection tab not correct";
      },

      getTimeMinus(time) {
        switch(time) {
          case M1:
            return '1';
          case M5:
            return '5';
          case M15:
            return '15';
          case H1:
            return '60';
          case H4:
            return '240';
        }
        return time;
      },

      toggleFullScreen() {
        this.$refs['fullscreen'].toggle();
      },

      fullscreenChange (fullscreen) {
        this.fullscreen = fullscreen;
        this.$refs.depthChart.redraw();
      },

      toUpperCase(value) {
        return value.toUpperCase();
      },

      async updateMinTick() {
        if (!this.masterData) {
          this.minTick = 'default';
          return;
        }
        let setting = window._.find(this.masterData.coin_settings, (setting) => {
          return setting.currency == this.currency && setting.coin == this.coin;
        });

        // If setting null, try re-call MasterData from API
        if (!setting) {
          let immediate = true;
          await rf.getRequest('MasterdataRequest').getAll(immediate).then(res => {
            setting = window._.find(res.coin_settings, (setting) => {
              return setting.currency == this.currency && setting.coin == this.coin;
            });
          });
        }

        const newMinTick = setting ? 1 / setting.price_precision : 0.001;
        this.minTick =  `${newMinTick}`;
      },

      saveDataLocalStorage() {
        const data = {
          selectedMinute      : this.selectedMinute,
          selectedHour        : this.selectedHour,
          resolution          : this.resolution,
          timeActive          : this.timeActive
        };
        LocalStorageUtils.saveItem('trading_chart', data);
      },

      getDataLocalStorage() {
        const data = LocalStorageUtils.getItem('trading_chart');
        if (!data) {
          return;
        }

        this.selectedMinute = data.selectedMinute;
        this.selectedHour = data.selectedHour;
        this.resolution = data.resolution;
        this.timeActive = data.timeActive;
      },

      showIndicatorDialog() {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.showIndicatorDialog();
        }
      },

      onSaveTrandingChartConfiguration() {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.saveTradingChartConfiguration();
        }
      },

      onReloadTrandingChart() {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.reloadTrandingChart();
        }
      },

      showSetBarStyle(type) {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.setBarStyle(type);
        }
      },

      showChartPropertiesDialog() {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.showChartPropertiesDialog();
        }
      },

      onshowFavoredList() {
        this.showFavoredList = !this.showFavoredList;
      },

      initPairs() {
        window._.forEach(this.masterData.coin_settings, (setting) => {
          const item = {
            coin      : setting.coin,
            currency  : setting.currency,
            id        : setting.id,
            name      : `<b>${this.toUpperCase(setting.coin)}</b> / ${this.toUpperCase(setting.currency)}`,
          };
          this.pairs.push(item);
        });
        // Set select first
        this.selectedPair = this.pairs[0];
      }
    },
    created() {
      this.resolution = this.selectedHour;
      this.updateMinTick();
      this.initPairs();
      this.getDataLocalStorage();
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  .line {
    border-left: 1px solid #8E8E92;
    height: 24px !important;
  }
  .dark-theme {
    .chart-option {
      //background-color: $color-black-shark!important;
      border-bottom: 4px solid $color-wood-smoke!important;
    }
    .icon-expand {
      background: $color-storm-grey;
    }
    .icon-exit-expand {
      background: $color-storm-grey;
    }
  }

  .icon-expand {
    width: 24px;
    height: 24px;
    background-color: $color-gray-dark;
    -webkit-mask-image: url("@/assets//images/icons/icon-expand.svg");
    mask-image: url("@/assets//images/icons/icon-expand.svg");
    -webkit-mask-repeat: no-repeat;
    mask-size: 100%;
  }
  .icon-exit-expand {
    width: 24px;
    height: 24px;
    background-color: $color-gray-dark;
    -webkit-mask-image: url("@/assets//images/icons/icon-exit-expand.svg");
    mask-image: url("@/assets//images/icons/icon-exit-expand.svg");
    -webkit-mask-repeat: no-repeat;
  }

  $borderColor: #BBBBBB;
  $heightOption: 40px;   
  #btn-upload-chart {
    position: relative;
    .tooltip-upload {
      position: absolute;
      top: 100%;
      margin-top: 5px;
      z-index: 5;
      background: rgba(255, 255, 255, 0.8);
      white-space: nowrap;
      left: 50%;
      font-size: 15px;
      padding: 5px 5px;
      line-height: 20px;
      border-radius: 3px;
      color: #000;
      width: 120px;
      text-align: center;
      margin-left: -60px;
      display: none;
    }
    &:hover {
      .tooltip-upload {
        display: block;
      }
    }
    
  }
  #btn-download-chart {
    position: relative;
    .tooltip-download {
      position: absolute;
      top: 100%;
      margin-top: 5px;
      z-index: 5;
      background: rgba(255, 255, 255, 0.8);
      white-space: nowrap;
      left: 50%;
      font-size: 15px;
      padding: 5px 5px;
      line-height: 20px;
      border-radius: 3px;
      color: #000;
      width: 120px;
      text-align: center;
      margin-left: -60px;
      display: none;
    }
    &:hover {
      .tooltip-download {
        display: block;
      }
    }
  }
  .chart-container {
    background-color: var(--background-chart);
    width: 100%;
    height: 100%;
    overflow: hidden;
    &>:first-child {
      width: 100%;
      height: 100%;
    }
    .fullscreen {
      background: $color-mirage !important;
      overflow-y: hidden !important;
    }
    .chart-option {
      min-width: 393px;
      //background-color: $color-gray-alabaster;
      padding: 0px 10px;
      height: $heightOption;
      border-bottom: 1px solid $color-alabaster;
      line-height: 28px;
      font-size: $font-smaller;
      color: $color-grey;

      .tooltip-dot {
        text-align:center;
        margin-left: auto;
        min-width: 35px;
        cursor: pointer;
      }
      .minimize-tooltip {
        top: 35px;
        right: 16px;
        padding: 10px 0;
        padding-left: 20px;
        background: white;
        width: 250px;
        border: solid 1px $color-gray-dark;
        flex-wrap: wrap;
        background: var(--background-current_coin);
        height: auto !important;
      }

      .time-option {
        height: 100%;
        float: left;
        display: flex;
        align-items: center;
        width: 100%;
        .setting-minimize{
          margin-left: 11px;
        }
        
        ::v-deep {
          .sc_search_select .group_search_select .list_search_select {
            background: var(--bgr-pagination);

            li {
              span {
                color: var(--text-primary-color);
              }
              &:hover {
                span {
                  color: $color-caribbean-green;
                }
              }
            }
          }
          .sc_search_select .group_search_select .button_drop_search {
            span{
              font-size: 12px;
              border-right: 0px;
              display: flex;
              align-items: center;
              padding: 4px;
              border-radius: 16px;
              background-color: var(--background-time);
              min-width: 33px;
              justify-content: center;
            }
            .list_search_select {
              background: var(--bgr-pagination);
            }
            .full_name_coin_select {
              color: var(--text-primary-color);
            }
          }
              //background: var(--bgr-pagination);
        }
        div {
          float: left;
          height: 100%;
          display: flex;
          align-items: center;
          ::v-deep {
            .group_search_select {
              //height: 100%;
              .button_drop_search {
                float: left;
                //height: 100%;
                align-items: center;
                display: flex;
                margin-right: 11.34px;
                border: none;
                padding: 0;
              }
            }
          }

          select {
            height: 100%;
            padding-left: 5px;
            -webkit-appearance: none;
            border: none;
            border-right: 1px solid $borderColor;
            // padding-top: 3px;
            padding-bottom: 3px;
            -webkit-border-radius: 0px;
          }
        }
        .select-box-mul-chart {
          border-right: 1px solid $borderColor;
        }
        .time {
          &.div-time {
            font-size: 12px;
            border-right: 0px;
            display: flex;
            align-items: center;
            border-radius: 3px;
            margin-right: 11.34px;
            cursor: pointer;
            color: #8e8e92;
            padding: 4px;
            border-radius: 16px;
            height: auto;
            line-height: normal;
            &:hover {
              color: $color-aqua-green;
            }
          }
          &.button-icon {
            cursor: pointer;
            font-size: 10px;
            .icon_drop {              
              font-size: $font-mini-mini;
              float: right;
              line-height: 26px;
              margin-left: 5px;
            }
            &:hover {
              color: $color-aqua-green;
              .icon-download,
              .icon-upload {
                &:before {
                  color: $color-aqua-green;
                }
              }
            }
          }
          &.active-time {
            //color: $color-aqua-green;
            background-color: var(--background-time);
          }
        }
        .time-filter {
          color: $color-gray-dark;
        }
        .group_favered {
          position: relative;
          .btn-favored {
            border: 0px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            line-height: 20px;
            background-color: transparent;
            display: flex;
            .icon {
              font-size: 14px;
              display: inline-block;
              float: left;
              margin: 4px 0px;
            }
            .icon_drop {
              line-height: 25px;
              height: 25px;
              float: right;
              padding-top: 2px;
              font-size: 9px;
            }            
          }
          .box_favored_list {
            position: absolute;
            top: 100%;
            right: 0px;
            width: 156px;
            z-index: 999;
            margin-top: 5px;
            height: auto;
            .favored_lit {
              background-color: var(--bgr-pagination);;
              margin: 0;
              padding: 5px 0px;
              li.item {
                display: block;
                width: 100%;
                line-height: 20px;
                cursor: pointer;
                padding: 3px 14px;
                font-size: $font-root;
                overflow: hidden;
                color: var(--text-primary-color);

                &:hover {
                  color: $color-aqua-green;
                  .icon {
                    &:after,
                    &:before {
                      color: $color-aqua-green;
                    }
                  }
                }
                .icon {
                  display: inline-block;
                  float: left;
                  font-size: $font-big;
                  line-height: 20px;
                  margin-right: 5px;
                  width: 20px;
                }
                .name {
                  display: inline-block;
                  float: left;
                  line-height: 20px;
                  font-size: $font-root;
                  width: calc(100% - 25px);
                }
              }
            }
          }
          &.active,
          &:hover {
            .btn-favored {
              color: $color-aqua-green;
            }
          }
          &.active {
            .btn-favored {
              .icon_drop {
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari prior 9.0 */
                transform: rotate(180deg); /* Standard syntax */
                padding-top: 0px;
                padding-bottom: 18px;
              }
            }
          }
        }
      }
      .chart-type-position {
        right: 18px!important;
      }

      .chart-type {
        float: right;
        height: 100%;
        line-height: $heightOption;
        right: 18px;
        .total-aml-remaining {
          align-self: center;
          margin-right: -6px; 
          font-family: $font-family-roboto;
          font-size: $font-smaller;
          color: $color-green;
          span {
            font-family: $font-family-roboto-bold;
          }
        }
        .icon-close1 {
          color: $color-white;
          float: right;
          line-height: $heightOption;
          padding-left: 9px;
          padding-right: 9px;
          cursor: pointer;
          background: $color-grey;
        }
        .icon-screen {
          float: right;
          padding-left: 9px;
          padding-right: 9px;
          cursor: pointer;
          line-height: $heightOption;
        }
        .chart-tab {
          cursor: pointer;
          margin: 0;
          float: right;
          .chart-tab_bar{
            background-color: var(--background-tab);
            border-radius: 50px;
            margin-right: 16px;
            padding: 2px;
          }
          .tab-element {
            border-right: 0px;
            min-width: 96px;
            float: left;
            text-align: center;
            border-radius: 50px;
            padding: 3px 8px;
            font-size: 12px;
            line-height: 1.5;
            color: var(--text-close);
            font-family: $font-family-roboto-medium;
            &.active-tab {
              background: var(--background-tab-active);
              color: var(--text-grey-dark);
            }
          }
          a {
            color: $color-grey-dusty;
            &:hover {
              color: $color-corn;
            }
          }
        }
      }
    }
    .chart-content {
      height: calc(100% - #{$heightOption});
      width: 100%;
      .vue-highcharts {
        box-shadow: none !important;
        border: none !important;
      }
      &.chart-full-screen {
        height: calc(100% - #{$heightOption}) !important;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .dark-theme {
      .chart-option {
        border-bottom: 1px solid var(--color-border-right)!important;
        border-top: 1px solid var(--color-border-right)!important;
      }
    }
    .chart-container .chart-option {
      //border: 1px solid $color-gray-alto;
      padding-right: 0;
      padding-left: 20px;
      border-bottom: 1px solid $color-gray-alto;
      //border-top: 1px solid $color-gray-alto;

      .time-option {
        width: 100%;
        ::v-deep {
          .sc_search_select .group_search_select .button_drop_search span {
            font-size: 10px;
          }
        }
      }

      .tab-element {
        padding-right: 0;
      }
      .time-option {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .div-time {
          margin-top: 0;
          height: auto;
          font-size: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .chart-container .chart-option {
      //border: 1px solid $color-gray-alto;
      padding-right: 0;

      .time-option {
        width: 100%;
      }

      .tab-element {
        padding-right: 0;
      }
      .time-option {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        div {
          margin-top: 0;
          height: auto;
        }
        .setting-minimize{
          margin-left: auto;
        }

        .tooltip-dot {
          margin-left: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .chart-container .chart-option {
      min-width: 325px;
    }
    .chart-container .chart-option .chart-type {
      right: 30px;
    }
    .chart-container .chart-option {
      .time-option {
        .div-time {
          font-size: 12px;
          color: $color-grey-dark;

        }
        ::v-deep {
          .sc_search_select .group_search_select .button_drop_search span {
            font-size: 12px;
            color: $color-gray-dark;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .chart-content {
    .tv-side-toolbar {
      background-color: red
    }
  }

</style>
