<template>
  <div class="app-download-section">
    <h2 v-if="sizeScreen < 610" class="text-trade">
      <span class="highlight-text">Trade</span> anytime, anywhere!
    </h2>
    <div class="app-preview">
      <img :src="appImage" alt="App Preview" />
    </div>
    <div
      class="container-download"
      v-if="sizeScreen < 610"
      :style="{ display: 'flex' }"
    >
      <div :style="{ display: 'flex', alignItems: 'center', gap: '10px' }">
        <img
          :style="{ width: '32px', height: '32px' }"
          :src="require(`@/assets/images/drx/${appleLogo}`)"
        />
        <div class="brand">App Store</div>
      </div>
      <div :style="{ display: 'flex', alignItems: 'center', gap: '10px' }">
        <img
          :style="{ width: '32px', height: '32px' }"
          :src="require(`@/assets/images/drx/${androidLogo}`)"
        />
        <div class="brand">Google Play</div>
      </div>
    </div>
    <div class="download-info" v-if="sizeScreen > 610">
      <h2 :style="{ width: '462px' }" class="text-trade">
        <span class="highlight-text">Trade</span> anytime,<br />
        anywhere!
      </h2>
      <div
        :style="{
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          marginTop: '72px',
        }"
      >
        <img :src="qrCodeImage" alt="QR Code" class="qr-code" />
        <div class="download-text">
          <p>Scan to download the app</p>
          <span>IOS & Android</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    appImage: {
      type: String,
      default: require("@/assets/images/drx/app_preview.png"), // Hình ảnh mặc định của app
    },
    qrCodeImage: {
      type: String,
      default: require("@/assets/images/drx/qr_code.png"), // Hình ảnh mặc định của QR code
    },
  },
  data() {
    return {
      sizeScreen: window.innerWidth,
    };
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
      theme: (state) => state.userThemeMode,
    }),
    appleLogo() {
      return this.theme === "light-theme" ? "appleDark.png" : "apple.png";
    },
    androidLogo() {
      return this.theme === "light-theme" ? "androidDark.png" : "android.png";
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>

<style lang="scss" scoped>
.app-download-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: var(--text-primary-color);
  max-width: 1284px;
  margin: 0 auto;
  gap: 163px;
  .container-download {
    min-width: 335px;
    justify-content: space-around;
    margin-bottom: 65px;
    margin-top: 32px;
    .brand {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  }
  .text-trade{
    letter-spacing: -0.2px;
    font-family: $font-notosans-bold;
  }
  @media screen and (max-width: 610px) {
    flex-direction: column;
    padding: unset;
    gap: 32px;
  }
}

.app-preview img {
  width: 300px;
  border: 1px solid var(--color-border-home);
  border-radius: 12px;
  @media screen and (max-width: 610px) {
    width: 200px;
  }
}

.download-info {
  text-align: left;
  max-width: 300px;
}

h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
  @media screen and (max-width: 610px) {
    font-size: 20px;
  }
}

.highlight-text {
  color: #03aac7; /* Màu xanh cho chữ "Trade" */
}

.qr-code {
  width: 150px;
}

.download-text {
  color: #b0b0b0;
  display: flex;
  flex-direction: column;

  span {
    font-size: 24px;
  }
}

.dark-theme {
  .download-text p {
    color: #e2e8f0;
    font-weight: 400;
    font-family: $font-notosans-regular;
  }
}

.download-text p {
  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
  font-size: 20px;
  color: #151717;
}
.download-text span {
  font-weight: bold;
  color: var(--text-primary-color);
}
</style>
