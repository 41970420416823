var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-table"},[_c('p',{staticClass:"title-market"},[_vm._v("Popular Cryptocurrencies")]),_c('div',{staticClass:"clearfix",attrs:{"id":"container-tab"}},[_c('div',{staticClass:"clearfix group_table_landing"},_vm._l((_vm.marketTabMain),function(item){return _c('label',{key:item,class:_vm.tabMain === item ? 'current-market' : '',on:{"click":function($event){return _vm.showTabMain(item)}}},[(item === 'Favorites')?_c('i',{staticClass:"icon-star2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("landing.tab_" + item))+" ")])}),0)]),_c('div',{staticClass:"table-content"},[(_vm.showType === _vm.CONSTS.TYPE_FUTURE)?[_c('div',{attrs:{"id":"content"}},[_c('market-future',{attrs:{"id":"table-future"}})],1)]:_c('div',{attrs:{"id":"content"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabMain === 'Futures'),expression:"tabMain === 'Futures'"}],attrs:{"id":"content-future"}},[_c('div',{staticClass:"container-tab"},[_c('div',{staticClass:"tag-container"},[_c('button',{class:[
                'tag-button',
                {
                  'active-tag': _vm.selectedMarket === _vm.CONSTS.TYPE_USDT_CONTRACTS,
                },
              ],on:{"click":function($event){return _vm.showMarket(_vm.CONSTS.TYPE_USDT_CONTRACTS)}}},[_vm._v(" "+_vm._s(_vm.$t("landing.future.usdt_contracts"))+" ")]),_c('button',{class:[
                'tag-button',
                {
                  'active-tag': _vm.selectedMarket === _vm.CONSTS.TYPE_USD_CONTRACTS,
                },
              ],on:{"click":function($event){return _vm.showMarket(_vm.CONSTS.TYPE_USD_CONTRACTS)}}},[_vm._v(" "+_vm._s(_vm.$t("landing.future.usd_contracts"))+" ")])])]),_c('market-coin',{key:_vm.selectedMarket,attrs:{"marketSelected":_vm.showType,"columns":_vm.columns,"coinNames":_vm.coinNames,"tabMain":_vm.tabMain,"isHomePage":true,"futuresTable":true,"subTabMain":_vm.selectedMarket},on:{"selectedPairMarket":_vm.onSelectedPair}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabMain === 'Spot'),expression:"tabMain === 'Spot'"}]},[_c('market-coin',{key:_vm.selectedMarket,attrs:{"priceScope":_vm.priceScope,"tabMain":_vm.tabMain,"isHomePage":true},on:{"selectedPairMarket":_vm.onSelectedPair}})],1)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }