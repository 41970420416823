<template>
  <div class="OtpModal">
    <modal :name="name" :width="sizeScreen" :style="{width: '400px'}">
      <div slot="body">
        <div class="content_login_form_container">
          <div class="content_login_form">
            <div class="input-group">
              <div class="name_input">API Label <span class="require">*</span></div>
              <input :disabled="!!newApiNameProps" type="" name="" class="input_enter_key" v-model="newApiName"
                     :maxlength="20"
                     :placeholder="$t('account.api.input_placeholder')">
            </div>
            <div class="row">
              <div class="name_input">Permissions <span class="require">*</span></div>
              <div class="grid-checkbox">
                <div class="checkbox-function" v-for="item in scopeSetting" :key="item.key">
                  <label :for="`checkbox_id${item.key}`" :style="{display: 'flex', alignItems: 'center'}">
                    <input class="checkbox_input" type="checkbox" name="checkbox" v-model="item.isChecked" :id="`checkbox_id${item.key}`">
                    <span class="button-check"></span>
                    {{ item.name }}
                  </label>
                </div>
              </div>
            </div>
            <div>
              <button :disabled="isSubmitDisabled" class="submit-button"   @click="handleSubmit">
                {{ type === 'create' ?  $t('account.api.create_btn') : 'Update' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/shared_components/common/Modal';
import OtpModal from "@/components/shared_components/funds/common/OtpModal.vue";

export default {
  inject: ['$validator'],
  components: {
    OtpModal,
    Modal,
  },
  data() {
    return {
      sizeScreen: "400",
      newApiName: this.newApiNameProps || '',
    }
  },
  props: {
    name: {type: String, default: 'APIKey'},
    submit: {type: Function},
    errorMessenger: {type: String},
    scopeSetting: {type: Array},
    newApiNameProps: {type: String},
    type: {type: String},
  },
  emits: ["onSubmit","onClose"],
  watch: {
    newApiNameProps: {
      immediate: true,
      handler(newVal) {
        this.newApiName = newVal;
      },
    },
  },
  computed: {
    isSubmitDisabled() {
      const isAnyCheckboxChecked = this.scopeSetting.some(item => item.isChecked);
      return !(this.newApiName && this.newApiName.trim() !== '' && isAnyCheckboxChecked);
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('onSubmit', {
        apiName: this.newApiName,
        permissions: this.scopeSetting.filter(item => item.isChecked)
      });
    },
    validate() {
      if (window._.isEmpty(this.otp)) {
        this.$validator.errors.add({ field: 'otp', msg: window.i18n.t('funds.withdrawals.errors.otp_required') });
      } else if (this.otp.length !== 6) {
        this.$validator.errors.add({ field: 'otp', msg: window.i18n.t('funds.withdrawals.errors.otp_length_digits') });
      } else if (this.errorMessenger) {
        this.$validator.errors.add({ field: 'otp', msg: this.errorMessenger });
      }
      return !this.$validator.errors.has('otp');
    },
    handleWindowResize() {
      if(window.innerWidth > 800) {
        this.sizeScreen = "610";
      } else {
        this.sizeScreen = "550";
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize)
    this.$on('hideCommonModal', (name) => {
      this.$emit('onClose');
    })
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.name_input{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--text-or);
  margin-bottom: 10px;
}
.require{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #F1493F;
}
.submit-button {
  background-color: var(--text-primary-color);
  color: var(--background-color-primary);
  width: 100%;
  padding: 10px 16px;
  border-radius: 1234px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.OtpModal {
  .modal-footer {
    padding-top: 0px !important;
    padding-bottom: 12px !important;
  }

}
.grid-checkbox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .checkbox-function {
    right: 35px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #EAECEF;
    cursor: pointer;
    label {
      cursor: pointer;
      color: var(--dark-1);
      font-family: $font-inter-regular;
      .button-check {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        background: #DEDEDE;
        border-radius: 6px;
        height: 16px;
        width: 16px;
        display: block;
        float: left;
        position: relative;
        margin-right: 5px;
        &:after {
          content: '';
          position: absolute;
          left: 6px;
          top: 1px;
          width: 6px;
          height: 13px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          display: none;
        }
      }
      .checkbox_input {
        display: none;
        &[type="checkbox"]:checked + .button-check {
          background-color: $color-caribbean-green;
        }
        &[type="checkbox"]:checked + .button-check:after {
          display: block;
        }
      }
    }

  }

}
@media screen and (max-width: 992px) {
  .OtpModal {
    .modal-body {
      padding: 0 15px 30px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
:deep(.modal-content){
  background: var(--background-color-primary)!important;
  .icon-close{
    color: var(--text-primary-color) !important;
  }
}
.content_login_form {
  width: 100%;
  .icon {
    margin: 0 auto 20px;
    display: block;
  }
  .box-title {
    margin-bottom: 40px;
    font-family: $font-inter-bold;
    color: var(--dark-1);
    font-size: 34px;
    line-height: 40px;
    text-align: center;
  }
  input {
    min-height: 48px;
    padding: 12px;
    width: 100%;
    border-radius: 123px;
    border: 1px solid var(--button-border-outline);
    background: var(--input-bg);
    max-width: 454px;
    font-size: 18px;
    line-height: 21px;
    font-family: $font-inter-regular;
    color: var(--text-primary-color);
    &::placeholder {
      color: var(--text-close);
      font-size: 14px;
    }
    &.error {
      border: 1px solid $text-color-red;
    }
  }
  .invalid-feedback {
    color: $text-color-red;
    font-family: $font-inter-regular;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-top: 8px;
  }
  .submit {
    width: 100%;
    height: 45px;
    transition: all ease-out 0.2s;
    color: $color-white;
  }
  .btn-submit {
    border-radius: 8px;
    padding: 15.5px 10px;
    background: $color-aqua-green;
    font-family: $font-inter-regular;
    color: $dark-1;
    font-size: 18px;
    line-height: 21px;
    width: 100%;
    margin-top: 30px;
  }
}
.lost_verify_code {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  .lost_verify_click {
    display: inline-block;
    font-family: $font-inter-regular;
    font-size: 16px;
    line-height: 19px;
    color: $color-aqua-green;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: 992px) {
  .content_login_form {
    .box-title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .icon {
      width: 104px;
      height: 80px;
    }
    .input {
      &::placeholder {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .lost_verify_code {
    .lost_verify_click {
      font-size: 14px;
      line-height: 16px;
    }
  }

}
@media screen and (max-width: 992px) {
  .OtpModal {
    :deep(.modal-dialog) {
      max-width: 60%;
    }
  }
}
@media screen and (max-width: 768px) {
  .OtpModal {
    :deep(.modal-dialog) {
      max-width: 60%;
    }
  }
}
@media screen and (max-width: 676px) {
  .OtpModal {
    :deep(.modal-dialog) {
      max-width: 70%;
    }
  }
}
@media screen and (max-width: 576px) {
  .OtpModal {
    :deep(.modal-dialog) {
      max-width: 80%;
    }
  }
}
@media screen and (max-width: 476px) {
  .OtpModal {
    :deep(.modal-dialog) {
      max-width: 90%;
    }
  }
}
@media screen and (max-width: 400px) {
  .OtpModal {
    :deep(.modal-dialog) {
      max-width: 94%;
    }
  }
}
</style>
