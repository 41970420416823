var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"container-modal",attrs:{"name":_vm.modalName,"width":"610"}},[_c('template',{slot:"body"},[_c('div',{staticClass:"anti-phishing-modal",attrs:{"slot":"body"},slot:"body"},[(_vm.mode !== 'create')?_c('div',{staticClass:"d-flex justify-content-center image-anti"},[(_vm.mode === 'success' || _vm.mode === 'change')?_c('img',{attrs:{"src":require(`@/assets/images/icon/send-mail-success${
                  _vm.userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)}}):_vm._e(),(_vm.mode === 'invalid')?_c('img',{attrs:{"src":require(`@/assets/images/icon/warning-mail-invalid${
                  _vm.userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)}}):_vm._e(),(_vm.mode === 'resend')?_c('img',{attrs:{"src":require("@/assets/images/icon/resend-email.svg")}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"w-100 text-center title-modal"},[(_vm.mode === 'create')?_c('span',[_vm._v(_vm._s(_vm.$t('anti.text_create')))]):_vm._e(),(_vm.mode === 'success')?_c('span',[_vm._v(_vm._s(_vm.$t('anti.text_enable')))]):_vm._e(),(_vm.mode === 'change')?_c('span',[_vm._v(_vm._s(_vm.$t('anti.text_change')))]):_vm._e()]),(_vm.mode === 'create')?_c('div',{staticClass:"input-form-phising position-relative"},[_c('label',[_vm._v(_vm._s(_vm.$t('anti_title')))]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  min: 4,
                  max: 20,
                  regex: /^[a-zA-Z0-9]*$/,
                }),expression:"{\n                  required: true,\n                  min: 4,\n                  max: 20,\n                  regex: /^[a-zA-Z0-9]*$/,\n                }"},{name:"model",rawName:"v-model",value:(_vm.params.code),expression:"params.code"}],staticClass:"form-control",class:{ 'error': _vm.errors.has('code_anti_phishing') },attrs:{"type":"text","maxlength":"20","name":"code_anti_phishing"},domProps:{"value":(_vm.params.code)},on:{"focus":_vm.resetErrors,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.params, "code", $event.target.value)}}}),_c('span',{staticClass:"recommend",class:{ 'error': _vm.errors.has('code_anti_phishing') }},[_vm._v(" "+_vm._s(_vm.$t('anti.text_anti_note'))+" ")])]):_vm._e(),(_vm.mode !== 'create')?_c('div',{staticClass:"sub-title"},[(_vm.mode === 'success')?_c('span',[_vm._v(_vm._s(_vm.$t('anti.code.success')))]):_vm._e(),(_vm.mode === 'change')?_c('span',[_vm._v(_vm._s(_vm.$t('anti.code.change')))]):_vm._e(),(_vm.mode === 'invalid')?_c('span',[_vm._v(_vm._s(_vm.$t('anti.code.invalid')))]):_vm._e(),(_vm.mode === 'resend')?_c('span',[_vm._v(_vm._s(_vm.$t('anti.code.resend', { email: _vm.email })))]):_vm._e()]):_vm._e(),(_vm.mode === 'create')?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.sentMail()}}},[_vm._v(_vm._s(_vm.$t('anti.btn_submit')))]):_c('button',{staticClass:"btn btn-success",on:{"click":_vm.closeModal}},[(_vm.mode === 'invalid' || _vm.mode === 'resend')?_c('span',[_vm._v(_vm._s(_vm.$t('common.action.ok')))]):_c('span',[_vm._v(_vm._s(_vm.$t('common.action.close')))])])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }