var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-tab"},[_c('data-table3',{ref:"datatable",staticClass:"table-landing",attrs:{"get-data":_vm.getData,"table-no-data":true,"id":"data-table-landing","msgEmptyData":_vm.$t('common.datatable.no_have_data')},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('tr',[(_vm.isVisibleColumn('pair'))?_c('td',{staticClass:"text-left col-pair",on:{"click":function($event){return _vm.selectedCurrenyPair(props.item.currency, props.item.coin)}}},[(!_vm.isSportExchange)?_c('span',{staticClass:"icon-star1",class:_vm.isFavorite(props.item.coin, props.item.currency)
                ? 'icon-star2'
                : 'star_gray',on:{"click":function($event){$event.stopPropagation();_vm.isFavorite(props.item.coin, props.item.currency)
                ? _vm.removeFavorite(props.item.coin, props.item.currency)
                : _vm.insertFavorite(props.item.coin, props.item.currency)}}}):_c('span',{staticClass:"icon-star2",class:_vm.isFavorite(props.item.coin, props.item.currency)
                ? 'icon-star3'
                : 'star_gray',style:({
              color: _vm.isFavorite(props.item.coin, props.item.currency)
                ? '#FFBF0F'
                : _vm.userThemeMode === 'light-theme'
                ? '#DEDEDE'
                : '#6D7387',
            }),on:{"click":function($event){$event.stopPropagation();_vm.isFavorite(props.item.coin, props.item.currency)
                ? _vm.removeFavorite(props.item.coin, props.item.currency)
                : _vm.insertFavorite(props.item.coin, props.item.currency)}}}),_c('span',{staticClass:"number-top"},[_vm._v(_vm._s(props?.index +1))]),_c('span',{staticClass:"coin"},[_vm._v(" "+_vm._s(_vm._f("currencyName")(props.item.coin)))]),_c('span',{staticClass:"currency"},[_vm._v("/"+_vm._s(_vm._f("currencyName")(props.item.currency)))])]):_vm._e(),(_vm.isVisibleColumn('coin'))?_c('td',{staticClass:"col-coin",on:{"click":function($event){return _vm.selectedCurrenyPair(props.item.currency, props.item.coin)}}},[_vm._v(" "+_vm._s(_vm._f("fullName")(props.item.coin))+" ")]):_vm._e(),(_vm.isVisibleColumn('current_price'))?_c('td',{staticClass:"col-last-price",attrs:{"title":_vm._f("formatCurrencyAmount")(props.item.current_price,props.item.currency, '0')},on:{"click":function($event){return _vm.selectedCurrenyPair(props.item.currency, props.item.coin)}}},[_c('span',{staticClass:"change-node",class:_vm.calculateLastPriceStatus(props.item)},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyAmount")(props.item.current_price,props.item.currency, "0"))+" ")]),(props.item.currency !== 'usd' && props.item.usdPrice > 0)?_c('span',{staticClass:"is-muted"},[(!_vm.isSportExchange)?_c('span',[_vm._v("/ $"+_vm._s(_vm._f("formatCurrencyAmount")(props.item.usdPrice,"usd", "0")))]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.isVisibleColumn('changed_percent'))?_c('td',{staticClass:"change-node col-24h-changed",class:{
            grown: props.item.changed_percent > 0,
            down: props.item.changed_percent < 0,
            black: props.item.changed_percent == 0,
          },attrs:{"title":_vm._f("changedPercent")(props.item.changed_percent)},on:{"click":function($event){return _vm.selectedCurrenyPair(props.item.currency, props.item.coin)}}},[_vm._v(" "+_vm._s(_vm._f("changedPercent")(props.item.changed_percent))+" ")]):_vm._e(),(_vm.isVisibleColumn('max_price'))?_c('td',{staticClass:"col-max-price",attrs:{"title":_vm._f("formatCurrencyAmount")(props.item.max_price,props.item.currency, '0')},on:{"click":function($event){return _vm.selectedCurrenyPair(props.item.currency, props.item.coin)}}},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyAmount")(props.item.max_price,props.item.currency, "0"))+" ")]):_vm._e(),(_vm.isVisibleColumn('min_price'))?_c('td',{staticClass:"col-min-price",attrs:{"title":_vm._f("formatCurrencyAmount")(props.item.min_price,props.item.currency, '0')},on:{"click":function($event){return _vm.selectedCurrenyPair(props.item.currency, props.item.coin)}}},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyAmount")(props.item.min_price,props.item.currency, "0"))+" ")]):_vm._e(),(_vm.isVisibleColumn('quote_volume'))?_c('td',{staticClass:"col-volume",attrs:{"title":_vm._f("formatCurrencyAmount")(props.item.quote_volume,props.item.currency, '0')},on:{"click":function($event){return _vm.selectedCurrenyPair(props.item.currency, props.item.coin)}}},[_vm._v(" "+_vm._s(_vm._f("formatNumberAndDecimal")(props.item.quote_volume))+" ")]):_vm._e()])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }