<template>
  <div id="indices">
     
    <div class="table-container table-positions">
      <data-table3 ref="datatable" :getData="getData" :limit="5">
        <template slot-scope="props">
        <th data-sort-field="symbol" @click="props.echoclick">
          <span class="box_custom_tooltip">
            {{$t('margin.positions_symbol')}}
            <div class="tooltip_custom_c">
              <span class="underline">{{$t('margin.positions_symbol')}}</span>
              {{$t('margin.position_symbol_tooltip')}}
            </div>
          </span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th data-sort-field="value" @click="props.echoclick">
          <span class="box_custom_tooltip">
            {{$t('margin.last_price')}}
            <div class="tooltip_custom_c">
              <span class="underline">{{$t('margin.last_price')}}</span>
              {{$t('margin.last_price_tooltip')}}
            </div>
          </span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th data-sort-field="previous_24h_value" @click="props.echoclick">
          <span class="box_custom_tooltip">
            {{$t('margin.prev_price_24h')}}
            <div class="tooltip_custom_c">
              <span class="underline">{{$t('margin.prev_price_24h')}}</span>
              {{$t('margin.last_price_24h_tooltip')}}
            </div>
          </span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th data-sort-field="changedPercent" @click="props.echoclick">
          <span class="box_custom_tooltip">
            {{$t('margin.last_change')}}
            <div class="tooltip_custom_c">
              <span class="underline">{{$t('margin.last_change')}}</span>
              {{$t('margin.last_change_tooltip')}}
            </div>
          </span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th data-sort-field="reference" @click="props.echoclick">
          <span class="box_custom_tooltip">
            {{$t('margin.reference')}}
            <div class="tooltip_custom_c">
              <span class="underline">{{$t('margin.reference')}}</span>
              {{$t('margin.reference_tooltip')}}
            </div>
          </span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th class="last-column width-last padding-right-0" data-sort-field="reference_symbol" @click="props.echoclick">
          <span class="fl-right2 box_custom_tooltip">
            {{$t('margin.reference_symbol')}}
            <div class="tooltip_custom_c left">
              <span class="underline">{{$t('margin.reference_symbol')}}</span>
              {{$t('margin.reference_symbol_tooltip')}}
            </div>
          </span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>

        </template>
        <template slot="body" slot-scope="props">
          <tr class="border_tr">
            <td><span>{{ props.item.symbol}}</span></td>
            <td><span>{{ getPercentageValue(props.item) }}</span></td>
            <td><span>{{ props.item.previous_24h_value | formatMarginNumber}}</span></td>
            <td><span>{{ props.item.changedPercent | formatMarginNumber }}</span></td>
            <td><span>{{ props.item.reference}}</span></td>
            <td class="last-column"><span>{{ props.item.reference_symbol }}</span></td>
          </tr>
        </template>
      </data-table3>      
    </div>

  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import { mapGetters } from 'vuex';
  import BigNumber from 'bignumber.js';

  export default {
    components: {

    },
    data() {
      return {
        
      }
    },
    props: {

    },
    watch: {
      indices() {
        this.$refs.datatable.refresh();
      }
    },
    computed: {
      ...mapGetters(['indices'])
    },
    methods: {
      getData (params) {
        return new Promise((resolve, reject) => {
          let indices = [].concat(this.indices);
          if (params.sort && params.sort_type) {
            const sort = params.sort;
            // console.log(sort);
            indices.sort((a, b) => {
              const sign = params.sort_type === 'asc' ? 1 : -1;
              if (sort === 'symbol' || sort === 'reference_symbol' || sort === 'reference') {
                const value1 = a[sort] || '';
                const value2 = b[sort] || '';
                return sign * (value1 < value2 ? -1 : 1);
              } else {
                const value1 = parseFloat(a[sort]).toFixed(8) || 0;
                const value2 = parseFloat(b[sort]).toFixed(8) || 0;
                return sign * (new BigNumber(value1).comparedTo(value2));
              }
            });
          }
          resolve(indices);
        });
      },
      getPercentageValue({symbol, value, precision=6}){
        const digits = Math.floor(precision)
        const format = '0.' + Array(digits).join('0') + '1'
        return this.$options.filters.formatMarginPrice(value, parseFloat(format));
      }
    },
    mounted() {
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .fl-right2 {
    float: right;
    padding: 0px 15px 0px 4px;
  }
  .fl-right {
    float: right;
  }
  #indices {
    padding-bottom: 12px;
    table {
      thead {
        th {
          padding: 10px 0px 9px 20px;
          font-size: $font-smaller;
          height: 40px;
          font-family: $font-family-roboto;
          vertical-align: middle;
          color: $color-grey-dusty;
          &:last-child {
            padding-right: 20px;
            padding-left: 8px;
            &::after {
                float: right;
            }
          }
        }
        th[data-sort-order="asc"]:after {
          padding-top: 2px;
          font-size: $font-smaller;
        }
        th[data-sort-order="desc"]:after {
          padding-top: 2px;
          font-size: $font-smaller;
        }
      }
      tbody {
        tr {
          td {
            color: $color-gray-dark;
            font-size: $font-smaller;
            font-family: $font-family-roboto-medium;
            text-align: left;
            vertical-align: middle;
            padding: 6px 0px 6px 20px;
            line-height: 20px;
            &:last-child {
              padding: 6px 20px 6px 8px;
            }
            .symbol_th {
              font-size: 13px;
              color: $color-blue-custom;
            }
            .up {
              color: $color-jungle-green;
            }
            .down {
              color: $color-denim;
            }
          }
        }
      }
    }
    .btn-close {
      width: 50px;
      height: 25px;
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
      letter-spacing: 0.8px;
      text-align: center;
      color: $color-jungle-green;
      font-size: $font-mini-mini;
      background-color: transparent;
      padding: 5px 2px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      float: right;
      &:hover, &:focus {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
        color: $color-white;
      }
    }
  }

  .box_custom_tooltip {
    position: relative;
    .tooltip_custom_c {
      visibility: hidden;
      display: none;
      width: 250px;
      background-color: #222832;
      color: #cccccc;
      text-align: center;
      padding: 5px 0;
      border-radius: 4px;
      font-size: 13px;
      -webkit-box-shadow: 0px 0px 3px #cccccc;
      box-shadow: 0px 0px 3px #cccccc;
      position: absolute;
      z-index: 50;
      left: 100%;
      top: 100%;
      line-height: 18px;
      &.left {
        left: auto;
        right: 100%;
      }
      .underline {
        text-decoration: underline;
        display: block;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
    &:hover {
      cursor: help;
      .tooltip_custom_c {
        display: block;
        visibility: visible;
        color: #cccccc;
      }
    }
  }
</style>

<style lang="scss">
  @import "@/assets/sass/variables";
   #indices {
    .VuePagination {
      display: none;
    }
    table {
      thead {
        background-color: transparent;
        overflow: inherit;
      }
      tbody {
        tr {
          td {
            &:last-child {
              color: $color-gray-dark;
              font-size: $font-smaller;
              font-family: $font-family-roboto-medium;
            }
          }
        }
      }
    }
    .empty-data {
      &:hover {
        background-color: transparent !important;
      }
    }
    .border_tr {
      position: relative;
      border-bottom: 4px solid #181f2b;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      &.up {
        border-left: 3px solid $color-jungle-green;
        border-right: 3px solid $color-jungle-green;
      }
      &.down {
        border-left: 3px solid $color-denim;
        border-right: 3px solid $color-denim;
      }
    }
    .last-column{
      text-align: right !important;
    }
    .padding-right-0{
      padding-right: 0px;
    }
    .width-last{
      width: 18%;
    }
    .width-10{
      width: 10%;
    }
  }
</style>