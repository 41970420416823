<template>
  <div id="temp_nav_slider">
    <div class="header_nav_slider clearfix"><h2>{{ $t('margin.contracts') }}</h2></div>
    <div class="clearfix"></div>

    <ul id="nav_slider">
      <li v-for="(items,index) in slideMenuItems" class="item_navbar_right">
        <span class="title_menu">{{ items.name }}</span>
        <ul class="treeview-menu active">
          <li v-for="(item,index) in items.items" v-if="item.router && item.router.name">
            <router-link tag="a" :to="item.router.name"  >
              {{ item.name }}
            </router-link>
          </li>
          <li v-else class="dropdown">
            <div class="title_menu2" :class="{ active: item === selectedItem}" @click="showSubMenu(item)"> 
              {{ item.name }}
            </div>
            <ul class="treeview-menu2" :class="{ intro: item === selectedItem}">
               <li v-for="(item,index) in item.items2">
                <router-link tag="a" :to="item.router.name"  >
                    {{ item.name }}
                 </router-link>
               </li>
             </ul>
          </li> 
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
  import Const from '@/common/Const';
  import { mapActions, mapGetters } from 'vuex';
  import { getLayoutPath } from '@/routes';
   
  export default {
    components: {
    },
    computed: {
      ...mapGetters(['instrument', 'indices'])
    },
    watch: {
      indices(){

        let indicesGuide = () => {
          return window._.chain(this.indices).filter(el=>el.root_symbol)
                .orderBy(['id'], ['asc'])
                .groupBy("root_symbol").map(function (indice, value){

            return {
              icon: '',
              isHasPermission: false,
              items2: window._.chain(indice).orderBy(['symbol'], ['asc']).map((key, value) => ({
                type: 'item',
                icon: '',
                name: key.symbol,
                router: {
                  name: getLayoutPath({name: 'Indice Series Index'}, true) + key.symbol
                }
              })).value(),
              name: value + ' (' + this.$t(`${Array.join(['margin.symbol', value.toLowerCase(), 'coinname'], ".")}`) + ')',
              type: 'tree2'
            };
          }.bind(this)).value();
        };

        if (!this.loadedIndice) {
          const indices = indicesGuide();
          if (indices.length > 0) {
            this.loadedIndice = true;
            this.slideMenuItems[3].items = indices;
          }
        }
      },
      instrument() {
        let contracts = () => {
          return _.chain(this.instrument).filter(el=>el.root_symbol).groupBy("root_symbol").map(function (contract, value){
            const getRoutePath = (guide=false, rootSymbol, symbol) => {
              const data = {
                true: getLayoutPath({name: 'Contract Series Guide Index'}, true) + rootSymbol.toLowerCase(),
                false: getLayoutPath({name: 'Contract Specification Index'}, true) + symbol.toLowerCase()
              }
              return data[guide.toString()]
            }

            contract.unshift({
              isGuide: true,
              symbol: this.$t('margin.symbol.guide_title', {coinname: this.$t('margin.symbol.'+value.toLowerCase()+'.coinname'), symbol: value}),
              root_symbol: value
            });
            return {
              icon: '',
              isHasPermission: false,
              items2: window._.chain(contract).orderBy(['isGuide', 'symbol'], ['asc', 'asc']).map((key, value) => ({
                type: 'item',
                icon: '',
                name: key.symbol,
                router: {
                  name: getRoutePath(key.isGuide, key.root_symbol, key.symbol)
                }
              })).value(),
              name: value + ' (' + this.$t(`${Array.join(['margin.symbol', value.toLowerCase(), 'coinname'], ".")}`) + ')',
              type: 'tree2'
            };
          }.bind(this)).value();
        };

        if (!this.loadedInstrument) {
          const instruments = contracts();
          // console.log('Load Instruments:', instruments);
          if (instruments.length > 0) {
            this.loadedInstrument = true;
            this.slideMenuItems[2].items = instruments;
          }
        }
      }
    },
    data() {
      return {
        slideMenuItems: Const.MENU_CONTRACTS,
        ShowSubMenu: true,
        selectedItem: null,
        loadedIndice: false,
        loadedInstrument: false,
      }
    },
    methods: {
      ...mapActions(['getInstrument', 'getActiveIndices']),
      showSubMenu(item) {
        if (this.selectedItem && this.selectedItem === item) {
          this.selectedItem = null;
          return;
        }
        this.selectedItem = item;
      },
    },
    created() {},
    mounted() {
      this.getInstrument();
      this.getActiveIndices();
      this.$on('UPDATED_LOCALE', () => {
        this.loadedIndice = false;
        this.loadedInstrument = false;
        this.getInstrument();
        this.getActiveIndices();
      });
    }
  } 
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  #temp_nav_slider {
    .header_nav_slider {
      border-bottom: 1px solid rgba(207, 207, 207, 0.5);
      line-height: 25px;
      font-size: $font-title-size-small;
      padding-left: 40px;
      color: $color-eden;
      font-family: $font-family-roboto-medium;
      h2 {
        margin: 20px 0px 20px 0px;
        line-height: 25px;
        font-size: $font-title-size-small;
      }
    }
    #nav_slider {
      padding: 20px 0px;
      .item_navbar_right {
        margin-bottom: 15px;
        .title_menu {
          display: block;
          width: 100%;
          color: $color-grey-dark;
          font-size: $font-medium;
          font-family: $font-family-roboto-medium;
          line-height: 18px;
          margin-bottom: 8px;
          padding-left: 40px;
        }
        .treeview-menu {
          > li {
            display: block;
            width: 100%;
            cursor: pointer;
            > a,
            > span {
              font-size: $font-small;
              color: $color-grey-dusty;
              font-family: $font-family-roboto;
              line-height: 17px;
              padding: 5px 15px 5px 40px;
              display: block;
              cursor: pointer;
              &:hover {
                background-color: $color-blue-custom;
                color: $color-white;
              }
              &.router-link-active {
                background-color: $color-jungle-green;
                color: $color-white;
              }
            }
          }
        }
        .treeview-menu2 {
          display: none;
          &.intro {
            display: block;
          }
          > li {
            display: block;
            width: 100%;
            cursor: pointer;
            > a,
            > span {
              font-size: $font-small;
              color: $color-grey-dusty;
              font-family: $font-family-roboto;
              line-height: 17px;
              padding: 5px 15px 5px 50px;
              display: block;
              cursor: pointer;
              &:hover {
                background-color: $color-blue-custom;
                color: $color-white;
              }
              &.active {
                background-color: $color-jungle-green;
                color: $color-white;
              }
              &.router-link-active {
                background-color: $color-jungle-green;
                color: $color-white;
              }
            }
          }
        }
        .title_menu2 {
          font-size: $font-small;
          color: $color-grey-dusty;
          font-family: $font-family-roboto;
          line-height: 17px;
          padding: 5px 15px 5px 40px;
          display: block;
          cursor: pointer;
          &:before {
            content: "\e904";
            font-family: 'icomoon' !important;
            font-size: 9px;
            float: left;
            margin-right: 7px;
            width: 5px;
            line-height: 18px;
            position: relative;
            transition: all 0.3s;
          }
          &:hover {
            background-color: $color-blue-custom;
            color: $color-white;
          }
          &.active {
            &:before {
              -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
              top: -2px;
              transition: all 0.3s;
            }
          }
        }
      }
    }
  }
</style>