import Vue from "vue";
import Vuex from "vuex";
import createLogger from "@/common/logger-vuex";
import createPersistedState from "vuex-persistedstate";

import Cookies from "js-cookie";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

let rootStore = {
  state: {
    masterdata: localStorage.getItem('masterdata') ? JSON.parse(localStorage.getItem('masterdata')) : undefined,
    pairCoins: localStorage.getItem('pairCoins') ? JSON.parse(localStorage.getItem('pairCoins')) : {},
    userThemeMode: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
    isAuthentication : Cookies.get("access_token", { domain: process.env.VUE_APP_PUBLIC_URL }) || '',
    lang: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) || "en",
    loadingPage: false,
    recallBalances: false,
    isShowBalances: JSON.parse(Cookies.get("hide-balance", { domain: process.env.VUE_APP_PUBLIC_URL }) || '{}')
  },
  getters: {
    getLang: (state) => state.lang,
    isShowBalances: (state) => state.isShowBalances
  },
  mutations: {
    setMasterdata: (state, payload) => {
      state.masterdata = payload
    },
    setPairCoins: (state, payload) => {
      state.pairCoins = payload
    },
    setUserThemeMode: (state, payload) => {
      state.userThemeMode = payload
    },
    setLang: (state, payload) => {
      state.lang = payload
    },
    setLoadingPage: (state, payload) => {
      state.loadingPage = payload
    },
    setRecallBalances: (state, payload) => {
      state.recallBalances = payload
    },
    setAuthentication: (state, payload) => {
      state.isAuthentication = payload
    },
    setShowBalances: (state, payload) => {
      state.isShowBalances = payload
    }
  },
  actions: {
    setUserThemeMode({commit}, mode) {
      commit('setUserThemeMode', mode)
    },
    setLang({ commit }, lang) {
      commit('setLang', lang)
    },
    setLoadingPage({ commit }, status) {
      commit('setLoadingPage', status)
    },
    setRecallBalances({ commit }, balance) {
      commit('setRecallBalances', balance)
    },
    setAuthentication({ commit }, auth) {
      commit('setAuthentication', auth)
    },
    setShowBalances({ commit }, balances) {
      commit('setShowBalances', balances)
    }
  },
}

const resources = require.context('.', true, /^\.\/(?!index\.js$)/);
resources.keys().map(file => {

  const data = resources(file);

  const storeData = data.default || data;
  const { actions, mutations, getters, state, ...otherDataStore } = storeData;

  rootStore = {
    ...rootStore,
    actions: {
      ...rootStore.actions,
      ...actions,
    },
    getters: {
      ...rootStore.getters,
      ...getters,
    },
    mutations: {
      ...rootStore.mutations,
      ...mutations,
    },
    state: {
      ...rootStore.state,
      ...state,
    },
  }

});

const presistedOptions = {
  paths: [
    'currentSymbol',
    'currentIndexSymbol',
    'common',
    'orderbook',
    'user',
  ]
}
const plugins = [createPersistedState(presistedOptions)];
const store = new Vuex.Store({
  ...rootStore,
  strict: debug,
  plugins: debug ? [createLogger(), ...plugins] : plugins,
  // state: {
  //   user: {},
  //   instrument: {},
  //   settlement: {},
  //   funding: {},
  //   insurance: {},
  //   compositeIndex: {},
  //   localLang:{}, 
    
  // },
  
});

export default store;