<template>
  <div v-if="items.length > 0" class="infinite-scroll" @scroll="handleScroll">
    <ListTransactionHistory
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :index="index"
      :coinImages="coinImages"
      :isActiveRow="isActiveRow"
      :activeRow="activeRow"
    />
  </div>
  <div v-else>
    <div class="icon-no-data-1 text-center w-100">
      <img
        class="icon-search"
        :src="
          require(`@/assets/images/landing/icon-nodata${
            theme === 'light-theme' ? '' : '-dark'
          }.svg`)
        "
      />
      <div
        class="text-no-data w-100 text-center"
        v-if="currentTab === 'deposit'"
      >
        {{ $t("funds.transaction_history.no_deposit_data") }}
      </div>
      <div
        class="text-no-data w-100 text-center"
        v-if="currentTab === 'withdraw'"
      >
        {{ $t("funds.transaction_history.no_deposit_data") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListTransactionHistory from "@/components/spotexchange_pages/wallet/ListTransactionHistory.vue";

export default {
  name: "ListHistoryMobile",
  components: {
    ListTransactionHistory,
  },
  props: {
    coinImages: {
      type: Object,
      default: () => ({}),
    },
    isActiveRow: {
      type: Number,
    },
    activeRow: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    currentTab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeMenu: false,
      items: [],
      loading: false,
      currentPage: 1,
      nextPageUrl: null,
      noMoreData: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  watch: {
    currentTab: {
      immediate: true,
      handler() {
        this.currentPage = 1;
        this.loading = false;
        this.noMoreData = false;
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData() {
      if (this.loading || this.noMoreData) return;

      this.loading = true;
      try {
        const params = {
          type: this.currentTab,
          page: this.currentPage,
          limit: 10,
        };

        const response = await this.getData(Object.assign(params));
        console.log("response>>>>>", response);

        if (response && response.data.data) {
          this.items = [...this.items, ...response.data.data];
          this.currentPage = response.data.current_page + 1;
          this.nextPageUrl = response.data.next_page_url;
        }

        if (!this.nextPageUrl) {
          this.noMoreData = true;
        }
      } catch (error) {
        console.error("error>>>>:", error);
      } finally {
        this.loading = false;
      }
    },

    handleScroll(event) {
      const container = event.target;
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - 10
      ) {
        this.fetchData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.infinite-scroll {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100vh;
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.infinite-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari và Edge */
}

.text-no-data {
  color: var(--text-no-data) !important;
  opacity: 0.8 !important;
}
</style>
