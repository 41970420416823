import BaseRequest from '../base/BaseRequest';

export default class ChartsRequest extends BaseRequest {
  percent(params) {
    let url = `/mam/percent-charts`;
    return this.get(url, params);
  }

  balance(params) {
    let url = `/mam/balance-charts`;
    return this.get(url, params);
  }
}
