<template>
  <div id="order-group">
    <navi-tab
      :size-open-order="size"
      @action-navi-tab="actionFromNaviTab"
      ref="naviTab"
    />
    <div
      class="order-content"
      :class="!this.isAuthenticated ? 'pos-relative' : ''"
    >
      <template v-if="!this.isAuthenticated">
        <div class="textAuthen position-absolute">
          <router-link
            class="but_get_log"
            :to="{
              name: 'Login',
              params: { customRedirect: 'Margin Exchange' },
            }"
            >{{ $t("order.order_form.login") }}</router-link
          >
          <span> {{ $t("order.order_form.or") }} </span>
          <router-link class="but_get_log" :to="{ name: 'Register' }">{{
            $t("order.order_form.signup")
          }}</router-link>
          <span> {{ $t("order.order_form.trade") }}</span>
        </div>
      </template>
      <template v-else>
        <TradeOpenOrder
          @dataFromChild="handleDataFromChild"
          v-if="currentTab && currentTab.id === commonConst.OPEN_ORDER"
          :ref="commonConst.OPEN_ORDER"
          :coinCode="coin"
          :currencyCode="currency"
          :openOrderLayout="'trade'"
           @action-navi-tab="actionFromNaviTab"
        />
        <TradeOrderHistory
          v-if="currentTab && currentTab.id === commonConst.ORDER_HISTORY"
          :ref="commonConst.ORDER_HISTORY"
          :coinCode="coin"
          :currencyCode="currency"
          :openOrderLayout="'trade'"
           @action-navi-tab="actionFromNaviTab"
        />
        <TradeTradeHistory
          v-if="currentTab && currentTab.id === commonConst.TRADE_HISTORY"
          :ref="commonConst.TRADE_HISTORY"
          :coinCode="coin"
          :currencyCode="currency"
          :openOrderLayout="'trade'"
           @action-navi-tab="actionFromNaviTab"
        />
        <funds
          v-if="currentTab && currentTab.id === commonConst.FUNDS"
          :ref="commonConst.FUNDS"
           @action-navi-tab="actionFromNaviTab"
        />
        <sub-accounts
          v-if="currentTab && currentTab.id === commonConst.SUB_ACCOUNTS"
          :ref="commonConst.SUB_ACCOUNTS"
        />
      </template>
    </div>
  </div>
</template>

<script>
import NaviTab from "../common/NaviTab.vue";
import OpenOrder from "./OpenOrder.vue";
import OrderHistory from "./OrderHistory.vue";
import TradeHistory from "./TradeHistory.vue";
import SubAccounts from "../exchange/basic/sub-accounts/SubAccounts.vue";
import Funds from "./Funds.vue";
import COMMON_CONST from "@/common/Const";
import TradeOpenOrder from "./TradeOpenOrder.vue";
import TradeOrderHistory from "./TradeOrderHistory.vue";
import TradeTradeHistory from "./TradeTradeHistory.vue";

export default {
  components: {
    NaviTab,
    OpenOrder,
    OrderHistory,
    TradeHistory,
    SubAccounts,
    Funds,
    TradeOpenOrder,
    TradeOrderHistory,
    TradeTradeHistory,
  },
  data() {
    return {
      currentTab: null,
      commonConst: COMMON_CONST,
      coin: this.$route.query.coin || COMMON_CONST.DEFAULT_COIN,
      currency: this.$route.query.currency || COMMON_CONST.DEFAULT_CURRENCY,
      isHide: false,
      isAuthenticated: window.isAuthenticated,
      size: 0,
    };
  },
  watch: {
    $route(to, from) {
      this.currency = this.$route.query.currency || this.commonConst.USD;
      this.coin = this.$route.query.coin || this.commonConst.BTC;
      this.hideOtherPair();
    },
  },
  methods: {
    handleDataFromChild(data) {
      this.size = data;
    },
    getSocketEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
      this.isHide = false;
      if (this.$refs) {
        this.hideOtherPair();
      }
    },
    actionFromNaviTab(data) {
      if (data.action === this.commonConst.ACTION_CHANGE_ORDER_TAB) {
        this.openOrderNaviChange(data.item);
      } else {
        this.hideOtherPair(data.isCheck);
      }
    },
    openOrderNaviChange(objectTab) {
      this.currentTab = objectTab;
    },
    hideOtherPair(isCheck) {
      this.isHide = isCheck === undefined ? this.isHide : isCheck;

      switch (this.currentTab.id) {
        case this.commonConst.OPEN_ORDER:
        case this.commonConst.ORDER_HISTORY:
        case this.commonConst.TRADE_HISTORY:
          if (
            !(
              this.$refs &&
              this.$refs[this.currentTab.id] &&
              this.$refs[this.currentTab.id].$refs &&
              this.$refs[this.currentTab.id].$refs.datatable
            )
          ) {
            return;
          }

          if (!this.isHide) {
            this.$refs[this.currentTab.id].$refs.datatable.filter({
              hide: "no_hide",
            });
            return;
          }
          const params = {
            coin: this.coin,
            currency: this.currency,
            hide: "hide",
          };
          this.$refs[this.currentTab.id].$refs.datatable.filter(params);
          break;
        default:
          this.$refs[this.commonConst.FUNDS].hideSmallPair(isCheck);
          break;
      }
    },
  },
  mounted() {
    this.currentTab = COMMON_CONST.OPEN_ORDER_NAVI[0];
    if (this.$route.query.fromConvert) {
      const orderHistory = this.$refs.naviTab.naviList[1];
      this.$refs.naviTab.selectNavi(orderHistory);
      window.scrollTo(0, document.body.scrollHeight);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  .textAuthen {
    background: $color-dark !important;
    color: $color-storm-grey !important;
  }
}
#order-group {
  .order-content {
    height: calc(100% - 40px);
    min-height: 350px;
    div {
      div {
        height: 100%;
      }
    }

    .textAuthen {
      text-align: center;
      font-size: 18px;
      color: $color-grey-dark;
      background: $color-gray-alabaster;
      line-height: 20px;
      //padding: 80px 0 100px 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;

      a {
        font-family: $font-inter-bold;
        color: $color-caribbean-green;
      }
    }
  }
}
.order-content header {
  min-width: 100% !important;
}
//@media only screen and (max-width: 992px) {
//  #order-group {
//      .order-content{
//        overflow-x: auto;
//      }
//    }
//}
</style>
