<template>
  <div id="modal">
    <modal :name="modalName" width="560" :enableClose="false">
      <template slot="body">
        <div class="d-flex justify-content-center img-success">
          <img  :src="require(`@/assets/images/icon/send-mail-success${userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)">

        </div>
        <div class="title-success">{{ $t("convert.title-successful") }}</div>
        <div class="title-coint">From {{fromValue}} {{type ? currency : coin}}</div>
<!--        <div class="calculate-convert w-100">-->
<!--          <div class="d-flex w-100 justify-content-between">-->
<!--            <div class="left d-flex justify-content-center">Rate</div>-->
<!--            <div class="right">1 {{coin}} = {{rate | formatCurrencyAmount2(currency?.toLowerCase(), '0') }} {{currency}}</div>-->
<!--          </div>-->
<!--          <div class="d-flex w-100 justify-content-between">-->
<!--            <div class="left">Inverse Rate</div>-->
<!--            <div class="right">1 {{currency}} = {{1/rate | formatCurrencyAmount2(coin?.toLowerCase(), '0')}} {{coin}}</div>-->
<!--          </div>-->
<!--          <div class="d-flex w-100 justify-content-between">-->
<!--            <div class="left">You received</div>-->
<!--            <div class="right">{{received | formatFeeAmount(type ? coin?.toLowerCase() : currency?.toLowerCase(), '0')}} {{type ? coin : currency}}</div>-->
<!--          </div>-->
<!--          <div class="d-flex w-100 justify-content-between">-->
<!--            <div class="left" style="margin: 0">Payment Method</div>-->
<!--            <div class="right"  style="margin: 0">Spot wallet</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="title-spent">-->
<!--          <span v-html="$t('convert.check-your-convert-order')"></span>-->
<!--        </div>-->
        <div class="box-button d-inline-flex justify-content-between">
       
            <router-link to="/funds/spot-wallet" >
              <button class="btn btn-go-wallet">
                {{ $t("convert.go-to-wallet") }}
              </button>
            </router-link>
        
          <button class="btn btn-ok" @click="closeModal">
            {{ $t("common.action.ok") }}
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";
import {mapState} from "vuex";
import rf from "@/request/RequestFactory";

export default {
  name: "SuccessfulModal",
  components: {
    Modal,
  },
  data() {
    return {
      modalName : 'SuccessfulModal',
      spent: 0,
      rate: 0
    }
  },
  props: {
    received : {
      type: Number,
      default : undefined,
    },
    currency: {
      type: String,
      default : 'usd'
    },
    coin: {
      type: String,
      default : 'btc'
    },
    type: {
      type: Boolean,
      default : false
    },
    orderId : {},
    fromValue: {
      type: Number,
      default: 0,
    },
    closeModalSuccess: {type: Function},
  },
  computed : {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  watch: {
    // orderId: {
    //   handler(newValue) {
    //     if (this.orderId) {
    //       setTimeout(() => {
    //         this.fetchData(newValue);
    //       }, 500)
    //     }
    //   },
    //   immediate: true
    // }
  },

  methods: {
    getSocketEventHandlers() {
      return {
        OrderChanged: this.onOrderChanged,
      }
    },
    onOrderChanged(data) {
      if (data && this.orderId) {
        if(this.orderId === data.order.id) {
          console.log(data)
          this.rate = data.order.executed_price;
                 this.spent = this.type ? data.order.executed_price * data.order.executed_quantity : data.order.executed_quantity;
        }
      }
    },
    closeModal() {
      window.CommonModal.hide('SuccessfulModal');
      this.closeModalSuccess();
    },
    // async fetchData(data) {
    //   rf.getRequest('OrderRequest').getOrderHistory()
    //       .then(res => {
    //         const trade = res?.data?.data.find(res => res.id === this.orderId);
    //         this.rate = trade?.executed_price;
    //         this.spent = this.type ? trade?.executed_price * trade?.executed_quantity : trade?.executed_quantity;
    //       });
    // }
  },
}
</script>

<style lang="scss" scoped>
#modal {
  ::v-deep {
    .modal-dialog {
      max-width: 90%;
    }
  }
}
.btn-ok {
  //margin-top: 25px;
  width: 48%;
  background: $color-caribbean-green;
  border-radius: 8px;
  height: 50px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $dark-1;
  font-size: 18px;

  &:hover, &:active{
    background: $color-aquamarine ;
    color: $color-grey-dark;
  }
  &:disabled {
    background: $color-aero-blue;
  }
}

.box-button{
  gap: 24px;
  width: 100%;
  a {
    width: 50%;
  }
  .btn-ok {
    width: 50%;
  }
}

.btn-go-wallet {
  width: 100%;
  //margin-top: 25px;
  background: var(--monas-convert-background-wallet);
  border-radius: 8px;
  height: 50px;
  padding: 10px;
  color: var(--text-primary-color);
  font-size: 18px;

  &:hover{
    color: $color-white;
    background-color: var(--bgr-btn-hover);
  }
  &:disabled {
    background: $color-gray-alto;
  }
  a {
    color: var(--text-primary-color);
  }
}

#modal {
  font-family: $font-inter-regular;
}
  .img-success {
    padding-top: 40px;
    padding-bottom: 30px;
  }
.calculate-convert {
  margin-top: 25px;
  font-family: $font-inter-regular;
  border-radius: 8px;
  background: var(--monas-convert-price-background);
  padding: 24px;

  .left {
    font-weight: 400;
    font-size: 16px;
    color: var(--monas-convert-color-tab);
    margin-bottom: 12px;
  }
  .right {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-primary-color);
  }
  .left2 {
    font-weight: 400;
    font-size: 20px;
    color: var(--text-primary-color);
    margin-bottom: 12px;
  }
  .right2 {
    font-weight: 600;
    font-size: 20px;
    color: var(--text-primary-color);
  }
  hr {
    margin: 16px 0;
    border-color: var(--monas-convert-hr-color);
  }
}
  div#infor:hover div#content-infor {
    display: block;
  }

  div#infor2:hover div#content-infor2 {
    display: block;
  }
  .information {
    .content {
      width: 227px;
      height: auto;
      z-index: 1;
      right: 0;
      top: 24px;
      background: rgba(25, 25, 25, 0.8);
      border-radius: 8px;
      padding: 10px;
      color: white;
      display: none;
    }

    .infor-light {
      display: block;
    }
    .infor-dark {
      display: none;
    }

  }
  .title-coint {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    color: $color-caribbean-green;
    margin-bottom: 40px;
  }
  .title-spent {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--monas-convert-modal-spent);
    margin: 16px auto 8px;
    // max-width: 400px;
    a {
      color: $color-caribbean-green;
    }
  }
  .title-success {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 16px;
    text-align: center;
    color: var(--text-primary-color);
    line-height: 40px;
  }
#modal {
  font-family: $font-inter-regular;

  ::v-deep {
    .modal-body {
      padding: 0 30px 40px;
    }
    .modal-footer {
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .box-button{
    gap: 16px;
  }
  #modal {
    ::v-deep {
      .modal-body {
        padding: 0 16px 30px 16px;
      }
    }
  }

  .img-success {
    padding-top: 32px;
  }
  .title-success {
    font-size: 20px;
    margin-bottom: 8px;
    line-height: 24px;
  }
  .title-spent {
    font-size:14px;
  }
  .title-coint {
    font-size: 20px;
    margin-bottom: 0;
  }
  .calculate-convert {
    padding: 24px 16px;
  }

  .btn-go-wallet, .btn-ok {
    margin-top: 30px;
    font-size: 16px;
  }
  .calculate-convert {
    .left, .right {
      font-size: 14px;
    }
  }
  .asset-title {
    font-size: 14px!important;
  }
}
</style>