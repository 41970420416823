<template>
  <div class="container_withdraw_usd clearfix">
    <div class="title">{{ $t('menu.withdraw_usd') }}</div>
    <div class="clearfix"></div>
    <div class="group_withdraw_usd row">
      <div class="left_withdraw_usd col-12 col-md-12 col-lg-5 col-xl-5">
      <div class="box_left1">
        <div class="list-balance">
          <p class="item-balance">
            <span>{{ $t('funds.withdrawals.total_balance') }}</span>
            <strong
              class="text-right"
            >{{ balance.balance | formatCurrencyAmount(currencyName, '0') }} {{ $t("currency.usd.shortname")}}</strong>
          </p>
          <p class="item-balance">
            <span>{{ $t('funds.balances.in_use') }}</span>
            <strong
              class="text-right"
            >{{balance.in_order | formatCurrencyAmount(currencyName, '0')}} {{ $t("currency.usd.shortname")}}</strong>
          </p>
          <p class="item-balance">
            <span>{{ $t('funds.withdrawals.available_balance') }}</span>
            <strong
              class="text-right"
            >{{ balance.available_balance | formatCurrencyAmount(currencyName, '0') }} {{ $t("currency.usd.shortname")}}</strong>
          </p>
        </div>
      </div>
      <div v-show="!isSubmitted" class="minimum">
          {{ $t('withdrawal_usd.minimum') }}: {{ getMinimum(withdrawLimit.minium_withdrawal , withdrawLimit.fee) }} <span>{{withdrawLimit.currency}}</span>
      </div>
      <div class="clearfix"></div>
      <div v-if="isLoaded && isWithdrawEnable">
        <div v-if="!isSubmitted" class="box_left2">
          <div class="deposit-content">
            <div class="form-group address-box clearfix">
              <div class="address_for fc">{{$t('funds.withdraw_usd.bank_info')}}</div>
              <div class="group_input_withdraw">
                <input-required-localize
                  ref="bank_name"
                  field="bank_name"
                  class="input_w_withdraw"
                  :placeholder="$t('funds.withdraw_usd.bank_name')"
                  v-model="params.bank_name"
                  @focus="resetErrors"
                />

                <input-required-localize
                  ref="bank_branch"
                  field="bank_branch"
                  :placeholder="$t('funds.withdraw_usd.bank_branch')"
                  v-model="params.bank_branch"
                  @focus="resetErrors"
                />
              </div>
            </div>
            <div class="form-group address-box clearfix">
              <div class="address_for">{{$t('funds.withdraw_usd.account_info')}}</div>
              <div class="group_input_withdraw">
                <input-required-localize
                  ref="account_name"
                  field="account_name"
                  :placeholder="$t('funds.withdraw_usd.account_name')"
                  v-model="params.account_name"
                  @focus="resetErrors"
                />
                <input-account-no
                  ref="account_no"
                  field="account_no"
                  :placeholder="$t('funds.withdraw_usd.account_no')"
                  v-model="params.account_no"
                  @focus="resetErrors"
                />
              </div>
            </div>
            <div class="form-group address-box clearfix">
              <div class="wrapper_all">
                <div class="wrapper wrapperAmount">
                  {{$t('funds.withdraw_usd.amount')}}
                  <span>{{ currencyName | uppercase }}</span>
                </div>
                <div class="address_for2 right_add_for2">
                  <span>{{ $t('funds.withdrawals.withdrawal_limit_24') }}</span>
                  <span
                    class="txt-bold"
                  >{{withdrawDaily | formatCurrencyAmount(currencyName, '0')}}/{{ withdrawLimit.daily_limit | formatUsdAmount}} {{ currencyName | uppercase }}</span>
                </div>
              </div>
              <div class="clearfix"></div>
              <InputAmount1
                ref="amount"
                v-model="params.amount"
                coin="usd"
                :withdrawLimit="withdrawLimit"
                :balance="balance"
                :withdrawDaily="withdrawDaily"
                @focus="resetErrors"
              />
            </div>
            <div class="form-group wrapper spaceMobile quantity clearfix">
              <span class="address_for2 space left_add_for2 121">
                {{ $t('funds.withdrawals.transaction_fee') }}:
                <span
                  class="txt-bold"
                >{{ withdrawLimit.fee | toNumber}}</span>
              </span>
              <span class="address_for2 space right_add_for2">
                {{ $t('funds.withdrawals.you_will_get') }}:
                <span class="txt-bold"> {{ Number(params.amount) <= 0 ? '0.00' : Number(params.amount).toLocaleString()}}</span>
              </span>
            </div>
            <div class="form-group address-box clearfix">
              <template v-if="isSubmitting">
                <button
                  type="submit"
                  class="btn btn-submit"
                >{{ $t('funds.withdrawals.loading') }}</button>
              </template>
              <template v-else>
                <button
                  type="submit"
                  class="btn btn-submit"
                  @click="withdraw()"
                >{{ $t('funds.withdrawals.submit') }}</button>
              </template>
            </div>
          </div>
        </div>
        <div class="submited-withdraw" v-else>
          <div class="warning-title">{{ $t('withdrawUSD.note') }}</div>
          <div class="d-flex">
            <div class="text-left">
              <div>{{ $t('order.open_order.amount') }}</div>
              <div>{{ $t('bank_setting.bank_name') }}</div>
              <div>{{ $t('bank_setting.bank_branch') }}</div>
              <div>{{ $t('bank_setting.account_name') }}</div>
              <div>{{ $t('bank_setting.account_no') }}</div>
            </div>
            <div class="text-right">
              <div>{{getParams().amount | amountToPositiveNumber}}</div>
              <div>{{getParams().bank_name}}</div>
              <div>{{getParams().bank_branch}}</div>
              <div>{{getParams().account_name}}</div>
              <div>{{getParams().account_no}}</div>
            </div>
          </div>
          <button class="btn btn-submit" @click="makeAnotherTransaction()">
            {{ $t("funds.deposit_usd.other_deposit") }}
          </button>



        </div>
        <!-- <div class="box_left3">
          <div class="deposit-content">
            <div class="form-group">
              <p class="txt-bold">{{$t('funds.withdraw_usd.notes')}}</p>
              <div class="clearfix"></div>
              <p class="txt-tit" v-html="$t('funds.withdraw_usd.guide.text1')"></p>
            </div>
          </div>
        </div>-->
      </div>
      <div v-else class="alert alert-danger text-center space-top" role="alert">
        {{$t('funds.disable_coin_msg')}}
      </div>
    </div>
    <div class="right_withdraw_usd col-12 col-md-12 col-lg-7 col-xl-7">
      <div class="box_right1">
        <withdraw-transaction/>
      </div>
    </div>
    </div>
   

    <otp-modal ref="otp_modal" :submit="createTransaction" :params="getParams()" :is-disable-button="isDisableButton" :contact_email="siteSetting.contact_email"/>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import BigNumber from "bignumber.js";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import InputRequired from "@/components/shared_components/funds/withdraw_usd/InputRequired";
import InputRequiredLocalize from "@/components/shared_components/funds/withdraw_usd/InputRequiredLocalize";
import InputAccountNo from "@/components/shared_components/funds/withdraw_usd/InputAccountNo";
import WithdrawTransaction from "@/components/shared_components/funds/withdraw_usd/WithdrawTransaction";
import OtpModal from "@/components/shared_components/funds/common/OtpModal";
import InputAmount1 from "@/components/shared_components/funds/common/InputAmount1";
import {mapState} from "vuex";

export default {
  components: {
    WithdrawTransaction,
    InputRequired,
    InputRequiredLocalize,
    InputAccountNo,
    OtpModal,
    InputAmount1
  },
  data() {
    return {
      currencyName: "usd",
      balance: {},
      params: {},
      isSubmitting: false,
      securitySetting: {},
      withdrawLimit: {},
      withdrawDaily: "0",

      modalName: "OtpModal",

      isLoaded: false,
      isWithdrawEnable: false,
      isDisableButton: false,
      siteSetting: {},
      totalInOrder: 0,
      isSubmitted: false,
    };
  },
  mixins: [RemoveErrorsMixin],
  computed: {
    ...mapState({
      masterdata: state => state.masterdata,  
    }),
    isPassSecurityLevel3() {
      return this.withdrawLimit.security_level > 2;
    }
  },
  methods: {
    getMinimum(minimum ,fee ) {
      const result = Number(minimum) + Number(fee);
      return result;
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    validateDepositWithdraw() {
      rf.getRequest("MasterdataRequest")
        .getAll()
        .then(res => {
          const setting = window._.filter(
            res.coins_confirmation,
            item => item.coin === "usd"
          );
          if (!window._.isEmpty(setting)) {
            this.isWithdrawEnable = setting[0].is_withdraw;
          }
          this.isLoaded = true;
        })
        .catch(e => {
          this.isLoaded = true;
        })
    },
    getUsdBalance() {
      this.balance = {};
      rf.getRequest("UserRequest")
        .getDetailsUsdBalance("usd")
        .then(res => {
          let total = 0;
          this.totalInOrder = 0;
          if(res.data.exchange && res.data.exchange.balance && res.data.exchange.available_balance) {
            this.totalInOrder = new BigNumber(`${this.totalInOrder || 0}`).add(new BigNumber(`${res.data.exchange.balance || 0}`));
            total = new BigNumber(`${total || 0}`).add(new BigNumber(`${res.data.exchange.balance || 0}`));
          }
          if(res.data.margin && res.data.margin.balance && res.data.margin.available_balance) {
            this.totalInOrder = new BigNumber(`${this.totalInOrder || 0}`).add(new BigNumber(`${res.data.margin.balance || 0}`));
            total = new BigNumber(`${total || 0}`).add(new BigNumber(`${res.data.margin.balance || 0}`));
          }
          if(res.data && res.data.main && res.data.main.balance && res.data.main.available_balance) {
            this.balance = {
              ...this.balance,
              'balance': new BigNumber(`${res.data.main.balance || 0}`).add(new BigNumber(`${total || 0}`)),
              'available_balance': new BigNumber(`${res.data.main.available_balance || 0}`)
            }
          }
          this.calculateBalanceInOrder();
        });
    },

    calculateBalanceInOrder() {
      return rf
        .getRequest("TransactionRequest")
        .getTotalUsdPendingWithdraw()
        .then(res => {
          const totalUsdPendingWithdraw = res.data.total || "0";
          const inOrder = new BigNumber(`${this.totalInOrder || 0}`)
            .add(`${totalUsdPendingWithdraw}`)
            .toString();
          this.$set(this.balance, "in_order", inOrder);
        });
    },

    getUsdWithdrawDaily() {
      rf.getRequest("UserRequest")
        .getUsdWithdrawDaily()
        .then(res => {
          this.withdrawDaily = `${res.data || 0}`;
        });
    },

    onBalanceUpdated(newBalance) {
      // this.balance.balance = newBalance && newBalance.total_balance
      // this.balance.available_balance = newBalance && newBalance.available_balance
      // this.calculateBalanceInOrder();
      this.getUsdBalance();
      this.getUsdWithdrawDaily();
    },

    onOtpUpdated() {
      this.getSecuritySettings();
      this.getSettingWithdrawLimit(false);
    },

    getSocketEventHandlers() {
      return {
        MainBalanceUpdated: this.onBalanceUpdated,
        OtpUpdated: this.onOtpUpdated
      };
    },

    validate() {
      this.resetError();

      // if (new BigNumber(`${this.withdrawLimit.daily_limit || 0}`).comparedTo(0) === 0) {
      //   this.errors.add('amount', window.i18n.t('funds.withdrawals.errors.not_allowed_withdraw'));
      // }
      this.$refs.bank_name.validate();
      this.$refs.bank_branch.validate();
      this.$refs.account_name.validate();
      this.$refs.account_no.validate();
      this.$refs.amount.validate();

      if (this.errors.count() > 0) {
        return false;
      }
      return true;
    },

    async withdraw() {
      await this.getSettingWithdrawLimit(false);
      if (!this.validate()) { 
        return false;
      }
      if (!this.securitySetting.otp_verified || !this.isPassSecurityLevel3) {
        return Message.error(this.$t('funds.withdrawals.errors.kyc_usd'))
      }
      CommonModal.show(this.modalName);
    },

    makeAnotherTransaction() {
      this.params = {};
      this.isSubmitted = false;
    },

    getParams() {
      return {
        amount: `-${this.params.amount || "0"}`,
        bank_name: this.params.bank_name,
        bank_branch: this.params.bank_branch,
        account_name: this.params.account_name,
        account_no: this.params.account_no,
        fee:this.withdrawLimit.fee || '0',
        limit : this.withdrawLimit.limit,
        daily_limit : this.withdrawLimit.daily_limit,
        minium_withdrawal : this.withdrawLimit.minium_withdrawal,
      };
    },

    createTransaction(params) {
      this.isDisableButton = true;
      this.isSubmitting = true;
      rf.getRequest("TransactionRequest")
        .withdrawUsd(params)
        .then(res => {
          this.onCreateTransaction(res.data);
          this.$broadcast('TransactionCreated');
          this.isDisableButton = false;
          this.getUsdBalance();
        })
        .catch(error => {
          this.isDisableButton = false;
          this.isSubmitting = false;
          const errors = error.response.data.errors
          if(errors?.otp){
            window._.each(errors, (value, key) => {
              this.errors.add(key? key : 'otp', this.$t(`${value[0].formatUnicorn({values:".jpg, .jpeg, .png"})}`));
            });
          } else if (error.response.status === 422) {
            this.convertRemoteErrors(error);
          } else {
            if( error.response.data.message === 'limit_withdraw') {
              this.errors.add('amount', this.$t(`${error.response.data.message}`));
            } else {
              Message.error(this.$t(`${error.response.data.message}`), {}, {position: 'bottom_left'});
            }
            CommonModal.hide(this.modalName);
          }
        });
    },

    onCreateTransaction(data) {
      this.isSubmitting = false;
      this.getUsdWithdrawDaily();
      CommonModal.hide(this.modalName);
      Message.success(window.i18n.t("funds.withdrawals.success_message"));
      this.isSubmitted = true;
    },

    getSecuritySettings() {
      rf.getRequest("UserRequest")
        .getSecuritySettings()
        .then(res => {
          this.securitySetting = res.data;
        });
    },

    init() {
      this.getUsdBalance();
      this.getUsdWithdrawDaily();
      this.getSecuritySettings();
      this.getSettingWithdrawLimit();
      this.handleMasterData();
    },

    getSettingWithdrawLimit(useCache = true) {
      rf.getRequest("UserRequest")
        .getCurrentUser(useCache)
        .then(res => {
          let securityLevel = res.data.security_level;
          let withdrawLimit = {};
          withdrawLimit =
            window._.find(this.masterdata.withdrawal_limits, wl => {
              return (
                wl.security_level === securityLevel &&
                wl.currency === this.currencyName
              );
            }) || {};
          this.withdrawLimit = withdrawLimit;
        });
    },
    handleMasterData() {
      window._.each(this.masterdata.settings, item => {
        this.siteSetting[item.key] = item.value;
      });
    }
  },

  mounted() {
    this.scrollToTop();
    this.init();
    document.title = this.$t("menu.withdraw_usd") + ` – ${this.tabTitle}`;
  },
  created() {
    this.validateDepositWithdraw();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.wrapper_all {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
   .right_add_for2  {
     font-size: $font-root;
    font-weight: 400;
     span {
      color : var(--color-limit-withdraw);
      margin-right: 4px;
     }
     .txt-bold {
      color: var(--text-primary-color);
     }
   }
  @media screen and (max-width: 768px) {
    display: unset;
     .right_add_for2 {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
     }
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.container_withdraw_usd {
  background: var(--color-background-withdrawls);
  //max-width: 1320px;
  padding: 40px ;
  .title {
    font-size: $font-title-size-medium-2;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin: 0 0 30px 0;
    line-height: 41px;
    color: var(--text-primary-color);
  }
  .group_withdraw_usd {
    justify-content: space-between;
  }
  .left_withdraw_usd {
    max-width: 480px;
    min-height: 860px;
    // background: $row-coin-value-background-color;
    // padding: 20px 0px;
    .box_left1 {
      // padding: 0px 25px;
      margin-bottom: 40px;
      @media screen and (max-width : 768px) {
        margin-bottom: 30px;
      }
      .list-balance {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.79;
        letter-spacing: normal;
        color: $color-grey-dusty;
        display: block;
        .item-balance {
          margin: 0px 0px;
          font-size: 18px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-weight: 400;
            color: var(--color-text-title );
            line-height: 22px;
          }
          strong {
            float: right;
            font-weight: 600;
            color: var(--text-primary-color);
            line-height: 22px;
          }
        }
      }
    }
    .minimum {
      color: $text-color-red;
      font-weight: 400;
      font-size: $font-root;
      line-height: 17px;
      span {
        text-transform: uppercase;
      }
    }
    .box_left2 {
      padding-bottom: 15px;
      .deposit-content {
        .address-box {
          margin-bottom: 0;
          .fc {
            margin-top: 30px;
            @media screen and ( max-width : 768px) {
              margin-top: 8px;
            }
          }
          .address_for {
            color: var(--text-primary-color);
            font-size: 18px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 8px;
            // margin-top:32px;
          }
          .fee {
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $color-grey-dusty;
          }
          .group_input_withdraw {
            div {
              width: calc(100% - (5px + 50%));
              float: left;
             
              &:last-child {
                float: right;
              }   
              @media screen and (max-width: 768px){
                width: 100%;
              }    
            }
          }
        }
        .spaceMobile {
           &::after , &::before {
              content: unset !important;
            }
            margin-top: 20px;
            @media screen and (max-width: 768px){
              margin-top: 0px;
              margin-bottom: 30px !important;
              display: block;
              justify-content: space-between;
            }
        }
        .wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        .address_for2 {
          // float: left;
          color: var(--text-primary-color);
          font-size: 18px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 6px;
          margin-right: 4px;
          .txt-bold {
            font-weight: bold;
            color: var(--text-primary-color);
            }
            @media screen and (max-width: 768px){
              display: flex;
              justify-content: space-between;
            }
          }
          .space {
            @media screen and (max-width: 768px){
              display: block;
            }
          }
        .left_add_for2 {
          // float: left;
          margin-right: 0px;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--text-primary-color);
        }
        .right_add_for2 {
          // float: right;
          margin-right: 0px;
          font-size: 13px;
          color: var(--text-close);
          margin-bottom: 0px;
        }
          @media screen and (max-width: 768px){
            display: block;
            margin-bottom: 6px;
          }
        }
        .wrapperAmount {
          display: unset;
          font-size: 18px;
          font-weight: 400;
          color: var(--text-primary-color);
        }
        .btn-submit {
          width: 100%;
          height: 50px;
          border: 1px solid $color-caribbean-green;
          color: $color-grey-dark;
          font-size: 18px;
          line-height: 21px;
          margin: 0px auto;
          border-radius: 8px;
          background: $color-caribbean-green;
          display: block;
          float: right;

          &:hover, &:active{
            background-color: $color-aquamarine;
            border-color: $color-aquamarine;
          }
        }
        .amount-box {
          .amount-input {
            input {
              background: $background-default;
            }
          }
        }
      }
    }

    .submited-withdraw {
      padding: 20px;
      background: var(--bg-usd);
      border-radius: 8px;

      .warning-title {
        font-family: $font-inter-regular;
        font-size: $font-medium-bigger;
        color: $text-color-red;
        margin-bottom: 24px;

      }
      .text-left {
        font-family: $font-inter-regular;
        font-size: 18px;
        color: var(--text-close);
        margin-bottom: 10px;
        width: 35%;
        white-space: nowrap;
      }
      .text-right{
        font-family: $font-inter-bold;
        font-weight: 600;
        font-size: 18px;
        color: var(--text-primary-color);
        margin-bottom: 10px;
        width: 65%;
      }

      .btn-submit {
        background: $color-aqua-green;
        width: 100%;
        min-height: 50px;
        font-size: 18px;
        color: $color-grey-dark;
        margin-top: 10px;

        &:hover, &:active{
          background: $color-aquamarine ;
          border-color: $color-aquamarine ;
          color: $color-grey-dark;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
        &:disabled {
          background: $color-aero-blue ;
          border-color: $color-aero-blue ;
          color: $color-gray-dark;
        }
      }
    }
    .box_left3 {
      border-top: 1px solid $color-grey-border;
      padding: 0px 25px;
      padding-top: 12px;
      font-size: $font-small;
      color: $color-grey-dusty;
      line-height: 20px;
      .txt-bold {
        font-weight: 700;
        color: $color-grey-dark;
      }
      .txt-tit {
        color: $color-grey-dark;
      }
    }
  }
  .right_withdraw_usd {
    // width: calc(100% - (15px + 50%));
    min-height: 100%;
    // height: calc(100% - 210px);
    max-width: 730px;
    // background-color: $color-white;
  }
  @media screen and (max-width: 1024px){
    .left_withdraw_usd {
      width: 100%;
      max-width: unset;
    }
  }
  @media screen and (max-width: 992px){
    padding: 30px 16px;
    //background: unset;
    .right_withdraw_usd {
      margin-top: 40px;
      max-width: 100%;
    }
  }
}
</style>