import en from './en';
import vi from './vi';
import zh from './zh';
import ja from './ja';
import ko from './ko';
import ru from './ru';
import de from './de';
import es from './es';
import fr from './fr';
import id from './id';

const locales = { en, vi, zh, ja, ko, ru,de,es,fr,id };

export default locales;