<template>
  <div id="referral">

    <div class="content_referral">
      <div class="conainer_referral">

        <!-- <div class="tit_page_referral title-medium-small">{{$t('refferal.title')}}</div> -->
          
        <div class="clearfix"></div>

        <div class="box_qr_referral">
            <div class="box_qr_referral_left">
              <div class="crop_qr_referral">
                <img :src="refferalCode.urlImg" />
              </div>
              <div class="qr_content_referral">
                <div class="w-50 fl-left w100-mb">
                  <div class="id_referral">
                    <span class="tit_id_referral">{{$t('refferal.text6')}}</span>
                    <span class="code_id_referral">{{refferalCode.referrer_code}}</span>
                  </div>
                  <div class="tit_input_link_referran hidden-600">{{$t('refferal.text8')}}</div>
                </div>
                <div class="w-50 fl-left w100-mb">
                  <!-- <div class="rata_referral">
                    <span class="fright">{{$t('refferal.text7')}}</span>
                    <span class="rate_nb_referral">20%</span>
                  </div> -->
                  <div class="society_link">
                    <div class="tit_society_link">{{$t('refferal.text9')}}</div>
                    <div class="list_society_link">
                      <a @click="fbShareAction()" href="javascript:void(0)" class="icon-facebook"></a>
                      <a @click="twShareAction()" href="javascript:void(0)" class="icon-twitter"></a>
                      <a @click="linShareAction()" href="javascript:void(0)" class="icon-linkedin"></a>
                    </div>
                  </div>
                </div>
                
                <div class="clearfix"></div>

                <div class="input_link_referran">
                  <div class="group_input_link_referran">
                    <div class="tit_input_link_referran visbale-600">{{$t('refferal.text8')}}</div>
                    <input class="link_referran" :value="refferalCode.url" disabled/>
                    <button v-clipboard:copy="refferalCode.url" @click="showSuccess($t('refferal.text25'))">{{$t('refferal.copy_link')}}</button>
                  </div>
                </div>

              </div>
            </div>

            <div class="box_qr_referral_right">
              <div class="referel_block">
                <div class="referal_element">
                  <p class="content">{{numberFriend}}</p>
                  <p class="tit">{{$t('refferal.text10')}}</p>
                </div>
                <div class="referal_element">
                  <p class="content">{{ balanceCommissions | formatCurrencyAmount('btc', '0') }} BTC</p>
                  <p class="tit" v-html="$t('refferal.text23')"></p>
                </div>
                <div class="referal_element">
                  <p class="content">{{ balanceCommissionsForMargin | formatCurrencyAmount('btc', '0') }} BTC</p>
                  <p class="tit" v-html="$t('refferal.text23')"></p>
                </div>
              </div>

            </div>
        </div>

        <div class="detail_referral">
          <div class="title_absolute">{{$t('refferal.text12')}}</div>
          <div class="block_tric_referral clearfix">
            <ul>
              <li>
                <p v-html="$t('refferal.text14', {rate: trimNumber(this.settingReferral.refund_rate)})"></p>
                <span class="line" v-if="this.settingReferral.number_of_levels >=1"> {{$t('referral.level1')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_at_level_1)}}%</span></span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=2"> {{$t('referral.level2')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_at_level_2)}}%</span></span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=3"> {{$t('referral.level3')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_at_level_3)}}%</span></span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=4"> {{$t('referral.level4')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_at_level_4)}}%</span></span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=5"> {{$t('referral.level5')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_at_level_5)}}%</span></span>
                <span class="line"> {{$t('referral.next_program', {condition: this.settingReferral.number_people_in_next_program})}}</span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=1"> {{$t('referral.level1')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_in_next_program_lv_1)}}%</span></span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=2"> {{$t('referral.level2')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_in_next_program_lv_2)}}%</span></span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=3"> {{$t('referral.level3')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_in_next_program_lv_3)}}%</span></span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=4"> {{$t('referral.level4')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_in_next_program_lv_4)}}%</span></span>
                <span class="line" v-if="this.settingReferral.number_of_levels >=5"> {{$t('referral.level5')}}: <span>{{$t('referral.rate')}}{{trimNumber(this.settingReferral.refund_percent_in_next_program_lv_5)}}%</span></span>
              </li>

              <li>{{$t('refferal.text16')}}</li>
              <li>{{$t('refferal.text18')}}</li>
              <li>{{$t('refferal.text19')}}</li>
            </ul>
          </div>

          <div class="block_important blue_point">
            <p class="fo_blue">{{$t('refferal.text20')}}</p>
            <p>{{$t('refferal.text21')}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import MasterdataUtils from '@/common/MasterdataUtils';
  import Utils from '@/common/Utils';
  import Modal from '@/components/shared_components/common/Modal.vue';
  import DateTimeSelect from '@/components/shared_components/common/DateTimeSelect';
  import COMMON_CONST from '@/common/Const';
  import moment from 'moment';

  export default {
    components: {
      DateTimeSelect,
      Modal,
    },
    data() {
      return {
        userReferralCommissions: undefined,
        userReferralCommissionsForMargin: undefined,
        prices: undefined,
        sort: {},
        numberFriend: 0,
        refferalCode:{},
        titles: "",
        isLogin: window.isAuthenticated,
        topUserRefCommissions: [],
        settingReferral : {},
        heightTable: '',
        APP_NAME: process.env.VUE_APP_NAME,
        showContentTypes: 'spot',
      }
    },

    methods: {
      getHeightTable() {
        var heightTableFriends =  document.getElementById("table_referral_friends").clientHeight;
        var heightTableLatest =  document.getElementById("table_latest_commission").clientHeight;

        if (heightTableFriends > heightTableLatest) {
          this.heightTable = heightTableFriends;
        } else {
          this.heightTable = heightTableLatest;
        }
        return
      },
      onLoadHight () {
        this.$nextTick(() => {
          this.getHeightTable()
        });
      },
      convertTime(time) {
        let stillUtc = moment.utc(time).toDate();
        let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local;
      },
      trimNumber(number) {
        if(!number) return number;
        return new BigNumber(number);
      },
      getReferralSetting() {
        rf.getRequest('UserRequest').getReferralSetting()
          .then( res => {
            this.settingReferral = res.data;
          })
      },
      fbShareAction() {
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + escape(this.refferalCode.url) + "&t=" + document.title, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        return false;
      },
      twShareAction() {
        window.open("https://twitter.com/intent/tweet?original_referer=" + this.refferalCode.url + "&ref_src=&text=&tw_p=tweetbutton&url=" + this.refferalCode.url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        return false;
      },
      linShareAction() {
        window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + this.refferalCode.url + "&title=" + "&summary=" + "&source=", '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        return false;
      },

      showSuccess(message) {
        Message.success(
          message,
          {},
          { position: 'bottom_left' }
        );
      },
      showDateTimeSelectModal() {
        window.CommonModal.show('DateTimeSelect');
      },
      downloadReportFriend() {
        rf.getRequest('UserRequest').downloadReportFriend({timezone_offset: Utils.getTimzoneOffset()}, 'ReferralFriends.csv');

      },
      downloadReportCommission() {
        rf.getRequest('UserRequest').downloadReportCommission({timezone_offset: Utils.getTimzoneOffset(), "type" : this.showContentTypes || 'spot'}, 'CommissionHistory.csv');
      },

      getUserReferralCommission(params = null) {
        if (!this.isLogin) {
          return;
        }
        let default_object = {
          "type" : this.showContentTypes || 'spot'
        }
        const response = rf.getRequest('UserRequest').getUserReferralCommission(Object.assign(default_object, params));
        return response;
      },

      getAllUserReferralCommission() {
        if (!this.isLogin) {
          return;
        }
        //  get referrer_histories with type=spot to calculate total commission for SpotEx
        const response = rf.getRequest('UserRequest').getUserReferralCommission();
        this.getuserReferralCommissionBalances(response);
        //  get referrer_histories with type=margin to calculate total commission for MarginEx
        let params = {
          "type" : "margin"
        }
        const responseForMargin = rf.getRequest('UserRequest').getUserReferralCommission(params);
        this.getuserReferralCommissionBalancesForMargin(responseForMargin);
      },
      getUserReferralFriends(){
        if (!this.isLogin) {
          return;
        }
        const promise = rf.getRequest('UserRequest').getUserReferralFriends();
        return promise;
      },
      getAllReferrer(params = null){
        if (!this.isLogin) {
          return;
        }
        const promise = rf.getRequest('UserRequest').getAllReferrer(params);
        return promise;
      },
      countFriend(){
        rf.getRequest('UserRequest').getTotalReferrer().then( res => {
          this.numberFriend = res;
        });
      },
      getuserReferralCommissionBalances(response){
        response.then(res => {
          this.userReferralCommissions = res.data;
        });
      },
      getuserReferralCommissionBalancesForMargin(response){
        response.then(res => {
          this.userReferralCommissionsForMargin = res.data;
        });
      },
      getTopUserReferralCommission() {
        rf.getRequest('UserRequest').getTopUserReferralCommission().then(res => {
          this.topUserRefCommissions = res.data;
        });
      },
      createUserQrcode() {
        let params = {
          url: `${window.location.origin}/?${COMMON_CONST.REFERRAL_KEY}=`
        }
        rf.getRequest('UserRequest').createUserQrcode(params).then(res => {
          this.refferalCode = Object.assign({}, this.refferalCode, res.data);
        });

      },
      getPrices(){
        rf.getRequest('PriceRequest').getPrices().then(res => {
          this.onPricesUpdated(res.data);
        });
      },
      loadDataImmediately() {
        this.getReferralSetting();
        this.createUserQrcode();
        this.getUserReferralFriends();
        this.countFriend();
        //this.getTopUserReferralCommission();
      },
      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
      },
      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
        }
      },
      refresh() {
        this.sumBTC();
        this.sumBTCForMargin();
        const isNoData = window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices) || window._.isEmpty(this.coins);
        if (isNoData) {
          return;
        }
      },
      sumBTC() {
        let sum = new BigNumber(0);
        let self = this;
          _.map(this.userReferralCommissions,function(value, key) {
            sum = sum.add(new BigNumber(self.getBalance(value.amount, value.coin.toLowerCase(), 'btc')));
           });
          return sum;
      },
      sumBTCForMargin() {
        let sum = new BigNumber(0);
        let self = this;
          _.map(this.userReferralCommissionsForMargin,function(value, key) {
            sum = sum.add(new BigNumber(self.getBalance(value.amount, value.coin.toLowerCase(), 'btc')));
           });
          return sum;
      },
      getBalance(balance, coin, currency) {
        if (!balance) {
          return 0;
        }

        if (coin === currency) {
          return balance;
        }

        const pair_btc = coin == 'usd' ? `${coin}_${currency}` : `${currency}_${coin}`;
        const pair_eth = `eth_${coin}`;
        const pair_usdt = `usdt_${coin}`;
        const pair_usd = `usd_${coin}`;
        if (!this.prices[pair_btc]) {
          if(pair_btc == 'btc_usdt') return new BigNumber(balance).div(this.prices['usdt_btc'].price).toString();
          if(this.prices[pair_eth]) {
            return new BigNumber(balance).mul(this.prices[pair_eth].price).mul(this.prices['btc_eth'].price).toString();
          }
          if(this.prices[pair_usdt]) {
            return new BigNumber(balance).mul(this.prices[pair_usdt].price).div(this.prices['usdt_btc'].price).toString();
          }
          if(this.prices[pair_usd]) {
            return new BigNumber(balance).mul(this.prices[pair_usd].price).div(this.prices['usd_btc'].price).toString();
          }
          return 0;
        }
        return coin == 'usd' ? new BigNumber(balance).div(this.prices[pair_btc].price).toString() :
               new BigNumber(balance).mul(this.prices[pair_btc].price).toString();
      },
      getImageMedal(index) {
        switch (index) {
            case 0:
                return 'icon-no1';
            case 1:
                return 'icon-no2';
            case 2:
                return 'icon-no3';
        }
      }
    },
    mounted() {
      document.title = this.$t('refferal.title') + ` – ${this.tabTitle}`;
      if(this.isLogin){
        this.loadDataImmediately();
        this.getAllUserReferralCommission();
      }
    },
    watch: {
      prices(newPrices) {
        this.refresh();
      },
    },
    computed: {
      balanceCommissions() {
        if(this.userReferralCommissions) {
          return this.sumBTC();
        }
      },
      balanceCommissionsForMargin() {
        if(this.userReferralCommissionsForMargin) {
          return this.sumBTCForMargin();
        }
      }
    },
    created() {
      this.getPrices();
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .t-left{
    text-align : left !important;
  }
  .box_responsive_table {
    background-color: $color-white;
    margin-top: 15px;
  }
  .text-table{
    font-family: Roboto-Medium;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: $color-grey-dark;

  }
  ul{
    list-style-type: none;
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  #referral {
    .content_referral {
      padding: 0px 0px 100px 0px;
      background-color: $color-alabaster;
    }

    .tit_page_referral {
      margin-bottom: 20px;
      color: $color-eden;
      font-family: $font-family-roboto;
      font-weight: 500;
    }
    .step_account {
      margin: 20px 0px 40px 0px;
      .block_circle {
        width: calc(100% / 3);
        display: block;
        float: left;
        padding-right: 40px;
        &:last-child {
          padding-right: 0px;
          .circle {
            &:after {
              display: none;
            }
          }
          .block_content {
            &::after {
              content: none;
            }
          }
        }
        &:first-child {
          .circle {
            &:before {
              display: none;
            }
          }
        }
        .block_content {
          float: left;
          width: 100%;
          border-radius: 3px;
          background-color: $color-bright-gray;
          min-height: 250px;
          padding: 40px 0px 40px 60px;
          position:relative;
          &::after {
            z-index: 1;
            position: absolute;
            top: 32%;
            left: 100%;
            content: '';
            width: 0;
            height: 0;
            border-left: solid 40px $color-bright-gray;
            border-right: solid 40px transparent;
            border-bottom: solid 40px transparent;
            border-top: solid 40px transparent;
          }
        }
        .circle {
          margin-bottom: 18px;
          position: relative;
          i {
            width: 60px;
            height: 60px;
            font-size: 60px;
            text-align: center;
            line-height: 60px;
            border-radius: 50%;
            position: relative;
            z-index: 2;
            background-color: $color-bright-gray;
            color: $color-eden;
          }
        }
        .title_step {
          margin-bottom: 10px;
          font-family: $font-family-roboto;
          font-size: $font-medium-bigger;
          font-weight: 500;
          line-height: normal;
          color: $color-black;
        }
        .content_step {
          width: 262px;
          max-width: 96%;
          font-size: $font-small;
          line-height: 1.54;
          color: $color-grey-dusty;
        }
      }
    }
    .commission {
      margin-right: -30px;
      overflow: hidden;
      .block_commission {
        display: block;
        width: calc(100% / 3 - 4px);
        margin-right: 4px;
        float: left;
        background-color: $color-eden;
        padding: 6px;
        border-radius: 3px;
        overflow: hidden;
        &:nth-child(2) {
          margin-left: 4px;
        }
        &:nth-child(3) {
          margin-left: 4px;
          margin-right: 0px;
        }

        .img_commission {
          width: 64px;
          height: 64px;
          float: left;
          line-height: 35px;
          padding: 15px 1px 15px 0px;
          text-align: center;
          border-radius: 50%;
          display: block;
          color: $color-white;
          overflow: hidden;
          background-color: transparent;
          margin-right: 25px;
          i {
            font-size: 35px;
          }
        }
        .sub_commission {
          width:  calc(100% - 64px - 25px);
          float: left;
          padding: 9px 0px;
          font-size: $font-root;
          color: $color-white;
          line-height: 23px;
          .left_sub_commission {
            display: inline-block;
            float: left;
          }
          .right_sub_commission {
            display: inline-block;
            float: right;
            min-width: 40%;
          }
          .emailcommission {
            font-weight: 600;
          }
          .btc_commission {
            font-weight: 600;
          }
        }
      }
    }
    .box_qr_referral {
      overflow: hidden;
      border: 1px solid $color-alto;
      margin: 30px 0px 45px 0px;
      .box_qr_referral_left {
        width: 55%;
        float: left;
        padding: 30px 25px 25px 25px;
        border-right: 1px solid $color-alto;
        .crop_qr_referral{
          float: left;
          width: 160px;
          height: 160px;
          margin-right: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .qr_content_referral {
          padding-right: 15px;
          overflow: hidden;
          display: block;
          color: $color-grey-dusty;
          font-size: $font-root;
          line-height: 20px;
          .rate_nb_referral {
            color: $color-grey-dark;
            font-weight: 700;
          }
          .code_id_referral {
            color: $color-grey-dark;
            font-weight: 700;
          }
          .id_referral {
            margin-bottom: 8px;
          }
          .tit_input_link_referran {
            margin-bottom: 8px;
          }
          .rata_referral {
            margin-bottom: 8px;
          }
          .society_link {
            margin-top: 29px;
            .tit_society_link {
              display: inline-block;
              float: left;
              margin-right: 8px;
            }
            .list_society_link {
              display: block;
              overflow: hidden;
              a {
                display: inline-block;
                float: left;
                width: 20px;
                height: 20px;
                font-size: $font-medium-bigger;
                color: $color-grey;
                line-height: 20px;
                text-align: center;
                margin: 0px 2px;
                &:hover {
                  color: $color-green;
                }
              }
            }
          }
          .input_link_referran {
            width: 100%;
            .group_input_link_referran {
              width: 100%;
              input {
                width: 100%;
                height: 42px;
                border: 1px solid $color-bright-gray;
                font-size: $font-root;
                line-height: 20px;
                padding: 15px;
                color: $color-grey-dark;
                margin-bottom: 12px;
                margin-top: 5px;
                border-radius: 3px;
                background-color: $color-bright-gray;
                font-weight: 600;
              }
              button {
                width: 118px;
                height: 35px;
                padding: 8px 10px 7px 10px;
                text-transform: uppercase;
                border: 1px solid $color-green;
                border-radius: 3px;
                background: transparent;
                font-family: $font-family-roboto-bold;
                font-size: $font-smaller;
                font-weight: bold;
                letter-spacing: 0.9px;
                color: $color-green;
                &:hover, &:active, &:focus {
                  background-color: $color-blue-custom;
                  border-color: $color-blue-custom;
                  color: $color-white;
                }
              }
            }
          }
        }
      }
      .box_qr_referral_right {
        width: 45%;
        float: right;
        padding: 30px 25px 25px 25px;
        .referal_element {
          padding-top: 35px;
          display: block;
          float: left;
          width: 50%;
          text-align: center;
          .content {
            font-size: $font-title-size-small-25;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 10px;
            text-transform: uppercase;
          }
          .tit {
            color: $color-grey-dusty;
            font-size: $font-root;
            font-weight: 500;
            line-height: 17px;
          }
        }
      }
    }
    .export_refarral {
      overflow: hidden;
      margin-bottom: 45px;
      .table_left_refarral {
        width: calc(100% / 2 - 15px);
        float: left;
        display: block;
        margin-right: 15px;
      }
      .table_right_refarral {
        width: calc(100% / 2 - 15px);
        float: left;
        display: block;
        margin-left: 15px;
      }      
      .datatable {
        .table_title {
          float: left;
          margin-right: $font-smaller;
          font-family: $font-family-roboto;
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          color: $color-eden;
          font-family: $font-family-roboto-medium;
        }
        .excel_export {
          display: inline-block;
          padding-top: 6px;
          font-size: 13px;
          color: $color-blue-custom;
          i {
            margin-left: 3px;
          }
        }
        .tableContainer {
          background-color: $color-white;
          // margin-top: 15px;
          padding: 10px 0 0 0;
          min-height: 682px;
        }
        table {
          thead {
            th {
              &:after{
                display:none;
              }
            }
          }
        }
      }
    }
    .detail_referral {
      width: 650px;
      max-width: 100%;
      .title_absolute {
        margin-bottom: 20px;
        font-family: $font-family-roboto;
        font-size: $font-big-20;
        font-weight: 500;
        line-height: normal;
        color: $color-eden;
      }
      .block_tric_referral {
        overflow: hidden;
        ul {
          list-style-type: none;
          font-size: $font-root;
          color: $color-grey-dark;
          line-height: 20px;
          li {
            position: relative;
            margin-bottom: 15px;
            padding-left: 30px;
            font-size: $font-small;
            display: grid;
            .line {
              float: left;
              width: 100%;
              clear: both;
              span {
                margin-left: 5px;
              }
            }
            &:before {
              display: inline-block;
              float: left;
              content: "\E907";
              font-family: 'icomoon' !important;
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: $color-green;
              -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
              line-height: 20px;
              margin-right: 10px;
              width: 15px;
              font-size: $font-medium;
              position: absolute;
              top: 0px;
              left: 0px;
            }
          }
        }
      }
      .block_important {
        margin-top: 10px;
        background-color: $color-bright-gray;
        padding: 25px 30px;
        font-size: $font-root;
        color: $color-grey-dusty;
        line-height: 20px;
        .fo_blue {
          color: $color-grey-dark;
          font-weight: 600;
          margin-bottom: 7px;
        }
      }
    }
  }
  
</style>

<style lang="scss">
  @import "@/assets/sass/variables";
  #table_latest_commission,
  #table_referral_friends {
    position: relative;
    .VuePagination {
      background-color: transparent;
      position: absolute;
      right: 0;
      bottom: 20px;
    }
    .pagination_fake {
      background-color: $color-alabaster;
      position: absolute;
      width: 100%;
      height: 76px;
      bottom: 0px;
      left: 0px;
      min-width: 555px;
    }
 
  }
  @media screen and (max-width: 1199px) {

    #content {
      &.page-container.isReferral {
        min-width: auto;
        max-width: 1199px;
        width: 100%;
        #referral {
          .step_account {
            .block_circle {
              width: 100%;
              margin-bottom: 30px;
              padding-right: 0;
              &:last-child {
                margin-bottom: 0px; 
              }
              .block_content {
                padding: 40px 10px 40px 10px;
                text-align: center;
                .content_step {
                  width: 100%;
                }
                &:after {
                  display: none;
                }
              }
            }
          }
        }
        .box_qr_referral {
          .box_qr_referral_left {
            width: 55%;
            border-right: 0;
          }
        }
        .export_refarral {
          .table_left_refarral {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 30px;
            .box_responsive_table {
              margin-top: 15px;
              overflow-y: auto;
              display: block;
              width: 100%;
              .tableContainer {
                min-width: 550px;
              }
            }
          }
          .table_right_refarral {
            width: 100%;
            margin-left: 0px;
            margin-bottom: 30px;
            .box_responsive_table {
              margin-top: 15px;
              overflow-y: auto;
              display: block;
              width: 100%;
              .tableContainer {
                min-width: 550px;
              }
            }
          }
        }
        .detail_referral {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    #content {
      &.page-container.isReferral {
        min-width: auto;
        max-width: 1199px;
        width: 100%;
        .box_qr_referral {
          .box_qr_referral_left {
            padding: 30px 10px 25px 10px;
            width: 100%;
            border-right: 0;
          }
          .box_qr_referral_right {
            width: 100%;
            padding: 0px 10px 25px 10px;
          }
        }
      }
    }
    #table_latest_commission,
    #table_referral_friends {
      .VuePagination {
        left: 0 !important;
      }
    }

  }
  .visbale-600 {
    display: none;
  }
  .hidden-600 {
    display: block;
  }
  @media screen and (max-width: 600px) {
    .visbale-600 {
      display: block;
    }
    .hidden-600 {
      display: none;
    }
    #content {
      &.page-container.isReferral {
        .box_qr_referral {
          margin: 10px 0px 25px 0px;
          .box_qr_referral_left {
            width: 100%;
            padding: 30px 10px 10px 10px;
            .crop_qr_referral {
              width: 100%;
              margin-right: 0px;
              text-align: center;
              img {
                width: 160px;
              }
            }
            .id_referral {
              margin-top: 10px;
            }
            .w100-mb {
              width: 100%;
              display: block;
            }
            .society_link {
              margin-top: 0px;
              margin-bottom: 10px;
            }
            .group_input_link_referran {
              button {
                display: block;
                margin: auto;
              }
            }
          }
          .box_qr_referral_right {
            padding: 0px 10px 20px 10px;
            .referal_element {
              overflow: hidden;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
  #footer,
  #header {
    &.disable-app {
      display: none;
    }
  }
</style>