<!-- Breadcrumb.vue -->
<template>
  <nav aria-label="breadcrumb" :class="$attrs.class">
    <ul class="breadcrumb">
      <li v-for="(item, index) in items" :key="index">
        <span v-if="index !== items.length - 1">
          <router-link :to="item.link">{{ item.label }}</router-link>
        </span>
        <span v-else>{{ item.label }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  list-style: none;
  display: flex;
  color: white;
  font-weight: 700;
}
.breadcrumb li {
  margin-right: 5px;
}
.breadcrumb a {
  color: #8e8e92;
  text-decoration: none;
}
.breadcrumb a:hover {
  text-decoration: underline;
}
.breadcrumb span {
  color: var(--text-primary-color);
  font-weight: bold;
}
</style>
