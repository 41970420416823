<template>
  <div class="step-progress">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="step-item"
      v-if="shouldShowStep(index)"
    >
      <div
        class="line"
        :class="{ active: userSecurityLevel >= step.security_level }"
      ></div>
      <div
        class="step-circle"
        :class="{ active: userSecurityLevel >= step.security_level }"
      >
        {{ index + 1 }}
      </div>
      <div class="step-info">
        <div class="step-amount">
          {{ step.daily_limit | formatNumberAndDecimal }} BTC
        </div>
        <div class="step-limit">{{ $t("account.daily_limit_withdraw") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepProgress",
  props: {
    steps: {
      type: Array,
      required: true,
    },
    userSecurityLevel: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isMobileView: false,
    };
  },
  mounted() {
    this.checkWindowSize();
    window.addEventListener("resize", this.checkWindowSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkWindowSize);
  },
  methods: {
    checkWindowSize() {
      this.isMobileView = window.innerWidth <= 770;
    },
    shouldShowStep(index) {
      if (this.isMobileView) {
        const lastActiveIndex = this.steps.filter(
          (step) => this.userSecurityLevel >= step.security_level
        );
        return index === lastActiveIndex.length - 1;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.step-progress {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  flex: 1;
}

.line {
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #DFDFDF;
  top: 20px;
  z-index: 0;
}

.line.active {
  background-color: #A4E7F2;
}

.step-circle {
  z-index: 1;
  background-color: var(--background-color-primary);
  border: 1px solid #8E8E92;
  color:#8E8E92;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 5px;
  position: relative;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  &:after{
    content: "";
    position: absolute;
    right: -6px;
    top: 50%;
    height: 10px;
    width: 5px;
    background-color: var(--background-color-primary);
  }
  &:before{
    content: "";
    position: absolute;
    left: -6px;
    top: 50%;
    height: 10px;
    width: 5px;
    background-color: var(--background-color-primary);
  }
}

.step-circle.active {
  background-color: #03AAC7;
  border-color: #03AAC7;
  color: white !important;
}

.step-info {
  color: var(--text-primary-color);
}

.step-amount {
  font-size: 16px;
  font-weight: 800;
}

.step-limit {
  color: #b0b0b0;
  font-size: 14px;
  font-weight: 400;
}

/* Responsive CSS */
@media (min-width: 768px) {
  .step-item {
    width: calc(25% - 20px); /* 25% kích thước mỗi mục, trừ khoảng cách giữa */
    margin-bottom: 0;
  }
}

@media (max-width: 770px) {
  .step-progress {
    flex-direction: column;
    align-items: flex-start; /* Canh trái khi màn hình nhỏ */
  }
  .line {
    display: none; /* Ẩn các dòng khi ở màn hình nhỏ */
  }
  .step-item {
    width: 100%;
  }
  .step-circle {
    margin-right: 10px; /* Thêm khoảng cách bên phải cho các vòng tròn */
  }
}
</style>
