<template>
  <div id="closed_positions_table">
     
    <div class="table-container table-positions">
      <data-table3  ref="datatable" :getData="getData" :limit="10">
        <template slot-scope="props">
          <th data-sort-field="symbol" @click="props.echoclick">
            {{$t('margin.positions_symbol')}}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="realised_PN" class="text-right" @click="props.echoclick">
            <span class="fl-right">{{$t('margin.realised_PNL')}}</span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr v-if='props.item' class="border_tr up">
            <td><span class="symbol_th">{{ props.item.symbol}}</span></td>
            <td><span class="fl-right">{{ props.item.latest_realised_pnl | formatMarginNumber(8,'0') }} {{ $t('margin.symbol.btc') }}</span></td>
          </tr>
        </template>
      </data-table3>      
    </div>

  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import { mapGetters } from 'vuex';

  export default {
    components: {

    },
    data() {
      return {
        
      }
    },
    props: {

    },
    watch: {
      positions (newval, oldval) {
        this.$refs.datatable.refresh();
      },
    },
    computed: {
      ...mapGetters({ positions: 'mamPositions' }),
    },
    methods: {
      getData (params) {
        params.limit = 100;
        if(!window.isAuthenticated) return;

        return new Promise(resolve => {
          let currentPositions = _.cloneDeep(this.positions);

          if (params.sort_type == 'desc') {
            currentPositions = window._.sortBy(currentPositions, [params.sort]).reverse();
          } else {
            currentPositions = window._.sortBy(currentPositions, [params.sort]);
          }

          return resolve(currentPositions);
        });
      },
    },
    mounted() {
      
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .fl-right {
    float: right;
  }
  #closed_positions_table {
    padding-bottom: 12px;
    table {
      thead {
        th {
          padding: 10px 0px 9px 20px;
          font-size: $font-smaller;
          height: 40px;
          font-family: $font-family-roboto;
          vertical-align: middle;
          &:last-child {
            padding-right: 20px;
            padding-left: 8px;
          }
        }
      }
      tbody {
        tr {
          td {
            color: $color-gray-dark;
            font-size: $font-smaller;
            font-family: $font-family-roboto-medium;
            text-align: left;
            vertical-align: middle;
            padding: 6px 0px 6px 20px;
            line-height: 20px;
            &:last-child {
              padding: 6px 20px 6px 8px;
            }
            .symbol_th {
              font-size: 13px;
              color: $color-blue-custom;
            }
            .up {
              color: $color-jungle-green;
            }
            .down {
              color: $color-denim;
            }
          }
        }
      }
    }
    .btn-close {
      width: 50px;
      height: 25px;
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
      letter-spacing: 0.8px;
      text-align: center;
      color: $color-jungle-green;
      font-size: $font-mini-mini;
      background-color: transparent;
      padding: 5px 2px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      float: right;
      &:hover, &:focus {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
        color: $color-white;
      }
    }
  }
</style>

<style lang="scss">
  @import '@/assets/sass/variables';
   #closed_positions_table {
    table {
      thead {
        background-color: transparent;
      }
    }
    .empty-data {
      &:hover {
        background-color: transparent !important;
      }
    }
    .border_tr {
      position: relative;
      border-bottom: 4px solid #181f2b;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      &.up {
        border-left: 3px solid $color-jungle-green;
        border-right: 3px solid $color-jungle-green;
      }
      &.down {
        border-left: 3px solid $color-denim;
        border-right: 3px solid $color-denim;
      }
    }
  }
</style>