<template>
  <div class="tableContainer tableContainer3" :style="{ width: widthTable }">
    <div class="box_table2 clearfix">
      <table>
        <thead>
          <tr
            :class="defaultSort ? 'default-sort' : 'custom-sort'"
            @click="onSort"
          >
            <slot
              :resetIE="resetIE"
              :innerHtmlSort="innerHtmlSort"
              :echoclick="echoclick"
            />
          </tr>
        </thead>
        <tbody>
          <slot name="first_row" />
          <slot
            name="body"
            v-for="(row, index) in rows"
            :item="row"
            :index="index"
          />
          <template v-if="this.rows.length === 0">
            <tr class="empty-data">
              <td colspan="50">
                <p>
                  <span class="icon-nodata"></span>
                  <span v-if="tableNoData === false">{{
                    msgEmptyData || $t("common.datatable.data_empty")
                  }}</span>
                  <span v-else>{{
                    msgEmptyData || $t("common.datatable.no_data")
                  }}</span>
                </p>
              </td>
            </tr>
          </template>
          <slot name="end_row" />
        </tbody>
      </table>
    </div>
    <template v-if="lastPage > 1">
<!--      <pagination-->
<!--        ref="pagination"-->
<!--        class="text-right"-->
<!--        :per-page="perPage"-->
<!--        :records="totalRecord"-->
<!--        :chunk="chunk"-->
<!--        @Pagination:page="onPageChange"-->
<!--        :pageParent="page"-->
<!--      ></pagination>-->
      <pagination2
          ref="pagination"
          class="text-right"
          :per-page="perPage"
          :records="totalRecord"
          @Pagination:page="onPageChange"
          :pageParent="page"
      ></pagination2>
    </template>
    <div class="pagination_fake" v-if="lastPage <= 1"></div>
  </div>
</template>

<script>
import Pagination2 from "./Pagination2";

export default {
  components: {
    Pagination2,
  },
  props: {
    getData: {
      type: Function,
    },
    limit: {
      type: Number,
      default: 10,
    },
    chunk: {
      type: Number,
      default: 5,
    },
    widthTable: {
      type: String,
      default: "100%",
    },
    msgEmptyData: {
      type: String,
    },
    defaultSort: {
      type: Boolean,
      default: true,
    },
    tableNoData: {
      type: Boolean,
      default: false,
    },
    innerHtmlSort: {
      type: String,
      default:
        '<span class="icon-sort-tabl3 icon-arrow6"></span><span class="icon-sort-tabl3 icon-arrow5"></span>',
    },
  },
  data() {
    return {
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 10,
      fetching: false,
      rows: [],
      params: {},

      orderBy: null,
      sortedBy: null,

      emptyData: false,
      resetIE: false,
    };
  },
  computed: {
    emptyRow() {
      let emptyRowCount = Math.max(this.limit - _.size(this.rows), 0);
      return Math.min(emptyRowCount, this.limit);
    },
  },
  methods: {
    rawHtml() {
      document.getElementById("demo").innerHTML = "My First JavaScript";
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    echoclick() {
      this.resetIE = !this.resetIE;
    },
    getTarget(target) {
      let node = target;
      while (node.parentNode.nodeName !== "TR") {
        node = node.parentNode;
      }
      return node;
    },

    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder;
      switch (sortOrder) {
        case "asc":
          sortOrder = "";
          break;
        case "desc":
          sortOrder = "asc";
          break;
        default:
          sortOrder = "desc";
      }
      return sortOrder;
    },

    setSortOrders(target, sortOrder) {
      let iterator = target.parentNode.firstChild;
      while (iterator) {
        iterator.dataset.sortOrder = "";
        iterator = iterator.nextElementSibling;
      }
      target.dataset.sortOrder = sortOrder;
    },

    onSort(event) {
      const target = this.getTarget(event.target);
      const orderBy = target.dataset.sortField;
      if (!orderBy) {
        return;
      }
      this.sortedBy = this.getSortOrder(target);
      this.orderBy = this.sortedBy ? orderBy : "";
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy,
      });
      this.setSortOrders(target, this.sortedBy);
      this.fetch();
    },

    fetch() {
      const meta = {
        page: this.page,
        limit: this.limit,
      };

      this.fetching = true;
      this.getData(Object.assign(meta, this.params))
        .then((res) => {
          const data = res.data || res;
          if (!data) {
            return;
          }
          this.column = _.chain(this.$slots.default)
            .filter((el) => {
              return el.tag === "th";
            })
            .value().length;

          if (!data.data) {
            this.rows = data;
            this.$emit("DataTable:finish");
            return;
          }
          this.page = parseInt(data.current_page);
          this.totalRecord = parseInt(data.total);
          this.lastPage = parseInt(data.last_page);
          this.perPage = parseInt(data.per_page);
          this.rows = data.data;
          this.emptyData = window._.isEmpty(this.rows);
          this.$emit("DataTable:finish");
        })
        .then((res) => {
          this.fetching = false;
        });
    },
    refresh() {
      this.page = 1;
      this.params = {};
      this.fetch();
    },

    filter(params) {
      this.page = 1;
      this.params = params;
      this.fetch();
    },
  },
  created() {
    this.fetch();
    this.$on("DataTable:filter", (params) => {
      this.filter(params);
    });
  },
  mounted() {
    this.column = _.chain(this.$slots.default)
      .filter((el) => {
        return el.tag === "th";
      })
      .value().length;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

table {
  width: 100%;
  overflow-y: hidden;
  thead {
    text-align: center;
    color: $color_black;
    border: none;
    line-height: 17px;
    overflow: hidden;
     tr {
      background: var(--color-tab-data-table);
    }
    th {
      user-select: none;
      font-weight: normal;
      font-size: $font_small;
      border: none;
      padding: 8px 0px 8px 20px;
      line-height: 20px;
      color: $color-grey;
      cursor: pointer;
      &:last-child {
        padding: 8px 20px 8px 8px;
      }
      &::after {
        font-family: "icomoon" !important;
        font-size: $font_small;
        margin-left: 5px;
        position: relative;
        top: 0px;
        width: 14px;
        height: 14px;
        display: inline-block;
        line-height: 14px;
        overflow: hidden;
        position: relative;
        top: 3px;
        content: "";
      }
      &[data-sort-order] {
        line-height: 15px;
        &::after {
          font-family: "icomoon" !important;
          font-size: $font_small;
          margin-left: 5px;
          position: relative;
          top: 0px;
          width: 14px;
          height: 14px;
          display: inline-block;
          line-height: 14px;
          overflow: hidden;
          position: relative;
          top: 3px;
          content: "";
        }
      }
      &[data-sort-order="asc"] {
        color: #2dac91;
        &::after {
          content: "\e906";
        }
      }
      &[data-sort-order="desc"] {
        color: #2dac91;
        &::after {
          content: "\e907";
        }
      }
    }
  }
  tbody {
    text-align: center;
    color: $color-grey-dark;
    tr {
      vertical-align: top;
      height: 32px;
      overflow-y: hidden;
      transition-property: height;
      transition-duration: 0.3s, 0.3s;
      transition-timing-function: ease, ease-in;
      .icon-arrow_2_2 {
        color: $color-green;
      }
      &.active {
        max-height: 300px;
        background-color: $color-bright-gray;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
      }
      &.empty-data {
        width: 100%;
        text-align: center;
        color: $color-grey-dusty;
        font-size: $font-root;
        text-align: center;
        td {
          width: 100%;
          text-align: center !important;
          color: $color-grey-dusty !important;
        }

        &:hover {
          background: transparent;
        }
        p {
          padding-top: 22px;
          span {
            vertical-align: middle;
          }
        }
        .icon-nodata {
          font-size: 40px;
          color: $color-grey-sliver;
          margin-right: 10px;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    td {
      font-weight: normal;
      font-size: $font_root;
      color: $color-grey-dusty;
      border: none;
      padding: 8px 0px 8px 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:last-child {
        padding: 8px 20px 8px 8px;
        text-align: right;
      }
      &.info {
        background-color: $color-bright-gray;
        color: $color-grey-dusty;
        font-size: $font-small;
        text-align: left;
        padding: 8px 20px 8px 38px;
        .label-text {
          color: $color-grey;
        }
      }
    }
  }
}
</style>