<template>
  <div id="markets-dark">
    <markets ref="markets"
      :columns="columns"
      :default-market="defaultMarket"
      :callback="closeModal"
      shortNameTab
      :zone="zone"
      ></markets>
  </div>
</template>

<script>
  import Const from '@/common/Const';
  import Markets from './landing/Markets';

  const VISIBLE_COLUMNS = ['pair', 'current_price', 'changed_percent', 'volume'];

  export default {
    components: {
      Markets
    },
    props: {
      defaultMarket: { type: String, default: Const.DEFAULT_MARKET },
      zone: { default: 0 },
    },
    data() {
      return {
        columns: VISIBLE_COLUMNS,
      }
    },
    methods: {
      closeModal() {
        this.$emit('closeMarketModal', true);
      },
    },
    mounted() {
      this.$refs.markets.isShowSearchField = true;
    },
  }

</script>

<style lang="scss">
  @import "@/assets/sass/variables";

  $bgTabMarket: $color-grey-dark-blue;

  .light-theme {
    #markets-dark {
      width: 820px;
      z-index: 1000;
      position: absolute;
      // padding: 5px;
      top: 100%;
      margin-top: 10px;
      left: 0px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);

      #container-tab {
        overflow: hidden;
        width: 100% !important;
        margin: 0;
        max-width: 100%;
        padding: 5px 20px 0px 12px;
        background-color: $color-white;
        .search__coin {
          border: none;
          border-left: 1px solid $color-violet;
        }
        &:first-child{
          background-color: $color-alabaster !important;
          border-radius: 8px 8px 0px 0px;
          min-height: 53px;
        }
        .current-market {
          background-color: $color-white;
          color: $pure_color_black !important;
          font-weight: 600;
        }
        label {
          display: flex;
          align-items: center;
          width: auto;
          height: 100%;
          float: left;
          line-height: 17px;
          padding: 10px 24px !important;
          font-size: $font-medium !important;
          font-weight: 500;
          color: $color-gray-dark !important;
          border: none !important;
          text-align: center;
          border-radius: 3px;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
          .icon-star2 {
            padding-top: 0px;
            top: 1px;
            position: relative;
            color: $color-orange!important;
            &.star_color {
              color: $color-orange!important;
            }
          }
        }
        input {
          &:checked + label {
            background: $color-violet !important;
            color: $text-yellow !important;
          }
        }
        .search__coin {
          float: right;
          padding: 0px 0px 0px 0px !important;
          i.icon {
            font-size: $font-small;
          }
          .input_group_seach {
            background-color: $color-jungle-green-light !important;
            height: 25px !important;
            line-height: 25px;
            &::before {
              color: $color-grey-sliver !important;
            }
          }
          .input-search {
            width: 210px !important;
            height: 36px !important;
            background-color: $text-white !important;
            border: none !important;
            color: $color-grey-dark;
            padding: 2px 5px 2px 39px;
            font-style: normal;
            caret-color: $color-grey-sliver;
            &::placeholder {
              color: $color-grey-sliver !important;
            }
          }
        }
      }
      .table-content {
        margin-top: 0px;
        padding: 0px 0px 0px 0px;
        border-top: 0px;
        background-color: $color-white !important;
      }
      #content {
        background-color: $color-white !important;
        border: none !important;
        // overflow: hidden;
        min-height: 300px !important;
        div {
          background: $color-white;
        }
        .currency {
          color: $color-grey;
        }
        .is-muted {
          color: $color-grey;
        }
        .item-tab {

          table{
            width: 100%;
            thead {
              border: none !important;
              background-color: transparent;
              tr {
                th {
                  text-align: left!important;
                  font-family: $font-inter-bold;
                  font-weight: 500;
                  padding: 15px 0 0 0 !important;
                  font-size: $font-medium-bigger !important;

                  &:first-child{
                    width: 25%;
                    padding-left: 8%!important;
                  }

                  &:nth-child(2), &:nth-child(3){
                    width: 23.5%;
                  }

                  &:last-child {
                    //width: 25.5%;
                    padding-right: 22px !important;
                    padding-left: 0px !important;
                    float: right;

                    span {
                    }
                    &[data-sort-order='desc']:after, &[data-sort-order='asc']:after {
                      float: right;
                      margin-right: 3px;
                    }
                  }
                }
                th[data-sort-order='desc']:after, th[data-sort-order='asc']:after {
                  margin-top: 4px;
                  font-size: $font-smaller;
                }
              }
            }
            tbody {
              width: 100%;
              overflow-y: auto;
              tr {
                &:hover {
                  background-color: $color-alabaster;
                  &.empty-data {
                    background-color: transparent;
                  }
                }
                .icon-star1 {
                  line-height: 20px;
                  margin-right: 3px;
                }
                td {
                  color: #191919;
                  font-family: $font-inter-regular;
                  font-size:  $font-medium-bigger;
                  text-align: left!important;
                  padding: 14px 0px 8px 0px;
                  &:last-child {
                    text-align: right!important;
                  }

                  &:first-child {
                    padding-left: 4%;
                    span {
                      color: #191919;
                      font-family: $font-inter-regular;
                      font-size:  $font-medium-bigger;
                    }

                    .icon-star2 {
                      color: $color-corn-pale!important;
                    }
                    .coin {
                      color: #191919!important;
                    }
                  }

                  &:last-child {
                    padding-right: 20px;
                  }
                }
                .col-pair {
                  .coin {
                    font-size: $font-medium-bigger;
                    color: $color-grey-dark;
                    font-weight: 500;
                    font-family: $font-inter-bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .dark-theme {
    #markets-dark {
      width: 820px;
      z-index: 1000;
      position: absolute;
      // padding: 5px;
      top: 100%;
      margin-top: 10px;
      left: 0px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);

      #container-tab {
        overflow: hidden;
        width: 100% !important;
        margin: 0;
        max-width: 100%;
        padding: 5px 20px 0px 12px;
        background-color: $color-white;
        .search__coin {
          border: none;
          border-left: 1px solid $color-violet;
        }
        &:first-child{
          background-color: $color-black-shark !important;
          border-radius: 8px 8px 0px 0px;
          min-height: 53px;
        }
        .current-market {
          background-color: $color-night;
          color: $color-white !important;
          font-weight: 600;
        }
        label {
          display: flex;
          align-items: center;
          width: auto;
          height: 100%;
          float: left;
          line-height: 17px;
          padding: 10px 24px !important;
          font-size: $font-medium !important;
          font-weight: 500;
          color: $color-storm-grey !important;
          border: none !important;
          text-align: center;
          border-radius: 3px;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
          .icon-star2 {
            padding-top: 0px;
            top: 1px;
            position: relative;
            color: $color-orange!important;
            &.star_color {
              color: $color-orange!important;
            }
          }
        }
        input {
          &:checked + label {
            background: $color-violet !important;
            color: $text-yellow !important;
          }
        }
        .search__coin {
          float: right;
          padding: 0px 0px 0px 0px !important;
          i.icon {
            font-size: $font-small;
          }
          .input_group_seach {
            background-color: $color-jungle-green-light !important;
            height: 25px !important;
            line-height: 25px;
            &::before {
              color: $color-grey-sliver !important;
            }
          }
          .input-search {
            width: 210px !important;
            height: 36px !important;
            background-color: $color-night !important;
            border: none !important;
            color: $text-white;
            padding: 2px 5px 2px 39px;
            font-style: normal;
            caret-color: $color-gray-mako;
            &::placeholder {
              color: $color-gray-mako !important;
            }
          }
        }
      }
      .table-content {
        margin-top: 0px;
        padding: 0px 0px 0px 0px;
        border-top: 0px;
        background-color: $color-night !important;
      }
      #content {
        background-color: transparent !important;
        border: none !important;
        // overflow: hidden;
        min-height: 300px !important;
        div {
          background: $color-night;
        }
        .currency {
          color: $color-grey;
        }
        .is-muted {
          color: $color-grey;
        }
        .item-tab {

          table{
            width: 100%;
            thead {
              border: none !important;
              background-color: transparent;
              tr {
                th {
                  text-align: left!important;
                  font-family: $font-inter-bold;
                  font-weight: 500;
                  padding: 15px 0 0 0 !important;
                  font-size: $font-medium-bigger !important;
                  color: $color-auro-metal;
                  .icon-double-arrow-table:before {
                    color: $color-auro-metal;
                  }

                  &:first-child{
                    width: 27.5%;
                    padding-left: 8%!important;
                  }

                  &:nth-child(2), &:nth-child(3){
                    width: 23.5%;
                  }

                  &:last-child {
                    //width: 25.5%;
                    padding-right: 22px !important;
                    padding-left: 0px !important;
                    float: right;
                    //span {
                    //
                    //}
                    &[data-sort-order='desc']:after, &[data-sort-order='asc']:after {
                      float: right;
                      margin-right: 3px;
                    }
                  }
                }
                th[data-sort-order='desc']:after, th[data-sort-order='asc']:after {
                  margin-top: 4px;
                  font-size: $font-smaller;
                }
              }
            }
            tbody {
              width: 100%;
              overflow-y: auto;
              tr {
                &:hover {
                  background-color: #383838;
                  &.empty-data {
                    background-color: transparent;
                  }
                }
                .icon-star1 {
                  line-height: 20px;
                  margin-right: 3px;
                }
                td {
                  color: #FFFFFF;
                  font-family: $font-inter-regular;
                  font-size:  $font-medium-bigger;
                  text-align: left!important;
                  padding: 14px 0px 8px 0px;
                  &:last-child {
                    text-align: right!important;
                  }

                  &:first-child {
                    padding-left: 4%;
                    span {
                      color: #FFFFFF;
                      font-family: $font-inter-regular;
                      font-size:  $font-medium-bigger;
                    }

                    .icon-star2 {
                      color: $color-corn-pale!important;
                    }
                    .coin {
                      color: white!important;
                    }
                  }

                  &:last-child {
                    padding-right: 20px;
                  }
                }
                .col-pair {
                  .coin {
                    font-size: $font-medium-bigger;
                    color: $color-grey-dark;
                    font-weight: 500;
                    font-family: $font-family-roboto-medium;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #markets-dark  {
    tbody {
      display: block;
      height: 300px;
    }
    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .table-landing > table tr:first-child {
      margin-top: 0!important;
    }
  }

  @media screen and (max-width: 992px) {
    #markets-dark  {
        tbody {
        display: block;
        height: 300px;
      }
      thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      .table-landing > table tr:first-child {
        margin-top: 0!important;
      }
    }
   
  }

</style>
<style lang="scss" scoped >
@media screen and (max-width: 992px) {
  #markets-dark  {
    left: 12px;
    min-width: 325px;
    max-width: calc(100vw - 32px);
    overflow-x: auto;
    background: $color-white;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 8px;
    .box-table {
      overflow-x: auto;
    }
  }

  .dark-theme #markets-dark {
    background: $color-night;
  }
}



</style>