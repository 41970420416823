<template>
  <div id="term-page">
    <div class="title-container">
      <div class="t-title">
        <p>{{ $t("terms.title_terms") }}</p>
        <!-- <img src="@/assets/images/about/bgr-policy.svg" /> -->
      </div>
    </div>

    <div class="content-terms">
      <p class="text-sub">{{ $t("terms.sub") }}</p>
      <p class="text-sub">{{ $t("terms.des") }}</p>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_1") }}</div>
        <p class="text-sub">{{ $t("terms.sub_1") }}</p>
        <ul>
          <li>{{ $t("terms.list_reminder.reminder_1") }}</li>
          <li>{{ $t("terms.list_reminder.reminder_2") }}</li>
          <li>{{ $t("terms.list_reminder.reminder_3") }}</li>
          <li>{{ $t("terms.list_reminder.reminder_4") }}</li>
        </ul>
        <p class="text-sub">{{ $t("terms.para_1") }}</p>
        <p class="text-sub">{{ $t("terms.sub_2") }}</p>
        <ul>
          <li>{{ $t("terms.list_inform.inform_1") }}</li>
          <li>{{ $t("terms.list_inform.inform_2") }}</li>
          <li>{{ $t("terms.list_inform.inform_3") }}</li>
          <li>{{ $t("terms.list_inform.inform_4") }}</li>
          <li>{{ $t("terms.list_inform.inform_5") }}</li>
          <li>{{ $t("terms.list_inform.inform_6") }}</li>
          <li>{{ $t("terms.list_inform.inform_7") }}</li>
          <li>{{ $t("terms.list_inform.inform_8") }}</li>
        </ul>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_2") }}</div>
        <ul>
          <li>{{ $t("terms.list_general.general_1") }}</li>
          <li>{{ $t("terms.list_general.general_2") }}</li>
          <li>{{ $t("terms.list_general.general_3") }}</li>
          <li>{{ $t("terms.list_general.general_4") }}</li>
          <li>{{ $t("terms.list_general.general_5") }}</li>
          <li>{{ $t("terms.list_general.general_6") }}</li>
        </ul>
        <div class="list-sub">
          <p>{{ $t("terms.list_general.general_7") }}</p>
          <p>{{ $t("terms.list_general.general_8") }}</p>
          <p>{{ $t("terms.list_general.general_9") }}</p>
          <p>{{ $t("terms.list_general.general_10") }}</p>
          <p>{{ $t("terms.list_general.general_11") }}</p>
          <p>{{ $t("terms.list_general.general_12") }}</p>
          <p>{{ $t("terms.list_general.general_13") }}</p>
        </div>
        <p class="text-sub">{{ $t("terms.sub_3") }}</p>
        <p class="text-sub">{{ $t("terms.sub_4") }}</p>
        <p class="text-sub">{{ $t("terms.sub_5") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_3") }}</div>
        <p class="text-sub text-regis">{{ $t("terms.sub_6") }}</p>
        <p class="text-sub">{{ $t("terms.sub_7") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_4") }}</div>
        <p class="text-sub text-regis">{{ $t("terms.sub_8") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_5") }}</div>
        <ol>
          <li>{{ $t("terms.list_process.process_1") }}</li>
          <li>{{ $t("terms.list_process.process_2") }}</li>
          <li>{{ $t("terms.list_process.process_3") }}</li>
          <li>{{ $t("terms.list_process.process_4") }}</li>
          <li>
            {{ $t("terms.list_process.process_5") }}
            <ul class="list-child">
              <div>
                <p class="text-sub">{{ $t("terms.sub_9") }}</p>
                <p class="text-sub m-bottom">{{ $t("terms.sub_10") }}</p>
              </div>
            </ul>
          </li>
          <li>
            {{ $t("terms.sub_11") }}
            <ul class="list-child">
              <p class="text-sub">{{ $t("terms.sub_12") }}</p>
              <p class="text-sub">{{ $t("terms.sub_13") }}</p>
              <p class="text-sub">{{ $t("terms.sub_14") }}</p>
              <p class="text-sub">{{ $t("terms.sub_15") }}</p>
            </ul>
          </li>

          <li>
            {{ $t("terms.sub_16") }}
            <p class="list-child">{{ $t("terms.sub_17") }}</p>
          </li>
          <li>{{ $t("terms.sub_18") }}</li>
          <li>{{ $t("terms.sub_19") }}</li>
          <li>{{ $t("terms.sub_20") }}</li>
          <li>{{ $t("terms.sub_21") }}</li>
          <li>
            {{ $t("terms.sub_22") }}
          </li>
          <li>{{ $t("terms.sub_28") }}</li>
          <li>{{ $t("terms.sub_29") }}</li>
          <li>
            {{ $t("terms.sub_25") }}
            <div class="list-child">
              <p class="text-sub">{{ $t("terms.sub_26") }}</p>
              <p class="text-sub">{{ $t("terms.sub_27") }}</p>
            </div>
          </li>
          <li>
            {{ $t("terms.sub_31") }}
            <div class="list-child">
              <p class="text-sub">{{ $t("terms.sub_30") }}</p>
            </div>
          </li>
          <li>
            {{ $t("terms.sub_32") }}
            <div class="list-child">
              <p class="text-sub">{{ $t("terms.sub_33") }}</p>
            </div>
          </li>
          <li>
            {{ $t("terms.regis_18") }}
            <div class="list-child">
              <p class="text-sub">{{ $t("terms.regis_18_1") }}</p>
            </div>
          </li>
          <li>
            {{ $t("terms.regis_19") }}
            <p class="list-child">{{ $t("terms.regis_20") }}</p>
          </li>
          <li>{{ $t("terms.regis_20_1") }}</li>
          <li>{{ $t("terms.regis_21") }}</li>
          <li>{{ $t("terms.regis_22") }}</li>
          <li>{{ $t("terms.regis_23") }}</li>
          <li>{{ $t("terms.regis_24") }}</li>
          <li>{{ $t("terms.regis_25") }}</li>
          <li>{{ $t("terms.regis_26") }}</li>
          <li>{{ $t("terms.regis_27") }}</li>
          <li>{{ $t("terms.regis_28") }}</li>
          <li>
            {{ $t("terms.regis_29") }}
            <p class="list-child">{{ $t("terms.regis_imde") }}</p>
          </li>
          <li>{{ $t("terms.regis_30") }}</li>
          <li>{{ $t("terms.regis_31") }}</li>
        </ol>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_7") }}</div>
        <p class="text-sub">{{ $t("terms.sub_34") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_8") }}</div>
        <ol>
          <li>
            {{ $t("terms.limitation_1") }}
            <ul class="list-child-limit">
              <li>{{ $t("terms.limitation_1_1") }}</li>
              <li>{{ $t("terms.limitation_1_2") }}</li>
              <li>{{ $t("terms.limitation_1_3") }}</li>
              <li>{{ $t("terms.limitation_1_4") }}</li>
              <li>{{ $t("terms.limitation_1_5") }}</li>
              <li>{{ $t("terms.limitation_1_6") }}</li>
              <li>{{ $t("terms.limitation_1_7") }}</li>
              <li>{{ $t("terms.limitation_1_8") }}</li>
              <li>{{ $t("terms.limitation_1_9") }}</li>
              <li>{{ $t("terms.limitation_1_10") }}</li>
            </ul>
          </li>
          <li>
            {{ $t("terms.limitation_2") }}
            <ul class="list-child-limit">
              <li>{{ $t("terms.limitation_2_1") }}</li>
              <li>{{ $t("terms.limitation_2_2") }}</li>
              <li>{{ $t("terms.limitation_2_3") }}</li>
              <li>{{ $t("terms.limitation_2_4") }}</li>
              <li>{{ $t("terms.limitation_2_5") }}</li>
            </ul>
          </li>
          <li>{{ $t("terms.limitation_3") }}</li>
          <li>{{ $t("terms.limitation_4") }}</li>
          <li>{{ $t("terms.limitation_5") }}</li>
          <li>{{ $t("terms.limitation_6") }}</li>
          <li>{{ $t("terms.limitation_7") }}</li>
          <li>{{ $t("terms.limitation_8") }}</li>
          <li>
            {{ $t("terms.limitation_9") }}
            <p class="text-sub">{{ $t("terms.sub_35") }}</p>
          </li>
          <li>{{ $t("terms.limitation_10") }}</li>
          <li>{{ $t("terms.limitation_11") }}</li>
          <li>{{ $t("terms.limitation_12") }}</li>
          <li>
            {{ $t("terms.limitation_13") }}
            <p class="text-sub">{{ $t("terms.sub_36") }}</p>
          </li>
          <li>{{ $t("terms.limitation_14") }}</li>
          <li>{{ $t("terms.limitation_15") }}</li>
          <li>{{ $t("terms.limitation_16") }}</li>
          <li>{{ $t("terms.limitation_17") }}</li>
          <li>{{ $t("terms.limitation_18") }}</li>
        </ol>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_9") }}</div>
        <p class="text-sub">{{ $t("terms.des_calculation") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_10") }}</div>
        <p class="text-sub">{{ $t("terms.des_control") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_11") }}</div>
        <p class="text-sub">{{ $t("terms.des_trasfer") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_12") }}</div>
        <p class="text-sub">{{ $t("terms.des_severability") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_13") }}</div>
        <p class="text-sub">{{ $t("terms.des_agency") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_14") }}</div>
        <p class="text-sub">{{ $t("terms.des_waiver") }}</p>
      </div>

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_15") }}</div>
        <p class="text-sub">{{ $t("terms.des_headings") }}</p>
      </div>

      <!-- <div class="para-term">
          <div class="t-bold-term">{{ $t('terms.terms_bold_15') }}</div>
          <p class="text-sub">{{ $t('terms.des_headings') }}</p>
        </div> -->

      <div class="para-term">
        <div class="t-bold-term">{{ $t("terms.terms_bold_16") }}</div>
        <ol>
          <li>{{ $t("terms.entry_1") }}</li>
          <li>{{ $t("terms.entry_2") }}</li>
          <li>{{ $t("terms.entry_3") }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      urlPolicy: `${window.location.origin}/policy`,
    };
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    document.title = this.$t("menu.terms") + ` – ${this.tabTitle}`;
    window.addEventListener("beforeunload", this.scrollToTop);
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.m-bottom {
  margin-bottom: 8px;
}

#term-page {
  color: var(--color-text);
  font-size: $font-root;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;

  .title-container {
    .t-title {
      margin: 0 auto;
      padding: 0;
      max-width: 1440px;
      font-size: 34px;
      line-height: 40px;
    }
  }

  .content-terms {
    margin: 0 auto;
    padding: 50px 0px 120px;
    max-width: 1440px;
    font-size: 16px;
    line-height: 19px;
    .t-bold-term {
      font-size: 16px;
      line-height: 19px;
      /* color: var(--text-primary-color); */
      font-family: $font-inter-bold;
      margin-bottom: 8px;
    }
    .text-sub {
      /* color: var(--text-close); */
      margin-top: 8px;
      text-align: justify;
    }

    .para-term {
      padding: 24px 0 0;

      & ul {
        padding: 8px 0 0px 18px;
        margin: 0;
        list-style: decimal;

        li {
          /* color: var(--text-close); */
          text-align: justify;
        }
      }
      & ol {
        counter-reset: item;
        li {
          display: block;
          margin-left: 1.7em;
          /* color: var(--text-close); */
          text-align: justify;
          &::before {
            content: counter(item) ". ";
            counter-increment: item;
            position: absolute;
            margin-left: -1.7em;
          }
        }
      }
      .list-sub {
        p {
          margin: 2px 0;
          /* color: var(--text-close); */
          text-align: justify;
        }
      }
      .list-child {
        padding-left: 0;

        margin-top: 5px;
      }
      .list-child-limit {
        padding: 8px 0;
        counter-reset: item;

        li {
          display: block;
          margin-left: 1.7em;
          /* color: var(--text-close); */
          &::before {
            content: counter(item) ") ";
            counter-increment: item;
            position: absolute;
            margin-left: -1.7em;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  #term-page {
    .content-terms {
      padding: 40px 16px;
    }
    .title-container {
      padding-left: 19px;
    }
  }
}

@media screen and (max-width: 992px) {
  #term-page {
    .title-container {
      .t-title {
        max-height: 82px;
        p {
          font-size: 24px;
          line-height: 29px;
        }
        img {
          width: 222px;
        }
      }
    }
    .content-terms {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>
