<!-- ProfileHeader.vue -->
<template>
  <div class="profile-header">
    <img src="/images/icon/icon-user.svg" alt="icon" width="64" height="64" />
    <div class="profile-info">
      <div class="profile-details">
        <div class="profile-email">{{ email }}</div>
        <div class="container-detail-item">
          <div class="detail-item">
            <div class="kyc_status">{{ $t("menu.kyc_status") }}</div>
            <div class="group_verify" v-show="fetching == false">
              <!-- <div v-if="kyc.status!='pending' && kyc.status!='verified' && kyc.status!='rejected'" class="status unverified">
                            {{ $t('account.btn_unverified')}}
                          </div> -->
              <div
                  v-if="
                kyc.status != 'verified' &&
                kyc.status != 'rejected' &&
                kyc.bank_status === 'init'
              "
                  class="status unverified"
              >
                {{ $t("account.btn_unverified") }}
              </div>
              <div v-if="kyc.status == 'verified'" class="status verified">
                {{ $t("account.btn_verified") }}
              </div>
              <div
                  v-if="kyc.status == 'pending' && kyc.bank_status !== 'init'"
                  class="status pending"
              >
                {{ $t("account.btn_pending") }}
              </div>
              <div v-if="kyc.status == 'rejected'" class="status rejected">
                {{ $t("account.btn_rejected") }}
              </div>

              <div v-show="(!kyc || !kyc.status && levelOtpVerify === 1 || kyc.status === 'rejected' && levelOtpVerify === 1) || (identityVerified === 0 && checkIdentityVerified)"
                  class="status click verified"
                  @click="$emit('onShowEnableIdentityModal')"
                  >
              {{ $t('account.btn_verify_now_kyc')}}
            </div>
            <div v-if="kyc.status=='pending' && kyc.bank_status!=='init'"
                  class="status click verified"
                  @click="showEnableIdentityModal(1)"
                  >
              {{ $t('account.btn_verify_change')}}
            </div>
              <!-- <div v-show="!kyc || !kyc.status && levelOtpVerify === 1 || kyc.status === 'rejected' && levelOtpVerify === 1 "
                                 class="status click verified" id="kyc-connect">
                              {{ $t('account.btn_verify_now')}}
                            </div> -->
              <!--                          <div v-if="kyc.status === 'pending' " class="status click verified" id="kyc-connect" >-->
              <!--                            {{ $t('account.btn_verify_change')}}-->
              <!--                          </div>-->
            </div>
            <div class="group_verify" v-if="fetching == true">
              <div class="spinner">
                <div class="spinner-border"></div>
              </div>
            </div>
          </div>
          <div class="detail-item">
            <span class="kyc_status">User ID</span>
            <span class="sub_info">{{ userId }}</span>
          </div>
          <div class="detail-item">
            <span class="kyc_status">Account level</span>
            <span class="sub_info">{{ accountLevel }}</span>
          </div>
          <div class="detail-item">
            <span class="kyc_status">Last login time</span>
            <span class="sub_info">{{ lastLoginTime | timeAgo }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileHeader",
  props: {
    email: {
      type: String,
      required: true,
    },
    kyc: {
      type: Object,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    accountLevel: {
      type: Number,
      required: true,
    },
    lastLoginTime: {
      type: String,
      required: true,
    },
    levelOtpVerify: {
      type: Number,
      required: true,
    },
    fetching: {
      type: Boolean,
      required: true,
    },
    identityVerified: {
      type: Number,
      required: true,
    },
    checkIdentityVerified: {
      type: Boolean,
      required: true,
    },
    
  },
  emits: ["onShowEnableIdentityModal"],
  mounted() {
    console.log(this.lastLoginTime);
  },
};
</script>

<style scoped lang="scss">
.profile-header {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: var(--bg-stats-home);
  border-radius: 8px;
  color: white;
  gap: 16px;
  @media screen and (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.sub_info {
  color: var(--text-primary-color) !important;
  font-family: $font-notosans-medium;
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-email {
  font-size: 20px;
  font-family: $font-notosans-bold;
  margin-bottom: 5px;
  color: var(--text-primary-color);
}

.profile-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .container-detail-item{
    gap: 12px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 770px) {
      flex-direction: column;
    }
  }
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 5px;
  .kyc_status {
    font-size: 14px;
    line-height: 19px;
    font-family: $font-notosans-medium;
    color: var(--text-desc);
  }
  .group_verify {
    display: flex;
    gap: 12px;
    .status {
      padding: 4px 8px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      font-size: 12px !important;
      color: #fff !important;
      border-radius: 1234px !important;
      font-family: $font-notosans-semibold;
      line-height: 16px !important;
      &.click {
        @include btn-common(yes);
      }
      &.unverified {
        background: var(--bgr-btn);
        color: var(--text-primary-color);
      }
      &.verified {
        background: $color-aqua-green;
      }
      &.pending {
        background: $text-color-yellow;
      }
      &.rejected {
        background: $text-color-red;
        color: $color-white;
      }
    }
  }
  .spinner {
    margin-top: 8px;
    height: 37px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bgr-btn);
    color: var(--text-primary-color);
    border-radius: 8px;
    @media screen and (max-width: 400px) {
      height: 32px;
    }
    div {
      width: 15px;
      height: 15px;
    }
  }
}

.verify-kyc {
  background-color: #00bfff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 3px 10px;
  cursor: pointer;
}

.verified-kyc {
  color: #00ff00;
}
</style>
