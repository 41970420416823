<template>
  <div class="chart-container" id="exchange-chart">
    <fullscreen @change="fullscreenChange" ref="fullscreen">
      <div class="chart-option">
        <div class="time-option" v-show="chartActive == 1">
          <template>
            <div id="select-chart">
              <select-box class="time"
                v-model="selectedMinute"
                :placeholder="$t('exchange.basic.label_minute')"
                :options="minutes()"
                :classFocused="{'active-time': (timeActive === 1)}"/>
            </div>
            <div id="select-chart">
              <select-box class="time"
                v-model="selectedHour"
                :placeholder="$t('exchange.basic.label_hour')"
                :options="hours()"
                :classFocused="{'active-time': (timeActive === 2)}"/>
            </div>
            <div class="time div-time" :class="{'active-time':(timeActive == 3)}" @click="onChangeResolution('D')">1D</div>
            <div class="time div-time" :class="{'active-time':(timeActive == 4)}" @click="onChangeResolution('W')">1W</div>
            <!-- API load data for chart does't support -->
            <div class="time div-time" :class="{'active-time':(timeActive == 5)}" @click="onChangeResolution('M')">1M</div>
          </template>
          <div class="time div-time button-icon" v-on:click.stop="showIndicatorDialog()">
            <span class="icon-chart1"></span>
          </div>
          <div class="time div-time button-icon" v-on:click.stop="showChartPropertiesDialog()">
            <span class="icon-setting"></span>
          </div>
          <div class="time div-time button-icon group_favered" :class="{ 'active':(showFavoredList == true)}" @click="onshowFavoredList()">
            <div class="btn btn-favored">

              <span class="icon" :class="{
                'icon-heikin-ashi2'   :activeFavoredItem === 2,
                'icon-bar'            :activeFavoredItem === 1,
                'icon-heikin-ashi3'   :activeFavoredItem === 3,
                'icon-heikin-ashi'    :activeFavoredItem === 4,
                'icon-line2'          :activeFavoredItem === 5,
                'icon-line3'          :activeFavoredItem === 6,
              }"></span>
              <i class="icon-arrow1 icon_drop"></i>
            </div>
            <div class="box_favored_list clearfix" v-show="showFavoredList == true">
              <ul class="favored_lit">
                <li class="item" @click="activeFavoredItem = 1, showSetBarStyle(0)">
                  <i class="icon icon-bar"></i>
                  <span class="name">{{ $t('barstyle_chart.bars') }}</span>
                </li>
                <li class="item" @click="activeFavoredItem = 2, showSetBarStyle(1)">
                  <i class="icon icon-heikin-ashi2"></i>
                  <span class="name">{{ $t('barstyle_chart.candles') }}</span>
                </li>
                <li class="item" @click="activeFavoredItem = 3, showSetBarStyle(9)">
                  <i class="icon icon-heikin-ashi3"></i>
                  <span class="name">{{ $t('barstyle_chart.hollow_candles') }}</span>
                </li>
                <li class="item" @click="activeFavoredItem = 4, showSetBarStyle(8)">
                  <i class="icon icon-heikin-ashi"></i>
                  <span class="name">{{ $t('barstyle_chart.heikin_ashi') }}</span>
                </li>
                <li class="item" @click="activeFavoredItem = 5, showSetBarStyle(2)">
                  <i class="icon icon-line2"></i>
                  <span class="name">{{ $t('barstyle_chart.line') }}</span>
                </li>
                <li class="item" @click="activeFavoredItem = 6, showSetBarStyle(3)">
                  <i class="icon icon-line3"></i>
                  <span class="name">{{ $t('barstyle_chart.area') }}</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="time div-time button-icon">
            <span class="icon-screen" @click="toggleFullScreen" :class="{'icon-expad': !fullscreen, 'icon-exit-full-screen': fullscreen}"></span>
          </div>
          <div class="time div-time button-icon" @click.stop="onSaveTrandingChartConfiguration()" id="btn-upload-chart">
            <span class="icon-upload"></span>
            <span class="tooltip-upload">{{ $t('barstyle_chart.save_chart') }}</span>
          </div>
          <div class="time div-time button-icon" @click.stop="onReloadTrandingChart()" id="btn-download-chart">
            <span class="icon-download"></span>
            <span class="tooltip-download">{{ $t('barstyle_chart.load_chart') }}</span>
          </div>
        </div>

        <div class="chart-type">
          <span class="icon-close1" @click.stop="onCloseChart()" v-if="!fullscreen"/>
           <div class="chart-tab">
            <div class="tab-element" :class="{'active-tab':(chartActive == 1)}" @click="chartActive = 1">{{ $t('exchange.basic.chart.candle') }}</div>
            <div class="tab-element" :class="{'active-tab':(chartActive == 2)}" @click="chartActive = 2">{{ $t('exchange.basic.chart.depth') }}</div>
          </div>
        </div>
      </div>
      <div class="chart-content" :class="{'chart-full-screen': fullscreen}" >
          <trading-view-chart
            :symbol="symbol"
            :resolution.sync="resolution"
            :min-tick="minTick"
            ref="tradingView" v-show="chartActive === 1">
          </trading-view-chart>
          <high-charts :symbol="symbol" v-show="chartActive === 2" ref="depthChart"></high-charts>
      </div>
    </fullscreen>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import TradingViewChart from './TradingViewChart';
  import HighCharts from './HighCharts';
  import LocalStorageUtils from '@/common/LocalStorageUtils';
  import SelectBox from '@/components/shared_components/common/SelectBox';
  import Const from '@/common/Const';
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  const DAY = 'D';
  const WEEK = 'W';
  const MONTH = 'M';

  export default {
    components: {
      TradingViewChart,
      HighCharts,
      SelectBox
    },
    props: {
      keyChart: { type: Number, default: 1 },
      // currentSymbol: { type: String, default: '' },
    },
    data() {
      return {
        resolution: '1',
        minTick: 'default',

        selectedMinute: null,
        selectedHour: '60',
        isSelectMinute: true,
        chartActive: 1,
        timeActive: 2,
        fullscreen: false,

        selectedTime: '60',
        routeSymbol: this.$route.query.symbol,
        symbol: Const.DEFAULT_MARGIN_SYMBOL,
        /*button show Favored List*/
        showFavoredList: false,
        activeFavoredItem: 2,
      };
    },
    computed: {
      ...mapGetters(['currentSymbol', 'checkContractAvailable']),
      ...mapGetters({ instrument: 'currentInstrument' }),
      tickSize() {
        return this.instrument.tick_size;
      }
    },
    watch: {
      '$route' (to, from) {
        this.routeSymbol = this.$route.query.symbol;
        this.updateSymbolAfterRouteChange();
      },

      currentSymbol(newValue) {
        this.onSymbolChanged(newValue);
        this.symbol = newValue;
      },

      resolution() {
        this.saveDataLocalStorage();
      },

      selectedMinute(newMinute) {
        if (!newMinute) {
          return;
        }
        this.timeActive = 1;
        this.resolution = newMinute;
        this.selectedHour = null;
      },

      selectedHour(newHour) {
        if (!newHour) {
          return;
        }
        this.timeActive = 2;
        this.resolution = newHour;
        this.selectedMinute = null;
      },

      selectedTime(newTime) {
        if (!newTime) {
          return;
        }
        this.resolution = newTime;
      },

      tickSize() {
        this.updateMinTick();
      }
    },
    methods: {
      ...mapActions(['saveAllContractName']),
      minutes() {
        return window._.map(Const.MINUTES, item => {
          const name = this.formatTime(item);
          return {id: `${item}`, name: name};
        });
      },

      hours() {
        return window._.map(Const.HOURS, item => {
          const name = this.formatTime(item);
          return {id: `${item}`, name: name};
        });
      },

      times() {
        const arr = [
          { id: 'D', name: window.i18n.t('exchange.basic.time.one_day') },
          { id: 'W', name: window.i18n.t('exchange.basic.time.one_week') },
          { id: 'M', name: window.i18n.t('exchange.basic.time.one_month') }
        ];
        return window._.concat(this.minutes(), this.hours(), arr);
      },

      formatTime(time) {
        time = parseInt(time);
        if (time >= 60) {
          let hours = time / 60;
          return window.i18n.t(hours > 1 ? 'exchange.basic.time.hours' : 'exchange.basic.time.hour', { number: hours });
        }
        return window.i18n.t(time > 1 ? 'exchange.basic.time.minutes' : 'exchange.basic.time.minute', { number: time });
      },

      onSymbolChanged(value) {
        this.updateMinTick();
        this.getDataLocalStorage();

        // update depth chart
        this.onChangedPairInDepthChart();
      },

      async updateMinTick() {
        let tickSize = this.tickSize;
        if (!tickSize) {
            return;
        }
        let minTick = '1';
        while (new BigNumber('1').div(minTick).gt(tickSize)) {
            minTick = minTick + '0';
        }
        this.minTick =  `${minTick}`;
      },

      onChangeResolution(time) {
        this.timeActive = this.getTimeActive(time);
        this.resolution = time;
        this.selectedMinute = null;
        this.selectedHour = null;
      },

      onCloseChart() {
        ConfirmationModal.show({
          type: 'warning',
          title: '',
          content: window.i18n.t('exchange.basic.chart.msg_close_chart'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.$emit('closeChart', this.keyChart);
            this.fullscreen = false;
          },
          onCancel: () => {
          }
        });
      },

      onChangedPairInDepthChart() {
        if (this.chartActive === 2) {
          this.$refs.depthChart.reload(this.symbol);
        }
      },

      redrawChart() {
        this.$nextTick(() => {
          // Re-render depth chart
          this.$refs.depthChart.redraw();
        });
      },

      getTimeActive(time) {
        switch(time) {
          case DAY:
            return 3;
          case WEEK:
            return 4;
          case MONTH:
            return 5;
        }
        throw "Selection tab not correct";
      },

      toggleFullScreen() {
        // this.$refs['fullscreen'].toggle();
        this.$refs.tradingView.getResolution()
        
      },

      fullscreenChange (fullscreen) {
        this.fullscreen = fullscreen;
      },

      saveDataLocalStorage() {
        const data = {
          selectedMinute      : this.selectedMinute,
          selectedHour        : this.selectedHour,
          resolution          : this.resolution,
          timeActive          : this.timeActive
        };
        LocalStorageUtils.saveItem('margin_trading_chart', data);
      },

      getDataLocalStorage() {
        const data = LocalStorageUtils.getItem('margin_trading_chart');
        if (!data) {
          return;
        }

        // console.log('getDataLocalStorage', data)

        this.selectedMinute = data.selectedMinute;
        this.selectedHour = data.selectedHour;
        this.resolution = data.resolution;
        this.timeActive = data.timeActive;
      },

      showIndicatorDialog() {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.showIndicatorDialog();
        }
      },
      
      onSaveTrandingChartConfiguration() {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.saveTradingChartConfiguration();
        }
      },

      onReloadTrandingChart() {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.reloadTrandingChart();
        }
      },

      showSetBarStyle(type) {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.setBarStyle(type);
        }
      },

      showChartPropertiesDialog() {
        if (!window._.isEmpty(this.$refs.tradingView)) {
          this.$refs.tradingView.showChartPropertiesDialog();
        }
      },

      onshowFavoredList() {
        this.showFavoredList = !this.showFavoredList;
      },

      updateSymbolAfterRouteChange(){
        // console.log('updateSymbolAfterRouteChange before', this.symbol, this.routeSymbol, this.checkContractAvailable(this.routeSymbol))
        //check if symbol from route is available
        if(this.symbol != this.currentSymbol && this.checkContractAvailable(this.routeSymbol)){
          this.symbol = this.routeSymbol
          // console.log('updateSymbolAfterRouteChange after', this.symbol, this.routeSymbol)
        }
      }
    },
    created() {
      this.resolution = this.selectedHour;
      this.updateSymbolAfterRouteChange();
      this.getDataLocalStorage();
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  $borderColor: #BBBBBB;
  $heightOption: 40px;
  #btn-upload-chart {
    position: relative;
    .tooltip-upload {
      position: absolute;
      top: 100%;
      margin-top: 5px;
      z-index: 5;
      background: rgba(255, 255, 255, 0.8);
      white-space: nowrap;
      left: 50%;
      font-size: 15px;
      padding: 5px 5px;
      line-height: 20px;
      border-radius: 3px;
      color: #000;
      width: 120px;
      text-align: center;
      margin-left: -60px;
      display: none;
    }
    &:hover {
      .tooltip-upload {
        display: block;
      }
    }
    
  }
  #btn-download-chart {
    position: relative;
    .tooltip-download {
      position: absolute;
      top: 100%;
      margin-top: 5px;
      z-index: 5;
      background: rgba(255, 255, 255, 0.8);
      white-space: nowrap;
      left: 50%;
      font-size: 15px;
      padding: 5px 5px;
      line-height: 20px;
      border-radius: 3px;
      color: #000;
      width: 120px;
      text-align: center;
      margin-left: -60px;
      display: none;
    }
    &:hover {
      .tooltip-download {
        display: block;
      }
    }
  }
  .chart-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    &>:first-child {
      width: 100%;
      height: 100%;
    }
    .fullscreen {
      background: $color-mirage !important;
      overflow-y: hidden !important;
    }
    .chart-option {
      min-width: 393px;
      background-color: $color-mirage;
      padding: 0px 10px;
      height: $heightOption;
      border-bottom: 1px solid $color-bright-gray-dark;
      line-height: 28px;
      font-size: $font-smaller;
      color: $color-grey;
      .time-option {
        height: 100%;
        float: left;
        div {
          float: left;
          height: 25px;
          margin-top: 7px;
          &.sc_search_select {
            margin-top: 0px;
          }
          select {
            height: 100%;
            padding-left: 5px;
            -webkit-appearance: none;
            border: none;
            border-right: 1px solid $borderColor;
            // padding-top: 3px;
            padding-bottom: 3px;
            -webkit-border-radius: 0px;
          }
        }
        .select-box-mul-chart {
          border-right: 1px solid $borderColor;
        }
        .time {
          &.div-time {
            border-right: 0px;
            display: inline-block;
            padding: 0px 8px;
            min-width: 35px;
            border-radius: 3px;
            margin-right: 3px;
            cursor: pointer;
            &:hover {
              background-color: $color-charade;
              color: $color-jungle-green;
            }
          }
          &.button-icon {
            cursor: pointer;
            font-size: $font-big;
            .icon_drop {              
              font-size: $font-mini-mini;
              float: right;
              line-height: 26px;
              margin-left: 5px;
            }
            &:hover {
              background-color: $color-charade;
              color: $color-jungle-green;
              .icon-download,
              .icon-upload {
                &:before {
                  color: $color-jungle-green;
                }
              }
            }
          }
          &.active-time {
            background-color: $color-charade;
            color: $color-jungle-green;
          }
        }
        .group_favered {
          position: relative;
          .btn-favored {
            border: 0px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            line-height: 20px;
            background-color: transparent;
            .icon {
              font-size: $font-big;
              display: inline-block;
              float: left;
              margin: 4px 0px;
            }
            .icon_drop {
              line-height: 25px;
              height: 25px;
              float: right;
              padding-top: 2px;
              font-size: 9px;
            }            
          }
          .box_favored_list {
            position: absolute;
            top: 100%;
            right: 0px;
            width: 195px;
            z-index: 999;
            margin-top: 5px;
            height: auto;
            .favored_lit {
              background-color: $color-white;
              margin: 0;
              padding: 5px 0px;
              li.item {
                display: block;
                width: 100%;
                line-height: 20px;
                cursor: pointer;
                padding: 3px 14px;
                font-size: $font-root;
                overflow: hidden;
                color: $color-grey-dark;
                &:hover {
                  color: $color-jungle-green;
                  .icon {
                    &:after,
                    &:before {
                      color: $color-jungle-green;
                    }
                  }
                }
                .icon {
                  display: inline-block;
                  float: left;
                  font-size: $font-big;
                  line-height: 20px;
                  margin-right: 5px;
                  width: 20px;
                }
                .name {
                  display: inline-block;
                  float: left;
                  line-height: 20px;
                  font-size: $font-root;
                  width: calc(100% - 25px);
                }
              }
            }
          }
          &.active,
          &:hover {
            background-color: $color-charade;
            .btn-favored {
              color: $color-jungle-green;
            }
          }
          &.active, {
            .btn-favored {
              .icon_drop {
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari prior 9.0 */
                transform: rotate(180deg); /* Standard syntax */
                padding-top: 0px;
                padding-bottom: 18px;
              }
            }
          }
        }
      }
      .chart-type {
        float: right;
        height: 100%;
        line-height: $heightOption;
        .total-aml-remaining {
          align-self: center;
          margin-right: -6px; 
          font-family: $font-family-roboto;
          font-size: $font-smaller;
          color: $color-green;
          span {
            font-family: $font-family-roboto-bold;
          }
        }
        .icon-close1 {
          color: $color-white;
          float: right;
          line-height: $heightOption;
          padding-left: 9px;
          padding-right: 9px;
          cursor: pointer;
          background: $color-grey;
        }
        .icon-screen {
          float: right;
          padding-left: 9px;
          padding-right: 9px;
          cursor: pointer;
          line-height: $heightOption;
        }
        .chart-tab {
          cursor: pointer;
          margin: 0;
          float: right;
          .tab-element {
            border-right: 0px;
            float: left;
            padding-left: 8px;
            padding-right: 8px;
            font-size: $font-smaller;
            color: $color-eden-light;
            font-family: $font-family-roboto-medium;
            &.active-tab {
              background: transparent;
              color: $color-jungle-green;
            }
          }
          a {
            color: $color-grey-dusty;
            &:hover {
              color: $color-corn;
            }
          }
        }
      }
    }
    .chart-content {
      height: calc(100% - #{$heightOption});
      width: 100%;
      .vue-highcharts {
        box-shadow: none !important;
        border: none !important;
      }
      &.chart-full-screen {
        height: calc(100% - #{$heightOption}) !important;
      }
    }
  }

  /*.button_drop_search span {
    color: $color-grey !important;
    font-size: $font-smaller !important;
  }*/

</style>

<style lang="scss">
  .chart-content {
    .tv-side-toolbar {
      background-color: red
    }
  }

  #select-chart{
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          font-size: 12px;
          color: #999;
          > span{
            font-size: 12px;
            color: #999;
          }
        }
      }
    }
  }
</style>
