<template>
  <div class="contest-group-btn" id="contest-modal">
    <button @click="onClickContest" class="btn btn-contest">{{ $t('margin.entry.contest') }}</button>

    <modal name="ContestTeamOverView" width="1000">
      <template slot="body">
        <div class="entry-teams-tab tab_entry">
          <div class="group_search_save clearfix">
            <div class="form_search_contracts">
              <div class="contest-infor">
                <div class="">
                  <div class="col-6">
                    <ul class="list_total clearfix">
                      <li class="item">
                        <div class="row">
                          <div class="col-xs-6">
                            <div class="name">{{$t('margin.entry.contest_start_at')}}:</div>
                            <div class="value">{{contest.start_contest_time | convertToLocalTime}}</div>
                          </div>
                          <div class="col-xs-6">
                            <div class="name">{{$t('margin.entry.contest_end_at')}}:</div>
                            <div class="value">{{contest.end_contest_time | convertToLocalTime}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item">
                        <div class="row">
                          <div class="col-xs-6">
                            <div class="name">{{$t('margin.minimum_member')}}:</div>
                            <div class="value">{{contest.minimum_member}}</div>
                          </div>
                          <div class="col-xs-6">
                            <div class="name">{{$t('margin.entry.maximum_member')}}:</div>
                            <div class="value">{{contest.maximum_member }}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item">
                        <div class="row">
                          <div class="col-xs-6">
                            <div class="name">{{$t('trading_information.minimum_amount')}}:</div>
                            <div class="value">{{contest.minimum_margin | formatCurrencyAmount(null,0)}}</div>
                          </div>
                          <div class="col-xs-6">
                            <div class="name">{{$t('margin.minimum_volume')}}:</div>
                            <div class="value">{{contest.minimum_volume | formatCurrencyAmount(null,0)}} {{
                              $t('margin.symbol.btc') }}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="item">
                        <div class="row">
                          <div class="col-xs-6">
                            <div class="name">{{$t('margin.entry.team_name') | upperCaseFirstLetter}}:</div>
                            <div class="value">
                              <div class="item_name_setting font14">
                                <span class="txt_email_setting font14">{{ teamName }}</span>
                                <span class="tooltip_name_setting font14">{{ teamName }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-6">
                            <div class="name">{{$t('margin.entry.team_id')}}:</div>
                            <div class="value">{{teamId}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item">
                        <div class="row">
                          <div class="col-xs-6">
                            <div class="name">{{$t('table.rank')}}:</div>
                            <div class="value">{{myRank ? myRank : '--'}}</div>
                          </div>
                          <div class="col-xs-6">
                            <div class="name">{{$t('margin.entry.team_member_number')}}:</div>
                            <div class="value">{{totalMember}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item">
                        <div class="row">
                          <div class="col-xs-6">
                            <span class="name">{{$t('margin.total_volume')}}</span>
                            <span class="value">{{total_volume | formatCurrencyAmount(null,0) }} {{ $t('margin.symbol.btc') }}</span>
                          </div>
                          <div class="col-xs-6">
                            <span class="name">{{$t('margin.total_roe')}}</span>
                            <span class="value">{{ ((total_roe )/ totalMember) | floatToPercent}}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="data-table">
              <data-table4 :getData="getTeamEntryMember" :limit="10" class="datatable_contracts" ref="dataTeamTable">
                <template slot-scope="props">
                  <th class="w_25" data-sort-field="margin_entry.email" v-if="isLeader">
                    {{$t('margin.entry.email')}}
                  </th>
                  <th class="w_25" data-sort-field="margin_entry.id" v-else>
                    {{$t('m_account.id')}}
                  </th>
                  <th class="w-25" data-sort-field="margin_entry_leaderboard_volume.total_volume">
                    {{$t('table.trading_volume')}}
                  </th>
                  <th class="w-25" data-sort-field="margin_entry_leaderboard.roe">
                    {{$t('margin.roe')}}
                  </th>
                </template>
                <template slot="body" slot-scope="props">
                  <tr>
                    <td v-if="isLeader">{{props.item.email}}</td>
                    <td v-else>{{props.item.id}}</td>
                    <td>{{props.item.total_volume | formatCurrencyAmount(null,0)}} {{ $t('margin.symbol.btc') }}</td>
                    <td>{{props.item.roe | floatToPercent}}</td>
                  </tr>
                </template>
              </data-table4>
            </div>
          </div>
        </div>

      </template>
    </modal>
    <modal name="ContestSingleOverView" width="1000">
      <template slot="body">
        <div class="form_search_contracts">
          <div class="contest-infor">
            <div class="">
              <div class="col-6">
                <ul class="list_total clearfix">
                  <li class="item">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="name">{{$t('margin.entry.contest_name')}}: </span>
                        <span class="value">{{contest.name}}</span>
                      </div>
                      <div class="col-xs-6">
                        <span class="name">{{$t('entry.number_of_users')}}: </span>
                        <span class="value">{{contest.number_of_users}}</span>
                      </div>
                    </div>
                  </li>
                  <li class="item">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="name">{{$t('margin.entry.contest_start_at')}}: </span>
                        <span class="value">{{contest.start_contest_time | convertToLocalTime}}</span>
                      </div>
                      <div class="col-xs-6">
                        <span class="name">{{$t('margin.entry.contest_end_at')}}: </span>
                        <span class="value">{{contest.end_contest_time | convertToLocalTime}}</span>
                      </div>
                    </div>
                  </li>
                  <li class="item">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="name">{{$t('table.rank')}}: </span>
                        <span class="value">{{myRank ? myRank : '--'}}</span>
                      </div>
                      <div class="col-xs-6">
                        <span class="name">{{$t('margin.roe')}}: </span>
                        <span class="value">{{roe | floatToPercent}}</span>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import Modal from '@/components/shared_components/common/Modal.vue';
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import { mapState } from 'vuex';


  export default {
    name: "Contest",
    components: {
      Modal,
    },
    props: {
      isLeader: {},
      contest: {},
    },
    data() {
      return {
        teamName: "",
        teamId: "",
        totalMember: 0,
        totalRoe: 0,
        totalRoeOut: 0,
        totalVolume: 0,
        totalVolumeOut: 0,

        roe: 0,
        myRank: 0,

      }
    },
    computed: {
      ...mapState(['user']),
      total_roe()  {
        return new BigNumber(`${this.totalRoe || 0}`).sub(`${this.totalRoeOut || 0}`).toString();
      },
      total_volume() {
        return new BigNumber(`${this.totalVolume || 0}`).sub(`${this.totalVolumeOut || 0}`).toString();
      }
    },
    methods: {
      getEventHandlers() {
        return {
          EntryTeamUpdated: this.onUpdateTeamTrading,
        };
      },
      onUpdateTeamTrading: function(){
        this.getMyTeamInformation(false);
        if(this.$refs.dataTeamTable){
          this.$refs.dataTeamTable.refresh();
        }
      },
      getTeamEntryMember: function ($params) {
        return rf.getRequest('EntryRequest').getTeamEntryMember($params).then((response) => {
          this.totalMember = response.data.total;
          return response;
        });
      },
      getMyTeamInformation: async function (socket = true) {
        const response = await rf.getRequest('EntryRequest').getMyTeamInformation();
        if (response.data) {
          this.teamName = window._.get(response, 'data.team.name');
          this.teamId = window._.get(response, 'data.team.id');
          this.totalRoe = window._.get(response, 'data.sum_roe');
          this.totalRoeOut = window._.get(response, 'data.roe_user_out');
          this.roe = window._.get(response, 'data.roe');
          this.totalVolume = window._.get(response, 'data.team.total_volume');
          this.totalVolumeOut = window._.get(response, 'data.team.volume_user_out');
          this.myRank = response.data.rank;
        }
        if(socket && this.teamId){
          window.GlobalSocket.listenForEntryTeamUpdated(this.teamId);
        }else{
          window.GlobalSocket.listenForEntryUpdated(this.user.id);
        }
      },
      onClickContest: function () {
        if (this.contest.is_team_battle) {
          window.CommonModal.show("ContestTeamOverView");
        } else {
          window.CommonModal.show("ContestSingleOverView");
        }
      }
    },
    created() {
      this.getMyTeamInformation();
    },
    beforeDestroy() {
      if(this.teamId){
        window.Echo.channel(`Entry.Team.${this.teamId}.Updated`).unbind();
        window.Echo.leave(`Entry.Team.${this.teamId}.Updated`);
      }else{
        window.Echo.channel(`Entry.${this.user.id}.Updated`).unbind();
        window.Echo.leave(`Entry.${this.user.id}.Updated`);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  .contest-group-btn {
    .btn-contest {
      display: block;
      float: right;
      width: 85px;
      height: 32px;
      margin-left: 10px;
      border-radius: 3px !important;
      background-color: #FFBC00;
      letter-spacing: 0.9px;
      color: $color-white;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      font-size: $font-smaller;

      &:hover, &:active {
        background-color: $color-blue-custom;
        color: $color-white;
      }
    }
  }

  .item_name_setting {
    display: inline-block;
    float: left;
    position: relative;

    .txt_email_setting {
      display: block;
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      .tooltip_name_setting {
        transition: 0.5s;
        display: inline-block;
      }
    }

    .tooltip_name_setting {
      position: absolute;
      top: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      word-break: break-all;
      white-space: pre-wrap;
      width: 300px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
  }
</style>
<style lang="scss">
  @import "@/assets/sass/variables";

  #contest-modal {
    .modal-content {
      background-color: $color-alabaster !important;

      .form_search_contracts {
        background-color: #ffffff;
        display: block;
        width: 100%;
        float: left;
        margin-bottom: 10px;
      }

      .contest-infor {
        padding: 5px 15px 5px 15px;
      }

      .list_total {
        margin-bottom: 15px;

        .item {
          display: block;
          width: 100%;
          margin-top: 5px;
          margin-bottom: 5px;
          line-height: 20px;
          float: left;

          .name {
            display: block;
            float: left;
            margin-right: 15px;
            font-size: $font-root;
            color: $color-grey-dusty;
            text-transform: capitalize;
          }

          .value {
            font-weight: 500;
            font-family: $font-family-roboto-medium;
            font-size: $font-root;
            word-break: break-word;

            .group_2 {
              width: 100px;
              display: block;
              position: relative;

              .input_value {
                width: 100%;
                height: 32px;
                line-height: 20px;
                padding: 5px 10px;
                font-weight: 500;
                padding-right: 25px;
                font-family: $font-family-roboto-medium;
                font-size: $font-root;
              }

              .unit {
                display: inline-block;
                position: absolute;
                top: 0px;
                right: 0px;
                line-height: 20px;
                padding: 5px 6px;
              }
            }
          }

          .value1 {
            display: flex;
            overflow: hidden;
            font-weight: 500;
            font-family: $font-family-roboto-medium;
            font-size: $font-root;

            .group_input_value {
              width: 100px;
              display: block;
              position: relative;

              .input_value {
                width: 100%;
                height: 32px;
                line-height: 20px;
                padding: 5px 10px;
                font-weight: 500;
                padding-right: 25px;
                font-family: $font-family-roboto-medium;
                font-size: $font-root;
              }

              .unit {
                display: inline-block;
                position: absolute;
                top: 0px;
                right: 0px;
                line-height: 20px;
                padding: 5px 6px;
              }
            }

            .group_input_value1 {
              width: 50px;
              display: block;
              position: relative;

              .input_value {
                width: 100%;
                height: 32px;
                line-height: 20px;
                padding: 5px 10px;
                font-weight: 500;
                padding-right: 25px;
                font-family: $font-family-roboto-medium;
                font-size: $font-root;
              }

              .input_value1 {
                width: 100%;
                height: 32px;
                line-height: 20px;
                padding: 5px 0px;
                font-weight: 500;
                font-family: $font-family-roboto-medium;
                font-size: $font-root;
              }

              .unit {
                display: inline-block;
                position: absolute;
                top: 0px;
                right: 0px;
                line-height: 20px;
                padding: 5px 6px;
              }
            }
          }
        }
      }

      table {
        background-color: #ffffff;

        th {
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 700;
          background-color: #ffffff;
        }

        td {
          text-align: left;
          font-weight: bold;
        }
      }
    }
  }
</style>