export default {
  data() {
    return {
      sizeScreen: window.innerWidth,
    };
  },

  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
