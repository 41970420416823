<template>
  <div class="table_witdhdrar_usd" id="table_witdhdrar_usd">
    <data-table3
      :getData="getData"
      :limit="10"
      :widthTable="'100%'"
      ref="datatable"
      :msgEmptyData="msgEmptyData"
    >
      <template slot-scope="props">
        <th style="width: 29% ; min-width : 140px" data-sort-field="created_at" @click="props.echoclick">
          {{$t('funds.deposit_usd.transaction_history_table.time')}}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th style="width: 24% ; min-width : 200px" data-sort-field="amount" class="cl_amount" @click="props.echoclick">
          <!-- <span class="sort">{{$t('funds.deposit_usd.transaction_history_table.bank')}}</span>
          &nbsp;/&nbsp;
          <span class="sort">{{$t('funds.deposit_usd.transaction_history_table.account')}}</span>-->
          <span
            class="sort"
          >{{$t('funds.deposit_usd.transaction_history_table.amount')}}</span>
          &nbsp;/&nbsp;
          <span
            class="sort"
          >{{$t('funds.deposit_usd.transaction_history_table.status')}}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th style="width: 46% ; min-width : 350px">
          <span class="sort">{{$t('funds.deposit_usd.transaction_history_table.bank')}}</span>
          &nbsp;/&nbsp;
          <span
            class="sort"
          >{{$t('funds.deposit_usd.transaction_history_table.account')}}</span>
        </th>
        <!-- <th style="width: 20%" class="t-right">
          <span class="sort">{{$t('funds.deposit_usd.pending_transaction.code')}}</span>
        </th> -->
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td style="width: 20%;" class="t-left">
            <span>{{ props.item.created_at | orderDate }}</span>
            <span class="text-sub">{{ props.item.created_at | orderTime }}</span>
            <!-- <span>{{props.item}}</span> -->
            
          </td>
          <td style="width: 30%;" class="t-left">
            <span>{{ props.item.amount | abs | formatUsdAmount }}</span>
            <span v-if="props.item.status=='pending'" class="pending">{{ props.item.status | transactionStatus}}</span>
            <span v-if="props.item.status=='success'" class="success">{{ props.item.status | transactionStatus}}</span>
            <span v-if="props.item.status=='cancelled'" class="cancelled">{{ props.item.status | transactionStatus}}</span>
            <span v-if="props.item.status=='rejected'" class="rejected">{{ props.item.status | transactionStatus}}</span>
            <!-- <span>{{props.item}}</span> -->
          </td>
          <td style="width: 50%;" class="t-left">
            <!-- <span>USD {{ props.item.amount | abs | formatUsdAmount }}</span>
            <span>{{ props.item.status | transactionStatus}}</span> -->
            <!-- <span v-if="props.item.bank_name && props.item.bank_branch">{{ `${props.item.bank_name} - ${props.item.bank_branch}` }}</span>
            <span v-if="props.item.account_name && props.item.account_no" >{{ `${props.item.account_name} - ${props.item.account_no}` }}</span> -->

            <div class="item_email_user">
              <span class="text-bold text-not-bold-account txt_email_user">{{ `${props.item.bank_name || '--'} -  ${props.item.bank_branch || '--'}` }}</span>
              <span class="text-not-bold text-bold-account txt_email_user">{{ `${props.item.account_name || '--'} -  ${props.item.account_no || '--'}` }}</span>
              <span class="tooltip_email_user">
                <span class="content_tooltip">{{ props.item.bank_name }}{{' - '}}{{props.item.bank_branch  }}</span>
                <br>
                <span class="text-not-bold">{{ props.item.account_name }}{{' - '}}{{props.item.account_no  }}</span>
              </span>
            </div>
          </td>
          <!-- <td style="width: 20%;" class="t-left">
            <span>{{ props.item.code}}</span> -->
            <!-- <span v-bind:class="[{cancel: isPending}]"></span>
             -->
             <!-- <button v-if="props.item.status== 'pending'" class="cancel" @click="cancel(props.item.id)">{{$t('notice.cancel')}}</button> -->
          <!-- </td> -->
        </tr>
      </template>
    </data-table3>
    
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'

export default {
  data() {
    return {
      msgEmptyData: window.i18n.t("funds.withdrawals.no_usd_data"),
    };
  },

  methods: {
    async getData(params) {
      const meta = {
        type: "withdraw"
      };
      // params.limit = 5;
      return rf
        .getRequest("TransactionRequest")
        .getUsdTransactions(Object.assign({}, params, meta));
    },
    getSocketEventHandlers() {
      return {
        TransactionCreated: this.onTransactionCreated
      };
    },
    cancel(transactionId) {
      // console.log(transactionId);
        rf.getRequest('TransactionRequest').cancelUsdDepositTransaction(transactionId)
        .then(res => {

        })
        .catch(function (error) {

        });
      },

    onTransactionCreated() {
      this.$refs.datatable.refresh();
    },
  },

  created() {
    this.$on('UPDATED_LOCALE', () => {
       this.msgEmptyData = window.i18n.t("funds.withdrawals.no_usd_data")
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.f-left {
  text-align: left;
}
.t-cnter {
  text-align: center;
}
.f-right {
  float: right;
  text-align: right;
}
.pending{
  color: $text-color-yellow;
}
.cancelled{
  color: $text-color-red;
}
.rejected{
  color: $text-color-red;
}
.success{
  color: $text-color-jade;
}
.cancel{
  width: 60px !important;
  height: 20px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
  // font-style: normal;
  // font-stretch: normal;
  // line-height: 6;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  color: $color-white;
  background-color: $color-jungle-green;
}
th.t-left::after{
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

table tr td {
  vertical-align: middle;
}

.table_witdhdrar_usd {
    /* background-color: $color-white; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  .tableContainer {    
    table {
      border: 0px;
      thead {
        tr {
          th {
            height: 40px;
            padding-left: 10px;
            padding-right: 0px;
            background: var(--color-bg-select);
            font-size: 14px;
            text-align: left;
            color: var(--text-close);
            &:first-child {
              cursor: pointer;
              border-radius: 8px 0px 0px 8px;
              text-align: left;
              padding: 23px 15px;
            }
            &:last-child {
              border-radius: 0px 8px 8px 0;
            }
          }
        }
      }
      tbody {
        tr {
          background: var(--background-color-primary);
          td {
            text-align: left;
            padding-left: 10px;
            padding-right: 0px;
            line-height: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            color: $color-grey-dusty;
            font-size: 14px;
            // &:last-child {
            //   text-align: right;
            //   padding-right: 20px;
            //   padding-left: 0px;
            // }
            .text-bold{
              font-size: $font-small;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: $text-default;
            }
            .text-not-bold {
              font-size: $font-medium-bigger!important;
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: var(--text-close) !important;
            }
            span {
              display: block;
              width: 100%;
              font-weight: 400;
              font-size: $font-medium-bigger;
              &:first-child {
                color: var(--text-primary-color);
                font-weight: 400;
                font-size: $font-big;
              }
            }
            .text-sub{
              color: var(--text-close);
            }
          }
          // &:nth-child(odd) {
          //   background: $row-coin-value-background-color;
          // }
          // &:nth-child(even) {
          //   background: $background-default;
          // }
        }
      }
      .text-bold-account{
        text-overflow: ellipsis;
        width: 250px;
      }
      .text-not-bold-account{
        color: $text-grey-dusty;
        text-overflow: ellipsis;
        width: 250px;
      }
    }
  }
}
table {
  border: 0px;
}
// .cl_amount {
//   &[data-sort-order="asc"] {
//     color: $color-jungle-green;
//     &::after {
//       content: "\e907" !important;
//     }
//   }
//   &[data-sort-order="desc"] {
//     color: $color-jungle-green;
//     &::after {
//       content: "\e906" !important;
//     }
//   }
// }
.item_email_user {
  display: block;
  position: relative;
  .txt_email_user {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tooltip_email_user {
    position: absolute;
    top: -28px;
    left: 8px;
    line-height: 20px;
    padding: 5px 20px;
    // left: 100%;
    background: $bgr-tooltip;
    white-space: nowrap;
    width: 350px !important;
    z-index: 100;
    font-size: $font_root;
    font-weight: 500;
    color: $color-white;
    padding: 10px;
    transition: 0.5s;
    display: none !important;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
    span {
      word-wrap: break-word;
      white-space: normal;
    }
    &:after {
      right: 100%;
      top: 30px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
    .content_tooltip {
      color: $color-white !important;
      padding-right: 10px;
    }
  }
  &:hover {
    .tooltip_email_user {
      display: block !important;
      transition: 0.5s;
    }
  }
}
</style>


<style lang="scss">
  #table_witdhdrar_usd {
    .tableContainer {
      width: 100%;
      // min-height: 500px;
      position: relative;
      /* background: var(--background-color-primary); */
      @media screen and (max-width: 768px){
        overflow: auto; 
        white-space: nowrap;
      }
    }
    .VuePagination {
      width: 100%;
    }
    td {
      overflow: unset;
    }
  }
</style>