<template>
  <div id="CalculatorModal">
    <modal :name="modalName" width="680">
      <template slot="body">
        <div class="header_modal">
          <h2 class="title_head"><span>{{ $t('margin.adjust_risk_limit', { contract: this.currentSymbol ? this.currentSymbol : 'BTCUSD' }) }}</span></h2>
        </div>
          <div class="clearfix"></div>
          <div class="des_header_modal" v-html="$t('margin.des_risk_limit', {
            base: riskLimits[0] && riskLimits[0].riskLimit,
            step: riskLimits[0] && riskLimits[1].riskLimit - riskLimits[0].riskLimit,
            stepPercent })">
        </div>
        <!-- <div class="clearfix clearfix-10"></div>
          <div class="des_header_modal" v-html="$t('margin.des_more_risk_limit', { link: '#' })">
        </div> -->
        <div class="clearfix clearfix-20"></div>
        <div class="body_modal">
          <h4 class="title_change">{{ $t('margin.change_risk_limit') }}</h4>
          <div class="clearfix"></div>
          <div class="riskLimitSlider clearfix">
            <div class="orderControlsInput clearfix">
              <div class="rc-slider-with-marks">
                <div class="rc-slider-step">
                  <span class="rc-slider-dot" 
                    v-for="item in sliderRiskLimit" 
                    :key='item.left'
                    :style="{left: item.left +'%'}"
                    @click="activeSliderHandle(item)"
                    ></span>
                </div>
                <div role="slider" tabindex="0" aria-valuemin="0" aria-valuemax="9" aria-valuenow="2" aria-disabled="false" class="rc-slider-handle" :style="{'left': currentRiskLimit.left + '%'}"></div>
                <div class="rc-slider-mark">
                  <span class="rc-slider-mark-text" 
                    v-for="item in sliderRiskLimit" 
                    :key='item.left'
                    v-bind:style="{left: item.left +'%', width: item.width +'%'}"
                    @click="activeSliderHandle(item)"
                    ><span :class="{'text-active': item.isActive}">{{ item.value }}</span></span>
                </div>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <div class="table_modal">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t('margin.risk_limit') }}</th>
                  <th>{{ $t('margin.maintenance_margin') }}</th>
                  <th>{{ $t('margin.initial_margin') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('margin.current') }}</td>
                  <td>{{ position.risk_limit | formatMarginNumber }} {{ $t('margin.symbol.btc') }}</td>
                  <td>{{ riskLimits[0] && position.required_init_margin_percent - riskLimits[0].maintMargin | toPercent | formatMarginNumber }}%</td>
                  <td>{{ position.required_init_margin_percent | toPercent | formatMarginNumber }}%</td>
                </tr>
                <tr>
                  <td><strong>{{ $t('margin.new') }}</strong></td>
                  <td><strong>{{ currentRiskLimit.value | formatMarginNumber }} {{ $t('margin.symbol.btc') }}</strong></td>
                  <td><strong>{{ currentRiskLimit.maintMargin | toPercent | formatMarginNumber }}%</strong></td>
                  <td><strong>{{ currentRiskLimit.initMargin | toPercent | formatMarginNumber }}%</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="footer_model">
          <button class="btn btn-cancel" @click="closeModal()">{{ $t('margin.cancel') }}</button>
          <button :key="index" class="btn btn-confirm" :class="{'disabled': disableBtn }" @click="handle()">{{ $t('margin.confirm') }}</button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import Modal from '@/components/shared_components/common/Modal.vue';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      Modal,
    },
    computed: {
      ...mapGetters({ balance: 'mamBalance', currentSymbol: 'currentSymbol', position: 'mamPosition', riskLimits: 'riskLimits', contract: 'contract' }),
      stepPercent () {
        if (this.riskLimits[0]) {
          return new BigNumber(`${this.riskLimits[0].maintMargin || 0}`).mul(100).toString();
        }
        return 0;
      },
      disableBtn () {
        if (this.currentRiskLimit && this.position && new BigNumber(`${this.currentRiskLimit.value || 0}`).comparedTo(`${this.position.risk_limit || 0}`) == 0) {
          return true;
        }
        return false;
      }
    },
    data() {
      return {
        modalName:'RiskLimitModal',
        sliderRiskLimit: [
          {width: 10, left: 0, value: 200, isActive: false},
          {width: 10, left: 11.1111, value: 300, isActive: false},
          {width: 10, left: 22.2222, value: 400, isActive: false},
          {width: 10, left: 33.3333, value: 500, isActive: false},
          {width: 10, left: 44.4444, value: 600, isActive: false},
          {width: 10, left: 55.5556, value: 700, isActive: false},
          {width: 10, left: 66.6667, value: 800, isActive: false},
          {width: 10, left: 77.7778, value: 900, isActive: false},
          {width: 10, left: 88.8889, value: 1000, isActive: false},
          {width: 10, left: 100, value: 1100, isActive: false},
        ],
        currentRiskLimit: {},
        index: 0,
      }
    },
    watch: {
      async currentSymbol() {
        await this.getListRiskLimit({ symbol: this.currentSymbol ? this.currentSymbol : 'BTCUSD'});
      },
      riskLimits() {
        this.resetActive();
        this.applyRiskLimits();
        this.index++;
      }
    },
    methods: {
      ...mapActions(['getListRiskLimit', 'updateMamRiskLimit']),
      getEventHandlers() {
        return {
          hideCommonModal: this.onHideCommonModal,
        };
      },
      onHideCommonModal(modalName) {
        if(modalName == 'RiskLimitModal') {
          this.resetActive();
          this.applyRiskLimits();
        }
      },
      closeModal () {
        window.CommonModal.hide('RiskLimitModal');
        this.resetActive();
        this.applyRiskLimits();
      },
      activeSliderHandle (item) {
        this.resetActive();
        item.isActive = true;
        for (let i = 0; i < 10; i++) {
          if (new BigNumber(`${item.value || 0}`).comparedTo(`${this.riskLimits[i].riskLimit || 0}`) == 0) {
            this.currentRiskLimit = { ...item, ...this.riskLimits[i] };
          }
        }
      },
      resetActive () {
        this.sliderRiskLimit = _.each(this.sliderRiskLimit, function (item) {
          item.isActive = false;
        })
      },
      applyRiskLimits () {
        for (let i = 0; i < 10; i++) {
          this.sliderRiskLimit[i].value = this.riskLimits[i].riskLimit;
          if (this.position && new BigNumber(`${this.position.required_init_margin_percent || 0}`).comparedTo(`${this.riskLimits[i].initMargin || 0}`) == 0) {
            this.sliderRiskLimit[i].isActive = true;
            this.currentRiskLimit = { ...this.sliderRiskLimit[i], ...this.riskLimits[i] };
          }
        }
      },
      async handle () {
        try {
          let dataUpdate = {
            symbol: this.currentSymbol,
            init_margin: this.currentRiskLimit.initMargin,
            maint_margin: this.currentRiskLimit.maintMargin,
            risk_limit: this.currentRiskLimit.value,
          };
          await this.updateMamRiskLimit(dataUpdate);
          this.closeModal();
        } catch(e) {
          if (e.response && e.response.data) {
            Message.error(e.response.data.message, {}, { position: 'bottom_left' });
          }
        }
      },
    },
    async created () {
      await this.getListRiskLimit({ symbol: this.currentSymbol ? this.currentSymbol : 'BTCUSD'});
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .clearfix-10 {
    display: block;
    clear: both;
    height: 10px;
    width: 100%;
  }
  .clearfix-20 {
    display: block;
    clear: both;
    height: 20px;
    width: 100%;
  }
  button.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
  #CalculatorModal {
    .header_modal {
      line-height: 20px;
      padding: 24px 25px 14px 25px;
      .title_head {
        font-weight: 500;
        text-align: center;
        margin: 0px;
        line-height: 30px;
        position: relative;
        font-family: $font-family-roboto-medium;
        font-size: $font-big-20;
        color: $color-eden;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          top: 50%;
          margin-top: 1px;
          position: absolute;
          left: 0px;
          background-color: $color-light-grey;
        }
        >span {
          display: inline-block;
          padding: 5px 17px;
          position: relative;
          z-index: 1;
          background-color: $color-white;
        }
      }
    }
    .des_header_modal {
      display: block;
      padding: 0px 40px;
      text-align: center;
      font-size: $font-root;
      color: $color-grey-dark;
      line-height: 20px;
      strong {
        font-family: $font-family-roboto-bold;
        font-weight: 500;
      }
      a {
        color: $color-jungle-green;
      }
    }
    .body_modal {
      display: block;
      padding: 16px 25px;
      background-color: $color-alabaster;
      color: $color-grey-dark;
      font-weight: 500;
      .title_change {
        text-align: center;
        margin: 0px;
        font-family: $font-family-roboto-medium;
        font-size: $font-medium-bigger;
        color: $color-black;
      }
      .table_modal {
        margin-top: 10px;
        text-align: center;
        table {
          border: 1px solid $color-alto;
          margin: 0px;
          thead {
            tr {
              th {
                border-bottom: 0px;
                text-align: center;
                font-weight: 500;
                border: 0px;
                border-left: 1px solid $color-alto;
                background-color: $color-bright-gray;
              }
            }
          }
          tbody {
            tr {
              td {
                border: 0px;
                border-left: 1px solid $color-alto; 
              }
            }
            tr:nth-child(2) {
              background-color: $color-bright-gray;
            }
          }
          strong {
            font-family: $font-family-roboto-bold;
            font-weight: 500;
          }
        }
      }
    }
    .riskLimitSlider {
      margin-top: 15px;
      background-color: #daece8;
      border-radius: 3px;
      font-size: $font-mini;
      color: $color-grey-dusty;
      padding: 30px 40px 41px 30px;
      .orderControlsInput {
        .rc-slider-with-marks {
          position: relative;
          .rc-slider-handle {
            position: absolute;
            margin-left: 0px;
            margin-top: -8px;
            width: 12px;
            height: 12px;
            cursor: pointer;
            border-radius: 50%;
            border: solid 3px $color-jungle-green;
            background-color: $color-white;
            z-index: 3;
          }
        }
        .rc-slider-step {
          height: 4px;
          background-color: #a0a0a0;
          position: relative;
          .rc-slider-dot {
            position: absolute;
            display: block;
            width: 12px;
            height: 12px;
            background-color: #a0a0a0;
            top: -4px;
            left: 0px;
            border-radius: 50%;
            z-index: 2;
            cursor: pointer;
          }
        }
      }
      .rc-slider-mark {
        position: relative;
        .rc-slider-mark-text {
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
          font-size: $font-smaller;
          text-align: center;
          margin-left: -25px;
          cursor: pointer;
          &:active {
            color: $color-jungle-green;
            font-family: $font-family-roboto-bold;
            text-decoration: underline;
          }
        }
      }
    }
    .text-active {
      color: $color-jungle-green;
      font-family: $font-family-roboto-bold;
      text-decoration: underline;
    }
    .footer_model {
      text-align: center;
      padding: 20px 0px 35px 0px;
      background-color: $color-alabaster;
      .btn-cancel {
        min-width: 130px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        height: 50px;
        text-align: center;
        background-color: transparent;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        line-height: 20px;
        padding: 15px 5px;
        margin-right: 18px;
        font-family: $font-family-roboto-bold;
        font-size: $font-root;
        color: $color-jungle-green;
        &:hover {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
        }
      }
      .btn-confirm {
        min-width: 130px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        height: 50px;
        text-align: center;
        background-color: $color-jungle-green;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        line-height: 20px;
        padding: 15px 5px;
        font-family: $font-family-roboto-bold;
        font-size: $font-root;
        color: $color-white;
        &:hover {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
        }
      }
    }
  }
</style>
