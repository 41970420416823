import Numeral from '../lib/numeral';
import BigNumber from 'bignumber.js';
import Utils from './Utils';

export default {

  validateOrderInput: function (data, coinSetting, isConvert) {
    const minPrice = coinSetting.price_precision || 0.0001;
    const minTotal = coinSetting.minimum_amount || 0.0001;
    const minQuantity = coinSetting.minimum_quantity || 0.0001;
    const minQuantityMarket = data.priceMarket? minQuantity*data.priceMarket : minQuantity
    const quantityMarket = data.priceMarket? Number(data.quantity)*data.priceMarket : data.quantity

    this.validateBalance(data, isConvert);
    switch(data.type){
      case 'limit':
        this.validatePrice(data.price, data.trade_type, minPrice, isConvert, coinSetting.coin.toString().toUpperCase());
        this.validateAmount(data.quantity, data.trade_type, minQuantity, isConvert, coinSetting.coin.toString().toUpperCase());
        if (isConvert) {
          this.validateTotalConvert(data.price, data.quantity, minTotal, data.total, coinSetting.currency.toString().toUpperCase());
        } else {
          this.validateTotal(data.price, data.quantity, minTotal, data.total);
        }
        break;
      case 'market':
        this.validateAmount(quantityMarket, data.trade_type,minQuantityMarket, isConvert, coinSetting.coin.toString().toUpperCase());
        break;
      case 'stop_limit':
        this.validateStopPrice(data.base_price, data.trade_type, minPrice);
        this.validateLimit(data.price, data.trade_type, minPrice);
        this.validateAmount(data.quantity, data.trade_type, minQuantity);
        this.validateTotal(data.price, data.quantity, minTotal, data.total);
        break;
      case 'stop_market':
        this.validateStopPrice(data.base_price,data.trade_type, minPrice);
        this.validateAmount(data.quantity, data.trade_type, minQuantity);
        break;
      default: {
        throw {field: null, message: 'Order type is invalid.'};
      }
    }
  },

  validateBalance(data, isConvert) {
    const balance = parseFloat(data.balance);
    if (window._.isEmpty(data.balance) || balance <= 0) {
      this.validateEmpty(null, null, 'messages.error.balance_insufficient1');
    }

    if (data.trade_type === 'buy') {
      if (data.type === 'limit') {
        if (new BigNumber(`${data.quantity || 0}`).mul(`${data.price || 0}`).comparedTo(data.balance) > 0) {
          if (isConvert) {
            this.validateEmpty(null, null, 'messages.error.balance_insufficient1');
          } else {
            this.validateEmpty(null, null, 'messages.error.balance_insufficient');
          }
        }
      }
    }

    if (data.trade_type === 'sell') {
      if (new BigNumber(`${data.quantity || 0}`).comparedTo(data.balance) > 0) {
        if (isConvert) {
          this.validateEmpty(null, null, 'messages.error.balance_insufficient1');
        } else {
          this.validateEmpty(null, null, 'messages.error.balance_insufficient');
        }
      }
    }
  },

  validatePrice(value, trade_type, minValue, isConvert, underlyingAsset) {
    const fieldName = 'price';
    if(trade_type === 'buy') {
      this.validateEmpty(fieldName, value, 'messages.error.buy.empty_price', true);
    } else {
      this.validateEmpty(fieldName, value, 'messages.error.sell.empty_price', true);
    }
    if (isConvert) {
      this.validateMinimumValueConvert(fieldName, value, minValue, 'messages.error.minimum_price', underlyingAsset);
    } else {
      this.validateMinimumValue(fieldName, value, minValue, 'messages.error.minimum_price');
    }
  },

  validateLimit(value, trade_type, minValue) {
    const fieldName = 'price';
    if(trade_type === 'buy') {
      this.validateEmpty(fieldName, value, 'messages.error.buy.empty_limit', true);
    } else {
      this.validateEmpty(fieldName, value, 'messages.error.sell.empty_limit', true);
    }
    this.validateMinimumValue(fieldName, value, minValue, 'messages.error.minimum_limit');
  },

  validateAmount(value, trade_type, minValue, isConvert, underlyingAsset) {
    const fieldName = 'amount';
    if(trade_type === 'buy') {
      this.validateEmpty(fieldName, value, 'messages.error.buy.empty_quantity', true);
    } else {
      this.validateEmpty(fieldName, value, 'messages.error.sell.empty_quantity', true);
    }
    if (isConvert) {
      this.validateMinimumValueConvert(fieldName, value, minValue, 'messages.error.minimum_quantity_convert', underlyingAsset);
    } else {
      this.validateMinimumValue(fieldName, value, minValue, 'messages.error.minimum_quantity');
    }
  },

  validateTotal(price, amount, minValue, total) {
    const fieldName = 'total';
    this.validateEmpty(fieldName, total, 'messages.error.empty_total', true);
    total = new BigNumber(`${price}`).mul(`${amount}`);
    const bgMinValue = new BigNumber(`${minValue}`);

    if (total.lt(bgMinValue)) {
      const value = Utils.trimEndZero(new BigNumber(`${minValue}`).toFixed(20));
      throw { field: fieldName, message: window.i18n.t('messages.error.minimum_total', {minimum: value}) };
    }
  },

  validateTotalConvert(price, amount, minValue, total, quote_asset) {
    const fieldName = 'total';
    this.validateEmpty(fieldName, total, 'messages.error.empty_total', true);
    total = new BigNumber(`${price}`).mul(`${amount}`);
    const bgMinValue = new BigNumber(`${minValue}`);

    if (total.lt(bgMinValue)) {
      const value = Utils.trimEndZero(new BigNumber(`${minValue}`).toFixed(20));
      throw { field: fieldName, message: window.i18n.t('messages.error.minimum_total_convert', {minimum: value, quote_asset: quote_asset}) };
    }
  },

  validateStopPrice(value, trade_type, minValue){
    const fieldName = 'stop_price';
    if(trade_type === 'buy') {
      this.validateEmpty(fieldName, value, 'messages.error.buy.empty_base_price', true);
    } else {
      this.validateEmpty(fieldName, value, 'messages.error.sell.empty_base_price', true);
    }
    this.validateMinimumValue(fieldName, value, minValue, 'messages.error.minimum_base_price');
  },

  validateEmpty(field, value, msgKey, disableSubMessage) {
    if (!value) {
      throw { field: field, message: window.i18n.t(msgKey) , disableSubMessage};
    }
  },

  validateMinimumValue(field, value, minValue, msgKey) {
    const bgValue = new BigNumber(`${value || 0}`);
    const bgMinValue = new BigNumber(`${minValue || 0}`);

    if(bgValue.lt(bgMinValue)) {
      const value = Utils.trimEndZero(new BigNumber(`${minValue}`).toFixed(20));
      throw { field: field, message: window.i18n.t(msgKey, {minimum: value}) };
    }
  },
  validateMinimumValueConvert(field, value, minValue, msgKey, underlyingAsset) {
    const bgValue = new BigNumber(`${value || 0}`);
    const bgMinValue = new BigNumber(`${minValue || 0}`);

    if(bgValue.lt(bgMinValue)) {
      const value = Utils.trimEndZero(new BigNumber(`${minValue}`).toFixed(20));
      throw { field: field, message: window.i18n.t(msgKey, {minimum: value, underlying_asset: underlyingAsset}) };
    }
  },

};
