import BaseModelRequest from './base/BaseModelRequest';

export default class OrderRequest extends BaseModelRequest {

  getModelName() {
    return 'orders';
  }

  cancel(id) {
    let url = '/orders/' + id + '/cancel';
    return this.put(url);
  }

  cancelAll(body) {
    let url = '/orders/cancel-all';
    return this.put(url, body);
  }

  cancelByType(params) {
    let url = '/orders/cancel-by-type';
    return this.put(url, params);
  }

  getRecentTransactions(params) {
    let url = '/orders/transactions/recent';
    return this.get(url, params);
  }

  getOrders(params, currency, status, cancelToken) {
    let url = '/orders/' + currency + '/' + status;
    return this.get(url, params, cancelToken);
  }

  getOrdersPending(params) {
    let url = '/orders/pending';
    return this.get(url, params);
  }

  getOrderBook(params) {
    let url = '/orders/order-book';
    return this.get(url, params);
  }

  getTransactionsFee(params) {
    let url = '/orders/market_fee';
    return this.get(url, params);
  }

  getUserOrderBook(params, cancelToken) {
    let url = '/orders/user-order-book';
    return this.get(url, params, cancelToken);
  }

  getUserTransactions(params) {
    let url = '/orders/user-transactions';
    return this.get(url, params);
  }

  getOrderHistory(params) {
    let url = '/orders/transactions';
    return this.get(url, params)
  }

  getTradeHistory(params) {
    let url = '/orders/trading-histories';
    return this.get(url, params)
  }

  downloadTradeHistory(params, fileName) {
    const url = '/orders/trade-history/export';
    this.download(url, params, fileName);
  }

  downloadTransactions(params, fileName) {
    const url = '/orders/transactions/export';
    this.download(url, params, fileName);
  }

  getOrderDetail(id) {
    let url = '/orders/detail/' + id;
    return this.get(url)
  }

  convertOrder(params) {
    let url = '/orders';
    return this.post(url, params)
  }
}
