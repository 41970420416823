<template>
    <div class="page-container-about">
      <div class="group-about">
         <img src="@/assets/images/about/bgr-vision.png"/>
          <div class="text-vision">
            <h3 class="title">{{ $t("about.title_vision") }}</h3>
            <p class="sub-content">{{ $t("about.sub_title_vision") }}</p>
            <span>{{ $t("about.information_vision") }}</span>
          </div>
      </div>

      <div class="group-about">
          <div class="text-mision">
            <h3 class="title">{{ $t("about.title_mission") }}</h3>
           
            <span>{{ $t("about.information_mission") }}</span>
          </div>

          <!-- <img src="@/assets/images/about/bgr-mision.png"/> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .page-container-about{
         margin: 0 auto;
        padding: 130px 0px 115px;
        font-family: "inter-regular";
        max-width: 1440px;
  }
  .group-about {
    display: flex;
    gap: 150px;
    padding-bottom: 100px;

    &:last-child {
        padding-bottom: 0;
    }
    .text-vision {
        max-width: 636px;
    }
    .text-mision{
        max-width: 665px;
    }
    .title {
            font-size: 34px;
            line-height: 40px;
            color: var(--text-primary-color);
            font-family: $font-inter-bold;
        }
        & p, span {
            font-size: 16px;
            line-height: 19px;
            color: var(--text-close);
        }
    
  }
  @media screen and (max-width: 1440px) {
    .page-container-about {
      padding: 130px 23px 115px;
    }
   
  }

  @media screen and (max-width: 992px) {
    .page-container-about {
      padding: 40px 23px;
    }
    .group-about {
      flex-direction: column-reverse;
      gap: 40px;
      padding-bottom: 40px;

      &:last-child {
        flex-direction: column;
      }

      .title {
        font-size: 28px;
        line-height: 34px;
      }

      span {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  </style>
  