import { render, staticRenderFns } from "./DigitalClock.vue?vue&type=template&id=66129a35&scoped=true&"
import script from "./DigitalClock.vue?vue&type=script&lang=js&"
export * from "./DigitalClock.vue?vue&type=script&lang=js&"
import style0 from "./DigitalClock.vue?vue&type=style&index=0&id=66129a35&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66129a35",
  null
  
)

export default component.exports