<template>
  <div>
    <div class="balance-container">
      <div class="table-container table-history">
        <data-table4 ref="datatable" :getData="getData" :msgEmptyData="$t('common.datatable.data_empty.request_history')" id="order-table" :limit="10">
          <template>
            <th data-sort-field="master_id" class="cl1">
                {{$t('mam.fund_id')}}
            </th>
            <th data-sort-field="type" class="cl1">
                {{$t('mam.type')}}
            </th>
            <th data-sort-field="created_at" class="cl1">
                {{$t('mam.submited_at')}}
            </th>
            <th data-sort-field="amount" class="cl1">
                {{$t('mam.amonut')}}
            </th>
            <th data-sort-field="status" class="cl1">
                {{$t('mam.status')}}
            </th>
            <th></th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="cl1">{{ props.item.master_id }}</td>
              <td class="cl1">{{ getRequestType(props.item) }}</td>
              <td class="cl1">{{ props.item.created_at | convertToLocalTime }}</td>
              <td class="cl1">{{ props.item.amount | formatMarginNumber(8, 0, true) }} {{ $t('margin.symbol.btc') }}</td>
              <td class="cl1">{{ getRequestStatus(props.item) }}</td>
              <td class="cl1">
                <div class="as_rv_funds">
                  <a v-if="props.item.status === 'submitted'"
                      @click="confirmCancel(props.item.id)" class="btn btn-detail">{{ $t("mam.cancel") }}
                  </a>
                </div>
              </td>
            </tr>
          </template>
        </data-table4>
        <!-- end pagination-container -->
      </div>
      <!-- end table container -->
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Mam from '../common/mam.js'

  export default {
    data() {
        return {
          currentPage: '',
          isClickButtonCancel: false,
          isShowPendingOnly: false,
        }
    },
    methods: {
      getEventHandlers() {
        return {
          showPendingFollower: this.onShowPendingFollower,
        };
      },
      onShowPendingFollower(value) {
        this.isShowPendingOnly = value;
        this.$refs.datatable.refresh();
      },
      getRequestType (item) {
        return Mam.getRequestType(item);
      },
      getRequestStatus (item) {
        return Mam.getRequestStatus(item);
      },
      getData(params) {
          if(this.isClickButtonCancel){
            params.page = this.currentPage;
            this.isClickButtonCancel = false;
          }
          params.is_pending = this.isShowPendingOnly ? 1 : 0;
          const res = rf.getRequest('MamRequest').getFollowerRequest(params);
          this.currentPage = params.page; 
        return res;
      },
      confirmCancel(id) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          content: window.i18n.t('mam.confirm.cancel'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.cancel(id)
          },
          onCancel: () => {}
        });
      },
      cancel(id) {
        this.isClickButtonCancel = true;
        const params = {
          id,
          is_master: 0,
          action: Mam.action.cancel
        };
        return rf.getRequest('MamRequest').updateRequest(params).then((res) => {
          Message.success(window.i18n.t('mam.msg.cancel.success'), {}, { position: 'bottom_left' });
          this.$refs.datatable.refresh();
        }).catch(error => {
            if (error && error.response && error.response.data) {
              Message.error(error.response.data.message, {}, { position: 'bottom_left' });
            }
          })
      },
    }
  }
</script>

<style lang="scss" scoped>
    @import "@/assets/sass/variables";
    @import "@/assets/sass/common.scss";

    .cl1 {
      width: 20%;
    }
    .cl2 {
      width: 20%;
    }
    .cl3{
      width: 20%;
    }
    .cl4 {
        width: 20%;
    }
    .cl5 {
        width: 10%;
    }
    .clearfix-20 {
        display: block;
        clear: both;
        height: 20px;
    }

    .btn-detail {
        min-width: 70px;
        height: 25px;
        line-height: 20px;
        padding: 2px 5px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        letter-spacing: 0.92px;
        text-align: center;
        font-family: $font-family-roboto-bold;
        font-size: $font-smaller;
        color: $color-jungle-green;
        text-transform: uppercase;
        background-color: transparent;
        float: right;
        margin-left: 12px;
        &:hover {
            background-color: $color-blue-custom;
            border-color: $color-blue-custom;
            color: $color-white;
        }
    }

    #order-table {
        background-color:transparent;
        table {
            border: 0px;

            thead {
                background-color: $color-white;
                border: 0px;

                tr {
                    height: 48px;

                    th {
                        font-family: $font-family-roboto-medium;
                        font-size: $font-small;
                        padding: 8px 0px 8px 20px;

                        &:last-child {
                            padding: 8px 20px 8px 0px;
                        }

                        &[data-sort-order='asc'] {
                            color: $color-jungle-green;
                        }

                        &[data-sort-order='desc'] {
                            color: $color-jungle-green;
                        }
                    }
                }
            }

            tbody {
                text-align: left;

                tr {
                    &.canceled {
                        opacity: 0.3
                    }

                    height: auto;
                    font-size: $font-small;

                    td {
                        font-size: $font-root;
                        font-weight: 500;
                        color: $color-grey-dark;
                        font-family: $font-family-roboto-medium;
                        line-height: 21px;
                        padding: 7px 0px 6px 20px;
                        overflow: initial;
                        vertical-align: middle;

                        &.blue {
                            color: $color-jungle-green;
                        }

                        &.red {
                            color: $color-denim;
                        }

                        &:last-child {
                            text-align: left;
                            padding: 7px 20px 6px 0px;
                        }
                    }
                }
            }
        }
    }
</style>

<style lang="scss">
    @import "@/assets/sass/variables";
    @import "@/assets/sass/common.scss";

    #order-table {
        background-color: transparent;
        table {
            margin-bottom: 22px;
        }
    }
</style>