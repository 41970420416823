<template>
  <div id="date-tab">
    <ul>
      <li v-for="item in dateList" @click="selectDate(item)">
        <a :class="{ active: item.isActive }">{{ $t(item.name) }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";

export default {
  data() {
    return {
      dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB,
    };
  },
  methods: {
    selectDate(item) {
      if (item.isActive) {
        return;
      }
      this.resetSelectDate();
      item.isActive = true;
      this.$emit("change-select-date", item);
    },
    resetSelectDate() {
      this.dateList.forEach((item) => {
        item.isActive = false;
      });
    },
  },
  mounted() {
    this.resetSelectDate();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

#date-tab {
  display: inline-block;
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      font-size: $font-small;
      &:hover {
        cursor: pointer;
      }
      a {
        height: 23px;
        border-radius: 15px;
        color: #8e8e92;
        margin-right: 12px;
        padding: 2px 6px;
        background-color: transparent;
        min-width: 50px;
        width: auto;
        display: inline-block;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        &.active {
          color: var(--text-transfer);
          background-color: var(--bg-funds-time);
          border-radius: 16px;
        }
      }
    }
  }
}

@media (max-width: 1700px) {
  #date-tab {
    ul {
      li {
        a {
          min-width: auto;
          padding: 2px 5px;
          font-size: $font-smaller;
        }
      }
    }
  }
}
</style>
