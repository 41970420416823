<template>
  <div class="container-item">
    <div class="container-row">
      <div class="item">
        <div class="item-info">{{$t("funds.transfer.date") }}</div>
        <div class="value-info">{{date}}</div>
      </div>
      <div class="item">
        <div class="item-info text-right">{{$t("funds.transfer.assets") }}</div>
        <div :style="{display: 'flex', gap: '4px'}">
          <img
              class="icon-coin"
              :src="`${coinImg}`"
              alt=""
              width="20"
              height="20"
          />
          <div class="value-info text-right">{{coinName}}</div>
        </div>
      </div>
    </div>
    <div class="container-row">
      <div class="item">
        <div class="item-info">{{ $t("funds.transfer.amount")}}</div>
        <div class="value-info">{{amount}}</div>
      </div>
      <div class="item">
        <div class="item-info text-right">{{ $t("funds.transfer.from")}}</div>
        <div class="value-info text-right">{{from}}</div>
      </div>
    </div>
    <div class="container-row">
      <div class="item">
        <div class="item-info">{{ $t("funds.transfer.to")}}</div>
        <div class="value-info">{{to}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransferItem",
  data(){
    return {
    }
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    coinImg: {
      type: String,
      required: true,
    },
    coinName:{
      type: {}
    },
    amount: {
      type: Number
    },
    from: {
      type: String
    },
    to: {
      type: String
    }
  },
};
</script>

<style lang="scss">
.container-item{
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-color-input-transaction-history);
  .container-row{
    display: flex;
    justify-content: space-between ;
    .item{
      display: flex;
      flex-direction: column;
      gap: 4px;
      .item-info{
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #8E8E92;
      }
      .value-info{
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-primary-color);
      }
    }
  }
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
</style>
