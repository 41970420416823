<template>
  <div id="trade-history-screen" class="page_container container_history">
    <div class="trade-history-content">
      <sidebar class="sidebar" />

      <div class="main-trade-history">
        <header-title
          :title="`${$t('menu.trade_history')}`"
          :label="`${$t('menu.wallet.spot')}`"
        />
        <!-- <div class="box-title title-medium-small position-relative justify-content-end">
          <div class="position-absolute title-open-order">
            <p class="title_spot ">{{ $t('title.order') }} </p>
            <p>{{ $t('menu.trade_history') }}</p>
          </div>
          <img class="title-img" :src="require(`@/assets/images/${sizeScreen < 992 ? 'bgr-order-mobile' : 'bgr-order'}.svg`)" alt=""/>
        </div> -->
        <trade-history
          class="order-group-screen"
          :options="{ isNoPadding: true }"
          :tableHistory="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";
import TradeHistory from "./TradeHistory.vue";
import Sidebar from "../common/Sidebar.vue";
import HeaderTitle from "../common/HeaderTitle.vue";

export default {
  components: {
    TradeHistory,
    Sidebar,
    HeaderTitle,
  },
  data() {
    return {
      dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB,
      sizeScreen: window.innerWidth,
    };
  },
  methods: {
    selectDate(item) {},
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.dark-theme {
  .container_history {
    background: $color-wood-smoke;
  }
  .main-trade-history {
    background: $color-grey-black !important;
    .order-group-screen {
      background: $color-grey-black !important;
    }
  }
}
.container_history {
  width: 100%;
  margin: 0px auto;
}
#trade-history-screen {
  padding: 4px 0 0;

  .box-title {
    background: var(--bgr-order);
    width: 100%;
    font-size: 34px;
    font-family: $font-inter-bold;
    font-weight: 600;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-open-order {
      left: 40px;
      top: 53%;
      transform: translateY(-50%);
      .title_spot {
        font-size: $font-big-24;
        line-height: 29px;
        font-family: $font-inter-medium;
        font-weight: 500;
      }
      p {
        margin: 0;
      }
    }
  }

  .title-img {
    padding-right: 100px;
  }
  .trade-history-content {
    display: flex;
    min-height: calc(100vh - 65px - 252px);
    border-top: 4px solid var(--color-border-right);
  }

  .main-trade-history {
    width: 100%;
    padding-top: 24px;

    background-color: var(--background-color-primary);
  }
}
@media screen and (max-width: 1550px) {
  #trade-history-screen .order-group-screen {
    // max-width: calc(100vw - 325px);
  }
}
@media screen and (max-width: 1400px) {
  #trade-history-screen .order-group-screen {
    position: relative;
    padding: 40px 15px 0px 15px;
    ::v-deep {
      .no-data {
        margin-bottom: 60px;
        min-height: 300px !important;
        overflow: auto !important;
      }
      .box_padding {
        margin-bottom: 122px;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .sidebar {
    display: none;
  }
}
@media screen and (max-width: 1191px) {
  #trade-history-screen .order-group-screen {
    max-width: 1318px;
  }
}
@media only screen and (max-width: 992px) {
  #trade-history-screen {
    .order-group-screen {
      padding-top: 0;
    }
    .box-title {
      max-height: 80px;
      .title-open-order {
        padding: 11px 17px 12px;
        font-size: 24px;
        line-height: 29px;
        .title_spot {
          font-size: 18px;
          line-height: 21px;
        }
      }
      .main-title {
        height: 80px;
        padding-right: 26px;
      }
    }
  }

  #trade-history-screen .title-img {
    padding-right: 0;
  }

  #trade-history-screen .box-title .title-open-order {
    left: 16px;
  }
}
</style>
