<template>
  <base-layout>
    <create-anti-phishing-modal :mode="'resend'" ref="modalAntiPhishing"></create-anti-phishing-modal>
    <div class="email-verify-container">
      <div class="title-email">{{$t('anti.email.title')}}</div>
      <div class="sub-title-email">
       {{ $t('anti.email.content', {email: user.email}) }}
      </div>
      <div class="warning-email text-left">
        <span>{{ $t('anti.email.content_1')}}</span>
        <ol class="sub-warning-email">
          <li>{{ $t('anti.email.content_2') }}</li>
          <li> {{ $t('anti.email.content_3') }}</li>
          <li> {{ $t('anti.email.content_4')}}</li>
          <li>{{ $t('anti.email.content_5')}}</li>
        </ol>
      </div>
      <button class="btn btn-success" @click="resendEmail">{{ $t('anti.email.btn-resend')}}</button>

    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '../auth/BaseLayout';
import {mapState} from "vuex";
import rf from "@/request/RequestFactory";
import Message from '@/components/shared_components/common/Message';
import CreateAntiPhishingModal from "@/pages/account/modal/CreateAntiPhishingModal";

export default {
  name: "EmailVerification",
  components: {
    BaseLayout,
    CreateAntiPhishingModal
  },
  data() {
    return {
      code: this.$route.query.code,
      status: this.$route.query.type,
      type: 'resend'
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    // handleCloseModal () {
    //   const params = {
    //     anti_phishing_code : this.code,
    //     email:this.user.email,
    //     type: this.status
    //   }
    //   rf.getRequest('UserRequest').reconfirmEmailAntiPhishing(params).then();
    // },
    resendEmail () {
      const params = {
        anti_phishing_code : this.code,
        email:this.user.email,
        type: this.$route.query.type
      }

      rf.getRequest('UserRequest').reconfirmEmailAntiPhishing(params).then(() => {
        this.showAntiPhishingModal()
      })
    },
    showAntiPhishingModal() {
      this.type = 'resend';
      this.$refs.modalAntiPhishing.$props.email = this.user.email;
      this.$refs.modalAntiPhishing.$props.mode = 'resend';
      window.CommonModal.show('CreateAntiPhishingModal');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .dark-theme {
    .email-verify-container {
      .title-email {
        color: $color-white;
      }
      .sub-title-email {
        color: $color-storm-grey;
      }

      .warning-email {
        background: $color-gray-charade;
        color: $color-gray-alabaster;
      }
    }
  }

  .email-verify-container {
    width: 410px;
    margin:  0 auto  ;
    text-align: center;
    padding : 100px 0 200px;
    .title-email {
      color: $dark-1;
      font-size: 34px;
      font-weight: 600;
      font-family: $font-inter-bold;
      margin-bottom: 40px;
    }
    .sub-title-email {
      color: $dark-2;
      font-size: $font-big;
      opacity: 0.8;
      margin-bottom: 40px;
    }
    .warning-email {
      margin-bottom: 40px;
      border-radius: 8px;
      background: rgba(25, 25, 25, 0.8);
      padding: 15px;
      color: $color-gray-alabaster;
      font-size: $font-medium-bigger;
      .sub-warning-email {
        margin-top: 8px;
        font-size: $font-root;
        color: $color-gray-alto;
        list-style-type: disc;
        padding-left: 20px;
      }
    }
    .btn-success {
      background-color: $color-caribbean-green;
      border-radius: 8px;
      color: $dark-1;
      font-size: $font-big;
      width: 100%;
      border: none;
      height: 50px;
      font-weight: 400;
      text-transform: none;
      &:hover, &:active{
        background-color: $color-aquamarine;
      }
    }
    .form-control {
      box-shadow: inset 0 0 0 rgb(0 0 0 / 8%);
    }

  }
  @media screen and (max-width: 992px) {
    .email-verify-container {
      padding: 75px 30px 60px;
      width: 100%;
      margin: 0;
      .title-email {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 30px;
      }
      .sub-title-email {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
</style>