 import rf from '@/request/RequestFactory';

const actions = {
  getMamBalance({ commit }) {
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getBalance({ is_mam: 1 }).
        then(res => {
          commit('updateMamBalance', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  getBalanceRequest({}, params) {
    return rf.getRequest('MarginRequest').getBalance({ ...params, is_mam: 1 });
  },
  updateMamBalance({ commit }, data) {
    if (data && data.is_mam == 1) {
      commit('updateMamBalance', data);
    }
  },
}

const getters = {
  mamBalance: state => {
    return state.mamBalance
  }
}

const mutations = {
  updateMamBalance: (state, payload) => {
    state.mamBalance = {
      walletBalance: payload.balance,
      isolatedBalance: payload.isolated_balance,
      crossBalance: payload.cross_balance,
      crossEquity: payload.cross_equity,
      unrealisedPnl: payload.unrealised_pnl,
      crossMargin: payload.cross_margin,
      orderMargin: payload.order_margin,
      availableBalance: payload.available_balance,
      maxAvailableBalance: payload.max_available_balance,
    }
  }
}

export default {
  actions,
  getters,
  mutations,
  state: {
    mamBalance: {}
  },
}