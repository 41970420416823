<template>
  <div id="order-history-screen" class="page_container container_history">
    <div v-if="sizeScreen < 1300" :class="isOpen ? 'activeMenu' : ''"></div>

    <div class="order-history-content clearfix">
      <sidebar
        v-click-outside="clickOutside"
        :class="{
          tab_menu: sizeScreen < 1300,
          sidebar: !isOpen && sizeScreen < 1300,
        }"
      />

      <div class="main-order-history">
        <header-title
          :title="`${$t('menu.order_history')}`"
          :label="`${$t('menu.wallet.spot')}`"
        />
        <!-- <div class="box-title title-medium-small position-relative justify-content-end">
          <div class="position-absolute title-open-order">
            <p class="title_spot ">{{ $t('title.order') }} </p>
            <p>{{ $t('menu.order_history') }}</p>
          </div>
          <img class="title-img" :src="require(`@/assets/images/bgr-order${sizeScreen < 992 ? '-mobile' : ''}.svg`)" alt=""/>
        </div> -->
        <order-history
          class="order-group-screen"
          :options="{ isNoPadding: true }"
          :tableOrderHistory="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import COMMON_CONST from "@/common/Const";
import OrderHistory from "./OrderHistory.vue";
import Sidebar from "../common/Sidebar.vue";
import HeaderTitle from "../common/HeaderTitle.vue";

export default {
  components: {
    OrderHistory,
    Sidebar,
    HeaderTitle,
  },
  data() {
    return {
      dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB,
      sizeScreen: window.innerWidth,
      isOpen: false,
    };
  },
  methods: {
    selectDate(item) {},
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
      this.isOpen = false;
    },
    clickOutside(event) {
      if (event.target.className !== "btn-tab-menu") {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.dark-theme {
  .container_history {
    background: $color-wood-smoke;
  }

  .main-order-history {
    background: $color-grey-black !important;
    .order-group-screen {
      background: $color-grey-black !important;
    }
  }
}
@media screen and (max-width: 1300px) {
  .sidebar {
    display: none;
  }
}
.container_history {
  width: 100%;
  margin: 0px auto;
  &after {
    display: block;
    clear: both;
    content: "";
  }
}
#order-history-screen {
  padding: 4px 0 0;
  .box-title {
    background: var(--bgr-order);
    width: 100%;
    font-size: 34px;
    font-family: $font-inter-bold;
    font-weight: 600;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-open-order {
      left: 40px;
      top: 53%;
      transform: translateY(-50%);

      .title_spot {
        font-size: $font-big-24;
        line-height: 29px;
        font-family: $font-inter-medium;
        font-weight: 500;
      }
      p {
        margin: 0;
        font-family: $font-inter-bold;
        font-weight: 600;
      }
    }
    .title_spot {
      font-size: $font-big-24;
    }
    p {
      margin: 0;
    }
  }

  .title-img {
    padding-right: 100px;
  }
  .order-history-content {
    display: flex;
    border-top: 4px solid var(--color-border-right);
  }
  .main-order-history {
    width: 100%;
    padding-top: 24px;
    background-color: $color_white;
  }
  .order-group-screen {
    background-color: $color_white;
    padding: 62px 0 50px 40px;
    max-width: 93%;
    // min-width: 1280px;
    @media screen and (max-width: 1300px) {
      width: 100% !important;
      min-width: unset;
      max-width: unset;
    }
  }
}
@media screen and (max-width: 1670px) {
  #order-history-screen .order-group-screen {
    width: 95%;
  }
}
@media screen and (max-width: 1550px) {
  #order-history-screen .order-group-screen {
    //max-width: calc(100vw - 350px);
    min-width: unset;
    padding: 62px 66px 131px 40px;
    width: calc(100vw - 200px);
  }
  .title-img {
    padding-right: 17px;
  }
}
@media screen and (max-width: 1400px) {
  #order-history-screen .order-group-screen {
    max-width: 90%;
  }
}
@media screen and (max-width: 1300px) {
  #order-history-screen .order-group-screen {
    /* max-width: 1318px; */
    padding: 40px 15px 0px 15px;
    position: relative;

    &::v-deep {
      .no-data {
        margin-bottom: 60px;
        min-height: 300px !important;
        overflow: auto !important;
      }
    }
  }
}
@media screen and (max-width: 1191px) {
  #order-history-screen .order-group-screen {
    max-width: 1318px;
  }
}

@media only screen and (max-width: 992px) {
  #order-history-screen {
    .order-group-screen {
      padding-top: 0;
    }
    .box-title {
      max-height: 80px;
      .title-open-order {
        padding: 11px 17px 12px;
        font-size: 24px;
        line-height: 29px;
        .title_spot {
          font-size: 18px;
          line-height: 21px;
        }
      }
      .main-title {
        height: 80px;
        padding-right: 26px;
      }
    }
  }

  #order-history-screen .title-img {
    padding-right: 0;
  }

  #order-history-screen .box-title .title-open-order {
    left: 16px;
  }
}
</style>
