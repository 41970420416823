<template>
  <div id="account_reward">
    <Breadcrumb :items="breadcrumbItems" class="container-breadcrumb" />
    <AccountTopHeader
      :title="$t('account.reward_center')"
      :icon="require(`@/assets/images/bgr-order.svg`)"
    />
    <div class="content_reward">
      <div id="choose-desktop" class="choose-type">
        <div class="voucher-type">
          <div class="text-main choose-type_text">
            {{ $t("account.voucher_type") }}
          </div>
          <select-box v-model="voucherType" :options="listVoucherType" />
        </div>
        <div class="status">
          <div class="text-main choose-type_text">
            {{ $t("account.status") }}
          </div>
          <select-box v-model="status" :options="listStatus" />
        </div>
      </div>
      <div id="choose-mobile" class="choose-type-mobile">
        <div class="filter" @click="filterHandleClick">
          <img
            :src="
              require(`@/assets/images/icon/icon-filter${
                theme === 'light-theme' ? '' : '-dark'
              }.svg`)
            "
          />
        </div>
        <div class="choose-type">
          <div v-if="isFilter === true" class="voucher-type">
            <div class="text-main choose-type_text">
              {{ $t("account.voucher_type") }}
            </div>
            <select-box v-model="voucherType" :options="listVoucherType" />
          </div>
          <div v-if="isFilter === true" class="status">
            <div class="text-main choose-type_text">
              {{ $t("account.status") }}
            </div>
            <select-box v-model="status" :options="listStatus" />
          </div>
        </div>
      </div>
      <div class="main-reward">
        <div class="list-reward" v-if="listVoucher.length > 0">
          <div
            class="item"
            v-for="item in listVoucher"
            :key="item.id"
            :class="{
              active: checkStatus(item),
              inprogress:
                convertStatus(item) === 'In Progress' && !item.user_id,
            }"
          >
            <div class="content-item">
              <div class="type">
                <span class="type_text">{{ item.type }}</span>
              </div>
              <div class="content-value">
                <div class="content-value-item">
                  <div class="text-main">
                    {{ item.name }}
                  </div>
                  <div class="text-description">
                    Complete identity verification to get 100 USDT
                  </div>
                  <div class="text-sub text-date">
                    {{ $t("account.expiry_date") }}:
                    {{
                      item.expires_date === null
                        ? "26-10-2024"
                        : getDate(item.expires_date)
                    }}
                  </div>
                </div>

                <!-- <div class="length-value">
                  <div
                    class="length-main"
                    :style="{
                      width: calculateWidth(
                        item.total_trading,
                        item.user_id
                          ? item.conditions_use_old
                          : item.conditions_use
                      ),
                    }"
                  ></div>
                </div> -->
                <!-- <div class="trade-volume">
                  <div class="text-sub text-trade">
                    {{ $t("account.trading_volume") }}
                  </div>
                  <div class="text-sub text-trade">
                    {{ item.total_trading | toNumber }} /
                    {{
                      item.user_id
                        ? item.conditions_use_old
                        : item.conditions_use | toNumber
                    }}
                    {{ item.currency | uppercase }}
                  </div>
                </div> -->
                <div class="wrap-button">
                  <button
                    class="button"
                    @click="claimReward(item)"
                    v-if="checkStatus(item)"
                  >
                    <!-- {{ convertStatus(item) }} -->
                    Claim
                  </button>
                  <button class="button" v-else>
                    {{ convertStatus(item) }}
                  </button>
                </div>
              </div>
              <div class="content-money">
                <!-- <img
                  v-if="!isNaN(checkStatus(item))"
                  :src="
                    require(`/public/images/icon-star-reward${
                      theme === 'dark-theme' ? 'semi-dark' : ''
                    }.svg`)
                  "
                  alt=""
                  class="icon icon1"
                />
                <img
                  v-else
                  :src="
                    require(`/public/images/icon-star-reward${
                      theme === 'dark-theme' ? 'semi-dark' : ''
                    }.svg`)
                  "
                  alt=""
                  class="icon icon1"
                /> -->
                <img
                  :src="require('@/assets/images/mobile/star.png')"
                  alt=""
                  class="star-icon"
                />
                <div class="value-money">
                  {{
                    item.user_id ? item.amount_old : item.amount || 0 | toNumber
                  }}
                </div>
                <div class="coin">{{ item.currency | uppercase }}</div>
                <!-- <img
                  v-if="!isNaN(checkStatus(item))"
                  :src="
                    require(`/public/images/icon-star-reward${
                      theme === 'dark-theme' ? 'semi-dark' : ''
                    }.svg`)
                  "
                  alt=""
                  class="icon icon2"
                />
                <img
                  v-else
                  :src="
                    require(`/public/images/icon-star-reward${
                      theme === 'dark-theme' ? 'semi-dark' : ''
                    }.svg`)
                  "
                  alt=""
                  class="icon icon2"
                /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="nodata" v-else>
          <img
            class="icon-search"
            :src="
              require(`@/assets/images/landing/icon-nodata${
                theme === 'light-theme' ? '' : '-dark'
              }.svg`)
            "
          />
          <div class="mess_nodata">
            {{ $t("common.datatable.data_empty.reward") }}
          </div>
        </div>
        <Pagination
          v-if="last_page > 1"
          :per-page="8"
          :records="records"
          :chunk="last_page"
          @Pagination:page="onPageChange"
          :pageParent="page"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import AccountTopHeader from "@/components/shared_components/common/AccountTopHeader.vue";
import SelectBox from "@/components/shared_components/common/SelectBox.vue";
import rf from "@/request/RequestFactory";
import Pagination from "@/components/shared_components/common/DataTable/Pagination.vue";
import starImage from "@/assets/images/mobile/star.png";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: "RewardCenter",
  data() {
    return {
      voucherType: "",
      status: "",
      listStatus: [
        { id: "", name: this.$t("account.all") },
        { id: "available", name: this.$t("account.in_progress") },
        { id: "claim", name: this.$t("account.claim") },
        { id: "redeemed", name: this.$t("account.received") },
        { id: "expired", name: this.$t("account.expired") },
      ],
      listVoucherType: [
        { id: "", name: this.$t("account.all") },
        { id: "spot", name: this.$t("account.spot_reward") },
        { id: "future", name: this.$t("account.futures_reward") },
      ],
      listVoucher: [],
      listOriginVoucher: [],
      limit: 8,
      last_page: 0,
      records: 0,
      page: 1,
      breadcrumbItems: [
        { label: "Profile", link: "/account/dashboard" },
        { label: "Reward Center", link: "/account/reward-center" },
      ],
      isMobile: window.innerWidth <= 768,
      isFilter: false,
    };
  },
  components: {
    Breadcrumb,
    AccountTopHeader,
    SelectBox,
    Pagination,
  },
  computed: {
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
  },
  watch: {
    voucherType() {
      this.initData(this.voucherType, this.status, "", this.limit, this.page);
    },
    status() {
      this.initData(this.voucherType, this.status, "", this.limit, this.page);
    },
  },
  async mounted() {
    this.initData();
  },
  methods: {
    filterHandleClick() {
      this.isFilter = !this.isFilter;
    },
    onPageChange(page) {
      this.page = page;
      this.initData(this.voucherType, this.status, "", this.limit, this.page);
    },
    async initData(type, status, expires_date, limit, page) {
      const res = await rf
        .getRequest("UserRequest")
        .getListVoucher(type, status, expires_date, limit, page);
      const data = res.data.data;

      if (typeof data.data === "object") {
        data.data = Object.values(data.data);
      }

      this.listVoucher = data.data.map((item) => {
        const num = item.user_id
          ? item.conditions_use_old
          : item.conditions_use;
        return {
          ...item,
          total_trading:
            Number(item.total_trading) <= Number(num)
              ? item.total_trading
              : num,
        };
      });
      this.listOriginVoucher = this.listVoucher;
      this.last_page = data.last_page;
      this.records = data.total;
    },
    calculateWidth(total_trading, conditions_use_old) {
      return `${(total_trading * 100) / conditions_use_old}%`;
    },
    claimReward(item) {
      const now = Date.now();
      const timeItem = Date.parse(this.getDate(item.expires_date));
      if (
        !(
          timeItem &&
          now < timeItem &&
          item.status_use === "available" &&
          item.user_id
        )
      ) {
        Message.error(this.$t("account.claim_fail"));
        return;
      }
      const claimType =
        item.type === "spot" ? "claimVoucher" : "claimVoucherFuture";
      rf.getRequest("UserRequest")
        [claimType]({ voucher_id: item.id })
        .then((res) => {
          Message.success(this.$t("account.successfully_claimed"));
        })
        .catch((err) => {
          Message.error(this.$t("account.claim_fail"));
        })
        .finally(() => {
          this.initData(
            this.voucherType,
            this.status,
            "",
            this.limit,
            this.page
          );
        });
    },
    convertStatus(item) {
      const now = Date.now();
      const timeItem = Date.parse(this.getDate(item.expires_date));
      if (item.status_use === "redeemed") return this.$t("account.received");
      if ((timeItem && now > timeItem) || item.status_use === "expired")
        return this.$t("account.expired");
      if (item.status_use === "available")
        return item.user_id
          ? this.$t("account.claim")
          : this.$t("account.in_progress");
      return "";
    },
    checkStatus(item) {
      const now = Date.now();
      const timeItem = Date.parse(this.getDate(item.expires_date));
      return true;
      // timeItem &&
      // now < timeItem &&
      // item.status_use === "available" &&
      // item.user_id
    },
    getDate(date) {
      if (!date) return;
      const timeZone = -(new Date().getTimezoneOffset() / 60);
      const dateTime = new Date(date);
      dateTime.setHours(dateTime.getHours() + timeZone);
      const item = dateTime
        .toString()
        .split(" ")
        .find((item) => item.includes("+") || item.includes("-"));
      const time = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
      return `${time} (${item?.slice(0, -2)})`;
    },
  },
};
</script>
<style lang="scss" scoped>
#account_reward {
  margin-bottom: 100px;
  padding: 40px;
  .text-main {
    font-size: 12px;
    line-height: 16px;
    color: var(--dark-1);
    width: 100%;
    span {
      border-radius: 4px;
      float: right;
      text-transform: capitalize;
      padding: 4px 12px;
      color: var(--text-spot);
      background: var(--background-spot2);
      font-size: $font-medium-bigger;
    }
  }
  .text-sub {
    font-size: 14px;
    line-height: 16px;
  }
  .content_reward {
    .choose-type {
      display: flex;
      gap: 16px;
      .choose-type_text {
        margin-bottom: 8px;
      }
      .voucher-type {
        width: 295px;
      }
    }
    .status {
      width: 295px;
    }
    #choose-desktop {
      display: flex;
    }
    #choose-mobile {
      display: none;
    }
  }
  .main-reward {
    margin-top: 40px;
    .list-reward {
      display: flex;
      gap: 25px 60px;
      flex-wrap: wrap;
      .item {
        overflow: hidden;
        width: 576px;
        max-width: calc(100vw - 16px);
        height: fit-content;
        .content-item {
          display: flex;
          background-color: var(--background-color-card);
          border: 1px solid var(--color-border);
          position: relative;
          border-radius: 16px;
          overflow: hidden;
          padding: 10px;
          .type {
            position: absolute;
            background: #03aac7;
            right: -2px;
            top: -2px;
            z-index: 100;
            border-top-right-radius: 16px;
            border-bottom-left-radius: 16px;
            width: 100px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            .type_text {
              color: #ffffff;
              font-size: 12px;
              font-weight: 700;
              line-height: 16px;
            }
          }
          .content-money {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 121px !important;
            gap: 4px;
            width: auto;
            .star-icon {
              display: none;
            }
            .value-money {
              font-size: 30px;
              line-height: 38px;
              font-weight: 700;
              color: #fec84b;
            }
            .coin {
              color: #8e8e92;
              font-size: 14px;
              line-height: 20px;
            }

            .icon1 {
              position: absolute;
              top: 10px;
              left: 10px;
            }
            .icon2 {
              position: absolute;
              bottom: 10px;
              right: 10px;
            }
            .circle {
              position: absolute;
              width: 24px;
              height: 24px;
              background: var(--background-color-primary);
              border: 2px solid var(--color-input);
              border-radius: 50%;
              right: -12px;
              &.circle1 {
                top: -12px;
              }
              &.circle2 {
                bottom: -12px;
              }
            }
          }
          .content-value {
            flex: 1;
            padding: 24px 16px;
            background-color: var(--background-color-value);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
            border-radius: 16px;
            width: 100%;
            .content-value-item {
              width: fit-content;

              .text-main {
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                color: var(--text-check-label);
                width: fit-content;
                text-transform: capitalize;
              }
              .text-description {
                font-size: 14px;
                line-height: 24px;
                color: #8e8e92;
                width: fit-content;
              }
              .text-date {
                color: var(--text-check-label);
                line-height: 22px;
                font-size: 12px;
                width: fit-content;
              }
            }

            .length-value {
              margin-top: 33px;
              border-radius: 4px;
              height: 8px;
              background: var(--color-dark-5);
              overflow: hidden;
              .length-main {
                border-radius: 4px;
                height: 8px;
                background: var(--text-desc);
              }
            }
            .trade-volume {
              margin-top: 8px;
              display: flex;
              justify-content: space-between;
              .text-trade {
                color: var(--color-tab-wallet);
              }
            }
            .wrap-button {
              .button {
                min-width: fit-content;
                @include btn-common(no);
                background: var(--background-progress) !important;
                color: var(--text-progress) !important;
                pointer-events: none;
                padding: 6px 12px;
                border-radius: 1234px;
                font-size: 14px;
                line-height: 17px;
                color: #fff;
              }
            }
          }
        }
      }
      .item.active .content-item {
        // border: 2px solid $color-caribbean-green;
        .content-money {
          height: 160px;
          // background: $color-caribbean-green;
          .coin {
            // color: $dark-1;
          }
          .circle {
            border: 2px solid $color-caribbean-green;
          }
        }
        .content-value {
          .text-date {
            display: block;
          }
          .text-main {
            span {
              background: $color-caribbean-green;
              color: $dark-1;
            }
          }
          .length-value {
            margin-top: 7px !important;
            .length-main {
              background: $color-caribbean-green;
            }
          }
          .trade-volume {
            .text-trade {
              color: var(--text-primary-color);
            }
          }
          .wrap-button .button {
            @include btn-common(yes);
            pointer-events: unset;
            background: #03aac7 !important;
            padding: 6px 12px;
            border-radius: 1234px;
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            color: #fff !important;
          }
        }
      }
      .item.inprogress .content-item {
        // border: 2px solid var(--color-border-ticket-reward-center-inprogress);
        .content-money {
          height: 160px;
          // background: var(--color-ticket-reward-center-inprogress);
          .coin {
            // color: var(--color-text-ticket-reward-center-inprogress);
          }
          .circle {
            border: 2px solid
              var(--color-border-ticket-reward-center-inprogress);
          }
        }
      }
    }
    .nodata {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 430px;
      .mess_nodata {
        margin-top: 16px;
        font-size: 18px;
        line-height: 22px;
        color: var(--color-image-nodata);
      }
    }
  }
}
@media screen and (max-width: 992px) {
  #account_reward {
    margin-bottom: 20px;
    padding: 20px;
    .text-main {
      font-size: 14px;
      line-height: 17px;

      span {
        font-size: 10px;
        line-height: 16px;
      }
    }
    .text-sub {
      font-size: 9px;
      line-height: 10px;
    }
    .content_reward {
      padding: 40px 0px;
    }
    .main-reward {
      margin-top: 30px;
      .list-reward {
        gap: 15px;
        .item {
          .content-item {
            display: list-item;
            position: relative;
            height: 180px;

            .content-money {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              height: fit-content !important;
              position: absolute;
              bottom: 10px;
              left: -9px;
              .star-icon {
                display: block;
                width: 13px;
                height: 13px;
              }
              .value-money {
                font-size: 18px;
                line-height: 24px;
                color: #fec84b;
                font-weight: 700;
              }
              .coin {
                font-size: 12px;
                line-height: 16px;
              }
              .circle {
                width: 24px;
                height: 24px;
                &.circle1 {
                  top: -12px;
                }
                &.circle2 {
                  bottom: -12px;
                }
              }
            }
            .content-value {
              max-width: 100%;
              height: 120px;
              padding: 20px 37px 20px 16px;
              .text-main {
                font-size: 16px;
                line-height: 22px !important;
              }
              .text-description {
                font-size: 12px !important;
                line-height: 16px !important;
              }
              .text-date {
                font-size: 12px;
                line-height: 16px;
              }
              .length-value {
                margin-top: 20px;
                height: 6px;
                .length-main {
                  height: 6px;
                }
              }
              .wrap-button {
                margin-top: 5px;
                position: relative;
                .button {
                  position: absolute;
                  width: 64px;
                  height: 32px;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 20px;
                  padding: 6px 12px !important;
                  bottom: -97px;
                  right: -33px;
                }
              }
              .trade-volume {
                margin-top: 5px;
              }
            }
          }
        }
        .item.active .content-item {
          .content-value {
            .wrap-button .button {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
      .nodata {
        min-height: 250px;
        .mess_nodata {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    :deep(.logo-header) {
      object-position: 50px;
    }
    .choose-type-mobile {
      display: flex;
      position: relative;
      .filter {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        top: -45px;
        img {
          height: 32px;
          width: 32px;
        }
      }
    }
    #choose-desktop {
      display: none !important;
    }
    #choose-mobile {
      display: block !important;
    }
  }
}
</style>

<style lang="scss">
#account_reward {
  .button_drop_search {
    float: unset;
    height: auto;
    margin: 0;
    background: var(--color-dark-5);
    display: flex;
    justify-content: space-between;
    padding: 14px 15px;
    border-radius: 80px;
    i {
      float: unset;
    }
  }
  .box_list_search_select {
    width: 100%;
    ul {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
      background: var(--background-color-modal);

      li {
        padding: 6px 15px;

        span {
          font-size: 16px;
          line-height: 19px;
        }
        &:hover {
          span {
            color: $color-caribbean-green;
          }
        }
      }
    }
  }
  .full_name_coin_select {
    color: var(--text-close);
  }
}
</style>
