var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-download-section"},[(_vm.sizeScreen < 610)?_c('h2',{staticClass:"text-trade"},[_c('span',{staticClass:"highlight-text"},[_vm._v("Trade")]),_vm._v(" anytime, anywhere! ")]):_vm._e(),_c('div',{staticClass:"app-preview"},[_c('img',{attrs:{"src":_vm.appImage,"alt":"App Preview"}})]),(_vm.sizeScreen < 610)?_c('div',{staticClass:"container-download",style:({ display: 'flex' })},[_c('div',{style:({ display: 'flex', alignItems: 'center', gap: '10px' })},[_c('img',{style:({ width: '32px', height: '32px' }),attrs:{"src":require(`@/assets/images/drx/${_vm.appleLogo}`)}}),_c('div',{staticClass:"brand"},[_vm._v("App Store")])]),_c('div',{style:({ display: 'flex', alignItems: 'center', gap: '10px' })},[_c('img',{style:({ width: '32px', height: '32px' }),attrs:{"src":require(`@/assets/images/drx/${_vm.androidLogo}`)}}),_c('div',{staticClass:"brand"},[_vm._v("Google Play")])])]):_vm._e(),(_vm.sizeScreen > 610)?_c('div',{staticClass:"download-info"},[_c('h2',{staticClass:"text-trade",style:({ width: '462px' })},[_c('span',{staticClass:"highlight-text"},[_vm._v("Trade")]),_vm._v(" anytime,"),_c('br'),_vm._v(" anywhere! ")]),_c('div',{style:({
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        marginTop: '72px',
      })},[_c('img',{staticClass:"qr-code",attrs:{"src":_vm.qrCodeImage,"alt":"QR Code"}}),_vm._m(0)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-text"},[_c('p',[_vm._v("Scan to download the app")]),_c('span',[_vm._v("IOS & Android")])])
}]

export { render, staticRenderFns }