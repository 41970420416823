<template>
  <div class="border-gradient-container" :style="containerStyle">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BorderGradient",
  props: {
    borderRadius: {
      type: String,
      default: "16px",
    },
    padding: {
      type: String,
      default: "1px",
    },
  },
  computed: {
    containerStyle() {
      return {
        borderRadius: this.borderRadius,
        padding: this.padding,
      };
    },
    borderGradientStyle() {
      return {
        borderRadius: this.borderRadius,
        padding: this.padding,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.border-gradient-container {
  position: relative;
  display: inline-block;
  padding: 0;
  box-shadow: 0px 2px 20.7px -2px rgba(23, 23, 23, 0.06);
}

.border-gradient-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: inherit;
  background: linear-gradient(97.91deg, #5a717c 6.1%, #05b4cc 103.66%);
  mask-composite: exclude;
  mask-size: 200%;
}

.dark-theme {
  .content {
    background-color: #17181a !important;
  }
}

.content {
  position: relative;
  background-color: #fff;
  border-radius: inherit;
  z-index: 1;
}
</style>
