<template>
  <div class="trust-section">
    <div class="section-header">
      <span class="title">{{ title }}</span>
      <p class="description">{{ subtitle }}</p>
    </div>

    <div class="features-container">
      <div
        v-for="(feature, index) in computedFeatures"
        :key="index"
        class="feature-card"
      >
        <div v-if="feature.type === 1" class="bubble-1" />
        <div v-if="feature.type === 1" class="bubble-2" />
        <img
          :src="require(`@/assets/images/drx/${feature.icon}`)"
          alt="feature-icon"
          :class="`feature-icon`"
          :style="{ width: '40px', marginBottom: 'unset' }"
        />
        <h3 class="title-feature">{{ feature.title }}</h3>
        <p class="des-feature">{{ feature.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: "A trustworthy crypto exchange",
    },
    subtitle: {
      type: String,
      default: "When it comes to security, we go the extra mile.",
    },
    features: {
      type: Array,
      default: () => [
        {
          icon: "blockchain-dark.png",
          iconDark: "blockchain-dark.png",
          iconLight: "blockchain-light.png",
          title: "Proof of Reserves",
          type: 1,
          description:
            "We guarantee at least a 1:1 reserve ratio of our customer funds.",
        },
        {
          icon: "shield-dark.png",
          iconDark: "shield-dark.png",
          iconLight: "shield-light.png",
          title: "Cold storage",
          type: 2,
          description:
            "We store most digital assets in offline, multi-signature wallets.",
        },
        {
          icon: "wallet-dark.png",
          iconDark: "wallet-dark.png",
          iconLight: "wallet-light.png",
          title: "Protection Fund",
          type: 3,
          description:
            "Our 300M USDT fund gives an extra layer of protect against security threats.",
        },
      ],
    },
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
      theme: (state) => state.userThemeMode,
    }),
    computedFeatures() {
      return this.features.map((feature) => ({
        ...feature,
        icon:
          this.theme !== "light-theme" ? feature.iconDark : feature.iconLight,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.trust-section {
  text-align: center;
  padding: 40px;
  padding-right: unset;
  padding-left: unset;
  color: white;
  max-width: 1284px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 610px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  .description {
    margin-top: 16px;
    @media screen and (max-width: 500px) {
      margin-top: 0px;
    }
  }
}

.section-header {
  margin-bottom: 30px;
  text-align: left;
  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
  }
}

.section-header h1 {
  font-size: 36px;
  font-weight: bold;
}

.section-header p {
  font-size: 18px;
  color: #a0a0a0;
}

.features-container {
  display: flex;
  gap: 24px;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: auto;
  height: 284px;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.features-container::-webkit-scrollbar {
  display: none;
}

.feature-card {
  background-color: var(--bg-body);
  padding: 32px;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid var(--line-color-table-home);
  border-radius: 8px;
  width: 100%;
  min-width: 284px;
  position: relative;
  overflow: hidden;

  .bubble-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background: #03aac7;
    filter: blur(45.650001525878906px);
  }
  .bubble-2 {
    position: absolute;
    right: 143px;
    bottom: 8px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background: #03aac7;
    filter: blur(45.650001525878906px);
  }
}

.dark-theme {
  .title-feature {
    color: #f9fafb;
  }
}

.title-feature {
  color: var(--text-primary-color);
  font-weight: 700;
  font-size: 24px;
  margin-top: 42px;
  font-family: $font-notosans-bold;
  @media screen and (max-width: 610px) {
    font-size: 14px !important;
  }
}

.des-feature {
  font-size: 24px;
  color: var(--text-our-story) !important;
  line-height: 30px;
  margin-top: 12px;

  @media screen and (max-width: 610px) {
    font-size: 14px !important;
  }
}

.feature-icon {
  width: 50px;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.feature-card p {
  font-size: 20px;
  color: #b0b0b0;
  text-align: left;
  font-family: $font-notosans-regular;
}

.title {
  font-size: 48px;
  font-style: normal;
  line-height: 32px;
  color: var(--text-primary-color);
  font-family: $font-notosans-bold;
  @media screen and (max-width: 610px) {
    font-size: 20px !important;
  }
}
.description {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  @media screen and (max-width: 610px) {
    font-size: 12px !important;
  }
}
</style>
