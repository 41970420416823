import BaseModelRequest from './base/BaseModelRequest';

export default class UserSettingRequest extends BaseModelRequest {

  saveSymbolsSetting(data) {
    let url = '/user/symbols-setting';
    return this.post(url, data);
  }

  getSymbolsSetting(params) {
    let url = '/user/symbols-setting';
    return this.get(url, params);
  }
}
