<template>
  <div id="account_sitebar">
    <div v-for="(item, index) in settingsAccountPath" :key="index">
      <router-link
        v-if="currentPath === item.path"
        :to="item.path"
        class="path-item active"
      >
        <!-- <img :src="item.iconActive" alt="icon" :style="{width: '20px', height: '20px'}"/> -->
        <img
          :src="lightMode ? item.icon : item.iconDark"
          alt="icon"
          :style="{ width: '20px', height: '20px' }"
        />
        {{ $t(item.name) }}
      </router-link>
      <router-link v-else :to="item.path" class="path-item">
        <img
          :src="lightMode ? item.icon : item.iconDark"
          alt="icon"
          :style="{ width: '20px', height: '20px' }"
        />
        {{ $t(item.name) }}
      </router-link>
    </div>
  </div>
</template>
<script>
import Const from "@/common/Const";
import { mapState } from "vuex";

export default {
  name: "AccountSiteBar",
  props: {
    isOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      settingsAccountPath: Const.SETTINGS_ACCOUNT_PATH,
    };
  },
  computed: {
    currentPath() {
      if (
        this.$route.path === "/account/address" ||
        this.$route.path === "/account/devices" ||
        this.$route.path === "/account/activity" ||
        this.$route.path === "/account/anti-phishing" ||
        this.$route.path === "/account/anti-phishing/create" ||
        this.$route.path === "/account/anti-phishing/change" ||
        this.$route.path === "/account/googleAuthenticator"
      ) {
        return "/account/security";
      }
      return this.$route.path;
    },
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    lightMode() {
      return this.theme === "light-theme";
    },
  },
  watch: {
    isOpen: function (newVal) {
      console.log("newVal", newVal);
    },
  },
};
</script>
<style lang="scss">
#account_sitebar {
  width: 258px;
  height: 100%;
  background-color: var(--background-color-primary-2);
  min-height: calc(100vh - 65px - 252px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 16px;
  .path-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    color: var(--text-primary-color);
    font-family: $font-notosans-medium;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 16px;
    &.active {
      background: var(--bg-stats-home);
      position: relative;
    }
    //&.active::before {
    //  content: '';
    //  position: absolute;
    //  right: 0;
    //  top: 0;
    //  width: 6px;
    //  height: 100%;
    //  background: $color-caribbean-green;
    //}

    &:hover {
      background: var(--bg-stats-home);
    }
  }
}
</style>
