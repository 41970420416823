<template>
  <vue-highcharts :options="options" ref="lineCharts" id="high-chart" v-if="visible"></vue-highcharts>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import VueHighcharts from 'vue2-highcharts';
  import BigNumber from 'bignumber.js';
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

  const SELL = 'sell';
  const BUY = 'buy';
  const BLANK = '--';
  const TAB_SELL = 1;
  const BOTH_SELL_BUY = 2;
  const TAB_BUY = 3;

  export default {
    components: {
      VueHighcharts
    },
    props: {
      coin        : { type: String},
      currency    : { type: String},
    },
    data() {
      return {
        buyRowCount: 14,
        sellRowCount: 14,
        visible: true,
        internalCoin: undefined,
        internalCurrency: undefined,
        tab: BOTH_SELL_BUY,
        tabConfig: {sell: TAB_SELL, bothSellBuy: BOTH_SELL_BUY, buy: TAB_BUY},
        orderBook: [],
        buyOrdersBook: [],
        sellOrdersBook: [],
        tickerSize: undefined,
        options: this.getChartOptions(),

        // Beta Tester
        hideDataOrderBook: false,  // Default auto show data
      };
    },
    computed: {
      ...mapGetters({
        symbol: 'currentSymbol',
        orderbook: 'orderbook',
        instrument: 'currentInstrument',
        indexBySymbol: 'indexBySymbol',
      }),
      ...mapState({
        masterdata: state => state.masterdata
      })
    },
    watch: {
      orderbook: function (val) {
        if(this.orderbook) this.buildChart();
      }
    },
    methods: {
      getSocketEventHandlers() {
        return {
          OrderBookUpdated: this.onReceiveSocket
        }
      },
      getEventHandlers() {
        return {
          BetaTesterHideData: this.onBetaTesterHideData,
          BetaTesterShowData: this.onBetaTesterShowData,
        }
      },

      onBetaTesterHideData(data) {
        // Only when the chart is showing,
        // then set hide and re-build chart
        if (!this.hideDataOrderBook) {
          this.hideDataOrderBook = true;
          this.buildChart();
        }
      },

      onBetaTesterShowData(data) {
        // Only when the chart is hidden,
        // then set show and re-build chart
        if (this.hideDataOrderBook) {
          this.hideDataOrderBook = false;
          this.buildChart();
        }
      },

      getOrderbookDisplay() {
          const groupSubOrderbook = (subOrderbook, grouping, isSellOrderbook) => {
            let groupedOrderbook = [];
            for (let item of subOrderbook) {
              let price = new BigNumber(item.price).dividedToIntegerBy(grouping).times(grouping).toString();
              if (isSellOrderbook && !(new BigNumber(price).eq(item.price))) {
                price = new BigNumber(price).plus(grouping).toString();
              }
              let row = {
                quantity: item.quantity,
                price: price,
              };

              if (groupedOrderbook.length === 0) {
                groupedOrderbook.push(row);
              } else {
                let lastRow = groupedOrderbook[groupedOrderbook.length - 1];
                if (lastRow.price === row.price) {
                  lastRow.quantity = (new BigNumber(lastRow.quantity)).plus(item.quantity).toString();
                } else {
                  groupedOrderbook.push(row);
                }
              }
            }

            return groupedOrderbook;
          }

          const groupOrderbook = (buy, sell, tickSize) => {
            if (!tickSize) {
              return [[], []];
            }

            let groupedBuyOrderbook = groupSubOrderbook(buy, tickSize, false);
            let groupedSellOrderbook = groupSubOrderbook(sell, tickSize, true);

            return [groupedBuyOrderbook, groupedSellOrderbook];
          }

          const calculateTotalQuantity = (subOrderbook) => {
            let totalQuantity = 0;
            return subOrderbook.map(item => {
              totalQuantity = new BigNumber(totalQuantity).plus(item.quantity).toString();
              item.totalQuantity = totalQuantity;
              return item;
            });
          }

          const getMaxTotalQuantity = (buy, sell, type) => {
            let totalBuyQuantity = 0;
            let totalSellQuantity = 0;

            if (buy.length > 0) {
              totalBuyQuantity = buy[buy.length - 1].totalQuantity;
            }
            if (sell.length > 0) {
              totalSellQuantity = sell[sell.length - 1].totalQuantity;
            }
            if (type === TAB_BUY) {
              return totalBuyQuantity;
            } else if (type === TAB_SELL) {
              return totalSellQuantity;
            } else {
              return Math.max(totalBuyQuantity, totalSellQuantity);
            }
          }

        // const calculatePercent = (buy, sell) => {
        //   let maxTotalQuantity = getMaxTotalQuantity(buy, sell, this.tab);
        //   buy = buy.map(item => ({
        //     ...item,
        //     percent: item.totalQuantity * 100 / maxTotalQuantity,
        //   }))
        //   sell = sell.map(item => ({
        //     ...item,
        //     percent: item.totalQuantity * 100 / maxTotalQuantity,
        //   }))
        //   return [buy, sell];
        // }

        const fillRowBlankIfNeed = (subOrderbook, size) => {
          while(subOrderbook.length < size) {
            subOrderbook.push({
              price: undefined,
              quantity: 0,
              percent: 0
            });
          }
        }

        let orderBook = this.orderbook;
        // if(orderBook) sortOrderBook(orderBook);
        let buyOrderbook = orderBook ? orderBook.buy : [];
        let sellOrderbook = orderBook ? orderBook.sell : [];

        [buyOrderbook, sellOrderbook] = groupOrderbook(buyOrderbook, sellOrderbook, this.tickerSize);

        if (this.tab === BOTH_SELL_BUY) {
          buyOrderbook = buyOrderbook.slice(0, this.buyRowCount);
          sellOrderbook = sellOrderbook.slice(0, this.sellRowCount);
        }

        buyOrderbook = calculateTotalQuantity(buyOrderbook);
        sellOrderbook = calculateTotalQuantity(sellOrderbook);

        // [buyOrderbook, sellOrderbook] = calculatePercent(buyOrderbook, sellOrderbook);

        fillRowBlankIfNeed(buyOrderbook, this.buyRowCount);
        fillRowBlankIfNeed(sellOrderbook, this.sellRowCount);

        buyOrderbook = buyOrderbook.reverse();

        let data = {
          buy: buyOrderbook.map(el=>el.price && [parseFloat(el.price), parseFloat(el.totalQuantity)]),
          sell: sellOrderbook.map(el=>el.price && [parseFloat(el.price), parseFloat(el.totalQuantity)]),
        }
        return data
      },

      onReceiveSocket(data) {
        if (this.internalCurrency != data.currency || this.internalCoin != data.coin || this.tickerSize != data.tickerSize) {
          return;
        }

        if (data.isFullOrderBook) {
          this.orderBook = data.orderBook;
        } else {
            this.mergeSubOrderBook(this.orderBook.buy, data.orderBook.buy);
            this.mergeSubOrderBook(this.orderBook.sell, data.orderBook.sell);
        }
        // build chart
        this.buildChart();
      },
      mergeSubOrderBook(subOrderBook, newSubOrderBook) {
        const newRows = [];

        window._.each(newSubOrderBook, item => {
          const rowExisted = this.findRowByPrice(subOrderBook, item.price);

          if (rowExisted) {
            rowExisted.quantity = new BigNumber(`${rowExisted.quantity || 0}`).add(`${item.quantity || 0}`).toString();
          } else {
            newRows.push(item);
          }
        });
        // concat with newRows
        subOrderBook = window._.concat(subOrderBook, newRows);
      },
      findRowByPrice(subOrderBook, price) {
        return window._.find(subOrderBook, item => {
          return new BigNumber(`${item.price || 0}`).eq(`${price || 0}`);
        });
      },
      getDefaultParams() {
        return {
          currency: this.internalCurrency,
          coin: this.internalCoin,
          tickerSize: this.tickerSize
        };
      },
      getTickerSize() {
        let priceGroups = window._.filter(this.masterdata.price_groups, (item) => {
          return item.currency === this.internalCurrency && item.coin === this.internalCoin;
        });
        this.tickerSize = window._.minBy(priceGroups, 'value') ? window._.minBy(priceGroups, 'value').value : 0.01;
        this.buildChart();
      },
      reload(coin, currency) {
        this.internalCoin = coin;
        this.internalCurrency = currency;
        // this.getTickerSize();
      },
      redraw() {
        this.visible = !this.visible;
        this.$nextTick(() => {
          // Re-render start
          this.visible = !this.visible;
          this.$nextTick(() => {
            // Re-render end
            this.buildChart();
          });
        });
      },
      buildChart() {
        if (this.hideDataOrderBook) {
          this.buyOrdersBook = [];
          this.sellOrdersBook = [];
        } else {
          const orderBook = this.getOrderbookDisplay();
          this.buyOrdersBook = orderBook ? orderBook.buy : [];
          this.sellOrdersBook = orderBook ? orderBook.sell : [];
        }

        this.updateChartOptions();
      },
      updateChartOptions() {
        this.$refs.lineCharts.chart.update(this.getChartOptions());
        this.$refs.lineCharts.chart.zoomOut();
      },
      getChartOptions() {
        return {
          chart: {
            type: 'area',
            zoomType: 'x',
            backgroundColor: '#181f2b',
          },
          title: {
            text: null
          },
          xAxis: {
            allowDecimals: true,
            gridLineWidth: 1,
            gridLineColor: '#2B3038',
            lineColor: 'rgba(255,255,255,.2)',
            tickColor: 'rgba(255,255,255,.2)',
            labels: {
              formatter: function () {
                return `${this.value}`;
              }
            }
          },
          yAxis: {
            gridLineColor: '#2B3038',
            lineColor: 'rgba(255,255,255,.2)',
            tickColor: 'rgba(255,255,255,.2)',
            title: {
              text: null,
            },
            labels: {
              align: 'left',
              x: 0,
            },
            showFirstLabel: false,
          },
          tooltip: {
            pointFormat: this.pointFormat(),
            headerFormat: '',
            backgroundColor: '#fff',
            borderColor: 'rgba(255,255,255,.08)',
            style: {
              color: '#999'
            }
          },
          plotOptions: {
            area: {
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 2,
                states: {
                  hover: {
                    enabled: true,
                  },
                },
              },
            },
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: window.i18n.t('exchange.basic.chart.buy_order'),
              color: '#00a854',
              data: this.buyOrdersBook,
              lineWidth: 2,
              fillOpacity: 0.1,
              showInLegend: false
            },
            {
              name: window.i18n.t('exchange.basic.chart.sell_order'),
              color: '#f04134',
              data: this.sellOrdersBook,
              lineWidth: 2,
              fillOpacity: 0.1,
              showInLegend: false
            }
          ]
        };
      },
      pointFormat(currency) {
        return `${window.i18n.t('exchange.basic.chart.price')}: {point.x} <b>${this.internalCurrency}</b><br/>{series.name}: {point.y}`;
      }
    },
    mounted() {
      this.internalCoin = this.coin || Const.DEFAULT_COIN;
      this.internalCurrency = this.currency || Const.DEFAULT_CURRENCY;
      // this.reCalculateMinMaxRowDisp();
      this.getTickerSize();
      this.buildChart();
    }
  }
</script>
<style lang="scss">
  #high-chart {
    padding: 0;
    margin: 0 auto;
    background-color: #181f2b;
    height: 100%;
    width: 100%;
    .highcharts-container {
      height: 100% !important;
      .highcharts-root {
        height: 100% !important;
      }
    }
  }
</style>
