var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OtpModal"},[_c('modal',{attrs:{"name":_vm.name,"width":_vm.sizeScreen,"title":'Filter',"cssTitle":{
        textAlign: 'left',
        fontSize: '16px',
        color: _vm.userThemeMode === 'light-theme' ? 'black' : 'white'
      }}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"content_login_form_container"},[_c('div',{staticClass:"select-box-item"},[_c('div',{staticClass:"text-main choose-type_text"},[_vm._v(" "+_vm._s(_vm.$t('orders.order_history.time'))+" ")]),_c('select-box',{attrs:{"options":_vm.transformedTimeShow},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('div',{staticClass:"select-box-coin"},[_c('span',{staticClass:"text-main"},[_vm._v(_vm._s(_vm.$t('address.coin')))]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutSidePair),expression:"clickOutSidePair"}],staticClass:"drop-down-pair drop-down position-relative d-flex justify-content-between align-items-center"},[(_vm.coinSelected.name)?_c('span',{staticClass:"placeholder-input"},[_vm._v(_vm._s(_vm.coinSelected.name == 'All' ? _vm.$t("order.order_form.all") : _vm.coinSelected.name))]):_vm._e(),_c('span',{staticClass:"w-100 h-100 current-pair",on:{"click":function($event){_vm.openDropPair = !_vm.openDropPair}}}),_c('i',{staticClass:"icon",class:{
          'icon-arrow2': _vm.openDropPair,
          'icon-arrow1': !_vm.openDropPair,
        },on:{"click":function($event){_vm.openDropPair = !_vm.openDropPair}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.openDropPair),expression:"openDropPair"}],staticClass:"position-absolute drop-list drop-list-pair"},[_c('div',{staticClass:"position-relative text-search-pair"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pairName),expression:"pairName"}],staticClass:"w-100 h-100",attrs:{"type":"text","placeholder":_vm.$t('order.trade_history.search')},domProps:{"value":(_vm.pairName)},on:{"input":[function($event){if($event.target.composing)return;_vm.pairName=$event.target.value},_vm.searchPair],"focus":function($event){_vm.focusInput = true}}}),_c('img',{staticClass:"close-value position-absolute",attrs:{"width":"19","height":"19","src":require(`@/assets/images/landing/icon-search${_vm.dark}.svg`)}})]),_c('div',{staticClass:"list-pair-drop"},[(_vm.pairShow?.length > 0)?_vm._l((_vm.pairShow),function(pair){return _c('div',{key:pair.value,staticClass:"list-pair",on:{"click":function($event){return _vm.handleSelectPair(pair)}}},[_c('span',{class:{
                  active:
                    pair.name.toUpperCase() ===
                    _vm.coinSelected.name.toUpperCase(),
                }},[(pair.name !== _vm.$t('order.order_form.all'))?_c('img',{staticClass:"coin-img",attrs:{"src":`${_vm.coinImages[pair.name]}`,"alt":"","width":"30","height":"30"}}):_vm._e(),_vm._v(" "+_vm._s(pair.name !== _vm.$t("order.order_form.all") ? pair.name.toUpperCase() : pair.name))])])}):_c('div',{staticClass:"list-no-data"},[_vm._v(_vm._s(_vm.$t('common.datatable.no_have_data')))])],2)])])]),_c('div',{style:({display: 'flex', gap: '10px'})},[_c('button',{staticClass:"btn btn-reset",on:{"click":_vm.handleReset}},[_vm._v(" "+_vm._s(_vm.$t("button.reset"))+" ")]),_c('button',{staticClass:"btn btn-search",on:{"click":_vm.handleSearch}},[_vm._v(" Search ")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }