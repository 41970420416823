<template>
  <div id="tab_faq">
    <div v-for="(item, index) in dataTab" :key="index">
        <div @click="handleChangeTab(item)" class="path-item" :class="idActive === item?.id ? 'active' : ''">
            <!-- <img :src="item.iconActive" alt="icon"/> -->
            {{ $t(item.title_en) }}
        </div>
    </div>
  </div>
</template>
<script>
import Const from '@/common/Const';
import { mapState } from 'vuex';

export default {
    name: 'TabFaq',
    props: {
      dataTab: {
      type: Array,
      default: [],
    },
  },
    data() {
        return {
          settingsAccountPath: [],
          idActive: null,
        }
    },
    created() {
      // this.listTitle();
    },
    computed: {
      currentPath() {
        if(
          this.$route.path === '/account/address' || 
          this.$route.path === '/account/devices' ||
          this.$route.path === '/account/activity' ||
          this.$route.path === '/account/anti-phishing' ||
          this.$route.path === '/account/anti-phishing/create' ||
          this.$route.path === '/account/anti-phishing/change'
        ) {
          return '/account/security'
        } 
        return this.$route.path;
      },
      ...mapState({
        theme: state => state.userThemeMode,
      }),
      lightMode() {
        return this.theme === 'light-theme';
      }
    },
    methods: {
      handleChangeTab(value) {
        this.idActive = value?.id
        this.$emit(`tabType`, value);

      }
    },
    watch: {
      data(newValue) {
        this.idActive = newValue?.[0].id
      },
  },
}
</script>
<style lang="scss">
  #tab_faq {
    min-width: 336px;
    height: 100%;
    background-color: var(--background-color-primary-2);;
    min-height: calc(100vh - 65px - 252px);
    .path-item {
      display: flex;
      align-items: center;
      gap: 22px;
      padding: 18px 18px 18px 50px;
      color: var(--dark-1);
      font-family: $font-inter-regular;
      font-size: 20px;
      line-height: 24px;
      cursor: pointer;
      &.active {
        background: var(--hover-item-nav);
        position: relative;
      }
      &.active::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background: $color-caribbean-green;
      }

      &:hover{
        background: var(--hover-item-nav);
      }
    }
    @media screen and (max-width: 992px) {
      display: flex;
      height: auto;
      min-height: fit-content;
      overflow-x: scroll;
      /* width */
      &::-webkit-scrollbar {
        height: 0;
      }
      .path-item {
        padding: 10px 20px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
</style>