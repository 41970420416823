<template>
  <div class="container-box-auth">
    <div class="section-1">
      <img width="32px" height="32px" :src="icon"/>
      <StatusBadge v-if="isStatus" :status="isEnabled ? 'enable' : 'disable'" />
    </div>
    <div class="section-2">
      <span class="title">{{title}}</span>
      <span class="description" v-if="type === 'account_activity'">{{$t('account.recent_activity')}}: {{description}}</span>
      <span class="description" v-if="type !== 'account_activity'">{{description}}</span>
      <div v-if="isEnabled && isLost" class="link_lost_opt"  @click="$emit('onShowLostOTP')">
        {{ $t('shared_components.google_authentication_modal.lost_code') }}
      </div>
      <div v-if="isAddress" class="link_lost_opt">
        <router-link class="address_management-link" to="/account/address">
          {{ $t('security.address_management') }}
        </router-link>
      </div>

    </div>
    <div class="section-3">
      <router-link v-if="link" :to="link">
        <button v-if="type !== 'anti_phishing_code'" class="btn btn-withdraw">
          {{ buttonText }}
        </button>
        <button v-else class="edit-text">
          Edit
        </button>
      </router-link>
      <button v-if="!link" class="btn btn-withdraw" @click="handleButtonClick">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/components/setting_pages/StatusBadge.vue";
export default {
  components: {
    StatusBadge
  },
  name: 'TwoFactorAuth',
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonText:{
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon:{
      type: String,
      required: true,
    },
    isEnabled:{
      type: Boolean,
      required: true,
    },
    isStatus:{
      type: Boolean,
    },
    isLost:{
      type: Boolean,
    },
    isAddress:{
      type: Boolean,
    },
    type:{
      type: String,
    },
    link:{
      type: String,
    },
    handleOnClick: {
      type: Function,
    }
  },
  emits: ["onShowLostOTP"],
  methods: {
    handleButtonClick() {
      this.handleOnClick();
    }
  }
}
</script>

<style scoped lang="scss">
.container-box-auth{
  display: flex;
  width:100%;
  max-width: 517px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border: 1px solid var(--color-border);
  border-radius: 8px;

  .link_lost_opt {
    font-family: $font-inter-regular;
    font-size: 16px;
    line-height: 20px;
    color: $color-aqua-green;
    text-decoration: underline;
    margin-top: 5px;
    display: inline-block;
    cursor: pointer;
  }
  .section-1{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .section-2{
    display: flex;
    flex-direction: column;
    width: 100%;
    .title{
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: var(--text-primary-color);
    }
    .description{
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #8E8E92;
    }
  }
}

.btn {
  display: flex;
  max-width: 93px;
  width: 100%;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border: none;
  border-radius: 1234px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 32px;
  letter-spacing: -0.144px;
  color: var(--background-color-primary);
  background:  var(--text-primary-color);
  &:hover {
    color: var(--background-color-primary);
  }
  @media screen and (max-width: 770px) {
    padding: 10px 16px;
    font-size: 14px;
  }
}
.edit-text{
  font-size: 14px;
  font-style: normal;
  color: #03AAC7;
  font-family: $font-notosans-bold;
  background-color: transparent;
}

</style>
