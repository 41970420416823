<template>
  <base-layout>
    <div class="auth-container">
      <!-- end guest-logo -->
      <!-- SEND RESET EMAIL -->
      <div class="guest-page-form" v-show="!isshow">
        <div class="form-container">
          <div class="guest-page-form-header text-center">
            <span class="title-l"></span>
            <h3 class="title-account">
              {{ $t("reset_password.title_forgot_2") }}
            </h3>
            <span class="title-l"></span>
          </div>
          <div class="guest-page-form-body pt-20">
            <div class="input-group input-group-otp">
              <label class="mb-8">{{ $t("common.email") }}</label>
              <input
                :name="'email'"
                data-vv-validate-on="none"
                v-validate="'required|email'"
                :maxlength="maxlength"
                :placeholder="$t('common.placeholders.email2')"
                v-model="email"
                type="text"
                :class="{ error: errors.has('email') }"
                @focus="resetError"
                class="form-input"
              />
              <div class="invalid-feedback" v-if="errors.has('email')">
                {{ errors.first("email") }}
              </div>
              <div class="invalid-feedback" v-if="errors.has('error')">
                {{ errors.first("error") }}
              </div>
            </div>
            <div class="form_actions">
              <button
                :class="{ chrome_only: isChrome }"
                tabindex="0"
                :disabled="isSubmitting"
                type="button"
                class="btn-primary"
                @click="sendResetPasswordEmail"
              >
                {{ getSubmitName($t("reset_password.submit_btn_2")) }}
              </button>
            </div>
            <div class="back-login d-fex justify-content-center">
              <span>
                {{ $t("reset_password.back_to") }}
                <router-link class="btn-back-login" to="/login">{{
                  $t("login.title")
                }}</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- SEND RESET EMAIL -->

      <!-- RESET FORM -->
      <div class="guest-page-form" v-show="isshow">
        <div class="form-container">
          <div class="guest-page-form-header text-center">
            <span class="title-l"></span>
            <h3 class="title-account">
              {{ $t("reset_password.title_forgot_2") }}
            </h3>
            <span class="title-l"></span>
          </div>

          <div class="guest-page-form-body">
            <label class="label password-label">{{
              $t("common.new_password")
            }}</label>
            <div class="input-group">
              <div class="pos-relative">
                <input
                  :placeholder="$t('common.placeholders.password2')"
                  v-model="password"
                  :type="isEyeOpenPassword ? 'text' : 'password'"
                  @focus="resetError"
                  :maxlength="maxlength"
                  name="password"
                  data-vv-validate-on="none"
                  v-validate="{
                    required: true,
                    min: 8,
                    max: 72,
                    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                  }"
                  :class="{ error: errors.has('password') }"
                  class="form-input form-input-password form-new-password"
                  @input="checkNewPasswordFormat()"
                />
                <div
                  @click="isEyeOpenPassword = !isEyeOpenPassword"
                  class="icon-last"
                >
                  <img
                    v-if="userThemeMode == 'light-theme'"
                    :src="`/images/eye-${
                      isEyeOpenPassword ? 'open' : 'close'
                    }.svg`"
                    alt="eye"
                  />
                  <img
                    v-else
                    :src="`/images/eyes-${
                      isEyeOpenPassword ? 'open-dark' : 'close-dark'
                    }.svg`"
                    alt="eye"
                  />
                </div>
                <div class="tooltip_info">
                  <div class="item">
                    <i
                      :class="
                        passwordValidate.checkedLength
                          ? 'icon-check2'
                          : 'icon-close'
                      "
                    ></i>
                    <span class="text">{{
                      $t("validate.format.password_1")
                    }}</span>
                  </div>
                  <div class="item">
                    <i
                      :class="
                        passwordValidate.checkedNumber
                          ? 'icon-check2'
                          : 'icon-close'
                      "
                    ></i>
                    <span class="text">{{
                      $t("validate.format.password_2")
                    }}</span>
                  </div>
                  <div class="item">
                    <i
                      :class="
                        passwordValidate.checkedUpperCase
                          ? 'icon-check2'
                          : 'icon-close'
                      "
                    ></i>
                    <span class="text">{{
                      $t("validate.format.password_3")
                    }}</span>
                  </div>
                  <div class="item">
                    <i
                      :class="
                        passwordValidate.checkedLowerCase
                          ? 'icon-check2'
                          : 'icon-close'
                      "
                    ></i>
                    <span class="text">{{
                      $t("validate.format.password_4")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="invalid-feedback" v-if="errors.has('password')">
                {{ errors.first("password") }}
              </div>
            </div>
            <label class="label re-password-label">{{
              $t("common.repassword")
            }}</label>
            <div class="input-group">
              <!-- <div class="input-group-prepend"><span class="icon-key2"></span></div> -->
              <div class="pos-relative">
                <input
                  :placeholder="$t('common.placeholders.repassword2')"
                  v-model="passwordConfirmation"
                  :type="isEyeOpenRePassword ? 'text' : 'password'"
                  @focus="resetError"
                  :name="$t('confirm password')"
                  :maxlength="maxlength"
                  data-vv-validate-on="none"
                  v-validate="'required|confirmed:password'"
                  :class="{ error: errors.has($t('confirm password')) }"
                  class="form-input form-input-password"
                />
                <div
                  @click="isEyeOpenRePassword = !isEyeOpenRePassword"
                  class="icon-last"
                >
                  <img
                    v-if="userThemeMode == 'light-theme'"
                    :src="`/images/eye-${
                      isEyeOpenRePassword ? 'open' : 'close'
                    }.svg`"
                    alt="eye"
                  />
                  <img
                    v-else
                    :src="`/images/eyes-${
                      isEyeOpenRePassword ? 'open-dark' : 'close-dark'
                    }.svg`"
                    alt="eye"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                {{ errors.first($t("confirm password")) }}
              </div>
              <div class="invalid-feedback">{{ errors.first("error") }}</div>
              <div class="form_actions">
                <button
                  :class="{ chrome_only: isChrome && !errors.any() }"
                  tabindex="0"
                  :disabled="isSubmitting"
                  type="button"
                  class="btn-primary"
                  @click="resetPassword"
                >
                  {{ getSubmitName($t("reset_password.submit_btn_2")) }}
                </button>
              </div>
              <div class="back-login d-fex justify-content-center">
                <span>
                  {{ $t("reset_password.back_to") }}
                  <router-link class="btn-back-login" to="/login">{{
                    $t("login.title")
                  }}</router-link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- RESET FORM -->
    <confirmation-modal
      linkOnCancel="Login"
      v-if="isshow === true"
    ></confirmation-modal>
    <confirmation-modal v-else></confirmation-modal>
  </base-layout>
</template>

<script>
import rf from "@/request/RequestFactory";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import BaseLayout from "./BaseLayout.vue";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal";
import COMMON_CONST from "@/common/Const";
import { mapState } from "vuex";
import { extend } from "vee-validate";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      modalMessage: "",
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      host: window.location.origin,
      isConfirming: false,
      isshow: false,
      heightModal: "",
      withModal: "",
      maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
      isChrome: !!window.navigator.userAgent.toLowerCase().indexOf("chrome"),
      locale: Cookies.get("locale", { domain: process.env.VUE_APP_PUBLIC_URL }),
      isEyeOpen: false,
      isEyeOpenRePassword: false,
      isEyeOpenPassword: false,
      passwordValidate: {
        checkedLength: false,
        checkedNumber: false,
        checkedUpperCase: false,
        checkedLowerCase: false,
      },
    };
  },
  mixins: [RemoveErrorsMixin],
  components: {
    BaseLayout,
    ConfirmationModal,
  },
  props: {
    token: { type: String, default: "" },
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  methods: {
    confirm() {
      this.heightModal = "";
      this.isConfirming = true;
      if (this.isSubmitting) {
        return;
      }
      this.startSubmit();
      rf.getRequest("UserRequest")
        .confirmResetPassword(this.token)
        .then((response) => {
          this.isConfirming = false;
          this.isshow = true;
          this.endSubmit();
          this.email = response.data;
        })
        .catch((error) => {
          this.isConfirming = false;
          this.endSubmit();
          this.showModalResetPassword("confirm-fail-modal");
        });
    },
    async sendResetPasswordEmail() {
      this.resetError();

      if (this.isSubmitting) {
        return;
      }

      await this.$validator.validate("email");

      if (this.errors.any()) {
        return;
      }

      this.startSubmit();

      rf.getRequest("UserRequest")
        .resetPassword(this.email)
        .then((response) => {
          this.endSubmit();
          let message = "resetMail";
          this.showModalEmail(message);
        })
        .catch((error) => {
          this.endSubmit();
          this.showResetPasswordError(error);
        });
    },

    async resetPassword() {
      this.resetError();
      if (this.isSubmitting) {
        return;
      }
      await this.$validator.validate("email");
      if (this.password) {
        await this.validateInputNewPassword();
      } else {
        await this.$validator.validate("password");
      }
      await this.$validator.validate("confirm password");

      if (this.errors.any()) {
        return;
      }

      this.startSubmit();

      const params = {
        email: this.email,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        token: this.token,
      };

      rf.getRequest("UserRequest")
        .executeResetPassword(params)
        .then((response) => {
          this.endSubmit();
          const message = "resetPW";
          this.showModalPassword(message);
        })
        .catch((error) => {
          this.endSubmit();
          this.convertRemoteErrors(error);
        });
    },
    async validateInputNewPassword() {
      if (!(await this.$validator.validate("password"))) {
        this.errors.items.forEach((item, index) => {
          if (item.field === "password") {
            this.errors.items[index].msg = this.$t(
              "change_password_form.new_password_error"
            );
          }
        });
      }
    },
    showModalResetPassword(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("reset_password.send_mail_title"),
        content: "",
        btnCancelLabel: null,
        btnConfirmLabel: null,
        widthModal: 560,
        onConfirm: () => {},
        onCancel: () => {},
      });
    },
    showResetPasswordError(error) {
      const errors = error.response.data.errors || {};
      const errors_1 = error.response.data.message || {};
      for (const field in errors) {
        for (const error of errors[field]) {
          if (field == "email") {
            // this.errors.add({field : this.$t('common.validate.email'), msg : this.$t('validation.verified_email')})
            this.errors.add({ field: "email", msg: this.$t(`${errors_1}`) });
          } else if (field == "error") {
            // this.errors.add({field : field, msg : this.$t('login.confirm_email_failed')})
            this.errors.add({ field: field, msg: this.$t(`${errors_1}`) });
          } else {
            this.errors.add({ field: field, msg: error });
          }
        }
      }
      if (!this.errors.any()) {
        this.errors.add("error", this.$t("server.logs"));
      }
    },
    showModalEmail(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("reset_password.send_mail_title"),
        class: "modal-success-email",
        // content: message,
        btnCancelLabel: null,
        btnConfirmLabel: null,
        widthModal: 560,
        onConfirm: () => {},
        onCancel: () => {},
      });
    },

    showModalPassword(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("reset_password.send_mail_title"),
        class: "modal-success-password",
        // content: message,
        btnCancelLabel: null,
        btnConfirmLabel: null,
        widthModal: 560,
        onConfirm: () => {},
        onCancel: () => {},
      });
    },

    onModalClose() {
      // if(this.isshow){
      //   console.log("Onmodal Close");
      //   this.$router.push({ path: '/login' });
      // }
    },
    checkNewPasswordFormat() {
      const password = this.password.toString().trim();
      this.passwordValidate.checkedLength = password.length >= 8;
      this.passwordValidate.checkedNumber = /\d/.test(password);
      this.passwordValidate.checkedUpperCase = /[A-Z]/.test(password);
      this.passwordValidate.checkedLowerCase = /[a-z]/.test(password);
    },
  },
  mounted() {
    if (this.token) {
      return this.confirm();
    }
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 13 && this.$route.name == "ResetPassword") {
        if (!this.ishow) this.sendResetPasswordEmail();
        else this.resetPassword();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";
.form-input {
  display: block;
  height: 49px;
  width: 100% !important;
  max-width: 100%;
  padding: 12px;
  background: var(--background-color-second);
  border-radius: 123px;
  border-color: var(--color-border);
  color: var(--text-primary-color) !important;
  font-size: $font-root;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  &:focus,
  &:active {
    border: 1px solid $color-aquamarine !important;
  }
  &.error {
    border: 1px solid $text-color-red !important;
  }
  &::placeholder {
    color: var(--text-placeholder);
  }
}
.invalid-feedback {
  margin-top: 8px;
}
.input-group-prepend {
}
.pt-20 {
  padding-top: 10px;
}
.verify-box {
  border-radius: 3px;
  height: 50px;
  text-align: center;
  border: 1px solid $color-green;
  padding-left: 0 !important;
  background-color: $color-dark-blue;
  line-height: 30px;
  .icon-home2 {
    padding-right: 10px;
  }
  a {
    position: relative;
    top: 9%;
    transform: perspective(1px) translateY(-50%);
  }
}
.title {
  text-transform: uppercase;
}
.btn-primary {
  height: 50px;
  width: 100%;
  border-radius: 1234px;
  background-color: $color-caribbean-green;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.084px;

  margin: 15px 0 10px;
  &:disabled {
    background-color: $background-bright-gray !important;
    border-color: $border-bright-gray !important;
    color: $text-default !important;
  }
  &:hover:enabled,
  &:focus {
    background-color: $color-aquamarine !important;
    border-color: $border-blue-custom !important;
    color: $color-grey-dark !important;
  }
}

.back-login {
  & span {
    font-family: $font-inter-regular;
    color: $color-gray-dark;
    font-size: $font-big;
  }
  & a {
    font-family: $font-inter-bold;
    color: $color-caribbean-green;
    font-size: $font-big;
  }
}

.title-account {
  margin: 0px auto 40px auto !important;
  font-size: $font-title-size-medium-2;
  line-height: 41px;
  color: var(--text-primary-color) !important;
  font-family: $font-inter-bold;
}
.title-l {
  margin: 13px 0px 15px 0px;
  height: 1px;
  //width: 30%;
  border-radius: 5px;
  background-color: $color-light-grey;
}
.email-reset {
  font-family: $font-family-roboto;
  font-size: $font-root;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  letter-spacing: normal;
  color: $text-default;
  font-weight: normal;
  &:focus,
  &:active {
    color: $text-default;
    border-bottom: 1px solid $border-alto !important;
  }
}
.text-homepage-login {
  text-decoration: none;
  background-color: $color-dark-blue;
  border: 1px solid $color-green;
  display: flex;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 50px;
  // margin: 20px;
  .link-text-login {
    font-size: $font-medium;
    line-height: 40px;
    color: $color-jungle-green;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    font-family: $font-family-roboto-medium;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
  }
  .icon-network {
    color: $color-jungle-green;
    font-size: 24px;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}
.input-group {
  margin-bottom: 24px;
  .icon-last {
    img {
      position: absolute;
      top: 40%;
      right: 15px;
      float: left;
      cursor: pointer;
    }
  }
  .tooltip_info {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(calc(100% + 11px));
    padding: 10px;
    background: var(--background-desc);
    border-radius: 8px;
    margin-right: -5px;
    .item {
      display: flex;
      align-items: center;
      gap: 12px;
      & + .item {
        margin-top: 8px;
      }
      i::before {
        color: $color-aqua-green;
      }
      .icon-close::before {
        color: $text-color-red;
      }
      .text {
        font-family: $font-inter-regular;
        color: $color-white;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  .form-new-password:focus {
    ~ .tooltip_info {
      display: block;
    }
  }
}
label {
  font-weight: 400 !important;
  font-size: $font-smaller;
  line-height: 16px;
  color: var(--text-label) !important;
  letter-spacing: -0.06px;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}
#confirmationModal {
  :deep(.dialog22) {
    margin: 0 !important;
  }
}
@media screen and (max-width: 992px) {
  .tooltip_info {
    top: 110% !important;
    right: 104% !important;
    width: 100%;
    z-index: 10;
  }
  #confirmationModal {
    :deep(.modal-dialog) {
      .modal-content {
        .modal-body {
          .fo_green_4,
          .fo_green_3,
          .fo_green2 {
            .tit_modal_confir {
              font-size: $font-title-size-medium-2;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .title-account {
    margin-bottom: 25px !important;
    font-size: $font-big-24;
    line-height: 29px;
  }

  .guest-page-form-body {
    max-width: 100%;
    .input-group {
      margin-bottom: 16px;
      width: 100%;
      input {
        font-size: $font-root !important;
      }
    }
    input {
      &:focus,
      &:active {
        border: 1px solid $color-aquamarine !important;
      }
      &.error {
        border: 1px solid $text-color-red !important;
      }
    }
    .back-login {
      & span {
        font-size: $font-root;
        a {
          font-size: $font-root;
        }
      }
    }
    .btn-primary {
      width: 100%;
    }
    .form_actions {
      margin-bottom: 10px;
    }
  }

  #confirmationModal {
    :deep(.modal-dialog) {
      width: 343px !important;
      .modal-header {
        padding-top: 10px !important;
      }
      .modal-content {
        width: 343px !important;
        min-height: 281px;
        .modal-body {
          padding: 0px;
          .fo_green_4,
          .fo_green_3,
          .fo_green2 {
            img {
              width: 74px;
              height: 74px !important;
            }
            .title-header-modal {
              margin-top: 23px !important;
              font-size: $font-big-20;
              margin-bottom: 5px;
            }
            .tit_modal_confirm {
              font-size: $font-big-20;
              margin: 0;
            }
            .tit_modal_confir {
              font-size: $font-big-20;
              margin: 0;
            }
            .modal-content-text {
              font-size: $font-root;
              margin: 0 auto 10px;
            }

            .mb-20 {
              margin-bottom: 0px !important;
            }
            .mt-40 {
              margin-top: 20px !important;
            }
            .btn-resetMail {
              width: 160px !important;
              margin-top: 20px !important;
            }
            .desc-change {
              font-size: $font-root;
            }
          }
        }
      }
    }
    :deep(.dialog22) {
      //top: 40%;
      //left: 40%;
      .modal-content {
        height: 318px !important;
      }
      .btn-resetMail {
        margin-top: 15px !important;
        width: 160px !important;
        height: 50px;
      }
      .modal-content-text {
        font-size: $font-root;
        margin: 0 !important;
      }
      .mb-8 {
        margin-bottom: 5px !important;
      }
    }
  }
}

.mb-8 {
  margin-bottom: 8px;
}
</style>
