<template>
  <div id="notfound" class="clearfix">
    <div class="title">
      <span>
        <strong>404</strong> -
      </span>
      {{$t('not_found.title')}}
    </div>
    <div class="text">
      <p>{{$t('not_found.text1')}} {{$t('not_found.text2')}}</p>
    </div>
        
    <div class="btn-landing">
        <router-link to="/"><p class ="btn-text">{{$t('not_found.landing')}}</p></router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

#notfound {
  display: block;
  background-color: $color-alabaster;
  padding-left: 40px;
  padding-bottom: 103px;
  // padding-top:0;
  width: 100%;
  .title {
    font-family: Roboto;
    font-size: 50px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: $color-eden-light;
    margin: 76px 0 22px 0;
  }
  .text {
    width: 498px;
    height: 40px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: $color-grey-dark;
  }
  
  .btn-landing {
    width: 160px;
    height: 40px;
    border-radius: 3px;
    background-color: $color-jungle-green;
    margin-top:29px;
    padding-top:7px;
    // vertical-align:middle;
    &:hover{
      background-color:$color-blue-custom
    }
    .btn-text{
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.42;
      letter-spacing: 0.9px;
      text-align: center;
      color: $color-white;
      // vertical-align:middle;
      margin :0;
      // margin-top:auto;
      text-transform: uppercase;
    }

  }
}
</style>