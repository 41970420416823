<template>
  <div class="header-overview">
    {{ $t(nameTab) }}
  </div>
</template>
<script>
export default {
  data() {
    return {
      nameTab: "funds.header",
      sizeScreen: window.innerWidth,
    };
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    if (this.$route.path == "/funds/history-wallet") {
      this.nameTab = "funds.history.transaction_history";
    } else if (this.$route.path == "/funds/spot-wallet") {
      this.nameTab = "funds.history.spot_overview";
    } else if (this.$route.path == "/funds/futures-wallet") {
      this.nameTab = "funds.history.future_overview";
    }
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.header-overview {
  color: var(--color-text);
  font-size: $font-title-size-medium-small;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.39px;
  margin-bottom: 40px;

  @media screen and (max-width: 992px) {
    white-space: nowrap;
  }
}
</style>
