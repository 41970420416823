<template>
  <div id="message-with-icon" v-if="messages.length"  :class="configs.position">
    <div id="message_content" v-for="(message , index) in messages" :class="setClass(message.type)">
      <span v-if="message.type != 'Success'" :class="setIcon(message.type)"></span>
      <img v-if="message.type == 'Success'" src="../../../assets/images/tranferSucess.svg" />
      <div class="message-text">
        <!-- <strong>{{ message.type.charAt(0).toUpperCase() + message.type.slice(1) }}: </strong> -->
        <span v-if="typeof message.content === 'string'">{{ message.content | upperFirst }}</span>
      </div>
       <span class="btn_cancel">
        <i class="icon-close" @click="removeNoti(index)"></i>
      </span>
    </div>
  </div>
</template>

<script>
  window.MessageWithIcon = {
    success: function(content, params, configs){
      window.app.$broadcast('showMessageWithIcon', 'Success', content, params, configs);
    },
    error: function(content, params, configs){
      window.app.$broadcast('showMessageWithIcon', window.i18n.t('messages.error'), content, params, configs);
    }
  }
  export default {
    data(){
      return {
        messages: [],
        showTime: 5000,
        configs: {},
      }
    },
    methods: {
      removeNoti() {
        this.messages = [];
      },
      setClass(type) {
        if(type == 'Success') {
          return 'success';
        }
        else if (type == window.i18n.t('messages.error')) {
          return 'error';
        }
      },
      setIcon(type) {
        if(type == window.i18n.t('messages.success')) {
          return 'icon-save';
        }
        else if (type == window.i18n.t('messages.error')) {
          return 'icon-close';
        }
      }
    },
      created() {
          this.messages = [];
          this.configs = {
              position: 'bottom_right'
          };
          this.$on('showMessageWithIcon', (type, content, params, configs) => {
              const newMessage = {
                  type,
                  content,
                  params,
                  timer: setTimeout(() => {
                      this.messages.splice(this.messages.indexOf(newMessage), 1);
                  }, this.showTime)
              };
              if (configs) {
                  this.configs = Object.assign(this.configs, configs);
              }
              const index = this.messages.findIndex((message) => message.type === type && message.content === content);
              if (index >= 0) {
                  // message already exists, update it
                  this.messages.splice(index, 1, newMessage);
              } else {
                  // message doesn't exist, add it
                  this.messages.push(newMessage);
              }
          });
      }
  };

</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
#message-with-icon {
  position     : fixed;
  min-height   : 54px;
  min-width    : 369px;
  line-height: 9px;
  z-index      : 10000;
  padding      : 15px;
  width: 380px;
  &.bottom_right {
    bottom: 10px;
    right: 20px;
  }

  #message_content {
    padding: 16px;
    display: flex;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
       
    .btn_cancel {
      position: absolute;
      right: 26px;
      cursor: pointer;
      top: 26px;
      .icon-close::before {
        font-size: 10px;
        color: var(--color-close-toast );
      }
    }
    img {
      margin-right: 13px;
    }
    &.success {
      background-color: var(--bgr-toast-message);
      border: 1px solid var(--bgr-toast-message);
      border-radius: 4px;
      .icon-save {
        padding-right: 13px;
        font-size: $font-big-20;
        font-weight: bold;
        &:before {
          color: #ffffff;
        }
      }
      .message-text {
        align-self: center;
        font-size: 18px;
        line-height: 21px;
        strong, span{
          color: var(--text-primary-color) !important;
        }
      }
    }
    &.error {
      background-color: $color-denim;
      border: 1px solid $color-denim;
      border-radius: 3px;
      .icon-close {
        padding-right: 13px;
        font-size: $font-big-20;
        font-weight: bold;
        &:before {
          color: #ffffff;
        }
      }
      .message-text {
        align-self: center;
        strong, span{
          color: $color-white;
        }
      }
    }
  }
}
</style>
