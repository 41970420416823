<template>
  <div class="page-container">
    <div class="group-coin">
      <div class="volume ">
        <p class="title">{{$t('landing.title_loser')}}</p>
        <a
            v-for="(itemVolume, index) in dataLoser"
            :key="index"
            class="main-volume "
            @click="selectedCurrenyPair( 'usdt', itemVolume.name)"
            :href="'/spot-exchange/basic?currency=usdt&coin=' + itemVolume.name"
            target="_blank"
        >
          <div class="img-coin" >
            <img
                class="icon-coin"
                :src="itemVolume.img"
                alt=""
                width="30"
                height="30"
            />
          </div>


          <div class="item-volume name-coin">{{ itemVolume.name }}</div>
          <div class="price-container d-flex justify-content-between">
            <div
                :class="{
                 upCoin : itemVolume?.changedLastestPrice  > 0,
                 downCoin : itemVolume?.changedLastestPrice < 0,
              }"
                class="item-volume price-coin"
            >
                <span v-if="itemVolume.lastest_price >=1">
                     {{ itemVolume.lastest_price | toNumber |  formatNumberAfterDecimal( 2)}}
                </span>
                <span  v-else-if="itemVolume.lastest_price < 1 && itemVolume.lastest_price>=0.1">
                      {{ itemVolume.lastest_price  | toNumber |  formatNumberAfterDecimal( 4)}}
                </span>
                <span  v-else>
                      {{ itemVolume.lastest_price  | toNumber | formatNumberAfterDecimal( 8)}}
                </span>

            </div>
            <div
                :class="{
                grown: +itemVolume.changed_percent > 0,
                down: +itemVolume.changed_percent < 0,
              }"
                class="item-volume percent-coin"
            >
              <span v-if="itemVolume.changed_percent >=1000">
                {{ itemVolume.changed_percent | formatChangedPercent(5) }}
              </span>
              <span  v-else>
                {{ formatShortText((itemVolume.changed_percent), 2, 8) | changedPercent }}
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";

export default {
  props: {
    dataLoser: {
      type: [Array],
      default: () => {
        return [];
      },
    },
  },
  methods : {
    formatShortText(value, maxDecimal, maxLength) {
      if(value == null || value == "Infinity") return '0.0';
      const split = parseFloat(value).toString().split('.');
      const beforeDecimal = split[0];
      const numFormat = this.numFormat(value, maxDecimal)
      const shorttedNumber = beforeDecimal.length > maxLength ? numFormat.slice(0, maxLength-1).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...') :
        numFormat.slice(0, maxLength).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...')
      const returnValue = numFormat.length > maxLength ? shorttedNumber : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return returnValue.toString();
    },
    numFormat (value, maxDecimal) {
      const numFormat = new BigNumber(value).toFixed(maxDecimal, BigNumber.ROUND_DOWN);
      return numFormat;
    },
    selectedCurrenyPair(currency, coin) {
      const pair = { currency: currency, coin: coin };
      let newurl = `/spot-exchange/basic?currency=${pair.currency}&coin=${pair.coin}`;
      this.$broadcast("selectedPairMarket", pair);

      window.history.pushState({ path: newurl }, "", newurl);
      this.$router.go(newurl);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.w_15 {
  width: 15%;
}
.clearfix {
  content: "";
  clear: both;
  display: table;
}

.item-volume {
  position: relative;
  cursor: pointer;
  .unHideCoin {
    display: none !important;
  }
  &:hover {
    .unHideCoin {
      z-index: 1000;
      white-space: break-spaces;
      display: block !important;
      position: absolute;
      padding: 10px;
      top: -40px;
      border-radius: 8px;
      background: var(--color-tooltip);
      color: $color-white !important;
      font-size: 16px;
      width: 140px !important;
      text-align: start;
    }
  }
}

.page-container {
  padding-right: 0!important;
}

#main-market {
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 100px 50px;
  min-height: calc(100% - 65px - 252px);
  font-family: $font-inter-regular;

  .market-title {
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    color: $color-grey-dark;
    margin: 0;
  }
  .title {
    color: var(--text-close);
    margin: 30px 0 25px;
  }

  .main-volume {
    display: flex;
    padding-bottom: 16px;
    cursor: pointer;

    .img-coin {
      width: 12%;
      img {
        cursor: pointer;
        object-fit: contain;
      }
    }
    .name-coin {
      width: 23%;
      text-transform: uppercase;
    }

    .price-container {
      width: 65%;
      .price-coin {
        width: fit-content;
      }
      .percent-coin {
        width: fit-content;
        text-align: right;
      }
      .upCoin {
        color : $text-color-jade;
      }
      .downCoin {
        color : $text-color-red;
      }
    }
  }

  .volume {
    padding-left: 30px;
    padding-right: 30px;
    .item-volume {
      color: var(--text-primary-color);
      font-size: $font-big-20;
      font-weight: 400;
    }

    .item-label {
      width: 15%;
    }
  }

  .grown {
    color: $text-color-jade !important;
  }
  .down {
    color: $color-denim !important;
  }
}
@media screen and (max-width: 1440px) {
  #main-market {
    .main-volume {
      .name-coin {
        width: 50%;
      }
      .img-coin {
        width: 20%;
      }
    }
    .volume {
      padding: 0 16px;
    }
  }
}
@media screen and (max-width: 1191px) {
  #main-market {
    .main-volume {
      .name-coin {
        width: 38%;
      }
      .price-container {
       width: 50%;
      }
      .img-coin {
        width: 7%;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  #main-market{

    padding: 32px 15px;
    .market-title{
      font-size: 24px;
    }
    .main-volume{
      &:last-child{
        padding-bottom: 0;
      }
      .name-coin {
        width: 38%;
      }
      .price-container {
       width: 50%;
      }
    }
    .volume{
      padding: 0;

      .item-volume{
        font-size: 14px;
      }

      .img-coin{
        width: 9%;
        img{
          width: 24px;
        height: 24px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #main-market {
    .volume {
      .img-coin {
        width: 5%;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  #main-market {
    .volume {
      .img-coin {
        width: 9%;
      }
    }
  }
}

</style>
