<template>
  <div id="table_positions">
     
    <position-margin-modal></position-margin-modal>
    <confirmation-modal></confirmation-modal>
    <div class="table-container table-positions">
      <data-table2 ref="datatable" :getData="getData" :msgEmptyData="$t('common.datatable.empty_data.positions')" :limit="100">
        <template slot-scope="props">
          <th data-sort-field="symbol" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.positions_symbol')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.positions_symbol')}}</span>
                {{$t('margin.position_symbol_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="current_qty" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.size')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.size')}}</span>
                {{$t('margin.position_size_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="entry_value" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.value')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.value')}}</span>
                {{$t('margin.position_value_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="entry_price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.entry_price')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.entry_price')}}</span>
                {{$t('margin.position_entry_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="mark_price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.mark_price')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.mark_price')}}</span>
                {{$t('margin.position_mark_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="liquidation_price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.liq_price')}}
              <div class="tooltip_custom_c">
                <span class="underline"> {{$t('margin.liq_price')}}</span>
                {{$t('margin.position_liq_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="init_margin" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.margin')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.margin')}}</span>
                {{$t('margin.position_margin_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="unrealised_pnl" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.unrealised_pnl')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.unrealised_pnl')}}</span>
                {{$t('margin.position_unrealised_pnl_tooltip')}}
              </div>
            </span>
            
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="realised_pnl" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.realised_PNL')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.realised_PNL')}}</span>
                {{$t('margin.position_realised_pnl_tooltip')}}
              </div>
            </span>
            
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th></th>
        </template>
        <template slot="body" slot-scope="props">
          <tr v-if="props.item.current_qty != 0" :class="{'up': props.item.current_qty > 0, 'down': props.item.current_qty < 0, 'row-has-error': errors.has('price['+props.item.id+']'), 'border_tr': true}">
            <td><span class="symbol_th">{{ props.item.symbol}}</span></td>
            <td><span :class="{'up': props.item.current_qty > 0, 'down': props.item.current_qty < 0}">{{ props.item.current_qty }}</span></td>
            <td>{{ props.item.entry_value | formatMarginNumber(8) | formatMarginValue }} {{ $t('margin.symbol.btc') }}</td>
            <td>{{ props.item.entry_price | formatMarginPrice(OrderUtils.getTickSizeByPosition(props.item)) }}</td>
            <td>{{ getMarkPrice(props.item.symbol) | formatMarginPrice(OrderUtils.getTickSizeByPosition(props.item)) }}</td>
            <td><span class="down">{{ props.item.liquidation_price | formatMarginPrice(OrderUtils.getTickSizeByPosition(props.item)) }}</span></td>
            <td>{{ props.item.init_margin | formatMarginNumber(8) }} {{ $t('margin.symbol.btc') }} <i @click="showPositionMarginModal(props.item)" v-if="props.item.is_cross == 0" class="icon icon-edit"></i></td>
            <td><span :class="{'up': props.item.unrealised_pnl > 0, 'down': props.item.unrealised_pnl < 0}">{{ props.item.unrealised_pnl | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }} </span></td>
            <td @mouseover="isOnOverPNL = props.item.id" @mouseleave="isOnOverPNL = ''">
              <div class="box_custom_tooltip">
                <div style="max-width: 145px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" :class="{'up': props.item.realised_pnl > 0, 'down': props.item.realised_pnl < 0}">{{ props.item.id === isOnOverPNL  ? props.item.latest_realised_pnl : props.item.realised_pnl | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }} </div>
                <div class="tooltip_custom_c">
                  {{$t('margin.position_lastest_realised_tooltip')}}
                </div>
              </div>
            </td>
            <td>

              <div class="fl-right" v-if="!props.item.closed_id">
                <button class="btn btn-market" @click="confirmPositionsMargin(props.item, false)">{{ $t('margin.market') }}</button>
                <button :disabled="!isValidPrice(props.item)" class="btn btn-close" @click="confirmPositionsMargin(props.item, true)">{{ $t('margin.close') }}</button> 
                <marked-input :class="{'error': errors.has('price['+props.item.id+']'), 'input-price': true}" v-model="price[props.item.id]" :placeholder="$t('margin.price')" :decimal="decimal(props.item.symbol)" :restrict-step-price="true" :isMargin="true" :precision="precisionPrice(props.item.symbol)" /> 
                <div v-show="errors.has('price['+props.item.id+']')" class="invalid-feedback">{{ errors.first('price['+props.item.id+']') }}</div>
              </div>
              <div class="closed-item" v-else>
                <button class="icon-close btn-close-order" style="width: 30px; margin-left: 10px; margin-top: -4px;" @click="cancelClosingPosition(props.item)"></button>
                <div>{{$t('margin.close_position_at')}} {{props.item.closed_price | formatMarginPrice(OrderUtils.getTickSizeByPosition(props.item))}}</div>
              </div>

            </td>
          </tr>
        </template>
      </data-table2>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue';
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import BigNumber from 'bignumber.js';
  import MarkedInput from "@/components/marginexchange_pages/common/MarkedInput.vue";
  import PositionMarginModal from "../../../common/PositionMarginModal.vue";
  import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal.vue';
  import OrderUtils from './OrderUtils.js';
  import Utils from '@/common/Utils'
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      MarkedInput,
      ConfirmationModal,
      PositionMarginModal,
    },
    data() {
      return {
        price: [],
        quantity: {},
        activeRows: {},
        isRefreshing: false,
        positionList: [],
        OrderUtils,
        isOnOverPNL:false,
        params: {},
      }
    },
    props: {
    },
    computed: {
      ...mapGetters({ instrument: 'instrument', instrumentBySymbol: 'instrumentBySymbol', positions: 'mamPositions' }),
    },
    watch: {
      positions (newval, oldval) {
        this.$refs.datatable.refresh();
      },
    },
    methods: {
      ...mapActions(['getMamPositions', 'getMamPositionRequest']),
      precisionPrice(symbol) {
        const contract = this.instrumentBySymbol(symbol);
        const a = contract && contract.tick_size ? this.$options.filters.formatFloatNumber(contract.tick_size) : Const.MARGIN_DEFAULT_TICK_SIZE;
        const b = contract && contract.tick_size ? (typeof contract.tick_size == 'string' ? parseFloat(contract.tick_size) : contract.tick_size) : Const.MARGIN_DEFAULT_TICK_SIZE
        return (b > 1e-8) ? b : 0.00000001
      },
      decimal(symbol) {
        const contract = this.instrumentBySymbol(symbol);
        if(!contract) return
        const precision = Utils.getPrecisionNumber(contract.tick_size)
        return precision
      },
      getSocketEventHandlers() {
        return {
          MarginOrderUpdated: this.onMarginOrderUpdated,
          MarginPositionUpdated: this.onPositionUpdated,
        };
      },
      isValidPrice(item) {
        return typeof this.price[item.id] !== 'undefined' && Utils.formatMarginNumber(this.price[item.id]) !== '--'
      },
      onPercentClicked(item, percent) {
        Vue.set(this.quantity, item.id, new BigNumber(item.current_qty).mul(percent).div('100').absoluteValue().toString());
      },
      showPositionMarginModal(position) {
        if (position.is_cross == 1) {
          return;
        }
        this.$broadcast('selectedPosition', position);
        window.CommonModal.show('PositionMarginModal');
      },
      confirmPositionsMargin (item, isLimit) {
        let clasModel = item.current_qty > 0 ? 'sell' : 'buy';
        if (isLimit) {
          if (!this.validate(item, isLimit)) return;
          window.ConfirmationModal.show({
            class: 'margin_positions_model ' + clasModel,
            type: 'positionsMargin',
            head: this.$t('margin.order.succes.head_model_positions', { 
              quantity: window.i18n.t('margin.limit'),
            }),
            title: this.$t('margin.order.succes.close_positions', { 
              side: item.current_qty > 0 ? window.i18n.t('order.order_form.sell') : window.i18n.t('order.order_form.buy'),
              quantity: window.i18n.t('margin.limit'),
            }),
            content: this.$t('margin.order.succes.content_model_positions', {
              class: item.current_qty > 0 ? 'sell' : 'buy',
              side: item.current_qty > 0 ? window.i18n.t('order.order_form.sell') : window.i18n.t('order.order_form.buy'),
              quantity: Math.abs(item.current_qty),
              symbol: item.symbol,
              price: this.price[item.id],
            }),
            bottom: this.$t('margin.order.succes.bottom_model_positions_close'),
            btnCancelLabel: window.i18n.t('common.action.cancel'),
            btnConfirmLabel: window.i18n.t('common.action.limit_close_position'),
            onConfirm: () => {
              this.close(item, isLimit);
            }
          });
        } else {
          window.ConfirmationModal.show({
            class: 'margin_positions_model ' + clasModel,
            type: 'positionsMargin',
            head: this.$t('margin.order.succes.head_model_positions', { 
              quantity: window.i18n.t('margin.market'),
            }),
            title: this.$t('margin.order.succes.close_positions', { 
              side: item.current_qty > 0 ? window.i18n.t('order.order_form.sell') : window.i18n.t('order.order_form.buy'),
              quantity: window.i18n.t('margin.market'),
            }),
            content: this.$t('margin.order.succes.content_model_positions', {
              class: item.current_qty > 0 ? 'sell' : 'buy',
              side: item.current_qty > 0 ? window.i18n.t('order.order_form.sell') : window.i18n.t('order.order_form.buy'),
              quantity: Math.abs(item.current_qty),
              symbol: item.symbol,
              market: window.i18n.t('margin.market'),
            }),
            bottom: this.$t('margin.order.succes.bottom_model_positions_market'),
            btnCancelLabel: window.i18n.t('common.action.cancel'),
            btnConfirmLabel: window.i18n.t('common.action.market_close_position'),
            onConfirm: () => {
              this.close(item, isLimit);
            }
          });
        }
      },

      getMarkPrice(symbol) {
        const currentInstrument = _.find(this.instrument, function (item) {
          return item.symbol == symbol;
        })
        return currentInstrument && currentInstrument.mark_price ? currentInstrument.mark_price : 0;
      },

      onMarginOrderUpdated(data) {
        console.error('onMarginOrderUpdated', data)
        if(data && data.action == "canceled" && window.isAuthenticated){
          setTimeout(() => { 
            this.restartPosition() 
          }, 5000);
        }
        if(data && data.action == "matched" && window.isAuthenticated){
          setTimeout(() => { 
            this.restartPosition() 
          }, 5000);
        }
      },

      restartPosition(){
        this.getMamPositions(this.params);
        this.$refs.datatable.refresh();
      },

      onPositionUpdated(data) {
        if(window.isAuthenticated){
          this.$refs.datatable.refresh();
        }
      },

      getData (params) {
        params.limit = 100;
        if(!window.isAuthenticated) return;

        params = window._.merge(this.params, params);
        this.params = params;
        // console.log('Sort Params: ', this.params, params);

        let _this = this;
        // if (params.sort && params.sort_type) {
        //   if (params.sort == 'mark_price') {
            return new Promise(resolve => {
              let currentPositions = _.cloneDeep(this.positions);
              currentPositions = _.map(currentPositions, item => {
                item.mark_price = new BigNumber(_this.getMarkPrice(item.symbol)).toString();
                return item;
              });
              currentPositions = _.sortBy(currentPositions, [
                (item) => {
                  const itemValue = item[params.sort];
                  if (params.sort == 'symbol') return itemValue;
                  if (params.sort == 'entry_value') {
                    return new BigNumber(itemValue).abs().toNumber();
                  }
                  return parseFloat(itemValue);
                }
              ]);

              if (params.sort_type == 'desc') {
                currentPositions = currentPositions.reverse();
              }
              return resolve(currentPositions);
            });
        //   }
        // }

        // return this.getMamPositionRequest(params);
      },
      validate(item, isLimit) {
        this.errors.clear();
        if (isLimit && !this.price[item.id]) {
          this.errors.add('price['+item.id+']', window.i18n.t('common.error.required', { field: 'Price' }));
        }

        if (this.errors.count() > 0) {
          return false;
        }

        return true;
      },
      cancelClosingPosition (item) {
        // console.log('cancelClosingPosition', item)
        if(!item.closed_id) {
          Message.error('Cannot cancel order', {}, { position: 'bottom_left' });
          return
        }

        const params = {
          id: item.closed_id,
          close_position: 1,
          is_mam: 1,
        }

        rf.getRequest('MarginRequest').cancelActiveOrder(params).catch(error => {
          console.log(error);
          Message.error('Cannot cancel order', {}, { position: 'bottom_left' });
        }).finally(() => {
          this.$refs.datatable.refresh();
        });
      },
      close (item, isLimit) {
        if (!this.validate(item)) return;
          
        let data = {
          instrument_symbol: item.symbol,
          side: item.current_qty > 0 ? 'sell' : 'buy',
          type: isLimit ? 'limit' : 'market',
          quantity: Math.abs(item.current_qty), //this.quantity[item.id],
          stop_type: null,
          price: isLimit ? this.price[item.id] : null,
          time_in_force: 'gtc',
          is_reduce_only: 1,
          close_position: 1,
        }
          rf.getRequest('MarginRequest').createOrder({ ...data, is_mam: 1}).then(res => {
            this.closeSuccess(item, isLimit, res);
            this.$refs.datatable.refresh();
          }).catch(error => {
            this.closeError(error);
          });
      },
        closeSuccess(item, isLimit, res) {
          let message = '';
          let price = isLimit ? this.price[item.id] : window.i18n.t('margin.market');
          if (res && res.data) {
            message = window.i18n.t('margin.order.succes.limit_market', { 
              side: res.data.side === 'buy' ? window.i18n.t('margin.buy') : window.i18n.t('margin.sell'),
              symbol: res.data.instrument_symbol,
              price: isLimit ? res.data.price : window.i18n.t('margin.market'),
              quantity: res.data.quantity
            });
            Message.success(message, {}, { position: 'bottom_left', head: window.i18n.t('margin.order.submitted') });
          }
        },
      
        closeError(error) {
          let message;
          if (error && error.response && error.response.data) {
            message = error.response.data.message;
          } else {
            message = 'Error!!!'; //error.response.data.message
          }
          Message.error(message, {}, { position: 'bottom_left' });

          // const errors = error.response.data.message;
          // if (typeof errors === 'undefined') {
          //   let message = 'Error!!!'; //error.response.data.message
          //   Message.error(message, {}, { position: 'bottom_left' });
          //   return;
          // }

          // let err = [];
          // Object.keys(errors).forEach((key) => {
          //   err.push({
          //     field: key,
          //     msg: errors[key][0]
          //   });
          //   return;
          // });

        }
    },
    mounted() {
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  #table_positions {
    
    .box_edit_item {
      padding: 5px 20px;
      background: #222832;
      margin-top: -2px;

      .group_input_form {
        float: left;
        margin-right: 5px;
        margin-top: 5px;

        .input_right_group {
          margin: 0px
        }
      }
    }
    .closed-item{
      text-align: end;
      color: $color-gray-dark;
      font-size: 12px;
      font-family: "Roboto-Medium", sans-serif;
    }

    .btn-close-order {
      background-color: #00000000;
      display: block;
      width: 30px;
      height: 30px;
      position: relative;
      top: -2px;
      float: right;
      line-height: 32px;

      &.active {
        background-color: #222832;
      }
      &:hover {
        background-color: #222832;
      }
    }

    .icon-edit {
      color: $color-jungle-green;
      cursor: pointer;
    }
    .btn-close {
      width: 50px;
      height: 25px;
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
      letter-spacing: 0.8px;
      text-align: center;
      color: $color-jungle-green;
      font-size: $font-mini-mini;
      background-color: transparent;
      padding: 5px 2px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      float: right;
      &:hover {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
        color: $color-white;
      }
    }
    .btn-market {
      margin-left: 5px;
      width: 55px;
      height: 25px;
      border-radius: 3px;
      border: solid 1px $color-denim;
      letter-spacing: 0.8px;
      text-align: center;
      color: $color-denim;
      font-size: $font-mini-mini;
      background-color: transparent;
      padding: 5px 2px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      float: right;
      &:hover {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
        color: $color-white;
      }
    }
    .invalid-feedback{
      clear: both;
      text-align: right;
    }
  }

  .box_custom_tooltip {
    position: relative;
    .tooltip_custom_c {
      visibility: hidden;
      display: none;
      width: 250px;
      background-color: #222832;
      color: #cccccc;
      text-align: center;
      padding: 5px 0;
      border-radius: 4px;
      font-size: 13px;
      -webkit-box-shadow: 0px 0px 3px #cccccc;
      box-shadow: 0px 0px 3px #cccccc;
      position: absolute;
      z-index: 50;
      left: 100%;
      top: 100%;
      line-height: 18px;
      white-space: initial;
      &.left {
        left: auto;
        right: 100%;
      }
      .underline {
        text-decoration: underline;
        display: block;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
    &:hover {
      cursor: help;
      .tooltip_custom_c {
        display: block;
        visibility: visible;
        color: #cccccc;
      }
    }
  }
</style>

<style lang="scss">
  @import '@/assets/sass/variables';
   #table_positions {
    .input-price {
      float: right;
      margin-right: 5px;
      .input-coin-number {
        line-height: 16px;
        padding: 2px 5px;
        width: 80px;
        height: 25px;
        border: 1px solid $color-charade;
        border-radius: 3px;
        color: $color-grey-sliver;
        background-color: $color-charade;
        font-size: $font-smaller;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $color-gray-dark;
          opacity: 1; /* Firefox */
          font-size: $font-smaller;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $color-gray-dark;
          font-size: $font-smaller;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $color-gray-dark;
          font-size: $font-smaller;
        }
        &:focus {
          border-color: $color-jungle-green;
        }
      }
    }
    .table-positions {
      height: 238px;
      overflow-y: auto;
      overflow-x: auto;
      &::-webkit-scrollbar-track
      {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 0px;
        background-color: #555555;
      }

      &::-webkit-scrollbar
      {
        width: 5px;
        background-color: #555555;
      }

      &::-webkit-scrollbar-thumb
      {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: $color-grey;
      }
      .VuePagination {
        background-color: transparent;
        .page-item {
          a {
            color: $color-gray-dark;
            &:hover {
              color: $color-jungle-green;
            }
          }
          &.active {
            a {
              color: $color-jungle-green;
            }
          }
        }
      }
      table {
        overflow-x: initial;
        overflow-y: initial;
        display: block;
        width: 100%;
        min-width: 1200px;
        thead {
          display: block;
          width: 100%;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          position: sticky;
          top: 0px;
          background: #191f2a;
          z-index: 5;
          overflow: inherit;
          tr {
            display: block;
            width: 100%;
            float: left;
            th {
              display: block;
              float: left;
              height: 48px;
              padding: 14px 0px 14px 16px;
              text-align: left;
              vertical-align: middle;
              font-size: $font-smaller;
              font-family: $font-family-roboto;
              color: $color-grey-dusty;
              &:last-child {
                float: right;
                padding: 14px 16px 14px 8px;
              }
            }
          }
        }
        tbody {
          display: block;
          width: 100%;
          tr {
            height: 36px;
            display: block;
            position: relative;
            height: 36px;
            margin-bottom: 2px;
            td {
              overflow: initial;
              display: block;
              float: left;
              padding: 8px 0px 8px 16px;
              line-height: 20px;
              text-align: left;
              vertical-align: middle;
              color: $color-gray-dark;
              font-size: $font-smaller;
              font-family: $font-family-roboto-medium;
              &:last-child {
                float: right;
                padding: 8px 16px 8px 8px;
              }
              .symbol_th {
                font-size: 13px;
                color: $color-blue-custom;
              }
              .up {
                color: $color-jungle-green;
              }
              .down {
                color: $color-denim;
              }
            }
            &.empty-data {
              height: auto;
              text-align: center;
              &:hover {
                background-color: transparent !important;
              }
              td {
                margin: auto;
                float: none;
                width: auto;
              }
            }
          }
        }
      }
    }
    table {
      thead {
        background-color: transparent;
        overflow: inherit;
        th:first-child{
          width: 6%;
        }
        th:nth-child(2){
          width: 5%;
        }
        th:nth-child(3){
          width: 10%;
        }
        th:nth-child(4){
          width: 7%;
        }
        th:nth-child(5){
          width: 7%;
        }
        th:nth-child(6) {
          width: 9%;
        }
        th:nth-child(7) {
          width: 11%;
        }
        th:nth-child(8) {
          width: 11%;
        }
        th:nth-child(9) {
          width: 11%;
          max-width: 150px;
        }
        th:nth-child(10) {
          width: 9%;
          min-width: 220px;
        }
      }
      tbody {
        td:first-child{
          width: 6%;
        }
        td:nth-child(2){
          width: 5%;
        }
        td:nth-child(3){
          width: 10%;
        }
        td:nth-child(4){
          width: 7%;
        }
        td:nth-child(5) {
          width: 7%;
        }
        td:nth-child(6) {
          width: 9%;
        }
        td:nth-child(7) {
          width: 11%;
        }
        td:nth-child(8) {
          width: 11%;
        }
        th:nth-child(9) {
          width: 11%;
        }
        td:nth-child(10) {
          width: 9%;
          min-width: 220px;
        }
      }
    }

    @media (min-width: 768px) {
      table {
        thead {
          tr {
            th {
              padding: 14px 0px 14px 8px !important;
            }
          }
        }
        tbody {
          tr{
            td{
              padding: 8px 0px 8px 8px !important;
            }
          }
        }

      }
    }

    .border_tr {
      float: left;
      width: 100%;
      overflow: initial;
      position: relative;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      &.up {
        border-left: 5px solid $color-jungle-green;
        border-right: 5px solid $color-jungle-green;
      }
      &.down {
        border-left: 5px solid $color-denim;
        border-right: 5px solid $color-denim;
      }
      &.row-has-error{
        height: 50px !important;
      }
    }
  }

  #table_positions {
    .margin_positions_model {
      &.buy {
        .tit_modal_positions_argin {
          color: $color-jungle-green;
        }
        .buy {
          color: $color-jungle-green;
        }
      }
      &.sell {
        .tit_modal_positions_argin {
          color: $color-denim;
        }
        .sell {
          color: $color-denim;
        }
      }
      .modal-dialog {
        .modal-content {
          .modal-body {
            .content_text {
              min-height: auto !important;
            }
          }
          .tit_modal_positions_argin {
            font-family: $font-family-roboto-bold;
          }
        }
      }
    }
  }

  .input_right_group {
    margin-bottom: 12px;
    width: 100%;
    .check_percent {
      display: inline-block;
      margin-top: 0px;
      width: calc((100% / 4) - 4.5px);
      margin-right: 6px;
      border: 1px solid $color-charade;
      background-color: transparent;
      line-height: 23px;
      text-align: center;
      padding: 1px 5px;
      color: $color-grey;
      font-size: $font-smaller;
      float: left;
      cursor: pointer;
      border-radius: 3px;
      height: 25px;
      font-weight: 500;
      &.last_item {
        margin-right: 0px;
      }
      &:hover {
        background: $color-green;
        border-color: $color-green;
        color: $color-white;
      }
    }
  }
</style>