<template>
  <div >
    <transition name="modal"  v-if="isUnregisteredSession" role="dialog" >
      <div class="modal-mask"> 
        <div class="modal-dialog" role="document">
          <div class="modal-container">
            <div class="modal-body">
              <div name="body">
                <img src="/images/icon/icon-devices.svg" alt="icon" class="icon">
                <p class="content">
                  {{$t('unregistered_session.text')}} 
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <div name="footer" class="text-center btn-control">
                <button @click="login()" class="btn-login">{{$t('unregistered_session.login')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Cookies from 'js-cookie';
  export default {
    data() {
      return {
        isUnregisteredSession: false,
      }
    },
    computed: {
      ...mapState({
        dark: (state) => state.userThemeMode === 'light-theme'? '': '-dark',
      }),
    },
    methods: {
      login() {
        Cookies.remove('access_token', { domain: process.env.VUE_APP_PUBLIC_URL })
        window.location.href = "/login"
      }
    },
    created () {
      this.$on('UserSessionRegistered', (data) => {
        this.isUnregisteredSession = true;
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  .modal{
   overflow: hidden;
  }
  .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: block;
      transition: opacity .3s ease;
      overflow: hidden;
    }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    position: fixed;
    top: 23%;
    left: 37%;
  }

  .modal-dialog {
    z-index: 10000;
      width: 590px;
      max-width: 80%;
      max-height: 80%;
      vertical-align : middle;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
  }

  .modal-container {
    width: 471px;
    margin: 0px auto;
    padding: 50px;
    background-color: var(--bg-body);
    border-radius: 8px;
    transition: all .3s ease;
    .modal-body {
      padding: 0;
      .icon {
        display: block;
        margin: auto;
      }
      .content {
        text-align: center;
      }
    }
  }
  .content {
    margin-top: 24.5px;
    margin-bottom: 25px;
    font-family: $font-inter-bold;
    color: var(--color-pagi);
    font-size: 20px;
    line-height: 24px;
  }
  .modal-header{
    border-bottom: none !important;
  }

  .modal-footer{
    border-top: none !important;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .btn-login {
    @include btn-common(yes);
    padding: 15.5px 10px;
    display: block;
    width: 100%;
    //background-color: var(--color-text);
    border-radius: 20px;

    &:hover, &:active, &:focus {
      
    }
  }
  @media screen and (max-width: 992px) {
    .modal-container {
      width: 100%;
      padding: 30px 15px;
      .modal-body {
        .icon {
          width: 80px;
          height: 80px;
        }
      }
    }
    .content {
      font-size: 16px;
      line-height: 19px;
    }
    .modal-footer {
      padding: 0;
    }
  }
  @media screen and (max-width: 768px) {}
  @media screen and (max-width: 576px) {}
</style>
