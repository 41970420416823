<template>
  <div id="order_form" class="page_container clearfix">
    <!--    <div class="spot-title">{{ $t('landing.title_Spot') }}</div>-->
    <!--    <div class="order-type">-->
    <!--      <div class="dropdown style-focus" tabindex="12">-->
    <!--        <button class="btn form-control btn-order-form" type="button" data-toggle="dropdown">-->
    <!--          <span class="left order-type-selected" v-if="tab == 'limit'">{{ $t('order.order_form.limit') }}</span>-->
    <!--          <span class="left order-type-selected" v-if="tab == 'market'">{{ $t('orders.open_order.market') }}</span>-->
    <!--          <span class="left order-type-selected" v-if="tab == 'stop_limit'">{{ $t('order.order_form.stop_limit') }}</span>-->
    <!--          <span class="left order-type-selected" v-if="tab == 'stop_market'">{{ $t('order.order_form.stop_market') }}</span>-->
    <!--          <span class="icon icon-arrow1"></span>-->
    <!--        </button>-->
    <!--        <ul class="dropdown-menu">-->
    <!--          <div v-on:click="tab='limit'" class="tab-element" v-bind:class="{'active': tab === 'limit'}">-->
    <!--            <span class="text-size">{{ $t('order.order_form.limit') }}</span>-->
    <!--          </div>-->
    <!--          <div v-on:click="tab='market'" class="tab-element" v-bind:class="{'active': tab === 'market'}">-->
    <!--            <span class="text-size">{{ $t('orders.open_order.market') }}</span>-->
    <!--          </div>-->
    <!--          <div v-on:click="tab='stop_limit'" class="tab-element" v-bind:class="{'active': tab === 'stop_limit'}">-->
    <!--            <span class="text-size">{{ $t('order.order_form.stop_limit') }}</span>-->
    <!--            &lt;!&ndash; <span class="question-sign" @click="showTooltipLimit()" v-click-outside="hideTooltipLimit"></span> &ndash;&gt;-->
    <!--            <div v-if="isShowTooltipLimit" class="tooltip-order">-->
    <!--              <p class="text-tooltip">{{ $t('order.order_form.stop_limit_tooltip') }}</p>-->
    <!--              <a :href="$siteSupportArticle($i18n.locale, 'how-to-use-stop-limit-function')" target="_blank"> {{ $t('order.order_form.view_more') }}</a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div v-on:click="tab='stop_market'" class="tab-element" v-bind:class="{'active': tab === 'stop_market'}">-->
    <!--            <span class="text-size">{{ $t('order.order_form.stop_market') }}</span>-->
    <!--            &lt;!&ndash; <span class="question-sign" @click="showTooltipMarket()" v-click-outside="hideTooltipMarket"></span> &ndash;&gt;-->
    <!--            <div v-if="isShowTooltipMarket" class="tooltip-order">-->
    <!--              <p class="text-tooltip"> {{ $t('order.order_form.stop_market_tooltip') }}</p>-->
    <!--              <a :href="$siteSupportArticle($i18n.locale,'how-to-use-stop-limit-function')" target="_blank">{{ $t('order.order_form.view_more') }}</a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </ul>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="d-flex justify-content-center group_button">
      <button class="custom-button custom-button_buy" :class="action !== 'BUY' && 'custom-button_inactive'" @click="action='BUY' ">
        <span class="button-text">{{ $t("common.trade_type.buy") }}</span>
      </button>
      <button class="custom-button custom-button_sell" :class="action !== 'SELL' && 'custom-button_inactive'"  @click="action='SELL'">
        <span class="button-text">{{ $t("common.trade_type.sell") }}</span>
      </button>
    </div>

    <div class="tab_content tab_responsive">
      <order-form-tabs
        :tab="tab"
        v-on:tabChange="handleTabChange"
        v-show="action==='BUY'"
      ></order-form-tabs>
      <order-form-tabs
        :showFormOrder="0"
       
        :tab="tab"
        v-on:tabChange="handleTabChange"
         v-show="action==='SELL'"
      ></order-form-tabs>
    </div>
    <div class="tab_content tab_responsive2">
      <order-form-tabs
        :tab="tab"
        v-on:tabChange="handleTabChange"
        :showFormOrder="triggerOrderFormTab"
      ></order-form-tabs>
    </div>
  </div>
</template>
<script>
import OrderFormTabs from "./OrderFormTabs.vue";

export default {
  components: {
    OrderFormTabs,
  },
  props: {
    triggerOrderFormTab: { type: Number, default: 1 },
  },
  data() {
    return {
      tab: "limit",
      isShowTooltipLimit: false,
      show_select: false,
      isShowTooltipMarket: false,
      isMobileScreen: window.matchMedia("(max-width: 1250px)").matches,
      action : "BUY"
    };
  },
  methods: {
    handleWindowResize() {
      this.checkMobileScreen();
    },
    checkMobileScreen() {
      const isMobile = window.matchMedia("(max-width: 1500px)").matches;
      this.isMobileScreen = isMobile;
    },
    handleTabChange(event) {
      this.tab = event;
    },
    getOrderTypeName() {
      const result = window._.find(
        this.getOrderTypes(),
        (item) => item.name === this.orderType
      );
      return result ? result.name : "Limit";
    },
    getOrderTypes() {
      return [
        { name: "Limit" },
        { name: "Market" },
        { name: "Stop Limit" },
        { name: "Stop Market" },
      ];
    },
    showTooltipLimit() {
      this.hideTooltipMarket();
      this.isShowTooltipLimit = !this.isShowTooltipLimit;
    },
    hideTooltipLimit() {
      this.isShowTooltipLimit = false;
    },

    showTooltipMarket() {
      this.hideTooltipLimit();
      this.isShowTooltipMarket = !this.isShowTooltipMarket;
    },
    hideTooltipMarket() {
      this.isShowTooltipMarket = false;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

.dark-theme {
  #order_form {
    .spot-title {
      color: $color-white;
    }
  }
}

#order_form {
  display: block;
  width: 100%;

  .spot-title {
    font-weight: 600;
    font-size: $font-medium-bigger;
    font-family: $font-inter-bold;
    line-height: 19px;
    color: $color-grey-dark;
    margin-bottom: 1rem;
  }

  .order-type {
    position: relative;
    margin-bottom: 30px;
    .btn-order-form {
      height: 40px;
      border-radius: 3px;
      border: solid 1px $color-bright-gray-dark;
      color: $color-grey;
      font-size: $font-small;
      line-height: 20px;
      padding: 9px 13px 9px 20px;
      background-color: #181f2b;
      text-align: left;
      width: 100%;
      overflow: hidden;
      .icon {
        float: right;
        font-size: $font-mini;
        line-height: 22px;
        display: inline-block;
        position: absolute;
        top: 9px;
        right: 9px;
      }
    }
    .dropdown-menu {
      width: 100%;
      border-radius: 3px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
      background-color: $color-white;
      padding: 10px 0px;
      .tab-element {
        cursor: pointer;
        display: block;
        width: 100%;
        line-height: 20px;
        padding: 5px 20px;
        font-size: $font-root;
        color: $color-grey-dark;
        &:hover {
          color: $color-jungle-green-light;
        }
      }
    }
  }
}
.group_button {
  margin-bottom: 24px;
}
.custom-button {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 10px 40px;
  border: none;

  position: relative;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  cursor: pointer;
  width: 190px;
  &:hover {
    opacity: 0.8;
  }
  &_sell {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #e02d3c;

    clip-path: polygon(
      100% 85.263%,
      100% 85.263%,
      100% 85.682%,
      100% 86.06%,
      100% 86.402%,
      99.999% 86.71%,
      99.999% 86.99%,
      99.997% 87.244%,
      99.996% 87.477%,
      99.994% 87.691%,
      99.991% 87.891%,
      99.988% 88.08%,
      99.988% 88.08%,
      99.926% 89.924%,
      99.815% 91.671%,
      99.658% 93.303%,
      99.458% 94.801%,
      99.221% 96.146%,
      98.949% 97.321%,
      98.646% 98.306%,
      98.317% 99.083%,
      97.964% 99.635%,
      97.591% 99.942%,
      97.591% 99.942%,
      97.553% 99.957%,
      97.512% 99.97%,
      97.469% 99.98%,
      97.422% 99.987%,
      97.37% 99.993%,
      97.314% 99.996%,
      97.251% 99.998%,
      97.182% 100%,
      97.106% 100%,
      97.021% 100%,
      3.75% 100%,
      3.75% 100%,
      3.597% 100%,
      3.459% 99.999%,
      3.334% 99.997%,
      3.223% 99.993%,
      3.124% 99.986%,
      3.035% 99.975%,
      2.957% 99.961%,
      2.887% 99.941%,
      2.825% 99.916%,
      2.77% 99.885%,
      2.77% 99.885%,
      2.24% 99.266%,
      1.767% 98.183%,
      1.356% 96.699%,
      1.014% 94.88%,
      0.748% 92.79%,
      0.565% 90.493%,
      0.47% 88.054%,
      0.471% 85.538%,
      0.575% 83.007%,
      0.787% 80.528%,
      0.787% 80.528%,
      0.815% 80.289%,
      0.847% 80.027%,
      0.885% 79.737%,
      0.929% 79.415%,
      0.98% 79.056%,
      1.038% 78.656%,
      1.103% 78.209%,
      1.176% 77.713%,
      1.257% 77.161%,
      1.348% 76.549%,
      11.8% 6.023%,
      11.8% 6.023%,
      11.827% 5.843%,
      11.851% 5.681%,
      11.873% 5.534%,
      11.893% 5.4%,
      11.911% 5.279%,
      11.928% 5.168%,
      11.944% 5.066%,
      11.959% 4.97%,
      11.974% 4.88%,
      11.988% 4.793%,
      11.988% 4.793%,
      12.136% 3.976%,
      12.296% 3.228%,
      12.467% 2.551%,
      12.648% 1.948%,
      12.839% 1.421%,
      13.037% 0.973%,
      13.242% 0.607%,
      13.454% 0.324%,
      13.67% 0.128%,
      13.891% 0.021%,
      13.891% 0.021%,
      13.914% 0.015%,
      13.937% 0.011%,
      13.962% 0.007%,
      13.988% 0.005%,
      14.016% 0.003%,
      14.046% 0.001%,
      14.08% 0.001%,
      14.117% 0%,
      14.158% 0%,
      14.203% 0%,
      97.021% 0%,
      97.021% 0%,
      97.106% 0%,
      97.182% 0%,
      97.251% 0.002%,
      97.314% 0.004%,
      97.37% 0.007%,
      97.422% 0.013%,
      97.469% 0.02%,
      97.512% 0.03%,
      97.553% 0.043%,
      97.591% 0.058%,
      97.591% 0.058%,
      97.964% 0.365%,
      98.317% 0.917%,
      98.646% 1.694%,
      98.949% 2.679%,
      99.221% 3.854%,
      99.458% 5.199%,
      99.658% 6.697%,
      99.815% 8.328%,
      99.926% 10.076%,
      99.988% 11.92%,
      99.988% 11.92%,
      99.991% 12.109%,
      99.994% 12.309%,
      99.996% 12.523%,
      99.997% 12.756%,
      99.999% 13.01%,
      99.999% 13.29%,
      100% 13.598%,
      100% 13.94%,
      100% 14.318%,
      100% 14.737%,
      100% 85.263%
    );
  }

  &_buy {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    color: white;
    background-color: #01bc8d;
    clip-path: polygon(
      0% 14.737%,
      0% 14.737%,
      0% 14.318%,
      0% 13.94%,
      0% 13.598%,
      0.001% 13.29%,
      0.001% 13.01%,
      0.003% 12.756%,
      0.004% 12.523%,
      0.006% 12.309%,
      0.009% 12.109%,
      0.012% 11.92%,
      0.012% 11.92%,
      0.074% 10.076%,
      0.185% 8.328%,
      0.342% 6.697%,
      0.542% 5.199%,
      0.779% 3.854%,
      1.051% 2.679%,
      1.354% 1.694%,
      1.683% 0.917%,
      2.037% 0.365%,
      2.409% 0.058%,
      2.409% 0.058%,
      2.448% 0.043%,
      2.488% 0.03%,
      2.531% 0.02%,
      2.578% 0.013%,
      2.63% 0.007%,
      2.686% 0.004%,
      2.749% 0.002%,
      2.818% 0%,
      2.894% 0%,
      2.979% 0%,
      96.25% 0%,
      96.25% 0%,
      96.403% 0%,
      96.542% 0.001%,
      96.666% 0.003%,
      96.777% 0.007%,
      96.876% 0.014%,
      96.965% 0.025%,
      97.043% 0.039%,
      97.113% 0.059%,
      97.175% 0.084%,
      97.23% 0.115%,
      97.23% 0.115%,
      97.76% 0.734%,
      98.233% 1.817%,
      98.644% 3.301%,
      98.986% 5.12%,
      99.252% 7.21%,
      99.435% 9.507%,
      99.53% 11.946%,
      99.529% 14.462%,
      99.425% 16.993%,
      99.213% 19.472%,
      99.213% 19.472%,
      99.186% 19.711%,
      99.153% 19.973%,
      99.115% 20.263%,
      99.071% 20.585%,
      99.02% 20.944%,
      98.962% 21.344%,
      98.897% 21.791%,
      98.824% 22.287%,
      98.743% 22.839%,
      98.652% 23.451%,
      88.199% 93.977%,
      88.199% 93.977%,
      88.173% 94.157%,
      88.149% 94.319%,
      88.127% 94.466%,
      88.107% 94.6%,
      88.089% 94.721%,
      88.072% 94.832%,
      88.056% 94.934%,
      88.041% 95.03%,
      88.026% 95.12%,
      88.012% 95.207%,
      88.012% 95.207%,
      87.864% 96.024%,
      87.704% 96.772%,
      87.533% 97.449%,
      87.352% 98.052%,
      87.161% 98.579%,
      86.963% 99.027%,
      86.758% 99.393%,
      86.546% 99.676%,
      86.33% 99.872%,
      86.109% 99.979%,
      86.109% 99.979%,
      86.086% 99.985%,
      86.063% 99.989%,
      86.038% 99.993%,
      86.012% 99.996%,
      85.984% 99.997%,
      85.954% 99.999%,
      85.92% 99.999%,
      85.883% 100%,
      85.842% 100%,
      85.797% 100%,
      2.979% 100%,
      2.979% 100%,
      2.894% 100%,
      2.818% 100%,
      2.749% 99.998%,
      2.686% 99.996%,
      2.63% 99.993%,
      2.578% 99.987%,
      2.531% 99.98%,
      2.488% 99.97%,
      2.448% 99.957%,
      2.409% 99.942%,
      2.409% 99.942%,
      2.037% 99.635%,
      1.683% 99.083%,
      1.354% 98.306%,
      1.051% 97.321%,
      0.779% 96.146%,
      0.542% 94.801%,
      0.342% 93.303%,
      0.185% 91.671%,
      0.074% 89.924%,
      0.012% 88.08%,
      0.012% 88.08%,
      0.009% 87.891%,
      0.006% 87.691%,
      0.004% 87.477%,
      0.003% 87.244%,
      0.001% 86.99%,
      0.001% 86.71%,
      0% 86.402%,
      0% 86.06%,
      0% 85.682%,
      0% 85.263%,
      0% 14.737%
    );
  }
  &_inactive {
    background-color: #F4F5F7;
    color : $color-grey-black;
  }
}

.style-focus {
  outline-width: 1px;
}

.tab_responsive {
  display: block;
}
.tab_responsive2 {
  display: none;
}
@media screen and (max-width: 1250px) {
  .tab_responsive {
    display: none !important;
  }
  .tab_responsive2 {
    display: flex !important;
  }
}
@media screen and (max-width: 992px) {
  .dark-theme {
    #order_form {
      border-bottom-color: $color-cinder;
    }
  }

  #order_form {
    padding: 0 20px 18px 20px;
    margin-top: 15px;
    border-bottom: 3px solid $color-alabaster;
  }
}
</style>
