import { render, staticRenderFns } from "./FiatCurrency.vue?vue&type=template&id=22e05460&scoped=true&"
import script from "./FiatCurrency.vue?vue&type=script&lang=js&"
export * from "./FiatCurrency.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e05460",
  null
  
)

export default component.exports