<template>
  <div class="container-stats-sec">
    <div class="crypto-stats-section">
      <div class="stats-header">
        <h1 class="title-crypto-stats">
          {{ title }}
        </h1>
        <p class="sub-crypto-stats" :style="{marginTop: '8px'}">{{ subtitle }}</p>
      </div>
      <div class="states-container-mobile" v-if="sizeScreen < 610">
        <div class="stats-container-item">
          <div class="stats-sub-container">
            <h2 class="title-crypto-stats text-left">{{ stats[0].value }}</h2>
            <p class="sub-crypto-stats text-left">{{ stats[0].label }}</p>
          </div>
          <div>
            <h2 class="title-crypto-stats text-left">{{ stats[1].value }}</h2>
            <p class="sub-crypto-stats text-left">{{ stats[1].label }}</p>
          </div>
        </div>
        <div class="stats-container-item">
          <div>
            <h2 class="title-crypto-stats text-left">{{ stats[2].value }}</h2>
            <p class="sub-crypto-stats text-left">{{ stats[2].label }}</p>
          </div>
          <div>
            <h2 class="title-crypto-stats text-left">{{ stats[3].value }}</h2>
            <p class="sub-crypto-stats text-left">{{ stats[3].label }}</p>
          </div>
        </div>
      </div>
      <div class="stats-container" v-if="sizeScreen > 610">
        <div v-for="(stat, index) in stats" :key="index" class="stat-box">
          <h2 class="title-crypto-stats">{{ stat.value }}</h2>
          <p class="sub-crypto-stats">{{ stat.label }}</p>
        </div>
      </div>

        <div class="cta-button">
          <button  v-if="!isAuthenticated">
            <router-link
                :to="{ name: 'Register', query: { destination: $route.name } }"
            >{{ $t(buttonText) }}
            </router-link>
          </button>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Start your crypto journey now!",
    },
    subtitle: {
      type: String,
      default: "Trade on Dikex. Trade smarter",
    },
    stats: {
      type: Array,
      default: () => [
        { value: "$15,676,289,662", label: "24h trading volume" },
        { value: "800+", label: "Cryptocurrencies" },
        { value: "30 million", label: "Registered users" },
        { value: "180,000+", label: "Elite traders" },
      ],
    },
    buttonText: {
      type: String,
      default: "landing.signup",
    },
    isAuthenticated: {
      type: Boolean,
    },
  },
  data() {
    return {
      sizeScreen: window.innerWidth,
    };
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>

<style lang="scss" scoped>
.title-crypto-stats {
  color: var(--text-primary-color);
  font-family: $font-notosans-bold;
  @media screen and (max-width: 610px) {
    font-size: 20px !important;
    line-height: 28px;
  }
}

.dark-theme {
  .sub-crypto-stats {
    color: #f9fafb !important;
  }
}

.sub-crypto-stats {
  color: var(--text-second-color);
  @media screen and (max-width: 610px) {
    font-size: 12px !important;
    line-height: 16px;
  }
}
.container-stats-sec {
  background-color: var(--bg-our-item);
}
.crypto-stats-section {
  text-align: center;
  padding: 100px 24px;
  color: white;
  max-width: 1284px;
  margin: 0 auto;
  @media screen and (max-width: 500px) {
    padding: 40px 24px;
  }
}

.stats-header h1 {
  font-size: 48px;
  font-weight: 700;
}

.stats-header p {
  font-size: 20px;
  color: #8e8e92;
  font-weight: 400;
  margin-top: 10px;
  @media screen and (max-width: 500px) {
    margin-top: unset;
  }
}

.stats-container {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  flex-wrap: wrap;
}

.stat-box {
  margin-bottom: 20px;
}

@media screen and (min-width: 611px) {
  .stat-box {
    width: auto;
  }
}

.stat-box h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}

.stat-box p {
  font-size: 16px;
  color: #8e8e92;
  font-weight: 400;
}

.cta-button {
  margin-top: 30px;
  width: fit-content;
  min-width: 200px;
  margin-left: auto;
  margin-right: auto;
  a{
    color: var(--background-color-primary);
  }
}

.cta-button button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  cursor: pointer;
  border-radius: 40px;
  background: var(--text-primary-color);
  color: var(--background-color-primary);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  height: 36px;
  font-size: 14px;
  font-family: $font-notosans-medium;

  @media screen and (max-width: 610px) {
    font-size: 12px !important;
    line-height: 16px;
    max-width: fit-content;
    padding: 10px 24px;
  }
}

.cta-button button:hover {
  background-color: var(--text-primary-color);
}

.states-container-mobile {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  .stats-container-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
</style>
