 import rf from '@/request/RequestFactory';

const actions = {
  getFunding({ commit }) {
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getFunding().
        then(res => {
          commit('updateFunding', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  getFundingRequest({}, params) {
    return rf.getRequest('MarginRequest').getFunding(params);
  }
}

const getters = {
  funding: state => {
    return state.funding
  }
}

const mutations = {
  updateFunding: (state, payload) => {
    state.funding = { ...payload }
  }
}

export default {
  actions,
  getters,
  mutations,
  state: {
    'funding': {}
  },
}