<template>
  <div id="instruments">
     
    <div class="table-container table-positions">
      <data-table3 ref="datatable" :getData="getData" :limit="5">
        <template slot-scope="props">
          <th data-sort-field="symbol" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.positions_symbol')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.positions_symbol')}}</span>
                {{$t('margin.position_symbol_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="mark_price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.mark_price')}}
              <div class="tooltip_custom_c">
                <span class="underline">  {{$t('margin.mark_price')}}</span>
                {{$t('margin.instrument_mark_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="last_price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.last_price')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.last_price')}}</span>
                {{$t('margin.last_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="bid_price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.bid_price')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.bid_price')}}</span>
                {{$t('margin.bid_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="ask_price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.ask_price')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.ask_price')}}</span>
                {{$t('margin.ask_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="open_value" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.open_value')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.open_value')}}</span>
                {{$t('margin.open_value_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="width-10">
            <span class="box_custom_tooltip">
              {{$t('margin.contract_value')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.contract_value')}}</span>
                {{$t('margin.contract_value_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="last-column width-last padding-right-0" data-sort-field="expiry" @click="props.echoclick">
            <span class="fl-right box_custom_tooltip">
              {{$t('margin.expiry_date')}}
              <div class="tooltip_custom_c left">
                <span class="underline">{{$t('margin.expiry_date')}}</span>
                {{$t('margin.expiry_date_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr class="border_tr">
            <td><span class="symbol_th">{{ props.item.symbol}}</span></td>
            <td><span class="">{{ props.item.mark_price  | formatMarginPrice(props.item.tick_size)}}</span></td>
            <td>{{ props.item.last_price | formatMarginPrice(props.item.tick_size)}}</td>
            <td>{{ props.item.bid_price  | formatMarginPrice(props.item.tick_size)}}</td>
            <td>{{ props.item.ask_price  | formatMarginPrice(props.item.tick_size)}}</td>
            <td><span class="">{{ props.item.open_value | formatMarginNumber(8) }}</span></td>
            <td>{{ props.item.contract_value }}</td>
            <td class="last-column"><span>{{ props.item.expiry | convertToLocalTime }}</span></td>
          </tr>
        </template>
      </data-table3>
    </div>

  </div>
</template>
<script>
  import BigNumber from 'bignumber.js';
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import moment from 'moment';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    components: {

    },
    data() {
      return {
        
      }
    },
    props: {

    },
    watch: {
      instruments(newValue) {
        this.$refs.datatable.fetch();
      },
    },
    computed: {
      ...mapGetters({
        instruments: 'instrument',
      }),
    },
    methods: {
      getData(params) {
        return new Promise((resolve, reject) => {
          let instruments = [].concat(this.instruments);
          if (params.sort && params.sort_type) {
            const sort = params.sort;
            // console.log(sort);
            instruments.sort((a, b) => {
              const sign = params.sort_type === 'asc' ? 1 : -1;
              if (sort === 'expiry') {
                const value1 = a[sort] || '';
                const value2 = b[sort] || '';
                return sign * (value1 < value2 ? -1 : value1 === value2 ? 0 : 1);
              } else if (sort === 'symbol') {
                const value1 = a[sort] || '';
                const value2 = b[sort] || '';
                return sign * (value1 < value2 ? -1 : 1);
              } else if (sort === 'ask_price') {
                const value1 = a[sort] || '';
                const value2 = b[sort] || '';
                return sign * (value1 < value2 ? -1 : 1);
              } else {
                const value1 = parseFloat(a[sort]).toFixed(8) || 0;
                const value2 = parseFloat(b[sort]).toFixed(8) || 0;
                return sign * (new BigNumber(value1).comparedTo(value2));
              }
            });
          }
          resolve(instruments);
        });
      },
    },
    mounted() {
      
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .fl-right {
    padding: 0px 15px 0px 4px;
  }
  #instruments {
    // padding-bottom: 12px;
    table {
      thead {
        th {
          padding: 10px 0px 9px 20px;
          font-size: $font-smaller;
          height: 40px;
          font-family: $font-family-roboto;
          vertical-align: middle;
          color: $color-grey-dusty;
          &:last-child {
            padding-right: 20px;
            padding-left: 8px;
            &::after {
                float: right;
            }
          }
        }
        th[data-sort-order="asc"]:after {
          padding-top: 2px;
          font-size: $font-smaller;
        }
        th[data-sort-order="desc"]:after {
          padding-top: 2px;
          font-size: $font-smaller;
        }
      }
      tbody {
        tr {
          td {
            color: $color-gray-dark;
            font-size: $font-smaller;
            font-family: $font-family-roboto-medium;
            text-align: left;
            vertical-align: middle;
            padding: 6px 0px 6px 20px;
            line-height: 20px;
            &:last-child {
              padding: 6px 20px 6px 8px;
            }
            .symbol_th {
              font-size: 13px;
              color: $color-blue-custom;
            }
            .up {
              color: $color-jungle-green;
            }
            .down {
              color: $color-denim;
            }
          }
        }
      }
    }
    .btn-close {
      width: 50px;
      height: 25px;
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
      letter-spacing: 0.8px;
      text-align: center;
      color: $color-jungle-green;
      font-size: $font-mini-mini;
      background-color: transparent;
      padding: 5px 2px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      float: right;
      &:hover, &:focus {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
        color: $color-white;
      }
    }
    .padding-right-0{
      padding-right: 0px;
    }
    .last-column{
      text-align: right;
    }
    .width-last{
      width: 12%
    }
    .width-10{
      width: 10%
    }
  }

  .box_custom_tooltip {
    position: relative;
    .tooltip_custom_c {
      visibility: hidden;
      display: none;
      width: 250px;
      background-color: #222832;
      color: #cccccc;
      text-align: center;
      padding: 5px 0;
      border-radius: 4px;
      font-size: 13px;
      -webkit-box-shadow: 0px 0px 3px #cccccc;
      box-shadow: 0px 0px 3px #cccccc;
      position: absolute;
      z-index: 50;
      left: 100%;
      top: 100%;
      line-height: 18px;
      &.left {
        left: auto;
        right: 100%;
      }
      .underline {
        text-decoration: underline;
        display: block;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
    &:hover {
      cursor: help;
      .tooltip_custom_c {
        display: block;
        visibility: visible;
        color: #cccccc;
      }
    }
  }
</style>

<style lang="scss">
  @import "@/assets/sass/variables";
   #instruments {
    .VuePagination {
      display: none;
    }
    table {
      border-collapse: initial;
      thead {
        background-color: transparent;
        overflow: inherit;
      }
      tbody {
        tr {
          td {
            &:last-child {
              color: $color-gray-dark;
              font-size: $font-smaller;
              font-family: $font-family-roboto-medium;
            }
          }
        }
      }
    }
    .empty-data {
      &:hover {
        background-color: transparent !important;
      }
    }
    .border_tr {
      position: relative;
      border-bottom: 4px solid #181f2b;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      &.up {
        border-left: 3px solid $color-jungle-green;
        border-right: 3px solid $color-jungle-green;
      }
      &.down {
        border-left: 3px solid $color-denim;
        border-right: 3px solid $color-denim;
      }
    }
  }
</style>