<template>
  <div id="qna-page">
    <create-qna @EnableCreateQna:CLOSE="hideCreateModal" @EnableCreateQna:SUBMIT="submitSuccessModal" :idDefault="idQna"></create-qna>
    <div id="qna_content" class="container">

      <div class="qna-header">
        <div>
          {{ $t('qna.inquiry')}}
        </div>
        <button class="btn-create" @click="showCreateModal()">
          {{ $t('qna.inquiry_create')}}
        </button>
      </div>
      <div class="qna-body">
        <!-- <div class="qna-category">
          <div class="category" :class="{ active: activeCategory === category.id }" v-for="category in categories" v-on:click="activeCategory = category.id"
            :key="category.id">
            {{ category.name }}
          </div>
        </div> -->
        <div class="table-container table-positions">
          <data-table3 ref="datatable" :getData="getData" :limit="10">
            <template slot-scope="props">
              <th data-sort-field="title" @click="props.echoclick">
                {{ $t('qna.inquiry_title') }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="question" @click="props.echoclick">
                {{ $t('qna.inquiry_content') }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="status" @click="props.echoclick">
                {{ $t('qna.inquiry_status') }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="created_at" @click="props.echoclick">
                <span>{{ $t('qna.inquiry_create_at') }}</span>
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="">
                <span class="fl-right"></span>
              </th>
            </template>
            <template slot="body" slot-scope="props">
              <tr v-if='props.item' class="border_tr up">
                <td><span>{{ props.item.title }}</span></td>
                <td><div v-html="formattedText(props.item.question)"></div></td>
                <td><span>{{ convertTextStatus(props.item?.status_text) }}</span></td>
                <td>
                  <p style="margin-bottom: 0">{{ formatDate(props.item.created_at)}}</p>
                  <span>{{ formatTime(props.item.created_at)}}</span>
                
                </td>
                <td><span class="btn-detail" @click="showDetail(props.item.id)">{{ $t('qna.inquiry_detail') }}</span></td>
              </tr>
            </template>
          </data-table3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CreateQna from "./CreateQna.vue";
import Const from '@/common/Const';
import rf from '@/request/RequestFactory';

export default {
  components: {
    CreateQna,
  },
  data() {
    return {
      categories: null,
      activeCategory: 1,
      idQna: null
    }
  },
  created() {
    this.getData()
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    convertTextStatus(text){
      let textConvert = ''
      switch (text) {
        case 'Pending':
        textConvert=  this.$t('qna.inquiry_pending')
          break;
        case 'Replied':
        textConvert= this.$t('qna.inquiry_replied')
          break;
        default:
          break;
      }
      return textConvert
    },
    showCreateModal() {
      this.idQna = null
      window.CommonModal.show('EnableCreateQna');
    },

    hideCreateModal() {
      window.CommonModal.hide('EnableCreateQna');
    },
    submitSuccessModal(){
      this.$refs.datatable.refresh();
    },
    formattedText(text){
      // return text?.replace(/\n/g, '<br>');
      const lines = text?.split(/\n+/); // Tách chuỗi thành các dòng
      return lines?.length > 1 ? lines?.[0] + "..." : lines?.[0];
    },
    getData(params) {
      const newParams = {
        // type_id: this.activeCategory,
        ...params
      }
      return rf.getRequest('UserRequest').getListInquiries(newParams)
        .then(res => {
          return res;
        });
    },
    getDataCategory() {
      return rf.getRequest('UserRequest').getListInquiriesType()
        .then(res => {
          if (res.success) {
            this.categories = res.data
          }
        });
    },
    showDetail(id){
      this.idQna = id
      window.CommonModal.show('EnableCreateQna');
    },
    formatDate(iosDate) {
      const date = new Date(iosDate);
      const formattedDate = date.getFullYear() + "-" +
        String(date.getMonth() + 1).padStart(2, '0') + "-" +
        String(date.getDate()).padStart(2, '0')
      return formattedDate
    },
    formatTime(iosDate) {
      const date = new Date(iosDate);
      const formattedDate = 
        String(date.getHours()).padStart(2, '0') + ":" +
        String(date.getMinutes()).padStart(2, '0') + ":" +
        String(date.getSeconds()).padStart(2, '0');
      return formattedDate
    },
  },
  mounted() {
    document.title = this.$t("qna") + ` – ${this.tabTitle}`;
    window.addEventListener('beforeunload', this.scrollToTop);
    this.getDataCategory();
  },
  watch: {

  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";

.m-bottom {
  margin-bottom: 8px;
}

.dark-theme {
  #qna-page {
    background-color: $color-night;

    .title-container {
      background: $color-black-shark;
      border-top: 4px solid $color-black;
    }
  }
}

#qna-page {
  display: flex;
  height: 100%;
  border-top: solid 4px var(--color-black);
  color: var(--dark-1);
  padding-top: 20px;
  min-height: calc(100vh - 65px - 252px);
  #left-sitebar {
    // max-width: 340px;
    background-color: var(--background-color-primary);
    border-right: solid 4px var(--color-black);
  }

  .qna-header {
    color: var(--dark-1);
    font-size: 28px;
    font-weight: 600;
  }

  #qna_content {
    background-color: var(--background-home-page);
    width: 100%;
  }

  .qna-body {
    margin-top: 50px;

    .qna-category {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 24px;

      .category {
        padding: 10px 24px;
        border-radius: 50px;
        border: 1px solid var(--text-no-data);

        &:hover {
          background-color: #dedede49;
          cursor: pointer;
        }

        &.active {
          background-color: $bgr-hover-cell;
          font-weight: 600;
        }
      }
    }

    .border_tr {
      padding: 10px 0;
      border-top: 1px solid $color-alto;
      td{
        color: var(--text-primary-color);
      }
    }
  }

  .qna-header {
    display: flex;
    justify-content: space-between;
  }

  .btn-create {
    background-color: #03AAC7;
    padding: 15.5px 20px;
    font-size: 18px;
    line-height: 21px;
    border-radius: 1234px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.144px;
    background-color: #03AAC7;
    color: white;
    @media screen and (max-width: 992px) {
      font-size: 14px;
      line-height: normal;
      padding: 10px;
    }
  }

  .btn-detail {
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 32px;
    padding-top: 16px;

  }
}
</style>
