<template>
  <div id="message" v-if="messages.length"  :class="configs.position">
    <div id="message_content" v-for="(message, index) in messages" :class="[setClass(String(message.type), message.border), {dark: isDarkMode}, typeof message.subMessage !== 'string' && 'd-flex align-item-center' ]" :key="index">
      <span class="btn_cancel">
        <!-- <i class="icon-close" @click="removeNoti(index)"></i> -->
        <img src="@/assets/images/icons/icon-close-message.svg"  @click="removeNoti(index)"/>
      </span>
      <div class="header_err" v-if="message.type == 'Error'">
        <img class="img-message"
           :src="require(`@/assets/images/icon/${setIcon(message.type)}.svg`)"/>
<!--        <p >Error</p>-->
      </div>
          <img class="img-message" v-else 
           :src="require(`@/assets/images/icon/${setIcon(message.type)}.svg`)"/>
      <div :class="['message-text' , message.type != 'Error' && 'moveTop', typeof message.subMessage !== 'string' && 'm-0 p-0' ]">
        <template v-if="typeof message.head !== 'undefined'">
          <strong>{{message.head}}: </strong>
          <!-- <br /> -->
        </template> 
        <template v-else>
<!--          <strong>{{ message.type.charAt(0).toUpperCase() + message.type.slice(1) }}: </strong>-->
        </template>
       
        <div v-if=" typeof message.content === 'string'" v-html="$options.filters.upperFirst(message.content)"></div>
        <!-- <br/> -->
         <span class="message-sub-message" v-if="typeof message.subMessage === 'string'">{{ message.subMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    data(){
      return {
        messages: [],
        showTime: 5000,
        configs: {},
        typeSuccess: window.i18n.t('messages.success'),
        typeInfo: window.i18n.t('messages.info'),
        typeError: window.i18n.t('messages.error'),
        typeWarning: window.i18n.t('messages.warning'),
        typePrimary: window.i18n.t('messages.primary'),
        lastStatusIcon : 'icon-close',
      }
    },
    computed: {
      ...mapState({
        theme: state => state.userThemeMode,
      }),
      isDarkMode() {
        return this.theme === 'dark-theme';
      },
    },
    methods: {
      removeNoti(idx) {
        this.messages = this.messages.filter((item, index) => index !== idx);
      },
      setMessage(){
        this.typeSuccess= window.i18n.t('messages.success');
        this.typeInfo= window.i18n.t('messages.info');
        this.typeError =  window.i18n.t('messages.error');
        this.typeWarning = window.i18n.t('messages.warning'),
        this.typePrimary = window.i18n.t('messages.primary')
      },
      setClass(classStyle, borderInput) {
        let _self=  this;
        if( borderInput ) return 'border';
        switch(classStyle){
          case _self.typeSuccess :
            return 'success';
            break;
          case _self.typeInfo :
            return 'info';
            break;
          case _self.typeError :
            return 'error';
            break;
          case _self.typeWarning:
            return 'warning';
            break;
          case _self.typeWarning:
          return 'warning';
          break;
          case 'errorWithdrawalManagement':
            return 'error';
            break;
        }
      },
      setIcon(type) {
        let _self=  this;
      
        switch(type) {
          case _self.typeSuccess :
          case _self.typeInfo :
            return 'icon-success';
            break;
          case _self.typeError :
          return 'icon-error';
          break;
          case _self.typePrimary :
            return 'primary';
            break;
          case _self.typeWarning:
            return 'warning';
            break;
          case 'orderWarning':
            return 'icon-warning';
            break;
          case 'errorInputSpot':
             return 'icon-error-input';
             break;
          case 'deleteDevice':
            return 'success';
            break;
          case 'errorWithdrawalManagement':
           return 'icon-warning';
        }
      }
    },
    created () {
      var self = this;

      window.Message = {
        primary : function(content, params, configs){
          self.setMessage();
          window.app.$broadcast('showMessage', 'primary', content, params, configs);
        },
        warning: function(content, params, configs){
          self.setMessage();
          window.app.$broadcast('showMessage', window.i18n.t('messages.warning'), content, params, configs);
        },
        success: function(content, params, configs){
          self.setMessage();
          window.app.$broadcast('showMessage', window.i18n.t('messages.success'), content, params, configs);
        },
        error: function(content, params, configs){
            self.setMessage();
          window.app.$broadcast('showMessage', window.i18n.t('messages.error'), content, params, configs);
        },
        errorWithdrawalManagement: function(content, params, configs){
          self.setMessage();
          window.app.$broadcast('showMessage', 'errorWithdrawalManagement', content, params, configs);
        },
        info: function(content, params, configs){
          window.app.$broadcast('showMessage', window.i18n.t('messages.info'), content, params, configs);
        },
        orderWarning: function(content, subMessage, params, configs){
          self.setMessage();
          window.app.$broadcast('showMessage', 'orderWarning', content, subMessage, params, configs);
        },
        errorInputSpot:function(content, subMessage, params, configs){
          self.setMessage();
          window.app.$broadcast('showMessage', 'errorInputSpot', content, subMessage, params, configs);
        },
        deleteDevice:function(content, subMessage, params, configs){
          self.setMessage();
          window.app.$broadcast('showMessage', 'deleteDevice', content, subMessage, params, configs);
        },
      }
      
      this.$on('showMessage', (type, content, subMessage, params, configs) => {
        var newMessage = {
          type    : type,
          content : content,
          params  : params,
          timer   : null,
          head    : configs?.head,
          subMessage: subMessage,
          border: type === 'errorInputSpot',
        };
        if (!configs) {
          configs = {
            position: 'bottom_right'
          };
        }
        this.configs = Object.assign(this.configs, configs);
        
        newMessage.timer = setTimeout(
          function(){
            self.messages.splice(self.messages.indexOf(newMessage), 1);
          },
          self.showTime
        );
        this.messages.push(newMessage);
      });
    }
  };
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
#message {
  position     : fixed;
  min-height   : 40px;
  max-width    : 400px;
  z-index      : 10000;
  padding      : 15px;

  &.center {
    margin-left: -143.5px;
    left: 50%;
    text-align: 50%;
    color: red;
    margin-top: -37.5px;
    top: 50%;
  }
  &.bottom_right {
    bottom: 10px;
    right: 20px;
    @media screen and (max-width: 375px){
      right: 0px;
    }
  }
  &.bottom_left {
    bottom: 10px;
    right: 20px;
  }
  &.top_left {
    top: 30px;
    right: 0px;
  }
  
  #message_content {
    padding: 15px;
    // display: flex;
    min-width: 369px;
    gap: 12px;
    border-radius: 8px;
    align-items: center;
    background-color: var(--bgr-toast-message);
    font-size: 18px;
    position: relative;
    margin-top: 10px;


    .btn_cancel {
      position: absolute;
      right: 12px;
      cursor: pointer;
      top: 8px;
      .icon-close::before {
        font-size: 11px;
        color: $dark-1;
      }
    }
    .header_err {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      p {
        margin-bottom: 0px;
        margin-left: 12px;
        font-weight: 400;
        font-size: $font-big ;
      }
    }
    .moveTop {
      margin-top: -22px;
    }
    .message-sub-message{
      color: var(--text-second-color) !important;
      margin-top: 5px;
      font-size: 14px !important;
    }

    .img-message{
      padding-top: 3px;
      margin: 0 !important;
    }

    .message-text{
      width: 100%;
      padding-left: 36px;
      font-weight: 400;
      font-size: 18px;
      div {
        color: var(--text-primary-color);
      }
      span{
        &:first-child{
          color: var(--text-second-color);
        }
      }
    }

    &.border{
      border: 1px solid $text-color-red !important;
    }
    
    &.success {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    }
    &.error {
      // border: 1px solid $text-color-red ;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
    &.info {
      background-color: $color_green_text;
      border: 1px solid $color_green_text;
      border-radius: 3px;
      margin-top: 10px;
      .icon-save {
        padding-right: 13px;
        font-size: $font-big-20;
        font-weight: bold;
        line-height: 2;
        &:before {
          color: $color-white;
        }
      }
      .message-text {
        align-self: center;
        // text-align: justify;
        strong, span{
          color: $color-white;
          line-height: 20px;
        }
      }
    }
    &.dark {
      border: 1px solid $color-black;
      .btn_cancel .icon-close::before {
        color: $color-night;
      }
    }
 
   
  }
  
}
@media screen and (max-width: 992px){
#message {
  #message_content {
    font-size: 16px;
    line-height: 19px;
    width: 91.5%;
    min-width: 325px;
  }
}
}
@media screen and (max-width: 500px){
  #message.bottom_right {
    right: auto;
  }
  #message {
    #message_content {
      width: calc(100vw - 32px);
    }
  }
}
@media screen and (max-width: 375px){
    #message {
      max-width: 374px;
      &.bottom_right{
        right: 5px;
      }

      #message_content{
        width: calc(100vw - 32px);
        min-width: 325px;
      }
    }
    
  
  }
</style>