<template>
  <div class="OtpModal">
    <modal
        :name="name"
        :width="sizeScreen"
        :title="'Filter'"
        :cssTitle=" {
          textAlign: 'left',
          fontSize: '16px',
          color: userThemeMode === 'light-theme' ? 'black' : 'white'
        }">
      <div slot="body">
        <div class="content_login_form_container">
          <div class="select-box-item">
            <div class="text-main choose-type_text">
              {{$t('orders.order_history.time')}}
            </div>
            <select-box v-model="time" :options="transformedTimeShow" />
          </div>
          <div class="select-box-coin">
            <span class="text-main">{{$t('address.coin')}}</span>
            <div
                class="drop-down-pair drop-down position-relative d-flex justify-content-between align-items-center"
                v-click-outside="clickOutSidePair"
            >
       <span v-if="coinSelected.name" class="placeholder-input">{{
           coinSelected.name == 'All' ? $t("order.order_form.all") : coinSelected.name
         }}</span>
              <span
                  class="w-100 h-100 current-pair"
                  @click="openDropPair = !openDropPair"
              ></span>
              <i
                  class="icon"
                  @click="openDropPair = !openDropPair"
                  :class="{
            'icon-arrow2': openDropPair,
            'icon-arrow1': !openDropPair,
          }"
              ></i>
              <div
                  class="position-absolute drop-list drop-list-pair"
                  v-show="openDropPair"
              >
                <div class="position-relative text-search-pair">
                  <input
                      v-model="pairName"
                      class="w-100 h-100"
                      type="text"
                      @input="searchPair"
                      @focus="focusInput = true"
                      :placeholder="$t('order.trade_history.search')"
                  />
                  <img
                      class="close-value position-absolute"
                      width="19"
                      height="19"
                      :src="require(`@/assets/images/landing/icon-search${dark}.svg`)"
                  />
                  <!-- <img
                    v-if="focusInput"
                    class="close-value position-absolute"
                    :src="`/images/icon/icon-close-input${dark}.svg`"
                    @click="handleDelete"
                  /> -->
                </div>
                <div class="list-pair-drop">
                  <template v-if="pairShow?.length > 0">
                    <div
                        class="list-pair"
                        v-for="pair in pairShow"
                        :key="pair.value"
                        @click="handleSelectPair(pair)"
                    >
                <span
                    :class="{
                    active:
                      pair.name.toUpperCase() ===
                      coinSelected.name.toUpperCase(),
                  }"
                >
                  <img
                      v-if="pair.name !== $t('order.order_form.all')"
                      :src="`${coinImages[pair.name]}`"
                      alt=""
                      width="30"
                      height="30"
                      class="coin-img"
                  />
                  {{
                    pair.name !== $t("order.order_form.all")
                        ? pair.name.toUpperCase()
                        : pair.name
                  }}</span
                >
                    </div>
                  </template>
                  <div v-else class="list-no-data">{{ $t('common.datatable.no_have_data') }}</div>
                </div>
              </div>
            </div>

          </div>
          <div :style="{display: 'flex', gap: '10px'}">
            <button class="btn btn-reset" @click="handleReset">
              {{ $t("button.reset") }}
            </button>
            <button class="btn btn-search" @click="handleSearch">
              Search
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/shared_components/common/Modal';
import OtpModal from "@/components/shared_components/funds/common/OtpModal.vue";
import SelectBox from "@/components/shared_components/common/SelectBox.vue";
import {mapState} from "vuex";

export default {
  inject: ['$validator'],
  components: {
    SelectBox,
    OtpModal,
    Modal,
  },
  data() {
    return {
      timeSelected: '',
      coinSelected: {
        name: 'All'
      },
      sizeScreen: "288",
      pairName: '',
      newApiName: this.newApiNameProps || '',
      openDropPair: false,
    }
  },
  props: {
    name: {type: String, default: 'modalFilter'},
    transformedTimeShow: {
      type: [],
    },
    timeShow:{
      type: []
    },
    time: {
      type: Number,
    },
    clickOutSidePair: {
      type: Function
    },
    focusInput: {
      type: Boolean
    },
    pairShow:{
      type: []
    },
    dark:{
      type: String
    },
    coinImages:{
      type: {}
    },
    pairs:{
      type: []
    },
  },
  watch: {
    time(newValue) {
      const selectedTime = this.timeShow.find(item => item.value === newValue);
      if (selectedTime) {
       this.timeSelected = selectedTime;
      }
    },
  },
  computed : {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  methods: {
    searchPair() {
      const debouncedFunc = _.debounce(() => {
        this.pairShow = this.pairs.filter((item) =>
            item.name.toLowerCase().trim().includes(this.pairName.toLowerCase())
        );
      }, 500);
      return debouncedFunc()
    },
    handleSelectPair(val){
      this.coinSelected = val
      this.openDropPair = false
    },
    handleWindowResize() {
      if(window.innerWidth > 800) {
        this.sizeScreen = "610";
      } else {
        this.sizeScreen = "288";
      }
    },
    handleReset(){
      console.log("trigger reset")
      this.timeSelected = 1;
      this.time = 1;
      this.coinSelected = {
        name: this.$t("order.order_form.all"),
      }
    },
    handleSearch(){
      console.log("time selected in modal", this.timeSelected)
      console.log("time coinSelected in modal", this.coinSelected)

      this.$emit('onSearch', {
        timeSelected: this.timeSelected,
        coinSelected: this.coinSelected
      });
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize)
    this.$on('hideCommonModal', (name) => {
      this.$emit('onClose');
    })
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/variables";

</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
:deep(.modal-content){
  background: var(--bg-our-item)!important;
  .content_login_form_container{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .icon-close{
    color: var(--text-primary-color) !important;
  }
  .select-box-item{
    width: 100%;
    .box_list_search_select{
      ul {
        background-color: var(--background-color-primary) !important;
      }
    }
  }
  .select-box-coin{
    display: flex;
    flex-direction: column;
    .drop-down-pair {
      width: 100%;
      height: 49px;
      padding: 12px;
      background: transparent;
      color: var(--text-second-color);
      border-radius: 80px;
      cursor: pointer;
      border: 1px solid var(--border-color-input-transaction-history);
      .current-pair {
        font-size: 18px;
        color: var(--monas-convert-color-tab);
        .placeholder-input {
          position: absolute;
          transform: translateY(-5%);
          right: 36px;
          text-align: right;
          color: red;
        }
      }
      .icon-arrow1, .icon-arrow2 {
        color: var(--text-primary-color);
        &:before{
          font-size: 14px;
        }
      }
      .drop-list {
        width: 100%;
        background: var(--background-color-primary);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        top: 57px;
        left: -25%;
        transform: translateX(25%);
        padding: 15px 20px;
        z-index: 1;

        .list-pair {
          margin-bottom: 12px;
          font-size: 14px;
          line-height: 16px;
          color: var(--color-dropdown);
          span {
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .active {
            color: $color-caribbean-green;
          }
          img {
            clip-path: circle(47%);
            width: 28px;
            object-fit: contain;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .list-pair-drop {
          max-height: 184px;
          overflow-y: auto;
        }
        .list-no-data {
          text-align: center;
          padding: 24px 0;
          color: var(--text-close);
        }

        .text-search-pair {
          width: 100%;
          height: 42px;
          margin-bottom: 16px;

          input {
            background: var(--bg-text-search);
            border-radius: 8px;
            border: none;
            padding: 10px 15px;
            color: var(--text-primary-color);;
            font-size: 18px;
            line-height: 22px;
          }
          ::placeholder {
            color: var(--color-text-search);
          }

          .icon-search {
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
          .close-value {
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
          }
        }
      }
      .drop-list-pair {
        width: 100%;
        height: 268px;
        padding: 15px 20px 0 20px;
      }
    }
    .drop-down {
      width: 100%;
    }
    .group-type {
      display: inline-block;
      margin-right: 20px;
      float: left;
      .sc_search_select {
        height: 30px;
        width: 95px;
        display: inline-block;
        float: left;
      }

      button {
        line-height: 20px;
        height: 49px;
        padding: 6px 16px;
        width: auto;
        min-width: 85px;
      }
    }
  }
  .btn{
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 1234px;
    border: 1px solid var(--border-color-input-transaction-history);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .btn-reset {
    background-color: var(--background-color-primary);
    color: var(--text-primary-color);
  }
  .btn-search {
    background-color: var(--text-primary-color) ;
    color: var(--background-color-primary);
  }
  .modal-header{
    border-bottom: 1px solid var(--border-color-input-transaction-history) !important;
  }
  .modal-body{
    padding: 16px !important;
  }
  .modal-title{
    font-family: $font-notosans-semibold;
  }
}
</style>
