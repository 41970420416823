<template>
  <base-layout>
    <div class="auth-container">
      <!-- end guest-logo -->
      <div class="guest-page-form">
        <div class="form-container" v-if="isConfirmMailShow">
          <div class="guest-page-form-header text-center header-account">
            <h3 class="title t-uppercase-comment title-account">
              {{ $t("menu.register_2") }}
            </h3>
          </div>
          <div class="guest-page-form-body">
            <div class="input-group">
              <label class="label-input mb-8">{{ $t("common.email") }}</label>
              <input
                :name="'email'"
                data-vv-validate-on="none"
                v-validate="'required|email'"
                :maxlength="maxlength"
                :placeholder="$t('common.placeholders.email2')"
                v-model="email"
                type="text"
                :class="{ error: errors.has('email') }"
                @focus="resetError"
                class="form-input"
              />
              <div class="invalid-feedback" v-if="errors.has('email')">
                {{ errors.first("email") }}
              </div>
            </div>

            <div class="input-group">
              <label class="label-input mb-8">{{
                $t("common.password")
              }}</label>
              <div class="custom-input pos-relative">
                <input
                  name="new_password"
                  ref="password"
                  data-vv-validate-on="none"
                  v-validate="{
                    required: true,
                    min: 8,
                    max: 72,
                    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                  }"
                  :placeholder="$t('common.placeholders.password2')"
                  v-model="password"
                  :maxlength="maxlength"
                  :type="isEyeOpen ? 'text' : 'password'"
                  :class="{ error: errors.has('new_password') }"
                  @focus="resetError"
                  class="form-input pos-relative form-new-password"
                  @input="checkNewPasswordFormat"
                />
                <div @click="isEyeOpen = !isEyeOpen" class="icon-last">
                  <img
                    v-if="userThemeMode == 'light-theme'"
                    :src="`/images/eye-${isEyeOpen ? 'open' : 'close'}.svg`"
                    alt="eye"
                  />
                  <img
                    v-else
                    :src="`/images/eyes-${
                      isEyeOpen ? 'open-dark' : 'close-dark'
                    }.svg`"
                    alt="eye"
                  />
                </div>
                <div class="tooltip_info">
                  <div class="item">
                    <i
                      :class="
                        passwordValidate.checkedLength
                          ? 'icon-check2'
                          : 'icon-close'
                      "
                    ></i>
                    <span class="text">{{
                      $t("validate.format.password_1")
                    }}</span>
                  </div>
                  <div class="item">
                    <i
                      :class="
                        passwordValidate.checkedNumber
                          ? 'icon-check2'
                          : 'icon-close'
                      "
                    ></i>
                    <span class="text">{{
                      $t("validate.format.password_2")
                    }}</span>
                  </div>
                  <div class="item">
                    <i
                      :class="
                        passwordValidate.checkedUpperCase
                          ? 'icon-check2'
                          : 'icon-close'
                      "
                    ></i>
                    <span class="text">{{
                      $t("validate.format.password_3")
                    }}</span>
                  </div>
                  <div class="item">
                    <i
                      :class="
                        passwordValidate.checkedLowerCase
                          ? 'icon-check2'
                          : 'icon-close'
                      "
                    ></i>
                    <span class="text">{{
                      $t("validate.format.password_4")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="invalid-feedback" v-if="errors.has('new_password')">
                {{ errors.first("new_password") }}
              </div>
            </div>
            <div class="input-group pos-relative">
              <label class="label-input mb-8">{{
                $t("common.repassword")
              }}</label>
              <div class="custom-input pos-relative">
                <input
                  :name="$t('confirm password')"
                  data-vv-validate-on="none"
                  v-validate="'required|confirmed:new_password'"
                  :placeholder="$t('common.placeholders.repassword2')"
                  v-model="passwordConfirmation"
                  :type="isEyeOpenRePassword ? 'text' : 'password'"
                  :maxlength="maxlength"
                  :class="{ error: errors.has($t('confirm password')) }"
                  @focus="resetError"
                  class="form-input"
                />
                <div
                  @click="isEyeOpenRePassword = !isEyeOpenRePassword"
                  class="icon-last"
                >
                  <img
                    v-if="userThemeMode == 'light-theme'"
                    :src="`/images/eye-${
                      isEyeOpenRePassword ? 'open' : 'close'
                    }.svg`"
                    alt="eye"
                  />
                  <img
                    v-else
                    :src="`/images/eyes-${
                      isEyeOpenRePassword ? 'open-dark' : 'close-dark'
                    }.svg`"
                    alt="eye"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                {{ errors.first($t("confirm password")) }}
              </div>
            </div>
            <div class="input-group">
              <div
                @click="collapse = !collapse"
                class="label-referral d-flex justify-content-between"
              >
                <label class="label-input mb-8">{{
                  $t("common.placeholders.referral")
                }}</label>
                <img
                  src="@/assets/images/icon/expand.svg"
                  alt="collapse"
                  class="referral-collapse"
                  :class="!collapse ? 'expand' : ''"
                />
              </div>
              <input
                v-show="collapse"
                :placeholder="$t('common.placeholders.referral2')"
                v-model="referralCode"
                type="text"
                :maxlength="maxlength"
                :class="{ error: errors.has('referrer_code') }"
                @focus="resetError"
                class="form-input"
              />
              <div class="invalid-feedback">
                {{ errors.first("referrer_code") }}
              </div>
            </div>
            <!--          <div class="input-group last">-->
            <!--          <VueRecaptcha id="register-captcha" ref="recaptcha" :sitekey="GOOGLE_CAPTCHA_KEY" @verify="onVerifyCaptcha" @expired="onExpiredCaptcha" />-->
            <!--          <div class="invalid-feedback" v-show="failCapcha === true">-->
            <!--            {{ $t('common.geetest.fail') }}-->
            <!--          </div>-->
            <!--          </div>-->
            <div class="checked-group">
              <div class="checkbox-input">
                <input
                  :class="{ error: errors.has('agree_term') }"
                  type="checkbox"
                  id="checkboxFiveInput"
                  v-model="agree"
                  class="check_term"
                />
                <label for="checkboxFiveInput"></label>
              </div>
              <label class="form-check-label">
                {{ $t("register.terms_1") }}
                <a class="link-primary term" target="_blank" href="/terms">{{
                  $t("register.terms_2")
                }}</a>
              </label>
            </div>
            <div class="input-group last" v-show="errors.has('error')">
              <div class="invalid-feedback term-error">
                {{ errors.first("error") }}
              </div>
            </div>
            <div class="form_actions">
              <button
                :class="{
                  chrome_only: isChrome && !errors.any(),
                  disabledBtn: isSubmitting,
                }"
                tabindex="0"
                :disabled="isSubmitting"
                type="button"
                class="btn-primary btn-primary-loading"
                @click="register"
              >
                <img src="@/assets/images/loading.png" class="loading-img" />
                {{ getSubmitName($t("menu.register_2")) }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-container-success" v-if="!isConfirmMailShow">
        <div class="guest-page-form-header text-center header-account">
          <h3 class="title t-uppercase-comment title-account-2">
            {{ $t("register.sendConfirmEmail_2") }}
          </h3>
        </div>
        <div class="guest-page-form-body">
          <div
            class="after-page-massge"
            v-html="$t('register.success_4', { email: email })"
          ></div>

          <div class="guest-page-guide">
            <div class="guest-page-guide-content">
              <h5>
                {{ $t("register.title_3") }}
              </h5>
              <ul>
                <li>{{ $t("register.paragraph_1") }}</li>
                <li>{{ $t("register.paragraph_2") }}</li>
                <li>{{ $t("register.paragraph_3") }}</li>
                <li>{{ $t("register.paragraph_4") }}</li>
              </ul>
            </div>
          </div>
          <div class="invalid-feedback" v-if="errors.has($t('errors-resend'))">
            {{ errors.first($t("errors-resend")) }}
          </div>
          <div class="re-sendmail">
            <button v-on:click="reSendEmail" class="btn-primary re-sendmail">
              {{ $t("register.title_4") }}
            </button>
          </div>
        </div>
      </div>
      <ConfirmationModal name="login_confirm_modal" />
    </div>
  </base-layout>
</template>

<script>
import rf from "@/request/RequestFactory";
import Utils from "@/common/Utils";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import BigNumber from "bignumber.js";
import BaseLayout from "./BaseLayout.vue";
import SucceedModal from "./SucceedModal.vue";
import CookiesUtils from "@/common/CookiesUtils";
import COMMON_CONST from "@/common/Const";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal";
import GeetestCom from "@/components/Geetest.vue";
import { VueRecaptcha } from "vue-recaptcha";
import { mapState } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      modalMessage: "",
      referralCode: "",
      isSubmiting: false,
      agree: false,
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      isConfirmMailShow: true,
      isChrome: window.navigator.userAgent.toLowerCase().indexOf("chrome") > 0,
      recaptchaKey: process.env.MIX_RECAPTCHA_KEY,
      // geetestData: false,
      geetestData: null,
      GOOGLE_CAPTCHA_KEY: process.env.VUE_APP_GOOGLE_CAPTCHA_KEY,
      is_ggcaptcha: 0,
      failCapcha: null,
      isEyeOpen: false,
      isEyeOpenRePassword: false,
      collapse: true,
      passwordValidate: {
        checkedLength: false,
        checkedNumber: false,
        checkedUpperCase: false,
        checkedLowerCase: false,
      },
    };
  },

  mixins: [RemoveErrorsMixin],
  components: {
    BaseLayout,
    SucceedModal,
    ConfirmationModal,
    GeetestCom,
    VueRecaptcha,
  },

  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      await this.$recaptcha("login").then((token) => {
        this.geetestData = token;
        this.is_ggcaptcha = 1;
      });
    },
    onVerifyCaptcha(response) {
      this.failCapcha = false;
      this.geetestData = response;
      this.is_ggcaptcha = 1;
    },
    onExpiredCaptcha() {
      this.failCapcha = true;
      this.geetestData = null;
      this.is_ggcaptcha = 0;
    },
    async register() {
      this.resetError();
      this.is_ggcaptcha = 0;
      if (this.isSubmitting) {
        return;
      }
      if (this.password) {
        await this.validateInputNewPassword();
      } else {
        await this.$validator.validate("new_password");
      }
      await this.$validator.validate("email");

      // await this.$validator.validate('email')
      await this.$validator.validate("confirm password");
      if (!this.agree) {
        this.errors.add({
          field: "error",
          msg: this.$t("register.error_terms"),
        });
      }
      await this.recaptcha();
      if (!this.geetestData) {
        // this.failCapcha = true;
        return;
      }
      if (parseInt(this.errors.items.length) > 0) {
        return;
      }

      const params = {
        email: this.email,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        referralCode: this.referralCode,
      };

      this.startSubmit();
      try {
        await rf
          .getRequest("UserRequest")
          .register(params, this.geetestData, this.is_ggcaptcha);
        this.endSubmit();
        this.showRegisteredModal();
      } catch (e) {
        this.endSubmit();
        // this.$refs.getGeetestObj.resetForm();
        if (
          (e.response && e.response.data.message == "validation.ip-active.") ||
          e.response.data.message == "google.recaptcha.errors"
        ) {
          this.errors.add({
            field: "error",
            msg: this.$t(`${e.response.data.message}`),
          });
        } else if (
          e.response &&
          e.response.data.message == "email.check_exist_email"
        ) {
          this.showConfirmedModal();
        } else if (
          e.response &&
          e.response.data.message == "email.mail_confirm_exists"
        ) {
          this.errors.add({
            field: "email",
            msg: this.$t("email.check_exist_email"),
          });
        } else {
          // this.convertRemoteErrors(e);
          if (e.response && e.response.data.errors.email) {
            this.errors.add({
              field: "email",
              msg: this.$t(`${e.response.data.errors.email[0]}`),
            });
          }
          if (e.response && e.response.data.errors.referrer_code) {
            this.errors.add({
              field: "referrer_code",
              msg: this.$t(`${e.response.data.errors.referrer_code[0]}`),
            });
          }
        }
      }
    },
    async validateInputNewPassword() {
      if (!(await this.$validator.validate("new_password"))) {
        this.errors.items.forEach((item, index) => {
          if (item.field === "new_password") {
            this.errors.items[index].msg = this.$t(
              "change_password_form.new_password_error"
            );
          }
        });
      }
    },

    showConfirmedModal() {
      window.ConfirmationModal.show({
        type: "confirmResend",
        title: "",
        content: this.$t("account.modal.confirm_registered_email"),
        btnCancelLabel: this.$t("common.action.no"),
        btnConfirmLabel: this.$t("common.action.yes"),
        onConfirm: () => {
          this.startSubmit();
          this.resetError();
          rf.getRequest("UserRequest")
            .resendConfirm({ email: this.email })
            .then((response) => {
              this.endSubmit();
              $("#confirmationModal").modal("hide");
            })
            .catch((error) => {
              this.errors.add(
                "errors-resend",
                this.$t("register.error_resendMail")
              );
              this.endSubmit();
              this.convertRemoteErrors(error);
            });
        },
        onCancel: () => {},
      });
    },

    onGeetestUpdated(data) {
      this.geetestData = data;
    },
    showRegisteredModal() {
      // window.CommonModal.show('register-succeed-modal');
      this.isConfirmMailShow = false;
    },
    reSendEmail() {
      this.startSubmit();
      this.resetError();
      rf.getRequest("UserRequest")
        .resendConfirm({ email: this.email })
        .then((response) => {
          this.endSubmit();
          this.showModalConfirm(true, this.email);
        })
        .catch((error) => {
          this.errors.add(
            "errors-resend",
            this.$t("register.error_resendMail")
          );
          this.endSubmit();
          this.convertRemoteErrors(error);
        });
    },
    onModalClose() {
      this.$router.push({ path: "/Landing Page" });
    },
    showModalConfirm(result, email) {
      this.modalMessage = result
        ? this.$t("login.resend_confirm_mail_success", { email: email })
        : this.$t("login.active_device_failed");
      this.showModal("confirm-succeed-resent-email-modal", this.modalMessage);
    },
    showModal(type, message) {
      window.ConfirmationModal.show({
        type: type,
        title: message,
        content: "",
        btnCancelLabel: null,
        btnConfirmLabel: null,
        widthModal: 560,
        onConfirm: () => {},
        onCancel: () => {},
      });
    },
    checkNewPasswordFormat() {
      const password = this.password.toString().trim();
      this.passwordValidate.checkedLength = password.length >= 8;
      this.passwordValidate.checkedNumber = /\d/.test(password);
      this.passwordValidate.checkedUpperCase = /[A-Z]/.test(password);
      this.passwordValidate.checkedLowerCase = /[a-z]/.test(password);
    },
  },
  created() {
    // const referralCd = CookiesUtils.getItem(COMMON_CONST.REFERRAL_KEY);
    const referralCd = this.$route?.query?.ref;
    if (referralCd) {
      this.referralCode = referralCd;
    }
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 13 && this.$route.name == "Register") {
        this.register();
      }
    });
  },
};
</script>

<style lang="scss">
.auth-container {
  .model-body {
    padding: 0 !important;
    .padd-bottom-20 {
      height: 75px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/common.scss";
@import "@/assets/sass/reset.scss";

.term-error {
  margin-bottom: 12px;
}
.re-sendmail a {
  font-size: 13px;
}
.re-sendmail i {
  font-size: 11px;
}
.invalid-feedback {
  padding-top: 5px;
  //margin-bottom: 20px;
}
.form-container-success {
  //max-width: 380px;
  width: 409px;
  max-width: 100%;
  margin: 0 auto;
}
.form-container {
  max-width: 100%;
}
.input-group-prepend {
  span {
    line-height: 50px;
  }
}
ul {
  list-style-type: disc;
}
.btn-primary {
  height: 50px;
  width: 100%;
  border-radius: 1234px;
  background-color: $color-caribbean-green;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.084px;
  text-transform: none;
  margin-top: 30px;
  &:disabled {
    background-color: $background-bright-gray !important;
    border-color: $border-bright-gray !important;
    color: $text-default !important;
  }
  &:hover:enabled,
  &:focus {
    background-color: $color-aquamarine !important;
    border-color: $border-blue-custom !important;
    color: $color-grey-dark !important;
  }
  &.re-sendmail {
    width: 400px;
    max-width: 100%;
  }
}

.form-input {
  display: block;
  height: 49px;
  width: 100% !important;
  max-width: 100%;
  padding: 12px;
  background: var(--background-color-second);
  border-radius: 123px;
  border-color: var(--color-border);
  color: var(--text-primary-color) !important;
  font-size: $font-root;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  &:focus,
  &:active {
    border: 1px solid $color-aquamarine !important;
  }
  &.error {
    border: 1px solid $text-color-red !important;
  }
  &::placeholder {
    color: var(--text-placeholder);
  }
}

.header-account {
  display: flex;
  text-align: center;
  margin: 20px 0px;
  justify-content: center;
}
.title-l {
  margin: 13px 0px 15px 0px;
  height: 1px;
  width: 35%;
  border-radius: 5px;
  background-color: $color-light-grey;
}
.title-l-2 {
  margin: 35px 0px 15px 0px;
  height: 1px;
  width: 15%;
  border-radius: 5px;
  background-color: #f2f2f2;
}
.title-account {
  margin: 0px auto 40px auto !important;
  font-size: $font-title-size-medium-2;
  line-height: 41px;
  color: var(--text-primary-color) !important;
  font-family: $font-inter-bold;
}
.title-account-2 {
  color: var(--text-primary-color) !important;
  padding: 0 10px;
  font-family: $font-inter-regular;
  font-size: 34px;
  font-weight: 600;
}
.guest-page-form-body {
  max-width: 100%;
  a {
    color: $color-curious-blue !important;
  }
  .after-page-massge {
    font-size: $font-big;
    text-align: center;
  }
}
.guest-page-massge {
  font-family: $font-inter-regular;
  color: var(--text-second-color) !important;
  font-size: $font-medium-bigger;
  text-align: center;
  & p {
    font-size: $font-medium-bigger;
    text-align: center;
    opacity: 0.8;
  }
}
.guest-page-guide {
  width: 409px !important;
  max-width: 100%;
  background: var(--background-desc);
  border-radius: 8px;
  margin-top: 20px;
}
.guest-page-guide-content {
  padding: 15px 0px 15px 15px;
  font-family: $font-inter-regular;
  color: $color-gray-alabaster;
  h5 {
    font-family: $font-inter-regular;
    color: $color-gray-alabaster;
    font-size: $font-medium-bigger;
  }
  ul {
    font-size: $font-root;
    line-height: 22px;
    font-family: $font-inter-regular;
    color: $color-gray-alto;
    li {
      list-style: none;
      font: $font-root;
    }

    li:before {
      content: "\2022";
      padding: 0px 10px;
      font-size: 12px;
      vertical-align: center;
    }
  }
}
.recaptcha-form {
  margin-bottom: 12px;
}
.checked-group {
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 5px;
  .check_term {
    border: none;
    width: 19px;
    height: 19px;
  }
  .form-check-label {
    font-size: $font-root;
    color: var(--text-check-label);
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.084px;

    .term {
      color: #03aac7 !important;
    }
  }
}
.input-group {
  margin-bottom: 24px;
  position: relative;
  .icon-last {
    img {
      position: absolute;
      top: 35%;
      right: 20px;
      float: left;
      cursor: pointer;
    }
  }
  .custom-input {
    height: 52px;
    max-height: 100%;
  }
  .last {
    margin-bottom: 30px;
  }
  label {
    font-weight: 400 !important;
    font-size: $font-smaller;
    line-height: 16px;
    color: var(--text-label) !important;
    letter-spacing: -0.06px;
  }
  .referral-collapse {
    margin-right: 15px;
    cursor: pointer;
    &.expand {
      transform: rotate(180deg);
    }
  }
}
.tooltip_info {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 11px));
  padding: 10px;
  background: var(--background-desc);
  border-radius: 8px;
  margin-right: -5px;
  .item {
    display: flex;
    align-items: center;
    gap: 12px;
    & + .item {
      margin-top: 8px;
    }
    i::before {
      color: $color-aqua-green;
    }
    .icon-close::before {
      color: $text-color-red;
    }
    .text {
      font-family: $font-inter-regular;
      color: $color-white;
      font-size: 18px;
      line-height: 21px;
    }
  }
}
.form-new-password:focus {
  ~ .tooltip_info {
    display: block;
  }
}
.checkbox-input {
  height: 19px !important;
  width: 19px !important;
  label {
    width: 19px !important;
    height: 19px !important;
    border-radius: 6px;
    margin: 0;
    border: 1px solid $border-checkbox;
  }
  label:after {
    width: 14px !important;
    height: 10px !important;
    top: 15% !important;
  }
}

:deep(#register-captcha) {
  max-width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  transform: scale(1);
  -webkit-transform: scale(1.33, 1.2);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
@media screen and (max-width: 576px) {
  .header-account {
    margin: 0;
  }
  .title-account {
    margin-bottom: 25px !important;
    font-size: $font-big-24;
    line-height: 29px;
  }
  .title-account-2 {
    margin-bottom: 25px !important;
    font-size: $font-big-24;
    line-height: 29px;
  }

  :deep(.guest-page-guide-content) {
    & h5 {
      font-size: $font-root;
    }
    ul {
      font-size: $font-smaller;
    }
  }
  .guest-page-form-body {
    margin: auto;
    .after-page-massge {
      font-size: $font-root;
    }
    label {
      font-size: $font-root;
      line-height: 22px;
    }
    .input-group {
      margin-bottom: 16px;
      width: 100%;
      input {
        font-size: $font-root !important;
      }
    }
    input {
      &:focus,
      &:active {
        border: 1px solid $color-aquamarine !important;
      }
      &.error {
        border: 1px solid $text-color-red !important;
      }
    }

    #register-captcha {
      transform: scale(1);
      -webkit-transform: scale(1.05, 1);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      margin-bottom: 0px;
    }
    .btn-primary {
      width: 100%;
    }
    .form_actions {
      margin-bottom: 30px;
    }
  }
  .tooltip_info {
    top: 100%;
    right: 104%;
    width: 100%;
    z-index: 10;
  }
  #confirmationModal {
    :deep(.modal-dialog) {
      width: 343px !important;
      left: 50%;
      padding: 0 !important;
      .modal-header {
        padding-top: 10px !important;
      }
      .modal-content {
        width: 343px !important;
        height: 281px;
        .modal-body {
          .fo_green_4 {
            img {
              width: 74px;
              height: 74px !important;
            }
            .title-header-modal {
              margin-top: 23px !important;
              font-size: $font-big-24;
              margin-bottom: 5px;
            }
            .tit_modal_confirm {
              font-size: $font-medium-bigger;
            }
            .modal-content-text,
            .desc-change {
              font-size: $font-medium-bigger;
              margin: 0 auto 10px;
              white-space: normal;
            }
            .mb-20 {
              margin-bottom: 0px !important;
            }
            .mt-40 {
              margin-top: 20px !important;
            }
            .btn-resetMail {
              width: 160px !important;
              margin-top: 20px !important;
            }
          }
        }
      }
      .mt-40 {
        margin: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .tooltip_info {
    top: 100%;
    right: 104%;
    width: 100%;
    z-index: 10;
  }
}
@media screen and (max-width: 375px) {
  #register-captcha::v-deep {
    max-width: 100%;
    transform: unset !important;
    div {
      max-width: 100%;
      iframe {
        width: 100%;
      }
    }
  }
}
.mb-8 {
  margin-bottom: 8px;
}
</style>
