<template>
  <div id="setting_dashboard">
    <!-- <change-password ref='changePassComponent'/> -->
    <otp-modal
      name="otp_modal"
      :submit="updateModeWhitelist"
      :params="getParamsUpdateModeWhitelist()"
      :is-disable-button="isDisableButton"
      :contact_email="siteSetting.contact_email"
    />
    <cancel-google-auth-modal
      @CancelGoogleAuthModal:CLOSE="hideCancelGoogleAuthModal"
    ></cancel-google-auth-modal>
    <enable-google-authentication
      @EnableGoogleAuthentication:CLOSE="hideEnableGoogleAuthenticaion"
    ></enable-google-authentication>
    <identity-verified
      :workflow-run-url-default="workflowRunUrl"
      :first-name="firstName"
      :last-name="lastName"
      :status="kyc.status"
      @EnableIdentityVerified:CLOSE="hideEnableIdentityModal"
      @refreshData="refreshData"
    ></identity-verified>
    <lost-otp-modal
      :contact_email="siteSetting.contact_email"
      name="lostOtpForm"
      @DISABLE_OTP_SUCCESS="disableOtpSuccess"
    />
    <confirmation-modal>
      <template slot="content">
        <div class="checkbox-bank">
          <input type="checkbox" id="c-bank" v-model="isCreateNewBankAccount" />
          <label for="c-bank">{{
            $t("refferal.confirm_bank_account_content")
          }}</label>
        </div>
      </template>
    </confirmation-modal>
    <div class="right_list_option_acc">
      <div class="setting_top_header">
        <AccountTopHeader :title="$t('account.dashboard')" />
      </div>
      <div v-show="fetching == false" class="contents_setting">
        <p class="title-medium-small">{{ $t("menu.account") }}</p>
        <ProfileInfoHeader
          :email="user.email"
          :kyc="kyc"
          :levelOtpVerify="levelOtpVerify"
          :fetching="fetching"
          :userId="user.uid"
          :accountLevel="user.security_level"
          :lastLoginTime="lastLogin.created_at | timestampToDate"
          @onShowEnableIdentityModal="showEnableIdentityModal()"
          :identityVerified="identityVerified"
          :checkIdentityVerified="checkIdentityVerified"
          
        />
        <div class="acount_info" :style="{ marginTop: '40px' }">
          <div class="acc_info_level">
            <p class="title-medium-small">{{ $t("account.level") }}</p>
            <StepProgress
              :steps="withdrawalLimitBTC"
              :userSecurityLevel="userSecurityLevel"
            />
            <AccountBalance
              :amount="100"
              currency="USDT"
              conversion="~ 2,500,000 VND"
              :typeIcon="typeIcon"
              :isShowEstimate="isShowEstimate"
              :totalBtcBalance="totalBtcBalance"
              :totalUsdBalance="totalUsdBalance"
              @onToggleShowEstimate="toggleShowEstimate()"
            />
          </div>
        </div>
      </div>
      <div
        v-if="fetching == true"
        class="d-flex justify-content-center align-items-center contents_setting"
        style="max-width: initial"
      >
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import CancelGoogleAuthModal from "@/components/shared_components/otp/CancelGoogleAuthModal";
import OtpModal from "@/components/shared_components/funds/common/OtpModal";
import EnableGoogleAuthentication from "./account/EnableGoogleAuthentication";
import IdentityVerified from "./account/IdentityVerified";
import ChangePassword from "./account/ChangePassword.vue";
import DeviceManager from "./account/DeviceManager.vue";
import LostOtpModal from "@/components/shared_components/otp/LostOtpModal";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal";
import SelectBox from "@/components/shared_components/common/SelectBox";
import moment from "moment";
import Const from "@/common/Const";
import { getLayoutPath } from "@/routes";
import ChartContracts from "./ChartIndex.vue";
import { mapState } from "vuex";
import AccountSiteBar from "@/components/shared_components/common/AccountSiteBar.vue";
import AccountTopHeader from "@/components/shared_components/common/AccountTopHeader.vue";
import BigNumber from "bignumber.js";
import "url-search-params-polyfill";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProfileInfoHeader from "@/components/setting_pages/ProfileInfoHeader.vue";
import StepProgress from "@/components/setting_pages/StepProgress.vue";
import AccountBalance from "@/components/setting_pages/AccountBalance.vue";

const KYC_UNVERIFED = "unverified";
const KYC_PENDING = "pending";
const KYC_VERIFIED = "verified";
const KYC_REJECTED = "rejected";
const BANK_UNVERIFED = "unverified";
const BANK_CREATING = "creating";
const BANK_VERIFING = "verifing";
const BANK_VERIFIED = "verified";
const BANK_REJECTED = "rejected";
var sha1 = require("sha1");
BigNumber.config({ EXPONENTIAL_AT: 15 });
export default {
  components: {
    ChartContracts,
    CancelGoogleAuthModal,
    EnableGoogleAuthentication,
    OtpModal,
    ChangePassword,
    LostOtpModal,
    DeviceManager,
    ConfirmationModal,
    SelectBox,
    AccountSiteBar,
    AccountTopHeader,
    IdentityVerified,
    Breadcrumb,
    ProfileInfoHeader,
    StepProgress,
    AccountBalance,
  },
  mixins: [RemoveErrorsMixin],
  data() {
    return {
      linkKycAccount: getLayoutPath({ name: "IdentityPersonal" }, true),
      linkAPIAccount: getLayoutPath({ name: "ApiCreated" }, true),
      linkAPIPage: process.env.VUE_APP_API_URL + "/api/documentation",
      isShowTooltip: false,
      tab: "main",
      enableWhiteList: undefined,
      enableLeaderboard: undefined,
      localShowFakeName: undefined,
      lastLogin: {},
      withdrawalLimitBTC: [],
      check: 0,
      settings: {
        email: true,
        phone: false,
        bank: false,
        otp: false,
        password: false,
      },
      kyc: {
        status: "",
        bank_status: "",
        workflowRunUrl: "",
      },
      user: {},
      infomation: {},
      otp: undefined,
      userSettingWhitelist: undefined,
      enableOtp: true,
      emailNotification: true,
      telegramNotification: true,
      lineNotification: false,
      isCreateNewBankAccount: false,
      enableAmalFromAdmin: false,
      enableAmlPay: false,
      app_name: process.env.VUE_APP_NAME,
      moment: moment,
      isDisableButton: false,
      siteSetting: {},
      idEncrypt: "",
      perpetual_dividend: false,
      isEditFakeName: false,
      fakeNameTemp: "",
      isShowEstimate: true,
      kyc_key: process.env.VUE_APP_BLOCKPASS_KYC_KEY,
      coinsMasterData: [],
      dataBalance: [],
      prices: {},
      balanceFutures: [],
      fetching: false,
    };
  },
  watch: {
    enableAmalFromAdmin(newValue) {
      if (!newValue) {
        this.enableAmlPay = false;
      }
    },
  },
  mounted() {
    this.getMasterData();
    let url = window.location.href;
    if (url.includes("need-close")) {
      window.close();
    }
    this.loadBlockpassWidgets();
    this.getHistoryConnection();
    this.$on("hideCommonModal", (name) => {
      if (name === "EnableIdentityVerified") {
        this.refreshData();
      }
    });
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
      theme: (state) => state.userThemeMode,
    }),
    isVerifiableBank() {
      return (
        this.kyc.bank_status === BANK_CREATING ||
        this.kyc.bank_status === BANK_VERIFING ||
        this.kyc.bank_status === BANK_VERIFIED
      );
    },
    appUrl() {
      return window.location.origin;
    },
    submittableKyc() {
      return window._.get(this, "user.security_setting.otp_verified", 0);
    },
    userSecurityLevel() {
      if (this.user.security_level > this.user.max_security_level) {
        return this.user.security_level;
      }
      return this.user.max_security_level;
    },
    typeIcon() {
      if (this.theme === "light-theme") {
        return this.isShowEstimate ? "eye-open" : "eye-close";
      } else {
        return this.isShowEstimate ? "eyes-open-dark" : "eyes-close-dark";
      }
    },
    levelOtpVerify() {
      return this.user?.security_setting?.otp_verified;
    },
    workflowRunUrl() {
      const workflowRunUrl = this.kyc?.workflowRunUrl;
      return workflowRunUrl;
    },
    firstName() {
      const first_name = this.kyc?.first_name;
      return first_name;
    },
    lastName() {
      const last_name = this.kyc?.last_name;
      return last_name;
    },
    identityVerified() {
      const identity_verified = this.user?.security_setting?.identity_verified;
      // return identity_verified;
      return 0;
    },
    checkIdentityVerified() {
      const status = this.kyc?.status;
      const workflowRunUrl = this.kyc?.workflowRunUrl;
      const checkIdentity =
        (status !== "pending" && workflowRunUrl === "") ||
        !status ||
        (workflowRunUrl && this.kyc?.bank_status === "init");
      //return !status || !workflowRunUrl || !this.kyc?.id_back || !this.kyc?.id_front || !this.kyc?.id_selfie  // Code Peter mới update 31/07/2024
      return checkIdentity; // Code cũ của Life
    },
    totalBtcBalance() {
      return this.sumBalanceBy("btcValue");
    },
    totalUsdBalance() {
      return this.sumBalanceBy("usdValue");
    },
  },
  methods: {
    toggleShowEstimate() {
      this.isShowEstimate = !this.isShowEstimate;
    },
    onSaveFakeName() {
      rf.getRequest("UserRequest")
        .onUpdateFakeName({ fake_name: this.fakeNameTemp })
        .then((res) => {
          if (res && res.data) {
            this.user.fake_name = this.fakeNameTemp;
            this.isEditFakeName = false;
            this.getUser(false);
            this.showSuccess(this.$t("mam.update.success"));
          }
        });
    },
    activeLeaderboard() {},
    getLinkPnlChart() {
      rf.getRequest("UserRequest")
        .getLinkPnlChart({ name: "Pnl-Chart" })
        .then((res) => {
          const path = APP_URL + "/pnl-chart?token=" + res.data.id;
          // if (!navigator.clipboard) {
          //   Utils.fallbackCopyTextToClipboard(path);
          //   return;
          // }
          // navigator.clipboard.writeText(path);
          // this.$router.push({ name: 'PNLChart', query: {token: res.data.id} });
          window.open(path, "_blank");
        });
    },
    convertDate(date) {
      var dateFormat = "YYYY-MM-DD HH:mm:ss";
      var testDateUtc = moment.utc(date);
      var localDate = testDateUtc.local();
      return localDate.format(dateFormat);
    },
    addChannelTelegram: function (userID) {
      document.getElementById("cancel_focus_telegram").blur();
      this.listenForTelegramLine(userID);
      let apiTelegram = process.env.VUE_APP_MIX_API_TELEGRAM + userID;
      window.open(apiTelegram, "_blank");
    },

    getParamsUpdateModeWhitelist() {
      return {
        active: !this.userSettingWhitelist,
      };
    },
    listenForTelegramLine(userId) {
      window.Echo.private("App.User." + userId).listen(
        "UserNotificationUpdated",
        (res) => {
          let channel = res.data.channel;
          let is_enable = res.data.is_enable;
          if (channel === "line") {
            this.lineNotification = is_enable;
          }
          if (channel === "mail") {
            this.emailNotification = is_enable;
          }
          if (channel === "telegram") {
            this.telegramNotification = is_enable;
          }
        }
      );
    },
    async redirectLine(userID) {
      await this.encryptId();
      document.getElementById("cancel_focus_line").blur();
      if (userID) {
        let callback_uri =
          this.$router.resolve({ name: "Account" }).href + "?need-close";
        let state = userID + "&" + this.idEncrypt + "$" + callback_uri;
        let client_id = process.env.VUE_APP_MIX_LINE_CLIENT_ID;
        let redirect_uri =
          process.env.VUE_APP_API_URL + "/api/v1/get-auth-code";
        let response_type = "code";
        let url = "https://notify-bot.line.me/oauth/authorize?";
        let scope = "notify";
        var searchParams = new URLSearchParams("");
        searchParams.append("client_id", client_id);
        searchParams.append("redirect_uri", redirect_uri);
        searchParams.append("response_type", response_type);
        searchParams.append("scope", scope);
        searchParams.append("state", state);
        url = url + searchParams;
        window.open(url, "_blank");
      }
    },
    changeWalletFee(type) {
      if (this.tab != type && this.enableAmlPay) {
        this.tab = type;
        let param = {
          wallet_name: type,
        };
        rf.getRequest("UserRequest")
          .changeTypeWalletAmalPayFee(param)
          .then((res) => {});
      }
    },

    showChannelModal(type, channel) {
      if (type === "disable") {
        window.ConfirmationModal.show({
          type:
            channel === "email"
              ? "disableEmail"
              : channel === "telegram"
              ? "disableTelegram"
              : channel === "line"
              ? "disableLine"
              : "",
          title: "",
          content:
            channel === "email"
              ? this.$t("account.email_disable_confirm")
              : channel === "telegram"
              ? this.$t("account.email_disable_confirm")
              : channel === "line"
              ? this.$t("account.email_disable_confirm")
              : "",
          btnCancelLabel: window.i18n.t("common.action.no"),
          btnConfirmLabel: window.i18n.t("common.action.yes"),
          onConfirm: () => {
            this.actionNotification(type, channel);
          },
        });
      } else {
        document.getElementById("cancel_focus_mail").blur();
        this.actionNotification(type, channel);
      }
    },
    actionNotification(type, channel) {
      let param = {
        active: 1,
      };
      if (type === "disable") {
        param.active = 0;
      } else {
        param.active = 1;
      }
      if (channel === "email") {
        rf.getRequest("UserRequest")
          .changeEmailNotification(param)
          .then((res) => {
            this.getUserNotification();
          });
      }
      if (channel === "telegram" && param.active == 0) {
        rf.getRequest("UserRequest")
          .changeTelegramNotification(param)
          .then((res) => {
            this.getUserNotification();
          });
      }
      if (channel === "line") {
        rf.getRequest("UserRequest")
          .changeLineNotification(param)
          .then((res) => {
            this.getUserNotification();
          });
      }
    },
    showTooltip() {
      this.isShowTooltip = !this.isShowTooltip;
    },
    hideTooltip() {
      this.isShowTooltip = false;
    },
    showCancelGoogleAuthModal() {
      window.CommonModal.show("CancelGoogleAuthModal");
    },
    showChangePasswordModal() {
      window.CommonModal.show("ChangePassword");
      this.$refs.changePassComponent.resetData();
    },
    hideCancelGoogleAuthModal() {
      window.CommonModal.hide("CancelGoogleAuthModal");
      this.getSecuritySettings();
      this.getUser(false);
    },
    hideEnableGoogleAuthenticaion() {
      window.CommonModal.hide("EnableGoogleAuthentication");
      this.getSecuritySettings();
      this.getUser(false);
    },
    showEnableOtpModal() {
      window.CommonModal.show("EnableGoogleAuthentication");
    },
    hideEnableIdentityModal() {
      window.CommonModal.hide("EnableIdentityVerified");
    },
    refreshData() {
      this.getSecuritySettings();
      this.getUserKyc();
      this.getUser(false);
    },
    showEnableIdentityModal() {
      window.CommonModal.show("EnableIdentityVerified");
    },

    showModalLostOtp() {
      window.CommonModal.show("lostOtpForm");
    },
    disableOtpSuccess() {
      this.getSecuritySettings();
      this.getUser(false);
    },
    loadDataImmediately() {
      this.getUserSettings();
      this.getUserNotification();
      this.getSecuritySettings();
      this.getUser();
      this.getWithdrawalLimitBTC();
      this.getUserKyc();
      this.getDividendSettings();
    },
    getSecuritySettings() {
      rf.getRequest("UserRequest")
        .getSecuritySettings()
        .then((res) => {
          this.settings = Object.assign({}, this.settings, res.data);
          this.enableOtp = window._.get(this, "settings.otp_verified", 0);
        });
    },
    getUserKyc() {
      rf.getRequest("UserRequest")
        .getUserKyc()
        .then((res) => {
          this.kyc = res.data || {};
          this.isCreateNewBankAccount = this.kyc.bank_status === BANK_CREATING;
          this.fetching = false;
        });
    },
    async encryptId() {
      await rf
        .getRequest("UserRequest")
        .encryptId()
        .then((res) => {
          this.idEncrypt = sha1(res.toString()) || {};
        });
    },
    getDividendSettings() {
      rf.getRequest("UserRequest")
        .getDividendSetting()
        .then((res) => {
          this.enableAmalFromAdmin = res.data?.enable_fee_amal || false;
          this.perpetual_dividend = res.data?.enable_wallet_pay_fee || false;
        });
    },
    getHistoryConnection(params) {
      const promise = rf.getRequest("UserRequest").getHistoryConnection(params);
      this.getLatest(promise);
      return promise;
    },
    getLatest(promise) {
      promise.then((res) => {
        this.lastLogin = res.data.data[0];
      });
    },

    getUserNotification() {
      rf.getRequest("UserRequest")
        .getUserNotificationSettings()
        .then((res) => {
          const emailNotification =
            _.find(res.data, (item) => {
              return item.channel === "mail";
            }) || {};

          this.emailNotification = emailNotification.is_enable === 1;

          const telegramNotification =
            _.find(res.data, (item) => {
              return item.channel === "telegram";
            }) || {};

          this.telegramNotification = telegramNotification.is_enable === 1;

          const lineNotification =
            _.find(res.data, (item) => {
              return item.channel === "line";
            }) || {};
          this.lineNotification = lineNotification.is_enable === 1;
        });
    },

    getUserSettings() {
      rf.getRequest("UserRequest")
        .getUserSettings()
        .then((res) => {
          const setting =
            _.find(res.data, (item) => {
              return item.key === "whitelist";
            }) || {};
          this.userSettingWhitelist = this.enableWhiteList = !!parseInt(
            setting.value
          );

          const amlPay =
            _.find(res.data, (item) => {
              return item.key === "amal_pay";
            }) || {};
          const amlWalletPay =
            _.find(res.data, (item) => {
              return item.key === "amal_pay_wallet";
            }) || {};
          this.enableAmlPay = amlPay.value === "1";
          if (amlWalletPay && amlWalletPay.value) {
            this.tab = amlWalletPay.value;
          }
        });
    },
    getUser(useCache = true) {
      rf.getRequest("UserRequest")
        .getCurrentUser(useCache)
        .then((res) => {
          this.user = Object.assign({}, this.user, res.data);
          this.listenForTelegramLine(res.data.id);
          this.loadBlockpassWidgets(res.data.id);
          this.localShowFakeName = this.user.security_setting.use_fake_name;
          return (this.enableLeaderboard =
            this.user.security_setting.use_fake_name);
        });
    },
    getWithdrawalLimitBTC() {
      rf.getRequest("UserRequest")
        .getWithdrawalLimitBTC()
        .then((res) => {
          this.withdrawalLimitBTC = res.data;
        });
    },
    activeWhitelist() {
      const message = this.userSettingWhitelist
        ? window.i18n.t("account.turn_on_mode_whitelist")
        : window.i18n.t("account.turn_off_mode_whitelist");
      this.showModal({
        type: "primary",
        title: "",
        content: message,
        onConfirm: () => {
          if (this.enableOtp) {
            window.CommonModal.show("otp_modal");
            this.enableWhiteList = this.userSettingWhitelist;
          } else {
            const params = this.getParamsUpdateModeWhitelist();
            this.updateModeWhitelist(params);
          }
        },
        onCancel: () => {
          this.enableWhiteList = this.userSettingWhitelist;
        },
      });
    },
    updateModeWhitelist(params) {
      this.isDisableButton = true;
      rf.getRequest("UserRequest")
        .activeWhitelist(params)
        .then((response) => {
          this.isDisableButton = false;
          window.CommonModal.hide("otp_modal");
          this.userSettingWhitelist = this.enableWhiteList =
            response.data.whitelist;
        })
        .catch((error) => {
          this.isDisableButton = false;
          if (!error.response) {
            Message.error(window.i18n.t("common.message.network_error"));
          } else {
            this.convertRemoteErrors(error);
          }
        });
    },
    activeLeaderboard() {
      const message = this.enableLeaderboard
        ? window.i18n.t("leaderboard.show_fake_username")
        : window.i18n.t("leaderboard.show_real_username");
      this.showModal({
        title: "",
        content: message,
        btnCancelLabel: window.i18n.t("common.action.no"),
        btnConfirmLabel: window.i18n.t("common.action.yes"),
        onConfirm: () => {
          this.updateLeaderboard();
        },
        onCancel: () => {
          this.enableLeaderboard = !this.enableLeaderboard ? 1 : 0;
        },
      });
    },
    updateLeaderboard() {
      let params = {
        use_fake_name: !this.enableLeaderboard ? 0 : 1,
      };
      rf.getRequest("UserRequest")
        .activeLeaderBoard(params)
        .then((res) => {
          this.localShowFakeName = res.data;
        })
        .catch((err) => {
          this.localShowFakeName = 0;
        });
    },
    showSuccess(message) {
      Message.success(message, {}, { position: "bottom_left" });
    },
    getSocketEventHandlers() {
      return {
        OtpUpdated: this.getSecuritySettings,
      };
    },
    verifyBankAccount(status) {
      rf.getRequest("UserRequest")
        .updateBankAccountStatus(status)
        .then((res) => {
          this.getUserKyc();
        });
    },
    verifyBankModal() {
      if (this.kyc.status !== KYC_VERIFIED) {
        this.showModal({
          type: "warning",
          title: "Error",
          content: this.$t("refferal.alert_bank_account_content"),
          noAction: true,
        });
        return;
      }
      this.showModal({
        type: "confirm",
        title: this.$t("refferal.confirm_bank_account_title"),
        customContent: true,
        onConfirm: () => {
          this.verifyBankAccount({
            status: this.isCreateNewBankAccount ? BANK_CREATING : BANK_VERIFING,
          });
        },
      });
    },
    showModal(config) {
      const type = config.type;
      const title = config.title;
      const content = config.content;
      const customContent = config.customContent;
      let btnCancelLabel = config.btnCancelLabel || this.$t("common.action.no");
      let btnConfirmLabel =
        config.btnConfirmLabel || this.$t("common.action.yes");
      if (config.noAction) {
        btnCancelLabel = null;
        btnConfirmLabel = null;
      }
      const onConfirm = config.onConfirm;
      const onCancel = config.onCancel;
      window.ConfirmationModal.show({
        type: type,
        title: title,
        content: content,
        customContent: customContent,
        btnCancelLabel: btnCancelLabel,
        btnConfirmLabel: btnConfirmLabel,
        onConfirm: onConfirm,
        onCancel: onCancel,
      });
    },
    onClickedVerifyGoogleAuthen(pathRoute) {
      if (!this.submittableKyc) {
        Message.error(
          this.$t("account.otp.should_enable_first"),
          {},
          { position: "bottom_left" }
        );
        return;
      }
      // this.$router.push({ path: pathRoute });
    },
    activeAmlPay() {
      if (this.enableAmalFromAdmin) {
        let param = {
          active: !this.enableAmlPay ? 1 : 0,
        };
        rf.getRequest("UserRequest")
          .changeAmlPay(param)
          .then((res) => {});
      }
    },
    getUserDevice(params) {
      const promise = rf.getRequest("UserRequest").getUserDevice(params);
      return promise;
    },
    onDatatableFinished() {
      if (!this.$refs.datatableDevice || !this.$refs.datatableDevice.rows) {
        return;
      }
      window._.each(this.$refs.datatableDevice.rows, (item) => {
        item.isActiveRow = false;
      });
    },
    activeRow(item) {
      item.isActiveRow = !item.isActiveRow;
      this.$forceUpdate();
    },
    refreshDevice() {
      this.$refs.datatableDevice.refresh();
    },
    confirmRemoveDevice(deviceId) {
      window.ConfirmationModal.show({
        type: "primary",
        title: "",
        content: this.$t("account.modal.confirm_trusted_device"),
        btnCancelLabel: window.i18n.t("common.action.no"),
        btnConfirmLabel: window.i18n.t("common.action.yes"),
        onConfirm: () => {
          this.removeDevice(deviceId);
        },
        onCancel: () => {},
      });
    },
    removeDevice(deviceId) {
      rf.getRequest("UserRequest")
        .deleteDevice(deviceId)
        .then((res) => {
          this.refreshDevice();
          Message.success(window.i18n.t("address.deleted_success"));
        });
    },

    getMasterData() {
      window._.each(this.masterdata.settings, (item) => {
        this.siteSetting[item.key] = item.value;
      });
      this.coinsMasterData = window._.keyBy(
        this.masterdata.coins,
        (item) => item.coin
      );
    },

    loadBlockpassWidgets(id) {
      if (id) {
        const blockpass = new window.BlockpassKYCConnect(this.kyc_key, {
          refId: id,
          mainColor: "00DDB3",
          elementId: "kyc-connect",
          auto: 0,
        });
        blockpass.startKYCConnect();
      }
    },
    getCoinName(coin) {
      let coinName = "";
      if (this.coinsMasterData && this.coinsMasterData[coin]) {
        coinName = this.coinsMasterData[coin].name;
      }
      return coinName;
    },
    formatCurrencyRoundDown(currency, value) {
      let numberOfDecimalDigits =
        currency && currency.toLowerCase() === "usd"
          ? Const.NUMBER_OF_DECIMAL_DIGITS_USD
          : Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;

      let newValue = new BigNumber(value);
      return newValue
        .round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR)
        .toString();
    },
    sumBalanceBy(attr) {
      let total = new BigNumber(0);
      for (let item of this.dataBalance) {
        if (item[attr] == "NaN" || new BigNumber(item[attr]).isZero()) {
          continue;
        }
        total = total.add(item[attr]);
      }
      return total.toString();
    },
    convertToBtcValue(coin, value) {
      let balance = value;
      if (new BigNumber(balance).isZero()) return 0;

      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) return 0;

        return new BigNumber(`${balance}`)
          .div(this.prices[pair].price)
          .toString();
      }
      return new BigNumber(`${balance}`)
        .mul(this.prices[pair].price)
        .toString();
    },
    convertBtcToCoinValue(coin, value) {
      let balance = value;
      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) return 0;
        return new BigNumber(`${balance}`)
          .mul(this.prices[pair].price)
          .toString();
      }
      return new BigNumber(`${balance}`)
        .div(this.prices[pair].price)
        .toString();
    },
    addPricesInBtc() {
      this.prices["btc_btc"] = { price: 1 };
      for (const fiat of Const.FIAT_LIKE_LIST) {
        const fiatPrice = this.prices[`${fiat}_btc`];
        const checkNullPrice =
          fiatPrice && !new BigNumber(fiatPrice.price).isZero();
        const price = checkNullPrice
          ? new BigNumber("1").div(fiatPrice.price).toString()
          : "0";
        this.prices[`btc_${fiat}`] = { price };
      }
    },
    convertCoins(fromCoin, toCoin, value) {
      // Example:
      // If do not have price of usd_$coin pair, transform to btc_$coin pair then transform to usd_btc pair.
      let btcValue = this.convertToBtcValue(fromCoin, value);
      let newValue = this.convertBtcToCoinValue(toCoin, btcValue);

      return newValue;
    },
    onUpdateBalance(dataBalance) {
      const data = [];
      const coinList = dataBalance[Const.TYPE_MAIN_BALANCE];
      const mainBalanceData = dataBalance[Const.TYPE_MAIN_BALANCE];
      // const marginBalanceData = dataBalance[Const.TYPE_MARGIN_BALANCE];
      const marginBalanceData = this.balanceFutures.assets;
      const exchangeBalanceData = dataBalance[Const.TYPE_EXCHANGE_BALANCE];
      const mamBalanceData = dataBalance[Const.TYPE_MAM_BALANCE];
      const airdropBalanceData = dataBalance[Const.TYPE_AIRDROP_BALANCE];

      for (let coin in coinList) {
        const item = {};

        item.coin = coin;
        item.name = this.getCoinName(coin);
        item.balance =
          (mainBalanceData &&
            mainBalanceData[coin] &&
            mainBalanceData[coin]?.balance) ||
          0;
        item.availableBalance =
          (mainBalanceData &&
            mainBalanceData[coin] &&
            mainBalanceData[coin]?.available_balance) ||
          0;
        item.exchangeBalance =
          (exchangeBalanceData &&
            exchangeBalanceData[coin] &&
            exchangeBalanceData[coin]?.balance) ||
          0;
        item.airdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin]?.balance_bonus) ||
          0;
        item.availableAirdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin]?.available_balance_bonus) ||
          0;
        item.exchangeAvailabelBalance =
          (exchangeBalanceData &&
            exchangeBalanceData[coin] &&
            exchangeBalanceData[coin]?.available_balance) ||
          0;
        item.marginBalance =
          (marginBalanceData &&
            marginBalanceData[coin] &&
            marginBalanceData[coin]?.balance) ||
          0;
        item.marginAvailabelBalance =
          (marginBalanceData &&
            marginBalanceData[coin] &&
            marginBalanceData[coin]?.available_balance) ||
          0;
        item.mamBalance =
          (mamBalanceData &&
            mamBalanceData[coin] &&
            mamBalanceData[coin]?.balance) ||
          0;
        item.airdropBalance =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin]?.balance) ||
          0;
        item.totalBalance = new BigNumber(item.balance)
          .add(item.exchangeBalance)
          .add(item.marginBalance);
        let coinType = item.coin || "";
        // if (coinType == Const.BTC) {
        //   item.totalBalance = new BigNumber(item.totalBalance).add(item.marginBalance)
        // }
        if (coinType == Const.AML) {
          item.totalBalance = new BigNumber(item.totalBalance)
            .add(item.marginBalance)
            .add(item.airdropBalance)
            .add(item.airdropBalanceBonus);
        }
        item.totalBalance = this.formatCurrencyRoundDown(
          coin,
          item.totalBalance.toString()
        );
        item.btcValue = this.convertToBtcValue(coin, item.totalBalance);

        let usdValue = this.convertCoins(Const.BTC, Const.USD, item.btcValue);
        if (!usdValue) {
          const pair = "usd_btc";
          const price = this.prices[pair] ? this.prices[pair].price : "0";
          usdValue = new BigNumber(item.btcValue).mul(price).toString();
        }
        item.usdValue = usdValue;

        data.push(item);
      }
      this.dataBalance = window._.sortBy(data, "name");
    },
    getBalanceFutureAPI() {
      return rf
        .getRequest("UserRequest")
        .getFuturesBalances()
        .then((res) => {
          this.assetsWallet = res.data;
          const assetsKeyObject = {};
          res.data.forEach((asset) => {
            assetsKeyObject[asset.asset.toLowerCase()] = {
              balance: asset?.balance,
              marginBalance: 0,
              availableBalance: 0,
              totalAllowcated: 0,
              unrealisedPnl: 0,
              unrealisedROE: "",
            };
          });
          this.balanceFutures = {
            assets: assetsKeyObject,
          };
        });
    },
    getPriceCoinGecKotoBTC() {
      for (const coin in this.coinsMasterData) {
        const pairValue = `btc_${this.coinsMasterData[coin].coin}`;
        if (this.prices[pairValue] === undefined && coin !== "usd") {
          rf.getRequest("TransactionRequest")
            // .getPriceToBTC({symbol: coin === 'sol' ? 'Solana' : coin})
            .getPriceToBTC({ symbol: coin })
            .then((res) => {
              this.prices[pairValue] = { price: res?.data || 0 }; // Set the price object
            })
            .catch((error) => {
              // Handle the error if needed
            });
        }
      }
    },
  },
  async created() {
    this.fetching = true;
    await rf
      .getRequest("PriceRequest")
      .getPrices()
      .then((res) => {
        this.prices = { ...this.prices, ...res.data };
        this.addPricesInBtc();
        this.getPriceCoinGecKotoBTC();
      });
    rf.getRequest("UserRequest")
      .getBalance(false)
      .then((res) => {
        this.onUpdateBalance(res.data);
      });
    this.loadDataImmediately();
    await this.getBalanceFutureAPI();
    document.title = this.$t("menu.account") + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.loader {
  display: block;
  margin: auto;
  border-radius: 50%;
  border-top: 5px solid $color-blue-custom;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

#setting_dashboard{
  padding: 24px 40px;
  @media screen and (max-width: 500px) {
    padding: 24px 20px;
    padding-top: 0px;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.title-medium-small {
  color: var(--dark-1);
  font-family: $font-notosans-bold;
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: none;
}
.account_container {
  display: inline-block;
  width: 100%;
  padding: 0px;
  background-color: var(--background-color-primary-2);
}
.path_acount_container {
  display: flex;
  margin-top: 4px;
  gap: 4px;
  background-color: var(--color-black);
}

.submit_contact {
  font-size: $font-small;
  color: $color-yellow;
  height: 20px;
  margin-top: 10px;
  text-align: center;
  padding-left: 52px;
  div {
    height: 25px !important;
  }
}
.submit {
  width: 200px;
  color: $color-curious-blue;
}
.contact_us {
  width: 100px;
  color: $color-curious-blue;
  margin-right: 18px;
}
.h-18 {
  height: 18px;
}
.road_lv {
  .item_road {
    text-align: center;
    &:last-child {
      .icon-diamond-1 {
        margin-right: 0px;
        text-align: right;
      }
    }
    &:first-child {
      .icon-diamond-1 {
        margin-left: 0px;
        text-align: center;
      }
    }
    .text_milestone {
      font-size: $font-small;
      color: $color-grey-dusty;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 15px;
      .nb_read_item {
        color: $color-grey-dark;
        font-size: $font-root;
        font-weight: 600;
      }
    }
  }
}
.milestone {
  width: 100%;
  border-bottom: 6px solid var(--color-dark-5);
  @media screen and (max-width: 576px) {
    border-bottom: 2px solid var(--color-dark-5);
  }
}
.icon-diamond-1 {
  margin: auto;
  text-align: center;
  font-size: $font-big-20;
  position: relative;
  padding-bottom: 16px;

  .widthdraw_limit {
    font-family: $font-inter-bold;
    color: var(--text-primary-color);
    font-size: 12px;
    line-height: 16px;
    padding-top: 12px;
    @media screen and (max-width: 380px) {
      height: 40px;
    }
  }
  .icon-diamond::before {
    color: var(--text-desc);
  }
  &.step_active {
    color: $color-aqua-green;
    &:after {
      background: $color-aqua-green;
      content: "";
      display: block;
      width: 100%;
      height: 6px;
      position: absolute;
      left: 0px;
      bottom: -6px;
    }
  }
  .level {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
    .text {
      font-family: $font-inter-bold;
      color: var(--text-desc);
      font-size: 20px;
      line-height: 24px;
    }
  }
  &.step_active {
    .level {
      .text,
      .icon-diamond::before {
        color: $color-aqua-green;
      }
    }
    &:after {
      @media screen and (max-width: 380px) {
        height: 2px;
        bottom: -2px;
      }
      height: 6px;
      bottom: -6px;
    }
  }
}
.right_list_option_acc {
  background-color: var(--background-color-primary-2);
  flex: 1;
  .contents_setting {
    min-height: calc(100vh - 50px);
    margin-top: 40px;
    .contents_setting_container {
      display: flex;
      gap: 28px;
      &.setting_border-bottom {
        border-bottom: 2px solid $color-white-alto;
      }
      .text-main {
        font-family: $font-inter-bold;
        font-size: 20px;
        line-height: 24px;
        color: var(--dark-1);
      }
      .contents_setting_icon {
        width: 52px;
      }
      .preferences_group {
        flex: 1;
        .check_authen_activation {
          font-family: $font-inter-regular;
          font-size: 16px;
          line-height: 20px;
          color: var(--dark-2);
        }
        .group-text-main {
          margin-bottom: 43px;
        }
        .group {
          margin-bottom: 30px;
          .group_center_option {
            display: flex;
            .text-mb {
              margin-bottom: 5px;
            }
            .center_group {
              flex: 1;
            }
            .group_btn {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .group_status {
                display: flex;
                gap: 10px;
                align-items: flex-start;
                .text-status {
                  font-family: $font-inter-regular;
                  font-size: 18px;
                  line-height: 21px;
                  color: var(--dark-1);
                  max-width: 245px;
                }
              }
              .group_btn-btn {
                .btn {
                  font-family: $font-inter-regular;
                  border-radius: 8px;
                  padding: 14px 20px;
                  font-size: 18px;
                  line-height: 21px;
                  min-width: 140px;
                  &.btn_disable {
                    color: var(--dark-1);
                    background: $color-gray-alto;
                  }
                  &.btn_enable {
                    color: $color-white;
                    background: $text-color-jade;
                  }
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 500px) {
      margin-top: unset;
    }
  }
}
.link_account {
  font-size: $font-small;
  font-weight: 500;
  margin: 0px;
  margin-top: 6px;
  margin-bottom: 20px;
  color: $color-grey-dusty;
  line-height: 20px;
  display: block;
  a {
    color: $color-curious-blue;
    &:hover {
      text-decoration: none;
    }
  }
}
.link_button {
  color: $color-curious-blue;
}

.right_withdrawal_acc {
  display: block;
  width: calc(100% - 110px - 70px - 15px);
  position: relative;
  line-height: 20px;
  font-size: $font-small;
  color: $color-grey-dusty;
}
.switch {
  margin-bottom: 0px;
  input:checked + span {
    background-color: $color-jungle-green;
  }
  span {
    background-color: #d8d8d8;
  }
}
.icon_if_w {
  width: 18px;
  height: 18px;
  font-size: $font-big;
  color: $color-alizarin-crimson;
}
.btn_active {
  height: 35px;
  max-width: 100%;
  width: 246px;
  margin-right: 15px;
  text-align: center;
  background-color: $color-jungle-green;
  border-radius: 3px;
  font-weight: 600;
  color: $color-white;
  font-size: $font-smaller;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px 0px;
  line-height: 20px;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom;
    border-color: $color-blue-custom;
    outline: none;
  }
}
.no-shadow {
  i {
    box-shadow: none !important;
  }
}
.link_referral {
  a {
    text-decoration: none !important;
  }
}
.mgb0 {
  margin-bottom: 0px;
}

@media screen and (max-width: 992px) {
  #setting_dashboard {
    .right_list_option_acc .contents_setting {
      //padding: 30px 16px;
      .title-medium-small {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
      }
      .d-show {
        display: block;
      }
      .acount_info {
        .acc_info {
          padding-bottom: 20px;
          gap: 16px;
          .img-user {
            img {
              width: 50px;
              height: 50px;
            }
          }
          .sub_acc_info {
            flex: 1;
            .email_acc {
              font-size: 16px;
              line-height: 19px;
            }
            .label-userID,
            .text-userID {
              font-size: 12px;
              line-height: 16px;
            }
            .lv_content {
              gap: 12px;
              -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
              .kyc_level {
                .status {
                  font-size: 14px;
                  line-height: 16px;
                }
              }
              .kyc_status,
              .level,
              .time_account {
                font-size: 12px;
                line-height: 16px;
              }
              .kyc_verify {
                max-width: 167px;
              }
            }
          }
        }
        .acc_info_level {
          .acc_info_balance {
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            gap: 30px;
            .blance_left .estimate_note_wrap {
              max-width: 256px;
              width: 100%;
            }
          }
          .text_milestone.dp-none {
            display: none;
          }
          .text-label {
            font-family: $font-inter-regular;
            font-size: 14px;
            line-height: 16px;
            color: var(--text-desc);
          }
          .nb_read_item {
            margin-top: 4px;
            margin-bottom: 20px;
            font-family: $font-inter-bold;
            font-size: 20px;
            line-height: 24px;
            color: var(--text-primary-color);
          }
          .text_estimate {
            font-size: 14px;
            line-height: 16px;
          }
          .number_balance {
            .value,
            .sub_value {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }
      .blance_right {
        .button_group {
          gap: 16px;
          margin-bottom: 30px;
        }
        .overview {
          i:before {
            font-size: 10px;
          }
          .link {
            .text_overview {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
    .icon-diamond-1 {
      padding-bottom: 8px;
    }
    .icon-diamond-1 .level .text {
      font-size: 14px;
      line-height: 19px;
    }
    .icon-diamond-1 .level.left {
      justify-content: center;
    }
    .icon-diamond-1 .level.right {
      justify-content: center;
    }
  }
  .acount_info .acc_info_level .acc_info_balance .blance_left .wrap_estmate {
    align-items: center;
    img {
      width: 14px;
      height: 8px;
    }
  }
}
@media screen and (max-width: 576px) {
  .acount_info .acc_info_level .acc_info_balance .blance_right .overview {
    justify-content: flex-start;
  }
}
</style>
