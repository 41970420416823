import BigNumber from 'bignumber.js';
import store from '@/store/index.js';

// function getCurrentInstrument() {
//   return store.getters.currentInstrument;
// }

const currentInstrument = () => store.getters.currentInstrument;

export default {
  getMultipler: function() {
    return currentInstrument().multiplier;
  },

  getMarkPrice: function() {
    return currentInstrument().mark_price;
  },

  getInitMarginRatio: function() {
    return new BigNumber(`${currentInstrument().init_margin || 0}`).toString();
  },

  getMaintMarginRatio: function() {
    return new BigNumber(`${currentInstrument().maint_margin || 0}`).toString();
  },

  getTakerFee: function() {
    return new BigNumber(`${currentInstrument().taker_fee || 0}`).toString();
  },

  getFundingRate: function() {
    return new BigNumber(`${currentInstrument().funding_rate || 0}`).toString();
  },

  getMaxQuanity: function() {
    return new BigNumber(`${currentInstrument().max_order_qty || 0}`).toString();
  },
}