<template>
  <div class = "row-airdrop" id="mam-transfer-history">
    <span class="box-title title-medium-small box-title-secord">{{ $t('mam.transfer_history') }}</span>

  <div class="row-search is_single_pag">
      <div class="group-date ">
          <span class="date-title">{{ $t('funds.history.from') }}</span>
          <datepicker
          :value="fromDate"
          class="date-from" @selected="selectDateFrom" :format="customFormatter" input-class="date-picker-input"></datepicker>
           <span class="date-title">{{ $t('funds.history.to') }}</span>
          <datepicker
          :value="toDate"
          class="date-to" @selected="selectDateTo" :format="customFormatter" input-class="date-picker-input"></datepicker>
      </div>
      <!-- <select-box
        :options="reasonOption"
        v-model="searchReason"
        :placeholder="$t('mam.reason')"
      /> -->
    <div class="btn-group">
       <button class="button-search" @click="actionSearch">{{$t('common.search')}}</button>
       <button class="button-search" @click="actionReset">{{$t('common.reset')}}</button>
    </div>
  </div>
    <div class="table-container table-history">
      <data-table :getData="getData" ref="datatable" :msgEmptyData="noHistory" :limit="4">
        <th style="width:20%" data-sort-field="created_at" @click="FixSortIE = !FixSortIE">
          {{ $t('salespoint.buy_history.time') }}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
        </th>
        <th style="width:20%" data-sort-field="amount" @click="FixSortIE = !FixSortIE">
          {{$t('salespoint.buy_history.amount')}}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
        </th>
        <th style="width:20%" data-sort-field="master_id" @click="FixSortIE = !FixSortIE">
          {{$t('mam.fund_id')}}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
        </th>
        <th style="width:40%" @click="FixSortIE = !FixSortIE">
          {{$t('mam.reason')}}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
        </th>
        <template slot="body" slot-scope="props">
          <tr>
            <td>{{ props.item.created_at | convertToLocalTime }}</td>
            <td>{{ props.item.amount | formatDigitsNumber(10) | filterZeroValue }} BTC</td>
            <td>{{ props.item.master_id }}</td>
            <td>{{ convertReason(props.item.reason) }}</td>
          </tr>
        </template>
      </data-table>
    </div>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import Const from '@/common/Const';
  import SelectBox from '@/components/shared_components/common/SelectBox';
  import Datepicker from 'vuejs-datepicker';
  import moment from 'moment';
  import Utils from '@/common/Utils'

  export default {
    components: {
      SelectBox,
      Datepicker
    },
     data() {
      return {
        noHistory: window.i18n.t('mam.transfer_history.nodata'),
        FixSortIE:null,
        searchReason:"",
        fromDate: moment().subtract(6, 'days').startOf('day').toDate(),
        toDate: moment().endOf('day').toDate(),
        reasonOption:[
          {name: window.i18n.t('mam.reason.search.join'),id:'join'},
          {name: window.i18n.t('mam.reason.search.assign'),id:'assign'},
          {name: window.i18n.t('mam.reason.search.revoke'),id:'revoke'},
          {name: window.i18n.t('mam.reason.search.reject'),id:'reject'},
          {name: window.i18n.t('mam.reason.search.cancel'),id:'cancel'},
          {name: window.i18n.t('mam.reason.search.commission'),id:'commission'},
          {name: window.i18n.t('mam.reason.search.closed'),id:'closed'},
          {name: window.i18n.t('mam.reason.search.created'),id:'created'},
        ]
      }
    },
    watch:{
    },
    methods: {
      convertReason (reason) {
        if(!reason) return ''
        switch (reason) {
          case 'join':
            return window.i18n.t('mam.reason.join');
          case 'assign':
            return window.i18n.t('mam.reason.assign');
          case 'revoke':
            return window.i18n.t('mam.reason.revoke');
          case 'reject':
            return window.i18n.t('mam.reason.reject');
          case 'cancel':
            return window.i18n.t('mam.reason.cancel');
          case 'commission':
            return window.i18n.t('mam.reason.commission');
          case 'expired':
            return window.i18n.t('mam.reason.expired');
          case 'closed':
            return window.i18n.t('mam.reason.closed');
          case 'created':
            return window.i18n.t('mam.reason.created');
          default:
            return '';
        }
      },
      customFormatter(date) {
        return moment(date).format('YYYY-MM-DD');
      },
      selectDateFrom (dateFrom) {
        this.fromDate = moment(dateFrom).startOf('day').toDate();
      },
      selectDateTo (dateTo) {
        this.toDate = moment(dateTo).endOf('day').toDate();
      },
      getData (params) {
        const timezoneOffSet = Utils.getTimzoneOffset();
        const from_date = moment(this.fromDate).format('YYYY-MM-DD HH:mm:ss');
        const to_date = moment(this.toDate).format('YYYY-MM-DD HH:mm:ss');
        return rf.getRequest("MamRequest").getTransferHistory({...params,to_date,from_date,timezoneOffSet});
      },
      actionSearch(){
        if(this.fromDate > this.toDate) {
          this.showError(window.i18n.t('common.date.warning'));
          return false;
        }
        this.$refs.datatable.refresh();
      },
      actionReset(){
        this.fromDate = moment().subtract(6, 'days').toDate();
        this.toDate = moment().toDate(),
        this.$refs.datatable.refresh();
      },
      showError(message) {
        Message.error(message, {}, { position: "bottom_left" });
      },
    },
    filters:{
      filterZeroValue(value){
        if(value === "--"){
          return 0;
        }
        return value;
      },
    },
    created() {
    },
    mounted() {
    },
  }
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.row-search{
  margin-bottom: 15px;
  display: flex;
  .button-search{
    margin-left: 15px;
    background-color: $color-jungle-green;
    color: $color-white;
    box-shadow: none;
    font-family: $font-family-roboto-bold;
    border: 1px solid $color-jungle-green;
    border-radius: 3px;
    width: 85px;
    height: 32px;
    font-size: $font-smaller;
    float: left;
    -webkit-transition: 0.5s;
    transition: 0.5s;
      letter-spacing: 0.9px;
    text-transform: uppercase;
    &:hover{
      background: $color-eden;
      border-color: $color-eden;
      color: $color-white;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }

  .group-date{
    display: inline-block;
    position: relative;
    float: left;
    .vdp-datepicker{
      display: inline-block;
      input{
        padding: 5px 3px 5px 40px !important;
        background-position: top 8px left 14px !important;
        background-color: $color-white-smoke !important;
        margin-right: 0px !important;
        &:focus {
          border: 1px solid $color-jungle-green;
        }
      }
      &.date-from {
        input {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
      &.date-to {
        input {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
    .date-to{
      margin-left: -5px;
    }
  }

  .input-search {
  width: 200px;
  height: 32px;
  border: 1px solid $color-gray;
  border-radius: 3px;
  background-color: $color-white;

  &:focus-within {
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
  }

  input {
    background: $color-white;
    font-size: $font-small;
    color: $text-grey;
    font-weight: normal;
    border: none;
    width: calc(100% - 37px);
    height: 30px;
    padding-left: 0px;
    float: left;
    line-height: 20px;
    padding-top: 5px; 
    padding-bottom: 4px; 

    &:focus {
      outline: none;
    }
  }

  .btn-search {
    font-size: $font-title-size-small;
    font-weight: bold;
    display: block;
    text-align: center;
    width: 35px;
    height: 32px;

    .icon-search {
      font-size: $font-big-17;
      line-height: 31px;
    }
  }
  }
}
#balance{
  &.is_no_single_page{
    .balance-container {
      margin: 0;
      .table-container thead th{
        height: 22px;
        border-bottom: 1px solid $color-gray;
        font-size: $font-mini;
      }
    }
  }
  table {
    tbody {
      tr {
        overflow-y: initial;
      }
    }
  }
}
.balance-container {
  display: inline-block;
  width: 100%;
  .row-airdrop {
    margin-top: 35px;
    margin-bottom: 15px;
    .estimate-value-airdrop{
      padding-top: 15px;
      font-family: Roboto-Regular;
      font-size: 13px;
      color:$color-grey-dark;
    }
  }
  .box-title {
    margin-bottom: $title-bottom-margin;
    &.box-title-secord{
      margin-top : 35px;
    }
  }
}
.balance-info {
  line-height: 25px;
  width: 180px;
  font-weight: 100;
  font-size: $font-small;
  padding-right: 5px;
  padding-left: 5px;
  position: absolute;
  background: $background-default;
  border: $border_thin;
  box-shadow: 0 2px 10px 0 $border-color-grey;
  border-top: none;
  z-index: 10;
  padding: 5px 10px;
}
label,
input {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: $color-grey-dark;
}
.sort_up{
  color: $color-corn-pale !important;
}
.sort_down{
  color: $color-corn-pale !important;
}
.es-toolbar {
  font-size: $font-root;
  margin-bottom: 20px;
  .icon {
    color: $color-grey-dusty;
  }
  .btn-small-icon {
    width: 150px;
    height: 32px;
    line-height: 31px;
    border: 1px solid $color-gray;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 3px;
    .icon-help {
      font-size: $font-medium;
      margin-right: 5px;
      position: relative;
      top: 1px;
    }
  }
  .btn-checkbox {
    width: 70px;
    height: 32px;
    line-height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 3px;
    .checkbox-input{
      width: 12px;
      height: 12px;
      margin-right: 5px;
      position: relative;
      input{
        display: none;
      }
      label{
        cursor: pointer;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        left: 0;
        border:1px solid $color-grey-dusty;
        border-radius: 1px;
        &:after{
          opacity: 0;
          content: '';
          position: absolute;
          width: 12px;
          height: 5px;
          background: transparent;
          top: 0px;
          left: 0px;
          border: 3px solid $color-grey-dusty;
          border-top: none;
          border-right: none;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
      input[type=checkbox]:checked + label:after {
        opacity: 1;
      }
    }
  }
  .btn-convert-icon {
    cursor: pointer;
    border-radius: 3px;
    min-width: 150px;
    height: 32px;
    line-height: 31px;
    border: 1px solid $color-alto;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 3px;
    margin-right: 10px;
    span {
      vertical-align: middle;
    }
    .icon-convert {
      font-size: $font-title-size-small;
      margin-right: 5px;
    }
    &:hover {
      color: $color-white;
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      .icon {
        color: $color-white;
      }
    }
  }
  .input-search {
    width: 170px;
    height: 32px;
    border: 1px solid $color-gray;
    border-radius: 3px;
    background-color: $color-white;
    &:focus-within {
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
    }
    input {
      background: $color-white;
      font-size: $font-small;
      color: $text-grey;
      font-weight: normal;
      border: none;
      width: calc(100% - 37px);
      height: 30px;
      padding-left: 0px;
      float: left;
      &:focus {
        outline: none;
      }
    }
    .btn-search {
      font-size: $font-title-size-small;
      font-weight: bold;
      display: block;
      text-align: center;
      width: 35px;
      height: 32px;
      .icon-search {
        font-size: $font-big-17;
        line-height: 31px;
      }
    }
  }
  .estimate-value {
    color: $color-grey-dusty;
    font-size: $font-small;
    text-align: right;
    margin-top: -9px;
    .total {
      font-weight: normal;
      margin-bottom: 8px;
      line-height: 16px;
      .number-value{
        color: $color-grey-dark;
        font-size: $font-root;
        font-family: $font-family-roboto-bold;
      }
    }
    .limit{
      font-weight: normal;
      line-height: 16px;
      span{
        &:first-child{
          margin-right: 3px;
        }
      }
    }
  }
}
.table-container {
  background: $color-white;
}
 .icon-coin {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
}
.group_button_tooltip {
  display: inline-block;
  float: left;
  position: relative;
  // width: 23px;
  .btn-deposit-tooltip {
    display: inline-block;
    float: right;
    height: 23px;
    overflow: hidden;
    width: auto;
    padding: 1px 5px;
    border-radius: 11.5px;
    font-size: 20px;
    font-weight: 600;
    border: 0px;
    i.icon-signal2 {
      color: $color-alizarin-crimson;
      position: relative;
      top: 2px;
      margin-right: 2px;
    }
  }
  .tooltip_hover {
    position: absolute;
    width: 165px;
    line-height: 20px;
    padding: 16px 14px 14px 19px;
    color: $color-grey-dark;
    font-size: $font-root;
    font-weight: 500;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
    background-color: $color-white;
    border-radius: 3px;
    right: 100%;
    margin-right: 2px;
    top: -50%;
    margin-top: -7px;
    z-index: 5;
    display: none;
    text-align: left;
    white-space: normal;
    font-family: $font-family-roboto-medium;
  }
  &:hover {
    .tooltip_hover {
      display: block;
    }
  }
}
#balance {
  &.page_container {
    .balance-container {
      .table-container {
        th {
          line-height: 20px;
          padding: 20px 0px 10px 20px;
          font-size: $font-small;
          font-weight: 500;
          color: $color-grey;
          font-family: $font-family-roboto-medium;
          &.after_none:after {
            display: none;
          }
          &.coin_balances {
            padding-left: 53px;
          }
          &[data-sort-order="asc"], &[data-sort-order="desc"] {
            color: $color-jungle-green;
          }
        }
        tr {
          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            padding: 7px 0px 6px 20px;
            overflow: visible;
            vertical-align: middle;
            &.coin_balances {
              padding-left: 20px;
            }
            &:last-child {
              text-align: left;
            }
          }
          &:hover {
            background-color: $color-bright-gray;
            td {
              .icon_balances {
                color: $color-eden;
              }
              .btn-lose-color {
                .icon_balances {
                  color: $color-grey;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
      }
    }
  }

}
</style>

<style lang="scss">
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss";

#mam-transfer-history{
  .vdp-datepicker__calendar{
    right: auto;
    left: 0px;
    
  }      
  .vdp-datepicker{
    input{
      padding: 5px 3px 5px 40px !important;
      background-position: top 8px left 14px !important;
      background-color: $color-white-smoke !important;
      margin-right: 0px !important;
      &:focus {
        border: 1px solid $color-jungle-green;
      }
    }
    &.date-from {
      margin-right: 5px;
      margin-left: 5px;
      input {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
    &.date-to {
      margin-left: 5px;
      input {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}

</style>