<template>
  <div>
    <Preloader />
    <router-view v-if="typeStart === 'done'"></router-view>
    <div v-if="typeStart === 'disable'" class="web-block">
      <img :src="require(`@/assets/images/web-block.svg`)" />
    </div>
  </div>
</template>
<script>
import rf from "@/request/RequestFactory";
import Preloader from "./components/shared_components/common/Preloader.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Preloader,
  },
  data() {
    return {
      is_disable: false,
      typeStart: "",
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLang",
    }),
  },
  watch: {
    lang() {
      this.setLanguageValidate(this.lang);
    },
  },
  created() {
    rf.getRequest("MasterdataRequest")
      .getAll()
      .then((res) => {
        const coinSettings = res?.coin_settings.filter((p) => p?.is_enable);
        res.coin_settings = coinSettings;
        this.$store.commit("setMasterdata", res);
      });
    rf.getRequest("MasterdataRequest")
      .getLocationInfo()
      .then((res) => {
        this.$store.dispatch("setLoadingPage", true);
        const is_block = res?.data?.is_block;
        if (
          is_block === 1 
        ) {
          // this.is_disable = true
          this.typeStart = "disable";
        } else {
          this.typeStart = "done";
        }
        this.$store.dispatch("setLoadingPage", false);
      })
      .catch((error) => {
        this.typeStart = "disable";
        this.$store.dispatch('setLoadingPage', false)
      });
    this.setLanguageValidate(this.lang);
  },
  methods: {
    setLanguageValidate(lang) {
      const require = { required: this.$t("account.identity.errors.required") };
      const dict = {
        custom: {
          email: {
            ...require,
            email: this.$t("account.email.required"),
          },
          password: require,
          new_password: require,
          new_password_confirm: require,
          "confirm password": {
            ...require,
            confirmed: this.$t("change_password_form.error_confirm"),
          },
          code: {
            ...require,
            length: this.$t("funds.withdrawals.errors.otp_length_6_digits"),
          },
          otp_recovery_code: require,
          confirmNewCode: require,
          newCode: require,
        },
      };
      this.$validator.localize(lang, dict);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/main.scss";
//::-webkit-scrollbar-thumb {
//   background: var(--background-progress);
//   border-radius: 10px;
// }
body {
  margin: 0;
}
.clearfix::before,
.row::before {
  display: none;
}
.clearfix::after .row::after {
  display: none;
}
.web-block {
  width: 100vw;
  height: 100vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
