<template>
  <div class="item-tab">
    <data-table3
      ref="datatable"
      :get-data="getData"
      :table-no-data="true"
      id="data-table-landing"
      class="table-landing"
      :msgEmptyData="$t('common.datatable.no_have_data')"
    >
      <template slot-scope="props">
        <th
          data-sort-field="coin"
          v-if="isVisibleColumn('pair')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.pair") }}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="full_name"
          class="col-coin"
          v-if="isVisibleColumn('coin')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.coin") }}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="current_price"
          class="col-last-price"
          v-if="isVisibleColumn('current_price')"
          @click="props.echoclick"
        >
          <span v-if="!isSportExchange">{{ $t("landing.last_price") }}</span>
          <span v-else>{{ $t("landing.price") }}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="changed_percent"
          class="col-24h-changed"
          v-if="isVisibleColumn('changed_percent')"
          @click="props.echoclick"
        >
          <span v-if="!isSportExchange">{{ $t("landing.change") }}</span>
          <span v-else>{{ $t("landing.chart.change") }}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="max_price"
          class="col-max-price"
          v-if="isVisibleColumn('max_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.high") }}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="min_price"
          class="col-min-price"
          v-if="isVisibleColumn('min_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.low") }}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="quote_volume"
          class="col-volume text-right"
          v-if="isVisibleColumn('quote_volume')"
          @click="props.echoclick"
        >
          <span v-if="!isSportExchange">{{ $t("landing.volume") }}</span>
          <span v-else>{{ $t("table.volume") }}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th v-if="isSwitchPercentAndVolumn">
          <div style="margin-left: 8px" v-if="isSwitch" class="icon-arrow-left-right" @click.stop="switchChangeVolume(false)"></div>
          <div style="margin-left: 8px" v-else  class="icon-arrow-left-right" @click.stop="switchChangeVolume(true)"></div>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <!-- <tr> -->
          <td
            class="text-left col-pair"
        
            v-if="isVisibleColumn('pair')"
          >
          
            <button 
              :disabled="isLoadingFavorite ? true : false"
              class="btn-icon-star"
              @click.stop="
                isFavorite(props.item.coin, props.item.currency)
                  ? removeFavorite(props.item.coin, props.item.currency)
                  : insertFavorite(props.item.coin, props.item.currency)
              ">
             <span v-if="!isSportExchange"
              class="icon-star1"
              :class="[
                isFavorite(props.item.coin, props.item.currency)
                  ? 'icon-star2'
                  : 'star_gray',
                   isLoadingFavorite ? 'disabled' : ''
                   ]
              "
              @click.stop="
                isFavorite(props.item.coin, props.item.currency)
                  ? removeFavorite(props.item.coin, props.item.currency)
                  : insertFavorite(props.item.coin, props.item.currency)
              "
            ></span>
            <span v-else
                  class="icon-star2"
                  :style="{color: isFavorite(props.item.coin, props.item.currency)
                  ? '#FFBF0F'
                  : userThemeMode === 'light-theme' ? '#DEDEDE' : '#6D7387'}"
                  :class="[
                isFavorite(props.item.coin, props.item.currency)
                  ? 'icon-star3'
                  : 'star_gray',
                     isLoadingFavorite ? 'disabled' : ''
                     ]
              "
                  @click.stop="
                isFavorite(props.item.coin, props.item.currency)
                  ? removeFavorite(props.item.coin, props.item.currency)
                  : insertFavorite(props.item.coin, props.item.currency)
              "
            ></span>
            </button>
          
            <span  @click="selectedCurrenyPair(props.item.currency, props.item.coin)">
            <span class="coin"> {{ props.item.coin | currencyName }}</span
            ><span class="currency">/{{ props.item.currency | currencyName }}</span>
            </span>
         
            
          </td>
          <!-- <td
            class="col-coin"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('coin')"
          >
            {{ props.item.coin | fullName }}
          </td> -->
          <td
            class="col-last-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.current_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('current_price')"
          >
            <span
              class="change-node"
              :class="calculateLastPriceStatus(props.item)"
            >
              {{
                props.item.current_price
                  | formatCurrencyAmount(props.item.currency, "0")
              }}
            </span>
            <span
              class="is-muted"
              v-if="props.item.currency !== 'usd' && props.item.usdPrice > 0"
            >
             <span v-if="!isSportExchange">/ ${{ props.item.usdPrice | formatCurrencyAmount("usd", "0") }}</span>
            </span>
          </td>
          <td
            class="change-node col-24h-changed"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :class="{
              grown: props.item.changed_percent > 0,
              down: props.item.changed_percent < 0,
              black: props.item.changed_percent == 0,
            }"
            :title="props.item.changed_percent | changedPercent"
            v-if="isVisibleColumn('changed_percent')"
          >
            {{ props.item.changed_percent | changedPercent }}
          </td>
          <td
            class="col-max-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.max_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('max_price')"
          >
            {{
              props.item.max_price
                | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
          <td
            class="col-min-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.min_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('min_price')"
          >
            {{
              props.item.min_price
                | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
          <td
            class="col-volume"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.quote_volume | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('quote_volume')"
          >
            {{
              props.item.quote_volume | formatNumberAndDecimal
            }}
          </td>
        </tr>
      </template>
    </data-table3>
  </div>
</template>

<script>
import FavoritesManager from "@/common/FavoritesManager";
import COMMON_CONST from "@/common/Const";
import BigNumber from "bignumber.js";
import {mapState} from "vuex";

const ALL_COLUMNS = [
  "pair",
  "coin",
  "current_price",
  "changed_percent",
  "max_price",
  "min_price",
  "quote_volume",
];

export default {
  components: {},
  props: {
    priceScope: { type: [Object, Array] },
    columns: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    favorites: { type: [Array] },
    marketSelected: { type: String },
    zone: {},
    isSportExchange: { type: Boolean, default: false},
    isSwitchPercentAndVolumn: { type: Boolean, default: false},
    triggerSortAfterUpdateDataOfSocket: { },
  },
  data() {
    return {
      pricesSorted: {},
      FixSortIE: null,
      isSwitch: false,
      isLoadingFavorite: false
    };
  },
  watch: {
    priceScope: {
      handler() {
        this.pricesSorted = this.priceScope;
      },
      immediate: true,
    },
    pricesSorted() {
      if (this.$refs.datatable) {
        this.$refs.datatable.refresh();
      }
    },
    triggerSortAfterUpdateDataOfSocket() {
      if (this.$refs.datatable) {
        this.$refs.datatable.refresh();
      }
    }
  },
  computed: {
    visibleColumns() {
      if (window._.isEmpty(this.columns)) {
        return ALL_COLUMNS;
      }
      return this.columns;
    },
    ...mapState({
      userThemeMode: state => state.userThemeMode
    })
  },

  methods: {
    calculateLastPriceStatus(item) {
      if (!item.current_price || !item.previous_price) {
        return {
          black: true,
        };
      }

      let compareResult = new BigNumber(item.current_price).comparedTo(
        item.previous_price
      );
      return {
        grown: compareResult > 0,
        down: compareResult < 0,
        black: compareResult == 0,
      };
    },

    switchChangeVolume (from) {
      this.isSwitch = from;
      const sortValue = from? "changed_percent" : "quote_volume"
      const sortType = this.$refs.datatable.sortedBy
      this.$refs.datatable.params.sort = sortValue
      this.$refs.datatable.params.sort_type = sortType
      const thElement = document.querySelector(`th[data-sort-field='${sortValue}']`);
      if(thElement) {
        thElement.setAttribute('data-sort-order', sortType);
      }
      this.$broadcast("SwitchChangeVolumeSpotPairMarket", from);
      this.$refs.datatable.fetch()
    },
    isVisibleColumn(column) {
      return window._.indexOf(this.visibleColumns, column) > -1;
    },
    isFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      let result = window._.filter(this.favorites, function (value) {
        return value.coin_pair == coinPair;
      });
      return result.length > 0;
    },

    insertFavorite(coin, currency) {
      this.isLoadingFavorite = true
      const coinPair = this.getCoinPair(coin, currency);
      FavoritesManager.addFavorite({ coin_pair: coinPair }).then((data) => {
        this.favorites.push(data);
        this.isLoadingFavorite = false
      });
    },

    removeFavorite(coin, currency) {
      this.isLoadingFavorite = true
      const coinPair = this.getCoinPair(coin, currency);
      let favoriteObject = window._.find(this.favorites, [
        "coin_pair",
        coinPair,
      ]);

      FavoritesManager.removeFavorite(favoriteObject).then((res) => {
        this.favorites.splice(this.favorites.indexOf(favoriteObject), 1);
        // In the favorites tab, table remove pair which isn't favorited.
        if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
          this.pricesSorted = window._.filter(this.pricesSorted, (item) => {
            const pair = this.getCoinPair(item.coin, item.currency);
            return pair !== coinPair;
          });
        }
        this.isLoadingFavorite = false
      });
    },

    getCoinPair(coin, currency) {
      return `${coin}/${currency}`;
    },

    selectedCurrenyPair(currency, coin) {
      if (!currency) {
        currency = "eth";
      }
      if (!coin) {
        coin = "btc";
      }

      const pair = { currency: currency, coin: coin };
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?currency=${pair.currency}&coin=${pair.coin}`;
      // const newurl = `/spot-exchange/basic?currency=${pair.currency}&coin=${pair.coin}`;

      this.$broadcast("SpotSelectedPairMarket", { ...pair, zone: this.zone });
      // window.history.pushState({ path: newurl }, '', newurl);

      this.$router.replace(
        window.location.pathname +
          `?currency=${pair.currency}&coin=${pair.coin}`
      );
    },

    getData(params) {
      return new Promise((resolve) => {
        let pricesSorted = this.pricesSorted;
        if (params.sort && params.sort_type) {
          pricesSorted = _.orderBy(
            pricesSorted,
            (item) => {
              if (!item.quote_volume) {
                item.quote_volume = 0;
              }
              if (isNaN(item[params.sort])) {
                if (params.sort !== "full_name") {
                  return item[params.sort];
                } else {
                  return window.i18n.t("currency." + item.coin + ".fullname");
                }
              }
              return new BigNumber(`${item[params.sort]}`).toNumber();
            },
            [params.sort_type]
          );
        }
        if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
          return resolve(
            _.chain(pricesSorted)
              .filter((item) => this.isFavorite(item.coin, item.currency))
              .value()
          );
        }
        return resolve(
          _.chain(pricesSorted)
            .filter((item) => item.currency === this.marketSelected)
            .value()
        );
      });
    },
  },
  mounted() {
    if (this.isSwitchPercentAndVolumn) {
      const thElement = document.querySelector('th[data-sort-field="quote_volume"]');
      if(thElement) {
        thElement.setAttribute('data-sort-order', '');
        thElement.click();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.btn-icon-star{
  background-color: transparent;
}
.icon-arrow-left-right {
  width: 18px;
  height: 18px;
  background-color: var(--text-close);
  -webkit-mask-image: url("@/assets/images/icons/ArrowsLeftRight.svg");
  mask-image: url("@/assets/images/icons/ArrowsLeftRight.svg");
  -webkit-mask-repeat: no-repeat;
}

.item-tab {
  display: block;
  width: 100%;
  .grown {
    color: $text-color-jade !important;
  }
  .down {
    color: $text-color-red !important;
  }
  .table-landing {
    table {
      .icon-star1 {
        display: block;
        float: left;
        width: 15px;
        font-size: $font-medium;
        margin-right: 20px;
        height: 20px;
        float: left;
        color: $color-grey;
        cursor: pointer;
      }
      .icon-star2 {
        color: $color-yellow;
        cursor: pointer;
        padding: 0px 10px;
        user-select: none;
        &.disabled{
          cursor: not-allowed;
        }
      }
      .icon-star3 {
        color: $color-yellow;
        cursor: pointer;
      }

      th {
        padding: 25px 10px 10px 0;
      }

      td {
        text-align: left;

        &:last-child {
          text-align: left;
        }
      }
    }
    table tbody tr {
      height: 25px ;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .col-pair {
    font-family: $font-inter-regular;
    .coin {
      color: var(--text-primary-color);
    }
    .currency {
      color: var(--text-close);
    }
  }

  .is-muted {
    color: $color-grey;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .table-landing > table tr {
    padding: 0 25px;
    cursor: pointer;

    &:first-child {
      margin-top: 54px;
    }
  }

  .table-landing > table td {
    padding: 10px 10px 10px 0;
    color: $color-grey-dark;
  }

  .table-landing > table td:last-child {
    padding-right: 25px;
  }

  .table-landing > table tr td:first-child {
    padding-left: 20px;
  }

  .table-landing > table th:first-child {
    padding-left: 60px;
  }
</style>