var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"account_reward"}},[_c('Breadcrumb',{staticClass:"container-breadcrumb",attrs:{"items":_vm.breadcrumbItems}}),_c('AccountTopHeader',{attrs:{"title":_vm.$t('account.reward_center'),"icon":require(`@/assets/images/bgr-order.svg`)}}),_c('div',{staticClass:"content_reward"},[_c('div',{staticClass:"choose-type",attrs:{"id":"choose-desktop"}},[_c('div',{staticClass:"voucher-type"},[_c('div',{staticClass:"text-main choose-type_text"},[_vm._v(" "+_vm._s(_vm.$t("account.voucher_type"))+" ")]),_c('select-box',{attrs:{"options":_vm.listVoucherType},model:{value:(_vm.voucherType),callback:function ($$v) {_vm.voucherType=$$v},expression:"voucherType"}})],1),_c('div',{staticClass:"status"},[_c('div',{staticClass:"text-main choose-type_text"},[_vm._v(" "+_vm._s(_vm.$t("account.status"))+" ")]),_c('select-box',{attrs:{"options":_vm.listStatus},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]),_c('div',{staticClass:"choose-type-mobile",attrs:{"id":"choose-mobile"}},[_c('div',{staticClass:"filter",on:{"click":_vm.filterHandleClick}},[_c('img',{attrs:{"src":require(`@/assets/images/icon/icon-filter${
              _vm.theme === 'light-theme' ? '' : '-dark'
            }.svg`)}})]),_c('div',{staticClass:"choose-type"},[(_vm.isFilter === true)?_c('div',{staticClass:"voucher-type"},[_c('div',{staticClass:"text-main choose-type_text"},[_vm._v(" "+_vm._s(_vm.$t("account.voucher_type"))+" ")]),_c('select-box',{attrs:{"options":_vm.listVoucherType},model:{value:(_vm.voucherType),callback:function ($$v) {_vm.voucherType=$$v},expression:"voucherType"}})],1):_vm._e(),(_vm.isFilter === true)?_c('div',{staticClass:"status"},[_c('div',{staticClass:"text-main choose-type_text"},[_vm._v(" "+_vm._s(_vm.$t("account.status"))+" ")]),_c('select-box',{attrs:{"options":_vm.listStatus},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1):_vm._e()])]),_c('div',{staticClass:"main-reward"},[(_vm.listVoucher.length > 0)?_c('div',{staticClass:"list-reward"},_vm._l((_vm.listVoucher),function(item){return _c('div',{key:item.id,staticClass:"item",class:{
            active: _vm.checkStatus(item),
            inprogress:
              _vm.convertStatus(item) === 'In Progress' && !item.user_id,
          }},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"type"},[_c('span',{staticClass:"type_text"},[_vm._v(_vm._s(item.type))])]),_c('div',{staticClass:"content-value"},[_c('div',{staticClass:"content-value-item"},[_c('div',{staticClass:"text-main"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"text-description"},[_vm._v(" Complete identity verification to get 100 USDT ")]),_c('div',{staticClass:"text-sub text-date"},[_vm._v(" "+_vm._s(_vm.$t("account.expiry_date"))+": "+_vm._s(item.expires_date === null ? "26-10-2024" : _vm.getDate(item.expires_date))+" ")])]),_c('div',{staticClass:"wrap-button"},[(_vm.checkStatus(item))?_c('button',{staticClass:"button",on:{"click":function($event){return _vm.claimReward(item)}}},[_vm._v(" Claim ")]):_c('button',{staticClass:"button"},[_vm._v(" "+_vm._s(_vm.convertStatus(item))+" ")])])]),_c('div',{staticClass:"content-money"},[_c('img',{staticClass:"star-icon",attrs:{"src":require('@/assets/images/mobile/star.png'),"alt":""}}),_c('div',{staticClass:"value-money"},[_vm._v(" "+_vm._s(_vm._f("toNumber")(item.user_id ? item.amount_old : item.amount || 0))+" ")]),_c('div',{staticClass:"coin"},[_vm._v(_vm._s(_vm._f("uppercase")(item.currency)))])])])])}),0):_c('div',{staticClass:"nodata"},[_c('img',{staticClass:"icon-search",attrs:{"src":require(`@/assets/images/landing/icon-nodata${
              _vm.theme === 'light-theme' ? '' : '-dark'
            }.svg`)}}),_c('div',{staticClass:"mess_nodata"},[_vm._v(" "+_vm._s(_vm.$t("common.datatable.data_empty.reward"))+" ")])]),(_vm.last_page > 1)?_c('Pagination',{attrs:{"per-page":8,"records":_vm.records,"chunk":_vm.last_page,"pageParent":_vm.page},on:{"Pagination:page":_vm.onPageChange}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }