<template>
	<div id="form_order_trade">
    <calculator-modal/>
	 	<div class="head_form_order clearfix">
      <ul>
        <li @click="showFormOrder = 0" class="tab-order" v-bind:class="{'active' : showFormOrder === 0}">
           {{ $t('margin.orders') }} <i class="icon icon-calculator" @click="showCalculatorModal"></i>
        </li>
        <li  @click="showFormOrder = 1" class="tab-trades" v-bind:class="{'active' : showFormOrder === 1}">
           {{ $t('margin.trades') }}
        </li>
      </ul>
    </div>
		<div class="clearfix"></div>

		<div class="content_form_order_trade clearfix">
			<div class="content_form_rorder" v-show='showFormOrder === 0'>
				<order-form />
			</div>
			<div class="content_form_trade" v-show='showFormOrder === 1'>
        <div class="top-title">
          <ul class="title-group">
            <li class="od-price">{{ $t('margin.price') }}</li>
            <li class="od-size">{{ $t('margin.size') }}</li>
            <li class="od-total">{{ $t('margin.time') }}</li>
          </ul>
        </div>
				<trade-form/>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import CalculatorModal from '@/components/marginexchange_pages/common/CalculatorModal.vue';
  import OrderForm from './order-form/OrderForm.vue';
	import TradeForm from '@/components/marginexchange_pages/exchange/basic/order-form/TradeForm.vue';

  export default {
    components: {
      CalculatorModal,
			OrderForm,
			TradeForm,
    },
    data() {
      return {
        showFormOrder: 0,
      }
    },
    watch: {
      
    },
    methods: {
      showCalculatorModal() {
        window.CommonModal.show('CalculatorModal');
      },
    },
    mounted() {

    }
   }
</script>


<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  $heightHeaderTitle             : 27px;
	.head_form_order {
    border-bottom: 1px solid $color-bright-gray-dark;
    padding: 8px 0 0 0;
    background-color: $color-ebony-clay;
    ul {
      margin: 0px;
      display: block;
      width: 100%;
      overflow: hidden;
      margin-bottom: -1px;
      li {
        width: calc(50% - 1px);
        float: left;
        cursor: pointer;
        text-align: center;
        font-size: $font-root;
        color: $color-grey-sliver;
        font-family: $font-family-roboto-bold;
        height: 32px;
        line-height: 20px;
        padding: 6px;
        margin-right: 1px;
        &:last-child {
          margin-right: 0px;
          margin-left: 1px; 
        }
        &:hover {
          background-color: $color-mirage;
        }
        &.active {
          background-color: $color-mirage;
          color: $color-orange;
        }
        i.icon {
          &:hover {
            box-shadow: 0px 0px 7px $color-white;
          }
        }
      }
    }
    .icon-calculator {
      margin-left: 5px;
    }
  }
  .top-title {
          height: #{$heightHeaderTitle};
          background: transparent;
          position: relative;
          z-index: 10;
          .title-group{
            padding: 0 12px;
            display: inline-block;
            width: 100%;
            // border-bottom: 1px solid $color-alto;
            li{
              width: 33.33%;
              display: block;
              float: left;
              text-align: center;
              font-size: $font-mini;
              color: $color-grey-dusty;
              font-weight: 500;
              padding: 4px 0px 3px 0px;
              line-height: 20px;
              height: 27px;
              &:nth-child(1) {
                text-align: left;
                width: 35%;
              }
              &:nth-child(2) {
                width: 35%;
              }
              &:nth-child(3) {
                text-align: right;
                width: 30%;
              }
            }
          }
  }
  .content_form_order_trade {
    padding: 7px 0px;
    table{
      width: 100%;
      color: $color-gray-dark;
    }
    .content_form_trade {
      height: 403px;
      overflow-y: auto; 
    }
  }
</style>