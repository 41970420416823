<template>
  <div class="device-item">
    <div class="device-info">
      <span class="device-name">
         <img class="ico" :src="getImageUrl(kind)"/>
        <span :style="{marginLeft: '8px'}">{{ deviceName | uppercaseFirst }}</span>
      </span>
     <div class="container-other-info">
       <span class="login-time-label">Login time</span>
       <span class="login-time">{{ loginTime }}</span>
     </div>
  <div class="container-other-info">
    <span class="ip-label">IP</span>
    <span class="ip">{{ ip }}</span>
  </div>
    </div>
    <button class="delete-button" @click="onDelete">Delete</button>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Cookies from "js-cookie";

export default {
  data(){
    return {
      theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
    }
  },
  props: {
    deviceName: {
      type: String,
      required: true,
    },
    loginTime: {
      type: String,
      required: true,
    },
    ip: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
  computed: {
    ...mapState({
      masterdata: state => state.masterdata,
      userThemeMode: (state) => state.userThemeMode,
    }),
    desktopMode() {
      return this.theme !== "light-theme" ? "desktop-light.svg" : "desktop-dark.svg";
    },
    mobileMode() {
      return this.theme !== "light-theme" ? "smartphone-light.svg" : "smartphone-dark.svg";
    },
    tabletMode() {
      return this.theme !== "light-theme" ? "tablet-light.svg" : "tablet-dark.svg";
    },
    otherDeviceMode(){
      return this.theme === "light-theme" ? "other-device-light.svg" : "other-device-dark.svg";
    }
  },
  methods: {
    getImageUrl(kind) {
      console.log("my kind",kind)
      let ico = '';
      switch (kind) {
        case 'desktop':
          ico = this.desktopMode
          break;
        case 'smartphone':
          ico = this.mobileMode
          break;
        case 'tablet':
          ico = this.tabletMode
          break;
        default:
          ico = this.otherDeviceMode
      }
      return require(`@/assets/images/drx/${ico}`);
    },
    onDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style scoped lang="scss">
.device-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  .container-other-info{
    display: flex;
    justify-content: space-between;
  }
}

.device-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.device-name {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: var(--text-primary-color);

}

.login-time-label,
.ip-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #8E8E92;
}

.login-time,
.ip {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: var(--text-primary-color);
}

.delete-button {
  color: #03AAC7;
  background: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
  margin: 12px auto 0px auto;
}

.delete-button:hover {
  color: #0077cc;
}
</style>
