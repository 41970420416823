<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th
            v-for="col in columns"
            :key="col.field"
            @click="sortTable(col.field)"
          >
            {{ col.label }}
            <span v-if="currentSort === col.field">{{
              currentSortDir === "asc" ? "↑" : "↓"
            }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in paginatedData" :key="index">
          <td
            v-if="col.field === 'id'"
            v-for="col in columns"
            :key="col.field"
            :class="
              index === 0 || index === 1 || index === 2
                ? 'text-top'
                : 'text-normal'
            "
          >
            {{ index + 1 }}
          </td>
          <td
            v-if="col.field === 'name'"
            v-for="col in columns"
            :key="col.field"
          >
            <div
              :style="{
                display: 'flex',
                alignItems: 'center',
                maxWidth: '90px',
              }"
            >
              <img
                :src="logCoin(row['coin'])"
                :style="{ width: '28px', height: '28px', objectFit: 'contain' }"
              />
              <p class="text-coin">{{ row["name"] }}</p>
              <p class="text-sign">/{{ row["sign"] }}</p>
              <p class="container-type" v-show="row['type']">
                {{ row["type"] }}
              </p>
            </div>
          </td>
          <td
            v-if="
              col.field !== 'id' &&
              col.field !== 'action' &&
              col.field !== 'name'
            "
            v-for="col in columns"
            :key="col.field"
          >
            {{ row[col.field] }}
          </td>

          <td
            v-if="col.field === 'action'"
            v-for="col in columns"
            :key="col.field"
          >
            <span class="action-trade">Trade</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-show="false" class="pagination">
      <button
        :disabled="currentPage === 1"
        @click="changePage(currentPage - 1)"
      >
        Previous
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        :disabled="currentPage === totalPages"
        @click="changePage(currentPage + 1)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      currentPage: 1,
      currentSort: "",
      currentSortDir: "asc",
    };
  },
  mounted() {
    console.log("my col", this.columns);
  },
  computed: {
    sortedData() {
      if (!this.currentSort) return this.data;
      return this.data.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.data.length / this.itemsPerPage);
    },
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    sortTable(colField) {
      if (this.currentSort === colField) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.currentSort = colField;
        this.currentSortDir = "asc";
      }
    },
    logCoin(coin) {
      console.log("Coin:", coin);
      return coin; // Trả về giá trị để dùng cho thuộc tính `src`
    },
  },
};
</script>

<style scoped>
.table-container {
  margin: 20px;
  color: white;
  p {
    margin: 0;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  border-bottom: 1px solid #222426;
  cursor: pointer;
  padding: 2px 8px;
}

thead tr th {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #8e8e92;
}

tbody tr td {
  padding: 20px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

th:hover {
  background-color: transparent;
}

.pagination {
  margin-top: 10px;
}

button {
  padding: 10px;
  margin-right: 5px;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.text-top {
  color: #fa97a3;
}
.text-normal {
  color: #8e8e92;
}
.text-coin {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: white;
  margin-left: 8px !important;
}
.text-sign {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #8e8e92;
}

.container-type {
  padding: 0 4px;
  display: flex;
  height: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: #122c30;
  color: #03aac7;
  border-radius: 4px;
  margin-left: 8px !important;
}

.action-trade {
  color: #03aac7;
}
</style>
