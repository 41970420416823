<template>
  <div id="overview_container" class="page-overview">
    <div v-if="sizeScreen < 769" :class="isOpen ? 'activeMenu' : ''"></div>

    <div
      class="left-menu"
      v-click-outside="clickOutside"
      :class="{
        tab_menu: sizeScreen < 769,
        hideLeftMenu: !isOpen && sizeScreen < 769,
      }"
    >
      <NavigationWallet
        :sizeScreen="sizeScreen"
        :tabIdActive="tabIdActive"
        @selected="select"
      />
    </div>
    <div :class="['content-right', sizeScreen < 769 && 'overScreen']">
      <div class="wrapper-tab-menu" :class="{ hide: sizeScreen >= 769 }">
        <div class="btn-tab-menu" @click="isOpen = !isOpen">
          <img width="16px" src="@/assets/images/tab_menu.png" />
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>
<script>
import NavigationWallet from "@/components/shared_components/common/NavigationWallet.vue";
import WalletOverView from "@/pages/wallet/component/WalletOverview.vue";
import SpotOverview from "@/pages/wallet/component/SpotOverview.vue";

export default {
  data() {
    return {
      tabIdActive: 1,
      sizeScreen: window.innerWidth,
      isOpen: false,
    };
  },
  components: {
    NavigationWallet,
    WalletOverView,
    SpotOverview,
  },
  methods: {
    select(id) {
      this.tabIdActive = parseInt(id);
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
      this.isOpen = false;
    },
    checkActiveTab() {
      if (this.$route.path == "/funds/history-wallet") this.select(4);
      else if (this.$route.path == "/funds/spot-wallet") this.select(2);
      else if (
        this.$route.path == "/funds/balances-wallet" ||
        this.$route.path == "/funds/withdraw-usd-wallet" ||
        this.$route.path == "/funds/withdraw-wallet" ||
        this.$route.path == "/funds/deposits-wallet" ||
        this.$route.path == "/funds/deposit-usd-wallet"
      )
        this.select(1);
      else this.select(3);
    },
    clickOutside(event) {
      if (event.target.className !== "btn-tab-menu") {
        this.isOpen = false;
      }
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.changeActiveTab;
      },
    },
    "window.innerWidth": {
      handler() {
        this.resizeHandler;
      },
    },
  },
  computed: {
    changeActiveTab: function () {
      this.checkActiveTab();
    },
    checkSizeScreen: function () {
      window.addEventListener("resize", this.resizeHandler);
    },
  },
  mounted() {
    this.checkActiveTab();
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.page-overview {
  display: flex;
  height: 100%;
  padding-top: 4px;
  .left-menu {
    width: 258px;
    /* min-width: 337px; */
    padding: 0 !important;
    transition: width 0.5s;
    border-right: solid 1px var(--color-border-line);
    @media only screen and (max-width: 1440px) {
      flex: unset;
    }
  }
  .content-right {
    width: calc(100% - 300px);
    flex: 1%;
    padding: 24px 34px;
    @media screen and (max-width: 992px) {
      padding: 16px 20px;
    }
  }
  .overScreen {
    width: 100%;
  }
}
</style>
