<template>
  <div id="overview_container" class="page-overview">
    <div v-if="sizeScreen > 1300" class="left-menu">
      <NavigationConvert
        :sizeScreen="sizeScreen"
        :tabIdActive="tabIdActive"
        @selected="select"
      />
    </div>
    <div :class="['content-right', sizeScreen < 769 && 'overScreen']">
      <div class="main-open-order">
        <div class="box-title position-relative justify-content-end">
          <div  class="position-absolute title-open-order">
            <p class="title_spot ">{{ $t('convert.convert_order') }} </p>
            <p v-if="convertOrder">{{ $t('menu.open_orders') }}</p>
            <p v-else>{{ $t('menu.history.convert') }}</p>
          </div>
          <img class="title-img" :src="require(`@/assets/images/bgr-order${sizeScreen < 992 ? '-mobile' : ''}.svg`)" alt=""/>

        </div>
      </div>
      <router-view class="convert-group-page"/>
    </div>
  </div>
</template>
<script>
import NavigationConvert  from "@/components/shared_components/common/NavigationConvert.vue";
import WalletOverView from "@/pages/wallet/component/WalletOverview.vue";
import SpotOverview from "@/pages/wallet/component/SpotOverview.vue";

export default {
  data() {
    return {
      tabIdActive: 0,
      sizeScreen: window.innerWidth,
      convertOrder: true
    };
  },
  components: {
    NavigationConvert,
    WalletOverView,
    SpotOverview,
  },
  methods: {
    select(id) {
      this.tabIdActive = parseInt(id);
      this.convertOrder = id > 0? false :  true;
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
    checkActiveTab() {
      if (this.$route.path == "/convert/open-order") this.select(0);
      else this.select(1);
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.changeActiveTab;
      },
    },
    "window.innerWidth": {
      handler() {
        this.resizeHandler;
      },
    },
  },
  computed: {
    changeActiveTab: function () {
      this.checkActiveTab();
    },
    checkSizeScreen: function () {
      window.addEventListener("resize", this.resizeHandler);
    },
  },
  mounted() {
    this.checkActiveTab();
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  .page-overview .content-right {
    background: $color-night;
  }
}

.page-overview {
  display: flex;
  height: 100%;
  background-color: var(--background-color-primary);
  padding-top: 0px;
  border-top: 4px solid var(--color-border-right);
  .left-menu {
    flex: 0 0 337px;
    max-width: 337px;
    padding: 0 !important;
    transition: width 0.5s;
    background-color: var(--background-home-page);
    border-right: solid 4px var(--color_border_gap);
    @media only screen and (max-width: 1440px) {
      flex: unset;
    }
  }
  .content-right {
    width: calc(100% - 300px);
    flex: 1%;
    background-color: var(--background-color-primary);
    @media screen and (max-width: 1440px) {
      //width: 100%;
    }
  }
  .overScreen {
    width: 100%;
  }
}
.box-title{
  .title-open-order {
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    .title_spot {
      font-size: $font-big-24;
      font-weight: 500;
      
    }
    p {
      margin: 0;
    }
  }
  background: var(--bgr-order);
  width: 100%;
  font-size: 34px;
  font-family: $font-inter-bold;
  font-weight: 600;
  color: $color-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img{
    padding-right: 100px;
  }
}

table{
  table{
    .tableContainer3{
      thead {
        tr {
          th:first-child {
            text-align: left!important;
          }
        }
      }
    }
  }
}
.convert-group-page {
    padding: 50px 0 50px 40px;
    max-width: 1320px;
    position: relative;
}

@media screen and (max-width: 1550px) {
    .convert-group-page {
      max-width: calc(100vw - 300px);
      padding: 50px 60px 112px 40px;
    }

  }

@media screen and (max-width: 1440px) {
  .convert-group-page {
      max-width: 1318px;
      padding: 50px 40px 112px 40px;
    }

  }
  @media only screen and (max-width: 992px) {
    .box-title img{
      padding-right: 0;
    }
  .convert-group-page {
    padding: 40px 16px 0px;
  }
  .box-title {
    height: 82px;
    padding-left: 16px;
    .title-open-order {
      left: 16px;
      .title_spot{
        font-size: 18px;
        line-height: 24px;
        }
      p{
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}


</style>