<template>
  <div>
    <div v-if="visibleMarkets == true" class="click_out_side"></div>

    <div class="exchange-header" @click="showModalSearch" v-click-outside="onCloseMarketModal" :class="{'border-right-none': visibleMarkets}">
      <div class="exchange-market df">
<!--        <i class="icon_coin_ch icon-chart2"></i>-->
        <img
        v-if="coinImages[coin]"
        class="icon-coin"
        :src="`${coinImages[coin]}`"
        alt=""
        />
        <img
        v-else
        class="icon-coin"
        :src="coin == 'btc' ? btcicon : coin == 'xrp' ? xrpicon : eosicon"
        alt=""
        />
        <strong class="current-coin">{{ zone == 0 ? coin : '--' | uppercase }}</strong>  <span class="current-coin">/{{ zone == 0 ? currency : '--' | uppercase }}</span>
<!--        <span :class="{'icon-arrow-down-bold1': !visibleMarkets, 'icon-arrow-up-bold1': visibleMarkets}"></span>-->
        <div class="icon-arrow-token">
          <img :src="require(`@/assets/images/icon/arrow-${userTheme === 'light-theme'? 'down-light': 'down-dark'}.svg`)"/>
        </div>

      </div>

<!--      <div class="list_coin_hd" v-show="visibleMarkets">-->
<!--        <markets-dark @closeMarketModal="onCloseMarketModal" :zone="0"/>-->
<!--      </div>-->
    </div>

    <!-- second zone -->
    <div v-if="visibleMarkets2 == true" class="click_out_side"></div>
    <!-- <div class="exchange-header" v-click-outside="onCloseMarketModal2" >
      <div class="exchange-market" v-on:click="visibleMarkets2 = !visibleMarkets2">
        <i class="icon_coin_ch icon-chart2"></i>
        <strong>{{ zone == 1 ? coin : '--' | uppercase }}</strong> / <span>{{ zone == 1 ? currency : '--' | uppercase }}</span>
        <span :class="{'icon-arrow1': !visibleMarkets2, 'icon-arrow2': visibleMarkets2}"></span>
      </div>

      <div class="list_coin_hd" v-show="visibleMarkets2">
        <markets-dark @closeMarketModal="onCloseMarketModal2" :zone="1"/>
      </div>
    </div> -->
    <!-- <digital-clock/> -->

    <top-info class="d-none d-sm-flex"/>
    <div class="d-flex d-sm-none align-items-center" style="margin-left: auto">
      <span class="price-current-coin" :class="{grown: lastPriceGrownStatus  > 0, down: lastPriceGrownStatus  < 0}">
              {{currentPrice | formatCurrencyAmount(currency, '0')}}
            </span>
      <!-- <fiat-currency class="price-previous-coin" :value="usdPrice" v-if="!isUsdCurrency"></fiat-currency> -->
    </div>
    <br>
    <div class="volume-coin d-flex d-sm-none w-100 justify-content-between">
      <div class="change-24h">
        <div class="title">{{ $t('exchange.basic.trading_page.change_24h') }}</div>
        <span class="nb_basic_tn" :class="{grown: percentChange > 0, down: percentChange < 0}">{{ get24hPriceChanged() | formatCurrencyAmount(currency, '0') }}</span>
        <span class="nb_basic_tn" :class="{grown: percentChange > 0, down: percentChange < 0}">{{ characterIncrease }}{{ percentChange | to2Precision }}%</span>
      </div>
      <div>
        <div class="title">{{ $t('exchange.basic.trading_page.high_24h') }}</div>
        <span class="nb_basic_tn">{{ maxPrice | formatCurrencyAmount(currency, '0') }}</span></div>
      <div>
        <div class="title">{{ $t('exchange.basic.trading_page.low_24h') }}</div>
        <span class="nb_basic_tn">{{ minPrice | formatCurrencyAmount(currency, '0') }}</span>
      </div>
      <div>
        <div class="title">{{ $t('exchange.basic.trading_page.volume_24h') }} ({{ coin.toUpperCase() }})</div>
        <span class="nb_basic_tn">{{ volume | formatCurrencyAmount(currency, '0') }}
          <!--              {{ coin | uppercase }}-->
            </span>
      </div>

    </div>

<!--    &lt;!&ndash; <price-other-exchange/> &ndash;&gt;-->
  </div>
</template>

<script>
import Const from '@/common/Const';
import { mapState } from "vuex";
import TopInfo from './TopInfo';
import DigitalClock from './DigitalClock';
import MarketsDark from './MarketsDark';
import PriceOtherExchange from './PriceOtherExchange';
import BigNumber from "bignumber.js";
import Utils from "@/common/Utils";
import rf from "@/request/RequestFactory";
import BTCIcon from "@/assets/images/icon/coin/color/btc.png";
import XRPIcon from "@/assets/images/icon/coin/color/xrp.png";
import EOSIcon from "@/assets/images/icon/coin/color/eos.png";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";
import Cookies from "js-cookie";

export default {
    components: {
      TopInfo,
      DigitalClock,
      MarketsDark,
      PriceOtherExchange
    },
    props: {
      masterdata: {
        type: Object, default: null
      },
    },
    data() {
      let coinEx = this.$route.query.coin;
      let currencyEx = this.$route.query.currency;
      if(this.checkCoinCurrencyFromMasterdata(coinEx, currencyEx)) {
          coinEx =  Const.DEFAULT_COIN;
          currencyEx=  Const.DEFAULT_CURRENCY;
          this.$router.push({ query: { coin: coinEx, currency: currencyEx }})
      }
      if (coinEx && currencyEx){
        localStorage.setItem("spotEx_coin", coinEx);
        localStorage.setItem("spotEx_currency", currencyEx);
      }else {
        coinEx = localStorage.spotEx_coin || Const.DEFAULT_COIN;
        currencyEx = localStorage.spotEx_currency || Const.DEFAULT_CURRENCY;
        if (coinEx != this.$route.query.coin || currencyEx != this.$route.query.currency) {
          this.$router.push({ query: { coin: coinEx, currency: currencyEx }})
        }
      }
      return {
        coin: coinEx,
        currency: currencyEx,
        visibleMarkets: false,
        visibleMarkets2: false,
        zone: localStorage.spotEx_zone || 0,
        prices: {},
        currentPrice: 0,
        lastPriceGrownStatus: 0,
        previousPrice: 0,
        changePrice: 0, // change price with latest price
        usdPrice: 0,
        minPrice: 0,
        maxPrice: 0,
        price24h: 0,
        volume: 0,
        percentChange: 0,
        coinImages: {},
        btcicon: BTCIcon,
        xrpicon: XRPIcon,
        eosicon: EOSIcon,
        ethicon: ETHIcon,
        userTheme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
      }
    },

    methods: {
      showModalSearch() {
        window.CommonModal.show('SearchBox');
      },
      getEventHandlers() {
        return {
          SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        }
      },
        checkCoinCurrencyFromMasterdata(coin, currency){
            let count = 0;
            this.masterdata?.coin_settings.forEach((item) => {
                const coinPairMasterData = `${item.coin}_${item.currency}`
                const coinPairUrl = `${coin}_${currency}`
                if (coinPairMasterData === coinPairUrl) {
                    count += 1;
                }
            })
            return count > 0;
        },
      onSpotSelectedPairMarket(pair) {
        this.coin = pair.coin;
        this.currency = pair.currency;
        this.zone = pair.zone || 0;
        if (localStorage) {
          localStorage.setItem("spotEx_coin", this.coin);
          localStorage.setItem("spotEx_currency", this.currency);
          localStorage.setItem("spotEx_zone", pair.zone);
        }
        this.getPriceScope()
            .then(() => {
              this.onPricesUpdated(this.prices);
            });
      },
      onCloseMarketModal() {
        this.visibleMarkets = false;
      },
      onCloseMarketModal2() {
        this.visibleMarkets2 = false;
      },
      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          OrderTransactionCreated: this.onOrderTransactionCreated
        }
      },

      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
        if (window._.isEmpty(this.prices)) {
          return;
        }
        let coinPrice = newPrices[this.tradingPair];
        if (!coinPrice) {
          return;
        }
        if (this.currentPrice) {
          this.changePrice = new BigNumber(coinPrice.price).sub(this.currentPrice).toString();
        }
        this.price24h = coinPrice.last_24h_price;
        this.previousPrice = coinPrice.previous_price || this.currentPrice;
        this.currentPrice = coinPrice.price;
        this.lastPriceGrownStatus = new BigNumber(this.currentPrice).comparedTo(this.previousPrice);
        if (new BigNumber(this.currentPrice).gt(this.maxPrice)) {
          this.maxPrice = this.currentPrice;
        }
        if (new BigNumber(this.currentPrice).lt(this.minPrice)) {
          this.minPrice = this.currentPrice;
        }

        this.percentChange = this.getPercentChanged();
        this.usdPrice = this.getUsdPrice();

        let titlePrice = this.tradingPairPrice.price;
        titlePrice = Utils.formatCurrencyAmount(titlePrice, this.currency);
        titlePrice = titlePrice + " " + this.coin + '/' + this.currency;
        if(this.$route.name === 'Spot Exchange' ) //if change route link that socket response does not affect other tabs
        {
          document.title = titlePrice.toUpperCase() + ` – ${process.env.VUE_APP_NAME}`;
        }
      },

      onOrderTransactionCreated(data) {
        if (data.orderTransaction.coin === this.coin && data.orderTransaction.currency === this.currency) {
          this.volume = new BigNumber(this.volume).add(data.orderTransaction.quantity);
        }
      },

      getUsdPrice() {
        if (this.currency === 'usd') {
          return this.currentPrice;
        }
        let usdPrice = this.prices['usd_' + this.currency];
        if (!usdPrice) {
          return 0;
        }
        const price = usdPrice.price;
        return new BigNumber(this.currentPrice).mul(price).toString();
      },

      get24hPriceChanged() {
        return new BigNumber(this.currentPrice).sub(this.price24h).toString();
      },

      getPercentChanged() {
        var change = this.get24hPriceChanged();
        if (change === '0') return '0';

        if (this.price24h == 0) return '100'; //division to zero

        return new BigNumber(change).div(this.price24h).mul(100);
      },

      getPriceScope() {
        let params = {
          currency: this.currency,
          coin: this.coin
        };
        return rf.getRequest('PriceRequest').getPriceScope(params).then(res => {
          this.maxPrice = res.data.max_price;
          this.minPrice = res.data.min_price;
          this.volume = res.data.volume;
          this.$broadcast('SpotUpdateVolumeMarket', res.data);
        }).catch(e => {
          console.log(e);
        });
      },

      initData() {
        this.changePrice = 0;
        this.currentPrice = 0;

        rf.getRequest('PriceRequest').getPrices().then((res) => {
          this.onPricesUpdated(res.data);
        });
      }
    },
    watch: {
      '$route' (to, from) {
        this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
        this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

        // hide markets dialog
        this.visibleMarkets = false;
        this.initData();
      },
      userThemeMode(theme) {
        this.userTheme = theme;
      },
    },
    computed: {
      ...mapState({
        userThemeMode: (state) => state.userThemeMode,
      }),
      tradingPair: function () {
        return this.currency + '_' + this.coin;
      },

      tradingPairPrice: function() {
        return this.prices[this.tradingPair] || {};
      },

      isUsdCurrency() {
        return this.currency === 'usd';
      },

      characterIncrease(percent) {
        if (this.percentChange <= 0) {
          return '';
        }
        return '+';
      }
    },
    mounted() {
      this.initData();
    }
  }
</script>


<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .icon-coin {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    vertical-align: middle;
    object-fit: contain;
}
.icon-arrow-token{
  background-color: var(--background-time);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  padding: 4px;
  margin-left: 8px;
}
  .dark-theme {
    .current-coin {
      color: $color-white;
    }
    .icon-arrow-down-bold1:before, icon-arrow-up-bold1:before{
      color: $color-white!important;
    }
    /* .nb_basic_tn {
      color: $color-grey-dark;
    } */
    .price-current-coin {
      color: white;
    }
  }

  .nb_basic_tn {
    color: var(--text-primary-color);
    font-size: $font-smaller;
    font-family: $font-notosans-medium;
  }
  .change-24h .nb_basic_tn {
    color: $color-mint-green;
    margin-right: 3px;
  }
  .price-current-coin {
    font-weight: 500;
    font-size: $font-big;
    line-height: 24px;
    color: $color-grey-dark;
    font-family: $font-notosans-bold;
    padding: 0 16px;
  }
  .price-previous-coin {
    font-weight: 500;
    font-size: $font-big;
    line-height: 15px;
    color: var(--color-percent);
    font-family: $font-notosans-bold;
    margin-left: 8px;
  }
  .volume-coin {
    padding: 8px 16px;
    border-top: 3px solid $color-alabaster;
    font-size: 12px;
    font-family: $font-notosans-regular;
    color: $color-gray-dark;
  }
  .dark-theme {
    .volume-coin {
      border-bottom-color: $color-cinder;
      border-top-color: $color-cinder;
    }
  }

  .grown {
    color: $text-color-jade!important;
  }
  .down {
    color: $text-color-red!important;
  }
  .current-coin {
    font-family: $font-inter-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: $color-grey-dark;
  }
  .title {
    color: var(--color-percent);
    font-family: $font-notosans-medium;
  }
  .exchange-header {
    z-index: 2020;
    position: relative;
    display: inline-block;
    float: left;
    border: 0;
    font-size: $font-small;
    color: $color-white;
    font-family: $font-family-roboto-medium;
    border-radius: 3px;
    //background: var(--background-current_coin);
    min-width: 145px;
    max-width: 250px;
    margin: 7px 13px;
    cursor: pointer;
    text-align: right;
    .exchange-market {
      display: flex;
      align-items: center;
      strong {
        font-weight: 600;
      }
    }
    .icon-arrow-up-bold1, .icon-arrow-down-bold1 {
      margin-left: 10px;
      font-weight: 700;
      line-height: 23px;
      font-size: $font-mini-mini;
      float: right;

      &:before {
        color: $color-grey-dark;
      }
    }
    .trading-pair {
      cursor: pointer;
    }
  }
  .top-info-content{
    display: inline-block;
    margin-top: 10px;
  }
  .icon_coin_ch {
    float: left;
    line-height: 21px;
  }
  .list_coin_hd {
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 999;
    left: 4px;
    right: 4px;
    ul {
      margin-top: 10px;
      background-color: $color-alabaster;
      border-radius: 3px;
      padding: 10px 0px;
      li {
        line-height: 20px;
        padding: 5px 20px;
        color: $color-jungle-green;
        font-size: $font-small;
        font-family: $font-family-roboto-bold;
        cursor: pointer;
        &:hover {
          background-color: $color-jungle-green;
          color: $color-white;
        }
      }
    }
  }
  .click_out_side {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51,51,51,0.7);
    z-index: 2010;
  }

  @media only screen and (max-width: 1601px) {
    .dark-theme {
      .exchange-header {
        border-right: none;

        .exchange-market .current-coin {
          color: $color-white;
        }
      }
    }

    .exchange-header {
      .exchange-market .current-coin {
        font-size: 28px;
        font-weight: 700;
        line-height: unset;
        padding: 10px 0;
      }
    }


    .border-right-none {
      border-right: none;
    }
  }

  @media only screen and (max-width: 992px) {
    .exchange-header {
      min-width: 98px;
      height: 42px;
      margin: 0;
      .exchange-market {
        height: 100%;
        padding-left: 16px;
        //border-right: 2px solid var(--color-border-right);
        padding-right: 8px;
      }
      .exchange-market .current-coin {
        height: 100%;
        font-size: $font-medium-bigger;
        font-weight: 600;
      }
      .icon-arrow-down-bold1, .icon-arrow-up-bold1 {
        margin-left: 4px;
        font-size: 6px;
      }
    }
  }
  .price-current-coin {
    font-weight: 600;
  }
</style>
