<template>
  <div class="lading-page">
    <!-- head home landing -->
    <!-- <div class="left_home_landing transitionSlide">
            <div class="header-bg">
                <img src="@/assets/images/landing/header-landing-bg.png" />
            </div>
            <div class="landingbg">
                <div class="ariship3">
                    <img src="@/assets/images/landing/airship3.png" />
                </div>
                <div class="rocket"></div>
                <div class="ariship1">
                    <img src="@/assets/images/landing/airship1.png" />
                </div>
                <div class="ariship2">
                    <img src="@/assets/images/landing/airship2.png" />
                </div>
            </div>
            <div class="insurance">
                <span
                    >{{ $t("index_buyback_fund") }}:
                    {{
                        insurance.buyBackFund
                            | formatCurrencyAmount("btc", "--")
                    }}
                    BTC</span
                >
                <span
                    >{{ $t("index_insurance") }}:
                    {{
                        insurance.insuranceFund
                            | formatCurrencyAmount("btc", "--")
                    }}
                    BTC</span
                >
            </div>
            <div class="landingslide landingfirstSlide">
                <div class="landingtxt"></div>
                <h2 :class="'landtit_' + $t('i18n.lang')">
                    <span v-html="$t('landing.banner.title.text')"></span>
                </h2>
                <div class="tool">
                    <router-link to="/">
                        to="/margin-exchange?symbol=BTCUSD"
                        {{ $t("common.begin.trading") }}
                    </router-link>
                </div>
                <div class="socical-network">
                    <a
                        v-for="(socicalNetwork, index) in socicalNetworks"
                        :key="index"
                        :href="socicalNetwork.link"
                        :title="socicalNetwork.name"
                        target="_blank"
                        class="icon-social"
                    >
                        <i :class="socicalNetwork.icon_class"></i>
                    </a>
                </div>
                <div class="banner">
                    <TSlide></TSlide>
                </div>
            </div>
        </div> -->

    <!-- End head home landing -->
    <!-- <div class="box-chart padding-box " v-if="!visibleCoinChart"> -->
    <!-- <div class="box-chart padding-box">
      <div class="curreny-chart" v-for="coin in top24hBtcCoins">
        <coin-chart :imageCoins="getImages()" :price-scope="getPriceScope('btc', coin)" :color="getChartColor()">
        </coin-chart>
      </div>
    </div> -->
    <div id="content-landing">
      <market-main id="market_top_landing"></market-main>

      <markets id="markets_table_landing" visibleCoinName></markets>

    </div>



    <div class="clearfix"></div>
    <!--
    <current-rate></current-rate>
    -->
    <!-- <div class="banner-big-group">
            <div class="banner-content">
                <div class="group-content">
                    <div class="left-bg"></div>
                    <div class="center-bg"></div>
                    <div class="group">
                        <div class="left-banner-40 left-banner-txt">
                            <div class="clearfix"></div>
                            <router-link class="title" to="/salespoint">
                                <span class="tit">{{
                                    $t("common.sales_point")
                                }}</span>
                                <br />
                                <span class="subtit">{{
                                    $t("common.sales_point.title")
                                }}</span>
                            </router-link>
                            <div class="clearfix"></div>
                            <div class="desc">
                                {{ $t("common.sales_point.desc") }}
                            </div>
                            <div class="clearfix"></div>
                            <router-link class="button" to="/salespoint"
                                >{{ $t("common.exchange.now") }}
                            </router-link>
                        </div>
                        <div class="right-banner">
                            <img src="@/assets/images/landing/iphone2.png" />
                        </div>
                    </div>
                </div>
                <div class="group-content group-reverse">
                    <div class="center-bg"></div>
                    <div class="left-bg"></div>
                    <div class="group">
                        <div class="left-banner-1"></div>
                        <div class="right-banner-40 right-banner-txt">
                            <div class="clearfix"></div>
                            <router-link
                                class="title"
                                to="/spot-exchange/basic?coin=btc&currency=usdt"
                            >
                                <span class="tit">{{
                                    $t("common.spot_exchange")
                                }}</span>
                                <br />
                                <span class="subtit">{{
                                    $t("common.spot_exchange.title")
                                }}</span>
                            </router-link>
                            <div class="clearfix"></div>
                            <div class="desc">
                                {{ $t("common.spot_exchange.desc") }}
                            </div>
                            <div class="clearfix"></div>
                            <router-link
                                class="button"
                                to="/spot-exchange/basic?coin=btc&currency=usdt"
                                >{{ $t("common.exchange.now") }}
                            </router-link>
                        </div>
                    </div> -->
    <!-- <div class="blue-left"></div> -->
    <!-- </div>
                <div class="group-content">
                    <div class="left-bg"></div>
                    <div class="center-bg"></div>
                    <div class="group">
                        <div class="left-banner-40 left-banner-txt">
                            <div class="clearfix"></div>
                            <router-link class="title" to="/">
                                <span class="tit">{{
                                    $t("common.margin_exchange")
                                }}</span>
                                <br />
                                <span
                                    class="subtit"
                                    v-html="$t('common.margin_exchange.title')"
                                ></span>
                            </router-link>
                            <div class="clearfix"></div>
                            <div
                                class="desc"
                                style="width: 300px"
                                v-html="$t('common.margin_exchange.desc')"
                            ></div>
                            <div class="clearfix"></div>
                            <router-link class="button" to="/"> -->
    <!-- /margin-exchange?symbol=BTCUSD -->
    <!-- {{ $t("common.begin.trading") }}
                            </router-link>
                        </div>
                        <div class="right-banner-2"></div>
                    </div>
                </div>
                <div class="group-content group-reverse">
                    <div class="center-bg"></div>
                    <div class="left-bg"></div>
                    <div class="group banner-store">
                        <div class="left-banner-2"></div>
                        <div
                            class="left-banner-40 right-banner4-txt right-banner-txt"
                        >
                            <div class="clearfix"></div>
                            <router-link class="title" to="/">
                                <span class="tit">{{
                                    $t("common.mobile_app")
                                }}</span>
                                <br />
                                <span
                                    class="subtit"
                                    v-html="$t('common.mobile_app.title')"
                                ></span>
                            </router-link>
                            <div class="clearfix"></div>
                            <div class="desc">
                                {{ $t("common.mobile_app.desc") }}
                            </div>
                            <div class="links">
                                <a
                                    :href="siteSetting.ios_app_url"
                                    target="_blank"
                                >
                                    <img
                                        src="@/assets/images/landing/appstore.png"
                                    />
                                </a>
                                <a
                                    :href="siteSetting.android_app_url"
                                    target="_blank"
                                >
                                    <img
                                        src="@/assets/images/landing/Googleplay.png"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- <div class="banner-store">
      <transition name="fade">
        <div class="banner-content">
          <div class="left-banner-60">
            <img class="img-ipad-1" src="@/assets/images/landing/iphone-white3.png" />
          </div>
          <div class="left-banner-40">
            <router-link class="icon" to="/">
              <span class="icon-responsive"></span>
            </router-link>
            <div class="clearfix"></div>
            <router-link class="title" to="/">
              <span>{{$t('common.mobile_app')}} -</span>
              <br>
              <span v-html="$t('common.mobile_app.title')"></span>
            </router-link>
            <div class="clearfix"></div>
            <div class="desc">{{$t('common.mobile_app.desc')}}</div>
            <div class="links">
              <a :href="siteSetting.ios_app_url" target="_blank">
                <img src="@/assets/images/landing/appstore.png" />
              </a>
              <a :href="siteSetting.android_app_url" target="_blank">
                <img src="@/assets/images/landing/Googleplay.png" />
              </a>
            </div>
          </div>
        </div>
      </transition>
    </div> -->

    <!-- <div class="banner-announcements">
            <h2 v-if="banners && banners.length > 0">
                {{ $t("common.announcements") }}
            </h2>
            <div class="banner-content">
                <div class="content-1" v-if="banners && banners.length > 0">
                    <div
                        class="item-group-1"
                        v-for="(item, index) in banners"
                        :key="index"
                    >
                        <a :href="item.support_url">
                            <img :src="`${apiUrl}/${item.banner_url}`" />
                        </a>
                        <span class="date-info">{{
                            parseBannerDate(item.started_at)
                        }}</span>
                        <div class="text">
                            <a :href="item.support_url">
                                {{ item.title }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="content-2">
                    <div class="item-group-2">
                        <div class="item-content">
                            <div class="bg-1">
                                <div class="title">
                                    <router-link to="/">{{
                                        $t("trezor.landing.title1")
                                    }}</router-link>
                                </div>
                                <div
                                    class="desc"
                                    v-html="$t('trezor.landing.desc1')"
                                ></div>
                                <div class="link">
                                    <a @click="gotoAPIDocs()">LEARN MORE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-group-2">
                        <div class="item-content">
                            <div class="bg-2">
                                <div class="title">
                                    <router-link to="/">{{
                                        $t("trezor.landing.title")
                                    }}</router-link>
                                </div>
                                <div
                                    class="desc"
                                    v-html="$t('trezor.landing.desc')"
                                ></div>

                                <div class="link">
                                    <router-link to="/">LEARN MORE</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- <div class="banner-create-account">
            <div class="banner-content">
                <div>
                    <div class="text">
                        {{ $t("common.slogan_create_account") }}
                    </div>
                    <div class="link" v-if="!isAuthenticated">
                        <router-link class="tab" to="/register">
                            <span class="icon-plus"></span
                            >{{
                                $t("common.create_account_amanpuri")
                            }}</router-link
                        >
                    </div>
                </div>
            </div>
        </div> -->

    <!--
    <div class="market_footer">
      <market-footer></market-footer>
    </div>
    -->
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import CookiesUtils from "@/common/CookiesUtils";
import Const from "@/common/Const";
import MarketFooter from "@/components/shared_components/common/MarketFooter.vue";
import CoinChart from "./CoinChart.vue";
import BigNumber from "bignumber.js";
import Markets from "./Markets";
import MarketMain from "./MarketMain/Main.vue";
import CurrentRate from "./CurrentRate";
import moment from "moment";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import TransitionSlide from "./../common/TransitionSlide.vue";
import TSlide from "@/components/Slide.vue";
import { mapState } from "vuex";


const CHART_COLORS = ["#e8d579", "#94bcea", "#f2bd8e", "#82e0c9", "#d9d9f1"];

export default {
  components: {
    CoinChart,
    MarketFooter,
    Markets,
    CurrentRate,
    TransitionSlide,
    TSlide,
    MarketMain,
  },
  data() {
    return {
      currenSlide: 0,
      prices: {},
      top24hBtcCoins: [],
      pricesScope: {},
      idxColor: 0,
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,
      isAuthenticated: window.isAuthenticated,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      banners: [],
      apiUrl: process.env.VUE_APP_STORAGE_URL,
      siteSetting: {},
      socicalNetworks: [],
      coinImages: [],

      insurance: {},
    };
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    visibleCoinChart() {
      if (
        window._.isEmpty(this.top24hBtcCoins) ||
        window._.isEmpty(this.pricesScope)
      ) {
        return false;
      }
      setTimeout(function () {
        $(".carousel").carousel({
          activate: function () {},
          timerAnimSlide: 400,
          itensDisplay: 4,
          spaceBetweenItens: 20,
          itensMove: 1,
          breakPoint: [
            { width: 800, itensDisplay: 3 },
            { width: 580, itensDisplay: 1 },
            { width: 700, itensDisplay: 2 },
          ],
          responsive: true,
        });
      }, 100);
      return true;
    },
    localeLang() {
      let lang = this.$store.state.localLang;
      if (typeof lang === "object") {
        lang = window.localStorage.locale;
      }
      if (lang === "ko") {
        lang = "en";
      }
      if (lang === "ko") {
        lang = "en";
      }
      // return lang;
      let obj = {
        lang: lang,
        en: "en",
      };
      return obj;
    },
  },
  watch: {},
  methods: {

    getImages() {
      return this.coinImages;
    },
    getPrice(pair) {
      return this.prices[pair] || { price: 0 };
    },
    checkShowByLocale() {
      return AuthenticationUtils.getLocale() !== "en";
    },
    getChartColor() {
      if (this.top24hBtcCoins && this.idxColor > this.top24hBtcCoins.length) {
        return;
      }
      const index = this.idxColor % 5;
      this.idxColor++;
      return CHART_COLORS[index];
    },

    getPriceScope(currency, coin) {
      return window._.filter(this.pricesScope, (priceScope) => {
        return priceScope.currency === currency && priceScope.coin === coin;
      })[0];
    },

    toUsdPrice(price, fromCurrency) {
      return new BigNumber(price)
        .mul(new BigNumber(this.getPrice("usd_" + fromCurrency).price))
        .toString();
    },

    pricesScopeUpdated(newPrices) {
      this.pricesScope = newPrices;

      this.calculateUsdPrice();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.calculateUsdPrice();
      this.top24hBtcCoins = this.getTop24hBtcCoins();
    },

    calculateUsdPrice() {
      window._.forEach(this.pricesScope, (item) => {
        item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
      });
    },

    getTop24hBtcCoins() {
      let dataReturn = window._.chain(this.prices)
        .filter((item) => item.currency === "btc")
        .orderBy(["volume"], ["desc"])
        .map((item) => item.coin)
        .take(4)
        .value();
      return dataReturn;
    },

    gotoUrl(url) {
      window.location.href = url;
    },

    gotoAPIDocs() {
      let url = process.env.VUE_APP_API_URL + "/api/documentation";
      this.gotoUrl(url);
    },
    getInsuranceFund() {
      return rf
        .getRequest("InsuranceRequest")
        .getInsuranceFund()
        .then((res) => {
          this.insurance = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    initData() {
      rf.getRequest("PriceRequest")
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
        });

      rf.getRequest("PriceRequest")
        .getPrices24h()
        .then((res) => {
          this.pricesScopeUpdated(res.data);
        });

      rf.getRequest("NoticeRequest")
        .getBannerNotices()
        .then((res) => {
          this.banners = res.data;
        });

      _.forEach(this.masterdata?.coins, (item, key) => {
        this.coinImages[item.coin] = item.icon_image;
      });
      this.coinImages["usd"] = `@/assets/images/color_coins/usd.png`;

      window._.each(this.masterdata?.settings, (item) => {
        this.siteSetting[item.key] = item.value;
      });

      this.socicalNetworks =
        window._.filter(
          this.masterdata?.social_networks,
          (item) => item.is_active
        ) || {};

      this.getInsuranceFund();
    },

    setCookiesReferralCode() {
      const referralCode = this.$route.query[Const.REFERRAL_KEY] || null;
      if (referralCode) {
        CookiesUtils.saveItem(Const.REFERRAL_KEY, referralCode);
      }
    },

    doSlide() {
      if (this.currenSlide >= 3) {
        this.currenSlide = 0;
      } else {
        this.currenSlide = this.currenSlide + 1;
      }
    },

    goSlide(slide) {
      clearInterval(slideTimer);
      this.currenSlide = slide;
      slideTimer = setInterval(function () {
        window["landingCpn"].doSlide();
      }, 5000);
    },

    parseBannerDate(str) {
      if (str !== null && str.toString() !== "") {
        var date = new Date(str);
        return moment(String(date)).format("DD / MM / YYYY");
      } else {
        return str;
      }
    },
  },
  mounted() {
    if (
      this.$route.params.isChangedPassword === true ||
      this.$route.query.isChangedPassword
    ) {
      Message.success(
        window.i18n.t("change_password_form.successful_message"),
        {},
        { position: "bottom_left" }
      );
      AuthenticationUtils.removeAuthenticationData();
      window.isAuthenticated = false;
      window.app.$broadcast("CHANGED_PASSWORD");
    }
    this.setCookiesReferralCode();
    this.initData();

    // Handle 'window.GlobalSocket.notifyPricesUpdate'
    this.$on("PricesUpdated", (data) => {
      this.onPricesUpdated(data);
    });

    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "/tradingview/charting_library/static/js/external/carousel.js"
    );
    document.head.appendChild(recaptchaScript);
    window.scrollTo({
        top: 0,
        left: 0,
      });
  },
  created() {
    window["landingCpn"] = this;

    slideTimer = setInterval(function () {
      window["landingCpn"].doSlide();
    }, 5000);
  },
};

var slideTimer;
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/slide/slide";
@import "@/assets/sass/slide/TransitionSlide";

$bland_w_1200: 1200px;
$wain_btn_color: #c30b57;
$wain_bg: 1px solid rgba(255, 255, 255, 0.7);

#market_top_landing{
  background: var(--bg-market-linear);
}
.carousel {
  width: 100%;
  position: relative;
  .carousel__navigation {
    position: absolute;
    z-index: 10;
    width: 100%;
    left: 0;
    top: 50%;
    margin-top: -10px;
  }
  .carousel__nav--left {
    position: absolute;
    left: -15px;
    i {
      color: $color-gray-2;
      font-size: $font-medium-bigger;
    }
  }
  .carousel__nav--right {
    position: absolute;
    right: -15px;
    i {
      color: $color-gray-2;
      font-size: $font-medium-bigger;
    }
  }
  .carousel__container {
    margin: 0 17px;
  }
  .carousel__content {
    overflow: hidden;
    height: 129px !important;
    width: 100%;
    position: relative;
  }
  .carousel__slider {
    position: absolute;
  }
  .carousel__item {
    position: absolute;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.banner-big-group {
  float: left;
  width: 100%;
  height: auto;
  /* height: 1950px;
    background-image: linear-gradient(to bottom, #003f6b, #2dac91 50%, #12575f); */
}

.banner-big-group .banner-content,
.banner-big-group .banner-content .group {
  width: 100%;
  float: left;
}

.banner-big-group .left-banner-40 {
  float: left;
  width: 40%;
  text-align: right;
  padding: 130px 0px 0px 130px;
}

.banner-big-group .left-banner-40 .icon {
  width: 50px;
  height: 50px;
  display: block;
  float: right;
  span {
    font-size: 50px;
    color: $color-white;
  }
}

.banner-big-group .left-banner-40 a.title {
  font-family: $font-family-roboto;
  font-size: $font-title-size-medium;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: $color-white;
  display: block;
  margin-top: 30px;
  clear: both;
  float: right;
}

.banner-big-group .left-banner-40 a.title span,
.banner-big-group .right-banner-50 a.title span {
  font-weight: bold;
}

.banner-big-group .left-banner-40 .desc {
  opacity: 0.8;
  font-family: $font-family-roboto;
  font-size: $font-medium;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: right;
  color: $color-white;
  width: 279px;
  float: right;
  margin-top: 20px;
}

.banner-big-group .left-banner-40 a.button {
  float: right;
  clear: both;
  border-radius: 3px;
  background-color: $color-white;
  padding: 10px 30px;
  font-family: $font-family-roboto;
  font-size: $font-root;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  letter-spacing: 1.1px;
  text-align: center;
  color: $color-eden;
  margin-top: 35px;
  text-transform: uppercase;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom;
    color: $color-white;
    outline: none;
  }
}

.banner-big-group .right-banner-60 {
  float: left;
  width: 60%;
  position: relative;
  padding: 80px 80px 0px 0px;
  img {
    margin-left: 50px;
  }
}

.banner-big-group .right-banner-60 .img-ipad-1 {
  position: absolute;
  left: 180px;
  bottom: 40px;
}

.banner-big-group .right-banner-60 .img-macbook {
  position: absolute;
  bottom: -70px;
  left: 150px;
}

.banner-big-group .left-banner-60 {
  float: left;
  width: 60%;
  position: relative;
  padding: 200px 70px 0px 0px;
  img {
    float: right;
  }
}

.banner-big-group .right-banner-40 {
  float: left;
  width: 40%;
  padding: 200px 110px 0px 0px;
}

.banner-big-group .right-banner-40 .icon {
  width: 50px;
  height: 50px;
  display: block;
  float: left;
  span {
    font-size: 50px;
    color: $color-white;
  }
}

.banner-big-group .right-banner-40 a.title {
  font-family: $font-family-roboto;
  font-size: $font-title-size-medium;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $color-white;
  display: block;
  margin-top: 30px;
  float: left;
  span {
    font-weight: bold;
  }
}

.banner-big-group .right-banner-40 .desc {
  opacity: 0.8;
  font-family: $font-family-roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $color-white;
  width: 279px;
  margin-top: 20px;
  float: left;
}

.banner-big-group .right-banner-40 a.button {
  float: left;
  clear: both;
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.2);
  background-color: $color-blue-custom;
  padding: 10px 30px;
  font-family: $font-family-roboto;
  font-size: $font-root;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  letter-spacing: 1.1px;
  text-align: center;
  color: $color-white;
  margin-top: 35px;
  text-transform: uppercase;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom-2;
    color: $color-white;
    outline: none;
  }
}

.banner-big-group .banner-content {
  .group-content {
    width: 100%;
    height: 790px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    background: url("@/assets/images/landing/landingbg.png") repeat-x;
    background-size: 100% auto;
    background-position: top left;
    background-color: #cecfcf;
    z-index: 0;
    position: relative;
    .center-bg {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 280px;
      height: 100%;
      z-index: 2;
      background: url("@/assets/images/landing/bnleft-bg-1.png") no-repeat;
      background-size: 100% 100%;
    }
    .left-bg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: calc(50% - 130px);
      height: 790px;
      background-image: url("@/assets/images/landing/bnleft-bg-2.png");
      background-repeat: repeat-x;
      background-size: auto 100%;
      z-index: 1;
      /* position: relative; */
    }
    .group {
      width: 1100px;
      height: 790px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
      .left-banner-40,
      .right-banner-40 {
        z-index: 3;
        position: relative;
        padding: 0px;
        a.title {
          width: 100%;
          text-align: left;
          .tit {
            font-size: 64px;
          }
          .subtit {
            font-size: 32px;
            display: inline-block;
            width: 500px;
          }
        }
        .desc {
          font-size: 19px;
          opacity: 1;
        }
        a.button {
          float: left;
          background-color: $wain_btn_color;
          color: #fff;
          border: $wain_bg;
          border-radius: 0px;
          &:hover,
          &:active,
          &:focus {
            transition: all 0.5s;
            background-color: #fff;
            color: $wain_btn_color;
            outline: none;
          }
        }
      }
      .left-banner-40 {
        width: 50%;
        .desc {
          float: left;
          text-align: left;
        }
      }
      .right-banner,
      .left-banner-1,
      .right-banner-2,
      .left-banner-2 {
        position: relative;
        z-index: 3;
      }

      /* banner1 text */
      .left-banner-txt {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .right-banner-txt {
        position: absolute;
        top: 50%;
        width: 41%;
        transform: translateY(-50%);
        right: 0px;
      }
      /* right banner img */
      .right-banner {
        position: absolute;
        width: 54%;
        height: 50%;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        img {
          height: 100%;
          width: 100%;
        }
      }

      /* btc banner2 img */
      .left-banner-1 {
        width: 50%;
        height: 70%;
        padding: 0px 0px 0px;
        /* text-align: center; */
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        img {
          height: 100%;
          width: 100%;
        }
      }

      .left-banner3-txt {
        /* padding: 170px 0px 0px 0px; */
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      /* 火箭 */
      .right-banner-2 {
        position: absolute;
        bottom: 0px;
        right: 0%;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 10px 0px 0px;
        text-align: center;
        img {
          height: 100%;
          width: 100%;
        }
      }
      /* last banner */
      .left-banner-2 {
        width: 54%;
        height: 52%;
        position: absolute;
        left: 0px;
        top: 52%;
        transform: translateY(-50%);
        clear: both;
        img {
          height: 100%;
          width: 100%;
        }
      }
      /* last banner text */
      .right-banner4-txt {
        /* width: 36%; */
        .desc {
          font-weight: 600;
        }
        /* padding-left: 130px; */
      }
    }
    .blue-left,
    .white-right {
      width: calc(50% - 550px);
      z-index: 2;
    }
    .blue-left {
      background-color: #12337d;
      background: url("@/assets/images/landing/bnleft-bg-2.png") repeat-x;
      background-size: auto 100%;
    }
    .white-right {
      background-color: #d2d2d2;
      // background: url('@/assets/images/landing/bnleft-bg-3.png') repeat-x;
      background-size: auto 100%;
    }
    .reverse {
      .center-bg {
        transform: rotateX(180deg);
        background-color: #cecfcf;
      }
    }

    .links {
      /* float: left; */
      text-align: left;
    }
  }
  .group-reverse {
    .left-bg {
      right: 0px;
      left: unset;
    }
    background-image: none;
    .center-bg {
      right: 50%;
      transform: translateX(-50%) rotateY(180deg);
    }
    &::after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: "";
      width: 100%;
      height: 100%;
      background: url("@/assets/images/landing/landingbg.png") repeat-x;
      background-size: 100% auto;
      background-position: top left;
      z-index: 0;
      transform: rotateY(180deg);
    }
  }
}

.banner-store {
  float: left;
  width: 100%;
  min-height: 600px;
  /* background-color: $color-eden; */
  position: relative;
}

.banner-store .banner-content {
  float: left;
  width: 100%;
  padding: 113px 0px 0px;
}

.banner-store .left-banner-60 {
  float: left;
  width: 50%;
  position: relative;
  padding-right: 50px;
}

.banner-store .left-banner-60 img {
  float: right;
}

.banner-store .left-banner-40 {
  float: left;
  width: 50%;
}

.banner-store .left-banner-40 .icon {
  width: 50px;
  height: 50px;
  display: block;
  span {
    font-size: $font-title-size-big-small;
    color: $color-jungle-green;
  }
}

.banner-store .left-banner-40 a.title {
  float: left;
  width: 367px;
  margin-top: 30px;
  font-family: $font-family-roboto;
  font-size: $font-title-size-medium;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $color-white;
  span {
    font-weight: bold;
  }
}

.banner-store .left-banner-40 .desc {
  clear: both;
  float: left;
  margin-top: 20px;
  width: 300px;
  font-family: $font-family-roboto;
  font-size: $font-medium;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $color-alto;
}

.banner-store .left-banner-40 .links {
  float: left;
  width: 100%;
  margin-top: 35px;
}

.banner-store .left-banner-40 .links a {
  margin-right: 10px;
  &:hover,
  &:active,
  &:focus {
    outline: none;
    img {
      opacity: 1;
    }
  }
  img {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}

.banner-store .slides {
  float: left;
  margin-top: 70px;
  position: absolute;
  right: 44%;
  z-index: 99;
  bottom: 70px;
}

.banner-store .slides span {
  float: left;
  border-radius: 50%;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  opacity: 0.4;
  background-color: #d8d8d8;
  cursor: pointer;
}

.banner-store .slides span.active {
  width: 15px;
  border-radius: 3px;
  background-color: $color-jungle-green;
  opacity: 1;
}

.banner-announcements {
  width: 100%;
  float: left;
  background-color: $color-white;
  padding: 70px 27px 80px 27px;
  .banner-content {
    .content-1 {
      .item-group-1 {
        img {
          height: 250px;
        }
        .text {
          width: 100%;
          > a {
            display: block;
            white-space: inherit;
            text-overflow: inherit;
            overflow: hidden;
            letter-spacing: initial;
            word-break: break-word;
          }
        }
      }
    }
  }
}

.banner-announcements h2 {
  font-family: $font-family-roboto;
  font-size: $font-title-size-medium;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: $color-eden;
}

.banner-announcements .banner-content {
  float: left;
  width: 100%;
  margin-top: 30px;
}

.banner-announcements .banner-content .content-1 {
  float: left;
  width: 100%;
  margin-bottom: 110px;
}

.banner-announcements .banner-content .item-group-1 {
  float: left;
  width: 25%;
  padding-right: 13px;
  padding-left: 13px;
}

.banner-announcements .banner-content .item-group-1 img {
  width: 100%;
}

.banner-announcements .banner-content .item-group-1 .date-info {
  display: block;
  font-family: $font-family-roboto;
  font-size: $font-root;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $color-grey;
  margin: 10px 5px;
}

.banner-announcements .banner-content .item-group-1 .text {
  font-family: $font-family-roboto-medium;
  font-size: $font-medium;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  line-height: 23px; // width: 250px;
}

.banner-announcements .banner-content .item-group-1 .text a {
  color: $color-grey-dark;
  font-weight: 500;
  font-family: $font-family-roboto-medium;
}

.banner-announcements .banner-content .item-group-1 .text a:hover {
  color: $color-jungle-green;
}

.banner-announcements .banner-content .content-2 {
  float: left;
  width: 100%;
}

.banner-announcements .banner-content .content-2 .item-group-2 {
  float: left;
  width: 50%;
  padding: 0 13px;
}

.banner-announcements .banner-content .content-2 .item-group-2 .item-content {
  padding: 40px 0px 40px 50px;
  float: left;
  width: 100%;
  background-color: #f2f2f2;
  min-height: 250px;
}

.banner-announcements
  .banner-content
  .content-2
  .item-group-2
  .item-content
  .bg-1 {
  float: left;
  width: 100%;
  background: url(@/assets/images/landing/api.png);
  background-position: 100% 0px;
  background-repeat: no-repeat;
  min-height: 180px;
  .title {
    a {
      color: $color-eden !important;
    }
  }
}

.banner-announcements
  .banner-content
  .content-2
  .item-group-2
  .item-content
  .bg-2 {
  float: left;
  width: 100%;
  background: url(@/assets/images/landing/trezor1.png);
  background-position: 100% 0px;
  background-repeat: no-repeat;
  background-size: 159px;
  min-height: 180px;
  .title {
    a {
      color: $color-eden !important;
    }
  }
  .desc {
    width: calc(100% - 190px);
  }
}

.banner-announcements
  .banner-content
  .content-2
  .item-group-2
  .item-content
  .title {
  float: left;
  width: calc(100% - 190px);
  font-family: $font-family-roboto;
  font-size: $font-title-size-small-25;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $color-eden;
}

.banner-announcements
  .banner-content
  .content-2
  .item-group-2
  .item-content
  .desc {
  line-height: 1.79;
  color: $color-grey-dusty;
  width: 320px;
  float: left;
  clear: both;
}

.banner-announcements
  .banner-content
  .content-2
  .item-group-2
  .item-content
  .link {
  margin-top: 20px;
  float: left;
  width: 100%;
}

.banner-announcements
  .banner-content
  .content-2
  .item-group-2
  .item-content
  .link
  a {
  border-radius: 3px;
  background-color: $color-jungle-green;
  font-family: $font-family-roboto;
  font-size: $font-smaller;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.42;
  letter-spacing: 0.9px;
  text-align: center;
  color: $color-white;
  padding: 10px 20px;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom;
    outline: none;
  }
}

.banner-create-account {
  width: 100%;
  float: left;
  background-color: #13347d;
}

.banner-create-account .banner-content {
  width: 100%;
  float: left;
  height: auto;
  padding: 85px 0px;
  /* height: 300px; */
  /* display: flex; */
  /* align-items: center; */
  background: url(@/assets/images/landing/group-16-1.png) repeat-x;
  background-size: auto 100%;
}

.banner-create-account .banner-content > div {
  width: 100%;
}

.banner-create-account .banner-content .text {
  float: left;
  width: 100%;
  height: 40px;
  font-family: $font-family-roboto;
  font-size: $font-title-size-medium;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: $color-white;
}

.banner-create-account .banner-content .link {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.banner-create-account .banner-content .link a {
  height: 60px;
  line-height: 60px;
  /* border-radius: 3px; */
  background-color: $wain_btn_color;
  /* background-color: $color-curious-blue; */
  font-family: $font-family-roboto;
  font-size: $font-big;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $color-white;
  display: block;
  width: 410px;
  margin: auto;
  border: $wain_bg;
  &:hover,
  &:active,
  &:focus {
    background-color: #fff;
    outline: none;
    color: $wain_btn_color;
    transition: all 0.5s;
  }
  span {
    margin-right: 15px;
  }
}

.header_home_landing .header_left {
  /* padding-left: 140px; */
  min-width: 710px;
  width: 40%;
  float: left;
}

.header_home_landing .header_left h2 {
  font-family: $font-family-roboto;
  font-size: 55px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-white;
  margin-top: 40px;
  margin-bottom: 20px;
}

.header_home_landing .header_left h2 > span.bold {
  font-family: $font-family-roboto-bold;
}

.header_home_landing .header_left .decription {
  width: 391px;
  font-family: $font-family-roboto;
  font-size: $font-medium;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $color-white;
}

.header_home_landing .header_left .tool {
  margin-top: 30px;
  float: left;
  width: 100%;
}

.header_home_landing .header_left .tool a {
  border-radius: 3px;
  background-color: $color-white;
  padding: 11px 33px;
  font-family: $font-family-roboto;
  font-size: $font-root;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  letter-spacing: 1.1px;
  text-align: center;
  color: $color-eden;
  text-transform: uppercase;
  float: left;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom;
    color: $color-white;
    outline: none;
  }
  span {
    margin-left: 15px;
  }
}

.header_home_landing .header_right {
  float: left;
  /* max-width: calc(100% - 710px); */
  width: 60%;
  position: relative;
  overflow: hidden;
}

.header_home_landing .header_right .right {
  float: right;
}

#content-landing{

  background-size: 100% 385px;

  @media screen and (max-width: 992px) {
    background: none;
  }
}

.lading-page {
  /* min-width: 1200px; */
  background-color: var(--background-home-page);

  .header_home_landing {
    width: 100%;
    position: relative;
    z-index: 2;
    min-height: 580px;
    /* background-image: linear-gradient(to top, #228a7d, $color-jungle-green); */
    padding-top: 35px;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 500px;
      max-height: 100%;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -10;
    } // &:before{
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 130px;
    //   max-height: 100%;
    //   top: 0px;
    //   left: 0px;
    //   z-index: -9;
    //   position: absolute;
    //   background: -moz-linear-gradient(0deg, rgba(254, 251, 251, 0) 0, #000000 100%);/* FF3.6+ */
    //   background: -webkit-gradient(linear, 0deg, color-stop(0, rgba(254, 251, 251, 0)), color-stop(100%, 000000));/* Chrome,Safari4+ */
    //   background: -webkit-linear-gradient(0deg, rgba(254, 251, 251, 0)B 0, #000000 100%);/* Chrome10+,Safari5.1+ */
    //   background: -o-linear-gradient(0deg, rgba(254, 251, 251, 0) 0, #000000 100%);/* Opera 11.10+ */
    //   background: -ms-linear-gradient(0deg, rgba(254, 251, 251, 0) 0, #000000 100%);/* IE10+ */
    //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
    //   background: linear-gradient(0deg, rgba(254, 251, 251, 0) 0, #000000 100%);/* W3C */
    // }
    .group_tit_home_landing {
      text-align: center;
      .img_title_landing {
        width: 60%;
        display: block;
        float: right;
        text-align: left;
        padding-left: 5%;
        background: url("@/assets/images/landing/landing-3.png") top left;
        background-size: auto auto;
        img {
          max-width: 100%;
        }
      }
      .title_landing {
        display: block;
        width: 40%;
        float: left;
        text-align: right;
        padding-right: 5%;
        .center_left_ld {
          width: 300px;
          text-align: center;
          max-width: 100%;
          float: right;
        }
        h2 {
          margin-top: 75px;
          margin-bottom: 30px;
          color: $color-blue-cerulean-light;
          font-weight: 600;
          line-height: 60px;
          display: block;
          font-size: 60px;
          text-transform: uppercase;
        }
      }
      .hidden-button {
        opacity: 0;
        visibility: hidden;
      }
      .button_resg_home_landing {
        width: 180px;
        max-width: 100%;
        height: 40px;
        border: 2px solid $color-corn-pale;
        color: $color-white;
        font-size: $font-medium;
        line-height: 20px;
        padding: 8px 20px;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0px 0px;
        border-radius: 25px;
        background-color: $color-corn-pale;
        outline: -webkit-focus-ring-color auto 0px !important;
        margin-bottom: 65px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        &:hover,
        &:active,
        &:focus {
          background: $color-corn;
          border-color: $color-corn;
          color: $color-white;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
      }
    }
    .insurance {
      /* margin-left: 140px; */
      margin-bottom: 30px;
    }
    .insurance > span {
      color: $color-white;
      font-size: $font-medium;
      margin-right: 30px;
    }
  }

  .c0b225b {
    background-image: linear-gradient(to top, #252a92, #252a92) !important;
  }
  .amal_slide {
    text-align: center;
    position: relative;
    padding-top: 45px;
    .amal_tit {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 55px;
      color: #fff;
      padding-left: 2%;
      /* text-align: left; */
    }
    .amal_img {
      width: 55%;
      display: block;
      margin: 20px auto 0px;
    }
    .amanpuri_img {
      display: block;
      margin: 0px auto 20px;
      width: 10%;
    }
    .amal_time {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 24px;
      display: inline-block;
      padding: 8px 5px;
      font-family: "Roboto-Bold", sans-serif;
      position: absolute;
      bottom: 10%;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
    }
    &:hover {
      cursor: pointer;
    }
  }

  .bg_tranding {
    background: url("@/assets/images/rebate/tranding_banner.png") no-repeat;
    background-size: 100% 100%;
  }
  .bg_btc {
    background: url("@/assets/images/rebate/btc-bg.png");
    background-size: 100% 100%;
    position: relative;
    .bonus_txt {
      width: 43%;
      padding: 104px 0px 0px 153px;
      z-index: 1;
      .bonus_tit {
        letter-spacing: 20px;
        line-height: 74px;
        font-size: 66px;
      }
      .bonus_time {
        letter-spacing: 7px;
        margin-top: 20px;
        line-height: 34px;
        font-size: 17px;
        font-weight: 400;
      }
      .w_90 {
        width: 90%;
      }
      .bonus_btn {
        word-spacing: 5px;
        margin-top: 22px;
        background: linear-gradient(to right, #537cb6 0%, #615c9e 100%);
      }
    }
    .btc_banner_img {
      height: 580px;
      width: 526px;
      position: absolute;
      background-image: url("@/assets/images/rebate/btc_banner_img.png");
      background-size: 100% 92%;
      z-index: 2;
      top: 0px;
      left: 92%;
      transform: translateX(-100%);
    }
  }
  .image-menu {
    max-width: 450px;
    max-height: 250px !important;
  }

  .swiper-slide {
    height: 300px;
  }

  .group_slider_home_landding {
    width: 1410px;
    display: block;
    margin: auto;
    overflow: hidden;
    max-width: 96%;
    .row_15 {
      margin: 0px -15px;
      .col-4-slider {
        width: 33.33%;
        display: block;
        float: left;
        padding: 0px 15px;
        .box_slider {
          display: block;
          float: left;
          width: 100%;
          height: auto;
          background: $color-grey-dusty;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .box-chart {
    position: relative;
    z-index: 2;
    margin: auto;
    overflow: hidden;
    text-align: center;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 40px 40px 20px 40px;
    width: 100%;
    .curreny-chart {
      float: none;
      overflow: hidden;
      text-align: left;
      width: 23%;
      /*width: calc(100% / 4 - 24px);*/
      margin-right: 1%;
      margin-left: 1%;
      a {
        display: block;
        overflow: hidden;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .ladding_content_page {
    width: 1410px;
    max-width: 96%;
    position: relative;
    margin: auto;
    display: block;
  }
  .bottom_landing {
    width: 100%;
    display: block;
    clear: both;
    .bottom_left_ld {
      display: block;
      float: left;
      width: 55%;
      .img_left_bot {
        width: 360px;
        max-width: 100%;
        height: 180px;
        float: right;
        margin-right: 40px;
      }
    }
    .bottom_right_ld {
      display: block;
      float: left;
      width: 45%;
      .txt_button_bot {
        font-size: $font-big-20;
        font-weight: 700;
        color: $color-white;
        margin-top: 20px;
      }
      .des_button_bot {
        font-size: $font-root;
        color: $color-grey;
        line-height: 20px;
        margin: 15px 0px 5px 0px;
      }
      .button_dl_landing {
        padding: 9px 21px;
        border: 1px solid $color-yellow;
        color: $color-yellow;
        font-size: $font-root;
        line-height: 60px;
        font-weight: 700;
        border-radius: 25px;
        background: transparent;
        outline: -webkit-focus-ring-color auto 0px !important;
        a {
          color: $color-yellow;
        }
      }
      .button_dl_landing:focus,
      .button_dl_landing:active,
      .button_dl_landing:hover {
        background: $color-yellow;
        -webkit-transition: 0.5s;
        /* Safari */
        transition: 0.5s;
        color: $color-white;
        border-color: $color-yellow;
        a {
          color: $color-white;
        }
      }
    }
  }
  .margin-l-15 {
    margin-left: 15px;
  }
}

@media (max-width: 1399px) {
  // .header_home_landing {
  //  .header_left {
  //    h2 {
  //      font-size: 48px;
  //    }
  //  }
  // }
  .banner-announcements .banner-content .content-2 .item-group-2 .item-content {
    padding: 23px 0px 40px 10px;
  }
}
</style>

<style lang="scss">

#markets_table_landing {
  margin: 0 auto;
  padding: 0px 0px 0px;
  max-width: 1510px;

  @media screen and (max-width: 1440px) {
    padding: 0px 16px 0px;
  }

  @media screen and (max-width: 992px) {
    padding: 23px 15px 0px !important;
  }
}



.lading-page {
  overflow: hidden;
  .swiper-container {
    height: 580px;
  }
  .swiper-slide {
    // height: 580px;
  }
}


</style>
