<template>
  <modal :name="modalName" :enableClose="false" width="750">
    <template slot="body">
      <div id="account-authen">
        <div class="wrap-icon-close" @click="closeModal">
          <i class="icon-close"></i>
        </div>

        <div class="box-title title-medium-small">
          <h3>{{ $t("account.identity.enable_des") }}</h3>
        </div>

        <!-- End title -->
        <div class="authen-container">
          <!-- Begin Steps -->
          <div class="steps">
            <div class="list_authen">
              <div class="step step-0" :class="{ active: step.current >= 0 }">
                <div class="step_number">1</div>
                <div class="step_number_des dp-none">
                  {{ $t("account.identity.fill_name") }}
                </div>
              </div>
              <div class="step step-1" :class="{ active: step.current >= 1 }">
                <div class="step_number">2</div>
                <div class="step_number_des dp-none">
                  {{ $t("account.identity.verify_document") }}
                </div>
              </div>
              <div class="step step-2" :class="{ active: step.current === 2 }">
                <div class="step_number">3</div>
                <div class="step_number_des dp-none">
                  {{ $t("account.identity.reviewing") }}
                </div>
              </div>
            </div>
            <div class="none dp-show">
              <div v-if="step.current === 0" class="step_des">
                {{ $t("account.identity.fill_name") }}
              </div>
              <div v-if="step.current === 1" class="step_des">
                {{ $t("account.identity.verify_document") }}
              </div>
              <div v-if="step.current === 2" class="step_des">
                {{ $t("account.identity.reviewing") }}
              </div>
            </div>
          </div>
          <!-- End steps -->

          <!-- Begin StepContent -->
          <div class="step-content">
            <!-- end-step-0 -->
            <div v-show="step.current == 0">
              <!-- <p class="sub-title-step">{{ $t("account.otp.scan_qrcode") }}</p> -->
              <div class="content-box fourth">
                <div class="verify-input">
                  <div class="login">
                    <div class="input-group">
                      <div class="name_input">
                        {{ $t("account.identity.txt_first_name") }}
                      </div>
                      <input
                        v-model="first_name"
                        @keyup.enter="verifyKYC"
                        @focus="resetError"
                        name="first_name"
                        type="text"
                        data-vv-validate-on="none"
                        v-validate="'required'"
                        :disabled="isVerifyStep1"
                        class="input"
                        :class="{ error: errors.has('first_name') }"
                        :placeholder="$t('common.placeholders.first_name')"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="errors.has('first_name')"
                      >
                        {{ errors.first("first_name") }}
                      </div>
                    </div>
                  </div>
                  <div class="authencode">
                    <div class="input-group">
                      <div class="name_input">
                        {{ $t("account.identity.txt_last_name") }}
                      </div>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="last_name"
                        @keyup.enter="verifyKYC"
                        @focus="resetError"
                        name="last_name"
                        :data-vv-as="$t('last_name')"
                        data-vv-validate-on="none"
                        v-validate="'required'"
                        :disabled="isVerifyStep1"
                        class="input"
                        :class="{ error: errors.has('last_name') }"
                        :placeholder="$t('common.placeholders.last_name')"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="errors.has('last_name')"
                      >
                        {{ errors.first("last_name") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end-step-1 -->
            <div v-show="step.current == 1">
              <!-- <p class="sub-title-step">{{$t('account.otp.save_key')}}</p> -->
              <div class="kyc-content">
                <iframe :src="workflowRunUrl" allow="camera"></iframe>
              </div>
            </div>
            <div v-show="step.current == 2">
              <!-- <p class="sub-title-step">{{ $t("account.otp.enable_title") }}</p> -->
              <div class="content-box fourth">
                <div class="text-note">
                  <p>
                    {{ $t("account.identity.note") }}
                  </p>
                  <p>
                    {{ $t("account.identity.note_des") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="step-button">
              <button
                class="btn-prev"
                v-show="step.current >= 1"
                @click="backStep"
              >
                {{ $t("account.otp.back") }}
              </button>
              <!-- <span class="para" v-show="step.current == 0">{{$t('account.otp.enable_text1')}}</span>
                <span class="para" v-show="step.current == 1">{{$t('account.otp.enable_text2')}}</span>
                <span class="para" v-show="step.current == 2">{{$t('account.otp.enable_text3')}}</span> -->
              <!-- <button id="btnNext" class="btn-next" v-show="step.current <= 2" @click="nextStep">
                {{$t('account.otp.next')}}
              </button> -->
              <button
                id="btnNext"
                :disabled="isSubmitting"
                class="btn-next"
                v-show="step.current == 0"
                @click="verifyKYC"
              >
                {{ $t("account.otp.next") }}
              </button>
              <button
                :disabled="isSubmitting"
                class="btn-next"
                v-show="step.current == 1"
                @click="checkKYC"
              >
                {{ $t("account.otp.next") }}
              </button>
              <button
                :disabled="isSubmitting"
                class="btn-enable btn-next"
                v-show="step.current == 3"
                @click="verifyCode"
              >
                {{ getSubmitName($t("account.otp.next")) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/shared_components/common/Modal";
import rf from "@/request/RequestFactory";

export default {
  data() {
    return {
      isShowing: true,
      step: {
        current: 0,
        max: 3,
      },
      isVerifyOTP: false,
      isVerifyStep1: false,
      first_name: "",
      last_name: "",
      modalName: "EnableIdentityVerified",
      workflowRunUrl: "",
      bank_status: "init",
    };
  },
  components: {
    Modal,
  },
  props: {
    workflowRunUrlDefault: { type: String, default: null }, // Code Peter update 31/07/2024
    // workflowRunUrlDefault: { type: String, default: "" }, // Code cũ của Life
    firstName: { type: String, default: "" },
    lastName: { type: String, default: "" },
    bankStatus: { type: String, default: "" },
    status: { type: String, default: "" },
  },
  watch: {
    // workflowRunUrlDefault: function(newVal) {
    //   if(newVal) {
    //     this.step.current = 1;
    //     this.workflowRunUrl = newVal;
    //   }
    // },
    // firstName: function(newVal) {
    //   if(newVal) {
    //     this.first_name = newVal;
    //   }
    // },
    // lastName: function(newVal) {
    //   if(newVal) {
    //     this.last_name = newVal;
    //   }
    // }
  },
  methods: {
    nextStep() {
      this.jumpStep(this.step.current + 1);
    },
    backStep() {
      this.jumpStep(this.step.current - 1);
    },
    jumpStep(target) {
      target = Math.max(0, target);
      target = Math.min(this.step.max, target);

      if (target < 1 || (this.last_name && this.first_name)) {
        this.step.current = target;
      }
      $("#btnNext").blur();
    },
    resetError() {
      this.errors.clear();
    },
    isNumber($event) {
      this.resetError();
      const keyCode = $event.which ? $event.which : $event.keyCode;
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    closeModal() {
      this.isShowing = false;
      this.$emit(`${this.modalName}:CLOSE`);
    },
    async checkKYC() {
      try {
        this.startSubmit();
        const response = await rf.getRequest("UserRequest").getUserKyc();
        if (response?.success) {
          const dataKyc = response?.data;

          if (
            (dataKyc?.status === "pending" && dataKyc?.workflowRunUrl === "") ||
            dataKyc?.bank_status !== "init"
          ) {
            this.nextStep();
            this.$emit(`refreshData`);
          } else {
            Message.error(
              "You have not completed verification",
              {},
              { position: "bottom_left" }
            );
          }
        }
        this.endSubmit();
        // Message.success(
        //   window.i18n.t('account.otp.success'),
        //   {},
        //   { position: 'bottom_left' }
        // );
        // this.nextStep();
      } catch (error) {
        this.endSubmit();
      }
    },
    async verifyKYC() {
      if (this.isSubmitting) {
        return;
      }
      await this.$validator.validateAll();
      if (this.errors.any()) {
        return;
      }
      if (this.isVerifyStep1) {
        return this.nextStep();
      }
      try {
        this.startSubmit();
        const response = await rf.getRequest("UserRequest").updateUserKyc({
          first_name: this.first_name,
          last_name: this.last_name,
        });
        console.log("response", response);
        if (response?.success) {
          this.workflowRunUrl = response?.data?.url;
          this.isVerifyStep1 = true;
        }
        this.endSubmit();
        // Message.success(
        //   window.i18n.t('account.otp.success'),
        //   {},
        //   { position: 'bottom_left' }
        // );
        this.nextStep();
      } catch (error) {
        this.endSubmit();
        this.errors.clear();
      }
    },
    async verifyCode() {
      if (this.isSubmitting) {
        return;
      }
      await this.$validator.validateAll();
      if (this.errors.any()) {
        return;
      }
      try {
        this.startSubmit();
        await rf.getRequest("UserRequest").addSecuritySettingOtp({
          code: this.authenticationCode,
          password: this.password,
        });
        this.endSubmit();
        Message.success(
          window.i18n.t("account.otp.success"),
          {},
          { position: "bottom_left" }
        );
        this.closeModal();
      } catch (error) {
        this.endSubmit();
        this.errors.clear();
        // const errorCode = error.response.data.errors?.code;
        // const errorPassword = error.response.data.errors?.password;
        // if(errorCode) {
        //   this.errors.add({
        //     field: 'code',
        //     msg: this.$t('validation.otp_incorrect')
        //   });
        // }
        // if(errorPassword) {
        //   this.errors.add({
        //     field: 'password',
        //     msg: this.$t('validation.custom.password.correct_password')
        //   });
        // }
        const errorOtp = error.response.data.errors;
        window._.each(errorOtp, (value, key) => {
          this.errors.add(
            key,
            this.$t(
              `${value[0].formatUnicorn({ values: ".jpg, .jpeg, .png" })}`
            )
          );
        });
      }
    },
    getEventHandlers() {
      return {
        showCommonModal: this.onModalShowing,
      };
    },
    onModalShowing(modalName) {
      if (modalName == "EnableIdentityVerified") {
        this.isShowing = true;
      }
    },
  },
  created() {
    this.$on("showCommonModal", (modalName, step) => {
      if (modalName === this.modalName) {
        this.step.current = 0;
        this.first_name = "";
        this.last_name = "";
        this.isVerifyStep1 = false;
      }
      if (this.firstName && this.status) {
        this.first_name = this.firstName;
        // this.isVerifyStep1 = true;
      }
      if (this.lastName && this.status) {
        this.last_name = this.lastName;
        // this.isVerifyStep1 = true;
      }
      if (this.workflowRunUrlDefault && this.status !== "rejected") {
        this.step.current = 1;
        this.workflowRunUrl = this.workflowRunUrlDefault;
      }
      if (
        this.bankStatus !== "init" &&
        this.workflowRunUrlDefault === "" &&
        this.status !== "rejected"
      ) {
        this.step.current = 2;
        this.isVerifyStep1 = true;
      }
      if(step===1){
        this.step.current = 0;
        this.isVerifyStep1 = false;
      }
    });
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (
        event.keyCode === 13 &&
        this.modalName == "EnableIdentityVerified" &&
        this.isShowing &&
        this.step.current == 3
      ) {
        this.verifyCode();
      }
    });

    // if (this.firstName) {
    //   console.log('this.firstName', this.firstName)
    //   this.first_name = this.firstName;
    // }
    // if (this.lastName) {
    //   this.last_name = this.lastName;
    // }
    // if (this.workflowRunUrlDefault) {
    //   this.step.current = 1;
    //   this.workflowRunUrl = this.workflowRunUrlDefault;
    // }
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/common.scss";

// This line was added by Mr. Peter on August 18th 2024
#EnableIdentityVerified {
  &.modal {
    .modal-dialog {
      height: 90%;
      overflow: auto;
      .modal-content {
        .modal-footer {
          display: none;
        }
      }
    }
  }
}

#EnableGoogleAuthentication {
  //pointer-events: auto;
  overflow: auto;
}
#account-authen {
  width: 100%;
  .wrap-icon-close {
    display: flex;
    justify-content: flex-end;
  }
  .icon-close {
    border-radius: 50%;
    color: var(--color-icon-close);
    position: relative;
    left: 15px;
    top: 30px;
    cursor: pointer;
  }
  .text-grey {
    color: $color-grey-dusty;
    font-size: $font-small;
  }
  .text-gg {
    color: var(--text-primary-color) !important;
    font-weight: 700;
  }
  .text-bold {
    font-family: $font-family-roboto-medium;
  }
  .box-title {
    margin-bottom: 20px;
    h3 {
      font-family: $font-inter-bold;
      color: var(--text-dropdown);
      font-size: 34px;
      line-height: 40px;
      text-align: center;
    }
  }
  .authen-container {
    width: 100%;
    margin: 0 auto;
    background: var(--bgr-dropdown);

    .steps {
      .list_authen {
        display: flex;
        .step {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 5px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 28px;
            width: 50%;
            border-top: 2px dashed var(--bgr-btn);
            transform: translateY(-50%) translateX(-100%);
          }
          &:first-child::before {
            display: none;
          }
          .step_number {
            width: 36px;
            border-radius: 40px;
            color: var(--color-step);
            font-family: $font-inter-regular;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            border: 2px solid var(--color-step);
            display: inline-block;
            padding: 5px 0;
          }
          .step_number_des {
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: var(--color-step);
          }
          &.active {
            .step_number {
              background: var(--text-dropdown);
              border: 2px solid transparent;
              color: var(--bgr-pagination);
            }
            .step_number_des {
              color: var(--text-primary-color);
            }
            &::before {
              border-top: 2px dashed $color-aqua-green;
            }
          }
        }
      }
    }
    .step-content {
      padding-top: 20px;
      margin: auto;
      .sub-title-step {
        font-family: $font-inter-bold;
        color: var(--text-dropdown);
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 30px;
      }
      .kyc-content {
        height: 662px;
        overflow: auto;
        scrollbar-width: none;
        iframe {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
      .content-box {
        width: 100%;
        text-align: center;
        max-width: 600px;
        margin: auto;
        .title-step {
          font-size: $font-medium-bigger;
          font-family: $font-family-roboto-medium;
          margin-bottom: 40px;
          color: $color-black;
        }
        .applink {
          display: flex;
          gap: 30px;
          justify-content: center;
          margin-top: 40px;
        }
      }
      .qr-code {
        margin: 40px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        .img_qr {
          width: 130px;
          height: 130px;
          padding: 10px;
          background: #ffffff;
          box-shadow: 0px 3px 8px rgba(51, 51, 51, 0.102);
          border-radius: 8px;
        }
      }
      .show-key {
        font-size: 22px;
        line-height: 27px;
        font-family: $font-inter-bold;
        color: $dark-1;
      }
      .backup-key {
        font-family: $font-inter-regular;
        color: var(--text-close);
        font-size: 18px;
        line-height: 20px;
      }
      .verify-input {
        .name_input {
          font-family: $font-inter-regular;
          color: var(--text-primary-color);
          font-size: 18px;
          line-height: 22px;
          text-align: left;
          margin-bottom: 8px;
        }
        .input {
          background: var(--bgr-hover-row);
          border-radius: 8px;
          padding: 14px 15px;
          border: none;
          width: 100%;

          font-size: 18px;
          line-height: 21px;
          font-family: $font-inter-regular;
          color: var(--text-primary-color);
          &::placeholder {
            color: var(--text-close);
          }
          &.error {
            border: 1px solid $text-color-red;
          }
        }
        .invalid-feedback {
          color: $text-color-red;
          font-family: $font-inter-regular;
          font-size: 14px;
          line-height: 17px;
          text-align: left;
          margin-top: 8px;
        }
      }
    }
    .step-button {
      margin: 60px auto 0;
      display: flex;
      justify-content: center;
      gap: 40px;
      max-width: 600px;
      button {
        flex: 1;
        min-width: 240px;
        max-width: 440px;
        &.btn-next {
          @include btn-common(yes);
        }
        &.btn-prev {
          @include btn-common(no);
        }
        &:disabled {
          opacity: 0.7;
        }
      }
    }
  }
}

#EnableGoogleAuthentication {
  &.modal .modal-dialog .modal-content {
    .modal-body {
      padding: 30px 50px 50px;
    }
    .modal-footer {
      display: none;
    }
  }
}

.text-note {
  font-family: $font-inter-regular;
  color: var(--text-primary-color);
  font-size: 18px;
  line-height: 22px;
  margin-top: 30px;
  text-align: left;
  p {
    margin: 0;
  }
}

@media screen and (max-width: 1100px) {
  #EnableGoogleAuthentication,
  #EnableIdentityVerified {
    .modal-dialog {
      width: 620px !important;
    }
    &.modal .modal-dialog .modal-content {
      .modal-body {
        padding: 30px 16px;
      }
      #account-authen {
        .wrap-icon-close {
          padding-right: 20px;
        }
        .title-medium-small {
          margin-bottom: 30px;
          h3 {
            font-size: 20px;
            line-height: 24px;
          }
        }
        .authen-container {
          .steps {
            .list_authen {
              .step {
                &::before {
                }
                .step_number {
                }
                .step_number_des {
                  &.dp-none {
                    display: none;
                  }
                }
                &.active {
                  .step_number {
                    width: 32px;
                    font-size: 16px;
                    line-height: 19px;
                  }
                  .step_number_des {
                  }
                  &::before {
                  }
                }
              }
            }
            .dp-show {
              margin: 20px 0;
              display: block;
              .step_des {
                font-family: $font-inter-regular;
                font-size: 16px;
                line-height: 19px;
                color: var(--text-primary-color);
                text-align: center;
              }
            }
          }
          .step-content {
            padding: 0;
            .sub-title-step {
              font-size: 18px;
              line-height: 21px;
              margin-bottom: 25px;
            }
            .content-box {
              .title-step {
              }
              .applink {
                margin-top: 0;
                gap: 15px;
                flex-direction: column;
              }
            }
            .qr-code {
              margin-top: 25px;
              img {
                width: 100px;
                height: 100px;
              }
            }
            .show-key {
              font-size: 16px;
              line-height: 19px;
            }
            .backup-key {
              font-size: 14px;
              line-height: 16px;
            }
            .verify-input {
              .name_input {
                font-size: 14px;
                line-height: 17px;
              }
              .input {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
          .step-button {
            margin-top: 30px;
            gap: 15px;
            button {
              min-width: unset;
            }
          }
        }
      }
    }
  }
}
</style>
