<template>
  <div id="CalculatorModal">
    <modal :name="modalName" width="440">
      <template slot="body">
        <div class="header_calulator_modal">
          <div class="item" @click="showCalculatorTab = 0" v-bind:class="{'active' : showCalculatorTab === 0}">{{ $t('margin.profit_loss') }}</div>
          <div class="item" @click="showCalculatorTab = 1" v-bind:class="{'active' : showCalculatorTab === 1}">{{ $t('margin.target_price') }}</div>
          <div class="item" @click="showCalculatorTab = 2" v-bind:class="{'active' : showCalculatorTab === 2}">{{ $t('margin.liquidation_price') }}</div>
        </div>
        <div class="clearfix"></div>
        <div class="body_calulator_modal">
          <tab-profit-loss v-if="showCalculatorTab == 0"/>
          <tab-target-price v-if="showCalculatorTab == 1"/>
          <tab-liquidation-price v-if="showCalculatorTab == 2"/>
        </div>
        <div class="clearfix"></div>
        <div class="footer_calulator_modal">
          <div class="left">{{ $t('margin.position') }}: {{ position.current_qty }}</div>
          <div class="right">{{ $t('margin.contract') }}: {{ position.symbol }}</div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Modal from '@/components/shared_components/common/Modal.vue';
  import TabProfitLoss from './TabProfitLoss.vue';
  import TabTargetPrice from './TabTargetPrice.vue';
  import TabLiquidationPrice from './TabLiquidationPrice.vue';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      Modal,
      TabProfitLoss,
      TabTargetPrice,
      TabLiquidationPrice,
    },
    data() {
      return {
        modalName:'CalculatorModal',
        showCalculatorTab: 0,
      }
    },
    computed: {
      ...mapGetters(['position'])
    },
    methods: {
      closeModal () {
        this.$emit(`${this.modalName}:CLOSE`);
      },      
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .header_calulator_modal {
    background-color: $color-bright-gray;
    display: block;
    width: 100%;
    overflow: hidden;
    .item {
      display: inline-block;
      float: left;
      height: 50px;
      line-height: 19px;
      padding: 18px 18px 16px 18px;
      color: $color-grey-dark;
      font-family: $font-family-roboto-bold;
      font-size: $font-medium-bigger;
      cursor: pointer;
      margin-right: 1px;
      &:hover {
        background-color: $color-white;
      }
      &.active {
        background-color: $color-white;
        color: $color-jungle-green;
      }
    }
  }
  .body_calulator_modal {
    padding: 15px 20px;
    min-height: 275px;
  }
  .footer_calulator_modal {
    overflow: hidden;
    display: block;
    width: 100%;
    line-height: 20px;
    background-color: $color-dark-green;
    color: $color-white;
    font-size: $font-small;
    padding: 8px 20px 7px 20px;
    font-family: $font-family-roboto-medium;
    .left {
      display: inline-block;
      float: left;
    }
    .right {
      display: inline-block;
      float: right;
      text-align: right;
    }
  }
</style>


<style lang="scss">
  @import '@/assets/sass/variables';

  #CalculatorModal {
    .modal-header {
      // height: 0px;
      padding: 0px;
      .close {
        position: absolute;
        z-index: 50;
        left: auto;
        right: 5px;
        top: 13px;
        // .icon-close {
        //   background-color: transparent !important;
        // }
      }
    }
    .modal-body {
      padding: 0px;
    }
    .modal-footer {
      display: none;
    }
    .modal-content {
      border-radius: 3px !important;
      overflow: hidden;
      border: 0px;
    }
  }
</style>
