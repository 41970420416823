<template>
    <div :id="containerId" class="trading-view-chart"></div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import TimezoneUtils from '@/common/TimezoneUtils';
  import Const from '@/common/Const';

  export default {
    components: {
    },
    props: {
      multipleChart   : { type: Boolean, default: false },
      chartData       : { type: String, default: 'contract-series' },
      symbol          : { default: 'BTCUSD', type: String },
      resolution      : { default: '15', type: String },
      minTick         : { default: 'default', type: String },
    },
    data() {
      const dataContractSeries = process.env.VUE_APP_API_URL + "/api/v1/margin/chart";
      const dataContractIndiceSeries = process.env.VUE_APP_API_URL + "/api/v1/margin/indice-chart";
      const uri = this.chartData == 'contract-series' ? dataContractSeries : dataContractIndiceSeries;
      var dataFeed           = new Datafeeds.UDFCompatibleDatafeed(uri);
      dataFeed.getBars       = this.getBars.bind(this);
      dataFeed.subscribeBars = this.subscribeBars.bind(this);
      return {
        tradingViewChart   : null,
        isChartReady       : false,
        realtimeDatafeed   : dataFeed,
        lastBar            : undefined,
        onRealtimeCallback : undefined
      }
    },
    computed: {
      containerId() {
        return 'trading-view-chart';
      }
    },
    methods: {
      subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        this.onRealtimeCallback = onRealtimeCallback;
      },
      getBars (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        this.$emit('update:resolution', this.resolution);
        let symbol      = symbolInfo.ticker;
        let params = {
          symbol     : this.symbol,
          resolution : this.resolutionToMilliseconds(resolution),
          from       : from,
          to         : to
        };
        let self = this;
        if(this.chartData == 'contract-series'){

          rf.getRequest('MarginRequest').getChartBars(params).then(bars => {
            bars = window._.forEach(bars, function(bar) {
              bar.time = parseInt(bar.time);
              bar.open   = parseFloat(bar.open);
              bar.close  = parseFloat(bar.close);
              bar.high   = parseFloat(bar.high);
              bar.low    = parseFloat(bar.low);
              bar.volume = parseFloat(bar.volume);
            });
            if (bars.length && !self.lastBar) {
              self.lastBar = bars[bars.length - 1];
            }
            onHistoryCallback(bars, {noData: (bars.length == 0)});
          });

        }else{

          rf.getRequest('MarginRequest').getIndiceChartBars(params).then(bars=>{
            bars = window._.forEach(bars.filter(el=>!!el.low), function(bar) {
              bar.time = parseInt(bar.time);
              bar.open   = parseFloat(bar.open);
              bar.close  = parseFloat(bar.close);
              bar.high   = parseFloat(bar.high);
              bar.low    = parseFloat(bar.low);
              bar.volume = parseFloat(bar.volume);
            });
            if (bars.length) {
              let currentTime = Math.floor(Date.now() / resolution) * resolution;
              let lastBar = bars[bars.length - 1];
              if (lastBar.time > currentTime) {
                  bars.pop();
              }
            }
            if (bars.length && !self.lastBar) {
              self.lastBar = bars[bars.length - 1];
            }
            onHistoryCallback(bars, {noData: (bars.length == 0)});
          });
          
        }
      },
      getSocketEventHandlers() {
        return {
          MarginTradesCreated: this.onMarginTradesCreated,
        }
      },
      onMarginTradesCreated(data) {
        if (data.length === 0 || this.symbol !== data[0].instrument_symbol) {
          return;
        }
        for (let trade of data) {
            this.onMarginTradeCreated(trade);
        }
      },
      onMarginTradeCreated(data) {
        let price       = parseFloat(data.price);
        let createdAt   = data.created_at;
        let volume      = parseFloat(data.quantity);
        let resolution = this.resolutionToMilliseconds(this.resolution);
        let time        = Math.floor(createdAt/resolution) * resolution;
        let existingBar = this.lastBar;
        if (this.lastBar) {
          if (time == this.lastBar.time) {
            existingBar.volume += volume;
            existingBar.high   = Math.max(price, existingBar.high);
            existingBar.low    = Math.min(price, existingBar.low);
            existingBar.close  = price;
          } else {
            existingBar = {
              "time"   : time,
              "high"   : Math.max(price, existingBar.close),
              "low"    : Math.min(price, existingBar.close),
              "open"   : existingBar.close,
              "close"  : price,
              "volume" : volume,
            };
          }
        } else {
          // existingBar = {
          //   "time"   : time,
          //   "high"   : price,
          //   "low"    : price,
          //   "open"   : price,
          //   "close"  : price,
          //   "volume" : volume,
          // };
          return;
        }
        this.lastBar = existingBar;
        if (this.onRealtimeCallback != undefined) {
          this.onRealtimeCallback(existingBar);
        }
      },
      createEmptyBarIfNeeded() {
        let createdAt   = Date.now();
        let resolution  = this.resolutionToMilliseconds(this.resolution);
        let time        = Math.floor(createdAt / resolution) * resolution;

        if (this.lastBar && time > this.lastBar.time) {
          let price = this.lastBar.close;
          this.lastBar = {
            "time"   : time,
            "high"   : price,
            "low"    : price,
            "open"   : price,
            "close"  : price,
            "volume" : 0,
          };
          if (this.onRealtimeCallback) {
            this.onRealtimeCallback(this.lastBar);
          }
        }
      },
      resolutionToMilliseconds(resolution) {
        var minutes = parseInt(resolution);
        if (isNaN(minutes)) {
          minutes = Const.CHARTING_RESOLUTION_MULTIPLICITY[resolution];
        }
        return minutes * 60 * 1000;
      },
      createChart: function() {
        this.isChartReady = false;
        let disabled_features = [
            "use_localstorage_for_settings",
            // "left_toolbar", 
            "context_menus",
            "display_market_status",
            "timeframes_toolbar",
            "header_compare",
            "header_undo_redo",
            "header_saveload",
            "header_screenshot",
            "header_interval_dialog_button",
            "volume_force_overlay",
            "control_bar",
            "border_around_the_chart",
            "header_widget",
            "header_symbol_search",
            "chart_property_page_scales"
          ];
        this.tradingViewChart = new TradingView.widget({
          symbol                     : this.symbol,
          interval                   : this.resolution,
          locale                     : window.i18n.locale,
          timezone                   : TimezoneUtils.getTimezone(),
          container_id               : this.containerId,
          datafeed                   : this.realtimeDatafeed,
          library_path               : window.location.protocol + '//' + window.location.host + "/tradingview/charting_library/",
          autosize                   : true,
          withdateranges             : false,
          client_id                  : 'amanpuri',
          user_id                    : 'public_user_id',
          save_image                 : false,
          drawings_access            : { type: 'black', tools: [ { name: "Regression Trend" } ] },
          disabled_features          : disabled_features,
          indicators_file_name       : '/js/indexes.js',
          custom_css_url             : 'tradingview.css',
          enabled_features: [
            "dont_show_boolean_study_arguments",
            "hide_last_na_study_output",
          ],
          overrides: {
            "paneProperties.topMargin"                             : 15,
            "scalesProperties.showStudyLastValue"                  : true,
            "paneProperties.background"                            : "#f8f8f8",
            "mainSeriesProperties.candleStyle.upColor"             : "#299b82",
            "mainSeriesProperties.candleStyle.downColor"           : "#f74940",
            "mainSeriesProperties.candleStyle.drawWick"            : true,
            "mainSeriesProperties.candleStyle.drawBorder"          : true,
            "mainSeriesProperties.candleStyle.borderColor"         : "#299b82",
            "mainSeriesProperties.candleStyle.borderUpColor"       : "#299b82",
            "mainSeriesProperties.candleStyle.borderDownColor"     : "#f74940",
            "mainSeriesProperties.candleStyle.wickUpColor"         : '#299b82',
            "mainSeriesProperties.candleStyle.wickDownColor"       : '#f74940',
            "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
            "mainSeriesProperties.minTick"                         : this.minTick,
            "paneProperties.vertGridProperties.color"              : "#e0e0e0",
            "paneProperties.horzGridProperties.color"              : "#e0e0e0",
            "scalesProperties.lineColor"                           : "#999999"
          },
          studies_overrides: {
            "volume.volume.color.0": "rgba(247, 73, 64, 0.19)",
            "volume.volume.color.1": "rgba(41, 155, 130, 0.2)",
            "volume.volume.transparency": 15,
            "volume.volume ma.color": "#f74940",
            "volume.volume ma.transparency": 0,
            "volume.volume ma.linewidth": 1,
            "volume.volume ma.plottype": 'line',
            "volume.show ma": true,
          }
        });
        var self = this;
        this.tradingViewChart.onChartReady(function() {
          self.isChartReady = true;
          self.tradingViewChart.chart().createStudy('Moving Average', false, false, [20], null, {'Plot.linewidth': 1.3, 'Plot.color': '#0090eb'});
          self.tradingViewChart.chart().createStudy('Moving Average', false, false, [40], null, {'Plot.linewidth': 1.3, 'Plot.color': '#2dac91'});

          self.$watch('minTick', self.watchMinTick);
        });
        
      },
      watchMinTick(newMinTick) {
        const overrides = Object.assign({}, this.tradingViewChart.options.overrides);
        overrides['mainSeriesProperties.minTick'] = newMinTick;
        this.tradingViewChart.applyOverrides(overrides);
      },
      showIndicatorDialog() {
        if (!window._.isEmpty(this.tradingViewChart)) {
          this.tradingViewChart.chart().executeActionById('insertIndicator');
        }
      },
      showChartPropertiesDialog() {
        if (!window._.isEmpty(this.tradingViewChart)) {
          this.tradingViewChart.chart().executeActionById('chartProperties');
        }
      },
      
      // reloadTrandingChart() {
      //   const jsonObject = LocalStorageUtils.getItem('margin_trading_chart');
      //   if (jsonObject) {
      //     this.tradingViewChart.load(JSON.parse(jsonObject));
      //     return true;
      //   }
      //   return false;
      // },

      setBarStyle(type) {
        this.tradingViewChart.chart().setChartType(type);
      },
    },
    watch: {
      symbol: function(newSymbol) {
        this.lastBar  = null;
        if (typeof this.tradingViewChart != 'undefined'){
          this.createChart();
        }
        if (this.isChartReady && this.symbol) {
          this.tradingViewChart.setSymbol(this.symbol, this.resolution, function(){});
        }
      },
      isChartReady: function(val) {
        if(this.isChartReady){
          this.tradingViewChart.setSymbol(this.symbol, this.resolution, function(){});
        }
      },
      resolution: function(newResolution) {
        if (typeof this.tradingViewChart != 'undefined'){
          this.createChart();
        }
        if (this.isChartReady && this.symbol) {
          this.tradingViewChart.setSymbol(this.symbol, this.resolution, function(){});
        }
      }
    },
    mounted() {
      // Create chart for the first time
      var that = this;
      TradingView.onready(function()
      {
        that.createChart();
      });

      window.setInterval(() => {
        this.createEmptyBarIfNeeded();
      }, 15000);

      // Create chart when route change, re-render
      if (typeof this.tradingViewChart != 'undefined'){
        this.createChart();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .trading-view-chart {
    height: 100%;
  }
</style>