<template>
  <div class="coin_input">
    <div class="left-input">{{ leftTitleInput }}</div>
    <input type="text" class="form-control input-coin-number" :placeholder="placeholder" v-bind:class="{ 'border-bottom': component === OrderForm}" :value="value" :disabled="disabled" v-if="inputText"/>
    <currency-input
      :class="getClasses()"
      :precision="getPrecision()"
      :maxLengthCus='16'
      v-model="internalValue" :placeholder="placeholder"
      :disabled="disabled" :ref="refCurrencyInput" v-else/>

    <span class="unit-coin" :class="{'r10i': !visibleButton}" v-if="visibleUnit">{{unit | uppercase}}</span>

    <div class="drop-down-pair d-flex justify-content-between align-items-center" 
     v-if="selectToken"
     v-click-outside="clickOutSidePair" 
    >
      <span class="w-100 current-pair" @click="openDropPair=!openDropPair">{{pairName | uppercase}}</span>
      <i class="icon"  @click="openDropPair=!openDropPair" :class="{'icon-arrow2': openDropPair, 'icon-arrow1':!openDropPair}"></i>

    </div>
    <div :ref="name" class="position-absolute drop-list-pair" v-show="openDropPair"  v-if="selectToken">
        <div class="list-pair-drop">
          <template v-if="selectToken.length > 0">
            <div class="list-pair" v-for="pair in selectToken" :key="pair" @click="selectPair(pair)">
              <span>{{pair | uppercase}}</span>
            </div>
          </template>
          <div v-else class="list-no-data">
            {{$t('convert.noresult')}}
          </div>
        </div>
      </div>
    <button tabindex="32766" class="plus" v-if="visibleButton" @click="onIncreaseClicked()"><i class="icon-arrow-up-bold1"></i></button>
    <button tabindex="32767" class="minus" v-if="visibleButton" @click="onDecreaseClicked()"><i class="icon-arrow-down-bold1"></i></button>
  </div>
</template>
<script>
  import BigNumber from 'bignumber.js';
  import CurrencyInput from './CurrencyInput';

  export default {
    components: {
      CurrencyInput
    },
    props: {
      value               : {},
      precision           : {type: Number},
      unit                : {type: String},
      name                : {type: String},
      selectToken         : {type: Array},
      disabled            : {type: Boolean, default: false},
      inputText           : {type: Boolean, default: false},
      visibleButton       : {type: Boolean, default: false},
      classes             : {type: String},
      placeholder         : {type: String},
      component           : {type: String},
      leftTitleInput      : {type: String}
    },
    data() {
      return {
        internalValue: '',
        refCurrencyInput: this.getRefUniqueId(),
        OrderForm: "order-form",
        openDropPair: false,
        pairName: '',
        focusInput: false,

      };
    },
    watch: {
      value(newValue) {
        this.updateValue();
      },
      precision(newPrecision) {
        this.updatePrecision(newPrecision);
      },
      internalValue(val) {
       this.$emit('input', val);
     },
     name(val) {
      console.log('val', val)
     },
     selectToken(val) {
      if(val?.length >0) {
        const pairName = val[0]
        this.pairName = pairName
      }
     },
     
   },
   computed: {
    visibleUnit() {
      return !window._.isEmpty(this.unit);
    },
  },
  methods: {
    clickOutSidePair() {
      // this.openDropPair =false;
     const refName =  this.$refs?.[this.name]
     if(refName) {
      // console.log('clickOutSidePair',refName)
     }

    },
    onIncreaseClicked() {
      this.internalValue = this.newBigNumber(this.internalValue).plus(`${this.precision}`).toString();
    },

    onDecreaseClicked() {
      const result = this.newBigNumber(this.internalValue).sub(`${this.precision}`);
      if (result.gte(this.precision)) {
        this.internalValue = result.toString();
      }
    },

    getClasses() {
      if (!this.classes) {
        return 'input-coin-number';
      }
      return `input-coin-number ${this.classes}`;
    },

    getPrecision() {
      return this.unit === 'usd' ? 0 : 8;
    },

    getRefUniqueId() {
      return window._.uniqueId('input-coin-number-');
    },

    newBigNumber(value) {
      if (window._.isEmpty(value)) {
        value = 0;
      }
      return new BigNumber(value);
    },

    updateValue() {
      if (this.value instanceof BigNumber) {
       this.internalValue = this.value.toString();
       return;
      }
      this.internalValue = this.value;
    },

   updatePrecision(newPrecision) {
    const precision = Math.round(Math.log(1 / newPrecision) / Math.log(10));
    this.$refs[this.refCurrencyInput].setPrecision(precision);
  },

  selectPair(pair) {
    this.pairName = pair
    this.openDropPair = false;
    this.$emit('select-value', pair);

  },
},
mounted() {
  this.updateValue();
  if(this.selectToken?.length >0) {
    const pairName = this.selectToken[0]
    this.pairName = pairName
  }
}
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  .coin_input {

    .left-input{
      color: $color-storm-grey!important;
    }

    input {
      background: #17181A !important;
    }

    input[type="text"] {
      color: white;
      border: 1px solid #414244;

    }
  }

  .coin_input > span.unit-coin {
    color: $color-auro-metal;
  }
  .coin_input input[type=text]::-webkit-input-placeholder {
    color: $color-auro-metal;

  }
  .coin_input {
    .icon-arrow-up-bold1, .icon-arrow-down-bold1 {
    &:before {
      color: $color-storm-grey;
    }
  }
  }
}

.drop-down-pair{
  position: absolute;

  right: 14px;
  font-family: "inter-regular";
  font-weight: 400;
  color: #888888;
  top: 0;
  z-index: 9999;
  >span{
    line-height: 49px;
    margin-right: 5px;
    font-size: 18px;
  }

}
.drop-list-pair {
  /* width: 200px; */
  background: var(--monas-convert-pair-list);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  top: 57px;
  right: 41px;
  transform: translateX(50%);
  padding: 20px;
  z-index: 15;

  .list-pair {
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--text-primary-color);
    cursor: pointer;
    &:hover {
      color: $color-caribbean-green;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  .list-pair-drop {
    max-height: 175px;
    overflow-y: auto;
    
  }
  .list-no-data{
    text-align: center;
    padding: 24px 0;
    color: var(--text-close);
  }

  .text-search-pair {
    width: 160px;
    height: 39px;
    margin-bottom: 16px;

    input {
      background: var(--background-color-second);
      border-radius: 8px;
      border: none;
      padding: 0 36px;
    }

    .icon-search {
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    .icon-close {
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }
  }
}

.coin_input{
  position: relative;

  .icon-arrow-up-bold1, .icon-arrow-down-bold1 {
    &:before {
      color: $color-gray-dark;
    }
  }

  .left-input {
    font-family: $font-inter-regular;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    color: $color-gray-dark;
  }
  input[type="text"]{
    padding: 10px;
    min-height: 49px;
    border: 1px solid #E6E6E6;
    box-shadow: none;
    width: 100%;
    padding-right: 27px;
    padding-left: 15px;
    border-radius: 50px;
    font-weight: 400;
    color: #191919;
    background-color: $color-alabaster;
    font-size: 18px;
    text-align: left;
    &:focus {
      border: 1px solid #01bc8d;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
       font-size: 18px;
       color: $color-gray-dark;
       font-weight: 400;
       font-family: $font-inter-regular;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      font-size: 18px;
      color: $color-gray-dark;
      font-weight: 400;
      font-family: $font-inter-regular;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 18px;
      color: $color-gray-dark;
      font-weight: 400;
      font-family: $font-inter-regular;
    }
    &:-moz-placeholder { /* Firefox 18- */
      font-size: 18px;
      color: $color-gray-dark;
      font-weight: 400;
      font-family: $font-inter-regular;
    }
  }
  &>span {
    position: absolute;
    top: 0;
    right: 27px;
    color: $color-grey;
    line-height: 49px;
    font-size: $font-root;
    &.r10i {
      right: 14px;
    }

    &.unit-coin {
      font-family: $font-inter-regular;
      font-weight: 400;
      font-size: 18px;
      color: $color-gray-dark;
    }
  }

  button{
    position: absolute;
    right: 0;
    height: 20px;
    width: 22px;
    background-color: transparent;
    border: 0;
    &:focus {outline:0;}
    &.plus{
      top: 3px;
      border-top: none;
      &>i {
        position: absolute;
        top: 6px;
        right: 9px;
        color: $color_grey_border;
        line-height: 19px;
        font-size: 6px;
      }
    }
    &.minus{
      bottom: 4px;
      &>i {
        position: absolute;
        bottom: 6px;
        right: 9px;
        color: $color_grey_border;
        line-height: 16px;
        font-size: 6px;
      }
    }
    }
    &:focus{
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
@media screen and (max-width: 992px) {
  .coin_input input {
    font-size: 16px!important;
  }
}
</style>
