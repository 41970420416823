import BaseModelRequest from './base/BaseModelRequest';

export default class AddressManagerRequest extends BaseModelRequest {

  getModelName() {
    return 'address';
  }

  updateWalletsWhiteList(data) {
    let url = '/' + this.getModelName();
    return this.put(url, data);
  }

  removeWalletsAddress(data) {
    let url = '/' + this.getModelName();
    return this.del(url, data);
  }
}
