<template>
  <div id="navi-tab" class="position-relative">
    <div class="full-navi" id="full-navi">
      <div class="d-flex justify-content-between scroll-navi">
        <ul class="col-xl-8">
          <li
            v-for="item in naviList"
            @click="selectNavi(item)"
            :class="{ active: item.isActive }"
          >
            {{ $t(item.name)
            }}<span v-show="item.id === 'open_order'"
              >({{ sizeOpenOrder }})</span
            >
          </li>
        </ul>
        <div class="checkbox-function">
          <label for="checkbox_id" :class="{ check: isHideOtherPair === true }">
            <input
              type="checkbox"
              name="checkbox"
              v-model="isHideOtherPair"
              value="check"
              id="checkbox_id"
              @change="hideOtherPair()"
            />
            <span class="button-check"></span>
            {{
              isCurrentTab === commonConst.FUNDS
                ? $t("order.open_order.hide_small_assets")
                : $t("order.open_order.hide_other_pairs")
            }}
          </label>
        </div>
      </div>
    </div>

    <div
      v-if="showScrollLeftIcon"
      class="position-absolute btn-left d-xl-none d-block icon-arrow4"
      @click="clickScroll('btn-right')"
      id="btn-left"
    ></div>
    <div
      v-if="showScrollRightIcon"
      class="position-absolute btn-right d-xl-none d-block icon-arrow3"
      @click="clickScroll('btn-left')"
      id="btn-right"
    ></div>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";

export default {
  data() {
    return {
      naviList: COMMON_CONST.OPEN_ORDER_NAVI,
      commonConst: COMMON_CONST,
      isHideOtherPair: false,
      isCurrentTab: null,
      showScrollLeftIcon: false,
      showScrollRightIcon: window.matchMedia("(max-width: 650px)").matches,
    };
  },
  props: {
    sizeOpenOrder: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    checkScrollPosition() {
      const container = document.getElementById("full-navi");

      // Check if the container has horizontal overflow
      const hasOverflow = container.scrollWidth > container.clientWidth;

      // Check if the container is scrolled to the left
      const scrolledToLeft = container.scrollLeft === 0;

      // Check if the container is scrolled to the right
      const scrolledToRight =
        container.scrollLeft === container.scrollWidth - container.clientWidth;

      // Update the showScrollLeftIcon and showScrollRightIcon based on the conditions
      this.showScrollLeftIcon = hasOverflow && !scrolledToLeft;
      this.showScrollRightIcon = hasOverflow && !scrolledToRight;
    },
    getSocketEventHandlers() {
      return {
        OrderListUpdated: this.onOrderListUpdated,
      };
    },
    onOrderListUpdated(data) {
      this.$broadcast("SpotOrderListUpdated", data, this.isHideOtherPair);
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.isHideOtherPair = false;
    },
    selectNavi(item) {
      if (item.isActive) {
        return;
      }
      this.isHideOtherPair = false;
      this.isCurrentTab = item.id;
      this.resetSelectNavi();
      item.isActive = true;
      this.$emit("action-navi-tab", {
        action: this.commonConst.ACTION_CHANGE_ORDER_TAB,
        item,
      });
    },
    hideOtherPair() {
      this.$emit("action-navi-tab", {
        action: this.commonConst.ACTION_HIDE_OTHER_PAIR,
        isCheck: this.isHideOtherPair,
      });
    },
    resetSelectNavi() {
      this.naviList.forEach((item) => {
        item.isActive = false;
      });
    },
    clickScroll(id) {
      const content = document.getElementById("full-navi");
      const content_scroll_width = content.scrollWidth;
      let content_scoll_left = 0;
      if (id === "btn-left") {
        content_scoll_left += 100;
        if (content_scoll_left >= content_scroll_width) {
          content_scoll_left = 0;
        }
      } else {
        content_scoll_left -= 100;
      }
      content.scrollTo({
        left: content.scrollLeft + content_scoll_left,
        behavior: "smooth",
      });
    },
    handleWindowResize() {
      this.showScrollRightIcon =
        window.matchMedia("(max-width: 650px)").matches;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
    this.selectNavi(this.naviList[0]);
    const container = document.getElementById("full-navi");
    container.addEventListener("scroll", this.checkScrollPosition);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

.dark-theme {
  #navi-tab {
    ul {
      background-color: transparent;

      li {
        color: $color-gun-powder;
      }
    }

    .checkbox-function {
      .button-check {
        background: transparent;
        color: $color-storm-grey !important;
        border: 1px solid var(--text-transfer);
      }
    }
  }
}

#navi-tab {
  position: relative;
  padding-left: 40px;

  .btn-left {
    top: 5px;
    left: 3px;
    background: transparent;
  }
  .btn-right {
    top: 5px;
    right: 3px;
    background: transparent;
  }
  .checkbox-function {
    margin-right: 40px;
    label {
      display: flex;
      align-items: center;
      font-weight: 400;
      margin: 0px;
      line-height: 21px;
      input {
        margin-right: 4px;
        float: left;
        width: 16px;
        height: 16px;
      }
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0 15px 0 0;
    background-color: $color-gray-alabaster;
    color: $color-grey-sliver;
    li {
      display: inline-block;
      margin-right: 40px;
      font-size: 14px;
      line-height: 20px;
      color: #8e8e92;
      font-family: $font-inter-regular;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      user-select: none;
      padding: 6px 0 14px 0;
      &:last-child {
        border-right: none;
      }
      &:hover {
        cursor: pointer;
      }
      &.active {
        color: var(--text-transfer);
        border-bottom: 1px solid var(--text-transfer);
      }
    }
  }
  .line {
    margin: -1px 0 0;
    border-color: $color-bright-gray-dark;
  }
  .checkbox-function {
    right: 35px;
    font-size: 12px;
    line-height: 18px;
    color: $color-grey;
    cursor: pointer;
    label {
      cursor: pointer;
      color: var(--text-close);
      font-family: $font-inter-regular;
      .button-check {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        background: #dedede;
        border-radius: 2px;
        height: 16px;
        width: 16px;
        display: block;
        float: left;
        position: relative;
        margin-right: 5px;
        &:after {
          content: "";
          position: absolute;
          left: 6px;
          top: 1px;
          width: 6px;
          height: 13px;
          border: solid $color-grey-dark;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          display: none;
        }
      }
      #checkbox_id {
        display: none;
        &[type="checkbox"]:checked + .button-check {
          background-color: $color-caribbean-green;
        }
        &[type="checkbox"]:checked + .button-check:after {
          display: block;
        }
      }
    }
  }
}
@media only screen and (max-width: 1601px) {
  #navi-tab ul li {
    margin-right: 20px;
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 992px) {
  #btn-right {
    display: none !important;
  }
  #navi-tab {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: flex-start;
    ul {
      padding-right: 0;
      li {
        margin-right: 10px;
      }
    }

    .checkbox-function {
      .button-check {
        background: transparent;
        color: $color-storm-grey !important;
        border: 1px solid var(--text-transfer);
      }
      label {
        font-size: $font-root;
      }
    }

    .full-navi {
      overflow-x: auto;

      .scroll-navi {
        padding: 0;
        position: relative;
        .checkbox-function {
          display: none;
        }
      }
    }

    .full-navi::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .full-navi {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
</style>
