<template> 
  <div id="confirmationModal" class="modal fade" :class="configs.class" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" :style="{height: configs.heightModal, with: configs.withModal}">
    <div class="modal-mask" @click="hide()"></div>
    <div class= "modal-dialog" :class="{'dialog22' :configs.type === 'resetMail'}" role="document">
      <div class="modal-content" :class="{'borderTop' :configs.type === 'convertSmallBalance'}">
        <div class="modal-header">
          <span class=" symbol message-icon"></span>
          <button type="button" class="close" data-dismiss="modal" @click="redirectLanding" v-if="configs.type === 'confirm-succeed-modal'"><span aria-hidden="true" class="icon-close"></span></button>
          <button type="button" class="close" data-dismiss="modal" @click="redirectLogin" v-else-if="configs.type === 'confirm-fail-modal'"><span aria-hidden="true" class="icon-close"></span></button>
          <button type="button" class="close" data-dismiss="modal" @click="redirectLogin" v-else-if="configs.type === 'resetPW'"><span aria-hidden="true" class="icon-close"></span></button>
          <button type="button" class="close" data-dismiss="modal" @click="onCancel" v-else-if="configs.type === 'success'"><span aria-hidden="true" class="icon-close"></span></button>
          <div v-else-if="configs.type==='convertSmallBalance'"></div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-else><span aria-hidden="true" class="icon-close"></span></button>
        </div>
        <div class="modal-body">
          <div class="t-center" >
              <div class="">
                <h4 class="title_positionsMargin" v-if="configs.type === 'positionsMargin'">{{configs.head}}</h4>
                <div v-if="configs.type === 'primary'" class="fo_light_blue">
                
                  <img v-if="configs.icon" :src="configs.icon" alt="icon" class="icon">
                  <h3 class="tit_modal_confir" v-html="configs.title"></h3>
                </div>
                <div v-else-if="configs.type === 'disableEmail'" class="fo_light_blue">
                  <span class="icon-congratulations"></span>
                  <h3 class="tit_modal_confir" v-html="configs.title"></h3>
                </div>
                <!-------------------------Modal confirm convert small balance success ------------------------>
                
                <div v-else-if="configs.type === 'convertSmallBalanceSuccess'" class="fo_light_blue">
                  <span class="icon-email2 icon_modal_confir login-complete-icon"></span>
                  <div class="wrap-notification2">
                    <span class="tit_modal_confirm" v-html="configs.title"></span>
                  </div>
                  <div class="mart50 padding-14">
                    <button type="button " data-dismiss="modal" v-on:click="redirectSendConfirmEmail" class="btn btn-confirm btn-space btn-primary btn-forgotToLogin">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
               
                
                <!-------------------------------convert small balance--------------------------------->
                <div v-else-if="configs.type === 'convertSmallBalance'" class="fo_light_blue">
                  <span class="icon-congratulations"></span>
                  <h2 class="h2-title">{{$t('balance.convert.btc')}}</h2>
                  <h4 class="tit_modal_confir_convert_balance" v-html="configs.title"></h4>
                </div>

                <div v-else-if="configs.type === 'resetMail'" class="contain-reset-mail">
                  <div class="fo_green2 reset_mail_modal ">
                    <img :src="require(`@/assets/images/icon/successCheck${userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)" width="106px" height="106px"/>
                    <!-- <span class="icon-checked icon-help2"></span> -->
                    <span class="title-l"></span>
                    <h3 class="tit_modal_confir title-header-modal mb-8" v-html="configs.title"></h3>
<!--                    <span class="title-l"></span>-->
<!--                    <br class="clear-fix"/>-->
                  </div>
<!--                  <h5 class="title-header-content">{{$t('reset_password.send_mail_title_2')}}</h5>-->
                  <p class="modal-content-text content">{{$t('reset_password.send_mail_title_2')}}</p>
                  <p class="modal-content-text">{{$t('reset_password.sent_mail_2')}}</p>
                  <div>
                    <button type="button " data-dismiss="modal" v-on:click="onCancel" class="btn btn-confirm btn-space btn-primary btn-resetMail">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                <div v-else-if="configs.type === 'resetPW'" class="fo_green_3">
<!--                  <i class="icon-save"></i>-->
                  <img :src="require(`@/assets/images/icon/successCheck${userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)" width="106px" height="106px"/>
                  <h3 class="tit_modal_confirm password-change">{{$t('reset_passsword_complete_2')}}</h3>
                  <p class="desc-change mb-20" >{{$t('reset_password_login_new')}}</p>
                  <div >
                    <button type="button " data-dismiss="modal" @click="redirectLogin" class="btn btn-confirm btn-space btn-primary btn-resetMail">{{$t('login.title-3')}}</button>
                  </div>
                </div>
                <div v-else-if="configs.type === 'login-succeed-modal'" class="fo_green_4">
                  <img :src="require(`@/assets/images/icon/successCheck${userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)" width="106px" height="106px"/>
<!--                  <span class="icon-responsive login-complete-icon"></span>-->
                  <div class="wrap-notification mt-40">
                    <h4 class="tit_modal_confir" v-html="configs.title"></h4>
                  </div>
                  <div class="mart50">
                    <button type="button " data-dismiss="modal" v-on:click="redirectLogin" class="btn btn-confirm btn-space btn-primary btn-resetMail">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                <!-- -----------------------Modal confirm Email success ------------------------>
                <div v-else-if="configs.type === 'confirm-succeed-modal'" class="fo_green_4">
                  <span class="icon-email2 icon_modal_confir login-complete-icon"></span>
                  <div class="wrap-notification2">
                    <span class="tit_modal_confirm" v-html="configs.title"></span>
                  </div>
                  <div class="mart50 padding-14">
                    <button type="button " data-dismiss="modal" v-on:click="redirectLanding" class="btn btn-confirm btn-space btn-primary btn-forgotToLogin">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                <!-------------------------Modal confirm resend Email success ------------------------>
                <div v-else-if="configs.type === 'confirm-succeed-resent-email-modal'" class="fo_green_4 mt-40">
<!--                  <span class="icon-email2 icon_modal_confir login-complete-icon"></span>-->
                  <img src="@/assets/images/icon/resend-email.svg" width="106px" height="106px"/>
                  <div class="wrap-notification2 ">
                    <div class="desc-change mb-20 mt-40" v-html="configs.title"></div>
                  </div>
                  <div >
                    <button type="button " data-dismiss="modal" v-on:click="redirectSendConfirmEmail" class="btn btn-confirm btn-space btn-primary btn-resetMail">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                <!-------------------------Modal confirm Email success 2 ------------------------>
                <div v-else-if="configs.type === 'confirm-succeed-modal2'" class="fo_green_4">
<!--                  <span class="icon-email2 icon_modal_confir complete-icon"></span>-->
                  <img :src="require(`@/assets/images/icon/successCheck${userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)" width="106px" height="106px"/>
                  <div class="wrap-notification2 mb-20 ">
                    <h3 class="tit_modal_confirm_2 title-header-modal" >{{$t("register.congratulation")}}</h3>
                    <span class="tit_modal_confirm " v-html="configs.title"></span>
                  </div>
                  <div class="mart50 padding-14">
                    <button type="button " data-dismiss="modal" v-on:click="redirectLogin" class="btn btn-confirm btn-space btn-primary btn-resetMail">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                <!--------------------------Modal confirm Email fail invaild or expired ------------------->
                <div v-else-if="configs.type === 'confirm-fail-modal'" class="fo_green_4">
                  <img   :src="require(`@/assets/images/icon/invalid-modal${userThemeMode === 'light-theme' ? '' : '-light'}.svg`)" width="98px" height="98px"
                  />

                  <div class="wrap-notification mt-40">
<!--                    <h3 class="tit_modal_confir title-header-modal" v-html="configs.title"></h3>-->

                    <div class="modal-content-text mt-40">{{$t('login.confirm_failed')}}</div>
                  </div>
                  <div >
                    <button type="button " data-dismiss="modal" v-on:click="redirectLogin" class="btn btn-confirm btn-space btn-primary btn-resetMail">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                 <!--------------------------Modal confirm Email fail invaild or expired ------------------->
                 <div v-else-if="configs.type === 'confirm-fail-modal-device'" class="fo_green_4">
                  <img   :src="require(`@/assets/images/icon/invalid-modal${userThemeMode === 'light-theme' ? '' : '-light'}.svg`)" width="98px" height="98px"
                  />

                  <div class="wrap-notification mt-40">
<!--                    <h3 class="tit_modal_confir title-header-modal" v-html="configs.title"></h3>-->

                    <div class="modal-content-text mt-40">{{$t('login.active_device_failed')}}</div>
                  </div>
                  <div >
                    <button type="button " data-dismiss="modal" v-on:click="redirectLogin" class="btn btn-confirm btn-space btn-primary btn-resetMail">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                <!--------------------------Modal confirm-withdrawal-fail-modal ------------------->
                <div v-else-if="configs.type === 'confirm-withdrawal-fail-modal'" class="fo_green_4 confirm-withdrawal-fail-modal">
                  <img src="@/assets/images/icon/invalid-modal.svg" width="98px" height="98px"/>
                  <div class="wrap-notification">
<!--                    <h3 class="tit_modal_confir title-header-modal" v-html="configs.title"></h3>-->

                    <div class="modal-content-text mt-40">{{$t('login.confirm_failed')}}</div>
                  </div>
                  <div >
                    <button type="button " data-dismiss="modal" v-on:click="redirectLanding" class="btn">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                <!--------------------------Modal display when internet connection is disconnect ------------------->
                <div v-else-if="configs.type === 'connection-internet-fail'" class="fo_green_4">
                  <span class="icon-email3 icon_modal_confir login-complete-icon"></span>
                  <div class="wrap-notification">
                    <h4 class="tit_modal_confir" v-html="configs.title"></h4>
                  </div>
                  <div class="mart50">
                    <button type="button " data-dismiss="modal" v-on:click="redirectLogin" class="btn btn-confirm btn-space btn-primary btn-forgotToLogin">{{$t('common.action.ok')}}</button>
                  </div>
                </div>

                <div v-else-if="configs.type === 'confirm-withdrawal-transaction'" class="fo_green_4 confirm-withdrawal-transaction">
                  <img class="icon-confirmed-withdrawal"
                       :src="require(`@/assets/images/icon/confirmed-withdrawal${userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)"
                        alt="icon-confirmed-withdrawal" />
                  <div class="wrap-notification">
                    <h4 class="tit_modal_confir" v-html="configs.title"></h4>
                  </div>
                  <div class="mart50 button-confirmed-withdrawal">
                    <button type="button " data-dismiss="modal" v-on:click="redirectLanding" class="btn">{{$t('common.action.ok')}}</button>
                  </div>
                </div>
                <div v-else-if="configs.type === 'success'" class="fo_green">
                  <span class="icon-checked icon-help2"></span>
                  <h3 class="tit_modal_confir" v-html="configs.title"></h3>
                </div>
                <div v-else-if="configs.type === 'warning'" class="fo_yellow">
                  <span class="icon-info2 icon-help2"></span>

                  <h3 class="tit_modal_confir" v-html="configs.title"></h3>
                </div>
                <div v-else-if="configs.type === 'danger'" class="fo_red">
                  <img src="@/assets/images/icon/cancel-gi.svg" width="40px" height="40px"/>
                  <h3 class="tit_modal_confir" v-html="configs.title"></h3>
                </div>
                <div v-else-if="configs.type === 'confirm'">
                  <h3 class="tit_modal_confir" v-html="configs.title"></h3>
                </div>
                <div v-else-if="configs.type === 'positionsMargin'">
                  <h3 class="tit_modal_positions_argin" v-html="configs.title"></h3>
                </div>
                <div v-else class="fo_light_blue">
<!--                  <span class="icon-info2 icon-help2"></span>-->
<!--                  <img src="@/assets/images/icon/confirmRemove.svg" width="106px" height="106px"/>-->
                  <img :src="require(`@/assets/images/icon/confirmRemove${
                    userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)" width="106px" height="106px"
                  />

                  <!-- <h3 class="tit_modal_confir">{{ $t('shared_components.common.confirmation_modal.info') }}</h3> -->
                </div>
              </div>
              <template v-if="configs.customContent">
                <p class="content_text"><slot name="content"></slot></p>
              </template>
              <template v-else-if="!!configs.content">
                <p class="content_text title-header-modal confirm-remove" v-html="configs.content"></p>
              </template>
              <template v-else-if="configs.type === 'confirm-succeed-modal2'">
                <p class="padd-bottom-15"></p>
              </template> 
              <template v-else-if="!['resetMail', 'confirm-fail-modal', 'resetPW', 'confirm-succeed-modal', 'confirm-succeed-modal2', 'confirm-withdrawal-transaction', 'confirm-succeed-resent-email-modal', 'confirm-withdrawal-fail-modal'].includes(configs.type)">
<!--                <p class="padd-bottom-20"></p>-->
              </template> 
              <template v-else>
<!--                <p class="padd-bottom-10"></p> -->
              </template>
              <template v-if="configs.type === 'positionsMargin'">
                <p class="des_modal">{{configs.bottom}}</p>
              </template>
              <div class="mart50" v-if="configs.btnCancelLabel || configs.btnConfirmLabel">
                <button type="button" data-dismiss="modal" class="btn btn-cancel btn-space btn-secondary">{{ configs.btnCancelLabel }}</button>
                <button type="button" data-dismiss="modal" class="btn btn-confirm btn-space btn-secondary" :class="configs.btnDelete">{{ configs.btnConfirmLabel }}</button>
                <!-- <button type="button" data-dismiss="modal" class="btn btn-confirm btn-space btn-primary">{{ configs.btnConfirmLabel }}</button> -->
              </div>
            </div>

        </div>
      </div>
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>

<script>
  import {mapState} from "vuex";

  window.ConfirmationModal = {
    show: function(configs){
      window.app.$broadcast('showModal', configs);
    }
  }
  export default {
    data(){
      return{
        configs: {
          type            : '',
          title           : '',
          content         : '',
          btnCancelLabel  : this.$t('common_action.cancel'),
          btnConfirmLabel : this.$t('common_action.confirm'),
          onConfirm       : () => {},
          onCancel        : () => {},
          size            : 'small',
          class           : '',
          heightModal     : '',
          withModal       : '',
          displayCloseButton : false,
          iconHelp: true,
          textAlign: '',
          icon: '',
        },
      }
    },
    props: {
      showRePass: { type: Boolean, default: false },
      linkOnCancel: { type: String},
    },
    computed : {
      ...mapState({
        userThemeMode: (state) => state.userThemeMode,
      }),
    },
    methods: {
      onCancel() {
        if (typeof this.linkOnCancel !== 'undefined') {
          let path = this.$router.options.routes.find(el => el.name == this.linkOnCancel);
          if (!path) {
            console.error('Cannot find path by name!', this.linkOnCancel);
          }
          this.$router.push(path ? path : '/');
        }
      },
      show () {
        const modal = $('#confirmationModal');
        modal.find('.close').unbind('click').click(this.configs.onCancel);
        modal.find('.btn-cancel').unbind('click').click(this.configs.onCancel);
        modal.find('.btn-confirm').unbind('click').click(this.configs.onConfirm);
        modal.modal('show');
      },
      hide () {
        $('#confirmationModal').modal('hide');
      },
      redirectLogin(){
        this.$router.push({ name: 'Login' });
      },
      redirectLanding(){
        this.$router.push({ name: 'Landing Page' });
      },
      redirectForgotPassword(){
        this.$router.push({ name: 'Landing Page' });
      },
      redirectResetPassword(){
        this.showRePass = true;
        this.$emit('showRePass', this.showRePass);
      },
      redirectSendConfirmEmail(){
        this.$emit('exit',true);
      }
    },
    created () {
      let self = this;
      var compatibleBrowser = typeof Object['__defineSetter__'] === 'function';
      this.$on('showModal', (userConfigs) => {
        self.configs = Object.assign(self.configs, userConfigs);
        this.show();
      });
    }
  };
</script>
<style lang="scss">
    @import "@/assets/sass/variables";
  .dialog22{
    margin: 9% auto 0px auto !important;
  }
  .block{
    display: block;
  }
  .none{
    display: none;
  }
  #confirmationModal {
    .close {
      &:hover {
        .icon-close {
          background-color: $color-light-grey;
          color: $color-grey;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .dark-theme {
    #confirmationModal .modal-dialog .fo_green_4 .wrap-notification h4 {
      color: $color-storm-grey;
    }
  }

  #confirmationModal {
    @import "@/assets/sass/variables";
    position   : fixed;
    z-index: 9998;
    text-align : center;
    padding    : 0!important;
    transition-duration: 0.3s;
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: block;
      transition: opacity .3s ease;
    }
    .modal{
      text-align: center;
      padding: 0!important;
    }
    .modal:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
    .icon-help2, .icon-email3, .icon-money {
      font-size: 70px;
      color: $color-jungle-green;
      margin-top: 0px;
    }
    .icon-congratulations{
      font-size: 110px;
      color: #2DAC91;
      margin-top: 0px;
    }
    .icon {
      margin-bottom: 20px;
    }
    .tit_modal_confir{
      color: var(--text-primary-color);
      font-size: 34px;
      margin-top: 0;
      font-family: $font-inter-bold;
      margin-bottom: 30px;
      @media screen and (max-width: 992px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .tit_modal_confirm_2 {
      color: var(--text-primary-color);
      font-size: 34px;
      margin-top: 0;
      font-family: $font-inter-bold;
    }
    .h2-title {
      font-family: Roboto-Regular;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #999999;
    }
    .tit_modal_confir_convert_balance {
      color: $color-eden;
      font-size: 30px;
      font-family: $font-family-roboto-medium;
      line-height: 29px;
      margin-bottom: -55px;
      margin-top: -5px;
      max-width: 350px;
      margin-left: 90px;
    }
    .tit_modal_confirm{
      color: var(--text-second-color);
      font-size: $font-medium-bigger;
      font-family: $font-inter-regular;
      line-height: 29px;
      margin-bottom: 15px;
      margin-top: 25px;
      &.password-change{
        font-size: 34px;
        line-height: 41px;
        color: var(--text-primary-color);
        font-family: $font-inter-bold;
      }
    }
    .desc-change{
      font-size: $font-big;
      line-height: 22px;
      font-family: $font-inter-regular;
      color: var(--text-second-color);
    }
    .modal-dialog {
      z-index: 10000;
      width: 590px;
      max-width: 80%;
      max-height: 80%;
      vertical-align : middle;
      // display        : inline-block;
      // text-align     : left;
      // margin: 6% auto 0px auto;
      
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      .modal-content {
        &.borderTop{
          border-top: 10px solid #2dac91;
        }
        border-radius: 5px;
        position:unset;
        border: 0;
        background-color: var(--background-color-modal);
          .modal-title {
            color: $color_grey_text;
            display: inline-block;
            font-size: $font-big-20;
          }
          .close {
            height: 30px;
            width: 30px;
            opacity: 0.9;
            z-index: 100;
            position: relative;
            outline: none;
            background: transparent !important;
            text-shadow: none;
            &:hover {
              opacity: 1;
              .icon-close {
                background-color: $color-light-grey;
                color: $color-grey;
              }
            }

            .icon-close {
              border-radius: 50%;
              color: var(--icon-close-color);
              line-height: 32px;
              overflow: hidden;
              text-align: center;
              display: inline-block;
              font-size: $font-root;
              float: right;
              width: 30px;
              height: 30px;
              margin: 0px 0px 0 0;
              cursor: pointer;
              font-weight: bold;
              &:hover{
                background-color: $color-light-grey;
                color: $color-grey;
              }
            }
          }
        .modal-header{
          position: relative;
          padding: 15px;
          padding-bottom: 0;
          border-bottom: none;
        }
        .modal-body{
          padding: 0 50px 50px;
          @media screen and (max-width: 992px) {
            padding: 0 16px 30px;
          }
          .t-left{
            text-align: left;
          }
          .content_text{
            font-family: $font-inter-regular;
            color: var(--color-black_3);
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 30px;
            @media screen and (max-width: 992px) {
              font-size: 14px;
              line-height: 17px;
            }
          }
          .primary{
            margin-top: 30px;
            margin-bottom: 20px;
            .btn-primary{
              background-color: $color-yellow;
              border-color: $color-yellow;
              color: $color_white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
              &:hover, &:active, &:focus{
                background: $color-corn;
                border-color: $color-corn;
                color: $color-white;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
          }
          .success{
            .btn-primary{
              background-color: $color-green;
              border-color: $color-green;
              color: $color_white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
              &:hover, &:active, &:focus{
                background: $color-corn;
                border-color: $color-corn;
                color: $color-white;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
          }
          .warning{
            .btn-primary{
              background-color: $color-yellow-pale;
              border-color: $color-yellow-pale;
              color: $color_white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
              &:hover, &:active, &:focus{
                background: $color-corn;
                border-color: $color-corn;
                color: $color-white;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
          }
          .danger{
            .btn-primary{
              background-color: $color-red;
              border-color: $color-red;
              color: $color_white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
              &:hover, &:active, &:focus{
                background: $color-corn;
                border-color: $color-corn;
                color: $color-white;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
          }
          .mart50 {
            display: flex;
            gap: 24px;
          }
          button{
            flex: 1;
            border-radius: 8px;
            padding: 15.5px 10px;
            font-family: $font-inter-regular;
            font-size: 18px;
            line-height: 21px;
            &.btn-confirm {
              @include btn-common(yes);
            }
            &.btn-cancel {
              @include btn-common(no);
            }
          }
        }
      }

      .modal-footer {
        padding: 15px;
        background-color: $color-white;
        border-top: none;
        .btn {
          border-radius : 0px;
          padding       : 7px 30px;
          color         : $color-white;
          border        : none;
          font-size     : 13px;
        }
        .btn-cancel {
          background: $color_black_logo;
          &:hover {
            opacity: 0.2;
          }
        }
      }
      .complete-icon::before, .login-complete-icon::before{
        color:$color-green;
      }
      .complete-icon{
        font-size: 70px;
      }
      .login-complete-icon{
        font-size:80px;
      }
      // new css
      .contain-reset-mail{
        max-width: 94%;
        margin: auto;
      }
      .fo_green2{
        display: flex;
        margin:0 auto;
        &.reset_mail_modal {
          display: block;

        }
      }
      .title-l{
          margin: 13px 0px 15px 0px;
          height: 1px;
          width: 10%;
          border-radius: 5px;
          background-color: $color-light-grey;
      }
      .title-header-modal{
        margin-top: 20px !important;
        color: var(--text-primary-color);
      }
      .title-header-content{
        font-family: $font-family-roboto-medium;
        color: $color-grey-dark;
        font-weight: bold;
      }
      .modal-content-text{
        //max-width: 80%;
        color: var(--text-second-color);
        font-size: $font-big;
        font-family: $font-inter-regular;
        margin: 0 auto 20px;
        line-height: 22px;
        &.content {
          line-height: 22px;
          color: var(--text-dropdown);
          margin: 0 auto 8px;
          font-weight: bold;
        }
      }
      .btn-forgotToLogin{
          margin-top:20px !important;
          width: 190px;
          height: 50px !important;
          border-radius: 3px !important;
          border : 1px solid $color-jungle-green !important;
          background-color: $color-jungle-green !important;
          &:hover{
            background-color: $color-curious-blue !important;
            border : 1px solid $color-curious-blue !important;
          }

        &:hover{
          background-color: $color-caribbean-green !important;
        }
      }
      .fo_green_4{
        margin: 0 auto;
        .icon-confirmed-withdrawal {
          margin-bottom: 40px;
        }
        .button-confirmed-withdrawal {
          justify-content: center;
          button {
            flex: inherit;
            min-width: 200px;
            background-color: $color-caribbean-green;
            border: none !important;
            border-radius: 8px;
            min-height: 52px;
            &:hover, &:active{
              background-color: $color-aquamarine;
              border-color: $color-aquamarine;
            }
          }
        }
      }
      .confirm-withdrawal-fail-modal {
        button {
          flex: inherit;
          min-width: 200px;
          background-color: $color-caribbean-green;
          border: none !important;
          border-radius: 8px;
          min-height: 52px;
          margin-top: 40px;
          &:hover, &:active{
            background-color: $color-aquamarine;
            border-color: $color-aquamarine;
          }
        }
      }

      .fo_green_4 .wrap-notification h4{
        color : $color-grey-dark;
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
      }
      .fo_green_4 p{
        color :red;
        font-size :14px;
        font-family: $font-inter-regular;
      }
      .fo_green_4 .wrap-notification h4 p strong{
        font-family: $font-family-roboto-bold;
      }
    }
    &.modal-success-email .modal-dialog {
      //height: 250px;
    }
    .wrap-notification2{
      text-overflow: ellipsis;
      overflow: hidden;
      //padding-top: 22px;
      padding-bottom:2px;
      white-space: nowrap;
    }

    &.modal-success-password .modal-dialog {
      height: 330px ! important;
      width: 480px ! important;
    }

    .icon-save{
      font-size: $font-title-size-big-small-3;
    }
    .icon-save:before {
      content: "\E922";
      color: $text-green;
    }
    .btn-resetMail {
      flex: inherit !important;
      margin: auto;
      margin-top: 20px !important;
      min-width: auto !important;
      width: 200px !important;
      max-width: 100%;
      height: 52px !important;
      background-color: $color-caribbean-green !important;
      border: none !important;
      font-weight: normal;
      text-transform: none;
      &:hover:enabled,
      &:focus {
        background-color: $color-aquamarine !important;
      }
    }
  }
  .desc-fail-modal{
    font-style: normal;
    font-weight: 400;
    font-size: $font-big;
    color: $color-gray-dark;
  }
  .padd-bottom-20{
    height: 80px !important;
  }
  .padd-bottom-10{
    height: 9px !important;
  }
  .padd-bottom-15{
    height: 18px !important;
  }
  .mb-20 {
    margin-bottom: 20px !important;
  }
  .mt-40 {
    margin-top: 40px !important ;
  }
  .mb-8 {
    margin-bottom: 8px !important;
  }
  @media screen and (max-width: 992px) {
    #confirmationModal {
      .modal-dialog {
        .modal-content {
          .close {
            .icon-close {
              width: 14px;
              height: 14px;
              line-height: 14px;
            }
          }
        }
      }
      .tit_modal_confir {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
      }
    }
  }
  @media screen and (max-width: 768px) {}
  @media screen and (max-width: 576px) {
    #confirmationModal {
      .modal-dialog {
        max-width: 91.5%;
      }
    }
  }
</style>