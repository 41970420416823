<template>
  <div class="tableContainer" :style="{width: widthTable}">
    <table>
      <thead :class="{
      'table-hidden': hasLayoutMobile
    }">
        <tr :class="defaultSort ? 'default-sort' : 'custom-sort'" @click="onSort">
          <slot
             :resetIE="resetIE"
            :innerHtmlSort="innerHtmlSort"
            :echoclick="echoclick"
          />
        </tr>
      </thead>
      <tbody>
        <slot name="first_row"/>
        <slot name="body" v-for="(row, index) in rows" :item="row" :index="index"/>
        <template v-if="this.rows.length === 0">
          <tr class="empty-data">
            <td :colspan="50" v-if="fetching == true">
              <div class="spinner-border fixed-empty"></div>
            </td>
            <td :colspan="50" v-if="fetching == false">
              <div class="fixed-empty">
                <p>
                  <!-- <span class="icon-nodata"></span> -->
                  <img
                    class="icon-search"
                    :src="
                      require(`@/assets/images/landing/icon-nodata${
                        theme === 'light-theme' ? '' : '-dark'
                      }.svg`)
                    "
                  />
                  <div class="mess_nodata">{{ msgEmptyData || $t('common.datatable.data_empty') }}</div>
                </p>
              </div>
            </td>
          </tr>
        </template>
        <slot name="end_row"/>
      </tbody>
    </table>
    <slot v-if="hasLayoutMobile" class="body-mobile" name="body-mobile" v-for="(row, index) in rows" :item="row" :index="index"/>
    <template v-if="lastPage > 1">
<!--      <pagination-->
<!--        ref="pagination"-->
<!--        class="text-right"-->
<!--        :per-page="perPage"-->
<!--        :records="totalRecord"-->
<!--        :chunk="chunk"-->
<!--        @Pagination:page="onPageChange"-->
<!--        :pageParent="page"-->
<!--      ></pagination>-->
      <pagination2
          ref="pagination"
          class="text-right"
          :per-page="perPage"
          :records="totalRecord"
          @Pagination:page="onPageChange"
          :paginationPosition="paginationPosition"
          :pageParent="page"
      ></pagination2>
    </template>
  </div>
</template>

<script>
import Pagination2 from "./Pagination2.vue";
import Pagination from "./Pagination";
import {mapState} from "vuex";

export default {
  components: {
    Pagination,
    Pagination2
  },
  props: {
    getData: {
      type: Function,
    },
    limit: {
      type: Number,
      default: 10,
    },
    chunk: {
      type: Number,
      default: 5,
    },
    widthTable: {
      type: String,
      default: "100%",
    },
    msgEmptyData: {
      type: String,
    },
    defaultSort: {
      type: Boolean,
      default: true,
    },
    innerHtmlSort: {
      type: String,
      default:
        '<span class="icon-double-arrow-table"></span>' +
        '<span class="icon-double-arrow-table-down"><span class="path1"></span><span class="path2"></span></span>' +
        '<span class="icon-double-arrow-table-up"><span class="path1"></span><span class="path2"></span></span>',
    },
    tableActivity: {
      type: Boolean,
      default: false,
    },
    hasLayoutMobile:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 10,
      column: 0,
      fetching: false,
      rows: [],
      params: {},

      orderBy: null,
      sortedBy: null,
      resetIE: false,
      emptyData: false,
    };
  },
  computed: {
    emptyRow() {
      let emptyRowCount = Math.max(this.limit - _.size(this.rows), 0);
      return Math.min(emptyRowCount, this.limit);
    },
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
  },
  methods: {
    rawHtml() {
      document.getElementById("demo").innerHTML = "My First JavaScript";
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    echoclick() {
      this.resetIE = !this.resetIE;
    },

    getTarget(target) {
      let node = target;
      while (node.parentNode.nodeName !== "TR") {
        node = node.parentNode;
      }
      return node;
    },

    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder;
      switch (sortOrder) {
        case "asc":
          sortOrder = "";
          break;
        case "desc":
          sortOrder = "asc";
          break;
        default:
          sortOrder = "desc";
      }
      return sortOrder;
    },

    setSortOrders(target, sortOrder) {
      let iterator = target.parentNode.firstChild;
      while (iterator) {
        iterator.dataset.sortOrder = "";
        iterator = iterator.nextElementSibling;
      }
      target.dataset.sortOrder = sortOrder;
    },

    onSort(event) {
      const target = this.getTarget(event.target);
      const orderBy = target.dataset.sortField;
      if (!orderBy) {
        return;
      }
      this.sortedBy = this.getSortOrder(target);
      this.orderBy = this.sortedBy ? orderBy : "";
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy,
      });
      this.setSortOrders(target, this.sortedBy);
      this.fetch();
    },

    fetch() {
      const meta = {
        page: this.page,
        limit: this.limit,
      };

      this.fetching = true;
      this.getData(Object.assign(meta, this.params))
        .then((res) => {
          const data = res.data || res;
          if (!data) {
            return;
          }
          this.column = _.chain(this.$slots.default)
            .filter((el) => {
              return el.tag === "th";
            })
            .value().length;

          if (!data.data) {
            this.rows = data;
            this.$emit("DataTable:finish");
            return;
          }
          this.page = parseInt(data.current_page);
          this.totalRecord = parseInt(data.total);
          this.lastPage = parseInt(data.last_page);
          this.perPage = parseInt(data.per_page);
          this.rows = data.data;
          this.emptyData = window._.isEmpty(this.rows);
          this.fetching = false;
          this.$emit("DataTable:finish");
        })
        .then((res) => {
          setTimeout(() => {
            this.fetching = false;
          }, 5000);
        });
    },
    refresh() {
      this.page = 1;
      this.params = {};
      this.fetch();
    },

    filter(params) {
      this.page = 1;
      this.params = params;
      this.fetch();
    },
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
  created() {
    this.fetch();
    this.$on("DataTable:filter", (params) => {
      this.filter(params);
    });
  },
  mounted() {
    this.column = _.chain(this.$slots.default)
      .filter((el) => {
        return el.tag === "th";
      })
      .value().length;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.tableContainer::v-deep {
   th {
    .icon-sort-table {
      display: flex;
      font-size: 17px;
    }
    .icon-sort-desc {
      display: none;
      margin-left: -0.5px;
      margin-top: 0.5px;
    }
    .icon-sort-asc {
      display: none;
      margin-left: -0.5px;
      margin-top: 0.5px;
    }
    &[data-sort-order="desc"] {
      .icon-sort-table {
        display: none;
      }
      .icon-sort-desc {
        display: flex;
      }
    }
    &[data-sort-order="asc"] {
      .icon-sort-table {
        display: none;
      }
      .icon-sort-asc {
        display: flex;
      }
    }
    /* &:hover {
      color: $color-caribbean-green !important;
    } */
  }
}
.loader {
  display: block;
  margin: auto;
  border: 5px solid $color-grey-concrete;
  border-radius: 50%;
  border-top: 5px solid $color-blue-custom;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.dark-theme {
  table {
    th {
      ::v-deep {
        .icon-double-arrow-table::before{
          color: $color-storm-grey;
            }
      }
    }
  }
}

table {
  width: 100%;
  overflow-y: hidden;
  thead {
    th {
      user-select: none;
      border: none;
      font-size: 14px;
      line-height: 17px;
      color: $dark-2;
      cursor: pointer;
      padding: 21px 15px;
      &:last-child {
        border-radius: 0px 8px 8px 0px;
      }
      &:first-child {
        border-radius: 8px 0px 0px 8px;
      }

      &:hover {
        color: $color-caribbean-green;
      }
      ::v-deep  {

        .group-sort-tabl3 {
          display: inline-table;
          vertical-align: middle;
          font-size: 15px;
          margin-left: 5px;
          position: relative;
          top: -2px;
        }
        .icon-double-arrow-table::before{
          color: $color-gray-dark;
          width: 6px;
          height: 12px;
        }
        .icon-double-arrow-table {
          display: flex;
        }
        .icon-double-arrow-table-down {
          display: none;
          width: 6px;
          height: 12px;
        }
        .icon-double-arrow-table-up {
          display: none;
          width: 6px;
          height: 12px;
        }

        &[data-sort-order="desc"] {
          .icon-double-arrow-table-down {
            display: flex;
          }
          .icon-double-arrow-table-down .path1:before {
            color: var(--text-close); 
          }
          .icon-double-arrow-table,
          .icon-double-arrow-table-up {
            display: none;
          }
        }
        &[data-sort-order="asc"] {

          .icon-double-arrow-table,
          .icon-double-arrow-table-down {
            display: none;
          }
          .icon-double-arrow-table-up {
            display: flex;
          }
            .icon-double-arrow-table-up .path2:before {
            color: var(--text-close); 
          }
        }
      }
    }
  }
  tbody {
    text-align: center;
    color: #333333;
    tr {
      vertical-align: top;
      height: 32px;
      overflow-y: hidden;
      transition-property: height;
      transition-duration: 0.3s, 0.3s;
      transition-timing-function: ease, ease-in;
      .icon-arrow_2_2 {
        color: $color-green;
      }
      &.active {
        max-height: 300px;
        background-color: $color-bright-gray;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
      }
      &.empty-data {
        width: 100%;
        text-align: center;
        color: $color-grey-dusty;
        font-size: $font-root;
        text-align: center;
        @media only screen and (max-width: 992px) { 
          min-height: 250px;
          display: flex;
        }
        td {
          width: 100%;
          text-align: center !important;
          color: $color-grey-dusty !important;
        }
        &:hover {
          background-color: transparent ;
        }
        p {
          padding-top: 22px;
          span {
            vertical-align: middle;
          }
        }
        .icon-nodata {
          font-size: 40px;
          color: $color-grey-sliver;
          margin-right: 10px;
        }
        &:hover {
          background-color: transparent;
        }
        .fixed-empty {
          @media only screen and (max-width: 992px) {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
            z-index: 1;
          }
        }
      }
    }
    td {
      font-family: $font-inter-regular;
      font-size: 18px;
      line-height: 22px;
      color: $dark-1;
      padding: 23px 15px;
      &:last-child {
        padding: 8px 20px 8px 8px;
        text-align: right;
      }
      &.info {
        background-color: $color-bright-gray;
        color: $color-grey-dusty;
        font-size: 13px;
        text-align: left;
        padding: 8px 20px 8px 38px;
        .label-text {
          color: $color-grey;
        }
      }
    }
    .mess_nodata {
      margin-top: 30px;
      font-size: 18px;
      line-height: 22px;
      color : var(--color-image-nodata);
      font-family: $font-inter-regular;
    }
  }
}
@media screen and (max-width: 992px) { 
  table tbody
  .mess_nodata {
    font-size: 14px;
    line-height: 16px;
  }
}
  
  .table-hidden{
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  .body-mobile {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}
</style>
