<template>
  <div class="input-group">
    <input
      @focus="$emit('focus')"
      :maxlength="maxLengthInput"
      class="form-input address"
      :placeholder="placeholder"
      :class="{'error': errors.has(field)}"
      v-model="internalValue"
    >
    <span v-show="errors.has(field)" class="invalid-feedback">{{ errors.first(field) }}</span>
  </div>
</template>

<script>
import COMMON_CONST from '@/common/Const';

export default {
  inject: ["$validator"],
  data() {
    return {
      internalValue: "",
      maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
    };
  },
  props: {
    placeholder: { type: String },
    field: { type: String, required: true },
    value: { required: true },
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      if (this.internalValue === this.value) {
        return;
      }
      this.$emit("input", val);
    }
  },
  methods: {
    focus() {
        this.$refs.input.focus();
      },
    validate() {
      if (_.isEmpty(this.internalValue.trim())) {
        this.errors.add(
          this.field,
          window.i18n.t("funds.withdrawals.errors.required", {
            field: _.lowerCase(this.field)
          })
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
input{
  &:focus{
    border: 1px solid $color-jungle-green !important;
  }
}
.address {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  border: solid 1px $color-alto;
  background-color: $color-bright-gray;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  // text-align: right;
  color: $color-grey;
  &::placeholder {
    color: var(--text-close);
  }
}
</style>
