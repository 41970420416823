<template>
  <div class="box-table">
    <div id="container-tab" class="clearfix">
        <h2 class="market-title" v-if="!shortNameTab">
          <i class="icon-24h"></i>
          <span>{{$t('common.spot_exchange')}}</span> {{$t('common.market_info')}}
        </h2>
        <div class="clearfix group_table_landing">
          <!-- <input id="tab-favirotes" type="radio" name="tab-group"/> -->
          <label for="tab-favirotes" :class="selectedMarket === tabFavorites ? 'current-market' : ''" v-on:click="showMarket(tabFavorites)">
            <span class="icon-star2" :class=" selectedMarket === tabFavorites ? 'star_color' : ''"></span>
            {{$t('landing.favorites')}}
          </label>
          <template v-for="market in markets">
            <!-- <input :id="`tab-${market}`" type="radio" name="tab-group" :checked="selectedMarket === market" /> -->
            <label :for="`tab-${market}`" :class="selectedMarket === market ? 'current-market' : ''" v-on:click="showMarket(market)" v-if="shortNameTab">{{ market | uppercase }}</label>
            <label :for="`tab-${market}`" :class="selectedMarket === market ? 'current-market' : ''" v-on:click="showMarket(market)" v-else>{{$t('landing.coin_markets', {coin: toUpperCase(market) })}}</label>
          </template>
          <label class="search__coin">
            <i class="icon-search icon"></i>
            <input
              type="text"
              :placeholder="$t('approved_order.btn_search')"
              class="visible input-search"
              v-model="searchKey">
          </label>
        </div>
    </div>
    <div class="table-content">
      <div id="content">
          <market-coin
              :priceScope="pricesFilter"
              :favorites="favorites"
              :marketSelected="selectedMarket"
              :columns="columns"
              :zone="zone"
              @SpotSelectedPairMarket="onSpotSelectedPairMarket" >
          </market-coin>
        </div>
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import MarketCoin from './MarketCoin';
  import FavoritesManager from '@/common/FavoritesManager';
  import Const from '@/common/Const';
  import {mapState} from 'vuex';

  export default {
    components: {
      MarketCoin
    },
    props: {
      //defaultMarket : { type: String, default: Const.DEFAULT_MARKET },
      columns       : { type: [Array], default: () => { return [] } },
      shortNameTab  : { type: Boolean, default: false },
      callback      : { type: Function, required: false },
      zone          : { default: 0 },
    },
    data() {
      return {
        coinSettings: [],
        favorites: [],
        searchKey: '',
        priceScope: {},
        pricesFilter: {},
        sortColumn: 'coin',
        isSortDesc: false,
        isShowSearchField: false,
        markets : [],
        selectedMarket: '',
        tabFavorites: Const.FAVORITES_TAB,
        defaultMarket: Const.DEFAULT_MARKET,
        isLogin : window.isAuthenticated,
      }
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
        theme: state => state.userThemeMode,
      }),
      getPairFavorites() {
        return window._.chain(this.favorites).map(item => item.coin_pair).value();
      }
    },
    watch: {
      selectedMarket(newMarket) {
        // Apply search key if have when change tabs
        if (!window._.isEmpty(this.searchKey)) {
          this.search();
          return;
        }
        const result = window._.chain(this.pricesScope)
            .filter(item => {
              return this.filterCoinByTab(item);
            })
            .orderBy(this.sortColumn, [this.isSortDesc ? 'desc' : 'asc'])
            .value();
        this.pricesFilter = Object.assign({}, result);
      },

      searchKey: function() {
        this.search();
      }
    },
    methods: {
      getEventHandlers() {
        return {
          SpotUpdateVolumeMarket: this.onSpotUpdateVolumeMarket,
        }
      },
      onSpotUpdateVolumeMarket(res) {
        // Update 24h Volumn in Market Table
        const pair = `${res.currency}_${res.coin}`;
        if (this.pricesScope) {
          const priceScope = this.pricesScope[pair];
          if (priceScope) {
            priceScope.volume = res.volume;
          }
        }
      },
      onSpotSelectedPairMarket(pair) {
        // Handle changed pair if need.

        if (this.callback) {
          return this.callback();
        }

      },
      search() {
        const keyWord = window._.toLower(this.searchKey);
        this.pricesFilter = window._.chain(this.pricesScope)
            .filter(item => {
              const filterByTab = this.filterCoinByTab(item);
              return filterByTab && item.coin.includes(keyWord);
            })
            .orderBy(this.sortColumn, [this.isSortDesc ? 'desc' : 'asc'])
            .value();
      },

      filterCoinByTab(item) {
        // Market coin tab
        let filterByTab = item.currency === this.selectedMarket;

        // Favorites tab
        if (this.tabFavorites === this.selectedMarket) {
          const pair = `${item.coin}/${item.currency}`;
          filterByTab = this.getPairFavorites.indexOf(pair) > -1;
        }
        return filterByTab;
      },

      toUpperCase(value) {
        if (!value) {
          return '';
        }
        return value.toUpperCase();
      },

      showMarket(market) {
        this.selectedMarket = market;
      },

      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          OrderTransactionCreated: this.onOrderTransactionCreated
        }
      },

      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);


        window._.each(this.pricesScope, item => {
          const coinPrice = newPrices[item.currency + '_' + item.coin];
          if (!coinPrice) {
            return;
          }

          const latestPrice = new BigNumber(`${coinPrice.price || 0}`);

          if (latestPrice.gt(`${item.max_price || 0}`)) {
            item.max_price = coinPrice.price;
          }
          if ( latestPrice.lt(`${item.min_price || 0}`)) {
            item.min_price = coinPrice.price;
          }

          if (item.current_price) {
            let result = latestPrice.sub(`${item.current_price || 0}`).toString();
            this.$set(item,'changePrice',result);
          }

          item.changed_percent = coinPrice.change;
          item.previous_price = coinPrice.previous_price;

          item.current_price = latestPrice.toString();
          item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
        });
      },

      onOrderTransactionCreated(data) {
        const pair = `${data.orderTransaction.currency}_${data.orderTransaction.coin}`;
        const priceScope = this.pricesScope[pair];
        if (priceScope) {
          priceScope.volume = new BigNumber(`${priceScope.volume || 0}`).add(`${data.orderTransaction.quantity}`);
        }
      },

      toUsdPrice(price, fromCurrency) {
        return new BigNumber(price).mul(new BigNumber(this.getPrice('usd_' + fromCurrency).price)).toString();
      },

      getPrice(pair) {
        return this.prices[pair] || { price: 1 };
      },

      calculateUsdPrice() {
        window._.forEach(this.pricesScope, (item) => {
          item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
        });
      },

      initData() {
        this.coinSettings = this.masterdata.coin_settings;
        this.markets = window._.chain(this.masterdata.market_settings)
            .filter(item => item.status == 'active')
            .map(item => item.currency)
            .value();

        if (this.markets && this.markets.length > 0) {
          this.defaultMarket = this.markets[0];
        }
        rf.getRequest('PriceRequest').getPrices24h().then(res => {
          this.pricesScopeUpdated(this.filterData(res.data));
          this.selectedMarket = this.getSelectTabViaRoute();
        });
        this.getFavorites();

        rf.getRequest('PriceRequest').getPrices().then(res => {
          this.onPricesUpdated(res.data);
        });
      },

      filterData(data) {
        let result = {};
        for (let key in data) {
          let pair = data[key];
          let pairZone = this.getPairZone(pair);
          if (pairZone == this.zone) {
            result[key] = pair;
          }
        }
        return result;
      },

      getPairZone(data) {
        for (let setting of this.coinSettings) {
          if (data.currency === setting.currency && data.coin === setting.coin) {
            return setting.zone;
          }
        }
        return -1;
      },

      getSelectTabViaRoute() {
        const query = this.$route.query || {};
        return query.tab || this.defaultMarket;
      },

      getFavorites() {
        FavoritesManager.getFavorites().then(data => {

          let markets = this.markets;

          data = window._.filter(data, item => {
            let coinPair = item.coin_pair;
            let currency = coinPair.split('/');
            if (currency.length > 1) {
              currency = currency[1];
            } else {
              currency = '';
            }
            return markets.indexOf(currency) != -1;
          });

          if(this.isLogin) this.favorites = data;
        });
      },

      pricesScopeUpdated(newPrices) {
        this.pricesFilter = this.pricesScope = newPrices;

        this.calculateUsdPrice();
      },
    },
    mounted() {
      this.initData();
    }
  }
</script>

<style lang="scss" scoped>

@import "@/assets/sass/variables";
  .box-table .table-content {
    background-color: $color-alabaster;
    border-top: 1px solid #e5e5e5;
    margin-top: 20px;
    padding: 0px 40px 30px;
    min-width: 350px;
  }

  .clearfix {
    content: "";
    clear: both;
    display: table;
  }
 .box-table {
    #container-tab {
      overflow: hidden;
      display: block;
      margin: 0 auto;
      margin-top: 50px;
      padding-left: 40px;
      padding-right: 40px;
      min-width: 820px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;
      .market-title {
        font-family: $font-family-roboto-semibold;
        font-size: $font-title-size-medium;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: $color-eden;
        margin-bottom: 57px;
        position: relative;
        span {
          font-family: $font-family-roboto-bold;
          position: relative;
          padding-left: 10px;
        }
        span.bold {
          font-weight: bold;
          padding-left: 0;
        }
        i {
          position: relative;
          top: 9px;
          font-size: 50px;
          display: inline-block;
        }
      }
      .group_table_landing{
        display: block;
        width: auto;
        margin: 0px;
        margin-bottom: -1px;
        height: 53px;

        label: {
          height: 100%
        }
      }
      
      .current-market{
        border-radius: 3px;
        color: $color-white;
        background-color: $color-jungle-green;
        font-weight: 600;
      }
      label {
        cursor: pointer;
        display: inline-block;
        float: none;
        padding: 10px 21px;
        border-radius: 20px;
        background: transparent;
        border: 0px;
        margin: 0px;
        font-family: $font-inter-bold;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-storm-grey;
        .icon-star2{
          padding-top: 1px;
          display: block;
          width: 20px;
          height: 20px;
          float: left;
          margin-right: 5px;
          color: $color-corn-pale;
        }    
        .icon-star2.star_color{
          color: $color-white;
        } 
      }
      input {
        &:checked + label {
          background: $color-white;
          color: $text-yellow;
          position: relative;
          z-index: 6;
          height: 100%;
          border-bottom: 0px solid $color-white;
        }
      }
      .search__coin{
        font-weight: 400;
        padding: 0px;
        margin-left: 15px;
        float: right;
        position: relative;
        i.icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
          font-size: 15px;
          color: $color-gray-mako;
        }
        .input-search{
          width: 200px;
          border-radius: 5px;
          border: solid 1px #e5e5e5;
          font-family: $font-family-roboto;
          font-size: $font-small;
          font-weight: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-grey;
          padding: 8px 10px 8px 30px;
          &:hover, &:active, &:focus {
            border-color: $color-green;
          }
        }
        .input_group_seach{
          cursor: pointer;
          float: left;
          &:before{        
            content: "\e948";
            font-family: 'icomoon' !important;
            font-size: $font-big;
            color: $color-grey;
            line-height: 20px;
            padding-top: 7px;
            height: 30px;
            width: 30px;
            display: block;
            text-align: center;
          }
        }
      }

    }
    #content {
      padding-bottom: 25px;
      color: $color-grey-dark;
      position: relative;
      width: 100%;
      z-index: 5;
      border: 0px;
      border-top: none;
      overflow-y: auto;
      overflow-x: auto;
      &::-webkit-scrollbar {
          width: 8px;
      }
      &::-webkit-scrollbar-track {
          background: $color-white;
      }
      &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background: $color-grey-border;
      }
      div {
      }
      .is-muted {
        color: $color-grey;
      }
    }

    #container-tab input {
      &#tab-favirotes:checked ~ #content #content-favirotes, &#tab-usd:checked ~ #content #content-usd, &#tab-btc:checked ~ #content #content-btc, &#tab-eth:checked ~ #content #content-eth {
        opacity: 1;
        z-index: 100;
        display: block;
      }
    }

    input.visible {
      visibility: visible !important;
    }
  }
</style>
<style lang="scss" scoped >
@media screen and (max-width: 992px) {
  .box-table .table-content {
    min-width: 820px;
  }
}
</style>