<template>
  <div id="identity">
    <div class="page960">
      <div class="section">
        <div class="header-identity">
        {{ $t('account.identity.header_verification') }}
        </div>
        <div class="form-identity-container">
          <div class="filed-verification">
            <div class="label-verification"></div>
            <div class="color_gray_dark fs13 mgb30 clearfix"> 
              <span v-html="$t('account.identity.verification_other')"></span>
            </div>
          </div>    
          <div id="identityForm">
            <div class="group_iden clearfix margin-left-10">
              <div class="left_content_iden lh25 color_gray_dark normal-title">
                <div v-html="$t('account.identity.verification_other1')"></div>
                <div v-html="$t('account.identity.verification_other2')"></div>
                <div v-html="$t('account.identity.verification_other3')"></div>
              </div>
              <div class="right_content_iden clearfix">
                <div class="mid_iden left_iden_0">
                  <div class="identity-form-group identity-form-group-0">
                    <div class="identity-form-label">{{ $t('account.identity.fullname') }}<span class="required">*</span></div>
                    <div class="identity-form-input">
                      <input type="text" name="fullName" v-model="record.fullName" :class="{'input-error': errors.has('fullName')}" maxlength="190" tabindex="101" @focus="resetErrors" >
                    </div>
                    <div class="error-box">
                      <span v-show="errors.has('fullName')" class="f-left is-error">{{ errors.first('fullName') }}</span>
                    </div>
                  </div>

                <div class="identity-form-group identity-form-group-1">
                    <div class="identity-form-label">{{ $t('account.identity.sex') }}<span class="required">*</span></div>
                    <div class="identity-form-input toolbar-element">
                      <select class="custom" v-model="record.gender" tabindex="102" @focus="resetErrors">
                        <option value="male">{{ $t('account.identity.male') }}</option>
                        <option value="female">{{ $t('account.identity.female') }}</option>
                      </select>
                    </div>
                    <!--
                    <div class="pick_gender_iden">
                      <input type="radio" class="radio-input color_back" v-model="record.gender" name="male" value="male" id="male"/>
                      <label class="lbl-sex color_back mgr15" for="male">{{ $t('account.identity.male') }}</label>

                      <input type="radio" class="radio-input color_back" v-model="record.gender" name="female" value="female" id="female"/>
                      <label class="lbl-sex color_back" for="female">{{ $t('account.identity.female') }}</label>
                    </div>
                    -->
                    <div class="error-box">
                      <span v-show="errors.has('gender')" class="fl-left is-error">{{ errors.first('gender') }}</span>
                    </div>
                  </div>
                </div>
                <div class="right_iden right_iden_0">
                  
                  <div class="identity-form-group identity-form-group-0">
                    <div class="identity-form-label">{{ $t('account.identity.country') }}<span class="required">*</span></div>
                    <div class="identity-form-input toolbar-element">
                      <select class="custom" v-model="record.country" tabindex="103" @focus="resetErrors">
                        <option v-for="country in countries">{{country.name}}</option>
                      </select>
                    </div> 
                  </div>
                  <div class="identity-form-group identity-form-group-1">
                    <div class="identity-form-label">{{ $t('account.identity.passport') }}<span class="required">*</span></div>
                    <div class="identity-form-input">
                      <input type="text" name="passport" v-model="record.passportID" :class="{'input-error': errors.has('passportID') || errors.has('id_number')}" tabindex="104" @focus="resetErrors">
                    </div>
                    <div class="error-box">
                      <span v-show="errors.has('passportID')" class="f-left is-error">{{ errors.first('passportID') }}</span>
                      <span v-show="errors.has('id_number')" class="f-left is-error">{{ $t('kyc.id_number_unique') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="divh50"></div>
            <div class="line_iden"></div>
            <div class="divh35"></div>

            <div class="group_iden clearfix">
              <div class="left_content_iden fs20 color_gray_dark lh25 left_content_iden_title">
                {{ $t('account.identity.identity_cover') }}<span class="required">*</span>
              </div>
              <div class="right_content_iden clearfix">
                <div class="mid_iden">
                  <div class="box_add_img_iden preView" :class="{'image-error': errors.has('id_front')}">
                    <img class="preview preview_0" :src="`${apiUrl}/${record.srcFront}`" ref="imageFront" :class="{'input-error': errors.has('id_front')}" v-if="record.srcFront">
                    <img class="preview preview_0" ref="imageFront" :class="{'input-error': errors.has('id_front')}" v-else>
                  </div>
                  <div v-show="errors.has('id_front')" class="error-box f-left is-error is-error-image f-left-error-image">
                      {{ errors.first('id_front') }}
                      <!-- <span v-show="errors.has('id_front')" class="f-left is-error"> {{ errors.first('id_front') }} </span> -->
                  </div>
                  <div class="fs13 color_gray mgb15"><p>{{ $t('account.identity.image_identity') }}</p></div>
                  <div class="button_add_file_iden" tabindex="105">
                    {{$t('account.identity.choose_file')}}
                    <input class="" type="file" @change="(event) => previewImage(event, $refs.imageFront, 1)" ref="file" accept="image/png,image/jpeg,image/jpg" :title="titleInput">
                  </div>

                </div>
                <div class="right_iden">
                  <div class="example mgb10">
                    <img width="230" src="@/assets/images/passport_front.png">
                  </div>
                  <div class="fs14">{{ $t('account.identity.example') }}</div>
                </div>
              </div>
            </div>

            <div class="divh50"></div>
            <div class="line_iden"></div>
            <div class="divh35"></div>

            <div class="group_iden clearfix">
              <div class="left_content_iden fs20 color_gray_dark lh25 left_content_iden_title">
                {{ $t('account.identity.identity_back') }}<span class="required">*</span>
              </div>
              <div class="right_content_iden clearfix">
                <div class="mid_iden">
                  <div class="box_add_img_iden preView" :class="{'image-error': errors.has('id_back')}">
                    <img class="preview preview_0" :src="`${apiUrl}/${record.srcBack}`" ref="imageBack" :class="{'input-error': errors.has('id_back')}" v-if="record.srcBack">
                    <img class="preview preview_0" ref="imageBack" :class="{'input-error': errors.has('id_back')}" v-else>
                  </div>
                  <div v-show="errors.has('id_back')" class="error-box f-left is-error is-error-image f-left-error-image">
                      {{ errors.first('id_back') }}
                      <!-- <span v-show="errors.has('id_back')" class="f-left is-error">{{ errors.first('id_back') }}</span> -->
                    </div>
                  <div class="fs13 color_gray mgb15 mgb16"><p>{{ $t('account.identity.identity_image_back') }}</p></div>
                  <div class="button_add_file_iden" tabindex="106">
                    {{ $t('account.identity.choose_file') }}
                    <input type="file" @change="(event) => previewImage(event, $refs.imageBack, 2)" accept="image/png,image/jpeg,image/jpg" ref="file1" :title="titleInput1">
                  </div>
                </div>
                <div class="right_iden">
                  <div class="example mgb10">
                    <img class="w_100_max_1000" src="@/assets/images/passport_back.jpg">
                  </div>
                  <div class="color_gray f-14">{{ $t('account.identity.example') }}</div>
                </div>
              </div>
            </div>

            <div class="divh50"></div>
            <div class="line_iden"></div>
            <div class="divh35"></div>

            <div class="group_iden clearfix">
              <div class="left_content_iden clearfix lh25 left_content_iden_title">
                <div class="fs20 color_gray_dark mgb20 title_selfie">
                  {{ $t('account.identity.selfie') }}<span class="required">*</span>
                </div>
                <div class="clearfix"></div>
                <p class="lh20 color_gray_dark fs13 mgb20" v-html="$t('account.identity.text_selfie')"></p>
                <!-- <ul class="other-info">
                  <li>{{ $t('account.identity.text_face') }}</li>
                  <li>{{ $t('account.identity.text_write') }}</li>
                  <li>{{ $t('account.identity.text_image') }}</li>
                  <li>{{ $t('account.identity.text_date') }}</li>
                </ul> -->
              </div>
              <div class="right_content_iden clearfix">
                <div class="mid_iden">
                  <div class="box_add_img_iden preView" :class="{'image-error': errors.has('id_selfie')}">
                    <img class="preview preview_0" :src="`${apiUrl}/${record.srcSelfie}`" ref="imageSelfie" v-if="record.srcSelfie">
                    <img class="preview preview_0" ref="imageSelfie" :class="{'input-error': errors.has('id_selfie')}" v-else>
                  </div>
                  <div v-show="errors.has('id_selfie')" class="error-box f-left is-error is-error-image f-left-error-image">
                      {{ errors.first('id_selfie') }}
                      <!-- <span v-show="errors.has('id_selfie')" class="f-left is-error">{{ errors.first('id_selfie') }}</span> -->
                  </div>
                  <div class="fs13 color_gray mgb15"><p>{{ $t('account.identity.image_passport_data') }}</p></div>
                  <div class="button_add_file_iden" tabindex="107">
                    {{ $t('account.identity.choose_file') }}
                    <input type="file" @change="(event) => previewImage(event, $refs.imageSelfie, 3)" accept="image/png,image/jpeg,image/jpg" ref="file2" :title="titleInput2">
                  </div>
                </div>
                <div class="right_iden">
                  <div class="example mgb10" id="selfie">
                    <img v-if="checkShowByLocale() == 'ja'" class="w_100_max_1000" src="@/assets/images/hold_passport_ja.png">
                    <img v-else-if="checkShowByLocale() == 'zh'" class="w_100_max_1000" src="@/assets/images/hold_passport_zh.png">
                    <img v-else-if="checkShowByLocale() == 'ko'" class="w_100_max_1000" src="@/assets/images/hold_passport_ko.png">
                    <img v-else-if="checkShowByLocale() == 'en'" class="w_100_max_1000" src="@/assets/images/hold_passport_en.png">
                  </div>
                  <div class="color_gray f-14">{{ $t('account.identity.example') }}</div>
                </div>
              </div>
            </div>


            <div class="divh50"></div>
            <div class="group_iden clearfix">
              <div class="left_content_iden clearfix">&nbsp;</div>
              <div class="right_content_iden clearfix">
                <p class="required"><span class="required">*</span>{{ $t('account.identity.text_condition') }}</p>
              </div>
            </div>

            <div class="divh10"></div>
            <div class="line_iden"></div>
            <div class="divh25"></div>

            <div class="group_iden clearfix">
              <div class="left_content_iden clearfix">&nbsp;</div>
              <div class="right_content_iden clearfix">
                <button class="button_submit_iden" :class="{chrome_only: isChrome}" v-on:click="saveIdentity" tabindex="108">{{ $t('account.identity.submit') }}</button>
              </div>
            </div>

            <div class="divh35"></div>

          </div>
        </div>
      </div>
    </div>
    <otp-modal class="otp-form" ref="otp_modal" :submit="verifyOtp" :params="record" :is-disable-button="isDisableButton" :contact_email="siteSetting.contact_email"/>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Modal from '@/components/shared_components/common/Modal.vue';
  import OtpModal from '@/components/shared_components/funds/common/OtpModal';
  import AuthenticationUtils from '@/common/AuthenticationUtils';
  import { mapState } from 'vuex';

  const IMAGE_FILE_UPLOAD_SIZE = 10485760; // 10Mb
  const KYC_STATUS_REJECTED = 'rejected';

  export default {
    components: {
      Modal,
      OtpModal,
    },
    data() {
      return {
        record: {
          id            : '',
          user_id       : '',
          fullName      : '',
          country       : 'Vietnam',
          gender        : 'male',
          passportID    : '',
          srcFront      : '',
          srcBack       : '',
          srcSelfie     : '',
          imageFront    : '',
          imageBack     : '',
          imageSelfie   : '',
          status        : '',
        },
        countries: [],
        file: '',
        titleInput: window.i18n.t('common.no_file_chosen'),
        titleInput1: window.i18n.t('common.no_file_chosen'),
        titleInput2: window.i18n.t('common.no_file_chosen'),
        validateErrors:{},
        otp: '',
        status: '',
        errMsg: '',
        isDisableButton: false,
        isChrome: window.navigator.userAgent.toLowerCase().indexOf('chrome') > 0,
        siteSetting: {},
        apiUrl: process.env.VUE_APP_STORAGE_URL,
      }
    },

    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
      }),
    },

    methods: {
      resetErrors() {
        if (this.errors.any()) {
          this.errors.clear();
        }
      },
      checkShowByLocale() {
        return AuthenticationUtils.getLocale();
      },
      showGoogleAuthenticationModal() {
        window.CommonModal.show('OtpModal');
      },

      previewImage: function(event, ref, flagTitle) {
        var input = event.target;
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = (e) => {
            ref.src = e.target.result;
          }
          reader.readAsDataURL(input.files[0]);
          
          switch (flagTitle) {
            case 1:
              this.titleInput = input.values;
              break;
            case 2:
              this.titleInput1 = input.values;
              break;
            case 3:
              this.titleInput2 = input.values;
              break;
            default:
              break;
          }
        }
      },
      validate() {
        this.record.imageFront = this.$refs.file.files[0] || this.record.imageFront;
        this.record.imageBack = this.$refs.file1.files[0] || this.record.imageBack;
        this.record.imageSelfie = this.$refs.file2.files[0] || this.record.imageSelfie;
        this.record.otp = this.otp;

        this.errors.clear();
        
        this.validateRequired(this.record.fullName.trim(), 'fullName');
        this.validateRequired(this.record.gender, 'gender');
        this.validateRequired(this.record.passportID, 'passportID');
        this.validateFormatNumber(this.record.passportID.trim(), 'passportID');
        this.validateCharacterLimit(this.record.passportID, 'passportID', 6, 15);

        this.validateImage(this.record.imageFront, this.record.srcFront, 'id_front');
        this.validateImage(this.record.imageBack, this.record.srcBack, 'id_back');
        this.validateImage(this.record.imageSelfie, this.record.srcSelfie, 'id_selfie');

        if(this.errors.count() != 0) {
          return false;
        }

        return true;
      },
      validateRequired(value, field) {
        if (_.isEmpty(value)) {
          this.errors.add(field, window.i18n.t('account.identity.errors.' + field + '.required'));
        }
      },
      validateImage(file, src, field) {
        let exts = ['.jpg', '.jpeg', '.png'];
        let fileType = file.type;
        let fileName = file.name;
        if (!file && window._.isEmpty(src)) {
          this.errors.add(field, window.i18n.t('account.identity.errors.' + field + '.required'));
        }
        else if (file.size > IMAGE_FILE_UPLOAD_SIZE) {
          this.errors.add(field, window.i18n.t('account.identity.errors.maxsize'));
        }
        else if (typeof fileType === 'string' && fileType.split('/')[0] !== 'image') {
          this.errors.add(field, window.i18n.t('validation.custom.image').formatUnicorn({values:".jpg, .jpeg, .png"}));
        }
        else if (typeof fileName === 'string' && !(new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName.toLowerCase())) {
          this.errors.add(field, window.i18n.t('validation.custom.image.mimes').formatUnicorn({values:".jpg, .jpeg, .png"}));
        }
      },
      validateFormatNumber(value, field) {
        var regsaid = /^[a-zA-Z0-9-]+$/;
        if(!regsaid.test(value)) {
          this.errors.add(field, window.i18n.t('account.identity.errors.format_number'));
        }
        /*
        const number = parseFloat(value);
        if (number <= 0 || !window._.isInteger(number)) {
          this.errors.add(field, window.i18n.t('account.identity.errors.format_number'));
        }
        */
      },
      validateCharacterLimit(value, field, min, max) {
        const inValid = !window._.inRange(value.length, min, max + 1);
        if (inValid) {
          this.errors.add(field, window.i18n.t('account.identity.errors.character_limit', {min: min, max: max}));
        }
      },
      saveIdentity() {
        if (!this.validate()) {
          return;
        }
        if(this.record.status == 'verified'){
          return;
        }
        this.verifyOtp(this.record);
      },
      verifyOtp(params) {
        $('.loading-effect').css('display', 'flex');
        this.isDisableButton = true;
        rf.getRequest('UserRequest').otpVerify({ otp:params.otp }).then(res => {
          this.createIdentity(params);
        }).catch(error => {
          $('.loading-effect').hide();
          this.isDisableButton = false;
          if (!error.response) {
            this.showError(window.i18n.t('common.message.network_error'));
            return;
          }

          const errors = error.response.data.errors;
          if(params.otp.length === 6) {
            
          } else {
            this.showGoogleAuthenticationModal();
            return;
          }

          window._.each(errors, (value, key) => {
            this.errors.add(key, value[0].formatUnicorn({values:".jpg, .jpeg, .png"}));
          });
        });
      },
      createIdentity(params) {
        return rf.getRequest('UserRequest').createIdentity(this.getParams(params)).then(res => {
          $('.loading-effect').hide();
          this.showSuccess(res.message);
          this.$router.push({path: '/account'});
          this.isDisableButton = false;
        }).catch(error => {
          $('.loading-effect').hide();
          this.isDisableButton = false;
          if (!error.response) {
            this.showError(window.i18n.t('common.message.network_error'));
            return;
          }
          const errors = error.response.data.errors;
          if (typeof errors === 'undefined') {
            this.showError(error.response.data.message);
          }
          window.CommonModal.hide('OtpModal');
          this.validateErrors = errors;
          window._.each(this.validateErrors, (value, key) => {
            this.errors.add(key, value[0].formatUnicorn({values:".jpg, .jpeg, .png"}));
          });
        });
      },

      getParams(data) {
        let fd = new FormData();

        fd = this.addFormData(fd, 'id', data.id);
        fd = this.addFormData(fd, 'user_id', data.user_id);
        fd = this.addFormData(fd, 'full_name', data.fullName);
        fd = this.addFormData(fd, 'country', data.country);
        fd = this.addFormData(fd, 'gender', data.gender);
        fd = this.addFormData(fd, 'id_number', data.passportID);
        fd = this.addFormData(fd, 'id_front', data.imageFront);
        fd = this.addFormData(fd, 'id_back', data.imageBack);
        fd = this.addFormData(fd, 'id_selfie', data.imageSelfie);
        fd = this.addFormData(fd, 'otp', data.otp);

        return fd;
      },

      addFormData(form, key, value) {
        if (value) {
          form.append(key, value);
        }
        return form;
      },

      convertResponse(data) {
        if (!data) {
          return;
        }
        const apiURL = process.env.VUE_APP_API_URL;

        this.record.id            = data.id;
        this.record.user_id       = data.user_id;
        this.record.fullName      = data.full_name;
        this.record.country       = data.country;
        this.record.gender        = data.gender;
        this.record.passportID    = data.id_number;
        this.record.srcFront      = data.id_front;
        this.record.srcBack       = data.id_back;
        this.record.srcSelfie     = data.id_selfie;
        this.record.imageFront    = '';
        this.record.imageBack     = '';
        this.record.imageSelfie   = '';
        this.record.status        = data.status;
      },

      showSuccess(message) {
        Message.success(
          message,
          {},
        );
      },
      showError(message) {
        Message.error(message);
      },

      showKycVerifiedMessage() {
        window.ConfirmationModal.show({
          type: 'warning',
          title: '',
          content: this.$t('kyc.msg_verified'),
          btnCancelLabel: '',
          btnConfirmLabel: '',
          onConfirm: () => {},
          onCancel: () => {}
        });
      },

      showOtpUnverifiedMessage() {
        window.ConfirmationModal.show({
          type: 'warning',
          title: '',
          content: this.$t('account.otp.should_enable_first'),
          btnCancelLabel: '',
          btnConfirmLabel: '',
          onConfirm: () => {},
          onCancel: () => {}
        });
      },

      redirectAccountPageIfNeed(callback) {
        setTimeout(() => {
          callback();
          this.$router.push({ name: 'Account' });
        }, 300);
      },
      getMasterData() {
        window._.each(this.masterdata.settings, item => {
          this.siteSetting[item.key] = item.value;
        });
      }
    },
    beforeMount() {
      rf.getRequest('UserRequest').getCurrentUser().then(res => {
        const securitySetting = res.data.security_setting;
        if (!securitySetting.otp_verified) {
          this.redirectAccountPageIfNeed(this.showOtpUnverifiedMessage);
        }
        if (securitySetting.identity_verified) {
          this.redirectAccountPageIfNeed(this.showKycVerifiedMessage);
        }
      });
    },
    mounted: function () {
      this.getMasterData();
      document.title = this.$t('account.identity.header_identity') + ` – ${this.tabTitle}`;
      this.countries = this.masterdata.countries;
      this.countries.sort((a, b) => (a.name > b.name) ? 1 : -1)
      rf.getRequest('UserRequest').getUserKyc().then(res => {
        this.convertResponse(res.data);
      });
      window.addEventListener('keyup', (event) => {
        if (event.keyCode === 13 && this.$route.name == 'IdentityPersonal') { 
          this.saveIdentity();
        }
      });
      this.$on('UPDATED_LOCALE', () => {

        this.titleInput = window.i18n.t('common.no_file_chosen');
        this.titleInput1 = window.i18n.t('common.no_file_chosen');
        this.titleInput2 = window.i18n.t('common.no_file_chosen');
       
      });
    }
  }

  String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
  function () {
      "use strict";
      var str = this.toString();
      if (arguments.length) {
          var t = typeof arguments[0];
          var key;
          var args = ("string" === t || "number" === t) ?
              Array.prototype.slice.call(arguments)
              : arguments[0];

          for (key in args) {
              str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
          }
      }
      return str;
  };
</script>
<style lang="scss">
  @import "@/assets/sass/variables";
  .w_100_max_1000 {
    width: 100%;
    max-width: 1000px;
  }
  @media screen and (max-width: 5760px) and (min-width: 2560px) {
    #identityForm .image-error {
      border: 2px solid #e2221e !important;
    }
  }
  @media screen and (min-width: 5760px) {
    #identityForm .image-error {
      border: 4px solid #e2221e !important;
    }
  }
  .is-error {
    color: $color-alizarin-crimson !important;
  }
  .input-error {
    border-color: $color-alizarin-crimson !important;
  }
  .f-left {
    float: left;
  }
  .required {
    color: $color-alizarin-crimson;
  }
  .error-box {
    //position: absolute;
  }
  #identity {
    height: auto;
    width: 100% !important;
    padding: 0px 0px 40px 0px;
    background-color: var(--background-color-primary);
    .section {
      width: 1400px;
      margin: auto;
      max-width: 100%;
      padding: 50px 0;
      .header-identity {
        margin-bottom: 15px;
        width: 100%;
        font-family: $font-inter-bold;
        color: var(--text-primary-color);
        font-size: 34px;
      }
      .form-identity-container {
        color: var(--text-primary-color);
        //line-height: 20px;
        font-size: $font-big;
        font-weight: $font-inter-regular !important;
      }
    }
  }
  #identityForm {
    .group_iden {
      display: block;
      width: 100%;
      padding-right: 40px;
      .left_content_iden {
        width: 280px;
        min-height: 1px;
        margin-right: 67px;
        float: left;
        font-family: $font-inter-regular;
        color: var(--text-primary-color);
        .title_selfie {
          color: var(--text-primary-color);
          font-family: $font-inter-regular;
          font-size: $font-big
        }
        & div, p {
        color: var(--text-primary-color);
          font-family: $font-inter-regular;
        font-size: $font-big;
      }
      }
      .left_content_iden_title {
        font-family: $font-inter-regular;
        color: var(--text-primary-color);
        margin-right: 77px;
      }
      .normal-title {
        line-height: 1.92;
        color: #333333;
        div {
          span {
            margin-right: 15px;
          }
        }
      }
      
      .right_content_iden {
        width: calc(100% - 280px - 77px);
        min-height: 1px;
        float: left;
        display: block;
        p.required {
          margin-left: 10px;
        }
      }
      .mid_iden {
        display: block;
        float: left;
        width: calc(100% - 50% - 40px);
        margin-right: 40px;
      }
      .right_iden {
        display: block;
        float: left;
        width: calc(100% - 50% - 40px);
        margin-left: 40px;
        font-size: $font-small;
      }
    }
    .identity-form-group{
      margin-bottom: 15px;
      .identity-form-label {
        line-height: 20px;
        margin-bottom: 8px;
        font-family: $font-inter-regular;
        font-size: $font-big;
      }
      .identity-form-input {
        input {
          width: 100%;
          height: 42px;
          line-height: 20px;
          padding: 11px 20px;
          font-size: $font-inter-regular;
          background: var(--background-color-second);
          border: 1px solid $color-alto;
          border-radius: 8px;
          &:hover, &:active, &:focus {
            border-color: $color-green;
          }
        }
      }
      .pick_gender_iden {
        height: 40px;
        line-height: 20px;
        padding: 10px 0px;
      }
    }
    .box_add_img_iden {
      display: block;
      width: 100%;
      //margin-bottom: 15px;
      img {
        width: 100%;
        background-color: $color-gray-2;
        min-height: 200px;
        padding-left: 0px;
      }
    }
    .image-error {
      border: 1px solid $color-alizarin-crimson;
    }
    .button_add_file_iden {
      padding: 8px 10px 7px 10px;
      width: 130px;
      position: relative;
      cursor: pointer;
      font-family: $font-inter-regular;
      font-size: $font-medium-bigger;
      text-align: center;
      margin: auto;
      input {
        position: absolute;
        opacity: 0;
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        top: 0px;
        left: 0px;
      }
        height: 40px;
        max-width: 100%;
        border-radius: 8px;
        background-color: $color-caribbean-green;
        color: $color-grey-dark;
        &:disabled {
          background-color: $background-bright-gray !important;
          border-color: $border-bright-gray !important;
          color: $text-default !important;
        }
      }
    .example {
      max-width: 100%;
      img {
        max-width: 1000px;
      }
    }
    .other-info {
      list-style-type: disc;
      font-size: $font-small;
      color: $color-grey-dusty;
      line-height: 20px;
      padding-left: 20px;
      li {
        position: relative;
        margin-bottom: 5px;
        padding-left: 10px;
      }
    }
    .button_submit_iden {
      height: 40px;
      padding: 8px 10px 7px 10px;
      width: 260px;
      border-radius: 8px;
      background-color: $color-caribbean-green;
      font-family: $font-inter-regular;
      font-size: $font-big;
      color: $color-grey-dark;
      //margin-left: 10px;
      margin: auto;
    }
  }
  .fs20 {
    font-size: $font-big-20 !important;
  }
  .fs14 {
    font-size: $font-root !important;
  }
  .fs13 {
    font-size: $font-small !important;
    & p {
      font-family: $font-inter-regular !important;
    }
  }

  .margin_330 {
    margin-left: 330px;
  }

  .color_back {
    color: $color_black !important;
  }

  .color_gray_dark {
    color: $color-grey-dark;
    & span {
      color: var(--text-primary-color);
      font-family: $font-inter-regular;
      font-size: $font-big;
    }
  }
  .mgb30 {
    margin-bottom: 30px;
  }
  .mgb20 {
    margin-bottom: 20px;
  }
  .mgb15 {
    margin-bottom: 15px;
  }
  .mgb10 {
    margin-bottom: 10px;
  }
  .lh25 {
    line-height: 25px;
  }
  .lh20 {
    line-height: 20px;
  }
  .divh50 {
    display: block;
    width: 100%;
    height: 50px;
  }
  .divh35 {
    display: block;
    width: 100%;
    height: 35px;
  }
  .divh25 {
    display: block;
    width: 100%;
    height: 25px;
  }
  .divh10 {
    display: block;
    width: 100%;
    height: 10px;
  }
  .line_iden {
    display: block;
    width: 100%;
    border-top: 1px solid $color-alto
  }
  .mgr15 {
    margin-right: 15px;
  }
  .margin-left-10 {
    margin-left: 10px;
  }
  select.custom {
    width: 100%;
    height: 42px;
    line-height: 20px;
    padding: 11px 20px;
    font-size: 14px;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    background: var(--background-color-second) url(@/assets/images/select-arrow.png) no-repeat right;
    background-position-x: 98%;
    &:hover, &:active, &:focus {
      border-color: $color-caribbean-green;
    }
    & :deep(option) {
      background-color: var(--background-color-primary);
      color: var(--text-color);
      &:hover {
        background-color: red;
      }
    }
  }
  select.custom::-ms-expand {
    display: none;
  }
  .preview_0{
    padding-left: 1px;
  }
  .left_iden_0{
    width: 100% !important;
    display: flex !important;
    justify-content: space-between;
    .identity-form-group-0{
      width: calc(100% - 50% - 40px);
    }
    .identity-form-group-1{
      width: calc(100% - 50% - 40px);
    }
  }
  .right_iden_0{
    width: 100% !important;
    display: flex !important;
    justify-content: space-between;
    margin-left: 0px !important;
    .identity-form-group-0{
      width: calc(100% - 50% - 40px);
    }
    .identity-form-group-1{
      width: calc(100% - 50% - 40px);
    }
  }
  .is-error-image{
    width: 100%;
  }
  .f-left-error-image{
    float: none;
    margin-top: 5px;
  }
  .f-14 {
    font-size: $font-root;
  }
  .chrome_only:focus {
    outline: -webkit-focus-ring-color auto 5px $color-green !important;
    outline-offset: 1px !important;
  }
</style>
