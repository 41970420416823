<template>
  <div>
    <div class="VuePagination" v-show="totalPages > 1">
      <ul
          class="pagination VuePagination__pagination"
          :class="{'justify-content-end': paginationPosition === 'right'}"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="records"
            :per-page="perPage"
            first-number
            last-number
        >
          <template #prev-text><i class="icon-arrow4"></i></template>
          <template #next-text><i class="icon-arrow3"></i></template>
        </b-pagination>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Cookies from 'js-cookie'

export default {
  props: {
    pageParent: {
      type: Number,
      default: 1,
    },
    records: {
      type: Number,
      required: true
    },
    chunk: {
      type: Number,
      required: false,
      default: 5
    },
    perPage: {
      type: Number,
      required: true,
    },

    paginationPosition: {
      type: 'center' | 'right' | 'left',
      default: 'center',
    },
  },
  data: function () {
    return {
      page: this.pageParent,
      theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
      currentPage: 1
    }
  },
  watch: {
    records() {
      if (this.page > this.totalPages) {
        this.page = 1;
      }
    },
    pageParent() {
      this.page = this.pageParent;
    },

    userThemeMode(theme) {
      this.theme = theme;
    },
    currentPage(page) {
      this.page = page
      this.$emit('Pagination:page', page)
    },
  },
  computed: {
    ...mapState({
      userThemeMode: state => state.userThemeMode
    }),
    pages: function () {
      if (!this.records)
        return []

      return range(this.paginationStart, this.chunk, this.totalPages)
    },
    totalPages: function () {
      return this.records ? Math.ceil(this.records / this.perPage) : 1
    },
    currentChunk: function () {
      return Math.ceil(this.page / this.chunk)
    },
    paginationStart: function () {
      return ((this.currentChunk - 1) * this.chunk) + 1
    },
  },
  methods: {
    activeClass: function (page) {
      return parseInt(this.page) === parseInt(page) ? 'active' : ''
    }
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/sass/common.scss";
@import "@/assets/sass/variables";
.dark-theme {
  .pagination{
    :deep(ul) {
      li > .page-link, button{
        border:  1px solid $color-gun-powder;
        background-color: $color-night;
        color: $color-storm-grey;
      }
      li {
        button {
          &:hover{
            //border: 1px solid $color-gray-mako !important;
          }
        }
      }
      .disabled{
        &:first-child , &:last-child {
          .page-link{
            border: 1px solid $color-gray-mako !important;
            .icon-arrow4, .icon-arrow3 {
              color:$color-auro-metal ;
              font-size: 12px;
            }
          }
        }
      }
      .page-item {
        .icon-arrow4, .icon-arrow3 {
          font-size: 12px;
        }
        &.disabled  {
          .page-link {
            border: none ;
            background-color: unset;
          }
        }
      }
      .active > button, {
        color: $color-caribbean-green ;
        border: 1px solid $color-caribbean-green !important;
        background-color: $color-night;
      }
    }
  }
}
:deep(.pagination){
  ul {
    li > .page-link, button{
      width: 32px;
      height: 32px;
      align-items: center;
      display: flex;
      justify-content: center;
      .icon-arrow4 {
        font-size: 12px !important;
      }
    }
    li {
      width: 32px;
      height: 32px;
      font-family: $font-inter-regular;
      //font-weight: 600;
      button {
        width: 32px;
        height: 32px;
        border-radius: 5px;
        font-size: 14px;
        font-family: $font-inter-regular;
        //font-weight: 600;
        &:hover{
          //border: 1px solid $color-caribbean-green;
          background-color: unset;
          color: $color-caribbean-green;
          @media screen and (max-width: 992px){
            border: unset;
          }
        }
      }
    }
    .disabled{
      &:first-child , &:last-child {
        .page-link{
          color: $color-gray-alto!important;
          background: transparent!important;
          border: 1px solid $color-gray-alto !important;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px !important;
          border-radius: 5px!important;
          font-family: $font-inter-regular;
          font-weight: 600;
          .icon-arrow4, .icon-arrow3 {
            font-size: 12px !important;
          }
        }
      }
    }
    .page-item {
      background: transparent!important;
      //border: 1px solid #e6e6e6;
      border-radius: 5px!important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      .icon-arrow3 {
        font-size: 12px;
      }
      &.disabled  {
        .page-link {
          border: none ;
          background-color: unset;
        }
      }
    }
    .active > button{
      color: $color-caribbean-green ;
      border: 1px solid $color-caribbean-green;
      background-color: unset;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";
@import "@/assets/sass/variables";
.dark-theme {
  //.pagination > .disabled > a {
  //  color: $color-gray-abbey !important;
  //}
  //.page-item .number-item {
  //  color: $color-oslo-gray;
  //}
  //.page-item a {
  //  border: 1px solid $color-silver-dark !important;
  //}
  //.pagination > li > a:hover {
  //  color: $color-oslo-gray;
  //}
}

.pagination {
  display: flex;
  gap: 8px;
  justify-content: center;
  li {
    height: 32px;
    width: 32px;
    font-family: $font-inter-regular;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}
.pagination > li > a, .pagination > li > span {
  color: var(--color-pagi);
}
.pagination > .disabled > a {
  color: $color-gray-alto !important;
}
.VuePagination {
  // background-color: $color-alabaster;
  padding-top: 20px;
}
.page-item {
  &.active {
    a {
      color: $color-caribbean-green !important;
      border: 1px solid $color-caribbean-green !important;
    }
  }
  .number-item {
    color: var(--dark-1);
    font-family: $font-inter-regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
    width: 32px;

  }
  a {
    background: transparent !important;
    border: 1px solid $color-gray-alto !important;
    border-radius: 5px;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: $font-mini;
    }
  }
}
.page-prev {

}
.page-next {

}
</style>
