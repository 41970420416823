<template>
  <div class="tag-container">
    <button
      v-for="(tag, index) in tags"
      :key="index"
      :class="['tag-button', { 'active-tag': selectedTag === tag }]"
      @click="selectTag(tag)"
    >
      {{ tag }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
    defaultSelected: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedTag: this.defaultSelected,
    };
  },
  methods: {
    selectTag(tag) {
      this.selectedTag = tag;
      this.$emit("tag-selected", tag);
    },
  },
};
</script>

<style scoped>
.tag-container {
  display: flex;
  gap: 10px;
}

.tag-button {
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #4a4a4a;
  color: #8e8e92;
  border-radius: 20px;
  cursor: pointer;
}

.active-tag {
  background-color: white; /* Màu sáng khi được chọn */
  color: black;
}

.tag-button:hover {
  border-color: #ffffff;
}
</style>
