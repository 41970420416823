
<template>
  <div class="container-item-management">
    <div class="header-item-api">
      <span class="header-name">{{name}}</span>
      <div class="action">
        <button class="btn btn-edit" @click="onEdit"> {{ $t('user.edit') }}</button>
        <button class="btn btn-delete" @click="onDelete">{{ $t('account.api.delete') }}</button>
      </div>
    </div>
    <div class="body-item-api">
      <div class="container-qr">
        <img class="img_qr" :src="qr_code" v-if="qr_code" alt="image"/>
        <img class="img_qr fake" src="/images/qr_code.png" v-else alt="image"/>
      </div>
      <div class="container-key-info">
        <div :style="{display: 'flex', flexDirection: 'column'}">
          <span class="info-item-api">{{ $t('account.api.api_key') }}</span>
          <span class="info-value-api" >{{id}}  <img :src="require(`@/assets/images/drx/copy-item.svg`)" @click="copyApiKey(id)" class="img-copy" /></span>
        </div>
        <div :style="{display: 'flex', flexDirection: 'column'}">
          <span class="info-item-api">{{ $t('account.api.secret_key') }}</span>
          <span class="info-value-api">{{secret}}</span>
        </div>
      </div>
      <div class="grid-checkbox">
        <span class="info-item-api">Permissions</span>
        <div class="container-checkbox">
          <div class="checkbox-function" v-for="item in scopeSetting" :key="item.key">
            <label :for="`checkbox_id${item.key}`">
              <input disabled class="checkbox_input" type="checkbox" name="checkbox" v-model="item.isChecked" :id="`checkbox_id${item.key}`">
              <span class="button-check"></span>
              <span class="text-check">{{ item.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/common/Utils";

export default {
  name: "APIManagementItem",
  props: {
    qr_code: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    secret: {
      type: String,
      required: true,
    },
    scopeSetting: {
      type: [],
      required: true,
    }
  },
  methods:{
    copyApiKey(text) {
      if (!navigator.clipboard) {
        Utils.fallbackCopyTextToClipboard(text);
        return;
      }
      console.log(text)
      navigator.clipboard.writeText(text);
      Message.success(window.i18n.t('funds.deposit.copy_success'));
    },
    onEdit() {
      this.$emit('edit');
    },
    onDelete() {
      this.$emit('delete');
    },
  }
}
</script>


<style scoped lang="scss">
.container-item-management{
  width: 100%;
  display: flex;
  flex-direction: column;
  .header-item-api{
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-top: 1px solid var(--button-border-outline);
    border-right: 1px solid var(--button-border-outline);
    border-left: 1px solid var(--button-border-outline);
    border-radius: 16px 16px 0 0;
    background: var(--bg-our-item);
    @media screen and (max-width: 1199px) {
     align-items: center;
    }
    .header-name{
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      color: var(--text-primary-color);
      @media screen and (max-width: 1199px) {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .action {
      display: flex;
      gap: 12px;
      .btn{
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 1234px;
        border: 1px solid var(--button-border-outline);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        background: transparent;
      }
      .btn-edit {
        color: var(--text-primary-color);
      }
      .btn-delete {
        color: #F1493F;
      }
    }
  }
  .body-item-api{
    display: flex;
    padding: 24px;
    border: 1px solid var(--button-border-outline);
    border-radius: 0px 0px 16px 16px;
    flex-wrap: wrap;
    gap: 40px;
    .container-qr{
      width: 120px;
      height: 120px;
      border-radius: 4px;
      img {
        border-radius: 4px;
      }
    }
    .container-key-info{
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 423px;
      width: 100%;

      .info-value-api{
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: var(--text-primary-color);
        word-break: break-all;
        @media screen and (max-width: 500px) {
          font-size: 14px;
        }
      }
      .img-copy{
        margin-left: 8px;
        width: 16px;
        height: 16px;
        @media screen and (max-width: 500px) {
          width: 20px;
          height: 20px;
        }
      }
    }
    @media screen and (max-width: 500px) {
      justify-content: center;
      gap: 20px;
      padding: 16px;
    }
  }
}
.info-item-api{
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #8E8E92;
}
.grid-checkbox {
  .container-checkbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Creates two columns of equal width
    gap: 16px 40px; // Sets row gap to 16px and column gap to 40px

    .checkbox-function {
      display: flex;
      align-items: center;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        .text-check{
          font-size: 14px;
          font-weight: 600;
          color: var(--color-text-permission-api);
          @media screen and (max-width: 500px) {
            font-size: 12px;
          }
        }
        .checkbox_input {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
