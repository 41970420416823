 import rf from '@/request/RequestFactory';

const actions = {
  getInsurance({ commit }) {
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getInsurance().
        then(res => {
          commit('updateInsurance', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  getInsuranceRequest({}, params) {
    return rf.getRequest('MarginRequest').getInsurance(params);
  },
};

const getters = {
  insurance: state => {
    return state.insurance
  }
}

const mutations = {
  updateInsurance: (state, payload) => {
    state.insurance = { ...payload }
  },
}

const moduleName = 'insurance';
export default {
  actions,
  getters,
  mutations,
  state: {
    [moduleName]: {}
  },
};
