import rf from '@/request/RequestFactory';

const actions = {
  updateStateContract: (state, payload) => {
    state.commit('updateStateContract', payload);
  },
  saveAllContractName: (state, payload) => {
    state.commit('saveAllContractName', payload);
  },
  updateStateTrigger: (state, payload) => {
    state.commit('updateStateTrigger', payload);
  }
}

const mutations = {
  updateStateContract: (state, {symbol, root_symbol}) => {
    state.common.stateContract = {
      ...state.common.stateContract,
      [root_symbol]: symbol
    }
  },
  saveAllContractName: (state, payload) => {
    const {instrument} = state;
    const input = payload ? payload : instrument 

    if(typeof input == 'object' && input.length > 0) {
      let data = []
      input.map(el=>data = [...data, el.symbol])
      state.common.allContract = data
    }
  },
  updateStateTrigger: (state, {symbol, root_symbol}) => {
    state.common.stateTrigger = {
      ...state.common.stateTrigger,
      [root_symbol]: symbol
    }
  }
}

const getters = {
  checkContractAvailable: (state) => (symbol) => {
    const {allContract} = state.common
    if(!symbol) return
    return (typeof allContract == 'object' && allContract.length > 0) ? allContract.includes(symbol) : false
  },
  stateContract: (state) => {
    return state.common.stateContract
  },
  stateTrigger: (state) => {
    return state.common.stateTrigger
  },
  getCommonStored: (state) => {
    return state.common
  }
}
const state = {
  common: {
    //store symbol follow by it own instrument
    stateContract: {},
    //store market price depends on instrument
    stateTrigger: {},
    //store all symbol and instrument (for name attr only). This will force to get the symbol properly
    allContract: [],
  }
}

const commonStore = {
  getters,
  state,
  actions,
  mutations,
}

export default commonStore