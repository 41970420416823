<template>
	<div class="content-box second">
		<div class="qr-code-container">
			<img class="img_qr" :src="urlQR" v-if="urlQR" />
        <!-- <button class="btn btn-show-recovery-code" @click="ShowRecoveryCode = !ShowRecoveryCode">
          <span v-if="!ShowRecoveryCode">{{ $t('account.modal.agreement_otp.view_secret_key') }}</span>
          <span type="text" class="recovery-code" name="recovery_code" v-if="ShowRecoveryCode">{{ key2fa }}</span>
        </button> -->
      <div class="backup-code"><span class="title-code">Backup code:</span> <span class="code">{{key2fa}}</span></div>
		</div>
	</div>
</template>
<script>
	import rf from '@/request/RequestFactory'

	export default {
    data() {
      return {
        urlQR : '',
        key2fa: '',
        ShowRecoveryCode: false,
      }
    },
    methods : {
      getQRCodeGoogleUrl() {
        rf.getRequest('UserRequest').getQRCodeGoogleUrl().then(res => {
          this.urlQR = res.data.url;
          this.key2fa = res.data.key;
          this.$emit('keyUpdated', this.key2fa);
        });
      },
    },
    created () {
      this.getQRCodeGoogleUrl();
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .qr-code-container {
    display: flex;
    align-items: center;
    padding: 16px;
    background: var(--bg-stats-home);
    gap: 27px;
    border-radius: 15px;
    margin-left: 55px;
    max-width: 337px;
    width: 100%;
    .img_qr{
      width: 100%;
      max-width: 120px;
    }
    @media screen and (max-width: 500px) {
      margin-left: 0px;

    }
    .title-code{
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color: #8E8E92;
      line-height: 16px;
      letter-spacing: -0.06px;
    }
    .code{
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      color: var(--text-primary-color);
      width: 100%;
      word-break: break-all;
    }
  }

  .qr-code {
    width: 120px;
    height: 120px;
  }

  .backup-code {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 150px;
    width: 100%;
  }
  .scan_qrcode_des {
    font-family: $font-inter-regular;
    color: var(--text-close);
    font-size: 18px;
    line-height: 20px;
  }
  .btn-show-recovery-code {
    text-align: center;
    font-size: 22px;
    line-height: 27px;
    font-family: $font-inter-bold;
    color: var(--text-primary-color);
  }

@media screen and (max-width: 992px) { 
  .scan_qrcode_des {
    font-size: 14px;
    line-height: 16px;
  }
  .btn-show-recovery-code {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>