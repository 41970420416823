<template>
  <div class="container-google-auth">
    <Breadcrumb :items="breadcrumbItems" class="container-breadcrumb" />
    <LinkGoogleAuthenticator v-if="!enableOtp"/>
    <DisableGoogleAuth v-if="enableOtp" />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import LinkGoogleAuthenticator from "@/components/setting_pages/LinkGoogleAuth.vue";
import rf from "@/request/RequestFactory";
import DisableGoogleAuth from "@/components/setting_pages/DisableGoogle.vue";

export default {
  name: "GoogleAuthenticatorPage",
  components: {DisableGoogleAuth, LinkGoogleAuthenticator, Breadcrumb},
  props: {
    status: {
      type: String,
      default: "disabled"
    }
  },
  data(){
    return {
      enableOtp: false,
      breadcrumbItems: [
        { label: "Profile", link: "/account/dashboard" },
        { label: "Security", link: "/account/security" },
        { label: "Link Google Authenticator", link: "/account/security" },
      ],
    }
  },
  computed: {
    statusClass() {
      return this.status.toLowerCase();
    },
    statusText() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    }
  },
  methods:{
    loadDataImmediately() {
      this.getSecuritySettings();
    },
    getSocketEventHandlers() {
      return {
        OtpUpdated: this.getSecuritySettings
      }
    },
    getSecuritySettings() {
      rf.getRequest('UserRequest').getSecuritySettings().then(res => {
        this.settings = Object.assign({}, this.settings, res.data);
        this.enableOtp = window._.get(this, "settings.otp_verified", 0);
      });
    },
  },
  created() {
    this.loadDataImmediately();
    document.title = this.$t('menu.account') + ` – ${this.tabTitle}`;
  },
};
</script>

<style scoped lang="scss">
.container-google-auth{
  padding: 24px 40px;
  @media screen and (max-width: 500px) {
    padding: 24px 20px;
  }
}
</style>
