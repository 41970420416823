import UserRequest from './UserRequest';
import LocaleRequest from './LocaleRequest';
import MasterdataRequest from './MasterdataRequest';
import NotificationRequest from './NotificationRequest';
import OrderRequest from './OrderRequest';
import PriceRequest from './PriceRequest';
import HistoryRequest from './HistoryRequest';
import TransactionRequest from './TransactionRequest';
import ServiceRequest from './ServiceRequest';
import NoticeRequest from './NoticeRequest';
import FavoriteRequest from './FavoriteRequest';
import UserSettingRequest from './UserSettingRequest';
import CoinMarketCap from './CoinMarketCap';
import CurrencyCoinRequest from './CurrencyCoinRequest';
import MarketPriceChangeRequest from './MarketPriceChangeRequest';
import BitfinexRequest from './BitfinexRequest';
import BithumbRequest from './BithumbRequest';
import AddressManagerRequest from './AddressManagerRequest';
import ZendeskRequest from './ZendeskRequest';
import SiteSettingRequest from './SiteSettingRequest';
import CoinCheckRequest from './CoinCheckRequest';
import SettingRequest from './SettingRequest';
import NewsRequest from './NewsRequest';
import BalanceRequest from './BalanceRequest';
import MarginRequest from './MarginRequest';
import ChartsRequest from './MAM/ChartsRequest';
import MamRequest from './MamRequest';
import InsuranceRequest from './InsuranceRequest';
import LeaderboardRequest from './LeaderboardRequest';
import EntryRequest from './EntryRequest';
import MarketStatistic from "@/request/MarketStatistic";
import MarketHot from "@/request/MarketHot";

const requestMap = {
  ChartsRequest,
  UserRequest,
  LocaleRequest,
  MasterdataRequest,
  NotificationRequest,
  OrderRequest,
  PriceRequest,
  HistoryRequest,
  TransactionRequest,
  ServiceRequest,
  NoticeRequest,
  FavoriteRequest,
  UserSettingRequest,
  CoinMarketCap,
  CurrencyCoinRequest,
  MarketPriceChangeRequest,
  BitfinexRequest,
  BithumbRequest,
  AddressManagerRequest,
  ZendeskRequest,
  SiteSettingRequest,
  CoinCheckRequest,
  SettingRequest,
  NewsRequest,
  BalanceRequest,
  MarginRequest,
  MamRequest,
  InsuranceRequest,
  LeaderboardRequest,
  EntryRequest,
  MarketStatistic,
  MarketHot,
};

const instances = {};

export default class RequestFactory {

  static getRequest(classname) {
    let RequestClass = requestMap[classname];
    if (!RequestClass) {
      throw new Error('Invalid request class name: ' + classname);
    }

    let requestInstance = instances[classname];
    if (!requestInstance) {
        requestInstance = new RequestClass();
        instances[classname] = requestInstance;
    }

    return requestInstance;
  }

}
