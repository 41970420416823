<template>
  <div class="">
    <div class="list-title">
      <div v-for="(item, index) in dataList" :key="index" class="item-title"
        :class="idActive === item?.id ? 'active' : ''" @click="setActiveSubCategories(item?.id)">
        {{ $t(item?.title_en) }}
      </div>
    </div>

    <div class="list-content">
       <div v-for="(item, index) in dataItem" :key="index" class="item-content">
        <div class="item-content_label" :class="idActiveSub === item?.id? 'active' : ''"
          @click="setActiveItem(item)">
          {{ item?.title_en }} {{ item?.id }}
        </div>
        <div class="item-content_text" :class="idActiveSub === item?.id? 'active' : ''">
          <div v-html="item?.content_en"></div>
        </div>
      </div>
    </div>

    <!-- <div class="item-content_label ffffff" :class="isActiveSub ? 'active' : ''" @click="setActiveItem(id)">
      {{ title }}
    </div>

    <div v-for="(item, index) in dataItem" :key="index" class="item-content">
      <div class="item-content_label" :class="isActiveSub ? 'active' : ''">
        {{ item?.title_en }}
      </div>
      <div class="item-content_text" :class="isActiveSub ? 'active' : ''">
        <div v-html="item?.content_en"></div>
      </div>
    </div> -->


  </div>
</template>
<script>
import TabFaq from "@/components/shared_components/common/TabFaq.vue";
import rf from '@/request/RequestFactory'

export default {
  components: {
    TabFaq
  },
  props: {
    dataList: {
      type: Array,
      default: [],
    },

  },
  data() {
    return {
      idActiveSub: null,
      idActive: null,
      content: '',
      dataItem: [],
    }
  },

  created() {
    // this.listTitle();
    if (this.dataList?.length > 0) {
      this.setActiveSubCategories(this.dataList?.[0]?.id)
    }else {
        this.dataItem = []
      }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.scrollToTop);
  },
  methods: {
    setActiveItem(value) {
      console.log('value', value?.id)
      this.idActiveSub = value?.id

    },
    setActiveSubCategories(id) {
      this.idActive = id
      this.getApiDetailFaq({ sub_cat_id: id })
    },
    getApiDetailFaq(params) {
      rf.getRequest('UserRequest').getDetailFaq(params).then(res => {
        this.dataItem = res?.data
      })
    },

  },
  mounted() {
  },
  watch: {
    dataList(newData) {
      if (newData?.length > 0) {
        this.setActiveSubCategories(newData?.[0]?.id)
      } else {
        this.dataItem = []
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";

.m-bottom {
  margin-bottom: 8px;
}

.item-content_label {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;

  &:hover,
  &.active {
    color: #03aac7;
  }

  &.active {
    color: #03aac7;
  }
}

.item-content_text {
  display: none;

  &.active {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .item-content_label {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: #03aac7;
    }
  }
}
</style>
