<template>
  <div class="langPage transitionSlide">
    <div class="collpase" :class="showLeftCon ? '' : 'hideLeftCon'">
      <div class="leftCon">
        <slot name="leftLanding"></slot>
      </div>
      <!-- <div class="rightCon">
        <HBSlide v-on:showSlideStatus="showSlideStatus"></HBSlide>
      </div> -->
    </div>
  </div>
</template>
<script>
  import HBSlide from '@/components/Slide.vue';
  export default {
    data() {
      return {
        showLeftCon: true,
        bannerData: {
          autoDisplayFlag: false, // 是否自动轮播
        }
      }
    },
    components: {
      HBSlide
    },
    methods: {
      showSlideStatus(val) {
        this.showLeftCon = val;
      }
    },
  }
</script>
<style scope lang="scss">
  @import "@/assets/sass/slide/TransitionSlide";
  @import "@/assets/sass/variables";
  .langPage {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    color: $pure_color_white;
    background-color: $pure_color_black;
    /* background-image: linear-gradient(to top, #228a7d, $color-jungle-green); */
    .collpase {
      width: 100%;
      height: 380px;
      display: flex;
      /* padding-top: 50px; */
      box-sizing: border-box;
      overflow: hidden;
      .leftCon {
        width: 100%;
      }
      .rightCon {
        width: 0%;
        .h-banner ul li {
          opacity: 0;
        }
      }
    }
    .hideLeftCon {
      .leftCon {
        width: 0px;
        opacity: 0;
        min-width: 0px;
      }
      .rightCon {
        width: 100%;
        min-width: 1200px;
        .h-banner ul li {
          opacity: 1;
        }
        .swiper-slide {
          width: 100% !important;
          height: 100%;
        }
      }
    }

  }
</style>