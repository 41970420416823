import { render, staticRenderFns } from "./Fee.vue?vue&type=template&id=6de2d3e9&scoped=true&"
import script from "./Fee.vue?vue&type=script&lang=js&"
export * from "./Fee.vue?vue&type=script&lang=js&"
import style0 from "./Fee.vue?vue&type=style&index=0&id=6de2d3e9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6de2d3e9",
  null
  
)

export default component.exports