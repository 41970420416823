var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"overview-detail"}},[_c('div',{staticClass:"content-right-wrapper"},[_c('div',{staticClass:"estimate"},[_c('div',{staticClass:"estimate-btc"},[_c('p',[_vm._v(_vm._s(_vm.$t("title_balance")))]),(!_vm.tongglePrice)?_c('span',[_c('img',{attrs:{"src":require(`../assets/icon/eyes${
                _vm.theme === 'light-theme' ? '' : '-dark'
              }.svg`),"width":"24","height":"12"},on:{"click":function($event){return _vm.clickHide()}}})]):_c('span',[_c('img',{attrs:{"src":require(`../assets/icon/hidePriceIcon${
                _vm.theme === 'light-theme' ? '' : '-dark'
              }.svg`),"width":"24","height":"12"},on:{"click":function($event){return _vm.clickHide()}}})])]),(!_vm.tongglePrice)?_c('div',{staticClass:"price-coin"},[_c('p',[_vm._v(_vm._s(_vm._f("formatNumberAndDecimal")(_vm.priceCoin))+" BTC")]),_c('span',[_vm._v("≈ "),_c('fiat-currency',{attrs:{"value":_vm.valued}})],1)]):_c('div',{staticClass:"price-coin-hide"},[_vm._v("***************")])]),_c('div',{staticClass:"recent-transactions"},[_c('div',{staticClass:"transaction-item"},[_c('div',{staticClass:"transaction-img"},[_c('img',{attrs:{"src":require(`@/assets/images/wallet/spot${
                _vm.theme === 'light-theme' ? '' : '-dark'
              }.png`),"alt":"spot-img"}})]),_c('div',{staticClass:"recent-box"},[_c('p',{staticClass:"recent-title"},[_vm._v(_vm._s(_vm.$t("menu.wallet.spot")))]),(!_vm.tongglePrice)?_c('div',[_c('p',{staticClass:"recent-coin"},[_vm._v(" "+_vm._s(_vm._f("formatNumberAndDecimal")(_vm.totalSpotBtcBalance))+" BTC ")]),_c('p',{staticClass:"recent-rounding"},[_vm._v(" ≈ "),_c('fiat-currency',{attrs:{"value":_vm.totalUsdSpotBalance}})],1)]):_c('div',[_c('p',{staticClass:"recent-coin"},[_vm._v("***********")])])])]),_c('div',{staticClass:"transaction-item"},[_c('div',{staticClass:"transaction-img"},[_c('img',{attrs:{"src":require(`@/assets/images/wallet/future${
                _vm.theme === 'light-theme' ? '' : '-dark'
              }.png`),"alt":"future-img"}})]),_c('div',{staticClass:"recent-box"},[_c('p',{staticClass:"recent-title"},[_vm._v(_vm._s(_vm.$t("menu.wallet.futures")))]),(!_vm.tongglePrice)?_c('div',[_c('p',{staticClass:"recent-coin"},[_vm._v(" "+_vm._s(_vm._f("formatNumberAndDecimal")(_vm.totalFuturesBtcBalance))+" BTC ")]),_c('p',{staticClass:"recent-rounding"},[_vm._v(" ≈ "),_c('fiat-currency',{attrs:{"value":_vm.totalUsdFutureBalance}})],1)]):_c('div',[_c('p',{staticClass:"recent-coin"},[_vm._v("***********")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }