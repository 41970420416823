<template>
  <div
    id="trade-history"
    :class="{
      no_padding: options && options.isNoPadding,
      trade_layout_trade_history: openOrderLayout === 'trade',
    }"
  >
    <div
      :class="{ 'wrap-group-control': openOrderLayout === 'trade' }"
      @scroll="updateFixedDivPositionCallendar"
    >
      <div
        id="time-group"
        class="group-control d-flex align-items-baseline"
        v-if="!options"
      >
        <date-tab @change-select-date="changeDate" ref="selectDateTab" /><span
          v-if="openOrderLayout === 'trade'"
          class="time-picker"
          >{{ $t("order.history.time") }}</span
        >
        <div class="group-date d-flex align-items-baseline position-relative">
          <datepicker
            @selected="selectDateFrom"
            :value="dateFromDefault"
            :format="customFormatter"
            class="date-from"
            :language="lang"
          >
          </datepicker>
          <datepicker
            :value="dateToDefault"
            class="date-to"
            @selected="selectDateTo"
            :format="customFormatter"
            :language="lang"
          ></datepicker>
          <div class="position-absolute to-title">
            {{ $t("order.history.time-to") }}
          </div>
        </div>

        <!--      <date-range-picker-->
        <!--          v-if="openOrderLayout === 'trade'"-->
        <!--          class="ml-1"-->
        <!--          ref="picker"-->
        <!--          :opens="opens"-->
        <!--          :locale-data="{ firstDay: 1, format: 'yyyy-MM-DD' }"-->
        <!--          :singleDatePicker="singleDatePicker"-->
        <!--          :timePicker="timePicker"-->
        <!--          :timePicker24Hour="timePicker24Hour"-->
        <!--          :showWeekNumbers="showWeekNumbers"-->
        <!--          :showDropdowns="showDropdowns"-->
        <!--          :autoApply="autoApply"-->
        <!--          v-model="dateRange"-->
        <!--          :ranges="show_ranges ? undefined : false"-->
        <!--          :linkedCalendars="linkedCalendars"-->
        <!--          :always-show-calendars="true"-->
        <!--          :alwaysShowCalendars="alwaysShowCalendars"-->
        <!--          :append-to-body="appendToBody"-->
        <!--          :closeOnEsc="closeOnEsc"-->
        <!--          @update="selectDateRange"-->
        <!--      >-->
        <!--        <template #input="picker">-->
        <!--          <span class="range-from">{{ picker.startDate | dateFormatSupport }}</span>-->
        <!--          <span>{{ $t('order.history.time-to') }}</span>-->
        <!--          <span class="range-to">{{ picker.endDate | dateFormatSupport }}</span>-->
        <!--          <img v-if="userThemeMode === 'light-theme'" class="p-1"  src="@/assets/images/icon/callendar-range-light.svg" alt="icon-callendar-range"/>-->
        <!--          <img v-else class="p-1" src="@/assets/images/icon/callendar-range-dark.svg" alt="icon-callendar-range"/>-->

        <!--        </template>-->
        <!--      </date-range-picker>-->
        <button class="button-search" @click="actionSearch">
          {{ $t("order.order_history.search") }}
        </button>
        <button class="button-reset" @click="actionReset">
          {{ $t("button.reset") }}
        </button>
      </div>
      <div
        id="is_single_page_trade"
        class="group-control filter-trade"
        v-if="options"
        :class="{ is_single_page: options && options.isNoPadding }"
      >
        <div class="group-pair">
          <div class="label-text">{{ $t("order.order_history.pair") }}:</div>
          <div class="content-search">
            <div class="toolbar-element">
              <div class="coin-input" v-click-outside="clickOut">
                <input
                  type="text"
                  :placeholder="$t('order.order_history.coin')"
                  v-model="filters.coin"
                  @click="showCoinOption()"
                  @keyup="onSearchCoin()"
                  @keyup.enter="actionSearch"
                />
                <div class="box-coin-option" v-if="isShowCoinOption">
                  <div class="coin-option">
                    <span v-if="coins.length == 0" class="no_result">
                      {{ $t("convert.noresult") }}.</span
                    >
                    <span
                      class="option"
                      :key="coin"
                      v-for="coin in coins"
                      @click="onSelectCoin(coin)"
                    >
                      <img
                        v-if="coin !== $t('order.order_form.all')"
                        :src="`${coinImages[coin]}`"
                        alt=""
                        width="30"
                        height="30"
                        class="coin-img"
                      />
                      {{ coin }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <span class="bw-element">/</span>
            <div class="toolbar-element2">
              <select-box
                v-model="filters.currency"
                :placeholder="$t('order.order_history.all')"
                :options="getMarkets()"
                :isNoBg="true"
              />
            </div>
          </div>
        </div>

        <div class="group-type">
          <div class="label-text">{{ $t("order.order_history.type") }}:</div>
          <div class="content-search search-type side-layout-order">
            <select-box
              v-model="filters.tradeType"
              :placeholder="$t('order.order_history.all')"
              :options="sideOptions"
              :isNoBg="true"
            />
          </div>
        </div>
        <!-- 
          <div class="group-date-picker">
            <div class="label-text">
              {{ $t("order.order_history.date_from") }}:
            </div>
            <div class="group-date from-date">
              <datepicker
                :value="dateFromDefault"
                class="date"
                @selected="selectDateFrom"
                :format="customFormatter"
                input-class="date-picker-input"
                :language="lang"
              >
              </datepicker>
            </div>
          </div>
  
          <div class="group-date-picker">
            <div class="label-text">{{ $t("order.order_history.date_to") }}:</div>
            <div class="group-date to-date">
              <datepicker
                :value="dateToDefault"
                class="date"
                @selected="selectDateTo"
                :format="customFormatter"
                input-class="date-picker-input"
                :language="lang"
              ></datepicker>
            </div>
          </div> -->

        <div class="group-date-picker">
          <date-range-picker
            v-model="dateRange"
            :locale-data="{ firstDay: 1, format: 'mm-dd-yyyy' }"
            @change="handleDateRangeChange"
            input-class="date-picker-input"
          ></date-range-picker>
        </div>

        <div class="group-type">
          <div class="content-search search-type side-layout-order">
            <div class="button-side-layout-order">
              <button class="button-search" @click="actionSearch">
                {{ $t("common.search") }}
              </button>
              <button class="button-reset" @click="actionReset">
                {{ $t("button.reset") }}
              </button>
            </div>
          </div>
        </div>

        <div class="export-file">
          <a
            class="excel_export f-fr mart3"
            @click="downloadReport()"
            @mouseover="mouseOver"
            @mouseout="mouseOver"
          >
            <!-- <img
                src="@/assets/images/icon/icon-export-history.svg"
                alt=""
                class="icon-export"
              /> -->
            {{ $t("order.order_history.export_complete_trade_history") }}
          </a>
          <div v-show="isShowToolTip" class="export-file-tooltip">
            {{
              $t("order.order_history.export_complete_trade_history_tooltip")
            }}
          </div>
        </div>
      </div>
    </div>

    <data-table3
      ref="datatable"
      :tableHistory="tableHistory"
      :typeEmpty="openOrderLayout !== 'trade' && !typeEmpty"
      :getData="getData"
      :msg-empty-data="$t('order.trade_history.no_data')"
      class="datatable history-table"
      id="order-table"
      :limit="6"
      :hasLayoutMobile="true"
    >
      <template slot-scope="props">
        <th
          v-for="item in headerTable"
          :key="item.id"
          :title="$t(item.name)"
          :data-sort-field="item.id"
          @click="props.echoclick"
          :class="$t(item.name)"
        >
          <span>{{ $t(item.name) }}</span>
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td class="cl_date" :title="props.item.created_at | timestampToDate">
            {{ props.item.created_at | timestampToDate }}
          </td>
          <td
            class="cl_pair"
            :title="`${props.item.coin} / ${props.item.currency}` | uppercase"
          >
            <img
              v-if="openOrderLayout !== 'trade'"
              :src="`${coinImages[props?.item?.coin]}`"
              alt=""
              width="30"
              height="30"
              class="coin-img"
            />
            <img
              v-if="openOrderLayout !== 'trade'"
              :src="`${currencyCoinsImages[props?.item?.currency]}`"
              alt=""
              width="30"
              height="30"
              class="currency-img"
            />
            {{ props?.item?.coin | uppercase }}/{{
              props?.item?.currency | uppercase
            }}
          </td>
          <td
            :class="{
              blue: props.item.trade_type === 'buy',
              red: props.item.trade_type === 'sell',
            }"
            :title="props.item.trade_type | tradeType"
          >
            {{ props.item.trade_type | tradeType }}
          </td>
          <td
            :title="
              props.item.price | formatCurrencyAmount(props.item.currency, '0')
            "
          >
            <span
              v-if="
                props.item.type == 'market' || props.item.type == 'stop_market'
              "
              >{{ $t("order.trade_history.market") }}</span
            >
            <span v-else>{{ props.item.price | formatNumberAndDecimal }}</span>
          </td>
          <td>
            <span v-if="props.item.trade_type === 'sell'">
              {{
                props.item.sell_order > props.item.buy_order ? "Taker" : "Maker"
              }}
            </span>
            <span v-else>
              {{
                props.item.sell_order < props.item.buy_order ? "Taker" : "Maker"
              }}
            </span>
          </td>
          <td :title="props.item.quantity | toNumber">
            {{ props.item.quantity | toNumber | formatNumberAndDecimal }}
          </td>

          <td
            width="190px"
            class="t-left"
            v-if="props.item.trade_type === 'buy' && !props.item.fee_amal"
            :title="props.item.fee | formatNumberAndDecimal"
          >
            {{ props.item.fee | formatNumberAndDecimal }}
            {{ props.item.coin | uppercase }}
          </td>
          <td
            width="190px"
            class="t-left"
            v-if="props.item.trade_type === 'buy' && props.item.fee_amal"
            :title="props.item.fee_amal | formatNumberAndDecimal"
          >
            {{ props.item.fee_amal | formatNumberAndDecimal }}
            {{ coinAMAL | uppercase }}
          </td>
          <td
            width="190px"
            class="t-left"
            v-if="props.item.trade_type !== 'buy' && !props.item.fee_amal"
            :title="props.item.fee | formatNumberAndDecimal"
          >
            {{ props.item.fee | formatNumberAndDecimal }}
            {{ props.item.currency | uppercase }}
          </td>
          <td
            width="190px"
            class="t-left"
            v-if="props.item.trade_type !== 'buy' && props.item.fee_amal"
            :title="props.item.fee | formatNumberAndDecimal"
          >
            {{ props.item.fee_amal | formatNumberAndDecimal }}
            {{ coinAMAL | uppercase }}
          </td>
          <td
            class="t-left"
            :title="props.item.amount | formatNumberAndDecimal"
          >
            {{ props.item.amount | formatNumberAndDecimal }}
          </td>
        </tr>
      </template>
      <template slot="header-action-mobile">
      <div class="item-row">  <div class="item-row-flex">
            <div class="checkbox-function">
              <label
                for="checkbox_id"
                :class="{ check: isHideOtherPair === true }"
              >
                <input
                  type="checkbox"
                  name="checkbox"
                  v-model="isHideOtherPair"
                  value="check"
                  id="checkbox_id"
                  @change="hideOtherPair()"
                />
                <span class="button-check"></span>
                {{
                  isCurrentTab === commonConst.FUNDS
                    ? $t("order.open_order.hide_small_assets")
                    : $t("order.open_order.hide_other_pairs")
                }}
              </label>
            </div>
            <div class="btn-cancel"></div>
          </div>
          <div class="item-row-flex">
            <date-tab
              class="date-tab"
              @change-select-date="changeDate"
              ref="selectDateTab"
            />
            <img
           @click="actionReset"
              :src="
                require(`/src/assets/images/iconFilter${
                  theme === 'light-theme' ? '' : '-dark'
                }.svg`)
              "
              alt=""
            />
          </div></div>
      </template>
      <template slot="body-mobile" slot-scope="props">
        <div class="item-row">
        
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above">
                {{ props.item.created_at | timestampToDate }}</span
              >
              <span class="text-below"> {{$t("order.open_order.date")}} </span>
            </div>
            <div class="item-right">
              <span class="text-above">
                {{ props?.item?.coin | uppercase }}/{{
              props?.item?.currency | uppercase
            }}
              </span>
              <span class="text-below"> {{$t("order.order_history.pair")}} </span>
            </div>
          </div>
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above" :class="{
              blue: props.item.trade_type === 'buy',
              red: props.item.trade_type === 'sell',
            }">
            {{ props.item.trade_type | tradeType }}
              </span>
              <span class="text-below"> {{$t("order.order_history.type")}} </span>
            </div>
            <div class="item-right">
              <span class="text-above"> <span
              v-if="
                props.item.type == 'market' || props.item.type == 'stop_market'
              "
              >{{ $t("order.trade_history.market") }}</span
            >
            <span v-else>{{ props.item.price | formatNumberAndDecimal }}</span></span>
              <span class="text-below"> {{$t("order.order_history.price")}} </span>
            </div>
          </div>
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above"><span v-if="props.item.trade_type === 'sell'">
              {{
                props.item.sell_order > props.item.buy_order ? "Taker" : "Maker"
              }}
            </span>
            <span v-else>
              {{
                props.item.sell_order < props.item.buy_order ? "Taker" : "Maker"
              }}
            </span></span>
              <span class="text-below"> Role </span>
            </div>
            <div class="item-right">
              <span class="text-above">  {{ props.item.quantity | toNumber | formatNumberAndDecimal }}</span>
              <span class="text-below"> {{$t("order.order_history.filled")}} </span>
            </div>
          </div>
          <div class="item-row-flex">
            <div class="item-left">
              <span class="text-above">   <span
            width="190px"
            class="t-left"
            v-if="props.item.trade_type === 'buy' && !props.item.fee_amal"
          >
            {{ props.item.fee | formatNumberAndDecimal }}
            {{ props.item.coin | uppercase }}
          </span>
          <span
            width="190px"
            class="t-left"
            v-if="props.item.trade_type === 'buy' && props.item.fee_amal"
          >
            {{ props.item.fee_amal | formatNumberAndDecimal }}
            {{ coinAMAL | uppercase }}
          </span>
          <span
            width="190px"
            class="t-left"
            v-if="props.item.trade_type !== 'buy' && !props.item.fee_amal"
          >
            {{ props.item.fee | formatNumberAndDecimal }}
            {{ props.item.currency | uppercase }}
          </span>
          <span
            width="190px"
            class="t-left"
            v-if="props.item.trade_type !== 'buy' && props.item.fee_amal"
          >
            {{ props.item.fee_amal | formatNumberAndDecimal }}
            {{ coinAMAL | uppercase }}
          </span></span>
              <span class="text-below"> {{$t("order.order_history.fee")}} </span>
            </div>
            <div class="item-right">
              <span class="text-above">
                {{ props.item.amount | formatNumberAndDecimal }}
              </span>
              <span class="text-below"> {{$t("order.order_history.total")}} </span>
            </div>
          </div>
          <div class="line" />
        </div>
      </template>
    </data-table3>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateTab from "@/pages/common/DateTab.vue";
import OPEN_ORDER_HEADER_CONST from "./DataTableInit.js";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import moment from "moment";
import MasterdataUtils from "@/common/MasterdataUtils";
import Utils from "@/common/Utils";
import SelectBox from "@/components/shared_components/common/SelectBox";
import { mapState } from "vuex";
import Cookies from "js-cookie";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";

export default {
  components: {
    DateTab,
    Datepicker,
    SelectBox,
    DateRangePicker,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    coinCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currencyCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },

    tableHistory: {
      type: Boolean,
      default: false,
    },
    openOrderLayout: {
      type: "trade" | "order",
      default: "order",
    },
    typeEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opens: "right",
      dateRange: {
        startDate: moment().subtract(6, "days").toDate(),
        endDate: moment().toDate(),
      },
      single_range_picker: false,
      show_ranges: false,
      singleDatePicker: false,
      timePicker: false,
      timePicker24Hour: false,
      showDropdowns: false,
      autoApply: true,
      showWeekNumbers: false,
      linkedCalendars: false,
      alwaysShowCalendars: true,
      appendToBody: false,
      closeOnEsc: false,
      headerTable: OPEN_ORDER_HEADER_CONST.TRADE_HISTORY_HEADER,
      sideOptions: [],
      commonConst: COMMON_CONST,
      dateFromDefault: moment().subtract(6, "days").toDate(),
      dateToDefault: moment().toDate(),
      dateFrom: moment().subtract(6, "days").startOf("day").format("x"),
      dateTo: moment().endOf("day").format("x"),
      disableFromDate: {
        from: new Date(),
      },
      disableToDate: {
        from: new Date(),
      },
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      filters: {
        coin: "",
        currency: "",
        tradeType: "",
      },
      durationDateFrom: null,
      isHide: false,
      isShowToolTip: false,
      coin: this.coinCode,
      currency: this.currencyCode,
      fetchingTradeHistory: false,
      markets: [],
      coinAMAL: COMMON_CONST.AML,
      coinNames: {},
      coinImages: {},
      currencyCoinNames: {},
      currencyCoinsImages: {},
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
      lang:
        Cookies.get("locale", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "en",
      ethicon: ETHIcon,
      isHideOtherPair:false
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
      userThemeMode: (state) => state.userThemeMode,
      user: (state) => state.user,
    }),
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
  methods: {
    hideOtherPair() {
      this.$emit("action-navi-tab", {
        action: this.commonConst.ACTION_HIDE_OTHER_PAIR,
        isCheck: this.isHideOtherPair,
      });
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        SpotOrderListUpdated: this.onOrderListUpdated,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
    },
    onOrderListUpdated(data, isHideOtherPair) {
      console.log(data);
      console.log(isHideOtherPair);
      if (!this.fetchingTradeHistory) {
        this.fetchingTradeHistory = true;
        if (isHideOtherPair) {
          this.$refs.datatable.filter({
            coin: this.coin,
            currency: this.currency,
          });
        } else {
          this.$refs.datatable.refresh();
        }
      }
    },
    changeDate(currentDateTab) {
      switch (currentDateTab.id) {
        case this.commonConst.ONE_DAY:
          this.durationDateFrom = moment().startOf("day").format("x");
          break;
        case this.commonConst.ONE_WEEK:
          this.durationDateFrom = moment()
            .subtract(6, "days")
            .startOf("day")
            .format("x");
          break;
        case this.commonConst.ONE_MONTH:
          this.durationDateFrom = moment()
            .subtract(1, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
          break;
        default:
          this.durationDateFrom = moment()
            .subtract(3, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
      }
      const params = {
        start_date: this.durationDateFrom,
        end_date: moment().endOf("day").format("x"),
      };
      this.$refs.datatable.filter(params);
    },
    getData(params) {
      if (!isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      // const el = document.getElementById('is_single_page_trade');
      // if (el) {
      //   el.scrollIntoView({behavior: "smooth"});
      // }
      if (params.hide) {
        this.isHide = params.hide === "hide";
      }
      const dateRange = this.getDefaultDateRange();
      params = Object.assign({}, dateRange, params);
      if (this.isHide) {
        params.coin = this.coinCode;
        params.currency = this.currencyCode;
      }
      return rf
        .getRequest("OrderRequest")
        .getTradeHistory(params)
        .then((res) => {
          this.fetchingTradeHistory = false;
          return res;
        });
    },

    getDefaultDateRange() {
      return {
        start_date: moment().subtract(6, "days").startOf("day").format("x"),
        end_date: moment().endOf("day").format("x"),
      };
    },
    showError(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },
    actionSearch() {
      if (this.dateFrom > this.dateTo) {
        this.showError(this.$t("common.date.warning"));
        return;
      }
      this.$refs.datatable.filter(this.filterParams());
      if (!this.options) {
        this.$refs.selectDateTab.resetSelectDate();
      }
    },
    filterParams() {
      const filterParams = {
        start_date: this.dateFrom,
        end_date: this.dateTo,
      };
      if (this.filters.coin && this.filters.coin !== "All") {
        filterParams.coin = this.filters.coin;
      }
      if (this.filters.currency && this.filters.currency !== "All") {
        filterParams.currency = this.filters.currency;
      }
      if (this.filters.tradeType && this.filters.tradeType !== "all") {
        filterParams.trade_type = this.filters.tradeType;
      }
      return filterParams;
    },
    actionReset() {
      this.filters.coin = "";
      this.filters.currency = "";
      this.filters.tradeType = "";
      this.dateFromDefault = moment().subtract(6, "days").toDate();
      (this.dateRange = {
        startDate: moment().subtract(6, "days").toDate(),
        endDate: moment().toDate(),
      }),
        (this.dateToDefault = moment().toDate());
      this.dateFrom = moment().subtract(6, "days").startOf("day").format("x");
      this.dateTo = moment().endOf("day").format("x");
      // this.$refs.datatable.refresh();
      $(".datatable").find("th").attr("data-sort-order", "");
      this.actionSearch();
    },
    async selectDateFrom(dateFrom) {
      this.dateFrom = moment(dateFrom).valueOf();
      this.dateFrom = moment(dateFrom).startOf("day").format("x");
    },
    async selectDateTo(dateTo) {
      this.dateTo = moment(dateTo).endOf("day").format("x");
      if (dateTo) {
        this.disableToDate = {
          from: new Date(dateTo),
        };
      }
    },
    handleDateRangeChange(range) {
      this.selectDateFrom(range.start);
      this.selectDateTo(range.end);
    },
    async selectDateRange(date) {
      this.dateFrom = moment(date.startDate).valueOf();
      this.dateFrom = moment(date.startDate).startOf("day").format("x");
      this.dateTo = moment(date.endDate).endOf("day").format("x");
      if (date) {
        this.disableToDate = {
          from: new Date(date.endDate),
        };
      }
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    requestCoinList() {
      MasterdataUtils.getCoins((coins) => {
        this.coinMasters = coins;
        this.coinMasters.unshift(this.$t("order.order_form.all"));
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin.toUpperCase();
      if (coin === "All") {
        this.filters.coin = coin;
      }
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    downloadReport() {
      const params = {
        start_date: this.dateFrom,
        end_date: this.dateTo,
        timezone_offset: Utils.getTimzoneOffset(),
        coin: this.filters.coin.length == 0 ? undefined : this.filters.coin,
        currency:
          this.filters.currency.length == 0 ? undefined : this.filters.currency,
        trade_type:
          this.filters.tradeType.length == 0
            ? undefined
            : this.filters.tradeType,
      };
      rf.getRequest("OrderRequest").downloadTradeHistory(
        params,
        "TradeHistory.csv"
      );
    },
    mouseOver() {
      this.isShowToolTip = !this.isShowToolTip;
    },

    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, (item) => {
        return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    getMarkets() {
      return window._.map(COMMON_CONST.MARKETS_2, (item) => {
        return {
          id: item.name,
          name: this.$t(item.name),
          icon: item.icon,
        };
      });
    },
    initSideOptions() {
      this.sideOptions = this.commonConst.SELECT_SIDE.map((option) => {
        return {
          id: option.id,
          name: this.$t(option.name),
        };
      });
    },

    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(
        this.masterData.coins_confirmation,
        (item) => item.coin
      );
      this.coinsMasterData = window._.keyBy(
        this.masterData.coins,
        (item) => item.coin
      );
      this.coinsMasterData.usd = {
        name: window.i18n.t(`currency.usd.fullname`),
      };

      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
        this.currencyCoinNames[item.coin] = item.name;
        this.currencyCoinsImages[item.coin] = item.icon_image;
      });

      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.currencyCoinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.currencyCoinsImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon;
      this.currencyCoinsImages["eth"] = this.ethicon;
    },
    updateFixedDivPositionCallendar() {
      if (this.openOrderLayout !== "trade") {
        return;
      }
      const referenceDiv = document.querySelector(".date-from");
      const referenceDiv2 = document.querySelector(".date-to");
      const fixedDiv = document.querySelector(
        ".date-from .vdp-datepicker__calendar"
      );
      const fixedDiv2 = document.querySelector(
        ".date-to .vdp-datepicker__calendar"
      );
      // Get the position and size of the referenceDiv
      const rect = referenceDiv.getBoundingClientRect();
      const rect2 = referenceDiv2.getBoundingClientRect();

      // Set the position of the fixedDiv based on the referenceDiv
      fixedDiv.style.top = `${rect.top + 40}px`;
      fixedDiv.style.left = `${rect.left}px`;
      fixedDiv2.style.top = `${rect2.top + 40}px`;
      fixedDiv2.style.left = `${rect2.left}px`;
    },
  },
  mounted() {
    this.requestCoinList();
    this.getMarkets();
    this.initSideOptions();
    this.$on("UPDATED_LOCALE", () => {
      this.initSideOptions();
    });
    this.handleMasterData();
    this.updateFixedDivPositionCallendar();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  .vdp-datepicker__calendar {
    border: 1px solid $color-gray-charade !important;
    background: $color-gray-charade;
  }

  .trade_layout_trade_history {
    background: transparent !important;

    .group-control {
      background: transparent !important;
    }

    .tableContainer3 {
      background: transparent !important;

      table {
        background: transparent !important;
      }
    }
  }

  #trade-history {
    background: $color-night;
    ::-webkit-scrollbar-thumb {
      background-color: $color-storm-grey;
    }
    .group-control {
      .vue-daterange-picker {
        ::v-deep {
          .reportrange-text {
            background: $color-grey-black !important;
            color: $color-white;
            border: 1px solid #2d2e2f !important;
            .range-from,
            .range-to {
              color: $color-white !important;
            }
            span {
              color: $color-white !important;
            }
          }
        }
      }
      .time-picker {
        font-size: 12px;
        line-height: 18px;
        color: #8e8e92;
        margin-right: 10px;
        margin-left: 10px;
      }
      &.is_single_page {
        .export-file {
          background-color: $color-white;
          a {
            color: $color-grey-black;
          }
        }
        .group-pair {
          input {
            background-color: $color-grey-black !important;
            color: $color-white;
            border: 1px solid #2d2e2f !important;

            &::placeholder {
              color: $color-white;
            }
          }
        }
      }
    }
    .tableContainer3 {
      //min-height: 500px;
      table {
        margin-bottom: 0 !important;
        thead {
          th {
            color: $color-storm-grey;
          }
        }

        tbody {
          tr {
            td {
              color: var(--color-text-content);
            }
            .red {
              color: $color-denim !important;
            }
            .blue {
              color: $text-color-jade !important;
            }
          }
        }
      }
    }
  }
}

.trade_layout_trade_history {
  //min-width: 1201px;
  min-height: 450px;
  table {
    background: transparent;
    thead {
      tr {
        th:first-child {
          text-align: left !important;
          padding-left: 40px !important;
        }
        th:last-child {
          text-align: right;
          padding-right: 40px !important;
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            text-align: left !important;
            padding-left: 40px !important;
          }
          &:last-child {
            text-align: right !important;
            padding-right: 40px !important;
          }
        }
      }
    }
  }
}

#trade-history {
  ::-webkit-scrollbar-thumb {
    background-color: $color-gray-alto;
  }
  :deep(.empty-data) {
  }
  .tableContainer3 {
    background: $color-gray-alabaster;

    table {
      width: 100%;
      overflow-y: initial;

      thead {
        tr {
          th:first-child {
            text-align: left !important;
          }
        }
      }

      tbody {
        tr {
          td {
            font-family: $font-inter-regular !important;
            color: $color-grey-dark;
            //font-size: $font-medium-bigger!important;
            text-align: center;

            &:first-child {
              text-align: left !important;
              padding-left: 24px;
            }
          }
        }
      }
      background: $color-gray-alabaster;
    }
  }

  table {
    margin-left: 0 !important;
  }

  .group-control {
    //min-width: 1280px;
    //max-width: 85%;
    font-size: $font-small;
    @media screen and (max-width: 1550px) {
      width: 100% !important;
      //max-width: 1318px;
    }
    @media screen and (max-width: 1300px) {
      width: 100% !important;
    }
    .time-picker {
      font-size: 12px;
      line-height: 18px;
      color: #8e8e92;
      margin-right: 10px;
      margin-left: 10px;
    }
    &:after {
      display: block;
      clear: both;
      content: "";
    }
    #date-tab {
      float: left;
    }
    .group-date {
      display: inline-block;
      margin-left: 36px;
      position: relative;
      float: left;

      .to-title {
        left: 44%;
        top: 50%;
        transform: translate(-50%, -50%);
        //color: $color-grey-dark;
      }
      .date-title {
        margin-right: 5px;
      }
      .vdp-datepicker {
        display: inline-block;
      }
      .date-to {
        margin-left: -5px;
      }
    }
    .vue-daterange-picker {
      ::v-deep {
        .reportrange-text {
          /* background: $color-alabaster; */
          border-radius: 20px;

          height: 50px;
          border: 1px solid #e6e6e6;
          box-shadow: none;
          display: flex;
          align-items: center;
          font-family: $font-inter-regular;
          font-size: $font-medium-bigger;

          .range-from {
            color: $color-gray-dark;
            margin-right: 4px;
            width: 95px;
          }
          .range-to {
            color: $color-gray-dark;
            margin-left: 4px;
            width: 95px;
          }

          .icon-calendar {
            margin-left: 8px;
          }

          span {
            color: $color-grey-dark;
          }
        }

        .daterangepicker {
          td.active {
            background-color: $color-aqua-green;
            border-color: transparent;
            color: white;
            border-radius: 50%;
          }
          td.off {
            background-color: #fff !important;
            border-color: transparent !important;
            color: #999 !important;
          }
        }
      }
    }
    .button-reset {
      width: 63px;
      height: 35px;
      margin-left: 0px;
      margin-right: 0px;
      color: #17181a;
      box-shadow: none;
      font-family: $font-inter-regular;
      font-size: 14px;
      float: left;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      letter-spacing: 0.9px;
      border-radius: 8px;
      background: transparent;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      &:hover {
        background: $color-gray-dark;
        border-color: $color-gray-dark;
        color: $color-white;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }
    .button-search {
      margin-left: 25px;
      margin-right: 10px;
      background-color: $color-grey-black;
      color: #fff;
      box-shadow: none;
      font-size: 18px;
      border: 1px solid $color-grey-black;
      border-radius: 63px !important;
      width: 109px !important;
      height: 49px;
      float: left;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      letter-spacing: 0.9px;
      background-color: #2d2e2f;
      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color-grey-dark;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }
    &.is_single_page {
      display: block;
      width: 100%;
      padding: 62px 0px 30px 40px !important;
      position: relative;
      @media screen and (max-width: 1550px) {
        padding: 62px 16px 30px 40px !important;
      }
      @media screen and (max-width: 1200px) {
        padding: 0 !important;
      }
      &:after {
        display: block;
        clear: both;
        content: "";
      }
      .label-text {
        color: var(--text-primary-color);
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 8px;
      }

      .group-date-picker {
        display: inline-block;
        float: left;
        /* margin-right: 20px;         */
        margin-top: 30px;

        .group-date {
          display: inline-block;
          margin-left: 0;
          position: relative;
          .vdp-datepicker {
            display: inline-block;
            float: left;
          }
          .date-to {
            margin-left: 0;
          }
        }
      }
      .group-pair {
        display: inline-block;
        margin-right: 20px;
        float: left;
        input {
          /* background-color: var(--color-tab-data-table); */
          height: 30px;
          float: left;
          border: 0px;
          padding: 0px 5px 0px 14px;
          margin-right: 1px;
          font-size: 18px;
          color: var(--text-primary-color);
          border-radius: 20px !important;
          border: 1px solid #e6e6e6 !important;
        }
        .sc_search_select {
          /* background-color: $color-catskill-white; */
          height: 30px;
          width: 95px;
        }
        .bw-element {
          float: left;
          font-size: 15px;
          margin-left: 5px;
          height: 30px;
          line-height: 30px;
          margin-right: 5px;
          margin-top: 10px;
          color: var(--text-close);
        }
      }
      .group-type {
        display: inline-block;
        margin-right: 20px;
        float: left;
        .sc_search_select {
          height: 30px;
          width: 95px;
          display: inline-block;
          float: left;
        }

        button {
          line-height: 20px;
          height: 49px;
          padding: 6px 16px;
          width: auto;
          min-width: 85px;
        }
      }
      .export-file {
        display: inline-block;
        float: right;
        margin-top: 50px;
        line-height: 20px;
        padding: 10px 16px;
        border-radius: 63px;
        font-size: $font-medium-bigger;
        position: relative;
        background-color: $color-grey-black;
        font-family: $font-inter-regular;
        a {
          color: $color-white;
          font-size: $font-medium-bigger;
          .icon-export {
            margin-right: 8px;
            margin-top: -3px;
          }
        }
        .export-file-tooltip {
          display: none;
          position: absolute;
          top: 150%;
          margin-bottom: 5px;
          right: 0;
          width: 220px;
          line-height: 20px;
          background-color: $bgr-tooltip;
          padding: 10px;
          border-radius: 8px;
          color: $color-white;
          font-size: 14px;
          line-height: 18px;
          z-index: 10;
        }
        &:hover {
          .export-file-tooltip {
            display: block;
          }
        }
      }
      .button-reset {
        margin-left: 0px;
        margin-right: 0px;
        background-color: $color-white;
        color: $color-grey-dark;
        box-shadow: none;
        font-family: $font-inter-regular;
        border: 1px solid #e6e6e6;
        width: 109px !important;
        height: 49px;
        font-size: $font-smaller;
        float: left;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        letter-spacing: 0.9px;
        border-radius: 63px !important;
        font-size: 18px !important;
        &:hover {
          background: $color-gray-dark;
          border-color: $color-gray-dark;
          color: $color-white;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
      }
      .toolbar-element {
        /* background-color: var(--color-tab-data-table); */
        margin-right: 0px;
        display: inline-block;
        float: left;
        border-radius: 8px;
        width: 95px;
        font-family: $font-inter-regular;
        input {
          /* border: 1px solid $color-alto; */
          border-radius: 8px;
          line-height: 20px;
          padding-top: 5px;
          padding-bottom: 5px;
          height: 49px;
          width: 100%;
          &:focus {
            border: 1px solid $color-jungle-green;
          }

          &::placeholder {
            color: $color-grey-black;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
          }
        }
      }
      .toolbar-element2 {
        /* background-color: var(--color-tab-data-table); */
        margin-right: 0px;
        display: inline-block;
        float: left;
        border-radius: 8px;
        width: 95px;
      }
      .coin-input {
        position: relative;
        float: left;
        .box-coin-option {
          left: 0;
          position: absolute;
          top: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-negative: 1;
          flex-shrink: 1;
          margin-top: 6px;
          z-index: 999;
          background: var(--bgr-dropdown);
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
          border-radius: 8px;
          .coin-option {
            position: relative;
            padding: 15px 0px;
            overflow-y: auto;
            max-height: 300px;
            .option {
              display: flex;
              gap: 8px;
              position: relative;
              white-space: nowrap;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              width: 100%;
              padding: 6px 3.15px 6px 20px;
              margin: 0;
              line-height: 21px;
              min-height: 25px;
              font-size: 18px;
              color: var(--color-dropdown);
              font-weight: normal;
              min-width: 100%;
              cursor: pointer;
              text-transform: uppercase;
              img {
                object-fit: contain;
                border-radius: 50%;
              }
              &:hover {
                color: $color-caribbean-green;
              }
              &:first-child {
                font-weight: 400;
                font-size: 18px;
                line-height: 17px;
                text-transform: capitalize;
                color: var(--text-dropdown) !important;
                &:hover {
                  color: $color-caribbean-green !important;
                }
              }
              img {
                margin-right: 5px;
              }
            }
            .no_result {
              font-size: 14px;
              line-height: 17px;
              text-transform: capitalize;
              color: var(--text-dropdown);
              display: inline-block;
              white-space: nowrap;
              width: 100%;
              padding: 0 10px;
            }
          }
        }
      }
      .excel_export {
        cursor: pointer;
        font-weight: 500;
      }
    }
    @media screen and (max-width: 1300px) {
      width: 90% !important;
    }
    @media screen and (max-width: 992px) {
      width: 100% !important;
    }
  }

  #order-table {
    overflow-x: auto;
    table {
      border: 0px;
      margin-bottom: 22px;
      thead {
        border: 0px;

        tr {
          th {
            font-family: $font-family-roboto-medium;
            font-size: $font-small;
            //padding: 8px 0px 8px 20px;
            &.Date {
              width: 15%;
              min-width: 220px;
            }
            &.Pair {
              width: 12%;
              min-width: 120px;
            }
            &.Type {
              width: 12%;
              min-width: 120px;
            }

            &.Price {
              width: 14%;
              min-width: 140px;
            }
            &.Role {
              width: 14%;
              min-width: 140px;
            }
            &.Filled {
              width: 14%;
              min-width: 140px;
            }
            &.Fee {
              width: 14%;
              min-width: 140px;
            }
            &.Total {
              width: 14%;
              text-align: right;
              min-width: 140px;
            }
            &:last-child {
              // padding: 8px 20px 8px 0px;
              &:after {
                margin-left: 0px;
                margin-right: 5px;
              }
            }
          }
        }
      }
      tbody {
        text-align: left;
        tr {
          &.canceled {
            opacity: 0.3;
          }
          height: auto;
          font-size: $font-small;
          td {
            font-size: 14px;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            //padding: 7px 0px 6px 20px;
            overflow: initial;
            vertical-align: middle;
            &.blue {
              color: $text-color-jade;
            }
            &.red {
              color: $text-color-red;
            }
            &:last-child {
              text-align: right;
              // padding: 7px 20px 6px 0px;
            }
          }
        }
      }
    }
  }
  #order-table .no-data {
    overflow: auto;
  }
  .side-layout-order {
    .button-side-layout-order {
      margin-top: 30px;
    }
  }
}

#date-tab {
  float: left;
}

::v-deep {
  #order-table table thead tr {
    height: auto;
  }
}

.trade_layout_trade_history {
  .wrap-group-control {
    overflow-x: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .group-control {
      min-width: 1500px;
    }
    ::v-deep {
      .vdp-datepicker__calendar {
        position: fixed;
      }
    }
  }
}

::v-deep {
  .tableContainer3 {
    .wrap-table {
      overflow-x: auto;
      //padding-bottom: 25px;

      //table {
      //  min-width: 1500px;
      //}
    }
  }
}

@media screen and (max-width: 992px) {
  .trade_layout_trade_history table tbody tr td:first-child {
    padding-left: 20px !important;
  }
  #trade-history-screen .group-control.is_single_page .export-file {
    margin-top: 24px;
    margin-bottom: 32px;
    width: 100%;
    a {
      font-size: $font-root;
    }
    .export-file-tooltip {
      width: 100%;
    }
  }
  #trade-history .group-control.is_single_page .group-type button {
    height: 36px;
  }

  .side-layout-order {
    .sc_search_select {
      width: 100% !important;
      height: auto !important;
    }
    .button-side-layout-order {
      width: 100%;
      margin-top: 24px;
      .button-search {
        margin-left: 0 !important;
        margin-right: 8px !important;
        font-size: 14px !important;
        padding: 9px 20px !important;
      }
      .button-reset {
        font-size: 14px !important;
        padding: 9px 20px !important;
      }
    }
  }
  #trade-history .group-control.is_single_page .group-pair .sc_search_select {
    width: 100%;
  }
  .item-row {
    width: 100vw !important;
    padding: 16px 16px;
    .line {
      background-color: #8e8e92;
      height: 1px;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .item-row-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .item-left {
        display: flex;
        flex-direction: column;
      }
      .item-right {
        display: flex;
        flex-direction: column;
        text-align: right;
      }
      .text-above {
        font-size: 12px;
        line-height: 18px;
        color: var(--text-check-label);
        font-weight: 400;
        &.blue{
        color:#01BC8D
      }

      &.red{
color:#d92d20;
      } 
      }
      .text-below {
        font-size: 12px;
        line-height: 18px;
        color: #8e8e92;
        font-weight: 400;
      }
      .checkbox-function {
        color: var(--text-check-label);
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
      }
      .btn-cancel {
        color: #03aac7;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

.dark-theme {
  #trade-history .vdp-datepicker input {
    background-image: url("@/assets/images/icon/date-time-home-dark.svg") !important;
  }

  #trade-history {
    .is_single_page {
      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            border: 1px solid #2d2e2f !important;
          }
        }
      }
    }
  }
  .button-search {
    background-color: white !important;
    color: #17181a !important;
  }
  .button-reset {
    background-color: #2d2e2f !important;
    color: white !important;
    border: 1px solid #2d2e2f !important;
  }
}
.order-group-screen {
  // padding: 62px 40px 131px 39px;
}

#trade-history {
  .filter-trade {
    max-width: 93%;
    @media screen and (max-width: 1550px) {
      max-width: 100% !important;
    }
    @media screen and (max-width: 1300px) {
      max-width: 100% !important;
    }
  }
  #order-table.table-history {
    table {
      @media screen and (min-width: 1440px) {
        table-layout: fixed;
      }

      thead {
        th {
          height: 63px;
          &[data-sort-field="created_at"] {
            width: 20.63% !important;
            min-width: 240px !important;
          }
          &[data-sort-field="coin"] {
            width: 13.28% !important;
            min-width: 170px !important;
          }
          &[data-sort-field="trade_type"] {
            width: 7% !important;
            min-width: 170px !important;
          }
          &[data-sort-field="price"] {
            width: 12% !important;
            min-width: 180px !important;
          }
          &[data-sort-field="role"] {
            width: 10% !important;
            min-width: 180px !important;
          }
          &[data-sort-field="quantity"] {
            width: 15% !important;
            min-width: 150px !important;
          }
          &[data-sort-field="fee"] {
            width: 18% !important;
            min-width: 180px !important;
          }
          &[data-sort-field="amount"] {
            width: 15% !important;
            min-width: 166px !important;
            border-radius: 0px 8px 8px 0px !important;
          }
        }
      }
      tbody {
        td {
          //font-size: 18px !important;
          line-height: 21px;
          padding: 20px 5px;
        }
        // td:not(:last-child){
        //   padding: 20px 5px;
        // }
        .cl_pair {
          text-align: left !important;
          position: relative;
          padding-left: 0 !important;

          .currency-img {
            margin-left: -8px;
          }
        }
      }
    }
  }
  .vdp-datepicker {
    input {
      font-family: $font-inter-regular;
      font-size: $font-medium-bigger;
      background: $color-alabaster;
      height: 35px;
      cursor: pointer;
    }
  }

  .date-to {
    input {
      height: 35px;
      width: 150px !important;
      border: none !important;
      border-radius: 0px 999px 999px 0px !important;
      background-position: top 8px right 14px !important;
    }
  }
  .date-from {
    input {
      background-image: none !important;
      padding: 0 0 0 16px !important;
      border: none !important;
      border-radius: 999px 0px 0px 999px !important;
      width: 116px !important;
    }
  }

  #order-table {
    table {
      margin-bottom: 0px;
      background: transparent;
    }
  }
  #order-table.history-table {
    // min-width: 1280px;
    // max-width: 85%;
  }
  .is_single_page {
    .search-type {
      .sc_search_select {
        .group_search_select {
          .box_list_search_select {
            left: 0 !important;
            ul {
              width: 95px !important;

              li {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          /* background-color: var(--color-tab-data-table) !important; */
          background: transparent !important;
          padding: 14px 10px !important;
          height: 49px !important;
          border: 1px solid #e6e6e6 !important;
          border-radius: 20px !important;
          &.active {
            border: 1px solid $color-jungle-green;
          }
        }
        .list_search_select {
          background: var(--bgr-dropdown);
        }

        .box_list_search_select {
          left: -21px !important;
          font-family: $font-inter-regular;

          ul {
            width: 127px !important;
            padding: 15px 0;
            li {
              text-transform: uppercase;
              padding: 6px 20px;
              span {
                color: var(--color-dropdown);

                img {
                  margin-right: 5px;
                  object-fit: contain;
                  border-radius: 50%;
                }
              }
              &:hover {
                background: none !important;
                span,
                a {
                  color: $color-caribbean-green !important;
                }
              }

              &:first-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                text-transform: capitalize !important;

                span {
                  color: var(--text-dropdown) !important;
                  &:hover {
                    color: $color-caribbean-green !important ;
                  }
                  &.select-name {
                    color: $color-caribbean-green !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .from-date {
      .vdp-datepicker__calendar {
        right: auto;
        left: 0px;
      }
    }

    .to-date {
      .vdp-datepicker__calendar {
        right: 0;
        left: auto;
      }
    }

    .vdp-datepicker {
      input {
        padding: 5px 3px 5px 47px !important;
        background-position: top 11px left 14px !important;
        height: 49px !important;
        background-color: var(--color-tab-data-table) !important;
        margin-right: 0px !important;
        border-radius: 8px !important;
        color: var(--text-close) !important;
        font-size: 18px !important;
        border: none !important;
        &:focus {
          border: 1px solid $color-jungle-green;
        }
      }

      @media screen and (max-width: 992px) {
        input {
          height: 36px !important;
          background-position: top 5px left 12px !important;
        }
      }
    }
  }

  .is_single_page {
    .search-type {
      .sc_search_select {
        .group_search_select {
          .box_list_search_select {
            left: 0 !important;
            ul {
              width: 95px !important;

              li {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }

  .vdp-datepicker__calendar {
    width: 310px;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px $color-shadow;
    border: 1px solid $color-white;
    header {
      span.up {
        //width: 55.428571%;
      }
    }
    .cell {
      padding: 0;
      color: var(--text-primary-color);
      font-size: 16px;
      height: 38px;
      line-height: 38px;
      &.day-header {
        color: var(--text-close);
        font-size: 16px;
        line-height: 19px;
      }
      &.blank {
        color: #424754;
      }
      &.selected {
        color: $color-grey-dark;
      }
      @media screen and (max-width: 992px) {
        font-size: $font-root;
        &.day-header {
          font-size: 14px;
        }
      }
    }
    header {
      span {
        color: var(--text-primary-color);
        font-size: 16px;
        @media screen and (max-width: 992px) {
          font-size: 14px;
        }
      }
    }
  }
  .side-layout-order {
    display: flex;
    flex-wrap: wrap;
  }
  .VuePagination {
    margin-top: 30px;
    padding-top: 0px;
  }
}
@media screen and (max-width: 1550px) {
  #trade-history-screen .order-group-screen {
    position: relative;
    max-width: calc(100vw - 325px);
    @media screen and (max-width: 1300px) {
      max-width: 100%;
    }
    #order-table.history-table {
      margin-bottom: 113px;
      padding-right: 16px;
      .VuePagination {
        position: unset;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: -73px;
        margin-top: 30px;
      }
    }
    .trade_layout_trade_history {
      #order-table.history-table {
        padding-bottom: 70px;
        .VuePagination {
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    #trade-history-screen .order-group-screen {
      max-width: 1318px;
    }
  }

  @media screen and (max-width: 992px) {
    #trade-history #order-table.table-history table tbody td {
      font-size: 14px !important;
      line-height: 16px;
    }

    #trade-history .group-control.is_single_page {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 !important;
      .group-date-picker {
        margin-right: 0 !important;
        width: 48%;
      }
      .vdp-datepicker input {
        width: 100%;
      }
    }

    #trade-history .is_single_page .vdp-datepicker input {
      font-size: 14px !important;
    }
    #trade-history
      .group-control.is_single_page
      .group-date-picker
      .group-date {
      width: 100%;
    }
    #trade-history
      .group-control.is_single_page
      .group-date-picker
      .group-date
      .vdp-datepicker {
      width: 100%;
    }
    #trade-history
      .is_single_page
      .sc_search_select
      .group_search_select
      .button_drop_search {
      height: 36px !important;
      padding: 9px 10px !important;
    }
    #trade-history .group-control.is_single_page .toolbar-element input {
      height: 36px !important;
      font-size: 14px !important;

      &::placeholder {
        font-size: 14px !important;
        line-height: 16px;
      }
    }
    #trade-history .group-control.is_single_page {
      .group-pair,
      .group-type {
        width: 100%;
        margin-top: 8px;
        margin-right: 0 !important;
      }
    }

    .label-text {
      font-size: $font-root !important;
    }

    .content-search {
      display: flex;
      justify-content: space-between;
      .toolbar-element {
        width: 48% !important;
        .coin-input {
          input {
            width: 100%;
          }
        }
      }
      .toolbar-element2 {
        width: 48% !important;
      }
    }
    #trade-history {
      .group-control {
        &.is_single_page {
          .coin-input {
            width: 100%;
            .box-coin-option {
              max-width: 100% !important;
            }
          }
          .group-type {
            .button-search {
              &:hover {
                background-color: $color-caribbean-green;
                border-color: $color-caribbean-green;
                color: $color-grey-dark;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
            }
            .button-reset {
              font-size: 14px !important;
            }
          }
        }
      }
      .is_single_page {
        .sc_search_select {
          .group_search_select {
            .box_list_search_select {
              left: 0px !important;
              width: 100%;
              ul {
                width: 100% !important;
                left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  #time-group {
    display: none !important  ;
  }
}
</style>
