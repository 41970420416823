<template>
  <masked-input
    type="text"
    v-model="model"
    :mask="createNumberMask"
    :guide="false"
    @keypress="eventKeyPress"
    @focus="onFocus" 
    @blur="handleBlur" 
    ref="input">
  </masked-input>

</template>
<script>

import MaskedInput from 'vue-text-mask'
// import BigNumber from 'bignumber.js';

export default {
  components: {
    MaskedInput
  },
  props: {
    value: '',
    focusHandler: {
      default: function() {},
    },
    precision: {},
    props: ['value'],
  },
  watch: {
    value() {
      this.init();
    },
    model(newValue) {
      this.$emit('input', newValue);
    }
  },
  data() {
    return {
      internalPrecision: 0,
      model: "",
    };
  },
  methods: {
    eventKeyPress(event) {
      let stringValue = "" + this.value;
      let charCode = (event.which) ? event.which : event.keyCode;
      if ((stringValue.length >= 18) || (charCode === 46 && (~ stringValue.indexOf('.') < 0)) ||
        ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46)) {
        // event.preventDefault();
      } else {
        this.$emit("keypressinput", event);
        return true;
      }
    },

    createNumberMask: function(value) {
      var standardizedValue = this.standardize(value)
      var result = [];
      for (var i = 0; i < standardizedValue.length; i++) {
        var char = standardizedValue.charAt(i);
        if (char >= '0' && char <= '9') {
          result.push(/\d/);
        } else {
          result.push(char);
        }
      }
      return result;
    },
    init() {
      this.focus();
      if (!this.value) {
        this.model = "";
        return;
      }
      this.model = this.value;
    },

    onFocus: function() {
      this.$emit('focus');
    },

    handleBlur(evt) {
      evt.target.value = insertNumber(evt.target.value);
      this.model = insertNumber(evt.target.value);
    },

    standardize: function(value) {
      var precision = this.internalPrecision;
      var result = value.trim().replace(/[^0-9\.]/g, '');
      return result;
    },

    removeLeadingZeros: function(value) {
      var result = value;
      while (true) {
        if (result.length < 2) break;
        if (result.charAt(0) == '0' && result.charAt(1) != '.') {
          result = result.slice(1);
        } else {
          break;
        }
      }
      return result;
    },

    focus() {
      this.$refs.input.$refs.input.focus();
    }
  },
  directives: {
    "number-only": {
      bind(el, binding) {
        el.value = insertNumber(el.value);
        binding.value = el.value;
      },

      inserted(el, bind) {
        el.value = insertNumber(el.value);
        bind.value = el.value;
      },

      update(el, bind) {
        el.value = insertNumber(el.value);
        bind.value = el.value;
      },
    }
  },
  mounted() {
    this.init();
  }
}
function insertNumber(newValue) {
  newValue = "" + newValue;
  newValue = newValue.match(/(\d)+(\.)?(\d)?/gi) ? newValue.match(/(\d)+(\.)?(\d)?/gi).join('') : "";
  return newValue;
}
</script>