import IconYourLink from "../assets/images/imgYourLink.svg";
import IconInvite from "../assets/images/imgInvite.svg";
import IconReward from "../assets/images/imgReward.svg";
import IconYourLinkDark from "../assets/images/imgYourLink-dark.svg";
import IconInviteDark from "../assets/images/imgInvite-dark.svg";
import IconRewardDark from "../assets/images/imgReward-dark.svg";

export const referral_program_box = [
  {
    id: 1,
    img: IconYourLink,
    imgDark: IconYourLinkDark,
    title: "1. ",
    muti: "refferal.title1",
    content: "refferal.text1",
  },
  {
    id: 2,
    img: IconInvite,
    imgDark: IconInviteDark,
    title: "2. ",
    muti: "refferal.text2",
    content: "refferal.text3",
  },
  {
    id: 3,
    img: IconReward,
    imgDark: IconRewardDark,
    title: "3. ",
    muti: "refferal.get_rewards",
    content: "refferal.text5",
  },
];
