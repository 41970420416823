<template>
  <div>
    <div class="VuePagination" v-show="totalPages > 1">
      <ul
          class="pagination VuePagination__pagination"
          :class="{'justify-content-end': paginationPosition === 'right'}"
      >

        <!--<li class="VuePagination__pagination-item page-item VuePagination__pagination-item-prev-chunk "-->
            <!--:class="{disabled : !allowedChunk(-1)}">-->
          <!--<a class="page-link" href="javascript:void(0);"-->
             <!--@click="setChunk(-1)">&lt;&lt;</a>-->
        <!--</li>-->


        <li class="VuePagination__pagination-item page-item VuePagination__pagination-item-prev-page page-prev"
            :class="{disabled : !allowedPage(page - 1)}">
          <a class="page-link " href="javascript:void(0);" @click="prev()">
             <i class="icon-arrow4"></i>
          </a>
        </li>

        <li v-for="(item, index) in pages" :key='index' class="VuePagination__pagination-item page-item "
            :class="{active: parseInt(page) === parseInt(item)}">
          <a class="page-link number-item" role="button"
              @click="setPage(item)">{{item}}</a>
        </li>

        <li class="VuePagination__pagination-item page-item VuePagination__pagination-item-next-page page-next"
            :class="{disabled : !allowedPage(page + 1)}">
          <a class="page-link" href="javascript:void(0);" @click="next()">
            <i class="icon-arrow3"></i>
          </a>
        </li>

        <!--<li class="VuePagination__pagination-item page-item VuePagination__pagination-item-next-chunk "-->
            <!--:class="{disabled : !allowedChunk(1)}">-->
          <!--<a class="page-link" href="javascript:void(0);"-->
             <!--@click="setChunk(1)">&gt;&gt;</a>-->
        <!--</li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Cookies from 'js-cookie'
  export default {
    props: {
      pageParent: {
        type: Number,
        default: 1,
      },
      records: {
        type: Number,
        required: true
      },
      chunk: {
        type: Number,
        required: false,
        default: 5
      },
      perPage: {
        type: Number,
        required: true,
      },

      paginationPosition: {
        type: 'center' | 'right' | 'left',
        default: 'center',
      },
    },
    data: function () {
      return {
        page: this.pageParent,
        theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
      }
    },
    watch: {
      records() {
        if (this.page > this.totalPages) {
          this.page = 1;
        }
      },
      pageParent() {
        this.page = this.pageParent;
      },
     
      userThemeMode(theme) {
        this.theme = theme;
      },
  
    },
    computed: {
      ...mapState({
        userThemeMode: state => state.userThemeMode
      }),
      pages: function () {
        if (!this.records)
          return []

        return range(this.paginationStart, this.chunk, this.totalPages)
      },
      totalPages: function () {
        return this.records ? Math.ceil(this.records / this.perPage) : 1
      },
      totalChunks: function () {
        return Math.ceil(this.totalPages / this.chunk)
      },
      currentChunk: function () {
        return Math.ceil(this.page / this.chunk)
      },
      paginationStart: function () {
        return ((this.currentChunk - 1) * this.chunk) + 1
      },
      pagesInCurrentChunk: function () {

        return this.paginationStart + this.chunk <= this.totalPages ? this.chunk : this.totalPages - this.paginationStart + 1

      },
    },
    methods: {
      setPage: function (page) {
        if (this.allowedPage(page)) {
          this.paginate(page)
        }
      },
      paginate (page) {
        this.page = page
        this.$emit('Pagination:page', page)
      },
      next: function () {
        return this.setPage(this.page + 1)
      },
      prev: function () {
        return this.setPage(this.page - 1)
      },
      setChunk: function (direction) {
        this.setPage((((this.currentChunk - 1) + direction) * this.chunk) + 1)
      },
      allowedPage: function (page) {
        return page >= 1 && page <= this.totalPages
      },
      allowedChunk: function (direction) {
        return (parseInt(direction) === 1 && this.currentChunk < this.totalChunks)
          || (parseInt(direction) === -1 && this.currentChunk > 1)
      },
      allowedPageClass: function (direction) {
        return this.allowedPage(direction) ? '' : 'disabled'
      },
      allowedChunkClass: function (direction) {
        return this.allowedChunk(direction) ? '' : 'disabled'
      },
      activeClass: function (page) {
        return parseInt(this.page) === parseInt(page) ? 'active' : ''
      }
    }
  }
  

  function range (start, chunk, total) {
    if( start + chunk > total) {
      start = Math.max(total - chunk + 1, 1);
    }
    var end = chunk > total ? total : chunk;
    return Array.apply(0, Array(end))
      .map(function (element, index) {
        return index + start
      })
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/common.scss";
@import "@/assets/sass/variables";
  .dark-theme {
    .pagination > .disabled > a {
      color: var(--color-tranfer-from)!important;
    }
    .page-item .number-item {
      color: var(--color-pagi);
    }
  }

  .pagination {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
  .pagination > li > a, .pagination > li > span {
    color: var(--color-pagi);
  }
  .pagination > .disabled > a {
    color: var(--color-input)!important;
  }
  .VuePagination {
    // background-color: $color-alabaster;
  }
  .page-item {
    &.active {
      a {
        color: $color-caribbean-green !important;
        border: 1px solid $color-caribbean-green !important;
      }
    }
    .number-item {
      color: var(--dark-1);
      font-family: $font-inter-regular;
      font-size: 14px;
      line-height: 25px;
      padding: 0 10px;
      width: 32px;
      height: 32px;

    }
    a {
      background: var(--bgr-pagination) !important;
      border: 1px solid var(--bgr-btn) !important;
      border-radius: 5px;
      padding: 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: $font-mini;
      }
       &:hover {
        color: $color-caribbean-green;
      }
      // &:link {
      //   color: $color-grey-dusty;
      //   background: transparent; 
      // }
    }
  }
  .page-prev {
    
  }
  .page-next {
    
  }
</style>
