<template>
  <div class="page_container" id="account">
    <change-password ref='changePassComponent'/>
    <otp-modal name="otp_modal" :submit="updateModeWhitelist" :params="getParamsUpdateModeWhitelist()" :is-disable-button="isDisableButton" :contact_email="siteSetting.contact_email"/>
    <cancel-google-auth-modal @CancelGoogleAuthModal:CLOSE="hideCancelGoogleAuthModal"></cancel-google-auth-modal>
    <enable-google-authentication @EnableGoogleAuthentication:CLOSE="hideEnableGoogleAuthenticaion"></enable-google-authentication>
    <lost-otp-modal :contact_email="siteSetting.contact_email" name="lostOtpForm" @DISABLE_OTP_SUCCESS="disableOtpSuccess"/>
    <confirmation-modal>
      <template slot="content">
        <div class="checkbox-bank">
          <input type="checkbox" id="c-bank" v-model="isCreateNewBankAccount"/>
          <label for="c-bank">{{ $t('refferal.confirm_bank_account_content') }}</label>
        </div>
      </template>
    </confirmation-modal>
    <div class="account_container">
      <div class="path_acount_container">
        <div class="account_navbar">
          <AccountSiteBar />
        </div>
        <div class="right_list_option_acc">
          <div class="setting_top_header">
            <AccountTopHeader title='Settings'/>
          </div>
          <div class="contents_setting">
            <p class="title-medium-small">{{ $t('menu.preferences')}}</p>
            <div class="contents_setting_container setting_border-bottom">
              <div class="contents_setting_icon">
                <img src="/images/icon/icon-phone-notification.svg" alt="icon" class="icon-phone-noti"/>
              </div>
              <div class="preferences_group">
                <div class="group-text-main">
                  <span class="text-main">
                    {{ $t('menu.notifications')}}
                  </span>
                </div>
                <div class="group"> 
                  <div class="group_center_option ">
                    <div class="center_group">
                      <span class="text-main text-mb">{{$t('account.line')}}</span>
                      <div class="check_authen_activation">
                        {{$t('account.line.desc')}}
                      </div>
                    </div>
                    <div class="group_btn">
                      <div class="group_status">
                        <img src="/images/icon/icon-verified.svg" alt="icon" class="icon-verified"/>
                        <span class="text-status">{{ $t('menu.default')}}</span>
                      </div>
                      <div class="group_btn-btn">
                        <button @click="showChannelModal('disable','line')"
                          v-if="lineNotification"
                          class="btn btn_disable">{{$t('account.disable')}}
                        </button>
                        <button @click="redirectLine(user.id)"
                            class="btn btn_enable"
                            id = "cancel_focus_line"
                            v-else>{{$t('account.enable')}}
                        </button>   
                      </div>
                    </div>
                  </div>
                </div>
                <div class="group">
                  <div class="group_center_option ">
                    <div class="center_group">
                      <span class="text-main text-mb">{{$t('account.telegram')}}</span>
                      <div class="check_authen_activation">
                        {{$t('account.telegram.desc')}}
                      </div>
                    </div>
                    <div class="group_btn">
                      <div class="group_status">
                        <img src="/images/icon/icon-verified.svg" alt="icon" class="icon-verified"/>
                        <span class="text-status">{{ $t('menu.activities_trade')}}</span>
                      </div>
                      <div class="group_btn-btn">
                        <button @click="showChannelModal('disable','telegram')"
                                class="btn btn_disable"
                                v-if="telegramNotification">{{$t('account.disable')}}</button>
                        <button @click="addChannelTelegram(user.id)"
                                class="btn btn_enable"
                                id="cancel_focus_telegram"
                                v-else>{{$t('account.enable')}}
                        </button>  
                      </div>
                    </div>
                  </div>
                </div>
                <div class="group">
                  <div class="group_center_option ">
                    <div class="center_group">
                      <!-- <span class="text-main text-mb">{{user.email}}</span> -->
                      <span class="text-main text-mb">{{ $t('menu.maket_email')}}</span>
                      <div class="check_authen_activation">
                        {{$t('menu.sub_maket_email')}}
                      </div>
                    </div>
                    <div class="group_btn">
                      <div class="group_status">
                        <img src="/images/icon/icon-verified.svg" alt="icon" class="icon-verified"/>
                        <span class="text-status">{{ $t('menu.on')}}</span>
                      </div>
                      <div class="group_btn-btn">
                        <button @click="showChannelModal('disable', 'email')" class="btn btn_disable"
                            v-if="emailNotification">{{$t('account.disable')}}</button>
                        <button @click="showChannelModal('enable','email')" class="btn btn_enable" id="cancel_focus_mail"
                                v-else>{{$t('account.enable')}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content_kyc">
              <p class="title-medium-small">KYC</p>
              <div class="contents_setting_container">
              <div class="contents_setting_icon">
                <img src="/images/icon/icon-user-kyc.svg" alt="icon" class="icon-user-kyc"/>
              </div>
              <div class="preferences_group">
                <div class="group"> 
                  <div class="group_center_option">
                    <div class="center_group">
                      <span class="text-main">{{ $t('menu.kyc_status')}}</span>
                    </div>
                    <div class="group_btn">
                      <div class="group_status" v-if="true">
                        <img src="/images/icon/icon-verified.svg" alt="icon"/>
                        <span class="text-status">{{ $t('menu.kyc_status_verified')}}</span>
                      </div>
                      <div class="group_status" v-else>
                        <img src="/images/icon/icon-un-verify.svg" alt="icon"/>
                        <span class="text-status">{{ $t('menu.kyc_status_verified')}}</span>
                      </div>
                      <div class="group_btn-btn">
                        <button v-if="user.max_security_level == 1 " class="btn btn_disable">
                          {{$t('account.disable')}}
                        </button>
                        <button class="btn btn_enable" id="kyc-connect" v-show="user.max_security_level >= 2 ">
                          {{$t('account.enable')}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
  import CancelGoogleAuthModal from '@/components/shared_components/otp/CancelGoogleAuthModal';
  import OtpModal from '@/components/shared_components/funds/common/OtpModal';
  import EnableGoogleAuthentication from './account/EnableGoogleAuthentication';
  import ChangePassword from './account/ChangePassword.vue';
  import DeviceManager from './account/DeviceManager.vue';
  import LostOtpModal from '@/components/shared_components/otp/LostOtpModal';
  import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal';
  import SelectBox from '@/components/shared_components/common/SelectBox';
  import moment from 'moment';
  import Const from '@/common/Const';
  import { getLayoutPath } from '@/routes';
  import ChartContracts from "./ChartIndex.vue";
  import { mapState } from 'vuex';
  import AccountSiteBar from '@/components/shared_components/common/AccountSiteBar.vue';
  import AccountTopHeader from '@/components/shared_components/common/AccountTopHeader.vue';

  import 'url-search-params-polyfill';
  const KYC_UNVERIFED = 'unverified';
  const KYC_PENDING = 'pending';
  const KYC_VERIFIED = 'verified';
  const KYC_REJECTED = 'rejected';
  const BANK_UNVERIFED = 'unverified';
  const BANK_CREATING = 'creating';
  const BANK_VERIFING = 'verifing';
  const BANK_VERIFIED = 'verified';
  const BANK_REJECTED = 'rejected';
  var sha1 = require('sha1');
  export default {
    components: {
      ChartContracts,
      CancelGoogleAuthModal,
      EnableGoogleAuthentication,
      OtpModal,
      ChangePassword,
      LostOtpModal,
      DeviceManager,
      ConfirmationModal,
      SelectBox,
      AccountSiteBar,
      AccountTopHeader
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        linkKycAccount: getLayoutPath({name: 'IdentityPersonal'}, true),
        linkAPIAccount: getLayoutPath({name: 'ApiCreated'}, true),
        linkAPIPage: process.env.VUE_APP_API_URL + '/api/documentation',
        isShowTooltip: false,
        tab : 'main',
        enableWhiteList: undefined,
        enableLeaderboard: undefined,
        localShowFakeName: undefined,
        lastLogin: {},
        withdrawalLimitBTC: [],
        check: 0,
        settings: {
          email: true,
          phone: false,
          bank: false,
          otp: false,
          password: false,
        },
        kyc: {
          status: '',
          bank_status: '',
        },
        user: {},
        infomation: {},
        otp: undefined,
        userSettingWhitelist: undefined,
        enableOtp: true,
        emailNotification: true,
        telegramNotification: true,
        lineNotification:false,
        isCreateNewBankAccount: false,
        enableAmalFromAdmin: false,
        enableAmlPay: false,
        app_name : process.env.VUE_APP_NAME,
        moment: moment,
        isDisableButton: false,
        siteSetting: {},
        idEncrypt:'',
        perpetual_dividend: false,
        isEditFakeName: false,
        fakeNameTemp:'',
        kyc_key : process.env.VUE_APP_BLOCKPASS_KYC_KEY
      }
    },
    watch: {
      enableAmalFromAdmin(newValue) {
        if (!newValue) {
          this.enableAmlPay = false;
        }
      }
    },
    
    mounted(){
      this.getMasterData();
      let url=window.location.href;
      if(url.includes("need-close")) {
          window.close();
      }
      this.loadBlockpassWidgets(this.user)
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
      }),
      isVerifiableBank() {
        return this.kyc.bank_status === BANK_CREATING
                || this.kyc.bank_status === BANK_VERIFING || this.kyc.bank_status === BANK_VERIFIED;
      },
      appUrl() {
        return window.location.origin;
      },
      submittableKyc() {
        return window._.get(this, "user.security_setting.otp_verified", 0);
      },
      userSecurityLevel() {
        if (this.user.security_level > this.user.max_security_level) {
          return this.user.security_level;
        }
        return this.user.max_security_level;
      }  
    },
    methods: {
        onSaveFakeName(){
          rf.getRequest('UserRequest').onUpdateFakeName({fake_name: this.fakeNameTemp}).then(res =>{
            if(res && res.data){
             this.user.fake_name = this.fakeNameTemp;
             this.isEditFakeName = false;
             this.getUser(false);
             this.showSuccess(this.$t('mam.update.success'));
            }
          })
        },
        activeLeaderboard() {
        },
        getLinkPnlChart() {
          rf.getRequest('UserRequest').getLinkPnlChart({name: 'Pnl-Chart'}).then(res => {
              const path = APP_URL + '/pnl-chart?token='+res.data.id
              // if (!navigator.clipboard) {
              //   Utils.fallbackCopyTextToClipboard(path);
              //   return;
              // }
              // navigator.clipboard.writeText(path);
              // this.$router.push({ name: 'PNLChart', query: {token: res.data.id} });
              window.open(path,"_blank");
          });
        },
        convertDate(date) {
          var dateFormat = 'YYYY-MM-DD HH:mm:ss';
          var testDateUtc = moment.utc(date);
          var localDate = testDateUtc.local();
          return localDate.format(dateFormat);
        },  
        addChannelTelegram: function (userID) {
          document.getElementById("cancel_focus_telegram").blur();
          this.listenForTelegramLine(userID);
          let apiTelegram = process.env.VUE_APP_MIX_API_TELEGRAM + userID;    
          window.open(apiTelegram, "_blank");
        },

        getParamsUpdateModeWhitelist() {
          return {
            active: !this.userSettingWhitelist
          }
        },
        listenForTelegramLine(userId) {      
            window.Echo.private('App.User.' + userId)
              .listen('UserNotificationUpdated', (res) => { 
                    let channel = res.data.channel;
                    let is_enable= res.data.is_enable;
                    if(channel==="line"){
                      this.lineNotification = is_enable;
                    }
                    if(channel==="mail"){
                      this.emailNotification = is_enable;
                    }
                    if(channel==="telegram"){
                      this.telegramNotification = is_enable;
                    }
              });
        },
        async redirectLine(userID){          
            await this.encryptId();            
            document.getElementById("cancel_focus_line").blur();
            if(userID){
              let callback_uri=this.$router.resolve({name: 'Account'}).href+"?need-close";
              let state = userID + "&" + this.idEncrypt + "$" + callback_uri;
              let client_id=process.env.VUE_APP_MIX_LINE_CLIENT_ID;
              let redirect_uri=process.env.VUE_APP_API_URL+'/api/v1/get-auth-code';
              let response_type = "code";
              let url="https://notify-bot.line.me/oauth/authorize?";
              let scope="notify";
              var searchParams = new URLSearchParams("");
              searchParams.append("client_id",client_id);
              searchParams.append("redirect_uri",redirect_uri);
              searchParams.append("response_type",response_type);
              searchParams.append("scope",scope);
              searchParams.append("state",state);
              url=url+searchParams;              
              window.open(url,"_blank");
            }
        },
        changeWalletFee(type) {
          if (this.tab != type && this.enableAmlPay) {
            this.tab = type;
            let param = {
              wallet_name : type,
            }
            rf.getRequest('UserRequest').changeTypeWalletAmalPayFee(param).then(res => {});
          }
          
        },
        
        showChannelModal(type, channel) {            
            if (type === 'disable') {
                window.ConfirmationModal.show({
                    type: channel === 'email' ? 'disableEmail' : channel === 'telegram' ? 'disableTelegram' : channel==='line' ? 'disableLine':'',
                    title: '',
                    content: channel === 'email' ?
                        this.$t('account.email_disable_confirm') :
                        channel === 'telegram' ? this.$t('account.email_disable_confirm') : 
                        channel === 'line' ? this.$t('account.email_disable_confirm') : '',
                    btnCancelLabel: window.i18n.t('common.action.no'),
                    btnConfirmLabel: window.i18n.t('common.action.yes'),
                    onConfirm: () => {
                        this.actionNotification(type, channel);
                    }
                });
            } else {
                document.getElementById("cancel_focus_mail").blur();
                this.actionNotification(type, channel);
            }
        },
      actionNotification(type, channel) {
            let param = {
                active: 1
            };
            if (type === 'disable') {
                param.active = 0;
            } else {
                param.active = 1;
            }
            if (channel === 'email') {
                rf.getRequest('UserRequest').changeEmailNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
            if (channel === 'telegram' && param.active == 0) {
                rf.getRequest('UserRequest').changeTelegramNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
            if (channel === 'line' ) {
                rf.getRequest('UserRequest').changeLineNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
        },
      showTooltip() {
        this.isShowTooltip = !this.isShowTooltip;
      },
      hideTooltip() {
        this.isShowTooltip = false;
      },
      showCancelGoogleAuthModal() {
        window.CommonModal.show('CancelGoogleAuthModal');
      },
      showChangePasswordModal() {
        window.CommonModal.show('ChangePassword');
        this.$refs.changePassComponent.resetData();
      },
      hideCancelGoogleAuthModal() {
        window.CommonModal.hide('CancelGoogleAuthModal');
        this.getSecuritySettings();
        this.getUser(false);
      },
      hideEnableGoogleAuthenticaion() {
        window.CommonModal.hide('EnableGoogleAuthentication');
        this.getSecuritySettings();
        this.getUser(false);
      },
      showEnableOtpModal() {
        window.CommonModal.show('EnableGoogleAuthentication');
      },
      showModalLostOtp () {
        window.CommonModal.show('lostOtpForm');
      },
      disableOtpSuccess () {
        this.getSecuritySettings();
        this.getUser(false);
      },
      loadDataImmediately() {
        this.getUserSettings();
        this.getUserNotification();
        this.getSecuritySettings();
        this.getUser();
        this.getWithdrawalLimitBTC();
        this.getUserKyc();     
        this.getDividendSettings();   
      },
      getSecuritySettings() {
        rf.getRequest('UserRequest').getSecuritySettings().then(res => {
          this.settings = Object.assign({}, this.settings, res.data);
          this.enableOtp = window._.get(this, "settings.otp_verified", 0);
        });
      },
      getUserKyc() {
        rf.getRequest('UserRequest').getUserKyc().then(res => {
          this.kyc = res.data || {};
          this.isCreateNewBankAccount = this.kyc.bank_status === BANK_CREATING;
        });
      },
      async encryptId() {
        await rf.getRequest('UserRequest').encryptId().then(res => {
          this.idEncrypt = sha1(res.toString()) || {};          
        });                
      },
      getDividendSettings() {
        rf.getRequest('UserRequest').getDividendSetting().then(res => {
          this.enableAmalFromAdmin = res.data?.enable_fee_amal || false;
          this.perpetual_dividend = res.data?.enable_wallet_pay_fee || false;
        })

      },
      getHistoryConnection(params) {
        const promise = rf.getRequest('UserRequest').getHistoryConnection(params);
        this.getLatest(promise);
        return promise;
      },
      getLatest(promise) {
        promise.then(res => {
          this.lastLogin = res.data.data[0];
        });
      },

      getUserNotification(){
        rf.getRequest('UserRequest').getUserNotificationSettings().then(res => {
            const emailNotification =  _.find(res.data, (item) => {
              return item.channel === 'mail'
            }) || {};

            this.emailNotification = emailNotification.is_enable === 1;

            const telegramNotification = _.find(res.data, (item) => {
              return item.channel === 'telegram'
            }) || {};

            this.telegramNotification = telegramNotification.is_enable === 1;

            const lineNotification = _.find(res.data, (item) => {
              return item.channel === 'line'
            }) || {};
            this.lineNotification = lineNotification.is_enable === 1;
        });
      },

      getUserSettings() {
        rf.getRequest('UserRequest').getUserSettings().then(res => {
          const setting = _.find(res.data, (item) => {
            return item.key === 'whitelist'
          }) || {};
          this.userSettingWhitelist = this.enableWhiteList = !!parseInt(setting.value);

          const amlPay =  _.find(res.data, (item) => {
            return item.key === 'amal_pay'
          }) || {};
          const amlWalletPay =  _.find(res.data, (item) => {
            return item.key === 'amal_pay_wallet'
          }) || {};
          this.enableAmlPay = amlPay.value === "1";
          if (amlWalletPay && amlWalletPay.value) {
            this.tab = amlWalletPay.value;
          }

        });
      },
      getUser(useCache = true) {        
        rf.getRequest('UserRequest').getCurrentUser(useCache).then(res => {
          this.user = Object.assign({}, this.user, res.data);
          this.listenForTelegramLine(res.data.id);
          this.loadBlockpassWidgets(res.data.id);
          this.localShowFakeName = this.user.security_setting.use_fake_name;
          return this.enableLeaderboard = this.user.security_setting.use_fake_name
        });
      },
      getWithdrawalLimitBTC() {
        rf.getRequest('UserRequest').getWithdrawalLimitBTC().then(res => {
            this.withdrawalLimitBTC = res.data;
        });
      },
      activeWhitelist() {
        const message = this.userSettingWhitelist ? window.i18n.t('account.turn_on_mode_whitelist') : window.i18n.t('account.turn_off_mode_whitelist');
        this.showModal({
          type: 'primary',
          title: '',
          content: message,
          onConfirm: () => {
            if (this.enableOtp) {
              window.CommonModal.show('otp_modal');
              this.enableWhiteList = this.userSettingWhitelist;
            } else {
              const params = this.getParamsUpdateModeWhitelist();
              this.updateModeWhitelist(params);
            }
          },
          onCancel: () => {
            this.enableWhiteList = this.userSettingWhitelist;
          }
        });
      },
      updateModeWhitelist(params) {
        this.isDisableButton = true;
        rf.getRequest('UserRequest').activeWhitelist(params).then( response => {
          this.isDisableButton = false;
          window.CommonModal.hide('otp_modal');
          this.userSettingWhitelist = this.enableWhiteList = response.data.whitelist;
        }).catch(error => {
          this.isDisableButton = false;
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'));
          } else {
            this.convertRemoteErrors(error);
          }
        })
      },
      activeLeaderboard() {
        const message = this.enableLeaderboard ? window.i18n.t('leaderboard.show_fake_username') : window.i18n.t('leaderboard.show_real_username');
        this.showModal({
          title: '',
          content: message,
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.updateLeaderboard()
          },
          onCancel: () => {
            this.enableLeaderboard = !this.enableLeaderboard ? 1 : 0
          }
        });
      },
      updateLeaderboard() {
        let params = {
          use_fake_name: !this.enableLeaderboard ? 0 : 1
        }
        rf.getRequest('UserRequest').activeLeaderBoard(params).then(res=>{
          this.localShowFakeName = res.data;
        }).catch(err=>{
          this.localShowFakeName = 0;
        });
      },
      showSuccess(message) {
        Message.success(
          message,
          {},
          { position: 'bottom_left' }
        );
      },
      getSocketEventHandlers() {
        return {
          OtpUpdated: this.getSecuritySettings
        }
      },
      verifyBankAccount (status) {
        rf.getRequest('UserRequest').updateBankAccountStatus(status).then(res => {
          this.getUserKyc();
        });
      },
      verifyBankModal () {
        if (this.kyc.status !== KYC_VERIFIED) {
          this.showModal({
            type: 'warning',
            title: 'Error',
            content: this.$t('refferal.alert_bank_account_content'),
            noAction: true,
          });
          return;
        }
        this.showModal({
          type: 'confirm',
          title: this.$t('refferal.confirm_bank_account_title'),
          customContent: true,
          onConfirm: () => {
            this.verifyBankAccount({status: this.isCreateNewBankAccount ? BANK_CREATING : BANK_VERIFING})
          }
        });
      },
      showModal (config) {
        const type = config.type;
        const title = config.title;
        const content = config.content;
        const customContent = config.customContent;
        let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
        let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
        if (config.noAction) {
          btnCancelLabel = null;
          btnConfirmLabel = null;
        }
        const onConfirm = config.onConfirm;
        const onCancel = config.onCancel;
        window.ConfirmationModal.show({
          type: type,
          title: title,
          content: content,
          customContent: customContent,
          btnCancelLabel: btnCancelLabel,
          btnConfirmLabel: btnConfirmLabel,
          onConfirm: onConfirm,
          onCancel: onCancel
        });
      },
      onClickedVerifyGoogleAuthen(pathRoute) {
        if (!this.submittableKyc) {
          Message.error(this.$t('account.otp.should_enable_first'), {}, { position: 'bottom_left' });
          return;
        }
        this.$router.push({ path: pathRoute });
      },
      activeAmlPay() {
        if (this.enableAmalFromAdmin) {
          let param = {
            active: !this.enableAmlPay ? 1 : 0
          }
          rf.getRequest('UserRequest').changeAmlPay(param).then(res => { });
        }
      },
      getUserDevice(params) {
        const promise = rf.getRequest('UserRequest').getUserDevice(params);
        return promise;
      },
      onDatatableFinished() {
        if (!this.$refs.datatableDevice || !this.$refs.datatableDevice.rows) {
          return;
        }
        window._.each(this.$refs.datatableDevice.rows, item => {
          item.isActiveRow = false;
        });
      },
      activeRow(item) {
        item.isActiveRow = !item.isActiveRow;
        this.$forceUpdate();
      },
      refreshDevice() {
        this.$refs.datatableDevice.refresh();
      },
      confirmRemoveDevice (deviceId) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          content: this.$t('account.modal.confirm_trusted_device'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.removeDevice(deviceId);
          },
          onCancel: () => {
            
          }
        });
      },
      removeDevice (deviceId) {
        rf.getRequest('UserRequest').deleteDevice(deviceId).then((res) => {
          this.refreshDevice();
          Message.success(window.i18n.t('address.deleted_success'));
        });
      },

      getMasterData() {
        window._.each(this.masterdata.settings, item => {
          this.siteSetting[item.key] = item.value;
        });
      },
      loadBlockpassWidgets(id) {
          if(id) {
            const blockpass = new window.BlockpassKYCConnect (
              this.kyc_key,
              {
                refId: id,
                mainColor: '00DDB3',
                elementId: 'kyc-connect'
              }
            )
            blockpass.startKYCConnect()
          }
    }

    },
    created () {
      this.loadDataImmediately();      
        document.title = this.$t('menu.account') + ` – ${this.tabTitle}`;
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  #account {
    .group_custom {
      min-height: 405px;
      .group {
        width: 100%;
        margin-bottom: 10px;
        padding-right: 20px;
        .mgt38 {
          margin-top: 47px;
        }
      }
    }
    .group_custom-2 {
      min-height: inherit;
      .group {
        margin-bottom: 0px;
      }
    }
    .disabled_item {
      background-color: $color-bright-gray;
      border: none;
      cursor: not-allowed;
      pointer-events: none;
      color: $color_black;
      border: solid 1px $color-alto;
    }
    .break_boder {
      width: calc(100% - 30px - 30px - 15px);;
      height: 1px;
      opacity: 0.7;
      border-radius: 1px;
      background-color: $color-alto;
      clear: both;
      margin: auto;
    }
    .checkbox-bank {
      label {
        display: inline;
        font-weight: normal;
      }
    }
    .table-device {
      margin-top: 15px;
      .icon-close, .glyphicon{
        font-weight: 600;
        font-size: 16px;
        color: $color-green;
        &:hover, &:focus {
          color: $color-eden;
        }
      }
      .glyphicon{
        font-weight: normal;
        font-size: $font-medium;
      }
      tr {
        height: auto;
        &.double_tr {
          padding: 0 25px;
          th {
            font-size: $font-root;
            color: $color-grey;
            padding: 15px 0px;
            line-height: 20px;
            width: 31%;
            &:nth-child(4){
              width: 3.5%;
            }
            &:nth-child(5){
              width: 3.5%;
            }
          }
        }
        &.device-manager {
          padding: 0 25px;
          &:hover{
            background-color: $color-bright-gray;
          }
          th {
            &.active {
              max-height: 300px;
              transition-property: height;
              transition-duration: 0.3s, 0.3s;
              transition-timing-function: ease, ease-in;
              .glyphicon-menu-down {
                transition-duration: 0.5s;
                transform: rotate(180deg);
              }
            }
          }
          td {
            text-align: left;
            width: 31%;
            font-weight: 600;
            &:nth-child(4){
              width: 3.5%;
            }
            &:nth-child(5){
              width: 3.5%;
            }
          }
        }
      }
      .odd {
        background-color: $color-white;
      }
      .actions {
        cursor: pointer;
        color: $color-grey !important;
      }
    }
    .datatable {
      tr {
        &.empty-data {
          td {
            width: 100%
          }
        }
        height: auto;        
        &:hover{
          background-color: $color-bright-gray;
        }
        &.double_tr {
          padding: 0 25px;
          th {
            font-size: $font-root;
            color: $color-grey;
            padding: 15px 0px;
            line-height: 20px;
            font-size: 13px;          
          }
        }
        th{          
          width: 33.33%;
        }       
        td{
          font-size: $font-root;
          color: $color-grey-dark;
          padding: 10px 0px;
          line-height: 20px;
          width: 33.33%;
        }
        &.last-login {
          text-align: left;
          padding: 0 25px;
          
          td {
            font-weight: 600;
            color: $color-grey-dark;
            &:last-child {
              text-align: left;
            }
          }
        }
      }
    }
    .table_min{
      table{
        background-color: $color-white;
        border-top: 0px;
        thead{
          height: auto;
          background: $color-white;
        }
      }
    }
  }
  .scroll-table table tbody {
    height: auto !important;
    margin-bottom: 20px;
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  #chart_contracts {
    height: calc(100vw * 0.35);
    min-height: 495px;
    border: 1px solid $color-grey;
    margin-top: 15px;
  }
  .disable-class{
    cursor:not-allowed;

  }
  .fake-name-contain {
    color: #666666;
    font-size: 13px;
    margin: 10px 0;
    .fake-name-span {
      word-break: break-all;
    }
    span {
      color: #333;
    }
    .action-button-wrapper{
      display: inline-block;
    }
    .btn_edit_network, .btn_save_network, .btn_close_network {
      color: $color-jungle-green;
      padding: 5px 6px;
      line-height: 23px;
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      .icon-edit:before,.icon-save:before,.icon-close2:before {
        color: $color-jungle-green;
      }
      &:hover {
        .icon-edit:before,.icon-save:before,.icon-close2:before {
          color: $color-blue-custom;
        }
      }
    }
  }
  .group-flex{
    display: flex;
    .group-wallet{
      margin-right: 15px;
      margin-top: 47px;
      border: none;
      .dropdown{

        button {
          border: none;
          display: flex;
          color: #333333;
          height: 40px;
          background: #ffffff;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.54;
          min-width: 201px;
          justify-content: space-between;
          .paddingt-2{
            padding-top: 2px;
            span.icon-arrow1 {
              margin-left: 23px;
              color : #2dac91;
            }
          }
        }
        ul.dropdown-menu{
          min-width: 201px;
          .tab-element{
            text-align: left;
            min-height: 35px;
            padding-top: 7px;
            padding-left: 10px;
            &:hover{
              background: #2dac91;
              color: white;
            }
          }
        }
      }
    }
  }
  .title-medium-small {
    color: var(--dark-1);
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 43px;
  }
  .white-list-height {
    line-height: 23px;
  }
  .account_container {
    display: inline-block;
    width: 100%;
    padding: 0px;
    background-color: $color-alabaster;
  }
  .path_acount_container {
    display: flex;
    margin-top: 4px;
    gap: 4px;
  }
  .path_leaderboard {
    margin-top: 40px;
    .content_pick_leaderboard {
      margin-top: 15px;
      background-color: $color-white;
      padding: 15px;
      font-weight: 500;
      font-size: $font-small;
      color: $color-grey-dusty;
      line-height: 23px;
    }
  }
  .path_address_container {
    display: flex;
    width: 100%;
    height: 300px;
    margin-top: 15px;
  }
  .path_device_management {
     width: 100%;
     margin-top: 60px;
  }
  .path_last_login {
    display: inline-block;
    width: 100%;
    margin-top: 55px;
    table{
      thead{
        tr{
          th{
            font-size: $font-smaller;
          }
        }
      }
      tbody{
        tr{
          td{
            font-size: $font-small;
          }
        }
      }
    }
  }
  .acount_info {
    overflow: hidden;
    width: 100%;
    margin-bottom: 65px;
    .left_acc_info {
      width: 475px;
      max-width: 50%;
      margin-right: -1px;
      padding-right: 5px;
      padding-bottom: 10px;
      .sub_acc_info{
        display: block;
        overflow: hidden;
        padding-bottom: 8px;
      }
      .email_acc{
        margin-bottom: 12px;
        font-size: $font-medium-bigger;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-black;
      }
    }
    .right_acc_info {
      width: calc(100% - 475px);
      border-left: 1px solid $color-alto;
      min-width: 50%;
      padding-bottom: 10px;
    }
  }
  .login_password {
    width: 100%;
    background-color: transparent;
  }
  .ni_bank_acc{
    width: 20.5%;
    min-width: 236px;
    height: 100%;
    margin-right: 15px;
    background-color: $color-white;
    text-align: center;
    .icon-bank{
      font-size: 60px;
      width: 60px;
      height: 60px;
      margin-top: 80px;
      margin-bottom: 20px;
      color: $color-grey-sliver;
    }
    .text_bank{
      font-size: $font-big-20;
      line-height: 22px;
      color: $color-black;
      font-weight: 600;
      margin-bottom: 9px;
    }
    .butt_unver_bank{
      background: $color-blue-cerulean-light;
      color: $color-white;
      font-size: $font-small;
      font-weight: 700;
      height: 21px;
      line-height: 21px;
      padding: 0px 11px;
      border-radius: 15px;
      margin-bottom: 25px;
    }
    .butt_verify_bank{
      width: 110px;
      height: 32px;
      border: 1px solid $color-yellow;
      color: $color-yellow;
      font-size: $font-small;
      font-weight: 700px;
      text-align: center;
      padding: 5px 10px;
      line-height: 22px;
      background: transparent;
      border-radius: 20px;
      font-weight: 700;
      margin-bottom: 47px;
    }
    .error_bank_acc{
      color: $color-red-warning;
      font-size: $font-small;
      line-height: 20px;
      width: 165px;
      text-align: center;
    }
  }
  .google_authen {
    width: 100%;
    height: 100%;
    background-color: transparent;
    text-align: center;
    .form-check-label {
      margin-top: 18px;
      a {
        font-size: $font-small;
      }
    }
  }
  .background_white {
    background-color: transparent;
  }
  .address_management {
    width: 100%;
    background-color: transparent;
    min-height: 270px;
  }
  .referral_program {
    width: 100%;
    background-color:transparent;
  }
  .text_title_table {
    color: $color-eden;
    margin-bottom: 15px;
  }
  .table_descripe {
    padding-left: 11px;
    color: $color-grey-dusty;
  }
  .device_table {
    width: 100%;
    height: 100px;
    background-color: $color-white;
  }
  .login_table {
    width: 100%;
    height: 250px;
    background-color: $color-white;
  }
  .fs11{
    font-size: $font-mini;
  }
  .fs12 {
    font-size: $font-smaller;
  }
  .fs13 {
    font-size: $font-small;
  }
  .fs20 {
    font-size: $font-big-20;
  }
  .img-user {
      width: 110px;
      height: 110px;
      margin: auto;
      margin-right: 25px;
      display: block;
  }
  .lv_content{
    width: auto;
    display: inline-block;
    height: 24px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
    max-width: 100%;
  }
  .rejected {
    width: 300px;
  }
  .btn_common {
    border-radius: 3px;
    padding-left: 17px;
    padding-right: 17px;
    width: auto;
    min-width: 85px;
    text-align: center;
    height: 23px;
    display: flex;
    align-items: center;
    color: $color-white;
    text-transform: uppercase;
  }
  .lv_text {
    display: flex;
    align-items: center;
    height: 23px;
    width: 40px;
    color: $color-corn-pale;
    font-size: 16px;
  }
  .btn_unverified {
    background-color: $color-eden;
    color: $color-white;
    margin-right: 5px;
    cursor: pointer;
    width: 90px;
    line-height: 20px;
    padding: 3px;
    text-align: center;
  }
  .btn_verified {
    background-color: $color-curious-blue;
    color: $color-white;
    margin-right: 5px;
    cursor: pointer;
  }
  .btn_pending {
    background-color: $color-corn-pale;
    color: $color-white;
    margin-right: 5px;
    cursor: pointer;
  }
  .btn_rejected {
    background-color: $color-thunderbird;
    color: $color-white;
    margin-right: 5px;
    cursor: pointer;    
  }
   .btn_general {
    background-color: $color-grey;
  }
  .margin_auto {
    margin: auto;
  }
  .text_acount {
    color: $color-grey-dusty;
    font-size: $font-small;
    line-height: 20px;
    .text_discount {
      color: $color-grey-dark;
    }
  }
  .withdraw_limit_map {
  }
  .submit_contact {
    font-size: $font-small;
    color: $color-yellow;
    height: 20px;
    margin-top: 10px;
    text-align: center;
    padding-left: 52px;
    div {
      height: 25px !important;
    }
  }
  .submit {
    width: 200px;
    color: $color-curious-blue;
  }
  .border_bot {
    border-bottom: 1px solid $color-yellow;
  }
  .contact_us {
    width: 100px;
    color: $color-curious-blue;
    margin-right: 18px;
  }
  .h-18 {
    height: 18px;
  }
  .icon-login-password {
      font-size: 50px;
      width: 50px;
      height: 50px;
      color: $color-grey-sliver;
  }
  .icon-referral-program {
    font-size: 50px;
    width: 50px;
    height: 50px;
    color: $color-grey-sliver;
  }
  .icon-shield {
      font-size: 50px;
      width: 50px;
      height: 50px;
      color: $color-grey-sliver;
  }
  .text_login {
    font-size: $font-medium-bigger;
    font-weight: bold;
    line-height: normal;
    color: $color-black;
  }
  .btn_account {
    height: 35px;
    margin-right: 15px;
    width: 110px;
    text-align: center;
    border-radius: 3px;
    font-weight: 600;
    border: 1px solid $color-jungle-green;
    color: $color-white;
    font-size: $font-smaller;
    text-transform: uppercase;
    display: inline-block;
    padding: 7px 0px;
    line-height: 20px;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: $color-jungle-green;
    &:hover, &:active, &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
      outline: none;
    }
  }
  .btn_border {
    background-color: transparent;
    color: $color-jungle-green;
    &:hover, &:active, &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
    }
  }
  .btn_info {
    background-color: $color-curious-blue;
    border-color: $color-curious-blue;
    &:hover, &:active, &:focus {
      background-color: $color-blue-custom-2;
      border-color: $color-blue-custom-2;
      outline: none;
    }
  }
  .marr10 {
    margin-right: 10px;
  }
  .referral_program {
    .btn_active {
      width: 150px;
    }
  }
  .text_white {
    color: $color-white;
  }
  .text_yellow {
    color:  $color-yellow;
  }
  .icon-note {
    font-size: $font-big-20;
    width: 20px;
    height: 20px;
  }
.tooltip-order {
  width: 200px;
  height: 200px;
  background-color: $background-default;
  z-index: 1000;
  border: 1px solid $color-grey-border;
  position: absolute;
  border-radius: 15px;
  color: $color-grey-dark;
  font-weight: normal;
  font-size: $font-small;
  box-shadow: 1px 1px 1px 1px $color-grey-border;
  line-height: 20px;
}
.text-tooltip {
  margin: 10px 10px 10px;
}
.road_lv {
  width: 100%;
  padding: 0px 0px 0px 50px;
  overflow: hidden;
  padding-bottom: 5px;
  .item_road{
    text-align: center;
    &:last-child{
      .icon-diamond-1{
        margin-right: 0px;
        text-align: right;
      }
    }
    &:first-child{
      .icon-diamond-1{
        margin-left: 0px;
        text-align: center;
      }
    }
    .text_milestone{
      font-size: $font-small;
      color: $color-grey-dusty;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 15px;
      .nb_read_item{
        color: $color-grey-dark;  
        font-size: $font-root;
        font-weight: 600;
      }
    }
  }
}
  .milestone {
    width: 100%;
    border-bottom: 2px solid $color-eden;
  }
  .title_step {
    width: 100%;
  }
  .icon-diamond-1 {
    margin: auto;
    text-align: center;
    margin-top: 4px;
    font-size: $font-big-20;
    position: relative;
    padding-bottom: 6px;
    color: $color-eden;
    width: 100%;
    div {
      width: 100%;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    strong{
      font-size: $font-medium-bigger;
      color: $color-eden;
      font-weight: 600;
      padding-left: 5px;
      padding-left: 5px;
      font-size: $font-medium-bigger;
      line-height: normal;
      color: $color-eden;
    }
    strong.step_active {
      color: $color-corn-pale;
    }
  }
  .right_list_option_acc{
    background-color: $color-white;
    flex: 1;
    .contents_setting {
      padding: 36px 43px;
      .contents_setting_container {
        display: flex;
        gap: 28px;
        &.setting_border-bottom {
          border-bottom: 2px solid $color-white-alto;;
        }
        .text-main {
          font-size: 20px;
          line-height: 24px;
          color: var(--dark-1);
        }
        .contents_setting_icon {
          width: 52px;
        }
        .preferences_group {
          flex: 1;
          .check_authen_activation  {
            font-size: 16px;
            line-height: 20px;
            color: var(--dark-2);
          }
          .group-text-main {
            margin-bottom: 43px;
          }
          .group {
            margin-bottom: 30px;
            .group_center_option {
              display: flex;
              .text-mb {
                margin-bottom: 5px;
              }
              .center_group {
                flex: 1;
              }
              .group_btn {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .group_status {
                  display: flex;
                  gap: 10px;
                  align-items: flex-start;
                  .text-status {
                    font-size: 18px;
                    line-height: 21px;
                    color: var(--dark-1);
                    max-width: 245px;
                  }
                }
                .group_btn-btn {
                  .btn {
                    border-radius: 8px;
                    padding: 14px 20px;
                    font-size: 18px;
                    line-height: 21px;
                    min-width: 140px;
                    &.btn_disable {
                      color: var(--dark-1);
                      background: $color-gray-alto;
                    }
                    &.btn_enable {
                      color: $color-white;
                      background: $text-color-jade;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .link_account{
    font-size: $font-small;
    font-weight: 500;
    margin: 0px;
    margin-top:6px;
    margin-bottom:20px;
    color: $color-grey-dusty;
    line-height: 20px;
    display: block;
    a{
      color: $color-curious-blue;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .link_button{
    color: $color-curious-blue;
  }

  .right_withdrawal_acc{
    display: block;
    width: calc(100% - 110px - 70px - 15px);
    position: relative;
    line-height: 20px;
    font-size: $font-small;
    color: $color-grey-dusty;
  }
  .switch{
    margin-bottom: 0px;       
    input:checked + span{
      background-color: $color-jungle-green;
    }
    span{
      background-color: #d8d8d8;
    }    
  }
  .icon_if_w{
    width: 18px;
    height: 18px;
    font-size: $font-big;
    color: $color-alizarin-crimson
  }
  .btn_active{
    height: 35px;
    max-width: 100%;
    width: 246px;
    margin-right: 15px;
    text-align: center;
    background-color: $color-jungle-green;
    border-radius: 3px;
    font-weight: 600;
    color: $color-white;
    font-size: $font-smaller;
    text-transform: uppercase;
    display: inline-block;
    padding: 8px 0px;
    line-height: 20px;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &:hover, &:active, &:focus{
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      outline: none;
    }
  }
  .step_active {
    color: $color-corn-pale;
    &:after{
      background: $color-corn-pale;
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0px;
      bottom: -3px;
    }
  }
  .no-shadow {
    i {
      box-shadow: none !important;
    }
  }
  .link_referral {    
    a {
      text-decoration: none !important;
    }
  }
  .mgb0{
    margin-bottom: 0px;
  }
</style>
