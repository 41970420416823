<template>
  <div class="clearfix">
    <div class="about_footer_landing f-wrap" :class="{ is_landing: isLanding }">
      <div class="path_footer col-sm-12 col-lg-2 col-xl-3 col-12 header">
        <div class="group_logo_ft clearfix">
          <router-link class="link_logo_ft" to="/">
            <img
              class="img-logo"
              alt="logo"
              height="26px"
              :src="
                require(`@/assets/images/logo-${
                  userTheme === 'light-theme' ? 'dark' : 'white2'
                }.svg`)
              "
            />
          </router-link>

          <div class="description">Smart choice on your future</div>
          <!-- <div class="copyright" v-html="siteSetting.copyright">
          </div> -->
        </div>
        <div class="path_footer social-network">
          <a
            v-for="(socicalNetwork, index) in socicalNetworks"
            :href="socicalNetwork.link"
            :title="socicalNetwork.name"
            :key="index"
            target="_blank"
            class="icon-social"
          >
            <img
              :src="
                require(`@/assets/images/icon/social/${socicalNetwork.icon_class}.svg`)
              "
            />
          </a>
        </div>
      </div>
      <!-- <div class="copyright width-sm" v-html="siteSetting.copyright">
      </div> -->
      <div class="row g-0 flex-1">
        <div class="path_footer col">
          <li class="item item_title" @click="checkActive(1)">
            <div>
              {{ $t("menu_bottom.about_us") }}
            </div>
            <img
              v-if="sizeScreen < 768"
              class="dropdown-icon"
              :class="active === 1 ? 'active' : ''"
              :src="
                require(`@/assets/images/icon/arrow-${
                  userTheme === 'light-theme' ? 'down-black' : 'down-black'
                }.svg`)
              "
            />
          </li>
          <div
            class="list_menu_ft clearfix"
            v-if="(active === 1 && sizeScreen < 768) || sizeScreen > 767"
          >
            <li>
              <a class="item" href="/about">{{ $t("menu_bottom.about_us") }}</a>
            </li>
            <!-- <li>
              <a class="item" :href="url_announcement" target="_blank">{{
                $t("menu_bottom.announcements")
              }}</a>
            </li> -->
          </div>
        </div>
        <div class="path_footer col">
          <li class="item item_title" @click="checkActive(2)">
            <div>
              {{ $t("menu_bottom.products") }}
            </div>
            <img
              v-if="sizeScreen < 768"
              class="dropdown-icon"
              :class="active === 2 ? 'active' : ''"
              :src="
                require(`@/assets/images/icon/arrow-${
                  userTheme === 'light-theme' ? 'down-black' : 'down-black'
                }.svg`)
              "
            />
          </li>
          <div
            class="list_menu_ft clearfix"
            v-if="(active === 2 && sizeScreen < 768) || sizeScreen > 767"
          >
            <li>
              <router-link
                class="item"
                :to="{
                  name: 'Spot Exchange',
                  query: { coin: 'btc', currency: 'usdt' },
                }"
                >{{ $t("menu_bottom.spot_trade") }}</router-link
              >
            </li>
            <!-- <li>
              <router-link class="item" to="/convert">{{
                $t("menu.convert")
              }}</router-link>
            </li> -->
            <li>
              <router-link
                class="item"
                :to="{
                  name: 'Margin Future',
                  params: { coin: `futures/USD_M` },
                }"
                >{{ $t("footer.futures-usdm") }}</router-link
              >
            </li>
            <li>
              <router-link
                class="item"
                :to="{
                  name: 'Margin Future',
                  params: { coin: `futures/COIN_M` },
                }"
                >{{ $t("footer.futures-coinm") }}</router-link
              >
            </li>
            <!--          <li><router-link class="item" to="#">{{$t('menu_bottom.earn')}}</router-link></li>-->
            <!-- <li v-if="sizeScreen > 576" class="item item_title contact">{{$t('menu_bottom.contact')}}</li>
          <div  v-if="sizeScreen > 576" class="list_menu_ft clearfix">
            <li><span class="item email" to="#" >{{siteSetting.contact_email}} </span></li>
          </div> -->
          </div>
        </div>
        <div class="path_footer col">
          <li class="item item_title" @click="checkActive(3)">
            <div>
              {{ $t("menu_bottom.services") }}
            </div>
            <img
              v-if="sizeScreen < 768"
              class="dropdown-icon"
              :class="active === 3 ? 'active' : ''"
              :src="
                require(`@/assets/images/icon/arrow-${
                  userTheme === 'light-theme' ? 'down-black' : 'down-black'
                }.svg`)
              "
            />
          </li>
          <div
            class="list_menu_ft clearfix"
            v-if="(active === 3 && sizeScreen < 768) || sizeScreen > 767"
          >
            <li @click="goTop">
              <router-link class="item" to="/account/referral">{{
                $t("menu_bottom.referral-program")
              }}</router-link>
            </li>
            <li @click="goTop">
              <router-link class="item" to="/account/reward-center">{{
                $t("menu.user.reward_center")
              }}</router-link>
            </li>
          </div>
        </div>
        <div class="path_footer col">
          <li class="item item_title" @click="checkActive(4)">
            <div>
              {{ $t("menu_bottom.support") }}
            </div>
            <img
              v-if="sizeScreen < 768"
              class="dropdown-icon"
              :class="active === 4 ? 'active' : ''"
              :src="
                require(`@/assets/images/icon/arrow-${
                  userTheme === 'light-theme' ? 'down-black' : 'down-black'
                }.svg`)
              "
            />
          </li>
          <div
            class="list_menu_ft clearfix"
            v-if="(active === 4 && sizeScreen < 768) || sizeScreen > 767"
          >
            <li>
              <a class="item" :href="url_faq">{{
                $t("menu_bottom.FAQ")
              }}</a>
            </li>
            <li>
              <a class="item" :href="url_support">{{
                $t("menu_bottom.support_center")
              }}</a>
            </li>
            <!-- <li>
              <a class="item" :href="url_fees" target="_blank">{{
                $t("menu_bottom.fees")
              }}</a>
            </li> -->
            <li>
              <a
                class="item"
                @click="gotoAPIDocs()"
                target="_blank"
                rel="noopener noreferrer"
                >{{ $t("menu_bottom.api_document") }}</a
              >
            </li>
          </div>
        </div>
        <div class="path_footer col">
          <li class="item item_title" @click="checkActive(5)">
            <div>
              {{ $t("menu_bottom.legal") }}
            </div>
            <img
              v-if="sizeScreen < 768"
              class="dropdown-icon"
              :class="active === 5 ? 'active' : ''"
              :src="
                require(`@/assets/images/icon/arrow-${
                  userTheme === 'light-theme' ? 'down-black' : 'down-black'
                }.svg`)
              "
            />
          </li>
          <div
            class="list_menu_ft clearfix"
            v-if="(active === 5 && sizeScreen < 768) || sizeScreen > 767"
          >
            <li>
              <a class="item" href="/terms">{{ $t("menu_bottom.term") }}</a>
            </li>
            <li>
              <a class="item" href="/policy">{{
                $t("menu_bottom.privacy_policy")
              }}</a>
            </li>

            <li v-if="sizeScreen > 576" class="item item_title contact">
              {{ $t("menu_bottom.contact") }}
            </li>
            <div v-if="sizeScreen > 576" class="list_menu_ft clearfix">
              <li>
                <span class="item email" to="#"
                  >
                  {{ siteSetting.contact_email }}
                </span>
              </li>
            </div>
          </div>
        </div>
        <div
          v-if="sizeScreen < 576"
          class="path_footer col-sm-4 col-xl-2 col-lg-2 col-6"
        >
          <p class="title_contact">{{ $t("menu_bottom.contact") }}</p>
          <p class="item_email" to="#">{{ emailContact }}</p>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="footer_bottom">
      <div>
        2024 © client.dikek.com
        <!-- {{ siteSetting.copyright }} -->
      </div>
      <div class="bottom-right">
        <div
          tabindex="11"
          class="bar f-fr lang-bar dropdown-block btn-responsive"
          @focus="lang_bar = true"
          @blur="lang_bar = false"
          @mouseover="
            sizeScreen > 576 ? (lang_bar = mouseOverDetectScreen(lang_bar)) : ''
          "
          @click="lang_bar = !lang_bar"
          @mouseleave="lang_bar = false"
        >
          <button class="btn-lang">
            <div>
              <img
                class="dropdown-active"
                v-if="userTheme === 'light-theme'"
                :src="require(`@/assets/images/icon/globe-black.svg`)"
              />
              <img
                class="dropdown-active"
                v-else
                :src="require(`@/assets/images/icon/globe-white.svg`)"
              />
            </div>

            <div>
              <span
                v-if="this.$i18n.locale == 'en'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.acr.en_2") }}</span
              >
              <span
                v-if="this.$i18n.locale == 'ko'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.acr.ko_2") }}</span
              >
              <span
                v-if="this.$i18n.locale == 'vi'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.acr.vi_2") }}</span
              >

              <span
                v-if="this.$i18n.locale == 'zh'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.zh") }}</span
              >
              <span
                v-if="this.$i18n.locale == 'ja'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.ja") }}</span
              >

              <span
                v-if="this.$i18n.locale == 'de'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.acr.de_2") }}</span
              >
              <span
                v-if="this.$i18n.locale == 'es'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.acr.es_2") }}</span
              >
              <span
                v-if="this.$i18n.locale == 'fr'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.acr.fr_2") }}</span
              >
              <span
                v-if="this.$i18n.locale == 'id'"
                :class="lang_bar ? 'active' : ''"
                >{{ $t("menu.lang.acr.id_2") }}</span
              >
            </div>
          </button>

          <div class="dropdown-head">
            <ul class="subLang" v-show="lang_bar">
              <li>
                <a @click="updateUserLocale('en')">
                  <span>{{ $t("menu.lang.acr.en_2") }}</span>
                  <div class="img-link" />
                </a>
              </li>

              <li>
                <a @click="updateUserLocale('vi')">
                  <span>{{ $t("menu.lang.acr.vi_2") }}</span>
                  <div class="img-link" />
                </a>
              </li>
              <li>
                <a @click="updateUserLocale('zh')">
                  <span>{{ $t("menu.lang.zh") }}</span>
                  <div class="img-link" />
                </a>
              </li>
              <li>
                <a @click="updateUserLocale('ja')">
                  <span>{{ $t("menu.lang.ja") }}</span>
                  <div class="img-link" />
                </a>
              </li>
              <li>
                <a @click="updateUserLocale('de')">
                  <span>{{ $t("menu.lang.acr.de_2") }}</span>
                  <div class="img-link" />
                </a>
              </li>

              <li>
                <a @click="updateUserLocale('es')">
                  <span>{{ $t("menu.lang.acr.es_2") }}</span>
                  <div class="img-link" />
                </a>
              </li>

              <li>
                <a @click="updateUserLocale('fr')">
                  <span>{{ $t("menu.lang.acr.fr_2") }}</span>
                  <div class="img-link" />
                </a>
              </li>

              <li>
                <a @click="updateUserLocale('id')">
                  <span>{{ $t("menu.lang.acr.id_2") }}</span>
                  <div class="img-link" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="currency-box">
          <div>
            <img :src="require(`@/assets/images/vnd.svg`)" />
          </div>
          <div>VND</div>
        </div>
        <div
          tabindex="10"
          class="bar f-fr dropdown-block"
          @focus="nav_page_bar = true"
          @blur="nav_page_bar = false"
          @mouseover="nav_page_bar = false"
          @click="nav_page_bar === true"
          @mouseleave="nav_page_bar = false"
        >
          <input
            @change="toggleTheme"
            id="checkbox"
            type="checkbox"
            class="switch-checkbox"
          />
          <label for="checkbox" class="switch-label-layout btn-lang">
            <img
              :src="
                require(`@/assets/images/icon/${
                  userTheme === 'light-theme' ? 'moon-light' : 'sun-light'
                }.svg`)
              "
            />
            <span>{{
              userTheme === "light-theme" ? "Dark mode" : "Light mode"
            }}</span>
          </label>
        </div>
      </div>
    </div>
    <!-- <div id="footer_bottom_all" class="clearfix">
      <div class="about_footer clearfix">

        <button class="btn-go-top" @click="goTop"><i class="icon-arrow5"></i></button>

      </div>
    </div> -->
  </div>
</template>

<script>
import Const from "@/common/Const";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import rf from "@/request/RequestFactory";
import ExchangeFooter from "@/components/salespoint_pages/exchange/basic/ExchangeFooter.vue";
import { mapState } from "vuex";
import Cookies from "js-cookie";

export default {
  components: {
    ExchangeFooter,
  },
  data() {
    return {
      visibleLangueges: false,
      languages: Const.LANGUAGES,
      socicalNetworks: {},
      siteSetting: {},
      lang_bar: false,
      isAuthenticated: window.isAuthenticated,
      url_announcement: process.env.VUE_APP_ZENDESK_ANNOUNCEMEN,
      url_support: '/qna',
      url_faq: '/faq',
      url_fees: process.env.VUE_APP_ZENDESK_FEES,
      sizeScreen: window.innerWidth,
      userTheme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
      active: 0,
      masterDataChange: false,
    };
  },
  props: {
    miniFooter: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
      userThemeMode: (state) => state.userThemeMode,
    }),
    isExchangePage() {
      return this.$route.path === PathRoot.path;
    },
    isLanding() {
      return this.$route.path === "/";
    },
    emailContact() {
      return this.siteSetting?.contact_email;
    },
  },
  methods: {
    getWhitePaper(locale = "en") {
      let url = process.env.VUE_APP_API_URL + "/white-paper/" + locale;
      return url;
    },

    checkActive(i) {
      console.log("i", i);
      if (i === this.active) {
        this.active = 0;
      } else {
        this.active = i;
      }
    },
    gotoUrl(url) {
      window.location.href = url;
    },

    gotoAPIDocs() {
      let url = process.env.VUE_APP_API_URL + "/api/documentation";
      window.open(url);
      // this.gotoUrl(url);
    },
    goTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    updateUserLocale(locale) {
      console.log("locale", locale);
      AuthenticationUtils.setLocale(locale);

      let params = {
        lang: locale,
      };
      this.$i18n.locale = locale;
      Cookies.set("locale", locale, { domain: process.env.VUE_APP_PUBLIC_URL });
      window.i18n.locale = locale;
      this.$store.dispatch("setLang", locale);

      // if (this.isAuthenticated) {
      //   rf.getRequest('LocaleRequest').setUserLocale(params);
      // }
      if (this.isAuthenticated) {
        rf.getRequest("LocaleRequest")
          .setUserLocale(params)
          .then((res) => {
            if (res) {
              window.location.reload();
            }
          });
      }
    },

    convertSiteSetting(data) {
      window._.each(data, (item) => {
        this.siteSetting[item.key] = item.value;
        this.$forceUpdate();
      });
    },

    getMasterdata() {
      this.convertSiteSetting(this.masterdata?.settings);
      this.socicalNetworks =
        window._.filter(
          this.masterdata?.social_networks,
          (item) => item.is_active
        ) || {};
    },

    mouseOverDetectScreen(value) {
      if (this.sizeScreen > 1300) {
        return (value = true);
      }
    },

    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
    toggleTheme() {
      const activeTheme = Cookies.get("user-theme", {
        domain: process.env.VUE_APP_PUBLIC_URL,
      });
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
        this.$store.dispatch("setUserThemeMode", "dark-theme");
      } else {
        this.setTheme("light-theme");
        this.$store.dispatch("setUserThemeMode", "light-theme");
      }
    },

    getTheme() {
      return Cookies.get("user-theme", {
        domain: process.env.VUE_APP_PUBLIC_URL,
      });
    },

    setTheme(theme) {
      Cookies.set("user-theme", theme, {
        domain: process.env.VUE_APP_PUBLIC_URL,
      });
      Cookies.set("locale", Cookies.get("locale") || "en", {
        domain: process.env.VUE_APP_PUBLIC_URL,
      });
      this.userTheme = theme;
      this.$store.dispatch("setUserThemeMode", theme);
      document.documentElement.className = theme;
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
  watch: {
    masterdata() {
      this.$nextTick(() => {
        this.convertSiteSetting(this.masterdata?.settings);
      });
    },
    userThemeMode(theme) {
      this.userTheme = theme;
    },
  },
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    console.log("initUserTheme", initUserTheme);
    this.setTheme(initUserTheme);
  },
  created() {
    this.getMasterdata();
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.flex-1 {
  flex: 1;
}
.row {
  width: 100%;
}
.list_link_ft {
  float: left;
  margin-top: 5px;
  a {
    font-size: $font-big-19;
    margin-right: 19px;
    &:hover,
    &:active,
    &:focus {
      color: $color-white;
    }
  }
}
.clearfix-20 {
  height: 20px;
  clear: both;
}
.line-break {
  float: left;
  width: 100%;
  padding-top: 50px;
}
.line-break hr {
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background-color: $color-white;
  margin: 0px;
}
.list_menu_ft {
  text-align: left;
  li {
    @media screen and (max-width: 767px) {
      padding-left: 24px;
    }
    .item {
      font-family: $font-notosans-medium;
      font-size: 14px;
      line-height: 22px;
      color: $color-suva-gray;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
      display: inline-block;
      cursor: pointer;
      display: block;
      padding-bottom: 10px;
    }
    &:hover {
      color: $color-aquamarine;
    }
    .email {
      cursor: unset !important;
      display: inline-block;
    }

    &:last-child .item{
      padding-bottom: 0px;
    }
  }
}

li {
  width: 100%;
  //float: left;
  display: block;
  color: var(--color-menu);
  &.item_title {
    font-family: $font-notosans-medium;
    font-size: 18px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    display: inline-block;
    margin-bottom: 24px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      margin-bottom: 14px;
    }

    .dropdown-icon {
      transition: transform 0.4s ease;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  &.contact {
    margin-top: 24px;
    padding: 0;
  }
}

.dark-theme {
  .group_logo_ft {
    .description {
      color: white !important;
    }
  }
}
.group_logo_ft {
  margin-bottom: 20px;
  .logo_ft {
    float: left;
    margin-right: 3px;
  }

  .description {
    color: #57585c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}
.about_footer_landing {
  padding: 60px 24px 36px;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  .path_footer {
    &.header {
      padding-left: 0;
    }
    .social-network {
      display: flex;
      gap: 12px;
      img {
        height: 20px;
      }

      a {
        padding: 8px;
        background-color: #282828;
        border-radius: 100%;
      }
    }
    &.mr-5 {
      margin-right: 5%;
    }
    .copyright {
      font-size: 16px;
      line-height: 19px;
      color: var(--color-footer);
    }
    .img-logo {
      margin: 0;
      margin-bottom: 6px;
    }
    &.fl-right {
      max-width: 100%;
      float: right;
    }
  }
  .width-sm {
    display: none;
  }
  .path_footer_1 {
    width: 33.33%;
  }
  &.is_landing {
    path_footer-2 {
      width: 20%;
    }
  }
  .lang-bar {
    display: inline-block;
    float: left;
    position: relative;
    .btn-lang-head {
      border-radius: 3px;
      border: solid 1px $color-white;
      height: 35px;
      background-color: transparent;
      min-width: 85px;
      color: $color-alto;
      font-family: $font-family-roboto;
      .icon-arrow1 {
        font-size: 10px;
        float: right;
        line-height: 24px;
        margin-left: 7px;
      }
    }
    .dropdown-head {
      position: absolute;
      left: 0px;
      top: 100%;
      min-width: 100%;
      .subLang {
        width: 100%;
        li {
          background-color: $color-eden-dark;
          width: 100%;
          a {
            display: block;
            width: 100%;
            line-height: 20px;
            padding: 10px 13px;
            border-bottom: 1px solid $color-eden;
          }
          &:first-child {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
          }
          &:last-child {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
          }
          &:hover {
            background-color: $color-jungle-green-light;
            a {
              color: $color-white;
            }
          }
        }
      }
    }
  }
}
.line_ft {
  width: 100%;
  height: 1px;
  display: block;
  background-color: rgba(255, 255, 255, 0.1);
}
.social-network-right {
  float: right;
  .fl-right {
    width: 100px;
  }
}
.footer_bottom {
  width: 1200px;
  padding: 9px 24px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--color-border);
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 24px;
    padding-bottom: 37px;
    padding-top: 12px;
  }
  .bottom-right {
    display: flex;
    gap: 24px;
  }
  .btn-lang {
    display: flex;
    background: transparent;
    color: var(--color-menu);
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 11px 0;
    font-weight: 500;
  }
}
.lang-bar {
  position: relative;
}
.dropdown-head {
  position: absolute;
  bottom: 100%;
  margin: 0px;
  right: -25px;
  z-index: 10;
  min-width: 250px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  z-index: 2100;
  @media screen and (max-width: 768px) {
    right: auto;
  }
  &.right-side {
    left: 0;
    > .tradeTab {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: 230px;
      > .arrow-right {
        float: right;
      }
    }
  }
  &.dropdown-large {
    left: 0;
    min-width: 280px !important;
    @media screen and (max-width: 1330px) {
      min-width: fit-content;
      .sub-child {
        margin-top: 0;
        margin-right: 5px;
      }
    }
    .subFunds {
      width: 100%;
    }
    .sub-child {
      padding: 0;
    }
  }
  &.user-dropdown {
    right: -25px;
    left: auto;
  }
  > ul {
    padding: 15px;
    margin-top: 3px;
    background-color: var(--background-color-primary);
    border-radius: 8px;
    color: $pure_color_black;
    overflow: hidden;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
    width: 100%;
    li {
      display: block;
      width: 100%;
      color: var(--text-primary-color);
      a {
        display: flex !important;
        align-items: center;
        span {
          font-family: $font-inter-regular;
          color: var(--text-primary-color);
          font-size: $font-medium-bigger;
          margin-left: 10px;
          width: 100%;
          @media screen and (max-width: 1300px) {
            font-size: $font-root;
          }

          &.icon-arrow {
            width: fit-content;
            > img {
              float: right;
            }
          }
        }
      }
      &:hover {
        background-color: var(--hover-item-nav);
        border-radius: 8px;
        a {
          span {
            color: var(--text-primary-color);
          }
          & div {
            //background-image: url("@/assets/images/icon/arrow-right.svg") ;
            background-repeat: no-repeat;
            width: 32px;
            height: 15px;
          }
        }
      }
      &:last-child {
        border-bottom: 0;
      }
      a {
        display: block;
        width: 100%;
        font-size: $font-small;
        color: $color-white;
        line-height: 20px;
        padding: 10px 13px;
      }
    }
  }
  .subLang {
    width: 100%;
  }
}
#footer_bottom_all {
  background-color: var(--background-footer);
  .about_footer {
    padding: 22px 25px 25px 25px;
    .list_link {
      margin-top: 5px;
      a.icon {
        display: inline-block;
        float: left;
        margin-right: 5px;
        width: 30px;
        height: 30px;
        line-height: 20px;
        padding: 5px;
        font-size: $font-big-20;
        color: $color-bali-hai;
        border-radius: 3px;
        &:hover {
          color: $color-white;
        }
      }
    }
    .right_footer_ex {
      margin-top: 5px;
      float: right;
      font-size: $font-small;
      color: $color-bali-hai;
      line-height: 30px;
    }
  }
}
.btn-go-top {
  display: block;
  width: 40px;
  height: 40px;
  float: right;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
  /* background-color: $color-casal; */
  background-color: $active-bg-color;
  border: $border-bottom-bold;
  color: $color-white;
  font-size: $font-big-20;
  padding: 3px 0px;
  border: 0px;
  margin-left: 17px;
  &:hover {
    background-color: $color-caribbean-green;
  }
  &:focus {
    background-color: $active-bg-color;
  }
}

@media screen and (max-width: 1024px) {
  .about_footer_landing {
    padding: 50px 0 0;
    display: flex;
    flex-direction: column;
  }
  .row {
    padding: 10px;
    margin: 0 auto;
    //row-gap: 30px;
  }
  .path_footer {
    .social-network {
      div {
        display: flex;
        flex-direction: row;
      }
    }
    .copyright {
      display: none;
    }
  }
  .copyright {
    display: flex;
    gap: 10px;
    margin-left: 20px;
  }
  .width-sm {
    display: flex !important;
  }
  .social-network-right {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .about_footer_landing {
    padding: 30px 16px;
  }
  .row {
    padding: 2px 0 0px !important;
  }
  .path_footer {
    padding: 0 0px 0px 0px;
    margin-bottom: 14px;
    .social-network {
      //display: flex !important;
      //padding: 0 10px ;
      //gap: 10px;
      padding: 0;
      a {
        margin-right: 10px;
        img {
          margin-bottom: 0px !important;
          width: 24px;
          height: 24px;
        }
      }
      div {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
  }
  .title_contact {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    display: inline-block;
    cursor: pointer;
    color: $color-white;
    margin-bottom: 0px;
  }
  .copyright {
    margin-left: 0px;
    border-bottom: 1px solid $color-gray-charade;
    padding-bottom: 25px;
    p {
      font-size: $font-root;
    }
  }
  li {
    &.item_title {
      font-size: $font-root;
    }
    .item {
      font-size: $font-root !important;
      padding-bottom: 12px;
    }
    &.contact {
      margin-top: 20px;
    }
  }

  .f-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .row {
    padding: 10px;
    margin: 0 auto;
    //row-gap: 20px;
  }
}

@media screen and (max-width: 767px) {
  .row {
    .col {
      flex: 1 0 100%;
    }
  }
}

.currency-box {
  display: flex;
  align-items: center;
  column-gap: 6px;
  color: var(--text-primary-color);
}
</style>
