<template>
    <div id="convert" class="justify-content-center">
      <SuccessfulModal
          v-if="typeConvert === 'market'"
          :coin="currentPair.coin"
          :currency="currentPair.currency"
          :received="reversed ?
          (toValue - toValue * feeTaker / 100) : (toValue - fromValue * priceConvert * feeTaker / 100)"
          :type="reversed"
          :closeModalSuccess="successFullModalClose"
          :order-id="orderId"
          :fromValue="Number.parseFloat(fromValue)"
          ref="SuccessfulModal">
      </SuccessfulModal>
      <ConfirmOrderModal
          v-if="typeConvert === 'limit'"
          :coin="currentPair.coin"
          :currency="currentPair.currency"
          :rate="rate?.toString()" :receive="reversed ?
          (toValue - toValue * feeMaker) : (toValue - toValue * feeTaker)"
          :spent="reversed ? toValue : fromValue"
          :received="reversed ?
          (toValue - toValue * feeMaker) : (toValue - toValue * feeTaker)"
          :type="reversed"
          :fee="reversed ? (feeMaker * toValue / 100).toString(): (feeMaker * fromValue * rate / 100).toString()"
          :currentPair="currentPair"
          :to-value="Number.parseFloat(toValue)"
          :from-value="Number.parseFloat(fromValue)"
          :coin-balance="coinBalance"
          :currency-balance="currencyBalance"
          :coinPairSettings="coinPairSettings"
          ref="ConfirmOrderModal"
          @close-modal="closeModal"
      >
      </ConfirmOrderModal>
      <div :class="{ bgr_mobile: isOpenChartMobile}" ></div>
      <div v-if="isOpenChartMobile && typeConvert === 'limit'" class="chart-mobile" >
        <img style="right: 16px; top: 16px" class="icon-close position-absolute" :src="`/images/icon/icon-close-input${dark}.svg`" @click="isOpenChartMobile = false"/>
        <div class="chart-container" v-if="typeConvert ==='limit'">
          <div class="d-flex coin-convert align-items-center">
          <span class="coin align-items-center d-fex">
             <img v-if="currentPair.imgFrom !== 'usd-img'" width="24" height="24" :src="currentPair.imgFrom">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
             <img v-if="currentPair.imgTo !== 'usd-img'" width="24" height="24" :src="currentPair.imgTo">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
            {{currentPair.name}}
          </span>
            <span class="price_coin">1 {{currentPair.coin}} = {{ priceConvert | formatCurrencyAmount(currentPair.currency, '0') }} {{currentPair.currency}}</span>
          </div>
          <div class="d-flex align-items-baseline" style="margin-bottom: 30px">
            <span class="current-price">{{ priceConvert | formatCurrencyAmount(currentPair.currency, '0') }}</span>
            <span class="current-percent" :class="{'buy' : changePercent > 0, 'sell' : changePercent < 0}">{{changePercent | to2Precision }}%</span>
            <span class="current-24h d-flex justify-content-between w-100">
            <span class="past-24h">{{$t('convert.past-24hours')}}</span>
            <div class="d-flex time-desktop">
              <div class="time-chart" :class="{'time-chart-selected': resolution === 'D'}" @click="resolution = 'D'">24H</div>
              <div class="time-chart" :class="{'time-chart-selected': resolution === 'W'}" @click="resolution = 'W'">1W</div>
              <div class="time-chart" :class="{'time-chart-selected': resolution === 'M'}" @click="resolution = 'M'">1M</div>
            </div>
          </span>
          </div>
          <div class="d-flex time-mobile">
            <div class="time-chart" :class="{'time-chart-selected': resolution === 'D'}" @click="resolution = 'D'">24H</div>
            <div class="time-chart" :class="{'time-chart-selected': resolution === 'W'}" @click="resolution = 'W'">1W</div>
            <div class="time-chart" :class="{'time-chart-selected': resolution === 'M'}" @click="resolution = 'M'">1M</div>
          </div>
          <div class="chart" v-if="currentPair">
            <vue-highcharts :options="chartOptions"></vue-highcharts>
          </div>
        </div>
      </div>
      <div class="chart-container d-none d-xl-block" v-if="typeConvert ==='limit'">
        <div class="title-convert">{{$t('convert.title')}}</div>
        <div class="d-flex coin-convert align-items-baseline">
          <span class="coin">
             <img v-if="currentPair.imgFrom !== 'usd-img'" width="24" height="24" :src="currentPair.imgFrom">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
             <img v-if="currentPair.imgTo !== 'usd-img'" width="24" height="24" :src="currentPair.imgTo">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
            {{currentPair.name}}
          </span>
          <span class="price_coin">1 {{currentPair.coin}} = {{ priceConvert | formatCurrencyAmount(currentPair.currency, '0') }} {{currentPair.currency}}</span>
        </div>
        <div class="d-flex align-items-baseline" style="margin-bottom: 68px">
          <span class="current-price">{{ priceConvert | formatCurrencyAmount(currentPair.currency, '0') }}</span>
          <span class="current-percent" :class="{'buy' : changePercent > 0, 'sell' : changePercent < 0}">{{changePercent | to2Precision }}%</span>
          <span class="current-24h d-flex justify-content-between w-100">
            <span>{{$t('convert.past-24hours')}}</span>
            <div class="d-flex">
              <div class="time-chart" :class="{'time-chart-selected': resolution === 'D'}" @click="resolution = 'D'">24H</div>
              <div class="time-chart" :class="{'time-chart-selected': resolution === 'W'}" @click="resolution = 'W'">1W</div>
              <div class="time-chart" :class="{'time-chart-selected': resolution === 'M'}" @click="resolution = 'M'">1M</div>
            </div>
          </span>
        </div>
        <div class="chart" v-if="currentPair">
          <vue-highcharts :options="chartOptions"></vue-highcharts>
        </div>
      </div>
      <div class="choose-convert position-relative">
        <div class="conversion-history align-items-center d-none d-xl-flex" :class="{'position-absolute': typeConvert === 'market', 'justify-content-end' : typeConvert === 'limit'}">
<!--          ///DRE-1845-->
          <router-link :to="isAuthenticated ? '/convert/history' : '/login'" >
            <button>
                {{$t('menu.history.convert')}}
                <img style="margin-left: 9px" width="18" height="12" :src="require(`@/assets/images/conversion-history-${userThemeMode === 'light-theme' ? 'light' : 'dark'}.svg`)">
              </button>
          
          </router-link>
        
        </div>

        <div class="title-convert justify-content-between text-xl-center text-left"  v-show="typeConvert === 'market'">
          <div>{{$t('convert.title')}}</div>
          <router-link :to="isAuthenticated ? '/convert/history' : '/login'" >
            <img class="d-xl-none d-block" :src="require(`@/assets/images/icon-convert-history.svg`)"/>
          </router-link>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span class="choose-pair-convert">{{$t('convert.choose-pair')}}</span>
          <div class="drop-down-pair position-relative d-flex justify-content-between align-items-center" v-click-outside="clickOutSidePair" >
            <span class="w-100 current-pair" @click="openDropPair=!openDropPair">{{currentPair.name}}</span>
            <i class="icon"  @click="openDropPair=!openDropPair" :class="{'icon-arrow2': openDropPair, 'icon-arrow1':!openDropPair}"></i>
            <div class="position-absolute drop-list-pair" v-show="openDropPair">
              <div class="position-relative text-search-pair">
                <input v-model="pairName" class="w-100 h-100" type="text" v-on:keyup.enter="searchPair"  @focus="focusInput = true" >
                <img
                  class="icon-search position-absolute"
                  :src="require(`@/assets/images/landing/icon-search${dark}.svg`)"
                  width="19"
                  height="19"
                />
                <img v-if="focusInput" class="icon-close position-absolute" :src="`/images/icon/icon-close-input${dark}.svg`" @click="handleDelete"/>
              </div>
              <div ref="listPair" class="list-pair-drop">
                <template v-if="pairShow.length > 0">
                  <div class="list-pair" v-for="pair in pairShow" :key="pair.value" @click="selectPair(pair)">
                    <span>{{pair.name}}</span>
                  </div>
                </template>
                <div v-else class="list-no-data">
                  {{$t('convert.noresult')}}
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="convert-container">
          <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 36px">
            <div class="d-flex">
              <div @click="typeConvert ='market'" class="tab-item" :class="{'tab-selected': typeConvert === 'market'}">{{$t('order.open_order.market')}}</div>
              <div @click="typeConvert ='limit';getRateLimit();" class="tab-item" :class="{'tab-selected': typeConvert === 'limit'}">{{$t('order.order_form.limited_price')}}</div>
            </div>

            <div class="d-flex">
              <img v-if="typeConvert ==='limit'" @click="isOpenChartMobile =!isOpenChartMobile" class="d-xl-none d-block" :src="require(`@/assets/images/icon_chart_convert${dark}.svg`)" alt="" style="margin-right: 16px"/>

              <div class="position-relative information" id="infor">
                <img class="w-100 infor-dark" src="@/assets/images/icon/infor-dark.svg" alt=""/>
                <img class="w-100 infor-light" src="@/assets/images/icon/infor-light.svg" alt=""/>
                <div class="position-absolute content" id="content-infor">
                <span v-if="typeConvert === 'limit'">
                  {{$t('convert.limit-price')}}
                </span>
                  <span v-else>
                  {{$t('convert.instantly')}}
                </span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="typeConvert === 'market'">
            <div class="input-coin-value" :class="{'animate__animated': animate, 'animate__backInLeft': animate && reversed, 'animate__backInRight': animate && !reversed, 'error': errors.has('from')}">
              <div class="title d-flex align-items-center justify-content-between">
                <span>{{ $t("funds.balances.from") }}</span>
                <span>{{ $t("funds.balances.balance") }}:  {{ currencyBalance | formatCurrencyAmount(currentPair[reversed ? 'to' : 'from'], isAuthenticated ? '0': '--') }} {{currentPair[reversed ? 'to' : 'from']}}</span>
              </div>
              <div class="value-input d-flex align-items-center justify-content-between" v-if="currentPair.name">
                <currency-input
                    name="from"
                    :disabled="checkDisabled(currentPair.name, currentPair[reversed ? 'to' : 'from'])"
                    v-model="fromValue"
                    :unit="currentPair[reversed ? 'to' : 'from']?.toLowerCase()"
                    :placeholder="'0.00'"
                    :precision="coinPairSettings.quantityPrecision || 8"
                    :visible-button="true"
                    :maxLengthCus='15'
                    :class="{'disableColors': checkDisabled(currentPair.name, currentPair[reversed ? 'to' : 'from'])}"
                />
                <div class="coin">
                  <img v-if="currentPair[reversed ? 'imgTo' : 'imgFrom'] !== 'usd-img'" width="24" height="24" :src="currentPair[reversed ? 'imgTo' : 'imgFrom']">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
                  {{currentPair[reversed ? 'to' : 'from']}}
                </div>
              </div>
            </div>
            <div class="error-title" v-show="errors.has('from')">{{ $t("convert.field-error-mess") }}</div>
            <div class="w-100 d-flex justify-content-center btn-swap" >
              <img src="@/assets/images/icons/swap-btn.svg"  v-on:click="swapDivs">
            </div>
            <div class="input-coin-value" :class="{'animate__animated': animate, 'animate__backInLeft': animate && !reversed, 'animate__backInRight': animate && reversed, 'error': errors.has('to')}">
              <div class="title d-flex align-items-center justify-content-between">
                <span>{{ $t("funds.balances.to") }}</span>
                <span>{{ $t("funds.balances.balance") }}: {{ coinBalance | formatCurrencyAmount(currentPair[reversed ? 'from' : 'to'], isAuthenticated ? '0': '--') }} {{currentPair[reversed ? 'from' : 'to']}}</span>
              </div>
              <div class="value-input d-flex align-items-center justify-content-between" v-if="currentPair.name">
                <currency-input
                    name="to"
                    :disabled="checkDisabled(currentPair.name, currentPair[reversed ? 'from' : 'to'])"
                    v-model="toValue"
                    :unit="currentPair[reversed ? 'to' : 'from']?.toLowerCase()"
                    :placeholder="'0.00'"
                    :precision="coinPairSettings.quantityPrecision || 8"
                    :visible-button="true"
                    :maxLengthCus='15'
                    :class="{'disableColors': checkDisabled(currentPair.name, currentPair[reversed ? 'from' : 'to'])}"
                />
                <div class="coin">
                  <img v-if="currentPair[reversed ? 'imgFrom' : 'imgTo'] !== 'usd-img'" width="24" height="24" :src="currentPair[reversed ? 'imgFrom' : 'imgTo']">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
                  {{currentPair[reversed ? 'from' : 'to']}}
                </div>
              </div>
            </div>
            <div class="error-title" v-show="errors.has('to')">{{ $t("convert.field-error-mess") }}</div>
            <div class="calculate-convert w-100" v-show="converted && (fromValue>0 && toValue>0)">
              <div class="d-flex w-100 justify-content-between">
                <div class="left d-flex align-items-center">{{ $t("convert.rate") }}
                  <div class="position-relative information" id="infor2" style="margin-left: 5px">
                    <img class="w-100 infor-dark" src="@/assets/images/icon/infor-dark.svg" alt=""/>
                    <img class="w-100 infor-light" src="@/assets/images/icon/infor-light.svg" alt=""/>
                    <div class="position-absolute content" id="content-infor2">
                      {{$t('convert.please-note')}}
                    </div>
                  </div>
                </div>
                <div class="right"> 1 {{currentPair.from}} = {{priceConvert | formatCurrencyAmount(currentPair?.currency?.toLowerCase(), '0')}} {{currentPair.to}}</div>
              </div>
              <div class="d-flex w-100 justify-content-between">
                <div class="left">{{ $t("convert.inverse-rate") }}</div>
                <div class="right">1 {{currentPair.to}} = {{1/priceConvert | formatCurrencyAmount2(currentPair?.coin?.toLowerCase(), '0')}} {{currentPair.from}}</div>
              </div>
              <div class="d-flex w-100 justify-content-between">
                <div class="left">{{ $t("funds.withdrawals.transaction_fee") }}</div>
                <div class="right" v-if="!reversed">{{fromValue * priceConvert * feeTaker / 100 | formatTransactionFee(currentPair?.to?.toLowerCase(), '0')}} {{currentPair.to}}</div>
                <div class="right" v-else>{{toValue * feeTaker / 100 | formatTransactionFee(currentPair?.from?.toLowerCase(), '0')}} {{currentPair.from}}</div>
              </div>
              <hr>
              <div class="d-flex w-100 justify-content-between">
                <div class="left2">{{ $t("convert.you-will-receive") }}</div>
                <div class="right2" v-if="!reversed">{{(toValue - fromValue * priceConvert * feeTaker / 100) | formatFeeAmount(currentPair.to?.toLowerCase(), '0')}} {{currentPair.to}}</div>
                <div class="right2" v-else>{{(toValue - toValue * feeTaker / 100) | formatFeeAmount(currentPair.from?.toLowerCase())}} {{currentPair.from}}</div>
              </div>
            </div>
            <div class="d-flex loading" v-if="fetching === true"> <div class="loader"></div> <span>{{ $t("convert.fetching-best") }}</span></div>
            <!--            DRE-1877-->
            <span class="w-100 h-100 error-title warning-quote"  v-if="(fromValue>0 && toValue>0) && countdown === 0">
              <img src="/images/icon-warning.svg" width="24" height="24">
              {{ $t("convert.quote-expired") }}
            </span>
            <template v-if="isAuthenticated">
              <button :disabled="!(fromValue>0 && toValue>0)" class="btn btn-enter-amount">
                <span class="w-100 h-100 d-fex align-items-center justify-content-center" v-if="!(fromValue>0 && toValue>0)">{{ $t("convert.enter-an-amount") }}</span>
                <span class="w-100 h-100 d-fex align-items-center justify-content-center" v-else-if="(fromValue>0 && toValue>0) && countdown !== 0" @click="createConvert()">{{ $t("convert.btn-action-convert", {countdown}) }}</span>
                <span class="w-100 h-100 d-fex align-items-center justify-content-center" v-else @click="refreshQuote()">{{ $t("convert.refresh-quote") }}</span>
              </button>
            </template>
            <template v-else>
              <button class="btn btn-enter-amount">
                <router-link class="but_get_log" :to="{name: 'Login'}">{{ $t('login.title-3')}}</router-link>
                <span class="or-title"> {{ $t('order.order_form.or')}} </span>
                <router-link class="but_get_log" :to="{name: 'Register'}" >{{ $t('order.order_sign_up')}}</router-link>
              </button>
            </template>

          </div>
          <div v-else>
            <div class="input-coin-value" :class="{'animate__animated': animate, 'animate__backInLeft': animate && reversed, 'animate__backInRight': animate && !reversed, 'error': errors.has('from')}">
              <div class="title d-flex align-items-center justify-content-between">
                <span>{{ $t("funds.balances.from") }}</span>
                <span>{{ $t("funds.balances.balance") }}:  {{ currencyBalance | formatCurrencyAmount(currentPair[reversed ? 'to' : 'from'], isAuthenticated ? '0': '--')  }} {{currentPair[reversed ? 'to' : 'from']}}</span>
              </div>
              <div class="value-input d-flex align-items-center justify-content-between" v-if="currentPair.name">
                <currency-input
                    name="from"
                    :disabled="checkDisabled(currentPair.name, currentPair[reversed ? 'to' : 'from'])"
                    v-model="fromValue"
                    :unit="currentPair[reversed ? 'to' : 'from']?.toLowerCase()"
                    :placeholder="'0.00'"
                    :precision="coinPairSettings.quantityPrecision || 8"
                    :visible-button="true"
                    :maxLengthCus='15'
                    :class="{'disableColors': checkDisabled(currentPair.name, currentPair[reversed ? 'to' : 'from'])}"
                />
                <div class="coin">
                  <img v-if="currentPair[reversed ? 'imgTo' : 'imgFrom'] !== 'usd-img'" width="24" height="24" :src="currentPair[reversed ? 'imgTo' : 'imgFrom']">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
                  {{currentPair[reversed ? 'to' : 'from']}}
                </div>
              </div>
            </div>
            <div class="error-title" v-show="errors.has('from')">{{ $t("convert.field-error-mess") }}</div>
            <div style="margin-top: 8px" class="input-coin-value" :class="{'animate__animated': animate, 'animate__backInLeft': animate && reversed, 'animate__backInRight': animate && !reversed, 'error': errors.has('rate')}">
              <div class="title d-flex align-items-center justify-content-between">
                <span>{{ $t("convert.convert-price") }}</span>
                <span>{{ $t("trading_information.market_price") }}:  {{ priceConvert | formatCurrencyAmount(currentPair.currency, '0') }} {{currentPair.currency}}</span>
              </div>
              <div class="value-input d-flex align-items-center justify-content-between" v-if="currentPair.name">
                <currency-input
                    name="rate"
                    v-model="rate"
                    :unit="currentPair[reversed ? 'to' : 'from']?.toLowerCase()"
                    :placeholder="'0.00'"
                    :precision="coinPairSettings.pricePrecision || 2"
                    :visible-button="true"
                    :maxLengthCus='15'
                />
                <div class="coin">
                  <img v-if="currentPair.imgTo !== 'usd-img'" width="24" height="24" :src="currentPair.imgTo">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
                  {{currentPair.to}}
                </div>
              </div>
            </div>
            <div class="error-title" v-show="errors.has('rate')">{{ $t("convert.field-error-mess") }}</div>
            <div class="w-100 d-flex justify-content-center btn-swap" >
              <img src="@/assets/images/icons/swap-btn.svg"  v-on:click="swapDivs">
            </div>
            <div class="input-coin-value" :class="{'animate__animated': animate, 'animate__backInLeft': animate && !reversed, 'animate__backInRight': animate && reversed, 'error': errors.has('to')}">
              <div class="title d-flex align-items-center justify-content-between">
                <span>{{ $t("funds.balances.to") }}</span>
                <span>{{ $t("funds.balances.balance") }}: {{ coinBalance | formatCurrencyAmount(currentPair[reversed ? 'from' : 'to'],  isAuthenticated ? '0': '--' ) }} {{currentPair[reversed ? 'from' : 'to']}}</span>
              </div>
              <div class="value-input d-flex align-items-center justify-content-between" v-if="currentPair.name">
                <currency-input
                    name="to"
                    :disabled="checkDisabled(currentPair.name, currentPair[reversed ? 'from' : 'to'])"
                    v-model="toValue"
                    :unit="currentPair[reversed ? 'to' : 'from']?.toLowerCase()"
                    :placeholder="'0.00'"
                    :precision="coinPairSettings.quantityPrecision || 8"
                    :visible-button="true"
                    :maxLengthCus='15'
                    :class="{'disableColors': checkDisabled(currentPair.name, currentPair[reversed ? 'from' : 'to'])}"
                />
                <div class="coin">
                  <img v-if="currentPair[reversed ? 'imgFrom' : 'imgTo'] !== 'usd-img'" width="24" height="24" :src="currentPair[reversed ? 'imgFrom' : 'imgTo']">
                  <img v-else width="24" height="24" src="@/assets/images/usd-coin.svg">
                  {{currentPair[reversed ? 'from' : 'to']}}
                </div>
              </div>
            </div>
            <div class="error-title" v-show="errors.has('to')">{{ $t("convert.field-error-mess") }}</div>
            <div class="calculate-convert w-100">
              <div class="d-flex w-100 justify-content-between">
                <div class="left">{{ $t("convert.inverse-rate") }}</div>
                <div class="right">1 {{currentPair.to}} = {{1/ rate | formatCurrencyAmount2(currentPair?.coin?.toLowerCase(), '0')}} {{currentPair.from}}</div>
              </div>
              <div class="d-flex w-100 justify-content-between">
                <div class="left">{{ $t("funds.withdrawals.transaction_fee") }}</div>
                <div class="right" v-if="!reversed">
                  <span v-if="!(toValue && fromValue)">0.00</span>
                  <span v-else>{{(feeMaker * fromValue * rate / 100) | formatFeeAmount(reversed ? currentPair?.coin.toLowerCase() : currentPair?.currency.toLowerCase(), '0')}} </span>
                  {{currentPair.to}}</div>
                <div class="right" v-else>
                  <span v-if="!(toValue && fromValue)">0.00</span>
                  <span v-else>{{(feeMaker * toValue / 100) | formatFeeAmount(reversed ? currentPair?.coin.toLowerCase() : currentPair?.currency.toLowerCase(), '0')}} </span>
                  {{currentPair.from}}</div>
              </div>
            </div>
            <div class="d-flex loading" v-if="fetching === true"> <div class="loader"></div> <span>{{ $t("convert.fetching-best") }}</span></div>

            <template v-if="isAuthenticated">
              <button :disabled="!(fromValue>0 && toValue>0)" class="btn btn-enter-amount">
                <span class="w-100 h-100  d-fex align-items-center justify-content-center" v-if="!(fromValue>0 && toValue>0)">{{ $t("convert.place-order") }}</span>
                <span class="w-100 h-100  d-fex align-items-center justify-content-center" v-else @click="placeOrder()">{{ $t("convert.place-order") }}</span>
              </button>
            </template>
            <template v-else>
              <button class="btn btn-enter-amount">
                <router-link class="but_get_log" :to="{name: 'Login'}">{{ $t('login.title-3')}}</router-link>
                <span class="or-title"> {{ $t('order.order_form.or')}} </span>
                <router-link class="but_get_log" :to="{name: 'Register'}" >{{ $t('order.order_sign_up')}}</router-link>
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import VueHighcharts from 'vue2-highcharts';
import SuccessfulModal from './SuccessfulModal';
import {mapState} from "vuex";
import rf from "@/request/RequestFactory";
import Const from "@/common/Const";
import BigNumber from "bignumber.js";
import CurrencyInput from "@/components/spotexchange_pages/common/CurrencyInput";
import ConfirmOrderModal from "./ConfirmOrderModal";
import moment from 'moment';
import OrderUtils from "@/common/OrderUtils";

export default {
  name: "ConvertMarketScreen",
  components: {
    ConfirmOrderModal,
    VueHighcharts,
    SuccessfulModal,
    CurrencyInput
  },
  data() {
    return {
      typeConvert : 'market' , // 'market' | 'limit',
      isAuthenticated: window.isAuthenticated,
      currentPair: {},
      changePercent: 0,
      openDropPair: false,
      pairs: [],
      markets: [],
      pairShow: [],
      pairName: '',
      reversed: false, // track whether divs are swapped or not, reversed false =>> sell(get buy_price), reversed true ==> buy(get sell_price)
      animate: false, // track whether to animate or not
      chartOptions: {},
      fetching: false,
      converted: false,
      balances: [],
      currencyBalance: 0,
      coinBalance: 0,
      fromValue : undefined,
      toValue: undefined,
      rate: undefined,
      inverseRate: undefined,
      countdown: 8,
      priceConvert: undefined,
      feeMaker: 0,
      feeTaker: 0,
      resolution: 'D',
      focusInput: false,
      coinPairSettings: {},
      orderBookPrice: {},
      isOpenChartMobile: false,
      orderId: undefined
    }
  },

  computed: {
    ...mapState({
      masterData: state => state.masterdata,
      theme: state => state.userThemeMode,
    }),
    dark() {
          return this.theme === 'light-theme'? '': '-dark';
        },
    userThemeMode(){
      return this.theme
    }

  },
  watch: {
    isOpenChartMobile(value){
        if(value === true) {
            document.documentElement.style.overflow = 'hidden'
        } else {
            document.documentElement.style.overflow = 'auto'
        }
    },
    fromValue (newValue){
      if(!this.reversed && this.fromValue) {
        this.stopCountdown()
        this.countdown = 8;
        this.converted = false;
        this.fetching = true;
        // this.toValue = undefined;
        this.calculateInverse();
        this.startCountdown();
      }
    },
    toValue (newValue){
      if(this.reversed  && this.toValue) {
        this.stopCountdown()
        this.countdown = 8;
        this.converted = false;
        this.fetching = true;
        // this.fromValue = undefined;
        this.calculateInverse();
        this.startCountdown();
      }
    },
    rate (newRate) {
      if(this.reversed  && this.toValue) {
        this.stopCountdown()
        this.countdown = 8;
        this.converted = false;
        this.fetching = true;
        // this.fromValue = undefined;
        this.calculateInverse();
        this.startCountdown();
      }
      if(!this.reversed && this.fromValue) {
        this.stopCountdown()
        this.countdown = 8;
        this.converted = false;
        this.fetching = true;
        // this.toValue = undefined;
        this.calculateInverse();
        this.startCountdown();
      }
    },
    typeConvert() {
      // DRE-1855
      this.fromValue = undefined
      this.toValue = undefined
      this.reversed = false;
    },
    pairName (newValue) {
      this.searchPairDebounce();
    },
    resolution (newValue) {
      this.getBarChart();
    }
  },
  methods: {
    scrollToTop() {
          window.scrollTo(0, 0);
    },
    closeModal() {
      setTimeout(() => {
        this.getBlances(false);
        this.successFullModalClose();
      }, 300)
    },
    checkDisabled(name, from) {
      if (name.split('/')[0] !== from) {
        return true;
      }
      return false;
    },
    calculateInverse () {
      this.calculateInversePrice();
    },
    getTransactionFee() {
      if (window.isAuthenticated) {
        let params = {
          currency: this.currentPair.name.split('/')[1].toLowerCase(),
          coin: this.currentPair.name.split('/')[0].toLowerCase()
        }
        
        rf.getRequest('OrderRequest').getTransactionsFee(params).then(res => {
          this.feeMaker = res.data.fee_maker;
          this.feeTaker = res.data.fee_taker;
        })
      }
    },

    searchPairDebounce: _.debounce(function () {
      this.pairShow = this.pairs.filter(item => item.name.toLowerCase().includes(this.pairName.toLowerCase()))
    }, 800),
    calculateInversePrice: _.debounce(function () {
      let params = {
        currency: this.currentPair.currency.toLowerCase(),
        coin: this.currentPair.coin.toLowerCase()
      };
      rf.getRequest('PriceRequest').getPriceScope(params).then(res => {
      //   let ress= res
      //   if(params?.currency === 'usd' && params?.coin === 'btc') {
      //      ress = {
      //   ...res,
      //   data: {
      //     ...res.data,
      //     "current_price": 50000,
      //     "changed_percent": 1,
      //     "max_price": 50000,
      //     "min_price": 1,
      //     "volume": 1000,
      //     "quote_volume": "1000",
      //     "previous_price": 45000,
      //     "currency": "usd",
      //     "coin": "btc"
      //   }
      // }
      //   }
      //   if(params?.currency === 'usd' && params?.coin === 'sol') {
      //      ress = {
      //   ...res,
      //   data: {
      //     ...res.data,
      //     "current_price": 6,
      //     "changed_percent": 1,
      //     "max_price": 6,
      //     "min_price": 1,
      //     "volume": 100,
      //     "quote_volume": "100",
      //     "previous_price": 5,
      //   }
      // }
      //   }
        // let price = Number.parseFloat(ress.data.current_price || 0);
        let price = Number.parseFloat(res.data.current_price || 0);
        if (this.reversed) {
          if (this.typeConvert === 'market') {
            this.priceConvert = price;
            this.fromValue = this.toValue * price;
          } else {
            this.fromValue = this.toValue * this.rate;
          }

        } else {
          if (this.typeConvert === 'market') {
            this.priceConvert = price;
            this.toValue = this.fromValue * price;
          } else {
            this.toValue = this.fromValue * this.rate;
          }
        }
        this.fetching = false;
        if(!this.fetching) {
          this.converted = true;
        }
      });
    }, 800),

    clickOutSidePair() {
      this.openDropPair =false;
      this.pairName = '';
      this.focusInput = false;
      this.$refs.listPair.scrollTop = 0;
    },
    onChangeResolution(time) {
      this.resolution = time;
    },
    getBarChart() {
      let params = {
        coin       : this.currentPair.coin.toLowerCase(),
        currency   : this.currentPair.currency.toLowerCase(),
        resolution : 25000,
        from       : 0,
        // to         : moment().endOf('day').format('X'),
        to         : moment().format('X'),
        market_type: 1
      };

      if(this.resolution === 'D') {
        params.from =  moment().subtract(24, 'hours').format('X');
        params.resolution = 250000;
      }else if(this.resolution === 'W') {
        params.from = moment().subtract(6, 'days').startOf('day').format('X');
        params.resolution = 2500000;
      }
      else {
        params.from = moment().subtract(1, 'months').add('days', 1).startOf('day').format('X');
        params.resolution = 25000000;
      }
      rf.getRequest('PriceRequest').getChartBars(params).then(bars => {
        const data = bars.map(([timestamp, stringValue]) => [timestamp, Number.parseFloat(stringValue)]);
        this.chartOptions = {
          chart: {
            type: 'area',
            zoomType: 'x',
            height: window.matchMedia('(max-width: 1200px)').matches ? 280 : 530,
            backgroundColor: 'transparent'
          },
          title: {
            text: '',
          },
          xAxis: {
            type: 'datetime',
            allowDecimals: true,
            gridLineWidth: 0
          },
          yAxis: {
            visible: false, // remove the y-axis
            title: {
              text: null // set the title of the Y-axis to null to remove it
            },
            labels: {
              enabled: false // hide the labels of the Y-axis
            }
          },
          tooltip: {
            useHTML: true,
            formatter: function() {
              // Get the x and y values of the current point
              // const x = new Date(this.x).toISOString().slice(0, 19).replace('T', ' ');
              const x = moment( new Date(this.x)).format('YYYY-MM-DD HH:mm:ss');
              const y = this.y;

              // Get the series name of the current point
              const seriesName = '';

              // Define the custom HTML for the tooltip with the series name
              const tooltipHTML = `
              <div class="custom-tooltip" style="background: #2D303B">
                <span class="tooltip-date">${x}</span><br>
                <span class="tooltip-value"><span style="color:#00DDB3">\u25CF</span> ${seriesName} ${y}</span>
              </div>
            `;

              // Return the HTML for the tooltip
              return tooltipHTML;
            },
            style: {
              color: '#fff'
            },
            valueDecimals: 0,
            backgroundColor: '#2D303B',
            borderColor: '#2D303B',
            borderRadius: 8,
            borderWidth: 0,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: [0, 0, 0, 300], // set the direction and length of the gradient
                stops: [
                  [0, '#00DDB3'], // set the start color of the gradient
                  [1, 'rgba(0, 221, 179, 0.2)'] // set the end color of the gradient
                ]
              },
            }
          },
          credits: {
            enabled: false // disable the credits link
          },
          series: [{
            color: '#00DDB3',
            showInLegend: false,
            name: null,
            data: data
          }],
        };
      })

    },

    resolutionToMilliseconds(resolution) {
      var minutes = parseInt(resolution);
      if (isNaN(minutes)) {
        minutes = Const.CHARTING_RESOLUTION_MULTIPLICITY[resolution];
      }
      return minutes * 60 * 1000;
    },
    formatCurrencyRoundDown(amount, currency, value) {
      if (amount == undefined) {
        return 0;
      }

      let numberOfDecimalDigits = currency && currency.toLowerCase() === 'usd' ? Const.NUMBER_OF_DECIMAL_DIGITS_USD : Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;

      let newValue = new BigNumber(amount);

      return newValue.round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR).toString();
    },
    calculateCoinBalance() {
      const from = this.currentPair[this.reversed ? 'from' : 'to'].toLowerCase();
      const to = this.currentPair[this.reversed ? 'to' : 'from'].toLowerCase();
      if (this.balances[to]) {
        this.currencyBalance = this.formatCurrencyRoundDown(this.balances[to].available_balance, to, '0');
      }
      if (this.balances[from]) {
        this.coinBalance = this.formatCurrencyRoundDown(this.balances[from].available_balance, from, '0');
      }
    },
    getBlances(useCache = true) {
      if (window.isAuthenticated) {
        rf.getRequest("UserRequest")
            .getBalance(useCache, Const.TYPE_EXCHANGE_BALANCE)
            .then(res => {
              this.onBalanceUpdated(res.data);
            });
      }
    },
    getSocketEventHandlers() {
      return {
        BalanceUpdated: this.onBalanceUpdated,
      };
    },
    onBalanceUpdated(newBalances) {
      if (newBalances && newBalances[Const.TYPE_EXCHANGE_BALANCE]) {
        newBalances = newBalances[Const.TYPE_EXCHANGE_BALANCE];
      }
// const usdt = { "usdt": {
//         "balance": "1000.0000000000",
//         "available_balance": "1000.0000000000"
//     }}
//       this.balances = Object.assign({}, this.balances, newBalances,
//       usdt
//       );
      this.balances = Object.assign({}, this.balances, newBalances);
      if (window._.isEmpty(this.balances)) {
        return;
      }
      this.calculateCoinBalance();
    },
    successFullModalClose() {
      this.toValue = undefined;
      this.fromValue = undefined;
      this.rate = this.priceConvert;
    },
    selectPair(pair) {
      this.currentPair = {
        name: pair.name,
        from: pair.name.split("/")[0],
        imgFrom: this.imageCoin(pair.name.split("/")[0].toLowerCase()),
        to: pair.name.split("/")[1],
        imgTo: this.imageCoin(pair.name.split("/")[1].toLowerCase()),
        coin: pair.name.split('/')[0],
        currency: pair.name.split('/')[1]
      };
      let coin = this.currentPair.coin.toLowerCase();
      let currency = this.currentPair.currency.toLowerCase();
      rf.getRequest("SettingRequest").getPairCoinSettings({coin, currency}).then(res => {
        this.coinPairSettings = {
          minAmount: res?.data?.minimum_amount,
          minQuantity: res?.data?.minimum_quantity,
          pricePrecision: this.getExponent(res?.data?.price_precision),
          quantityPrecision: this.getExponent(res?.data?.quantity_precision)
        };
        // let data = {...res?.data}

        // if(currency==='usd' && coin==="btc"){
        //   data = 
        //   {
        //     "id": 3033,
        //     "currency": "usd",
        //     "coin": "btc",
        //     "minimum_quantity": "1.0000000000",
        //     "price_precision": "0.0000000100",
        //     "minimum_amount": "0.0010000000",
        //     "created_at": null,
        //     "updated_at": null,
        //     "is_enable": 1,
        //     "is_show_beta_tester": 0,
        //     "quantity_precision": "0.0000000100",
        //     "zone": 0,
        //     minAmount: "0.0010000000",
        //     minQuantity: "1.0000000000",
        //     pricePrecision: 8,
        //     quantityPrecision: 8,
        //   }
        // }
        this.coinPairSettings = window._.merge(this.coinPairSettings, 
        res.data,
        // data
        );
      })

      this.calculateCoinBalance();
      this.getTransactionFee();
      this.getRateLimit();
      this.getBarChart();
      this.getPriceScope();
      this.getOrderBookPrice();
      this.fromValue = undefined;
      this.toValue = undefined;
      this.openDropPair = false;
      this.pairName = '';
      this.pairShow = this.pairs;
      this.focusInput = false;
    },
    getExponent(num) {
      return Math.floor(Math.log10(num)) > 0 ? Math.floor(Math.log10(num)): Math.floor(Math.log10(num)) * -1;
    },

    getRateLimit() {
      if (this.typeConvert === 'limit') {
        rf.getRequest('PriceRequest').getPriceScope({
          currency: this.currentPair.name.split('/')[1].toLowerCase(),
          coin: this.currentPair.name.split('/')[0].toLowerCase()
        }).then(res => {

          //DRE-1857
          const currentPriceMarket = Number.parseFloat(res.data.current_price || 0);
          if(res.data.current_price === 0){
            this.priceConvert = this.rate = undefined
            return
          }
          this.priceConvert = this.rate = currentPriceMarket;
        });
      }
    },
    getOrderBookPrice() {
      rf.getRequest('OrderRequest').getOrderBook({
        currency: this.currentPair.name.split('/')[1].toLowerCase(),
        coin: this.currentPair.name.split('/')[0].toLowerCase()
      }).then(res => {
        this.orderBookPrice = {
          buy: res?.data?.buy?.length,
          sell: res?.data?.sell?.length
        }
      })
    },
    searchPair(){
      this.pairShow = this.pairs.filter(item => item.name.toLowerCase().includes(this.pairName.toLowerCase()))
    },
    handleDelete(){
      this.pairName = ''
    },
    swapDivs() {
      this.reversed = !this.reversed;
      // this.animate = true;
      const oldCoinBalance = this.coinBalance;
      const oldcurrencyBalance = this.currencyBalance;
      this.currencyBalance = oldCoinBalance;
      this.coinBalance = oldcurrencyBalance;
      // const oldFrom = this.fromValue;
      // const oldTo = this.toValue;
      // this.fromValue = oldTo;
      // this.toValue = oldFrom;
      ///DRE-1848
      this.fromValue = undefined;
      this.toValue = undefined;
      // setTimeout(() => { this.animate = false }, 1000); // animation duration is 1s
    },
    createConvert() {
      const params = {
        trade_type: this.reversed ? 'buy' : 'sell',
        type: this.typeConvert,
        market_type: 1,
        quantity: this.reversed ? this.toValue : this.fromValue,
        currency: this.currentPair.name.split('/')[1].toLowerCase(),
        coin: this.currentPair.name.split('/')[0].toLowerCase(),
        balance: this.currencyBalance
      }
      console.log('params', params)
      try {
        OrderUtils.validateOrderInput(params, this.coinPairSettings, true);
      } catch (error) {
        console.log('error', error)
        this.showInputError(error.message);
        return;
      }

      if (params.trade_type === 'buy' && this.orderBookPrice.sell == 0) {
        Message.errorInputSpot(window.i18n.t("convert.conversion-request"), {}, { position: "bottom_left" });
        return;
      } else if (params.trade_type === 'sell' && this.orderBookPrice.buy == 0) {
        Message.errorInputSpot(window.i18n.t("convert.conversion-request"), {}, { position: "bottom_left" });
        return;
      }

      rf.getRequest('OrderRequest').convertOrder(params).then(res => {
        if (res.success) {
          this.orderId = res.data.id;
          setTimeout(() => {
            this.getBlances(false);
          }, 1000)
          window.CommonModal.show('SuccessfulModal');
        }
      }).catch(error => {
        if (!error.response) {
          this.showError(window.i18n.t("common.message.network_error"));
        } else {
          this.showInputError(error.response.data.message);
        }
        this.getBlances(false);
      })

    },

    showInputError(message) {
      Message.errorInputSpot(message, window.i18n.t("tooltip.check-again"), { position: "bottom_left" });
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    placeOrder() {
      window.CommonModal.show('ConfirmOrderModal');
    },
    refreshQuote() {
      this.countdown = 8;
      this.calculateInverse();
      this.startCountdown();
    },
    imageCoin(name) {
      const item = this.masterData?.coins.find(m => m.coin == name.toLowerCase());
      return item?.icon_image || 'usd-img';
    },
    startCountdown() {
      this.timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer);
    },
    getPriceScope() {
      let params = {
        currency: this.currentPair.currency.toLowerCase(),
        coin: this.currentPair.coin.toLowerCase(),
      };
      return rf.getRequest('PriceRequest').getPriceScope(params).then(res => {
        this.changePercent = res.data.changed_percent;
      }).catch(e => {
        console.log(e);
      });
    },
  },

  mounted() {
    this.scrollToTop();
    rf.getRequest('PriceRequest').getPrices24h().then(res => {
        const data = {
          ...res.data,
      //     usd_btc: {
      //       "current_price": 50000,
      //     "changed_percent": 1,
      //     "max_price": 50000,
      //     "min_price": 1,
      //     "volume": 1,
      //     "quote_volume": "1",
      //     "previous_price": 45000,
      //     "currency": "usd",
      //     "coin": "btc"
      // }
        }
    // const convertData = Object.keys(data).map((key) => {
    //   const coin = key.split("_")[1].toUpperCase();
    //         const currency = key.split("_")[0].toUpperCase();
    //         return { name: `${coin}/${currency}`, value: key, coin, currency   }
    // })
    // const filter = convertData.filter(p => {
    //     const isFromMasterData = this.masterData.coin_settings.find(md => md.currency == p.currency.toLowerCase() && md.coin == p.coin.toLowerCase())
    //     console.log('isFromMasterData', !!isFromMasterData)
    //   // return true
    //     return isFromMasterData ? true : false
    //   })
    // this.pairs = filter
      this.pairs = Object.keys(data).map((key) => {
        const coin = key.split("_")[1].toUpperCase();
        const currency = key.split("_")[0].toUpperCase();
        return { name: `${coin}/${currency}`, value: key, coin, currency   };
      }).filter(p => {
        const isFromMasterData = this.masterData.coin_settings.find(md => md.currency == p.currency.toLowerCase() && md.coin == p.coin.toLowerCase())
        return isFromMasterData ? true : false
      });

      this.pairs.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      this.pairShow = this.pairs;
      const btc_usdt = this.pairs.find(item => item.value === 'usdt_btc')
      btc_usdt && this.selectPair(btc_usdt);
      this.getBlances();
      this.getTransactionFee();
      this.getBarChart();
      this.calculateInversePrice();
    });

  },
  beforeDestroy() {

  }
}
</script>

<style lang="scss" scoped>
  .bgr_mobile {
    background: $color-black !important;
    opacity: 0.4;
    position: absolute;
    z-index: 2022;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .error {
      border: 1px solid $text-color-red;
    }
  .error-title {
    color: $text-color-red;
    font-size: $font-root;
    line-height: 18px;
    margin-top: 8px;
  }
  .warning-quote {
    display: flex;
    align-items: center;
    gap: 9px;
    margin-top: 14.5px;
    margin-bottom: 26.5px;
    font-size: $font-big;
  }
  .dark-theme {
    #convert {
      background: linear-gradient(180deg,#002E26 0%, #090A0C 40%);


     .choose-convert .convert-container .btn-enter-amount:disabled {
      background: $bgr-button-disable;
      color: $color-gunmetal;
     }
     .conversion-history {
      button {
        &:hover {
          background-color: $color-hover-btn;
        }
      }
     }
    }
    .information {
      .infor-light {
        display: none!important;
      }
      .infor-dark {
        display: block!important;
      }
    }

    #convert .choose-convert .drop-down-pair .drop-list-pair {
      .list-pair {
        color: $color-auro-metal;
      }
      .text-search-pair input {
        background: $color-gray-mako;
      }
    }

  }
  #convert {
    //height: 150vh;
    background: linear-gradient(180deg, #F1FFFC 0%, #FFFFFF 100%);
    background-size: 100% 459px;
    background-repeat: no-repeat;
    display: flex;
    font-family: $font-inter-regular;
    padding: 67px 0px 148px;

    .conversion-history {
      right: -305px;
      button{
        color: var(--monas-convert-asset-title);
        background-color: var(--monas-convert-background-conversion);
        width: 239px;
        color: var(--monas-convert-asset-title);
        font-size: 18px;
        padding: 14px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
       
      
        border-radius: 8px;
        margin-bottom: 40px;
        margin-left: auto;
        cursor: pointer;
        &:hover {
          background-color: $color-gray-dark;
          color: $color-white;
        }
      }
      
    }
    .chart-container {
      width: 51%;
      min-height: 700px;
      background: transparent;
      margin-right: 60px;
      .title-convert {
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;
        color: var(--monas-convert-title);
        margin-bottom: 50px;
      }
      .current-price {
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;
        margin-right: 8px;
        color: var(--text-primary-color);
      }
      .current-percent {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        margin-right: 8px;
        color: var(--text-primary-color);
      }
      .current-24h {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: var(--monas-convert-color-tab);

        .time-chart {
          color: var(--monas-convert-chart-resolution);
          font-size: 16px;
          font-weight: 600;
          margin-right: 26px;
          cursor: pointer;
          &:last-child {
            margin-right: 13px;
          }
        }
        .time-chart-selected {
          color: $color-caribbean-green;
        }

      }
      .buy {
        color: $text-color-jade;
      }
      .sell {
        color: $text-color-red;
      }
      .coin-convert {
        margin-bottom: 16px;
        .coin {
          font-size: 20px;
          color: var(--text-primary-color);
          font-weight: 600;
          margin-right: 8px;
          gap: 4px;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .price_coin {
          color: var(--monas-convert-color-tab);
          font-size: $font-root;
        }

      }
      .chart {
        min-height: 500px;
      }
    }
    .choose-convert {
      width: 27%;
      max-width: 550px;
      min-width: 520px;

      .title-convert {
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 42px;
        color: var(--monas-convert-title);
        font-family: $font-inter-regular;
        @media screen and (max-width: 500px) {
          margin-bottom: 26px;
        }
      }
      .choose-pair-convert {
        font-size: 18px;
        color: var(--monas-choose-pair-convert);
      }
      .drop-down-pair {
        width: 150px;
        height: 49px;
        padding: 14px;
        background: var(--monas-background-dropdown-convert);
        color: var(--text-second-color);
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
        .current-pair {
          font-size: 18px;
          color: var(--monas-choose-pair-convert);
        }
        .icon-arrow1 {
          color: var(--monas-choose-pair-convert);
        }
        .drop-list-pair {
          width: 200px;
          background: var(--monas-convert-pair-list);
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          top: 57px;
          left: -50%;
          transform: translateX(25%);
          padding: 20px;
          z-index: 15;

          .list-pair {
            margin-bottom: 16px;
            font-size: 16px;
            color: var(--text-primary-color);
            cursor: pointer;
            &:hover {
              color: $color-caribbean-green;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
          .list-pair-drop {
            max-height: 175px;
            overflow-y: auto;
           
          }
          .list-no-data{
            text-align: center;
            padding: 24px 0;
            color: var(--text-close);
          }

          .text-search-pair {
            width: 160px;
            height: 39px;
            margin-bottom: 16px;

            input {
              background: var(--background-color-second);
              border-radius: 8px;
              border: none;
              padding: 0 36px;
            }

            .icon-search {
              left: 15px;
              top: 50%;
              transform: translateY(-50%);
            }
            .icon-close {
              top: 50%;
              transform: translateY(-50%);
              right: 15px;
            }
          }
        }
      }
      .convert-container {
        margin-top: 32px;
        .tab-item {
          color: var(--monas-convert-color-tab);
          margin-right: 40px;
          cursor: pointer;
          font-size: 20px;
          line-height: 24px;
        }
        .tab-selected {
          color: var(--text-primary-color);

          &:after {
            content:"";
            float:left;
            background:$color-caribbean-green;
            width:100%;
            height:4px;
            border-radius: 20px;
            margin-top: 8px;
          }
        }

        .input-coin-value {
          padding: 24px;
          background: var(--background-color-second);
          border-radius: 8px;
          width: 100%;
          height: 123px;
          .title {
            color: var(--monas-convert-color-tab);
            font-size: $font-root;
            line-height: 19px;
          }
          .value-input {
            margin-top: 16px;
            input {
              font-size: 24px;
              line-height: 32px;
              font-weight: 600;
              width: 80%;
              border: none;
              background: transparent;
              color: var(--text-primary-color);
              &::placeholder {
                font-size: 24px;
                color: var(--monas-convert-color-tab);
              }
            }
            .coin {
              width: fit-content;
              height: 40px;
              background: var(--monas-convert-background-coin);
              border-radius: 8px;
              display: flex;
              align-items: center;
              padding: 10px 12px;
              color: var(--text-primary-color);
              font-size: 18px;
              img {
                margin-right: 8px;
                object-fit: contain;
                border-radius: 50%;
              }
            }
          }
        }

        .loading {
          margin: 27px 0;
         span {
           font-size: 18px;
           margin-left: 10px;
           color: var(--text-primary-color);
         }
        }

        .btn-swap {
          margin: 8px 0;
        }
        .btn-enter-amount {
          margin-top: 24px;
          width: 100%;
          background: $color-caribbean-green;
          border-radius: 8px;
          height: 49px;
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: $dark-1;
          font-size: 18px;
          .or-title {
            margin: 0 8px;
          }

          a {
            color: $dark-1;
            font-weight: 600;
            text-underline-offset: 4px;
            text-decoration: underline;
          }

          &:hover, &:active{
            background: $color-aquamarine ;
            color: $color-grey-dark;
          }
          &:disabled {
            background: $color-aero-blue;
            color: $color-gray-dark;
          }
        }
      }
    }



    div#infor:hover div#content-infor {
      display: block;
    }

    div#infor2:hover div#content-infor2 {
      display: block;
    }
    .information {
      .content {
        width: 227px;
        height: auto;
        z-index: 1;
        right: 0;
        top: 24px;
        background: var(--monas-convert-tooltip);
        border-radius: 8px;
        padding: 10px;
        color: white;
        display: none;
      }

      .infor-light {
        display: block;
      }
      .infor-dark {
        display: none;
      }

    }
    .loader {
      border: 2px solid #80eed9;
      border-radius: 50%;
      border-top: 2px solid $color-caribbean-green;
      width: 20px;
      height: 20px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    .calculate-convert {
      margin-top: 25px;
      font-family: $font-inter-regular;
      .left {
        font-weight: 400;
        font-size: 16px;
        color: var(--monas-convert-color-tab);
        margin-bottom: 12px;
      }
      .right {
        font-weight: 500;
        font-size: 16px;
        color: var(--text-primary-color);
      }
      .left2 {
        font-weight: 400;
        font-size: 20px;
        color: var(--text-primary-color);
        margin-bottom: 12px;
      }
      .right2 {
        font-weight: 600;
        font-size: 20px;
        color: var(--text-primary-color);
      }
      hr {
        margin: 3px 0 16px;
        border-color: var(--monas-convert-hr-color);
      }
    }
    .chart-mobile {
      position: fixed;
      background: var(--bgr-dropdown);
      bottom: 0;
      height: 465px;
      width: 100%;
      z-index: 2023;
      padding: 30px 16px;
      border-radius: 8px 8px 0 0;
    }
  }
  @media screen and (max-width: 1200px) {
    #convert {
      padding: 40px 16px 80px;


      .chart-container {
        width: 100%;
        min-height: auto;
        .coin-convert {
          .coin {
            font-size: 16px;
          }
          .price_coin {
            font-size: 12px;
          }
        }
        .current-price {
          font-size: 24px;
        }
        .current-percent, .past-24h {
          font-size: 14px;
        }
      }
      .warning-quote {
        font-size: 14px;
      }

      .calculate-convert {
        .content {
          left: 0;
          right: auto;
        }
      }

      .choose-convert {
        .convert-container {
          .tab-item {
            font-size: 16px;
          }
          .btn-enter-amount{
            font-size: 16px;
            line-height: 19px;
          }
          .input-coin-value {
            height: 100px;
            padding: 16px 24px;
            .title {
              line-height: 16px;
            }

            .value-input {
              margin-top: 12px;
              input {
                font-size: 20px;
                &::placeholder {
                  font-size: 20px;
                  line-height: 24px;
                }
              }
              .coin {
                font-size: 14px;
              }
            }
          }
        }
        .title-convert {
          display: flex;
          font-size: 24px;
          font-weight: 600;
        }

        .choose-pair-convert {
          font-size: 14px;
        }
        .drop-down-pair {
          height: 44px;
          width: 124px;
          .current-pair {
            font-size: 14px;
          }

          .drop-list-pair {
            right: 0;
            left: auto;

            transform: none;
          }
        }

      }

      .calculate-convert {
        .left, .right {
          font-size: 14px;
        }
        .left2 {
          font-size: 16px;
        }
        .right2 {
          font-size: 18px;
        }

      }
    }

  }


  @media screen and (max-width: 650px) {
    #convert  {
      .choose-convert {
        width: 90%;
        max-width: 550px;
        min-width: 345px;
      }
    }
  }

  @media screen and (max-width: 350px) {
    #convert  {
      .choose-convert {
        width: 90%;
        max-width: 550px;
        min-width: 300px;
      }
    }
  }

  .disableColors {
    color: var(--monas-convert-disabled-color)!important;
    opacity: 0.5;
    &::placeholder {
      color: var(--monas-convert-disabled-color)!important;
      opacity: 0.5;
    }
  }
  .time-desktop {
    @media screen and (max-width: 1200px) {
      display: none !important;
    }
  }
  .time-mobile {
    @media screen and (min-width: 1200px) {
      display: block !important;
    }
  }
  .time-chart {
    color: var(--monas-convert-chart-resolution);
    font-size: 16px;
    font-weight: 600;
    font-family: $font-inter-regular;
    margin-right: 26px;
    cursor: pointer;
    &:last-child {
      margin-right: 13px;
    }
  }
  .time-chart-selected {
    color: $color-caribbean-green;
  }
  
</style>